import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  LinearProgress,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { Formik } from "formik";
import moment, { Moment } from "moment";
import * as Yup from "yup";

import ApiEnumMultiselect from "@/common/components/Enum/ApiEnumMultiselect";
import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import { DATETIME_FORMATS } from "@/common/constants/common";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { ContractDto, CreateContractCustomerCommunicationEntryDto } from "@/core/api/generated";

import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import GeneralValidationError from "../../Error/GeneralValidationError";
import PerformantTextFieldForFormikUnstable from "../../Form/Input/PerformantTextFieldForFormikUnstable";
import CustomerContactSelect from "../Customer/CustomerContactSelect";

export interface CreateContractCustomerCommunicationEntryOwnProps {
  contractId: string;
  onCreate?: (newValue: ContractDto) => void;
  onSave?: (newValue: ContractDto) => void;
}

export type CreateContractCustomerCommunicationEntryProps =
  CreateContractCustomerCommunicationEntryOwnProps;

export default function CreateContractCustomerCommunicationEntry({
  contractId,
  onCreate,
  onSave,
}: CreateContractCustomerCommunicationEntryProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const thunkDispatch = useAppThunkDispatch();

  const customerRequest = useApiRequest(
    apiClient.customersApi.apiV1CustomersByContractContractIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      contractId,
    },
    {
      skip: !contractId,
    },
  );
  const customer = customerRequest?.data;

  return (
    <Formik<CreateContractCustomerCommunicationEntryDto & BaseFormikValues>
      enableReinitialize={!!customer}
      initialValues={{
        customerContactId: customer?.contact?.id || undefined,
        contactChannel: undefined,
        subjectTypes: undefined,
        subject: undefined,
        notes: undefined,
        contactedAt: moment().format(),
        submit: "",
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          const response =
            await apiClient.contractsApi.apiV1ContractsContractIdCustomerCommunicationEntriesPost({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              contractId: contractId,
              createContractCustomerCommunicationEntryDto: {
                ...values,
                contactedAt: values.contactedAt && moment(values.contactedAt).utc().format(),
              },
            });
          enqueueSnackbar("Entry created.", { variant: "success" });
          onCreate && onCreate(response.data);
          onSave && onSave(response.data);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Box>
              {customerRequest.isLoading && <LinearProgress sx={{ my: 1 }} />}

              <FormControl
                required
                fullWidth
                margin='dense'
                error={Boolean(touched.customerContactId && errors.customerContactId)}
              >
                <FormLabel required sx={{ mb: 1 }}>
                  Customer contact (who contacted)
                </FormLabel>
                <CustomerContactSelect
                  customer={customer}
                  value={values.customerContactId}
                  onChange={(e, newValue) => setFieldValue("customerContactId", newValue.id)}
                />
                <FormHelperText>
                  {touched.customerContactId && errors.customerContactId}
                </FormHelperText>
              </FormControl>

              <FormControl
                required
                fullWidth
                margin='dense'
                error={Boolean(touched.contactedAt && errors.contactedAt)}
              >
                <MobileDateTimePicker
                  ampm={false}
                  label='Contacted at'
                  value={(values.contactedAt && moment(values.contactedAt)) || null}
                  format={DATETIME_FORMATS.DISPLAY_DATETIME}
                  onChange={(newValue: Moment | null) => {
                    setFieldValue("contactedAt", newValue?.format() || null);
                  }}
                  slots={{ textField: (params) => <TextField {...params} required /> }}
                />
                <FormHelperText>{touched.contactedAt && errors.contactedAt}</FormHelperText>
              </FormControl>

              <FormControl
                margin='dense'
                fullWidth
                error={Boolean(touched.contactChannel && errors.contactChannel)}
              >
                <InputLabel required>Contact channel</InputLabel>
                <ApiEnumSelect
                  type='ContactChannel'
                  value={values.contactChannel}
                  onChange={(newValue) => setFieldValue("contactChannel", newValue)}
                  selectProps={{
                    required: true,
                    label: "Contact channel",
                  }}
                />
                <FormHelperText>{touched.contactChannel && errors.contactChannel}</FormHelperText>
              </FormControl>

              <FormControl
                margin='dense'
                fullWidth
                error={Boolean(touched.subjectTypes && errors.subjectTypes)}
              >
                <ApiEnumMultiselect
                  type='ContractCommunicationSubjectType'
                  values={values.subjectTypes}
                  required
                  label='Subject types'
                  onChange={(newValue) => setFieldValue("subjectTypes", newValue)}
                />
                {!errors.subjectTypes && (
                  <FormHelperText>
                    What is the main subject of the conversation with the customer?
                  </FormHelperText>
                )}
                <FormHelperText>{touched.subjectTypes && errors.subjectTypes}</FormHelperText>
              </FormControl>

              <TextField
                error={Boolean(touched.subject && errors.subject)}
                fullWidth
                helperText={touched.subject && errors.subject}
                label='Subject'
                margin='dense'
                name='subject'
                onBlur={handleBlur}
                onChange={handleChange}
                type='text'
                value={values.subject}
                variant='outlined'
              />

              <PerformantTextFieldForFormikUnstable
                error={Boolean(touched.notes && errors.notes)}
                isPerformanceEnabled
                fullWidth
                multiline
                rows={2}
                helperText={touched.notes && errors.notes}
                label='Notes'
                margin='dense'
                name='notes'
                onBlur={handleBlur}
                onChange={handleChange}
                type='text'
                value={values.notes}
                variant='outlined'
              />
            </Box>

            <GeneralValidationError sx={{ my: 1 }} errors={errors} />

            <LoadingButton
              sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
              color='primary'
              loading={isSubmitting}
              fullWidth
              type='submit'
              variant='contained'
            >
              Save
            </LoadingButton>
          </form>
        );
      }}
    </Formik>
  );
}
