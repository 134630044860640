import { Button, IconButton, ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { DepartmentDto, LocationDto, LocationGetPaginatedDto } from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { useCallback, useState } from "react";
import DepartmentAddLocationsModal from "../../../../../../App/MainAppView/Management/Departments/components/DepartmentAddLocationsModal";

interface Props {
  department: DepartmentDto;
}

export default function LocationsTabContent({ department }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const commonRequestParams = useCommonRequestParams<LocationGetPaginatedDto>({});

  const [isAddLocationsModalOpen, setIsAddLocationsModelOpen] = useState(false);
  const [isRemoveLocationModalOpen, setIsRemoveLocationModalOpen] = useState(false);
  const [locationToRemove, setLocationToRemove] = useState<LocationDto | undefined>(undefined);

  const paginatedLocationsRequest = useApiRequest(
    apiClient.locationsApi.apiV1LocationsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      locationGetPaginatedDto: {
        departmentId: department.id!,
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [department.id, ...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedLocations = paginatedLocationsRequest?.data;

  const handleLocationRemovalConfirmed = useCallback(
    async (location: LocationDto) => {
      try {
        await apiClient.departmentsApi.apiV1DepartmentsDepartmentIdLocationsLocationIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          departmentId: department!.id!,
          locationId: location.id!,
        });
        enqueueSnackbar("Location removed from the department.", { variant: "success" });
        paginatedLocationsRequest.refetch();
        setIsRemoveLocationModalOpen(false);
      } catch (err) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        validation2.hasErrors &&
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      }
    },
    [department],
  );

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Locations"}
            primaryAction={
              <Button
                variant='contained'
                color='primary'
                onClick={() => setIsAddLocationsModelOpen(true)}
              >
                Add locations
              </Button>
            }
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <DataTabular
            columns={[
              {
                field: "localNumber",
                title: "Number",
                flex: 1,
                renderCell: (item) => item.localNumber,
              },
              {
                field: "name",
                title: "Name",
                flex: 1,
                renderCell: (item) => item.name,
              },
              {
                field: "description",
                title: "Description",
                flex: 1,
                renderCell: (item) => item.description,
              },
              {
                field: "address",
                title: "Address",
                flex: 1,
                renderCell: (item) => (
                  <GeneralAddressDisplay address={item.address} direction='row' />
                ),
              },
            ]}
            isLoading={paginatedLocationsRequest.isLoading}
            rows={paginatedLocations?.items}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.LOCATION_VIEW(item.id)}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            renderRowAction={(actionParams) => (
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <MenuItem
                  onClick={() => {
                    setLocationToRemove(actionParams.item);
                    setIsRemoveLocationModalOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='remove' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Remove from department</ListItemText>
                </MenuItem>
              </MenuWithTrigger>
            )}
          />
        </Stack>

        <DepartmentAddLocationsModal
          department={department}
          open={isAddLocationsModalOpen}
          onClose={() => {
            setIsAddLocationsModelOpen(false);
          }}
          onAdded={() => {
            setTimeout(() => {
              paginatedLocationsRequest.refetch();
            }, 2000);
          }}
        />

        {/* Confirm location removal */}
        {locationToRemove && (
          <ConfirmationModal
            title='Remove location from the department?'
            body={
              <>
                {`You're going to remove location`} <strong>{locationToRemove.name}</strong> from
                the department <strong>{department.name}</strong>. {`This action can't be undone.`}
              </>
            }
            open={isRemoveLocationModalOpen}
            onClose={() => setIsRemoveLocationModalOpen(false)}
            onCancel={() => setIsRemoveLocationModalOpen(false)}
            onConfirm={() => handleLocationRemovalConfirmed(locationToRemove)}
          />
        )}
      </ViewLayout>
    </PageTabContent>
  );
}
