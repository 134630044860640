import { Button, Stack, SxProps, Theme } from "@mui/material";
import { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import ImportFromRepairCatalogModal from "@/App/MainAppView/Repair/RepairCatalog/components/ImportFromRepairCatalogModal";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import PercentValue from "@/common/components/Form/Display/PercentValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  RepairSpecDto,
  RepairSpecGetPaginatedDto,
} from "@/core/api/generated";
import GeneralCurrencyDisplay from "../../General/Display/GeneralCurrencyDisplay";
import GeneralDiscountDisplay from "../../General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralTaxDisplay from "../../General/GeneralTax/GeneralTaxDisplay";
import { getDetalizationInlineText } from "../RepairSpecDetalizationInline";
import RepairSpecMenu from "../RepairSpecMenu";

const defaultDisplayProps = {
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type RepairSpecPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface RepairSpecPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
  };
  propagatedDeps?: PropagatedDeps<RepairSpecPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type RepairSpecPaginatedListProps = RepairSpecPaginatedListOwnProps;

export default function RepairSpecPaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
  sx,
}: RepairSpecPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<RepairSpecGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.RepairSpec,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
  });

  const [isPaginatedLoading, setIsPaginatedLoading] = useState(false);
  const [repairSpecToDelete, setRepairSpecToDelete] = useState<RepairSpecDto | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isImportFromRepairCatalogModalOpen, setIsImportFromRepairCatalogModalOpen] =
    useState(false);

  const paginatedRepairSpecsRequest = useApiRequest(
    apiClient.repairSpecsApi.apiV1RepairSpecsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairSpecGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedRepairSpecs = paginatedRepairSpecsRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.RepairSpec)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedRepairSpecsRequest.handleEntityChanged(data);
    },
  });

  return (
    <Stack spacing={2}>
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title={undefined}
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement permissions={[AppPermission.RepairCatalogManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='add' />}
                    component={RouterLink}
                    to={ROUTE_PATH.REPAIR_SPEC_CREATE}
                  >
                    Create new
                  </Button>
                </AuthorizedElement>
              )
            }
            /*  secondaryActions={
              <AuthorizedElement permissions={[AppPermission.RepairCatalogManage]}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AppIcon of='import' />}
                  onClick={() => setIsImportFromRepairCatalogModalOpen(true)}
                >
                  Import
                </Button>
              </AuthorizedElement>
            } */
          />
        }
      >
        <Stack spacing={1}>
          <DataTabular
            columns={[
              {
                field: getTypedPath<RepairSpecDto>().id.$path,
                title: "ID",
                width: 250,
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.id,
                filters: {
                  fieldType: FilterFieldType.Id,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().localNumber.$path,
                title: "Number",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <Stack direction='row' spacing={0.5}>
                    <span>{item.localNumber}</span>{" "}
                    <EntityChipList entity={item} variant='compact' />
                  </Stack>
                ),
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().name.$path,
                title: "Name",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.name || "-",
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().description.$path,
                title: "Description",
                width: 100,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => item.description || "-",
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().partType.id.$path,
                title: "Part type",
                width: 160,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => item.partType?.name,
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfVehiclePartType(),
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().damageType.id.$path,
                title: "Damage type",
                width: 160,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => item.damageType?.name,
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfDamageType(),
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().detalization.type.$path,
                title: "Detalization",
                width: 240,
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.detalization ? getDetalizationInlineText(item.detalization) : "-",
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.RepairSpecDetalizationType,
                    },
                  },
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().detalization.vehicleSize.$path,
                title: "Vehicle size",
                width: 160,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.detalization?.vehicleSize ? (
                    <InlineApiEnumValue type='VehicleSize' value={item.detalization?.vehicleSize} />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.VehicleSize,
                    },
                  },
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().detalization.bodyType.$path,
                title: "Body type",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.detalization?.bodyType ? (
                    <InlineApiEnumValue
                      type='VehicleBodyType'
                      value={item.detalization?.bodyType}
                    />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.VehicleBodyType,
                    },
                  },
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().detalization.make.id.$path,
                title: "Make",
                width: 160,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => item.detalization?.make?.name ?? "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfVehicleMake(),
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().detalization.model.id.$path,
                title: "Model",
                width: 160,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => item.detalization?.model?.name ?? "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfVehicleModel(),
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().detalization.generation.id.$path,
                title: "Generation",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => item.detalization?.generation?.name ?? "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfVehicleGeneration(),
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().detalization.modification.id.$path,
                title: "Modification",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => item.detalization?.modification?.name ?? "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfVehicleModification(),
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().repairType.$path,
                title: "Repair type",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.repairType ? (
                    <InlineApiEnumValue type='RepairType' value={item.repairType} />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.RepairType,
                    },
                  },
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().spotFactor.$path,
                title: "Spot factor",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => <PercentValue value={item.spotFactor} />,
                filters: {
                  fieldType: FilterFieldType.None,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().currency.code.$path,
                title: "Currency",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.currency ? <GeneralCurrencyDisplay currency={item.currency} /> : "-",
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.CurrencyCode,
                    },
                  },
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().subTotal.$path,
                title: "Sub total",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <CurrencyValue value={item.subTotal} currency={item.currency} />
                ),
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().discount.$path,
                title: "Discount",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => (
                  <GeneralDiscountDisplay
                    discount={item.discount}
                    currency={item.currency}
                    detailsPlacement='tooltip'
                  />
                ),
              },
              {
                field: getTypedPath<RepairSpecDto>().subTotalIncDiscount.$path,
                title: "Sub total (inc. discount)",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <CurrencyValue value={item.subTotalIncDiscount} currency={item.currency} />
                ),
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().tax.$path,
                title: "Tax",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => <GeneralTaxDisplay tax={item.tax} currency={item.currency} />,
              },
              {
                field: getTypedPath<RepairSpecDto>().total.$path,
                title: "Total",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <AppPopover
                    hoverBehavior={{}}
                    trigger={
                      <AppTypography decoration={{ variant: "helpText" }}>
                        <CurrencyValue value={item.total} currency={item.currency} />
                      </AppTypography>
                    }
                  >
                    <AppPopoverContent>
                      <GeneralPriceSummaryDisplay
                        direction='column'
                        summary={{
                          currency: item.currency,
                          subTotal: item.subTotal,
                          discount: item.discount || undefined,
                          tax: item.tax || undefined,
                          total: item.total || 0,
                        }}
                      />
                    </AppPopoverContent>
                  </AppPopover>
                ),
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().createdAt.$path,
                title: "Created at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().updatedAt.$path,
                title: "Updated at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().createdBy.$path,
                title: "Created by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().updatedBy.$path,
                title: "Updated by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<RepairSpecDto>().items.$path,
                title: "Items",
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                isToDisabled: true,
                renderCell: (item) => <TableCellContentOfCount count={item.items?.length} />,
              },
            ]}
            rows={paginatedRepairSpecs?.items}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.REPAIR_SPEC_VIEW(item.id)}
            renderRowAction={({ item }) => (
              <RepairSpecMenu
                entity={item}
                onDelete={() => paginatedRepairSpecsRequest.refetch()}
                onUpdate={() => paginatedRepairSpecsRequest.refetch()}
              />
            )}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
          />
        </Stack>

        <ImportFromRepairCatalogModal
          open={isImportFromRepairCatalogModalOpen}
          onClose={() => setIsImportFromRepairCatalogModalOpen(false)}
          onImportEnd={() => paginatedRepairSpecsRequest.refetch()}
        />
      </ViewLayout>
    </Stack>
  );
}
