import { Badge, Stack, SxProps, Theme } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";

import { FileItem } from "@/common/fileItem";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import * as filesSlice from "@/store/files/slice";

import NoFilesAlert from "../AppAlerts/NoFilesAlert";
import FileItemView, { FileItemViewProps } from "./FileItemView";

interface Props {
  files: FileItem[];
  /** Show '+N' if file count is greater than max value */
  maxVisibleFiles?: number;
  itemWidth?: number;
  itemHeight?: number;
  fileItemViewProps?: Pick<FileItemViewProps, "actions">;
  sx?: SxProps<Theme>;
}

/** Displays list of files. */
export default function FileListView({
  files = [],
  maxVisibleFiles,
  itemWidth = 60,
  itemHeight = 25,
  fileItemViewProps,
  sx,
}: Props) {
  const dispatch = useAppThunkDispatch();
  const fullscreenFiles = useAppSelector((x) => x.files.fullScreen);

  const maxVisibleFilesValue = useMemo(
    () =>
      _.isNil(maxVisibleFiles)
        ? files.length
        : Math.max(Math.min(maxVisibleFiles, files.length), 0),
    [files, maxVisibleFiles],
  );
  const visibleFiles = useMemo(
    () => files.slice(0, maxVisibleFiles),
    [files, maxVisibleFilesValue],
  );
  const hiddenFiles = useMemo(
    () => files.slice(maxVisibleFilesValue, files.length),
    [files, maxVisibleFilesValue],
  );

  const lastVisibleFileIndex = visibleFiles.length - 1;

  if (files.length === 0) {
    return <NoFilesAlert />;
  }

  return (
    <Stack direction='row' sx={{ alignItems: "center", flexWrap: "wrap", ...sx }} gap={1}>
      {visibleFiles.map((f, i) => (
        <Badge
          key={i}
          color='primary'
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            mb: 1,
            mr: 1,
            zIndex: 0,
          }}
          badgeContent={`+${hiddenFiles.length}`}
          invisible={i !== lastVisibleFileIndex || hiddenFiles.length === 0}
        >
          <FileItemView
            fileItem={f}
            size={{
              width: itemWidth,
              height: itemHeight,
            }}
            actions={(item) => ({
              enabled: true,
              click: true,
              download: true,
              openInNewTab: false,
              fullscreen: false,
              onClick: (item2) => {
                dispatch(filesSlice.setFullScreenImages({ files }));
              },
              ...(fileItemViewProps?.actions ? fileItemViewProps.actions(item) : {}),
            })}
            imageSx={{
              width: "100%",
              objectFit: "cover",
            }}
          />
        </Badge>
      ))}
    </Stack>
  );
}
