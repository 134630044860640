import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Modal, Stack, SxProps, Theme, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";

import { FileItem } from "@/common/fileItem";
import { useAppDispatch, useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";

import { FileHelper } from "@/common/helpers/file";
import * as filesSlice from "@/store/files/slice";
import { ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import AttachmentCaptionInput from "../Files/AttachmentCaptionInput";
import FileItemView from "../Files/FileItemView";
import AppIcon from "../Icons/AppIcon";
import AppTypography from "../Text/AppTypography";
import ZoomableBlock from "./ZoomableBlock";

interface Props {
  sx?: SxProps<Theme>;
}

function FullScreenFileViewer({ sx }: Props) {
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const fullscreenFiles = useAppSelector((x) => x.files.fullScreen);
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  const files = useAppSelector((x) => x.files.fullScreen.files || []);

  const [selectedFile, setSelectedFile] = useState<Nullable<FileItem>>(null);
  const [fullscreenFile, setFullscreenFile] = useState<Nullable<FileItem>>(null);
  const isOpen = files.length > 0;
  const isFullscreenOpen = !!fullscreenFile;

  useEffect(() => {
    if (!selectedFile && files.length > 0) {
      setSelectedFile(files[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleSelectImage = (file: FileItem) => {
    setSelectedFile(file);
  };

  const handleClose = () => {
    thunkDispatch(filesSlice.setFullScreenImages({ files: [] }));
    setSelectedFile(null);
  };

  const handleFullscreenClose = () => {
    setFullscreenFile(null);
  };

  const handleDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedFile?.blob) {
      FileHelper.downloadBlob(selectedFile.blob, selectedFile.blob.name);
    } else if (selectedFile?.file) {
      FileHelper.downloadFileByUrl(
        selectedFile.file.url!,
        selectedFile.file.originalFileName || selectedFile.file.fileName!,
      );
    }
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            ...(sx as unknown as any),
          }}
        >
          {/* Close button */}
          <IconButton
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              zIndex: 1,
            }}
            onClick={handleClose}
          >
            <CloseIcon
              sx={{
                fill: "white",
              }}
            />
          </IconButton>

          {/* Selected file viewer */}
          <Box
            sx={{
              width: "100%",
              height: "auto",
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              overflow: "hidden",
              mt: 1,
            }}
          >
            {selectedFile && (
              <>
                <Box
                  sx={{
                    position: "relative",
                    width: { xxs: "95%", md: "unset" },
                    maxWidth: { xxs: "95%", md: "90%", lg: "90%" },
                    height: "auto",
                    maxHeight: { xxs: "90%", md: "90%", lg: "90%" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    "& .react-transform-wrapper": {
                      maxHeight: "fill-available",
                    },
                  }}
                >
                  <ZoomableBlock wrapperRef={transformComponentRef}>
                    <FileItemView
                      fileItem={selectedFile}
                      withCaption={false}
                      actions={(item) => ({
                        enabled: false,
                        click: false,
                        download: true,
                        openInNewTab: false,
                        fullscreen: item.fileType.isImage,
                        onFullscreen: (item2) => {
                          setFullscreenFile(item2);
                        },
                      })}
                      fullWidth
                      sx={{
                        width: "auto",
                        height: "100%",
                        overflow: "hidden",
                      }}
                      imageSx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </ZoomableBlock>
                  {/* Fullscreen actions must be outside from ZoomableBlock */}
                  <Stack
                    className='controls'
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      borderRadius: "4px",
                      padding: "2px",
                      backgroundColor: (theme) => theme.palette.background.default,
                    }}
                    direction='column'
                    spacing='4px'
                  >
                    <Tooltip
                      title='You can use the mouse to zoom in/out and navigate through the image by dragging the image'
                      placement='top'
                    >
                      <IconButton size='extraSmall'>
                        <AppIcon of='info' />
                      </IconButton>
                    </Tooltip>
                    {fullscreenFiles.actions?.setCaption && (
                      <AttachmentCaptionInput
                        key={selectedFile.id}
                        defaultCaption={selectedFile.caption}
                        tooltipProps={{ enabled: true, title: "Set caption", placement: "top" }}
                        onSetCaption={(value) => {
                          fullscreenFiles.actions?.onSetCaption?.(selectedFile, value);
                          files.forEach((x) => {
                            if (x.id === selectedFile.id) {
                              x.setAttachmentCaption(value);
                            }
                          });
                          thunkDispatch(
                            filesSlice.setFullScreenImages({
                              files: files,
                              actions: fullscreenFiles.actions,
                            }),
                          );
                        }}
                      />
                    )}

                    <Tooltip title='Download' placement='top'>
                      <IconButton onClick={handleDownload} size='extraSmall'>
                        <AppIcon of='download' />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Box>

                {/* Caption */}
                <AppTypography sx={{ mt: 1 }}>{selectedFile.attachment?.caption}</AppTypography>
              </>
            )}
          </Box>

          {/* Files preview */}
          {files.length > 1 && (
            <Box
              sx={{
                mt: "auto",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  maxWidth: "100%",
                  overflowX: "auto",
                  display: "grid",
                  gridAutoFlow: "column",
                  padding: 2,
                  gridGap: "10px",
                  webkitOverflowScrolling: "touch",
                }}
              >
                {files.map((file, index) => (
                  <Box sx={{ position: "relative" }} key={index}>
                    <FileItemView
                      fileItem={file}
                      actions={(item) => ({
                        enabled: true,
                        click: true,
                        setCaption: fullscreenFiles.actions?.setCaption,
                        onClick: () => {
                          transformComponentRef?.current?.resetTransform();
                          handleSelectImage(file);
                        },
                        onSetCaption: fullscreenFiles.actions?.setCaption
                          ? (_, caption) => {
                              fullscreenFiles.actions?.onSetCaption?.(file, caption);
                              files.forEach((x) => {
                                if (x.id === file.id) {
                                  x.setAttachmentCaption(caption);
                                }
                              });
                              thunkDispatch(
                                filesSlice.setFullScreenImages({
                                  files: files,
                                  actions: fullscreenFiles.actions,
                                }),
                              );
                            }
                          : undefined,
                      })}
                      sx={{
                        height: "150px",
                        width: "unset",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          {/* Fullscreen file view modal */}
          <Modal open={isFullscreenOpen} onClose={handleFullscreenClose}>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              }}
            >
              {/* Close button */}
              <IconButton
                sx={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                }}
                onClick={handleFullscreenClose}
              >
                <CloseIcon
                  sx={{
                    fill: "white",
                  }}
                />
              </IconButton>

              <ZoomableBlock>
                <FileItemView
                  fileItem={fullscreenFile!}
                  actions={(item) => ({
                    enabled: true,
                    click: false,
                    download: true,
                    openInNewTab: false,
                    fullscreen: false,
                  })}
                  fullWidth
                  sx={{
                    width: "auto",
                    height: "100%",
                  }}
                  imageSx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </ZoomableBlock>
            </Box>
          </Modal>
        </Box>
      </Modal>
    </>
  );
}

export default FullScreenFileViewer;
