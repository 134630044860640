import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DamageCostEvaluationAggregateDto,
  DamageCostEvaluationAggregateGetPaginatedDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  ExportEntityType,
  GeneralAttachedTagsInputDto,
  TagEntityType,
} from "@/core/api/generated";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import DataExportModal from "@/common/components/DataExport/DataExportModal";
import Datetime from "@/common/components/Datetime/Datetime";
import ContractInline from "@/common/components/Entity/Contract/ContractInline";
import DamageCostEvaluationAggregateDeleteModal from "@/common/components/Entity/DamageCostEvaluationAggregate/DamageCostEvaluationAggregateDeleteModal";
import VehicleInline from "@/common/components/Entity/Vehicle/VehicleInline";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { FilterFieldType } from "@/common/ts/filters";
import _ from "lodash";
import DamageCostEvaluationInline from "../../DamageCostEvaluation/DamageCostEvaluationInline";
import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";

type DamageCostEvaluationAggregateGetPaginatedInputDto = Omit<
  DamageCostEvaluationAggregateGetPaginatedDto,
  "tags"
> & {
  tags?: TagStaticFilterInputDto;
};

export default function DamageCostEvaluationAggregatesPaginatedList() {
  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams =
    useCommonRequestParams<DamageCostEvaluationAggregateGetPaginatedInputDto>({
      statePersistence: {
        persistenceKey: EntityType.DamageCostEvaluationAggregate,
      },
      initParams: (filterDefinition) => {
        const tagFilter = filterDefinition.filterDefinition?.items.find(
          (x) => x.field === getTypedPath<DamageCostEvaluationAggregateDto>().tags.$path,
        );

        return {
          tags: {
            operator: tagFilter?.operator,
            query: tagFilter?.value as GeneralAttachedTagsInputDto,
          } as TagStaticFilterInputDto,
        };
      },
    });

  // menu, dialogs
  const [isStartDamageCostEvaluationModalOpen, setIsStartDamageCostEvaluationModalOpen] =
    useState(false);
  const [aggregate, setAggregate] = useState<DamageCostEvaluationAggregateDto | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDataExportModalOpened, setIsDataExportModalOpened] = useState(false);

  const paginatedDamageCostEvaluationAggregatesRequest = useApiRequest(
    apiClient.damageCostEvaluationAggregatesApi.apiV1DamageCostEvaluationsAggregatesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      damageCostEvaluationAggregateGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<DamageCostEvaluationAggregateDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, commonRequestParams.filterDefinition],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedDamageCostEvaluationAggregates = useMemo(
    () => paginatedDamageCostEvaluationAggregatesRequest?.data,
    [paginatedDamageCostEvaluationAggregatesRequest.data],
  );

  const damageCostEvaluationAggregateIds = useMemo(
    () => paginatedDamageCostEvaluationAggregates?.items?.map((item) => item.id!) || [],
    [paginatedDamageCostEvaluationAggregates?.items],
  );

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.DamageCostEvaluationAggregate),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedDamageCostEvaluationAggregatesRequest.handleEntityChanged(data);
    },
  });

  return (
    <Stack spacing={2}>
      <SimpleViewPageHeader
        title={undefined}
        primaryActions={undefined}
        secondaryActions={
          <AuthorizedElement permissions={[AppPermission.ExportManage]}>
            <MenuWithTrigger
              withAuthCloseOnClick
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              <MenuItem onClick={() => setIsDataExportModalOpened(true)}>
                <AppIcon of='export' fontSize='small' sx={{ mr: 1 }} />
                <Typography>Export aggregates</Typography>
              </MenuItem>
            </MenuWithTrigger>
          </AuthorizedElement>
        }
      />

      <Divider sx={{ my: 2 }} />

      <Stack direction='column' spacing={1}>
        <DataTabular
          columns={[
            {
              field: getTypedPath<DamageCostEvaluationAggregateDto>().id.$path,
              title: "ID",
              width: 250,
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.id,
              filters: {
                fieldType: FilterFieldType.Id,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<DamageCostEvaluationAggregateDto>().localNumber.$path,
              title: "Number",
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => (
                <Stack direction='row' spacing={0.5}>
                  <span>{item.localNumber}</span> <EntityChipList entity={item} variant='compact' />
                </Stack>
              ),
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<DamageCostEvaluationAggregateDto>().vehicle.id.$path,
              title: "Vehicle",
              width: 300,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) => <VehicleInline entity={item.vehicle} />,
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfVehicle(),
              },
            },
            {
              field: getTypedPath<DamageCostEvaluationAggregateDto>().contract.id.$path,
              title: "Contract",
              width: 300,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.contract ? <ContractInline entity={item.contract} /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfContract(),
              },
            },
            {
              field: getTypedPath<DamageCostEvaluationAggregateDto>().notes.$path,
              title: "Notes",
              width: 100,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) => item.notes || "-",
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<DamageCostEvaluationAggregateDto>().createdAt.$path,
              title: "Created at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<DamageCostEvaluationAggregateDto>().updatedAt.$path,
              title: "Updated at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<DamageCostEvaluationAggregateDto>().createdBy.$path,
              title: "Created by",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
              },
            },
            {
              field: getTypedPath<DamageCostEvaluationAggregateDto>().updatedBy.$path,
              title: "Updated by",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
              },
            },
            {
              field: getTypedPath<DamageCostEvaluationAggregateDto>().items.$path,
              title: "Items",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              isToDisabled: true,
              renderCell: (item) => <TableCellContentOfCount count={item.items?.length} />,
            },
            {
              field:
                getTypedPath<DamageCostEvaluationAggregateDto>().sourceDamageCostEvaluations.$path,
              title: "Source damage cost evaluations",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              isToDisabled: true,
              renderCell: (item) => (
                <TableCellContentOfCount
                  count={item.sourceDamageCostEvaluations?.length}
                  popoverContent={
                    <Stack>
                      {item.sourceDamageCostEvaluations?.map((x, i) => (
                        <DamageCostEvaluationInline key={i} entity={x} />
                      ))}
                    </Stack>
                  }
                />
              ),
            },
            {
              field: getTypedPath<DamageCostEvaluationAggregateDto>().attachments.$path,
              title: "Attachments",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              isToDisabled: true,
              renderCell: (item) => (
                <TableCellContentOfAttachments attachments={item.attachments} />
              ),
            },
            {
              field: "affiliation",
              title: "Affiliation",
              description: "Affiliation to departments & locations",
              width: 120,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              isToDisabled: true,
              renderCell: (item) => (
                <AffiliationInfoDisplay
                  variant='compact'
                  tenantId={item.tenantId}
                  departmentIds={item.departmentIds}
                  locationIds={item.locationIds}
                />
              ),
            },
            {
              field: getTypedPath<DamageCostEvaluationAggregateDto>().tags.$path,
              title: "Tags",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              isColumnMenuDisabled: false,
              isToDisabled: true,
              renderCell: (item) => (
                <EntityTagsInfoDisplay
                  entityType={TagEntityType.DamageCostEvaluationAggregate}
                  entity={item}
                  noDataPlaceholder='-'
                  edit={{
                    onSaved: (newValue) =>
                      paginatedDamageCostEvaluationAggregatesRequest.updateData((data) => {
                        data.items?.forEach((item2) => {
                          if (item2.id === item.id) {
                            item2.tags = newValue || undefined;
                          }
                        });
                      }),
                  }}
                />
              ),
              filters: {
                fieldType: FilterFieldType.Arbitrary,
                isUseSingleFilter: true,
                operators: FilterCatalog.getOperatorsForTagsField(),
              },
            },
          ]}
          rows={paginatedDamageCostEvaluationAggregates?.items}
          isLoading={paginatedDamageCostEvaluationAggregatesRequest.isLoading}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.DAMAGE_COST_EVALUATION_AGGREGATE_VIEW(item.id)}
          renderRowAction={({ item }) => (
            <>
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <AuthorizedMenuItem
                  permissions={[AppPermission.DamageCostEvaluationManage]}
                  onClick={() => {
                    setAggregate(item);
                    setIsDeleteModalOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='delete' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </AuthorizedMenuItem>
              </MenuWithTrigger>
            </>
          )}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
        />
      </Stack>

      {/* Delete modal */}
      {aggregate && (
        <DamageCostEvaluationAggregateDeleteModal
          entity={aggregate}
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={() => paginatedDamageCostEvaluationAggregatesRequest.refetch()}
        />
      )}

      {/* Export data modal */}
      <DataExportModal
        open={isDataExportModalOpened}
        onClose={() => setIsDataExportModalOpened(false)}
        exportDataProps={{
          entityType: ExportEntityType.DamageCostEvaluationAggregate,
          ids: damageCostEvaluationAggregateIds,
          onExportDone: () => {
            setIsDataExportModalOpened(false);
          },
        }}
      />
    </Stack>
  );
}
