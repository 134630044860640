import { Button, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import DepartmentMenu from "@/common/components/Entity/Department/DepartmentMenu";
import DepartmentsDeleteModal from "@/common/components/Entity/Department/DepartmentsDeleteModal";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { EntityHelper } from "@/common/helpers/entity";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  DepartmentGetPaginatedDto,
  EntityType,
} from "@/core/api/generated";

enum BulkActionFlags {
  Delete = "Delete",
}
const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
  create: true,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function DepartmentsPage({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<DepartmentGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.Department,
    },
  });

  const paginatedDepartmentsRequest = useApiRequest(
    apiClient.departmentsApi.apiV1DepartmentsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      departmentGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedDepartments = paginatedDepartmentsRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Department)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedDepartmentsRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayout
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title={undefined}
          primaryActions={
            displayProps?.create && (
              <AuthorizedElement permissions={[AppPermission.DepartmentManage]}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AppIcon of='add' />}
                  component={RouterLink}
                  to={ROUTE_PATH.DEPARTMENT_CREATE}
                >
                  Create new department
                </Button>
              </AuthorizedElement>
            )
          }
        />
      }
    >
      <Stack direction='column' spacing={1}>
        <DataTabular
          columns={[
            {
              field: "name",
              title: "Name",
              flex: 1,
              renderCell: (item) => <>{item.name}</>,
            },
            {
              field: "address",
              title: "Address",
              flex: 2,
              renderCell: (item) => (
                <AppTypography ellipsing={{ enabled: true }} component='div'>
                  <GeneralAddressDisplay address={item.address} direction='row' />
                </AppTypography>
              ),
            },
          ]}
          isLoading={paginatedDepartmentsRequest.isLoading}
          rows={paginatedDepartments?.items}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.DEPARTMENT_VIEW(item.id)}
          renderRowAction={({ item }) => (
            <DepartmentMenu
              onDelete={() => paginatedDepartmentsRequest.refetch()}
              onUpdate={() => paginatedDepartmentsRequest.refetch()}
              entity={item}
            />
          )}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
          bulkActions={{
            enabled: true,
            definition: BulkActionFlags,
            actionTriggers: ({ currentAction, startAction, selectedIds }) => (
              <>
                <AppIconButton
                  tooltipProps={{ title: "Delete" }}
                  onClick={() => startAction(BulkActionFlags.Delete)}
                >
                  <AppIcon of='delete' />
                </AppIconButton>
              </>
            ),
            actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => (
              <>
                <DepartmentsDeleteModal
                  entities={EntityHelper.filterEntitiesByIds(
                    paginatedDepartments?.items || [],
                    selectedIds as string[],
                  )}
                  open={currentAction === BulkActionFlags.Delete}
                  onClose={() => cancelAction()}
                  onDelete={() => {
                    completeAction();
                    paginatedDepartmentsRequest.refetch();
                  }}
                />
              </>
            ),
          }}
        />
      </Stack>
    </ViewLayout>
  );
}
