import {
  Button,
  DialogContentText,
  Divider,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import InlineEntityChangedByInfoDisplay from "@/common/components/EntityInfo/InlineEntityChangedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModalWithTrigger from "@/common/components/Modals/ConfirmationModalWithTrigger";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AdminDamageTypeGetPaginatedDto, EntityType } from "@/core/api/generated";

export default function DamageTypesPage() {
  const { enqueueSnackbar } = useAppSnackbar();
  const commonRequestParams = useCommonRequestParams<AdminDamageTypeGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.DamageType),
    },
  });

  const paginatedDamageTypesRequest = useApiRequest(
    apiClient.adminDamageTypesApi.apiV1AdminReferenceDataDamageTypesGetPost,
    {
      adminDamageTypeGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const { data: paginatedDamageTypes } = paginatedDamageTypesRequest;

  return (
    <ListPageLayout>
      <SimpleViewPageHeader
        title={undefined}
        primaryActions={
          <Button
            variant='contained'
            color='primary'
            startIcon={<AppIcon of='add' />}
            component={RouterLink}
            to={ROUTE_PATH.ADMIN_DAMAGE_TYPE_CREATE}
          >
            Create new damage type
          </Button>
        }
      />

      <Divider sx={{ my: 2 }} />

      <DataTabular
        columns={[
          {
            field: "globalNumber",
            title: "Global number",
            flex: 1,
            renderCell: (item) => item.globalNumber,
          },
          {
            field: "name",
            title: "Name",
            flex: 1,
            renderCell: (item) => <>{item.name}</>,
          },
          {
            field: "category",
            title: "Category",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='DamageTypeCategory' value={item.category} />
            ),
          },
          {
            field: "partType",
            title: "Part type",
            flex: 1,
            renderCell: (item) =>
              item.partType ? (
                <InlineApiEnumValue type='VehiclePartType' value={item.partType} />
              ) : (
                "-"
              ),
          },
          {
            field: "isCategoryRoot",
            title: "Category root",
            flex: 1,
            renderCell: (item) => (
              <Typography component='div' variant='body1'>
                {item.isCategoryRoot ? "yes" : "no"}
              </Typography>
            ),
          },
          {
            field: "isBuiltIn",
            title: "Built-in",
            flex: 1,
            renderCell: (item) => <BooleanValue value={item.isBuiltIn} />,
          },
          {
            field: "isEnabled",
            title: "Enabled",
            flex: 1,
            renderCell: (item) => <BooleanValue value={item.isEnabled} />,
          },
          {
            field: "isEnabledForDamageDetection",
            title: "Enabled for damage detection",
            flex: 1,
            renderCell: (item) => <BooleanValue value={item.isEnabledForDamageDetection} />,
          },
          {
            field: "sortOrder",
            title: "Sort order",
            flex: 1,
            renderCell: (item) => <>{item.sortOrder}</>,
          },
          {
            field: "changedBy",
            title: "Changed by",
            flex: 1,
            renderCell: (item) => <InlineEntityChangedByInfoDisplay entity={item} />,
          },
        ]}
        rows={paginatedDamageTypes?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.ADMIN_DAMAGE_TYPES_EDIT(item.id)}
        renderRowAction={({ item }) => (
          <>
            <MenuWithTrigger
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              {({ handleClose }) => [
                <MenuItem
                  key='edit'
                  component={RouterLink}
                  to={ROUTE_PATH.ADMIN_DAMAGE_TYPES_EDIT(item.id)}
                >
                  Edit
                </MenuItem>,

                <ConfirmationModalWithTrigger
                  key='delete'
                  trigger={<MenuItem>Delete</MenuItem>}
                  title='Delete reference data?'
                  body={({ error }) => {
                    return (
                      <DialogContentText>
                        {`You're going to delete reference data that is used across the system. This action can't
            be undone.`}
                      </DialogContentText>
                    );
                  }}
                  onConfirm={async () => {
                    try {
                      await apiClient.adminDamageTypesApi.apiV1AdminReferenceDataDamageTypesDamageTypeIdDelete(
                        { damageTypeId: item.id! },
                      );
                      paginatedDamageTypesRequest.refetch();
                      enqueueSnackbar("Damage type deleted.", { variant: "success" });
                      handleClose();
                    } catch (err) {
                      const validation2 = ValidationHelper.handleApiErrorResponse(err);
                      validation2.hasErrors &&
                        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
                    }
                  }}
                />,
              ]}
            </MenuWithTrigger>
          </>
        )}
        isLoading={paginatedDamageTypesRequest.isLoading}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
      />
    </ListPageLayout>
  );
}
