import { ProfileDto, VehicleDto } from "@/core/api/generated";

const whitelistedEmailDomainsForDemo = [
  "nexusops.io",
  "unicreo.com",
  "letty.no",
  "pixelzoo.no", // Kristian Sjøvold
  "bislet.no",
];

const whitelistedEmailsForDemo = [
  "mraknes@gmail.com", // Michael Råknes
];

export class TeslaHelper {
  public static isEmailWhitelistedForDemo(email: Nil<string>): boolean {
    return (
      !!email &&
      (whitelistedEmailsForDemo.includes(email) ||
        whitelistedEmailDomainsForDemo.some((domain) =>
          email.toLowerCase().endsWith(domain.toLowerCase()),
        ))
    );
  }

  public static isDemoEnabledForVehicle(vehicle: Nil<VehicleDto>): boolean {
    return (
      !!vehicle?.spec?.make &&
      (vehicle?.spec?.make?.identifier === "tesla" ||
        (vehicle?.spec?.make?.name || "").toLowerCase().includes("tesla"))
    );
  }

  public static isDemoEnabledForUserAndVehicle(
    profile: Nil<ProfileDto>,
    vehicle: Nil<VehicleDto>,
  ): boolean {
    return this.isEmailWhitelistedForDemo(profile?.email) && this.isDemoEnabledForVehicle(vehicle);
  }
}
