import { useParams } from "react-router-dom";

import VehicleDamageView from "@/common/components/Entity/VehicleDamage/View/VehicleDamageView";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { Box } from "@mui/material";

export interface VehicleDamageViewPageRouteParams {
  vehicleId?: string;
  vehicleDamageId?: string;
}

export type VehicleDamageViewPageQueryParams = GeneralQueryParams;

export default function VehicleDamageViewPage() {
  const { vehicleId, vehicleDamageId } = useParams<VehicleDamageViewPageRouteParams>();
  const queryParams = useQueryParams<VehicleDamageViewPageQueryParams>();

  return (
    <Box>
      <VehicleDamageView vehicleId={vehicleId} vehicleDamageId={vehicleDamageId} />
    </Box>
  );
}
