import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import {
  AppPermission,
  DamageCostEvaluationDto,
  DamageCostEvaluationStage,
  EntityType,
  RepairSpecDataDto,
  RepairSpecDataInputDto,
  VehicleDamageDto,
} from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppTooltip from "@/common/components/AppTooltip";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedTenantElement from "@/common/components/Auth/AuthorizedTenantElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import ContractBlock from "@/common/components/Entity/Contract/ContractBlock";
import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import GeneralInspectorDisplay from "@/common/components/Entity/General/Display/GeneralInspectorDisplay";
import { RepairOperationAddVehicleDamagesModal } from "@/common/components/Entity/RepairOperation/RepairOperationAddVehicleDamagesModal";
import CreateUpdateRepairSpecModal from "@/common/components/Entity/RepairSpec/CreateUpdateRepairSpecModal";
import RepairSpecLink from "@/common/components/Entity/RepairSpec/RepairSpecLink";
import VehicleBlock from "@/common/components/Entity/Vehicle/VehicleBlock";
import VehicleDamageLink from "@/common/components/Entity/VehicleDamage/VehicleDamageLink";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import GeneralStageHistory from "@/common/components/GeneralStageHistory";
import AppIcon from "@/common/components/Icons/AppIcon";
import GeneralEntitySourceLink from "@/common/components/Link/GeneralEntitySourceLink";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import AppModalContent from "@/common/components/Modals/AppModalContent";
import AppModalV2 from "@/common/components/Modals/AppModalV2";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FileItem } from "@/common/fileItem";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import _ from "lodash";
import { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import RepairSpecItemDataList from "../../../RepairSpec/RepairSpecItemDataList";
import CurrencyMismatchWithRepairSpecAlertCompact from "../../CurrencyMismatchWithRepairSpecAlertCompact";

interface Props {
  damageCostEvaluation: DamageCostEvaluationDto;
  onUpdate: (newValue?: DamageCostEvaluationDto) => void;
}

export default function OverviewTabContent({ damageCostEvaluation, onUpdate }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();
  const { hasPermissions } = useAuthorizationInfo();

  const [isSendToCustomer, setIsSendToCustomer] = useState(
    damageCostEvaluation.isSendToCustomer ?? false,
  );

  // menu, dialogs
  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);
  const [isAddToRepairOperationModalOpen, setIsAddToRepairOperationModalOpen] = useState(false);
  const [isCreateRepairSpecModalOpen, setIsCreateRepairSpecModalOpen] = useState(false);
  const [isConfirmCompletedModalOpen, setIsConfirmCompletedModalOpen] = useState(false);
  const [damagesToAddToRepairOperation, setDamagesToAddToRepairOperation] = useState<
    VehicleDamageDto[] | undefined
  >(undefined);
  const [createRepairSpecFromSpecData, setCreateRepairSpecFromSpecData] = useState<
    (RepairSpecDataDto & RepairSpecDataInputDto) | undefined
  >(undefined);

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={damageCostEvaluation} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack direction='column' spacing={2}>
            {damageCostEvaluation?.stageHistory?.stage === DamageCostEvaluationStage.Draft && (
              <AuthorizedElement permissions={[AppPermission.DamageCostEvaluationManage]}>
                <Alert severity='info' icon={<AppIcon of='tip' />}>
                  This operation is in{" "}
                  <strong>
                    <InlineApiEnumValue
                      type='DamageCostEvaluationStage'
                      value={DamageCostEvaluationStage.Draft}
                    />
                  </strong>{" "}
                  stage. Please review and complete it in order for changes to take effect.
                </Alert>
              </AuthorizedElement>
            )}

            <Stack direction={{ xxs: "column", md: "row" }} alignItems='flex-start' spacing={2}>
              <VehicleBlock
                sx={{ maxWidth: { md: "400px" } }}
                variant='reference'
                vehicle={damageCostEvaluation.vehicle}
                withDetailsToggle
                withLink
              />
              {damageCostEvaluation.contract && (
                <ContractBlock
                  sx={{ maxWidth: { md: "400px" } }}
                  variant='reference'
                  contract={damageCostEvaluation.contract}
                  withDetailsToggle
                  withLink
                />
              )}
            </Stack>

            {/* Affiliation */}
            <Stack spacing={1} sx={{ mt: 2 }}>
              <Typography variant='subtitle1'>
                Affiliation{" "}
                <AppIconButton
                  tooltipProps={{ title: "Edit entity affiliation" }}
                  onClick={() => setIsUpdateAffiliationModalOpened(true)}
                >
                  <AppIcon of='edit' fontSize='small' />
                </AppIconButton>
              </Typography>
              <AffiliationInfoDisplay
                tenantId={damageCostEvaluation.tenantId}
                departmentIds={damageCostEvaluation.departmentIds}
                locationIds={damageCostEvaluation.locationIds}
                sx={{ mt: 1 }}
              />
              <UpdateEntityAffiliationCascadeModal
                open={isUpdateAffiliationModalOpened}
                onClose={() => setIsUpdateAffiliationModalOpened(false)}
                entityType={EntityType.DamageCostEvaluation}
                entityId={damageCostEvaluation.id}
                onUpdate={() => onUpdate()}
              />
            </Stack>

            <Box>
              <Grid container columnSpacing={2} rowSpacing={1} sx={{ mb: 1 }}>
                <Grid item xxs={12} md minWidth={200}>
                  <Stack direction='column' spacing={1}>
                    <FieldValue label='Source'>
                      <GeneralEntitySourceLink source={damageCostEvaluation.source} />
                    </FieldValue>
                    <FieldValue label='Inspection date' isEmpty={!damageCostEvaluation.inspectedAt}>
                      <Datetime datetime={damageCostEvaluation.inspectedAt} withDurationFromNow />
                    </FieldValue>
                    <FieldValue label='Inspector' isEmpty={!damageCostEvaluation.inspector}>
                      <GeneralInspectorDisplay
                        inspector={damageCostEvaluation.inspector}
                        inlineUserProps={{
                          withAvatar: true,
                          withLink: true,
                        }}
                      />
                    </FieldValue>
                  </Stack>
                </Grid>
                <Grid item xxs={12} md minWidth={200}>
                  <Stack direction='column' spacing={1}>
                    <FieldValue label='Currency'>
                      <GeneralCurrencyDisplay currency={damageCostEvaluation.currency} />
                    </FieldValue>

                    <FieldValue label='Notes' isEmpty={!damageCostEvaluation.notes}>
                      {damageCostEvaluation.notes}
                    </FieldValue>
                  </Stack>
                </Grid>
              </Grid>
            </Box>

            {damageCostEvaluation.attachments!.length !== 0 && (
              <Box>
                <FileListView
                  files={FileItem.createManyFrom(damageCostEvaluation.attachments)}
                  maxVisibleFiles={10}
                  itemWidth={45}
                />
              </Box>
            )}

            {/* Stages */}
            <Box>
              <Typography variant='h2'>Stages</Typography>
              <GeneralStageHistory
                stageEnumType='DamageCostEvaluationStage'
                stageHistory={damageCostEvaluation.stageHistory}
                withUpdateButtons={hasPermissions([AppPermission.DamageCostEvaluationManage])}
                onUpdateStage={async (newStage) => {
                  try {
                    const response =
                      await apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut(
                        {
                          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                          damageCostEvaluationId: damageCostEvaluation.id || "",
                          generalStageUpdateDtoOfDamageCostEvaluationStage: {
                            newStage: newStage,
                          },
                        },
                      );
                    onUpdate && onUpdate(response.data);
                  } catch (err) {
                    const validation2 = ValidationHelper.handleApiErrorResponse(err);
                    enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
                  }
                }}
              />
            </Box>

            {/* Items */}
            <Typography variant='h2'>Items</Typography>

            <DataTabular
              columns={[
                {
                  field: "damage",
                  title: "Damages",
                  flex: 1,
                  renderCell: (item) => (
                    <Stack spacing={0}>
                      {item.damages?.map((damage, j) => (
                        <Stack key={j} direction='row' spacing={1} alignItems='center'>
                          <VehicleDamageLink
                            vehicleId={damage.damage?.vehicleId || ""}
                            entity={damage.damage}
                            withIcon
                          />
                          <Typography component='div' variant='body2' color='text.secondary'>
                            (<InlineApiEnumValue type='VehicleArea' value={damage.damage?.area} /> /{" "}
                            {(damage.damage?.projection && (
                              <InlineApiEnumValue
                                type='VehicleProjection'
                                value={damage.damage?.projection}
                              />
                            )) ||
                              "-"}
                            )
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  ),
                },
                {
                  field: "repairSpec",
                  if: hasPermissions([
                    AppPermission.FleetAppAccess,
                    AppPermission.RepairCatalogRead,
                  ]),
                  title: "Repair spec",
                  flex: 1,
                  renderCell: (item) => (
                    // The condition `item.repairSpec?.isDetailsHidden` is used to ensure that the `repairSpec` data is only displayed if `isDetailsHidden` is false.
                    // This condition prevents displaying the repair spec details when they are meant to remain hidden.
                    <AuthorizedTenantElement
                      tenantIds={[damageCostEvaluation?.tenantId, item.repairSpec?.tenantId]}
                      fallback='-'
                    >
                      <Stack spacing={0.5}>
                        <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                          {(!item.repairSpec || item.repairSpec.isDetailsHidden) && (
                            <Box>{"-"}</Box>
                          )}

                          {item.repairSpec &&
                            !item.repairSpec.isCreatedOnTheSpot &&
                            !item.repairSpec.isDetailsHidden && (
                              <AuthorizedElement permissions={[AppPermission.RepairCatalogRead]}>
                                <RepairSpecLink entity={item.repairSpec} withIcon />

                                {item.isRepairSpecChanged && (
                                  <Tooltip title='Repair spec was edited in this damage cost evaluation'>
                                    <Chip
                                      size='small'
                                      color='secondary'
                                      variant='outlined'
                                      label='Edited'
                                    />
                                  </Tooltip>
                                )}
                              </AuthorizedElement>
                            )}

                          {item.repairSpec &&
                            item.repairSpec.isCreatedOnTheSpot &&
                            !item.repairSpec.isDetailsHidden && (
                              <Box>Damage was evaluated locally without creating a spec</Box>
                            )}
                        </Stack>

                        {!item.repairSpec?.isDetailsHidden &&
                          (item.isRepairSpecChanged || item.repairSpec) && (
                            <Stack direction='row' spacing={1} sx={{ flexWrap: "wrap" }}>
                              {/* Create repair spec from spec data */}
                              {item.isRepairSpecChanged && (
                                <Box>
                                  <Tooltip title='Create new repair spec based on local evaluation data '>
                                    <Button
                                      size='extraSmall'
                                      color='secondary'
                                      variant='outlined'
                                      startIcon={<AppIcon of='add' />}
                                      onClick={() => {
                                        setCreateRepairSpecFromSpecData(item.repairSpec);
                                        setIsCreateRepairSpecModalOpen(true);
                                      }}
                                    >
                                      Save as new spec
                                    </Button>
                                  </Tooltip>
                                </Box>
                              )}

                              {/* Details */}
                              {item.repairSpec && (
                                <AuthorizedElement
                                  permissions={[
                                    AppPermission.FleetAppAccess,
                                    AppPermission.RepairCatalogRead,
                                    AppPermission.RepairCatalogManage,
                                  ]}
                                >
                                  <AuthorizedTenantElement
                                    tenantIds={[
                                      damageCostEvaluation?.tenantId,
                                      item.repairSpec?.tenantId,
                                    ]}
                                  >
                                    <Box>
                                      <AppModalV2
                                        fullWidth
                                        maxWidth='lg'
                                        keepMounted={false}
                                        trigger={
                                          <Button
                                            size='extraSmall'
                                            color='secondary'
                                            variant='outlined'
                                            startIcon={<AppIcon of='view' />}
                                          >
                                            View spec details
                                          </Button>
                                        }
                                        titleProps={{
                                          title: "Repair spec details",
                                        }}
                                      >
                                        <AppModalContent>
                                          <RepairSpecItemDataList repairSpec={item.repairSpec} />
                                        </AppModalContent>
                                      </AppModalV2>
                                    </Box>
                                  </AuthorizedTenantElement>
                                </AuthorizedElement>
                              )}
                            </Stack>
                          )}
                      </Stack>
                    </AuthorizedTenantElement>
                  ),
                },
                {
                  field: "attachments",
                  title: "Attachments",
                  flex: 0,
                  renderCell: (item) => (
                    <>
                      {!_.isEmpty(item.attachments) ? (
                        <FileListView
                          files={FileItem.createManyFrom(item.attachments)}
                          maxVisibleFiles={2}
                          itemWidth={45}
                        />
                      ) : (
                        "-"
                      )}
                    </>
                  ),
                },
                {
                  field: "notes",
                  title: "Notes",
                  flex: 0,
                  renderCell: (item) =>
                    item.notes ? (
                      <AppTooltip title={item.notes || ""}>
                        <AppTypography ellipsing={{ enabled: true }}>{item.notes}</AppTypography>
                      </AppTooltip>
                    ) : (
                      "-"
                    ),
                },
                {
                  field: "total",
                  title: "Total",
                  flex: 0,
                  renderCell: (item) => (
                    <AppPopover
                      hoverBehavior={{}}
                      trigger={
                        <AppTypography decoration={{ variant: "helpText" }}>
                          <CurrencyValue
                            value={item.total}
                            currency={damageCostEvaluation.currency}
                          />
                        </AppTypography>
                      }
                    >
                      <AppPopoverContent>
                        <GeneralPriceSummaryDisplay
                          direction='column'
                          summary={{
                            currency: damageCostEvaluation.currency,
                            subTotal: item.subTotal,
                            discount: item.discount || undefined,
                            tax: item.tax || undefined,
                            total: item.total || 0,
                          }}
                        />
                      </AppPopoverContent>
                    </AppPopover>
                  ),
                },
                {
                  field: "alerts",
                  title: "Alerts",
                  flex: 0,
                  renderCell: (item) => (
                    <>
                      {/* Currency mismatch alert */}
                      {damageCostEvaluation.currency && (
                        <CurrencyMismatchWithRepairSpecAlertCompact
                          entity={damageCostEvaluation}
                          item={item}
                        />
                      )}
                    </>
                  ),
                },
              ]}
              rows={damageCostEvaluation.items!}
              getRowId={(item) => item.id!}
              borderVariant='bordered'
              getRowHeight={() => "auto"}
              rowHeight={65}
              isColumnSelectorEnabled={false}
              isColumnMenuEnabled={false}
              isPaginationEnabled={false}
              renderRowAction={(actionParams) => (
                <MenuWithTrigger
                  withAuthCloseOnClick
                  trigger={
                    <IconButton>
                      <AppIcon of='moreVert' />
                    </IconButton>
                  }
                >
                  <MenuItem
                    onClick={() => {
                      setIsAddToRepairOperationModalOpen(true);
                      setDamagesToAddToRepairOperation(
                        actionParams.item.damages?.map((x) => x.damage!)?.filter(Boolean),
                      );
                    }}
                  >
                    <ListItemIcon>
                      <AppIcon of='add' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Add damage to repair operation</ListItemText>
                  </MenuItem>
                </MenuWithTrigger>
              )}
            />

            {/* Summary */}
            {damageCostEvaluation.items!.length !== 0 && (
              <Stack direction='row' justifyContent='flex-end'>
                <GeneralPriceSummaryDisplay
                  summary={{
                    currency: damageCostEvaluation.currency,
                    subTotal: damageCostEvaluation.subTotal,
                    subTotalIncDiscount: damageCostEvaluation.subTotalIncDiscount,
                    discount: damageCostEvaluation.discount,
                    tax: damageCostEvaluation.tax,
                    total: damageCostEvaluation.total,
                  }}
                />
              </Stack>
            )}

            {/* Complete helper buttons */}
            <AuthorizedTenantElement tenantIds={[damageCostEvaluation?.tenantId]}>
              <AuthorizedElement permissions={[AppPermission.DamageCostEvaluationManage]}>
                {damageCostEvaluation.stageHistory!.stage !==
                  DamageCostEvaluationStage.Completed && (
                  <Stack direction='row' spacing={1} sx={{ mt: 2, mb: 20 }}>
                    <Button
                      sx={{ minWidth: { md: 200 } }}
                      variant='outlined'
                      component={RouterLink}
                      to={ROUTE_PATH.DAMAGE_COST_EVALUATION_EDIT(damageCostEvaluation.id)}
                    >
                      Edit
                    </Button>

                    <Button
                      variant='contained'
                      color='primary'
                      fullWidth
                      onClick={() => {
                        setIsConfirmCompletedModalOpen(true);
                      }}
                    >
                      Complete
                    </Button>

                    {/* <SplitDropdownButton
                    sx={{ flex: 1 }}
                    isSelectAndTriggerAction={false}
                    buttonProps={{
                      variant: "contained",
                      color: "primary",
                      fullWidth: true,
                    }}
                    options={[
                      {
                        content: "Complete",
                        onClick: () => {
                          setIsSendToCustomer(false);
                          setIsConfirmCompletedModalOpen(true);
                        },
                      },
                      {
                        content: "Complete & send to customer",
                        if: !!damageCostEvaluation.contract,
                        onClick: () => {
                          setIsSendToCustomer(true);
                          setIsConfirmCompletedModalOpen(true);
                        },
                      },
                    ]}
                  /> */}
                  </Stack>
                )}
              </AuthorizedElement>
            </AuthorizedTenantElement>

            {/* Confirm complete */}
            {damageCostEvaluation && (
              <ConfirmationModal
                title='Complete the cost evaluation?'
                body={
                  <>
                    <Box>
                      {`You're going to complete the damage cost evaluation`}{" "}
                      <strong>{damageCostEvaluation.localNumber}</strong>.
                    </Box>

                    <FormControl margin='dense' fullWidth>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSendToCustomer}
                            onChange={(e) => setIsSendToCustomer(e.target.checked)}
                          />
                        }
                        label='Send to customer'
                      />
                    </FormControl>
                  </>
                }
                open={isConfirmCompletedModalOpen}
                onClose={() => setIsConfirmCompletedModalOpen(false)}
                onCancel={() => setIsConfirmCompletedModalOpen(false)}
                onConfirm={async () => {
                  try {
                    const response =
                      await apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost(
                        {
                          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                          damageCostEvaluationId: damageCostEvaluation.id || "",
                          damageCostEvaluationCompleteDto: {
                            isSendToCustomer: isSendToCustomer,
                          },
                        },
                      );
                    onUpdate && onUpdate(response.data);
                    setIsConfirmCompletedModalOpen(false);
                    history.goBack();
                  } catch (err) {
                    const validation2 = ValidationHelper.handleApiErrorResponse(err);
                    enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
                    throw err;
                  }
                }}
              />
            )}

            {/* Create repair spec from local repair spec data */}
            {createRepairSpecFromSpecData && (
              <CreateUpdateRepairSpecModal
                open={isCreateRepairSpecModalOpen}
                onClose={() => {
                  setIsCreateRepairSpecModalOpen(false);
                  setCreateRepairSpecFromSpecData(undefined);
                }}
                createUpdateProps={{
                  defaultValues: {
                    data: createRepairSpecFromSpecData,
                  },
                  onSave: () => {
                    setIsCreateRepairSpecModalOpen(false);
                    setCreateRepairSpecFromSpecData(undefined);
                  },
                }}
              />
            )}

            {/* Add to repair operation */}
            {damagesToAddToRepairOperation && (
              <RepairOperationAddVehicleDamagesModal
                open={isAddToRepairOperationModalOpen}
                onClose={() => setIsAddToRepairOperationModalOpen(false)}
                damages={damagesToAddToRepairOperation}
              />
            )}
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
