import { Stack, Typography, TypographyProps } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { useHistory } from "react-router-dom";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AccessoryCheckDto,
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
} from "@/core/api/generated";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import Image from "@/common/components/Images/Image";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { MakeLogoHelper } from "@/common/helpers/makeLogo";
import { StringHelper } from "@/common/helpers/string";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import AccessoryCheckMenu from "../AccessoryCheckMenu";
import ContractTabContent from "./Tabs/ContractTabContent";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import VehicleTabContent from "./Tabs/VehicleTabContent";

export enum AccessoryCheckViewPageTabs {
  Overview = "Overview",
  Vehicle = "Vehicle",
  Contract = "Contract",
  GeneralHistory = "GeneralHistory",
}
const defaultHeaderProps = {
  withLink: false,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  actions: true,
  vehicle: true,
  contract: true,
  items: true,
  attachments: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps {
  accessoryCheckId?: string | null;
  accessoryCheck?: AccessoryCheckDto | null;
  withRealtimeDataUpdates?: boolean;
  headerProps?: Partial<typeof defaultHeaderProps> & {
    title?: ReactNode | ((entity?: AccessoryCheckDto | null) => ReactNode);
    titleTypographyProps?: TypographyProps;
  };
  displayProps?: Partial<typeof defaultDisplayProps>;
  onEntityChanged?: () => void;
}

type Props = OwnProps & BaseEntityViewInheritableProps<AccessoryCheckDto>;

export default function AccessoryCheckView({
  accessoryCheckId,
  accessoryCheck,
  withRealtimeDataUpdates = true,
  headerProps = defaultHeaderProps,
  displayProps = defaultDisplayProps,
  onEntityChanged,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const { hasPermissions } = useAuthorizationInfo();
  const { enqueueSnackbar } = useAppSnackbar();
  const thunkDispatch = useAppThunkDispatch();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.AccessoryCheck),
      viewVariant: displayProps.viewVariant,
    },
  });

  const request = useApiRequest(
    apiClient.accessoryChecksApi.apiV1AccessoryChecksAccessoryCheckIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      accessoryCheckId: accessoryCheckId!,
    },
    {
      deps: [accessoryCheckId],
      skip: !accessoryCheckId,
    },
  );
  accessoryCheck = request?.data || accessoryCheck;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.AccessoryCheck,
        accessoryCheckId || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      request.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        accessoryCheck && {
          idValue: accessoryCheck.id!,
          newTitle: accessoryCheck.localNumber || "",
        }) ||
      undefined,
  });

  const _onEntityChanged = async () => {
    accessoryCheckId && (await request.refetch());
    onEntityChanged && onEntityChanged();
  };

  const vehicleMakeUrl = useMemo(() => {
    return MakeLogoHelper.getMakeLogoByIdentifier(accessoryCheck?.vehicle?.spec?.make?.identifier);
  }, [accessoryCheck]);

  return (
    <BaseEntityView
      entityType={EntityType.AccessoryCheck}
      entityId={accessoryCheckId}
      entity={accessoryCheck}
      entityRequest={request}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          <DetailedViewPageHeader
            image={
              // vehicleMakeUrl because RepairOperationDto doesn't have vehicle attachments
              <>
                {vehicleMakeUrl && (
                  <Image
                    src={vehicleMakeUrl}
                    sx={{
                      height: "45px",
                      width: "45px",
                      borderRadius: "6px",
                      objectFit: "contain",
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: (th) => th.palette.divider,
                    }}
                  />
                )}
              </>
            }
            title={
              <Typography variant='h4'>
                {accessoryCheck?.vehicle?.spec?.make?.name}
                {accessoryCheck?.vehicle?.spec?.model?.name}{" "}
                <EntityChipList entity={accessoryCheck?.vehicle} variant='normal' />
              </Typography>
            }
            subtitle={
              <Stack spacing={0.5}>
                <Typography variant='subtitle2'>
                  {StringHelper.joinIntoString(
                    [
                      accessoryCheck?.vehicle?.plateNo?.plateNo,
                      accessoryCheck?.vehicle?.identificationNumber,
                    ],
                    " • ",
                    { skipEmpty: true },
                  )}
                </Typography>

                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <GeneralAttachedTagsDisplay
                    tags={accessoryCheck?.tags}
                    defaultIsFolded={false}
                    edit={{
                      entityType: TagEntityType.AccessoryCheck,
                      entityId: accessoryCheck?.id,
                      onSaved: (newValue) => {
                        request.updateData((x) => {
                          x.tags = newValue || undefined;
                        });
                      },
                    }}
                  />
                </AuthorizedElement>
              </Stack>
            }
            primaryActions={
              displayProps?.actions && (
                <AccessoryCheckMenu
                  onUpdate={(newValue) =>
                    newValue ? request.replaceData(newValue) : request.refetch()
                  }
                  onDelete={() => history.goBack()}
                  entity={accessoryCheck}
                />
              )
            }
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <PageTabs
            tabIdsDefinition={AccessoryCheckViewPageTabs}
            defaultTabId={AccessoryCheckViewPageTabs.Overview}
            commonViewParams={commonViewParams}
            tabs={[
              { label: "Overview", value: AccessoryCheckViewPageTabs.Overview, isHideable: false },
              { label: "Vehicle", value: AccessoryCheckViewPageTabs.Vehicle },
              ...(accessoryCheck?.contract?.id
                ? [
                    {
                      label: "Contract",
                      value: AccessoryCheckViewPageTabs.Contract,
                    },
                  ]
                : []),
              { label: "History", value: AccessoryCheckViewPageTabs.GeneralHistory },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              accessoryCheck && (
                <>
                  {activeTab === AccessoryCheckViewPageTabs.Overview && (
                    <OverviewTabContent
                      accessoryCheck={accessoryCheck}
                      onUpdate={() => request.refetch()}
                    />
                  )}
                  {activeTab === AccessoryCheckViewPageTabs.Vehicle && (
                    <VehicleTabContent accessoryCheck={accessoryCheck} />
                  )}
                  {activeTab === AccessoryCheckViewPageTabs.Contract && (
                    <ContractTabContent accessoryCheck={accessoryCheck} />
                  )}
                  {activeTab === AccessoryCheckViewPageTabs.GeneralHistory && (
                    <GeneralHistoryTabContent accessoryCheck={accessoryCheck} />
                  )}
                </>
              )
            }
          </PageTabs>
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}
