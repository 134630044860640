import { Box, SxProps, Theme } from "@mui/material";

import { FileItem } from "@/common/fileItem";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import * as filesSlice from "@/store/files/slice";

import FileItemView, { FileItemViewProps } from "./FileItemView";

export interface FileUploadListProps {
  files: FileItem[];
  fileItemViewProps?: Pick<FileItemViewProps, "actions">;
  sx?: SxProps<Theme>;
}

/** List of uploaded files. */

export default function FileUploadList({ files, fileItemViewProps, sx }: FileUploadListProps) {
  const dispatch = useAppThunkDispatch();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          ...sx,
        }}
      >
        {files?.map((file, i) => {
          const actionsComputed = fileItemViewProps?.actions?.(file);
          return (
            <Box
              key={i}
              sx={{
                mt: 2,
                mr: 2,
              }}
            >
              <FileItemView
                {...fileItemViewProps}
                fileItem={file}
                size={{
                  width: 100,
                }}
                actions={(item) => ({
                  enabled: true,
                  click: true,
                  onClick: (item2) => {
                    dispatch(
                      filesSlice.setFullScreenImages({
                        files: files.filter((x) => x.fileType.isImage),
                        actions: {
                          setCaption: actionsComputed?.setCaption,
                          onSetCaption: actionsComputed?.setCaption
                            ? (f, c) => actionsComputed?.onSetCaption?.(f, c)
                            : undefined,
                        },
                      }),
                    );
                  },
                  ...(fileItemViewProps?.actions ? fileItemViewProps.actions(item) : {}),
                })}
                imageSx={{
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
