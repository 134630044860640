import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosRequestConfig } from "axios";

import { FileItem } from "@/common/fileItem";
import { FileApiApiV1FilesUploadPostRequest, UploadFilesResultDto } from "@/core/api/generated";

import { AppThunk } from "..";

export type UploadFilesFuncRequestParameters = FileApiApiV1FilesUploadPostRequest;

export type UploadFilesFunc = (
  requestParameters: UploadFilesFuncRequestParameters,
  options?: AxiosRequestConfig<any> | undefined,
) => Promise<UploadFilesResultDto>;

export interface FullScreenFileActions {
  setCaption?: boolean;
  onSetCaption?: (file: FileItem, caption: string | undefined) => void;
}

export interface FullScreenFilesState {
  files: FileItem[];
  actions?: FullScreenFileActions | null;
}

export interface FilesState {
  fullScreen: FullScreenFilesState;
}

const initialState: FilesState = {
  fullScreen: {
    files: [],
    actions: null,
  },
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    _fullScreenStateChanged: (
      state,
      action: PayloadAction<{ files: FileItem[]; actions?: FullScreenFileActions | null }>,
    ) => {
      const { files, actions } = action.payload;
      state.fullScreen = {
        files: files,
        actions: actions,
      };
    },
  },
});

export const { _fullScreenStateChanged } = filesSlice.actions;

export const setFullScreenImages =
  (params: { files: FileItem[]; actions?: FullScreenFileActions | null }): AppThunk<void> =>
  (dispatch) => {
    dispatch(_fullScreenStateChanged(params));
  };

export const filesReducer = filesSlice.reducer;
