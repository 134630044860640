/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * NexusFleet SaaS
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@nexusops.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccessoryChangedDto
 */
export interface AccessoryChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof AccessoryChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof AccessoryChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof AccessoryChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {AccessoryDto}
     * @memberof AccessoryChangedDto
     */
    'entity'?: AccessoryDto;
    /**
     * 
     * @type {string}
     * @memberof AccessoryChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface AccessoryCheckChangedDto
 */
export interface AccessoryCheckChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryCheckChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof AccessoryCheckChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof AccessoryCheckChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {AccessoryCheckDto}
     * @memberof AccessoryCheckChangedDto
     */
    'entity'?: AccessoryCheckDto;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface AccessoryCheckContentDto
 */
export interface AccessoryCheckContentDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckContentDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface AccessoryCheckContentInputDto
 */
export interface AccessoryCheckContentInputDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckContentInputDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface AccessoryCheckCountersDto
 */
export interface AccessoryCheckCountersDto {
    /**
     * 
     * @type {number}
     * @memberof AccessoryCheckCountersDto
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface AccessoryCheckCreatedInAppDataDto
 */
export interface AccessoryCheckCreatedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof AccessoryCheckCreatedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckCreatedInAppDataDto
     */
    'accessoryCheckId'?: string;
}


/**
 * 
 * @export
 * @interface AccessoryCheckDataDto
 */
export interface AccessoryCheckDataDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDataDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof AccessoryCheckDataDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof AccessoryCheckDataDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {FrontEndAppType}
     * @memberof AccessoryCheckDataDto
     */
    'appType'?: FrontEndAppType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryCheckDataDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof AccessoryCheckDataDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {Array<AccessoryCheckItemDataDto>}
     * @memberof AccessoryCheckDataDto
     */
    'items'?: Array<AccessoryCheckItemDataDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AccessoryCheckDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDataDto
     */
    'notes'?: string | null;
}


/**
 * 
 * @export
 * @interface AccessoryCheckDto
 */
export interface AccessoryCheckDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryCheckDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryCheckDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {FrontEndAppType}
     * @memberof AccessoryCheckDto
     */
    'appType'?: FrontEndAppType;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'localNumber'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof AccessoryCheckDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof AccessoryCheckDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof AccessoryCheckDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {Array<AccessoryCheckItemDto>}
     * @memberof AccessoryCheckDto
     */
    'items'?: Array<AccessoryCheckItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AccessoryCheckDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof AccessoryCheckDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof AccessoryCheckDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof AccessoryCheckDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface AccessoryCheckGetPaginatedDto
 */
export interface AccessoryCheckGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof AccessoryCheckGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}
/**
 * 
 * @export
 * @interface AccessoryCheckItemDataDto
 */
export interface AccessoryCheckItemDataDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDataDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AccessoryReferenceDto}
     * @memberof AccessoryCheckItemDataDto
     */
    'accessory'?: AccessoryReferenceDto;
    /**
     * 
     * @type {AccessoryStatus}
     * @memberof AccessoryCheckItemDataDto
     */
    'status'?: AccessoryStatus;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AccessoryCheckItemDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDataDto
     */
    'notes'?: string | null;
}


/**
 * 
 * @export
 * @interface AccessoryCheckItemDto
 */
export interface AccessoryCheckItemDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AccessoryReferenceDto}
     * @memberof AccessoryCheckItemDto
     */
    'accessory'?: AccessoryReferenceDto;
    /**
     * 
     * @type {AccessoryStatus}
     * @memberof AccessoryCheckItemDto
     */
    'status'?: AccessoryStatus;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AccessoryCheckItemDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDto
     */
    'notes'?: string | null;
}


/**
 * 
 * @export
 * @interface AccessoryCheckItemReferenceDto
 */
export interface AccessoryCheckItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemReferenceDto
     */
    'accessoryCheckId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface AccessoryCheckReferenceDto
 */
export interface AccessoryCheckReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface AccessoryCheckResultDto
 */
export interface AccessoryCheckResultDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckResultDto
     */
    'accessoryCheckId'?: string;
}
/**
 * 
 * @export
 * @interface AccessoryCheckResultInputDto
 */
export interface AccessoryCheckResultInputDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckResultInputDto
     */
    'accessoryCheckId'?: string;
}
/**
 * 
 * @export
 * @interface AccessoryCheckSearchPaginatedDto
 */
export interface AccessoryCheckSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof AccessoryCheckSearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}
/**
 * 
 * @export
 * @interface AccessoryDto
 */
export interface AccessoryDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AccessoryType}
     * @memberof AccessoryDto
     */
    'type'?: AccessoryType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AccessoryDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {EntityImportMetaDto}
     * @memberof AccessoryDto
     */
    'importMeta'?: EntityImportMetaDto;
    /**
     * 
     * @type {PoolsMetaDto}
     * @memberof AccessoryDto
     */
    'poolsMeta'?: PoolsMetaDto;
    /**
     * 
     * @type {AssetMetaDto}
     * @memberof AccessoryDto
     */
    'assetMeta'?: AssetMetaDto;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof AccessoryDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof AccessoryDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessoryDto
     */
    'isForAllVehicles'?: boolean;
}


/**
 * 
 * @export
 * @interface AccessoryFullReferenceDto
 */
export interface AccessoryFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AccessoryType}
     * @memberof AccessoryFullReferenceDto
     */
    'type'?: AccessoryType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AccessoryFullReferenceDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {EntityImportMetaDto}
     * @memberof AccessoryFullReferenceDto
     */
    'importMeta'?: EntityImportMetaDto;
    /**
     * 
     * @type {PoolsMetaDto}
     * @memberof AccessoryFullReferenceDto
     */
    'poolsMeta'?: PoolsMetaDto;
    /**
     * 
     * @type {AssetMetaDto}
     * @memberof AccessoryFullReferenceDto
     */
    'assetMeta'?: AssetMetaDto;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof AccessoryFullReferenceDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessoryFullReferenceDto
     */
    'isForAllVehicles'?: boolean;
}


/**
 * 
 * @export
 * @interface AccessoryGetPaginatedDto
 */
export interface AccessoryGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AccessoryGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccessoryGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessoryGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AccessoryGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AccessoryGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AccessoryGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {AccessoryType}
     * @memberof AccessoryGetPaginatedDto
     */
    'type'?: AccessoryType;
    /**
     * 
     * @type {VehicleType}
     * @memberof AccessoryGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {PoolsMetaQueryDto}
     * @memberof AccessoryGetPaginatedDto
     */
    'poolsMeta'?: PoolsMetaQueryDto;
    /**
     * 
     * @type {AssetMetaQueryDto}
     * @memberof AccessoryGetPaginatedDto
     */
    'assetMeta'?: AssetMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof AccessoryGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @interface AccessoryReferenceDto
 */
export interface AccessoryReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AccessoryType}
     * @memberof AccessoryReferenceDto
     */
    'type'?: AccessoryType;
    /**
     * 
     * @type {string}
     * @memberof AccessoryReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryReferenceDto
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @interface AccessorySearchPaginatedDto
 */
export interface AccessorySearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AccessorySearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccessorySearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessorySearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessorySearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessorySearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessorySearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessorySearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AccessorySearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AccessorySearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AccessorySearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {AccessoryType}
     * @memberof AccessorySearchPaginatedDto
     */
    'type'?: AccessoryType;
    /**
     * 
     * @type {VehicleType}
     * @memberof AccessorySearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {PoolsMetaQueryDto}
     * @memberof AccessorySearchPaginatedDto
     */
    'poolsMeta'?: PoolsMetaQueryDto;
    /**
     * 
     * @type {AssetMetaQueryDto}
     * @memberof AccessorySearchPaginatedDto
     */
    'assetMeta'?: AssetMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof AccessorySearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AccessoryStatus = {
    None: 'None',
    InOrder: 'InOrder',
    Missing: 'Missing',
    Damaged: 'Damaged'
} as const;

export type AccessoryStatus = typeof AccessoryStatus[keyof typeof AccessoryStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const AccessoryType = {
    None: 'None',
    SpareKey: 'SpareKey',
    ChargerCable: 'ChargerCable',
    TrunkCover: 'TrunkCover',
    AutopassUnit: 'AutopassUnit',
    PortableTireInflator: 'PortableTireInflator',
    ChildSeat: 'ChildSeat',
    DashCam: 'DashCam',
    PhoneHolder: 'PhoneHolder',
    PhoneCharger: 'PhoneCharger'
} as const;

export type AccessoryType = typeof AccessoryType[keyof typeof AccessoryType];


/**
 * 
 * @export
 * @interface AddAccessoryCheckToContractDto
 */
export interface AddAccessoryCheckToContractDto {
    /**
     * 
     * @type {string}
     * @memberof AddAccessoryCheckToContractDto
     */
    'contractId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddAccessoryCheckToContractDto
     */
    'isSendToCustomer'?: boolean;
}
/**
 * 
 * @export
 * @interface AddAssetSubscriptionPlanProductLocationsDto
 */
export interface AddAssetSubscriptionPlanProductLocationsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddAssetSubscriptionPlanProductLocationsDto
     */
    'productLocationIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AddChatParticipantsDto
 */
export interface AddChatParticipantsDto {
    /**
     * 
     * @type {Array<ChatParticipantCandidateInputDto>}
     * @memberof AddChatParticipantsDto
     */
    'candidates'?: Array<ChatParticipantCandidateInputDto>;
}
/**
 * 
 * @export
 * @interface AddDamageCostEvaluationToContractDto
 */
export interface AddDamageCostEvaluationToContractDto {
    /**
     * 
     * @type {string}
     * @memberof AddDamageCostEvaluationToContractDto
     */
    'contractId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddDamageCostEvaluationToContractDto
     */
    'isSendToCustomer'?: boolean;
}
/**
 * 
 * @export
 * @interface AddDamageDetectionToContractDto
 */
export interface AddDamageDetectionToContractDto {
    /**
     * 
     * @type {string}
     * @memberof AddDamageDetectionToContractDto
     */
    'contractId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddDamageDetectionToContractDto
     */
    'isSendToCustomer'?: boolean;
}
/**
 * 
 * @export
 * @interface AddDepartmentLocationsDto
 */
export interface AddDepartmentLocationsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddDepartmentLocationsDto
     */
    'locationIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AddDepartmentUsersDto
 */
export interface AddDepartmentUsersDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddDepartmentUsersDto
     */
    'userIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AddDocumentAttachmentsDto
 */
export interface AddDocumentAttachmentsDto {
    /**
     * 
     * @type {Array<UploadedFileDto>}
     * @memberof AddDocumentAttachmentsDto
     */
    'attachments'?: Array<UploadedFileDto>;
}
/**
 * 
 * @export
 * @interface AddDocumentItemAttachmentsDto
 */
export interface AddDocumentItemAttachmentsDto {
    /**
     * 
     * @type {Array<UploadedFileDto>}
     * @memberof AddDocumentItemAttachmentsDto
     */
    'attachments'?: Array<UploadedFileDto>;
}
/**
 * 
 * @export
 * @interface AddLocationSpotsDto
 */
export interface AddLocationSpotsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddLocationSpotsDto
     */
    'spotIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AddProductLocationSpotsDto
 */
export interface AddProductLocationSpotsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddProductLocationSpotsDto
     */
    'spotIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AddUserToLocationDto
 */
export interface AddUserToLocationDto {
    /**
     * 
     * @type {string}
     * @memberof AddUserToLocationDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddUserToLocationDto
     */
    'locationId'?: string;
}
/**
 * 
 * @export
 * @interface AdminAddUserToTenantDto
 */
export interface AdminAddUserToTenantDto {
    /**
     * 
     * @type {string}
     * @memberof AdminAddUserToTenantDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAddUserToTenantDto
     */
    'userId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminAddUserToTenantDto
     */
    'roleIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdminClearCacheDto
 */
export interface AdminClearCacheDto {
    /**
     * 
     * @type {boolean}
     * @memberof AdminClearCacheDto
     */
    'isClearAll'?: boolean;
}
/**
 * 
 * @export
 * @interface AdminCreateAnonymousInviteDto
 */
export interface AdminCreateAnonymousInviteDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateAnonymousInviteDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateAnonymousInviteDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateAnonymousInviteDto
     */
    'locationId'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof AdminCreateAnonymousInviteDto
     */
    'lifetime'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateAnonymousInviteDto
     */
    'roleIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdminCreateCustomSessionDto
 */
export interface AdminCreateCustomSessionDto {
    /**
     * 
     * @type {UserMembershipType}
     * @memberof AdminCreateCustomSessionDto
     */
    'membershipType'?: UserMembershipType;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCustomSessionDto
     */
    'tenantId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminCreateCustomSessionDto
     */
    'expiresAt'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof AdminCreateCustomSessionDto
     */
    'lifetime'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateCustomSessionDto
     */
    'roleIds'?: Array<string>;
    /**
     * 
     * @type {ForAccountDtoInAdminCreateCustomSessionDto}
     * @memberof AdminCreateCustomSessionDto
     */
    'forAccount'?: ForAccountDtoInAdminCreateCustomSessionDto;
    /**
     * 
     * @type {object}
     * @memberof AdminCreateCustomSessionDto
     */
    'forNoAccount'?: object;
    /**
     * 
     * @type {object}
     * @memberof AdminCreateCustomSessionDto
     */
    'forAnonymous'?: object;
}


/**
 * 
 * @export
 * @interface AdminCreateDamageTypeDto
 */
export interface AdminCreateDamageTypeDto {
    /**
     * 
     * @type {DamageTypeCategory}
     * @memberof AdminCreateDamageTypeDto
     */
    'category'?: DamageTypeCategory;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateDamageTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateDamageTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateDamageTypeDto
     */
    'isCategoryRoot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateDamageTypeDto
     */
    'isEnabledForDamageDetection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateDamageTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateDamageTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminCreateGlobalRoleDto
 */
export interface AdminCreateGlobalRoleDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateGlobalRoleDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateGlobalRoleDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<AppPermission>}
     * @memberof AdminCreateGlobalRoleDto
     */
    'permissions'?: Array<AppPermission>;
}
/**
 * 
 * @export
 * @interface AdminCreateInviteDto
 */
export interface AdminCreateInviteDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateInviteDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateInviteDto
     */
    'email'?: string;
    /**
     * 
     * @type {GeneralPersonNameInputDto}
     * @memberof AdminCreateInviteDto
     */
    'personName'?: GeneralPersonNameInputDto;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateInviteDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateInviteDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateInviteDto
     */
    'locationId'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof AdminCreateInviteDto
     */
    'lifetime'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateInviteDto
     */
    'roleIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateInviteDto
     */
    'isInviteExistingUser'?: boolean;
}
/**
 * 
 * @export
 * @interface AdminCreateNotificationDto
 */
export interface AdminCreateNotificationDto {
    /**
     * 
     * @type {NotificationChannel}
     * @memberof AdminCreateNotificationDto
     */
    'channel'?: NotificationChannel;
    /**
     * 
     * @type {AdminInAppChannelInfoDto}
     * @memberof AdminCreateNotificationDto
     */
    'inApp'?: AdminInAppChannelInfoDto;
    /**
     * 
     * @type {AdminEmailChannelInfoDto}
     * @memberof AdminCreateNotificationDto
     */
    'email'?: AdminEmailChannelInfoDto;
    /**
     * 
     * @type {AdminSmsChannelInfoDto}
     * @memberof AdminCreateNotificationDto
     */
    'sms'?: AdminSmsChannelInfoDto;
    /**
     * 
     * @type {AdminPushChannelInfoDto}
     * @memberof AdminCreateNotificationDto
     */
    'push'?: AdminPushChannelInfoDto;
    /**
     * 
     * @type {AdminWebPushChannelInfoDto}
     * @memberof AdminCreateNotificationDto
     */
    'webPush'?: AdminWebPushChannelInfoDto;
}


/**
 * 
 * @export
 * @interface AdminCreateSubscriptionPlanDto
 */
export interface AdminCreateSubscriptionPlanDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSubscriptionPlanDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSubscriptionPlanDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateSubscriptionPlanDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateSubscriptionPlanDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<AdminCreateUpdateSubscriptionPlanPriceDto>}
     * @memberof AdminCreateSubscriptionPlanDto
     */
    'prices'?: Array<AdminCreateUpdateSubscriptionPlanPriceDto>;
}
/**
 * 
 * @export
 * @interface AdminCreateUpdateSubscriptionPlanPriceDto
 */
export interface AdminCreateUpdateSubscriptionPlanPriceDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateUpdateSubscriptionPlanPriceDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof AdminCreateUpdateSubscriptionPlanPriceDto
     */
    'period'?: BillingPeriod;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateUpdateSubscriptionPlanPriceDto
     */
    'price'?: number;
}


/**
 * 
 * @export
 * @interface AdminCreateVehicleBodyTypeDto
 */
export interface AdminCreateVehicleBodyTypeDto {
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminCreateVehicleBodyTypeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof AdminCreateVehicleBodyTypeDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleBodyTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleBodyTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateVehicleBodyTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVehicleBodyTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminCreateVehicleFuelTypeDto
 */
export interface AdminCreateVehicleFuelTypeDto {
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminCreateVehicleFuelTypeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleFuelType}
     * @memberof AdminCreateVehicleFuelTypeDto
     */
    'fuelType'?: VehicleFuelType;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleFuelTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleFuelTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateVehicleFuelTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVehicleFuelTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminCreateVehicleGenerationDto
 */
export interface AdminCreateVehicleGenerationDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleGenerationDto
     */
    'makeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleGenerationDto
     */
    'modelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleGenerationDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVehicleGenerationDto
     */
    'startYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVehicleGenerationDto
     */
    'endYear'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateVehicleGenerationDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVehicleGenerationDto
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface AdminCreateVehicleMakeDto
 */
export interface AdminCreateVehicleMakeDto {
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminCreateVehicleMakeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleMakeDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateVehicleMakeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVehicleMakeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminCreateVehicleModelDto
 */
export interface AdminCreateVehicleModelDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleModelDto
     */
    'makeId'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminCreateVehicleModelDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleModelDto
     */
    'name'?: string;
    /**
     * 
     * @type {VehicleSize}
     * @memberof AdminCreateVehicleModelDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateVehicleModelDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVehicleModelDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminCreateVehicleModificationDto
 */
export interface AdminCreateVehicleModificationDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleModificationDto
     */
    'makeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleModificationDto
     */
    'modelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleModificationDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleModificationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleModificationDto
     */
    'fuelTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleModificationDto
     */
    'bodyTypeId'?: string | null;
    /**
     * 
     * @type {VehicleSize}
     * @memberof AdminCreateVehicleModificationDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateVehicleModificationDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVehicleModificationDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminCreateVehiclePartTypeDto
 */
export interface AdminCreateVehiclePartTypeDto {
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'category'?: VehiclePartCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'type'?: VehiclePartType;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'areas'?: Array<VehicleArea>;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'projections'?: Array<VehicleProjection> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'isCategoryRoot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'isTypeRoot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'isEnabledForDamageDetection'?: boolean;
    /**
     * 
     * @type {Array<DamageTypeCategory>}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'damageCategories'?: Array<DamageTypeCategory> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'damageTypeIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVehiclePartTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminCreateVehicleTypeDto
 */
export interface AdminCreateVehicleTypeDto {
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminCreateVehicleTypeDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateVehicleTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVehicleTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminCreateVehicleVisualModelDto
 */
export interface AdminCreateVehicleVisualModelDto {
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof AdminCreateVehicleVisualModelDto
     */
    'type'?: VehicleVisualModelType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AdminCreateVehicleVisualModelDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {Array<VehicleBodyType>}
     * @memberof AdminCreateVehicleVisualModelDto
     */
    'bodyTypes'?: Array<VehicleBodyType> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateVehicleVisualModelDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateVehicleVisualModelDto
     */
    'modelIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateVehicleVisualModelDto
     */
    'generationIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateVehicleVisualModelDto
     */
    'modificationIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<AdminCreateVehicleVisualModelImageDto>}
     * @memberof AdminCreateVehicleVisualModelDto
     */
    'images'?: Array<AdminCreateVehicleVisualModelImageDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateVehicleVisualModelDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateVehicleVisualModelDto
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateVehicleVisualModelDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminCreateVehicleVisualModelDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminCreateVehicleVisualModelImageDto
 */
export interface AdminCreateVehicleVisualModelImageDto {
    /**
     * 
     * @type {VehicleArea}
     * @memberof AdminCreateVehicleVisualModelImageDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof AdminCreateVehicleVisualModelImageDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {UploadedFileDto}
     * @memberof AdminCreateVehicleVisualModelImageDto
     */
    'file'?: UploadedFileDto;
}


/**
 * 
 * @export
 * @interface AdminCustomSessionGetPaginatedDto
 */
export interface AdminCustomSessionGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminCustomSessionGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminCustomSessionGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomSessionGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCustomSessionGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCustomSessionGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCustomSessionGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminCustomSessionGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminCustomSessionGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminCustomSessionGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomSessionGetPaginatedDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomSessionGetPaginatedDto
     */
    'customIdentityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomSessionGetPaginatedDto
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminDamageTypeDto
 */
export interface AdminDamageTypeDto {
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {DamageTypeCategory}
     * @memberof AdminDamageTypeDto
     */
    'category'?: DamageTypeCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof AdminDamageTypeDto
     */
    'partType'?: VehiclePartType;
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDamageTypeDto
     */
    'isCategoryRoot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDamageTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDamageTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminDamageTypeDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDamageTypeDto
     */
    'isEnabledForDamageDetection'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminDamageTypeDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminDamageTypeDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypeDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypeDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface AdminDamageTypeGetPaginatedDto
 */
export interface AdminDamageTypeGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {DamageTypeCategory}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'category'?: DamageTypeCategory;
    /**
     * 
     * @type {Array<DamageTypeCategory>}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'categories'?: Array<DamageTypeCategory> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'isCategoryRoot'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'isEnabledForDamageDetection'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'vehiclePartTypeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDamageTypeGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminDamageTypeSearchPaginatedDto
 */
export interface AdminDamageTypeSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {DamageTypeCategory}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'category'?: DamageTypeCategory;
    /**
     * 
     * @type {Array<DamageTypeCategory>}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'categories'?: Array<DamageTypeCategory> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'isCategoryRoot'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'isEnabledForDamageDetection'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'vehiclePartTypeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDamageTypeSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminEmailChannelInfoDto
 */
export interface AdminEmailChannelInfoDto {
    /**
     * 
     * @type {Array<AdminSendToAnonymousUserDto>}
     * @memberof AdminEmailChannelInfoDto
     */
    'users'?: Array<AdminSendToAnonymousUserDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminEmailChannelInfoDto
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEmailChannelInfoDto
     */
    'templateId'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof AdminEmailChannelInfoDto
     */
    'templateData'?: any | null;
    /**
     * 
     * @type {DefaultEmailTemplateDataV2}
     * @memberof AdminEmailChannelInfoDto
     */
    'defaultTemplateData'?: DefaultEmailTemplateDataV2;
}
/**
 * 
 * @export
 * @interface AdminInAppChannelInfoDto
 */
export interface AdminInAppChannelInfoDto {
    /**
     * 
     * @type {Array<AdminSendToUserDto>}
     * @memberof AdminInAppChannelInfoDto
     */
    'users'?: Array<AdminSendToUserDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminInAppChannelInfoDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInAppChannelInfoDto
     */
    'body'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminIntegrationApiClientGetPaginatedDto
 */
export interface AdminIntegrationApiClientGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminIntegrationApiClientGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminIntegrationApiClientGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminIntegrationApiClientGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminIntegrationApiClientGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminIntegrationApiClientGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminIntegrationApiClientGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminIntegrationApiClientGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminIntegrationApiClientGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientGetPaginatedDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {IntegrationApiScopeType}
     * @memberof AdminIntegrationApiClientGetPaginatedDto
     */
    'scopeType'?: IntegrationApiScopeType;
}


/**
 * 
 * @export
 * @interface AdminInviteAnonymousResultDto
 */
export interface AdminInviteAnonymousResultDto {
    /**
     * 
     * @type {string}
     * @memberof AdminInviteAnonymousResultDto
     */
    'inviteUrl'?: string;
}
/**
 * 
 * @export
 * @interface AdminInviteDto
 */
export interface AdminInviteDto {
    /**
     * 
     * @type {string}
     * @memberof AdminInviteDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInviteDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInviteDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInviteDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInviteDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {InviteType}
     * @memberof AdminInviteDto
     */
    'type'?: InviteType;
    /**
     * 
     * @type {InviteUserType}
     * @memberof AdminInviteDto
     */
    'userType'?: InviteUserType;
    /**
     * 
     * @type {InviteUserInfo}
     * @memberof AdminInviteDto
     */
    'userInfo'?: InviteUserInfo;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminInviteDto
     */
    'sentAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminInviteDto
     */
    'expiresAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInviteDto
     */
    'inviteUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInviteDto
     */
    'redirectUrl'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminInviteDto
     */
    'maxUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInviteDto
     */
    'currentUsers'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInviteDto
     */
    'registeredUserIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInviteDto
     */
    'acceptedUserIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInviteDto
     */
    'rejectedUserIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInviteDto
     */
    'roleIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminInviteDto
     */
    'isExpired'?: boolean;
}


/**
 * 
 * @export
 * @interface AdminInviteGetPaginatedDto
 */
export interface AdminInviteGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminInviteGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInviteGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminInviteGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInviteGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInviteGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInviteGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminInviteGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminInviteGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminInviteGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof AdminInviteGetPaginatedDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInviteGetPaginatedDto
     */
    'inviteId'?: string | null;
    /**
     * 
     * @type {InviteType}
     * @memberof AdminInviteGetPaginatedDto
     */
    'type'?: InviteType;
    /**
     * 
     * @type {InviteUserType}
     * @memberof AdminInviteGetPaginatedDto
     */
    'userType'?: InviteUserType;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInviteGetPaginatedDto
     */
    'allRoleIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInviteGetPaginatedDto
     */
    'anyRoleIds'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface AdminInvitePersonalResultDto
 */
export interface AdminInvitePersonalResultDto {
    /**
     * 
     * @type {string}
     * @memberof AdminInvitePersonalResultDto
     */
    'inviteUrl'?: string;
}
/**
 * 
 * @export
 * @interface AdminInvoiceDto
 */
export interface AdminInvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'subscriptionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof AdminInvoiceDto
     */
    'status'?: InvoiceStatus;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof AdminInvoiceDto
     */
    'paymentMethod'?: PaymentMethod;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof AdminInvoiceDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {InvoiceBusinessInfoDto}
     * @memberof AdminInvoiceDto
     */
    'businessInfo'?: InvoiceBusinessInfoDto;
    /**
     * 
     * @type {InvoiceCustomerBusinessInfoDto}
     * @memberof AdminInvoiceDto
     */
    'customerBusinessInfo'?: InvoiceCustomerBusinessInfoDto;
    /**
     * 
     * @type {Array<AdminInvoiceLineItemDto>}
     * @memberof AdminInvoiceDto
     */
    'lineItems'?: Array<AdminInvoiceLineItemDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'date'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'dueAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'sentAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'pendingAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'paidAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'cancelledAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof AdminInvoiceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof AdminInvoiceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof AdminInvoiceDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInvoiceDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInvoiceDto
     */
    'total'?: number;
    /**
     * 
     * @type {AdminTenantDto}
     * @memberof AdminInvoiceDto
     */
    'company'?: AdminTenantDto;
    /**
     * 
     * @type {AdminSubscriptionDto}
     * @memberof AdminInvoiceDto
     */
    'subscription'?: AdminSubscriptionDto;
}


/**
 * 
 * @export
 * @interface AdminInvoiceGetPaginatedDto
 */
export interface AdminInvoiceGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminInvoiceGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInvoiceGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInvoiceGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInvoiceGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInvoiceGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminInvoiceGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminInvoiceGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminInvoiceGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceGetPaginatedDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceGetPaginatedDto
     */
    'subscriptionId'?: string | null;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof AdminInvoiceGetPaginatedDto
     */
    'status'?: InvoiceStatus;
}


/**
 * 
 * @export
 * @interface AdminInvoiceLineItemDto
 */
export interface AdminInvoiceLineItemDto {
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceLineItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceLineItemDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminInvoiceLineItemDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInvoiceLineItemDto
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminInvoiceLineItemDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof AdminInvoiceLineItemDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof AdminInvoiceLineItemDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof AdminInvoiceLineItemDto
     */
    'subtotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInvoiceLineItemDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface AdminNotificationDto
 */
export interface AdminNotificationDto {
    /**
     * 
     * @type {string}
     * @memberof AdminNotificationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminNotificationDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminNotificationDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {NotificationChannel}
     * @memberof AdminNotificationDto
     */
    'channel'?: NotificationChannel;
    /**
     * 
     * @type {NotificationImportance}
     * @memberof AdminNotificationDto
     */
    'importance'?: NotificationImportance;
    /**
     * 
     * @type {NotificationDeliveryStatus}
     * @memberof AdminNotificationDto
     */
    'deliveryStatus'?: NotificationDeliveryStatus;
    /**
     * 
     * @type {NotificationDeliveryError}
     * @memberof AdminNotificationDto
     */
    'deliveryError'?: NotificationDeliveryError;
    /**
     * 
     * @type {InAppChannelInfoDto}
     * @memberof AdminNotificationDto
     */
    'inApp'?: InAppChannelInfoDto;
    /**
     * 
     * @type {EmailChannelInfoDto}
     * @memberof AdminNotificationDto
     */
    'email'?: EmailChannelInfoDto;
    /**
     * 
     * @type {SmsChannelInfoDto}
     * @memberof AdminNotificationDto
     */
    'sms'?: SmsChannelInfoDto;
    /**
     * 
     * @type {PushChannelInfoDto}
     * @memberof AdminNotificationDto
     */
    'push'?: PushChannelInfoDto;
    /**
     * 
     * @type {WebPushChannelInfoDto}
     * @memberof AdminNotificationDto
     */
    'webPush'?: WebPushChannelInfoDto;
    /**
     * 
     * @type {boolean}
     * @memberof AdminNotificationDto
     */
    'isSent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminNotificationDto
     */
    'isRead'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminNotificationDto
     */
    'readAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminNotificationDto
     */
    'sentAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminNotificationDto
     */
    'createdAt'?: string;
}


/**
 * 
 * @export
 * @interface AdminNotificationGetPaginatedDto
 */
export interface AdminNotificationGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminNotificationGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminNotificationGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminNotificationGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminNotificationGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminNotificationGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminNotificationGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminNotificationGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminNotificationGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminNotificationGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof AdminNotificationGetPaginatedDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {NotificationChannel}
     * @memberof AdminNotificationGetPaginatedDto
     */
    'channel'?: NotificationChannel;
    /**
     * 
     * @type {NotificationDeliveryStatus}
     * @memberof AdminNotificationGetPaginatedDto
     */
    'deliveryStatus'?: NotificationDeliveryStatus;
}


/**
 * 
 * @export
 * @interface AdminPushChannelInfoDto
 */
export interface AdminPushChannelInfoDto {
    /**
     * 
     * @type {Array<AdminSendToUserDto>}
     * @memberof AdminPushChannelInfoDto
     */
    'users'?: Array<AdminSendToUserDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminPushChannelInfoDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPushChannelInfoDto
     */
    'body'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPushChannelInfoDto
     */
    'imageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPushChannelInfoDto
     */
    'channel'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminSendToAnonymousUserDto
 */
export interface AdminSendToAnonymousUserDto {
    /**
     * 
     * @type {string}
     * @memberof AdminSendToAnonymousUserDto
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface AdminSendToUserDto
 */
export interface AdminSendToUserDto {
    /**
     * 
     * @type {string}
     * @memberof AdminSendToUserDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSendToUserDto
     */
    'tenantId'?: string;
}
/**
 * 
 * @export
 * @interface AdminSmsChannelInfoDto
 */
export interface AdminSmsChannelInfoDto {
    /**
     * 
     * @type {Array<AdminSendToAnonymousUserDto>}
     * @memberof AdminSmsChannelInfoDto
     */
    'users'?: Array<AdminSendToAnonymousUserDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminSmsChannelInfoDto
     */
    'body'?: string;
}
/**
 * 
 * @export
 * @interface AdminSubscriptionDto
 */
export interface AdminSubscriptionDto {
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {AdminSubscriptionSelectedPlanDto}
     * @memberof AdminSubscriptionDto
     */
    'plan'?: AdminSubscriptionSelectedPlanDto;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof AdminSubscriptionDto
     */
    'status'?: SubscriptionStatus;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof AdminSubscriptionDto
     */
    'period'?: BillingPeriod;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'startsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'currentPeriodStartsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'currentPeriodEndsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'cancelAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'canceledAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'endsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'endedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'lastInvoiceId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface AdminSubscriptionGetPaginatedDto
 */
export interface AdminSubscriptionGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminSubscriptionGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminSubscriptionGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminSubscriptionGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminSubscriptionGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminSubscriptionGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminSubscriptionGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminSubscriptionGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminSubscriptionGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionGetPaginatedDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof AdminSubscriptionGetPaginatedDto
     */
    'status'?: SubscriptionStatus;
}


/**
 * 
 * @export
 * @interface AdminSubscriptionPlanDto
 */
export interface AdminSubscriptionPlanDto {
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionPlanDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionPlanDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionPlanDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminSubscriptionPlanDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminSubscriptionPlanDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminSubscriptionPlanDto
     */
    'isFree'?: boolean;
    /**
     * 
     * @type {Array<SubscriptionPlanPriceDto>}
     * @memberof AdminSubscriptionPlanDto
     */
    'prices'?: Array<SubscriptionPlanPriceDto>;
    /**
     * 
     * @type {Array<AppModule>}
     * @memberof AdminSubscriptionPlanDto
     */
    'modules'?: Array<AppModule>;
    /**
     * 
     * @type {object}
     * @memberof AdminSubscriptionPlanDto
     */
    'limits'?: object;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminSubscriptionPlanDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminSubscriptionPlanDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionPlanDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionPlanDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminSubscriptionSelectedPlanDto
 */
export interface AdminSubscriptionSelectedPlanDto {
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionSelectedPlanDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionSelectedPlanDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionSelectedPlanDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {SubscriptionPlanPriceDto}
     * @memberof AdminSubscriptionSelectedPlanDto
     */
    'price'?: SubscriptionPlanPriceDto;
    /**
     * 
     * @type {Array<AppModule>}
     * @memberof AdminSubscriptionSelectedPlanDto
     */
    'modules'?: Array<AppModule>;
    /**
     * 
     * @type {object}
     * @memberof AdminSubscriptionSelectedPlanDto
     */
    'limits'?: object;
}
/**
 * 
 * @export
 * @interface AdminTenantDto
 */
export interface AdminTenantDto {
    /**
     * 
     * @type {string}
     * @memberof AdminTenantDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTenantDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTenantDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTenantDto
     */
    'description'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminTenantDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminTenantDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTenantDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminTenantDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminTenantGetPaginatedDto
 */
export interface AdminTenantGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminTenantGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTenantGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminTenantGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminTenantGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminTenantGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminTenantGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminTenantGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminTenantGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminTenantGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof AdminTenantGetPaginatedDto
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AdminTestDomainEventType = {
    None: 'None',
    TestEvent: 'TestEvent',
    TestEvent2: 'TestEvent2',
    TestEvent3: 'TestEvent3'
} as const;

export type AdminTestDomainEventType = typeof AdminTestDomainEventType[keyof typeof AdminTestDomainEventType];


/**
 * 
 * @export
 * @interface AdminUpdateDamageTypeDto
 */
export interface AdminUpdateDamageTypeDto {
    /**
     * 
     * @type {DamageTypeCategory}
     * @memberof AdminUpdateDamageTypeDto
     */
    'category'?: DamageTypeCategory;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateDamageTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateDamageTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateDamageTypeDto
     */
    'isCategoryRoot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateDamageTypeDto
     */
    'isEnabledForDamageDetection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateDamageTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateDamageTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminUpdateGlobalRoleDto
 */
export interface AdminUpdateGlobalRoleDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateGlobalRoleDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateGlobalRoleDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<AppPermission>}
     * @memberof AdminUpdateGlobalRoleDto
     */
    'permissions'?: Array<AppPermission>;
}
/**
 * 
 * @export
 * @interface AdminUpdateSubscriptionPlanDto
 */
export interface AdminUpdateSubscriptionPlanDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateSubscriptionPlanDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateSubscriptionPlanDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateSubscriptionPlanDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateSubscriptionPlanDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<AdminCreateUpdateSubscriptionPlanPriceDto>}
     * @memberof AdminUpdateSubscriptionPlanDto
     */
    'prices'?: Array<AdminCreateUpdateSubscriptionPlanPriceDto>;
}
/**
 * 
 * @export
 * @interface AdminUpdateVehicleBodyTypeDto
 */
export interface AdminUpdateVehicleBodyTypeDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehicleBodyTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehicleBodyTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehicleBodyTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateVehicleBodyTypeDto
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface AdminUpdateVehicleFuelTypeDto
 */
export interface AdminUpdateVehicleFuelTypeDto {
    /**
     * 
     * @type {VehicleFuelType}
     * @memberof AdminUpdateVehicleFuelTypeDto
     */
    'fuelType'?: VehicleFuelType;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehicleFuelTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehicleFuelTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehicleFuelTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateVehicleFuelTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminUpdateVehicleGenerationDto
 */
export interface AdminUpdateVehicleGenerationDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehicleGenerationDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateVehicleGenerationDto
     */
    'startYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateVehicleGenerationDto
     */
    'endYear'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehicleGenerationDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateVehicleGenerationDto
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface AdminUpdateVehicleMakeDto
 */
export interface AdminUpdateVehicleMakeDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehicleMakeDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehicleMakeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateVehicleMakeDto
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface AdminUpdateVehicleModelDto
 */
export interface AdminUpdateVehicleModelDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehicleModelDto
     */
    'name'?: string;
    /**
     * 
     * @type {VehicleSize}
     * @memberof AdminUpdateVehicleModelDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehicleModelDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateVehicleModelDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminUpdateVehicleModificationDto
 */
export interface AdminUpdateVehicleModificationDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehicleModificationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehicleModificationDto
     */
    'fuelTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehicleModificationDto
     */
    'bodyTypeId'?: string | null;
    /**
     * 
     * @type {VehicleSize}
     * @memberof AdminUpdateVehicleModificationDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehicleModificationDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateVehicleModificationDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminUpdateVehiclePartTypeDto
 */
export interface AdminUpdateVehiclePartTypeDto {
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'category'?: VehiclePartCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'type'?: VehiclePartType;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'areas'?: Array<VehicleArea>;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'projections'?: Array<VehicleProjection> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'isCategoryRoot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'isTypeRoot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'isEnabledForDamageDetection'?: boolean;
    /**
     * 
     * @type {Array<DamageTypeCategory>}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'damageCategories'?: Array<DamageTypeCategory> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'damageTypeIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateVehiclePartTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminUpdateVehicleTypeDto
 */
export interface AdminUpdateVehicleTypeDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehicleTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehicleTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateVehicleTypeDto
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface AdminUpdateVehicleVisualModelDto
 */
export interface AdminUpdateVehicleVisualModelDto {
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehicleVisualModelDto
     */
    'isForceUpdate'?: boolean;
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof AdminUpdateVehicleVisualModelDto
     */
    'type'?: VehicleVisualModelType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AdminUpdateVehicleVisualModelDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {Array<VehicleBodyType>}
     * @memberof AdminUpdateVehicleVisualModelDto
     */
    'bodyTypes'?: Array<VehicleBodyType> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpdateVehicleVisualModelDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpdateVehicleVisualModelDto
     */
    'modelIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpdateVehicleVisualModelDto
     */
    'generationIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpdateVehicleVisualModelDto
     */
    'modificationIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<AdminUpdateVehicleVisualModelImageDto>}
     * @memberof AdminUpdateVehicleVisualModelDto
     */
    'images'?: Array<AdminUpdateVehicleVisualModelImageDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVehicleVisualModelDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehicleVisualModelDto
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateVehicleVisualModelDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateVehicleVisualModelDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface AdminUpdateVehicleVisualModelImageDto
 */
export interface AdminUpdateVehicleVisualModelImageDto {
    /**
     * 
     * @type {VehicleArea}
     * @memberof AdminUpdateVehicleVisualModelImageDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof AdminUpdateVehicleVisualModelImageDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {UploadedFileDto}
     * @memberof AdminUpdateVehicleVisualModelImageDto
     */
    'file'?: UploadedFileDto;
}


/**
 * 
 * @export
 * @interface AdminUserActivateDto
 */
export interface AdminUserActivateDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUserActivateDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUserActivateDto
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminUserCompanyDto
 */
export interface AdminUserCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUserCompanyDto
     */
    'id'?: string;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof AdminUserCompanyDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof AdminUserCompanyDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {UserStatus}
     * @memberof AdminUserCompanyDto
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {UserSettingsDto}
     * @memberof AdminUserCompanyDto
     */
    'settings'?: UserSettingsDto;
    /**
     * 
     * @type {GeneralAvatarDto}
     * @memberof AdminUserCompanyDto
     */
    'avatar'?: GeneralAvatarDto;
    /**
     * 
     * @type {Array<UserRoleDto>}
     * @memberof AdminUserCompanyDto
     */
    'roles'?: Array<UserRoleDto>;
    /**
     * 
     * @type {UserSuspensionInfoDto}
     * @memberof AdminUserCompanyDto
     */
    'suspension'?: UserSuspensionInfoDto;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserCompanyDto
     */
    'isPersonalInfoComplete'?: boolean;
}


/**
 * 
 * @export
 * @interface AdminUserDto
 */
export interface AdminUserDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUserDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserDto
     */
    'auth0UserId'?: string;
    /**
     * 
     * @type {UserStatus}
     * @memberof AdminUserDto
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof AdminUserDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof AdminUserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {UserSettingsDto}
     * @memberof AdminUserDto
     */
    'settings'?: UserSettingsDto;
    /**
     * 
     * @type {GeneralAvatarDto}
     * @memberof AdminUserDto
     */
    'avatar'?: GeneralAvatarDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserDto
     */
    'roleIds'?: Array<string>;
    /**
     * 
     * @type {Array<UserRoleDto>}
     * @memberof AdminUserDto
     */
    'roles'?: Array<UserRoleDto>;
    /**
     * 
     * @type {Array<AdminUserCompanyDto>}
     * @memberof AdminUserDto
     */
    'companies'?: Array<AdminUserCompanyDto>;
    /**
     * 
     * @type {UserSuspensionInfoDto}
     * @memberof AdminUserDto
     */
    'suspension'?: UserSuspensionInfoDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminUserDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminUserDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUserDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserDto
     */
    'isPersonalInfoComplete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserDto
     */
    'isCustomerUser'?: boolean;
}


/**
 * 
 * @export
 * @interface AdminUserGetPaginatedDto
 */
export interface AdminUserGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminUserGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminUserGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUserGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminUserGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminUserGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminUserGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof AdminUserGetPaginatedDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUserGetPaginatedDto
     */
    'notForTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUserGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUserGetPaginatedDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {UserStatus}
     * @memberof AdminUserGetPaginatedDto
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserGetPaginatedDto
     */
    'allRoleIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserGetPaginatedDto
     */
    'anyRoleIds'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface AdminUserRolesUpdateDto
 */
export interface AdminUserRolesUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUserRolesUpdateDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserRolesUpdateDto
     */
    'roleIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdminUserSuspendDto
 */
export interface AdminUserSuspendDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUserSuspendDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUserSuspendDto
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminUserUnsuspendDto
 */
export interface AdminUserUnsuspendDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUserUnsuspendDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUserUnsuspendDto
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminVehicleBodyTypeDto
 */
export interface AdminVehicleBodyTypeDto {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleBodyTypeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof AdminVehicleBodyTypeDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleBodyTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleBodyTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleBodyTypeDto
     */
    'sortOrder'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleBodyTypeDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleBodyTypeDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypeDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypeDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleBodyTypeGetPaginatedDto
 */
export interface AdminVehicleBodyTypeGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleBodyTypeGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleBodyTypeGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypeGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleBodyTypeGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleBodyTypeGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleBodyTypeGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleBodyTypeGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleBodyTypeGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleBodyTypeGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleBodyTypeGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypeGetPaginatedDto
     */
    'vehicleTypeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleBodyTypeGetPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleBodyTypeGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleBodyTypeSearchPaginatedDto
 */
export interface AdminVehicleBodyTypeSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'vehicleTypeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleBodyTypeSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleFuelTypeDto
 */
export interface AdminVehicleFuelTypeDto {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleFuelTypeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleFuelType}
     * @memberof AdminVehicleFuelTypeDto
     */
    'fuelType'?: VehicleFuelType;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleFuelTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleFuelTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleFuelTypeDto
     */
    'sortOrder'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleFuelTypeDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleFuelTypeDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypeDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypeDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleFuelTypeGetPaginatedDto
 */
export interface AdminVehicleFuelTypeGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleFuelTypeGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleFuelTypeGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypeGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleFuelTypeGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleFuelTypeGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleFuelTypeGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleFuelTypeGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleFuelTypeGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleFuelTypeGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleFuelTypeGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypeGetPaginatedDto
     */
    'vehicleTypeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleFuelTypeGetPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleFuelTypeGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleFuelTypeSearchPaginatedDto
 */
export interface AdminVehicleFuelTypeSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'vehicleTypeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleFuelTypeSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleGenerationDto
 */
export interface AdminVehicleGenerationDto {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationDto
     */
    'car2DbId'?: string | null;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof AdminVehicleGenerationDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof AdminVehicleGenerationDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleGenerationDto
     */
    'startYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleGenerationDto
     */
    'endYear'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleGenerationDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleGenerationDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleGenerationDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleGenerationDto
     */
    'vehicleType'?: VehicleType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleGenerationDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleGenerationDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleGenerationGetPaginatedDto
 */
export interface AdminVehicleGenerationGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'modelIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleGenerationGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleGenerationSearchPaginatedDto
 */
export interface AdminVehicleGenerationSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'modelIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleGenerationSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleMakeDto
 */
export interface AdminVehicleMakeDto {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleMakeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleMakeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleMakeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleMakeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleMakeDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleMakeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleMakeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleMakeDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleMakeDto
     */
    'car2DbId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleMakeDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleMakeDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleMakeDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleMakeDto
     */
    'updatedBy'?: string | null;
    /**
     * Whether to sync this entity with the source entity from Car2DB.  Sync stops when entity is updated manually.
     * @type {boolean}
     * @memberof AdminVehicleMakeDto
     */
    'isSyncWithCar2Db'?: boolean;
}


/**
 * 
 * @export
 * @interface AdminVehicleMakeGetPaginatedDto
 */
export interface AdminVehicleMakeGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleMakeGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleMakeGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleMakeGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleMakeGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleMakeGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleMakeGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleMakeGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleMakeGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleMakeGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleMakeGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AdminVehicleMakeGetPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleMakeGetPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleMakeGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleMakeSearchPaginatedDto
 */
export interface AdminVehicleMakeSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleMakeSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleModelDto
 */
export interface AdminVehicleModelDto {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleModelDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelDto
     */
    'name'?: string;
    /**
     * 
     * @type {VehicleSize}
     * @memberof AdminVehicleModelDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof AdminVehicleModelDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleModelDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof AdminVehicleModelDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleModelDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleModelDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelDto
     */
    'car2DbId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleModelDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleModelDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelDto
     */
    'updatedBy'?: string | null;
    /**
     * Whether to sync this entity with the source entity from Car2DB.  Sync stops when entity is updated manually.
     * @type {boolean}
     * @memberof AdminVehicleModelDto
     */
    'isSyncWithCar2Db'?: boolean;
}


/**
 * 
 * @export
 * @interface AdminVehicleModelGetPaginatedDto
 */
export interface AdminVehicleModelGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'vehicleTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleModelGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleModelSearchPaginatedDto
 */
export interface AdminVehicleModelSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'vehicleTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleModelSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleModificationDto
 */
export interface AdminVehicleModificationDto {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof AdminVehicleModificationDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof AdminVehicleModificationDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {VehicleGenerationReferenceDto}
     * @memberof AdminVehicleModificationDto
     */
    'generation'?: VehicleGenerationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationDto
     */
    'name'?: string;
    /**
     * 
     * @type {VehicleFuelTypeReferenceDto}
     * @memberof AdminVehicleModificationDto
     */
    'fuelType'?: VehicleFuelTypeReferenceDto;
    /**
     * 
     * @type {VehicleBodyTypeReferenceDto}
     * @memberof AdminVehicleModificationDto
     */
    'bodyType'?: VehicleBodyTypeReferenceDto;
    /**
     * 
     * @type {VehicleSize}
     * @memberof AdminVehicleModificationDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleModificationDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleModificationDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleModificationDto
     */
    'sortOrder'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleModificationDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleModificationDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleModificationDto
     */
    'vehicleType'?: VehicleType;
}


/**
 * 
 * @export
 * @interface AdminVehicleModificationGetPaginatedDto
 */
export interface AdminVehicleModificationGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'modelIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'generationIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleModificationGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleModificationSearchPaginatedDto
 */
export interface AdminVehicleModificationSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'modelIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'generationIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleModificationSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehiclePartTypeDto
 */
export interface AdminVehiclePartTypeDto {
    /**
     * 
     * @type {string}
     * @memberof AdminVehiclePartTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehiclePartTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehiclePartTypeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof AdminVehiclePartTypeDto
     */
    'category'?: VehiclePartCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof AdminVehiclePartTypeDto
     */
    'type'?: VehiclePartType;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof AdminVehiclePartTypeDto
     */
    'areas'?: Array<VehicleArea>;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof AdminVehiclePartTypeDto
     */
    'projections'?: Array<VehicleProjection>;
    /**
     * 
     * @type {{ [key: string]: Array<VehicleProjection>; }}
     * @memberof AdminVehiclePartTypeDto
     */
    'projectionsPerArea'?: { [key: string]: Array<VehicleProjection>; };
    /**
     * 
     * @type {string}
     * @memberof AdminVehiclePartTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehiclePartTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeDto
     */
    'isCategoryRoot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeDto
     */
    'isTypeRoot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeDto
     */
    'isEnabledForDamageDetection'?: boolean;
    /**
     * 
     * @type {Array<DamageTypeCategory>}
     * @memberof AdminVehiclePartTypeDto
     */
    'damageCategories'?: Array<DamageTypeCategory> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehiclePartTypeDto
     */
    'damageTypeIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminVehiclePartTypeDto
     */
    'sortOrder'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehiclePartTypeDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehiclePartTypeDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehiclePartTypeDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehiclePartTypeDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface AdminVehiclePartTypeGetPaginatedDto
 */
export interface AdminVehiclePartTypeGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'category'?: VehiclePartCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'type'?: VehiclePartType;
    /**
     * 
     * @type {Array<VehiclePartType>}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'types'?: Array<VehiclePartType> | null;
    /**
     * 
     * @type {VehiclePartDescriptor}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'descriptor'?: VehiclePartDescriptor;
    /**
     * 
     * @type {Array<VehiclePartDescriptor>}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'descriptors'?: Array<VehiclePartDescriptor> | null;
    /**
     * 
     * @type {VehicleArea}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'projections'?: Array<VehicleProjection> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'isCategoryRoot'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'isTypeRoot'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'isEnabledForDamageDetection'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehiclePartTypeSearchPaginatedDto
 */
export interface AdminVehiclePartTypeSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'category'?: VehiclePartCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'type'?: VehiclePartType;
    /**
     * 
     * @type {Array<VehiclePartType>}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'types'?: Array<VehiclePartType> | null;
    /**
     * 
     * @type {VehiclePartDescriptor}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'descriptor'?: VehiclePartDescriptor;
    /**
     * 
     * @type {Array<VehiclePartDescriptor>}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'descriptors'?: Array<VehiclePartDescriptor> | null;
    /**
     * 
     * @type {VehicleArea}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'projections'?: Array<VehicleProjection> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'isCategoryRoot'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'isTypeRoot'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'isEnabledForDamageDetection'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehiclePartTypeSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleTypeDto
 */
export interface AdminVehicleTypeDto {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleTypeDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleTypeDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleTypeDto
     */
    'car2DbId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleTypeDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleTypeDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleTypeDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleTypeDto
     */
    'updatedBy'?: string | null;
    /**
     * Whether to sync this entity with the source entity from Car2DB.  Sync stops when entity is updated manually.
     * @type {boolean}
     * @memberof AdminVehicleTypeDto
     */
    'isSyncWithCar2Db'?: boolean;
}


/**
 * 
 * @export
 * @interface AdminVehicleTypeGetPaginatedDto
 */
export interface AdminVehicleTypeGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleTypeGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleTypeGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleTypeGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleTypeGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleTypeGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleTypeGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleTypeGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleTypeGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleTypeGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleTypeGetPaginatedDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleTypeGetPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleTypeGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleTypeSearchPaginatedDto
 */
export interface AdminVehicleTypeSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleTypeSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleTypeSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleTypeSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleTypeSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleTypeSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleTypeSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleTypeSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleTypeSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleTypeSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleTypeSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleTypeSearchPaginatedDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleTypeSearchPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleTypeSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleVisualModelDto
 */
export interface AdminVehicleVisualModelDto {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelDto
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelDto
     */
    'versionId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleVisualModelDto
     */
    'isLatestVersion'?: boolean;
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof AdminVehicleVisualModelDto
     */
    'type'?: VehicleVisualModelType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AdminVehicleVisualModelDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {Array<VehicleBodyType>}
     * @memberof AdminVehicleVisualModelDto
     */
    'bodyTypes'?: Array<VehicleBodyType> | null;
    /**
     * 
     * @type {Array<VehicleMakeReferenceDto>}
     * @memberof AdminVehicleVisualModelDto
     */
    'makes'?: Array<VehicleMakeReferenceDto> | null;
    /**
     * 
     * @type {Array<VehicleModelReferenceDto>}
     * @memberof AdminVehicleVisualModelDto
     */
    'models'?: Array<VehicleModelReferenceDto> | null;
    /**
     * 
     * @type {Array<VehicleGenerationReferenceDto>}
     * @memberof AdminVehicleVisualModelDto
     */
    'generations'?: Array<VehicleGenerationReferenceDto> | null;
    /**
     * 
     * @type {Array<VehicleModificationReferenceDto>}
     * @memberof AdminVehicleVisualModelDto
     */
    'modifications'?: Array<VehicleModificationReferenceDto> | null;
    /**
     * 
     * @type {Array<AdminVehicleVisualModelImageDto>}
     * @memberof AdminVehicleVisualModelDto
     */
    'images'?: Array<AdminVehicleVisualModelImageDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleVisualModelDto
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleVisualModelDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleVisualModelDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleVisualModelDto
     */
    'sortOrder'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleVisualModelDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AdminVehicleVisualModelDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AdminVehicleVisualModelDto
     */
    'vehicleTypesMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AdminVehicleVisualModelDto
     */
    'bodyTypesMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AdminVehicleVisualModelDto
     */
    'makeIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AdminVehicleVisualModelDto
     */
    'modelIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AdminVehicleVisualModelDto
     */
    'generationIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AdminVehicleVisualModelDto
     */
    'modificationIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof AdminVehicleVisualModelDto
     */
    'areas'?: Array<VehicleArea>;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof AdminVehicleVisualModelDto
     */
    'projections'?: Array<VehicleProjection>;
}


/**
 * 
 * @export
 * @interface AdminVehicleVisualModelGetPaginatedDto
 */
export interface AdminVehicleVisualModelGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'type'?: VehicleVisualModelType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {Array<VehicleBodyType>}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'bodyTypes'?: Array<VehicleBodyType> | null;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'areas'?: Array<VehicleArea> | null;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'projections'?: Array<VehicleProjection> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'excludeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleVisualModelGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminVehicleVisualModelImageDto
 */
export interface AdminVehicleVisualModelImageDto {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelImageDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleArea}
     * @memberof AdminVehicleVisualModelImageDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof AdminVehicleVisualModelImageDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {FileReferenceDto}
     * @memberof AdminVehicleVisualModelImageDto
     */
    'file'?: FileReferenceDto;
}


/**
 * 
 * @export
 * @interface AdminVehicleVisualModelSearchPaginatedDto
 */
export interface AdminVehicleVisualModelSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'type'?: VehicleVisualModelType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {Array<VehicleBodyType>}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'bodyTypes'?: Array<VehicleBodyType> | null;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'areas'?: Array<VehicleArea> | null;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'projections'?: Array<VehicleProjection> | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'excludeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'isBuiltIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVehicleVisualModelSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface AdminWebPushChannelInfoDto
 */
export interface AdminWebPushChannelInfoDto {
    /**
     * 
     * @type {Array<AdminSendToUserDto>}
     * @memberof AdminWebPushChannelInfoDto
     */
    'users'?: Array<AdminSendToUserDto>;
    /**
     * 
     * @type {string}
     * @memberof AdminWebPushChannelInfoDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminWebPushChannelInfoDto
     */
    'body'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminWebPushChannelInfoDto
     */
    'imageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface AgreeOrDisagreeWithNegotiationProposalResponseDto
 */
export interface AgreeOrDisagreeWithNegotiationProposalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AgreeOrDisagreeWithNegotiationProposalResponseDto
     */
    'proposalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreeOrDisagreeWithNegotiationProposalResponseDto
     */
    'responseId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AllocationStatus = {
    None: 'None',
    NotAllocated: 'NotAllocated',
    Allocating: 'Allocating',
    Allocated: 'Allocated'
} as const;

export type AllocationStatus = typeof AllocationStatus[keyof typeof AllocationStatus];


/**
 * 
 * @export
 * @interface AmazonSesEmailSettingsDto
 */
export interface AmazonSesEmailSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof AmazonSesEmailSettingsDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonSesEmailSettingsDto
     */
    'region'?: string;
    /**
     * 
     * @type {UserSecretReferenceDto}
     * @memberof AmazonSesEmailSettingsDto
     */
    'accessKeyId'?: UserSecretReferenceDto;
    /**
     * 
     * @type {UserSecretReferenceDto}
     * @memberof AmazonSesEmailSettingsDto
     */
    'secretAccessKey'?: UserSecretReferenceDto;
}
/**
 * 
 * @export
 * @interface AmazonSesEmailSettingsInputDto
 */
export interface AmazonSesEmailSettingsInputDto {
    /**
     * 
     * @type {string}
     * @memberof AmazonSesEmailSettingsInputDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonSesEmailSettingsInputDto
     */
    'region'?: string;
    /**
     * 
     * @type {UserSecretInputDto}
     * @memberof AmazonSesEmailSettingsInputDto
     */
    'accessKeyId'?: UserSecretInputDto;
    /**
     * 
     * @type {UserSecretInputDto}
     * @memberof AmazonSesEmailSettingsInputDto
     */
    'secretAccessKey'?: UserSecretInputDto;
}
/**
 * Describes paid parts of the system that user can access
 * @export
 * @enum {string}
 */

export const AppModule = {
    None: 'None',
    FleetManagment: 'FleetManagment',
    NafAssessment: 'NafAssessment',
    Reports: 'Reports',
    TestFeature1: 'TestFeature1',
    TestFeature2: 'TestFeature2',
    TestPremiumFeature: 'TestPremiumFeature'
} as const;

export type AppModule = typeof AppModule[keyof typeof AppModule];


/**
 * 
 * @export
 * @enum {string}
 */

export const AppPermission = {
    None: 'None',
    AdminAppAccess: 'AdminAppAccess',
    FleetAppAccess: 'FleetAppAccess',
    FleetCustomerAppAccess: 'FleetCustomerAppAccess',
    AdminUserManage: 'AdminUserManage',
    AdminTenantManage: 'AdminTenantManage',
    AdminSubscriptionPlanManage: 'AdminSubscriptionPlanManage',
    AdminSubscriptionManage: 'AdminSubscriptionManage',
    AdminInvoiceManage: 'AdminInvoiceManage',
    AdminNotificationsManage: 'AdminNotificationsManage',
    AdminReferenceDataRead: 'AdminReferenceDataRead',
    AdminReferenceDataManage: 'AdminReferenceDataManage',
    AdminInviteRead: 'AdminInviteRead',
    AdminInviteManage: 'AdminInviteManage',
    AdminDomainEventsManage: 'AdminDomainEventsManage',
    GlobalPermissionRead: 'GlobalPermissionRead',
    GlobalPermissionManage: 'GlobalPermissionManage',
    ScopedPermissionRead: 'ScopedPermissionRead',
    ScopedPermissionManage: 'ScopedPermissionManage',
    TenantPermissionRead: 'TenantPermissionRead',
    TenantPermissionManage: 'TenantPermissionManage',
    GlobalRoleRead: 'GlobalRoleRead',
    GlobalRoleManage: 'GlobalRoleManage',
    GlobalRoleAssignmentManage: 'GlobalRoleAssignmentManage',
    RoleAssignmentManage: 'RoleAssignmentManage',
    TenantRoleRead: 'TenantRoleRead',
    TenantRoleManage: 'TenantRoleManage',
    TenantRead: 'TenantRead',
    TenantCreate: 'TenantCreate',
    TenantManage: 'TenantManage',
    TenantSubscriptionRead: 'TenantSubscriptionRead',
    TenantSubscriptionManage: 'TenantSubscriptionManage',
    TenantConnectionRead: 'TenantConnectionRead',
    TenantConnectionManage: 'TenantConnectionManage',
    TenantRequestRead: 'TenantRequestRead',
    TenantRequestManage: 'TenantRequestManage',
    DataGrantRead: 'DataGrantRead',
    DataGrantManage: 'DataGrantManage',
    ImportRead: 'ImportRead',
    ImportManage: 'ImportManage',
    ExportManage: 'ExportManage',
    InvoiceRead: 'InvoiceRead',
    TenantUserRead: 'TenantUserRead',
    TenantUserManage: 'TenantUserManage',
    TenantInviteRead: 'TenantInviteRead',
    TenantInviteManage: 'TenantInviteManage',
    FleetRead: 'FleetRead',
    FleetManage: 'FleetManage',
    DepartmentRead: 'DepartmentRead',
    DepartmentManage: 'DepartmentManage',
    LocationRead: 'LocationRead',
    LocationManage: 'LocationManage',
    SpotRead: 'SpotRead',
    SpotManage: 'SpotManage',
    ProductRead: 'ProductRead',
    ProductManage: 'ProductManage',
    ProductLocationRead: 'ProductLocationRead',
    ProductLocationManage: 'ProductLocationManage',
    AssetSubscriptionPlanRead: 'AssetSubscriptionPlanRead',
    AssetSubscriptionPlanManage: 'AssetSubscriptionPlanManage',
    AssetSubscriptionRead: 'AssetSubscriptionRead',
    AssetSubscriptionManage: 'AssetSubscriptionManage',
    CustomerRead: 'CustomerRead',
    CustomerManage: 'CustomerManage',
    CustomerContactsRead: 'CustomerContactsRead',
    CustomerContactsManage: 'CustomerContactsManage',
    ContractRead: 'ContractRead',
    ContractManage: 'ContractManage',
    VisualInspectionPerform: 'VisualInspectionPerform',
    VisualInspectionRead: 'VisualInspectionRead',
    VisualInspectionManage: 'VisualInspectionManage',
    DamageDetectionPerform: 'DamageDetectionPerform',
    DamageDetectionRead: 'DamageDetectionRead',
    DamageDetectionManage: 'DamageDetectionManage',
    DamageCostEvaluationPerform: 'DamageCostEvaluationPerform',
    DamageCostEvaluationRead: 'DamageCostEvaluationRead',
    DamageCostEvaluationManage: 'DamageCostEvaluationManage',
    AccessoryRead: 'AccessoryRead',
    AccessoryManage: 'AccessoryManage',
    VehicleRead: 'VehicleRead',
    VehicleManage: 'VehicleManage',
    VehicleHistoryRead: 'VehicleHistoryRead',
    VehicleDamageRead: 'VehicleDamageRead',
    VehicleDamageManage: 'VehicleDamageManage',
    AccessoryCheckPerform: 'AccessoryCheckPerform',
    AccessoryCheckRead: 'AccessoryCheckRead',
    AccessoryCheckManage: 'AccessoryCheckManage',
    RepairOperationPerform: 'RepairOperationPerform',
    RepairOperationRead: 'RepairOperationRead',
    RepairOperationManage: 'RepairOperationManage',
    DocumentRead: 'DocumentRead',
    DocumentManage: 'DocumentManage',
    RepairCatalogRead: 'RepairCatalogRead',
    RepairCatalogManage: 'RepairCatalogManage',
    PoolRead: 'PoolRead',
    PoolManage: 'PoolManage',
    AssetRead: 'AssetRead',
    AssetManage: 'AssetManage',
    CustomTagRead: 'CustomTagRead',
    CustomTagManage: 'CustomTagManage',
    WebhookRead: 'WebhookRead',
    WebhookManage: 'WebhookManage',
    GeneralHistoryRead: 'GeneralHistoryRead',
    GeneralHistoryManage: 'GeneralHistoryManage',
    ChatRead: 'ChatRead',
    ChatManage: 'ChatManage',
    NegotiationRead: 'NegotiationRead',
    NegotiationManage: 'NegotiationManage',
    IntegrationApiClientRead: 'IntegrationApiClientRead',
    IntegrationApiClientManage: 'IntegrationApiClientManage',
    TeslaConnectionRead: 'TeslaConnectionRead',
    TeslaConnectionManage: 'TeslaConnectionManage',
    FlowRead: 'FlowRead',
    FlowManage: 'FlowManage',
    TestRead: 'TestRead',
    TestManage: 'TestManage',
    TestFeature1Access: 'TestFeature1Access',
    TestFeature2Access: 'TestFeature2Access',
    TestPremiumFeatureAccess: 'TestPremiumFeatureAccess'
} as const;

export type AppPermission = typeof AppPermission[keyof typeof AppPermission];


/**
 * 
 * @export
 * @interface ApplyMappingToImportDto
 */
export interface ApplyMappingToImportDto {
    /**
     * 
     * @type {string}
     * @memberof ApplyMappingToImportDto
     */
    'importId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyMappingToImportDto
     */
    'mappingTemplateId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApprovalResponseType = {
    None: 'None',
    Approve: 'Approve',
    Decline: 'Decline',
    ForceApprove: 'ForceApprove',
    ForceDecline: 'ForceDecline'
} as const;

export type ApprovalResponseType = typeof ApprovalResponseType[keyof typeof ApprovalResponseType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApprovalStatus = {
    None: 'None',
    Approved: 'Approved',
    Declined: 'Declined',
    ForceApproved: 'ForceApproved',
    ForceDeclined: 'ForceDeclined'
} as const;

export type ApprovalStatus = typeof ApprovalStatus[keyof typeof ApprovalStatus];


/**
 * 
 * @export
 * @interface AssessmentFlowBriefDto
 */
export interface AssessmentFlowBriefDto {
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowBriefDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowBriefDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowBriefDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowBriefDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowBriefDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowBriefDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof AssessmentFlowBriefDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof AssessmentFlowBriefDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {CustomerReferenceDto}
     * @memberof AssessmentFlowBriefDto
     */
    'customer'?: CustomerReferenceDto;
    /**
     * 
     * @type {AssessmentFlowStateDto}
     * @memberof AssessmentFlowBriefDto
     */
    'state'?: AssessmentFlowStateDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof AssessmentFlowBriefDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowBriefDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowBriefDto
     */
    'damageNegotiationIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowBriefDto
     */
    'damageCostNegotiationIds'?: Array<string>;
    /**
     * 
     * @type {Array<NegotiationReferenceDto>}
     * @memberof AssessmentFlowBriefDto
     */
    'negotiationRefs'?: Array<NegotiationReferenceDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssessmentFlowBriefDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssessmentFlowBriefDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowBriefDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowBriefDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AssessmentFlowBriefDto
     */
    'damageNegotiationIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AssessmentFlowBriefDto
     */
    'damageCostNegotiationIdsMap'?: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface AssessmentFlowChangedDto
 */
export interface AssessmentFlowChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof AssessmentFlowChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof AssessmentFlowChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {AssessmentFlowDto}
     * @memberof AssessmentFlowChangedDto
     */
    'entity'?: AssessmentFlowDto;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface AssessmentFlowCountersDto
 */
export interface AssessmentFlowCountersDto {
    /**
     * 
     * @type {number}
     * @memberof AssessmentFlowCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AssessmentFlowCountersDto
     */
    'countByFilterTypeMap'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface AssessmentFlowDto
 */
export interface AssessmentFlowDto {
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof AssessmentFlowDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof AssessmentFlowDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {CustomerReferenceDto}
     * @memberof AssessmentFlowDto
     */
    'customer'?: CustomerReferenceDto;
    /**
     * 
     * @type {AssessmentFlowStateDto}
     * @memberof AssessmentFlowDto
     */
    'state'?: AssessmentFlowStateDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof AssessmentFlowDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'damageDetectionAggregateId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'damageCostEvaluationAggregateId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowDto
     */
    'accessoryCheckIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowDto
     */
    'damageNegotiationIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowDto
     */
    'damageCostNegotiationIds'?: Array<string>;
    /**
     * 
     * @type {Array<NegotiationReferenceDto>}
     * @memberof AssessmentFlowDto
     */
    'negotiationRefs'?: Array<NegotiationReferenceDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AssessmentFlowDto
     */
    'damageNegotiationIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AssessmentFlowDto
     */
    'damageCostNegotiationIdsMap'?: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface AssessmentFlowGetCountersDto
 */
export interface AssessmentFlowGetCountersDto {
    /**
     * 
     * @type {DateRangeInputDto}
     * @memberof AssessmentFlowGetCountersDto
     */
    'range'?: DateRangeInputDto;
}
/**
 * 
 * @export
 * @interface AssessmentFlowGetPaginatedDto
 */
export interface AssessmentFlowGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {ContractAssessmentFlowStateFilterType}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'filterType'?: ContractAssessmentFlowStateFilterType;
    /**
     * 
     * @type {Array<ContractAssessmentFlowStateFilterType>}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'filterTypes'?: Array<ContractAssessmentFlowStateFilterType> | null;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof AssessmentFlowGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @interface AssessmentFlowReferenceDto
 */
export interface AssessmentFlowReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'contractId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'customerId'?: string;
}
/**
 * 
 * @export
 * @interface AssessmentFlowStateDto
 */
export interface AssessmentFlowStateDto {
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowStateDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentFlowStateDto
     */
    'isProcessingDamages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentFlowStateDto
     */
    'isProcessingDamageCosts'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentFlowStateDto
     */
    'isDamageCostEvaluating'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentFlowStateDto
     */
    'isDamageCostEvaluated'?: boolean;
    /**
     * 
     * @type {DamageDetectionStatusInContract}
     * @memberof AssessmentFlowStateDto
     */
    'damageDetectionStatus'?: DamageDetectionStatusInContract;
    /**
     * 
     * @type {DamageCostEvaluationStatusInContract}
     * @memberof AssessmentFlowStateDto
     */
    'damageCostEvaluationStatus'?: DamageCostEvaluationStatusInContract;
    /**
     * 
     * @type {ContractAssessmentFlowStateFilterType}
     * @memberof AssessmentFlowStateDto
     */
    'filterState'?: ContractAssessmentFlowStateFilterType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssessmentFlowStateDto
     */
    'lastActivityAt'?: string;
}


/**
 * 
 * @export
 * @interface AssetCandidateEntityDto
 */
export interface AssetCandidateEntityDto {
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetCandidateEntityDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {VehicleDto}
     * @memberof AssetCandidateEntityDto
     */
    'vehicle'?: VehicleDto;
    /**
     * 
     * @type {AccessoryDto}
     * @memberof AssetCandidateEntityDto
     */
    'accessory'?: AccessoryDto;
    /**
     * 
     * @type {string}
     * @memberof AssetCandidateEntityDto
     */
    'entityId'?: string;
}


/**
 * 
 * @export
 * @interface AssetCandidateEntityInputDto
 */
export interface AssetCandidateEntityInputDto {
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetCandidateEntityInputDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {string}
     * @memberof AssetCandidateEntityInputDto
     */
    'entityId'?: string;
}


/**
 * 
 * @export
 * @interface AssetChangedDto
 */
export interface AssetChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof AssetChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof AssetChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof AssetChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {AssetDto}
     * @memberof AssetChangedDto
     */
    'entity'?: AssetDto;
    /**
     * 
     * @type {string}
     * @memberof AssetChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface AssetCreateDto
 */
export interface AssetCreateDto {
    /**
     * 
     * @type {AssetCandidateEntityInputDto}
     * @memberof AssetCreateDto
     */
    'entity'?: AssetCandidateEntityInputDto;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {AssetSpecInputDto}
     * @memberof AssetCreateDto
     */
    'spec'?: AssetSpecInputDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetCreateDto
     */
    'accessoryIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof AssetCreateDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {AssetSettingsInputDto}
     * @memberof AssetCreateDto
     */
    'settings'?: AssetSettingsInputDto;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetCreateDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof AssetCreateDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface AssetDto
 */
export interface AssetDto {
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'ownerTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof AssetDto
     */
    'entity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {AssetSpecDto}
     * @memberof AssetDto
     */
    'spec'?: AssetSpecDto;
    /**
     * 
     * @type {Array<AccessoryReferenceDto>}
     * @memberof AssetDto
     */
    'accessories'?: Array<AccessoryReferenceDto> | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AssetDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
    /**
     * 
     * @type {AssetSettingsDto}
     * @memberof AssetDto
     */
    'settings'?: AssetSettingsDto;
    /**
     * 
     * @type {PoolsMetaDto}
     * @memberof AssetDto
     */
    'poolsMeta'?: PoolsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof AssetDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'updatedBy'?: string | null;
}


/**
 * Must contain only values from Domain.Enums.EntityType.
 * @export
 * @enum {string}
 */

export const AssetEntityType = {
    None: 'None',
    Vehicle: 'Vehicle',
    Accessory: 'Accessory'
} as const;

export type AssetEntityType = typeof AssetEntityType[keyof typeof AssetEntityType];


/**
 * 
 * @export
 * @interface AssetFullReferenceDto
 */
export interface AssetFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'ownerTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetFullReferenceDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof AssetFullReferenceDto
     */
    'entity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {AssetSpecDto}
     * @memberof AssetFullReferenceDto
     */
    'spec'?: AssetSpecDto;
    /**
     * 
     * @type {Array<AccessoryReferenceDto>}
     * @memberof AssetFullReferenceDto
     */
    'accessories'?: Array<AccessoryReferenceDto> | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AssetFullReferenceDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetFullReferenceDto
     */
    'poolIds'?: Array<string>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface AssetGetPaginatedDto
 */
export interface AssetGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AssetGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AssetGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AssetGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AssetGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetGetPaginatedDto
     */
    'assetEntityType'?: AssetEntityType;
    /**
     * 
     * @type {PoolsMetaQueryDto}
     * @memberof AssetGetPaginatedDto
     */
    'poolsMeta'?: PoolsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof AssetGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @interface AssetMetaDto
 */
export interface AssetMetaDto {
    /**
     * 
     * @type {string}
     * @memberof AssetMetaDto
     */
    'assetId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetMetaDto
     */
    'hasAsset'?: boolean;
}
/**
 * 
 * @export
 * @interface AssetMetaQueryDto
 */
export interface AssetMetaQueryDto {
    /**
     * 
     * @type {string}
     * @memberof AssetMetaQueryDto
     */
    'assetId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetMetaQueryDto
     */
    'hasAsset'?: boolean | null;
}
/**
 * 
 * @export
 * @interface AssetReferenceDto
 */
export interface AssetReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AssetReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetReferenceDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof AssetReferenceDto
     */
    'entity'?: GeneralStrictEntityRelationDto;
}


/**
 * 
 * @export
 * @interface AssetSearchPaginatedDto
 */
export interface AssetSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AssetSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AssetSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AssetSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AssetSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetSearchPaginatedDto
     */
    'assetEntityType'?: AssetEntityType;
    /**
     * 
     * @type {PoolsMetaQueryDto}
     * @memberof AssetSearchPaginatedDto
     */
    'poolsMeta'?: PoolsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof AssetSearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @interface AssetSettingsDto
 */
export interface AssetSettingsDto {
    /**
     * Whether asset spec should be synced with the source entity spec when it updates.
     * @type {boolean}
     * @memberof AssetSettingsDto
     */
    'isSyncSpec'?: boolean;
}
/**
 * 
 * @export
 * @interface AssetSettingsInputDto
 */
export interface AssetSettingsInputDto {
    /**
     * Whether asset spec should be synced with the source entity spec when it updates.
     * @type {boolean}
     * @memberof AssetSettingsInputDto
     */
    'isSyncSpec'?: boolean;
}
/**
 * 
 * @export
 * @interface AssetSpecDto
 */
export interface AssetSpecDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSpecDto
     */
    'id'?: string;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetSpecDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {VehicleSpecDto}
     * @memberof AssetSpecDto
     */
    'vehicle'?: VehicleSpecDto;
}


/**
 * 
 * @export
 * @interface AssetSpecInputDto
 */
export interface AssetSpecInputDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSpecInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetSpecInputDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {VehicleSpecInputDto}
     * @memberof AssetSpecInputDto
     */
    'vehicle'?: VehicleSpecInputDto;
}


/**
 * 
 * @export
 * @interface AssetSpecQueryDto
 */
export interface AssetSpecQueryDto {
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetSpecQueryDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {VehicleSpecQueryDto}
     * @memberof AssetSpecQueryDto
     */
    'vehicle'?: VehicleSpecQueryDto;
}


/**
 * 
 * @export
 * @interface AssetSubscriptionChangedDto
 */
export interface AssetSubscriptionChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof AssetSubscriptionChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof AssetSubscriptionChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {AssetSubscriptionDto}
     * @memberof AssetSubscriptionChangedDto
     */
    'entity'?: AssetSubscriptionDto;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface AssetSubscriptionCountersDto
 */
export interface AssetSubscriptionCountersDto {
    /**
     * 
     * @type {number}
     * @memberof AssetSubscriptionCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AssetSubscriptionCountersDto
     */
    'countByStatusMap'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AssetSubscriptionCountersDto
     */
    'countByAllocationStatusMap'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface AssetSubscriptionCreateDto
 */
export interface AssetSubscriptionCreateDto {
    /**
     * Number of external contract, agreement, etc.
     * @type {string}
     * @memberof AssetSubscriptionCreateDto
     */
    'externalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionCreateDto
     */
    'customerId'?: string;
    /**
     * 
     * @type {AssetSubscriptionPlanInputDto}
     * @memberof AssetSubscriptionCreateDto
     */
    'plan'?: AssetSubscriptionPlanInputDto;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionCreateDto
     */
    'isConfirmAssetAllocation'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionCreateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionCreateDto
     */
    'locationId'?: string | null;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionDto
 */
export interface AssetSubscriptionDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'externalNumber'?: string | null;
    /**
     * 
     * @type {CustomerReferenceDto}
     * @memberof AssetSubscriptionDto
     */
    'customer'?: CustomerReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof AssetSubscriptionDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionPlanReferenceDto}
     * @memberof AssetSubscriptionDto
     */
    'plan'?: AssetSubscriptionPlanReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'productLocationId'?: string | null;
    /**
     * 
     * @type {AssetSubscriptionSpotInfoDto}
     * @memberof AssetSubscriptionDto
     */
    'checkOutSpotInfo'?: AssetSubscriptionSpotInfoDto;
    /**
     * 
     * @type {AssetSubscriptionSpotInfoDto}
     * @memberof AssetSubscriptionDto
     */
    'checkInSpotInfo'?: AssetSubscriptionSpotInfoDto;
    /**
     * 
     * @type {SubscriptionAssetDto}
     * @memberof AssetSubscriptionDto
     */
    'asset'?: SubscriptionAssetDto;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof AssetSubscriptionDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {SubscriptionPlanPriceDto}
     * @memberof AssetSubscriptionDto
     */
    'basePrice'?: SubscriptionPlanPriceDto;
    /**
     * 
     * @type {SubscriptionPlanPriceDto}
     * @memberof AssetSubscriptionDto
     */
    'price'?: SubscriptionPlanPriceDto;
    /**
     * 
     * @type {SubscriptionPlanDurationDto}
     * @memberof AssetSubscriptionDto
     */
    'duration'?: SubscriptionPlanDurationDto;
    /**
     * 
     * @type {Array<SubscriptionPlanExtraOptionDto>}
     * @memberof AssetSubscriptionDto
     */
    'extraOptions'?: Array<SubscriptionPlanExtraOptionDto> | null;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof AssetSubscriptionDto
     */
    'status'?: SubscriptionStatus;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof AssetSubscriptionDto
     */
    'prevStatus'?: SubscriptionStatus;
    /**
     * 
     * @type {AllocationStatus}
     * @memberof AssetSubscriptionDto
     */
    'allocationStatus'?: AllocationStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'startsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'endsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'draftAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'pendingAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'unpaidAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'activeAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'pastDueAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'endedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'pausedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'canceledAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionDto
     */
    'canReallocate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionDto
     */
    'mustConfirmAllocation'?: boolean;
}


/**
 * 
 * @export
 * @interface AssetSubscriptionGetCountersDto
 */
export interface AssetSubscriptionGetCountersDto {
    /**
     * 
     * @type {DateRangeInputDto}
     * @memberof AssetSubscriptionGetCountersDto
     */
    'range'?: DateRangeInputDto;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionGetPaginatedDto
 */
export interface AssetSubscriptionGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'planId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'assetId'?: string | null;
    /**
     * 
     * @type {ContractProductsSubscriptionsStateFilterType}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'filterType'?: ContractProductsSubscriptionsStateFilterType;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'assetEntityType'?: AssetEntityType;
    /**
     * 
     * @type {Array<AssetEntityType>}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'assetEntityTypes'?: Array<AssetEntityType> | null;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'status'?: SubscriptionStatus;
    /**
     * 
     * @type {Array<SubscriptionStatus>}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'statuses'?: Array<SubscriptionStatus> | null;
    /**
     * 
     * @type {AllocationStatus}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'allocationStatus'?: AllocationStatus;
    /**
     * 
     * @type {Array<AllocationStatus>}
     * @memberof AssetSubscriptionGetPaginatedDto
     */
    'allocationStatuses'?: Array<AllocationStatus> | null;
}


/**
 * 
 * @export
 * @interface AssetSubscriptionPlanAvailabilityDto
 */
export interface AssetSubscriptionPlanAvailabilityDto {
    /**
     * 
     * @type {ProductLocationDto}
     * @memberof AssetSubscriptionPlanAvailabilityDto
     */
    'productLocation'?: ProductLocationDto;
    /**
     * 
     * @type {Array<PoolAvailabilityDto>}
     * @memberof AssetSubscriptionPlanAvailabilityDto
     */
    'perPool'?: Array<PoolAvailabilityDto>;
    /**
     * 
     * @type {number}
     * @memberof AssetSubscriptionPlanAvailabilityDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetSubscriptionPlanAvailabilityDto
     */
    'requestedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetSubscriptionPlanAvailabilityDto
     */
    'otherCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanAvailabilityDto
     */
    'isAnyAvailable'?: boolean;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanChangedDto
 */
export interface AssetSubscriptionPlanChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {AssetSubscriptionPlanDto}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'entity'?: AssetSubscriptionPlanDto;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface AssetSubscriptionPlanCreateDto
 */
export interface AssetSubscriptionPlanCreateDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {Array<AssetSubscriptionPlanProductLocationInputDto>}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'productLocations'?: Array<AssetSubscriptionPlanProductLocationInputDto> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'poolId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'isFree'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {AssetSpecInputDto}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'assetSpec'?: AssetSpecInputDto;
    /**
     * 
     * @type {SubscriptionPlanSettingsInputDto}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'settings'?: SubscriptionPlanSettingsInputDto;
    /**
     * 
     * @type {AssetSubscriptionPlanSettingsInputDto}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'settings2'?: AssetSubscriptionPlanSettingsInputDto;
    /**
     * 
     * @type {GeneralAttachmentInputDto}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'image'?: GeneralAttachmentInputDto;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'images'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {GeneralCountryInputDto}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'country'?: GeneralCountryInputDto;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {SubscriptionPlanPriceInputDto}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'basePrice'?: SubscriptionPlanPriceInputDto;
    /**
     * 
     * @type {Array<SubscriptionPlanPriceInputDto>}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'alternativePrices'?: Array<SubscriptionPlanPriceInputDto> | null;
    /**
     * 
     * @type {Array<SubscriptionPlanDurationInputDto>}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'durations'?: Array<SubscriptionPlanDurationInputDto> | null;
    /**
     * 
     * @type {Array<SubscriptionPlanIncludedOptionInputDto>}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'includedOptions'?: Array<SubscriptionPlanIncludedOptionInputDto> | null;
    /**
     * 
     * @type {Array<SubscriptionPlanExtraOptionInputDto>}
     * @memberof AssetSubscriptionPlanCreateDto
     */
    'extraOptions'?: Array<SubscriptionPlanExtraOptionInputDto> | null;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanDto
 */
export interface AssetSubscriptionPlanDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {Array<AssetSubscriptionPlanProductLocationDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'productLocations'?: Array<AssetSubscriptionPlanProductLocationDto> | null;
    /**
     * 
     * @type {PoolReferenceDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'pool'?: PoolReferenceDto;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanDto
     */
    'isFree'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {AssetSpecDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'assetSpec'?: AssetSpecDto;
    /**
     * 
     * @type {SubscriptionPlanSettingsDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'settings'?: SubscriptionPlanSettingsDto;
    /**
     * 
     * @type {AssetSubscriptionPlanSettingsDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'settings2'?: AssetSubscriptionPlanSettingsDto;
    /**
     * 
     * @type {GeneralAttachmentDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'image'?: GeneralAttachmentDto;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'images'?: Array<GeneralAttachmentDto> | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
    /**
     * 
     * @type {GeneralCountryDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'country'?: GeneralCountryDto;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {SubscriptionPlanPriceDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'basePrice'?: SubscriptionPlanPriceDto;
    /**
     * 
     * @type {Array<SubscriptionPlanPriceDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'alternativePrices'?: Array<SubscriptionPlanPriceDto> | null;
    /**
     * 
     * @type {Array<SubscriptionPlanDurationDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'durations'?: Array<SubscriptionPlanDurationDto> | null;
    /**
     * 
     * @type {Array<SubscriptionPlanIncludedOptionDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'includedOptions'?: Array<SubscriptionPlanIncludedOptionDto> | null;
    /**
     * 
     * @type {Array<SubscriptionPlanExtraOptionDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'extraOptions'?: Array<SubscriptionPlanExtraOptionDto> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanDto
     */
    'poolIds'?: Array<string>;
    /**
     * 
     * @type {Array<SubscriptionPlanPriceDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'prices'?: Array<SubscriptionPlanPriceDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanDto
     */
    'canAddPools'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanDto
     */
    'canRemovePools'?: boolean;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'allAttachments'?: Array<GeneralAttachmentDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanGetPaginatedDto
 */
export interface AssetSubscriptionPlanGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'productLocationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'notForProductLocationId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'isWithAnyProductLocation'?: boolean | null;
    /**
     * 
     * @type {AssetSpecQueryDto}
     * @memberof AssetSubscriptionPlanGetPaginatedDto
     */
    'assetSpec'?: AssetSpecQueryDto;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanInputDto
 */
export interface AssetSubscriptionPlanInputDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanInputDto
     */
    'planId'?: string;
    /**
     * Selected ProductLocation.  Pass null only if user haven\'t selected any ProductLocation.
     * @type {string}
     * @memberof AssetSubscriptionPlanInputDto
     */
    'productLocationId'?: string | null;
    /**
     * 
     * @type {AssetSubscriptionSpotInfoInputDto}
     * @memberof AssetSubscriptionPlanInputDto
     */
    'checkOutSpotInfo'?: AssetSubscriptionSpotInfoInputDto;
    /**
     * 
     * @type {AssetSubscriptionSpotInfoInputDto}
     * @memberof AssetSubscriptionPlanInputDto
     */
    'checkInSpotInfo'?: AssetSubscriptionSpotInfoInputDto;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanInputDto
     */
    'priceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanInputDto
     */
    'durationId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanInputDto
     */
    'extraOptionIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanProductLocationDto
 */
export interface AssetSubscriptionPlanProductLocationDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanProductLocationDto
     */
    'id'?: string;
    /**
     * 
     * @type {ProductLocationReferenceDto}
     * @memberof AssetSubscriptionPlanProductLocationDto
     */
    'productLocation'?: ProductLocationReferenceDto;
    /**
     * 
     * @type {PoolReferenceDto}
     * @memberof AssetSubscriptionPlanProductLocationDto
     */
    'pool'?: PoolReferenceDto;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanProductLocationInputDto
 */
export interface AssetSubscriptionPlanProductLocationInputDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanProductLocationInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanProductLocationInputDto
     */
    'productLocationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanProductLocationInputDto
     */
    'poolId'?: string | null;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanReferenceDto
 */
export interface AssetSubscriptionPlanReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanSearchPaginatedDto
 */
export interface AssetSubscriptionPlanSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'productLocationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'notForProductLocationId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'isWithAnyProductLocation'?: boolean | null;
    /**
     * 
     * @type {AssetSpecQueryDto}
     * @memberof AssetSubscriptionPlanSearchPaginatedDto
     */
    'assetSpec'?: AssetSpecQueryDto;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanSettingsDto
 */
export interface AssetSubscriptionPlanSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanSettingsDto
     */
    'isEnsurePoolBelongToSinglePlan'?: boolean;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanSettingsInputDto
 */
export interface AssetSubscriptionPlanSettingsInputDto {
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanSettingsInputDto
     */
    'isEnsurePoolBelongToSinglePlan'?: boolean;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanUpdateDto
 */
export interface AssetSubscriptionPlanUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {Array<AssetSubscriptionPlanProductLocationInputDto>}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'productLocations'?: Array<AssetSubscriptionPlanProductLocationInputDto> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'poolId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'isFree'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {AssetSpecInputDto}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'assetSpec'?: AssetSpecInputDto;
    /**
     * 
     * @type {SubscriptionPlanSettingsInputDto}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'settings'?: SubscriptionPlanSettingsInputDto;
    /**
     * 
     * @type {AssetSubscriptionPlanSettingsInputDto}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'settings2'?: AssetSubscriptionPlanSettingsInputDto;
    /**
     * 
     * @type {GeneralAttachmentInputDto}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'image'?: GeneralAttachmentInputDto;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'images'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {GeneralCountryInputDto}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'country'?: GeneralCountryInputDto;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {SubscriptionPlanPriceInputDto}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'basePrice'?: SubscriptionPlanPriceInputDto;
    /**
     * 
     * @type {Array<SubscriptionPlanPriceInputDto>}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'alternativePrices'?: Array<SubscriptionPlanPriceInputDto> | null;
    /**
     * 
     * @type {Array<SubscriptionPlanDurationInputDto>}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'durations'?: Array<SubscriptionPlanDurationInputDto> | null;
    /**
     * 
     * @type {Array<SubscriptionPlanIncludedOptionInputDto>}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'includedOptions'?: Array<SubscriptionPlanIncludedOptionInputDto> | null;
    /**
     * 
     * @type {Array<SubscriptionPlanExtraOptionInputDto>}
     * @memberof AssetSubscriptionPlanUpdateDto
     */
    'extraOptions'?: Array<SubscriptionPlanExtraOptionInputDto> | null;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionReallocateAssetDto
 */
export interface AssetSubscriptionReallocateAssetDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionReallocateAssetDto
     */
    'newPoolId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionReallocateAssetDto
     */
    'newPoolItemId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionReallocateAssetDto
     */
    'isConfirmed'?: boolean;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionReferenceDto
 */
export interface AssetSubscriptionReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionSearchPaginatedDto
 */
export interface AssetSubscriptionSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'planId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'assetId'?: string | null;
    /**
     * 
     * @type {ContractProductsSubscriptionsStateFilterType}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'filterType'?: ContractProductsSubscriptionsStateFilterType;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'assetEntityType'?: AssetEntityType;
    /**
     * 
     * @type {Array<AssetEntityType>}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'assetEntityTypes'?: Array<AssetEntityType> | null;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'status'?: SubscriptionStatus;
    /**
     * 
     * @type {Array<SubscriptionStatus>}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'statuses'?: Array<SubscriptionStatus> | null;
    /**
     * 
     * @type {AllocationStatus}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'allocationStatus'?: AllocationStatus;
    /**
     * 
     * @type {Array<AllocationStatus>}
     * @memberof AssetSubscriptionSearchPaginatedDto
     */
    'allocationStatuses'?: Array<AllocationStatus> | null;
}


/**
 * 
 * @export
 * @interface AssetSubscriptionSpotInfoDto
 */
export interface AssetSubscriptionSpotInfoDto {
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionSpotInfoDto
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSpotInfoDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSpotInfoDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSpotInfoDto
     */
    'productLocationId'?: string | null;
    /**
     * 
     * @type {SpotFullReferenceDto}
     * @memberof AssetSubscriptionSpotInfoDto
     */
    'spot'?: SpotFullReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSpotInfoDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionSpotInfoInputDto
 */
export interface AssetSubscriptionSpotInfoInputDto {
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionSpotInfoInputDto
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSpotInfoInputDto
     */
    'spotId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSpotInfoInputDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionUpdateDto
 */
export interface AssetSubscriptionUpdateDto {
    /**
     * Number of external contract, agreement, etc.
     * @type {string}
     * @memberof AssetSubscriptionUpdateDto
     */
    'externalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionUpdateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionUpdateDto
     */
    'locationId'?: string | null;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionUpdateStatusDto
 */
export interface AssetSubscriptionUpdateStatusDto {
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof AssetSubscriptionUpdateStatusDto
     */
    'newStatus'?: SubscriptionStatus;
}


/**
 * 
 * @export
 * @interface AssetUpdateDto
 */
export interface AssetUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof AssetUpdateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {AssetSpecInputDto}
     * @memberof AssetUpdateDto
     */
    'spec'?: AssetSpecInputDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetUpdateDto
     */
    'accessoryIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof AssetUpdateDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {AssetSettingsInputDto}
     * @memberof AssetUpdateDto
     */
    'settings'?: AssetSettingsInputDto;
    /**
     * 
     * @type {string}
     * @memberof AssetUpdateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetUpdateDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof AssetUpdateDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface AttachmentsUpdateDto
 */
export interface AttachmentsUpdateDto {
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof AttachmentsUpdateDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
}
/**
 * 
 * @export
 * @interface AuthorizationProfileDto
 */
export interface AuthorizationProfileDto {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationProfileDto
     */
    'authorizationState'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationProfileDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationProfileDto
     */
    'tenantIdentifier'?: string | null;
    /**
     * 
     * @type {Array<ProfileDepartmentDto>}
     * @memberof AuthorizationProfileDto
     */
    'departments'?: Array<ProfileDepartmentDto>;
    /**
     * 
     * @type {Array<AppPermission>}
     * @memberof AuthorizationProfileDto
     */
    'permissions'?: Array<AppPermission>;
}
/**
 * Describes a way of creating something in terms of automation.
 * @export
 * @enum {string}
 */

export const AutomationCreateMode = {
    None: 'None',
    Manual: 'Manual',
    Automatic: 'Automatic'
} as const;

export type AutomationCreateMode = typeof AutomationCreateMode[keyof typeof AutomationCreateMode];


/**
 * 
 * @export
 * @interface AzureCsEmailSettingsDto
 */
export interface AzureCsEmailSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof AzureCsEmailSettingsDto
     */
    'from'?: string;
    /**
     * 
     * @type {UserSecretReferenceDto}
     * @memberof AzureCsEmailSettingsDto
     */
    'connectionString'?: UserSecretReferenceDto;
}
/**
 * 
 * @export
 * @interface AzureCsEmailSettingsInputDto
 */
export interface AzureCsEmailSettingsInputDto {
    /**
     * 
     * @type {string}
     * @memberof AzureCsEmailSettingsInputDto
     */
    'from'?: string;
    /**
     * 
     * @type {UserSecretInputDto}
     * @memberof AzureCsEmailSettingsInputDto
     */
    'connectionString'?: UserSecretInputDto;
}
/**
 * 
 * @export
 * @interface BaseEntityChangedDto
 */
export interface BaseEntityChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseEntityChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof BaseEntityChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof BaseEntityChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {string}
     * @memberof BaseEntityChangedDto
     */
    'entityId'?: string;
}


/**
 * 
 * @export
 * @interface BaseInAppNotificationDataDto
 */
export interface BaseInAppNotificationDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof BaseInAppNotificationDataDto
     */
    'type'?: InAppNotificationType;
}


/**
 * 
 * @export
 * @interface BaseSignalRServerDto
 */
export interface BaseSignalRServerDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseSignalRServerDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof BaseSignalRServerDto
     */
    'groupName'?: string | null;
}
/**
 * Describes time period of billing for goods.  In the end of every billing period, customer should pay.
 * @export
 * @enum {string}
 */

export const BillingPeriod = {
    None: 'None',
    Month: 'Month',
    Year: 'Year'
} as const;

export type BillingPeriod = typeof BillingPeriod[keyof typeof BillingPeriod];


/**
 * 
 * @export
 * @interface CascadeActionInputDto
 */
export interface CascadeActionInputDto {
    /**
     * 
     * @type {EntityTypeEntityIdPairInputDto}
     * @memberof CascadeActionInputDto
     */
    'baseEntity'?: EntityTypeEntityIdPairInputDto;
    /**
     * 
     * @type {Array<EntityType>}
     * @memberof CascadeActionInputDto
     */
    'relatedEntitiesTypes'?: Array<EntityType> | null;
    /**
     * 
     * @type {CascadeActionParametersInputDto}
     * @memberof CascadeActionInputDto
     */
    'parameters'?: CascadeActionParametersInputDto;
}
/**
 * 
 * @export
 * @interface CascadeActionParametersInputDto
 */
export interface CascadeActionParametersInputDto {
    /**
     * 
     * @type {UpdateEntityAffiliationParametersInputDto}
     * @memberof CascadeActionParametersInputDto
     */
    'updateEntityAffiliation'?: UpdateEntityAffiliationParametersInputDto;
}
/**
 * 
 * @export
 * @interface CascadeActionSpecDto
 */
export interface CascadeActionSpecDto {
    /**
     * 
     * @type {CascadeActionType}
     * @memberof CascadeActionSpecDto
     */
    'type'?: CascadeActionType;
    /**
     * 
     * @type {EntityType}
     * @memberof CascadeActionSpecDto
     */
    'baseEntityType'?: EntityType;
    /**
     * 
     * @type {Array<EntityType>}
     * @memberof CascadeActionSpecDto
     */
    'relatedEntitiesTypes'?: Array<EntityType>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CascadeActionType = {
    None: 'None',
    UpdateEntityAffiliation: 'UpdateEntityAffiliation',
    DeleteEntity: 'DeleteEntity'
} as const;

export type CascadeActionType = typeof CascadeActionType[keyof typeof CascadeActionType];


/**
 * 
 * @export
 * @interface ChatAcknowledgeResultDto
 */
export interface ChatAcknowledgeResultDto {
    /**
     * 
     * @type {ChatDto}
     * @memberof ChatAcknowledgeResultDto
     */
    'chat'?: ChatDto;
    /**
     * 
     * @type {ChatParticipantDto}
     * @memberof ChatAcknowledgeResultDto
     */
    'acknowledgedByParticipant'?: ChatParticipantDto;
}
/**
 * 
 * @export
 * @interface ChatAcknowledgedDto
 */
export interface ChatAcknowledgedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatAcknowledgedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatAcknowledgedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatAcknowledgedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatAcknowledgedDto
     */
    'participantId'?: string;
    /**
     * 
     * @type {ChatMessageAcknowledgementDto}
     * @memberof ChatAcknowledgedDto
     */
    'acknowledgement'?: ChatMessageAcknowledgementDto;
}
/**
 * 
 * @export
 * @interface ChatAcknowledgedInAppDataDto
 */
export interface ChatAcknowledgedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatAcknowledgedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatAcknowledgedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatAcknowledgedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatAcknowledgedInAppDataDto
     */
    'acknowledgedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ChatActivityCategory = {
    None: 'None',
    Chat: 'Chat',
    Message: 'Message',
    Negotiation: 'Negotiation'
} as const;

export type ChatActivityCategory = typeof ChatActivityCategory[keyof typeof ChatActivityCategory];


/**
 * 
 * @export
 * @interface ChatActivityChangedDto
 */
export interface ChatActivityChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatActivityChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatActivityChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatActivityChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {ChatActivityDto}
     * @memberof ChatActivityChangedDto
     */
    'entity'?: ChatActivityDto;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface ChatActivityDto
 */
export interface ChatActivityDto {
    /**
     * 
     * @type {string}
     * @memberof ChatActivityDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatEventReferenceDto}
     * @memberof ChatActivityDto
     */
    'createdFromEvent'?: ChatEventReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityDto
     */
    'messageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityDto
     */
    'participantId'?: string | null;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof ChatActivityDto
     */
    'performedBy'?: GeneralByWhoDto;
    /**
     * 
     * @type {ChatActivityCategory}
     * @memberof ChatActivityDto
     */
    'category'?: ChatActivityCategory;
    /**
     * 
     * @type {ChatActivityType}
     * @memberof ChatActivityDto
     */
    'type'?: ChatActivityType;
    /**
     * 
     * @type {boolean}
     * @memberof ChatActivityDto
     */
    'isSystem'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityDto
     */
    'performedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityDto
     */
    'createdAt'?: string;
}


/**
 * 
 * @export
 * @interface ChatActivityGetPaginatedDto
 */
export interface ChatActivityGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof ChatActivityGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatActivityGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatActivityGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatActivityGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatActivityGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof ChatActivityGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof ChatActivityGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof ChatActivityGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {ChatActivityCategory}
     * @memberof ChatActivityGetPaginatedDto
     */
    'category'?: ChatActivityCategory;
    /**
     * 
     * @type {Array<ChatActivityCategory>}
     * @memberof ChatActivityGetPaginatedDto
     */
    'categories'?: Array<ChatActivityCategory> | null;
    /**
     * 
     * @type {ChatActivityType}
     * @memberof ChatActivityGetPaginatedDto
     */
    'type'?: ChatActivityType;
    /**
     * 
     * @type {Array<ChatActivityType>}
     * @memberof ChatActivityGetPaginatedDto
     */
    'types'?: Array<ChatActivityType> | null;
}


/**
 * 
 * @export
 * @interface ChatActivityOverviewDto
 */
export interface ChatActivityOverviewDto {
    /**
     * 
     * @type {string}
     * @memberof ChatActivityOverviewDto
     */
    'chatId'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityOverviewDto
     */
    'lastActivityAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityOverviewDto
     */
    'lastEventAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityOverviewDto
     */
    'lastMessageAt'?: string | null;
    /**
     * 
     * @type {Array<ChatActivityOverviewParticipantInfoDto>}
     * @memberof ChatActivityOverviewDto
     */
    'participants'?: Array<ChatActivityOverviewParticipantInfoDto>;
    /**
     * 
     * @type {ChatActivityOverviewParticipantInfoDto}
     * @memberof ChatActivityOverviewDto
     */
    'currentParticipant'?: ChatActivityOverviewParticipantInfoDto;
}
/**
 * 
 * @export
 * @interface ChatActivityOverviewParticipantInfoDto
 */
export interface ChatActivityOverviewParticipantInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ChatActivityOverviewParticipantInfoDto
     */
    'participantId'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityOverviewParticipantInfoDto
     */
    'lastActivityAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityOverviewParticipantInfoDto
     */
    'lastEventAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityOverviewParticipantInfoDto
     */
    'lastMessageAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ChatActivityPerformedDto
 */
export interface ChatActivityPerformedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatActivityPerformedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityPerformedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityPerformedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityPerformedDto
     */
    'participantId'?: string | null;
    /**
     * 
     * @type {ChatActivityDto}
     * @memberof ChatActivityPerformedDto
     */
    'activity'?: ChatActivityDto;
    /**
     * 
     * @type {ChatActivityOverviewDto}
     * @memberof ChatActivityPerformedDto
     */
    'activityOverview'?: ChatActivityOverviewDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChatActivityType = {
    None: 'None',
    ChatAcknowledged: 'ChatAcknowledged',
    MessageSent: 'MessageSent',
    MessageUpdated: 'MessageUpdated',
    MessageDeleted: 'MessageDeleted',
    MessageAcknowledged: 'MessageAcknowledged',
    NegotiationProposalResponseGiven: 'NegotiationProposalResponseGiven'
} as const;

export type ChatActivityType = typeof ChatActivityType[keyof typeof ChatActivityType];


/**
 * 
 * @export
 * @interface ChatChangedDto
 */
export interface ChatChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {ChatDto}
     * @memberof ChatChangedDto
     */
    'entity'?: ChatDto;
    /**
     * 
     * @type {string}
     * @memberof ChatChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface ChatDto
 */
export interface ChatDto {
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'localNumber'?: string | null;
    /**
     * 
     * @type {GeneralScopeDto}
     * @memberof ChatDto
     */
    'scope'?: GeneralScopeDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof ChatDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {Array<NegotiationReferenceDto>}
     * @memberof ChatDto
     */
    'negotiations'?: Array<NegotiationReferenceDto>;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatDto
     */
    'type'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {ChatSettingsDto}
     * @memberof ChatDto
     */
    'settings'?: ChatSettingsDto;
    /**
     * 
     * @type {boolean}
     * @memberof ChatDto
     */
    'isResolved'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatDto
     */
    'resolvedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'resolvedByParticipantId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatDto
     */
    'reopenedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'reopenedByParticipantId'?: string | null;
    /**
     * 
     * @type {Array<ChatParticipantReferenceDto>}
     * @memberof ChatDto
     */
    'participants'?: Array<ChatParticipantReferenceDto>;
    /**
     * 
     * @type {number}
     * @memberof ChatDto
     */
    'pinnedItemCount'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatDto
     */
    'isScoped'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatDto
     */
    'isRestrictedParticipants'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ChatEventCategory = {
    None: 'None',
    Chat: 'Chat',
    Participant: 'Participant',
    Message: 'Message',
    Negotiation: 'Negotiation'
} as const;

export type ChatEventCategory = typeof ChatEventCategory[keyof typeof ChatEventCategory];


/**
 * 
 * @export
 * @interface ChatEventChangedDto
 */
export interface ChatEventChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatEventChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatEventChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatEventChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatEventChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatEventChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {ChatEventDto}
     * @memberof ChatEventChangedDto
     */
    'entity'?: ChatEventDto;
    /**
     * 
     * @type {string}
     * @memberof ChatEventChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface ChatEventDto
 */
export interface ChatEventDto {
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'messageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'participantId'?: string | null;
    /**
     * 
     * @type {ChatEventCategory}
     * @memberof ChatEventDto
     */
    'category'?: ChatEventCategory;
    /**
     * 
     * @type {ChatEventType}
     * @memberof ChatEventDto
     */
    'type'?: ChatEventType;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof ChatEventDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'body'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatEventDto
     */
    'raisedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatEventDto
     */
    'createdAt'?: string;
}


/**
 * 
 * @export
 * @interface ChatEventGetPaginatedDto
 */
export interface ChatEventGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof ChatEventGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatEventGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChatEventGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatEventGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatEventGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatEventGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof ChatEventGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof ChatEventGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof ChatEventGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {ChatEventCategory}
     * @memberof ChatEventGetPaginatedDto
     */
    'category'?: ChatEventCategory;
    /**
     * 
     * @type {Array<ChatEventCategory>}
     * @memberof ChatEventGetPaginatedDto
     */
    'categories'?: Array<ChatEventCategory> | null;
    /**
     * 
     * @type {Array<ChatEventCategory>}
     * @memberof ChatEventGetPaginatedDto
     */
    'includeCategories'?: Array<ChatEventCategory> | null;
    /**
     * 
     * @type {Array<ChatEventCategory>}
     * @memberof ChatEventGetPaginatedDto
     */
    'excludeCategories'?: Array<ChatEventCategory> | null;
    /**
     * 
     * @type {ChatEventType}
     * @memberof ChatEventGetPaginatedDto
     */
    'type'?: ChatEventType;
    /**
     * 
     * @type {Array<ChatEventType>}
     * @memberof ChatEventGetPaginatedDto
     */
    'types'?: Array<ChatEventType> | null;
    /**
     * 
     * @type {Array<ChatEventType>}
     * @memberof ChatEventGetPaginatedDto
     */
    'includeTypes'?: Array<ChatEventType> | null;
    /**
     * 
     * @type {Array<ChatEventType>}
     * @memberof ChatEventGetPaginatedDto
     */
    'excludeTypes'?: Array<ChatEventType> | null;
}


/**
 * 
 * @export
 * @interface ChatEventReceivedDto
 */
export interface ChatEventReceivedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatEventReceivedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatEventReceivedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatEventReceivedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatEventDto}
     * @memberof ChatEventReceivedDto
     */
    'event'?: ChatEventDto;
}
/**
 * 
 * @export
 * @interface ChatEventReferenceDto
 */
export interface ChatEventReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof ChatEventReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatEventReferenceDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatEventCategory}
     * @memberof ChatEventReferenceDto
     */
    'category'?: ChatEventCategory;
    /**
     * 
     * @type {ChatEventType}
     * @memberof ChatEventReferenceDto
     */
    'type'?: ChatEventType;
    /**
     * 
     * @type {string}
     * @memberof ChatEventReferenceDto
     */
    'body'?: string;
}


/**
 * NB: Web FE reads   Common.Attributes.EnumLinkedToOneAttribute`1 and  Common.Attributes.EnumLinkedToManyAttribute`1.
 * @export
 * @enum {string}
 */

export const ChatEventType = {
    None: 'None',
    ChatCreated: 'ChatCreated',
    ChatUpdated: 'ChatUpdated',
    ChatDeleted: 'ChatDeleted',
    ChatResolved: 'ChatResolved',
    ChatReopened: 'ChatReopened',
    ChatAcknowledged: 'ChatAcknowledged',
    ChatHistoryItemPinned: 'ChatHistoryItemPinned',
    ChatHistoryItemUnpinned: 'ChatHistoryItemUnpinned',
    ParticipantJoined: 'ParticipantJoined',
    ParticipantLeft: 'ParticipantLeft',
    ParticipantAdded: 'ParticipantAdded',
    ParticipantRemoved: 'ParticipantRemoved',
    MessageSent: 'MessageSent',
    MessageUpdated: 'MessageUpdated',
    MessageDeleted: 'MessageDeleted',
    MessageAcknowledged: 'MessageAcknowledged',
    NegotiationCreated: 'NegotiationCreated',
    NegotiationUpdated: 'NegotiationUpdated',
    NegotiationDeleted: 'NegotiationDeleted',
    NegotiationOpened: 'NegotiationOpened',
    NegotiationReopened: 'NegotiationReopened',
    NegotiationProposalCreated: 'NegotiationProposalCreated',
    NegotiationProposalUpdated: 'NegotiationProposalUpdated',
    NegotiationProposalResponseGiven: 'NegotiationProposalResponseGiven',
    NegotiationProposalClosed: 'NegotiationProposalClosed',
    NegotiationResolved: 'NegotiationResolved'
} as const;

export type ChatEventType = typeof ChatEventType[keyof typeof ChatEventType];


/**
 * 
 * @export
 * @interface ChatHistoryEventsRequestDto
 */
export interface ChatHistoryEventsRequestDto {
    /**
     * 
     * @type {Array<ChatEventCategory>}
     * @memberof ChatHistoryEventsRequestDto
     */
    'categories'?: Array<ChatEventCategory> | null;
    /**
     * 
     * @type {Array<ChatEventCategory>}
     * @memberof ChatHistoryEventsRequestDto
     */
    'includeCategories'?: Array<ChatEventCategory> | null;
    /**
     * 
     * @type {Array<ChatEventCategory>}
     * @memberof ChatHistoryEventsRequestDto
     */
    'excludeCategories'?: Array<ChatEventCategory> | null;
    /**
     * 
     * @type {Array<ChatEventType>}
     * @memberof ChatHistoryEventsRequestDto
     */
    'types'?: Array<ChatEventType> | null;
    /**
     * 
     * @type {Array<ChatEventType>}
     * @memberof ChatHistoryEventsRequestDto
     */
    'includeTypes'?: Array<ChatEventType> | null;
    /**
     * 
     * @type {Array<ChatEventType>}
     * @memberof ChatHistoryEventsRequestDto
     */
    'excludeTypes'?: Array<ChatEventType> | null;
}
/**
 * 
 * @export
 * @interface ChatHistoryGetPaginatedDto
 */
export interface ChatHistoryGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof ChatHistoryGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatHistoryGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatHistoryGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatHistoryGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatHistoryGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof ChatHistoryGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof ChatHistoryGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof ChatHistoryGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {ChatHistoryEventsRequestDto}
     * @memberof ChatHistoryGetPaginatedDto
     */
    'event'?: ChatHistoryEventsRequestDto;
}
/**
 * 
 * @export
 * @interface ChatHistoryItemChangedDto
 */
export interface ChatHistoryItemChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatHistoryItemChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatHistoryItemChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatHistoryItemChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {ChatHistoryItemDto}
     * @memberof ChatHistoryItemChangedDto
     */
    'entity'?: ChatHistoryItemDto;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface ChatHistoryItemCreatedDto
 */
export interface ChatHistoryItemCreatedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatHistoryItemCreatedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemCreatedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemCreatedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatHistoryItemDto}
     * @memberof ChatHistoryItemCreatedDto
     */
    'item'?: ChatHistoryItemDto;
}
/**
 * 
 * @export
 * @interface ChatHistoryItemDeletedDto
 */
export interface ChatHistoryItemDeletedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatHistoryItemDeletedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDeletedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDeletedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDeletedDto
     */
    'itemId'?: string;
}
/**
 * 
 * @export
 * @interface ChatHistoryItemDto
 */
export interface ChatHistoryItemDto {
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'participantId'?: string | null;
    /**
     * 
     * @type {ChatHistoryItemType}
     * @memberof ChatHistoryItemDto
     */
    'type'?: ChatHistoryItemType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'date'?: string;
    /**
     * 
     * @type {ChatMessageDto}
     * @memberof ChatHistoryItemDto
     */
    'message'?: ChatMessageDto;
    /**
     * 
     * @type {ChatEventDto}
     * @memberof ChatHistoryItemDto
     */
    'event'?: ChatEventDto;
    /**
     * 
     * @type {NegotiationProposalDto}
     * @memberof ChatHistoryItemDto
     */
    'negotiationProposal'?: NegotiationProposalDto;
    /**
     * 
     * @type {boolean}
     * @memberof ChatHistoryItemDto
     */
    'isPinned'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'pinnedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'pinnedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'pinnedByParticipant'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'updatedAt'?: string;
}


/**
 * 
 * @export
 * @interface ChatHistoryItemPinStatusChangedDto
 */
export interface ChatHistoryItemPinStatusChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatHistoryItemPinStatusChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemPinStatusChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemPinStatusChangedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatHistoryItemDto}
     * @memberof ChatHistoryItemPinStatusChangedDto
     */
    'item'?: ChatHistoryItemDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChatHistoryItemType = {
    None: 'None',
    ChatMessage: 'ChatMessage',
    ChatEvent: 'ChatEvent',
    NegotiationProposal: 'NegotiationProposal'
} as const;

export type ChatHistoryItemType = typeof ChatHistoryItemType[keyof typeof ChatHistoryItemType];


/**
 * 
 * @export
 * @interface ChatHistoryItemUpdatedDto
 */
export interface ChatHistoryItemUpdatedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatHistoryItemUpdatedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemUpdatedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemUpdatedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatHistoryItemDto}
     * @memberof ChatHistoryItemUpdatedDto
     */
    'item'?: ChatHistoryItemDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChatHubClientMethodName = {
    TestNotify: 'TestNotify',
    Broadcast: 'Broadcast',
    Echo: 'Echo',
    ServerErrorOccurred: 'ServerErrorOccurred',
    ChatActivityPerformed: 'ChatActivityPerformed',
    ChatUpdated: 'ChatUpdated',
    ChatResolved: 'ChatResolved',
    ChatReopened: 'ChatReopened',
    ChatAcknowledged: 'ChatAcknowledged',
    UserAddedToChat: 'UserAddedToChat',
    ParticipantConnected: 'ParticipantConnected',
    ParticipantDisconnected: 'ParticipantDisconnected',
    ParticipantStatusUpdated: 'ParticipantStatusUpdated',
    ParticipantJoined: 'ParticipantJoined',
    ParticipantLeft: 'ParticipantLeft',
    ParticipantsAdded: 'ParticipantsAdded',
    ParticipantRemoved: 'ParticipantRemoved',
    MessageSent: 'MessageSent',
    MessageUpdated: 'MessageUpdated',
    MessageDeleted: 'MessageDeleted',
    MessageAcknowledged: 'MessageAcknowledged',
    MessagesRead: 'MessagesRead',
    ChatEventReceived: 'ChatEventReceived',
    ChatHistoryItemCreated: 'ChatHistoryItemCreated',
    ChatHistoryItemUpdated: 'ChatHistoryItemUpdated',
    ChatHistoryItemDeleted: 'ChatHistoryItemDeleted',
    ChatHistoryItemPinStatusChanged: 'ChatHistoryItemPinStatusChanged'
} as const;

export type ChatHubClientMethodName = typeof ChatHubClientMethodName[keyof typeof ChatHubClientMethodName];


/**
 * 
 * @export
 * @enum {string}
 */

export const ChatHubServerMethodName = {
    Echo: 'Echo',
    ConnectToChat: 'ConnectToChat',
    DisconnectFromChat: 'DisconnectFromChat',
    ReportMyChatParticipantStatus: 'ReportMyChatParticipantStatus',
    SubscribeOnChatActivityUpdates: 'SubscribeOnChatActivityUpdates',
    UnsubscribeFromChatActivityUpdates: 'UnsubscribeFromChatActivityUpdates'
} as const;

export type ChatHubServerMethodName = typeof ChatHubServerMethodName[keyof typeof ChatHubServerMethodName];


/**
 * 
 * @export
 * @interface ChatMessageAcknowledgedDto
 */
export interface ChatMessageAcknowledgedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageAcknowledgedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgedDto
     */
    'messageId'?: string;
    /**
     * 
     * @type {ChatMessageAcknowledgementDto}
     * @memberof ChatMessageAcknowledgedDto
     */
    'acknowledgement'?: ChatMessageAcknowledgementDto;
}
/**
 * 
 * @export
 * @interface ChatMessageAcknowledgedInAppDataDto
 */
export interface ChatMessageAcknowledgedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatMessageAcknowledgedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatMessageAcknowledgedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgedInAppDataDto
     */
    'acknowledgedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @interface ChatMessageAcknowledgementDto
 */
export interface ChatMessageAcknowledgementDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgementDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgementDto
     */
    'participantId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessageAcknowledgementDto
     */
    'isAcknowledged'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatMessageAcknowledgementDto
     */
    'acknowledgedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ChatMessageChangedDto
 */
export interface ChatMessageChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatMessageChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatMessageChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {ChatMessageDto}
     * @memberof ChatMessageChangedDto
     */
    'entity'?: ChatMessageDto;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface ChatMessageDeletedDto
 */
export interface ChatMessageDeletedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageDeletedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDeletedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDeletedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDeletedDto
     */
    'messageId'?: string;
}
/**
 * 
 * @export
 * @interface ChatMessageDto
 */
export interface ChatMessageDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof ChatMessageDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'participantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'body'?: string | null;
    /**
     * 
     * @type {ChatMessageRootNodeDto}
     * @memberof ChatMessageDto
     */
    'rootNode'?: ChatMessageRootNodeDto;
    /**
     * 
     * @type {Array<ChatMessageAcknowledgementDto>}
     * @memberof ChatMessageDto
     */
    'acknowledgements'?: Array<ChatMessageAcknowledgementDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof ChatMessageDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {Array<ChatMessageReadByInfoDto>}
     * @memberof ChatMessageDto
     */
    'readByInfo'?: Array<ChatMessageReadByInfoDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatMessageDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof ChatMessageDto
     */
    'bodyTags'?: GeneralAttachedTagsDto;
}
/**
 * 
 * @export
 * @interface ChatMessageGetPaginatedDto
 */
export interface ChatMessageGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof ChatMessageGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatMessageGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof ChatMessageGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof ChatMessageGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof ChatMessageGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
}
/**
 * 
 * @export
 * @interface ChatMessageMentionNodeDataInputDto
 */
export interface ChatMessageMentionNodeDataInputDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageMentionNodeDataInputDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageMentionNodeDataInputDto
     */
    'participantId'?: string | null;
}
/**
 * 
 * @export
 * @interface ChatMessageNodeDto
 */
export interface ChatMessageNodeDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageNodeDto
     */
    'id'?: string;
    /**
     * 
     * @type {ChatMessageNodeType}
     * @memberof ChatMessageNodeDto
     */
    'type'?: ChatMessageNodeType;
    /**
     * 
     * @type {number}
     * @memberof ChatMessageNodeDto
     */
    'startIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatMessageNodeDto
     */
    'endIndex'?: number;
    /**
     * 
     * @type {ChatMessageTextNodeDataDto}
     * @memberof ChatMessageNodeDto
     */
    'text'?: ChatMessageTextNodeDataDto;
    /**
     * 
     * @type {ChatMessageWebLinkNodeDataDto}
     * @memberof ChatMessageNodeDto
     */
    'webLink'?: ChatMessageWebLinkNodeDataDto;
    /**
     * 
     * @type {ChatMessageTagNodeDataDto}
     * @memberof ChatMessageNodeDto
     */
    'tag'?: ChatMessageTagNodeDataDto;
}


/**
 * 
 * @export
 * @interface ChatMessageNodeInputDto
 */
export interface ChatMessageNodeInputDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageNodeInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {ChatMessageNodeType}
     * @memberof ChatMessageNodeInputDto
     */
    'type'?: ChatMessageNodeType;
    /**
     * 
     * @type {number}
     * @memberof ChatMessageNodeInputDto
     */
    'startIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatMessageNodeInputDto
     */
    'endIndex'?: number;
    /**
     * 
     * @type {ChatMessageTextNodeDataInputDto}
     * @memberof ChatMessageNodeInputDto
     */
    'text'?: ChatMessageTextNodeDataInputDto;
    /**
     * 
     * @type {ChatMessageMentionNodeDataInputDto}
     * @memberof ChatMessageNodeInputDto
     */
    'mention'?: ChatMessageMentionNodeDataInputDto;
    /**
     * 
     * @type {ChatMessageWebLinkNodeDataInputDto}
     * @memberof ChatMessageNodeInputDto
     */
    'webLink'?: ChatMessageWebLinkNodeDataInputDto;
    /**
     * 
     * @type {ChatMessageTagNodeDataInputDto}
     * @memberof ChatMessageNodeInputDto
     */
    'tag'?: ChatMessageTagNodeDataInputDto;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ChatMessageNodeType = {
    None: 'None',
    Root: 'Root',
    Text: 'Text',
    WebLink: 'WebLink',
    Tag: 'Tag'
} as const;

export type ChatMessageNodeType = typeof ChatMessageNodeType[keyof typeof ChatMessageNodeType];


/**
 * 
 * @export
 * @interface ChatMessageReadByInfoDto
 */
export interface ChatMessageReadByInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageReadByInfoDto
     */
    'participantId'?: string;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof ChatMessageReadByInfoDto
     */
    'who'?: GeneralByWhoDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatMessageReadByInfoDto
     */
    'readAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageReadByInfoDto
     */
    'computedId'?: string | null;
}
/**
 * 
 * @export
 * @interface ChatMessageRootNodeDto
 */
export interface ChatMessageRootNodeDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageRootNodeDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<ChatMessageNodeDto>}
     * @memberof ChatMessageRootNodeDto
     */
    'nodes'?: Array<ChatMessageNodeDto>;
}
/**
 * 
 * @export
 * @interface ChatMessageRootNodeInputDto
 */
export interface ChatMessageRootNodeInputDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageRootNodeInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<ChatMessageNodeInputDto>}
     * @memberof ChatMessageRootNodeInputDto
     */
    'nodes'?: Array<ChatMessageNodeInputDto>;
}
/**
 * 
 * @export
 * @interface ChatMessageSentDto
 */
export interface ChatMessageSentDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageSentDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageSentDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageSentDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatMessageDto}
     * @memberof ChatMessageSentDto
     */
    'message'?: ChatMessageDto;
}
/**
 * 
 * @export
 * @interface ChatMessageTagNodeDataDto
 */
export interface ChatMessageTagNodeDataDto {
    /**
     * 
     * @type {GeneralTagDto}
     * @memberof ChatMessageTagNodeDataDto
     */
    'tag'?: GeneralTagDto;
}
/**
 * 
 * @export
 * @interface ChatMessageTagNodeDataInputDto
 */
export interface ChatMessageTagNodeDataInputDto {
    /**
     * 
     * @type {GeneralTagInputDto}
     * @memberof ChatMessageTagNodeDataInputDto
     */
    'tag'?: GeneralTagInputDto;
}
/**
 * 
 * @export
 * @interface ChatMessageTextNodeDataDto
 */
export interface ChatMessageTextNodeDataDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageTextNodeDataDto
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface ChatMessageTextNodeDataInputDto
 */
export interface ChatMessageTextNodeDataInputDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageTextNodeDataInputDto
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface ChatMessageUpdatedDto
 */
export interface ChatMessageUpdatedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageUpdatedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageUpdatedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageUpdatedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatMessageDto}
     * @memberof ChatMessageUpdatedDto
     */
    'message'?: ChatMessageDto;
}
/**
 * 
 * @export
 * @interface ChatMessageWebLinkNodeDataDto
 */
export interface ChatMessageWebLinkNodeDataDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageWebLinkNodeDataDto
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ChatMessageWebLinkNodeDataInputDto
 */
export interface ChatMessageWebLinkNodeDataInputDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageWebLinkNodeDataInputDto
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ChatMessagesReadDto
 */
export interface ChatMessagesReadDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessagesReadDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessagesReadDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessagesReadDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessagesReadDto
     */
    'messageIds'?: Array<string>;
    /**
     * 
     * @type {ChatMessageReadByInfoDto}
     * @memberof ChatMessagesReadDto
     */
    'readByInfo'?: ChatMessageReadByInfoDto;
}
/**
 * 
 * @export
 * @interface ChatNegotiationProposalCreatedInAppDataDto
 */
export interface ChatNegotiationProposalCreatedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatNegotiationProposalCreatedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatNegotiationProposalCreatedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatNegotiationProposalCreatedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatNegotiationProposalCreatedInAppDataDto
     */
    'createdByParticipantId'?: string | null;
}


/**
 * 
 * @export
 * @interface ChatNegotiationProposalResponseGivenInAppDataDto
 */
export interface ChatNegotiationProposalResponseGivenInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatNegotiationProposalResponseGivenInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatNegotiationProposalResponseGivenInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatNegotiationProposalResponseGivenInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatNegotiationProposalResponseGivenInAppDataDto
     */
    'respondedParticipantId'?: string | null;
}


/**
 * 
 * @export
 * @interface ChatParticipantAddedInAppDataDto
 */
export interface ChatParticipantAddedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatParticipantAddedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantAddedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatParticipantAddedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantAddedInAppDataDto
     */
    'addedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @interface ChatParticipantCandidateDto
 */
export interface ChatParticipantCandidateDto {
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantCandidateDto
     */
    'randomId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantCandidateDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof ChatParticipantCandidateDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantCandidateDto
     */
    'email'?: string;
    /**
     * 
     * @type {GeneralAvatarDto}
     * @memberof ChatParticipantCandidateDto
     */
    'avatar'?: GeneralAvatarDto;
}
/**
 * 
 * @export
 * @interface ChatParticipantCandidateInputDto
 */
export interface ChatParticipantCandidateInputDto {
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantCandidateInputDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantCandidateInputDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ChatParticipantChangedDto
 */
export interface ChatParticipantChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatParticipantChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatParticipantChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {ChatParticipantDto}
     * @memberof ChatParticipantChangedDto
     */
    'entity'?: ChatParticipantDto;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface ChatParticipantConnectedDto
 */
export interface ChatParticipantConnectedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantConnectedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantConnectedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantConnectedDto
     */
    'connectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantConnectedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantConnectedDto
     */
    'participantId'?: string;
}
/**
 * 
 * @export
 * @interface ChatParticipantDisconnectedDto
 */
export interface ChatParticipantDisconnectedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantDisconnectedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDisconnectedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDisconnectedDto
     */
    'connectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDisconnectedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDisconnectedDto
     */
    'participantId'?: string;
}
/**
 * 
 * @export
 * @interface ChatParticipantDto
 */
export interface ChatParticipantDto {
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {Array<PartyAndMemberReferenceDto>}
     * @memberof ChatParticipantDto
     */
    'parties'?: Array<PartyAndMemberReferenceDto> | null;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof ChatParticipantDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatParticipantDto
     */
    'isAcknowledged'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'acknowledgedAt'?: string | null;
    /**
     * 
     * @type {GeneralAvatarDto}
     * @memberof ChatParticipantDto
     */
    'avatar'?: GeneralAvatarDto;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'addedByParticipantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'removedByParticipantId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'updatedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'joinedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'leftAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'lastConnectedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatParticipantDto
     */
    'isSystem'?: boolean;
}
/**
 * 
 * @export
 * @interface ChatParticipantGetPaginatedDto
 */
export interface ChatParticipantGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof ChatParticipantGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatParticipantGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof ChatParticipantGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof ChatParticipantGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof ChatParticipantGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
}
/**
 * 
 * @export
 * @interface ChatParticipantJoinedDto
 */
export interface ChatParticipantJoinedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantJoinedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantJoinedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantJoinedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatParticipantDto}
     * @memberof ChatParticipantJoinedDto
     */
    'participant'?: ChatParticipantDto;
}
/**
 * 
 * @export
 * @interface ChatParticipantLeftDto
 */
export interface ChatParticipantLeftDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantLeftDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantLeftDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantLeftDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatParticipantDto}
     * @memberof ChatParticipantLeftDto
     */
    'participant'?: ChatParticipantDto;
}
/**
 * 
 * @export
 * @interface ChatParticipantMentionedInAppDataDto
 */
export interface ChatParticipantMentionedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatParticipantMentionedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantMentionedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatParticipantMentionedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantMentionedInAppDataDto
     */
    'mentionedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @interface ChatParticipantReferenceDto
 */
export interface ChatParticipantReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantReferenceDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantReferenceDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantReferenceDto
     */
    'email'?: string;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof ChatParticipantReferenceDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {boolean}
     * @memberof ChatParticipantReferenceDto
     */
    'isSystem'?: boolean;
}
/**
 * 
 * @export
 * @interface ChatParticipantRemovedDto
 */
export interface ChatParticipantRemovedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantRemovedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantRemovedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantRemovedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatParticipantDto}
     * @memberof ChatParticipantRemovedDto
     */
    'participant'?: ChatParticipantDto;
}
/**
 * 
 * @export
 * @interface ChatParticipantRemovedInAppDataDto
 */
export interface ChatParticipantRemovedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatParticipantRemovedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantRemovedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatParticipantRemovedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantRemovedInAppDataDto
     */
    'removedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @interface ChatParticipantStatusUpdatedDto
 */
export interface ChatParticipantStatusUpdatedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'connectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'participantId'?: string;
    /**
     * 
     * @type {ParticipantOnlineStatus}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'onlineStatus'?: ParticipantOnlineStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'isReportBack'?: boolean | null;
}


/**
 * 
 * @export
 * @interface ChatParticipantsAddedDto
 */
export interface ChatParticipantsAddedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantsAddedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsAddedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsAddedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {Array<ChatParticipantDto>}
     * @memberof ChatParticipantsAddedDto
     */
    'participants'?: Array<ChatParticipantDto>;
}
/**
 * 
 * @export
 * @interface ChatPartyLimit
 */
export interface ChatPartyLimit {
    /**
     * From less to more privileged.  Usually parties are processed according to the sort order,   because they might have different level of privileges, which affects business logic.
     * @type {number}
     * @memberof ChatPartyLimit
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {PartyReference}
     * @memberof ChatPartyLimit
     */
    'party'?: PartyReference;
}
/**
 * 
 * @export
 * @interface ChatReferenceDto
 */
export interface ChatReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof ChatReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatReferenceDto
     */
    'localNumber'?: string | null;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatReferenceDto
     */
    'type'?: ChatType;
}


/**
 * 
 * @export
 * @interface ChatReopenedDto
 */
export interface ChatReopenedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatReopenedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatReopenedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {ChatDto}
     * @memberof ChatReopenedDto
     */
    'chat'?: ChatDto;
    /**
     * 
     * @type {string}
     * @memberof ChatReopenedDto
     */
    'reopenedByParticipantId'?: string | null;
}
/**
 * 
 * @export
 * @interface ChatReopenedInAppDataDto
 */
export interface ChatReopenedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatReopenedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatReopenedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatReopenedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatReopenedInAppDataDto
     */
    'reopenedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @interface ChatResolvedDto
 */
export interface ChatResolvedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatResolvedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatResolvedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {ChatDto}
     * @memberof ChatResolvedDto
     */
    'chat'?: ChatDto;
}
/**
 * 
 * @export
 * @interface ChatResolvedInAppDataDto
 */
export interface ChatResolvedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatResolvedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatResolvedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatResolvedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatResolvedInAppDataDto
     */
    'resolvedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @interface ChatSettingsDto
 */
export interface ChatSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowJoin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowLeave'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowPartyMemberJoin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowParticipantManagement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowResolution'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowAcknowledgement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowMessageAcknowledgement'?: boolean;
    /**
     * 
     * @type {Array<ChatPartyLimit>}
     * @memberof ChatSettingsDto
     */
    'limitedToParties'?: Array<ChatPartyLimit>;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowEdit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowPinnedItems'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ChatSettingsDto
     */
    'maxPinnedItems'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'isPinNegotiations'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowSendMessages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowMessageAttachments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowEditMessages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowDeleteMessages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowNegotiations'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'isNotifyAboutNewMessageViaEmail'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChatType = {
    None: 'None',
    Personal: 'Personal',
    Group: 'Group',
    Comment: 'Comment',
    Negotiation: 'Negotiation'
} as const;

export type ChatType = typeof ChatType[keyof typeof ChatType];


/**
 * 
 * @export
 * @interface ChatUpdatedDto
 */
export interface ChatUpdatedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatUpdatedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatUpdatedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatUpdatedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatDto}
     * @memberof ChatUpdatedDto
     */
    'chat'?: ChatDto;
    /**
     * 
     * @type {string}
     * @memberof ChatUpdatedDto
     */
    'participantId'?: string | null;
}
/**
 * 
 * @export
 * @interface ChatUserSettingsChangedDto
 */
export interface ChatUserSettingsChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatUserSettingsChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatUserSettingsChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatUserSettingsChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {ChatUserSettingsDto}
     * @memberof ChatUserSettingsChangedDto
     */
    'entity'?: ChatUserSettingsDto;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface ChatUserSettingsDto
 */
export interface ChatUserSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsDto
     */
    'chatId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsDto
     */
    'participantId'?: string | null;
    /**
     * 
     * @type {ChatUserSettingsEvent}
     * @memberof ChatUserSettingsDto
     */
    'event'?: ChatUserSettingsEvent;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsDto
     */
    'isGlobal'?: boolean;
}
/**
 * 
 * @export
 * @interface ChatUserSettingsEvent
 */
export interface ChatUserSettingsEvent {
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ChatUserSettingsEvent
     */
    'disabledCategoriesMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ChatUserSettingsEvent
     */
    'disabledTypesMap'?: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface CheckoutManualDto
 */
export interface CheckoutManualDto {
    /**
     * 
     * @type {string}
     * @memberof CheckoutManualDto
     */
    'subscriptionPlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutManualDto
     */
    'priceId'?: string;
}
/**
 * 
 * @export
 * @interface ClaimTestDto
 */
export interface ClaimTestDto {
    /**
     * 
     * @type {string}
     * @memberof ClaimTestDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimTestDto
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ColumnInfoDtoInSpreadsheetFileMetadataDto
 */
export interface ColumnInfoDtoInSpreadsheetFileMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof ColumnInfoDtoInSpreadsheetFileMetadataDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ColumnInfoDtoInSpreadsheetFileMetadataDto
     */
    'index'?: number;
}
/**
 * 
 * @export
 * @interface CompanyIdentifierValidateResultDto
 */
export interface CompanyIdentifierValidateResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyIdentifierValidateResultDto
     */
    'isFree'?: boolean;
}
/**
 * 
 * @export
 * @interface CompanyIncompleteRegistrationCompanyInfoDto
 */
export interface CompanyIncompleteRegistrationCompanyInfoDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyIncompleteRegistrationCompanyInfoDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyIncompleteRegistrationCompanyInfoDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyIncompleteRegistrationCompanyInfoDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyIncompleteRegistrationCompanyInfoDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {TenantContactPersonDto}
     * @memberof CompanyIncompleteRegistrationCompanyInfoDto
     */
    'contactPerson'?: TenantContactPersonDto;
}
/**
 * Info needed to display incomplete company registration.
 * @export
 * @interface CompanyIncompleteRegistrationDto
 */
export interface CompanyIncompleteRegistrationDto {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyIncompleteRegistrationDto
     */
    'isFoundIncompleteRegistration'?: boolean;
    /**
     * 
     * @type {CompanyIncompleteRegistrationCompanyInfoDto}
     * @memberof CompanyIncompleteRegistrationDto
     */
    'company'?: CompanyIncompleteRegistrationCompanyInfoDto;
}
/**
 * 
 * @export
 * @interface ConnectToChatDto
 */
export interface ConnectToChatDto {
    /**
     * 
     * @type {string}
     * @memberof ConnectToChatDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectToChatDto
     */
    'participantId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ConsensusType = {
    None: 'None',
    Consensus: 'Consensus',
    ForcedConsensus: 'ForcedConsensus',
    NoConsensus: 'NoConsensus'
} as const;

export type ConsensusType = typeof ConsensusType[keyof typeof ConsensusType];


/**
 * Describes types of communication channels between company and customer.
 * @export
 * @enum {string}
 */

export const ContactChannel = {
    None: 'None',
    InPerson: 'InPerson',
    Email: 'Email',
    VoiceCall: 'VoiceCall',
    Sms: 'Sms',
    Chat: 'Chat'
} as const;

export type ContactChannel = typeof ContactChannel[keyof typeof ContactChannel];


/**
 * 
 * @export
 * @enum {string}
 */

export const ContractAssessmentFlowStateFilterType = {
    None: 'None',
    All: 'All',
    Pending: 'Pending',
    ActionRequired: 'ActionRequired'
} as const;

export type ContractAssessmentFlowStateFilterType = typeof ContractAssessmentFlowStateFilterType[keyof typeof ContractAssessmentFlowStateFilterType];


/**
 * 
 * @export
 * @interface ContractBulkDeleteDto
 */
export interface ContractBulkDeleteDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractBulkDeleteDto
     */
    'ids'?: Array<string>;
    /**
     * 
     * @type {Array<EntityType>}
     * @memberof ContractBulkDeleteDto
     */
    'relatedEntitiesTypesToDelete'?: Array<EntityType>;
    /**
     * 
     * @type {boolean}
     * @memberof ContractBulkDeleteDto
     */
    'isDeleteAllRelatedData'?: boolean;
}
/**
 * 
 * @export
 * @interface ContractChangedDto
 */
export interface ContractChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ContractChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ContractChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ContractChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof ContractChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {ContractDto}
     * @memberof ContractChangedDto
     */
    'entity'?: ContractDto;
    /**
     * 
     * @type {string}
     * @memberof ContractChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * Describes subject in communication (in scope of a contract) between company and customer.
 * @export
 * @enum {string}
 */

export const ContractCommunicationSubjectType = {
    None: 'None',
    DamageDetection: 'DamageDetection',
    DamageCostEvaluation: 'DamageCostEvaluation',
    Other: 'Other'
} as const;

export type ContractCommunicationSubjectType = typeof ContractCommunicationSubjectType[keyof typeof ContractCommunicationSubjectType];


/**
 * 
 * @export
 * @interface ContractCountersDto
 */
export interface ContractCountersDto {
    /**
     * 
     * @type {number}
     * @memberof ContractCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ContractCountersDto
     */
    'countByFilterTypeMap'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ContractCountersDto
     */
    'countByAllocationStatusMap'?: { [key: string]: number; };
    /**
     * Check-outs count according to the request.
     * @type {number}
     * @memberof ContractCountersDto
     */
    'checkOutCount'?: number;
    /**
     * Check-outs count according to the request.
     * @type {number}
     * @memberof ContractCountersDto
     */
    'checkInCount'?: number;
}
/**
 * 
 * @export
 * @interface ContractCustomerCommunicationDto
 */
export interface ContractCustomerCommunicationDto {
    /**
     * 
     * @type {Array<ContractCustomerCommunicationEntryDto>}
     * @memberof ContractCustomerCommunicationDto
     */
    'entries'?: Array<ContractCustomerCommunicationEntryDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ContractCustomerCommunicationDto
     */
    'isContacted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractCustomerCommunicationDto
     */
    'isContactedOnDamageDetection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractCustomerCommunicationDto
     */
    'isContactedOnDamageCostEvaluation'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractCustomerCommunicationDto
     */
    'contactedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractCustomerCommunicationDto
     */
    'contactedOnDamageDetectionAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractCustomerCommunicationDto
     */
    'contactedOnDamageCostEvaluationAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ContractCustomerCommunicationEntryDto
 */
export interface ContractCustomerCommunicationEntryDto {
    /**
     * 
     * @type {string}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'customerContactId'?: string;
    /**
     * 
     * @type {ContactChannel}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'contactChannel'?: ContactChannel;
    /**
     * 
     * @type {Array<ContractCommunicationSubjectType>}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'subjectTypes'?: Array<ContractCommunicationSubjectType>;
    /**
     * 
     * @type {string}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'notes'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'contactedAt'?: string;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'handledBy'?: GeneralByWhoDto;
    /**
     * 
     * @type {boolean}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'isAuto'?: boolean;
}


/**
 * 
 * @export
 * @interface ContractDto
 */
export interface ContractDto {
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof ContractDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {CustomerReferenceDto}
     * @memberof ContractDto
     */
    'customer'?: CustomerReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionReferenceDto}
     * @memberof ContractDto
     */
    'assetSubscription'?: AssetSubscriptionReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'externalNumber'?: string | null;
    /**
     * 
     * @type {ContractType}
     * @memberof ContractDto
     */
    'type'?: ContractType;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfContractStage}
     * @memberof ContractDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfContractStage;
    /**
     * 
     * @type {AllocationStatus}
     * @memberof ContractDto
     */
    'allocationStatus'?: AllocationStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'startsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'endsAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<AccessoryReferenceDto>}
     * @memberof ContractDto
     */
    'accessories'?: Array<AccessoryReferenceDto>;
    /**
     * 
     * @type {ContractSettingsDto}
     * @memberof ContractDto
     */
    'settings'?: ContractSettingsDto;
    /**
     * 
     * @type {ContractReminderSettingsDto}
     * @memberof ContractDto
     */
    'reminderSettings'?: ContractReminderSettingsDto;
    /**
     * 
     * @type {ContractCustomerCommunicationDto}
     * @memberof ContractDto
     */
    'customerCommunication'?: ContractCustomerCommunicationDto;
    /**
     * 
     * @type {ContractSpotInfoDto}
     * @memberof ContractDto
     */
    'checkOutSpotInfo'?: ContractSpotInfoDto;
    /**
     * 
     * @type {ContractSpotInfoDto}
     * @memberof ContractDto
     */
    'checkInSpotInfo'?: ContractSpotInfoDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof ContractDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'isExternal'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'draftAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'activatedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'completedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'closedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'canReallocate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'mustConfirmAllocation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'mustPerformVisualInspectionBeforeCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'mustPerformVisualInspectionBeforeCheckIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'canCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'canCheckIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'isPreparedForCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'isPreparedForCheckIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'isCheckedOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'isCheckedIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'canPerformAnyOperation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'canPerformVisualInspection'?: boolean;
    /**
     * 
     * @type {ContractFilterType}
     * @memberof ContractDto
     */
    'filterType'?: ContractFilterType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ContractFilterType = {
    None: 'None',
    All: 'All',
    CheckOut: 'CheckOut',
    CheckIn: 'CheckIn'
} as const;

export type ContractFilterType = typeof ContractFilterType[keyof typeof ContractFilterType];


/**
 * 
 * @export
 * @interface ContractFullReferenceDto
 */
export interface ContractFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof ContractFullReferenceDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {CustomerReferenceDto}
     * @memberof ContractFullReferenceDto
     */
    'customer'?: CustomerReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionReferenceDto}
     * @memberof ContractFullReferenceDto
     */
    'assetSubscription'?: AssetSubscriptionReferenceDto;
    /**
     * 
     * @type {VisualInspectionReferenceDto}
     * @memberof ContractFullReferenceDto
     */
    'visualInspectionBeforeCheckOut'?: VisualInspectionReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'externalNumber'?: string | null;
    /**
     * 
     * @type {ContractType}
     * @memberof ContractFullReferenceDto
     */
    'type'?: ContractType;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfContractStage}
     * @memberof ContractFullReferenceDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfContractStage;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'startsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'endsAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<AccessoryReferenceDto>}
     * @memberof ContractFullReferenceDto
     */
    'accessories'?: Array<AccessoryReferenceDto>;
    /**
     * 
     * @type {ContractSettingsDto}
     * @memberof ContractFullReferenceDto
     */
    'settings'?: ContractSettingsDto;
    /**
     * 
     * @type {ContractReminderSettingsDto}
     * @memberof ContractFullReferenceDto
     */
    'reminderSettings'?: ContractReminderSettingsDto;
    /**
     * 
     * @type {ContractCustomerCommunicationDto}
     * @memberof ContractFullReferenceDto
     */
    'customerCommunication'?: ContractCustomerCommunicationDto;
    /**
     * 
     * @type {ContractSpotInfoDto}
     * @memberof ContractFullReferenceDto
     */
    'checkOutSpotInfo'?: ContractSpotInfoDto;
    /**
     * 
     * @type {ContractSpotInfoDto}
     * @memberof ContractFullReferenceDto
     */
    'checkInSpotInfo'?: ContractSpotInfoDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'isExternal'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'draftAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'activatedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'completedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'closedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'canReallocate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'mustConfirmAllocation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'mustPerformVisualInspectionBeforeCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'mustPerformVisualInspectionBeforeCheckIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'canCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'canCheckIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'isPreparedForCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'isPreparedForCheckIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'isCheckedOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'isCheckedIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'canPerformAnyOperation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'canPerformVisualInspection'?: boolean;
    /**
     * 
     * @type {ContractFilterType}
     * @memberof ContractFullReferenceDto
     */
    'filterType'?: ContractFilterType;
}


/**
 * 
 * @export
 * @interface ContractGetCountersDto
 */
export interface ContractGetCountersDto {
    /**
     * 
     * @type {DateRangeInputDto}
     * @memberof ContractGetCountersDto
     */
    'range'?: DateRangeInputDto;
    /**
     * 
     * @type {{ [key: string]: DateRangeInputDto; }}
     * @memberof ContractGetCountersDto
     */
    'rangeByFilterTypeMap'?: { [key: string]: DateRangeInputDto; } | null;
}
/**
 * 
 * @export
 * @interface ContractGetPaginatedDto
 */
export interface ContractGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof ContractGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContractGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof ContractGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof ContractGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof ContractGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof ContractGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractGetPaginatedDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractGetPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractGetPaginatedDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {Array<ContractType>}
     * @memberof ContractGetPaginatedDto
     */
    'types'?: Array<ContractType> | null;
    /**
     * 
     * @type {ContractStage}
     * @memberof ContractGetPaginatedDto
     */
    'stage'?: ContractStage;
    /**
     * 
     * @type {Array<ContractStage>}
     * @memberof ContractGetPaginatedDto
     */
    'stages'?: Array<ContractStage> | null;
    /**
     * 
     * @type {ContractStage}
     * @memberof ContractGetPaginatedDto
     */
    'excludeStage'?: ContractStage;
    /**
     * 
     * @type {Array<ContractStage>}
     * @memberof ContractGetPaginatedDto
     */
    'excludeStages'?: Array<ContractStage> | null;
    /**
     * 
     * @type {AllocationStatus}
     * @memberof ContractGetPaginatedDto
     */
    'allocationStatus'?: AllocationStatus;
    /**
     * 
     * @type {ContractFilterType}
     * @memberof ContractGetPaginatedDto
     */
    'filterType'?: ContractFilterType;
    /**
     * 
     * @type {{ [key: string]: DateRangeInputDto; }}
     * @memberof ContractGetPaginatedDto
     */
    'rangeByFilterTypeMap'?: { [key: string]: DateRangeInputDto; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractGetPaginatedDto
     */
    'isPreparedForCheckOut'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractGetPaginatedDto
     */
    'isPreparedForCheckIn'?: boolean | null;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof ContractGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
    /**
     * 
     * @type {DateRangeInputDto}
     * @memberof ContractGetPaginatedDto
     */
    'checkOutRange'?: DateRangeInputDto;
    /**
     * 
     * @type {DateRangeInputDto}
     * @memberof ContractGetPaginatedDto
     */
    'checkInRange'?: DateRangeInputDto;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ContractProductsSubscriptionsStateFilterType = {
    None: 'None',
    All: 'All',
    Reservations: 'Reservations',
    Active: 'Active',
    Completed: 'Completed'
} as const;

export type ContractProductsSubscriptionsStateFilterType = typeof ContractProductsSubscriptionsStateFilterType[keyof typeof ContractProductsSubscriptionsStateFilterType];


/**
 * 
 * @export
 * @interface ContractReallocateVehicleDto
 */
export interface ContractReallocateVehicleDto {
    /**
     * 
     * @type {string}
     * @memberof ContractReallocateVehicleDto
     */
    'newVehicleId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContractReallocateVehicleDto
     */
    'isConfirmed'?: boolean;
}
/**
 * 
 * @export
 * @interface ContractReferenceDto
 */
export interface ContractReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof ContractReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractReferenceDto
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {ContractType}
     * @memberof ContractReferenceDto
     */
    'type'?: ContractType;
    /**
     * 
     * @type {string}
     * @memberof ContractReferenceDto
     */
    'externalNumber'?: string | null;
}


/**
 * 
 * @export
 * @interface ContractReminderDto
 */
export interface ContractReminderDto {
    /**
     * 
     * @type {string}
     * @memberof ContractReminderDto
     */
    'id'?: string;
    /**
     * 
     * @type {ContractReminderType}
     * @memberof ContractReminderDto
     */
    'type'?: ContractReminderType;
    /**
     * 
     * @type {boolean}
     * @memberof ContractReminderDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContractReminderDto
     */
    'jobId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractReminderDto
     */
    'isFired'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractReminderDto
     */
    'nextFireAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractReminderDto
     */
    'previousFireAt'?: string | null;
}


/**
 * 
 * @export
 * @interface ContractReminderSettingsDto
 */
export interface ContractReminderSettingsDto {
    /**
     * 
     * @type {ContractReminderDto}
     * @memberof ContractReminderSettingsDto
     */
    'ofDamageDetectionToCustomer'?: ContractReminderDto;
    /**
     * 
     * @type {ContractReminderDto}
     * @memberof ContractReminderSettingsDto
     */
    'ofDamageCostEvaluationToCustomer'?: ContractReminderDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ContractReminderType = {
    None: 'None',
    DamageDetectionToCustomer: 'DamageDetectionToCustomer',
    DamageCostEvaluationToCustomer: 'DamageCostEvaluationToCustomer'
} as const;

export type ContractReminderType = typeof ContractReminderType[keyof typeof ContractReminderType];


/**
 * 
 * @export
 * @interface ContractReminderUpdatedDto
 */
export interface ContractReminderUpdatedDto {
    /**
     * 
     * @type {ContractDto}
     * @memberof ContractReminderUpdatedDto
     */
    'contract'?: ContractDto;
    /**
     * 
     * @type {ContractReminderDto}
     * @memberof ContractReminderUpdatedDto
     */
    'reminder'?: ContractReminderDto;
}
/**
 * 
 * @export
 * @interface ContractSearchPaginatedDto
 */
export interface ContractSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof ContractSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContractSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof ContractSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof ContractSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof ContractSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof ContractSearchPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractSearchPaginatedDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {Array<ContractType>}
     * @memberof ContractSearchPaginatedDto
     */
    'types'?: Array<ContractType> | null;
    /**
     * 
     * @type {ContractStage}
     * @memberof ContractSearchPaginatedDto
     */
    'stage'?: ContractStage;
    /**
     * 
     * @type {Array<ContractStage>}
     * @memberof ContractSearchPaginatedDto
     */
    'stages'?: Array<ContractStage> | null;
    /**
     * 
     * @type {ContractStage}
     * @memberof ContractSearchPaginatedDto
     */
    'excludeStage'?: ContractStage;
    /**
     * 
     * @type {Array<ContractStage>}
     * @memberof ContractSearchPaginatedDto
     */
    'excludeStages'?: Array<ContractStage> | null;
    /**
     * 
     * @type {AllocationStatus}
     * @memberof ContractSearchPaginatedDto
     */
    'allocationStatus'?: AllocationStatus;
    /**
     * 
     * @type {ContractFilterType}
     * @memberof ContractSearchPaginatedDto
     */
    'filterType'?: ContractFilterType;
    /**
     * 
     * @type {{ [key: string]: DateRangeInputDto; }}
     * @memberof ContractSearchPaginatedDto
     */
    'rangeByFilterTypeMap'?: { [key: string]: DateRangeInputDto; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractSearchPaginatedDto
     */
    'isPreparedForCheckOut'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractSearchPaginatedDto
     */
    'isPreparedForCheckIn'?: boolean | null;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof ContractSearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
    /**
     * 
     * @type {DateRangeInputDto}
     * @memberof ContractSearchPaginatedDto
     */
    'checkOutRange'?: DateRangeInputDto;
    /**
     * 
     * @type {DateRangeInputDto}
     * @memberof ContractSearchPaginatedDto
     */
    'checkInRange'?: DateRangeInputDto;
}


/**
 * 
 * @export
 * @interface ContractSettingsDto
 */
export interface ContractSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof ContractSettingsDto
     */
    'isRequireVisualInspectionBeforeCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractSettingsDto
     */
    'isRequireVisualInspectionBeforeCheckIn'?: boolean;
}
/**
 * 
 * @export
 * @interface ContractSettingsInputDto
 */
export interface ContractSettingsInputDto {
    /**
     * 
     * @type {boolean}
     * @memberof ContractSettingsInputDto
     */
    'isRequireVisualInspectionBeforeCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractSettingsInputDto
     */
    'isRequireVisualInspectionBeforeCheckIn'?: boolean;
}
/**
 * 
 * @export
 * @interface ContractSpotInfoDto
 */
export interface ContractSpotInfoDto {
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractSpotInfoDto
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoDto
     */
    'productLocationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoDto
     */
    'visualInspectionId'?: string | null;
    /**
     * 
     * @type {SpotFullReferenceDto}
     * @memberof ContractSpotInfoDto
     */
    'spot'?: SpotFullReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractSpotInfoDto
     */
    'isCompleted'?: boolean;
}
/**
 * 
 * @export
 * @interface ContractSpotInfoInputDto
 */
export interface ContractSpotInfoInputDto {
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractSpotInfoInputDto
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoInputDto
     */
    'visualInspectionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoInputDto
     */
    'spotId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoInputDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface ContractSpotInfoVisualInspectionInputDto
 */
export interface ContractSpotInfoVisualInspectionInputDto {
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoVisualInspectionInputDto
     */
    'visualInspectionId'?: string | null;
}
/**
 * NB: use Common.Attributes.SortOrderAttribute to specify the order in which stages can be activated.
 * @export
 * @enum {string}
 */

export const ContractStage = {
    None: 'None',
    Draft: 'Draft',
    Pending: 'Pending',
    Active: 'Active',
    Completed: 'Completed',
    Closed: 'Closed'
} as const;

export type ContractStage = typeof ContractStage[keyof typeof ContractStage];


/**
 * 
 * @export
 * @enum {string}
 */

export const ContractType = {
    None: 'None',
    Rental: 'Rental',
    Leasing: 'Leasing',
    Carsharing: 'Carsharing',
    Proxy: 'Proxy',
    Subscription: 'Subscription'
} as const;

export type ContractType = typeof ContractType[keyof typeof ContractType];


/**
 * 
 * @export
 * @interface ContractVehicleCheckInDto
 */
export interface ContractVehicleCheckInDto {
    /**
     * 
     * @type {ContractSpotInfoInputDto}
     * @memberof ContractVehicleCheckInDto
     */
    'spotInfo'?: ContractSpotInfoInputDto;
}
/**
 * 
 * @export
 * @interface ContractVehicleCheckOutDto
 */
export interface ContractVehicleCheckOutDto {
    /**
     * 
     * @type {ContractSpotInfoInputDto}
     * @memberof ContractVehicleCheckOutDto
     */
    'spotInfo'?: ContractSpotInfoInputDto;
}
/**
 * 
 * @export
 * @interface CreateAccessoryCheckDto
 */
export interface CreateAccessoryCheckDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAccessoryCheckDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessoryCheckDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessoryCheckDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessoryCheckDto
     */
    'locationId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CreateAccessoryCheckDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorAddDto}
     * @memberof CreateAccessoryCheckDto
     */
    'inspector'?: GeneralInspectorAddDto;
    /**
     * 
     * @type {Array<CreateAccessoryCheckItemDto>}
     * @memberof CreateAccessoryCheckDto
     */
    'items'?: Array<CreateAccessoryCheckItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof CreateAccessoryCheckDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessoryCheckDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {TenantRequestsMetaInputDto}
     * @memberof CreateAccessoryCheckDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaInputDto;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof CreateAccessoryCheckDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface CreateAccessoryCheckItemDto
 */
export interface CreateAccessoryCheckItemDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAccessoryCheckItemDto
     */
    'accessoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessoryCheckItemDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {AccessoryStatus}
     * @memberof CreateAccessoryCheckItemDto
     */
    'status'?: AccessoryStatus;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessoryCheckItemDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof CreateAccessoryCheckItemDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
}


/**
 * 
 * @export
 * @interface CreateAccessoryDto
 */
export interface CreateAccessoryDto {
    /**
     * 
     * @type {AccessoryType}
     * @memberof CreateAccessoryDto
     */
    'type'?: AccessoryType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof CreateAccessoryDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessoryDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessoryDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof CreateAccessoryDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}


/**
 * 
 * @export
 * @interface CreateCompanyDto
 */
export interface CreateCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'description'?: string;
    /**
     * 
     * @type {CreateDepartmentDto}
     * @memberof CreateCompanyDto
     */
    'department'?: CreateDepartmentDto;
}
/**
 * 
 * @export
 * @interface CreateContractCustomerCommunicationEntryDto
 */
export interface CreateContractCustomerCommunicationEntryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateContractCustomerCommunicationEntryDto
     */
    'customerContactId'?: string;
    /**
     * 
     * @type {ContactChannel}
     * @memberof CreateContractCustomerCommunicationEntryDto
     */
    'contactChannel'?: ContactChannel;
    /**
     * 
     * @type {Array<ContractCommunicationSubjectType>}
     * @memberof CreateContractCustomerCommunicationEntryDto
     */
    'subjectTypes'?: Array<ContractCommunicationSubjectType>;
    /**
     * 
     * @type {string}
     * @memberof CreateContractCustomerCommunicationEntryDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContractCustomerCommunicationEntryDto
     */
    'notes'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CreateContractCustomerCommunicationEntryDto
     */
    'contactedAt'?: string | null;
}


/**
 * 
 * @export
 * @interface CreateContractDto
 */
export interface CreateContractDto {
    /**
     * 
     * @type {string}
     * @memberof CreateContractDto
     */
    'externalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContractDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContractDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContractDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContractDto
     */
    'customerId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateContractDto
     */
    'accessoryIds'?: Array<string> | null;
    /**
     * 
     * @type {ContractType}
     * @memberof CreateContractDto
     */
    'type'?: ContractType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CreateContractDto
     */
    'startsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CreateContractDto
     */
    'endsAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContractDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContractDto
     */
    'isConfirmVehicleAllocation'?: boolean;
    /**
     * 
     * @type {ContractSettingsInputDto}
     * @memberof CreateContractDto
     */
    'settings'?: ContractSettingsInputDto;
    /**
     * 
     * @type {ContractSpotInfoInputDto}
     * @memberof CreateContractDto
     */
    'checkOutSpotInfo'?: ContractSpotInfoInputDto;
    /**
     * 
     * @type {ContractSpotInfoInputDto}
     * @memberof CreateContractDto
     */
    'checkInSpotInfo'?: ContractSpotInfoInputDto;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof CreateContractDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}


/**
 * 
 * @export
 * @interface CreateCustomerContactDto
 */
export interface CreateCustomerContactDto {
    /**
     * 
     * @type {GeneralPersonNameInputDto}
     * @memberof CreateCustomerContactDto
     */
    'personName'?: GeneralPersonNameInputDto;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerContactDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerContactDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {GeneralNationalIdentityNumberInputDto}
     * @memberof CreateCustomerContactDto
     */
    'nationalIdentityNumber'?: GeneralNationalIdentityNumberInputDto;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCustomerContactDto
     */
    'isPrimary'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateCustomerDto
 */
export interface CreateCustomerDto {
    /**
     * 
     * @type {CustomerType}
     * @memberof CreateCustomerDto
     */
    'type'?: CustomerType;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCustomerDto
     */
    'isDriver'?: boolean | null;
    /**
     * 
     * @type {GeneralAddressInputDto}
     * @memberof CreateCustomerDto
     */
    'address'?: GeneralAddressInputDto;
    /**
     * 
     * @type {Array<CreateCustomerContactDto>}
     * @memberof CreateCustomerDto
     */
    'contacts'?: Array<CreateCustomerContactDto>;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof CreateCustomerDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'locationId'?: string | null;
}


/**
 * 
 * @export
 * @interface CreateDamageDetectionDto
 */
export interface CreateDamageDetectionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDamageDetectionDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDamageDetectionDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDamageDetectionDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDamageDetectionDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDamageDetectionDto
     */
    'vehicleVisualModelId'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CreateDamageDetectionDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorAddDto}
     * @memberof CreateDamageDetectionDto
     */
    'inspector'?: GeneralInspectorAddDto;
    /**
     * 
     * @type {number}
     * @memberof CreateDamageDetectionDto
     */
    'fuelLevel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateDamageDetectionDto
     */
    'mileage'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDamageDetectionDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDamageDetectionDto
     */
    'isNoDamagesDetected'?: boolean;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof CreateDamageDetectionDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof CreateDamageDetectionDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {Array<CreateDamageDetectionItemDto>}
     * @memberof CreateDamageDetectionDto
     */
    'items'?: Array<CreateDamageDetectionItemDto>;
    /**
     * 
     * @type {TenantRequestsMetaInputDto}
     * @memberof CreateDamageDetectionDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaInputDto;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof CreateDamageDetectionDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface CreateDamageDetectionItemDto
 */
export interface CreateDamageDetectionItemDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDamageDetectionItemDto
     */
    'partTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDamageDetectionItemDto
     */
    'damageTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDamageDetectionItemDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof CreateDamageDetectionItemDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {VehicleArea}
     * @memberof CreateDamageDetectionItemDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof CreateDamageDetectionItemDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof CreateDamageDetectionItemDto
     */
    'point'?: GeneralSvgPointDto;
}


/**
 * 
 * @export
 * @interface CreateDepartmentDto
 */
export interface CreateDepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressInputDto}
     * @memberof CreateDepartmentDto
     */
    'address'?: GeneralAddressInputDto;
    /**
     * 
     * @type {GeneralBrandingInputDto}
     * @memberof CreateDepartmentDto
     */
    'branding'?: GeneralBrandingInputDto;
}
/**
 * 
 * @export
 * @interface CreateGroupChatDto
 */
export interface CreateGroupChatDto {
    /**
     * 
     * @type {string}
     * @memberof CreateGroupChatDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CreateLocationDto
 */
export interface CreateLocationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressInputDto}
     * @memberof CreateLocationDto
     */
    'address'?: GeneralAddressInputDto;
}
/**
 * 
 * @export
 * @interface CreateNegotiationProposalDto
 */
export interface CreateNegotiationProposalDto {
    /**
     * 
     * @type {string}
     * @memberof CreateNegotiationProposalDto
     */
    'createFromProposalId'?: string | null;
    /**
     * 
     * @type {NegotiationType}
     * @memberof CreateNegotiationProposalDto
     */
    'type'?: NegotiationType;
    /**
     * 
     * @type {string}
     * @memberof CreateNegotiationProposalDto
     */
    'statement'?: string;
    /**
     * 
     * @type {NegotiationProposalSettingsDto}
     * @memberof CreateNegotiationProposalDto
     */
    'settings'?: NegotiationProposalSettingsDto;
    /**
     * 
     * @type {CreateNegotiationProposalValueDto}
     * @memberof CreateNegotiationProposalDto
     */
    'value'?: CreateNegotiationProposalValueDto;
}


/**
 * 
 * @export
 * @interface CreateNegotiationProposalValueDto
 */
export interface CreateNegotiationProposalValueDto {
    /**
     * 
     * @type {NegotiationValueType}
     * @memberof CreateNegotiationProposalValueDto
     */
    'valueType'?: NegotiationValueType;
    /**
     * 
     * @type {boolean}
     * @memberof CreateNegotiationProposalValueDto
     */
    'bool'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNegotiationProposalValueDto
     */
    'string'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateNegotiationProposalValueDto
     */
    'int32'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateNegotiationProposalValueDto
     */
    'double'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateNegotiationProposalValueDto
     */
    'decimal'?: number | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CreateNegotiationProposalValueDto
     */
    'dateTime'?: string | null;
    /**
     * 
     * @type {CreateNegotiationProposalValuePriceDto}
     * @memberof CreateNegotiationProposalValueDto
     */
    'price'?: CreateNegotiationProposalValuePriceDto;
}


/**
 * 
 * @export
 * @interface CreateNegotiationProposalValuePriceDto
 */
export interface CreateNegotiationProposalValuePriceDto {
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof CreateNegotiationProposalValuePriceDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {GeneralPriceInputDto}
     * @memberof CreateNegotiationProposalValuePriceDto
     */
    'price'?: GeneralPriceInputDto;
    /**
     * 
     * @type {GeneralPriceInputDto}
     * @memberof CreateNegotiationProposalValuePriceDto
     */
    'minPrice'?: GeneralPriceInputDto;
    /**
     * 
     * @type {GeneralPriceInputDto}
     * @memberof CreateNegotiationProposalValuePriceDto
     */
    'maxPrice'?: GeneralPriceInputDto;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof CreateNegotiationProposalValuePriceDto
     */
    'discount'?: GeneralDiscountInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof CreateNegotiationProposalValuePriceDto
     */
    'tax'?: GeneralTaxInputDto;
}
/**
 * 
 * @export
 * @interface CreatePersonalChatDto
 */
export interface CreatePersonalChatDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalChatDto
     */
    'interlocutorUserId'?: string;
}
/**
 * 
 * @export
 * @interface CreateProductLocationDto
 */
export interface CreateProductLocationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProductLocationDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductLocationDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductLocationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductLocationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressInputDto}
     * @memberof CreateProductLocationDto
     */
    'address'?: GeneralAddressInputDto;
}
/**
 * 
 * @export
 * @interface CreateRepairMaterialDto
 */
export interface CreateRepairMaterialDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRepairMaterialDto
     */
    'repairCatalogId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairMaterialDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairMaterialDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof CreateRepairMaterialDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairMaterialDto
     */
    'brandName'?: string | null;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof CreateRepairMaterialDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof CreateRepairMaterialDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRepairMaterialDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof CreateRepairMaterialDto
     */
    'discount'?: GeneralDiscountInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof CreateRepairMaterialDto
     */
    'tax'?: GeneralTaxInputDto;
}


/**
 * 
 * @export
 * @interface CreateRepairOperationDto
 */
export interface CreateRepairOperationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRepairOperationDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairOperationDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairOperationDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairOperationDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairOperationDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairOperationDto
     */
    'description'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CreateRepairOperationDto
     */
    'date'?: string;
    /**
     * 
     * @type {GeneralByWhoInputDto}
     * @memberof CreateRepairOperationDto
     */
    'responsibleUser'?: GeneralByWhoInputDto;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof CreateRepairOperationDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {Array<RepairOperationItemInputDto>}
     * @memberof CreateRepairOperationDto
     */
    'items'?: Array<RepairOperationItemInputDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairOperationDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof CreateRepairOperationDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {TenantRequestsMetaInputDto}
     * @memberof CreateRepairOperationDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaInputDto;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof CreateRepairOperationDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface CreateRepairSparePartDto
 */
export interface CreateRepairSparePartDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRepairSparePartDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairSparePartDto
     */
    'partNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairSparePartDto
     */
    'brandName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairSparePartDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof CreateRepairSparePartDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {VehicleType}
     * @memberof CreateRepairSparePartDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairSparePartDto
     */
    'partTypeId'?: string | null;
    /**
     * 
     * @type {Array<RepairSparePartDetalizationInputDto>}
     * @memberof CreateRepairSparePartDto
     */
    'detalizations'?: Array<RepairSparePartDetalizationInputDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof CreateRepairSparePartDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof CreateRepairSparePartDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {number}
     * @memberof CreateRepairSparePartDto
     */
    'price'?: number | null;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof CreateRepairSparePartDto
     */
    'discount'?: GeneralDiscountInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof CreateRepairSparePartDto
     */
    'tax'?: GeneralTaxInputDto;
}


/**
 * 
 * @export
 * @interface CreateRepairSpecDto
 */
export interface CreateRepairSpecDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRepairSpecDto
     */
    'repairCatalogId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairSpecDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairSpecDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof CreateRepairSpecDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof CreateRepairSpecDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairSpecDto
     */
    'damageTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairSpecDto
     */
    'partTypeId'?: string;
    /**
     * 
     * @type {RepairSpecDetalizationInputDto}
     * @memberof CreateRepairSpecDto
     */
    'detalization'?: RepairSpecDetalizationInputDto;
    /**
     * 
     * @type {number}
     * @memberof CreateRepairSpecDto
     */
    'spotFactor'?: number;
    /**
     * 
     * @type {Array<RepairSpecItemInputDto>}
     * @memberof CreateRepairSpecDto
     */
    'items'?: Array<RepairSpecItemInputDto>;
}


/**
 * 
 * @export
 * @interface CreateRepairWorkDto
 */
export interface CreateRepairWorkDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRepairWorkDto
     */
    'repairCatalogId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairWorkDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRepairWorkDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof CreateRepairWorkDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof CreateRepairWorkDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof CreateRepairWorkDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateRepairWorkDto
     */
    'expendablesPercent'?: number;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof CreateRepairWorkDto
     */
    'discount'?: GeneralDiscountInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof CreateRepairWorkDto
     */
    'tax'?: GeneralTaxInputDto;
}


/**
 * 
 * @export
 * @interface CreateSpotDto
 */
export interface CreateSpotDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSpotDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSpotDto
     */
    'productLocationId'?: string | null;
    /**
     * 
     * @type {Array<SpotType>}
     * @memberof CreateSpotDto
     */
    'types'?: Array<SpotType>;
    /**
     * 
     * @type {string}
     * @memberof CreateSpotDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSpotDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressInputDto}
     * @memberof CreateSpotDto
     */
    'address'?: GeneralAddressInputDto;
}
/**
 * 
 * @export
 * @interface CreateTenantRoleDto
 */
export interface CreateTenantRoleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTenantRoleDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantRoleDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<AppPermission>}
     * @memberof CreateTenantRoleDto
     */
    'permissions'?: Array<AppPermission> | null;
}
/**
 * 
 * @export
 * @interface CreateVehicleDto
 */
export interface CreateVehicleDto {
    /**
     * 
     * @type {VehicleSpecInputDto}
     * @memberof CreateVehicleDto
     */
    'spec'?: VehicleSpecInputDto;
    /**
     * 
     * @type {string}
     * @memberof CreateVehicleDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateVehicleDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateVehicleDto
     */
    'identificationNumber'?: string | null;
    /**
     * 
     * @type {GeneralPlateNoInputDto}
     * @memberof CreateVehicleDto
     */
    'plateNo'?: GeneralPlateNoInputDto;
    /**
     * 
     * @type {number}
     * @memberof CreateVehicleDto
     */
    'mileage'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateVehicleDto
     */
    'accessoryIds'?: Array<string> | null;
    /**
     * 
     * @type {object}
     * @memberof CreateVehicleDto
     */
    'car'?: object;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof CreateVehicleDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateVehicleDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateVehicleDto
     */
    'defaultVisualModelId'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof CreateVehicleDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface CreateWebhookDto
 */
export interface CreateWebhookDto {
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<WebhookEventType>}
     * @memberof CreateWebhookDto
     */
    'eventTypes'?: Array<WebhookEventType>;
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookDto
     */
    'url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateWebhookDto
     */
    'isEnabled'?: boolean;
}
/**
 * ISO 4217 currency code.
 * @export
 * @enum {string}
 */

export const CurrencyCode = {
    None: 'None',
    Usd: 'USD',
    Eur: 'EUR',
    Nok: 'NOK',
    Sek: 'SEK',
    Dkk: 'DKK'
} as const;

export type CurrencyCode = typeof CurrencyCode[keyof typeof CurrencyCode];


/**
 * 
 * @export
 * @interface CurrentIdentityTestDto
 */
export interface CurrentIdentityTestDto {
    /**
     * 
     * @type {string}
     * @memberof CurrentIdentityTestDto
     */
    'auth0UserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentIdentityTestDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentIdentityTestDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {Array<ClaimTestDto>}
     * @memberof CurrentIdentityTestDto
     */
    'claims'?: Array<ClaimTestDto>;
    /**
     * 
     * @type {UserIdentityTestDto}
     * @memberof CurrentIdentityTestDto
     */
    'userIdentity'?: UserIdentityTestDto;
}
/**
 * Additional object to be added under \"debug\" key to Microsoft.AspNetCore.Mvc.ProblemDetails.
 * @export
 * @interface CustomProblemDetailsDebugInfoDto
 */
export interface CustomProblemDetailsDebugInfoDto {
    /**
     * 
     * @type {string}
     * @memberof CustomProblemDetailsDebugInfoDto
     */
    'exceptionMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomProblemDetailsDebugInfoDto
     */
    'stackTrace'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomSessionDto
 */
export interface CustomSessionDto {
    /**
     * 
     * @type {string}
     * @memberof CustomSessionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomSessionDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomSessionDto
     */
    'userIdentityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomSessionDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomSessionDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {UserMembershipType}
     * @memberof CustomSessionDto
     */
    'membershipType'?: UserMembershipType;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof CustomSessionDto
     */
    'issuedBy'?: GeneralByWhoDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CustomSessionDto
     */
    'issuedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CustomSessionDto
     */
    'expiresAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomSessionDto
     */
    'allowRefresh'?: boolean;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof CustomSessionDto
     */
    'lifetimeAfterRefresh'?: string | null;
    /**
     * 
     * @type {Array<RoleReferenceDto>}
     * @memberof CustomSessionDto
     */
    'roles'?: Array<RoleReferenceDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomSessionDto
     */
    'permissions'?: Array<string>;
    /**
     * 
     * @type {Array<AppModule>}
     * @memberof CustomSessionDto
     */
    'modules'?: Array<AppModule>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CustomSessionDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CustomSessionDto
     */
    'updatedAt'?: string;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof CustomSessionDto
     */
    'lifetime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomSessionDto
     */
    'isExpired'?: boolean;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof CustomSessionDto
     */
    'lifetimeLeft'?: string | null;
}


/**
 * 
 * @export
 * @interface CustomTagChangedDto
 */
export interface CustomTagChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomTagChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CustomTagChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof CustomTagChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof CustomTagChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof CustomTagChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {CustomTagDto}
     * @memberof CustomTagChangedDto
     */
    'entity'?: CustomTagDto;
    /**
     * 
     * @type {string}
     * @memberof CustomTagChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface CustomTagCreateDto
 */
export interface CustomTagCreateDto {
    /**
     * 
     * @type {string}
     * @memberof CustomTagCreateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagCreateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {TagStylesInputDto}
     * @memberof CustomTagCreateDto
     */
    'styles'?: TagStylesInputDto;
}
/**
 * 
 * @export
 * @interface CustomTagDto
 */
export interface CustomTagDto {
    /**
     * 
     * @type {string}
     * @memberof CustomTagDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomTagDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {TagStylesDto}
     * @memberof CustomTagDto
     */
    'styles'?: TagStylesDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CustomTagDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CustomTagDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomTagDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomTagFullReferenceDto
 */
export interface CustomTagFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof CustomTagFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomTagFullReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {TagStylesDto}
     * @memberof CustomTagFullReferenceDto
     */
    'styles'?: TagStylesDto;
}
/**
 * 
 * @export
 * @interface CustomTagGetPaginatedDto
 */
export interface CustomTagGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof CustomTagGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTagGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomTagGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomTagGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomTagGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomTagGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof CustomTagGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof CustomTagGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof CustomTagGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
}
/**
 * 
 * @export
 * @interface CustomTagReferenceDto
 */
export interface CustomTagReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof CustomTagReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomTagReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CustomTagSearchPaginatedDto
 */
export interface CustomTagSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof CustomTagSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTagSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomTagSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomTagSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomTagSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomTagSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomTagSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof CustomTagSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof CustomTagSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof CustomTagSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
}
/**
 * 
 * @export
 * @interface CustomTagUpdateDto
 */
export interface CustomTagUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof CustomTagUpdateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {TagStylesInputDto}
     * @memberof CustomTagUpdateDto
     */
    'styles'?: TagStylesInputDto;
}
/**
 * 
 * @export
 * @interface CustomerChangedDto
 */
export interface CustomerChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof CustomerChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof CustomerChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof CustomerChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {CustomerDto}
     * @memberof CustomerChangedDto
     */
    'entity'?: CustomerDto;
    /**
     * 
     * @type {string}
     * @memberof CustomerChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface CustomerContactDto
 */
export interface CustomerContactDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDto
     */
    'id'?: string;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof CustomerContactDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {GeneralNationalIdentityNumberDto}
     * @memberof CustomerContactDto
     */
    'nationalIdentityNumber'?: GeneralNationalIdentityNumberDto;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerContactDto
     */
    'isPrimary'?: boolean;
}
/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {CustomerType}
     * @memberof CustomerDto
     */
    'type'?: CustomerType;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<CustomerContactDto>}
     * @memberof CustomerDto
     */
    'contacts'?: Array<CustomerContactDto>;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof CustomerDto
     */
    'address'?: GeneralAddressDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof CustomerDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CustomerDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CustomerDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {CustomerContactDto}
     * @memberof CustomerDto
     */
    'contact'?: CustomerContactDto;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'name'?: string;
}


/**
 * 
 * @export
 * @interface CustomerGetPaginatedDto
 */
export interface CustomerGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof CustomerGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof CustomerGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof CustomerGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof CustomerGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {CustomerType}
     * @memberof CustomerGetPaginatedDto
     */
    'type'?: CustomerType;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof CustomerGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @interface CustomerReferenceDto
 */
export interface CustomerReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {CustomerType}
     * @memberof CustomerReferenceDto
     */
    'type'?: CustomerType;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {CustomerContactDto}
     * @memberof CustomerReferenceDto
     */
    'contact'?: CustomerContactDto;
}


/**
 * 
 * @export
 * @interface CustomerSearchPaginatedDto
 */
export interface CustomerSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof CustomerSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof CustomerSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof CustomerSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof CustomerSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {CustomerType}
     * @memberof CustomerSearchPaginatedDto
     */
    'type'?: CustomerType;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof CustomerSearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerType = {
    None: 'None',
    B2C: 'B2C',
    B2B: 'B2B'
} as const;

export type CustomerType = typeof CustomerType[keyof typeof CustomerType];


/**
 * 
 * @export
 * @interface CustomersInviteGetPaginatedDto
 */
export interface CustomersInviteGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof CustomersInviteGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomersInviteGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomersInviteGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersInviteGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersInviteGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomersInviteGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof CustomersInviteGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof CustomersInviteGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof CustomersInviteGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateChangedDto
 */
export interface DamageCostEvaluationAggregateChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {DamageCostEvaluationAggregateDto}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'entity'?: DamageCostEvaluationAggregateDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateDataDto
 */
export interface DamageCostEvaluationAggregateDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {Array<DamageCostEvaluationReferenceDto>}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'sourceDamageCostEvaluations'?: Array<DamageCostEvaluationReferenceDto>;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationAggregateItemDataDto>}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'items'?: Array<DamageCostEvaluationAggregateItemDataDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'total'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'lastInspectedAt'?: string | null;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'isSentToCustomer'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateDto
 */
export interface DamageCostEvaluationAggregateDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {Array<DamageCostEvaluationReferenceDto>}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'sourceDamageCostEvaluations'?: Array<DamageCostEvaluationReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationAggregateItemDto>}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'items'?: Array<DamageCostEvaluationAggregateItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'total'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'lastInspectedAt'?: string | null;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'isSentToCustomer'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateGetPaginatedDto
 */
export interface DamageCostEvaluationAggregateGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationAggregateGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationAggregateGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationAggregateGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DamageCostEvaluationAggregateGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DamageCostEvaluationAggregateGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DamageCostEvaluationAggregateGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateGetPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateGetPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof DamageCostEvaluationAggregateGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateItemDataDto
 */
export interface DamageCostEvaluationAggregateItemDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {GeneralApprovalStatusInfoDto}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'approval'?: GeneralApprovalStatusInfoDto;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {DamageCostEvaluationDataDto}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationDataDto;
    /**
     * 
     * @type {DamageCostEvaluationItemDataDto}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'item'?: DamageCostEvaluationItemDataDto;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateItemDto
 */
export interface DamageCostEvaluationAggregateItemDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {GeneralApprovalStatusInfoDto}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'approval'?: GeneralApprovalStatusInfoDto;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {DamageCostEvaluationDataDto}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationDataDto;
    /**
     * 
     * @type {DamageCostEvaluationItemDataDto}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'item'?: DamageCostEvaluationItemDataDto;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateItemReferenceDto
 */
export interface DamageCostEvaluationAggregateItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemReferenceDto
     */
    'damageCostEvaluationAggregateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateReferenceDto
 */
export interface DamageCostEvaluationAggregateReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateSearchPaginatedDto
 */
export interface DamageCostEvaluationAggregateSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationAggregateSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationAggregateSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationAggregateSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DamageCostEvaluationAggregateSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DamageCostEvaluationAggregateSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DamageCostEvaluationAggregateSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateSearchPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateSearchPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof DamageCostEvaluationAggregateSearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateUpdatedInAppDataDto
 */
export interface DamageCostEvaluationAggregateUpdatedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof DamageCostEvaluationAggregateUpdatedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateUpdatedInAppDataDto
     */
    'damageCostEvaluationAggregateId'?: string;
}


/**
 * 
 * @export
 * @interface DamageCostEvaluationChangedDto
 */
export interface DamageCostEvaluationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DamageCostEvaluationChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DamageCostEvaluationChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {DamageCostEvaluationDto}
     * @memberof DamageCostEvaluationChangedDto
     */
    'entity'?: DamageCostEvaluationDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface DamageCostEvaluationCompleteDto
 */
export interface DamageCostEvaluationCompleteDto {
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationCompleteDto
     */
    'isSendToCustomer'?: boolean;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationCompletedInAppDataDto
 */
export interface DamageCostEvaluationCompletedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof DamageCostEvaluationCompletedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationCompletedInAppDataDto
     */
    'damageCostEvaluationId'?: string;
}


/**
 * 
 * @export
 * @interface DamageCostEvaluationContentDto
 */
export interface DamageCostEvaluationContentDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationContentDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationContentDto
     */
    'vehicleDamageIds'?: Array<string>;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof DamageCostEvaluationContentDto
     */
    'currency'?: GeneralCurrencyDto;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationContentInputDto
 */
export interface DamageCostEvaluationContentInputDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationContentInputDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationContentInputDto
     */
    'vehicleDamageIds'?: Array<string>;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof DamageCostEvaluationContentInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationCountersDto
 */
export interface DamageCostEvaluationCountersDto {
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationCountersDto
     */
    'draftCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationCountersDto
     */
    'inProgressCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationCountersDto
     */
    'completedCount'?: number;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationCreateDto
 */
export interface DamageCostEvaluationCreateDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationCreateDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationCreateDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationCreateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationCreateDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof DamageCostEvaluationCreateDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationCreateDto
     */
    'inspectedAt'?: string | null;
    /**
     * 
     * @type {GeneralInspectorAddDto}
     * @memberof DamageCostEvaluationCreateDto
     */
    'inspector'?: GeneralInspectorAddDto;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof DamageCostEvaluationCreateDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationCreateDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationItemCreateDto>}
     * @memberof DamageCostEvaluationCreateDto
     */
    'items'?: Array<DamageCostEvaluationItemCreateDto>;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationCreateDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * 
     * @type {TenantRequestsMetaInputDto}
     * @memberof DamageCostEvaluationCreateDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaInputDto;
    /**
     * 
     * @type {GeneralStageUpdateDtoOfDamageCostEvaluationStage}
     * @memberof DamageCostEvaluationCreateDto
     */
    'newStage'?: GeneralStageUpdateDtoOfDamageCostEvaluationStage;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof DamageCostEvaluationCreateDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationDataDto
 */
export interface DamageCostEvaluationDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDataDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageCostEvaluationDataDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageCostEvaluationDataDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfDamageCostEvaluationStage}
     * @memberof DamageCostEvaluationDataDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfDamageCostEvaluationStage;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof DamageCostEvaluationDataDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationDataDto
     */
    'inspectedAt'?: string | null;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof DamageCostEvaluationDataDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationItemDataDto>}
     * @memberof DamageCostEvaluationDataDto
     */
    'items'?: Array<DamageCostEvaluationItemDataDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageCostEvaluationDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationDataDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationDataDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationDataDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationDataDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationDataDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationDataDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationDataDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationDto
 */
export interface DamageCostEvaluationDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageCostEvaluationDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageCostEvaluationDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {GeneralEntitySourceDto}
     * @memberof DamageCostEvaluationDto
     */
    'source'?: GeneralEntitySourceDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfDamageCostEvaluationStage}
     * @memberof DamageCostEvaluationDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfDamageCostEvaluationStage;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof DamageCostEvaluationDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'inspectedAt'?: string | null;
    /**
     * 
     * @type {GeneralInspector}
     * @memberof DamageCostEvaluationDto
     */
    'inspector'?: GeneralInspector;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationItemDto>}
     * @memberof DamageCostEvaluationDto
     */
    'items'?: Array<DamageCostEvaluationItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageCostEvaluationDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof DamageCostEvaluationDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof DamageCostEvaluationDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof DamageCostEvaluationDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationGetPaginatedDto
 */
export interface DamageCostEvaluationGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'vehicleDamageId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'vehicleDamageIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'anyVehicleDamageIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'damageDetectionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'damageDetectionAggregateId'?: string | null;
    /**
     * 
     * @type {DamageCostEvaluationStage}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'stage'?: DamageCostEvaluationStage;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof DamageCostEvaluationGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @interface DamageCostEvaluationItemCreateDto
 */
export interface DamageCostEvaluationItemCreateDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemCreateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationItemDamageInputDto>}
     * @memberof DamageCostEvaluationItemCreateDto
     */
    'damages'?: Array<DamageCostEvaluationItemDamageInputDto>;
    /**
     * 
     * @type {RepairSpecDataInputDto}
     * @memberof DamageCostEvaluationItemCreateDto
     */
    'repairSpec'?: RepairSpecDataInputDto;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemCreateDto
     */
    'isRepairSpecChanged'?: boolean;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof DamageCostEvaluationItemCreateDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemCreateDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationItemDamageDto
 */
export interface DamageCostEvaluationItemDamageDto {
    /**
     * 
     * @type {VehicleDamageFullReferenceDto}
     * @memberof DamageCostEvaluationItemDamageDto
     */
    'damage'?: VehicleDamageFullReferenceDto;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationItemDamageInputDto
 */
export interface DamageCostEvaluationItemDamageInputDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDamageInputDto
     */
    'damageId'?: string;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationItemDataDto
 */
export interface DamageCostEvaluationItemDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'name'?: string | null;
    /**
     * v2.
     * @type {Array<DamageCostEvaluationItemDamageDto>}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'damages'?: Array<DamageCostEvaluationItemDamageDto>;
    /**
     * 
     * @type {RepairSpecDataDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'repairSpec'?: RepairSpecDataDto;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'isRepairSpecChanged'?: boolean;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {GeneralPriceDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'negotiatedPrice'?: GeneralPriceDto;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'negotiatedDiscount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'negotiatedTax'?: GeneralTaxDto;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'initialDiscount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'initialTax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'initialSubTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'initialSubTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'initialTotal'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'total'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'isNegotiatedNewSubTotal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'isNegotiatedNewDiscount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'isNegotiatedNewTax'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'isNegotiatedAnyNew'?: boolean;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationItemDto
 */
export interface DamageCostEvaluationItemDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationItemDamageDto>}
     * @memberof DamageCostEvaluationItemDto
     */
    'damages'?: Array<DamageCostEvaluationItemDamageDto>;
    /**
     * 
     * @type {RepairSpecDataDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'repairSpec'?: RepairSpecDataDto;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDto
     */
    'isRepairSpecChanged'?: boolean;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageCostEvaluationItemDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {GeneralPriceDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'negotiatedPrice'?: GeneralPriceDto;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'negotiatedDiscount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'negotiatedTax'?: GeneralTaxDto;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'initialDiscount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'initialTax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDto
     */
    'initialSubTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDto
     */
    'initialSubTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDto
     */
    'initialTotal'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDto
     */
    'total'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDto
     */
    'isNegotiatedNewSubTotal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDto
     */
    'isNegotiatedNewDiscount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDto
     */
    'isNegotiatedNewTax'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDto
     */
    'isNegotiatedAnyNew'?: boolean;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationItemReferenceDto
 */
export interface DamageCostEvaluationItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemReferenceDto
     */
    'damageCostEvaluationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationItemUpdateDto
 */
export interface DamageCostEvaluationItemUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemUpdateDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemUpdateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationItemDamageInputDto>}
     * @memberof DamageCostEvaluationItemUpdateDto
     */
    'damages'?: Array<DamageCostEvaluationItemDamageInputDto>;
    /**
     * 
     * @type {RepairSpecDataInputDto}
     * @memberof DamageCostEvaluationItemUpdateDto
     */
    'repairSpec'?: RepairSpecDataInputDto;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemUpdateDto
     */
    'isRepairSpecChanged'?: boolean;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof DamageCostEvaluationItemUpdateDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemUpdateDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationReferenceDto
 */
export interface DamageCostEvaluationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationResultDto
 */
export interface DamageCostEvaluationResultDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationResultDto
     */
    'damageCostEvaluationId'?: string;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationResultInputDto
 */
export interface DamageCostEvaluationResultInputDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationResultInputDto
     */
    'damageCostEvaluationId'?: string;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationSearchPaginatedDto
 */
export interface DamageCostEvaluationSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'vehicleDamageId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'vehicleDamageIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'anyVehicleDamageIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'damageDetectionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'damageDetectionAggregateId'?: string | null;
    /**
     * 
     * @type {DamageCostEvaluationStage}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'stage'?: DamageCostEvaluationStage;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof DamageCostEvaluationSearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * NB: use Common.Attributes.SortOrderAttribute to specify the order in which stages can be activated.
 * @export
 * @enum {string}
 */

export const DamageCostEvaluationStage = {
    None: 'None',
    Draft: 'Draft',
    InProgress: 'InProgress',
    Completed: 'Completed'
} as const;

export type DamageCostEvaluationStage = typeof DamageCostEvaluationStage[keyof typeof DamageCostEvaluationStage];


/**
 * 
 * @export
 * @interface DamageCostEvaluationStartedInAppDataDto
 */
export interface DamageCostEvaluationStartedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof DamageCostEvaluationStartedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationStartedInAppDataDto
     */
    'damageCostEvaluationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationStartedInAppDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationStartedInAppDataDto
     */
    'localNumber'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DamageCostEvaluationStatusInContract = {
    None: 'None',
    NoDamageCost: 'NoDamageCost',
    EvaluatingDamageCost: 'EvaluatingDamageCost',
    DamageCostEvaluated: 'DamageCostEvaluated',
    NegotiatingDamageCost: 'NegotiatingDamageCost',
    DamageCostChanged: 'DamageCostChanged',
    DamageCostAgreed: 'DamageCostAgreed',
    DamageCostForceApproved: 'DamageCostForceApproved'
} as const;

export type DamageCostEvaluationStatusInContract = typeof DamageCostEvaluationStatusInContract[keyof typeof DamageCostEvaluationStatusInContract];


/**
 * 
 * @export
 * @interface DamageCostEvaluationUpdateDto
 */
export interface DamageCostEvaluationUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationUpdateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationUpdateDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof DamageCostEvaluationUpdateDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationUpdateDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorAddDto}
     * @memberof DamageCostEvaluationUpdateDto
     */
    'inspector'?: GeneralInspectorAddDto;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof DamageCostEvaluationUpdateDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationUpdateDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationItemUpdateDto>}
     * @memberof DamageCostEvaluationUpdateDto
     */
    'items'?: Array<DamageCostEvaluationItemUpdateDto>;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationUpdateDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof DamageCostEvaluationUpdateDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface DamageDetectedDataDto
 */
export interface DamageDetectedDataDto {
    /**
     * 
     * @type {VehicleDamageReferenceDto}
     * @memberof DamageDetectedDataDto
     */
    'damage'?: VehicleDamageReferenceDto;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateChangedDto
 */
export interface DamageDetectionAggregateChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {DamageDetectionAggregateDto}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'entity'?: DamageDetectionAggregateDto;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface DamageDetectionAggregateDataDto
 */
export interface DamageDetectionAggregateDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDataDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageDetectionAggregateDataDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageDetectionAggregateDataDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {Array<DamageDetectionReferenceDto>}
     * @memberof DamageDetectionAggregateDataDto
     */
    'sourceDamageDetections'?: Array<DamageDetectionReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageDetectionAggregateItemDataDto>}
     * @memberof DamageDetectionAggregateDataDto
     */
    'items'?: Array<DamageDetectionAggregateItemDataDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageDetectionAggregateDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionAggregateDataDto
     */
    'lastInspectedAt'?: string | null;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateDataDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateDataDto
     */
    'isSentToCustomer'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateDto
 */
export interface DamageDetectionAggregateDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionAggregateDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionAggregateDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageDetectionAggregateDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageDetectionAggregateDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {Array<DamageDetectionReferenceDto>}
     * @memberof DamageDetectionAggregateDto
     */
    'sourceDamageDetections'?: Array<DamageDetectionReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageDetectionAggregateItemDto>}
     * @memberof DamageDetectionAggregateDto
     */
    'items'?: Array<DamageDetectionAggregateItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageDetectionAggregateDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof DamageDetectionAggregateDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof DamageDetectionAggregateDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'updatedBy'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'lastInspectedAt'?: string | null;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateDto
     */
    'isSentToCustomer'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateGetPaginatedDto
 */
export interface DamageDetectionAggregateGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionAggregateGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionAggregateGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionAggregateGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionAggregateGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionAggregateGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DamageDetectionAggregateGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DamageDetectionAggregateGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DamageDetectionAggregateGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateGetPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateGetPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof DamageDetectionAggregateGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateItemDataDto
 */
export interface DamageDetectionAggregateItemDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'isCostEvaluationStarted'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'costEvaluationStartedAt'?: string | null;
    /**
     * 
     * @type {GeneralApprovalStatusInfoDto}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'approval'?: GeneralApprovalStatusInfoDto;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {DamageDetectionDataDto}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'damageDetection'?: DamageDetectionDataDto;
    /**
     * 
     * @type {DamageDetectionItemDataDto}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'item'?: DamageDetectionItemDataDto;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateItemDto
 */
export interface DamageDetectionAggregateItemDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DamageDetectionAggregateItemDto
     */
    'isCostEvaluationStarted'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionAggregateItemDto
     */
    'costEvaluationStartedAt'?: string | null;
    /**
     * 
     * @type {GeneralApprovalStatusInfoDto}
     * @memberof DamageDetectionAggregateItemDto
     */
    'approval'?: GeneralApprovalStatusInfoDto;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateItemDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateItemDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {DamageDetectionDataDto}
     * @memberof DamageDetectionAggregateItemDto
     */
    'damageDetection'?: DamageDetectionDataDto;
    /**
     * 
     * @type {DamageDetectionItemDataDto}
     * @memberof DamageDetectionAggregateItemDto
     */
    'item'?: DamageDetectionItemDataDto;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateItemReferenceDto
 */
export interface DamageDetectionAggregateItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemReferenceDto
     */
    'damageDetectionAggregateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateReferenceDto
 */
export interface DamageDetectionAggregateReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateSearchPaginatedDto
 */
export interface DamageDetectionAggregateSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionAggregateSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionAggregateSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionAggregateSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionAggregateSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionAggregateSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DamageDetectionAggregateSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DamageDetectionAggregateSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DamageDetectionAggregateSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateSearchPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateSearchPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof DamageDetectionAggregateSearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}
/**
 * 
 * @export
 * @interface DamageDetectionBulkDeleteDto
 */
export interface DamageDetectionBulkDeleteDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionBulkDeleteDto
     */
    'ids'?: Array<string>;
    /**
     * 
     * @type {Array<EntityType>}
     * @memberof DamageDetectionBulkDeleteDto
     */
    'relatedEntitiesTypesToDelete'?: Array<EntityType>;
    /**
     * 
     * @type {boolean}
     * @memberof DamageDetectionBulkDeleteDto
     */
    'isDeleteVehicleDamages'?: boolean;
}
/**
 * 
 * @export
 * @interface DamageDetectionChangedDto
 */
export interface DamageDetectionChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DamageDetectionChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DamageDetectionChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {DamageDetectionDto}
     * @memberof DamageDetectionChangedDto
     */
    'entity'?: DamageDetectionDto;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface DamageDetectionContentDto
 */
export interface DamageDetectionContentDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionContentDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface DamageDetectionContentInputDto
 */
export interface DamageDetectionContentInputDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionContentInputDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface DamageDetectionCountersDto
 */
export interface DamageDetectionCountersDto {
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionCountersDto
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface DamageDetectionCreatedInAppDataDto
 */
export interface DamageDetectionCreatedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof DamageDetectionCreatedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionCreatedInAppDataDto
     */
    'damageDetectionId'?: string;
}


/**
 * 
 * @export
 * @interface DamageDetectionDataDto
 */
export interface DamageDetectionDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDataDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageDetectionDataDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageDetectionDataDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof DamageDetectionDataDto
     */
    'vehicleVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {FrontEndAppType}
     * @memberof DamageDetectionDataDto
     */
    'appType'?: FrontEndAppType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionDataDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof DamageDetectionDataDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionDataDto
     */
    'mileage'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionDataDto
     */
    'fuelLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DamageDetectionDataDto
     */
    'isNoDamagesDetected'?: boolean;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionDataDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionDataDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {Array<DamageDetectionItemDataDto>}
     * @memberof DamageDetectionDataDto
     */
    'items'?: Array<DamageDetectionItemDataDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageDetectionDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
}


/**
 * 
 * @export
 * @interface DamageDetectionDeleteDto
 */
export interface DamageDetectionDeleteDto {
    /**
     * 
     * @type {boolean}
     * @memberof DamageDetectionDeleteDto
     */
    'isDeleteVehicleDamages'?: boolean;
}
/**
 * 
 * @export
 * @interface DamageDetectionDto
 */
export interface DamageDetectionDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageDetectionDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageDetectionDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof DamageDetectionDto
     */
    'vehicleVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof DamageDetectionDto
     */
    'oldVehicleVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {FrontEndAppType}
     * @memberof DamageDetectionDto
     */
    'appType'?: FrontEndAppType;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'localNumber'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof DamageDetectionDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionDto
     */
    'mileage'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionDto
     */
    'fuelLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DamageDetectionDto
     */
    'isNoDamagesDetected'?: boolean;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {Array<DamageDetectionItemDto>}
     * @memberof DamageDetectionDto
     */
    'items'?: Array<DamageDetectionItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageDetectionDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof DamageDetectionDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof DamageDetectionDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof DamageDetectionDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface DamageDetectionGetPaginatedDto
 */
export interface DamageDetectionGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'vehicleDamageId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'vehicleDamageIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'anyVehicleDamageIds'?: Array<string> | null;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof DamageDetectionGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}
/**
 * 
 * @export
 * @interface DamageDetectionItemDataDto
 */
export interface DamageDetectionItemDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof DamageDetectionItemDataDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof DamageDetectionItemDataDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageDetectionItemDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {VehicleArea}
     * @memberof DamageDetectionItemDataDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof DamageDetectionItemDataDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof DamageDetectionItemDataDto
     */
    'point'?: GeneralSvgPointDto;
    /**
     * 
     * @type {VehicleDamageFullReferenceDto}
     * @memberof DamageDetectionItemDataDto
     */
    'damage'?: VehicleDamageFullReferenceDto;
}


/**
 * 
 * @export
 * @interface DamageDetectionItemDto
 */
export interface DamageDetectionItemDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof DamageDetectionItemDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof DamageDetectionItemDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageDetectionItemDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {VehicleArea}
     * @memberof DamageDetectionItemDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof DamageDetectionItemDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof DamageDetectionItemDto
     */
    'point'?: GeneralSvgPointDto;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof DamageDetectionItemDto
     */
    'oldPoint'?: GeneralSvgPointDto;
    /**
     * 
     * @type {VehicleDamageFullReferenceDto}
     * @memberof DamageDetectionItemDto
     */
    'damage'?: VehicleDamageFullReferenceDto;
}


/**
 * 
 * @export
 * @interface DamageDetectionItemReferenceDto
 */
export interface DamageDetectionItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemReferenceDto
     */
    'damageDetectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageDetectionMigrateToNewVisualModelDto
 */
export interface DamageDetectionMigrateToNewVisualModelDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionMigrateToNewVisualModelDto
     */
    'oldVehicleVisualModelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionMigrateToNewVisualModelDto
     */
    'newVehicleVisualModelId'?: string;
    /**
     * 
     * @type {Array<ItemDtoInDamageDetectionMigrateToNewVisualModelDto>}
     * @memberof DamageDetectionMigrateToNewVisualModelDto
     */
    'items'?: Array<ItemDtoInDamageDetectionMigrateToNewVisualModelDto>;
    /**
     * 
     * @type {boolean}
     * @memberof DamageDetectionMigrateToNewVisualModelDto
     */
    'isMigrateVehicleDamages'?: boolean;
}
/**
 * 
 * @export
 * @interface DamageDetectionReferenceDto
 */
export interface DamageDetectionReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageDetectionResultDto
 */
export interface DamageDetectionResultDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionResultDto
     */
    'damageDetectionId'?: string;
}
/**
 * 
 * @export
 * @interface DamageDetectionResultInputDto
 */
export interface DamageDetectionResultInputDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionResultInputDto
     */
    'damageDetectionId'?: string;
}
/**
 * 
 * @export
 * @interface DamageDetectionSearchPaginatedDto
 */
export interface DamageDetectionSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'vehicleDamageId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'vehicleDamageIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'anyVehicleDamageIds'?: Array<string> | null;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof DamageDetectionSearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DamageDetectionStatusInContract = {
    None: 'None',
    NoDamage: 'NoDamage',
    NewDamageDetected: 'NewDamageDetected',
    NegotiatingDamage: 'NegotiatingDamage',
    DamageAgreed: 'DamageAgreed',
    DamageForceApproved: 'DamageForceApproved'
} as const;

export type DamageDetectionStatusInContract = typeof DamageDetectionStatusInContract[keyof typeof DamageDetectionStatusInContract];


/**
 * 
 * @export
 * @interface DamageRepairedDataDto
 */
export interface DamageRepairedDataDto {
    /**
     * 
     * @type {VehicleDamageReferenceDto}
     * @memberof DamageRepairedDataDto
     */
    'damage'?: VehicleDamageReferenceDto;
    /**
     * 
     * @type {RepairOperationReferenceDto}
     * @memberof DamageRepairedDataDto
     */
    'repairOperation'?: RepairOperationReferenceDto;
}
/**
 * General category of a damage.  Damage should belong to one of these categories for correct mapping to appropriate vehicle parts, for instance.
 * @export
 * @enum {string}
 */

export const DamageTypeCategory = {
    None: 'None',
    Damaged: 'Damaged',
    Scratch: 'Scratch',
    StoneChip: 'StoneChip',
    GlassCrack: 'GlassCrack',
    Dent: 'Dent',
    Paint: 'Paint',
    Smell: 'Smell',
    AbnormalSound: 'AbnormalSound',
    Torn: 'Torn',
    Malfunction: 'Malfunction',
    Stain: 'Stain',
    Missing: 'Missing'
} as const;

export type DamageTypeCategory = typeof DamageTypeCategory[keyof typeof DamageTypeCategory];


/**
 * 
 * @export
 * @interface DamageTypeChangedDto
 */
export interface DamageTypeChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageTypeChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DamageTypeChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DamageTypeChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {DamageTypeDto}
     * @memberof DamageTypeChangedDto
     */
    'entity'?: DamageTypeDto;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface DamageTypeDto
 */
export interface DamageTypeDto {
    /**
     * 
     * @type {string}
     * @memberof DamageTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {DamageTypeCategory}
     * @memberof DamageTypeDto
     */
    'category'?: DamageTypeCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof DamageTypeDto
     */
    'partType'?: VehiclePartType;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeDto
     */
    'isCategoryRoot'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DamageTypeDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeDto
     */
    'isEnabledForDamageDetection'?: boolean;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageTypeDto
     */
    'examplePictures'?: Array<GeneralAttachmentDto>;
}


/**
 * 
 * @export
 * @interface DamageTypeGetPaginatedDto
 */
export interface DamageTypeGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DamageTypeGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageTypeGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageTypeGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageTypeGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageTypeGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DamageTypeGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DamageTypeGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DamageTypeGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {DamageTypeCategory}
     * @memberof DamageTypeGetPaginatedDto
     */
    'category'?: DamageTypeCategory;
    /**
     * 
     * @type {Array<DamageTypeCategory>}
     * @memberof DamageTypeGetPaginatedDto
     */
    'categories'?: Array<DamageTypeCategory> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeGetPaginatedDto
     */
    'isCategoryRoot'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeGetPaginatedDto
     */
    'isEnabledForDamageDetection'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeGetPaginatedDto
     */
    'vehiclePartTypeId'?: string | null;
}


/**
 * 
 * @export
 * @interface DamageTypeReferenceDto
 */
export interface DamageTypeReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageTypeReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {DamageTypeCategory}
     * @memberof DamageTypeReferenceDto
     */
    'category'?: DamageTypeCategory;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeReferenceDto
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @interface DamageTypeSearchPaginatedDto
 */
export interface DamageTypeSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {DamageTypeCategory}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'category'?: DamageTypeCategory;
    /**
     * 
     * @type {Array<DamageTypeCategory>}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'categories'?: Array<DamageTypeCategory> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'isCategoryRoot'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'isEnabledForDamageDetection'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'vehiclePartTypeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface DataGrantChangedDto
 */
export interface DataGrantChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DataGrantChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DataGrantChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DataGrantChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DataGrantChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {DataGrantDto}
     * @memberof DataGrantChangedDto
     */
    'entity'?: DataGrantDto;
    /**
     * 
     * @type {string}
     * @memberof DataGrantChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface DataGrantCountersDto
 */
export interface DataGrantCountersDto {
    /**
     * 
     * @type {number}
     * @memberof DataGrantCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {IncomingCountersDtoInDataGrantCountersDto}
     * @memberof DataGrantCountersDto
     */
    'incoming'?: IncomingCountersDtoInDataGrantCountersDto;
    /**
     * 
     * @type {OutcomingCountersDtoInDataGrantCountersDto}
     * @memberof DataGrantCountersDto
     */
    'outcoming'?: OutcomingCountersDtoInDataGrantCountersDto;
}
/**
 * 
 * @export
 * @interface DataGrantCreateDto
 */
export interface DataGrantCreateDto {
    /**
     * 
     * @type {string}
     * @memberof DataGrantCreateDto
     */
    'consumerTenantId'?: string;
    /**
     * 
     * @type {DataGrantEntityType}
     * @memberof DataGrantCreateDto
     */
    'grantEntityType'?: DataGrantEntityType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantCreateDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {Array<DataGrantPermission>}
     * @memberof DataGrantCreateDto
     */
    'permissions'?: Array<DataGrantPermission>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DataGrantCreateDto
     */
    'expiresAt'?: string | null;
    /**
     * 
     * @type {TenantRequestsMetaInputDto}
     * @memberof DataGrantCreateDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaInputDto;
}


/**
 * 
 * @export
 * @interface DataGrantDto
 */
export interface DataGrantDto {
    /**
     * 
     * @type {string}
     * @memberof DataGrantDto
     */
    'id'?: string;
    /**
     * 
     * @type {DataGrantType}
     * @memberof DataGrantDto
     */
    'type'?: DataGrantType;
    /**
     * 
     * @type {boolean}
     * @memberof DataGrantDto
     */
    'isAuto'?: boolean;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof DataGrantDto
     */
    'issuerTenant'?: TenantReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof DataGrantDto
     */
    'issuerUserId'?: string | null;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof DataGrantDto
     */
    'consumerTenant'?: TenantReferenceDto;
    /**
     * 
     * @type {DataGrantEntityType}
     * @memberof DataGrantDto
     */
    'grantEntityType'?: DataGrantEntityType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {Array<DataGrantPermission>}
     * @memberof DataGrantDto
     */
    'permissions'?: Array<DataGrantPermission>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DataGrantDto
     */
    'expiresAt'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof DataGrantDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof DataGrantDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DataGrantDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DataGrantDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataGrantDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataGrantDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {EntityType}
     * @memberof DataGrantDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DataGrantDto
     */
    'tenantIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DataGrantDto
     */
    'userIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DataGrantDto
     */
    'permissionsMap'?: { [key: string]: boolean; };
}


/**
 * Must contain only values from Domain.Enums.EntityType.
 * @export
 * @enum {string}
 */

export const DataGrantEntityType = {
    None: 'None',
    Vehicle: 'Vehicle',
    VehicleHistory: 'VehicleHistory',
    VehicleDamage: 'VehicleDamage',
    Accessory: 'Accessory',
    AccessoryCheck: 'AccessoryCheck',
    VisualInspection: 'VisualInspection',
    DamageDetection: 'DamageDetection',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageCostEvaluation: 'DamageCostEvaluation',
    DamageCostEvaluationAggregate: 'DamageCostEvaluationAggregate',
    RepairOperation: 'RepairOperation'
} as const;

export type DataGrantEntityType = typeof DataGrantEntityType[keyof typeof DataGrantEntityType];


/**
 * 
 * @export
 * @interface DataGrantGetPaginatedDto
 */
export interface DataGrantGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DataGrantGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataGrantGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataGrantGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataGrantGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataGrantGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataGrantGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DataGrantGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DataGrantGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DataGrantGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {DataGrantType}
     * @memberof DataGrantGetPaginatedDto
     */
    'type'?: DataGrantType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantGetPaginatedDto
     */
    'issuerTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataGrantGetPaginatedDto
     */
    'consumerTenantId'?: string | null;
    /**
     * 
     * @type {DataGrantEntityType}
     * @memberof DataGrantGetPaginatedDto
     */
    'grantEntityType'?: DataGrantEntityType;
    /**
     * 
     * @type {EntityType}
     * @memberof DataGrantGetPaginatedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantGetPaginatedDto
     */
    'entityId'?: string | null;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof DataGrantGetPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
}


/**
 * Brief info about DataGrants stored in shared entity.
 * @export
 * @interface DataGrantMeta
 */
export interface DataGrantMeta {
    /**
     * 
     * @type {string}
     * @memberof DataGrantMeta
     */
    'id'?: string;
    /**
     * 
     * @type {DataGrantType}
     * @memberof DataGrantMeta
     */
    'type'?: DataGrantType;
    /**
     * 
     * @type {TenantReference}
     * @memberof DataGrantMeta
     */
    'issuerTenant'?: TenantReference;
    /**
     * 
     * @type {TenantReference}
     * @memberof DataGrantMeta
     */
    'consumerTenant'?: TenantReference;
    /**
     * 
     * @type {DataGrantEntityType}
     * @memberof DataGrantMeta
     */
    'grantEntityType'?: DataGrantEntityType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantMeta
     */
    'entityId'?: string;
    /**
     * 
     * @type {Array<DataGrantPermission>}
     * @memberof DataGrantMeta
     */
    'permissions'?: Array<DataGrantPermission>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DataGrantMeta
     */
    'expiresAt'?: string | null;
    /**
     * 
     * @type {EntityType}
     * @memberof DataGrantMeta
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {EntityTypeEntityIdPair}
     * @memberof DataGrantMeta
     */
    'entityTypeEntityId'?: EntityTypeEntityIdPair;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DataGrantPermission = {
    None: 'None',
    Read: 'Read',
    Write: 'Write',
    Delete: 'Delete'
} as const;

export type DataGrantPermission = typeof DataGrantPermission[keyof typeof DataGrantPermission];


/**
 * 
 * @export
 * @interface DataGrantReferenceDto
 */
export interface DataGrantReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DataGrantReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {DataGrantType}
     * @memberof DataGrantReferenceDto
     */
    'type'?: DataGrantType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantReferenceDto
     */
    'issuerTenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataGrantReferenceDto
     */
    'consumerTenantId'?: string;
    /**
     * 
     * @type {DataGrantEntityType}
     * @memberof DataGrantReferenceDto
     */
    'grantEntityType'?: DataGrantEntityType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantReferenceDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {EntityType}
     * @memberof DataGrantReferenceDto
     */
    'entityType'?: EntityType;
}


/**
 * 
 * @export
 * @interface DataGrantSearchPaginatedDto
 */
export interface DataGrantSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DataGrantSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataGrantSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataGrantSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataGrantSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataGrantSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataGrantSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataGrantSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DataGrantSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DataGrantSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DataGrantSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {DataGrantType}
     * @memberof DataGrantSearchPaginatedDto
     */
    'type'?: DataGrantType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantSearchPaginatedDto
     */
    'issuerTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataGrantSearchPaginatedDto
     */
    'consumerTenantId'?: string | null;
    /**
     * 
     * @type {DataGrantEntityType}
     * @memberof DataGrantSearchPaginatedDto
     */
    'grantEntityType'?: DataGrantEntityType;
    /**
     * 
     * @type {EntityType}
     * @memberof DataGrantSearchPaginatedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantSearchPaginatedDto
     */
    'entityId'?: string | null;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof DataGrantSearchPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DataGrantType = {
    None: 'None',
    TenantToTenant: 'TenantToTenant'
} as const;

export type DataGrantType = typeof DataGrantType[keyof typeof DataGrantType];


/**
 * 
 * @export
 * @interface DataGrantUpdateDto
 */
export interface DataGrantUpdateDto {
    /**
     * 
     * @type {Array<DataGrantPermission>}
     * @memberof DataGrantUpdateDto
     */
    'permissions'?: Array<DataGrantPermission>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DataGrantUpdateDto
     */
    'expiresAt'?: string | null;
    /**
     * 
     * @type {TenantRequestsMetaInputDto}
     * @memberof DataGrantUpdateDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaInputDto;
}
/**
 * 
 * @export
 * @interface DataGrantsMetaBriefDto
 */
export interface DataGrantsMetaBriefDto {
    /**
     * 
     * @type {string}
     * @memberof DataGrantsMetaBriefDto
     */
    'issuerTenantId'?: string;
    /**
     * 
     * @type {DataGrantEntityType}
     * @memberof DataGrantsMetaBriefDto
     */
    'grantEntityType'?: DataGrantEntityType;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DataGrantsMetaBriefDto
     */
    'grantIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DataGrantsMetaBriefDto
     */
    'consumerTenantIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: Array<DataGrantPermission>; }}
     * @memberof DataGrantsMetaBriefDto
     */
    'tenantPermissions'?: { [key: string]: Array<DataGrantPermission>; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: boolean; }; }}
     * @memberof DataGrantsMetaBriefDto
     */
    'tenantPermissionsMap'?: { [key: string]: { [key: string]: boolean; }; };
}


/**
 * 
 * @export
 * @interface DataGrantsMetaDto
 */
export interface DataGrantsMetaDto {
    /**
     * 
     * @type {Array<DataGrantMeta>}
     * @memberof DataGrantsMetaDto
     */
    'grants'?: Array<DataGrantMeta>;
    /**
     * 
     * @type {string}
     * @memberof DataGrantsMetaDto
     */
    'issuerTenantId'?: string;
    /**
     * 
     * @type {DataGrantEntityType}
     * @memberof DataGrantsMetaDto
     */
    'grantEntityType'?: DataGrantEntityType;
    /**
     * 
     * @type {EntityType}
     * @memberof DataGrantsMetaDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantsMetaDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DataGrantsMetaDto
     */
    'grantIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DataGrantsMetaDto
     */
    'consumerTenantIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: Array<DataGrantPermission>; }}
     * @memberof DataGrantsMetaDto
     */
    'tenantPermissions'?: { [key: string]: Array<DataGrantPermission>; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: boolean; }; }}
     * @memberof DataGrantsMetaDto
     */
    'tenantPermissionsMap'?: { [key: string]: { [key: string]: boolean; }; };
}


/**
 * SignalR client methods that can be called by the server.
 * @export
 * @enum {string}
 */

export const DataUpdatesHubClientMethodName = {
    Echo: 'Echo',
    ServerErrorOccurred: 'ServerErrorOccurred',
    EntityChanged: 'EntityChanged'
} as const;

export type DataUpdatesHubClientMethodName = typeof DataUpdatesHubClientMethodName[keyof typeof DataUpdatesHubClientMethodName];


/**
 * SignalR server methods that can be called by clients.
 * @export
 * @enum {string}
 */

export const DataUpdatesHubServerMethodName = {
    Echo: 'Echo',
    SubscribeOnDataUpdates: 'SubscribeOnDataUpdates',
    UnsubscribeFromDataUpdates: 'UnsubscribeFromDataUpdates'
} as const;

export type DataUpdatesHubServerMethodName = typeof DataUpdatesHubServerMethodName[keyof typeof DataUpdatesHubServerMethodName];


/**
 * 
 * @export
 * @interface DateRangeDto
 */
export interface DateRangeDto {
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DateRangeDto
     */
    'from'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DateRangeDto
     */
    'to'?: string | null;
}
/**
 * 
 * @export
 * @interface DateRangeInputDto
 */
export interface DateRangeInputDto {
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DateRangeInputDto
     */
    'from'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DateRangeInputDto
     */
    'to'?: string | null;
}
/**
 * 
 * @export
 * @interface DefaultEmailTemplateDataV2
 */
export interface DefaultEmailTemplateDataV2 {
    /**
     * 
     * @type {EmailLogo}
     * @memberof DefaultEmailTemplateDataV2
     */
    'logo'?: EmailLogo;
    /**
     * 
     * @type {string}
     * @memberof DefaultEmailTemplateDataV2
     */
    'title'?: string;
    /**
     * 
     * @type {Array<EmailSection>}
     * @memberof DefaultEmailTemplateDataV2
     */
    'sections'?: Array<EmailSection>;
    /**
     * 
     * @type {Array<EmailLink>}
     * @memberof DefaultEmailTemplateDataV2
     */
    'links'?: Array<EmailLink>;
    /**
     * 
     * @type {Array<EmailButton>}
     * @memberof DefaultEmailTemplateDataV2
     */
    'buttons'?: Array<EmailButton>;
}
/**
 * 
 * @export
 * @interface DeleteRepairMaterialInfoDto
 */
export interface DeleteRepairMaterialInfoDto {
    /**
     * 
     * @type {Array<RepairSpecDto>}
     * @memberof DeleteRepairMaterialInfoDto
     */
    'affectedRepairSpecs'?: Array<RepairSpecDto>;
}
/**
 * 
 * @export
 * @interface DeleteRepairSparePartInfoDto
 */
export interface DeleteRepairSparePartInfoDto {
    /**
     * 
     * @type {Array<RepairSpecDto>}
     * @memberof DeleteRepairSparePartInfoDto
     */
    'affectedRepairSpecs'?: Array<RepairSpecDto>;
}
/**
 * 
 * @export
 * @interface DeleteRepairWorkInfoDto
 */
export interface DeleteRepairWorkInfoDto {
    /**
     * 
     * @type {Array<RepairSpecDto>}
     * @memberof DeleteRepairWorkInfoDto
     */
    'affectedRepairSpecs'?: Array<RepairSpecDto>;
}
/**
 * 
 * @export
 * @interface DepartmentChangedDto
 */
export interface DepartmentChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DepartmentChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DepartmentChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DepartmentChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof DepartmentChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {DepartmentDto}
     * @memberof DepartmentChangedDto
     */
    'entity'?: DepartmentDto;
    /**
     * 
     * @type {string}
     * @memberof DepartmentChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface DepartmentDto
 */
export interface DepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof DepartmentDto
     */
    'address'?: GeneralAddressDto;
    /**
     * 
     * @type {GeneralBrandingDto}
     * @memberof DepartmentDto
     */
    'branding'?: GeneralBrandingDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof DepartmentDto
     */
    'locationIds'?: Array<string>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DepartmentDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DepartmentDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface DepartmentGetPaginatedDto
 */
export interface DepartmentGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DepartmentGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DepartmentGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DepartmentGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DepartmentGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DepartmentGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DepartmentGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DepartmentGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
}
/**
 * 
 * @export
 * @interface DepartmentReferenceDto
 */
export interface DepartmentReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DepartmentReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DepartmentSearchPaginatedDto
 */
export interface DepartmentSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DepartmentSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DepartmentSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DepartmentSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DepartmentSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DepartmentSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DepartmentSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DepartmentSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
}
/**
 * 
 * @export
 * @interface DisconnectFromChatDto
 */
export interface DisconnectFromChatDto {
    /**
     * 
     * @type {string}
     * @memberof DisconnectFromChatDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisconnectFromChatDto
     */
    'participantId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DiscountType = {
    None: 'None',
    Trade: 'Trade',
    Bulk: 'Bulk',
    PromptPayment: 'PromptPayment'
} as const;

export type DiscountType = typeof DiscountType[keyof typeof DiscountType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DiscountValueType = {
    None: 'None',
    Percent: 'Percent',
    Value: 'Value'
} as const;

export type DiscountValueType = typeof DiscountValueType[keyof typeof DiscountValueType];


/**
 * 
 * @export
 * @interface DocumentChangedDto
 */
export interface DocumentChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DocumentChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DocumentChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof DocumentChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {DocumentDto}
     * @memberof DocumentChangedDto
     */
    'entity'?: DocumentDto;
    /**
     * 
     * @type {string}
     * @memberof DocumentChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface DocumentDto
 */
export interface DocumentDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'prevDocumentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof DocumentDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentDto
     */
    'type'?: DocumentType;
    /**
     * 
     * @type {GeneralEntitySourceDto}
     * @memberof DocumentDto
     */
    'source'?: GeneralEntitySourceDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DocumentDto
     */
    'sourceLastUpdatedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDto
     */
    'isLatestForSource'?: boolean;
    /**
     * 
     * @type {Array<DocumentSourcingStrategy>}
     * @memberof DocumentDto
     */
    'sourcingStrategies'?: Array<DocumentSourcingStrategy>;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfDocumentStage}
     * @memberof DocumentDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfDocumentStage;
    /**
     * 
     * @type {DocumentStatus}
     * @memberof DocumentDto
     */
    'status'?: DocumentStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DocumentDto
     */
    'date'?: string;
    /**
     * 
     * @type {Array<DocumentSectionDto>}
     * @memberof DocumentDto
     */
    'sections'?: Array<DocumentSectionDto>;
    /**
     * 
     * @type {Array<DocumentItemDto>}
     * @memberof DocumentDto
     */
    'items'?: Array<DocumentItemDto>;
    /**
     * 
     * @type {DocumentGeneralDataDto}
     * @memberof DocumentDto
     */
    'generalData'?: DocumentGeneralDataDto;
    /**
     * 
     * @type {DamageDetectionDataDto}
     * @memberof DocumentDto
     */
    'damageDetection'?: DamageDetectionDataDto;
    /**
     * 
     * @type {DamageDetectionAggregateDataDto}
     * @memberof DocumentDto
     */
    'damageDetectionAggregate'?: DamageDetectionAggregateDataDto;
    /**
     * 
     * @type {DamageCostEvaluationDataDto}
     * @memberof DocumentDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationDataDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateDataDto}
     * @memberof DocumentDto
     */
    'damageCostEvaluationAggregate'?: DamageCostEvaluationAggregateDataDto;
    /**
     * 
     * @type {AccessoryCheckDataDto}
     * @memberof DocumentDto
     */
    'accessoryCheck'?: AccessoryCheckDataDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DocumentDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DocumentDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {GeneralScopeInfoDto}
     * @memberof DocumentDto
     */
    'scopeInfo'?: GeneralScopeInfoDto;
    /**
     * 
     * @type {Array<GeneralScopeInfoDto>}
     * @memberof DocumentDto
     */
    'allScopeInfo'?: Array<GeneralScopeInfoDto>;
}


/**
 * 
 * @export
 * @interface DocumentGeneralDataDto
 */
export interface DocumentGeneralDataDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentGeneralDataDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentGeneralDataDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentGeneralDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DocumentGeneralDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
}
/**
 * 
 * @export
 * @interface DocumentGetPaginatedDto
 */
export interface DocumentGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof DocumentGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof DocumentGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof DocumentGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof DocumentGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentGetPaginatedDto
     */
    'type'?: DocumentType;
    /**
     * 
     * @type {DocumentStage}
     * @memberof DocumentGetPaginatedDto
     */
    'stage'?: DocumentStage;
    /**
     * 
     * @type {EntitySourceType}
     * @memberof DocumentGetPaginatedDto
     */
    'sourceType'?: EntitySourceType;
    /**
     * 
     * @type {string}
     * @memberof DocumentGetPaginatedDto
     */
    'sourceId'?: string | null;
}


/**
 * 
 * @export
 * @interface DocumentItemDto
 */
export interface DocumentItemDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentItemDto
     */
    'sectionId'?: string | null;
    /**
     * 
     * @type {GeneralEntitySourceDto}
     * @memberof DocumentItemDto
     */
    'source'?: GeneralEntitySourceDto;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentItemDto
     */
    'type'?: DocumentType;
    /**
     * 
     * @type {DocumentStatus}
     * @memberof DocumentItemDto
     */
    'status'?: DocumentStatus;
    /**
     * 
     * @type {DocumentItemGeneralDataDto}
     * @memberof DocumentItemDto
     */
    'generalData'?: DocumentItemGeneralDataDto;
    /**
     * 
     * @type {DamageDetectionItemDataDto}
     * @memberof DocumentItemDto
     */
    'damageDetectionItem'?: DamageDetectionItemDataDto;
    /**
     * 
     * @type {DamageDetectionAggregateItemDataDto}
     * @memberof DocumentItemDto
     */
    'damageDetectionAggregateItem'?: DamageDetectionAggregateItemDataDto;
    /**
     * 
     * @type {DamageCostEvaluationItemDataDto}
     * @memberof DocumentItemDto
     */
    'damageCostEvaluationItem'?: DamageCostEvaluationItemDataDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateItemDataDto}
     * @memberof DocumentItemDto
     */
    'damageCostEvaluationAggregateItem'?: DamageCostEvaluationAggregateItemDataDto;
    /**
     * 
     * @type {AccessoryCheckItemDataDto}
     * @memberof DocumentItemDto
     */
    'accessoryCheckItem'?: AccessoryCheckItemDataDto;
    /**
     * 
     * @type {GeneralScopeInfoDto}
     * @memberof DocumentItemDto
     */
    'scopeInfo'?: GeneralScopeInfoDto;
}


/**
 * 
 * @export
 * @interface DocumentItemGeneralDataDto
 */
export interface DocumentItemGeneralDataDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentItemGeneralDataDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentItemGeneralDataDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentItemGeneralDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DocumentItemGeneralDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
}
/**
 * 
 * @export
 * @interface DocumentReferenceDto
 */
export interface DocumentReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentReferenceDto
     */
    'type'?: DocumentType;
    /**
     * 
     * @type {GeneralEntitySourceDto}
     * @memberof DocumentReferenceDto
     */
    'source'?: GeneralEntitySourceDto;
}


/**
 * 
 * @export
 * @interface DocumentSectionDto
 */
export interface DocumentSectionDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentSectionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSectionDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentSectionDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentSectionDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentSectionDto
     */
    'itemIds'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentSourcingStrategy = {
    None: 'None',
    CreateOnCreate: 'CreateOnCreate',
    UpdateOnUpdate: 'UpdateOnUpdate',
    CreateOnUpdate: 'CreateOnUpdate',
    DeleteOnDelete: 'DeleteOnDelete',
    UpdateOnDelete: 'UpdateOnDelete'
} as const;

export type DocumentSourcingStrategy = typeof DocumentSourcingStrategy[keyof typeof DocumentSourcingStrategy];


/**
 * NB: use Common.Attributes.SortOrderAttribute to specify the order in which stages can be activated.
 * @export
 * @enum {string}
 */

export const DocumentStage = {
    None: 'None',
    Draft: 'Draft',
    Negotiating: 'Negotiating',
    Processed: 'Processed',
    Signed: 'Signed',
    Archived: 'Archived'
} as const;

export type DocumentStage = typeof DocumentStage[keyof typeof DocumentStage];


/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentStatus = {
    None: 'None'
} as const;

export type DocumentStatus = typeof DocumentStatus[keyof typeof DocumentStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentType = {
    None: 'None',
    General: 'General',
    Typed: 'Typed'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * 
 * @export
 * @interface EmailButton
 */
export interface EmailButton {
    /**
     * 
     * @type {string}
     * @memberof EmailButton
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailButton
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailButton
     */
    'backgroundColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailButton
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailButton
     */
    'fontSize'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailButton
     */
    'lineHeight'?: string;
}
/**
 * 
 * @export
 * @interface EmailChannelInfoDto
 */
export interface EmailChannelInfoDto {
    /**
     * 
     * @type {string}
     * @memberof EmailChannelInfoDto
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailChannelInfoDto
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailChannelInfoDto
     */
    'templateId'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof EmailChannelInfoDto
     */
    'templateData'?: any | null;
    /**
     * 
     * @type {DefaultEmailTemplateDataV2}
     * @memberof EmailChannelInfoDto
     */
    'defaultTemplateData'?: DefaultEmailTemplateDataV2;
}
/**
 * 
 * @export
 * @interface EmailLink
 */
export interface EmailLink {
    /**
     * 
     * @type {string}
     * @memberof EmailLink
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailLink
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailLink
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailLink
     */
    'fontWeight'?: string | null;
}
/**
 * 
 * @export
 * @interface EmailLogo
 */
export interface EmailLogo {
    /**
     * 
     * @type {string}
     * @memberof EmailLogo
     */
    'imageSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailLogo
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EmailProviderType = {
    None: 'None',
    Default: 'Default',
    SendGrid: 'SendGrid',
    Mailgun: 'Mailgun',
    AmazonSes: 'AmazonSes',
    Mailchimp: 'Mailchimp',
    Microsoft365: 'Microsoft365',
    AzureCs: 'AzureCs',
    Smtp: 'Smtp'
} as const;

export type EmailProviderType = typeof EmailProviderType[keyof typeof EmailProviderType];


/**
 * 
 * @export
 * @interface EmailSection
 */
export interface EmailSection {
    /**
     * 
     * @type {string}
     * @memberof EmailSection
     */
    'text'?: string;
    /**
     * 
     * @type {Array<EmailLink>}
     * @memberof EmailSection
     */
    'sectionLinks'?: Array<EmailLink> | null;
    /**
     * 
     * @type {Array<EmailButton>}
     * @memberof EmailSection
     */
    'sectionButtons'?: Array<EmailButton> | null;
}
/**
 * 
 * @export
 * @interface EntitiesPoolsGetRequestDto
 */
export interface EntitiesPoolsGetRequestDto {
    /**
     * 
     * @type {Array<EntityPoolsGetDto>}
     * @memberof EntitiesPoolsGetRequestDto
     */
    'entities'?: Array<EntityPoolsGetDto> | null;
}
/**
 * 
 * @export
 * @interface EntityBulkDeleteDto
 */
export interface EntityBulkDeleteDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityBulkDeleteDto
     */
    'ids'?: Array<string>;
    /**
     * 
     * @type {Array<EntityType>}
     * @memberof EntityBulkDeleteDto
     */
    'relatedEntitiesTypesToDelete'?: Array<EntityType>;
}
/**
 * Describes type of already applied change.
 * @export
 * @enum {string}
 */

export const EntityChangeType = {
    None: 'None',
    Created: 'Created',
    Updated: 'Updated',
    Deleted: 'Deleted'
} as const;

export type EntityChangeType = typeof EntityChangeType[keyof typeof EntityChangeType];


/**
 * 
 * @export
 * @interface EntityChangedDtoOfTEntityDto
 */
export interface EntityChangedDtoOfTEntityDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {object}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'entity'?: object;
    /**
     * 
     * @type {string}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface EntityImportMetaDto
 */
export interface EntityImportMetaDto {
    /**
     * 
     * @type {string}
     * @memberof EntityImportMetaDto
     */
    'importId'?: string;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof EntityImportMetaDto
     */
    'changeType'?: EntityChangeType;
}


/**
 * Contains metadata related to results of matching/searching an entity by some criteria.
 * @export
 * @interface EntityMatchMetaDto
 */
export interface EntityMatchMetaDto {
    /**
     * Match score.  The higher value the better match.
     * @type {number}
     * @memberof EntityMatchMetaDto
     */
    'score'?: number;
}
/**
 * 
 * @export
 * @interface EntityPoolsDto
 */
export interface EntityPoolsDto {
    /**
     * 
     * @type {EntityPoolsGetRequest}
     * @memberof EntityPoolsDto
     */
    'request'?: EntityPoolsGetRequest;
    /**
     * 
     * @type {Array<PoolEntity>}
     * @memberof EntityPoolsDto
     */
    'pools'?: Array<PoolEntity>;
    /**
     * 
     * @type {Array<PoolEntity>}
     * @memberof EntityPoolsDto
     */
    'assetPools'?: Array<PoolEntity> | null;
}
/**
 * 
 * @export
 * @interface EntityPoolsGetDto
 */
export interface EntityPoolsGetDto {
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof EntityPoolsGetDto
     */
    'entityType'?: PoolItemEntityType;
    /**
     * 
     * @type {string}
     * @memberof EntityPoolsGetDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EntityPoolsGetDto
     */
    'isIncludeAssetPools'?: boolean;
}


/**
 * 
 * @export
 * @interface EntityPoolsGetRequest
 */
export interface EntityPoolsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EntityPoolsGetRequest
     */
    'tenantId'?: string;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof EntityPoolsGetRequest
     */
    'entityType'?: PoolItemEntityType;
    /**
     * 
     * @type {string}
     * @memberof EntityPoolsGetRequest
     */
    'entityId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EntityPoolsGetRequest
     */
    'isIncludeAssetPools'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const EntitySourceSubType = {
    None: 'None',
    DamageDetection: 'DamageDetection',
    DamageDetectionItem: 'DamageDetectionItem',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageDetectionAggregateItem: 'DamageDetectionAggregateItem',
    DamageCostEvaluationItem: 'DamageCostEvaluationItem',
    DamageCostEvaluationAggregateItem: 'DamageCostEvaluationAggregateItem',
    AccessoryCheckItem: 'AccessoryCheckItem'
} as const;

export type EntitySourceSubType = typeof EntitySourceSubType[keyof typeof EntitySourceSubType];


/**
 * 
 * @export
 * @enum {string}
 */

export const EntitySourceType = {
    None: 'None',
    VisualInspection: 'VisualInspection',
    DamageDetection: 'DamageDetection',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageCostEvaluation: 'DamageCostEvaluation',
    DamageCostEvaluationAggregate: 'DamageCostEvaluationAggregate',
    AccessoryCheck: 'AccessoryCheck',
    RepairOperation: 'RepairOperation',
    Document: 'Document'
} as const;

export type EntitySourceType = typeof EntitySourceType[keyof typeof EntitySourceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const EntityType = {
    None: 'None',
    User: 'User',
    Role: 'Role',
    Company: 'Company',
    Department: 'Department',
    Location: 'Location',
    Spot: 'Spot',
    Vehicle: 'Vehicle',
    VehicleHistory: 'VehicleHistory',
    VehicleDamage: 'VehicleDamage',
    Contract: 'Contract',
    Customer: 'Customer',
    Accessory: 'Accessory',
    VisualInspection: 'VisualInspection',
    DamageDetection: 'DamageDetection',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageCostEvaluation: 'DamageCostEvaluation',
    DamageCostEvaluationAggregate: 'DamageCostEvaluationAggregate',
    AccessoryCheck: 'AccessoryCheck',
    RepairOperation: 'RepairOperation',
    Document: 'Document',
    DocumentItem: 'DocumentItem',
    Chat: 'Chat',
    ChatParticipant: 'ChatParticipant',
    ChatMessage: 'ChatMessage',
    ChatHistoryItem: 'ChatHistoryItem',
    ChatEvent: 'ChatEvent',
    ChatActivity: 'ChatActivity',
    ChatUserSettings: 'ChatUserSettings',
    Negotiation: 'Negotiation',
    NegotiationProposal: 'NegotiationProposal',
    File: 'File',
    Invoice: 'Invoice',
    Invite: 'Invite',
    VehicleType: 'VehicleType',
    VehicleMake: 'VehicleMake',
    VehicleModel: 'VehicleModel',
    VehicleGeneration: 'VehicleGeneration',
    VehicleModification: 'VehicleModification',
    VehicleBodyType: 'VehicleBodyType',
    VehicleFuelType: 'VehicleFuelType',
    VehiclePartType: 'VehiclePartType',
    VehicleVisualModel: 'VehicleVisualModel',
    DamageType: 'DamageType',
    RepairCatalog: 'RepairCatalog',
    RepairWork: 'RepairWork',
    RepairMaterial: 'RepairMaterial',
    RepairSpec: 'RepairSpec',
    RepairSparePart: 'RepairSparePart',
    SubscriptionPlan: 'SubscriptionPlan',
    Subscription: 'Subscription',
    AssetSubscriptionPlan: 'AssetSubscriptionPlan',
    AssetSubscription: 'AssetSubscription',
    ProductLocation: 'ProductLocation',
    Pool: 'Pool',
    PoolItem: 'PoolItem',
    Asset: 'Asset',
    IntegrationApiClient: 'IntegrationApiClient',
    TenantConnectionRequest: 'TenantConnectionRequest',
    TenantConnection: 'TenantConnection',
    TenantRequest: 'TenantRequest',
    DataGrant: 'DataGrant',
    AssessmentFlow: 'AssessmentFlow',
    UserSecret: 'UserSecret',
    CustomIdentity: 'CustomIdentity',
    CustomSession: 'CustomSession',
    FlowInstance: 'FlowInstance',
    FlowSpec: 'FlowSpec',
    FlowStepSpec: 'FlowStepSpec',
    GeneralEventLog: 'GeneralEventLog',
    Import: 'Import',
    ImportMappingTemplate: 'ImportMappingTemplate',
    NumberRegistryItem: 'NumberRegistryItem',
    Party: 'Party',
    PartyMember: 'PartyMember',
    TestDamageAssessmentReport: 'TestDamageAssessmentReport',
    Test: 'Test',
    UserVerificationRequest: 'UserVerificationRequest',
    DeviceToken: 'DeviceToken',
    Notification: 'Notification',
    NotificationGroup: 'NotificationGroup',
    NotificationGroupMember: 'NotificationGroupMember',
    NotificationProfile: 'NotificationProfile',
    CustomTag: 'CustomTag',
    Webhook: 'Webhook',
    WebhookActivation: 'WebhookActivation',
    GeneralHistory: 'GeneralHistory',
    TeslaConnection: 'TeslaConnection'
} as const;

export type EntityType = typeof EntityType[keyof typeof EntityType];


/**
 * 
 * @export
 * @interface EntityTypeEntityIdPair
 */
export interface EntityTypeEntityIdPair {
    /**
     * 
     * @type {EntityType}
     * @memberof EntityTypeEntityIdPair
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof EntityTypeEntityIdPair
     */
    'entityId'?: string;
}


/**
 * 
 * @export
 * @interface EntityTypeEntityIdPairInputDto
 */
export interface EntityTypeEntityIdPairInputDto {
    /**
     * 
     * @type {EntityType}
     * @memberof EntityTypeEntityIdPairInputDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof EntityTypeEntityIdPairInputDto
     */
    'entityId'?: string;
}


/**
 * 
 * @export
 * @interface EnumCatalogDto
 */
export interface EnumCatalogDto {
    /**
     * {{EnumTypeName}: Dto}
     * @type {{ [key: string]: Array<EnumDto>; }}
     * @memberof EnumCatalogDto
     */
    'enumsMap'?: { [key: string]: Array<EnumDto>; };
    /**
     * {{EnumTypeName}: Dto}
     * @type {{ [key: string]: EnumTransitionSpecDto; }}
     * @memberof EnumCatalogDto
     */
    'enumTransitionsMap'?: { [key: string]: EnumTransitionSpecDto; };
}
/**
 * Represents enum data retrieved from System.ComponentModel.DataAnnotations.DisplayAttribute
 * @export
 * @interface EnumDto
 */
export interface EnumDto {
    /**
     * 
     * @type {string}
     * @memberof EnumDto
     */
    'enumTypeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnumDto
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnumDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnumDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnumDto
     */
    'shortName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnumDto
     */
    'description'?: string | null;
    /**
     * List enum values to which this enum value is linked.
     * @type {Array<EnumLinkDto>}
     * @memberof EnumDto
     */
    'enumLinks'?: Array<EnumLinkDto>;
}
/**
 * 
 * @export
 * @interface EnumLinkDto
 */
export interface EnumLinkDto {
    /**
     * 
     * @type {string}
     * @memberof EnumLinkDto
     */
    'enumTypeName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnumLinkDto
     */
    'enumValues'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof EnumLinkDto
     */
    'enumValuesMap'?: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface EnumTransitionSpecDto
 */
export interface EnumTransitionSpecDto {
    /**
     * 
     * @type {string}
     * @memberof EnumTransitionSpecDto
     */
    'enumTypeName'?: string;
    /**
     * 
     * @type {{ [key: string]: EnumTransitionSpecEntryDto; }}
     * @memberof EnumTransitionSpecDto
     */
    'entriesMap'?: { [key: string]: EnumTransitionSpecEntryDto; };
    /**
     * 
     * @type {Array<string>}
     * @memberof EnumTransitionSpecDto
     */
    'ignoredValues'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface EnumTransitionSpecEntryDto
 */
export interface EnumTransitionSpecEntryDto {
    /**
     * 
     * @type {string}
     * @memberof EnumTransitionSpecEntryDto
     */
    'source'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnumTransitionSpecEntryDto
     */
    'exactTargets'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnumTransitionSpecEntryDto
     */
    'exceptTargets'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnumTransitionSpecEntryDto
     */
    'ignoredTargets'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnumTransitionSpecEntryDto
     */
    'targets'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof EnumTransitionSpecEntryDto
     */
    'targetsMap'?: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ExportEntityType = {
    None: 'None',
    Vehicle: 'Vehicle',
    Contract: 'Contract',
    AssessmentFlow: 'AssessmentFlow',
    Accessory: 'Accessory',
    Customer: 'Customer',
    DamageDetection: 'DamageDetection',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageCostEvaluation: 'DamageCostEvaluation',
    DamageCostEvaluationAggregate: 'DamageCostEvaluationAggregate',
    AccessoryCheck: 'AccessoryCheck',
    VisualInspection: 'VisualInspection'
} as const;

export type ExportEntityType = typeof ExportEntityType[keyof typeof ExportEntityType];


/**
 * 
 * @export
 * @interface ExportOptionsDto
 */
export interface ExportOptionsDto {
    /**
     * 
     * @type {ExportEntityType}
     * @memberof ExportOptionsDto
     */
    'entityType'?: ExportEntityType;
    /**
     * 
     * @type {string}
     * @memberof ExportOptionsDto
     */
    'mimeType'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportOptionsDto
     */
    'properties'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ExportOptionsDto
     */
    'isExportAll'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportOptionsDto
     */
    'ids'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface FileChangedDto
 */
export interface FileChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof FileChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof FileChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof FileChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof FileChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof FileChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {FileDto}
     * @memberof FileChangedDto
     */
    'entity'?: FileDto;
    /**
     * 
     * @type {string}
     * @memberof FileChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'originalFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'sizeBytes'?: number;
    /**
     * 
     * @type {Array<FileThumbnailDto>}
     * @memberof FileDto
     */
    'thumbnails'?: Array<FileThumbnailDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof FileDto
     */
    'uploadedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof FileDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'extension'?: string | null;
    /**
     * 
     * @type {MimeBaseType}
     * @memberof FileDto
     */
    'mimeBaseType'?: MimeBaseType;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'url'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof FileDto
     */
    'urlLifetime'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof FileDto
     */
    'urlExpiresAt'?: string | null;
}


/**
 * 
 * @export
 * @interface FileReferenceDto
 */
export interface FileReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof FileReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileReferenceDto
     */
    'originalFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileReferenceDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileReferenceDto
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileReferenceDto
     */
    'sizeBytes'?: number;
    /**
     * 
     * @type {Array<FileThumbnailDto>}
     * @memberof FileReferenceDto
     */
    'thumbnails'?: Array<FileThumbnailDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof FileReferenceDto
     */
    'uploadedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileReferenceDto
     */
    'extension'?: string | null;
    /**
     * 
     * @type {MimeBaseType}
     * @memberof FileReferenceDto
     */
    'mimeBaseType'?: MimeBaseType;
    /**
     * 
     * @type {string}
     * @memberof FileReferenceDto
     */
    'url'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof FileReferenceDto
     */
    'urlLifetime'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof FileReferenceDto
     */
    'urlExpiresAt'?: string | null;
}


/**
 * 
 * @export
 * @interface FileThumbnailDto
 */
export interface FileThumbnailDto {
    /**
     * 
     * @type {ThumbnailSizeType}
     * @memberof FileThumbnailDto
     */
    'sizeType'?: ThumbnailSizeType;
    /**
     * 
     * @type {GeneralImageSizeDto}
     * @memberof FileThumbnailDto
     */
    'size'?: GeneralImageSizeDto;
    /**
     * 
     * @type {string}
     * @memberof FileThumbnailDto
     */
    'url'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof FileThumbnailDto
     */
    'urlLifetime'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof FileThumbnailDto
     */
    'urlExpiresAt'?: string | null;
}


/**
 * 
 * @export
 * @interface FileUploadSpecDto
 */
export interface FileUploadSpecDto {
    /**
     * Max number of files that is allowed to be uploaded simultaneously via one request.
     * @type {number}
     * @memberof FileUploadSpecDto
     */
    'uploadSimultaneousFilesLimit'?: number;
    /**
     * Max allowed file size in bytes.
     * @type {number}
     * @memberof FileUploadSpecDto
     */
    'uploadFileSizeLimitBytes'?: number;
    /**
     * Max lifetime of SAS URL that can be requested by a client via the API.
     * @type {string}
     * @memberof FileUploadSpecDto
     */
    'sasUrlLifetimeLimit'?: string;
    /**
     * Default lifetime of SAS URL for files returned via the API.
     * @type {string}
     * @memberof FileUploadSpecDto
     */
    'sasUrlDefaultLifetimeInDto'?: string;
    /**
     * List of allowed file extensions.
     * @type {Array<string>}
     * @memberof FileUploadSpecDto
     */
    'uploadPermittedExtensions'?: Array<string>;
    /**
     * List of allowed file content types.
     * @type {Array<string>}
     * @memberof FileUploadSpecDto
     */
    'uploadPermittedContentTypes'?: Array<string>;
    /**
     * Max allowed file size in bytes per file extension.
     * @type {{ [key: string]: number; }}
     * @memberof FileUploadSpecDto
     */
    'uploadFileSizeLimitBytesPerExtensionMap'?: { [key: string]: number; };
    /**
     * Max allowed file size in bytes per file content type.
     * @type {{ [key: string]: number; }}
     * @memberof FileUploadSpecDto
     */
    'uploadFileSizeLimitBytesPerContentTypeMap'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface FilterDefinitionDto
 */
export interface FilterDefinitionDto {
    /**
     * 
     * @type {Array<FilterDefinitionItemDto>}
     * @memberof FilterDefinitionDto
     */
    'items'?: Array<FilterDefinitionItemDto> | null;
}
/**
 * 
 * @export
 * @interface FilterDefinitionItemDto
 */
export interface FilterDefinitionItemDto {
    /**
     * 
     * @type {FilterType}
     * @memberof FilterDefinitionItemDto
     */
    'type'?: FilterType;
    /**
     * 
     * @type {string}
     * @memberof FilterDefinitionItemDto
     */
    'field'?: string;
    /**
     * 
     * @type {FilterOperator}
     * @memberof FilterDefinitionItemDto
     */
    'operator'?: FilterOperator;
    /**
     * 
     * @type {FilterValueType}
     * @memberof FilterDefinitionItemDto
     */
    'valueType'?: FilterValueType;
    /**
     * 
     * @type {any}
     * @memberof FilterDefinitionItemDto
     */
    'value'?: any | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FilterOperator = {
    None: 'None',
    Equal: 'Equal',
    NotEqual: 'NotEqual',
    Contain: 'Contain',
    NotContain: 'NotContain',
    LessThan: 'LessThan',
    LessThanOrEqual: 'LessThanOrEqual',
    GreaterThan: 'GreaterThan',
    GreaterThanOrEqual: 'GreaterThanOrEqual',
    AnyOf: 'AnyOf',
    NoneOf: 'NoneOf',
    Empty: 'Empty',
    NotEmpty: 'NotEmpty'
} as const;

export type FilterOperator = typeof FilterOperator[keyof typeof FilterOperator];


/**
 * 
 * @export
 * @enum {string}
 */

export const FilterType = {
    None: 'None',
    Static: 'Static',
    Dynamic: 'Dynamic'
} as const;

export type FilterType = typeof FilterType[keyof typeof FilterType];


/**
 * 
 * @export
 * @enum {string}
 */

export const FilterValueType = {
    None: 'None',
    Arbitrary: 'Arbitrary',
    String: 'String',
    Number: 'Number',
    Boolean: 'Boolean',
    Date: 'Date',
    Enum: 'Enum',
    Id: 'Id',
    ArrayOfString: 'ArrayOfString',
    ArrayOfEnum: 'ArrayOfEnum',
    ArrayOfId: 'ArrayOfId'
} as const;

export type FilterValueType = typeof FilterValueType[keyof typeof FilterValueType];


/**
 * 
 * @export
 * @interface ForAccountDtoInAdminCreateCustomSessionDto
 */
export interface ForAccountDtoInAdminCreateCustomSessionDto {
    /**
     * 
     * @type {string}
     * @memberof ForAccountDtoInAdminCreateCustomSessionDto
     */
    'userId'?: string | null;
}
/**
 * Describes params of the action to be automatically performed on the FE page when user opens it.
 * @export
 * @interface FrontEndActionParamsInQueryStringDto
 */
export interface FrontEndActionParamsInQueryStringDto {
    /**
     * 
     * @type {FrontEndActionTypeInQueryString}
     * @memberof FrontEndActionParamsInQueryStringDto
     */
    'actionType'?: FrontEndActionTypeInQueryString;
}


/**
 * Describes type of action to be automatically performed on the FE page when user opens it.
 * @export
 * @enum {string}
 */

export const FrontEndActionTypeInQueryString = {
    None: 'None',
    ApproveAllDamageNegotiationProposals: 'ApproveAllDamageNegotiationProposals',
    DeclineAllDamageNegotiationProposals: 'DeclineAllDamageNegotiationProposals',
    ApproveAllDamageCostNegotiationProposals: 'ApproveAllDamageCostNegotiationProposals',
    DeclineAllDamageCostNegotiationProposals: 'DeclineAllDamageCostNegotiationProposals'
} as const;

export type FrontEndActionTypeInQueryString = typeof FrontEndActionTypeInQueryString[keyof typeof FrontEndActionTypeInQueryString];


/**
 * 
 * @export
 * @enum {string}
 */

export const FrontEndAppType = {
    None: 'None',
    Web: 'Web',
    Mobile: 'Mobile'
} as const;

export type FrontEndAppType = typeof FrontEndAppType[keyof typeof FrontEndAppType];


/**
 * Describes auth params that FE app should apply when loaded.
 * @export
 * @interface FrontEndAuthParamsInQueryStringDto
 */
export interface FrontEndAuthParamsInQueryStringDto {
    /**
     * Auth0 connection to be used when logging in.
     * @type {string}
     * @memberof FrontEndAuthParamsInQueryStringDto
     */
    'preferredAuth0Connection'?: string | null;
    /**
     * The user\'s email address or other identifier.  Used during login to prefill inputs.
     * @type {string}
     * @memberof FrontEndAuthParamsInQueryStringDto
     */
    'auth0LoginHint'?: string | null;
    /**
     * Whether to prefer quick login - skip SPA auth pages (like login, logged out, etc) and redirect   directly to Auth0 login page if user is not authenticated.
     * @type {boolean}
     * @memberof FrontEndAuthParamsInQueryStringDto
     */
    'isPreferQuickLogin'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GeneralAddressDto
 */
export interface GeneralAddressDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressDto
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressDto
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressDto
     */
    'line1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressDto
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressDto
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralAddressDto
     */
    'isEmpty'?: boolean;
}
/**
 * 
 * @export
 * @interface GeneralAddressInputDto
 */
export interface GeneralAddressInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressInputDto
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressInputDto
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressInputDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressInputDto
     */
    'line1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressInputDto
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressInputDto
     */
    'postalCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralApprovalStatusInfoDto
 */
export interface GeneralApprovalStatusInfoDto {
    /**
     * 
     * @type {ApprovalStatus}
     * @memberof GeneralApprovalStatusInfoDto
     */
    'status'?: ApprovalStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralApprovalStatusInfoDto
     */
    'updatedAt'?: string;
}


/**
 * 
 * @export
 * @interface GeneralAttachedTagsDto
 */
export interface GeneralAttachedTagsDto {
    /**
     * 
     * @type {Array<GeneralTagDto>}
     * @memberof GeneralAttachedTagsDto
     */
    'tags'?: Array<GeneralTagDto> | null;
}
/**
 * 
 * @export
 * @interface GeneralAttachedTagsInputDto
 */
export interface GeneralAttachedTagsInputDto {
    /**
     * 
     * @type {Array<GeneralTagInputDto>}
     * @memberof GeneralAttachedTagsInputDto
     */
    'tags'?: Array<GeneralTagInputDto> | null;
}
/**
 * 
 * @export
 * @interface GeneralAttachedTagsOfEntitiesDeleteDto
 */
export interface GeneralAttachedTagsOfEntitiesDeleteDto {
    /**
     * 
     * @type {TagEntityType}
     * @memberof GeneralAttachedTagsOfEntitiesDeleteDto
     */
    'tagEntityType'?: TagEntityType;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralAttachedTagsOfEntitiesDeleteDto
     */
    'entityIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralAttachedTagsOfEntitiesDeleteDto
     */
    'tagIds'?: Array<string>;
}


/**
 * 
 * @export
 * @interface GeneralAttachedTagsOfEntitiesUpdateDto
 */
export interface GeneralAttachedTagsOfEntitiesUpdateDto {
    /**
     * 
     * @type {TagEntityType}
     * @memberof GeneralAttachedTagsOfEntitiesUpdateDto
     */
    'tagEntityType'?: TagEntityType;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralAttachedTagsOfEntitiesUpdateDto
     */
    'entityIds'?: Array<string>;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof GeneralAttachedTagsOfEntitiesUpdateDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralAttachedTagsOfEntitiesUpdateDto
     */
    'isReplace'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralAttachedTagsOfEntityDeleteDto
 */
export interface GeneralAttachedTagsOfEntityDeleteDto {
    /**
     * 
     * @type {TagEntityType}
     * @memberof GeneralAttachedTagsOfEntityDeleteDto
     */
    'entityType'?: TagEntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralAttachedTagsOfEntityDeleteDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralAttachedTagsOfEntityDeleteDto
     */
    'tagIds'?: Array<string>;
}


/**
 * 
 * @export
 * @interface GeneralAttachedTagsOfEntityUpdateDto
 */
export interface GeneralAttachedTagsOfEntityUpdateDto {
    /**
     * 
     * @type {TagEntityType}
     * @memberof GeneralAttachedTagsOfEntityUpdateDto
     */
    'entityType'?: TagEntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralAttachedTagsOfEntityUpdateDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof GeneralAttachedTagsOfEntityUpdateDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}


/**
 * 
 * @export
 * @interface GeneralAttachedTagsQueryDto
 */
export interface GeneralAttachedTagsQueryDto {
    /**
     * 
     * @type {Array<TagTargetType>}
     * @memberof GeneralAttachedTagsQueryDto
     */
    'targetTypes'?: Array<TagTargetType> | null;
    /**
     * 
     * @type {Array<TagSubTargetType>}
     * @memberof GeneralAttachedTagsQueryDto
     */
    'subTargetTypes'?: Array<TagSubTargetType> | null;
    /**
     * 
     * @type {Array<VisibilityStatus>}
     * @memberof GeneralAttachedTagsQueryDto
     */
    'visibilityStatuses'?: Array<VisibilityStatus> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralAttachedTagsQueryDto
     */
    'targetIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralAttachedTagsQueryDto
     */
    'subTargetIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAttachedTagsQueryDto
     */
    'search'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralAttachmentDto
 */
export interface GeneralAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralAttachmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {FileReferenceDto}
     * @memberof GeneralAttachmentDto
     */
    'file'?: FileReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralAttachmentDto
     */
    'caption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAttachmentDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralAttachmentDto
     */
    'isAttachment'?: boolean;
}
/**
 * Describes adding already uploaded file as attachment to something.
 * @export
 * @interface GeneralAttachmentInputDto
 */
export interface GeneralAttachmentInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralAttachmentInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {UploadedFileDto}
     * @memberof GeneralAttachmentInputDto
     */
    'file'?: UploadedFileDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralAttachmentInputDto
     */
    'caption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAttachmentInputDto
     */
    'notes'?: string | null;
    /**
     * The file in the attachment must be copied before saving.  When set to true it means that provided file was not uploaded as a new file, but rather was taken from attachments of other entity,  and therefor must be copied before saving to avoid multiple entities reference the same file (each entity must have own file copy).
     * @type {boolean}
     * @memberof GeneralAttachmentInputDto
     */
    'mustBeCopied'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralAttachmentInputDto
     */
    'isAttachmentInput'?: boolean;
}
/**
 * 
 * @export
 * @interface GeneralAvatarAddDto
 */
export interface GeneralAvatarAddDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralAvatarAddDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {UploadedFileDto}
     * @memberof GeneralAvatarAddDto
     */
    'file'?: UploadedFileDto;
}
/**
 * 
 * @export
 * @interface GeneralAvatarDto
 */
export interface GeneralAvatarDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralAvatarDto
     */
    'id'?: string;
    /**
     * 
     * @type {FileReferenceDto}
     * @memberof GeneralAvatarDto
     */
    'file'?: FileReferenceDto;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralAvatarDto
     */
    'isAvatar'?: boolean;
}
/**
 * 
 * @export
 * @interface GeneralBrandingDto
 */
export interface GeneralBrandingDto {
    /**
     * 
     * @type {GeneralLogoResizedDto}
     * @memberof GeneralBrandingDto
     */
    'logo'?: GeneralLogoResizedDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralBrandingDto
     */
    'fullLegalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralBrandingDto
     */
    'shortName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralBrandingDto
     */
    'disclaimer'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralBrandingInputDto
 */
export interface GeneralBrandingInputDto {
    /**
     * 
     * @type {GeneralLogoInputDto}
     * @memberof GeneralBrandingInputDto
     */
    'logo'?: GeneralLogoInputDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralBrandingInputDto
     */
    'fullLegalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralBrandingInputDto
     */
    'shortName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralBrandingInputDto
     */
    'disclaimer'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralBrandingInputMetaDto
 */
export interface GeneralBrandingInputMetaDto {
    /**
     * 
     * @type {GeneralBrandingInputMetaForLogoDto}
     * @memberof GeneralBrandingInputMetaDto
     */
    'logo'?: GeneralBrandingInputMetaForLogoDto;
}
/**
 * 
 * @export
 * @interface GeneralBrandingInputMetaForLogoDto
 */
export interface GeneralBrandingInputMetaForLogoDto {
    /**
     * 
     * @type {GeneralImageSizeDto}
     * @memberof GeneralBrandingInputMetaForLogoDto
     */
    'minSize'?: GeneralImageSizeDto;
    /**
     * 
     * @type {GeneralImageSizeDto}
     * @memberof GeneralBrandingInputMetaForLogoDto
     */
    'maxSize'?: GeneralImageSizeDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralBrandingInputMetaForLogoDto
     */
    'allowedContentTypes'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralBrandingInputMetaForLogoDto
     */
    'allowedFileExtensions'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GeneralByWhoDto
 */
export interface GeneralByWhoDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {WhoType}
     * @memberof GeneralByWhoDto
     */
    'type'?: WhoType;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof GeneralByWhoDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoDto
     */
    'phone'?: string | null;
}


/**
 * 
 * @export
 * @interface GeneralByWhoInputDto
 */
export interface GeneralByWhoInputDto {
    /**
     * 
     * @type {boolean}
     * @memberof GeneralByWhoInputDto
     */
    'isCurrentUser'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoInputDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoInputDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoInputDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoInputDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {GeneralPersonNameInputDto}
     * @memberof GeneralByWhoInputDto
     */
    'personName'?: GeneralPersonNameInputDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoInputDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoInputDto
     */
    'phone'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralCountryDto
 */
export interface GeneralCountryDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCountryDto
     */
    'alpha2Code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCountryDto
     */
    'alpha3Code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCountryDto
     */
    'numericCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCountryDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCountryDto
     */
    'officialName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCountryDto
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface GeneralCountryInputDto
 */
export interface GeneralCountryInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCountryInputDto
     */
    'alpha2Code'?: string;
}
/**
 * 
 * @export
 * @interface GeneralCultureDto
 */
export interface GeneralCultureDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCultureDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCultureDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCultureDto
     */
    'nativeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCultureDto
     */
    'englishName'?: string;
    /**
     * The same as name.
     * @type {string}
     * @memberof GeneralCultureDto
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface GeneralCultureInputDto
 */
export interface GeneralCultureInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCultureInputDto
     */
    'name'?: string;
}
/**
 * Represents ISO 4217 currency.  https://en.wikipedia.org/wiki/ISO_4217
 * @export
 * @interface GeneralCurrency
 */
export interface GeneralCurrency {
    /**
     * 
     * @type {CurrencyCode}
     * @memberof GeneralCurrency
     */
    'code'?: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof GeneralCurrency
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface GeneralCurrencyDto
 */
export interface GeneralCurrencyDto {
    /**
     * 
     * @type {CurrencyCode}
     * @memberof GeneralCurrencyDto
     */
    'code'?: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof GeneralCurrencyDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCurrencyDto
     */
    'id'?: string;
}


/**
 * 
 * @export
 * @interface GeneralCurrencyInputDto
 */
export interface GeneralCurrencyInputDto {
    /**
     * 
     * @type {CurrencyCode}
     * @memberof GeneralCurrencyInputDto
     */
    'code'?: CurrencyCode;
}


/**
 * 
 * @export
 * @interface GeneralDeleteEntityDto
 */
export interface GeneralDeleteEntityDto {
    /**
     * 
     * @type {boolean}
     * @memberof GeneralDeleteEntityDto
     */
    'isDeleteAllRelatedData'?: boolean;
}
/**
 * Represents a percentage/amount from goods price that is deducted from the final price.  Stored as positive number and is always deducted from SubTotal (adding discount to SubTotal is not allowed).
 * @export
 * @interface GeneralDiscount
 */
export interface GeneralDiscount {
    /**
     * 
     * @type {DiscountType}
     * @memberof GeneralDiscount
     */
    'type'?: DiscountType;
    /**
     * 
     * @type {DiscountValueType}
     * @memberof GeneralDiscount
     */
    'valueType'?: DiscountValueType;
    /**
     * Percents [0; 1].
     * @type {number}
     * @memberof GeneralDiscount
     */
    'percent'?: number | null;
    /**
     * Amount in some currency representation.
     * @type {number}
     * @memberof GeneralDiscount
     */
    'value'?: number | null;
    /**
     * 
     * @type {GeneralCurrency}
     * @memberof GeneralDiscount
     */
    'currency'?: GeneralCurrency;
    /**
     * Discount that is a result of merging N discounts into one.
     * @type {boolean}
     * @memberof GeneralDiscount
     */
    'isCompound'?: boolean;
}


/**
 * <inheritdoc cref=\"T:Domain.Entities.General.GeneralDiscount\" />
 * @export
 * @interface GeneralDiscountDto
 */
export interface GeneralDiscountDto {
    /**
     * 
     * @type {DiscountType}
     * @memberof GeneralDiscountDto
     */
    'type'?: DiscountType;
    /**
     * 
     * @type {DiscountValueType}
     * @memberof GeneralDiscountDto
     */
    'valueType'?: DiscountValueType;
    /**
     * <inheritdoc cref=\"P:Domain.Entities.General.GeneralDiscount.Percent\" />
     * @type {number}
     * @memberof GeneralDiscountDto
     */
    'percent'?: number | null;
    /**
     * <inheritdoc cref=\"P:Domain.Entities.General.GeneralDiscount.Value\" />
     * @type {number}
     * @memberof GeneralDiscountDto
     */
    'value'?: number | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof GeneralDiscountDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * <inheritdoc cref=\"P:Domain.Entities.General.GeneralDiscount.IsCompound\" />
     * @type {boolean}
     * @memberof GeneralDiscountDto
     */
    'isCompound'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralDiscountInputDto
 */
export interface GeneralDiscountInputDto {
    /**
     * 
     * @type {DiscountType}
     * @memberof GeneralDiscountInputDto
     */
    'type'?: DiscountType;
    /**
     * 
     * @type {DiscountValueType}
     * @memberof GeneralDiscountInputDto
     */
    'valueType'?: DiscountValueType;
    /**
     * 
     * @type {number}
     * @memberof GeneralDiscountInputDto
     */
    'percent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralDiscountInputDto
     */
    'value'?: number | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof GeneralDiscountInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralDiscountInputDto
     */
    'isCompound'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralEntitySourceDto
 */
export interface GeneralEntitySourceDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralEntitySourceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralEntitySourceDto
     */
    'sourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralEntitySourceDto
     */
    'sourceSubId'?: string | null;
    /**
     * 
     * @type {EntitySourceType}
     * @memberof GeneralEntitySourceDto
     */
    'type'?: EntitySourceType;
    /**
     * 
     * @type {EntitySourceSubType}
     * @memberof GeneralEntitySourceDto
     */
    'subType'?: EntitySourceSubType;
    /**
     * 
     * @type {VisualInspectionReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'visualInspection'?: VisualInspectionReferenceDto;
    /**
     * 
     * @type {DamageDetectionReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageDetection'?: DamageDetectionReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageDetectionAggregate'?: DamageDetectionAggregateReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageCostEvaluationAggregate'?: DamageCostEvaluationAggregateReferenceDto;
    /**
     * 
     * @type {AccessoryCheckReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'accessoryCheck'?: AccessoryCheckReferenceDto;
    /**
     * 
     * @type {DocumentReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'document'?: DocumentReferenceDto;
    /**
     * 
     * @type {DamageDetectionItemReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageDetectionItem'?: DamageDetectionItemReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateItemReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageDetectionAggregateItem'?: DamageDetectionAggregateItemReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationItemReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageCostEvaluationItem'?: DamageCostEvaluationItemReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateItemReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageCostEvaluationAggregateItem'?: DamageCostEvaluationAggregateItemReferenceDto;
    /**
     * 
     * @type {AccessoryCheckItemReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'accessoryCheckItem'?: AccessoryCheckItemReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralEntitySourceDto
     */
    'globalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralEntitySourceDto
     */
    'localNumber'?: string | null;
}


/**
 * Describes sub type of entity described by Domain.Enums.EntityType.  E.g. EntityType=Asset and Asset has own underlying entity types like Vehicle, Accessory, etc.
 * @export
 * @interface GeneralEntitySubType
 */
export interface GeneralEntitySubType {
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralEntitySubType
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralEntitySubType
     */
    'entitySubType'?: EntityType;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof GeneralEntitySubType
     */
    'assetEntityType'?: AssetEntityType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof GeneralEntitySubType
     */
    'poolItemEntityType'?: PoolItemEntityType;
}


/**
 * 
 * @export
 * @interface GeneralEntitySubTypeDto
 */
export interface GeneralEntitySubTypeDto {
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralEntitySubTypeDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralEntitySubTypeDto
     */
    'entitySubType'?: EntityType;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof GeneralEntitySubTypeDto
     */
    'assetEntityType'?: AssetEntityType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof GeneralEntitySubTypeDto
     */
    'poolItemEntityType'?: PoolItemEntityType;
}


/**
 * 
 * @export
 * @interface GeneralEntitySubTypeInputDto
 */
export interface GeneralEntitySubTypeInputDto {
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralEntitySubTypeInputDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralEntitySubTypeInputDto
     */
    'entitySubType'?: EntityType;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof GeneralEntitySubTypeInputDto
     */
    'assetEntityType'?: AssetEntityType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof GeneralEntitySubTypeInputDto
     */
    'poolItemEntityType'?: PoolItemEntityType;
}


/**
 * 
 * @export
 * @interface GeneralEventLogChangedDto
 */
export interface GeneralEventLogChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralEventLogChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof GeneralEventLogChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralEventLogChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {GeneralEventLogDto}
     * @memberof GeneralEventLogChangedDto
     */
    'entity'?: GeneralEventLogDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface GeneralEventLogDto
 */
export interface GeneralEventLogDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof GeneralEventLogDto
     */
    'entity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {GeneralEventLogType}
     * @memberof GeneralEventLogDto
     */
    'type'?: GeneralEventLogType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralEventLogDto
     */
    'date'?: string;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof GeneralEventLogDto
     */
    'raisedBy'?: GeneralByWhoDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof GeneralEventLogDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogDto
     */
    'body'?: string | null;
}


/**
 * 
 * @export
 * @interface GeneralEventLogGetPaginatedDto
 */
export interface GeneralEventLogGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralEventLogGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralEventLogGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralEventLogGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralEventLogGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralEventLogGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof GeneralEventLogGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof GeneralEventLogGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof GeneralEventLogGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GeneralEventLogType = {
    None: 'None',
    NegotiationCreated: 'NegotiationCreated',
    NegotiationUpdated: 'NegotiationUpdated',
    NegotiationOpened: 'NegotiationOpened',
    NegotiationReopened: 'NegotiationReopened',
    NegotiationResolved: 'NegotiationResolved',
    NegotiationProposalCreated: 'NegotiationProposalCreated',
    NegotiationProposalUpdated: 'NegotiationProposalUpdated',
    NegotiationProposalResponseGiven: 'NegotiationProposalResponseGiven',
    NegotiationProposalAutoClosed: 'NegotiationProposalAutoClosed',
    NegotiationProposalClosed: 'NegotiationProposalClosed'
} as const;

export type GeneralEventLogType = typeof GeneralEventLogType[keyof typeof GeneralEventLogType];


/**
 * 
 * @export
 * @interface GeneralHistoryChangedDto
 */
export interface GeneralHistoryChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralHistoryChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof GeneralHistoryChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralHistoryChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {GeneralHistoryDto}
     * @memberof GeneralHistoryChangedDto
     */
    'entity'?: GeneralHistoryDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface GeneralHistoryCreateDto
 */
export interface GeneralHistoryCreateDto {
    /**
     * 
     * @type {GeneralHistoryType}
     * @memberof GeneralHistoryCreateDto
     */
    'type'?: GeneralHistoryType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralHistoryCreateDto
     */
    'raisedAt'?: string;
    /**
     * 
     * @type {GeneralHistorySubjectInputDto}
     * @memberof GeneralHistoryCreateDto
     */
    'subject'?: GeneralHistorySubjectInputDto;
    /**
     * 
     * @type {GeneralHistoryEventInputDto}
     * @memberof GeneralHistoryCreateDto
     */
    'event'?: GeneralHistoryEventInputDto;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof GeneralHistoryCreateDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof GeneralHistoryCreateDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}


/**
 * 
 * @export
 * @interface GeneralHistoryDto
 */
export interface GeneralHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {GeneralHistoryType}
     * @memberof GeneralHistoryDto
     */
    'type'?: GeneralHistoryType;
    /**
     * 
     * @type {AutomationCreateMode}
     * @memberof GeneralHistoryDto
     */
    'autoCreateMode'?: AutomationCreateMode;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'raisedAt'?: string;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof GeneralHistoryDto
     */
    'raisedBy'?: GeneralByWhoDto;
    /**
     * 
     * @type {GeneralHistorySubjectDto}
     * @memberof GeneralHistoryDto
     */
    'subject'?: GeneralHistorySubjectDto;
    /**
     * 
     * @type {GeneralHistoryLogDto}
     * @memberof GeneralHistoryDto
     */
    'log'?: GeneralHistoryLogDto;
    /**
     * 
     * @type {GeneralHistoryEventDto}
     * @memberof GeneralHistoryDto
     */
    'event'?: GeneralHistoryEventDto;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof GeneralHistoryDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof GeneralHistoryDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {DataGrantsMetaDto}
     * @memberof GeneralHistoryDto
     */
    'grantsMeta'?: DataGrantsMetaDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface GeneralHistoryEventDto
 */
export interface GeneralHistoryEventDto {
    /**
     * 
     * @type {GeneralHistoryEventType}
     * @memberof GeneralHistoryEventDto
     */
    'type'?: GeneralHistoryEventType;
    /**
     * 
     * @type {GeneralHistoryEventOfArbitraryDto}
     * @memberof GeneralHistoryEventDto
     */
    'arbitrary'?: GeneralHistoryEventOfArbitraryDto;
    /**
     * 
     * @type {GeneralHistoryEventOfVehicleDto}
     * @memberof GeneralHistoryEventDto
     */
    'vehicle'?: GeneralHistoryEventOfVehicleDto;
    /**
     * 
     * @type {GeneralHistoryEventOfVehicleDamageDto}
     * @memberof GeneralHistoryEventDto
     */
    'vehicleDamage'?: GeneralHistoryEventOfVehicleDamageDto;
}


/**
 * 
 * @export
 * @interface GeneralHistoryEventInputDto
 */
export interface GeneralHistoryEventInputDto {
    /**
     * 
     * @type {GeneralHistoryEventType}
     * @memberof GeneralHistoryEventInputDto
     */
    'type'?: GeneralHistoryEventType;
    /**
     * 
     * @type {GeneralHistoryEventOfArbitraryInputDto}
     * @memberof GeneralHistoryEventInputDto
     */
    'arbitrary'?: GeneralHistoryEventOfArbitraryInputDto;
}


/**
 * 
 * @export
 * @interface GeneralHistoryEventOfArbitraryDto
 */
export interface GeneralHistoryEventOfArbitraryDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryEventOfArbitraryDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryEventOfArbitraryDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralHistoryEventOfArbitraryInputDto
 */
export interface GeneralHistoryEventOfArbitraryInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryEventOfArbitraryInputDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryEventOfArbitraryInputDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralHistoryEventOfVehicleDamageDto
 */
export interface GeneralHistoryEventOfVehicleDamageDto {
    /**
     * 
     * @type {GeneralHistoryEventTypeOfVehicleDamage}
     * @memberof GeneralHistoryEventOfVehicleDamageDto
     */
    'type'?: GeneralHistoryEventTypeOfVehicleDamage;
    /**
     * 
     * @type {VehicleDamageDetectedEventDataDto}
     * @memberof GeneralHistoryEventOfVehicleDamageDto
     */
    'damageDetected'?: VehicleDamageDetectedEventDataDto;
    /**
     * 
     * @type {VehicleDamageRepairedEventDataDto}
     * @memberof GeneralHistoryEventOfVehicleDamageDto
     */
    'damageRepaired'?: VehicleDamageRepairedEventDataDto;
}


/**
 * 
 * @export
 * @interface GeneralHistoryEventOfVehicleDto
 */
export interface GeneralHistoryEventOfVehicleDto {
    /**
     * 
     * @type {GeneralHistoryEventTypeOfVehicle}
     * @memberof GeneralHistoryEventOfVehicleDto
     */
    'type'?: GeneralHistoryEventTypeOfVehicle;
    /**
     * 
     * @type {VehicleMileageChangedEventDataDto}
     * @memberof GeneralHistoryEventOfVehicleDto
     */
    'mileageChanged'?: VehicleMileageChangedEventDataDto;
    /**
     * 
     * @type {VehicleDamageDetectedEventDataDto}
     * @memberof GeneralHistoryEventOfVehicleDto
     */
    'damageDetected'?: VehicleDamageDetectedEventDataDto;
    /**
     * 
     * @type {VehicleDamageRepairedEventDataDto}
     * @memberof GeneralHistoryEventOfVehicleDto
     */
    'damageRepaired'?: VehicleDamageRepairedEventDataDto;
    /**
     * 
     * @type {VehicleDamageDeletedEventDataDto}
     * @memberof GeneralHistoryEventOfVehicleDto
     */
    'damageDeleted'?: VehicleDamageDeletedEventDataDto;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const GeneralHistoryEventType = {
    None: 'None',
    Arbitrary: 'Arbitrary',
    Vehicle: 'Vehicle',
    VehicleDamage: 'VehicleDamage'
} as const;

export type GeneralHistoryEventType = typeof GeneralHistoryEventType[keyof typeof GeneralHistoryEventType];


/**
 * 
 * @export
 * @enum {string}
 */

export const GeneralHistoryEventTypeOfVehicle = {
    None: 'None',
    MileageChanged: 'MileageChanged',
    DamageDetected: 'DamageDetected',
    DamageRepaired: 'DamageRepaired',
    DamageDeleted: 'DamageDeleted'
} as const;

export type GeneralHistoryEventTypeOfVehicle = typeof GeneralHistoryEventTypeOfVehicle[keyof typeof GeneralHistoryEventTypeOfVehicle];


/**
 * 
 * @export
 * @enum {string}
 */

export const GeneralHistoryEventTypeOfVehicleDamage = {
    None: 'None',
    DamageDetected: 'DamageDetected',
    DamageRepaired: 'DamageRepaired'
} as const;

export type GeneralHistoryEventTypeOfVehicleDamage = typeof GeneralHistoryEventTypeOfVehicleDamage[keyof typeof GeneralHistoryEventTypeOfVehicleDamage];


/**
 * 
 * @export
 * @interface GeneralHistoryGetPaginatedDto
 */
export interface GeneralHistoryGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {GeneralHistoryType}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'type'?: GeneralHistoryType;
    /**
     * 
     * @type {AutomationCreateMode}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'autoCreateMode'?: AutomationCreateMode;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'subjectEntityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'subjectEntityId'?: string | null;
    /**
     * 
     * @type {GeneralHistoryEventType}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'eventType'?: GeneralHistoryEventType;
    /**
     * 
     * @type {GeneralHistoryEventTypeOfVehicle}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'eventTypeOfVehicle'?: GeneralHistoryEventTypeOfVehicle;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof GeneralHistoryGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @interface GeneralHistoryLogDto
 */
export interface GeneralHistoryLogDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryLogDto
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GeneralHistorySearchPaginatedDto
 */
export interface GeneralHistorySearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {GeneralHistoryType}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'type'?: GeneralHistoryType;
    /**
     * 
     * @type {AutomationCreateMode}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'autoCreateMode'?: AutomationCreateMode;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'subjectEntityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'subjectEntityId'?: string | null;
    /**
     * 
     * @type {GeneralHistoryEventType}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'eventType'?: GeneralHistoryEventType;
    /**
     * 
     * @type {GeneralHistoryEventTypeOfVehicle}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'eventTypeOfVehicle'?: GeneralHistoryEventTypeOfVehicle;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof GeneralHistorySearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @interface GeneralHistorySubjectDto
 */
export interface GeneralHistorySubjectDto {
    /**
     * 
     * @type {GeneralHistorySubjectType}
     * @memberof GeneralHistorySubjectDto
     */
    'type'?: GeneralHistorySubjectType;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistorySubjectDto
     */
    'identifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistorySubjectDto
     */
    'parentIdentifier'?: string | null;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralHistorySubjectDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistorySubjectDto
     */
    'entityId'?: string | null;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralHistorySubjectDto
     */
    'parentEntityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistorySubjectDto
     */
    'parentEntityId'?: string | null;
}


/**
 * 
 * @export
 * @interface GeneralHistorySubjectInputDto
 */
export interface GeneralHistorySubjectInputDto {
    /**
     * 
     * @type {GeneralHistorySubjectType}
     * @memberof GeneralHistorySubjectInputDto
     */
    'type'?: GeneralHistorySubjectType;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralHistorySubjectInputDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistorySubjectInputDto
     */
    'entityId'?: string | null;
}


/**
 * Describes object types to which history can be linked.
 * @export
 * @enum {string}
 */

export const GeneralHistorySubjectType = {
    None: 'None',
    Arbitrary: 'Arbitrary',
    Entity: 'Entity'
} as const;

export type GeneralHistorySubjectType = typeof GeneralHistorySubjectType[keyof typeof GeneralHistorySubjectType];


/**
 * 
 * @export
 * @enum {string}
 */

export const GeneralHistoryType = {
    None: 'None',
    Log: 'Log',
    Event: 'Event'
} as const;

export type GeneralHistoryType = typeof GeneralHistoryType[keyof typeof GeneralHistoryType];


/**
 * 
 * @export
 * @interface GeneralHistoryUpdateDto
 */
export interface GeneralHistoryUpdateDto {
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralHistoryUpdateDto
     */
    'raisedAt'?: string;
    /**
     * 
     * @type {GeneralHistoryEventInputDto}
     * @memberof GeneralHistoryUpdateDto
     */
    'event'?: GeneralHistoryEventInputDto;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof GeneralHistoryUpdateDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof GeneralHistoryUpdateDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * Enumerates client method names that should support all hubs.
 * @export
 * @enum {string}
 */

export const GeneralHubClientMethodName = {
    Echo: 'Echo',
    ServerErrorOccurred: 'ServerErrorOccurred'
} as const;

export type GeneralHubClientMethodName = typeof GeneralHubClientMethodName[keyof typeof GeneralHubClientMethodName];


/**
 * 
 * @export
 * @interface GeneralImageSizeDto
 */
export interface GeneralImageSizeDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralImageSizeDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralImageSizeDto
     */
    'height'?: number;
}
/**
 * Represents a person who inspected something.
 * @export
 * @interface GeneralInspector
 */
export interface GeneralInspector {
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {GeneralPersonName}
     * @memberof GeneralInspector
     */
    'personName'?: GeneralPersonName;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralInspectorAddDto
 */
export interface GeneralInspectorAddDto {
    /**
     * 
     * @type {boolean}
     * @memberof GeneralInspectorAddDto
     */
    'isCurrentUser'?: boolean;
    /**
     * 
     * @type {GeneralPersonNameInputDto}
     * @memberof GeneralInspectorAddDto
     */
    'personName'?: GeneralPersonNameInputDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorAddDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorAddDto
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralInspectorDto
 */
export interface GeneralInspectorDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof GeneralInspectorDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralInsurance
 */
export interface GeneralInsurance {
    /**
     * 
     * @type {number}
     * @memberof GeneralInsurance
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralCurrency}
     * @memberof GeneralInsurance
     */
    'currency'?: GeneralCurrency;
}
/**
 * 
 * @export
 * @interface GeneralInsuranceDto
 */
export interface GeneralInsuranceDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralInsuranceDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof GeneralInsuranceDto
     */
    'currency'?: GeneralCurrencyDto;
}
/**
 * 
 * @export
 * @interface GeneralInsuranceInputDto
 */
export interface GeneralInsuranceInputDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralInsuranceInputDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof GeneralInsuranceInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
}
/**
 * 
 * @export
 * @interface GeneralLogoInputDto
 */
export interface GeneralLogoInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralLogoInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {UploadedFileDto}
     * @memberof GeneralLogoInputDto
     */
    'file'?: UploadedFileDto;
}
/**
 * 
 * @export
 * @interface GeneralLogoResizedDto
 */
export interface GeneralLogoResizedDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralLogoResizedDto
     */
    'id'?: string;
    /**
     * 
     * @type {FileReferenceDto}
     * @memberof GeneralLogoResizedDto
     */
    'file'?: FileReferenceDto;
    /**
     * 
     * @type {Array<GeneralLogoResizedImageDto>}
     * @memberof GeneralLogoResizedDto
     */
    'logos'?: Array<GeneralLogoResizedImageDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralLogoResizedDto
     */
    'isLogo'?: boolean;
}
/**
 * 
 * @export
 * @interface GeneralLogoResizedImageDto
 */
export interface GeneralLogoResizedImageDto {
    /**
     * 
     * @type {LogoSizeType}
     * @memberof GeneralLogoResizedImageDto
     */
    'sizeType'?: LogoSizeType;
    /**
     * 
     * @type {GeneralImageSizeDto}
     * @memberof GeneralLogoResizedImageDto
     */
    'size'?: GeneralImageSizeDto;
    /**
     * 
     * @type {FileReferenceDto}
     * @memberof GeneralLogoResizedImageDto
     */
    'file'?: FileReferenceDto;
}


/**
 * 
 * @export
 * @interface GeneralNationalIdentityNumberDto
 */
export interface GeneralNationalIdentityNumberDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralNationalIdentityNumberDto
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralNationalIdentityNumberDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {GeneralCountryDto}
     * @memberof GeneralNationalIdentityNumberDto
     */
    'country'?: GeneralCountryDto;
}
/**
 * 
 * @export
 * @interface GeneralNationalIdentityNumberInputDto
 */
export interface GeneralNationalIdentityNumberInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralNationalIdentityNumberInputDto
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralNationalIdentityNumberInputDto
     */
    'countryCode'?: string;
}
/**
 * 
 * @export
 * @interface GeneralPersonName
 */
export interface GeneralPersonName {
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonName
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonName
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonName
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonName
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface GeneralPersonNameDto
 */
export interface GeneralPersonNameDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonNameDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonNameDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonNameDto
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonNameDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface GeneralPersonNameInputDto
 */
export interface GeneralPersonNameInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonNameInputDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonNameInputDto
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface GeneralPlateNoDto
 */
export interface GeneralPlateNoDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralPlateNoDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPlateNoDto
     */
    'plateNo'?: string;
}
/**
 * 
 * @export
 * @interface GeneralPlateNoInputDto
 */
export interface GeneralPlateNoInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralPlateNoInputDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPlateNoInputDto
     */
    'plateNo'?: string;
}
/**
 * 
 * @export
 * @interface GeneralPriceDto
 */
export interface GeneralPriceDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof GeneralPriceDto
     */
    'currency'?: GeneralCurrencyDto;
}
/**
 * 
 * @export
 * @interface GeneralPriceInputDto
 */
export interface GeneralPriceInputDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceInputDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof GeneralPriceInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
}
/**
 * 
 * @export
 * @interface GeneralPriceSummaryDto
 */
export interface GeneralPriceSummaryDto {
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof GeneralPriceSummaryDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceSummaryDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceSummaryDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {GeneralDiscount}
     * @memberof GeneralPriceSummaryDto
     */
    'discount'?: GeneralDiscount;
    /**
     * 
     * @type {GeneralTax}
     * @memberof GeneralPriceSummaryDto
     */
    'tax'?: GeneralTax;
    /**
     * 
     * @type {GeneralInsurance}
     * @memberof GeneralPriceSummaryDto
     */
    'insurance'?: GeneralInsurance;
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceSummaryDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GeneralPriceSummaryInputDto
 */
export interface GeneralPriceSummaryInputDto {
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof GeneralPriceSummaryInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceSummaryInputDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof GeneralPriceSummaryInputDto
     */
    'discount'?: GeneralDiscountInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof GeneralPriceSummaryInputDto
     */
    'tax'?: GeneralTaxInputDto;
    /**
     * 
     * @type {GeneralInsuranceInputDto}
     * @memberof GeneralPriceSummaryInputDto
     */
    'insurance'?: GeneralInsuranceInputDto;
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceSummaryInputDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GeneralScopeDto
 */
export interface GeneralScopeDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeDto
     */
    'subIdentifier'?: string | null;
    /**
     * 
     * @type {GeneralScopeType}
     * @memberof GeneralScopeDto
     */
    'type'?: GeneralScopeType;
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeDto
     */
    'parentIdentifier'?: string | null;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof GeneralScopeDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {AssessmentFlowReferenceDto}
     * @memberof GeneralScopeDto
     */
    'assessmentFlow'?: AssessmentFlowReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateReferenceDto}
     * @memberof GeneralScopeDto
     */
    'damageDetectionAggregate'?: DamageDetectionAggregateReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateItemReferenceDto}
     * @memberof GeneralScopeDto
     */
    'damageDetectionAggregateItem'?: DamageDetectionAggregateItemReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateReferenceDto}
     * @memberof GeneralScopeDto
     */
    'damageCostEvaluationAggregate'?: DamageCostEvaluationAggregateReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateItemReferenceDto}
     * @memberof GeneralScopeDto
     */
    'damageCostEvaluationAggregateItem'?: DamageCostEvaluationAggregateItemReferenceDto;
    /**
     * 
     * @type {TenantConnectionRequestReferenceDto}
     * @memberof GeneralScopeDto
     */
    'tenantConnectionRequest'?: TenantConnectionRequestReferenceDto;
    /**
     * 
     * @type {TenantConnectionReferenceDto}
     * @memberof GeneralScopeDto
     */
    'tenantConnection'?: TenantConnectionReferenceDto;
    /**
     * 
     * @type {TenantRequestReferenceDto}
     * @memberof GeneralScopeDto
     */
    'tenantRequest'?: TenantRequestReferenceDto;
}


/**
 * 
 * @export
 * @interface GeneralScopeInfoDto
 */
export interface GeneralScopeInfoDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeInfoDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeInfoDto
     */
    'subIdentifier'?: string | null;
    /**
     * 
     * @type {GeneralScopeType}
     * @memberof GeneralScopeInfoDto
     */
    'type'?: GeneralScopeType;
}


/**
 * 
 * @export
 * @interface GeneralScopeRequestDto
 */
export interface GeneralScopeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeRequestDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeRequestDto
     */
    'subIdentifier'?: string | null;
    /**
     * 
     * @type {GeneralScopeType}
     * @memberof GeneralScopeRequestDto
     */
    'type'?: GeneralScopeType;
}


/**
 * Helps link chat to its scope.  For instance, link comments chat to report entity to be able to update report based on chat events or actions.
 * @export
 * @enum {string}
 */

export const GeneralScopeType = {
    None: 'None',
    Dynamic: 'Dynamic',
    Vehicle: 'Vehicle',
    Contract: 'Contract',
    AssessmentFlow: 'AssessmentFlow',
    VisualInspection: 'VisualInspection',
    DamageDetection: 'DamageDetection',
    DamageDetectionItem: 'DamageDetectionItem',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageDetectionAggregateItem: 'DamageDetectionAggregateItem',
    DamageCostEvaluation: 'DamageCostEvaluation',
    DamageCostEvaluationItem: 'DamageCostEvaluationItem',
    DamageCostEvaluationAggregate: 'DamageCostEvaluationAggregate',
    DamageCostEvaluationAggregateItem: 'DamageCostEvaluationAggregateItem',
    AccessoryCheck: 'AccessoryCheck',
    AccessoryCheckItem: 'AccessoryCheckItem',
    RepairOperation: 'RepairOperation',
    Document: 'Document',
    DocumentItem: 'DocumentItem',
    TenantConnectionRequest: 'TenantConnectionRequest',
    TenantConnection: 'TenantConnection',
    TenantRequest: 'TenantRequest'
} as const;

export type GeneralScopeType = typeof GeneralScopeType[keyof typeof GeneralScopeType];


/**
 * 
 * @export
 * @interface GeneralScopeUnwoundDto
 */
export interface GeneralScopeUnwoundDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeUnwoundDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeUnwoundDto
     */
    'subIdentifier'?: string | null;
    /**
     * 
     * @type {GeneralScopeType}
     * @memberof GeneralScopeUnwoundDto
     */
    'type'?: GeneralScopeType;
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeUnwoundDto
     */
    'parentIdentifier'?: string | null;
    /**
     * 
     * @type {ContractDto}
     * @memberof GeneralScopeUnwoundDto
     */
    'contract'?: ContractDto;
    /**
     * 
     * @type {DamageDetectionAggregateDto}
     * @memberof GeneralScopeUnwoundDto
     */
    'damageDetectionAggregate'?: DamageDetectionAggregateDto;
    /**
     * 
     * @type {DamageDetectionAggregateItemDto}
     * @memberof GeneralScopeUnwoundDto
     */
    'damageDetectionAggregateItem'?: DamageDetectionAggregateItemDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateDto}
     * @memberof GeneralScopeUnwoundDto
     */
    'damageCostEvaluationAggregate'?: DamageCostEvaluationAggregateDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateItemDto}
     * @memberof GeneralScopeUnwoundDto
     */
    'damageCostEvaluationAggregateItem'?: DamageCostEvaluationAggregateItemDto;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryDtoOfContractStage
 */
export interface GeneralStageHistoryDtoOfContractStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryDtoOfContractStage
     */
    'id'?: string;
    /**
     * 
     * @type {ContractStage}
     * @memberof GeneralStageHistoryDtoOfContractStage
     */
    'stage'?: ContractStage;
    /**
     * 
     * @type {Array<GeneralStageHistoryItemDtoOfContractStage>}
     * @memberof GeneralStageHistoryDtoOfContractStage
     */
    'items'?: Array<GeneralStageHistoryItemDtoOfContractStage>;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryDtoOfContractStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<GeneralStageInfoDtoOfContractStage>}
     * @memberof GeneralStageHistoryDtoOfContractStage
     */
    'allStagesInfo'?: Array<GeneralStageInfoDtoOfContractStage>;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryDtoOfDamageCostEvaluationStage
 */
export interface GeneralStageHistoryDtoOfDamageCostEvaluationStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryDtoOfDamageCostEvaluationStage
     */
    'id'?: string;
    /**
     * 
     * @type {DamageCostEvaluationStage}
     * @memberof GeneralStageHistoryDtoOfDamageCostEvaluationStage
     */
    'stage'?: DamageCostEvaluationStage;
    /**
     * 
     * @type {Array<GeneralStageHistoryItemDtoOfDamageCostEvaluationStage>}
     * @memberof GeneralStageHistoryDtoOfDamageCostEvaluationStage
     */
    'items'?: Array<GeneralStageHistoryItemDtoOfDamageCostEvaluationStage>;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryDtoOfDamageCostEvaluationStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<GeneralStageInfoDtoOfDamageCostEvaluationStage>}
     * @memberof GeneralStageHistoryDtoOfDamageCostEvaluationStage
     */
    'allStagesInfo'?: Array<GeneralStageInfoDtoOfDamageCostEvaluationStage>;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryDtoOfDocumentStage
 */
export interface GeneralStageHistoryDtoOfDocumentStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryDtoOfDocumentStage
     */
    'id'?: string;
    /**
     * 
     * @type {DocumentStage}
     * @memberof GeneralStageHistoryDtoOfDocumentStage
     */
    'stage'?: DocumentStage;
    /**
     * 
     * @type {Array<GeneralStageHistoryItemDtoOfDocumentStage>}
     * @memberof GeneralStageHistoryDtoOfDocumentStage
     */
    'items'?: Array<GeneralStageHistoryItemDtoOfDocumentStage>;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryDtoOfDocumentStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<GeneralStageInfoDtoOfDocumentStage>}
     * @memberof GeneralStageHistoryDtoOfDocumentStage
     */
    'allStagesInfo'?: Array<GeneralStageInfoDtoOfDocumentStage>;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryDtoOfRepairOperationStage
 */
export interface GeneralStageHistoryDtoOfRepairOperationStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryDtoOfRepairOperationStage
     */
    'id'?: string;
    /**
     * 
     * @type {RepairOperationStage}
     * @memberof GeneralStageHistoryDtoOfRepairOperationStage
     */
    'stage'?: RepairOperationStage;
    /**
     * 
     * @type {Array<GeneralStageHistoryItemDtoOfRepairOperationStage>}
     * @memberof GeneralStageHistoryDtoOfRepairOperationStage
     */
    'items'?: Array<GeneralStageHistoryItemDtoOfRepairOperationStage>;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryDtoOfRepairOperationStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<GeneralStageInfoDtoOfRepairOperationStage>}
     * @memberof GeneralStageHistoryDtoOfRepairOperationStage
     */
    'allStagesInfo'?: Array<GeneralStageInfoDtoOfRepairOperationStage>;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryDtoOfTenantRequestStage
 */
export interface GeneralStageHistoryDtoOfTenantRequestStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryDtoOfTenantRequestStage
     */
    'id'?: string;
    /**
     * 
     * @type {TenantRequestStage}
     * @memberof GeneralStageHistoryDtoOfTenantRequestStage
     */
    'stage'?: TenantRequestStage;
    /**
     * 
     * @type {Array<GeneralStageHistoryItemDtoOfTenantRequestStage>}
     * @memberof GeneralStageHistoryDtoOfTenantRequestStage
     */
    'items'?: Array<GeneralStageHistoryItemDtoOfTenantRequestStage>;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryDtoOfTenantRequestStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<GeneralStageInfoDtoOfTenantRequestStage>}
     * @memberof GeneralStageHistoryDtoOfTenantRequestStage
     */
    'allStagesInfo'?: Array<GeneralStageInfoDtoOfTenantRequestStage>;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryItemDtoOfContractStage
 */
export interface GeneralStageHistoryItemDtoOfContractStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'id'?: string;
    /**
     * 
     * @type {ContractStage}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'stage'?: ContractStage;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'isCurrent'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'firstStartedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'startedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'firstEndedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'endedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'startedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'endedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'startReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'endReason'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'startCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'isEnded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'isLast'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
 */
export interface GeneralStageHistoryItemDtoOfDamageCostEvaluationStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'id'?: string;
    /**
     * 
     * @type {DamageCostEvaluationStage}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'stage'?: DamageCostEvaluationStage;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'isCurrent'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'firstStartedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'startedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'firstEndedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'endedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'startedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'endedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'startReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'endReason'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'startCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'isEnded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'isLast'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryItemDtoOfDocumentStage
 */
export interface GeneralStageHistoryItemDtoOfDocumentStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'id'?: string;
    /**
     * 
     * @type {DocumentStage}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'stage'?: DocumentStage;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'isCurrent'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'firstStartedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'startedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'firstEndedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'endedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'startedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'endedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'startReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'endReason'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'startCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'isEnded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'isLast'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryItemDtoOfRepairOperationStage
 */
export interface GeneralStageHistoryItemDtoOfRepairOperationStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'id'?: string;
    /**
     * 
     * @type {RepairOperationStage}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'stage'?: RepairOperationStage;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'isCurrent'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'firstStartedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'startedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'firstEndedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'endedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'startedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'endedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'startReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'endReason'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'startCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'isEnded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'isLast'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryItemDtoOfTenantRequestStage
 */
export interface GeneralStageHistoryItemDtoOfTenantRequestStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'id'?: string;
    /**
     * 
     * @type {TenantRequestStage}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'stage'?: TenantRequestStage;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'isCurrent'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'firstStartedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'startedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'firstEndedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'endedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'startedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'endedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'startReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'endReason'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'startCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'isEnded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'isLast'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralStageInfoDtoOfContractStage
 */
export interface GeneralStageInfoDtoOfContractStage {
    /**
     * 
     * @type {ContractStage}
     * @memberof GeneralStageInfoDtoOfContractStage
     */
    'stage'?: ContractStage;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageInfoDtoOfContractStage
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface GeneralStageInfoDtoOfDamageCostEvaluationStage
 */
export interface GeneralStageInfoDtoOfDamageCostEvaluationStage {
    /**
     * 
     * @type {DamageCostEvaluationStage}
     * @memberof GeneralStageInfoDtoOfDamageCostEvaluationStage
     */
    'stage'?: DamageCostEvaluationStage;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageInfoDtoOfDamageCostEvaluationStage
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface GeneralStageInfoDtoOfDocumentStage
 */
export interface GeneralStageInfoDtoOfDocumentStage {
    /**
     * 
     * @type {DocumentStage}
     * @memberof GeneralStageInfoDtoOfDocumentStage
     */
    'stage'?: DocumentStage;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageInfoDtoOfDocumentStage
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface GeneralStageInfoDtoOfRepairOperationStage
 */
export interface GeneralStageInfoDtoOfRepairOperationStage {
    /**
     * 
     * @type {RepairOperationStage}
     * @memberof GeneralStageInfoDtoOfRepairOperationStage
     */
    'stage'?: RepairOperationStage;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageInfoDtoOfRepairOperationStage
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface GeneralStageInfoDtoOfTenantRequestStage
 */
export interface GeneralStageInfoDtoOfTenantRequestStage {
    /**
     * 
     * @type {TenantRequestStage}
     * @memberof GeneralStageInfoDtoOfTenantRequestStage
     */
    'stage'?: TenantRequestStage;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageInfoDtoOfTenantRequestStage
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface GeneralStageUpdateDtoOfDamageCostEvaluationStage
 */
export interface GeneralStageUpdateDtoOfDamageCostEvaluationStage {
    /**
     * 
     * @type {DamageCostEvaluationStage}
     * @memberof GeneralStageUpdateDtoOfDamageCostEvaluationStage
     */
    'newStage'?: DamageCostEvaluationStage;
}


/**
 * 
 * @export
 * @interface GeneralStageUpdateDtoOfRepairOperationStage
 */
export interface GeneralStageUpdateDtoOfRepairOperationStage {
    /**
     * 
     * @type {RepairOperationStage}
     * @memberof GeneralStageUpdateDtoOfRepairOperationStage
     */
    'newStage'?: RepairOperationStage;
}


/**
 * 
 * @export
 * @interface GeneralStageUpdateDtoOfTenantRequestStage
 */
export interface GeneralStageUpdateDtoOfTenantRequestStage {
    /**
     * 
     * @type {TenantRequestStage}
     * @memberof GeneralStageUpdateDtoOfTenantRequestStage
     */
    'newStage'?: TenantRequestStage;
}


/**
 * 
 * @export
 * @interface GeneralStrictEntityRelationDto
 */
export interface GeneralStrictEntityRelationDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralStrictEntityRelationDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralStrictEntityRelationDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {{ [key: string]: boolean | null; }}
     * @memberof GeneralStrictEntityRelationDto
     */
    'parentEntityIdMap'?: { [key: string]: boolean | null; };
    /**
     * 
     * @type {{ [key: string]: boolean | null; }}
     * @memberof GeneralStrictEntityRelationDto
     */
    'parentEntityTypeMap'?: { [key: string]: boolean | null; };
    /**
     * 
     * @type {DepartmentReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'department'?: DepartmentReferenceDto;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'location'?: LocationReferenceDto;
    /**
     * 
     * @type {SpotReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'spot'?: SpotReferenceDto;
    /**
     * 
     * @type {ProductLocationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'productLocation'?: ProductLocationReferenceDto;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {VehicleFullReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleFullRef'?: VehicleFullReferenceDto;
    /**
     * 
     * @type {VehicleHistoryItemReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleHistory'?: VehicleHistoryItemReferenceDto;
    /**
     * 
     * @type {VehicleDamageReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleDamage'?: VehicleDamageReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {ContractFullReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'contractFullRef'?: ContractFullReferenceDto;
    /**
     * 
     * @type {CustomerReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'customer'?: CustomerReferenceDto;
    /**
     * 
     * @type {AssessmentFlowReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'assessmentFlow'?: AssessmentFlowReferenceDto;
    /**
     * 
     * @type {AccessoryReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'accessory'?: AccessoryReferenceDto;
    /**
     * 
     * @type {AccessoryFullReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'accessoryFullRef'?: AccessoryFullReferenceDto;
    /**
     * 
     * @type {VisualInspectionReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'visualInspection'?: VisualInspectionReferenceDto;
    /**
     * 
     * @type {DamageDetectionReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'damageDetection'?: DamageDetectionReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'damageDetectionAggregate'?: DamageDetectionAggregateReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'damageCostEvaluationAggregate'?: DamageCostEvaluationAggregateReferenceDto;
    /**
     * 
     * @type {AccessoryCheckReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'accessoryCheck'?: AccessoryCheckReferenceDto;
    /**
     * 
     * @type {RepairOperationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'repairOperation'?: RepairOperationReferenceDto;
    /**
     * 
     * @type {ChatReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'chat'?: ChatReferenceDto;
    /**
     * 
     * @type {NegotiationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'negotiation'?: NegotiationReferenceDto;
    /**
     * 
     * @type {NegotiationProposalReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'proposal'?: NegotiationProposalReferenceDto;
    /**
     * 
     * @type {DocumentReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'document'?: DocumentReferenceDto;
    /**
     * 
     * @type {InvoiceReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'invoice'?: InvoiceReferenceDto;
    /**
     * 
     * @type {VehicleTypeReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleType'?: VehicleTypeReferenceDto;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleMake'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleModel'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {VehicleGenerationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleGeneration'?: VehicleGenerationReferenceDto;
    /**
     * 
     * @type {VehicleModificationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleModification'?: VehicleModificationReferenceDto;
    /**
     * 
     * @type {VehicleBodyTypeReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleBodyType'?: VehicleBodyTypeReferenceDto;
    /**
     * 
     * @type {VehicleFuelTypeReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleFuelType'?: VehicleFuelTypeReferenceDto;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehiclePartType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {RepairCatalogReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'repairCatalog'?: RepairCatalogReferenceDto;
    /**
     * 
     * @type {RepairWorkReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'repairWork'?: RepairWorkReferenceDto;
    /**
     * 
     * @type {RepairMaterialReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'repairMaterial'?: RepairMaterialReferenceDto;
    /**
     * 
     * @type {RepairSpecReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'repairSpec'?: RepairSpecReferenceDto;
    /**
     * 
     * @type {SubscriptionPlanReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'subscriptionPlan'?: SubscriptionPlanReferenceDto;
    /**
     * 
     * @type {SubscriptionReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'subscription'?: SubscriptionReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionPlanReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'assetSubscriptionPlan'?: AssetSubscriptionPlanReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'assetSubscription'?: AssetSubscriptionReferenceDto;
    /**
     * 
     * @type {AssetReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'asset'?: AssetReferenceDto;
    /**
     * 
     * @type {AssetFullReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'assetFullRef'?: AssetFullReferenceDto;
    /**
     * 
     * @type {PoolReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'pool'?: PoolReferenceDto;
    /**
     * 
     * @type {PoolItemReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'poolItem'?: PoolItemReferenceDto;
    /**
     * 
     * @type {PoolItemFullReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'poolItemFullRef'?: PoolItemFullReferenceDto;
    /**
     * 
     * @type {IntegrationApiClientReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'integrationApiClient'?: IntegrationApiClientReferenceDto;
    /**
     * 
     * @type {TenantConnectionRequestReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'tenantConnectionRequest'?: TenantConnectionRequestReferenceDto;
    /**
     * 
     * @type {TenantConnectionReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'tenantConnection'?: TenantConnectionReferenceDto;
    /**
     * 
     * @type {TenantRequestReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'tenantRequest'?: TenantRequestReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralStrictEntityRelationDto
     */
    'nameComputed'?: string | null;
}


/**
 * 
 * @export
 * @interface GeneralStrictEntityRelationInputDto
 */
export interface GeneralStrictEntityRelationInputDto {
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralStrictEntityRelationInputDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralStrictEntityRelationInputDto
     */
    'entityId'?: string;
}


/**
 * 
 * @export
 * @interface GeneralSvgPointDto
 */
export interface GeneralSvgPointDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralSvgPointDto
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralSvgPointDto
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralSvgPointDto
     */
    'xp'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralSvgPointDto
     */
    'yp'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralSvgPointDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralSvgPointDto
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidateDto
 */
export interface GeneralTagCandidateDto {
    /**
     * 
     * @type {GeneralTagDto}
     * @memberof GeneralTagCandidateDto
     */
    'tag'?: GeneralTagDto;
    /**
     * 
     * @type {Array<GeneralTagCandidateDto>}
     * @memberof GeneralTagCandidateDto
     */
    'subCandidates'?: Array<GeneralTagCandidateDto> | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidateDto
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesAccessoryCheckRequestDto
 */
export interface GeneralTagCandidatesAccessoryCheckRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesAccessoryCheckRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesByScopeRequestDto
 */
export interface GeneralTagCandidatesByScopeRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralTagCandidatesByScopeRequestDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralTagCandidatesByScopeRequestDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesByScopeRequestDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {GeneralScopeDto}
     * @memberof GeneralTagCandidatesByScopeRequestDto
     */
    'scope'?: GeneralScopeDto;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesChatParticipantRequestDto
 */
export interface GeneralTagCandidatesChatParticipantRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesChatParticipantRequestDto
     */
    'chatId'?: string;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesDamageCostEvaluationAggregateRequestDto
 */
export interface GeneralTagCandidatesDamageCostEvaluationAggregateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesDamageCostEvaluationAggregateRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesDamageCostEvaluationRequestDto
 */
export interface GeneralTagCandidatesDamageCostEvaluationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesDamageCostEvaluationRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesDamageDetectionAggregateRequestDto
 */
export interface GeneralTagCandidatesDamageDetectionAggregateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesDamageDetectionAggregateRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesDamageDetectionRequestDto
 */
export interface GeneralTagCandidatesDamageDetectionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesDamageDetectionRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesRepairOperationRequestDto
 */
export interface GeneralTagCandidatesRepairOperationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesRepairOperationRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesRequestDto
 */
export interface GeneralTagCandidatesRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'targetId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'targetSubId'?: string | null;
    /**
     * 
     * @type {TagTargetType}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'targetType'?: TagTargetType;
    /**
     * 
     * @type {TagSubTargetType}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'targetSubType'?: TagSubTargetType;
    /**
     * 
     * @type {GeneralTagCandidatesChatParticipantRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'chatParticipant'?: GeneralTagCandidatesChatParticipantRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesVehicleDamageRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'vehicleDamage'?: GeneralTagCandidatesVehicleDamageRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesVisualInspectionRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'visualInspection'?: GeneralTagCandidatesVisualInspectionRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesDamageDetectionRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'damageDetection'?: GeneralTagCandidatesDamageDetectionRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesDamageDetectionAggregateRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'damageDetectionAggregate'?: GeneralTagCandidatesDamageDetectionAggregateRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesDamageCostEvaluationRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'damageCostEvaluation'?: GeneralTagCandidatesDamageCostEvaluationRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesDamageCostEvaluationAggregateRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'damageCostEvaluationAggregate'?: GeneralTagCandidatesDamageCostEvaluationAggregateRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesAccessoryCheckRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'accessoryCheck'?: GeneralTagCandidatesAccessoryCheckRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesRepairOperationRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'repairOperation'?: GeneralTagCandidatesRepairOperationRequestDto;
}


/**
 * 
 * @export
 * @interface GeneralTagCandidatesVehicleDamageRequestDto
 */
export interface GeneralTagCandidatesVehicleDamageRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesVehicleDamageRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesVisualInspectionRequestDto
 */
export interface GeneralTagCandidatesVisualInspectionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesVisualInspectionRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagDto
 */
export interface GeneralTagDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagDto
     */
    'id'?: string;
    /**
     * 
     * @type {TagTargetType}
     * @memberof GeneralTagDto
     */
    'targetType'?: TagTargetType;
    /**
     * 
     * @type {TagSubTargetType}
     * @memberof GeneralTagDto
     */
    'subTargetType'?: TagSubTargetType;
    /**
     * 
     * @type {VisibilityStatus}
     * @memberof GeneralTagDto
     */
    'visibilityStatus'?: VisibilityStatus;
    /**
     * 
     * @type {GeneralTagTargetDto}
     * @memberof GeneralTagDto
     */
    'target'?: GeneralTagTargetDto;
    /**
     * 
     * @type {GeneralTagSubTargetDto}
     * @memberof GeneralTagDto
     */
    'subTarget'?: GeneralTagSubTargetDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralTagDto
     */
    'taggedAt'?: string;
    /**
     * 
     * @type {GeneralTagTaggedByDto}
     * @memberof GeneralTagDto
     */
    'taggedBy'?: GeneralTagTaggedByDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagDto
     */
    'computedId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagDto
     */
    'targetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagDto
     */
    'subTargetId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralTagDto
     */
    'isPersonTagged'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagDto
     */
    'title'?: string;
}


/**
 * 
 * @export
 * @interface GeneralTagInputDto
 */
export interface GeneralTagInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {TagTargetType}
     * @memberof GeneralTagInputDto
     */
    'targetType'?: TagTargetType;
    /**
     * 
     * @type {TagSubTargetType}
     * @memberof GeneralTagInputDto
     */
    'subTargetType'?: TagSubTargetType;
    /**
     * 
     * @type {VisibilityStatus}
     * @memberof GeneralTagInputDto
     */
    'visibilityStatus'?: VisibilityStatus;
    /**
     * 
     * @type {GeneralTagTargetInputDto}
     * @memberof GeneralTagInputDto
     */
    'target'?: GeneralTagTargetInputDto;
    /**
     * 
     * @type {GeneralTagSubTargetInputDto}
     * @memberof GeneralTagInputDto
     */
    'subTarget'?: GeneralTagSubTargetInputDto;
}


/**
 * 
 * @export
 * @interface GeneralTagSubTargetDto
 */
export interface GeneralTagSubTargetDto {
    /**
     * 
     * @type {DamageDetectionItemReferenceDto}
     * @memberof GeneralTagSubTargetDto
     */
    'damageDetectionItem'?: DamageDetectionItemReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateItemReferenceDto}
     * @memberof GeneralTagSubTargetDto
     */
    'damageDetectionAggregateItem'?: DamageDetectionAggregateItemReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationItemReferenceDto}
     * @memberof GeneralTagSubTargetDto
     */
    'damageCostEvaluationItem'?: DamageCostEvaluationItemReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateItemReferenceDto}
     * @memberof GeneralTagSubTargetDto
     */
    'damageCostEvaluationAggregateItem'?: DamageCostEvaluationAggregateItemReferenceDto;
    /**
     * 
     * @type {AccessoryCheckItemReferenceDto}
     * @memberof GeneralTagSubTargetDto
     */
    'accessoryCheckItem'?: AccessoryCheckItemReferenceDto;
    /**
     * 
     * @type {NegotiationProposalReferenceDto}
     * @memberof GeneralTagSubTargetDto
     */
    'proposal'?: NegotiationProposalReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagSubTargetDto
     */
    'subTargetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagSubTargetDto
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface GeneralTagSubTargetInputDto
 */
export interface GeneralTagSubTargetInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagSubTargetInputDto
     */
    'subTargetId'?: string;
}
/**
 * 
 * @export
 * @interface GeneralTagTaggedByDto
 */
export interface GeneralTagTaggedByDto {
    /**
     * 
     * @type {UserReferenceDto}
     * @memberof GeneralTagTaggedByDto
     */
    'user'?: UserReferenceDto;
    /**
     * 
     * @type {ChatParticipantReferenceDto}
     * @memberof GeneralTagTaggedByDto
     */
    'chatParticipant'?: ChatParticipantReferenceDto;
}
/**
 * 
 * @export
 * @interface GeneralTagTargetDto
 */
export interface GeneralTagTargetDto {
    /**
     * 
     * @type {CustomTagFullReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'customTag'?: CustomTagFullReferenceDto;
    /**
     * 
     * @type {UserReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'user'?: UserReferenceDto;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {AssetReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'asset'?: AssetReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {AssessmentFlowReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'assessmentFlow'?: AssessmentFlowReferenceDto;
    /**
     * 
     * @type {CustomerReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'customer'?: CustomerReferenceDto;
    /**
     * 
     * @type {VehicleDamageReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'vehicleDamage'?: VehicleDamageReferenceDto;
    /**
     * 
     * @type {AccessoryReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'accessory'?: AccessoryReferenceDto;
    /**
     * 
     * @type {VisualInspectionReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'visualInspection'?: VisualInspectionReferenceDto;
    /**
     * 
     * @type {DamageDetectionReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'damageDetection'?: DamageDetectionReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'damageDetectionAggregate'?: DamageDetectionAggregateReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'damageCostEvaluationAggregate'?: DamageCostEvaluationAggregateReferenceDto;
    /**
     * 
     * @type {AccessoryCheckReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'accessoryCheck'?: AccessoryCheckReferenceDto;
    /**
     * 
     * @type {RepairOperationReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'repairOperation'?: RepairOperationReferenceDto;
    /**
     * 
     * @type {ChatReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'chat'?: ChatReferenceDto;
    /**
     * 
     * @type {ChatParticipantReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'chatParticipant'?: ChatParticipantReferenceDto;
    /**
     * 
     * @type {NegotiationReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'negotiation'?: NegotiationReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionPlanReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'assetSubscriptionPlan'?: AssetSubscriptionPlanReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'assetSubscription'?: AssetSubscriptionReferenceDto;
    /**
     * 
     * @type {TenantConnectionRequestReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'tenantConnectionRequest'?: TenantConnectionRequestReferenceDto;
    /**
     * 
     * @type {TenantConnectionReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'tenantConnection'?: TenantConnectionReferenceDto;
    /**
     * 
     * @type {TenantRequestReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'tenantRequest'?: TenantRequestReferenceDto;
    /**
     * 
     * @type {DataGrantReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'dataGrant'?: DataGrantReferenceDto;
    /**
     * 
     * @type {PoolReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'pool'?: PoolReferenceDto;
    /**
     * 
     * @type {DepartmentReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'department'?: DepartmentReferenceDto;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'location'?: LocationReferenceDto;
    /**
     * 
     * @type {SpotReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'spot'?: SpotReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagTargetDto
     */
    'targetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagTargetDto
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface GeneralTagTargetInputDto
 */
export interface GeneralTagTargetInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagTargetInputDto
     */
    'targetId'?: string;
}
/**
 * Represents a percentage/amount from goods price that should be paid as tax.  Stored as positive number and is always added to SubTotal.
 * @export
 * @interface GeneralTax
 */
export interface GeneralTax {
    /**
     * 
     * @type {TaxType}
     * @memberof GeneralTax
     */
    'type'?: TaxType;
    /**
     * 
     * @type {TaxValueType}
     * @memberof GeneralTax
     */
    'valueType'?: TaxValueType;
    /**
     * Percents [0; N].
     * @type {number}
     * @memberof GeneralTax
     */
    'percent'?: number | null;
    /**
     * Amount in some currency representation.
     * @type {number}
     * @memberof GeneralTax
     */
    'value'?: number | null;
    /**
     * 
     * @type {GeneralCurrency}
     * @memberof GeneralTax
     */
    'currency'?: GeneralCurrency;
    /**
     * Tax that is a result of merging N taxes into one.
     * @type {boolean}
     * @memberof GeneralTax
     */
    'isCompound'?: boolean;
    /**
     * 
     * @type {GeneralTax}
     * @memberof GeneralTax
     */
    'original'?: GeneralTax;
}


/**
 * 
 * @export
 * @interface GeneralTaxDto
 */
export interface GeneralTaxDto {
    /**
     * 
     * @type {TaxType}
     * @memberof GeneralTaxDto
     */
    'type'?: TaxType;
    /**
     * 
     * @type {TaxValueType}
     * @memberof GeneralTaxDto
     */
    'valueType'?: TaxValueType;
    /**
     * Percents [0, 1]
     * @type {number}
     * @memberof GeneralTaxDto
     */
    'percent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralTaxDto
     */
    'value'?: number | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof GeneralTaxDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralTaxDto
     */
    'isCompound'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralTaxInputDto
 */
export interface GeneralTaxInputDto {
    /**
     * 
     * @type {TaxType}
     * @memberof GeneralTaxInputDto
     */
    'type'?: TaxType;
    /**
     * 
     * @type {TaxValueType}
     * @memberof GeneralTaxInputDto
     */
    'valueType'?: TaxValueType;
    /**
     * Percents [0, 1]
     * @type {number}
     * @memberof GeneralTaxInputDto
     */
    'percent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralTaxInputDto
     */
    'value'?: number | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof GeneralTaxInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
}


/**
 * 
 * @export
 * @interface GeneralTimeZoneDto
 */
export interface GeneralTimeZoneDto {
    /**
     * IANA time zone id.
     * @type {string}
     * @memberof GeneralTimeZoneDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralTimeZoneDto
     */
    'displayName'?: string;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof GeneralTimeZoneDto
     */
    'minOffset'?: string;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof GeneralTimeZoneDto
     */
    'maxOffset'?: string;
}
/**
 * 
 * @export
 * @interface GetChatActivityOverviewByScopeDto
 */
export interface GetChatActivityOverviewByScopeDto {
    /**
     * 
     * @type {GeneralScopeRequestDto}
     * @memberof GetChatActivityOverviewByScopeDto
     */
    'scope'?: GeneralScopeRequestDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetChatActivityOverviewByScopeDto
     */
    'isResolved'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetChatActivityOverviewByScopesDto
 */
export interface GetChatActivityOverviewByScopesDto {
    /**
     * 
     * @type {Array<GeneralScopeRequestDto>}
     * @memberof GetChatActivityOverviewByScopesDto
     */
    'scopes'?: Array<GeneralScopeRequestDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetChatActivityOverviewByScopesDto
     */
    'isResolved'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetChatUserSettingsResultDto
 */
export interface GetChatUserSettingsResultDto {
    /**
     * 
     * @type {string}
     * @memberof GetChatUserSettingsResultDto
     */
    'requestedForChatId'?: string;
    /**
     * 
     * @type {ChatUserSettingsDto}
     * @memberof GetChatUserSettingsResultDto
     */
    'global'?: ChatUserSettingsDto;
    /**
     * 
     * @type {ChatUserSettingsDto}
     * @memberof GetChatUserSettingsResultDto
     */
    'chat'?: ChatUserSettingsDto;
}
/**
 * 
 * @export
 * @interface GetOrCreateCommentChatDto
 */
export interface GetOrCreateCommentChatDto {
    /**
     * 
     * @type {GeneralScopeRequestDto}
     * @memberof GetOrCreateCommentChatDto
     */
    'scope'?: GeneralScopeRequestDto;
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateCommentChatDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateCommentChatDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GlobalSearchCategory = {
    All: 'All',
    Vehicles: 'Vehicles',
    Contracts: 'Contracts',
    Documents: 'Documents',
    VisualInspections: 'VisualInspections',
    DamageDetections: 'DamageDetections',
    DamageDetectionAggregates: 'DamageDetectionAggregates',
    AccessoryChecks: 'AccessoryChecks',
    Invoices: 'Invoices',
    Assets: 'Assets',
    Customers: 'Customers',
    AssessmentFlows: 'AssessmentFlows',
    DamageCostEvaluations: 'DamageCostEvaluations',
    DamageCostEvaluationAggregates: 'DamageCostEvaluationAggregates',
    RepairOperations: 'RepairOperations',
    Departments: 'Departments',
    Locations: 'Locations',
    Spots: 'Spots',
    ProductLocations: 'ProductLocations',
    Pools: 'Pools',
    AssetSubscriptionPlans: 'AssetSubscriptionPlans',
    AssetSubscriptions: 'AssetSubscriptions',
    TenantRequests: 'TenantRequests'
} as const;

export type GlobalSearchCategory = typeof GlobalSearchCategory[keyof typeof GlobalSearchCategory];


/**
 * 
 * @export
 * @interface GlobalSearchResultDto
 */
export interface GlobalSearchResultDto {
    /**
     * 
     * @type {GlobalSearchCategory}
     * @memberof GlobalSearchResultDto
     */
    'category'?: GlobalSearchCategory;
    /**
     * 
     * @type {PaginationDtoOfVehicleDto}
     * @memberof GlobalSearchResultDto
     */
    'vehicles'?: PaginationDtoOfVehicleDto;
    /**
     * 
     * @type {PaginationDtoOfContractDto}
     * @memberof GlobalSearchResultDto
     */
    'contracts'?: PaginationDtoOfContractDto;
    /**
     * 
     * @type {PaginationDtoOfDocumentDto}
     * @memberof GlobalSearchResultDto
     */
    'documents'?: PaginationDtoOfDocumentDto;
    /**
     * 
     * @type {PaginationDtoOfVisualInspectionDto}
     * @memberof GlobalSearchResultDto
     */
    'visualInspections'?: PaginationDtoOfVisualInspectionDto;
    /**
     * 
     * @type {PaginationDtoOfDamageDetectionDto}
     * @memberof GlobalSearchResultDto
     */
    'damageDetections'?: PaginationDtoOfDamageDetectionDto;
    /**
     * 
     * @type {PaginationDtoOfDamageDetectionAggregateDto}
     * @memberof GlobalSearchResultDto
     */
    'damageDetectionAggregates'?: PaginationDtoOfDamageDetectionAggregateDto;
    /**
     * 
     * @type {PaginationDtoOfAccessoryCheckDto}
     * @memberof GlobalSearchResultDto
     */
    'accessoryChecks'?: PaginationDtoOfAccessoryCheckDto;
    /**
     * 
     * @type {PaginationDtoOfInvoiceDto}
     * @memberof GlobalSearchResultDto
     */
    'invoices'?: PaginationDtoOfInvoiceDto;
    /**
     * 
     * @type {PaginationDtoOfAssetDto}
     * @memberof GlobalSearchResultDto
     */
    'assets'?: PaginationDtoOfAssetDto;
    /**
     * 
     * @type {PaginationDtoOfCustomerDto}
     * @memberof GlobalSearchResultDto
     */
    'customers'?: PaginationDtoOfCustomerDto;
    /**
     * 
     * @type {PaginationDtoOfAssessmentFlowDto}
     * @memberof GlobalSearchResultDto
     */
    'assessmentFlows'?: PaginationDtoOfAssessmentFlowDto;
    /**
     * 
     * @type {PaginationDtoOfDamageCostEvaluationDto}
     * @memberof GlobalSearchResultDto
     */
    'damageCostEvaluations'?: PaginationDtoOfDamageCostEvaluationDto;
    /**
     * 
     * @type {PaginationDtoOfDamageCostEvaluationAggregateDto}
     * @memberof GlobalSearchResultDto
     */
    'damageCostEvaluationAggregates'?: PaginationDtoOfDamageCostEvaluationAggregateDto;
    /**
     * 
     * @type {PaginationDtoOfRepairOperationDto}
     * @memberof GlobalSearchResultDto
     */
    'repairOperations'?: PaginationDtoOfRepairOperationDto;
    /**
     * 
     * @type {PaginationDtoOfDepartmentDto}
     * @memberof GlobalSearchResultDto
     */
    'departments'?: PaginationDtoOfDepartmentDto;
    /**
     * 
     * @type {PaginationDtoOfLocationDto}
     * @memberof GlobalSearchResultDto
     */
    'locations'?: PaginationDtoOfLocationDto;
    /**
     * 
     * @type {PaginationDtoOfSpotDto}
     * @memberof GlobalSearchResultDto
     */
    'spots'?: PaginationDtoOfSpotDto;
    /**
     * 
     * @type {PaginationDtoOfProductLocationDto}
     * @memberof GlobalSearchResultDto
     */
    'productLocations'?: PaginationDtoOfProductLocationDto;
    /**
     * 
     * @type {PaginationDtoOfPoolDto}
     * @memberof GlobalSearchResultDto
     */
    'pools'?: PaginationDtoOfPoolDto;
    /**
     * 
     * @type {PaginationDtoOfAssetSubscriptionPlanDto}
     * @memberof GlobalSearchResultDto
     */
    'assetSubscriptionPlans'?: PaginationDtoOfAssetSubscriptionPlanDto;
    /**
     * 
     * @type {PaginationDtoOfAssetSubscriptionDto}
     * @memberof GlobalSearchResultDto
     */
    'assetSubscriptions'?: PaginationDtoOfAssetSubscriptionDto;
    /**
     * 
     * @type {PaginationDtoOfTenantRequestDto}
     * @memberof GlobalSearchResultDto
     */
    'tenantRequests'?: PaginationDtoOfTenantRequestDto;
    /**
     * 
     * @type {number}
     * @memberof GlobalSearchResultDto
     */
    'totalReturnedCount'?: number;
}


/**
 * See:  - System.Security.Authentication.HashAlgorithmType  - System.Security.Cryptography.HashAlgorithmName
 * @export
 * @enum {string}
 */

export const HashAlgorithmType = {
    None: 'None',
    Md5: 'MD5',
    Sha1: 'SHA1',
    Sha256: 'SHA256',
    Sha384: 'SHA384',
    Sha512: 'SHA512'
} as const;

export type HashAlgorithmType = typeof HashAlgorithmType[keyof typeof HashAlgorithmType];


/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IAssetSourceEntity\" />
 * @export
 * @interface IAssetSourceEntityDto
 */
export interface IAssetSourceEntityDto {
    /**
     * 
     * @type {AssetMetaDto}
     * @memberof IAssetSourceEntityDto
     */
    'assetMeta'?: AssetMetaDto;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IBaseEntity\" />
 * @export
 * @interface IBaseEntityDto
 */
export interface IBaseEntityDto {
    /**
     * 
     * @type {string}
     * @memberof IBaseEntityDto
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface IEntityChangedDto
 */
export interface IEntityChangedDto {
    /**
     * 
     * @type {EntityChangeType}
     * @memberof IEntityChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof IEntityChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof IEntityChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IEntityChangedDto
     */
    'tenantId'?: string | null;
    /**
     * Optional list of connection ids of client/clients that initiated this server to client SignalR method call.
     * @type {Array<string>}
     * @memberof IEntityChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * SignalR group name to which server sends the message.
     * @type {string}
     * @memberof IEntityChangedDto
     */
    'groupName'?: string | null;
}


/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IHasGlobalNumber\" />
 * @export
 * @interface IHasGlobalNumberDto
 */
export interface IHasGlobalNumberDto {
    /**
     * Number unique across the whole system.
     * @type {string}
     * @memberof IHasGlobalNumberDto
     */
    'globalNumber'?: string;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IHasLocalNumber\" />
 * @export
 * @interface IHasLocalNumberDto
 */
export interface IHasLocalNumberDto {
    /**
     * Number unique across a tenant.
     * @type {string}
     * @memberof IHasLocalNumberDto
     */
    'localNumber'?: string;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IImportedEntity\" />
 * @export
 * @interface IImportedEntityDto
 */
export interface IImportedEntityDto {
    /**
     * 
     * @type {EntityImportMetaDto}
     * @memberof IImportedEntityDto
     */
    'importMeta'?: EntityImportMetaDto;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHaveDataGrants\" />
 * @export
 * @interface IMightHaveDataGrantsBriefDto
 */
export interface IMightHaveDataGrantsBriefDto {
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof IMightHaveDataGrantsBriefDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHaveDataGrants\" />
 * @export
 * @interface IMightHaveDataGrantsDto
 */
export interface IMightHaveDataGrantsDto {
    /**
     * 
     * @type {DataGrantsMetaDto}
     * @memberof IMightHaveDataGrantsDto
     */
    'grantsMeta'?: DataGrantsMetaDto;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHaveDepartments\" />
 * @export
 * @interface IMightHaveDepartmentsDto
 */
export interface IMightHaveDepartmentsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof IMightHaveDepartmentsDto
     */
    'departmentIds'?: Array<string> | null;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHaveLocations\" />
 * @export
 * @interface IMightHaveLocationsDto
 */
export interface IMightHaveLocationsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof IMightHaveLocationsDto
     */
    'locationIds'?: Array<string> | null;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHavePools\" />
 * @export
 * @interface IMightHavePoolsDto
 */
export interface IMightHavePoolsDto {
    /**
     * 
     * @type {PoolsMetaDto}
     * @memberof IMightHavePoolsDto
     */
    'poolsMeta'?: PoolsMetaDto;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHaveTags\" />
 * @export
 * @interface IMightHaveTagsDto
 */
export interface IMightHaveTagsDto {
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof IMightHaveTagsDto
     */
    'tags'?: GeneralAttachedTagsDto;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHaveTenantRequests\" />
 * @export
 * @interface IMightHaveTenantRequestsDto
 */
export interface IMightHaveTenantRequestsDto {
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof IMightHaveTenantRequestsDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
}
/**
 * 
 * @export
 * @interface ImportDto
 */
export interface ImportDto {
    /**
     * 
     * @type {string}
     * @memberof ImportDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {ImportStatus}
     * @memberof ImportDto
     */
    'status'?: ImportStatus;
    /**
     * 
     * @type {ImportEntityType}
     * @memberof ImportDto
     */
    'entityType'?: ImportEntityType;
    /**
     * 
     * @type {GeneralAttachmentDto}
     * @memberof ImportDto
     */
    'attachment'?: GeneralAttachmentDto;
    /**
     * 
     * @type {SpreadsheetFileMetadataDto}
     * @memberof ImportDto
     */
    'fileMetadata'?: SpreadsheetFileMetadataDto;
    /**
     * 
     * @type {InlineImportMappingDto}
     * @memberof ImportDto
     */
    'mapping'?: InlineImportMappingDto;
    /**
     * 
     * @type {ImportResultDto}
     * @memberof ImportDto
     */
    'result'?: ImportResultDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ImportDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ImportDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ImportEntityType = {
    None: 'None',
    Accessory: 'Accessory',
    Vehicle: 'Vehicle'
} as const;

export type ImportEntityType = typeof ImportEntityType[keyof typeof ImportEntityType];


/**
 * 
 * @export
 * @interface ImportFieldErrorDto
 */
export interface ImportFieldErrorDto {
    /**
     * 
     * @type {string}
     * @memberof ImportFieldErrorDto
     */
    'field'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFieldErrorDto
     */
    'column'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportFieldErrorDto
     */
    'error'?: string | null;
}
/**
 * 
 * @export
 * @interface ImportFieldSpecDto
 */
export interface ImportFieldSpecDto {
    /**
     * 
     * @type {string}
     * @memberof ImportFieldSpecDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ImportFieldSpecDto
     */
    'isRequired'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImportFieldSpecDto
     */
    'allowUserEnteredDefaultValue'?: boolean;
}
/**
 * 
 * @export
 * @interface ImportFromCatalogDto
 */
export interface ImportFromCatalogDto {
    /**
     * 
     * @type {string}
     * @memberof ImportFromCatalogDto
     */
    'sourceCatalogId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFromCatalogDto
     */
    'targetCatalogId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ImportFromCatalogDto
     */
    'isImportAll'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImportFromCatalogDto
     */
    'isImportAllMaterials'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImportFromCatalogDto
     */
    'isImportAllWork'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImportFromCatalogDto
     */
    'isImportAllSpecs'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportFromCatalogDto
     */
    'materialIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportFromCatalogDto
     */
    'workIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportFromCatalogDto
     */
    'specIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ImportFromCatalogInfoDto
 */
export interface ImportFromCatalogInfoDto {
    /**
     * 
     * @type {RepairCatalogDto}
     * @memberof ImportFromCatalogInfoDto
     */
    'sourceCatalog'?: RepairCatalogDto;
    /**
     * 
     * @type {RepairCatalogDto}
     * @memberof ImportFromCatalogInfoDto
     */
    'targetCatalog'?: RepairCatalogDto;
    /**
     * 
     * @type {Array<RepairMaterialDto>}
     * @memberof ImportFromCatalogInfoDto
     */
    'materials'?: Array<RepairMaterialDto>;
    /**
     * 
     * @type {Array<RepairWorkDto>}
     * @memberof ImportFromCatalogInfoDto
     */
    'work'?: Array<RepairWorkDto>;
    /**
     * 
     * @type {Array<RepairSpecDto>}
     * @memberof ImportFromCatalogInfoDto
     */
    'specs'?: Array<RepairSpecDto>;
}
/**
 * 
 * @export
 * @interface ImportGetPaginatedDto
 */
export interface ImportGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof ImportGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof ImportGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof ImportGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof ImportGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
}
/**
 * 
 * @export
 * @interface ImportMappingTemplateDto
 */
export interface ImportMappingTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof ImportMappingTemplateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportMappingTemplateDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportMappingTemplateDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportMappingTemplateDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ImportMappingTemplateDto
     */
    'name'?: string;
    /**
     * 
     * @type {ImportEntityType}
     * @memberof ImportMappingTemplateDto
     */
    'entityType'?: ImportEntityType;
    /**
     * 
     * @type {Array<MappingEntryDto>}
     * @memberof ImportMappingTemplateDto
     */
    'mappings'?: Array<MappingEntryDto>;
}


/**
 * 
 * @export
 * @interface ImportResultDto
 */
export interface ImportResultDto {
    /**
     * 
     * @type {string}
     * @memberof ImportResultDto
     */
    'error'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ImportResultDto
     */
    'date'?: string | null;
    /**
     * 
     * @type {Array<ImportRowResultDto>}
     * @memberof ImportResultDto
     */
    'rows'?: Array<ImportRowResultDto>;
}
/**
 * 
 * @export
 * @interface ImportRowResultDto
 */
export interface ImportRowResultDto {
    /**
     * Index of row in file
     * @type {number}
     * @memberof ImportRowResultDto
     */
    'index'?: number;
    /**
     * 
     * @type {ImportRowResultStatus}
     * @memberof ImportRowResultDto
     */
    'status'?: ImportRowResultStatus;
    /**
     * 
     * @type {string}
     * @memberof ImportRowResultDto
     */
    'error'?: string | null;
    /**
     * 
     * @type {Array<ImportFieldErrorDto>}
     * @memberof ImportRowResultDto
     */
    'fieldErrors'?: Array<ImportFieldErrorDto>;
    /**
     * 
     * @type {AccessoryReferenceDto}
     * @memberof ImportRowResultDto
     */
    'accessory'?: AccessoryReferenceDto;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof ImportRowResultDto
     */
    'vehicle'?: VehicleReferenceDto;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ImportRowResultStatus = {
    None: 'None',
    Created: 'Created',
    Updated: 'Updated',
    Skipped: 'Skipped',
    Failed: 'Failed'
} as const;

export type ImportRowResultStatus = typeof ImportRowResultStatus[keyof typeof ImportRowResultStatus];


/**
 * 
 * @export
 * @interface ImportSpecDto
 */
export interface ImportSpecDto {
    /**
     * 
     * @type {ImportEntityType}
     * @memberof ImportSpecDto
     */
    'entityType'?: ImportEntityType;
    /**
     * 
     * @type {Array<ImportFieldSpecDto>}
     * @memberof ImportSpecDto
     */
    'fields'?: Array<ImportFieldSpecDto>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ImportStatus = {
    None: 'None',
    DataUploading: 'DataUploading',
    MappingConfiguration: 'MappingConfiguration',
    PreLaunchConfiguration: 'PreLaunchConfiguration',
    Processing: 'Processing',
    Completed: 'Completed',
    Failed: 'Failed'
} as const;

export type ImportStatus = typeof ImportStatus[keyof typeof ImportStatus];


/**
 * 
 * @export
 * @interface InAppChannelInfoDto
 */
export interface InAppChannelInfoDto {
    /**
     * 
     * @type {string}
     * @memberof InAppChannelInfoDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InAppChannelInfoDto
     */
    'body'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InAppChannelInfoDto
     */
    'data'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface InAppNotificationReceivedDto
 */
export interface InAppNotificationReceivedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof InAppNotificationReceivedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InAppNotificationReceivedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {NotificationDto}
     * @memberof InAppNotificationReceivedDto
     */
    'notification'?: NotificationDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InAppNotificationType = {
    None: 'None',
    DamageDetectionCreated: 'DamageDetectionCreated',
    DamageCostEvaluationStarted: 'DamageCostEvaluationStarted',
    DamageCostEvaluationCompleted: 'DamageCostEvaluationCompleted',
    DamageCostEvaluationAggregateUpdated: 'DamageCostEvaluationAggregateUpdated',
    AccessoryCheckCreated: 'AccessoryCheckCreated',
    ChatResolved: 'ChatResolved',
    ChatReopened: 'ChatReopened',
    ChatAcknowledged: 'ChatAcknowledged',
    ChatParticipantAdded: 'ChatParticipantAdded',
    ChatParticipantRemoved: 'ChatParticipantRemoved',
    ChatParticipantMentioned: 'ChatParticipantMentioned',
    ChatMessageAcknowledged: 'ChatMessageAcknowledged',
    ChatNegotiationProposalCreated: 'ChatNegotiationProposalCreated',
    ChatNegotiationProposalResponseGiven: 'ChatNegotiationProposalResponseGiven'
} as const;

export type InAppNotificationType = typeof InAppNotificationType[keyof typeof InAppNotificationType];


/**
 * 
 * @export
 * @interface IncomingCountersDtoInDataGrantCountersDto
 */
export interface IncomingCountersDtoInDataGrantCountersDto {
    /**
     * 
     * @type {number}
     * @memberof IncomingCountersDtoInDataGrantCountersDto
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface IncomingCountersDtoInTenantConnectionRequestCountersDto
 */
export interface IncomingCountersDtoInTenantConnectionRequestCountersDto {
    /**
     * 
     * @type {number}
     * @memberof IncomingCountersDtoInTenantConnectionRequestCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof IncomingCountersDtoInTenantConnectionRequestCountersDto
     */
    'pendingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof IncomingCountersDtoInTenantConnectionRequestCountersDto
     */
    'acceptedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof IncomingCountersDtoInTenantConnectionRequestCountersDto
     */
    'declinedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof IncomingCountersDtoInTenantConnectionRequestCountersDto
     */
    'notRespondedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof IncomingCountersDtoInTenantConnectionRequestCountersDto
     */
    'revokedCount'?: number;
}
/**
 * 
 * @export
 * @interface IncomingCountersDtoInTenantRequestCountersDto
 */
export interface IncomingCountersDtoInTenantRequestCountersDto {
    /**
     * 
     * @type {number}
     * @memberof IncomingCountersDtoInTenantRequestCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof IncomingCountersDtoInTenantRequestCountersDto
     */
    'pendingCount'?: number;
}
/**
 * 
 * @export
 * @interface InlineImportMappingDto
 */
export interface InlineImportMappingDto {
    /**
     * Definition of mapping
     * @type {Array<InlineMappingEntry>}
     * @memberof InlineImportMappingDto
     */
    'mappings'?: Array<InlineMappingEntry>;
}
/**
 * 
 * @export
 * @interface InlineMappingEntry
 */
export interface InlineMappingEntry {
    /**
     * 
     * @type {string}
     * @memberof InlineMappingEntry
     */
    'target'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineMappingEntry
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineMappingEntry
     */
    'defaultValue'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InlineMappingEntry
     */
    'isConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineMappingEntry
     */
    'isIgnored'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineMappingEntryDto
 */
export interface InlineMappingEntryDto {
    /**
     * 
     * @type {string}
     * @memberof InlineMappingEntryDto
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineMappingEntryDto
     */
    'target'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineMappingEntryDto
     */
    'defaultValue'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InlineMappingEntryDto
     */
    'isConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineMappingEntryDto
     */
    'isIgnored'?: boolean;
}
/**
 * 
 * @export
 * @interface IntegrationApiClientChangedDto
 */
export interface IntegrationApiClientChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationApiClientChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof IntegrationApiClientChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof IntegrationApiClientChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {IntegrationApiClientDto}
     * @memberof IntegrationApiClientChangedDto
     */
    'entity'?: IntegrationApiClientDto;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface IntegrationApiClientCreateDto
 */
export interface IntegrationApiClientCreateDto {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientCreateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientCreateDto
     */
    'description'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientCreateDto
     */
    'expiresAt'?: string | null;
}
/**
 * 
 * @export
 * @interface IntegrationApiClientDto
 */
export interface IntegrationApiClientDto {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'localNumber'?: string | null;
    /**
     * 
     * @type {IntegrationApiScopeDto}
     * @memberof IntegrationApiClientDto
     */
    'scope'?: IntegrationApiScopeDto;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'description'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'expiresAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'expiredAt'?: string | null;
    /**
     * 
     * @type {Array<IntegrationApiClientKeyDto>}
     * @memberof IntegrationApiClientDto
     */
    'keys'?: Array<IntegrationApiClientKeyDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface IntegrationApiClientGetPaginatedDto
 */
export interface IntegrationApiClientGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof IntegrationApiClientGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrationApiClientGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationApiClientGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationApiClientGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationApiClientGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof IntegrationApiClientGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof IntegrationApiClientGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof IntegrationApiClientGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {IntegrationApiScopeType}
     * @memberof IntegrationApiClientGetPaginatedDto
     */
    'scopeType'?: IntegrationApiScopeType;
}


/**
 * 
 * @export
 * @interface IntegrationApiClientKeyDto
 */
export interface IntegrationApiClientKeyDto {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientKeyDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientKeyDto
     */
    'name'?: string;
    /**
     * 
     * @type {IntegrationApiKeyStatus}
     * @memberof IntegrationApiClientKeyDto
     */
    'status'?: IntegrationApiKeyStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientKeyDto
     */
    'expiresAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientKeyDto
     */
    'activeAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientKeyDto
     */
    'revokedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientKeyDto
     */
    'expiredAt'?: string | null;
}


/**
 * 
 * @export
 * @interface IntegrationApiClientKeyInputDto
 */
export interface IntegrationApiClientKeyInputDto {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientKeyInputDto
     */
    'name'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientKeyInputDto
     */
    'expiresAt'?: string | null;
}
/**
 * 
 * @export
 * @interface IntegrationApiClientKeyValueDto
 */
export interface IntegrationApiClientKeyValueDto {
    /**
     * 
     * @type {IntegrationApiClientKeyDto}
     * @memberof IntegrationApiClientKeyValueDto
     */
    'key'?: IntegrationApiClientKeyDto;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientKeyValueDto
     */
    'keyValue'?: string;
}
/**
 * 
 * @export
 * @interface IntegrationApiClientReferenceDto
 */
export interface IntegrationApiClientReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientReferenceDto
     */
    'localNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface IntegrationApiClientSearchPaginatedDto
 */
export interface IntegrationApiClientSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof IntegrationApiClientSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrationApiClientSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationApiClientSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationApiClientSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationApiClientSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof IntegrationApiClientSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof IntegrationApiClientSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof IntegrationApiClientSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {IntegrationApiScopeType}
     * @memberof IntegrationApiClientSearchPaginatedDto
     */
    'scopeType'?: IntegrationApiScopeType;
}


/**
 * 
 * @export
 * @interface IntegrationApiClientUpdateDto
 */
export interface IntegrationApiClientUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientUpdateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientUpdateDto
     */
    'description'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientUpdateDto
     */
    'expiresAt'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IntegrationApiKeyStatus = {
    None: 'None',
    Active: 'Active',
    Revoked: 'Revoked',
    Expired: 'Expired'
} as const;

export type IntegrationApiKeyStatus = typeof IntegrationApiKeyStatus[keyof typeof IntegrationApiKeyStatus];


/**
 * 
 * @export
 * @interface IntegrationApiScopeDto
 */
export interface IntegrationApiScopeDto {
    /**
     * 
     * @type {IntegrationApiScopeType}
     * @memberof IntegrationApiScopeDto
     */
    'type'?: IntegrationApiScopeType;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiScopeDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const IntegrationApiScopeType = {
    None: 'None',
    Global: 'Global',
    Tenant: 'Tenant',
    Combined: 'Combined'
} as const;

export type IntegrationApiScopeType = typeof IntegrationApiScopeType[keyof typeof IntegrationApiScopeType];


/**
 * 
 * @export
 * @interface InviteAnonymousUserDto
 */
export interface InviteAnonymousUserDto {
    /**
     * 
     * @type {string}
     * @memberof InviteAnonymousUserDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InviteAnonymousUserDto
     */
    'locationId'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof InviteAnonymousUserDto
     */
    'lifetime'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteAnonymousUserDto
     */
    'roleIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InviteAnonymousUserResultDto
 */
export interface InviteAnonymousUserResultDto {
    /**
     * 
     * @type {string}
     * @memberof InviteAnonymousUserResultDto
     */
    'inviteUrl'?: string;
}
/**
 * 
 * @export
 * @interface InviteChangedDto
 */
export interface InviteChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InviteChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof InviteChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof InviteChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof InviteChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {InviteDto}
     * @memberof InviteChangedDto
     */
    'entity'?: InviteDto;
    /**
     * 
     * @type {string}
     * @memberof InviteChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface InviteCheckUserExistsDto
 */
export interface InviteCheckUserExistsDto {
    /**
     * 
     * @type {string}
     * @memberof InviteCheckUserExistsDto
     */
    'inviteId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteCheckUserExistsDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface InviteCustomerAsUserDto
 */
export interface InviteCustomerAsUserDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteCustomerAsUserDto
     */
    'contactIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InviteDto
 */
export interface InviteDto {
    /**
     * 
     * @type {string}
     * @memberof InviteDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {InviteType}
     * @memberof InviteDto
     */
    'type'?: InviteType;
    /**
     * 
     * @type {InviteUserType}
     * @memberof InviteDto
     */
    'userType'?: InviteUserType;
    /**
     * 
     * @type {InviteUserInfo}
     * @memberof InviteDto
     */
    'userInfo'?: InviteUserInfo;
    /**
     * 
     * @type {string}
     * @memberof InviteDto
     */
    'redirectUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InviteDto
     */
    'isExpired'?: boolean;
    /**
     * 
     * @type {InviteTenantDto}
     * @memberof InviteDto
     */
    'tenant'?: InviteTenantDto;
    /**
     * 
     * @type {boolean}
     * @memberof InviteDto
     */
    'isIAlreadyAnswered'?: boolean | null;
}


/**
 * 
 * @export
 * @interface InviteTenantDto
 */
export interface InviteTenantDto {
    /**
     * 
     * @type {string}
     * @memberof InviteTenantDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteTenantDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteTenantDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InviteType = {
    None: 'None',
    Personal: 'Personal',
    Anonymous: 'Anonymous'
} as const;

export type InviteType = typeof InviteType[keyof typeof InviteType];


/**
 * 
 * @export
 * @interface InviteUserDto
 */
export interface InviteUserDto {
    /**
     * 
     * @type {string}
     * @memberof InviteUserDto
     */
    'email'?: string;
    /**
     * 
     * @type {GeneralPersonNameInputDto}
     * @memberof InviteUserDto
     */
    'personName'?: GeneralPersonNameInputDto;
    /**
     * 
     * @type {string}
     * @memberof InviteUserDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InviteUserDto
     */
    'locationId'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof InviteUserDto
     */
    'lifetime'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteUserDto
     */
    'roleIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof InviteUserDto
     */
    'isInviteExistingUser'?: boolean;
}
/**
 * 
 * @export
 * @interface InviteUserExistsDto
 */
export interface InviteUserExistsDto {
    /**
     * 
     * @type {boolean}
     * @memberof InviteUserExistsDto
     */
    'exists'?: boolean;
}
/**
 * 
 * @export
 * @interface InviteUserInfo
 */
export interface InviteUserInfo {
    /**
     * 
     * @type {GeneralPersonName}
     * @memberof InviteUserInfo
     */
    'personName'?: GeneralPersonName;
    /**
     * 
     * @type {string}
     * @memberof InviteUserInfo
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserInfo
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface InviteUserInfoDto
 */
export interface InviteUserInfoDto {
    /**
     * 
     * @type {string}
     * @memberof InviteUserInfoDto
     */
    'email'?: string;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof InviteUserInfoDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof InviteUserInfoDto
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InviteUserType = {
    None: 'None',
    Tenant: 'Tenant',
    Customer: 'Customer'
} as const;

export type InviteUserType = typeof InviteUserType[keyof typeof InviteUserType];


/**
 * 
 * @export
 * @interface InvoiceBusinessInfoDto
 */
export interface InvoiceBusinessInfoDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceBusinessInfoDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBusinessInfoDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBusinessInfoDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBusinessInfoDto
     */
    'webSiteUrl'?: string | null;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof InvoiceBusinessInfoDto
     */
    'address'?: GeneralAddressDto;
}
/**
 * 
 * @export
 * @interface InvoiceChangedDto
 */
export interface InvoiceChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof InvoiceChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof InvoiceChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof InvoiceChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {InvoiceDto}
     * @memberof InvoiceChangedDto
     */
    'entity'?: InvoiceDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface InvoiceCustomerBusinessInfoDto
 */
export interface InvoiceCustomerBusinessInfoDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerBusinessInfoDto
     */
    'businessName'?: string;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof InvoiceCustomerBusinessInfoDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerBusinessInfoDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerBusinessInfoDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof InvoiceCustomerBusinessInfoDto
     */
    'address'?: GeneralAddressDto;
}
/**
 * 
 * @export
 * @interface InvoiceDto
 */
export interface InvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'subscriptionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof InvoiceDto
     */
    'status'?: InvoiceStatus;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof InvoiceDto
     */
    'paymentMethod'?: PaymentMethod;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof InvoiceDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {InvoiceBusinessInfoDto}
     * @memberof InvoiceDto
     */
    'businessInfo'?: InvoiceBusinessInfoDto;
    /**
     * 
     * @type {InvoiceCustomerBusinessInfoDto}
     * @memberof InvoiceDto
     */
    'customerBusinessInfo'?: InvoiceCustomerBusinessInfoDto;
    /**
     * 
     * @type {Array<InvoiceLineItemDto>}
     * @memberof InvoiceDto
     */
    'lineItems'?: Array<InvoiceLineItemDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof InvoiceDto
     */
    'date'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof InvoiceDto
     */
    'dueAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof InvoiceDto
     */
    'sentAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof InvoiceDto
     */
    'pendingAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof InvoiceDto
     */
    'paidAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof InvoiceDto
     */
    'cancelledAt'?: string | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof InvoiceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof InvoiceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface InvoiceGetPaginatedDto
 */
export interface InvoiceGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof InvoiceGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof InvoiceGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof InvoiceGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceGetPaginatedDto
     */
    'subscriptionId'?: string | null;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof InvoiceGetPaginatedDto
     */
    'status'?: InvoiceStatus;
}


/**
 * 
 * @export
 * @interface InvoiceLineItemDto
 */
export interface InvoiceLineItemDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItemDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItemDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItemDto
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItemDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof InvoiceLineItemDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof InvoiceLineItemDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItemDto
     */
    'subtotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItemDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface InvoiceReferenceDto
 */
export interface InvoiceReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReferenceDto
     */
    'subscriptionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InvoiceStatus = {
    None: 'None',
    Draft: 'Draft',
    Pending: 'Pending',
    Paid: 'Paid',
    Overdue: 'Overdue',
    Cancelled: 'Cancelled'
} as const;

export type InvoiceStatus = typeof InvoiceStatus[keyof typeof InvoiceStatus];


/**
 * 
 * @export
 * @interface ItemDtoInDamageDetectionMigrateToNewVisualModelDto
 */
export interface ItemDtoInDamageDetectionMigrateToNewVisualModelDto {
    /**
     * 
     * @type {string}
     * @memberof ItemDtoInDamageDetectionMigrateToNewVisualModelDto
     */
    'id'?: string;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof ItemDtoInDamageDetectionMigrateToNewVisualModelDto
     */
    'oldPoint'?: GeneralSvgPointDto;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof ItemDtoInDamageDetectionMigrateToNewVisualModelDto
     */
    'newPoint'?: GeneralSvgPointDto;
}
/**
 * 
 * @export
 * @interface JoinChatDto
 */
export interface JoinChatDto {
    /**
     * 
     * @type {string}
     * @memberof JoinChatDto
     */
    'chatId'?: string;
}
/**
 * 
 * @export
 * @interface LaunchImportDto
 */
export interface LaunchImportDto {
    /**
     * 
     * @type {string}
     * @memberof LaunchImportDto
     */
    'importId'?: string;
    /**
     * Flag that indicates whether we should update entity it already exists
     * @type {boolean}
     * @memberof LaunchImportDto
     */
    'shouldUpdateEntityIfExists'?: boolean;
}
/**
 * 
 * @export
 * @interface LeaveChatDto
 */
export interface LeaveChatDto {
    /**
     * 
     * @type {string}
     * @memberof LeaveChatDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveChatDto
     */
    'participantId'?: string;
}
/**
 * 
 * @export
 * @interface LocationChangedDto
 */
export interface LocationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof LocationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof LocationChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof LocationChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof LocationChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {LocationDto}
     * @memberof LocationChangedDto
     */
    'entity'?: LocationDto;
    /**
     * 
     * @type {string}
     * @memberof LocationChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {DepartmentReferenceDto}
     * @memberof LocationDto
     */
    'department'?: DepartmentReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof LocationDto
     */
    'address'?: GeneralAddressDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof LocationDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof LocationDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface LocationGetPaginatedDto
 */
export interface LocationGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof LocationGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof LocationGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof LocationGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof LocationGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof LocationGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationGetPaginatedDto
     */
    'notForDepartmentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LocationGetPaginatedDto
     */
    'isWithDepartment'?: boolean | null;
}
/**
 * 
 * @export
 * @interface LocationReferenceDto
 */
export interface LocationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof LocationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface LocationSearchPaginatedDto
 */
export interface LocationSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof LocationSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof LocationSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof LocationSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof LocationSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof LocationSearchPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationSearchPaginatedDto
     */
    'notForDepartmentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LocationSearchPaginatedDto
     */
    'isWithDepartment'?: boolean | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LogoSizeType = {
    None: 'None',
    Logo32: 'Logo32',
    Logo48: 'Logo48',
    Logo128: 'Logo128',
    Logo250x50: 'Logo250x50'
} as const;

export type LogoSizeType = typeof LogoSizeType[keyof typeof LogoSizeType];


/**
 * 
 * @export
 * @interface MailchimpEmailSettingsDto
 */
export interface MailchimpEmailSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof MailchimpEmailSettingsDto
     */
    'from'?: string;
    /**
     * 
     * @type {UserSecretReferenceDto}
     * @memberof MailchimpEmailSettingsDto
     */
    'apiKey'?: UserSecretReferenceDto;
}
/**
 * 
 * @export
 * @interface MailchimpEmailSettingsInputDto
 */
export interface MailchimpEmailSettingsInputDto {
    /**
     * 
     * @type {string}
     * @memberof MailchimpEmailSettingsInputDto
     */
    'from'?: string;
    /**
     * 
     * @type {UserSecretInputDto}
     * @memberof MailchimpEmailSettingsInputDto
     */
    'apiKey'?: UserSecretInputDto;
}
/**
 * 
 * @export
 * @interface MailgunEmailSettingsDto
 */
export interface MailgunEmailSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof MailgunEmailSettingsDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailgunEmailSettingsDto
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailgunEmailSettingsDto
     */
    'region'?: string;
    /**
     * 
     * @type {UserSecretReferenceDto}
     * @memberof MailgunEmailSettingsDto
     */
    'apiKey'?: UserSecretReferenceDto;
}
/**
 * 
 * @export
 * @interface MailgunEmailSettingsInputDto
 */
export interface MailgunEmailSettingsInputDto {
    /**
     * 
     * @type {string}
     * @memberof MailgunEmailSettingsInputDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailgunEmailSettingsInputDto
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailgunEmailSettingsInputDto
     */
    'region'?: string;
    /**
     * 
     * @type {UserSecretInputDto}
     * @memberof MailgunEmailSettingsInputDto
     */
    'apiKey'?: UserSecretInputDto;
}
/**
 * 
 * @export
 * @interface MappingEntryDto
 */
export interface MappingEntryDto {
    /**
     * 
     * @type {string}
     * @memberof MappingEntryDto
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MappingEntryDto
     */
    'target'?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingEntryDto
     */
    'defaultValue'?: string | null;
}
/**
 * 
 * @export
 * @interface MarkChatMessagesAsReadDto
 */
export interface MarkChatMessagesAsReadDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof MarkChatMessagesAsReadDto
     */
    'messageIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface MarkChatMessagesAsReadResultDto
 */
export interface MarkChatMessagesAsReadResultDto {
    /**
     * 
     * @type {string}
     * @memberof MarkChatMessagesAsReadResultDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarkChatMessagesAsReadResultDto
     */
    'messageIds'?: Array<string>;
    /**
     * 
     * @type {ChatMessageReadByInfoDto}
     * @memberof MarkChatMessagesAsReadResultDto
     */
    'readByInfo'?: ChatMessageReadByInfoDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MeasurementSystemType = {
    None: 'None',
    Metric: 'Metric',
    Imperial: 'Imperial',
    UsCustomary: 'USCustomary'
} as const;

export type MeasurementSystemType = typeof MeasurementSystemType[keyof typeof MeasurementSystemType];


/**
 * 
 * @export
 * @enum {string}
 */

export const MeasurementUnit = {
    None: 'None',
    Hour: 'Hour',
    Period: 'Period',
    Liter: 'Liter',
    Piece: 'Piece',
    Gram: 'Gram',
    Millimeter: 'Millimeter'
} as const;

export type MeasurementUnit = typeof MeasurementUnit[keyof typeof MeasurementUnit];


/**
 * 
 * @export
 * @interface MileageChangedDataDto
 */
export interface MileageChangedDataDto {
    /**
     * 
     * @type {number}
     * @memberof MileageChangedDataDto
     */
    'oldMileage'?: number;
    /**
     * 
     * @type {number}
     * @memberof MileageChangedDataDto
     */
    'newMileage'?: number;
    /**
     * 
     * @type {string}
     * @memberof MileageChangedDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof MileageChangedDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
}
/**
 * Describes base type (or just type) of some MIME type.  Base type is a first part that goes before first slash:  \"type \"/\" [tree \".\"] subtype [\"+\" suffix]* [\";\" parameter]\".
 * @export
 * @enum {string}
 */

export const MimeBaseType = {
    None: 'None',
    Unknown: 'Unknown',
    Application: 'Application',
    Audio: 'Audio',
    Image: 'Image',
    Message: 'Message',
    Model: 'Model',
    Multipart: 'Multipart',
    Text: 'Text',
    Video: 'Video'
} as const;

export type MimeBaseType = typeof MimeBaseType[keyof typeof MimeBaseType];


/**
 * 
 * @export
 * @interface Ms365EmailSettingsDto
 */
export interface Ms365EmailSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof Ms365EmailSettingsDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ms365EmailSettingsDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ms365EmailSettingsDto
     */
    'clientId'?: string;
    /**
     * 
     * @type {UserSecretReferenceDto}
     * @memberof Ms365EmailSettingsDto
     */
    'clientSecret'?: UserSecretReferenceDto;
}
/**
 * 
 * @export
 * @interface Ms365EmailSettingsInputDto
 */
export interface Ms365EmailSettingsInputDto {
    /**
     * 
     * @type {string}
     * @memberof Ms365EmailSettingsInputDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ms365EmailSettingsInputDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ms365EmailSettingsInputDto
     */
    'clientId'?: string;
    /**
     * 
     * @type {UserSecretInputDto}
     * @memberof Ms365EmailSettingsInputDto
     */
    'clientSecret'?: UserSecretInputDto;
}
/**
 * 
 * @export
 * @interface NegotiationAllowedActionsDto
 */
export interface NegotiationAllowedActionsDto {
    /**
     * 
     * @type {string}
     * @memberof NegotiationAllowedActionsDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NegotiationAllowedActionsDto
     */
    'negotiationId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationAllowedActionsDto
     */
    'canManageNegotiation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationAllowedActionsDto
     */
    'canManageProposal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationAllowedActionsDto
     */
    'canCreateNewProposal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationAllowedActionsDto
     */
    'canRespondOnProposal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationAllowedActionsDto
     */
    'canForceRespondOnProposal'?: boolean;
    /**
     * 
     * @type {Array<ApprovalResponseType>}
     * @memberof NegotiationAllowedActionsDto
     */
    'allowedProposalResponseTypes'?: Array<ApprovalResponseType>;
}
/**
 * 
 * @export
 * @interface NegotiationChangedDto
 */
export interface NegotiationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof NegotiationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof NegotiationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof NegotiationChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof NegotiationChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof NegotiationChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {NegotiationDto}
     * @memberof NegotiationChangedDto
     */
    'entity'?: NegotiationDto;
    /**
     * 
     * @type {string}
     * @memberof NegotiationChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface NegotiationDto
 */
export interface NegotiationDto {
    /**
     * 
     * @type {string}
     * @memberof NegotiationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NegotiationDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NegotiationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof NegotiationDto
     */
    'localNumber'?: string | null;
    /**
     * 
     * @type {Array<ChatReferenceDto>}
     * @memberof NegotiationDto
     */
    'chats'?: Array<ChatReferenceDto>;
    /**
     * 
     * @type {GeneralScopeDto}
     * @memberof NegotiationDto
     */
    'scope'?: GeneralScopeDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof NegotiationDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {string}
     * @memberof NegotiationDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NegotiationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {NegotiationType}
     * @memberof NegotiationDto
     */
    'type'?: NegotiationType;
    /**
     * 
     * @type {NegotiationValueType}
     * @memberof NegotiationDto
     */
    'valueType'?: NegotiationValueType;
    /**
     * 
     * @type {NegotiationStatus}
     * @memberof NegotiationDto
     */
    'status'?: NegotiationStatus;
    /**
     * 
     * @type {Array<PartyReferenceDto>}
     * @memberof NegotiationDto
     */
    'moderatorParties'?: Array<PartyReferenceDto>;
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof NegotiationDto
     */
    'initiatorParty'?: PartyReferenceDto;
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof NegotiationDto
     */
    'targetParty'?: PartyReferenceDto;
    /**
     * 
     * @type {NegotiationSettingsDto}
     * @memberof NegotiationDto
     */
    'settings'?: NegotiationSettingsDto;
    /**
     * 
     * @type {Array<NegotiationProposalDto>}
     * @memberof NegotiationDto
     */
    'proposals'?: Array<NegotiationProposalDto>;
    /**
     * 
     * @type {NegotiationResultDto}
     * @memberof NegotiationDto
     */
    'result'?: NegotiationResultDto;
}


/**
 * 
 * @export
 * @interface NegotiationGetPaginatedDto
 */
export interface NegotiationGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof NegotiationGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof NegotiationGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof NegotiationGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NegotiationGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NegotiationGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NegotiationGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof NegotiationGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof NegotiationGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof NegotiationGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof NegotiationGetPaginatedDto
     */
    'chatId'?: string | null;
    /**
     * 
     * @type {NegotiationType}
     * @memberof NegotiationGetPaginatedDto
     */
    'type'?: NegotiationType;
    /**
     * 
     * @type {NegotiationStatus}
     * @memberof NegotiationGetPaginatedDto
     */
    'status'?: NegotiationStatus;
}


/**
 * 
 * @export
 * @interface NegotiationProposalDto
 */
export interface NegotiationProposalDto {
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'negotiationId'?: string;
    /**
     * 
     * @type {GeneralScopeDto}
     * @memberof NegotiationProposalDto
     */
    'scope'?: GeneralScopeDto;
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof NegotiationProposalDto
     */
    'party'?: PartyReferenceDto;
    /**
     * 
     * @type {PartyMemberReferenceDto}
     * @memberof NegotiationProposalDto
     */
    'partyMember'?: PartyMemberReferenceDto;
    /**
     * 
     * @type {ChatParticipantReferenceDto}
     * @memberof NegotiationProposalDto
     */
    'participant'?: ChatParticipantReferenceDto;
    /**
     * 
     * @type {NegotiationProposalSettingsDto}
     * @memberof NegotiationProposalDto
     */
    'settings'?: NegotiationProposalSettingsDto;
    /**
     * 
     * @type {ProposalStatus}
     * @memberof NegotiationProposalDto
     */
    'status'?: ProposalStatus;
    /**
     * 
     * @type {ConsensusType}
     * @memberof NegotiationProposalDto
     */
    'consensusType'?: ConsensusType;
    /**
     * 
     * @type {ApprovalStatus}
     * @memberof NegotiationProposalDto
     */
    'approvalStatus'?: ApprovalStatus;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'statement'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalDto
     */
    'isInitial'?: boolean;
    /**
     * 
     * @type {NegotiationProposalValueDto}
     * @memberof NegotiationProposalDto
     */
    'value'?: NegotiationProposalValueDto;
    /**
     * 
     * @type {Array<NegotiationProposalResponseDto>}
     * @memberof NegotiationProposalDto
     */
    'responses'?: Array<NegotiationProposalResponseDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'openedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'closedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalDto
     */
    'isAnyOpenStatus'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalDto
     */
    'isAnyClosedStatus'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalDto
     */
    'isAnyConsensusType'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'participantId'?: string | null;
}


/**
 * 
 * @export
 * @interface NegotiationProposalReferenceDto
 */
export interface NegotiationProposalReferenceDto {
    /**
     * 
     * @type {NegotiationReferenceDto}
     * @memberof NegotiationProposalReferenceDto
     */
    'negotiation'?: NegotiationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalReferenceDto
     */
    'partyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalReferenceDto
     */
    'statement'?: string;
}
/**
 * 
 * @export
 * @interface NegotiationProposalResponseDto
 */
export interface NegotiationProposalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof NegotiationProposalResponseDto
     */
    'party'?: PartyReferenceDto;
    /**
     * 
     * @type {PartyMemberReferenceDto}
     * @memberof NegotiationProposalResponseDto
     */
    'partyMember'?: PartyMemberReferenceDto;
    /**
     * 
     * @type {ApprovalResponseType}
     * @memberof NegotiationProposalResponseDto
     */
    'responseType'?: ApprovalResponseType;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalResponseDto
     */
    'isAuto'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationProposalResponseDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationProposalResponseDto
     */
    'updatedAt'?: string;
}


/**
 * 
 * @export
 * @interface NegotiationProposalSettingsDto
 */
export interface NegotiationProposalSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalSettingsDto
     */
    'allowChangeProposalResponse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalSettingsDto
     */
    'requireProposalConsensus'?: boolean;
}
/**
 * 
 * @export
 * @interface NegotiationProposalValueDto
 */
export interface NegotiationProposalValueDto {
    /**
     * 
     * @type {NegotiationValueType}
     * @memberof NegotiationProposalValueDto
     */
    'valueType'?: NegotiationValueType;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalValueDto
     */
    'bool'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalValueDto
     */
    'string'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NegotiationProposalValueDto
     */
    'int32'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NegotiationProposalValueDto
     */
    'double'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NegotiationProposalValueDto
     */
    'decimal'?: number | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationProposalValueDto
     */
    'dateTime'?: string | null;
    /**
     * 
     * @type {NegotiationProposalValuePriceDto}
     * @memberof NegotiationProposalValueDto
     */
    'price'?: NegotiationProposalValuePriceDto;
}


/**
 * 
 * @export
 * @interface NegotiationProposalValuePriceDto
 */
export interface NegotiationProposalValuePriceDto {
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {GeneralPriceDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'price'?: GeneralPriceDto;
    /**
     * 
     * @type {GeneralPriceDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'minPrice'?: GeneralPriceDto;
    /**
     * 
     * @type {GeneralPriceDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'maxPrice'?: GeneralPriceDto;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {NegotiationProposalValuePriceDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'initial'?: NegotiationProposalValuePriceDto;
    /**
     * 
     * @type {NegotiationProposalValuePriceDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'prev'?: NegotiationProposalValuePriceDto;
    /**
     * 
     * @type {number}
     * @memberof NegotiationProposalValuePriceDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof NegotiationProposalValuePriceDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof NegotiationProposalValuePriceDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface NegotiationReferenceDto
 */
export interface NegotiationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof NegotiationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {NegotiationType}
     * @memberof NegotiationReferenceDto
     */
    'type'?: NegotiationType;
    /**
     * 
     * @type {string}
     * @memberof NegotiationReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof NegotiationReferenceDto
     */
    'localNumber'?: string | null;
}


/**
 * 
 * @export
 * @interface NegotiationResultDto
 */
export interface NegotiationResultDto {
    /**
     * 
     * @type {ConsensusType}
     * @memberof NegotiationResultDto
     */
    'consensusType'?: ConsensusType;
    /**
     * 
     * @type {ApprovalStatus}
     * @memberof NegotiationResultDto
     */
    'approvalStatus'?: ApprovalStatus;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationResultDto
     */
    'isForced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationResultDto
     */
    'isAuto'?: boolean;
    /**
     * 
     * @type {NegotiationProposalValueDto}
     * @memberof NegotiationResultDto
     */
    'value'?: NegotiationProposalValueDto;
}


/**
 * 
 * @export
 * @interface NegotiationSettingsDto
 */
export interface NegotiationSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationSettingsDto
     */
    'allowCreateNewProposal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationSettingsDto
     */
    'requireProposalResolution'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationSettingsDto
     */
    'allowReopen'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationSettingsDto
     */
    'allowChangeProposalResponse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationSettingsDto
     */
    'requireProposalConsensus'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationSettingsDto
     */
    'deadlineAt'?: string | null;
    /**
     * 
     * @type {ApprovalResponseType}
     * @memberof NegotiationSettingsDto
     */
    'autoResponseTypeOnDeadline'?: ApprovalResponseType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationSettingsDto
     */
    'deadlineHandledAt'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NegotiationStatus = {
    None: 'None',
    Open: 'Open',
    Escalated: 'Escalated',
    Reopened: 'Reopened',
    Resolved: 'Resolved'
} as const;

export type NegotiationStatus = typeof NegotiationStatus[keyof typeof NegotiationStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const NegotiationType = {
    None: 'None',
    Approval: 'Approval',
    Value: 'Value'
} as const;

export type NegotiationType = typeof NegotiationType[keyof typeof NegotiationType];


/**
 * 
 * @export
 * @enum {string}
 */

export const NegotiationValueType = {
    None: 'None',
    Bool: 'Bool',
    String: 'String',
    Int32: 'Int32',
    Double: 'Double',
    Decimal: 'Decimal',
    DateTime: 'DateTime',
    Price: 'Price'
} as const;

export type NegotiationValueType = typeof NegotiationValueType[keyof typeof NegotiationValueType];


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationChannel = {
    None: 'None',
    InApp: 'InApp',
    Email: 'Email',
    Sms: 'Sms',
    Push: 'Push',
    WebPush: 'WebPush'
} as const;

export type NotificationChannel = typeof NotificationChannel[keyof typeof NotificationChannel];


/**
 * Source: NotificationService.Core.Models.NotificationCountersModel
 * @export
 * @interface NotificationCountersDto
 */
export interface NotificationCountersDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationCountersDto
     */
    'readCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationCountersDto
     */
    'unreadCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationCountersDto
     */
    'totalFilteredCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationCountersDto
     */
    'totalCount'?: number | null;
}
/**
 * 
 * @export
 * @interface NotificationDeliveryError
 */
export interface NotificationDeliveryError {
    /**
     * 
     * @type {string}
     * @memberof NotificationDeliveryError
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDeliveryError
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationDeliveryStatus = {
    None: 'None',
    Pending: 'Pending',
    Sent: 'Sent',
    Delivered: 'Delivered',
    Failed: 'Failed'
} as const;

export type NotificationDeliveryStatus = typeof NotificationDeliveryStatus[keyof typeof NotificationDeliveryStatus];


/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {NotificationChannel}
     * @memberof NotificationDto
     */
    'channel'?: NotificationChannel;
    /**
     * 
     * @type {NotificationImportance}
     * @memberof NotificationDto
     */
    'importance'?: NotificationImportance;
    /**
     * 
     * @type {InAppChannelInfoDto}
     * @memberof NotificationDto
     */
    'inApp'?: InAppChannelInfoDto;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    'isSent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    'isRead'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NotificationDto
     */
    'readAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NotificationDto
     */
    'sentAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NotificationDto
     */
    'createdAt'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationHubClientMethodName = {
    TestNotify: 'TestNotify',
    Broadcast: 'Broadcast',
    Echo: 'Echo',
    ServerErrorOccurred: 'ServerErrorOccurred',
    InAppNotificationReceived: 'InAppNotificationReceived'
} as const;

export type NotificationHubClientMethodName = typeof NotificationHubClientMethodName[keyof typeof NotificationHubClientMethodName];


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationHubServerMethodName = {
    Echo: 'Echo'
} as const;

export type NotificationHubServerMethodName = typeof NotificationHubServerMethodName[keyof typeof NotificationHubServerMethodName];


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationImportance = {
    None: 'None',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
} as const;

export type NotificationImportance = typeof NotificationImportance[keyof typeof NotificationImportance];


/**
 * 
 * @export
 * @interface NotificationSendTestDto
 */
export interface NotificationSendTestDto {
    /**
     * 
     * @type {NotificationChannel}
     * @memberof NotificationSendTestDto
     */
    'channel'?: NotificationChannel;
    /**
     * 
     * @type {NotificationSendTestEmailChannelInfoDto}
     * @memberof NotificationSendTestDto
     */
    'email'?: NotificationSendTestEmailChannelInfoDto;
}


/**
 * 
 * @export
 * @interface NotificationSendTestEmailChannelInfoDto
 */
export interface NotificationSendTestEmailChannelInfoDto {
    /**
     * Specify either email or user.
     * @type {string}
     * @memberof NotificationSendTestEmailChannelInfoDto
     */
    'toEmail'?: string | null;
    /**
     * Specify either email or user.
     * @type {string}
     * @memberof NotificationSendTestEmailChannelInfoDto
     */
    'toUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationSendTestEmailChannelInfoDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationSendTestEmailChannelInfoDto
     */
    'body'?: string | null;
}
/**
 * 
 * @export
 * @interface OperationCountersDto
 */
export interface OperationCountersDto {
    /**
     * 
     * @type {AccessoryCheckCountersDto}
     * @memberof OperationCountersDto
     */
    'accessoryChecks'?: AccessoryCheckCountersDto;
    /**
     * 
     * @type {VisualInspectionCountersDto}
     * @memberof OperationCountersDto
     */
    'visualInspections'?: VisualInspectionCountersDto;
    /**
     * 
     * @type {DamageDetectionCountersDto}
     * @memberof OperationCountersDto
     */
    'damageDetections'?: DamageDetectionCountersDto;
    /**
     * 
     * @type {DamageCostEvaluationCountersDto}
     * @memberof OperationCountersDto
     */
    'damageCostEvaluations'?: DamageCostEvaluationCountersDto;
    /**
     * 
     * @type {RepairOperationCountersDto}
     * @memberof OperationCountersDto
     */
    'repairOperations'?: RepairOperationCountersDto;
}
/**
 * 
 * @export
 * @interface OptionalClosedIntervalDtoOfDouble
 */
export interface OptionalClosedIntervalDtoOfDouble {
    /**
     * 
     * @type {number}
     * @memberof OptionalClosedIntervalDtoOfDouble
     */
    'start'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionalClosedIntervalDtoOfDouble
     */
    'end'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OptionalClosedIntervalDtoOfDouble
     */
    'isNull'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OptionalClosedIntervalDtoOfDouble
     */
    'isNotNull'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OptionalClosedIntervalDtoOfDouble
     */
    'isAnyNotNull'?: boolean;
}
/**
 * 
 * @export
 * @interface OptionalClosedIntervalDtoOfInt32
 */
export interface OptionalClosedIntervalDtoOfInt32 {
    /**
     * 
     * @type {number}
     * @memberof OptionalClosedIntervalDtoOfInt32
     */
    'start'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionalClosedIntervalDtoOfInt32
     */
    'end'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OptionalClosedIntervalDtoOfInt32
     */
    'isNull'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OptionalClosedIntervalDtoOfInt32
     */
    'isNotNull'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OptionalClosedIntervalDtoOfInt32
     */
    'isAnyNotNull'?: boolean;
}
/**
 * 
 * @export
 * @interface OutcomingCountersDtoInDataGrantCountersDto
 */
export interface OutcomingCountersDtoInDataGrantCountersDto {
    /**
     * 
     * @type {number}
     * @memberof OutcomingCountersDtoInDataGrantCountersDto
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface OutcomingCountersDtoInTenantConnectionRequestCountersDto
 */
export interface OutcomingCountersDtoInTenantConnectionRequestCountersDto {
    /**
     * 
     * @type {number}
     * @memberof OutcomingCountersDtoInTenantConnectionRequestCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutcomingCountersDtoInTenantConnectionRequestCountersDto
     */
    'pendingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutcomingCountersDtoInTenantConnectionRequestCountersDto
     */
    'acceptedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutcomingCountersDtoInTenantConnectionRequestCountersDto
     */
    'declinedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutcomingCountersDtoInTenantConnectionRequestCountersDto
     */
    'notRespondedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutcomingCountersDtoInTenantConnectionRequestCountersDto
     */
    'revokedCount'?: number;
}
/**
 * 
 * @export
 * @interface OutcomingCountersDtoInTenantRequestCountersDto
 */
export interface OutcomingCountersDtoInTenantRequestCountersDto {
    /**
     * 
     * @type {number}
     * @memberof OutcomingCountersDtoInTenantRequestCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutcomingCountersDtoInTenantRequestCountersDto
     */
    'declinedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutcomingCountersDtoInTenantRequestCountersDto
     */
    'reportingCount'?: number;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAccessoryCheckDto
 */
export interface PaginationDtoOfAccessoryCheckDto {
    /**
     * 
     * @type {Array<AccessoryCheckDto>}
     * @memberof PaginationDtoOfAccessoryCheckDto
     */
    'items'?: Array<AccessoryCheckDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAccessoryCheckDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAccessoryDto
 */
export interface PaginationDtoOfAccessoryDto {
    /**
     * 
     * @type {Array<AccessoryDto>}
     * @memberof PaginationDtoOfAccessoryDto
     */
    'items'?: Array<AccessoryDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAccessoryDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminDamageTypeDto
 */
export interface PaginationDtoOfAdminDamageTypeDto {
    /**
     * 
     * @type {Array<AdminDamageTypeDto>}
     * @memberof PaginationDtoOfAdminDamageTypeDto
     */
    'items'?: Array<AdminDamageTypeDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminDamageTypeDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminInviteDto
 */
export interface PaginationDtoOfAdminInviteDto {
    /**
     * 
     * @type {Array<AdminInviteDto>}
     * @memberof PaginationDtoOfAdminInviteDto
     */
    'items'?: Array<AdminInviteDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminInviteDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminInvoiceDto
 */
export interface PaginationDtoOfAdminInvoiceDto {
    /**
     * 
     * @type {Array<AdminInvoiceDto>}
     * @memberof PaginationDtoOfAdminInvoiceDto
     */
    'items'?: Array<AdminInvoiceDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminInvoiceDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminNotificationDto
 */
export interface PaginationDtoOfAdminNotificationDto {
    /**
     * 
     * @type {Array<AdminNotificationDto>}
     * @memberof PaginationDtoOfAdminNotificationDto
     */
    'items'?: Array<AdminNotificationDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminNotificationDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminSubscriptionDto
 */
export interface PaginationDtoOfAdminSubscriptionDto {
    /**
     * 
     * @type {Array<AdminSubscriptionDto>}
     * @memberof PaginationDtoOfAdminSubscriptionDto
     */
    'items'?: Array<AdminSubscriptionDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminSubscriptionDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminTenantDto
 */
export interface PaginationDtoOfAdminTenantDto {
    /**
     * 
     * @type {Array<AdminTenantDto>}
     * @memberof PaginationDtoOfAdminTenantDto
     */
    'items'?: Array<AdminTenantDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminTenantDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminUserDto
 */
export interface PaginationDtoOfAdminUserDto {
    /**
     * 
     * @type {Array<AdminUserDto>}
     * @memberof PaginationDtoOfAdminUserDto
     */
    'items'?: Array<AdminUserDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminUserDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminVehicleBodyTypeDto
 */
export interface PaginationDtoOfAdminVehicleBodyTypeDto {
    /**
     * 
     * @type {Array<AdminVehicleBodyTypeDto>}
     * @memberof PaginationDtoOfAdminVehicleBodyTypeDto
     */
    'items'?: Array<AdminVehicleBodyTypeDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminVehicleBodyTypeDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminVehicleFuelTypeDto
 */
export interface PaginationDtoOfAdminVehicleFuelTypeDto {
    /**
     * 
     * @type {Array<AdminVehicleFuelTypeDto>}
     * @memberof PaginationDtoOfAdminVehicleFuelTypeDto
     */
    'items'?: Array<AdminVehicleFuelTypeDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminVehicleFuelTypeDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminVehicleGenerationDto
 */
export interface PaginationDtoOfAdminVehicleGenerationDto {
    /**
     * 
     * @type {Array<AdminVehicleGenerationDto>}
     * @memberof PaginationDtoOfAdminVehicleGenerationDto
     */
    'items'?: Array<AdminVehicleGenerationDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminVehicleGenerationDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminVehicleMakeDto
 */
export interface PaginationDtoOfAdminVehicleMakeDto {
    /**
     * 
     * @type {Array<AdminVehicleMakeDto>}
     * @memberof PaginationDtoOfAdminVehicleMakeDto
     */
    'items'?: Array<AdminVehicleMakeDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminVehicleMakeDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminVehicleModelDto
 */
export interface PaginationDtoOfAdminVehicleModelDto {
    /**
     * 
     * @type {Array<AdminVehicleModelDto>}
     * @memberof PaginationDtoOfAdminVehicleModelDto
     */
    'items'?: Array<AdminVehicleModelDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminVehicleModelDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminVehicleModificationDto
 */
export interface PaginationDtoOfAdminVehicleModificationDto {
    /**
     * 
     * @type {Array<AdminVehicleModificationDto>}
     * @memberof PaginationDtoOfAdminVehicleModificationDto
     */
    'items'?: Array<AdminVehicleModificationDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminVehicleModificationDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminVehiclePartTypeDto
 */
export interface PaginationDtoOfAdminVehiclePartTypeDto {
    /**
     * 
     * @type {Array<AdminVehiclePartTypeDto>}
     * @memberof PaginationDtoOfAdminVehiclePartTypeDto
     */
    'items'?: Array<AdminVehiclePartTypeDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminVehiclePartTypeDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminVehicleTypeDto
 */
export interface PaginationDtoOfAdminVehicleTypeDto {
    /**
     * 
     * @type {Array<AdminVehicleTypeDto>}
     * @memberof PaginationDtoOfAdminVehicleTypeDto
     */
    'items'?: Array<AdminVehicleTypeDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminVehicleTypeDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAdminVehicleVisualModelDto
 */
export interface PaginationDtoOfAdminVehicleVisualModelDto {
    /**
     * 
     * @type {Array<AdminVehicleVisualModelDto>}
     * @memberof PaginationDtoOfAdminVehicleVisualModelDto
     */
    'items'?: Array<AdminVehicleVisualModelDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAdminVehicleVisualModelDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAssessmentFlowBriefDto
 */
export interface PaginationDtoOfAssessmentFlowBriefDto {
    /**
     * 
     * @type {Array<AssessmentFlowBriefDto>}
     * @memberof PaginationDtoOfAssessmentFlowBriefDto
     */
    'items'?: Array<AssessmentFlowBriefDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAssessmentFlowBriefDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAssessmentFlowDto
 */
export interface PaginationDtoOfAssessmentFlowDto {
    /**
     * 
     * @type {Array<AssessmentFlowDto>}
     * @memberof PaginationDtoOfAssessmentFlowDto
     */
    'items'?: Array<AssessmentFlowDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAssessmentFlowDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAssetCandidateEntityDto
 */
export interface PaginationDtoOfAssetCandidateEntityDto {
    /**
     * 
     * @type {Array<AssetCandidateEntityDto>}
     * @memberof PaginationDtoOfAssetCandidateEntityDto
     */
    'items'?: Array<AssetCandidateEntityDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAssetCandidateEntityDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAssetDto
 */
export interface PaginationDtoOfAssetDto {
    /**
     * 
     * @type {Array<AssetDto>}
     * @memberof PaginationDtoOfAssetDto
     */
    'items'?: Array<AssetDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAssetDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAssetSubscriptionDto
 */
export interface PaginationDtoOfAssetSubscriptionDto {
    /**
     * 
     * @type {Array<AssetSubscriptionDto>}
     * @memberof PaginationDtoOfAssetSubscriptionDto
     */
    'items'?: Array<AssetSubscriptionDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAssetSubscriptionDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfAssetSubscriptionPlanDto
 */
export interface PaginationDtoOfAssetSubscriptionPlanDto {
    /**
     * 
     * @type {Array<AssetSubscriptionPlanDto>}
     * @memberof PaginationDtoOfAssetSubscriptionPlanDto
     */
    'items'?: Array<AssetSubscriptionPlanDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfAssetSubscriptionPlanDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfChatActivityDto
 */
export interface PaginationDtoOfChatActivityDto {
    /**
     * 
     * @type {Array<ChatActivityDto>}
     * @memberof PaginationDtoOfChatActivityDto
     */
    'items'?: Array<ChatActivityDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfChatActivityDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfChatDto
 */
export interface PaginationDtoOfChatDto {
    /**
     * 
     * @type {Array<ChatDto>}
     * @memberof PaginationDtoOfChatDto
     */
    'items'?: Array<ChatDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfChatDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfChatEventDto
 */
export interface PaginationDtoOfChatEventDto {
    /**
     * 
     * @type {Array<ChatEventDto>}
     * @memberof PaginationDtoOfChatEventDto
     */
    'items'?: Array<ChatEventDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfChatEventDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfChatHistoryItemDto
 */
export interface PaginationDtoOfChatHistoryItemDto {
    /**
     * 
     * @type {Array<ChatHistoryItemDto>}
     * @memberof PaginationDtoOfChatHistoryItemDto
     */
    'items'?: Array<ChatHistoryItemDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfChatHistoryItemDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfChatMessageDto
 */
export interface PaginationDtoOfChatMessageDto {
    /**
     * 
     * @type {Array<ChatMessageDto>}
     * @memberof PaginationDtoOfChatMessageDto
     */
    'items'?: Array<ChatMessageDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfChatMessageDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfChatParticipantCandidateDto
 */
export interface PaginationDtoOfChatParticipantCandidateDto {
    /**
     * 
     * @type {Array<ChatParticipantCandidateDto>}
     * @memberof PaginationDtoOfChatParticipantCandidateDto
     */
    'items'?: Array<ChatParticipantCandidateDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfChatParticipantCandidateDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfChatParticipantDto
 */
export interface PaginationDtoOfChatParticipantDto {
    /**
     * 
     * @type {Array<ChatParticipantDto>}
     * @memberof PaginationDtoOfChatParticipantDto
     */
    'items'?: Array<ChatParticipantDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfChatParticipantDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfContractDto
 */
export interface PaginationDtoOfContractDto {
    /**
     * 
     * @type {Array<ContractDto>}
     * @memberof PaginationDtoOfContractDto
     */
    'items'?: Array<ContractDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfContractDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfCustomSessionDto
 */
export interface PaginationDtoOfCustomSessionDto {
    /**
     * 
     * @type {Array<CustomSessionDto>}
     * @memberof PaginationDtoOfCustomSessionDto
     */
    'items'?: Array<CustomSessionDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfCustomSessionDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfCustomTagDto
 */
export interface PaginationDtoOfCustomTagDto {
    /**
     * 
     * @type {Array<CustomTagDto>}
     * @memberof PaginationDtoOfCustomTagDto
     */
    'items'?: Array<CustomTagDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfCustomTagDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfCustomerDto
 */
export interface PaginationDtoOfCustomerDto {
    /**
     * 
     * @type {Array<CustomerDto>}
     * @memberof PaginationDtoOfCustomerDto
     */
    'items'?: Array<CustomerDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfCustomerDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfDamageCostEvaluationAggregateDto
 */
export interface PaginationDtoOfDamageCostEvaluationAggregateDto {
    /**
     * 
     * @type {Array<DamageCostEvaluationAggregateDto>}
     * @memberof PaginationDtoOfDamageCostEvaluationAggregateDto
     */
    'items'?: Array<DamageCostEvaluationAggregateDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfDamageCostEvaluationAggregateDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfDamageCostEvaluationDto
 */
export interface PaginationDtoOfDamageCostEvaluationDto {
    /**
     * 
     * @type {Array<DamageCostEvaluationDto>}
     * @memberof PaginationDtoOfDamageCostEvaluationDto
     */
    'items'?: Array<DamageCostEvaluationDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfDamageCostEvaluationDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfDamageDetectionAggregateDto
 */
export interface PaginationDtoOfDamageDetectionAggregateDto {
    /**
     * 
     * @type {Array<DamageDetectionAggregateDto>}
     * @memberof PaginationDtoOfDamageDetectionAggregateDto
     */
    'items'?: Array<DamageDetectionAggregateDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfDamageDetectionAggregateDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfDamageDetectionDto
 */
export interface PaginationDtoOfDamageDetectionDto {
    /**
     * 
     * @type {Array<DamageDetectionDto>}
     * @memberof PaginationDtoOfDamageDetectionDto
     */
    'items'?: Array<DamageDetectionDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfDamageDetectionDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfDamageTypeDto
 */
export interface PaginationDtoOfDamageTypeDto {
    /**
     * 
     * @type {Array<DamageTypeDto>}
     * @memberof PaginationDtoOfDamageTypeDto
     */
    'items'?: Array<DamageTypeDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfDamageTypeDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfDataGrantDto
 */
export interface PaginationDtoOfDataGrantDto {
    /**
     * 
     * @type {Array<DataGrantDto>}
     * @memberof PaginationDtoOfDataGrantDto
     */
    'items'?: Array<DataGrantDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfDataGrantDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfDepartmentDto
 */
export interface PaginationDtoOfDepartmentDto {
    /**
     * 
     * @type {Array<DepartmentDto>}
     * @memberof PaginationDtoOfDepartmentDto
     */
    'items'?: Array<DepartmentDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfDepartmentDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfDocumentDto
 */
export interface PaginationDtoOfDocumentDto {
    /**
     * 
     * @type {Array<DocumentDto>}
     * @memberof PaginationDtoOfDocumentDto
     */
    'items'?: Array<DocumentDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfDocumentDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfGeneralEventLogDto
 */
export interface PaginationDtoOfGeneralEventLogDto {
    /**
     * 
     * @type {Array<GeneralEventLogDto>}
     * @memberof PaginationDtoOfGeneralEventLogDto
     */
    'items'?: Array<GeneralEventLogDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfGeneralEventLogDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfGeneralHistoryDto
 */
export interface PaginationDtoOfGeneralHistoryDto {
    /**
     * 
     * @type {Array<GeneralHistoryDto>}
     * @memberof PaginationDtoOfGeneralHistoryDto
     */
    'items'?: Array<GeneralHistoryDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfGeneralHistoryDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfGeneralTagCandidateDto
 */
export interface PaginationDtoOfGeneralTagCandidateDto {
    /**
     * 
     * @type {Array<GeneralTagCandidateDto>}
     * @memberof PaginationDtoOfGeneralTagCandidateDto
     */
    'items'?: Array<GeneralTagCandidateDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfGeneralTagCandidateDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfImportDto
 */
export interface PaginationDtoOfImportDto {
    /**
     * 
     * @type {Array<ImportDto>}
     * @memberof PaginationDtoOfImportDto
     */
    'items'?: Array<ImportDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfImportDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfIntegrationApiClientDto
 */
export interface PaginationDtoOfIntegrationApiClientDto {
    /**
     * 
     * @type {Array<IntegrationApiClientDto>}
     * @memberof PaginationDtoOfIntegrationApiClientDto
     */
    'items'?: Array<IntegrationApiClientDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfIntegrationApiClientDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfInvoiceDto
 */
export interface PaginationDtoOfInvoiceDto {
    /**
     * 
     * @type {Array<InvoiceDto>}
     * @memberof PaginationDtoOfInvoiceDto
     */
    'items'?: Array<InvoiceDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfInvoiceDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfLocationDto
 */
export interface PaginationDtoOfLocationDto {
    /**
     * 
     * @type {Array<LocationDto>}
     * @memberof PaginationDtoOfLocationDto
     */
    'items'?: Array<LocationDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfLocationDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfNegotiationDto
 */
export interface PaginationDtoOfNegotiationDto {
    /**
     * 
     * @type {Array<NegotiationDto>}
     * @memberof PaginationDtoOfNegotiationDto
     */
    'items'?: Array<NegotiationDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfNegotiationDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfNotificationDto
 */
export interface PaginationDtoOfNotificationDto {
    /**
     * 
     * @type {Array<NotificationDto>}
     * @memberof PaginationDtoOfNotificationDto
     */
    'items'?: Array<NotificationDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfNotificationDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfPoolDto
 */
export interface PaginationDtoOfPoolDto {
    /**
     * 
     * @type {Array<PoolDto>}
     * @memberof PaginationDtoOfPoolDto
     */
    'items'?: Array<PoolDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfPoolDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfPoolItemCandidateEntityDto
 */
export interface PaginationDtoOfPoolItemCandidateEntityDto {
    /**
     * 
     * @type {Array<PoolItemCandidateEntityDto>}
     * @memberof PaginationDtoOfPoolItemCandidateEntityDto
     */
    'items'?: Array<PoolItemCandidateEntityDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfPoolItemCandidateEntityDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfPoolItemDto
 */
export interface PaginationDtoOfPoolItemDto {
    /**
     * 
     * @type {Array<PoolItemDto>}
     * @memberof PaginationDtoOfPoolItemDto
     */
    'items'?: Array<PoolItemDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfPoolItemDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfProductLocationDto
 */
export interface PaginationDtoOfProductLocationDto {
    /**
     * 
     * @type {Array<ProductLocationDto>}
     * @memberof PaginationDtoOfProductLocationDto
     */
    'items'?: Array<ProductLocationDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfProductLocationDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfRepairCatalogDto
 */
export interface PaginationDtoOfRepairCatalogDto {
    /**
     * 
     * @type {Array<RepairCatalogDto>}
     * @memberof PaginationDtoOfRepairCatalogDto
     */
    'items'?: Array<RepairCatalogDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfRepairCatalogDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfRepairMaterialDto
 */
export interface PaginationDtoOfRepairMaterialDto {
    /**
     * 
     * @type {Array<RepairMaterialDto>}
     * @memberof PaginationDtoOfRepairMaterialDto
     */
    'items'?: Array<RepairMaterialDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfRepairMaterialDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfRepairOperationDto
 */
export interface PaginationDtoOfRepairOperationDto {
    /**
     * 
     * @type {Array<RepairOperationDto>}
     * @memberof PaginationDtoOfRepairOperationDto
     */
    'items'?: Array<RepairOperationDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfRepairOperationDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfRepairSparePartDto
 */
export interface PaginationDtoOfRepairSparePartDto {
    /**
     * 
     * @type {Array<RepairSparePartDto>}
     * @memberof PaginationDtoOfRepairSparePartDto
     */
    'items'?: Array<RepairSparePartDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfRepairSparePartDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfRepairSpecDto
 */
export interface PaginationDtoOfRepairSpecDto {
    /**
     * 
     * @type {Array<RepairSpecDto>}
     * @memberof PaginationDtoOfRepairSpecDto
     */
    'items'?: Array<RepairSpecDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfRepairSpecDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfRepairWorkDto
 */
export interface PaginationDtoOfRepairWorkDto {
    /**
     * 
     * @type {Array<RepairWorkDto>}
     * @memberof PaginationDtoOfRepairWorkDto
     */
    'items'?: Array<RepairWorkDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfRepairWorkDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfSpotDto
 */
export interface PaginationDtoOfSpotDto {
    /**
     * 
     * @type {Array<SpotDto>}
     * @memberof PaginationDtoOfSpotDto
     */
    'items'?: Array<SpotDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfSpotDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfSubscriptionDto
 */
export interface PaginationDtoOfSubscriptionDto {
    /**
     * 
     * @type {Array<SubscriptionDto>}
     * @memberof PaginationDtoOfSubscriptionDto
     */
    'items'?: Array<SubscriptionDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfSubscriptionDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfTItem
 */
export interface PaginationDtoOfTItem {
    /**
     * 
     * @type {Array<object>}
     * @memberof PaginationDtoOfTItem
     */
    'items'?: Array<object>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfTItem
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfTenantConnectionDto
 */
export interface PaginationDtoOfTenantConnectionDto {
    /**
     * 
     * @type {Array<TenantConnectionDto>}
     * @memberof PaginationDtoOfTenantConnectionDto
     */
    'items'?: Array<TenantConnectionDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfTenantConnectionDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfTenantConnectionRequestDto
 */
export interface PaginationDtoOfTenantConnectionRequestDto {
    /**
     * 
     * @type {Array<TenantConnectionRequestDto>}
     * @memberof PaginationDtoOfTenantConnectionRequestDto
     */
    'items'?: Array<TenantConnectionRequestDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfTenantConnectionRequestDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfTenantDto
 */
export interface PaginationDtoOfTenantDto {
    /**
     * 
     * @type {Array<TenantDto>}
     * @memberof PaginationDtoOfTenantDto
     */
    'items'?: Array<TenantDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfTenantDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfTenantInviteDto
 */
export interface PaginationDtoOfTenantInviteDto {
    /**
     * 
     * @type {Array<TenantInviteDto>}
     * @memberof PaginationDtoOfTenantInviteDto
     */
    'items'?: Array<TenantInviteDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfTenantInviteDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfTenantRequestDto
 */
export interface PaginationDtoOfTenantRequestDto {
    /**
     * 
     * @type {Array<TenantRequestDto>}
     * @memberof PaginationDtoOfTenantRequestDto
     */
    'items'?: Array<TenantRequestDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfTenantRequestDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfUserDto
 */
export interface PaginationDtoOfUserDto {
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof PaginationDtoOfUserDto
     */
    'items'?: Array<UserDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfUserDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfVehicleBodyTypeDto
 */
export interface PaginationDtoOfVehicleBodyTypeDto {
    /**
     * 
     * @type {Array<VehicleBodyTypeDto>}
     * @memberof PaginationDtoOfVehicleBodyTypeDto
     */
    'items'?: Array<VehicleBodyTypeDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfVehicleBodyTypeDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfVehicleDamageDto
 */
export interface PaginationDtoOfVehicleDamageDto {
    /**
     * 
     * @type {Array<VehicleDamageDto>}
     * @memberof PaginationDtoOfVehicleDamageDto
     */
    'items'?: Array<VehicleDamageDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfVehicleDamageDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfVehicleDto
 */
export interface PaginationDtoOfVehicleDto {
    /**
     * 
     * @type {Array<VehicleDto>}
     * @memberof PaginationDtoOfVehicleDto
     */
    'items'?: Array<VehicleDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfVehicleDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfVehicleFuelTypeDto
 */
export interface PaginationDtoOfVehicleFuelTypeDto {
    /**
     * 
     * @type {Array<VehicleFuelTypeDto>}
     * @memberof PaginationDtoOfVehicleFuelTypeDto
     */
    'items'?: Array<VehicleFuelTypeDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfVehicleFuelTypeDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfVehicleGenerationDto
 */
export interface PaginationDtoOfVehicleGenerationDto {
    /**
     * 
     * @type {Array<VehicleGenerationDto>}
     * @memberof PaginationDtoOfVehicleGenerationDto
     */
    'items'?: Array<VehicleGenerationDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfVehicleGenerationDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfVehicleHistoryItemDto
 */
export interface PaginationDtoOfVehicleHistoryItemDto {
    /**
     * 
     * @type {Array<VehicleHistoryItemDto>}
     * @memberof PaginationDtoOfVehicleHistoryItemDto
     */
    'items'?: Array<VehicleHistoryItemDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfVehicleHistoryItemDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfVehicleMakeDto
 */
export interface PaginationDtoOfVehicleMakeDto {
    /**
     * 
     * @type {Array<VehicleMakeDto>}
     * @memberof PaginationDtoOfVehicleMakeDto
     */
    'items'?: Array<VehicleMakeDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfVehicleMakeDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfVehicleModelDto
 */
export interface PaginationDtoOfVehicleModelDto {
    /**
     * 
     * @type {Array<VehicleModelDto>}
     * @memberof PaginationDtoOfVehicleModelDto
     */
    'items'?: Array<VehicleModelDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfVehicleModelDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfVehicleModificationDto
 */
export interface PaginationDtoOfVehicleModificationDto {
    /**
     * 
     * @type {Array<VehicleModificationDto>}
     * @memberof PaginationDtoOfVehicleModificationDto
     */
    'items'?: Array<VehicleModificationDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfVehicleModificationDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfVehiclePartTypeDto
 */
export interface PaginationDtoOfVehiclePartTypeDto {
    /**
     * 
     * @type {Array<VehiclePartTypeDto>}
     * @memberof PaginationDtoOfVehiclePartTypeDto
     */
    'items'?: Array<VehiclePartTypeDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfVehiclePartTypeDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfVehicleTypeDto
 */
export interface PaginationDtoOfVehicleTypeDto {
    /**
     * 
     * @type {Array<VehicleTypeDto>}
     * @memberof PaginationDtoOfVehicleTypeDto
     */
    'items'?: Array<VehicleTypeDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfVehicleTypeDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfVehicleVisualModelDto
 */
export interface PaginationDtoOfVehicleVisualModelDto {
    /**
     * 
     * @type {Array<VehicleVisualModelDto>}
     * @memberof PaginationDtoOfVehicleVisualModelDto
     */
    'items'?: Array<VehicleVisualModelDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfVehicleVisualModelDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfVisualInspectionDto
 */
export interface PaginationDtoOfVisualInspectionDto {
    /**
     * 
     * @type {Array<VisualInspectionDto>}
     * @memberof PaginationDtoOfVisualInspectionDto
     */
    'items'?: Array<VisualInspectionDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfVisualInspectionDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfWebhookActivationDto
 */
export interface PaginationDtoOfWebhookActivationDto {
    /**
     * 
     * @type {Array<WebhookActivationDto>}
     * @memberof PaginationDtoOfWebhookActivationDto
     */
    'items'?: Array<WebhookActivationDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfWebhookActivationDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfWebhookDto
 */
export interface PaginationDtoOfWebhookDto {
    /**
     * 
     * @type {Array<WebhookDto>}
     * @memberof PaginationDtoOfWebhookDto
     */
    'items'?: Array<WebhookDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfWebhookDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationInfoDto
 */
export interface PaginationInfoDto {
    /**
     * Total number of records in the requested dataset.
     * @type {number}
     * @memberof PaginationInfoDto
     */
    'totalCount'?: number;
    /**
     * Zero-based offset.
     * @type {number}
     * @memberof PaginationInfoDto
     */
    'offset'?: number;
    /**
     * The same as page size.
     * @type {number}
     * @memberof PaginationInfoDto
     */
    'limit'?: number;
    /**
     * Current page zero-based index.
     * @type {number}
     * @memberof PaginationInfoDto
     */
    'currentPage'?: number;
    /**
     * Total number of pages.
     * @type {number}
     * @memberof PaginationInfoDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PairDtoOfStringAndString
 */
export interface PairDtoOfStringAndString {
    /**
     * 
     * @type {string}
     * @memberof PairDtoOfStringAndString
     */
    'first'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PairDtoOfStringAndString
     */
    'second'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ParticipantOnlineStatus = {
    None: 'None',
    Offline: 'Offline',
    Online: 'Online'
} as const;

export type ParticipantOnlineStatus = typeof ParticipantOnlineStatus[keyof typeof ParticipantOnlineStatus];


/**
 * 
 * @export
 * @interface PartyAndMemberDto
 */
export interface PartyAndMemberDto {
    /**
     * 
     * @type {PartyDto}
     * @memberof PartyAndMemberDto
     */
    'party'?: PartyDto;
    /**
     * 
     * @type {PartyMemberReferenceDto}
     * @memberof PartyAndMemberDto
     */
    'member'?: PartyMemberReferenceDto;
}
/**
 * 
 * @export
 * @interface PartyAndMemberReferenceDto
 */
export interface PartyAndMemberReferenceDto {
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof PartyAndMemberReferenceDto
     */
    'party'?: PartyReferenceDto;
    /**
     * 
     * @type {PartyMemberReferenceDto}
     * @memberof PartyAndMemberReferenceDto
     */
    'member'?: PartyMemberReferenceDto;
}
/**
 * 
 * @export
 * @interface PartyChangedDto
 */
export interface PartyChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PartyChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PartyChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof PartyChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof PartyChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof PartyChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {PartyDto}
     * @memberof PartyChangedDto
     */
    'entity'?: PartyDto;
    /**
     * 
     * @type {string}
     * @memberof PartyChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface PartyDto
 */
export interface PartyDto {
    /**
     * 
     * @type {string}
     * @memberof PartyDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof PartyDto
     */
    'parentParty'?: PartyReferenceDto;
    /**
     * 
     * @type {PartySourceDto}
     * @memberof PartyDto
     */
    'source'?: PartySourceDto;
    /**
     * 
     * @type {PartyType}
     * @memberof PartyDto
     */
    'type'?: PartyType;
    /**
     * 
     * @type {string}
     * @memberof PartyDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartyDto
     */
    'memberCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartyDto
     */
    'isSystem'?: boolean;
}


/**
 * 
 * @export
 * @interface PartyEntity
 */
export interface PartyEntity {
    /**
     * 
     * @type {string}
     * @memberof PartyEntity
     */
    'id'?: string;
    /**
     * <inheritdoc cref=\"P:Domain.Interfaces.IAccountable.CreatedAt\" />
     * @type {string}
     * @memberof PartyEntity
     */
    'createdAt'?: string;
    /**
     * <inheritdoc cref=\"P:Domain.Interfaces.IAccountable.UpdatedAt\" />
     * @type {string}
     * @memberof PartyEntity
     */
    'updatedAt'?: string;
    /**
     * <inheritdoc cref=\"P:Domain.Interfaces.IAccountable.CreatedBy\" />
     * @type {string}
     * @memberof PartyEntity
     */
    'createdBy'?: string | null;
    /**
     * <inheritdoc cref=\"P:Domain.Interfaces.IAccountable.UpdatedBy\" />
     * @type {string}
     * @memberof PartyEntity
     */
    'updatedBy'?: string | null;
    /**
     * Is updated manually by user.
     * @type {boolean}
     * @memberof PartyEntity
     */
    'isUpdatedManually'?: boolean;
    /**
     * Is updated by the system or manually by unknown user.
     * @type {boolean}
     * @memberof PartyEntity
     */
    'isUpdatedBySystemOrUnknown'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartyEntity
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {PartyReference}
     * @memberof PartyEntity
     */
    'parentParty'?: PartyReference;
    /**
     * 
     * @type {PartySource}
     * @memberof PartyEntity
     */
    'source'?: PartySource;
    /**
     * 
     * @type {PartyType}
     * @memberof PartyEntity
     */
    'type'?: PartyType;
    /**
     * 
     * @type {string}
     * @memberof PartyEntity
     */
    'name'?: string;
    /**
     * Whether to infer members based on party type or use stored member list.  E.g. members can be inferred from tenant or department.
     * @type {boolean}
     * @memberof PartyEntity
     */
    'isInferMembers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartyEntity
     */
    'allowAddMembers'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartyEntity
     */
    'memberCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartyEntity
     */
    'isSystem'?: boolean;
}


/**
 * 
 * @export
 * @interface PartyMemberChangedDto
 */
export interface PartyMemberChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PartyMemberChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof PartyMemberChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof PartyMemberChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {PartyMemberDto}
     * @memberof PartyMemberChangedDto
     */
    'entity'?: PartyMemberDto;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface PartyMemberDto
 */
export interface PartyMemberDto {
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof PartyMemberDto
     */
    'party'?: PartyReferenceDto;
    /**
     * 
     * @type {PartyMemberSourceDto}
     * @memberof PartyMemberDto
     */
    'source'?: PartyMemberSourceDto;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {UserMembershipType}
     * @memberof PartyMemberDto
     */
    'membershipType'?: UserMembershipType;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PartyMemberDto
     */
    'isSystem'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'comparisonId'?: string;
}


/**
 * 
 * @export
 * @interface PartyMemberReferenceDto
 */
export interface PartyMemberReferenceDto {
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof PartyMemberReferenceDto
     */
    'party'?: PartyReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PartyMemberReferenceDto
     */
    'isInferred'?: boolean;
    /**
     * 
     * @type {PartyMemberSourceDto}
     * @memberof PartyMemberReferenceDto
     */
    'source'?: PartyMemberSourceDto;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PartyMemberReferenceDto
     */
    'isSystem'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'comparisonId'?: string;
}
/**
 * 
 * @export
 * @interface PartyMemberSourceDto
 */
export interface PartyMemberSourceDto {
    /**
     * 
     * @type {PartyMemberSourceType}
     * @memberof PartyMemberSourceDto
     */
    'sourceType'?: PartyMemberSourceType;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberSourceDto
     */
    'sourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberSourceDto
     */
    'systemIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberSourceDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberSourceDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberSourceDto
     */
    'customerContactId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberSourceDto
     */
    'customerUserId'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PartyMemberSourceType = {
    None: 'None',
    System: 'System',
    TenantUser: 'TenantUser',
    DepartmentUser: 'DepartmentUser',
    User: 'User',
    UserGroupUser: 'UserGroupUser',
    CustomerContact: 'CustomerContact',
    CustomerContactUser: 'CustomerContactUser'
} as const;

export type PartyMemberSourceType = typeof PartyMemberSourceType[keyof typeof PartyMemberSourceType];


/**
 * 
 * @export
 * @interface PartyReference
 */
export interface PartyReference {
    /**
     * 
     * @type {string}
     * @memberof PartyReference
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyReference
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {PartyType}
     * @memberof PartyReference
     */
    'type'?: PartyType;
    /**
     * 
     * @type {string}
     * @memberof PartyReference
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartyReference
     */
    'isSystem'?: boolean;
}


/**
 * 
 * @export
 * @interface PartyReferenceDto
 */
export interface PartyReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof PartyReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {PartyType}
     * @memberof PartyReferenceDto
     */
    'type'?: PartyType;
    /**
     * 
     * @type {string}
     * @memberof PartyReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartyReferenceDto
     */
    'isSystem'?: boolean;
}


/**
 * 
 * @export
 * @interface PartySource
 */
export interface PartySource {
    /**
     * 
     * @type {PartySourceType}
     * @memberof PartySource
     */
    'sourceType'?: PartySourceType;
    /**
     * Id of source object/entity the party is associated with. Can be any unique string.
     * @type {string}
     * @memberof PartySource
     */
    'sourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartySource
     */
    'systemIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySource
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySource
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySource
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySource
     */
    'userGroupIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySource
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PartySource
     */
    'sourceIdIsObjectId'?: boolean;
}


/**
 * 
 * @export
 * @interface PartySourceDto
 */
export interface PartySourceDto {
    /**
     * 
     * @type {PartySourceType}
     * @memberof PartySourceDto
     */
    'sourceType'?: PartySourceType;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'sourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'systemIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'userGroupIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'customerId'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PartySourceType = {
    None: 'None',
    System: 'System',
    Tenant: 'Tenant',
    Department: 'Department',
    User: 'User',
    UserGroup: 'UserGroup',
    Customer: 'Customer'
} as const;

export type PartySourceType = typeof PartySourceType[keyof typeof PartySourceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PartyType = {
    None: 'None',
    System: 'System',
    Tenant: 'Tenant',
    Department: 'Department',
    User: 'User',
    UserGroup: 'UserGroup',
    Customer: 'Customer'
} as const;

export type PartyType = typeof PartyType[keyof typeof PartyType];


/**
 * 
 * @export
 * @interface PasswordPolicyDto
 */
export interface PasswordPolicyDto {
    /**
     * 
     * @type {number}
     * @memberof PasswordPolicyDto
     */
    'minLength'?: number;
    /**
     * 
     * @type {number}
     * @memberof PasswordPolicyDto
     */
    'maxLength'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordPolicyDto
     */
    'withNumbers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordPolicyDto
     */
    'withUpperCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordPolicyDto
     */
    'withLowerCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordPolicyDto
     */
    'withSpecialChars'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PasswordPolicyDto
     */
    'allowedSpecialChars'?: string;
}
/**
 * 
 * @export
 * @interface PasswordValidateResultDto
 */
export interface PasswordValidateResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof PasswordValidateResultDto
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordValidateResultDto
     */
    'minLength'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordValidateResultDto
     */
    'maxLength'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordValidateResultDto
     */
    'withNumbers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordValidateResultDto
     */
    'withUpperCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordValidateResultDto
     */
    'withLowerCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordValidateResultDto
     */
    'withSpecialChars'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PasswordValidateResultDto
     */
    'allowedSpecialChars'?: string;
}
/**
 * 
 * @export
 * @interface PaymentBusinessInfoDto
 */
export interface PaymentBusinessInfoDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentBusinessInfoDto
     */
    'businessName'?: string;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof PaymentBusinessInfoDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof PaymentBusinessInfoDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentBusinessInfoDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof PaymentBusinessInfoDto
     */
    'address'?: GeneralAddressDto;
}
/**
 * 
 * @export
 * @interface PaymentBusinessInfoInputDto
 */
export interface PaymentBusinessInfoInputDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentBusinessInfoInputDto
     */
    'businessName'?: string;
    /**
     * 
     * @type {GeneralPersonNameInputDto}
     * @memberof PaymentBusinessInfoInputDto
     */
    'personName'?: GeneralPersonNameInputDto;
    /**
     * 
     * @type {string}
     * @memberof PaymentBusinessInfoInputDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentBusinessInfoInputDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {GeneralAddressInputDto}
     * @memberof PaymentBusinessInfoInputDto
     */
    'address'?: GeneralAddressInputDto;
}
/**
 * 
 * @export
 * @interface PaymentContactDto
 */
export interface PaymentContactDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentContactDto
     */
    'id'?: string;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof PaymentContactDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof PaymentContactDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentContactDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentContactDto
     */
    'isPrimary'?: boolean;
}
/**
 * 
 * @export
 * @interface PaymentContactInputDto
 */
export interface PaymentContactInputDto {
    /**
     * 
     * @type {GeneralPersonNameInputDto}
     * @memberof PaymentContactInputDto
     */
    'personName'?: GeneralPersonNameInputDto;
    /**
     * 
     * @type {string}
     * @memberof PaymentContactInputDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentContactInputDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentContactInputDto
     */
    'isPrimary'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentMethod = {
    None: 'None',
    Manual: 'Manual',
    Stripe: 'Stripe'
} as const;

export type PaymentMethod = typeof PaymentMethod[keyof typeof PaymentMethod];


/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentRegularityType = {
    None: 'None',
    OneTime: 'OneTime',
    Recurring: 'Recurring'
} as const;

export type PaymentRegularityType = typeof PaymentRegularityType[keyof typeof PaymentRegularityType];


/**
 * 
 * @export
 * @interface PermissionDto
 */
export interface PermissionDto {
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    'computedId'?: string;
    /**
     * 
     * @type {AppPermission}
     * @memberof PermissionDto
     */
    'permission'?: AppPermission;
    /**
     * GroupName, which groups permissions working in the same area
     * @type {string}
     * @memberof PermissionDto
     */
    'groupName'?: string;
    /**
     * Name of the permission - often says what it does, e.g. Read
     * @type {string}
     * @memberof PermissionDto
     */
    'name'?: string;
    /**
     * Long description of what action this permission allows
     * @type {string}
     * @memberof PermissionDto
     */
    'description'?: string;
    /**
     * Contains an optional app module that this feature is linked to
     * @type {string}
     * @memberof PermissionDto
     */
    'moduleName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDto
     */
    'isTest'?: boolean;
}


/**
 * 
 * @export
 * @interface PoolAvailabilityDto
 */
export interface PoolAvailabilityDto {
    /**
     * 
     * @type {PoolStateDto}
     * @memberof PoolAvailabilityDto
     */
    'state'?: PoolStateDto;
    /**
     * 
     * @type {number}
     * @memberof PoolAvailabilityDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolAvailabilityDto
     */
    'requestedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolAvailabilityDto
     */
    'otherCount'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolAvailabilityDto
     */
    'requestedItemIds'?: Array<string> | null;
    /**
     * 
     * @type {PoolDto}
     * @memberof PoolAvailabilityDto
     */
    'pool'?: PoolDto;
}
/**
 * 
 * @export
 * @interface PoolChangedDto
 */
export interface PoolChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PoolChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof PoolChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof PoolChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof PoolChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {PoolDto}
     * @memberof PoolChangedDto
     */
    'entity'?: PoolDto;
    /**
     * 
     * @type {string}
     * @memberof PoolChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface PoolCreateDto
 */
export interface PoolCreateDto {
    /**
     * 
     * @type {string}
     * @memberof PoolCreateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolCreateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {PoolStructureType}
     * @memberof PoolCreateDto
     */
    'structureType'?: PoolStructureType;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolCreateDto
     */
    'itemType'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolCreateDto
     */
    'itemEntityType'?: PoolItemEntityType;
    /**
     * 
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolCreateDto
     */
    'itemEntityTypes'?: Array<PoolItemEntityType> | null;
    /**
     * 
     * @type {GeneralEntitySubTypeInputDto}
     * @memberof PoolCreateDto
     */
    'itemEntitySubType'?: GeneralEntitySubTypeInputDto;
    /**
     * 
     * @type {PoolSettingsInputDto}
     * @memberof PoolCreateDto
     */
    'settings'?: PoolSettingsInputDto;
    /**
     * 
     * @type {string}
     * @memberof PoolCreateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolCreateDto
     */
    'locationId'?: string | null;
}


/**
 * 
 * @export
 * @interface PoolDto
 */
export interface PoolDto {
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {PoolStructureType}
     * @memberof PoolDto
     */
    'structureType'?: PoolStructureType;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolDto
     */
    'itemType'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolDto
     */
    'itemEntityType'?: PoolItemEntityType;
    /**
     * 
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolDto
     */
    'itemEntityTypes'?: Array<PoolItemEntityType> | null;
    /**
     * 
     * @type {GeneralEntitySubTypeDto}
     * @memberof PoolDto
     */
    'itemEntitySubType'?: GeneralEntitySubTypeDto;
    /**
     * 
     * @type {PoolSettingsDto}
     * @memberof PoolDto
     */
    'settings'?: PoolSettingsDto;
    /**
     * 
     * @type {PoolStateDto}
     * @memberof PoolDto
     */
    'state'?: PoolStateDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'updatedBy'?: string | null;
}


/**
 * Entity pool.  Pool serves as a medium for accounting for elements that belong to it.
 * @export
 * @interface PoolEntity
 */
export interface PoolEntity {
    /**
     * 
     * @type {string}
     * @memberof PoolEntity
     */
    'id'?: string;
    /**
     * <inheritdoc cref=\"P:Domain.Interfaces.IAccountable.CreatedAt\" />
     * @type {string}
     * @memberof PoolEntity
     */
    'createdAt'?: string;
    /**
     * <inheritdoc cref=\"P:Domain.Interfaces.IAccountable.UpdatedAt\" />
     * @type {string}
     * @memberof PoolEntity
     */
    'updatedAt'?: string;
    /**
     * <inheritdoc cref=\"P:Domain.Interfaces.IAccountable.CreatedBy\" />
     * @type {string}
     * @memberof PoolEntity
     */
    'createdBy'?: string | null;
    /**
     * <inheritdoc cref=\"P:Domain.Interfaces.IAccountable.UpdatedBy\" />
     * @type {string}
     * @memberof PoolEntity
     */
    'updatedBy'?: string | null;
    /**
     * Is updated manually by user.
     * @type {boolean}
     * @memberof PoolEntity
     */
    'isUpdatedManually'?: boolean;
    /**
     * Is updated by the system or manually by unknown user.
     * @type {boolean}
     * @memberof PoolEntity
     */
    'isUpdatedBySystemOrUnknown'?: boolean;
    /**
     * <inheritdoc />
     * @type {boolean}
     * @memberof PoolEntity
     */
    'isDeleted'?: boolean;
    /**
     * <inheritdoc />
     * @type {string}
     * @memberof PoolEntity
     */
    'deletedAt'?: string | null;
    /**
     * <inheritdoc />
     * @type {string}
     * @memberof PoolEntity
     */
    'restoredAt'?: string | null;
    /**
     * <inheritdoc />
     * @type {string}
     * @memberof PoolEntity
     */
    'deletedBy'?: string | null;
    /**
     * <inheritdoc />
     * @type {string}
     * @memberof PoolEntity
     */
    'restoredBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolEntity
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolEntity
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolEntity
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PoolEntity
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolEntity
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolEntity
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolEntity
     */
    'description'?: string | null;
    /**
     * 
     * @type {PoolStructureType}
     * @memberof PoolEntity
     */
    'structureType'?: PoolStructureType;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolEntity
     */
    'itemType'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolEntity
     */
    'itemEntityType'?: PoolItemEntityType;
    /**
     * Must be set if Domain.Enums.Pool.PoolStructureType.Diverse.
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolEntity
     */
    'itemEntityTypes'?: Array<PoolItemEntityType> | null;
    /**
     * 
     * @type {GeneralEntitySubType}
     * @memberof PoolEntity
     */
    'itemEntitySubType'?: GeneralEntitySubType;
    /**
     * 
     * @type {PoolSettings}
     * @memberof PoolEntity
     */
    'settings'?: PoolSettings;
    /**
     * 
     * @type {PoolSettings}
     * @memberof PoolEntity
     */
    'prevSettings'?: PoolSettings;
    /**
     * 
     * @type {PoolState}
     * @memberof PoolEntity
     */
    'state'?: PoolState;
    /**
     * General spec of assets in the pool.  Specific assets might have different specs.
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolEntity
     */
    'allItemEntityTypes'?: Array<PoolItemEntityType>;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof PoolEntity
     */
    'itemEntityTypesMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof PoolEntity
     */
    'departmentIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof PoolEntity
     */
    'locationIdsMap'?: { [key: string]: boolean; };
}


/**
 * 
 * @export
 * @interface PoolGetPaginatedDto
 */
export interface PoolGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof PoolGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof PoolGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof PoolGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof PoolGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof PoolGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {PoolStructureType}
     * @memberof PoolGetPaginatedDto
     */
    'structureType'?: PoolStructureType;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolGetPaginatedDto
     */
    'itemType'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolGetPaginatedDto
     */
    'itemEntityType'?: PoolItemEntityType;
    /**
     * 
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolGetPaginatedDto
     */
    'itemEntityTypes'?: Array<PoolItemEntityType> | null;
    /**
     * 
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolGetPaginatedDto
     */
    'anyItemEntityTypes'?: Array<PoolItemEntityType> | null;
    /**
     * 
     * @type {EntityType}
     * @memberof PoolGetPaginatedDto
     */
    'itemEntitySubType'?: EntityType;
    /**
     * 
     * @type {Array<EntityType>}
     * @memberof PoolGetPaginatedDto
     */
    'anyItemEntitySubTypes'?: Array<EntityType> | null;
}


/**
 * 
 * @export
 * @interface PoolItemCandidateEntityDto
 */
export interface PoolItemCandidateEntityDto {
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolItemCandidateEntityDto
     */
    'entityType'?: PoolItemEntityType;
    /**
     * 
     * @type {VehicleDto}
     * @memberof PoolItemCandidateEntityDto
     */
    'vehicle'?: VehicleDto;
    /**
     * 
     * @type {AccessoryDto}
     * @memberof PoolItemCandidateEntityDto
     */
    'accessory'?: AccessoryDto;
    /**
     * 
     * @type {AssetDto}
     * @memberof PoolItemCandidateEntityDto
     */
    'asset'?: AssetDto;
    /**
     * 
     * @type {string}
     * @memberof PoolItemCandidateEntityDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemCandidateEntityDto
     */
    'id'?: string;
}


/**
 * 
 * @export
 * @interface PoolItemCandidateEntityInputDto
 */
export interface PoolItemCandidateEntityInputDto {
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolItemCandidateEntityInputDto
     */
    'entityType'?: PoolItemEntityType;
    /**
     * 
     * @type {string}
     * @memberof PoolItemCandidateEntityInputDto
     */
    'entityId'?: string;
}


/**
 * 
 * @export
 * @interface PoolItemChangedDto
 */
export interface PoolItemChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PoolItemChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof PoolItemChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof PoolItemChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof PoolItemChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {PoolItemDto}
     * @memberof PoolItemChangedDto
     */
    'entity'?: PoolItemDto;
    /**
     * 
     * @type {string}
     * @memberof PoolItemChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface PoolItemCreateDto
 */
export interface PoolItemCreateDto {
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolItemCreateDto
     */
    'type'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolItemCreateDto
     */
    'entityType'?: PoolItemEntityType;
    /**
     * 
     * @type {GeneralEntitySubTypeInputDto}
     * @memberof PoolItemCreateDto
     */
    'entitySubType'?: GeneralEntitySubTypeInputDto;
    /**
     * 
     * @type {PoolItemStatus}
     * @memberof PoolItemCreateDto
     */
    'status'?: PoolItemStatus;
    /**
     * 
     * @type {string}
     * @memberof PoolItemCreateDto
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {PoolItemCandidateEntityInputDto}
     * @memberof PoolItemCreateDto
     */
    'entity'?: PoolItemCandidateEntityInputDto;
    /**
     * 
     * @type {PoolItemSettingsInputDto}
     * @memberof PoolItemCreateDto
     */
    'settings'?: PoolItemSettingsInputDto;
}


/**
 * 
 * @export
 * @interface PoolItemCreateManyDto
 */
export interface PoolItemCreateManyDto {
    /**
     * 
     * @type {Array<PoolItemCreateDto>}
     * @memberof PoolItemCreateManyDto
     */
    'items'?: Array<PoolItemCreateDto>;
}
/**
 * 
 * @export
 * @interface PoolItemDto
 */
export interface PoolItemDto {
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'poolId'?: string;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolItemDto
     */
    'type'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolItemDto
     */
    'entityType'?: PoolItemEntityType;
    /**
     * 
     * @type {GeneralEntitySubTypeDto}
     * @memberof PoolItemDto
     */
    'entitySubType'?: GeneralEntitySubTypeDto;
    /**
     * 
     * @type {PoolItemStatus}
     * @memberof PoolItemDto
     */
    'status'?: PoolItemStatus;
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof PoolItemDto
     */
    'entity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof PoolItemDto
     */
    'usedByEntity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {PoolItemSettingsDto}
     * @memberof PoolItemDto
     */
    'settings'?: PoolItemSettingsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolItemDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolItemDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'itemId'?: string;
}


/**
 * Describes entity types that are suitable for pools.  Must contain only values from Domain.Enums.EntityType.
 * @export
 * @enum {string}
 */

export const PoolItemEntityType = {
    None: 'None',
    Asset: 'Asset',
    Vehicle: 'Vehicle',
    Accessory: 'Accessory'
} as const;

export type PoolItemEntityType = typeof PoolItemEntityType[keyof typeof PoolItemEntityType];


/**
 * 
 * @export
 * @interface PoolItemFullReferenceDto
 */
export interface PoolItemFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'poolId'?: string;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolItemFullReferenceDto
     */
    'type'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolItemFullReferenceDto
     */
    'entityType'?: PoolItemEntityType;
    /**
     * 
     * @type {GeneralEntitySubTypeDto}
     * @memberof PoolItemFullReferenceDto
     */
    'entitySubType'?: GeneralEntitySubTypeDto;
    /**
     * 
     * @type {PoolItemStatus}
     * @memberof PoolItemFullReferenceDto
     */
    'status'?: PoolItemStatus;
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof PoolItemFullReferenceDto
     */
    'entity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof PoolItemFullReferenceDto
     */
    'usedByEntity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {PoolItemSettingsDto}
     * @memberof PoolItemFullReferenceDto
     */
    'settings'?: PoolItemSettingsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'itemId'?: string;
}


/**
 * 
 * @export
 * @interface PoolItemGetPaginatedDto
 */
export interface PoolItemGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof PoolItemGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolItemGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof PoolItemGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof PoolItemGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof PoolItemGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof PoolItemGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolItemGetPaginatedDto
     */
    'type'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolItemGetPaginatedDto
     */
    'entityType'?: PoolItemEntityType;
    /**
     * 
     * @type {EntityType}
     * @memberof PoolItemGetPaginatedDto
     */
    'entitySubType'?: EntityType;
    /**
     * 
     * @type {PoolItemStatus}
     * @memberof PoolItemGetPaginatedDto
     */
    'status'?: PoolItemStatus;
}


/**
 * 
 * @export
 * @interface PoolItemMeta
 */
export interface PoolItemMeta {
    /**
     * 
     * @type {string}
     * @memberof PoolItemMeta
     */
    'poolId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemMeta
     */
    'poolItemId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PoolItemMeta
     */
    'isEnsureEntityBelongToSinglePool'?: boolean;
}
/**
 * 
 * @export
 * @interface PoolItemReferenceDto
 */
export interface PoolItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof PoolItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemReferenceDto
     */
    'poolId'?: string;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolItemReferenceDto
     */
    'type'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolItemReferenceDto
     */
    'entityType'?: PoolItemEntityType;
}


/**
 * 
 * @export
 * @interface PoolItemSearchPaginatedDto
 */
export interface PoolItemSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof PoolItemSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolItemSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof PoolItemSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolItemSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof PoolItemSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof PoolItemSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof PoolItemSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolItemSearchPaginatedDto
     */
    'type'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolItemSearchPaginatedDto
     */
    'entityType'?: PoolItemEntityType;
    /**
     * 
     * @type {EntityType}
     * @memberof PoolItemSearchPaginatedDto
     */
    'entitySubType'?: EntityType;
    /**
     * 
     * @type {PoolItemStatus}
     * @memberof PoolItemSearchPaginatedDto
     */
    'status'?: PoolItemStatus;
}


/**
 * 
 * @export
 * @interface PoolItemSettingsDto
 */
export interface PoolItemSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof PoolItemSettingsDto
     */
    'isEnsureEntityBelongToSinglePool'?: boolean;
}
/**
 * 
 * @export
 * @interface PoolItemSettingsInputDto
 */
export interface PoolItemSettingsInputDto {
    /**
     * 
     * @type {boolean}
     * @memberof PoolItemSettingsInputDto
     */
    'isEnsureEntityBelongToSinglePool'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PoolItemStatus = {
    None: 'None',
    Pending: 'Pending',
    Available: 'Available',
    Locked: 'Locked',
    Reserved: 'Reserved',
    Unavailable: 'Unavailable'
} as const;

export type PoolItemStatus = typeof PoolItemStatus[keyof typeof PoolItemStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const PoolItemType = {
    None: 'None',
    Specific: 'Specific',
    Abstract: 'Abstract'
} as const;

export type PoolItemType = typeof PoolItemType[keyof typeof PoolItemType];


/**
 * 
 * @export
 * @interface PoolItemUpdateDto
 */
export interface PoolItemUpdateDto {
    /**
     * 
     * @type {PoolItemStatus}
     * @memberof PoolItemUpdateDto
     */
    'status'?: PoolItemStatus;
    /**
     * 
     * @type {string}
     * @memberof PoolItemUpdateDto
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {PoolItemSettingsInputDto}
     * @memberof PoolItemUpdateDto
     */
    'settings'?: PoolItemSettingsInputDto;
}


/**
 * 
 * @export
 * @interface PoolItemUpdateStatusDto
 */
export interface PoolItemUpdateStatusDto {
    /**
     * 
     * @type {PoolItemStatus}
     * @memberof PoolItemUpdateStatusDto
     */
    'status'?: PoolItemStatus;
    /**
     * 
     * @type {string}
     * @memberof PoolItemUpdateStatusDto
     */
    'statusReason'?: string | null;
}


/**
 * 
 * @export
 * @interface PoolReferenceDto
 */
export interface PoolReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof PoolReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {PoolStructureType}
     * @memberof PoolReferenceDto
     */
    'structureType'?: PoolStructureType;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolReferenceDto
     */
    'itemType'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolReferenceDto
     */
    'itemEntityType'?: PoolItemEntityType;
}


/**
 * 
 * @export
 * @interface PoolSearchPaginatedDto
 */
export interface PoolSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof PoolSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof PoolSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof PoolSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof PoolSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof PoolSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {PoolStructureType}
     * @memberof PoolSearchPaginatedDto
     */
    'structureType'?: PoolStructureType;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolSearchPaginatedDto
     */
    'itemType'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolSearchPaginatedDto
     */
    'itemEntityType'?: PoolItemEntityType;
    /**
     * 
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolSearchPaginatedDto
     */
    'itemEntityTypes'?: Array<PoolItemEntityType> | null;
    /**
     * 
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolSearchPaginatedDto
     */
    'anyItemEntityTypes'?: Array<PoolItemEntityType> | null;
    /**
     * 
     * @type {EntityType}
     * @memberof PoolSearchPaginatedDto
     */
    'itemEntitySubType'?: EntityType;
    /**
     * 
     * @type {Array<EntityType>}
     * @memberof PoolSearchPaginatedDto
     */
    'anyItemEntitySubTypes'?: Array<EntityType> | null;
}


/**
 * 
 * @export
 * @interface PoolSettings
 */
export interface PoolSettings {
    /**
     * 
     * @type {number}
     * @memberof PoolSettings
     */
    'minItems'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolSettings
     */
    'maxItems'?: number;
    /**
     * If true, ensures that single identifiable entity belongs max to 1 pool.
     * @type {boolean}
     * @memberof PoolSettings
     */
    'isEnsureEntityBelongToSinglePool'?: boolean;
    /**
     * Entity types that can be added to the pool.
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolSettings
     */
    'allowedEntityTypes'?: Array<PoolItemEntityType> | null;
}
/**
 * 
 * @export
 * @interface PoolSettingsDto
 */
export interface PoolSettingsDto {
    /**
     * 
     * @type {number}
     * @memberof PoolSettingsDto
     */
    'minItems'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolSettingsDto
     */
    'maxItems'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PoolSettingsDto
     */
    'isEnsureEntityBelongToSinglePool'?: boolean;
    /**
     * 
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolSettingsDto
     */
    'allowedEntityTypes'?: Array<PoolItemEntityType> | null;
}
/**
 * 
 * @export
 * @interface PoolSettingsInputDto
 */
export interface PoolSettingsInputDto {
    /**
     * 
     * @type {number}
     * @memberof PoolSettingsInputDto
     */
    'minItems'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PoolSettingsInputDto
     */
    'maxItems'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PoolSettingsInputDto
     */
    'isEnsureEntityBelongToSinglePool'?: boolean;
}
/**
 * Computed Pool state.
 * @export
 * @interface PoolState
 */
export interface PoolState {
    /**
     * 
     * @type {number}
     * @memberof PoolState
     */
    'totalCount'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PoolState
     */
    'countByStatusMap'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PoolState
     */
    'countByEntityTypeMap'?: { [key: string]: number; };
    /**
     * Actual distinct entity types in the pool.
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolState
     */
    'entityTypes'?: Array<PoolItemEntityType>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolState
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PoolState
     */
    'hasAny'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PoolState
     */
    'hasUniformItems'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PoolState
     */
    'hasDiverseItems'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PoolState
     */
    'pendingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolState
     */
    'availableCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolState
     */
    'lockedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolState
     */
    'unavailableCount'?: number;
}
/**
 * 
 * @export
 * @interface PoolStateDto
 */
export interface PoolStateDto {
    /**
     * 
     * @type {number}
     * @memberof PoolStateDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PoolStateDto
     */
    'countByStatusMap'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PoolStateDto
     */
    'countByEntityTypeMap'?: { [key: string]: number; };
    /**
     * 
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolStateDto
     */
    'entityTypes'?: Array<PoolItemEntityType>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolStateDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PoolStateDto
     */
    'hasAny'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PoolStateDto
     */
    'hasUniformItems'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PoolStateDto
     */
    'hasDiverseItems'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDto
     */
    'pendingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDto
     */
    'availableCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDto
     */
    'lockedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDto
     */
    'unavailableCount'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PoolStructureType = {
    None: 'None',
    Uniform: 'Uniform',
    Diverse: 'Diverse'
} as const;

export type PoolStructureType = typeof PoolStructureType[keyof typeof PoolStructureType];


/**
 * 
 * @export
 * @interface PoolUpdateDto
 */
export interface PoolUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof PoolUpdateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralEntitySubTypeInputDto}
     * @memberof PoolUpdateDto
     */
    'itemEntitySubType'?: GeneralEntitySubTypeInputDto;
    /**
     * 
     * @type {PoolSettingsInputDto}
     * @memberof PoolUpdateDto
     */
    'settings'?: PoolSettingsInputDto;
    /**
     * 
     * @type {string}
     * @memberof PoolUpdateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolUpdateDto
     */
    'locationId'?: string | null;
}
/**
 * 
 * @export
 * @interface PoolsIntersectionDto
 */
export interface PoolsIntersectionDto {
    /**
     * 
     * @type {Array<PoolDto>}
     * @memberof PoolsIntersectionDto
     */
    'pools'?: Array<PoolDto>;
    /**
     * 
     * @type {Array<PoolsIntersectionEntryDto>}
     * @memberof PoolsIntersectionDto
     */
    'entries'?: Array<PoolsIntersectionEntryDto>;
}
/**
 * 
 * @export
 * @interface PoolsIntersectionEntryDto
 */
export interface PoolsIntersectionEntryDto {
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof PoolsIntersectionEntryDto
     */
    'entity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {Array<PoolItemDto>}
     * @memberof PoolsIntersectionEntryDto
     */
    'poolItems'?: Array<PoolItemDto>;
}
/**
 * 
 * @export
 * @interface PoolsIntersectionResultDto
 */
export interface PoolsIntersectionResultDto {
    /**
     * 
     * @type {Array<PoolsIntersectionDto>}
     * @memberof PoolsIntersectionResultDto
     */
    'intersections'?: Array<PoolsIntersectionDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PoolsIntersectionResultDto
     */
    'isAnyIntersection'?: boolean;
}
/**
 * 
 * @export
 * @interface PoolsMetaDto
 */
export interface PoolsMetaDto {
    /**
     * 
     * @type {Array<PoolItemMeta>}
     * @memberof PoolsMetaDto
     */
    'pools'?: Array<PoolItemMeta> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PoolsMetaDto
     */
    'hasPool'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolsMetaDto
     */
    'poolIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolsMetaDto
     */
    'poolItemIds'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof PoolsMetaDto
     */
    'poolIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof PoolsMetaDto
     */
    'poolItemIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {boolean}
     * @memberof PoolsMetaDto
     */
    'isEnsureEntityBelongToSinglePoolSetForAny'?: boolean;
}
/**
 * 
 * @export
 * @interface PoolsMetaQueryDto
 */
export interface PoolsMetaQueryDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolsMetaQueryDto
     */
    'poolIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolsMetaQueryDto
     */
    'notForPoolIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PoolsMetaQueryDto
     */
    'onlySuitableCandidatesForPoolId'?: string | null;
}
/**
 * Describes how price summary is entered/calculated.
 * @export
 * @enum {string}
 */

export const PriceSummaryCalcType = {
    None: 'None',
    BasedOnSubTotal: 'BasedOnSubTotal',
    BasedOnTotal: 'BasedOnTotal'
} as const;

export type PriceSummaryCalcType = typeof PriceSummaryCalcType[keyof typeof PriceSummaryCalcType];


/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductLocationChangedDto
 */
export interface ProductLocationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductLocationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ProductLocationChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ProductLocationChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {ProductLocationDto}
     * @memberof ProductLocationChangedDto
     */
    'entity'?: ProductLocationDto;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface ProductLocationDto
 */
export interface ProductLocationDto {
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductLocationDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductLocationDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof ProductLocationDto
     */
    'address'?: GeneralAddressDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ProductLocationDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ProductLocationDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductLocationGetPaginatedDto
 */
export interface ProductLocationGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof ProductLocationGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductLocationGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductLocationGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductLocationGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductLocationGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof ProductLocationGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof ProductLocationGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof ProductLocationGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationGetPaginatedDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationGetPaginatedDto
     */
    'assetSubscriptionPlanId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationGetPaginatedDto
     */
    'notForDepartmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationGetPaginatedDto
     */
    'notForLocationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationGetPaginatedDto
     */
    'notForAssetSubscriptionPlanId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLocationGetPaginatedDto
     */
    'isWithDepartment'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLocationGetPaginatedDto
     */
    'isWithLocation'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLocationGetPaginatedDto
     */
    'isWithAnyAssetSubscriptionPlan'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ProductLocationReferenceDto
 */
export interface ProductLocationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof ProductLocationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface ProductLocationSearchPaginatedDto
 */
export interface ProductLocationSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'assetSubscriptionPlanId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'notForDepartmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'notForLocationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'notForAssetSubscriptionPlanId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'isWithDepartment'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'isWithLocation'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLocationSearchPaginatedDto
     */
    'isWithAnyAssetSubscriptionPlan'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ProfileDepartmentDto
 */
export interface ProfileDepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof ProfileDepartmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileDepartmentDto
     */
    'locationIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ProfileDto
 */
export interface ProfileDto {
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {UserStatus}
     * @memberof ProfileDto
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'email'?: string;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof ProfileDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {UserSettingsDto}
     * @memberof ProfileDto
     */
    'settings'?: UserSettingsDto;
    /**
     * 
     * @type {GeneralAvatarDto}
     * @memberof ProfileDto
     */
    'avatar'?: GeneralAvatarDto;
    /**
     * 
     * @type {UserSuspensionInfoDto}
     * @memberof ProfileDto
     */
    'suspension'?: UserSuspensionInfoDto;
    /**
     * 
     * @type {Array<UserDepartmentDto>}
     * @memberof ProfileDto
     */
    'departments'?: Array<UserDepartmentDto>;
    /**
     * 
     * @type {Array<ProfileUserLocationDto>}
     * @memberof ProfileDto
     */
    'locations'?: Array<ProfileUserLocationDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDto
     */
    'isPersonalInfoComplete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDto
     */
    'isCustomerUser'?: boolean;
}


/**
 * 
 * @export
 * @interface ProfilePersonalInfoUpdateDto
 */
export interface ProfilePersonalInfoUpdateDto {
    /**
     * 
     * @type {GeneralPersonNameInputDto}
     * @memberof ProfilePersonalInfoUpdateDto
     */
    'personName'?: GeneralPersonNameInputDto;
    /**
     * 
     * @type {string}
     * @memberof ProfilePersonalInfoUpdateDto
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface ProfileRolesDto
 */
export interface ProfileRolesDto {
    /**
     * 
     * @type {string}
     * @memberof ProfileRolesDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof ProfileRolesDto
     */
    'globalRoles'?: Array<RoleDto>;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof ProfileRolesDto
     */
    'tenantRoles'?: Array<RoleDto>;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof ProfileRolesDto
     */
    'roles'?: Array<RoleDto>;
}
/**
 * 
 * @export
 * @interface ProfileUserLocationDto
 */
export interface ProfileUserLocationDto {
    /**
     * 
     * @type {string}
     * @memberof ProfileUserLocationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUserLocationDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProposalStatus = {
    None: 'None',
    Open: 'Open',
    Closed: 'Closed',
    AutoClosed: 'AutoClosed'
} as const;

export type ProposalStatus = typeof ProposalStatus[keyof typeof ProposalStatus];


/**
 * 
 * @export
 * @interface PushChannelInfoDto
 */
export interface PushChannelInfoDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PushChannelInfoDto
     */
    'deviceTokens'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PushChannelInfoDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PushChannelInfoDto
     */
    'body'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PushChannelInfoDto
     */
    'imageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PushChannelInfoDto
     */
    'channel'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PushChannelInfoDto
     */
    'data'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface PushWebhookDto
 */
export interface PushWebhookDto {
    /**
     * 
     * @type {string}
     * @memberof PushWebhookDto
     */
    'messageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PushWebhookDto
     */
    'messageStatus'?: string;
}
/**
 * 
 * @export
 * @interface RegisterCompleteDto
 */
export interface RegisterCompleteDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterCompleteDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCompleteDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCompleteDto
     */
    'confirmPassword'?: string;
}
/**
 * 
 * @export
 * @interface RegisterDeviceTokenDto
 */
export interface RegisterDeviceTokenDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterDeviceTokenDto
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface RegisterDto
 */
export interface RegisterDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'companyIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'companyDescription'?: string;
    /**
     * 
     * @type {GeneralPersonNameInputDto}
     * @memberof RegisterDto
     */
    'personName'?: GeneralPersonNameInputDto;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface RegisterInvitedUserDto
 */
export interface RegisterInvitedUserDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterInvitedUserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterInvitedUserDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterInvitedUserDto
     */
    'confirmPassword'?: string;
}
/**
 * 
 * @export
 * @interface RegisterResultDto
 */
export interface RegisterResultDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterResultDto
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterResultDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterResultDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface RemoveDocumentAttachmentsDto
 */
export interface RemoveDocumentAttachmentsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoveDocumentAttachmentsDto
     */
    'attachmentIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RemoveDocumentItemAttachmentsDto
 */
export interface RemoveDocumentItemAttachmentsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoveDocumentItemAttachmentsDto
     */
    'attachmentIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RepairCatalogChangedDto
 */
export interface RepairCatalogChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairCatalogChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RepairCatalogChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RepairCatalogChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {RepairCatalogDto}
     * @memberof RepairCatalogChangedDto
     */
    'entity'?: RepairCatalogDto;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface RepairCatalogDto
 */
export interface RepairCatalogDto {
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairCatalogDto
     */
    'isDefault'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairCatalogDto
     */
    'isGlobal'?: boolean;
}
/**
 * 
 * @export
 * @interface RepairCatalogGetPaginatedDto
 */
export interface RepairCatalogGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof RepairCatalogGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairCatalogGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairCatalogGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairCatalogGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairCatalogGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof RepairCatalogGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof RepairCatalogGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof RepairCatalogGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairCatalogGetPaginatedDto
     */
    'isGlobal'?: boolean;
}
/**
 * 
 * @export
 * @interface RepairCatalogReferenceDto
 */
export interface RepairCatalogReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface RepairCatalogSearchPaginatedDto
 */
export interface RepairCatalogSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof RepairCatalogSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairCatalogSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairCatalogSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairCatalogSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairCatalogSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof RepairCatalogSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof RepairCatalogSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof RepairCatalogSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairCatalogSearchPaginatedDto
     */
    'isGlobal'?: boolean;
}
/**
 * 
 * @export
 * @interface RepairMaterialChangedDto
 */
export interface RepairMaterialChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairMaterialChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RepairMaterialChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RepairMaterialChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {RepairMaterialDto}
     * @memberof RepairMaterialChangedDto
     */
    'entity'?: RepairMaterialDto;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface RepairMaterialDto
 */
export interface RepairMaterialDto {
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'repairCatalogId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'sourceMaterialId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairMaterialDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'brandName'?: string | null;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairMaterialDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepairMaterialDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairMaterialDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairMaterialDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairMaterialDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface RepairMaterialFullReferenceDto
 */
export interface RepairMaterialFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'repairCatalogId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairMaterialFullReferenceDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'brandName'?: string | null;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairMaterialFullReferenceDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialFullReferenceDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepairMaterialFullReferenceDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairMaterialFullReferenceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairMaterialFullReferenceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairMaterialFullReferenceDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialFullReferenceDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialFullReferenceDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialFullReferenceDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface RepairMaterialGetPaginatedDto
 */
export interface RepairMaterialGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairMaterialGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairMaterialGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairMaterialGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof RepairMaterialGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof RepairMaterialGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof RepairMaterialGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairMaterialGetPaginatedDto
     */
    'isGlobal'?: boolean;
}
/**
 * 
 * @export
 * @interface RepairMaterialReferenceDto
 */
export interface RepairMaterialReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface RepairMaterialSearchPaginatedDto
 */
export interface RepairMaterialSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairMaterialSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairMaterialSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairMaterialSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof RepairMaterialSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof RepairMaterialSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof RepairMaterialSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairMaterialSearchPaginatedDto
     */
    'isGlobal'?: boolean;
}
/**
 * 
 * @export
 * @interface RepairOperationAddVehicleDamagesDto
 */
export interface RepairOperationAddVehicleDamagesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationAddVehicleDamagesDto
     */
    'vehicleDamageIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RepairOperationChangedDto
 */
export interface RepairOperationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RepairOperationChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RepairOperationChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {RepairOperationDto}
     * @memberof RepairOperationChangedDto
     */
    'entity'?: RepairOperationDto;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface RepairOperationContentDto
 */
export interface RepairOperationContentDto {
    /**
     * 
     * @type {string}
     * @memberof RepairOperationContentDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationContentDto
     */
    'vehicleDamageIds'?: Array<string>;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairOperationContentDto
     */
    'currency'?: GeneralCurrencyDto;
}
/**
 * 
 * @export
 * @interface RepairOperationContentInputDto
 */
export interface RepairOperationContentInputDto {
    /**
     * 
     * @type {string}
     * @memberof RepairOperationContentInputDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationContentInputDto
     */
    'vehicleDamageIds'?: Array<string>;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof RepairOperationContentInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
}
/**
 * 
 * @export
 * @interface RepairOperationCountersDto
 */
export interface RepairOperationCountersDto {
    /**
     * 
     * @type {number}
     * @memberof RepairOperationCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationCountersDto
     */
    'draftCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationCountersDto
     */
    'inProgressCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationCountersDto
     */
    'completedCount'?: number;
}
/**
 * 
 * @export
 * @interface RepairOperationDto
 */
export interface RepairOperationDto {
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof RepairOperationDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof RepairOperationDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfRepairOperationStage}
     * @memberof RepairOperationDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfRepairOperationStage;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'description'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairOperationDto
     */
    'date'?: string;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof RepairOperationDto
     */
    'responsibleUser'?: GeneralByWhoDto;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairOperationDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {Array<RepairOperationItemDto>}
     * @memberof RepairOperationDto
     */
    'items'?: Array<RepairOperationItemDto>;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof RepairOperationDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof RepairOperationDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof RepairOperationDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof RepairOperationDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairOperationDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairOperationDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairOperationDto
     */
    'canBeUpdated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairOperationDto
     */
    'canBeDeleted'?: boolean;
    /**
     * 
     * @type {GeneralDiscount}
     * @memberof RepairOperationDto
     */
    'discount'?: GeneralDiscount;
    /**
     * 
     * @type {GeneralTax}
     * @memberof RepairOperationDto
     */
    'tax'?: GeneralTax;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface RepairOperationGetPaginatedDto
 */
export interface RepairOperationGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof RepairOperationGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof RepairOperationGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof RepairOperationGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof RepairOperationGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationGetPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationGetPaginatedDto
     */
    'vehicleDamageId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationGetPaginatedDto
     */
    'vehicleDamageIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationGetPaginatedDto
     */
    'anyVehicleDamageIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationGetPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationGetPaginatedDto
     */
    'damageId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationGetPaginatedDto
     */
    'damageIds'?: Array<string> | null;
    /**
     * 
     * @type {RepairOperationStage}
     * @memberof RepairOperationGetPaginatedDto
     */
    'stage'?: RepairOperationStage;
    /**
     * 
     * @type {RepairOperationStage}
     * @memberof RepairOperationGetPaginatedDto
     */
    'excludeStage'?: RepairOperationStage;
    /**
     * 
     * @type {Array<RepairOperationStage>}
     * @memberof RepairOperationGetPaginatedDto
     */
    'excludeStages'?: Array<RepairOperationStage> | null;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof RepairOperationGetPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof RepairOperationGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @interface RepairOperationItemDto
 */
export interface RepairOperationItemDto {
    /**
     * 
     * @type {string}
     * @memberof RepairOperationItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationItemDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleDamageFullReferenceDto}
     * @memberof RepairOperationItemDto
     */
    'damage'?: VehicleDamageFullReferenceDto;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationItemDto
     */
    'cost'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationItemDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof RepairOperationItemDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairOperationItemDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairOperationItemDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationItemDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationItemDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationItemDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface RepairOperationItemInputDto
 */
export interface RepairOperationItemInputDto {
    /**
     * 
     * @type {string}
     * @memberof RepairOperationItemInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationItemInputDto
     */
    'damageId'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationItemInputDto
     */
    'cost'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationItemInputDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof RepairOperationItemInputDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof RepairOperationItemInputDto
     */
    'discount'?: GeneralDiscountInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof RepairOperationItemInputDto
     */
    'tax'?: GeneralTaxInputDto;
}
/**
 * 
 * @export
 * @interface RepairOperationReferenceDto
 */
export interface RepairOperationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairOperationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationReferenceDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface RepairOperationResultDto
 */
export interface RepairOperationResultDto {
    /**
     * 
     * @type {string}
     * @memberof RepairOperationResultDto
     */
    'repairOperationId'?: string;
}
/**
 * 
 * @export
 * @interface RepairOperationResultInputDto
 */
export interface RepairOperationResultInputDto {
    /**
     * 
     * @type {string}
     * @memberof RepairOperationResultInputDto
     */
    'repairOperationId'?: string;
}
/**
 * 
 * @export
 * @interface RepairOperationSearchPaginatedDto
 */
export interface RepairOperationSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'damageId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'damageIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'vehicleDamageId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'vehicleDamageIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'anyVehicleDamageIds'?: Array<string> | null;
    /**
     * 
     * @type {RepairOperationStage}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'stage'?: RepairOperationStage;
    /**
     * 
     * @type {RepairOperationStage}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'excludeStage'?: RepairOperationStage;
    /**
     * 
     * @type {Array<RepairOperationStage>}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'excludeStages'?: Array<RepairOperationStage> | null;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof RepairOperationSearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * NB: use Common.Attributes.SortOrderAttribute to specify the order in which stages can be activated.
 * @export
 * @enum {string}
 */

export const RepairOperationStage = {
    None: 'None',
    Draft: 'Draft',
    InProgress: 'InProgress',
    Completed: 'Completed'
} as const;

export type RepairOperationStage = typeof RepairOperationStage[keyof typeof RepairOperationStage];


/**
 * 
 * @export
 * @interface RepairSparePartChangedDto
 */
export interface RepairSparePartChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSparePartChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RepairSparePartChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RepairSparePartChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {RepairSparePartDto}
     * @memberof RepairSparePartChangedDto
     */
    'entity'?: RepairSparePartDto;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface RepairSparePartDetalizationDto
 */
export interface RepairSparePartDetalizationDto {
    /**
     * 
     * @type {RepairSparePartDetalizationType}
     * @memberof RepairSparePartDetalizationDto
     */
    'type'?: RepairSparePartDetalizationType;
    /**
     * 
     * @type {VehicleSize}
     * @memberof RepairSparePartDetalizationDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof RepairSparePartDetalizationDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof RepairSparePartDetalizationDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof RepairSparePartDetalizationDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {VehicleGenerationReferenceDto}
     * @memberof RepairSparePartDetalizationDto
     */
    'generation'?: VehicleGenerationReferenceDto;
    /**
     * 
     * @type {VehicleModificationReferenceDto}
     * @memberof RepairSparePartDetalizationDto
     */
    'modification'?: VehicleModificationReferenceDto;
}


/**
 * 
 * @export
 * @interface RepairSparePartDetalizationInputDto
 */
export interface RepairSparePartDetalizationInputDto {
    /**
     * 
     * @type {RepairSparePartDetalizationType}
     * @memberof RepairSparePartDetalizationInputDto
     */
    'type'?: RepairSparePartDetalizationType;
    /**
     * 
     * @type {VehicleSize}
     * @memberof RepairSparePartDetalizationInputDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof RepairSparePartDetalizationInputDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDetalizationInputDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDetalizationInputDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDetalizationInputDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDetalizationInputDto
     */
    'modificationId'?: string | null;
}


/**
 * Detalizations go from more general to more specific.  Priority: lower = more general, higher = more specific. Specific has priority.
 * @export
 * @enum {string}
 */

export const RepairSparePartDetalizationType = {
    None: 'None',
    General: 'General',
    VehicleSize: 'VehicleSize',
    BodyType: 'BodyType',
    Make: 'Make',
    Model: 'Model',
    Generation: 'Generation',
    Modification: 'Modification'
} as const;

export type RepairSparePartDetalizationType = typeof RepairSparePartDetalizationType[keyof typeof RepairSparePartDetalizationType];


/**
 * 
 * @export
 * @interface RepairSparePartDto
 */
export interface RepairSparePartDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'partNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'brandName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairSparePartDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {VehicleType}
     * @memberof RepairSparePartDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof RepairSparePartDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {Array<RepairSparePartDetalizationDto>}
     * @memberof RepairSparePartDto
     */
    'detalizations'?: Array<RepairSparePartDetalizationDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof RepairSparePartDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairSparePartDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartDto
     */
    'price'?: number | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSparePartDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSparePartDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSparePartDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartDto
     */
    'subTotal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartDto
     */
    'subTotalIncDiscount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartDto
     */
    'total'?: number | null;
    /**
     * 
     * @type {EntityMatchMetaDto}
     * @memberof RepairSparePartDto
     */
    'matchMeta'?: EntityMatchMetaDto;
}


/**
 * 
 * @export
 * @interface RepairSparePartFullReferenceDto
 */
export interface RepairSparePartFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'partNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'brandName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairSparePartFullReferenceDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {VehicleType}
     * @memberof RepairSparePartFullReferenceDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof RepairSparePartFullReferenceDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {Array<RepairSparePartDetalizationDto>}
     * @memberof RepairSparePartFullReferenceDto
     */
    'detalizations'?: Array<RepairSparePartDetalizationDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof RepairSparePartFullReferenceDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairSparePartFullReferenceDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartFullReferenceDto
     */
    'price'?: number | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSparePartFullReferenceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSparePartFullReferenceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSparePartFullReferenceDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartFullReferenceDto
     */
    'subTotal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartFullReferenceDto
     */
    'subTotalIncDiscount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartFullReferenceDto
     */
    'total'?: number | null;
}


/**
 * 
 * @export
 * @interface RepairSparePartGetPaginatedDto
 */
export interface RepairSparePartGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'partTypeId'?: string | null;
    /**
     * 
     * @type {VehicleType}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleSize}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartGetPaginatedDto
     */
    'modificationId'?: string | null;
}


/**
 * 
 * @export
 * @interface RepairSparePartMatchQueryDto
 */
export interface RepairSparePartMatchQueryDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartMatchQueryDto
     */
    'partTypeId'?: string | null;
    /**
     * 
     * @type {VehicleSize}
     * @memberof RepairSparePartMatchQueryDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof RepairSparePartMatchQueryDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartMatchQueryDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartMatchQueryDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartMatchQueryDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartMatchQueryDto
     */
    'modificationId'?: string | null;
}


/**
 * 
 * @export
 * @interface RepairSparePartReferenceDto
 */
export interface RepairSparePartReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface RepairSparePartSearchPaginatedDto
 */
export interface RepairSparePartSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'partTypeId'?: string | null;
    /**
     * 
     * @type {VehicleType}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleSize}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'modificationId'?: string | null;
    /**
     * 
     * @type {RepairSparePartMatchQueryDto}
     * @memberof RepairSparePartSearchPaginatedDto
     */
    'match'?: RepairSparePartMatchQueryDto;
}


/**
 * 
 * @export
 * @interface RepairSpecChangedDto
 */
export interface RepairSpecChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSpecChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RepairSpecChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RepairSpecChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {RepairSpecDto}
     * @memberof RepairSpecChangedDto
     */
    'entity'?: RepairSpecDto;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * <inheritdoc cref=\"T:Domain.Entities.Repair.RepairSpec.RepairSpecData\" />
 * @export
 * @interface RepairSpecDataDto
 */
export interface RepairSpecDataDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairSpecDataDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof RepairSpecDataDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof RepairSpecDataDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof RepairSpecDataDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {RepairSpecDetalizationDto}
     * @memberof RepairSpecDataDto
     */
    'detalization'?: RepairSpecDetalizationDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDataDto
     */
    'spotFactor'?: number;
    /**
     * 
     * @type {Array<RepairSpecItemDataDto>}
     * @memberof RepairSpecDataDto
     */
    'items'?: Array<RepairSpecItemDataDto>;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecDataDto
     */
    'isDetailsHidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecDataDto
     */
    'isCreatedOnTheSpot'?: boolean;
    /**
     * 
     * @type {RepairType}
     * @memberof RepairSpecDataDto
     */
    'repairType'?: RepairType;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSpecDataDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSpecDataDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDataDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDataDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDataDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface RepairSpecDataInputDto
 */
export interface RepairSpecDataInputDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataInputDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataInputDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof RepairSpecDataInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof RepairSpecDataInputDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataInputDto
     */
    'partTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataInputDto
     */
    'damageTypeId'?: string;
    /**
     * 
     * @type {RepairSpecDetalizationInputDto}
     * @memberof RepairSpecDataInputDto
     */
    'detalization'?: RepairSpecDetalizationInputDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDataInputDto
     */
    'spotFactor'?: number;
    /**
     * 
     * @type {Array<RepairSpecItemDataInputDto>}
     * @memberof RepairSpecDataInputDto
     */
    'items'?: Array<RepairSpecItemDataInputDto>;
}


/**
 * 
 * @export
 * @interface RepairSpecDetalizationDto
 */
export interface RepairSpecDetalizationDto {
    /**
     * 
     * @type {RepairSpecDetalizationType}
     * @memberof RepairSpecDetalizationDto
     */
    'type'?: RepairSpecDetalizationType;
    /**
     * 
     * @type {VehicleSize}
     * @memberof RepairSpecDetalizationDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof RepairSpecDetalizationDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof RepairSpecDetalizationDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof RepairSpecDetalizationDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {VehicleGenerationReferenceDto}
     * @memberof RepairSpecDetalizationDto
     */
    'generation'?: VehicleGenerationReferenceDto;
    /**
     * 
     * @type {VehicleModificationReferenceDto}
     * @memberof RepairSpecDetalizationDto
     */
    'modification'?: VehicleModificationReferenceDto;
}


/**
 * 
 * @export
 * @interface RepairSpecDetalizationInputDto
 */
export interface RepairSpecDetalizationInputDto {
    /**
     * 
     * @type {RepairSpecDetalizationType}
     * @memberof RepairSpecDetalizationInputDto
     */
    'type'?: RepairSpecDetalizationType;
    /**
     * 
     * @type {VehicleSize}
     * @memberof RepairSpecDetalizationInputDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof RepairSpecDetalizationInputDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDetalizationInputDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDetalizationInputDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDetalizationInputDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDetalizationInputDto
     */
    'modificationId'?: string | null;
}


/**
 * Detalizations go from more general to more specific.  Priority: lower = more general, higher = more specific. Specific has priority.
 * @export
 * @enum {string}
 */

export const RepairSpecDetalizationType = {
    None: 'None',
    General: 'General',
    VehicleSize: 'VehicleSize',
    BodyType: 'BodyType',
    Make: 'Make',
    Model: 'Model',
    Generation: 'Generation',
    Modification: 'Modification'
} as const;

export type RepairSpecDetalizationType = typeof RepairSpecDetalizationType[keyof typeof RepairSpecDetalizationType];


/**
 * 
 * @export
 * @interface RepairSpecDto
 */
export interface RepairSpecDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'repairCatalogId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'sourceSpecId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairSpecDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof RepairSpecDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof RepairSpecDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof RepairSpecDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {RepairSpecDetalizationDto}
     * @memberof RepairSpecDto
     */
    'detalization'?: RepairSpecDetalizationDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDto
     */
    'spotFactor'?: number;
    /**
     * 
     * @type {Array<RepairSpecItemDto>}
     * @memberof RepairSpecDto
     */
    'items'?: Array<RepairSpecItemDto>;
    /**
     * <inheritdoc cref=\"P:Domain.Entities.Repair.RepairSpec.RepairSpecEntity.LastReferenceSyncAt\" />
     * @type {string}
     * @memberof RepairSpecDto
     */
    'lastReferenceSyncAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecDto
     */
    'isDetailsHidden'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairSpecDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairSpecDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {RepairType}
     * @memberof RepairSpecDto
     */
    'repairType'?: RepairType;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSpecDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSpecDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDto
     */
    'total'?: number;
    /**
     * 
     * @type {EntityMatchMetaDto}
     * @memberof RepairSpecDto
     */
    'matchMeta'?: EntityMatchMetaDto;
}


/**
 * 
 * @export
 * @interface RepairSpecGetPaginatedDto
 */
export interface RepairSpecGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof RepairSpecGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSpecGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSpecGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSpecGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof RepairSpecGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof RepairSpecGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof RepairSpecGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecGetPaginatedDto
     */
    'isGlobal'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecGetPaginatedDto
     */
    'damageTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecGetPaginatedDto
     */
    'partTypeId'?: string | null;
    /**
     * 
     * @type {VehicleType}
     * @memberof RepairSpecGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleSize}
     * @memberof RepairSpecGetPaginatedDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof RepairSpecGetPaginatedDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecGetPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecGetPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecGetPaginatedDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecGetPaginatedDto
     */
    'modificationId'?: string | null;
}


/**
 * 
 * @export
 * @interface RepairSpecItemCustomItemDto
 */
export interface RepairSpecItemCustomItemDto {
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecItemCustomItemDto
     */
    'isDetailsHidden'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecItemCustomItemDto
     */
    'name'?: string;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairSpecItemCustomItemDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemCustomItemDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecItemCustomItemDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSpecItemCustomItemDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSpecItemCustomItemDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemCustomItemDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemCustomItemDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemCustomItemDto
     */
    'total'?: number;
}


/**
 * <inheritdoc cref=\"T:Domain.Entities.Repair.RepairSpec.RepairSpecItemData\" />
 * @export
 * @interface RepairSpecItemDataDto
 */
export interface RepairSpecItemDataDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecItemDataDto
     */
    'id'?: string;
    /**
     * 
     * @type {RepairSpecItemType}
     * @memberof RepairSpecItemDataDto
     */
    'type'?: RepairSpecItemType;
    /**
     * 
     * @type {RepairMaterialFullReferenceDto}
     * @memberof RepairSpecItemDataDto
     */
    'material'?: RepairMaterialFullReferenceDto;
    /**
     * 
     * @type {RepairWorkFullReferenceDto}
     * @memberof RepairSpecItemDataDto
     */
    'work'?: RepairWorkFullReferenceDto;
    /**
     * 
     * @type {RepairSpecSparePartDto}
     * @memberof RepairSpecItemDataDto
     */
    'sparePart'?: RepairSpecSparePartDto;
    /**
     * 
     * @type {RepairSpecItemCustomItemDto}
     * @memberof RepairSpecItemDataDto
     */
    'custom'?: RepairSpecItemCustomItemDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataDto
     */
    'spotFactor'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecItemDataDto
     */
    'isDetailsHidden'?: boolean;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairSpecItemDataDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecItemDataDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSpecItemDataDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSpecItemDataDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface RepairSpecItemDataInputDto
 */
export interface RepairSpecItemDataInputDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecItemDataInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {RepairSpecItemType}
     * @memberof RepairSpecItemDataInputDto
     */
    'type'?: RepairSpecItemType;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecItemDataInputDto
     */
    'materialId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecItemDataInputDto
     */
    'workId'?: string | null;
    /**
     * 
     * @type {RepairSpecSparePartInputDto}
     * @memberof RepairSpecItemDataInputDto
     */
    'sparePart'?: RepairSpecSparePartInputDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataInputDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataInputDto
     */
    'spotFactor'?: number;
}


/**
 * 
 * @export
 * @interface RepairSpecItemDto
 */
export interface RepairSpecItemDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {RepairSpecItemType}
     * @memberof RepairSpecItemDto
     */
    'type'?: RepairSpecItemType;
    /**
     * 
     * @type {RepairMaterialFullReferenceDto}
     * @memberof RepairSpecItemDto
     */
    'material'?: RepairMaterialFullReferenceDto;
    /**
     * 
     * @type {RepairWorkFullReferenceDto}
     * @memberof RepairSpecItemDto
     */
    'work'?: RepairWorkFullReferenceDto;
    /**
     * 
     * @type {RepairSpecSparePartDto}
     * @memberof RepairSpecItemDto
     */
    'sparePart'?: RepairSpecSparePartDto;
    /**
     * 
     * @type {RepairSpecItemCustomItemDto}
     * @memberof RepairSpecItemDto
     */
    'custom'?: RepairSpecItemCustomItemDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDto
     */
    'spotFactor'?: number;
    /**
     * <inheritdoc cref=\"P:Domain.Entities.Repair.RepairSpec.RepairSpecItem.LastReferenceSyncAt\" />
     * @type {string}
     * @memberof RepairSpecItemDto
     */
    'lastReferenceSyncAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecItemDto
     */
    'isDetailsHidden'?: boolean;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairSpecItemDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecItemDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSpecItemDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSpecItemDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface RepairSpecItemInputDto
 */
export interface RepairSpecItemInputDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecItemInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {RepairSpecItemType}
     * @memberof RepairSpecItemInputDto
     */
    'type'?: RepairSpecItemType;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecItemInputDto
     */
    'materialId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecItemInputDto
     */
    'workId'?: string | null;
    /**
     * 
     * @type {RepairSpecSparePartInputDto}
     * @memberof RepairSpecItemInputDto
     */
    'sparePart'?: RepairSpecSparePartInputDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemInputDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemInputDto
     */
    'spotFactor'?: number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RepairSpecItemType = {
    None: 'None',
    Material: 'Material',
    SparePart: 'SparePart',
    Work: 'Work',
    Custom: 'Custom'
} as const;

export type RepairSpecItemType = typeof RepairSpecItemType[keyof typeof RepairSpecItemType];


/**
 * 
 * @export
 * @interface RepairSpecMatchQueryDto
 */
export interface RepairSpecMatchQueryDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecMatchQueryDto
     */
    'partTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecMatchQueryDto
     */
    'damageTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecMatchQueryDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface RepairSpecReferenceDto
 */
export interface RepairSpecReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecReferenceDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {RepairSpecDetalizationType}
     * @memberof RepairSpecReferenceDto
     */
    'detalizationType'?: RepairSpecDetalizationType;
}


/**
 * 
 * @export
 * @interface RepairSpecSearchPaginatedDto
 */
export interface RepairSpecSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'isGlobal'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'damageTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'partTypeId'?: string | null;
    /**
     * 
     * @type {VehicleType}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleSize}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'modificationId'?: string | null;
    /**
     * 
     * @type {RepairSpecMatchQueryDto}
     * @memberof RepairSpecSearchPaginatedDto
     */
    'match'?: RepairSpecMatchQueryDto;
}


/**
 * 
 * @export
 * @interface RepairSpecSparePartDto
 */
export interface RepairSpecSparePartDto {
    /**
     * 
     * @type {RepairSparePartFullReferenceDto}
     * @memberof RepairSpecSparePartDto
     */
    'sparePart'?: RepairSparePartFullReferenceDto;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairSpecSparePartDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecSparePartDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSpecSparePartDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSpecSparePartDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecSparePartDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecSparePartDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecSparePartDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface RepairSpecSparePartInputDto
 */
export interface RepairSpecSparePartInputDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecSparePartInputDto
     */
    'sparePartId'?: string;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof RepairSpecSparePartInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecSparePartInputDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof RepairSpecSparePartInputDto
     */
    'discount'?: GeneralDiscountInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof RepairSpecSparePartInputDto
     */
    'tax'?: GeneralTaxInputDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RepairType = {
    None: 'None',
    Spot: 'Spot',
    Full: 'Full'
} as const;

export type RepairType = typeof RepairType[keyof typeof RepairType];


/**
 * 
 * @export
 * @interface RepairWorkChangedDto
 */
export interface RepairWorkChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairWorkChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RepairWorkChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RepairWorkChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {RepairWorkDto}
     * @memberof RepairWorkChangedDto
     */
    'entity'?: RepairWorkDto;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface RepairWorkDto
 */
export interface RepairWorkDto {
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'repairCatalogId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'sourceWorkId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairWorkDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairWorkDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkDto
     */
    'expendablesPercent'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairWorkDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairWorkDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairWorkDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairWorkDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkDto
     */
    'isExpendablesIncluded'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface RepairWorkFullReferenceDto
 */
export interface RepairWorkFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'repairCatalogId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairWorkFullReferenceDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairWorkFullReferenceDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkFullReferenceDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkFullReferenceDto
     */
    'expendablesPercent'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairWorkFullReferenceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairWorkFullReferenceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkFullReferenceDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkFullReferenceDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkFullReferenceDto
     */
    'isExpendablesIncluded'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkFullReferenceDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkFullReferenceDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkFullReferenceDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface RepairWorkGetPaginatedDto
 */
export interface RepairWorkGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof RepairWorkGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairWorkGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairWorkGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairWorkGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof RepairWorkGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof RepairWorkGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof RepairWorkGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkGetPaginatedDto
     */
    'isGlobal'?: boolean;
}
/**
 * 
 * @export
 * @interface RepairWorkReferenceDto
 */
export interface RepairWorkReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairWorkReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface RepairWorkSearchPaginatedDto
 */
export interface RepairWorkSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof RepairWorkSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairWorkSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairWorkSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairWorkSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof RepairWorkSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof RepairWorkSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof RepairWorkSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkSearchPaginatedDto
     */
    'isGlobal'?: boolean;
}
/**
 * 
 * @export
 * @interface ReportMyChatParticipantStatusDto
 */
export interface ReportMyChatParticipantStatusDto {
    /**
     * 
     * @type {string}
     * @memberof ReportMyChatParticipantStatusDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportMyChatParticipantStatusDto
     */
    'participantId'?: string;
    /**
     * 
     * @type {ParticipantOnlineStatus}
     * @memberof ReportMyChatParticipantStatusDto
     */
    'onlineStatus'?: ParticipantOnlineStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportMyChatParticipantStatusDto
     */
    'onlyToConnectionIds'?: Array<string> | null;
    /**
     * If true, receiving participant should report own status to the sender.
     * @type {boolean}
     * @memberof ReportMyChatParticipantStatusDto
     */
    'isReportBack'?: boolean | null;
}


/**
 * 
 * @export
 * @interface RespondOnManyProposalsDto
 */
export interface RespondOnManyProposalsDto {
    /**
     * 
     * @type {Array<PairDtoOfStringAndString>}
     * @memberof RespondOnManyProposalsDto
     */
    'byNegotiationAndProposalId'?: Array<PairDtoOfStringAndString> | null;
    /**
     * 
     * @type {GeneralScopeInfoDto}
     * @memberof RespondOnManyProposalsDto
     */
    'byParentScope'?: GeneralScopeInfoDto;
    /**
     * 
     * @type {Array<GeneralScopeInfoDto>}
     * @memberof RespondOnManyProposalsDto
     */
    'byScopes'?: Array<GeneralScopeInfoDto> | null;
    /**
     * 
     * @type {ApprovalResponseType}
     * @memberof RespondOnManyProposalsDto
     */
    'responseType'?: ApprovalResponseType;
    /**
     * 
     * @type {boolean}
     * @memberof RespondOnManyProposalsDto
     */
    'onlyForProposalsWithoutPartyMemberResponse'?: boolean | null;
    /**
     * 
     * @type {Array<ProposalStatus>}
     * @memberof RespondOnManyProposalsDto
     */
    'onlyForProposalsWithStatuses'?: Array<ProposalStatus> | null;
}


/**
 * 
 * @export
 * @interface RespondOnManyProposalsInfoDto
 */
export interface RespondOnManyProposalsInfoDto {
    /**
     * 
     * @type {Array<ChatReferenceDto>}
     * @memberof RespondOnManyProposalsInfoDto
     */
    'chats'?: Array<ChatReferenceDto>;
    /**
     * 
     * @type {Array<NegotiationReferenceDto>}
     * @memberof RespondOnManyProposalsInfoDto
     */
    'negotiations'?: Array<NegotiationReferenceDto>;
    /**
     * 
     * @type {Array<NegotiationProposalDto>}
     * @memberof RespondOnManyProposalsInfoDto
     */
    'proposals'?: Array<NegotiationProposalDto>;
    /**
     * 
     * @type {number}
     * @memberof RespondOnManyProposalsInfoDto
     */
    'chatCount'?: number;
    /**
     * Chats where requesting user is a participant.
     * @type {number}
     * @memberof RespondOnManyProposalsInfoDto
     */
    'chatWhereIsParticipantCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RespondOnManyProposalsInfoDto
     */
    'isParticipantOfAllChats'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RespondOnManyProposalsInfoDto
     */
    'negotiationCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RespondOnManyProposalsInfoDto
     */
    'proposalCount'?: number;
    /**
     * Proposals on which requesting used have responded.
     * @type {number}
     * @memberof RespondOnManyProposalsInfoDto
     */
    'respondedProposalCount'?: number;
    /**
     * Proposals on which requesting used haven\'t responded.
     * @type {number}
     * @memberof RespondOnManyProposalsInfoDto
     */
    'notRespondedProposalCount'?: number;
}
/**
 * 
 * @export
 * @interface RespondOnManyProposalsInfoRequestDto
 */
export interface RespondOnManyProposalsInfoRequestDto {
    /**
     * 
     * @type {Array<PairDtoOfStringAndString>}
     * @memberof RespondOnManyProposalsInfoRequestDto
     */
    'byNegotiationAndProposalId'?: Array<PairDtoOfStringAndString> | null;
    /**
     * 
     * @type {GeneralScopeInfoDto}
     * @memberof RespondOnManyProposalsInfoRequestDto
     */
    'byParentScope'?: GeneralScopeInfoDto;
    /**
     * 
     * @type {Array<GeneralScopeInfoDto>}
     * @memberof RespondOnManyProposalsInfoRequestDto
     */
    'byScopes'?: Array<GeneralScopeInfoDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RespondOnManyProposalsInfoRequestDto
     */
    'onlyForProposalsWithoutPartyMemberResponse'?: boolean | null;
    /**
     * 
     * @type {Array<ProposalStatus>}
     * @memberof RespondOnManyProposalsInfoRequestDto
     */
    'onlyForProposalsWithStatuses'?: Array<ProposalStatus> | null;
}
/**
 * 
 * @export
 * @interface RespondOnNegotiationProposalDto
 */
export interface RespondOnNegotiationProposalDto {
    /**
     * 
     * @type {string}
     * @memberof RespondOnNegotiationProposalDto
     */
    'proposalId'?: string;
    /**
     * 
     * @type {ApprovalResponseType}
     * @memberof RespondOnNegotiationProposalDto
     */
    'responseType'?: ApprovalResponseType;
}


/**
 * 
 * @export
 * @interface RoleAssignmentRestrictionsDto
 */
export interface RoleAssignmentRestrictionsDto {
    /**
     * 
     * @type {string}
     * @memberof RoleAssignmentRestrictionsDto
     */
    'byUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleAssignmentRestrictionsDto
     */
    'targetUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleAssignmentRestrictionsDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof RoleAssignmentRestrictionsDto
     */
    'allowedRoles'?: Array<RoleDto>;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof RoleAssignmentRestrictionsDto
     */
    'disallowedRoles'?: Array<RoleDto>;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof RoleAssignmentRestrictionsDto
     */
    'allowedRoleIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof RoleAssignmentRestrictionsDto
     */
    'disallowedRoleIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {Array<AppPermission>}
     * @memberof RoleAssignmentRestrictionsDto
     */
    'allowedPermissions'?: Array<AppPermission>;
    /**
     * 
     * @type {Array<AppPermission>}
     * @memberof RoleAssignmentRestrictionsDto
     */
    'disallowedPermissions'?: Array<AppPermission>;
}
/**
 * 
 * @export
 * @interface RoleChangedDto
 */
export interface RoleChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RoleChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RoleChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RoleChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof RoleChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {RoleDto}
     * @memberof RoleChangedDto
     */
    'entity'?: RoleDto;
    /**
     * 
     * @type {string}
     * @memberof RoleChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoleDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'builtInRole'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoleDto
     */
    'isScoped'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleDto
     */
    'isTest'?: boolean;
    /**
     * 
     * @type {Array<AppPermission>}
     * @memberof RoleDto
     */
    'permissions'?: Array<AppPermission>;
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    'sortOrder'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RoleDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RoleDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoleDto
     */
    'canBeEdited'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleDto
     */
    'canBeDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface RoleReferenceDto
 */
export interface RoleReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RoleReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RoleReferenceDto
     */
    'isBuiltIn'?: boolean;
}
/**
 * 
 * @export
 * @interface SendChatMessageDto
 */
export interface SendChatMessageDto {
    /**
     * 
     * @type {string}
     * @memberof SendChatMessageDto
     */
    'participantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendChatMessageDto
     */
    'body'?: string | null;
    /**
     * 
     * @type {ChatMessageRootNodeInputDto}
     * @memberof SendChatMessageDto
     */
    'rootNode'?: ChatMessageRootNodeInputDto;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof SendChatMessageDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
}
/**
 * 
 * @export
 * @interface SendEmailVerificationTokenDto
 */
export interface SendEmailVerificationTokenDto {
    /**
     * 
     * @type {string}
     * @memberof SendEmailVerificationTokenDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface SendGridEmailSettingsDto
 */
export interface SendGridEmailSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof SendGridEmailSettingsDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendGridEmailSettingsDto
     */
    'fromName'?: string;
    /**
     * 
     * @type {UserSecretReferenceDto}
     * @memberof SendGridEmailSettingsDto
     */
    'apiKey'?: UserSecretReferenceDto;
}
/**
 * 
 * @export
 * @interface SendGridEmailSettingsInputDto
 */
export interface SendGridEmailSettingsInputDto {
    /**
     * 
     * @type {string}
     * @memberof SendGridEmailSettingsInputDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendGridEmailSettingsInputDto
     */
    'fromName'?: string;
    /**
     * 
     * @type {UserSecretInputDto}
     * @memberof SendGridEmailSettingsInputDto
     */
    'apiKey'?: UserSecretInputDto;
}
/**
 * 
 * @export
 * @interface SendTestEventsDto
 */
export interface SendTestEventsDto {
    /**
     * 
     * @type {Array<TestEventDto>}
     * @memberof SendTestEventsDto
     */
    'testEvents'?: Array<TestEventDto>;
}
/**
 * 
 * @export
 * @interface Size
 */
export interface Size {
    /**
     * 
     * @type {boolean}
     * @memberof Size
     */
    'isEmpty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Size
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof Size
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface SmsChannelInfoDto
 */
export interface SmsChannelInfoDto {
    /**
     * 
     * @type {string}
     * @memberof SmsChannelInfoDto
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsChannelInfoDto
     */
    'body'?: string;
}
/**
 * 
 * @export
 * @interface SmtpEmailSettingsDto
 */
export interface SmtpEmailSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof SmtpEmailSettingsDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmtpEmailSettingsDto
     */
    'fromName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmtpEmailSettingsDto
     */
    'replyTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SmtpEmailSettingsDto
     */
    'host'?: string;
    /**
     * 
     * @type {number}
     * @memberof SmtpEmailSettingsDto
     */
    'port'?: number;
    /**
     * 
     * @type {string}
     * @memberof SmtpEmailSettingsDto
     */
    'username'?: string;
    /**
     * 
     * @type {UserSecretReferenceDto}
     * @memberof SmtpEmailSettingsDto
     */
    'password'?: UserSecretReferenceDto;
}
/**
 * 
 * @export
 * @interface SmtpEmailSettingsInputDto
 */
export interface SmtpEmailSettingsInputDto {
    /**
     * 
     * @type {string}
     * @memberof SmtpEmailSettingsInputDto
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmtpEmailSettingsInputDto
     */
    'fromName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmtpEmailSettingsInputDto
     */
    'replyTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SmtpEmailSettingsInputDto
     */
    'host'?: string;
    /**
     * 
     * @type {number}
     * @memberof SmtpEmailSettingsInputDto
     */
    'port'?: number;
    /**
     * 
     * @type {string}
     * @memberof SmtpEmailSettingsInputDto
     */
    'username'?: string;
    /**
     * 
     * @type {UserSecretInputDto}
     * @memberof SmtpEmailSettingsInputDto
     */
    'password'?: UserSecretInputDto;
}
/**
 * Defines sorting on one or multiple fields.
 * @export
 * @interface SortDefinitionDto
 */
export interface SortDefinitionDto {
    /**
     * Comma-separated list of fields to sort by.
     * @type {string}
     * @memberof SortDefinitionDto
     */
    'sortBy'?: string | null;
    /**
     * Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
     * @type {string}
     * @memberof SortDefinitionDto
     */
    'sortOrder'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SortOrder = {
    None: 'None',
    Asc: 'Asc',
    Desc: 'Desc'
} as const;

export type SortOrder = typeof SortOrder[keyof typeof SortOrder];


/**
 * 
 * @export
 * @interface SpotChangedDto
 */
export interface SpotChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SpotChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SpotChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof SpotChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof SpotChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof SpotChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {SpotDto}
     * @memberof SpotChangedDto
     */
    'entity'?: SpotDto;
    /**
     * 
     * @type {string}
     * @memberof SpotChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface SpotDto
 */
export interface SpotDto {
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof SpotDto
     */
    'location'?: LocationReferenceDto;
    /**
     * 
     * @type {ProductLocationReferenceDto}
     * @memberof SpotDto
     */
    'productLocation'?: ProductLocationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {Array<SpotType>}
     * @memberof SpotDto
     */
    'types'?: Array<SpotType>;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof SpotDto
     */
    'address'?: GeneralAddressDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SpotDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SpotDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface SpotFullReferenceDto
 */
export interface SpotFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof SpotFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotFullReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof SpotFullReferenceDto
     */
    'location'?: LocationReferenceDto;
    /**
     * 
     * @type {ProductLocationReferenceDto}
     * @memberof SpotFullReferenceDto
     */
    'productLocation'?: ProductLocationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof SpotFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {Array<SpotType>}
     * @memberof SpotFullReferenceDto
     */
    'types'?: Array<SpotType>;
    /**
     * 
     * @type {string}
     * @memberof SpotFullReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof SpotFullReferenceDto
     */
    'address'?: GeneralAddressDto;
}
/**
 * 
 * @export
 * @interface SpotGetPaginatedDto
 */
export interface SpotGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof SpotGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SpotGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpotGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpotGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpotGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpotGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof SpotGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof SpotGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof SpotGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof SpotGetPaginatedDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotGetPaginatedDto
     */
    'productLocationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotGetPaginatedDto
     */
    'notForLocationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotGetPaginatedDto
     */
    'notForProductLocationId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpotGetPaginatedDto
     */
    'isWithLocation'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpotGetPaginatedDto
     */
    'isWithProductLocation'?: boolean | null;
    /**
     * 
     * @type {SpotType}
     * @memberof SpotGetPaginatedDto
     */
    'type'?: SpotType;
    /**
     * 
     * @type {Array<SpotType>}
     * @memberof SpotGetPaginatedDto
     */
    'types'?: Array<SpotType> | null;
}


/**
 * 
 * @export
 * @interface SpotReferenceDto
 */
export interface SpotReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof SpotReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof SpotReferenceDto
     */
    'location'?: LocationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof SpotReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface SpotSearchPaginatedDto
 */
export interface SpotSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof SpotSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SpotSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpotSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpotSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpotSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpotSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof SpotSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof SpotSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof SpotSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof SpotSearchPaginatedDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotSearchPaginatedDto
     */
    'productLocationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotSearchPaginatedDto
     */
    'notForLocationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotSearchPaginatedDto
     */
    'notForProductLocationId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpotSearchPaginatedDto
     */
    'isWithLocation'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpotSearchPaginatedDto
     */
    'isWithProductLocation'?: boolean | null;
    /**
     * 
     * @type {SpotType}
     * @memberof SpotSearchPaginatedDto
     */
    'type'?: SpotType;
    /**
     * 
     * @type {Array<SpotType>}
     * @memberof SpotSearchPaginatedDto
     */
    'types'?: Array<SpotType> | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SpotType = {
    None: 'None',
    Delivery: 'Delivery',
    CheckOut: 'CheckOut',
    CheckIn: 'CheckIn',
    Pickup: 'Pickup',
    Return: 'Return',
    Service: 'Service'
} as const;

export type SpotType = typeof SpotType[keyof typeof SpotType];


/**
 * 
 * @export
 * @interface SpreadsheetDtoOfString
 */
export interface SpreadsheetDtoOfString {
    /**
     * 
     * @type {Array<string>}
     * @memberof SpreadsheetDtoOfString
     */
    'columns'?: Array<string>;
    /**
     * 
     * @type {Array<SpreadsheetRowDtoOfString>}
     * @memberof SpreadsheetDtoOfString
     */
    'rows'?: Array<SpreadsheetRowDtoOfString>;
}
/**
 * 
 * @export
 * @interface SpreadsheetFileMetadataDto
 */
export interface SpreadsheetFileMetadataDto {
    /**
     * 
     * @type {Array<ColumnInfoDtoInSpreadsheetFileMetadataDto>}
     * @memberof SpreadsheetFileMetadataDto
     */
    'columns'?: Array<ColumnInfoDtoInSpreadsheetFileMetadataDto>;
    /**
     * 
     * @type {number}
     * @memberof SpreadsheetFileMetadataDto
     */
    'rowCapacity'?: number;
}
/**
 * 
 * @export
 * @interface SpreadsheetRowDtoOfString
 */
export interface SpreadsheetRowDtoOfString {
    /**
     * 
     * @type {Array<string>}
     * @memberof SpreadsheetRowDtoOfString
     */
    'items'?: Array<string>;
}
/**
 * 
 * @export
 * @interface StartCostEvaluationDto
 */
export interface StartCostEvaluationDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof StartCostEvaluationDto
     */
    'aggregateItemIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface StartCostEvaluationResponseDto
 */
export interface StartCostEvaluationResponseDto {
    /**
     * 
     * @type {DamageDetectionAggregateDto}
     * @memberof StartCostEvaluationResponseDto
     */
    'damageDetectionAggregate'?: DamageDetectionAggregateDto;
    /**
     * 
     * @type {DamageCostEvaluationDto}
     * @memberof StartCostEvaluationResponseDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationDto;
}
/**
 * 
 * @export
 * @interface SubmitMappingDto
 */
export interface SubmitMappingDto {
    /**
     * 
     * @type {string}
     * @memberof SubmitMappingDto
     */
    'importId'?: string;
    /**
     * Flag that indicates whether we should save mapping of this import for future
     * @type {boolean}
     * @memberof SubmitMappingDto
     */
    'shouldSaveMappingForFuture'?: boolean;
    /**
     * Name for this mapping if we want to save him
     * @type {string}
     * @memberof SubmitMappingDto
     */
    'mappingName'?: string | null;
    /**
     * Mapping definition
     * @type {Array<InlineMappingEntryDto>}
     * @memberof SubmitMappingDto
     */
    'mappings'?: Array<InlineMappingEntryDto>;
}
/**
 * 
 * @export
 * @interface SubmitUploadDto
 */
export interface SubmitUploadDto {
    /**
     * 
     * @type {string}
     * @memberof SubmitUploadDto
     */
    'importId'?: string;
    /**
     * 
     * @type {ImportEntityType}
     * @memberof SubmitUploadDto
     */
    'importEntityType'?: ImportEntityType;
    /**
     * 
     * @type {GeneralAttachmentInputDto}
     * @memberof SubmitUploadDto
     */
    'attachment'?: GeneralAttachmentInputDto;
}


/**
 * 
 * @export
 * @interface SubscribeOnChatActivityUpdatesDto
 */
export interface SubscribeOnChatActivityUpdatesDto {
    /**
     * 
     * @type {string}
     * @memberof SubscribeOnChatActivityUpdatesDto
     */
    'chatId'?: string;
}
/**
 * 
 * @export
 * @interface SubscribeOnDataUpdatesDto
 */
export interface SubscribeOnDataUpdatesDto {
    /**
     * 
     * @type {string}
     * @memberof SubscribeOnDataUpdatesDto
     */
    'channelName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscribeOnDataUpdatesDto
     */
    'channelNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscribeOnDataUpdatesDto
     */
    'allChannelNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface SubscriptionAssetDto
 */
export interface SubscriptionAssetDto {
    /**
     * 
     * @type {AssetReferenceDto}
     * @memberof SubscriptionAssetDto
     */
    'asset'?: AssetReferenceDto;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof SubscriptionAssetDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {PoolItemReferenceDto}
     * @memberof SubscriptionAssetDto
     */
    'poolItem'?: PoolItemReferenceDto;
}


/**
 * 
 * @export
 * @interface SubscriptionChangedDto
 */
export interface SubscriptionChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof SubscriptionChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof SubscriptionChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {SubscriptionDto}
     * @memberof SubscriptionChangedDto
     */
    'entity'?: SubscriptionDto;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface SubscriptionDto
 */
export interface SubscriptionDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionDto
     */
    'isCurrent'?: boolean;
    /**
     * 
     * @type {SubscriptionSelectedPlanDto}
     * @memberof SubscriptionDto
     */
    'plan'?: SubscriptionSelectedPlanDto;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof SubscriptionDto
     */
    'status'?: SubscriptionStatus;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof SubscriptionDto
     */
    'period'?: BillingPeriod;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'currentPeriodStartsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'currentPeriodEndsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'startsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'endsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'endedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'activatedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'pastDueAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'activatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'lastInvoiceId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface SubscriptionGetPaginatedDto
 */
export interface SubscriptionGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof SubscriptionGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof SubscriptionGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof SubscriptionGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
}
/**
 * 
 * @export
 * @interface SubscriptionPlanChangedDto
 */
export interface SubscriptionPlanChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionPlanChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof SubscriptionPlanChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof SubscriptionPlanChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {SubscriptionPlanDto}
     * @memberof SubscriptionPlanChangedDto
     */
    'entity'?: SubscriptionPlanDto;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface SubscriptionPlanDto
 */
export interface SubscriptionPlanDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionPlanDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionPlanDto
     */
    'isFree'?: boolean;
    /**
     * 
     * @type {Array<SubscriptionPlanPriceDto>}
     * @memberof SubscriptionPlanDto
     */
    'prices'?: Array<SubscriptionPlanPriceDto>;
    /**
     * 
     * @type {Array<AppModule>}
     * @memberof SubscriptionPlanDto
     */
    'modules'?: Array<AppModule>;
    /**
     * 
     * @type {object}
     * @memberof SubscriptionPlanDto
     */
    'limits'?: object;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface SubscriptionPlanDurationDto
 */
export interface SubscriptionPlanDurationDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanDurationDto
     */
    'id'?: string;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanDurationDto
     */
    'minDuration'?: string | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof SubscriptionPlanDurationDto
     */
    'discount'?: GeneralDiscountDto;
}
/**
 * 
 * @export
 * @interface SubscriptionPlanDurationInputDto
 */
export interface SubscriptionPlanDurationInputDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanDurationInputDto
     */
    'id'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanDurationInputDto
     */
    'minDuration'?: string | null;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof SubscriptionPlanDurationInputDto
     */
    'discount'?: GeneralDiscountInputDto;
}
/**
 * 
 * @export
 * @interface SubscriptionPlanExtraOptionDto
 */
export interface SubscriptionPlanExtraOptionDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'id'?: string;
    /**
     * 
     * @type {SubscriptionPlanOptionDto}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'option'?: SubscriptionPlanOptionDto;
    /**
     * 
     * @type {PaymentRegularityType}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'regularityType'?: PaymentRegularityType;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface SubscriptionPlanExtraOptionInputDto
 */
export interface SubscriptionPlanExtraOptionInputDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanExtraOptionInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {SubscriptionPlanOptionInputDto}
     * @memberof SubscriptionPlanExtraOptionInputDto
     */
    'option'?: SubscriptionPlanOptionInputDto;
    /**
     * 
     * @type {PaymentRegularityType}
     * @memberof SubscriptionPlanExtraOptionInputDto
     */
    'regularityType'?: PaymentRegularityType;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanExtraOptionInputDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof SubscriptionPlanExtraOptionInputDto
     */
    'discount'?: GeneralDiscountInputDto;
}


/**
 * 
 * @export
 * @interface SubscriptionPlanIncludedOptionDto
 */
export interface SubscriptionPlanIncludedOptionDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanIncludedOptionDto
     */
    'id'?: string;
    /**
     * 
     * @type {SubscriptionPlanOptionDto}
     * @memberof SubscriptionPlanIncludedOptionDto
     */
    'option'?: SubscriptionPlanOptionDto;
}
/**
 * 
 * @export
 * @interface SubscriptionPlanIncludedOptionInputDto
 */
export interface SubscriptionPlanIncludedOptionInputDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanIncludedOptionInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {SubscriptionPlanOptionInputDto}
     * @memberof SubscriptionPlanIncludedOptionInputDto
     */
    'option'?: SubscriptionPlanOptionInputDto;
}
/**
 * 
 * @export
 * @interface SubscriptionPlanOptionDto
 */
export interface SubscriptionPlanOptionDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanOptionDto
     */
    'id'?: string;
    /**
     * 
     * @type {SubscriptionPlanOptionType}
     * @memberof SubscriptionPlanOptionDto
     */
    'type'?: SubscriptionPlanOptionType;
    /**
     * 
     * @type {AccessoryReferenceDto}
     * @memberof SubscriptionPlanOptionDto
     */
    'accessory'?: AccessoryReferenceDto;
    /**
     * 
     * @type {SubscriptionPlanOptionForVehicleDto}
     * @memberof SubscriptionPlanOptionDto
     */
    'forVehicle'?: SubscriptionPlanOptionForVehicleDto;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanOptionDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanOptionDto
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @interface SubscriptionPlanOptionForVehicleDto
 */
export interface SubscriptionPlanOptionForVehicleDto {
    /**
     * 
     * @type {SubscriptionPlanOptionForVehicleType}
     * @memberof SubscriptionPlanOptionForVehicleDto
     */
    'type'?: SubscriptionPlanOptionForVehicleType;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanOptionForVehicleDto
     */
    'mileage'?: number | null;
}


/**
 * 
 * @export
 * @interface SubscriptionPlanOptionForVehicleInputDto
 */
export interface SubscriptionPlanOptionForVehicleInputDto {
    /**
     * 
     * @type {SubscriptionPlanOptionForVehicleType}
     * @memberof SubscriptionPlanOptionForVehicleInputDto
     */
    'type'?: SubscriptionPlanOptionForVehicleType;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanOptionForVehicleInputDto
     */
    'mileage'?: number | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SubscriptionPlanOptionForVehicleType = {
    None: 'None',
    Mileage: 'Mileage',
    FullTankAtReceive: 'FullTankAtReceive',
    FullTankAtReturn: 'FullTankAtReturn',
    DeliverToHome: 'DeliverToHome'
} as const;

export type SubscriptionPlanOptionForVehicleType = typeof SubscriptionPlanOptionForVehicleType[keyof typeof SubscriptionPlanOptionForVehicleType];


/**
 * 
 * @export
 * @interface SubscriptionPlanOptionInputDto
 */
export interface SubscriptionPlanOptionInputDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanOptionInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {SubscriptionPlanOptionType}
     * @memberof SubscriptionPlanOptionInputDto
     */
    'type'?: SubscriptionPlanOptionType;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanOptionInputDto
     */
    'accessoryId'?: string | null;
    /**
     * 
     * @type {SubscriptionPlanOptionForVehicleInputDto}
     * @memberof SubscriptionPlanOptionInputDto
     */
    'forVehicle'?: SubscriptionPlanOptionForVehicleInputDto;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanOptionInputDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanOptionInputDto
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SubscriptionPlanOptionType = {
    None: 'None',
    Accessory: 'Accessory',
    ForVehicle: 'ForVehicle'
} as const;

export type SubscriptionPlanOptionType = typeof SubscriptionPlanOptionType[keyof typeof SubscriptionPlanOptionType];


/**
 * 
 * @export
 * @interface SubscriptionPlanPriceDto
 */
export interface SubscriptionPlanPriceDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanPriceDto
     */
    'id'?: string;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof SubscriptionPlanPriceDto
     */
    'period'?: BillingPeriod;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanPriceDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionPlanPriceDto
     */
    'isBase'?: boolean;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof SubscriptionPlanPriceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof SubscriptionPlanPriceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {GeneralInsuranceDto}
     * @memberof SubscriptionPlanPriceDto
     */
    'insurance'?: GeneralInsuranceDto;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanPriceDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanPriceDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanPriceDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface SubscriptionPlanPriceInputDto
 */
export interface SubscriptionPlanPriceInputDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanPriceInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof SubscriptionPlanPriceInputDto
     */
    'period'?: BillingPeriod;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanPriceInputDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionPlanPriceInputDto
     */
    'isBase'?: boolean;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof SubscriptionPlanPriceInputDto
     */
    'discount'?: GeneralDiscountInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof SubscriptionPlanPriceInputDto
     */
    'tax'?: GeneralTaxInputDto;
    /**
     * 
     * @type {GeneralInsuranceInputDto}
     * @memberof SubscriptionPlanPriceInputDto
     */
    'insurance'?: GeneralInsuranceInputDto;
}


/**
 * 
 * @export
 * @interface SubscriptionPlanReferenceDto
 */
export interface SubscriptionPlanReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanReferenceDto
     */
    'globalNumber'?: string;
}
/**
 * 
 * @export
 * @interface SubscriptionPlanSettingsDto
 */
export interface SubscriptionPlanSettingsDto {
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanSettingsDto
     */
    'minDuration'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanSettingsDto
     */
    'maxDuration'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanSettingsDto
     */
    'exactDuration'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanSettingsDto
     */
    'defaultDuration'?: string | null;
}
/**
 * 
 * @export
 * @interface SubscriptionPlanSettingsInputDto
 */
export interface SubscriptionPlanSettingsInputDto {
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanSettingsInputDto
     */
    'minDuration'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanSettingsInputDto
     */
    'maxDuration'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanSettingsInputDto
     */
    'exactDuration'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanSettingsInputDto
     */
    'defaultDuration'?: string | null;
}
/**
 * 
 * @export
 * @interface SubscriptionReferenceDto
 */
export interface SubscriptionReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionReferenceDto
     */
    'globalNumber'?: string;
}
/**
 * 
 * @export
 * @interface SubscriptionSelectedPlanDto
 */
export interface SubscriptionSelectedPlanDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSelectedPlanDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSelectedPlanDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSelectedPlanDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionSelectedPlanDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionSelectedPlanDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {SubscriptionPlanPriceDto}
     * @memberof SubscriptionSelectedPlanDto
     */
    'price'?: SubscriptionPlanPriceDto;
    /**
     * 
     * @type {Array<AppModule>}
     * @memberof SubscriptionSelectedPlanDto
     */
    'modules'?: Array<AppModule>;
    /**
     * 
     * @type {object}
     * @memberof SubscriptionSelectedPlanDto
     */
    'limits'?: object;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SubscriptionStatus = {
    None: 'None',
    Draft: 'Draft',
    Pending: 'Pending',
    Unpaid: 'Unpaid',
    Active: 'Active',
    PastDue: 'PastDue',
    Ended: 'Ended',
    Paused: 'Paused',
    Canceled: 'Canceled'
} as const;

export type SubscriptionStatus = typeof SubscriptionStatus[keyof typeof SubscriptionStatus];


/**
 * Entity where tag can be placed.  Must contain only values from Domain.Enums.EntityType.
 * @export
 * @enum {string}
 */

export const TagEntityType = {
    None: 'None',
    Arbitrary: 'Arbitrary',
    Chat: 'Chat',
    ChatMessage: 'ChatMessage',
    ChatEvent: 'ChatEvent',
    Negotiation: 'Negotiation',
    GeneralEventLog: 'GeneralEventLog',
    VehicleHistory: 'VehicleHistory',
    Document: 'Document',
    DataGrant: 'DataGrant',
    TenantRequest: 'TenantRequest',
    Contract: 'Contract',
    AssessmentFlow: 'AssessmentFlow',
    Customer: 'Customer',
    Vehicle: 'Vehicle',
    Asset: 'Asset',
    Accessory: 'Accessory',
    AccessoryCheck: 'AccessoryCheck',
    VisualInspection: 'VisualInspection',
    DamageDetection: 'DamageDetection',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageCostEvaluation: 'DamageCostEvaluation',
    DamageCostEvaluationAggregate: 'DamageCostEvaluationAggregate',
    RepairOperation: 'RepairOperation',
    GeneralHistory: 'GeneralHistory'
} as const;

export type TagEntityType = typeof TagEntityType[keyof typeof TagEntityType];


/**
 * 
 * @export
 * @interface TagStaticFilterDto
 */
export interface TagStaticFilterDto {
    /**
     * 
     * @type {FilterOperator}
     * @memberof TagStaticFilterDto
     */
    'operator'?: FilterOperator;
    /**
     * 
     * @type {GeneralAttachedTagsQueryDto}
     * @memberof TagStaticFilterDto
     */
    'query'?: GeneralAttachedTagsQueryDto;
}


/**
 * 
 * @export
 * @interface TagStylesDto
 */
export interface TagStylesDto {
    /**
     * 
     * @type {string}
     * @memberof TagStylesDto
     */
    'backgroundColor'?: string | null;
}
/**
 * 
 * @export
 * @interface TagStylesInputDto
 */
export interface TagStylesInputDto {
    /**
     * 
     * @type {string}
     * @memberof TagStylesInputDto
     */
    'backgroundColor'?: string | null;
}
/**
 * What/who can be tagged.
 * @export
 * @enum {string}
 */

export const TagSubTargetType = {
    None: 'None',
    DamageDetectionItem: 'DamageDetectionItem',
    DamageDetectionAggregateItem: 'DamageDetectionAggregateItem',
    DamageCostEvaluationItem: 'DamageCostEvaluationItem',
    DamageCostEvaluationAggregateItem: 'DamageCostEvaluationAggregateItem',
    AccessoryCheckItem: 'AccessoryCheckItem',
    NegotiationProposal: 'NegotiationProposal'
} as const;

export type TagSubTargetType = typeof TagSubTargetType[keyof typeof TagSubTargetType];


/**
 * What/who can be tagged.  Must contain only values from Domain.Enums.EntityType.
 * @export
 * @enum {string}
 */

export const TagTargetType = {
    None: 'None',
    CustomTag: 'CustomTag',
    User: 'User',
    Vehicle: 'Vehicle',
    Asset: 'Asset',
    Contract: 'Contract',
    AssessmentFlow: 'AssessmentFlow',
    Customer: 'Customer',
    VehicleDamage: 'VehicleDamage',
    Accessory: 'Accessory',
    VisualInspection: 'VisualInspection',
    DamageDetection: 'DamageDetection',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageCostEvaluation: 'DamageCostEvaluation',
    DamageCostEvaluationAggregate: 'DamageCostEvaluationAggregate',
    AccessoryCheck: 'AccessoryCheck',
    RepairOperation: 'RepairOperation',
    Chat: 'Chat',
    ChatParticipant: 'ChatParticipant',
    Negotiation: 'Negotiation',
    AssetSubscriptionPlan: 'AssetSubscriptionPlan',
    AssetSubscription: 'AssetSubscription',
    TenantConnectionRequest: 'TenantConnectionRequest',
    TenantConnection: 'TenantConnection',
    TenantRequest: 'TenantRequest',
    DataGrant: 'DataGrant',
    Pool: 'Pool',
    Department: 'Department',
    Location: 'Location',
    Spot: 'Spot'
} as const;

export type TagTargetType = typeof TagTargetType[keyof typeof TagTargetType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TaxType = {
    None: 'None',
    Custom: 'Custom',
    Vat: 'Vat'
} as const;

export type TaxType = typeof TaxType[keyof typeof TaxType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TaxValueType = {
    None: 'None',
    Percent: 'Percent',
    Value: 'Value'
} as const;

export type TaxValueType = typeof TaxValueType[keyof typeof TaxValueType];


/**
 * 
 * @export
 * @interface TenantChangedDto
 */
export interface TenantChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TenantChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof TenantChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof TenantChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof TenantChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {TenantDto}
     * @memberof TenantChangedDto
     */
    'entity'?: TenantDto;
    /**
     * 
     * @type {string}
     * @memberof TenantChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface TenantConnectionChangedDto
 */
export interface TenantConnectionChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof TenantConnectionChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof TenantConnectionChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {TenantConnectionDto}
     * @memberof TenantConnectionChangedDto
     */
    'entity'?: TenantConnectionDto;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface TenantConnectionCountersDto
 */
export interface TenantConnectionCountersDto {
    /**
     * 
     * @type {number}
     * @memberof TenantConnectionCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantConnectionCountersDto
     */
    'activeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantConnectionCountersDto
     */
    'endedCount'?: number;
}
/**
 * 
 * @export
 * @interface TenantConnectionDto
 */
export interface TenantConnectionDto {
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'requestId'?: string;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof TenantConnectionDto
     */
    'connectedTenant1'?: TenantReferenceDto;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof TenantConnectionDto
     */
    'connectedTenant2'?: TenantReferenceDto;
    /**
     * 
     * @type {ChatReferenceDto}
     * @memberof TenantConnectionDto
     */
    'chat'?: ChatReferenceDto;
    /**
     * 
     * @type {TenantConnectionStatus}
     * @memberof TenantConnectionDto
     */
    'status'?: TenantConnectionStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'statusUpdatedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'activeAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'endedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof TenantConnectionDto
     */
    'tenantIdsMap'?: { [key: string]: boolean; };
}


/**
 * 
 * @export
 * @interface TenantConnectionGetPaginatedDto
 */
export interface TenantConnectionGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof TenantConnectionGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantConnectionGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof TenantConnectionGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof TenantConnectionGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof TenantConnectionGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionGetPaginatedDto
     */
    'requestId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionGetPaginatedDto
     */
    'connectedTenantId'?: string | null;
    /**
     * 
     * @type {TenantConnectionStatus}
     * @memberof TenantConnectionGetPaginatedDto
     */
    'status'?: TenantConnectionStatus;
}


/**
 * 
 * @export
 * @interface TenantConnectionReferenceDto
 */
export interface TenantConnectionReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionReferenceDto
     */
    'globalNumber'?: string;
}
/**
 * 
 * @export
 * @interface TenantConnectionRequestChangedDto
 */
export interface TenantConnectionRequestChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionRequestChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof TenantConnectionRequestChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof TenantConnectionRequestChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {TenantConnectionRequestDto}
     * @memberof TenantConnectionRequestChangedDto
     */
    'entity'?: TenantConnectionRequestDto;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface TenantConnectionRequestCountersDto
 */
export interface TenantConnectionRequestCountersDto {
    /**
     * 
     * @type {number}
     * @memberof TenantConnectionRequestCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {IncomingCountersDtoInTenantConnectionRequestCountersDto}
     * @memberof TenantConnectionRequestCountersDto
     */
    'incoming'?: IncomingCountersDtoInTenantConnectionRequestCountersDto;
    /**
     * 
     * @type {OutcomingCountersDtoInTenantConnectionRequestCountersDto}
     * @memberof TenantConnectionRequestCountersDto
     */
    'outcoming'?: OutcomingCountersDtoInTenantConnectionRequestCountersDto;
}
/**
 * 
 * @export
 * @interface TenantConnectionRequestCreateDto
 */
export interface TenantConnectionRequestCreateDto {
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestCreateDto
     */
    'receiverTenantId'?: string;
}
/**
 * 
 * @export
 * @interface TenantConnectionRequestDto
 */
export interface TenantConnectionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof TenantConnectionRequestDto
     */
    'senderTenant'?: TenantReferenceDto;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof TenantConnectionRequestDto
     */
    'receiverTenant'?: TenantReferenceDto;
    /**
     * 
     * @type {ChatReferenceDto}
     * @memberof TenantConnectionRequestDto
     */
    'chat'?: ChatReferenceDto;
    /**
     * 
     * @type {TenantConnectionRequestStatus}
     * @memberof TenantConnectionRequestDto
     */
    'status'?: TenantConnectionRequestStatus;
    /**
     * 
     * @type {TenantConnectionRequestResponseType}
     * @memberof TenantConnectionRequestDto
     */
    'responseType'?: TenantConnectionRequestResponseType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'respondedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'statusUpdatedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'pendingAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'acceptedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'declinedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'notRespondedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'revokedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof TenantConnectionRequestDto
     */
    'tenantIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {boolean}
     * @memberof TenantConnectionRequestDto
     */
    'isResponded'?: boolean;
}


/**
 * 
 * @export
 * @interface TenantConnectionRequestGetPaginatedDto
 */
export interface TenantConnectionRequestGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof TenantConnectionRequestGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantConnectionRequestGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionRequestGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionRequestGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionRequestGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof TenantConnectionRequestGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof TenantConnectionRequestGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof TenantConnectionRequestGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestGetPaginatedDto
     */
    'senderTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestGetPaginatedDto
     */
    'receiverTenantId'?: string | null;
    /**
     * 
     * @type {TenantConnectionRequestStatus}
     * @memberof TenantConnectionRequestGetPaginatedDto
     */
    'status'?: TenantConnectionRequestStatus;
}


/**
 * 
 * @export
 * @interface TenantConnectionRequestReferenceDto
 */
export interface TenantConnectionRequestReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestReferenceDto
     */
    'globalNumber'?: string;
}
/**
 * 
 * @export
 * @interface TenantConnectionRequestRespondDto
 */
export interface TenantConnectionRequestRespondDto {
    /**
     * 
     * @type {TenantConnectionRequestResponseType}
     * @memberof TenantConnectionRequestRespondDto
     */
    'responseType'?: TenantConnectionRequestResponseType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TenantConnectionRequestResponseType = {
    None: 'None',
    Accept: 'Accept',
    Decline: 'Decline'
} as const;

export type TenantConnectionRequestResponseType = typeof TenantConnectionRequestResponseType[keyof typeof TenantConnectionRequestResponseType];


/**
 * 
 * @export
 * @interface TenantConnectionRequestSearchPaginatedDto
 */
export interface TenantConnectionRequestSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof TenantConnectionRequestSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantConnectionRequestSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionRequestSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionRequestSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionRequestSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof TenantConnectionRequestSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof TenantConnectionRequestSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof TenantConnectionRequestSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestSearchPaginatedDto
     */
    'senderTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestSearchPaginatedDto
     */
    'receiverTenantId'?: string | null;
    /**
     * 
     * @type {TenantConnectionRequestStatus}
     * @memberof TenantConnectionRequestSearchPaginatedDto
     */
    'status'?: TenantConnectionRequestStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TenantConnectionRequestStatus = {
    None: 'None',
    Pending: 'Pending',
    Accepted: 'Accepted',
    Declined: 'Declined',
    NotResponded: 'NotResponded',
    Revoked: 'Revoked'
} as const;

export type TenantConnectionRequestStatus = typeof TenantConnectionRequestStatus[keyof typeof TenantConnectionRequestStatus];


/**
 * 
 * @export
 * @interface TenantConnectionSearchPaginatedDto
 */
export interface TenantConnectionSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof TenantConnectionSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantConnectionSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof TenantConnectionSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof TenantConnectionSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof TenantConnectionSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionSearchPaginatedDto
     */
    'requestId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionSearchPaginatedDto
     */
    'connectedTenantId'?: string | null;
    /**
     * 
     * @type {TenantConnectionStatus}
     * @memberof TenantConnectionSearchPaginatedDto
     */
    'status'?: TenantConnectionStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TenantConnectionStatus = {
    None: 'None',
    Active: 'Active',
    Ended: 'Ended'
} as const;

export type TenantConnectionStatus = typeof TenantConnectionStatus[keyof typeof TenantConnectionStatus];


/**
 * 
 * @export
 * @interface TenantContactPersonDto
 */
export interface TenantContactPersonDto {
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof TenantContactPersonDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof TenantContactPersonDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContactPersonDto
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface TenantContactPersonInputDto
 */
export interface TenantContactPersonInputDto {
    /**
     * 
     * @type {GeneralPersonNameInputDto}
     * @memberof TenantContactPersonInputDto
     */
    'personName'?: GeneralPersonNameInputDto;
    /**
     * 
     * @type {string}
     * @memberof TenantContactPersonInputDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContactPersonInputDto
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface TenantDto
 */
export interface TenantDto {
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    'name'?: string;
    /**
     * 
     * @type {GeneralBrandingDto}
     * @memberof TenantDto
     */
    'branding'?: GeneralBrandingDto;
}
/**
 * 
 * @export
 * @interface TenantEmailSettingsDto
 */
export interface TenantEmailSettingsDto {
    /**
     * 
     * @type {EmailProviderType}
     * @memberof TenantEmailSettingsDto
     */
    'providerType'?: EmailProviderType;
    /**
     * 
     * @type {SendGridEmailSettingsDto}
     * @memberof TenantEmailSettingsDto
     */
    'sendGrid'?: SendGridEmailSettingsDto;
    /**
     * 
     * @type {MailgunEmailSettingsDto}
     * @memberof TenantEmailSettingsDto
     */
    'mailgun'?: MailgunEmailSettingsDto;
    /**
     * 
     * @type {AmazonSesEmailSettingsDto}
     * @memberof TenantEmailSettingsDto
     */
    'amazonSes'?: AmazonSesEmailSettingsDto;
    /**
     * 
     * @type {MailchimpEmailSettingsDto}
     * @memberof TenantEmailSettingsDto
     */
    'mailchimp'?: MailchimpEmailSettingsDto;
    /**
     * 
     * @type {Ms365EmailSettingsDto}
     * @memberof TenantEmailSettingsDto
     */
    'microsoft365'?: Ms365EmailSettingsDto;
    /**
     * 
     * @type {AzureCsEmailSettingsDto}
     * @memberof TenantEmailSettingsDto
     */
    'azureCs'?: AzureCsEmailSettingsDto;
    /**
     * 
     * @type {SmtpEmailSettingsDto}
     * @memberof TenantEmailSettingsDto
     */
    'smtp'?: SmtpEmailSettingsDto;
}


/**
 * 
 * @export
 * @interface TenantEmailSettingsInputDto
 */
export interface TenantEmailSettingsInputDto {
    /**
     * 
     * @type {EmailProviderType}
     * @memberof TenantEmailSettingsInputDto
     */
    'providerType'?: EmailProviderType;
    /**
     * 
     * @type {SendGridEmailSettingsInputDto}
     * @memberof TenantEmailSettingsInputDto
     */
    'sendGrid'?: SendGridEmailSettingsInputDto;
    /**
     * 
     * @type {MailgunEmailSettingsInputDto}
     * @memberof TenantEmailSettingsInputDto
     */
    'mailgun'?: MailgunEmailSettingsInputDto;
    /**
     * 
     * @type {AmazonSesEmailSettingsInputDto}
     * @memberof TenantEmailSettingsInputDto
     */
    'amazonSes'?: AmazonSesEmailSettingsInputDto;
    /**
     * 
     * @type {MailchimpEmailSettingsInputDto}
     * @memberof TenantEmailSettingsInputDto
     */
    'mailchimp'?: MailchimpEmailSettingsInputDto;
    /**
     * 
     * @type {Ms365EmailSettingsInputDto}
     * @memberof TenantEmailSettingsInputDto
     */
    'microsoft365'?: Ms365EmailSettingsInputDto;
    /**
     * 
     * @type {AzureCsEmailSettingsInputDto}
     * @memberof TenantEmailSettingsInputDto
     */
    'azureCs'?: AzureCsEmailSettingsInputDto;
    /**
     * 
     * @type {SmtpEmailSettingsInputDto}
     * @memberof TenantEmailSettingsInputDto
     */
    'smtp'?: SmtpEmailSettingsInputDto;
}


/**
 * 
 * @export
 * @interface TenantGetPaginatedDto
 */
export interface TenantGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof TenantGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof TenantGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof TenantGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof TenantGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof TenantGetPaginatedDto
     */
    'isInMyConnections'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TenantInviteDto
 */
export interface TenantInviteDto {
    /**
     * 
     * @type {string}
     * @memberof TenantInviteDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantInviteDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantInviteDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantInviteDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantInviteDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {InviteType}
     * @memberof TenantInviteDto
     */
    'type'?: InviteType;
    /**
     * 
     * @type {InviteUserType}
     * @memberof TenantInviteDto
     */
    'userType'?: InviteUserType;
    /**
     * 
     * @type {InviteUserInfoDto}
     * @memberof TenantInviteDto
     */
    'userInfo'?: InviteUserInfoDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantInviteDto
     */
    'sentAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantInviteDto
     */
    'expiresAt'?: string;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof TenantInviteDto
     */
    'lifetime'?: string;
    /**
     * 
     * @type {number}
     * @memberof TenantInviteDto
     */
    'maxUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantInviteDto
     */
    'currentUsers'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantInviteDto
     */
    'inviteUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantInviteDto
     */
    'redirectUrl'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantInviteDto
     */
    'registeredUserIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantInviteDto
     */
    'acceptedUserIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantInviteDto
     */
    'rejectedUserIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantInviteDto
     */
    'roleIds'?: Array<string>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantInviteDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantInviteDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantInviteDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantInviteDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TenantInviteDto
     */
    'isExpired'?: boolean;
}


/**
 * 
 * @export
 * @interface TenantInviteGetPaginatedDto
 */
export interface TenantInviteGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof TenantInviteGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantInviteGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantInviteGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantInviteGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantInviteGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantInviteGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof TenantInviteGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof TenantInviteGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof TenantInviteGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof TenantInviteGetPaginatedDto
     */
    'inviteId'?: string | null;
    /**
     * 
     * @type {InviteType}
     * @memberof TenantInviteGetPaginatedDto
     */
    'type'?: InviteType;
    /**
     * 
     * @type {InviteUserType}
     * @memberof TenantInviteGetPaginatedDto
     */
    'userType'?: InviteUserType;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantInviteGetPaginatedDto
     */
    'allRoleIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantInviteGetPaginatedDto
     */
    'anyRoleIds'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface TenantMembershipOfEntityUpdateDto
 */
export interface TenantMembershipOfEntityUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof TenantMembershipOfEntityUpdateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantMembershipOfEntityUpdateDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantMembershipOfEntityUpdateDto
     */
    'entitiesIds'?: Array<string>;
    /**
     * 
     * @type {EntityType}
     * @memberof TenantMembershipOfEntityUpdateDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {Array<EntityType>}
     * @memberof TenantMembershipOfEntityUpdateDto
     */
    'relatedEntitiesTypes'?: Array<EntityType> | null;
}


/**
 * 
 * @export
 * @interface TenantPaymentProfileDto
 */
export interface TenantPaymentProfileDto {
    /**
     * 
     * @type {PaymentBusinessInfoDto}
     * @memberof TenantPaymentProfileDto
     */
    'businessInfo'?: PaymentBusinessInfoDto;
    /**
     * 
     * @type {Array<PaymentContactDto>}
     * @memberof TenantPaymentProfileDto
     */
    'contacts'?: Array<PaymentContactDto>;
}
/**
 * 
 * @export
 * @interface TenantPaymentProfileInputDto
 */
export interface TenantPaymentProfileInputDto {
    /**
     * 
     * @type {PaymentBusinessInfoInputDto}
     * @memberof TenantPaymentProfileInputDto
     */
    'businessInfo'?: PaymentBusinessInfoInputDto;
    /**
     * 
     * @type {Array<PaymentContactInputDto>}
     * @memberof TenantPaymentProfileInputDto
     */
    'contacts'?: Array<PaymentContactInputDto>;
}
/**
 * 
 * @export
 * @interface TenantProfileDto
 */
export interface TenantProfileDto {
    /**
     * 
     * @type {string}
     * @memberof TenantProfileDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantProfileDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantProfileDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantProfileDto
     */
    'description'?: string;
    /**
     * 
     * @type {TenantContactPersonDto}
     * @memberof TenantProfileDto
     */
    'contactPerson'?: TenantContactPersonDto;
    /**
     * 
     * @type {TenantSettingsDto}
     * @memberof TenantProfileDto
     */
    'settings'?: TenantSettingsDto;
    /**
     * 
     * @type {GeneralBrandingDto}
     * @memberof TenantProfileDto
     */
    'branding'?: GeneralBrandingDto;
}
/**
 * 
 * @export
 * @interface TenantProfileUpdateDto
 */
export interface TenantProfileUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof TenantProfileUpdateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantProfileUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {TenantContactPersonInputDto}
     * @memberof TenantProfileUpdateDto
     */
    'contactPerson'?: TenantContactPersonInputDto;
    /**
     * 
     * @type {GeneralBrandingInputDto}
     * @memberof TenantProfileUpdateDto
     */
    'branding'?: GeneralBrandingInputDto;
}
/**
 * 
 * @export
 * @interface TenantReference
 */
export interface TenantReference {
    /**
     * 
     * @type {string}
     * @memberof TenantReference
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantReference
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantReference
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TenantReferenceDto
 */
export interface TenantReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof TenantReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantReferenceDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TenantRequestAppliedResultDto
 */
export interface TenantRequestAppliedResultDto {
    /**
     * When result was applied to sender tenant.
     * @type {string}
     * @memberof TenantRequestAppliedResultDto
     */
    'appliedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestAppliedResultDto
     */
    'appliedBy'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof TenantRequestAppliedResultDto
     */
    'createdEntityTags'?: GeneralAttachedTagsDto;
}
/**
 * 
 * @export
 * @interface TenantRequestApplyResultDto
 */
export interface TenantRequestApplyResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof TenantRequestApplyResultDto
     */
    'isReapply'?: boolean;
}
/**
 * 
 * @export
 * @interface TenantRequestChangedDto
 */
export interface TenantRequestChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantRequestChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof TenantRequestChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof TenantRequestChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {TenantRequestDto}
     * @memberof TenantRequestChangedDto
     */
    'entity'?: TenantRequestDto;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface TenantRequestCloseDto
 */
export interface TenantRequestCloseDto {
    /**
     * 
     * @type {string}
     * @memberof TenantRequestCloseDto
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface TenantRequestContentDto
 */
export interface TenantRequestContentDto {
    /**
     * 
     * @type {TenantRequestType}
     * @memberof TenantRequestContentDto
     */
    'type'?: TenantRequestType;
    /**
     * 
     * @type {object}
     * @memberof TenantRequestContentDto
     */
    'arbitrary'?: object;
    /**
     * 
     * @type {TenantRequestOperationContentDto}
     * @memberof TenantRequestContentDto
     */
    'operation'?: TenantRequestOperationContentDto;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestContentDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof TenantRequestContentDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
}


/**
 * 
 * @export
 * @interface TenantRequestContentInputDto
 */
export interface TenantRequestContentInputDto {
    /**
     * 
     * @type {TenantRequestType}
     * @memberof TenantRequestContentInputDto
     */
    'type'?: TenantRequestType;
    /**
     * 
     * @type {object}
     * @memberof TenantRequestContentInputDto
     */
    'arbitrary'?: object;
    /**
     * 
     * @type {TenantRequestOperationContentInputDto}
     * @memberof TenantRequestContentInputDto
     */
    'operation'?: TenantRequestOperationContentInputDto;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestContentInputDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof TenantRequestContentInputDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
}


/**
 * 
 * @export
 * @interface TenantRequestCountersDto
 */
export interface TenantRequestCountersDto {
    /**
     * 
     * @type {number}
     * @memberof TenantRequestCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {IncomingCountersDtoInTenantRequestCountersDto}
     * @memberof TenantRequestCountersDto
     */
    'incoming'?: IncomingCountersDtoInTenantRequestCountersDto;
    /**
     * 
     * @type {OutcomingCountersDtoInTenantRequestCountersDto}
     * @memberof TenantRequestCountersDto
     */
    'outcoming'?: OutcomingCountersDtoInTenantRequestCountersDto;
}
/**
 * 
 * @export
 * @interface TenantRequestCreateDto
 */
export interface TenantRequestCreateDto {
    /**
     * 
     * @type {string}
     * @memberof TenantRequestCreateDto
     */
    'receiverTenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestCreateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestCreateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {TenantRequestContentInputDto}
     * @memberof TenantRequestCreateDto
     */
    'content'?: TenantRequestContentInputDto;
    /**
     * 
     * @type {TenantRequestDataGrantSettingsInputDto}
     * @memberof TenantRequestCreateDto
     */
    'dataGrantSettings'?: TenantRequestDataGrantSettingsInputDto;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof TenantRequestCreateDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestCreateDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestCreateDto
     */
    'assessmentFlowId'?: string | null;
    /**
     * 
     * @type {GeneralStageUpdateDtoOfTenantRequestStage}
     * @memberof TenantRequestCreateDto
     */
    'newStage'?: GeneralStageUpdateDtoOfTenantRequestStage;
}
/**
 * 
 * @export
 * @interface TenantRequestDataGrantSettingsDto
 */
export interface TenantRequestDataGrantSettingsDto {
    /**
     * 
     * @type {Array<DataGrantPermission>}
     * @memberof TenantRequestDataGrantSettingsDto
     */
    'permissions'?: Array<DataGrantPermission>;
}
/**
 * 
 * @export
 * @interface TenantRequestDataGrantSettingsInputDto
 */
export interface TenantRequestDataGrantSettingsInputDto {
    /**
     * 
     * @type {Array<DataGrantPermission>}
     * @memberof TenantRequestDataGrantSettingsInputDto
     */
    'permissions'?: Array<DataGrantPermission>;
}
/**
 * 
 * @export
 * @interface TenantRequestDto
 */
export interface TenantRequestDto {
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof TenantRequestDto
     */
    'senderTenant'?: TenantReferenceDto;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof TenantRequestDto
     */
    'receiverTenant'?: TenantReferenceDto;
    /**
     * 
     * @type {ChatReferenceDto}
     * @memberof TenantRequestDto
     */
    'chat'?: ChatReferenceDto;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfTenantRequestStage}
     * @memberof TenantRequestDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfTenantRequestStage;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {TenantRequestContentDto}
     * @memberof TenantRequestDto
     */
    'content'?: TenantRequestContentDto;
    /**
     * 
     * @type {TenantRequestDataGrantSettingsDto}
     * @memberof TenantRequestDto
     */
    'dataGrantSettings'?: TenantRequestDataGrantSettingsDto;
    /**
     * 
     * @type {TenantRequestResponseType}
     * @memberof TenantRequestDto
     */
    'responseType'?: TenantRequestResponseType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantRequestDto
     */
    'respondedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'responseReason'?: string | null;
    /**
     * 
     * @type {TenantRequestResultDto}
     * @memberof TenantRequestDto
     */
    'result'?: TenantRequestResultDto;
    /**
     * 
     * @type {TenantRequestAppliedResultDto}
     * @memberof TenantRequestDto
     */
    'appliedResult'?: TenantRequestAppliedResultDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof TenantRequestDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof TenantRequestDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {AssessmentFlowReferenceDto}
     * @memberof TenantRequestDto
     */
    'assessmentFlow'?: AssessmentFlowReferenceDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantRequestDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantRequestDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TenantRequestDto
     */
    'isResponded'?: boolean;
}


/**
 * 
 * @export
 * @interface TenantRequestGetPaginatedDto
 */
export interface TenantRequestGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof TenantRequestGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantRequestGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantRequestGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantRequestGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantRequestGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof TenantRequestGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof TenantRequestGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof TenantRequestGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestGetPaginatedDto
     */
    'senderTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestGetPaginatedDto
     */
    'receiverTenantId'?: string | null;
    /**
     * 
     * @type {TenantRequestStage}
     * @memberof TenantRequestGetPaginatedDto
     */
    'stage'?: TenantRequestStage;
    /**
     * 
     * @type {TenantRequestType}
     * @memberof TenantRequestGetPaginatedDto
     */
    'type'?: TenantRequestType;
    /**
     * 
     * @type {TenantRequestOperationType}
     * @memberof TenantRequestGetPaginatedDto
     */
    'operationType'?: TenantRequestOperationType;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestGetPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestGetPaginatedDto
     */
    'assessmentFlowId'?: string | null;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof TenantRequestGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @interface TenantRequestOperationContentDto
 */
export interface TenantRequestOperationContentDto {
    /**
     * 
     * @type {TenantRequestOperationType}
     * @memberof TenantRequestOperationContentDto
     */
    'operationType'?: TenantRequestOperationType;
    /**
     * 
     * @type {VisualInspectionContentDto}
     * @memberof TenantRequestOperationContentDto
     */
    'visualInspection'?: VisualInspectionContentDto;
    /**
     * 
     * @type {DamageDetectionContentDto}
     * @memberof TenantRequestOperationContentDto
     */
    'damageDetection'?: DamageDetectionContentDto;
    /**
     * 
     * @type {DamageCostEvaluationContentDto}
     * @memberof TenantRequestOperationContentDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationContentDto;
    /**
     * 
     * @type {AccessoryCheckContentDto}
     * @memberof TenantRequestOperationContentDto
     */
    'accessoryCheck'?: AccessoryCheckContentDto;
    /**
     * 
     * @type {RepairOperationContentDto}
     * @memberof TenantRequestOperationContentDto
     */
    'repairOperation'?: RepairOperationContentDto;
}


/**
 * 
 * @export
 * @interface TenantRequestOperationContentInputDto
 */
export interface TenantRequestOperationContentInputDto {
    /**
     * 
     * @type {TenantRequestOperationType}
     * @memberof TenantRequestOperationContentInputDto
     */
    'operationType'?: TenantRequestOperationType;
    /**
     * 
     * @type {VisualInspectionContentInputDto}
     * @memberof TenantRequestOperationContentInputDto
     */
    'visualInspection'?: VisualInspectionContentInputDto;
    /**
     * 
     * @type {DamageDetectionContentInputDto}
     * @memberof TenantRequestOperationContentInputDto
     */
    'damageDetection'?: DamageDetectionContentInputDto;
    /**
     * 
     * @type {DamageCostEvaluationContentInputDto}
     * @memberof TenantRequestOperationContentInputDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationContentInputDto;
    /**
     * 
     * @type {AccessoryCheckContentInputDto}
     * @memberof TenantRequestOperationContentInputDto
     */
    'accessoryCheck'?: AccessoryCheckContentInputDto;
    /**
     * 
     * @type {RepairOperationContentInputDto}
     * @memberof TenantRequestOperationContentInputDto
     */
    'repairOperation'?: RepairOperationContentInputDto;
}


/**
 * 
 * @export
 * @interface TenantRequestOperationResultDto
 */
export interface TenantRequestOperationResultDto {
    /**
     * 
     * @type {TenantRequestOperationType}
     * @memberof TenantRequestOperationResultDto
     */
    'operationType'?: TenantRequestOperationType;
    /**
     * 
     * @type {VisualInspectionResultDto}
     * @memberof TenantRequestOperationResultDto
     */
    'visualInspection'?: VisualInspectionResultDto;
    /**
     * 
     * @type {DamageDetectionResultDto}
     * @memberof TenantRequestOperationResultDto
     */
    'damageDetection'?: DamageDetectionResultDto;
    /**
     * 
     * @type {DamageCostEvaluationResultDto}
     * @memberof TenantRequestOperationResultDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationResultDto;
    /**
     * 
     * @type {AccessoryCheckResultDto}
     * @memberof TenantRequestOperationResultDto
     */
    'accessoryCheck'?: AccessoryCheckResultDto;
    /**
     * 
     * @type {RepairOperationResultDto}
     * @memberof TenantRequestOperationResultDto
     */
    'repairOperation'?: RepairOperationResultDto;
}


/**
 * 
 * @export
 * @interface TenantRequestOperationResultInputDto
 */
export interface TenantRequestOperationResultInputDto {
    /**
     * 
     * @type {TenantRequestOperationType}
     * @memberof TenantRequestOperationResultInputDto
     */
    'operationType'?: TenantRequestOperationType;
    /**
     * 
     * @type {VisualInspectionResultInputDto}
     * @memberof TenantRequestOperationResultInputDto
     */
    'visualInspection'?: VisualInspectionResultInputDto;
    /**
     * 
     * @type {DamageDetectionResultInputDto}
     * @memberof TenantRequestOperationResultInputDto
     */
    'damageDetection'?: DamageDetectionResultInputDto;
    /**
     * 
     * @type {DamageCostEvaluationResultInputDto}
     * @memberof TenantRequestOperationResultInputDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationResultInputDto;
    /**
     * 
     * @type {AccessoryCheckResultInputDto}
     * @memberof TenantRequestOperationResultInputDto
     */
    'accessoryCheck'?: AccessoryCheckResultInputDto;
    /**
     * 
     * @type {RepairOperationResultInputDto}
     * @memberof TenantRequestOperationResultInputDto
     */
    'repairOperation'?: RepairOperationResultInputDto;
}


/**
 * Describes types of operations (from business logic perspective) that can be performed by tenants.
 * @export
 * @enum {string}
 */

export const TenantRequestOperationType = {
    None: 'None',
    VisualInspection: 'VisualInspection',
    DamageDetection: 'DamageDetection',
    DamageCostEvaluation: 'DamageCostEvaluation',
    AccessoryCheck: 'AccessoryCheck',
    RepairOperation: 'RepairOperation'
} as const;

export type TenantRequestOperationType = typeof TenantRequestOperationType[keyof typeof TenantRequestOperationType];


/**
 * 
 * @export
 * @interface TenantRequestReferenceDto
 */
export interface TenantRequestReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof TenantRequestReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestReferenceDto
     */
    'globalNumber'?: string;
}
/**
 * 
 * @export
 * @interface TenantRequestRespondDto
 */
export interface TenantRequestRespondDto {
    /**
     * 
     * @type {TenantRequestResponseType}
     * @memberof TenantRequestRespondDto
     */
    'responseType'?: TenantRequestResponseType;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestRespondDto
     */
    'reason'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TenantRequestResponseType = {
    None: 'None',
    Accept: 'Accept',
    Decline: 'Decline'
} as const;

export type TenantRequestResponseType = typeof TenantRequestResponseType[keyof typeof TenantRequestResponseType];


/**
 * 
 * @export
 * @interface TenantRequestResultDto
 */
export interface TenantRequestResultDto {
    /**
     * 
     * @type {TenantRequestType}
     * @memberof TenantRequestResultDto
     */
    'type'?: TenantRequestType;
    /**
     * 
     * @type {TenantRequestOperationResultDto}
     * @memberof TenantRequestResultDto
     */
    'operation'?: TenantRequestOperationResultDto;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestResultDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof TenantRequestResultDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
}


/**
 * 
 * @export
 * @interface TenantRequestResultInputDto
 */
export interface TenantRequestResultInputDto {
    /**
     * 
     * @type {TenantRequestType}
     * @memberof TenantRequestResultInputDto
     */
    'type'?: TenantRequestType;
    /**
     * 
     * @type {TenantRequestOperationResultInputDto}
     * @memberof TenantRequestResultInputDto
     */
    'operation'?: TenantRequestOperationResultInputDto;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestResultInputDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof TenantRequestResultInputDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
}


/**
 * 
 * @export
 * @interface TenantRequestReworkDto
 */
export interface TenantRequestReworkDto {
    /**
     * 
     * @type {string}
     * @memberof TenantRequestReworkDto
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface TenantRequestSearchPaginatedDto
 */
export interface TenantRequestSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'senderTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'receiverTenantId'?: string | null;
    /**
     * 
     * @type {TenantRequestStage}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'stage'?: TenantRequestStage;
    /**
     * 
     * @type {TenantRequestType}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'type'?: TenantRequestType;
    /**
     * 
     * @type {TenantRequestOperationType}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'operationType'?: TenantRequestOperationType;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'assessmentFlowId'?: string | null;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof TenantRequestSearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * NB: use Common.Attributes.SortOrderAttribute to specify the order in which stages can be activated.
 * @export
 * @enum {string}
 */

export const TenantRequestStage = {
    None: 'None',
    Draft: 'Draft',
    Pending: 'Pending',
    Declined: 'Declined',
    Accepted: 'Accepted',
    InProgress: 'InProgress',
    Completed: 'Completed',
    Reporting: 'Reporting',
    Closed: 'Closed'
} as const;

export type TenantRequestStage = typeof TenantRequestStage[keyof typeof TenantRequestStage];


/**
 * 
 * @export
 * @enum {string}
 */

export const TenantRequestType = {
    None: 'None',
    Arbitrary: 'Arbitrary',
    Operation: 'Operation'
} as const;

export type TenantRequestType = typeof TenantRequestType[keyof typeof TenantRequestType];


/**
 * 
 * @export
 * @interface TenantRequestUpdateDto
 */
export interface TenantRequestUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof TenantRequestUpdateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {TenantRequestContentInputDto}
     * @memberof TenantRequestUpdateDto
     */
    'content'?: TenantRequestContentInputDto;
    /**
     * 
     * @type {TenantRequestDataGrantSettingsInputDto}
     * @memberof TenantRequestUpdateDto
     */
    'dataGrantSettings'?: TenantRequestDataGrantSettingsInputDto;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof TenantRequestUpdateDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestUpdateDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestUpdateDto
     */
    'assessmentFlowId'?: string | null;
}
/**
 * 
 * @export
 * @interface TenantRequestsMetaDto
 */
export interface TenantRequestsMetaDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantRequestsMetaDto
     */
    'tenantRequestIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestsMetaDto
     */
    'appliedResultOfTenantRequestId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TenantRequestsMetaDto
     */
    'hasTenantRequest'?: boolean;
}
/**
 * 
 * @export
 * @interface TenantRequestsMetaInputDto
 */
export interface TenantRequestsMetaInputDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantRequestsMetaInputDto
     */
    'tenantRequestIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface TenantRequestsMetaQueryDto
 */
export interface TenantRequestsMetaQueryDto {
    /**
     * 
     * @type {string}
     * @memberof TenantRequestsMetaQueryDto
     */
    'tenantRequestId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantRequestsMetaQueryDto
     */
    'tenantRequestIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantRequestsMetaQueryDto
     */
    'notTenantRequestIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestsMetaQueryDto
     */
    'appliedResultOfTenantRequestId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TenantRequestsMetaQueryDto
     */
    'hasTenantRequest'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TenantSearchPaginatedDto
 */
export interface TenantSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof TenantSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof TenantSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof TenantSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof TenantSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof TenantSearchPaginatedDto
     */
    'isInMyConnections'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TenantSettingsDto
 */
export interface TenantSettingsDto {
    /**
     * 
     * @type {GeneralCountryDto}
     * @memberof TenantSettingsDto
     */
    'country'?: GeneralCountryDto;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof TenantSettingsDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof TenantSettingsDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {string}
     * @memberof TenantSettingsDto
     */
    'tzId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantSettingsDto
     */
    'isEnableTenantToTenantConnections'?: boolean;
    /**
     * 
     * @type {VisualInspectionSettingsDto}
     * @memberof TenantSettingsDto
     */
    'visualInspection'?: VisualInspectionSettingsDto;
    /**
     * 
     * @type {TenantVehicleTaxSettingsDto}
     * @memberof TenantSettingsDto
     */
    'vehicleTax'?: TenantVehicleTaxSettingsDto;
}
/**
 * 
 * @export
 * @interface TenantSettingsInputDto
 */
export interface TenantSettingsInputDto {
    /**
     * 
     * @type {GeneralCountryInputDto}
     * @memberof TenantSettingsInputDto
     */
    'country'?: GeneralCountryInputDto;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof TenantSettingsInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof TenantSettingsInputDto
     */
    'tax'?: GeneralTaxInputDto;
    /**
     * 
     * @type {string}
     * @memberof TenantSettingsInputDto
     */
    'tzId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantSettingsInputDto
     */
    'isEnableTenantToTenantConnections'?: boolean;
    /**
     * 
     * @type {VisualInspectionSettingsInputDto}
     * @memberof TenantSettingsInputDto
     */
    'visualInspection'?: VisualInspectionSettingsInputDto;
    /**
     * 
     * @type {TenantVehicleTaxSettingsInputDto}
     * @memberof TenantSettingsInputDto
     */
    'vehicleTax'?: TenantVehicleTaxSettingsInputDto;
}
/**
 * 
 * @export
 * @interface TenantToTenantCountersDto
 */
export interface TenantToTenantCountersDto {
    /**
     * 
     * @type {TenantConnectionRequestCountersDto}
     * @memberof TenantToTenantCountersDto
     */
    'connectionRequests'?: TenantConnectionRequestCountersDto;
    /**
     * 
     * @type {TenantConnectionCountersDto}
     * @memberof TenantToTenantCountersDto
     */
    'connections'?: TenantConnectionCountersDto;
    /**
     * 
     * @type {TenantRequestCountersDto}
     * @memberof TenantToTenantCountersDto
     */
    'requests'?: TenantRequestCountersDto;
}
/**
 * 
 * @export
 * @interface TenantVehicleTaxSettingsDto
 */
export interface TenantVehicleTaxSettingsDto {
    /**
     * 
     * @type {Array<VehicleTaxDto>}
     * @memberof TenantVehicleTaxSettingsDto
     */
    'vehicleTaxes'?: Array<VehicleTaxDto> | null;
}
/**
 * 
 * @export
 * @interface TenantVehicleTaxSettingsInputDto
 */
export interface TenantVehicleTaxSettingsInputDto {
    /**
     * 
     * @type {Array<VehicleTaxInputDto>}
     * @memberof TenantVehicleTaxSettingsInputDto
     */
    'vehicleTaxes'?: Array<VehicleTaxInputDto> | null;
}
/**
 * 
 * @export
 * @interface TestEventDto
 */
export interface TestEventDto {
    /**
     * 
     * @type {number}
     * @memberof TestEventDto
     */
    'numberOfEvents'?: number;
    /**
     * 
     * @type {number}
     * @memberof TestEventDto
     */
    'proccessDurationMs'?: number | null;
    /**
     * 
     * @type {AdminTestDomainEventType}
     * @memberof TestEventDto
     */
    'eventType'?: AdminTestDomainEventType;
}


/**
 * 
 * @export
 * @interface TestNullableSwaggerClass
 */
export interface TestNullableSwaggerClass {
    /**
     * 
     * @type {number}
     * @memberof TestNullableSwaggerClass
     */
    'int'?: number;
    /**
     * 
     * @type {number}
     * @memberof TestNullableSwaggerClass
     */
    'intNullable'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TestNullableSwaggerClass
     */
    'bool'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TestNullableSwaggerClass
     */
    'boolNullable'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TestNullableSwaggerClass
     */
    'string'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestNullableSwaggerClass
     */
    'stringNullable'?: string | null;
    /**
     * 
     * @type {VehicleType}
     * @memberof TestNullableSwaggerClass
     */
    'enum'?: VehicleType;
    /**
     * 
     * @type {VehicleType}
     * @memberof TestNullableSwaggerClass
     */
    'enumNullable'?: VehicleType;
    /**
     * 
     * @type {Size}
     * @memberof TestNullableSwaggerClass
     */
    'struct'?: Size;
    /**
     * 
     * @type {Size}
     * @memberof TestNullableSwaggerClass
     */
    'structNullable'?: Size;
    /**
     * 
     * @type {TestSwaggerClass}
     * @memberof TestNullableSwaggerClass
     */
    'class'?: TestSwaggerClass;
    /**
     * 
     * @type {TestSwaggerClass}
     * @memberof TestNullableSwaggerClass
     */
    'classNullable'?: TestSwaggerClass;
}


/**
 * 
 * @export
 * @interface TestSwaggerClass
 */
export interface TestSwaggerClass {
    /**
     * 
     * @type {string}
     * @memberof TestSwaggerClass
     */
    'string'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ThumbnailSizeType = {
    None: 'None',
    ExtraSmall: 'ExtraSmall',
    Small: 'Small',
    Medium: 'Medium',
    Large: 'Large',
    ExtraLarge: 'ExtraLarge'
} as const;

export type ThumbnailSizeType = typeof ThumbnailSizeType[keyof typeof ThumbnailSizeType];


/**
 * 
 * @export
 * @enum {string}
 */

export const UnitOfTime = {
    None: 'None',
    Year: 'Year',
    Month: 'Month',
    Week: 'Week',
    Day: 'Day',
    Hour: 'Hour',
    Minute: 'Minute',
    Second: 'Second',
    Millisecond: 'Millisecond'
} as const;

export type UnitOfTime = typeof UnitOfTime[keyof typeof UnitOfTime];


/**
 * 
 * @export
 * @interface UnsubscribeFromChatActivityUpdatesDto
 */
export interface UnsubscribeFromChatActivityUpdatesDto {
    /**
     * 
     * @type {string}
     * @memberof UnsubscribeFromChatActivityUpdatesDto
     */
    'chatId'?: string;
}
/**
 * 
 * @export
 * @interface UnsubscribeFromDataUpdatesDto
 */
export interface UnsubscribeFromDataUpdatesDto {
    /**
     * 
     * @type {string}
     * @memberof UnsubscribeFromDataUpdatesDto
     */
    'channelName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnsubscribeFromDataUpdatesDto
     */
    'channelNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnsubscribeFromDataUpdatesDto
     */
    'allChannelNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateAccessoryCheckDto
 */
export interface UpdateAccessoryCheckDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccessoryCheckDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccessoryCheckDto
     */
    'locationId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof UpdateAccessoryCheckDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorAddDto}
     * @memberof UpdateAccessoryCheckDto
     */
    'inspector'?: GeneralInspectorAddDto;
    /**
     * 
     * @type {Array<UpdateAccessoryCheckItemDto>}
     * @memberof UpdateAccessoryCheckDto
     */
    'items'?: Array<UpdateAccessoryCheckItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof UpdateAccessoryCheckDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccessoryCheckDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof UpdateAccessoryCheckDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface UpdateAccessoryCheckItemDto
 */
export interface UpdateAccessoryCheckItemDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccessoryCheckItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {AccessoryStatus}
     * @memberof UpdateAccessoryCheckItemDto
     */
    'status'?: AccessoryStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccessoryCheckItemDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof UpdateAccessoryCheckItemDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
}


/**
 * 
 * @export
 * @interface UpdateAccessoryDto
 */
export interface UpdateAccessoryDto {
    /**
     * 
     * @type {AccessoryType}
     * @memberof UpdateAccessoryDto
     */
    'type'?: AccessoryType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof UpdateAccessoryDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccessoryDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccessoryDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof UpdateAccessoryDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}


/**
 * 
 * @export
 * @interface UpdateAdminUserDto
 */
export interface UpdateAdminUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {GeneralPersonNameInputDto}
     * @memberof UpdateAdminUserDto
     */
    'personName'?: GeneralPersonNameInputDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface UpdateChatDto
 */
export interface UpdateChatDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateChatDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateChatDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {ChatSettingsDto}
     * @memberof UpdateChatDto
     */
    'settings'?: ChatSettingsDto;
}
/**
 * 
 * @export
 * @interface UpdateChatMessageDto
 */
export interface UpdateChatMessageDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateChatMessageDto
     */
    'body'?: string | null;
    /**
     * 
     * @type {ChatMessageRootNodeInputDto}
     * @memberof UpdateChatMessageDto
     */
    'rootNode'?: ChatMessageRootNodeInputDto;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof UpdateChatMessageDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
}
/**
 * 
 * @export
 * @interface UpdateChatUserSettingsDto
 */
export interface UpdateChatUserSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateChatUserSettingsDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateChatUserSettingsDto
     */
    'settingsId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateChatUserSettingsDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {UpdateChatUserSettingsEventDto}
     * @memberof UpdateChatUserSettingsDto
     */
    'event'?: UpdateChatUserSettingsEventDto;
}
/**
 * 
 * @export
 * @interface UpdateChatUserSettingsEventDto
 */
export interface UpdateChatUserSettingsEventDto {
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof UpdateChatUserSettingsEventDto
     */
    'disabledCategoriesMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof UpdateChatUserSettingsEventDto
     */
    'disabledTypesMap'?: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface UpdateContractDto
 */
export interface UpdateContractDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    'externalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateContractDto
     */
    'accessoryIds'?: Array<string> | null;
    /**
     * 
     * @type {ContractType}
     * @memberof UpdateContractDto
     */
    'type'?: ContractType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof UpdateContractDto
     */
    'startsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof UpdateContractDto
     */
    'endsAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {ContractSettingsInputDto}
     * @memberof UpdateContractDto
     */
    'settings'?: ContractSettingsInputDto;
    /**
     * 
     * @type {ContractSpotInfoInputDto}
     * @memberof UpdateContractDto
     */
    'checkOutSpotInfo'?: ContractSpotInfoInputDto;
    /**
     * 
     * @type {ContractSpotInfoInputDto}
     * @memberof UpdateContractDto
     */
    'checkInSpotInfo'?: ContractSpotInfoInputDto;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof UpdateContractDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}


/**
 * 
 * @export
 * @interface UpdateContractReminderDto
 */
export interface UpdateContractReminderDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateContractReminderDto
     */
    'isEnabled'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof UpdateContractReminderDto
     */
    'nextFireAt'?: string;
}
/**
 * 
 * @export
 * @interface UpdateContractStageDto
 */
export interface UpdateContractStageDto {
    /**
     * 
     * @type {ContractStage}
     * @memberof UpdateContractStageDto
     */
    'newStage'?: ContractStage;
}


/**
 * 
 * @export
 * @interface UpdateCustomerContactDto
 */
export interface UpdateCustomerContactDto {
    /**
     * 
     * @type {GeneralPersonNameInputDto}
     * @memberof UpdateCustomerContactDto
     */
    'personName'?: GeneralPersonNameInputDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerContactDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerContactDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {GeneralNationalIdentityNumberInputDto}
     * @memberof UpdateCustomerContactDto
     */
    'nationalIdentityNumber'?: GeneralNationalIdentityNumberInputDto;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCustomerContactDto
     */
    'isPrimary'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerContactDto
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCustomerDto
 */
export interface UpdateCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCustomerDto
     */
    'isDriver'?: boolean | null;
    /**
     * 
     * @type {GeneralAddressInputDto}
     * @memberof UpdateCustomerDto
     */
    'address'?: GeneralAddressInputDto;
    /**
     * 
     * @type {Array<UpdateCustomerContactDto>}
     * @memberof UpdateCustomerDto
     */
    'contacts'?: Array<UpdateCustomerContactDto>;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof UpdateCustomerDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'locationId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateDamageDetectionDto
 */
export interface UpdateDamageDetectionDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDamageDetectionDto
     */
    'vehicleVisualModelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDamageDetectionDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDamageDetectionDto
     */
    'locationId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof UpdateDamageDetectionDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorAddDto}
     * @memberof UpdateDamageDetectionDto
     */
    'inspector'?: GeneralInspectorAddDto;
    /**
     * 
     * @type {number}
     * @memberof UpdateDamageDetectionDto
     */
    'mileage'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDamageDetectionDto
     */
    'fuelLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDamageDetectionDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDamageDetectionDto
     */
    'isNoDamagesDetected'?: boolean;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof UpdateDamageDetectionDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof UpdateDamageDetectionDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {Array<UpdateDamageDetectionItemDto>}
     * @memberof UpdateDamageDetectionDto
     */
    'items'?: Array<UpdateDamageDetectionItemDto>;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof UpdateDamageDetectionDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface UpdateDamageDetectionItemDto
 */
export interface UpdateDamageDetectionItemDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDamageDetectionItemDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDamageDetectionItemDto
     */
    'partTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDamageDetectionItemDto
     */
    'damageTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDamageDetectionItemDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof UpdateDamageDetectionItemDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {VehicleArea}
     * @memberof UpdateDamageDetectionItemDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof UpdateDamageDetectionItemDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof UpdateDamageDetectionItemDto
     */
    'point'?: GeneralSvgPointDto;
}


/**
 * 
 * @export
 * @interface UpdateDepartmentDto
 */
export interface UpdateDepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDepartmentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDepartmentDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressInputDto}
     * @memberof UpdateDepartmentDto
     */
    'address'?: GeneralAddressInputDto;
    /**
     * 
     * @type {GeneralBrandingInputDto}
     * @memberof UpdateDepartmentDto
     */
    'branding'?: GeneralBrandingInputDto;
}
/**
 * 
 * @export
 * @interface UpdateDocumentStageDto
 */
export interface UpdateDocumentStageDto {
    /**
     * 
     * @type {DocumentStage}
     * @memberof UpdateDocumentStageDto
     */
    'newStage'?: DocumentStage;
}


/**
 * 
 * @export
 * @interface UpdateEntityAffiliationParametersInputDto
 */
export interface UpdateEntityAffiliationParametersInputDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateEntityAffiliationParametersInputDto
     */
    'departmentIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateEntityAffiliationParametersInputDto
     */
    'locationIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateLocationDto
 */
export interface UpdateLocationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressInputDto}
     * @memberof UpdateLocationDto
     */
    'address'?: GeneralAddressInputDto;
}
/**
 * 
 * @export
 * @interface UpdateNegotiationDto
 */
export interface UpdateNegotiationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateNegotiationDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateNegotiationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {NegotiationSettingsDto}
     * @memberof UpdateNegotiationDto
     */
    'settings'?: NegotiationSettingsDto;
}
/**
 * 
 * @export
 * @interface UpdateNegotiationProposalDto
 */
export interface UpdateNegotiationProposalDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateNegotiationProposalDto
     */
    'statement'?: string;
    /**
     * 
     * @type {NegotiationProposalSettingsDto}
     * @memberof UpdateNegotiationProposalDto
     */
    'settings'?: NegotiationProposalSettingsDto;
}
/**
 * 
 * @export
 * @interface UpdateProductLocationDto
 */
export interface UpdateProductLocationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProductLocationDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductLocationDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductLocationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductLocationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressInputDto}
     * @memberof UpdateProductLocationDto
     */
    'address'?: GeneralAddressInputDto;
}
/**
 * 
 * @export
 * @interface UpdateRepairMaterialDto
 */
export interface UpdateRepairMaterialDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairMaterialDto
     */
    'repairCatalogId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairMaterialDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairMaterialDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof UpdateRepairMaterialDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairMaterialDto
     */
    'brandName'?: string | null;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof UpdateRepairMaterialDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof UpdateRepairMaterialDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRepairMaterialDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof UpdateRepairMaterialDto
     */
    'discount'?: GeneralDiscountInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof UpdateRepairMaterialDto
     */
    'tax'?: GeneralTaxInputDto;
}


/**
 * 
 * @export
 * @interface UpdateRepairOperationDto
 */
export interface UpdateRepairOperationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairOperationDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairOperationDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairOperationDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairOperationDto
     */
    'description'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof UpdateRepairOperationDto
     */
    'date'?: string;
    /**
     * 
     * @type {GeneralByWhoInputDto}
     * @memberof UpdateRepairOperationDto
     */
    'responsibleUser'?: GeneralByWhoInputDto;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof UpdateRepairOperationDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {Array<RepairOperationItemInputDto>}
     * @memberof UpdateRepairOperationDto
     */
    'items'?: Array<RepairOperationItemInputDto>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairOperationDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof UpdateRepairOperationDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof UpdateRepairOperationDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface UpdateRepairSparePartDto
 */
export interface UpdateRepairSparePartDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairSparePartDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairSparePartDto
     */
    'partNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairSparePartDto
     */
    'brandName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairSparePartDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof UpdateRepairSparePartDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {VehicleType}
     * @memberof UpdateRepairSparePartDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairSparePartDto
     */
    'partTypeId'?: string | null;
    /**
     * 
     * @type {Array<RepairSparePartDetalizationInputDto>}
     * @memberof UpdateRepairSparePartDto
     */
    'detalizations'?: Array<RepairSparePartDetalizationInputDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof UpdateRepairSparePartDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof UpdateRepairSparePartDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {number}
     * @memberof UpdateRepairSparePartDto
     */
    'price'?: number | null;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof UpdateRepairSparePartDto
     */
    'discount'?: GeneralDiscountInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof UpdateRepairSparePartDto
     */
    'tax'?: GeneralTaxInputDto;
}


/**
 * 
 * @export
 * @interface UpdateRepairSpecDto
 */
export interface UpdateRepairSpecDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairSpecDto
     */
    'repairCatalogId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairSpecDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairSpecDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof UpdateRepairSpecDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof UpdateRepairSpecDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairSpecDto
     */
    'damageTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairSpecDto
     */
    'partTypeId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRepairSpecDto
     */
    'isSpot'?: boolean;
    /**
     * 
     * @type {RepairSpecDetalizationInputDto}
     * @memberof UpdateRepairSpecDto
     */
    'detalization'?: RepairSpecDetalizationInputDto;
    /**
     * 
     * @type {number}
     * @memberof UpdateRepairSpecDto
     */
    'spotFactor'?: number;
    /**
     * 
     * @type {Array<RepairSpecItemInputDto>}
     * @memberof UpdateRepairSpecDto
     */
    'items'?: Array<RepairSpecItemInputDto>;
}


/**
 * 
 * @export
 * @interface UpdateRepairWorkDto
 */
export interface UpdateRepairWorkDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairWorkDto
     */
    'repairCatalogId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairWorkDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRepairWorkDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof UpdateRepairWorkDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof UpdateRepairWorkDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof UpdateRepairWorkDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateRepairWorkDto
     */
    'expendablesPercent'?: number;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof UpdateRepairWorkDto
     */
    'discount'?: GeneralDiscountInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof UpdateRepairWorkDto
     */
    'tax'?: GeneralTaxInputDto;
}


/**
 * 
 * @export
 * @interface UpdateSpotDto
 */
export interface UpdateSpotDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSpotDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpotDto
     */
    'productLocationId'?: string | null;
    /**
     * 
     * @type {Array<SpotType>}
     * @memberof UpdateSpotDto
     */
    'types'?: Array<SpotType>;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpotDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpotDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressInputDto}
     * @memberof UpdateSpotDto
     */
    'address'?: GeneralAddressInputDto;
}
/**
 * 
 * @export
 * @interface UpdateTenantRoleDto
 */
export interface UpdateTenantRoleDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantRoleDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantRoleDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<AppPermission>}
     * @memberof UpdateTenantRoleDto
     */
    'permissions'?: Array<AppPermission> | null;
}
/**
 * 
 * @export
 * @interface UpdateTenantUserDto
 */
export interface UpdateTenantUserDto {
    /**
     * 
     * @type {GeneralPersonNameInputDto}
     * @memberof UpdateTenantUserDto
     */
    'personName'?: GeneralPersonNameInputDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantUserDto
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface UpdateUserRolesDto
 */
export interface UpdateUserRolesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRolesDto
     */
    'roleIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateVehicleDto
 */
export interface UpdateVehicleDto {
    /**
     * 
     * @type {VehicleSpecInputDto}
     * @memberof UpdateVehicleDto
     */
    'spec'?: VehicleSpecInputDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateVehicleDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateVehicleDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateVehicleDto
     */
    'identificationNumber'?: string | null;
    /**
     * 
     * @type {GeneralPlateNoInputDto}
     * @memberof UpdateVehicleDto
     */
    'plateNo'?: GeneralPlateNoInputDto;
    /**
     * 
     * @type {number}
     * @memberof UpdateVehicleDto
     */
    'mileage'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateVehicleDto
     */
    'accessoryIds'?: Array<string> | null;
    /**
     * 
     * @type {object}
     * @memberof UpdateVehicleDto
     */
    'car'?: object;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof UpdateVehicleDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateVehicleDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateVehicleDto
     */
    'defaultVisualModelId'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof UpdateVehicleDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface UpdateVehicleMileageDto
 */
export interface UpdateVehicleMileageDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateVehicleMileageDto
     */
    'mileage'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateVehicleMileageDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof UpdateVehicleMileageDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
}
/**
 * 
 * @export
 * @interface UpdateWebhookDto
 */
export interface UpdateWebhookDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateWebhookDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWebhookDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<WebhookEventType>}
     * @memberof UpdateWebhookDto
     */
    'eventTypes'?: Array<WebhookEventType>;
    /**
     * 
     * @type {string}
     * @memberof UpdateWebhookDto
     */
    'url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWebhookDto
     */
    'isEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UploadFilesResultDto
 */
export interface UploadFilesResultDto {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof UploadFilesResultDto
     */
    'files'?: Array<FileDto>;
}
/**
 * 
 * @export
 * @interface UploadedFileDto
 */
export interface UploadedFileDto {
    /**
     * File id.
     * @type {string}
     * @memberof UploadedFileDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadedFileDto
     */
    'isUploadedFile'?: boolean;
}
/**
 * 
 * @export
 * @interface UserActivateDto
 */
export interface UserActivateDto {
    /**
     * 
     * @type {string}
     * @memberof UserActivateDto
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface UserAddedToChatDto
 */
export interface UserAddedToChatDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAddedToChatDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddedToChatDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddedToChatDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatParticipantDto}
     * @memberof UserAddedToChatDto
     */
    'participant'?: ChatParticipantDto;
}
/**
 * 
 * @export
 * @interface UserChangedDto
 */
export interface UserChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof UserChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof UserChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof UserChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof UserChangedDto
     */
    'entity'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof UserChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface UserDepartmentDto
 */
export interface UserDepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof UserDepartmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDepartmentDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserDto
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof UserDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {UserSettingsDto}
     * @memberof UserDto
     */
    'settings'?: UserSettingsDto;
    /**
     * 
     * @type {Array<UserDepartmentDto>}
     * @memberof UserDto
     */
    'departments'?: Array<UserDepartmentDto>;
    /**
     * 
     * @type {GeneralAvatarDto}
     * @memberof UserDto
     */
    'avatar'?: GeneralAvatarDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    'roleIds'?: Array<string>;
    /**
     * 
     * @type {Array<UserRoleDto>}
     * @memberof UserDto
     */
    'roles'?: Array<UserRoleDto>;
    /**
     * 
     * @type {UserSuspensionInfoDto}
     * @memberof UserDto
     */
    'suspension'?: UserSuspensionInfoDto;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isPersonalInfoComplete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isCustomerUser'?: boolean;
}


/**
 * 
 * @export
 * @interface UserGetPaginatedDto
 */
export interface UserGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof UserGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof UserGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof UserGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof UserGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof UserGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGetPaginatedDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserGetPaginatedDto
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGetPaginatedDto
     */
    'allRoleIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGetPaginatedDto
     */
    'anyRoleIds'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface UserIdentityRoleDto
 */
export interface UserIdentityRoleDto {
    /**
     * 
     * @type {string}
     * @memberof UserIdentityRoleDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityRoleDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityRoleDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {Array<AppPermission>}
     * @memberof UserIdentityRoleDto
     */
    'permissions'?: Array<AppPermission>;
}
/**
 * 
 * @export
 * @interface UserIdentityTestDto
 */
export interface UserIdentityTestDto {
    /**
     * 
     * @type {VersionDto}
     * @memberof UserIdentityTestDto
     */
    'version'?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityTestDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityTestDto
     */
    'auth0UserId'?: string;
    /**
     * Current tenant.
     * @type {string}
     * @memberof UserIdentityTestDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityTestDto
     */
    'email'?: string;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserIdentityTestDto
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserIdentityTestDto
     */
    'tenantIds'?: Array<string>;
    /**
     * 
     * @type {Array<UserIdentityRoleDto>}
     * @memberof UserIdentityTestDto
     */
    'roles'?: Array<UserIdentityRoleDto>;
    /**
     * 
     * @type {Array<AppModule>}
     * @memberof UserIdentityTestDto
     */
    'modules'?: Array<AppModule>;
    /**
     * 
     * @type {Array<AppPermission>}
     * @memberof UserIdentityTestDto
     */
    'permissions'?: Array<AppPermission>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof UserIdentityTestDto
     */
    'createdAt'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UserMembershipType = {
    None: 'None',
    Account: 'Account',
    NoAccount: 'NoAccount',
    Anonymous: 'Anonymous'
} as const;

export type UserMembershipType = typeof UserMembershipType[keyof typeof UserMembershipType];


/**
 * 
 * @export
 * @interface UserPartiesMembershipDto
 */
export interface UserPartiesMembershipDto {
    /**
     * 
     * @type {string}
     * @memberof UserPartiesMembershipDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserPartiesMembershipDto
     */
    'userId'?: string;
    /**
     * 
     * @type {Array<PartyAndMemberDto>}
     * @memberof UserPartiesMembershipDto
     */
    'membership'?: Array<PartyAndMemberDto>;
    /**
     * 
     * @type {Array<PartyEntity>}
     * @memberof UserPartiesMembershipDto
     */
    'parties'?: Array<PartyEntity>;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof UserPartiesMembershipDto
     */
    'membershipByPartyMap'?: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface UserReferenceDto
 */
export interface UserReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof UserReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserReferenceDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserReferenceDto
     */
    'email'?: string;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof UserReferenceDto
     */
    'personName'?: GeneralPersonNameDto;
}
/**
 * 
 * @export
 * @interface UserRoleDto
 */
export interface UserRoleDto {
    /**
     * 
     * @type {RoleReferenceDto}
     * @memberof UserRoleDto
     */
    'role'?: RoleReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    'assignedBy'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof UserRoleDto
     */
    'assignedAt'?: string;
}
/**
 * 
 * @export
 * @interface UserSearchPaginatedDto
 */
export interface UserSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof UserSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof UserSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof UserSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof UserSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof UserSearchPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSearchPaginatedDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserSearchPaginatedDto
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSearchPaginatedDto
     */
    'allRoleIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSearchPaginatedDto
     */
    'anyRoleIds'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface UserSecretDto
 */
export interface UserSecretDto {
    /**
     * 
     * @type {string}
     * @memberof UserSecretDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSecretDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSecretDto
     */
    'hash'?: string;
    /**
     * 
     * @type {HashAlgorithmType}
     * @memberof UserSecretDto
     */
    'hashAlgorithmType'?: HashAlgorithmType;
}


/**
 * 
 * @export
 * @interface UserSecretInputDto
 */
export interface UserSecretInputDto {
    /**
     * 
     * @type {string}
     * @memberof UserSecretInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSecretInputDto
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface UserSecretReferenceDto
 */
export interface UserSecretReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof UserSecretReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSecretReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSecretReferenceDto
     */
    'hash'?: string;
}
/**
 * 
 * @export
 * @interface UserSecretValueDto
 */
export interface UserSecretValueDto {
    /**
     * 
     * @type {UserSecretDto}
     * @memberof UserSecretValueDto
     */
    'key'?: UserSecretDto;
    /**
     * 
     * @type {string}
     * @memberof UserSecretValueDto
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface UserSettingsDto
 */
export interface UserSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof UserSettingsDto
     */
    'tzId'?: string;
    /**
     * 
     * @type {GeneralCultureDto}
     * @memberof UserSettingsDto
     */
    'culture'?: GeneralCultureDto;
}
/**
 * 
 * @export
 * @interface UserSettingsInputDto
 */
export interface UserSettingsInputDto {
    /**
     * 
     * @type {string}
     * @memberof UserSettingsInputDto
     */
    'tzId'?: string | null;
    /**
     * 
     * @type {GeneralCultureInputDto}
     * @memberof UserSettingsInputDto
     */
    'culture'?: GeneralCultureInputDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserStatus = {
    None: 'None',
    InviteIncomplete: 'InviteIncomplete',
    InfoIncomplete: 'InfoIncomplete',
    Active: 'Active',
    Suspended: 'Suspended'
} as const;

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];


/**
 * 
 * @export
 * @interface UserSuspendDto
 */
export interface UserSuspendDto {
    /**
     * 
     * @type {string}
     * @memberof UserSuspendDto
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface UserSuspensionInfoDto
 */
export interface UserSuspensionInfoDto {
    /**
     * 
     * @type {UserStatus}
     * @memberof UserSuspensionInfoDto
     */
    'statusBeforeSuspension'?: UserStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof UserSuspensionInfoDto
     */
    'suspendedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof UserSuspensionInfoDto
     */
    'unsuspendedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSuspensionInfoDto
     */
    'suspendedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSuspensionInfoDto
     */
    'unsuspendedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSuspensionInfoDto
     */
    'suspendReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSuspensionInfoDto
     */
    'unsuspendReason'?: string | null;
}


/**
 * 
 * @export
 * @interface UserUnsuspendDto
 */
export interface UserUnsuspendDto {
    /**
     * 
     * @type {string}
     * @memberof UserUnsuspendDto
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface UserValidateResultDto
 */
export interface UserValidateResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof UserValidateResultDto
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserValidateResultDto
     */
    'attemptsLeft'?: number;
}
/**
 * 
 * @export
 * @interface ValidateCompanyIdentifierDto
 */
export interface ValidateCompanyIdentifierDto {
    /**
     * 
     * @type {string}
     * @memberof ValidateCompanyIdentifierDto
     */
    'identifier'?: string;
}
/**
 * 
 * @export
 * @interface ValidateEmailVerificationTokenDto
 */
export interface ValidateEmailVerificationTokenDto {
    /**
     * 
     * @type {string}
     * @memberof ValidateEmailVerificationTokenDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateEmailVerificationTokenDto
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface ValidatePasswordDto
 */
export interface ValidatePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ValidatePasswordDto
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'instance'?: string | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    'errors'?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface VehicleAccessoriesDto
 */
export interface VehicleAccessoriesDto {
    /**
     * 
     * @type {Array<AccessoryReferenceDto>}
     * @memberof VehicleAccessoriesDto
     */
    'accessories'?: Array<AccessoryReferenceDto>;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof VehicleAccessoriesDto
     */
    'contract'?: ContractReferenceDto;
}
/**
 * 
 * @export
 * @interface VehicleActualStateDto
 */
export interface VehicleActualStateDto {
    /**
     * 
     * @type {VehicleDto}
     * @memberof VehicleActualStateDto
     */
    'vehicle'?: VehicleDto;
    /**
     * 
     * @type {Array<VehicleDamageDto>}
     * @memberof VehicleActualStateDto
     */
    'damages'?: Array<VehicleDamageDto>;
}
/**
 * Describes basic vehicle areas.
 * @export
 * @enum {string}
 */

export const VehicleArea = {
    None: 'None',
    Interior: 'Interior',
    Exterior: 'Exterior',
    Internal: 'Internal'
} as const;

export type VehicleArea = typeof VehicleArea[keyof typeof VehicleArea];


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleBodyType = {
    None: 'None',
    Sedan: 'Sedan',
    Hatchback: 'Hatchback',
    Wagon: 'Wagon',
    Van: 'Van',
    Minivan: 'Minivan',
    Cuv: 'CUV',
    Suv: 'SUV',
    Coupe: 'Coupe',
    Convertible: 'Convertible',
    Roadster: 'Roadster',
    Pickup: 'Pickup',
    ChassisCab: 'ChassisCab',
    PlatformCab: 'PlatformCab',
    PassengerVan: 'PassengerVan',
    DoubleChassisCab: 'DoubleChassisCab',
    DoubleCabPickUp: 'DoubleCabPickUp',
    Microcar: 'Microcar',
    MiniMpv: 'MiniMPV',
    Targa: 'Targa',
    Other: 'Other',
    PlatforbCab: 'PlatforbCab'
} as const;

export type VehicleBodyType = typeof VehicleBodyType[keyof typeof VehicleBodyType];


/**
 * 
 * @export
 * @interface VehicleBodyTypeChangedDto
 */
export interface VehicleBodyTypeChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleBodyTypeChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleBodyTypeChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleBodyTypeChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {VehicleBodyTypeDto}
     * @memberof VehicleBodyTypeChangedDto
     */
    'entity'?: VehicleBodyTypeDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface VehicleBodyTypeDto
 */
export interface VehicleBodyTypeDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleBodyTypeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleBodyTypeDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleBodyTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleBodyTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleBodyTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface VehicleBodyTypeGetPaginatedDto
 */
export interface VehicleBodyTypeGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleBodyTypeGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleBodyTypeGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleBodyTypeGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleBodyTypeGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleBodyTypeGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleBodyTypeGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleBodyTypeGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleBodyTypeGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleBodyTypeGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeGetPaginatedDto
     */
    'vehicleTypeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleBodyTypeGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehicleBodyTypeReferenceDto
 */
export interface VehicleBodyTypeReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleBodyTypeReferenceDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleBodyTypeReferenceDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleBodyTypeReferenceDto
     */
    'isBuiltIn'?: boolean;
}


/**
 * 
 * @export
 * @interface VehicleBodyTypeSearchPaginatedDto
 */
export interface VehicleBodyTypeSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleBodyTypeSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleBodyTypeSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleBodyTypeSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleBodyTypeSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleBodyTypeSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleBodyTypeSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleBodyTypeSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleBodyTypeSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleBodyTypeSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeSearchPaginatedDto
     */
    'vehicleTypeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleBodyTypeSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehicleChangedDto
 */
export interface VehicleChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {VehicleDto}
     * @memberof VehicleChangedDto
     */
    'entity'?: VehicleDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface VehicleCreateManySimilarDto
 */
export interface VehicleCreateManySimilarDto {
    /**
     * 
     * @type {Array<VehicleCreateManySimilarVehicleDto>}
     * @memberof VehicleCreateManySimilarDto
     */
    'vehicles'?: Array<VehicleCreateManySimilarVehicleDto>;
    /**
     * 
     * @type {VehicleCreateManySimilarSharedDataDto}
     * @memberof VehicleCreateManySimilarDto
     */
    'shared'?: VehicleCreateManySimilarSharedDataDto;
    /**
     * 
     * @type {Array<CreateVehicleDto>}
     * @memberof VehicleCreateManySimilarDto
     */
    'createDtos'?: Array<CreateVehicleDto>;
}
/**
 * 
 * @export
 * @interface VehicleCreateManySimilarSharedDataDto
 */
export interface VehicleCreateManySimilarSharedDataDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleCreateManySimilarSharedDataDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleCreateManySimilarSharedDataDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {VehicleSpecInputDto}
     * @memberof VehicleCreateManySimilarSharedDataDto
     */
    'spec'?: VehicleSpecInputDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleCreateManySimilarSharedDataDto
     */
    'accessoryIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof VehicleCreateManySimilarSharedDataDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleCreateManySimilarSharedDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleCreateManySimilarSharedDataDto
     */
    'defaultVisualModelId'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof VehicleCreateManySimilarSharedDataDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface VehicleCreateManySimilarVehicleDto
 */
export interface VehicleCreateManySimilarVehicleDto {
    /**
     * 
     * @type {GeneralPlateNoInputDto}
     * @memberof VehicleCreateManySimilarVehicleDto
     */
    'plateNo'?: GeneralPlateNoInputDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleCreateManySimilarVehicleDto
     */
    'identificationNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleCreateManySimilarVehicleDto
     */
    'mileage'?: number | null;
}
/**
 * 
 * @export
 * @interface VehicleDamageChangedDto
 */
export interface VehicleDamageChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDamageChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleDamageChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleDamageChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {VehicleDamageDto}
     * @memberof VehicleDamageChangedDto
     */
    'entity'?: VehicleDamageDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface VehicleDamageCountersDto
 */
export interface VehicleDamageCountersDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleDamageCountersDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof VehicleDamageCountersDto
     */
    'countByStateMap'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof VehicleDamageCountersDto
     */
    'countByAreaMap'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface VehicleDamageDeletedEventDataDto
 */
export interface VehicleDamageDeletedEventDataDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDeletedEventDataDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDeletedEventDataDto
     */
    'vehicleDamageId'?: string;
}
/**
 * 
 * @export
 * @interface VehicleDamageDetectedEventDataDto
 */
export interface VehicleDamageDetectedEventDataDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDetectedEventDataDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDetectedEventDataDto
     */
    'vehicleDamageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDetectedEventDataDto
     */
    'damageDetectionId'?: string;
}
/**
 * 
 * @export
 * @interface VehicleDamageDto
 */
export interface VehicleDamageDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof VehicleDamageDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof VehicleDamageDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof VehicleDamageDto
     */
    'visualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof VehicleDamageDto
     */
    'oldVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {GeneralEntitySourceDto}
     * @memberof VehicleDamageDto
     */
    'source'?: GeneralEntitySourceDto;
    /**
     * 
     * @type {DamageDetectionReferenceDto}
     * @memberof VehicleDamageDto
     */
    'damageDetection'?: DamageDetectionReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'damageDetectionItemId'?: string;
    /**
     * 
     * @type {VehicleDamageState}
     * @memberof VehicleDamageDto
     */
    'state'?: VehicleDamageState;
    /**
     * 
     * @type {FrontEndAppType}
     * @memberof VehicleDamageDto
     */
    'appType'?: FrontEndAppType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof VehicleDamageDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof VehicleDamageDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof VehicleDamageDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VehicleDamageDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehicleDamageDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof VehicleDamageDto
     */
    'point'?: GeneralSvgPointDto;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof VehicleDamageDto
     */
    'oldPoint'?: GeneralSvgPointDto;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VehicleDamageDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'notes'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'repairedAt'?: string | null;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof VehicleDamageDto
     */
    'repairedBy'?: GeneralByWhoDto;
    /**
     * 
     * @type {RepairOperationReferenceDto}
     * @memberof VehicleDamageDto
     */
    'repairOperation'?: RepairOperationReferenceDto;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof VehicleDamageDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof VehicleDamageDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
}


/**
 * 
 * @export
 * @interface VehicleDamageFullReferenceDto
 */
export interface VehicleDamageFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'visualModelId'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof VehicleDamageFullReferenceDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof VehicleDamageFullReferenceDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof VehicleDamageFullReferenceDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VehicleDamageFullReferenceDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehicleDamageFullReferenceDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof VehicleDamageFullReferenceDto
     */
    'point'?: GeneralSvgPointDto;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VehicleDamageFullReferenceDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
}


/**
 * 
 * @export
 * @interface VehicleDamageGetCountersDto
 */
export interface VehicleDamageGetCountersDto {
    /**
     * 
     * @type {DateRangeInputDto}
     * @memberof VehicleDamageGetCountersDto
     */
    'range'?: DateRangeInputDto;
}
/**
 * 
 * @export
 * @interface VehicleDamageGetPaginatedDto
 */
export interface VehicleDamageGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleDamageGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDamageGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDamageGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDamageGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDamageGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleDamageGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleDamageGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleDamageGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleDamageState}
     * @memberof VehicleDamageGetPaginatedDto
     */
    'state'?: VehicleDamageState;
}


/**
 * 
 * @export
 * @interface VehicleDamageMigrateToNewVisualModelDto
 */
export interface VehicleDamageMigrateToNewVisualModelDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageMigrateToNewVisualModelDto
     */
    'oldVehicleVisualModelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageMigrateToNewVisualModelDto
     */
    'newVehicleVisualModelId'?: string;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof VehicleDamageMigrateToNewVisualModelDto
     */
    'oldPoint'?: GeneralSvgPointDto;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof VehicleDamageMigrateToNewVisualModelDto
     */
    'newPoint'?: GeneralSvgPointDto;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleDamageMigrateToNewVisualModelDto
     */
    'isMigrateDamageDetection'?: boolean;
}
/**
 * 
 * @export
 * @interface VehicleDamageReferenceDto
 */
export interface VehicleDamageReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageReferenceDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface VehicleDamageRepairedEventDataDto
 */
export interface VehicleDamageRepairedEventDataDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageRepairedEventDataDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageRepairedEventDataDto
     */
    'vehicleDamageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageRepairedEventDataDto
     */
    'repairOperationId'?: string;
}
/**
 * 
 * @export
 * @interface VehicleDamageSearchPaginatedDto
 */
export interface VehicleDamageSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleDamageSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDamageSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDamageSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDamageSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDamageSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleDamageSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleDamageSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleDamageSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleDamageState}
     * @memberof VehicleDamageSearchPaginatedDto
     */
    'state'?: VehicleDamageState;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleDamageState = {
    None: 'None',
    Actual: 'Actual',
    Repaired: 'Repaired'
} as const;

export type VehicleDamageState = typeof VehicleDamageState[keyof typeof VehicleDamageState];


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleDrivetrainType = {
    None: 'None',
    Fwd: 'FWD',
    Rwd: 'RWD',
    Awd: 'AWD',
    FourWd: 'FourWD'
} as const;

export type VehicleDrivetrainType = typeof VehicleDrivetrainType[keyof typeof VehicleDrivetrainType];


/**
 * 
 * @export
 * @interface VehicleDto
 */
export interface VehicleDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'ownerTenantId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {ContractFullReferenceDto}
     * @memberof VehicleDto
     */
    'lastContract'?: ContractFullReferenceDto;
    /**
     * 
     * @type {VehicleSpecDto}
     * @memberof VehicleDto
     */
    'spec'?: VehicleSpecDto;
    /**
     * 
     * @type {Array<AccessoryReferenceDto>}
     * @memberof VehicleDto
     */
    'accessories'?: Array<AccessoryReferenceDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VehicleDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof VehicleDto
     */
    'defaultVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'identificationNumber'?: string | null;
    /**
     * 
     * @type {GeneralPlateNoDto}
     * @memberof VehicleDto
     */
    'plateNo'?: GeneralPlateNoDto;
    /**
     * 
     * @type {number}
     * @memberof VehicleDto
     */
    'mileage'?: number;
    /**
     * 
     * @type {object}
     * @memberof VehicleDto
     */
    'car'?: object;
    /**
     * 
     * @type {number}
     * @memberof VehicleDto
     */
    'totalDamagesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDto
     */
    'actualDamagesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDto
     */
    'repairedDamagesCount'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDto
     */
    'lastVisualInspectionAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDto
     */
    'lastDamageDetectionAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDto
     */
    'lastAccessoryCheckAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDto
     */
    'lastMileageUpdatedAt'?: string | null;
    /**
     * 
     * @type {PoolsMetaDto}
     * @memberof VehicleDto
     */
    'poolsMeta'?: PoolsMetaDto;
    /**
     * 
     * @type {AssetMetaDto}
     * @memberof VehicleDto
     */
    'assetMeta'?: AssetMetaDto;
    /**
     * 
     * @type {EntityImportMetaDto}
     * @memberof VehicleDto
     */
    'importMeta'?: EntityImportMetaDto;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof VehicleDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof VehicleDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof VehicleDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {{ [key: string]: boolean | null; }}
     * @memberof VehicleDto
     */
    'departmentIdsMap'?: { [key: string]: boolean | null; };
    /**
     * 
     * @type {{ [key: string]: boolean | null; }}
     * @memberof VehicleDto
     */
    'locationIdsMap'?: { [key: string]: boolean | null; };
}
/**
 * 
 * @export
 * @interface VehicleFuelConsumptionDto
 */
export interface VehicleFuelConsumptionDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelConsumptionDto
     */
    'lPer100Km'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelConsumptionDto
     */
    'lPerKm'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelConsumptionDto
     */
    'lPer10Km'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelConsumptionDto
     */
    'kmPerL'?: number;
    /**
     * 
     * @type {VehicleFuelConsumptionImperial}
     * @memberof VehicleFuelConsumptionDto
     */
    'uk'?: VehicleFuelConsumptionImperial;
    /**
     * 
     * @type {VehicleFuelConsumptionImperial}
     * @memberof VehicleFuelConsumptionDto
     */
    'us'?: VehicleFuelConsumptionImperial;
}
/**
 * For UK and US in imperial units.  NB: UK gallon differs from US gallon.
 * @export
 * @interface VehicleFuelConsumptionImperial
 */
export interface VehicleFuelConsumptionImperial {
    /**
     * 
     * @type {MeasurementSystemType}
     * @memberof VehicleFuelConsumptionImperial
     */
    'type'?: MeasurementSystemType;
    /**
     * Aka MPG (Miles per gallon).
     * @type {number}
     * @memberof VehicleFuelConsumptionImperial
     */
    'miPerGal'?: number;
    /**
     * The opposite of MGP.
     * @type {number}
     * @memberof VehicleFuelConsumptionImperial
     */
    'galPerMi'?: number;
}


/**
 * 
 * @export
 * @interface VehicleFuelConsumptionInputDto
 */
export interface VehicleFuelConsumptionInputDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelConsumptionInputDto
     */
    'lPer100Km'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleFuelType = {
    None: 'None',
    Gasoline: 'Gasoline',
    Diesel: 'Diesel',
    Biodiesel: 'Biodiesel',
    Cng: 'CNG',
    Lpg: 'LPG',
    Alcohol: 'Alcohol',
    Ethanol: 'Ethanol',
    Methanol: 'Methanol',
    E85: 'E85',
    Mhev: 'MHEV',
    Hev: 'HEV',
    Phev: 'PHEV',
    Bev: 'BEV',
    Reev: 'REEV',
    Fcev: 'FCEV',
    Hydrogen: 'Hydrogen',
    Other: 'Other'
} as const;

export type VehicleFuelType = typeof VehicleFuelType[keyof typeof VehicleFuelType];


/**
 * 
 * @export
 * @interface VehicleFuelTypeChangedDto
 */
export interface VehicleFuelTypeChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleFuelTypeChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleFuelTypeChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleFuelTypeChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {VehicleFuelTypeDto}
     * @memberof VehicleFuelTypeChangedDto
     */
    'entity'?: VehicleFuelTypeDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface VehicleFuelTypeDto
 */
export interface VehicleFuelTypeDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleFuelTypeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleFuelType}
     * @memberof VehicleFuelTypeDto
     */
    'fuelType'?: VehicleFuelType;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleFuelTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleFuelTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface VehicleFuelTypeGetPaginatedDto
 */
export interface VehicleFuelTypeGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelTypeGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelTypeGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleFuelTypeGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleFuelTypeGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleFuelTypeGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleFuelTypeGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleFuelTypeGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleFuelTypeGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleFuelTypeGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeGetPaginatedDto
     */
    'vehicleTypeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleFuelTypeGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehicleFuelTypeReferenceDto
 */
export interface VehicleFuelTypeReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleFuelTypeReferenceDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleFuelType}
     * @memberof VehicleFuelTypeReferenceDto
     */
    'fuelType'?: VehicleFuelType;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleFuelTypeReferenceDto
     */
    'isBuiltIn'?: boolean;
}


/**
 * 
 * @export
 * @interface VehicleFuelTypeSearchPaginatedDto
 */
export interface VehicleFuelTypeSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelTypeSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelTypeSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleFuelTypeSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleFuelTypeSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleFuelTypeSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleFuelTypeSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleFuelTypeSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleFuelTypeSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleFuelTypeSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeSearchPaginatedDto
     */
    'vehicleTypeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleFuelTypeSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehicleFullReferenceDto
 */
export interface VehicleFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'ownerTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {ContractFullReferenceDto}
     * @memberof VehicleFullReferenceDto
     */
    'lastContract'?: ContractFullReferenceDto;
    /**
     * 
     * @type {VehicleSpecDto}
     * @memberof VehicleFullReferenceDto
     */
    'spec'?: VehicleSpecDto;
    /**
     * 
     * @type {DepartmentReferenceDto}
     * @memberof VehicleFullReferenceDto
     */
    'department'?: DepartmentReferenceDto;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof VehicleFullReferenceDto
     */
    'location'?: LocationReferenceDto;
    /**
     * 
     * @type {Array<AccessoryReferenceDto>}
     * @memberof VehicleFullReferenceDto
     */
    'accessories'?: Array<AccessoryReferenceDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VehicleFullReferenceDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof VehicleFullReferenceDto
     */
    'defaultVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'identificationNumber'?: string | null;
    /**
     * 
     * @type {GeneralPlateNoDto}
     * @memberof VehicleFullReferenceDto
     */
    'plateNo'?: GeneralPlateNoDto;
    /**
     * 
     * @type {number}
     * @memberof VehicleFullReferenceDto
     */
    'mileage'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleFullReferenceDto
     */
    'manufactureYear'?: number | null;
    /**
     * 
     * @type {object}
     * @memberof VehicleFullReferenceDto
     */
    'car'?: object;
    /**
     * 
     * @type {number}
     * @memberof VehicleFullReferenceDto
     */
    'actualDamagesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleFullReferenceDto
     */
    'repairedDamagesCount'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'lastDamageDetectionAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'lastAccessoryCheckAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'lastMileageUpdatedAt'?: string | null;
    /**
     * 
     * @type {PoolsMetaDto}
     * @memberof VehicleFullReferenceDto
     */
    'poolsMeta'?: PoolsMetaDto;
    /**
     * 
     * @type {AssetMetaDto}
     * @memberof VehicleFullReferenceDto
     */
    'assetMeta'?: AssetMetaDto;
    /**
     * 
     * @type {EntityImportMetaDto}
     * @memberof VehicleFullReferenceDto
     */
    'importMeta'?: EntityImportMetaDto;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof VehicleFullReferenceDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof VehicleFullReferenceDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {VehicleSize}
     * @memberof VehicleFullReferenceDto
     */
    'vehicleSize'?: VehicleSize;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleGearboxType = {
    None: 'None',
    Manual: 'Manual',
    Automatic: 'Automatic',
    Amt: 'AMT',
    DualClutch: 'DualClutch',
    TorqueConverter: 'TorqueConverter',
    Cvt: 'CVT'
} as const;

export type VehicleGearboxType = typeof VehicleGearboxType[keyof typeof VehicleGearboxType];


/**
 * 
 * @export
 * @interface VehicleGenerationChangedDto
 */
export interface VehicleGenerationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGenerationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleGenerationChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleGenerationChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {VehicleGenerationDto}
     * @memberof VehicleGenerationChangedDto
     */
    'entity'?: VehicleGenerationDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface VehicleGenerationDto
 */
export interface VehicleGenerationDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleGenerationDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof VehicleGenerationDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof VehicleGenerationDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleGenerationDto
     */
    'startYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleGenerationDto
     */
    'endYear'?: number | null;
    /**
     * 
     * @type {VehicleSize}
     * @memberof VehicleGenerationDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleGenerationDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleGenerationDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleGenerationDto
     */
    'vehicleType'?: VehicleType;
}


/**
 * 
 * @export
 * @interface VehicleGenerationGetPaginatedDto
 */
export interface VehicleGenerationGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'modelIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleGenerationGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehicleGenerationReferenceDto
 */
export interface VehicleGenerationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleGenerationReferenceDto
     */
    'isBuiltIn'?: boolean;
}
/**
 * 
 * @export
 * @interface VehicleGenerationSearchPaginatedDto
 */
export interface VehicleGenerationSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'modelIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleGenerationSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehicleGetPaginatedDto
 */
export interface VehicleGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleGetPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleGetPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleGetPaginatedDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleGetPaginatedDto
     */
    'types'?: Array<VehicleType> | null;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleGetPaginatedDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {VehicleFuelType}
     * @memberof VehicleGetPaginatedDto
     */
    'fuelType'?: VehicleFuelType;
    /**
     * 
     * @type {OptionalClosedIntervalDtoOfDouble}
     * @memberof VehicleGetPaginatedDto
     */
    'mileageRange'?: OptionalClosedIntervalDtoOfDouble;
    /**
     * 
     * @type {number}
     * @memberof VehicleGetPaginatedDto
     */
    'manufactureYear'?: number | null;
    /**
     * 
     * @type {OptionalClosedIntervalDtoOfInt32}
     * @memberof VehicleGetPaginatedDto
     */
    'manufactureYearRange'?: OptionalClosedIntervalDtoOfInt32;
    /**
     * 
     * @type {PoolsMetaQueryDto}
     * @memberof VehicleGetPaginatedDto
     */
    'poolsMeta'?: PoolsMetaQueryDto;
    /**
     * 
     * @type {AssetMetaQueryDto}
     * @memberof VehicleGetPaginatedDto
     */
    'assetMeta'?: AssetMetaQueryDto;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof VehicleGetPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof VehicleGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleGetPaginatedDto
     */
    'isPreparedForCheckOut'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleGetPaginatedDto
     */
    'isPreparedForCheckIn'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehicleHistoryGetPaginatedDto
 */
export interface VehicleHistoryGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleHistoryGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleHistoryGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleHistoryGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleHistoryGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleHistoryGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleHistoryGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleHistoryGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleHistoryGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {Array<VehicleHistoryItemType>}
     * @memberof VehicleHistoryGetPaginatedDto
     */
    'types'?: Array<VehicleHistoryItemType> | null;
}
/**
 * 
 * @export
 * @interface VehicleHistoryItemChangedDto
 */
export interface VehicleHistoryItemChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleHistoryItemChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleHistoryItemChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleHistoryItemChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {VehicleHistoryItemDto}
     * @memberof VehicleHistoryItemChangedDto
     */
    'entity'?: VehicleHistoryItemDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface VehicleHistoryItemDto
 */
export interface VehicleHistoryItemDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof VehicleHistoryItemDto
     */
    'who'?: GeneralByWhoDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleHistoryItemDto
     */
    'date'?: string;
    /**
     * 
     * @type {VehicleHistoryItemType}
     * @memberof VehicleHistoryItemDto
     */
    'type'?: VehicleHistoryItemType;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof VehicleHistoryItemDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {MileageChangedDataDto}
     * @memberof VehicleHistoryItemDto
     */
    'mileageChanged'?: MileageChangedDataDto;
    /**
     * 
     * @type {DamageDetectedDataDto}
     * @memberof VehicleHistoryItemDto
     */
    'damageDetected'?: DamageDetectedDataDto;
    /**
     * 
     * @type {DamageRepairedDataDto}
     * @memberof VehicleHistoryItemDto
     */
    'damageRepaired'?: DamageRepairedDataDto;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof VehicleHistoryItemDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
}


/**
 * 
 * @export
 * @interface VehicleHistoryItemReferenceDto
 */
export interface VehicleHistoryItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemReferenceDto
     */
    'vehicleId'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleHistoryItemReferenceDto
     */
    'date'?: string;
    /**
     * 
     * @type {VehicleHistoryItemType}
     * @memberof VehicleHistoryItemReferenceDto
     */
    'type'?: VehicleHistoryItemType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleHistoryItemType = {
    None: 'None',
    MileageChanged: 'MileageChanged',
    DamageDetected: 'DamageDetected',
    DamageRepaired: 'DamageRepaired',
    DamageDeleted: 'DamageDeleted'
} as const;

export type VehicleHistoryItemType = typeof VehicleHistoryItemType[keyof typeof VehicleHistoryItemType];


/**
 * 
 * @export
 * @interface VehicleMakeChangedDto
 */
export interface VehicleMakeChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleMakeChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleMakeChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleMakeChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {VehicleMakeDto}
     * @memberof VehicleMakeChangedDto
     */
    'entity'?: VehicleMakeDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface VehicleMakeDto
 */
export interface VehicleMakeDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleMakeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleMakeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleMakeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleMakeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface VehicleMakeGetPaginatedDto
 */
export interface VehicleMakeGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleMakeGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleMakeGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleMakeGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleMakeGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleMakeGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleMakeGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleMakeGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleMakeGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleMakeGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleMakeGetPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleMakeGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehicleMakeReferenceDto
 */
export interface VehicleMakeReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleMakeReferenceDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeReferenceDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleMakeReferenceDto
     */
    'isBuiltIn'?: boolean;
}


/**
 * 
 * @export
 * @interface VehicleMakeSearchPaginatedDto
 */
export interface VehicleMakeSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleMakeSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleMakeSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleMakeSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleMakeSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleMakeSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleMakeSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleMakeSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleMakeSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleMakeSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleMakeSearchPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleMakeSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehicleMileageChangedEventDataDto
 */
export interface VehicleMileageChangedEventDataDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleMileageChangedEventDataDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleMileageChangedEventDataDto
     */
    'visualInspectionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleMileageChangedEventDataDto
     */
    'damageDetectionId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleMileageChangedEventDataDto
     */
    'oldMileage'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleMileageChangedEventDataDto
     */
    'newMileage'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleMileageChangedEventDataDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface VehicleModelChangedDto
 */
export interface VehicleModelChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModelChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleModelChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleModelChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {VehicleModelDto}
     * @memberof VehicleModelChangedDto
     */
    'entity'?: VehicleModelDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface VehicleModelDto
 */
export interface VehicleModelDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleModelDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleModelDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelDto
     */
    'name'?: string;
    /**
     * 
     * @type {VehicleSize}
     * @memberof VehicleModelDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleModelDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModelDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof VehicleModelDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModelDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleModelDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface VehicleModelGetPaginatedDto
 */
export interface VehicleModelGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleModelGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleModelGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModelGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModelGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModelGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleModelGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleModelGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleModelGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleModelGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleModelGetPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelGetPaginatedDto
     */
    'vehicleTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelGetPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModelGetPaginatedDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModelGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehicleModelReferenceDto
 */
export interface VehicleModelReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleModelReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModelReferenceDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {VehicleSize}
     * @memberof VehicleModelReferenceDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof VehicleModelReferenceDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleModelReferenceDto
     */
    'vehicleType'?: VehicleType;
}


/**
 * 
 * @export
 * @interface VehicleModelSearchPaginatedDto
 */
export interface VehicleModelSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'vehicleTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModelSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehicleModificationChangedDto
 */
export interface VehicleModificationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleModificationChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleModificationChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {VehicleModificationDto}
     * @memberof VehicleModificationChangedDto
     */
    'entity'?: VehicleModificationDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface VehicleModificationDto
 */
export interface VehicleModificationDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof VehicleModificationDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof VehicleModificationDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {VehicleGenerationReferenceDto}
     * @memberof VehicleModificationDto
     */
    'generation'?: VehicleGenerationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationDto
     */
    'name'?: string;
    /**
     * 
     * @type {VehicleFuelTypeReferenceDto}
     * @memberof VehicleModificationDto
     */
    'fuelType'?: VehicleFuelTypeReferenceDto;
    /**
     * 
     * @type {VehicleBodyTypeReferenceDto}
     * @memberof VehicleModificationDto
     */
    'bodyType'?: VehicleBodyTypeReferenceDto;
    /**
     * 
     * @type {VehicleSize}
     * @memberof VehicleModificationDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModificationDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModificationDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleModificationDto
     */
    'vehicleType'?: VehicleType;
}


/**
 * 
 * @export
 * @interface VehicleModificationGetPaginatedDto
 */
export interface VehicleModificationGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'modelIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'generationIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModificationGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehicleModificationReferenceDto
 */
export interface VehicleModificationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModificationReferenceDto
     */
    'isBuiltIn'?: boolean;
}
/**
 * 
 * @export
 * @interface VehicleModificationSearchPaginatedDto
 */
export interface VehicleModificationSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'makeIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'modelIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'generationIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModificationSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * Describes general/basic categories of vehicle parts.   Vehicle part should belong to one of these categories for correct mapping and suggestions.   For instance, when mapping parts to damages and repair methods.
 * @export
 * @enum {string}
 */

export const VehiclePartCategory = {
    None: 'None',
    Body: 'Body',
    Wheels: 'Wheels',
    Glass: 'Glass',
    Lighting: 'Lighting',
    ExteriorAccessories: 'ExteriorAccessories',
    Seats: 'Seats',
    AirVentilation: 'AirVentilation',
    Dashboard: 'Dashboard',
    Upholstery: 'Upholstery',
    SteeringWheel: 'SteeringWheel',
    InteriorLighting: 'InteriorLighting',
    InteriorControls: 'InteriorControls',
    AudioSystem: 'AudioSystem',
    InteriorAccessories: 'InteriorAccessories'
} as const;

export type VehiclePartCategory = typeof VehiclePartCategory[keyof typeof VehiclePartCategory];


/**
 * Describes the final vehicle part.  It\'s not an identifier as multiple parts may have the same descriptor.
 * @export
 * @enum {string}
 */

export const VehiclePartDescriptor = {
    None: 'None',
    LicencePlateFront: 'LicencePlate_Front',
    LicencePlateRear: 'LicencePlate_Rear',
    LicencePlateLightFront: 'LicencePlateLight_Front',
    LicencePlateLightRear: 'LicencePlateLight_Rear',
    BumperFront: 'Bumper_Front',
    BumperRear: 'Bumper_Rear',
    HeadlightLeft: 'Headlight_Left',
    HeadlightRight: 'Headlight_Right',
    TailLightLeft: 'TailLight_Left',
    TailLightRight: 'TailLight_Right',
    FogLightFrontLeft: 'FogLight_Front_Left',
    FogLightFrontRight: 'FogLight_Front_Right',
    FogLightRearLeft: 'FogLight_Rear_Left',
    FogLightRearRight: 'FogLight_Rear_Right',
    TurnSignalFrontLeft: 'TurnSignal_Front_Left',
    TurnSignalFrontRight: 'TurnSignal_Front_Right',
    TurnSignalRearLeft: 'TurnSignal_Rear_Left',
    TurnSignalRearRight: 'TurnSignal_Rear_Right',
    TurnSignalSideLeft: 'TurnSignal_Side_Left',
    TurnSignalSideRight: 'TurnSignal_Side_Right',
    FenderFront: 'Fender_Front',
    FenderRear: 'Fender_Rear',
    FenderFrontLeft: 'Fender_Front_Left',
    FenderFrontRight: 'Fender_Front_Right',
    FenderRearLeft: 'Fender_Rear_Left',
    FenderRearRight: 'Fender_Rear_Right',
    FenderLinerFront: 'FenderLiner_Front',
    FenderLinerRear: 'FenderLiner_Rear',
    FenderLinerFrontLeft: 'FenderLiner_Front_Left',
    FenderLinerFrontRight: 'FenderLiner_Front_Right',
    FenderLinerRearLeft: 'FenderLiner_Rear_Left',
    FenderLinerRearRight: 'FenderLiner_Rear_Right',
    SideMirrorLeft: 'SideMirror_Left',
    SideMirrorRight: 'SideMirror_Right',
    DoorFront: 'Door_Front',
    DoorRear: 'Door_Rear',
    DoorFrontLeft: 'Door_Front_Left',
    DoorFrontRight: 'Door_Front_Right',
    DoorRearLeft: 'Door_Rear_Left',
    DoorRearRight: 'Door_Rear_Right',
    DoorHandleFront: 'DoorHandle_Front',
    DoorHandleRear: 'DoorHandle_Rear',
    DoorHandleFrontLeft: 'DoorHandle_Front_Left',
    DoorHandleFrontRight: 'DoorHandle_Front_Right',
    DoorHandleRearLeft: 'DoorHandle_Rear_Left',
    DoorHandleRearRight: 'DoorHandle_Rear_Right',
    DoorGlassFront: 'DoorGlass_Front',
    DoorGlassRear: 'DoorGlass_Rear',
    DoorGlassFrontLeft: 'DoorGlass_Front_Left',
    DoorGlassFrontRight: 'DoorGlass_Front_Right',
    DoorGlassRearLeft: 'DoorGlass_Rear_Left',
    DoorGlassRearRight: 'DoorGlass_Rear_Right',
    QuarterGlassFront: 'QuarterGlass_Front',
    QuarterGlassRear: 'QuarterGlass_Rear',
    QuarterGlassFrontLeft: 'QuarterGlass_Front_Left',
    QuarterGlassFrontRight: 'QuarterGlass_Front_Right',
    QuarterGlassRearLeft: 'QuarterGlass_Rear_Left',
    QuarterGlassRearRight: 'QuarterGlass_Rear_Right',
    DoorLockFront: 'DoorLock_Front',
    DoorLockRear: 'DoorLock_Rear',
    DoorLockFrontLeft: 'DoorLock_Front_Left',
    DoorLockFrontRight: 'DoorLock_Front_Right',
    DoorLockRearLeft: 'DoorLock_Rear_Left',
    DoorLockRearRight: 'DoorLock_Rear_Right',
    SeatFrontLeft: 'Seat_Front_Left',
    SeatFrontRight: 'Seat_Front_Right',
    SeatRearLeft: 'Seat_Rear_Left',
    SeatRearRight: 'Seat_Rear_Right',
    SeatRearMiddle: 'Seat_Rear_Middle',
    SeatThirdRowLeft: 'Seat_ThirdRow_Left',
    SeatThirdRowMiddle: 'Seat_ThirdRow_Middle',
    SeatThirdRowRight: 'Seat_ThirdRow_Right',
    HeadrestFrontLeft: 'Headrest_Front_Left',
    HeadrestFrontRight: 'Headrest_Front_Right',
    HeadrestRearLeft: 'Headrest_Rear_Left',
    HeadrestRearRight: 'Headrest_Rear_Right',
    HeadrestRearMiddle: 'Headrest_Rear_Middle',
    SeatAdjustmentControlsFrontLeft: 'SeatAdjustmentControls_Front_Left',
    SeatAdjustmentControlsFrontRight: 'SeatAdjustmentControls_Front_Right',
    SeatFoldingMechanismRear: 'SeatFoldingMechanism_Rear',
    SeatBeltFrontLeft: 'SeatBelt_Front_Left',
    SeatBeltFrontRight: 'SeatBelt_Front_Right',
    SeatBeltRearLeft: 'SeatBelt_Rear_Left',
    SeatBeltRearRight: 'SeatBelt_Rear_Right',
    SeatBeltRearMiddle: 'SeatBelt_Rear_Middle',
    SeatBeltBuckleFrontLeft: 'SeatBeltBuckle_Front_Left',
    SeatBeltBuckleFrontRight: 'SeatBeltBuckle_Front_Right',
    SeatBeltBuckleRearLeft: 'SeatBeltBuckle_Rear_Left',
    SeatBeltBuckleRearRight: 'SeatBeltBuckle_Rear_Right',
    SeatBeltBuckleRearMiddle: 'SeatBeltBuckle_Rear_Middle',
    SeatBeltBuckleThirdRowLeft: 'SeatBeltBuckle_ThirdRow_Left',
    SeatBeltBuckleThirdRowMiddle: 'SeatBeltBuckle_ThirdRow_Middle',
    SeatBeltBuckleThirdRowRight: 'SeatBeltBuckle_ThirdRow_Right',
    FloorMatFrontLeft: 'FloorMat_Front_Left',
    FloorMatFrontRight: 'FloorMat_Front_Right',
    FloorMatRearLeft: 'FloorMat_Rear_Left',
    FloorMatRearRight: 'FloorMat_Rear_Right',
    FloorMatRearMiddle: 'FloorMat_Rear_Middle',
    FloorMatTrunk: 'FloorMat_Trunk',
    MatCoverTrunk: 'MatCover_Trunk',
    DoorPanelFrontLeft: 'DoorPanel_Front_Left',
    DoorPanelFrontRight: 'DoorPanel_Front_Right',
    DoorPanelRearLeft: 'DoorPanel_Rear_Left',
    DoorPanelRearRight: 'DoorPanel_Rear_Right',
    DoorSwitchPanelFrontLeft: 'DoorSwitchPanel_Front_Left',
    DoorSwitchPanelFrontRight: 'DoorSwitchPanel_Front_Right',
    DoorSwitchPanelRearLeft: 'DoorSwitchPanel_Rear_Left',
    DoorSwitchPanelRearRight: 'DoorSwitchPanel_Rear_Right',
    AirVentilationFrontLeft: 'AirVentilation_Front_Left',
    AirVentilationFrontRight: 'AirVentilation_Front_Right',
    AirVentilationFrontMiddle: 'AirVentilation_Front_Middle',
    AirVentilationRearMiddle: 'AirVentilation_Rear_Middle',
    ArmrestFront: 'Armrest_Front',
    ArmrestRear: 'Armrest_Rear',
    SunVisorLeft: 'SunVisor_Left',
    SunVisorRight: 'SunVisor_Right',
    SunVisorLightingLeft: 'SunVisorLighting_Left',
    SunVisorLightingRight: 'SunVisorLighting_Right',
    InteriorDoorHandleLightingFrontLeft: 'InteriorDoorHandleLighting_Front_Left',
    InteriorDoorHandleLightingFrontRight: 'InteriorDoorHandleLighting_Front_Right',
    InteriorDoorHandleLightingRearLeft: 'InteriorDoorHandleLighting_Rear_Left',
    InteriorDoorHandleLightingRearRight: 'InteriorDoorHandleLighting_Rear_Right',
    DoorPanelLightingFrontLeft: 'DoorPanelLighting_Front_Left',
    DoorPanelLightingFrontRight: 'DoorPanelLighting_Front_Right',
    DoorPanelLightingRearLeft: 'DoorPanelLighting_Rear_Left',
    DoorPanelLightingRearRight: 'DoorPanelLighting_Rear_Right',
    SpeakerFront: 'Speaker_Front',
    SpeakerRear: 'Speaker_Rear',
    SpeakerTrunk: 'Speaker_Trunk'
} as const;

export type VehiclePartDescriptor = typeof VehiclePartDescriptor[keyof typeof VehiclePartDescriptor];


/**
 * Further detalization of Domain.Enums.Vehicle.VehiclePartCategory.  Describes general types of vehicle parts.
 * @export
 * @enum {string}
 */

export const VehiclePartType = {
    None: 'None',
    LicencePlate: 'LicencePlate',
    LicencePlateLight: 'LicencePlateLight',
    MarkerLight: 'MarkerLight',
    WindowFrame: 'WindowFrame',
    Bumper: 'Bumper',
    Grille: 'Grille',
    Headlight: 'Headlight',
    TailLight: 'TailLight',
    FogLight: 'FogLight',
    TurnSignal: 'TurnSignal',
    Hood: 'Hood',
    CowlPanel: 'CowlPanel',
    Windshield: 'Windshield',
    WindshieldWiper: 'WindshieldWiper',
    RearWindshield: 'RearWindshield',
    RearWindshieldWiper: 'RearWindshieldWiper',
    Wheel: 'Wheel',
    Tire: 'Tire',
    Rim: 'Rim',
    Hubcap: 'Hubcap',
    Fender: 'Fender',
    FenderLiner: 'FenderLiner',
    SideMirror: 'SideMirror',
    Door: 'Door',
    TrunkLid: 'TrunkLid',
    DoorHandle: 'DoorHandle',
    DoorGlass: 'DoorGlass',
    QuarterGlass: 'QuarterGlass',
    DoorLock: 'DoorLock',
    FuelTankLid: 'FuelTankLid',
    FuelTankCap: 'FuelTankCap',
    SideSkirt: 'SideSkirt',
    RockerPanel: 'RockerPanel',
    RockerPanelCover: 'RockerPanelCover',
    ExhaustPipeTip: 'ExhaustPipeTip',
    Spoiler: 'Spoiler',
    Roof: 'Roof',
    RoofMarkerLight: 'RoofMarkerLight',
    RoofHatch: 'RoofHatch',
    GlassRoofHatch: 'GlassRoofHatch',
    RoofHatchGlass: 'RoofHatchGlass',
    RoofRack: 'RoofRack',
    RoofRail: 'RoofRail',
    Seat: 'Seat',
    Headrest: 'Headrest',
    SeatAdjustmentControls: 'SeatAdjustmentControls',
    SeatFoldingMechanism: 'SeatFoldingMechanism',
    SeatBelt: 'SeatBelt',
    SeatBeltBuckle: 'SeatBeltBuckle',
    DoorPanel: 'DoorPanel',
    DoorSwitchPanel: 'DoorSwitchPanel',
    AirVentilation: 'AirVentilation',
    ClimateControl: 'ClimateControl',
    GloveCompartment: 'GloveCompartment',
    CentralDisplay: 'CentralDisplay',
    LighterSocket: 'LighterSocket',
    Speedometer: 'Speedometer',
    Tachometer: 'Tachometer',
    FuelGauge: 'FuelGauge',
    TemperatureGauge: 'TemperatureGauge',
    SteeringWheel: 'SteeringWheel',
    SteeringWheelControls: 'SteeringWheelControls',
    Horn: 'Horn',
    InteriorLightingControls: 'InteriorLightingControls',
    OverheadLighting: 'OverheadLighting',
    SunVisorLighting: 'SunVisorLighting',
    AmbientLighting: 'AmbientLighting',
    FootwellLighting: 'FootwellLighting',
    InteriorDoorHandleLighting: 'InteriorDoorHandleLighting',
    DoorPanelLighting: 'DoorPanelLighting',
    CupHolderLighting: 'CupHolderLighting',
    DashboardLighting: 'DashboardLighting',
    InteriorRoofLighting: 'InteriorRoofLighting',
    InteriorTrunkLighting: 'InteriorTrunkLighting',
    RoofLiner: 'RoofLiner',
    IgnitionSwitch: 'IgnitionSwitch',
    IgnitionKey: 'IgnitionKey',
    ParkingBrake: 'ParkingBrake',
    ElectronicParkingBrake: 'ElectronicParkingBrake',
    GearStick: 'GearStick',
    GearSelector: 'GearSelector',
    AcceleratorPedal: 'AcceleratorPedal',
    BreakPedal: 'BreakPedal',
    ClutchPedal: 'ClutchPedal',
    WindshieldWiperControl: 'WindshieldWiperControl',
    FloorMat: 'FloorMat',
    MatCover: 'MatCover',
    Armrest: 'Armrest',
    SunVisor: 'SunVisor',
    RearViewMirror: 'RearViewMirror',
    Navigator: 'Navigator',
    ProjectionNavigator: 'ProjectionNavigator',
    VideoRecorder: 'VideoRecorder',
    AudioSystem: 'AudioSystem',
    Speaker: 'Speaker',
    TrunkOrganizer: 'TrunkOrganizer',
    TrunkDivider: 'TrunkDivider',
    TrunkHiddenStorage: 'TrunkHiddenStorage',
    TrunkNet: 'TrunkNet',
    TrunkLiner: 'TrunkLiner',
    TireRepairKit: 'TireRepairKit',
    TireInflator: 'TireInflator',
    SpareWheel: 'SpareWheel',
    Jack: 'Jack'
} as const;

export type VehiclePartType = typeof VehiclePartType[keyof typeof VehiclePartType];


/**
 * 
 * @export
 * @interface VehiclePartTypeChangedDto
 */
export interface VehiclePartTypeChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehiclePartTypeChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehiclePartTypeChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehiclePartTypeChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {VehiclePartTypeDto}
     * @memberof VehiclePartTypeChangedDto
     */
    'entity'?: VehiclePartTypeDto;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface VehiclePartTypeDto
 */
export interface VehiclePartTypeDto {
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehiclePartTypeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof VehiclePartTypeDto
     */
    'category'?: VehiclePartCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof VehiclePartTypeDto
     */
    'type'?: VehiclePartType;
    /**
     * 
     * @type {VehiclePartDescriptor}
     * @memberof VehiclePartTypeDto
     */
    'descriptor'?: VehiclePartDescriptor;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof VehiclePartTypeDto
     */
    'areas'?: Array<VehicleArea>;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehiclePartTypeDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehiclePartTypeDto
     */
    'projections'?: Array<VehicleProjection>;
    /**
     * 
     * @type {{ [key: string]: Array<VehicleProjection>; }}
     * @memberof VehiclePartTypeDto
     */
    'projectionsPerArea'?: { [key: string]: Array<VehicleProjection>; };
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeDto
     */
    'isCategoryRoot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeDto
     */
    'isTypeRoot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehiclePartTypeDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeDto
     */
    'isEnabledForDamageDetection'?: boolean;
    /**
     * 
     * @type {Array<DamageTypeCategory>}
     * @memberof VehiclePartTypeDto
     */
    'damageCategories'?: Array<DamageTypeCategory> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehiclePartTypeDto
     */
    'damageTypeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VehiclePartTypeDto
     */
    'examplePictures'?: Array<GeneralAttachmentDto>;
}


/**
 * 
 * @export
 * @interface VehiclePartTypeGetPaginatedDto
 */
export interface VehiclePartTypeGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'category'?: VehiclePartCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'type'?: VehiclePartType;
    /**
     * 
     * @type {Array<VehiclePartType>}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'types'?: Array<VehiclePartType> | null;
    /**
     * 
     * @type {VehiclePartDescriptor}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'descriptor'?: VehiclePartDescriptor;
    /**
     * 
     * @type {Array<VehiclePartDescriptor>}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'descriptors'?: Array<VehiclePartDescriptor> | null;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'projections'?: Array<VehicleProjection> | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'isCategoryRoot'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'isTypeRoot'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'isEnabledForDamageDetection'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehiclePartTypeReferenceDto
 */
export interface VehiclePartTypeReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehiclePartTypeReferenceDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof VehiclePartTypeReferenceDto
     */
    'category'?: VehiclePartCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof VehiclePartTypeReferenceDto
     */
    'type'?: VehiclePartType;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof VehiclePartTypeReferenceDto
     */
    'areas'?: Array<VehicleArea>;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehiclePartTypeReferenceDto
     */
    'projections'?: Array<VehicleProjection>;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehiclePartTypeReferenceDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeReferenceDto
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @interface VehiclePartTypeSearchPaginatedDto
 */
export interface VehiclePartTypeSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'category'?: VehiclePartCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'type'?: VehiclePartType;
    /**
     * 
     * @type {Array<VehiclePartType>}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'types'?: Array<VehiclePartType> | null;
    /**
     * 
     * @type {VehiclePartDescriptor}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'descriptor'?: VehiclePartDescriptor;
    /**
     * 
     * @type {Array<VehiclePartDescriptor>}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'descriptors'?: Array<VehiclePartDescriptor> | null;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'projections'?: Array<VehicleProjection> | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'isCategoryRoot'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'isTypeRoot'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'isEnabledForDamageDetection'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehiclePriceApplySettingsInputDto
 */
export interface VehiclePriceApplySettingsInputDto {
    /**
     * Indicates whether the cost must be applied to all vehicles with the same vehicle spec
     * @type {boolean}
     * @memberof VehiclePriceApplySettingsInputDto
     */
    'isForAllSimilarVehicles'?: boolean;
    /**
     * Indicates whether the cost must be applied to all vehicles with the same make
     * @type {boolean}
     * @memberof VehiclePriceApplySettingsInputDto
     */
    'isForAllWithSameMake'?: boolean;
    /**
     * Indicates whether the cost must be applied to all vehicles with the same model
     * @type {boolean}
     * @memberof VehiclePriceApplySettingsInputDto
     */
    'isForAllWithSameModel'?: boolean;
    /**
     * Indicates whether the cost must be applied to all vehicles with the same generation
     * @type {boolean}
     * @memberof VehiclePriceApplySettingsInputDto
     */
    'isForAllWithSameGeneration'?: boolean;
    /**
     * Indicates whether the cost must be applied to all vehicles with the same modification
     * @type {boolean}
     * @memberof VehiclePriceApplySettingsInputDto
     */
    'isForAllWithSameModification'?: boolean;
}
/**
 * 
 * @export
 * @interface VehiclePriceDto
 */
export interface VehiclePriceDto {
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof VehiclePriceDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {number}
     * @memberof VehiclePriceDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof VehiclePriceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof VehiclePriceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * Total before any discount or tax is applied
     * @type {number}
     * @memberof VehiclePriceDto
     */
    'subTotal'?: number;
    /**
     * Total after discount is applied, but before any tax is applied.
     * @type {number}
     * @memberof VehiclePriceDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * Total after discounts and taxes.
     * @type {number}
     * @memberof VehiclePriceDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface VehiclePriceInputDto
 */
export interface VehiclePriceInputDto {
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof VehiclePriceInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {number}
     * @memberof VehiclePriceInputDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof VehiclePriceInputDto
     */
    'discount'?: GeneralDiscountInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof VehiclePriceInputDto
     */
    'tax'?: GeneralTaxInputDto;
}
/**
 * 
 * @export
 * @interface VehiclePriceWithSettingsInputDto
 */
export interface VehiclePriceWithSettingsInputDto {
    /**
     * 
     * @type {VehiclePriceInputDto}
     * @memberof VehiclePriceWithSettingsInputDto
     */
    'price'?: VehiclePriceInputDto;
    /**
     * 
     * @type {VehiclePriceApplySettingsInputDto}
     * @memberof VehiclePriceWithSettingsInputDto
     */
    'settings'?: VehiclePriceApplySettingsInputDto;
}
/**
 * Describes side of a vehicle in 3D space.
 * @export
 * @enum {string}
 */

export const VehicleProjection = {
    None: 'None',
    Any: 'Any',
    Front: 'Front',
    Back: 'Back',
    Top: 'Top',
    Bottom: 'Bottom',
    Left: 'Left',
    Right: 'Right'
} as const;

export type VehicleProjection = typeof VehicleProjection[keyof typeof VehicleProjection];


/**
 * 
 * @export
 * @interface VehicleReferenceDto
 */
export interface VehicleReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleSpecReferenceDto}
     * @memberof VehicleReferenceDto
     */
    'spec'?: VehicleSpecReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleReferenceDto
     */
    'identificationNumber'?: string | null;
    /**
     * 
     * @type {GeneralPlateNoDto}
     * @memberof VehicleReferenceDto
     */
    'plateNo'?: GeneralPlateNoDto;
}
/**
 * 
 * @export
 * @interface VehicleSearchPaginatedDto
 */
export interface VehicleSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleSearchPaginatedDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleSearchPaginatedDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleSearchPaginatedDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleSearchPaginatedDto
     */
    'types'?: Array<VehicleType> | null;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleSearchPaginatedDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {VehicleFuelType}
     * @memberof VehicleSearchPaginatedDto
     */
    'fuelType'?: VehicleFuelType;
    /**
     * 
     * @type {OptionalClosedIntervalDtoOfDouble}
     * @memberof VehicleSearchPaginatedDto
     */
    'mileageRange'?: OptionalClosedIntervalDtoOfDouble;
    /**
     * 
     * @type {number}
     * @memberof VehicleSearchPaginatedDto
     */
    'manufactureYear'?: number | null;
    /**
     * 
     * @type {OptionalClosedIntervalDtoOfInt32}
     * @memberof VehicleSearchPaginatedDto
     */
    'manufactureYearRange'?: OptionalClosedIntervalDtoOfInt32;
    /**
     * 
     * @type {PoolsMetaQueryDto}
     * @memberof VehicleSearchPaginatedDto
     */
    'poolsMeta'?: PoolsMetaQueryDto;
    /**
     * 
     * @type {AssetMetaQueryDto}
     * @memberof VehicleSearchPaginatedDto
     */
    'assetMeta'?: AssetMetaQueryDto;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof VehicleSearchPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof VehicleSearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSearchPaginatedDto
     */
    'isPreparedForCheckOut'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSearchPaginatedDto
     */
    'isPreparedForCheckIn'?: boolean | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleSize = {
    None: 'None',
    Small: 'Small',
    Medium: 'Medium',
    Large: 'Large',
    ExtraLarge: 'ExtraLarge'
} as const;

export type VehicleSize = typeof VehicleSize[keyof typeof VehicleSize];


/**
 * 
 * @export
 * @interface VehicleSpecDto
 */
export interface VehicleSpecDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof VehicleSpecDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof VehicleSpecDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {VehicleGenerationReferenceDto}
     * @memberof VehicleSpecDto
     */
    'generation'?: VehicleGenerationReferenceDto;
    /**
     * 
     * @type {VehicleModificationReferenceDto}
     * @memberof VehicleSpecDto
     */
    'modification'?: VehicleModificationReferenceDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleSpecDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {VehicleSize}
     * @memberof VehicleSpecDto
     */
    'size'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleSpecDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {VehicleFuelType}
     * @memberof VehicleSpecDto
     */
    'fuelType'?: VehicleFuelType;
    /**
     * 
     * @type {VehicleGearboxType}
     * @memberof VehicleSpecDto
     */
    'gearboxType'?: VehicleGearboxType;
    /**
     * 
     * @type {VehicleDrivetrainType}
     * @memberof VehicleSpecDto
     */
    'drivetrainType'?: VehicleDrivetrainType;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'manufactureYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'numberOfSeats'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'numberOfDoors'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'bootCapacityL'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'cO2EmissionsGKm'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'horsepower'?: number | null;
    /**
     * 
     * @type {VehicleFuelConsumptionDto}
     * @memberof VehicleSpecDto
     */
    'fuelConsumption'?: VehicleFuelConsumptionDto;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'batteryCapacityKWh'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'electricRangeKm'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSpecDto
     */
    'hasAirConditioner'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSpecDto
     */
    'hasTowHook'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSpecDto
     */
    'hasTowBar'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSpecDto
     */
    'hasTowRope'?: boolean | null;
    /**
     * v1. Preserved for backward compatibility.
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'purchasingCost'?: number | null;
    /**
     * 
     * @type {VehiclePriceDto}
     * @memberof VehicleSpecDto
     */
    'purchasePrice'?: VehiclePriceDto;
}


/**
 * 
 * @export
 * @interface VehicleSpecInputDto
 */
export interface VehicleSpecInputDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleSpecInputDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecInputDto
     */
    'makeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecInputDto
     */
    'modelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecInputDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecInputDto
     */
    'modificationId'?: string | null;
    /**
     * 
     * @type {VehicleSize}
     * @memberof VehicleSpecInputDto
     */
    'size'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleSpecInputDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {VehicleFuelType}
     * @memberof VehicleSpecInputDto
     */
    'fuelType'?: VehicleFuelType;
    /**
     * 
     * @type {VehicleGearboxType}
     * @memberof VehicleSpecInputDto
     */
    'gearboxType'?: VehicleGearboxType;
    /**
     * 
     * @type {VehicleDrivetrainType}
     * @memberof VehicleSpecInputDto
     */
    'drivetrainType'?: VehicleDrivetrainType;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecInputDto
     */
    'manufactureYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecInputDto
     */
    'numberOfSeats'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecInputDto
     */
    'numberOfDoors'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecInputDto
     */
    'bootCapacityL'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecInputDto
     */
    'cO2EmissionsGKm'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecInputDto
     */
    'horsepower'?: number | null;
    /**
     * 
     * @type {VehicleFuelConsumptionInputDto}
     * @memberof VehicleSpecInputDto
     */
    'fuelConsumption'?: VehicleFuelConsumptionInputDto;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecInputDto
     */
    'batteryCapacityKWh'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecInputDto
     */
    'electricRangeKm'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSpecInputDto
     */
    'hasAirConditioner'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSpecInputDto
     */
    'hasTowHook'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSpecInputDto
     */
    'hasTowBar'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSpecInputDto
     */
    'hasTowRope'?: boolean | null;
    /**
     * 
     * @type {VehiclePriceWithSettingsInputDto}
     * @memberof VehicleSpecInputDto
     */
    'purchasePrice'?: VehiclePriceWithSettingsInputDto;
}


/**
 * 
 * @export
 * @interface VehicleSpecQueryDto
 */
export interface VehicleSpecQueryDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecQueryDto
     */
    'makeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecQueryDto
     */
    'modelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecQueryDto
     */
    'generationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecQueryDto
     */
    'modificationId'?: string | null;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleSpecQueryDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleSpecQueryDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {VehicleFuelType}
     * @memberof VehicleSpecQueryDto
     */
    'fuelType'?: VehicleFuelType;
    /**
     * 
     * @type {VehicleGearboxType}
     * @memberof VehicleSpecQueryDto
     */
    'gearboxType'?: VehicleGearboxType;
    /**
     * 
     * @type {VehicleDrivetrainType}
     * @memberof VehicleSpecQueryDto
     */
    'drivetrainType'?: VehicleDrivetrainType;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecQueryDto
     */
    'manufactureYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecQueryDto
     */
    'numberOfSeats'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecQueryDto
     */
    'numberOfDoors'?: number | null;
}


/**
 * 
 * @export
 * @interface VehicleSpecReferenceDto
 */
export interface VehicleSpecReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof VehicleSpecReferenceDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof VehicleSpecReferenceDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {VehicleGenerationReferenceDto}
     * @memberof VehicleSpecReferenceDto
     */
    'generation'?: VehicleGenerationReferenceDto;
    /**
     * 
     * @type {VehicleModificationReferenceDto}
     * @memberof VehicleSpecReferenceDto
     */
    'modification'?: VehicleModificationReferenceDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleSpecReferenceDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleSpecReferenceDto
     */
    'bodyType'?: VehicleBodyType;
}


/**
 * 
 * @export
 * @interface VehicleTaxCalcRequestDto
 */
export interface VehicleTaxCalcRequestDto {
    /**
     * 
     * @type {VehicleTaxType}
     * @memberof VehicleTaxCalcRequestDto
     */
    'type'?: VehicleTaxType;
    /**
     * 
     * @type {VehicleSpecInputDto}
     * @memberof VehicleTaxCalcRequestDto
     */
    'spec'?: VehicleSpecInputDto;
    /**
     * 
     * @type {GeneralPriceInputDto}
     * @memberof VehicleTaxCalcRequestDto
     */
    'price'?: GeneralPriceInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof VehicleTaxCalcRequestDto
     */
    'tax'?: GeneralTaxInputDto;
    /**
     * 
     * @type {GeneralCountryInputDto}
     * @memberof VehicleTaxCalcRequestDto
     */
    'country'?: GeneralCountryInputDto;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof VehicleTaxCalcRequestDto
     */
    'currency'?: GeneralCurrencyInputDto;
}


/**
 * 
 * @export
 * @interface VehicleTaxCalcResponseDto
 */
export interface VehicleTaxCalcResponseDto {
    /**
     * 
     * @type {VehicleTaxCalcRequestDto}
     * @memberof VehicleTaxCalcResponseDto
     */
    'request'?: VehicleTaxCalcRequestDto;
    /**
     * 
     * @type {VehicleTaxDto}
     * @memberof VehicleTaxCalcResponseDto
     */
    'vehicleTax'?: VehicleTaxDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof VehicleTaxCalcResponseDto
     */
    'newTax'?: GeneralTaxDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleTaxCalcResponseDto
     */
    'newTaxCalcExplanationText'?: string | null;
}
/**
 * 
 * @export
 * @interface VehicleTaxDto
 */
export interface VehicleTaxDto {
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTaxDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {VehicleTaxType}
     * @memberof VehicleTaxDto
     */
    'type'?: VehicleTaxType;
    /**
     * 
     * @type {Array<GeneralCountryDto>}
     * @memberof VehicleTaxDto
     */
    'countries'?: Array<GeneralCountryDto> | null;
    /**
     * 
     * @type {Array<TaxType>}
     * @memberof VehicleTaxDto
     */
    'taxTypes'?: Array<TaxType> | null;
    /**
     * 
     * @type {Array<VehicleFuelType>}
     * @memberof VehicleTaxDto
     */
    'fuelTypes'?: Array<VehicleFuelType> | null;
    /**
     * 
     * @type {VehicleTaxReducedDto}
     * @memberof VehicleTaxDto
     */
    'reducedForPurchase'?: VehicleTaxReducedDto;
    /**
     * 
     * @type {VehicleTaxReducedDto}
     * @memberof VehicleTaxDto
     */
    'reducedForRental'?: VehicleTaxReducedDto;
    /**
     * 
     * @type {VehicleTaxFixedFreeValueDto}
     * @memberof VehicleTaxDto
     */
    'fixedFreeValueForPurchase'?: VehicleTaxFixedFreeValueDto;
    /**
     * 
     * @type {VehicleTaxFixedFreeValueDto}
     * @memberof VehicleTaxDto
     */
    'fixedFreeValueForRental'?: VehicleTaxFixedFreeValueDto;
}


/**
 * 
 * @export
 * @interface VehicleTaxFixedFreeValueDto
 */
export interface VehicleTaxFixedFreeValueDto {
    /**
     * 
     * @type {GeneralPriceDto}
     * @memberof VehicleTaxFixedFreeValueDto
     */
    'fixedFreeValue'?: GeneralPriceDto;
}
/**
 * 
 * @export
 * @interface VehicleTaxFixedFreeValueInputDto
 */
export interface VehicleTaxFixedFreeValueInputDto {
    /**
     * 
     * @type {GeneralPriceInputDto}
     * @memberof VehicleTaxFixedFreeValueInputDto
     */
    'fixedFreeValue'?: GeneralPriceInputDto;
}
/**
 * 
 * @export
 * @interface VehicleTaxInputDto
 */
export interface VehicleTaxInputDto {
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTaxInputDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {VehicleTaxType}
     * @memberof VehicleTaxInputDto
     */
    'type'?: VehicleTaxType;
    /**
     * 
     * @type {Array<GeneralCountryInputDto>}
     * @memberof VehicleTaxInputDto
     */
    'countries'?: Array<GeneralCountryInputDto> | null;
    /**
     * 
     * @type {Array<TaxType>}
     * @memberof VehicleTaxInputDto
     */
    'taxTypes'?: Array<TaxType> | null;
    /**
     * 
     * @type {Array<VehicleFuelType>}
     * @memberof VehicleTaxInputDto
     */
    'fuelTypes'?: Array<VehicleFuelType> | null;
    /**
     * 
     * @type {VehicleTaxReducedInputDto}
     * @memberof VehicleTaxInputDto
     */
    'reducedForPurchase'?: VehicleTaxReducedInputDto;
    /**
     * 
     * @type {VehicleTaxReducedInputDto}
     * @memberof VehicleTaxInputDto
     */
    'reducedForRental'?: VehicleTaxReducedInputDto;
    /**
     * 
     * @type {VehicleTaxFixedFreeValueInputDto}
     * @memberof VehicleTaxInputDto
     */
    'fixedFreeValueForPurchase'?: VehicleTaxFixedFreeValueInputDto;
    /**
     * 
     * @type {VehicleTaxFixedFreeValueInputDto}
     * @memberof VehicleTaxInputDto
     */
    'fixedFreeValueForRental'?: VehicleTaxFixedFreeValueInputDto;
}


/**
 * 
 * @export
 * @interface VehicleTaxReducedDto
 */
export interface VehicleTaxReducedDto {
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof VehicleTaxReducedDto
     */
    'reducedTax'?: GeneralTaxDto;
}
/**
 * 
 * @export
 * @interface VehicleTaxReducedInputDto
 */
export interface VehicleTaxReducedInputDto {
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof VehicleTaxReducedInputDto
     */
    'reducedTax'?: GeneralTaxInputDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleTaxType = {
    None: 'None',
    ReducedForPurchase: 'ReducedForPurchase',
    ReducedForRental: 'ReducedForRental',
    FixedFreeValueForPurchase: 'FixedFreeValueForPurchase',
    FixedFreeValueForRental: 'FixedFreeValueForRental'
} as const;

export type VehicleTaxType = typeof VehicleTaxType[keyof typeof VehicleTaxType];


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleType = {
    None: 'None',
    Car: 'Car'
} as const;

export type VehicleType = typeof VehicleType[keyof typeof VehicleType];


/**
 * 
 * @export
 * @interface VehicleTypeChangedDto
 */
export interface VehicleTypeChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleTypeChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleTypeChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleTypeChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {VehicleTypeDto}
     * @memberof VehicleTypeChangedDto
     */
    'entity'?: VehicleTypeDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface VehicleTypeDto
 */
export interface VehicleTypeDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleTypeDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface VehicleTypeGetPaginatedDto
 */
export interface VehicleTypeGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleTypeGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleTypeGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleTypeGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleTypeGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleTypeGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleTypeGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleTypeGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleTypeGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTypeGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}
/**
 * 
 * @export
 * @interface VehicleTypeReferenceDto
 */
export interface VehicleTypeReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleTypeReferenceDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeReferenceDto
     */
    'name'?: string;
}


/**
 * 
 * @export
 * @interface VehicleTypeSearchPaginatedDto
 */
export interface VehicleTypeSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleTypeSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleTypeSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleTypeSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleTypeSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleTypeSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleTypeSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleTypeSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleTypeSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTypeSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}
/**
 * 
 * @export
 * @interface VehicleVisualModelChangedDto
 */
export interface VehicleVisualModelChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleVisualModelChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleVisualModelChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleVisualModelChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {VehicleVisualModelDto}
     * @memberof VehicleVisualModelChangedDto
     */
    'entity'?: VehicleVisualModelDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface VehicleVisualModelDto
 */
export interface VehicleVisualModelDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelDto
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelDto
     */
    'versionId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDto
     */
    'isLatestVersion'?: boolean;
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof VehicleVisualModelDto
     */
    'type'?: VehicleVisualModelType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleVisualModelDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {Array<VehicleBodyType>}
     * @memberof VehicleVisualModelDto
     */
    'bodyTypes'?: Array<VehicleBodyType> | null;
    /**
     * 
     * @type {Array<VehicleMakeReferenceDto>}
     * @memberof VehicleVisualModelDto
     */
    'makes'?: Array<VehicleMakeReferenceDto> | null;
    /**
     * 
     * @type {Array<VehicleModelReferenceDto>}
     * @memberof VehicleVisualModelDto
     */
    'models'?: Array<VehicleModelReferenceDto> | null;
    /**
     * 
     * @type {Array<VehicleGenerationReferenceDto>}
     * @memberof VehicleVisualModelDto
     */
    'generations'?: Array<VehicleGenerationReferenceDto> | null;
    /**
     * 
     * @type {Array<VehicleModificationReferenceDto>}
     * @memberof VehicleVisualModelDto
     */
    'modifications'?: Array<VehicleModificationReferenceDto> | null;
    /**
     * 
     * @type {Array<VehicleVisualModelImageDto>}
     * @memberof VehicleVisualModelDto
     */
    'images'?: Array<VehicleVisualModelImageDto>;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDto
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VehicleVisualModelDto
     */
    'vehicleTypesMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VehicleVisualModelDto
     */
    'bodyTypesMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VehicleVisualModelDto
     */
    'makeIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VehicleVisualModelDto
     */
    'modelIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VehicleVisualModelDto
     */
    'generationIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VehicleVisualModelDto
     */
    'modificationIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof VehicleVisualModelDto
     */
    'areas'?: Array<VehicleArea>;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehicleVisualModelDto
     */
    'projections'?: Array<VehicleProjection>;
}


/**
 * 
 * @export
 * @interface VehicleVisualModelGetPaginatedDto
 */
export interface VehicleVisualModelGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'type'?: VehicleVisualModelType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {Array<VehicleBodyType>}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'bodyTypes'?: Array<VehicleBodyType> | null;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'areas'?: Array<VehicleArea> | null;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'projections'?: Array<VehicleProjection> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'excludeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelGetPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * 
 * @export
 * @interface VehicleVisualModelImageDto
 */
export interface VehicleVisualModelImageDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelImageDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VehicleVisualModelImageDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehicleVisualModelImageDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {FileReferenceDto}
     * @memberof VehicleVisualModelImageDto
     */
    'file'?: FileReferenceDto;
}


/**
 * 
 * @export
 * @interface VehicleVisualModelReferenceDto
 */
export interface VehicleVisualModelReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof VehicleVisualModelReferenceDto
     */
    'type'?: VehicleVisualModelType;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelReferenceDto
     */
    'name'?: string;
}


/**
 * 
 * @export
 * @interface VehicleVisualModelSearchPaginatedDto
 */
export interface VehicleVisualModelSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'type'?: VehicleVisualModelType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {Array<VehicleBodyType>}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'bodyTypes'?: Array<VehicleBodyType> | null;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'areas'?: Array<VehicleArea> | null;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'projections'?: Array<VehicleProjection> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'excludeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelSearchPaginatedDto
     */
    'isEnabled'?: boolean | null;
}


/**
 * Describes data object that is stored in VehicleVisualModel SVG file -> \"<path />\" -> \"<desc />\" metadata node.
 * @export
 * @interface VehicleVisualModelSvgPathMetadataDto
 */
export interface VehicleVisualModelSvgPathMetadataDto {
    /**
     * 
     * @type {VehicleVisualModelSvgPathType}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'pathType'?: VehicleVisualModelSvgPathType;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'vehicleArea'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'vehicleProjection'?: VehicleProjection;
    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'vehiclePartCategory'?: VehiclePartCategory;
    /**
     * 
     * @type {Array<VehiclePartType>}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'vehiclePartTypes'?: Array<VehiclePartType> | null;
    /**
     * 
     * @type {Array<VehiclePartDescriptor>}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'vehiclePartDescriptors'?: Array<VehiclePartDescriptor> | null;
}


/**
 * Describes SVG path types in VehicleVisualModel SVG file.
 * @export
 * @enum {string}
 */

export const VehicleVisualModelSvgPathType = {
    None: 'None',
    VehiclePart: 'VehiclePart'
} as const;

export type VehicleVisualModelSvgPathType = typeof VehicleVisualModelSvgPathType[keyof typeof VehicleVisualModelSvgPathType];


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleVisualModelType = {
    None: 'None',
    TwoDimensionalDivided: 'TwoDimensionalDivided'
} as const;

export type VehicleVisualModelType = typeof VehicleVisualModelType[keyof typeof VehicleVisualModelType];


/**
 * 
 * @export
 * @interface VersionDto
 */
export interface VersionDto {
    /**
     * 
     * @type {string}
     * @memberof VersionDto
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VisibilityStatus = {
    None: 'None',
    Visible: 'Visible',
    Hidden: 'Hidden'
} as const;

export type VisibilityStatus = typeof VisibilityStatus[keyof typeof VisibilityStatus];


/**
 * 
 * @export
 * @interface VisualInspectionAddToContractDto
 */
export interface VisualInspectionAddToContractDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionAddToContractDto
     */
    'contractId'?: string;
}
/**
 * 
 * @export
 * @interface VisualInspectionAreaSettingsDto
 */
export interface VisualInspectionAreaSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionAreaSettingsDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VisualInspectionAreaSettingsDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {boolean}
     * @memberof VisualInspectionAreaSettingsDto
     */
    'isRequired'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionAreaSettingsDto
     */
    'minAttachmentCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionAreaSettingsDto
     */
    'minPhotoCount'?: number | null;
}


/**
 * 
 * @export
 * @interface VisualInspectionAreaSettingsInputDto
 */
export interface VisualInspectionAreaSettingsInputDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionAreaSettingsInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VisualInspectionAreaSettingsInputDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {boolean}
     * @memberof VisualInspectionAreaSettingsInputDto
     */
    'isRequired'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionAreaSettingsInputDto
     */
    'minAttachmentCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionAreaSettingsInputDto
     */
    'minPhotoCount'?: number | null;
}


/**
 * 
 * @export
 * @interface VisualInspectionChangedDto
 */
export interface VisualInspectionChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VisualInspectionChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VisualInspectionChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VisualInspectionChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {VisualInspectionDto}
     * @memberof VisualInspectionChangedDto
     */
    'entity'?: VisualInspectionDto;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @interface VisualInspectionContentDto
 */
export interface VisualInspectionContentDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionContentDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface VisualInspectionContentInputDto
 */
export interface VisualInspectionContentInputDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionContentInputDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface VisualInspectionCountersDto
 */
export interface VisualInspectionCountersDto {
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionCountersDto
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface VisualInspectionCreateDto
 */
export interface VisualInspectionCreateDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionCreateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionCreateDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionCreateDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionCreateDto
     */
    'vehicleId'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VisualInspectionCreateDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorAddDto}
     * @memberof VisualInspectionCreateDto
     */
    'inspector'?: GeneralInspectorAddDto;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionCreateDto
     */
    'mileage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionCreateDto
     */
    'fuelLevel'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionCreateDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<VisualInspectionItemInputDto>}
     * @memberof VisualInspectionCreateDto
     */
    'items'?: Array<VisualInspectionItemInputDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof VisualInspectionCreateDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {VisualInspectionSettingsInputDto}
     * @memberof VisualInspectionCreateDto
     */
    'settings'?: VisualInspectionSettingsInputDto;
    /**
     * 
     * @type {TenantRequestsMetaInputDto}
     * @memberof VisualInspectionCreateDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaInputDto;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof VisualInspectionCreateDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface VisualInspectionDto
 */
export interface VisualInspectionDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisualInspectionDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisualInspectionDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof VisualInspectionDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof VisualInspectionDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {FrontEndAppType}
     * @memberof VisualInspectionDto
     */
    'appType'?: FrontEndAppType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof VisualInspectionDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionDto
     */
    'mileage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionDto
     */
    'fuelLevel'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<VisualInspectionItemDto>}
     * @memberof VisualInspectionDto
     */
    'items'?: Array<VisualInspectionItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VisualInspectionDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
    /**
     * 
     * @type {VisualInspectionSettingsDto}
     * @memberof VisualInspectionDto
     */
    'settings'?: VisualInspectionSettingsDto;
    /**
     * 
     * @type {DataGrantsMetaDto}
     * @memberof VisualInspectionDto
     */
    'grantsMeta'?: DataGrantsMetaDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof VisualInspectionDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof VisualInspectionDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof VisualInspectionDto
     */
    'areas'?: Array<VehicleArea>;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VisualInspectionDto
     */
    'areasMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean | null; }}
     * @memberof VisualInspectionDto
     */
    'tenantRequestIdsMap'?: { [key: string]: boolean | null; } | null;
}


/**
 * 
 * @export
 * @interface VisualInspectionGetCreateMetaDto
 */
export interface VisualInspectionGetCreateMetaDto {
    /**
     * 
     * @type {VisualInspectionSettingsDto}
     * @memberof VisualInspectionGetCreateMetaDto
     */
    'settings'?: VisualInspectionSettingsDto;
}
/**
 * 
 * @export
 * @interface VisualInspectionGetPaginatedDto
 */
export interface VisualInspectionGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'areas'?: Array<VehicleArea> | null;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof VisualInspectionGetPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @interface VisualInspectionItemDto
 */
export interface VisualInspectionItemDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VisualInspectionItemDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionItemDto
     */
    'minPhotos'?: number;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VisualInspectionItemDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
}


/**
 * 
 * @export
 * @interface VisualInspectionItemInputDto
 */
export interface VisualInspectionItemInputDto {
    /**
     * 
     * @type {VehicleArea}
     * @memberof VisualInspectionItemInputDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof VisualInspectionItemInputDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto>;
}


/**
 * 
 * @export
 * @interface VisualInspectionReferenceDto
 */
export interface VisualInspectionReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionReferenceDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface VisualInspectionResultDto
 */
export interface VisualInspectionResultDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionResultDto
     */
    'visualInspectionId'?: string;
}
/**
 * 
 * @export
 * @interface VisualInspectionResultInputDto
 */
export interface VisualInspectionResultInputDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionResultInputDto
     */
    'visualInspectionId'?: string;
}
/**
 * 
 * @export
 * @interface VisualInspectionSearchPaginatedDto
 */
export interface VisualInspectionSearchPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'areas'?: Array<VehicleArea> | null;
    /**
     * 
     * @type {TenantRequestsMetaQueryDto}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaQueryDto;
    /**
     * 
     * @type {TagStaticFilterDto}
     * @memberof VisualInspectionSearchPaginatedDto
     */
    'tags'?: TagStaticFilterDto;
}


/**
 * 
 * @export
 * @interface VisualInspectionSettingsDto
 */
export interface VisualInspectionSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionSettingsDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<VisualInspectionAreaSettingsDto>}
     * @memberof VisualInspectionSettingsDto
     */
    'perArea'?: Array<VisualInspectionAreaSettingsDto> | null;
}
/**
 * 
 * @export
 * @interface VisualInspectionSettingsInputDto
 */
export interface VisualInspectionSettingsInputDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionSettingsInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<VisualInspectionAreaSettingsInputDto>}
     * @memberof VisualInspectionSettingsInputDto
     */
    'perArea'?: Array<VisualInspectionAreaSettingsInputDto> | null;
}
/**
 * 
 * @export
 * @interface VisualInspectionUpdateDto
 */
export interface VisualInspectionUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionUpdateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionUpdateDto
     */
    'locationId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VisualInspectionUpdateDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorAddDto}
     * @memberof VisualInspectionUpdateDto
     */
    'inspector'?: GeneralInspectorAddDto;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionUpdateDto
     */
    'mileage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionUpdateDto
     */
    'fuelLevel'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionUpdateDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<VisualInspectionItemInputDto>}
     * @memberof VisualInspectionUpdateDto
     */
    'items'?: Array<VisualInspectionItemInputDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentInputDto>}
     * @memberof VisualInspectionUpdateDto
     */
    'attachments'?: Array<GeneralAttachmentInputDto> | null;
    /**
     * 
     * @type {VisualInspectionSettingsInputDto}
     * @memberof VisualInspectionUpdateDto
     */
    'settings'?: VisualInspectionSettingsInputDto;
    /**
     * 
     * @type {GeneralAttachedTagsInputDto}
     * @memberof VisualInspectionUpdateDto
     */
    'tags'?: GeneralAttachedTagsInputDto;
}
/**
 * 
 * @export
 * @interface WebPushChannelInfoDto
 */
export interface WebPushChannelInfoDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof WebPushChannelInfoDto
     */
    'deviceTokens'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WebPushChannelInfoDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebPushChannelInfoDto
     */
    'body'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebPushChannelInfoDto
     */
    'imageUrl'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WebPushChannelInfoDto
     */
    'data'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface WebPushWebhookDto
 */
export interface WebPushWebhookDto {
    /**
     * 
     * @type {string}
     * @memberof WebPushWebhookDto
     */
    'messageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebPushWebhookDto
     */
    'messageStatus'?: string;
}
/**
 * 
 * @export
 * @interface WebhookActivationDto
 */
export interface WebhookActivationDto {
    /**
     * 
     * @type {string}
     * @memberof WebhookActivationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookActivationDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {WebhookReference}
     * @memberof WebhookActivationDto
     */
    'webhook'?: WebhookReference;
    /**
     * 
     * @type {WebhookEventType}
     * @memberof WebhookActivationDto
     */
    'eventType'?: WebhookEventType;
    /**
     * 
     * @type {WebhookActivationStatus}
     * @memberof WebhookActivationDto
     */
    'status'?: WebhookActivationStatus;
    /**
     * 
     * @type {string}
     * @memberof WebhookActivationDto
     */
    'error'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebhookActivationDto
     */
    'jsonPayload'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof WebhookActivationDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof WebhookActivationDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookActivationDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebhookActivationDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface WebhookActivationGetPaginatedDto
 */
export interface WebhookActivationGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof WebhookActivationGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof WebhookActivationGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof WebhookActivationGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookActivationGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookActivationGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookActivationGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof WebhookActivationGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof WebhookActivationGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof WebhookActivationGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof WebhookActivationGetPaginatedDto
     */
    'webhookId'?: string | null;
    /**
     * 
     * @type {WebhookEventType}
     * @memberof WebhookActivationGetPaginatedDto
     */
    'eventType'?: WebhookEventType;
    /**
     * 
     * @type {WebhookActivationStatus}
     * @memberof WebhookActivationGetPaginatedDto
     */
    'status'?: WebhookActivationStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const WebhookActivationStatus = {
    None: 'None',
    New: 'New',
    Processing: 'Processing',
    Success: 'Success',
    Failed: 'Failed',
    Cancelled: 'Cancelled'
} as const;

export type WebhookActivationStatus = typeof WebhookActivationStatus[keyof typeof WebhookActivationStatus];


/**
 * 
 * @export
 * @interface WebhookDto
 */
export interface WebhookDto {
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<WebhookEventType>}
     * @memberof WebhookDto
     */
    'eventTypes'?: Array<WebhookEventType>;
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookDto
     */
    'isEnabled'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof WebhookDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof WebhookDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebhookDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WebhookEventType = {
    None: 'None',
    ContractVehicleAllocated: 'ContractVehicleAllocated',
    ContractCheckIn: 'ContractCheckIn',
    ContractCheckOut: 'ContractCheckOut',
    Test: 'Test'
} as const;

export type WebhookEventType = typeof WebhookEventType[keyof typeof WebhookEventType];


/**
 * 
 * @export
 * @interface WebhookGetPaginatedDto
 */
export interface WebhookGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof WebhookGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof WebhookGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof WebhookGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof WebhookGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof WebhookGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof WebhookGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {Array<WebhookEventType>}
     * @memberof WebhookGetPaginatedDto
     */
    'eventTypes'?: Array<WebhookEventType> | null;
}
/**
 * 
 * @export
 * @interface WebhookReference
 */
export interface WebhookReference {
    /**
     * 
     * @type {string}
     * @memberof WebhookReference
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookReference
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookReference
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookReference
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookReference
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookReference
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<WebhookEventType>}
     * @memberof WebhookReference
     */
    'eventTypes'?: Array<WebhookEventType>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WhoType = {
    None: 'None',
    System: 'System',
    User: 'User'
} as const;

export type WhoType = typeof WhoType[keyof typeof WhoType];



/**
 * AccessoriesApi - axios parameter creator
 * @export
 */
export const AccessoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesAccessoryIdDelete: async (nexusOpsTenant: string, accessoryId: string, isDeleteCascade?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoriesAccessoryIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'accessoryId' is not null or undefined
            assertParamExists('apiV1AccessoriesAccessoryIdDelete', 'accessoryId', accessoryId)
            const localVarPath = `/api/v1/accessories/{accessoryId}`
                .replace(`{${"accessoryId"}}`, encodeURIComponent(String(accessoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesAccessoryIdGet: async (nexusOpsTenant: string, accessoryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoriesAccessoryIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'accessoryId' is not null or undefined
            assertParamExists('apiV1AccessoriesAccessoryIdGet', 'accessoryId', accessoryId)
            const localVarPath = `/api/v1/accessories/{accessoryId}`
                .replace(`{${"accessoryId"}}`, encodeURIComponent(String(accessoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryId 
         * @param {UpdateAccessoryDto} [updateAccessoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesAccessoryIdPut: async (nexusOpsTenant: string, accessoryId: string, updateAccessoryDto?: UpdateAccessoryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoriesAccessoryIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'accessoryId' is not null or undefined
            assertParamExists('apiV1AccessoriesAccessoryIdPut', 'accessoryId', accessoryId)
            const localVarPath = `/api/v1/accessories/{accessoryId}`
                .replace(`{${"accessoryId"}}`, encodeURIComponent(String(accessoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccessoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoriesBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/accessories/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AccessoryGetPaginatedDto} [accessoryGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesGetPost: async (nexusOpsTenant: string, accessoryGetPaginatedDto?: AccessoryGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoriesGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/accessories/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessoryGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateAccessoryDto} [createAccessoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesPost: async (nexusOpsTenant: string, createAccessoryDto?: CreateAccessoryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoriesPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/accessories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccessoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AccessorySearchPaginatedDto} [accessorySearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesSearchPost: async (nexusOpsTenant: string, accessorySearchPaginatedDto?: AccessorySearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoriesSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/accessories/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessorySearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessoriesApi - functional programming interface
 * @export
 */
export const AccessoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoriesAccessoryIdDelete(nexusOpsTenant: string, accessoryId: string, isDeleteCascade?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoriesAccessoryIdDelete(nexusOpsTenant, accessoryId, isDeleteCascade, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoriesApi.apiV1AccessoriesAccessoryIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoriesAccessoryIdGet(nexusOpsTenant: string, accessoryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoriesAccessoryIdGet(nexusOpsTenant, accessoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoriesApi.apiV1AccessoriesAccessoryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryId 
         * @param {UpdateAccessoryDto} [updateAccessoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoriesAccessoryIdPut(nexusOpsTenant: string, accessoryId: string, updateAccessoryDto?: UpdateAccessoryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoriesAccessoryIdPut(nexusOpsTenant, accessoryId, updateAccessoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoriesApi.apiV1AccessoriesAccessoryIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoriesBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoriesBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoriesApi.apiV1AccessoriesBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AccessoryGetPaginatedDto} [accessoryGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoriesGetPost(nexusOpsTenant: string, accessoryGetPaginatedDto?: AccessoryGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAccessoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoriesGetPost(nexusOpsTenant, accessoryGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoriesApi.apiV1AccessoriesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateAccessoryDto} [createAccessoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoriesPost(nexusOpsTenant: string, createAccessoryDto?: CreateAccessoryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoriesPost(nexusOpsTenant, createAccessoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoriesApi.apiV1AccessoriesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AccessorySearchPaginatedDto} [accessorySearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoriesSearchPost(nexusOpsTenant: string, accessorySearchPaginatedDto?: AccessorySearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAccessoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoriesSearchPost(nexusOpsTenant, accessorySearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoriesApi.apiV1AccessoriesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccessoriesApi - factory interface
 * @export
 */
export const AccessoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {AccessoriesApiApiV1AccessoriesAccessoryIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesAccessoryIdDelete(requestParameters: AccessoriesApiApiV1AccessoriesAccessoryIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AccessoriesAccessoryIdDelete(requestParameters.nexusOpsTenant, requestParameters.accessoryId, requestParameters.isDeleteCascade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoriesApiApiV1AccessoriesAccessoryIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesAccessoryIdGet(requestParameters: AccessoriesApiApiV1AccessoriesAccessoryIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessoryDto> {
            return localVarFp.apiV1AccessoriesAccessoryIdGet(requestParameters.nexusOpsTenant, requestParameters.accessoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoriesApiApiV1AccessoriesAccessoryIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesAccessoryIdPut(requestParameters: AccessoriesApiApiV1AccessoriesAccessoryIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessoryDto> {
            return localVarFp.apiV1AccessoriesAccessoryIdPut(requestParameters.nexusOpsTenant, requestParameters.accessoryId, requestParameters.updateAccessoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoriesApiApiV1AccessoriesBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesBulkDeleteDelete(requestParameters: AccessoriesApiApiV1AccessoriesBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AccessoriesBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoriesApiApiV1AccessoriesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesGetPost(requestParameters: AccessoriesApiApiV1AccessoriesGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAccessoryDto> {
            return localVarFp.apiV1AccessoriesGetPost(requestParameters.nexusOpsTenant, requestParameters.accessoryGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoriesApiApiV1AccessoriesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesPost(requestParameters: AccessoriesApiApiV1AccessoriesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessoryDto> {
            return localVarFp.apiV1AccessoriesPost(requestParameters.nexusOpsTenant, requestParameters.createAccessoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoriesApiApiV1AccessoriesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoriesSearchPost(requestParameters: AccessoriesApiApiV1AccessoriesSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAccessoryDto> {
            return localVarFp.apiV1AccessoriesSearchPost(requestParameters.nexusOpsTenant, requestParameters.accessorySearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AccessoriesAccessoryIdDelete operation in AccessoriesApi.
 * @export
 * @interface AccessoriesApiApiV1AccessoriesAccessoryIdDeleteRequest
 */
export interface AccessoriesApiApiV1AccessoriesAccessoryIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoriesApiApiV1AccessoriesAccessoryIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AccessoriesApiApiV1AccessoriesAccessoryIdDelete
     */
    readonly accessoryId: string

    /**
     * 
     * @type {boolean}
     * @memberof AccessoriesApiApiV1AccessoriesAccessoryIdDelete
     */
    readonly isDeleteCascade?: boolean
}

/**
 * Request parameters for apiV1AccessoriesAccessoryIdGet operation in AccessoriesApi.
 * @export
 * @interface AccessoriesApiApiV1AccessoriesAccessoryIdGetRequest
 */
export interface AccessoriesApiApiV1AccessoriesAccessoryIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoriesApiApiV1AccessoriesAccessoryIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AccessoriesApiApiV1AccessoriesAccessoryIdGet
     */
    readonly accessoryId: string
}

/**
 * Request parameters for apiV1AccessoriesAccessoryIdPut operation in AccessoriesApi.
 * @export
 * @interface AccessoriesApiApiV1AccessoriesAccessoryIdPutRequest
 */
export interface AccessoriesApiApiV1AccessoriesAccessoryIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoriesApiApiV1AccessoriesAccessoryIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AccessoriesApiApiV1AccessoriesAccessoryIdPut
     */
    readonly accessoryId: string

    /**
     * 
     * @type {UpdateAccessoryDto}
     * @memberof AccessoriesApiApiV1AccessoriesAccessoryIdPut
     */
    readonly updateAccessoryDto?: UpdateAccessoryDto
}

/**
 * Request parameters for apiV1AccessoriesBulkDeleteDelete operation in AccessoriesApi.
 * @export
 * @interface AccessoriesApiApiV1AccessoriesBulkDeleteDeleteRequest
 */
export interface AccessoriesApiApiV1AccessoriesBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoriesApiApiV1AccessoriesBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof AccessoriesApiApiV1AccessoriesBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1AccessoriesGetPost operation in AccessoriesApi.
 * @export
 * @interface AccessoriesApiApiV1AccessoriesGetPostRequest
 */
export interface AccessoriesApiApiV1AccessoriesGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoriesApiApiV1AccessoriesGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AccessoryGetPaginatedDto}
     * @memberof AccessoriesApiApiV1AccessoriesGetPost
     */
    readonly accessoryGetPaginatedDto?: AccessoryGetPaginatedDto
}

/**
 * Request parameters for apiV1AccessoriesPost operation in AccessoriesApi.
 * @export
 * @interface AccessoriesApiApiV1AccessoriesPostRequest
 */
export interface AccessoriesApiApiV1AccessoriesPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoriesApiApiV1AccessoriesPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateAccessoryDto}
     * @memberof AccessoriesApiApiV1AccessoriesPost
     */
    readonly createAccessoryDto?: CreateAccessoryDto
}

/**
 * Request parameters for apiV1AccessoriesSearchPost operation in AccessoriesApi.
 * @export
 * @interface AccessoriesApiApiV1AccessoriesSearchPostRequest
 */
export interface AccessoriesApiApiV1AccessoriesSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoriesApiApiV1AccessoriesSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AccessorySearchPaginatedDto}
     * @memberof AccessoriesApiApiV1AccessoriesSearchPost
     */
    readonly accessorySearchPaginatedDto?: AccessorySearchPaginatedDto
}

/**
 * AccessoriesApi - object-oriented interface
 * @export
 * @class AccessoriesApi
 * @extends {BaseAPI}
 */
export class AccessoriesApi extends BaseAPI {
    /**
     * 
     * @param {AccessoriesApiApiV1AccessoriesAccessoryIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoriesApi
     */
    public apiV1AccessoriesAccessoryIdDelete(requestParameters: AccessoriesApiApiV1AccessoriesAccessoryIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AccessoriesApiFp(this.configuration).apiV1AccessoriesAccessoryIdDelete(requestParameters.nexusOpsTenant, requestParameters.accessoryId, requestParameters.isDeleteCascade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoriesApiApiV1AccessoriesAccessoryIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoriesApi
     */
    public apiV1AccessoriesAccessoryIdGet(requestParameters: AccessoriesApiApiV1AccessoriesAccessoryIdGetRequest, options?: RawAxiosRequestConfig) {
        return AccessoriesApiFp(this.configuration).apiV1AccessoriesAccessoryIdGet(requestParameters.nexusOpsTenant, requestParameters.accessoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoriesApiApiV1AccessoriesAccessoryIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoriesApi
     */
    public apiV1AccessoriesAccessoryIdPut(requestParameters: AccessoriesApiApiV1AccessoriesAccessoryIdPutRequest, options?: RawAxiosRequestConfig) {
        return AccessoriesApiFp(this.configuration).apiV1AccessoriesAccessoryIdPut(requestParameters.nexusOpsTenant, requestParameters.accessoryId, requestParameters.updateAccessoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoriesApiApiV1AccessoriesBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoriesApi
     */
    public apiV1AccessoriesBulkDeleteDelete(requestParameters: AccessoriesApiApiV1AccessoriesBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return AccessoriesApiFp(this.configuration).apiV1AccessoriesBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoriesApiApiV1AccessoriesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoriesApi
     */
    public apiV1AccessoriesGetPost(requestParameters: AccessoriesApiApiV1AccessoriesGetPostRequest, options?: RawAxiosRequestConfig) {
        return AccessoriesApiFp(this.configuration).apiV1AccessoriesGetPost(requestParameters.nexusOpsTenant, requestParameters.accessoryGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoriesApiApiV1AccessoriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoriesApi
     */
    public apiV1AccessoriesPost(requestParameters: AccessoriesApiApiV1AccessoriesPostRequest, options?: RawAxiosRequestConfig) {
        return AccessoriesApiFp(this.configuration).apiV1AccessoriesPost(requestParameters.nexusOpsTenant, requestParameters.createAccessoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoriesApiApiV1AccessoriesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoriesApi
     */
    public apiV1AccessoriesSearchPost(requestParameters: AccessoriesApiApiV1AccessoriesSearchPostRequest, options?: RawAxiosRequestConfig) {
        return AccessoriesApiFp(this.configuration).apiV1AccessoriesSearchPost(requestParameters.nexusOpsTenant, requestParameters.accessorySearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AccessoryChecksApi - axios parameter creator
 * @export
 */
export const AccessoryChecksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {AddAccessoryCheckToContractDto} [addAccessoryCheckToContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksAccessoryCheckIdAddContractPut: async (nexusOpsTenant: string, accessoryCheckId: string, addAccessoryCheckToContractDto?: AddAccessoryCheckToContractDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoryChecksAccessoryCheckIdAddContractPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'accessoryCheckId' is not null or undefined
            assertParamExists('apiV1AccessoryChecksAccessoryCheckIdAddContractPut', 'accessoryCheckId', accessoryCheckId)
            const localVarPath = `/api/v1/accessory-checks/{accessoryCheckId}/add-contract`
                .replace(`{${"accessoryCheckId"}}`, encodeURIComponent(String(accessoryCheckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAccessoryCheckToContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksAccessoryCheckIdDelete: async (nexusOpsTenant: string, accessoryCheckId: string, isDeleteCascade?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoryChecksAccessoryCheckIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'accessoryCheckId' is not null or undefined
            assertParamExists('apiV1AccessoryChecksAccessoryCheckIdDelete', 'accessoryCheckId', accessoryCheckId)
            const localVarPath = `/api/v1/accessory-checks/{accessoryCheckId}`
                .replace(`{${"accessoryCheckId"}}`, encodeURIComponent(String(accessoryCheckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet: async (nexusOpsTenant: string, accessoryCheckId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'accessoryCheckId' is not null or undefined
            assertParamExists('apiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet', 'accessoryCheckId', accessoryCheckId)
            const localVarPath = `/api/v1/accessory-checks/{accessoryCheckId}/download/pdf`
                .replace(`{${"accessoryCheckId"}}`, encodeURIComponent(String(accessoryCheckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksAccessoryCheckIdGet: async (nexusOpsTenant: string, accessoryCheckId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoryChecksAccessoryCheckIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'accessoryCheckId' is not null or undefined
            assertParamExists('apiV1AccessoryChecksAccessoryCheckIdGet', 'accessoryCheckId', accessoryCheckId)
            const localVarPath = `/api/v1/accessory-checks/{accessoryCheckId}`
                .replace(`{${"accessoryCheckId"}}`, encodeURIComponent(String(accessoryCheckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {UpdateAccessoryCheckDto} [updateAccessoryCheckDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksAccessoryCheckIdPut: async (nexusOpsTenant: string, accessoryCheckId: string, updateAccessoryCheckDto?: UpdateAccessoryCheckDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoryChecksAccessoryCheckIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'accessoryCheckId' is not null or undefined
            assertParamExists('apiV1AccessoryChecksAccessoryCheckIdPut', 'accessoryCheckId', accessoryCheckId)
            const localVarPath = `/api/v1/accessory-checks/{accessoryCheckId}`
                .replace(`{${"accessoryCheckId"}}`, encodeURIComponent(String(accessoryCheckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccessoryCheckDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoryChecksBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/accessory-checks/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksByIdsGet: async (nexusOpsTenant: string, ids?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoryChecksByIdsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/accessory-checks/by-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksCountersGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoryChecksCountersGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/accessory-checks/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AccessoryCheckGetPaginatedDto} [accessoryCheckGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksGetPost: async (nexusOpsTenant: string, accessoryCheckGetPaginatedDto?: AccessoryCheckGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoryChecksGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/accessory-checks/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessoryCheckGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateAccessoryCheckDto} [createAccessoryCheckDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksPost: async (nexusOpsTenant: string, createAccessoryCheckDto?: CreateAccessoryCheckDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoryChecksPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/accessory-checks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccessoryCheckDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AccessoryCheckSearchPaginatedDto} [accessoryCheckSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksSearchPost: async (nexusOpsTenant: string, accessoryCheckSearchPaginatedDto?: AccessoryCheckSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccessoryChecksSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/accessory-checks/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessoryCheckSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessoryChecksApi - functional programming interface
 * @export
 */
export const AccessoryChecksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessoryChecksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {AddAccessoryCheckToContractDto} [addAccessoryCheckToContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoryChecksAccessoryCheckIdAddContractPut(nexusOpsTenant: string, accessoryCheckId: string, addAccessoryCheckToContractDto?: AddAccessoryCheckToContractDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessoryCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoryChecksAccessoryCheckIdAddContractPut(nexusOpsTenant, accessoryCheckId, addAccessoryCheckToContractDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoryChecksApi.apiV1AccessoryChecksAccessoryCheckIdAddContractPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoryChecksAccessoryCheckIdDelete(nexusOpsTenant: string, accessoryCheckId: string, isDeleteCascade?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoryChecksAccessoryCheckIdDelete(nexusOpsTenant, accessoryCheckId, isDeleteCascade, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoryChecksApi.apiV1AccessoryChecksAccessoryCheckIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet(nexusOpsTenant: string, accessoryCheckId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet(nexusOpsTenant, accessoryCheckId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoryChecksApi.apiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoryChecksAccessoryCheckIdGet(nexusOpsTenant: string, accessoryCheckId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessoryCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoryChecksAccessoryCheckIdGet(nexusOpsTenant, accessoryCheckId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoryChecksApi.apiV1AccessoryChecksAccessoryCheckIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {UpdateAccessoryCheckDto} [updateAccessoryCheckDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoryChecksAccessoryCheckIdPut(nexusOpsTenant: string, accessoryCheckId: string, updateAccessoryCheckDto?: UpdateAccessoryCheckDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessoryCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoryChecksAccessoryCheckIdPut(nexusOpsTenant, accessoryCheckId, updateAccessoryCheckDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoryChecksApi.apiV1AccessoryChecksAccessoryCheckIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoryChecksBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoryChecksBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoryChecksApi.apiV1AccessoryChecksBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoryChecksByIdsGet(nexusOpsTenant: string, ids?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessoryCheckDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoryChecksByIdsGet(nexusOpsTenant, ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoryChecksApi.apiV1AccessoryChecksByIdsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoryChecksCountersGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessoryCheckCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoryChecksCountersGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoryChecksApi.apiV1AccessoryChecksCountersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AccessoryCheckGetPaginatedDto} [accessoryCheckGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoryChecksGetPost(nexusOpsTenant: string, accessoryCheckGetPaginatedDto?: AccessoryCheckGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAccessoryCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoryChecksGetPost(nexusOpsTenant, accessoryCheckGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoryChecksApi.apiV1AccessoryChecksGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateAccessoryCheckDto} [createAccessoryCheckDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoryChecksPost(nexusOpsTenant: string, createAccessoryCheckDto?: CreateAccessoryCheckDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessoryCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoryChecksPost(nexusOpsTenant, createAccessoryCheckDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoryChecksApi.apiV1AccessoryChecksPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AccessoryCheckSearchPaginatedDto} [accessoryCheckSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccessoryChecksSearchPost(nexusOpsTenant: string, accessoryCheckSearchPaginatedDto?: AccessoryCheckSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAccessoryCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccessoryChecksSearchPost(nexusOpsTenant, accessoryCheckSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessoryChecksApi.apiV1AccessoryChecksSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccessoryChecksApi - factory interface
 * @export
 */
export const AccessoryChecksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessoryChecksApiFp(configuration)
    return {
        /**
         * 
         * @param {AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdAddContractPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksAccessoryCheckIdAddContractPut(requestParameters: AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdAddContractPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessoryCheckDto> {
            return localVarFp.apiV1AccessoryChecksAccessoryCheckIdAddContractPut(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, requestParameters.addAccessoryCheckToContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksAccessoryCheckIdDelete(requestParameters: AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AccessoryChecksAccessoryCheckIdDelete(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, requestParameters.isDeleteCascade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDownloadPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet(requestParameters: AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksAccessoryCheckIdGet(requestParameters: AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessoryCheckDto> {
            return localVarFp.apiV1AccessoryChecksAccessoryCheckIdGet(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksAccessoryCheckIdPut(requestParameters: AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessoryCheckDto> {
            return localVarFp.apiV1AccessoryChecksAccessoryCheckIdPut(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, requestParameters.updateAccessoryCheckDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoryChecksApiApiV1AccessoryChecksBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksBulkDeleteDelete(requestParameters: AccessoryChecksApiApiV1AccessoryChecksBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AccessoryChecksBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoryChecksApiApiV1AccessoryChecksByIdsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksByIdsGet(requestParameters: AccessoryChecksApiApiV1AccessoryChecksByIdsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<AccessoryCheckDto>> {
            return localVarFp.apiV1AccessoryChecksByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoryChecksApiApiV1AccessoryChecksCountersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksCountersGet(requestParameters: AccessoryChecksApiApiV1AccessoryChecksCountersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessoryCheckCountersDto> {
            return localVarFp.apiV1AccessoryChecksCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoryChecksApiApiV1AccessoryChecksGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksGetPost(requestParameters: AccessoryChecksApiApiV1AccessoryChecksGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAccessoryCheckDto> {
            return localVarFp.apiV1AccessoryChecksGetPost(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoryChecksApiApiV1AccessoryChecksPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksPost(requestParameters: AccessoryChecksApiApiV1AccessoryChecksPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessoryCheckDto> {
            return localVarFp.apiV1AccessoryChecksPost(requestParameters.nexusOpsTenant, requestParameters.createAccessoryCheckDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessoryChecksApiApiV1AccessoryChecksSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccessoryChecksSearchPost(requestParameters: AccessoryChecksApiApiV1AccessoryChecksSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAccessoryCheckDto> {
            return localVarFp.apiV1AccessoryChecksSearchPost(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AccessoryChecksAccessoryCheckIdAddContractPut operation in AccessoryChecksApi.
 * @export
 * @interface AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdAddContractPutRequest
 */
export interface AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdAddContractPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdAddContractPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdAddContractPut
     */
    readonly accessoryCheckId: string

    /**
     * 
     * @type {AddAccessoryCheckToContractDto}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdAddContractPut
     */
    readonly addAccessoryCheckToContractDto?: AddAccessoryCheckToContractDto
}

/**
 * Request parameters for apiV1AccessoryChecksAccessoryCheckIdDelete operation in AccessoryChecksApi.
 * @export
 * @interface AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDeleteRequest
 */
export interface AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDelete
     */
    readonly accessoryCheckId: string

    /**
     * 
     * @type {boolean}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDelete
     */
    readonly isDeleteCascade?: boolean
}

/**
 * Request parameters for apiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet operation in AccessoryChecksApi.
 * @export
 * @interface AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDownloadPdfGetRequest
 */
export interface AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDownloadPdfGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet
     */
    readonly accessoryCheckId: string
}

/**
 * Request parameters for apiV1AccessoryChecksAccessoryCheckIdGet operation in AccessoryChecksApi.
 * @export
 * @interface AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdGetRequest
 */
export interface AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdGet
     */
    readonly accessoryCheckId: string
}

/**
 * Request parameters for apiV1AccessoryChecksAccessoryCheckIdPut operation in AccessoryChecksApi.
 * @export
 * @interface AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdPutRequest
 */
export interface AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdPut
     */
    readonly accessoryCheckId: string

    /**
     * 
     * @type {UpdateAccessoryCheckDto}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdPut
     */
    readonly updateAccessoryCheckDto?: UpdateAccessoryCheckDto
}

/**
 * Request parameters for apiV1AccessoryChecksBulkDeleteDelete operation in AccessoryChecksApi.
 * @export
 * @interface AccessoryChecksApiApiV1AccessoryChecksBulkDeleteDeleteRequest
 */
export interface AccessoryChecksApiApiV1AccessoryChecksBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1AccessoryChecksByIdsGet operation in AccessoryChecksApi.
 * @export
 * @interface AccessoryChecksApiApiV1AccessoryChecksByIdsGetRequest
 */
export interface AccessoryChecksApiApiV1AccessoryChecksByIdsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksByIdsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksByIdsGet
     */
    readonly ids?: Array<string>
}

/**
 * Request parameters for apiV1AccessoryChecksCountersGet operation in AccessoryChecksApi.
 * @export
 * @interface AccessoryChecksApiApiV1AccessoryChecksCountersGetRequest
 */
export interface AccessoryChecksApiApiV1AccessoryChecksCountersGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksCountersGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1AccessoryChecksGetPost operation in AccessoryChecksApi.
 * @export
 * @interface AccessoryChecksApiApiV1AccessoryChecksGetPostRequest
 */
export interface AccessoryChecksApiApiV1AccessoryChecksGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AccessoryCheckGetPaginatedDto}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksGetPost
     */
    readonly accessoryCheckGetPaginatedDto?: AccessoryCheckGetPaginatedDto
}

/**
 * Request parameters for apiV1AccessoryChecksPost operation in AccessoryChecksApi.
 * @export
 * @interface AccessoryChecksApiApiV1AccessoryChecksPostRequest
 */
export interface AccessoryChecksApiApiV1AccessoryChecksPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateAccessoryCheckDto}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksPost
     */
    readonly createAccessoryCheckDto?: CreateAccessoryCheckDto
}

/**
 * Request parameters for apiV1AccessoryChecksSearchPost operation in AccessoryChecksApi.
 * @export
 * @interface AccessoryChecksApiApiV1AccessoryChecksSearchPostRequest
 */
export interface AccessoryChecksApiApiV1AccessoryChecksSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AccessoryCheckSearchPaginatedDto}
     * @memberof AccessoryChecksApiApiV1AccessoryChecksSearchPost
     */
    readonly accessoryCheckSearchPaginatedDto?: AccessoryCheckSearchPaginatedDto
}

/**
 * AccessoryChecksApi - object-oriented interface
 * @export
 * @class AccessoryChecksApi
 * @extends {BaseAPI}
 */
export class AccessoryChecksApi extends BaseAPI {
    /**
     * 
     * @param {AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdAddContractPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoryChecksApi
     */
    public apiV1AccessoryChecksAccessoryCheckIdAddContractPut(requestParameters: AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdAddContractPutRequest, options?: RawAxiosRequestConfig) {
        return AccessoryChecksApiFp(this.configuration).apiV1AccessoryChecksAccessoryCheckIdAddContractPut(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, requestParameters.addAccessoryCheckToContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoryChecksApi
     */
    public apiV1AccessoryChecksAccessoryCheckIdDelete(requestParameters: AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AccessoryChecksApiFp(this.configuration).apiV1AccessoryChecksAccessoryCheckIdDelete(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, requestParameters.isDeleteCascade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDownloadPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoryChecksApi
     */
    public apiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet(requestParameters: AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig) {
        return AccessoryChecksApiFp(this.configuration).apiV1AccessoryChecksAccessoryCheckIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoryChecksApi
     */
    public apiV1AccessoryChecksAccessoryCheckIdGet(requestParameters: AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdGetRequest, options?: RawAxiosRequestConfig) {
        return AccessoryChecksApiFp(this.configuration).apiV1AccessoryChecksAccessoryCheckIdGet(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoryChecksApi
     */
    public apiV1AccessoryChecksAccessoryCheckIdPut(requestParameters: AccessoryChecksApiApiV1AccessoryChecksAccessoryCheckIdPutRequest, options?: RawAxiosRequestConfig) {
        return AccessoryChecksApiFp(this.configuration).apiV1AccessoryChecksAccessoryCheckIdPut(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, requestParameters.updateAccessoryCheckDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoryChecksApiApiV1AccessoryChecksBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoryChecksApi
     */
    public apiV1AccessoryChecksBulkDeleteDelete(requestParameters: AccessoryChecksApiApiV1AccessoryChecksBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return AccessoryChecksApiFp(this.configuration).apiV1AccessoryChecksBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoryChecksApiApiV1AccessoryChecksByIdsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoryChecksApi
     */
    public apiV1AccessoryChecksByIdsGet(requestParameters: AccessoryChecksApiApiV1AccessoryChecksByIdsGetRequest, options?: RawAxiosRequestConfig) {
        return AccessoryChecksApiFp(this.configuration).apiV1AccessoryChecksByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoryChecksApiApiV1AccessoryChecksCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoryChecksApi
     */
    public apiV1AccessoryChecksCountersGet(requestParameters: AccessoryChecksApiApiV1AccessoryChecksCountersGetRequest, options?: RawAxiosRequestConfig) {
        return AccessoryChecksApiFp(this.configuration).apiV1AccessoryChecksCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoryChecksApiApiV1AccessoryChecksGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoryChecksApi
     */
    public apiV1AccessoryChecksGetPost(requestParameters: AccessoryChecksApiApiV1AccessoryChecksGetPostRequest, options?: RawAxiosRequestConfig) {
        return AccessoryChecksApiFp(this.configuration).apiV1AccessoryChecksGetPost(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoryChecksApiApiV1AccessoryChecksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoryChecksApi
     */
    public apiV1AccessoryChecksPost(requestParameters: AccessoryChecksApiApiV1AccessoryChecksPostRequest, options?: RawAxiosRequestConfig) {
        return AccessoryChecksApiFp(this.configuration).apiV1AccessoryChecksPost(requestParameters.nexusOpsTenant, requestParameters.createAccessoryCheckDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessoryChecksApiApiV1AccessoryChecksSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessoryChecksApi
     */
    public apiV1AccessoryChecksSearchPost(requestParameters: AccessoryChecksApiApiV1AccessoryChecksSearchPostRequest, options?: RawAxiosRequestConfig) {
        return AccessoryChecksApiFp(this.configuration).apiV1AccessoryChecksSearchPost(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountPasswordPolicyGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account/password/policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidatePasswordDto} [validatePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountPasswordValidatePost: async (validatePasswordDto?: ValidatePasswordDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account/password/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validatePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterCompleteDto} [registerCompleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountRegisterCompanyCompletePost: async (registerCompleteDto?: RegisterCompleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account/register/company/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerCompleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountRegisterCompanyGetIncompleteRegistrationGet: async (email?: string, companyId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account/register/company/get-incomplete-registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateCompanyIdentifierDto} [validateCompanyIdentifierDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountRegisterCompanyIdentifierValidatePost: async (validateCompanyIdentifierDto?: ValidateCompanyIdentifierDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account/register/company/identifier/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateCompanyIdentifierDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterDto} [registerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountRegisterCompanyInfoPost: async (registerDto?: RegisterDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account/register/company/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateCompanyDto} [createCompanyDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountRegisterCompanyNewPost: async (nexusOpsTenant: string, createCompanyDto?: CreateCompanyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AccountRegisterCompanyNewPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/account/register/company/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendEmailVerificationTokenDto} [sendEmailVerificationTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountVerificationEmailTokenSendPost: async (sendEmailVerificationTokenDto?: SendEmailVerificationTokenDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account/verification/email/token/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendEmailVerificationTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateEmailVerificationTokenDto} [validateEmailVerificationTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountVerificationEmailTokenValidatePost: async (validateEmailVerificationTokenDto?: ValidateEmailVerificationTokenDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account/verification/email/token/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateEmailVerificationTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccountPasswordPolicyGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordPolicyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccountPasswordPolicyGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.apiV1AccountPasswordPolicyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ValidatePasswordDto} [validatePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccountPasswordValidatePost(validatePasswordDto?: ValidatePasswordDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PasswordValidateResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccountPasswordValidatePost(validatePasswordDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.apiV1AccountPasswordValidatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RegisterCompleteDto} [registerCompleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccountRegisterCompanyCompletePost(registerCompleteDto?: RegisterCompleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccountRegisterCompanyCompletePost(registerCompleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.apiV1AccountRegisterCompanyCompletePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccountRegisterCompanyGetIncompleteRegistrationGet(email?: string, companyId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIncompleteRegistrationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccountRegisterCompanyGetIncompleteRegistrationGet(email, companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.apiV1AccountRegisterCompanyGetIncompleteRegistrationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ValidateCompanyIdentifierDto} [validateCompanyIdentifierDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccountRegisterCompanyIdentifierValidatePost(validateCompanyIdentifierDto?: ValidateCompanyIdentifierDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdentifierValidateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccountRegisterCompanyIdentifierValidatePost(validateCompanyIdentifierDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.apiV1AccountRegisterCompanyIdentifierValidatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RegisterDto} [registerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccountRegisterCompanyInfoPost(registerDto?: RegisterDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccountRegisterCompanyInfoPost(registerDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.apiV1AccountRegisterCompanyInfoPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateCompanyDto} [createCompanyDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccountRegisterCompanyNewPost(nexusOpsTenant: string, createCompanyDto?: CreateCompanyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccountRegisterCompanyNewPost(nexusOpsTenant, createCompanyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.apiV1AccountRegisterCompanyNewPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SendEmailVerificationTokenDto} [sendEmailVerificationTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccountVerificationEmailTokenSendPost(sendEmailVerificationTokenDto?: SendEmailVerificationTokenDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccountVerificationEmailTokenSendPost(sendEmailVerificationTokenDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.apiV1AccountVerificationEmailTokenSendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ValidateEmailVerificationTokenDto} [validateEmailVerificationTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AccountVerificationEmailTokenValidatePost(validateEmailVerificationTokenDto?: ValidateEmailVerificationTokenDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserValidateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AccountVerificationEmailTokenValidatePost(validateEmailVerificationTokenDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.apiV1AccountVerificationEmailTokenValidatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountPasswordPolicyGet(options?: RawAxiosRequestConfig): AxiosPromise<PasswordPolicyDto> {
            return localVarFp.apiV1AccountPasswordPolicyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountApiApiV1AccountPasswordValidatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountPasswordValidatePost(requestParameters: AccountApiApiV1AccountPasswordValidatePostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<PasswordValidateResultDto>> {
            return localVarFp.apiV1AccountPasswordValidatePost(requestParameters.validatePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountApiApiV1AccountRegisterCompanyCompletePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountRegisterCompanyCompletePost(requestParameters: AccountApiApiV1AccountRegisterCompanyCompletePostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AccountRegisterCompanyCompletePost(requestParameters.registerCompleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountApiApiV1AccountRegisterCompanyGetIncompleteRegistrationGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountRegisterCompanyGetIncompleteRegistrationGet(requestParameters: AccountApiApiV1AccountRegisterCompanyGetIncompleteRegistrationGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CompanyIncompleteRegistrationDto> {
            return localVarFp.apiV1AccountRegisterCompanyGetIncompleteRegistrationGet(requestParameters.email, requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountApiApiV1AccountRegisterCompanyIdentifierValidatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountRegisterCompanyIdentifierValidatePost(requestParameters: AccountApiApiV1AccountRegisterCompanyIdentifierValidatePostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CompanyIdentifierValidateResultDto> {
            return localVarFp.apiV1AccountRegisterCompanyIdentifierValidatePost(requestParameters.validateCompanyIdentifierDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountApiApiV1AccountRegisterCompanyInfoPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountRegisterCompanyInfoPost(requestParameters: AccountApiApiV1AccountRegisterCompanyInfoPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RegisterResultDto> {
            return localVarFp.apiV1AccountRegisterCompanyInfoPost(requestParameters.registerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountApiApiV1AccountRegisterCompanyNewPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountRegisterCompanyNewPost(requestParameters: AccountApiApiV1AccountRegisterCompanyNewPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<RegisterResultDto> {
            return localVarFp.apiV1AccountRegisterCompanyNewPost(requestParameters.nexusOpsTenant, requestParameters.createCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountApiApiV1AccountVerificationEmailTokenSendPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountVerificationEmailTokenSendPost(requestParameters: AccountApiApiV1AccountVerificationEmailTokenSendPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AccountVerificationEmailTokenSendPost(requestParameters.sendEmailVerificationTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountApiApiV1AccountVerificationEmailTokenValidatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AccountVerificationEmailTokenValidatePost(requestParameters: AccountApiApiV1AccountVerificationEmailTokenValidatePostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<UserValidateResultDto> {
            return localVarFp.apiV1AccountVerificationEmailTokenValidatePost(requestParameters.validateEmailVerificationTokenDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AccountPasswordValidatePost operation in AccountApi.
 * @export
 * @interface AccountApiApiV1AccountPasswordValidatePostRequest
 */
export interface AccountApiApiV1AccountPasswordValidatePostRequest {
    /**
     * 
     * @type {ValidatePasswordDto}
     * @memberof AccountApiApiV1AccountPasswordValidatePost
     */
    readonly validatePasswordDto?: ValidatePasswordDto
}

/**
 * Request parameters for apiV1AccountRegisterCompanyCompletePost operation in AccountApi.
 * @export
 * @interface AccountApiApiV1AccountRegisterCompanyCompletePostRequest
 */
export interface AccountApiApiV1AccountRegisterCompanyCompletePostRequest {
    /**
     * 
     * @type {RegisterCompleteDto}
     * @memberof AccountApiApiV1AccountRegisterCompanyCompletePost
     */
    readonly registerCompleteDto?: RegisterCompleteDto
}

/**
 * Request parameters for apiV1AccountRegisterCompanyGetIncompleteRegistrationGet operation in AccountApi.
 * @export
 * @interface AccountApiApiV1AccountRegisterCompanyGetIncompleteRegistrationGetRequest
 */
export interface AccountApiApiV1AccountRegisterCompanyGetIncompleteRegistrationGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountApiApiV1AccountRegisterCompanyGetIncompleteRegistrationGet
     */
    readonly email?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiApiV1AccountRegisterCompanyGetIncompleteRegistrationGet
     */
    readonly companyId?: string
}

/**
 * Request parameters for apiV1AccountRegisterCompanyIdentifierValidatePost operation in AccountApi.
 * @export
 * @interface AccountApiApiV1AccountRegisterCompanyIdentifierValidatePostRequest
 */
export interface AccountApiApiV1AccountRegisterCompanyIdentifierValidatePostRequest {
    /**
     * 
     * @type {ValidateCompanyIdentifierDto}
     * @memberof AccountApiApiV1AccountRegisterCompanyIdentifierValidatePost
     */
    readonly validateCompanyIdentifierDto?: ValidateCompanyIdentifierDto
}

/**
 * Request parameters for apiV1AccountRegisterCompanyInfoPost operation in AccountApi.
 * @export
 * @interface AccountApiApiV1AccountRegisterCompanyInfoPostRequest
 */
export interface AccountApiApiV1AccountRegisterCompanyInfoPostRequest {
    /**
     * 
     * @type {RegisterDto}
     * @memberof AccountApiApiV1AccountRegisterCompanyInfoPost
     */
    readonly registerDto?: RegisterDto
}

/**
 * Request parameters for apiV1AccountRegisterCompanyNewPost operation in AccountApi.
 * @export
 * @interface AccountApiApiV1AccountRegisterCompanyNewPostRequest
 */
export interface AccountApiApiV1AccountRegisterCompanyNewPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AccountApiApiV1AccountRegisterCompanyNewPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateCompanyDto}
     * @memberof AccountApiApiV1AccountRegisterCompanyNewPost
     */
    readonly createCompanyDto?: CreateCompanyDto
}

/**
 * Request parameters for apiV1AccountVerificationEmailTokenSendPost operation in AccountApi.
 * @export
 * @interface AccountApiApiV1AccountVerificationEmailTokenSendPostRequest
 */
export interface AccountApiApiV1AccountVerificationEmailTokenSendPostRequest {
    /**
     * 
     * @type {SendEmailVerificationTokenDto}
     * @memberof AccountApiApiV1AccountVerificationEmailTokenSendPost
     */
    readonly sendEmailVerificationTokenDto?: SendEmailVerificationTokenDto
}

/**
 * Request parameters for apiV1AccountVerificationEmailTokenValidatePost operation in AccountApi.
 * @export
 * @interface AccountApiApiV1AccountVerificationEmailTokenValidatePostRequest
 */
export interface AccountApiApiV1AccountVerificationEmailTokenValidatePostRequest {
    /**
     * 
     * @type {ValidateEmailVerificationTokenDto}
     * @memberof AccountApiApiV1AccountVerificationEmailTokenValidatePost
     */
    readonly validateEmailVerificationTokenDto?: ValidateEmailVerificationTokenDto
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiV1AccountPasswordPolicyGet(options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiV1AccountPasswordPolicyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountApiApiV1AccountPasswordValidatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiV1AccountPasswordValidatePost(requestParameters: AccountApiApiV1AccountPasswordValidatePostRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiV1AccountPasswordValidatePost(requestParameters.validatePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountApiApiV1AccountRegisterCompanyCompletePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiV1AccountRegisterCompanyCompletePost(requestParameters: AccountApiApiV1AccountRegisterCompanyCompletePostRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiV1AccountRegisterCompanyCompletePost(requestParameters.registerCompleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountApiApiV1AccountRegisterCompanyGetIncompleteRegistrationGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiV1AccountRegisterCompanyGetIncompleteRegistrationGet(requestParameters: AccountApiApiV1AccountRegisterCompanyGetIncompleteRegistrationGetRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiV1AccountRegisterCompanyGetIncompleteRegistrationGet(requestParameters.email, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountApiApiV1AccountRegisterCompanyIdentifierValidatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiV1AccountRegisterCompanyIdentifierValidatePost(requestParameters: AccountApiApiV1AccountRegisterCompanyIdentifierValidatePostRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiV1AccountRegisterCompanyIdentifierValidatePost(requestParameters.validateCompanyIdentifierDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountApiApiV1AccountRegisterCompanyInfoPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiV1AccountRegisterCompanyInfoPost(requestParameters: AccountApiApiV1AccountRegisterCompanyInfoPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiV1AccountRegisterCompanyInfoPost(requestParameters.registerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountApiApiV1AccountRegisterCompanyNewPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiV1AccountRegisterCompanyNewPost(requestParameters: AccountApiApiV1AccountRegisterCompanyNewPostRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiV1AccountRegisterCompanyNewPost(requestParameters.nexusOpsTenant, requestParameters.createCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountApiApiV1AccountVerificationEmailTokenSendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiV1AccountVerificationEmailTokenSendPost(requestParameters: AccountApiApiV1AccountVerificationEmailTokenSendPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiV1AccountVerificationEmailTokenSendPost(requestParameters.sendEmailVerificationTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountApiApiV1AccountVerificationEmailTokenValidatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiV1AccountVerificationEmailTokenValidatePost(requestParameters: AccountApiApiV1AccountVerificationEmailTokenValidatePostRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiV1AccountVerificationEmailTokenValidatePost(requestParameters.validateEmailVerificationTokenDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminAccountApi - axios parameter creator
 * @export
 */
export const AdminAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountAllPermissionsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/account/all/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalPermissionsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/account/global/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [targetUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalRoleAssignmentsRestrictionsGet: async (targetUserId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/account/global/role-assignments/restrictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetUserId !== undefined) {
                localVarQueryParameter['targetUserId'] = targetUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sortBy] Comma-separated list of fields to sort by.
         * @param {string} [sortOrder] Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalRolesGet: async (sortBy?: string, sortOrder?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/account/global/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateGlobalRoleDto} [adminCreateGlobalRoleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalRolesPost: async (adminCreateGlobalRoleDto?: AdminCreateGlobalRoleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/account/global/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateGlobalRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalRolesRoleIdDelete: async (roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiV1AdminAccountGlobalRolesRoleIdDelete', 'roleId', roleId)
            const localVarPath = `/api/v1/admin/account/global/roles/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalRolesRoleIdGet: async (roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiV1AdminAccountGlobalRolesRoleIdGet', 'roleId', roleId)
            const localVarPath = `/api/v1/admin/account/global/roles/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} roleId 
         * @param {AdminUpdateGlobalRoleDto} [adminUpdateGlobalRoleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalRolesRoleIdPut: async (roleId: string, adminUpdateGlobalRoleDto?: AdminUpdateGlobalRoleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiV1AdminAccountGlobalRolesRoleIdPut', 'roleId', roleId)
            const localVarPath = `/api/v1/admin/account/global/roles/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateGlobalRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserRolesDto} [updateUserRolesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalUsersUserIdRolesPut: async (userId: string, updateUserRolesDto?: UpdateUserRolesDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1AdminAccountGlobalUsersUserIdRolesPut', 'userId', userId)
            const localVarPath = `/api/v1/admin/account/global/users/{userId}/roles`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRolesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountTenantsPermissionsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/account/tenants/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [targetUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGet: async (tenantId: string, targetUserId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGet', 'tenantId', tenantId)
            const localVarPath = `/api/v1/admin/account/tenants/{tenantId}/role-assignments/restrictions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetUserId !== undefined) {
                localVarQueryParameter['targetUserId'] = targetUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountTenantsTenantIdRolesGet: async (tenantId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiV1AdminAccountTenantsTenantIdRolesGet', 'tenantId', tenantId)
            const localVarPath = `/api/v1/admin/account/tenants/{tenantId}/roles`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountTenantsTenantIdRolesRoleIdGet: async (tenantId: string, roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiV1AdminAccountTenantsTenantIdRolesRoleIdGet', 'tenantId', tenantId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiV1AdminAccountTenantsTenantIdRolesRoleIdGet', 'roleId', roleId)
            const localVarPath = `/api/v1/admin/account/tenants/{tenantId}/roles/{roleId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} userId 
         * @param {AdminUserRolesUpdateDto} [adminUserRolesUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut: async (tenantId: string, userId: string, adminUserRolesUpdateDto?: AdminUserRolesUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut', 'tenantId', tenantId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut', 'userId', userId)
            const localVarPath = `/api/v1/admin/account/tenants/{tenantId}/users/{userId}/roles`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserRolesUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAccountApi - functional programming interface
 * @export
 */
export const AdminAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountAllPermissionsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountAllPermissionsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountAllPermissionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountGlobalPermissionsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountGlobalPermissionsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountGlobalPermissionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [targetUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountGlobalRoleAssignmentsRestrictionsGet(targetUserId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleAssignmentRestrictionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountGlobalRoleAssignmentsRestrictionsGet(targetUserId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountGlobalRoleAssignmentsRestrictionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [sortBy] Comma-separated list of fields to sort by.
         * @param {string} [sortOrder] Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountGlobalRolesGet(sortBy?: string, sortOrder?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountGlobalRolesGet(sortBy, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountGlobalRolesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateGlobalRoleDto} [adminCreateGlobalRoleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountGlobalRolesPost(adminCreateGlobalRoleDto?: AdminCreateGlobalRoleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountGlobalRolesPost(adminCreateGlobalRoleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountGlobalRolesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountGlobalRolesRoleIdDelete(roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountGlobalRolesRoleIdDelete(roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountGlobalRolesRoleIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountGlobalRolesRoleIdGet(roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountGlobalRolesRoleIdGet(roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountGlobalRolesRoleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} roleId 
         * @param {AdminUpdateGlobalRoleDto} [adminUpdateGlobalRoleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountGlobalRolesRoleIdPut(roleId: string, adminUpdateGlobalRoleDto?: AdminUpdateGlobalRoleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountGlobalRolesRoleIdPut(roleId, adminUpdateGlobalRoleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountGlobalRolesRoleIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserRolesDto} [updateUserRolesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountGlobalUsersUserIdRolesPut(userId: string, updateUserRolesDto?: UpdateUserRolesDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountGlobalUsersUserIdRolesPut(userId, updateUserRolesDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountGlobalUsersUserIdRolesPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountTenantsPermissionsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountTenantsPermissionsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountTenantsPermissionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [targetUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGet(tenantId: string, targetUserId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleAssignmentRestrictionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGet(tenantId, targetUserId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountTenantsTenantIdRolesGet(tenantId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountTenantsTenantIdRolesGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountTenantsTenantIdRolesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountTenantsTenantIdRolesRoleIdGet(tenantId: string, roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountTenantsTenantIdRolesRoleIdGet(tenantId, roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountTenantsTenantIdRolesRoleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} userId 
         * @param {AdminUserRolesUpdateDto} [adminUserRolesUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut(tenantId: string, userId: string, adminUserRolesUpdateDto?: AdminUserRolesUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut(tenantId, userId, adminUserRolesUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminAccountApi.apiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminAccountApi - factory interface
 * @export
 */
export const AdminAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAccountApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountAllPermissionsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<PermissionDto>> {
            return localVarFp.apiV1AdminAccountAllPermissionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalPermissionsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<PermissionDto>> {
            return localVarFp.apiV1AdminAccountGlobalPermissionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminAccountApiApiV1AdminAccountGlobalRoleAssignmentsRestrictionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalRoleAssignmentsRestrictionsGet(requestParameters: AdminAccountApiApiV1AdminAccountGlobalRoleAssignmentsRestrictionsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RoleAssignmentRestrictionsDto> {
            return localVarFp.apiV1AdminAccountGlobalRoleAssignmentsRestrictionsGet(requestParameters.targetUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminAccountApiApiV1AdminAccountGlobalRolesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalRolesGet(requestParameters: AdminAccountApiApiV1AdminAccountGlobalRolesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<RoleDto>> {
            return localVarFp.apiV1AdminAccountGlobalRolesGet(requestParameters.sortBy, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminAccountApiApiV1AdminAccountGlobalRolesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalRolesPost(requestParameters: AdminAccountApiApiV1AdminAccountGlobalRolesPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RoleDto> {
            return localVarFp.apiV1AdminAccountGlobalRolesPost(requestParameters.adminCreateGlobalRoleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalRolesRoleIdDelete(requestParameters: AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminAccountGlobalRolesRoleIdDelete(requestParameters.roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalRolesRoleIdGet(requestParameters: AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoleDto> {
            return localVarFp.apiV1AdminAccountGlobalRolesRoleIdGet(requestParameters.roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalRolesRoleIdPut(requestParameters: AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoleDto> {
            return localVarFp.apiV1AdminAccountGlobalRolesRoleIdPut(requestParameters.roleId, requestParameters.adminUpdateGlobalRoleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminAccountApiApiV1AdminAccountGlobalUsersUserIdRolesPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountGlobalUsersUserIdRolesPut(requestParameters: AdminAccountApiApiV1AdminAccountGlobalUsersUserIdRolesPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminAccountGlobalUsersUserIdRolesPut(requestParameters.userId, requestParameters.updateUserRolesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountTenantsPermissionsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<PermissionDto>> {
            return localVarFp.apiV1AdminAccountTenantsPermissionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminAccountApiApiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGet(requestParameters: AdminAccountApiApiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoleAssignmentRestrictionsDto> {
            return localVarFp.apiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGet(requestParameters.tenantId, requestParameters.targetUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountTenantsTenantIdRolesGet(requestParameters: AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<RoleDto>> {
            return localVarFp.apiV1AdminAccountTenantsTenantIdRolesGet(requestParameters.tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesRoleIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountTenantsTenantIdRolesRoleIdGet(requestParameters: AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesRoleIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoleDto> {
            return localVarFp.apiV1AdminAccountTenantsTenantIdRolesRoleIdGet(requestParameters.tenantId, requestParameters.roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminAccountApiApiV1AdminAccountTenantsTenantIdUsersUserIdRolesPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut(requestParameters: AdminAccountApiApiV1AdminAccountTenantsTenantIdUsersUserIdRolesPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut(requestParameters.tenantId, requestParameters.userId, requestParameters.adminUserRolesUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminAccountGlobalRoleAssignmentsRestrictionsGet operation in AdminAccountApi.
 * @export
 * @interface AdminAccountApiApiV1AdminAccountGlobalRoleAssignmentsRestrictionsGetRequest
 */
export interface AdminAccountApiApiV1AdminAccountGlobalRoleAssignmentsRestrictionsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountGlobalRoleAssignmentsRestrictionsGet
     */
    readonly targetUserId?: string
}

/**
 * Request parameters for apiV1AdminAccountGlobalRolesGet operation in AdminAccountApi.
 * @export
 * @interface AdminAccountApiApiV1AdminAccountGlobalRolesGetRequest
 */
export interface AdminAccountApiApiV1AdminAccountGlobalRolesGetRequest {
    /**
     * Comma-separated list of fields to sort by.
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountGlobalRolesGet
     */
    readonly sortBy?: string

    /**
     * Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountGlobalRolesGet
     */
    readonly sortOrder?: string
}

/**
 * Request parameters for apiV1AdminAccountGlobalRolesPost operation in AdminAccountApi.
 * @export
 * @interface AdminAccountApiApiV1AdminAccountGlobalRolesPostRequest
 */
export interface AdminAccountApiApiV1AdminAccountGlobalRolesPostRequest {
    /**
     * 
     * @type {AdminCreateGlobalRoleDto}
     * @memberof AdminAccountApiApiV1AdminAccountGlobalRolesPost
     */
    readonly adminCreateGlobalRoleDto?: AdminCreateGlobalRoleDto
}

/**
 * Request parameters for apiV1AdminAccountGlobalRolesRoleIdDelete operation in AdminAccountApi.
 * @export
 * @interface AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdDeleteRequest
 */
export interface AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdDelete
     */
    readonly roleId: string
}

/**
 * Request parameters for apiV1AdminAccountGlobalRolesRoleIdGet operation in AdminAccountApi.
 * @export
 * @interface AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdGetRequest
 */
export interface AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdGet
     */
    readonly roleId: string
}

/**
 * Request parameters for apiV1AdminAccountGlobalRolesRoleIdPut operation in AdminAccountApi.
 * @export
 * @interface AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdPutRequest
 */
export interface AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdPut
     */
    readonly roleId: string

    /**
     * 
     * @type {AdminUpdateGlobalRoleDto}
     * @memberof AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdPut
     */
    readonly adminUpdateGlobalRoleDto?: AdminUpdateGlobalRoleDto
}

/**
 * Request parameters for apiV1AdminAccountGlobalUsersUserIdRolesPut operation in AdminAccountApi.
 * @export
 * @interface AdminAccountApiApiV1AdminAccountGlobalUsersUserIdRolesPutRequest
 */
export interface AdminAccountApiApiV1AdminAccountGlobalUsersUserIdRolesPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountGlobalUsersUserIdRolesPut
     */
    readonly userId: string

    /**
     * 
     * @type {UpdateUserRolesDto}
     * @memberof AdminAccountApiApiV1AdminAccountGlobalUsersUserIdRolesPut
     */
    readonly updateUserRolesDto?: UpdateUserRolesDto
}

/**
 * Request parameters for apiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGet operation in AdminAccountApi.
 * @export
 * @interface AdminAccountApiApiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGetRequest
 */
export interface AdminAccountApiApiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGet
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGet
     */
    readonly targetUserId?: string
}

/**
 * Request parameters for apiV1AdminAccountTenantsTenantIdRolesGet operation in AdminAccountApi.
 * @export
 * @interface AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesGetRequest
 */
export interface AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesGet
     */
    readonly tenantId: string
}

/**
 * Request parameters for apiV1AdminAccountTenantsTenantIdRolesRoleIdGet operation in AdminAccountApi.
 * @export
 * @interface AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesRoleIdGetRequest
 */
export interface AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesRoleIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesRoleIdGet
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesRoleIdGet
     */
    readonly roleId: string
}

/**
 * Request parameters for apiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut operation in AdminAccountApi.
 * @export
 * @interface AdminAccountApiApiV1AdminAccountTenantsTenantIdUsersUserIdRolesPutRequest
 */
export interface AdminAccountApiApiV1AdminAccountTenantsTenantIdUsersUserIdRolesPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof AdminAccountApiApiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut
     */
    readonly userId: string

    /**
     * 
     * @type {AdminUserRolesUpdateDto}
     * @memberof AdminAccountApiApiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut
     */
    readonly adminUserRolesUpdateDto?: AdminUserRolesUpdateDto
}

/**
 * AdminAccountApi - object-oriented interface
 * @export
 * @class AdminAccountApi
 * @extends {BaseAPI}
 */
export class AdminAccountApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountAllPermissionsGet(options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountAllPermissionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountGlobalPermissionsGet(options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountGlobalPermissionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminAccountApiApiV1AdminAccountGlobalRoleAssignmentsRestrictionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountGlobalRoleAssignmentsRestrictionsGet(requestParameters: AdminAccountApiApiV1AdminAccountGlobalRoleAssignmentsRestrictionsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountGlobalRoleAssignmentsRestrictionsGet(requestParameters.targetUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminAccountApiApiV1AdminAccountGlobalRolesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountGlobalRolesGet(requestParameters: AdminAccountApiApiV1AdminAccountGlobalRolesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountGlobalRolesGet(requestParameters.sortBy, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminAccountApiApiV1AdminAccountGlobalRolesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountGlobalRolesPost(requestParameters: AdminAccountApiApiV1AdminAccountGlobalRolesPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountGlobalRolesPost(requestParameters.adminCreateGlobalRoleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountGlobalRolesRoleIdDelete(requestParameters: AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountGlobalRolesRoleIdDelete(requestParameters.roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountGlobalRolesRoleIdGet(requestParameters: AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountGlobalRolesRoleIdGet(requestParameters.roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountGlobalRolesRoleIdPut(requestParameters: AdminAccountApiApiV1AdminAccountGlobalRolesRoleIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountGlobalRolesRoleIdPut(requestParameters.roleId, requestParameters.adminUpdateGlobalRoleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminAccountApiApiV1AdminAccountGlobalUsersUserIdRolesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountGlobalUsersUserIdRolesPut(requestParameters: AdminAccountApiApiV1AdminAccountGlobalUsersUserIdRolesPutRequest, options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountGlobalUsersUserIdRolesPut(requestParameters.userId, requestParameters.updateUserRolesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountTenantsPermissionsGet(options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountTenantsPermissionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminAccountApiApiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGet(requestParameters: AdminAccountApiApiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGetRequest, options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGet(requestParameters.tenantId, requestParameters.targetUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountTenantsTenantIdRolesGet(requestParameters: AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesGetRequest, options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountTenantsTenantIdRolesGet(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesRoleIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountTenantsTenantIdRolesRoleIdGet(requestParameters: AdminAccountApiApiV1AdminAccountTenantsTenantIdRolesRoleIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountTenantsTenantIdRolesRoleIdGet(requestParameters.tenantId, requestParameters.roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminAccountApiApiV1AdminAccountTenantsTenantIdUsersUserIdRolesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountApi
     */
    public apiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut(requestParameters: AdminAccountApiApiV1AdminAccountTenantsTenantIdUsersUserIdRolesPutRequest, options?: RawAxiosRequestConfig) {
        return AdminAccountApiFp(this.configuration).apiV1AdminAccountTenantsTenantIdUsersUserIdRolesPut(requestParameters.tenantId, requestParameters.userId, requestParameters.adminUserRolesUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminCustomSessionsApi - axios parameter creator
 * @export
 */
export const AdminCustomSessionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} sessionIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCustomSessionsByIdentifierSessionIdentifierGet: async (sessionIdentifier: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionIdentifier' is not null or undefined
            assertParamExists('apiV1AdminCustomSessionsByIdentifierSessionIdentifierGet', 'sessionIdentifier', sessionIdentifier)
            const localVarPath = `/api/v1/admin/custom-sessions/by-identifier/{sessionIdentifier}`
                .replace(`{${"sessionIdentifier"}}`, encodeURIComponent(String(sessionIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCustomSessionGetPaginatedDto} [adminCustomSessionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCustomSessionsGetPost: async (adminCustomSessionGetPaginatedDto?: AdminCustomSessionGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/custom-sessions/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCustomSessionGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateCustomSessionDto} [adminCreateCustomSessionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCustomSessionsPost: async (adminCreateCustomSessionDto?: AdminCreateCustomSessionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/custom-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateCustomSessionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCustomSessionsSessionIdGet: async (sessionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('apiV1AdminCustomSessionsSessionIdGet', 'sessionId', sessionId)
            const localVarPath = `/api/v1/admin/custom-sessions/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCustomSessionsApi - functional programming interface
 * @export
 */
export const AdminCustomSessionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCustomSessionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} sessionIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCustomSessionsByIdentifierSessionIdentifierGet(sessionIdentifier: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomSessionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCustomSessionsByIdentifierSessionIdentifierGet(sessionIdentifier, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCustomSessionsApi.apiV1AdminCustomSessionsByIdentifierSessionIdentifierGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCustomSessionGetPaginatedDto} [adminCustomSessionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCustomSessionsGetPost(adminCustomSessionGetPaginatedDto?: AdminCustomSessionGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfCustomSessionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCustomSessionsGetPost(adminCustomSessionGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCustomSessionsApi.apiV1AdminCustomSessionsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateCustomSessionDto} [adminCreateCustomSessionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCustomSessionsPost(adminCreateCustomSessionDto?: AdminCreateCustomSessionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomSessionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCustomSessionsPost(adminCreateCustomSessionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCustomSessionsApi.apiV1AdminCustomSessionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCustomSessionsSessionIdGet(sessionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomSessionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCustomSessionsSessionIdGet(sessionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCustomSessionsApi.apiV1AdminCustomSessionsSessionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminCustomSessionsApi - factory interface
 * @export
 */
export const AdminCustomSessionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCustomSessionsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminCustomSessionsApiApiV1AdminCustomSessionsByIdentifierSessionIdentifierGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCustomSessionsByIdentifierSessionIdentifierGet(requestParameters: AdminCustomSessionsApiApiV1AdminCustomSessionsByIdentifierSessionIdentifierGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomSessionDto> {
            return localVarFp.apiV1AdminCustomSessionsByIdentifierSessionIdentifierGet(requestParameters.sessionIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminCustomSessionsApiApiV1AdminCustomSessionsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCustomSessionsGetPost(requestParameters: AdminCustomSessionsApiApiV1AdminCustomSessionsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfCustomSessionDto> {
            return localVarFp.apiV1AdminCustomSessionsGetPost(requestParameters.adminCustomSessionGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminCustomSessionsApiApiV1AdminCustomSessionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCustomSessionsPost(requestParameters: AdminCustomSessionsApiApiV1AdminCustomSessionsPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CustomSessionDto> {
            return localVarFp.apiV1AdminCustomSessionsPost(requestParameters.adminCreateCustomSessionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminCustomSessionsApiApiV1AdminCustomSessionsSessionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCustomSessionsSessionIdGet(requestParameters: AdminCustomSessionsApiApiV1AdminCustomSessionsSessionIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomSessionDto> {
            return localVarFp.apiV1AdminCustomSessionsSessionIdGet(requestParameters.sessionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminCustomSessionsByIdentifierSessionIdentifierGet operation in AdminCustomSessionsApi.
 * @export
 * @interface AdminCustomSessionsApiApiV1AdminCustomSessionsByIdentifierSessionIdentifierGetRequest
 */
export interface AdminCustomSessionsApiApiV1AdminCustomSessionsByIdentifierSessionIdentifierGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCustomSessionsApiApiV1AdminCustomSessionsByIdentifierSessionIdentifierGet
     */
    readonly sessionIdentifier: string
}

/**
 * Request parameters for apiV1AdminCustomSessionsGetPost operation in AdminCustomSessionsApi.
 * @export
 * @interface AdminCustomSessionsApiApiV1AdminCustomSessionsGetPostRequest
 */
export interface AdminCustomSessionsApiApiV1AdminCustomSessionsGetPostRequest {
    /**
     * 
     * @type {AdminCustomSessionGetPaginatedDto}
     * @memberof AdminCustomSessionsApiApiV1AdminCustomSessionsGetPost
     */
    readonly adminCustomSessionGetPaginatedDto?: AdminCustomSessionGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminCustomSessionsPost operation in AdminCustomSessionsApi.
 * @export
 * @interface AdminCustomSessionsApiApiV1AdminCustomSessionsPostRequest
 */
export interface AdminCustomSessionsApiApiV1AdminCustomSessionsPostRequest {
    /**
     * 
     * @type {AdminCreateCustomSessionDto}
     * @memberof AdminCustomSessionsApiApiV1AdminCustomSessionsPost
     */
    readonly adminCreateCustomSessionDto?: AdminCreateCustomSessionDto
}

/**
 * Request parameters for apiV1AdminCustomSessionsSessionIdGet operation in AdminCustomSessionsApi.
 * @export
 * @interface AdminCustomSessionsApiApiV1AdminCustomSessionsSessionIdGetRequest
 */
export interface AdminCustomSessionsApiApiV1AdminCustomSessionsSessionIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCustomSessionsApiApiV1AdminCustomSessionsSessionIdGet
     */
    readonly sessionId: string
}

/**
 * AdminCustomSessionsApi - object-oriented interface
 * @export
 * @class AdminCustomSessionsApi
 * @extends {BaseAPI}
 */
export class AdminCustomSessionsApi extends BaseAPI {
    /**
     * 
     * @param {AdminCustomSessionsApiApiV1AdminCustomSessionsByIdentifierSessionIdentifierGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomSessionsApi
     */
    public apiV1AdminCustomSessionsByIdentifierSessionIdentifierGet(requestParameters: AdminCustomSessionsApiApiV1AdminCustomSessionsByIdentifierSessionIdentifierGetRequest, options?: RawAxiosRequestConfig) {
        return AdminCustomSessionsApiFp(this.configuration).apiV1AdminCustomSessionsByIdentifierSessionIdentifierGet(requestParameters.sessionIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminCustomSessionsApiApiV1AdminCustomSessionsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomSessionsApi
     */
    public apiV1AdminCustomSessionsGetPost(requestParameters: AdminCustomSessionsApiApiV1AdminCustomSessionsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminCustomSessionsApiFp(this.configuration).apiV1AdminCustomSessionsGetPost(requestParameters.adminCustomSessionGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminCustomSessionsApiApiV1AdminCustomSessionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomSessionsApi
     */
    public apiV1AdminCustomSessionsPost(requestParameters: AdminCustomSessionsApiApiV1AdminCustomSessionsPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminCustomSessionsApiFp(this.configuration).apiV1AdminCustomSessionsPost(requestParameters.adminCreateCustomSessionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminCustomSessionsApiApiV1AdminCustomSessionsSessionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomSessionsApi
     */
    public apiV1AdminCustomSessionsSessionIdGet(requestParameters: AdminCustomSessionsApiApiV1AdminCustomSessionsSessionIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminCustomSessionsApiFp(this.configuration).apiV1AdminCustomSessionsSessionIdGet(requestParameters.sessionId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminDamageTypesApi - axios parameter creator
 * @export
 */
export const AdminDamageTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} damageTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataDamageTypesDamageTypeIdDelete: async (damageTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'damageTypeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataDamageTypesDamageTypeIdDelete', 'damageTypeId', damageTypeId)
            const localVarPath = `/api/v1/admin/reference-data/damage-types/{damageTypeId}`
                .replace(`{${"damageTypeId"}}`, encodeURIComponent(String(damageTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} damageTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataDamageTypesDamageTypeIdGet: async (damageTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'damageTypeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataDamageTypesDamageTypeIdGet', 'damageTypeId', damageTypeId)
            const localVarPath = `/api/v1/admin/reference-data/damage-types/{damageTypeId}`
                .replace(`{${"damageTypeId"}}`, encodeURIComponent(String(damageTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} damageTypeId 
         * @param {AdminUpdateDamageTypeDto} [adminUpdateDamageTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataDamageTypesDamageTypeIdPut: async (damageTypeId: string, adminUpdateDamageTypeDto?: AdminUpdateDamageTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'damageTypeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataDamageTypesDamageTypeIdPut', 'damageTypeId', damageTypeId)
            const localVarPath = `/api/v1/admin/reference-data/damage-types/{damageTypeId}`
                .replace(`{${"damageTypeId"}}`, encodeURIComponent(String(damageTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateDamageTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminDamageTypeGetPaginatedDto} [adminDamageTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataDamageTypesGetPost: async (adminDamageTypeGetPaginatedDto?: AdminDamageTypeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/damage-types/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminDamageTypeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateDamageTypeDto} [adminCreateDamageTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataDamageTypesPost: async (adminCreateDamageTypeDto?: AdminCreateDamageTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/damage-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateDamageTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminDamageTypeSearchPaginatedDto} [adminDamageTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataDamageTypesSearchPost: async (adminDamageTypeSearchPaginatedDto?: AdminDamageTypeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/damage-types/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminDamageTypeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminDamageTypesApi - functional programming interface
 * @export
 */
export const AdminDamageTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminDamageTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} damageTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataDamageTypesDamageTypeIdDelete(damageTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataDamageTypesDamageTypeIdDelete(damageTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminDamageTypesApi.apiV1AdminReferenceDataDamageTypesDamageTypeIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} damageTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataDamageTypesDamageTypeIdGet(damageTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDamageTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataDamageTypesDamageTypeIdGet(damageTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminDamageTypesApi.apiV1AdminReferenceDataDamageTypesDamageTypeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} damageTypeId 
         * @param {AdminUpdateDamageTypeDto} [adminUpdateDamageTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataDamageTypesDamageTypeIdPut(damageTypeId: string, adminUpdateDamageTypeDto?: AdminUpdateDamageTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDamageTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataDamageTypesDamageTypeIdPut(damageTypeId, adminUpdateDamageTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminDamageTypesApi.apiV1AdminReferenceDataDamageTypesDamageTypeIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminDamageTypeGetPaginatedDto} [adminDamageTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataDamageTypesGetPost(adminDamageTypeGetPaginatedDto?: AdminDamageTypeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminDamageTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataDamageTypesGetPost(adminDamageTypeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminDamageTypesApi.apiV1AdminReferenceDataDamageTypesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateDamageTypeDto} [adminCreateDamageTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataDamageTypesPost(adminCreateDamageTypeDto?: AdminCreateDamageTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDamageTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataDamageTypesPost(adminCreateDamageTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminDamageTypesApi.apiV1AdminReferenceDataDamageTypesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminDamageTypeSearchPaginatedDto} [adminDamageTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataDamageTypesSearchPost(adminDamageTypeSearchPaginatedDto?: AdminDamageTypeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminDamageTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataDamageTypesSearchPost(adminDamageTypeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminDamageTypesApi.apiV1AdminReferenceDataDamageTypesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminDamageTypesApi - factory interface
 * @export
 */
export const AdminDamageTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminDamageTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataDamageTypesDamageTypeIdDelete(requestParameters: AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminReferenceDataDamageTypesDamageTypeIdDelete(requestParameters.damageTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataDamageTypesDamageTypeIdGet(requestParameters: AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminDamageTypeDto> {
            return localVarFp.apiV1AdminReferenceDataDamageTypesDamageTypeIdGet(requestParameters.damageTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataDamageTypesDamageTypeIdPut(requestParameters: AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminDamageTypeDto> {
            return localVarFp.apiV1AdminReferenceDataDamageTypesDamageTypeIdPut(requestParameters.damageTypeId, requestParameters.adminUpdateDamageTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataDamageTypesGetPost(requestParameters: AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminDamageTypeDto> {
            return localVarFp.apiV1AdminReferenceDataDamageTypesGetPost(requestParameters.adminDamageTypeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataDamageTypesPost(requestParameters: AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminDamageTypeDto> {
            return localVarFp.apiV1AdminReferenceDataDamageTypesPost(requestParameters.adminCreateDamageTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataDamageTypesSearchPost(requestParameters: AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminDamageTypeDto> {
            return localVarFp.apiV1AdminReferenceDataDamageTypesSearchPost(requestParameters.adminDamageTypeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminReferenceDataDamageTypesDamageTypeIdDelete operation in AdminDamageTypesApi.
 * @export
 * @interface AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdDeleteRequest
 */
export interface AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdDelete
     */
    readonly damageTypeId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataDamageTypesDamageTypeIdGet operation in AdminDamageTypesApi.
 * @export
 * @interface AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdGetRequest
 */
export interface AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdGet
     */
    readonly damageTypeId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataDamageTypesDamageTypeIdPut operation in AdminDamageTypesApi.
 * @export
 * @interface AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdPutRequest
 */
export interface AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdPut
     */
    readonly damageTypeId: string

    /**
     * 
     * @type {AdminUpdateDamageTypeDto}
     * @memberof AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdPut
     */
    readonly adminUpdateDamageTypeDto?: AdminUpdateDamageTypeDto
}

/**
 * Request parameters for apiV1AdminReferenceDataDamageTypesGetPost operation in AdminDamageTypesApi.
 * @export
 * @interface AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesGetPostRequest
 */
export interface AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesGetPostRequest {
    /**
     * 
     * @type {AdminDamageTypeGetPaginatedDto}
     * @memberof AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesGetPost
     */
    readonly adminDamageTypeGetPaginatedDto?: AdminDamageTypeGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminReferenceDataDamageTypesPost operation in AdminDamageTypesApi.
 * @export
 * @interface AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesPostRequest
 */
export interface AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesPostRequest {
    /**
     * 
     * @type {AdminCreateDamageTypeDto}
     * @memberof AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesPost
     */
    readonly adminCreateDamageTypeDto?: AdminCreateDamageTypeDto
}

/**
 * Request parameters for apiV1AdminReferenceDataDamageTypesSearchPost operation in AdminDamageTypesApi.
 * @export
 * @interface AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesSearchPostRequest
 */
export interface AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesSearchPostRequest {
    /**
     * 
     * @type {AdminDamageTypeSearchPaginatedDto}
     * @memberof AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesSearchPost
     */
    readonly adminDamageTypeSearchPaginatedDto?: AdminDamageTypeSearchPaginatedDto
}

/**
 * AdminDamageTypesApi - object-oriented interface
 * @export
 * @class AdminDamageTypesApi
 * @extends {BaseAPI}
 */
export class AdminDamageTypesApi extends BaseAPI {
    /**
     * 
     * @param {AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDamageTypesApi
     */
    public apiV1AdminReferenceDataDamageTypesDamageTypeIdDelete(requestParameters: AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminDamageTypesApiFp(this.configuration).apiV1AdminReferenceDataDamageTypesDamageTypeIdDelete(requestParameters.damageTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDamageTypesApi
     */
    public apiV1AdminReferenceDataDamageTypesDamageTypeIdGet(requestParameters: AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminDamageTypesApiFp(this.configuration).apiV1AdminReferenceDataDamageTypesDamageTypeIdGet(requestParameters.damageTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDamageTypesApi
     */
    public apiV1AdminReferenceDataDamageTypesDamageTypeIdPut(requestParameters: AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesDamageTypeIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminDamageTypesApiFp(this.configuration).apiV1AdminReferenceDataDamageTypesDamageTypeIdPut(requestParameters.damageTypeId, requestParameters.adminUpdateDamageTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDamageTypesApi
     */
    public apiV1AdminReferenceDataDamageTypesGetPost(requestParameters: AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminDamageTypesApiFp(this.configuration).apiV1AdminReferenceDataDamageTypesGetPost(requestParameters.adminDamageTypeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDamageTypesApi
     */
    public apiV1AdminReferenceDataDamageTypesPost(requestParameters: AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminDamageTypesApiFp(this.configuration).apiV1AdminReferenceDataDamageTypesPost(requestParameters.adminCreateDamageTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDamageTypesApi
     */
    public apiV1AdminReferenceDataDamageTypesSearchPost(requestParameters: AdminDamageTypesApiApiV1AdminReferenceDataDamageTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminDamageTypesApiFp(this.configuration).apiV1AdminReferenceDataDamageTypesSearchPost(requestParameters.adminDamageTypeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminDomainEventsApi - axios parameter creator
 * @export
 */
export const AdminDomainEventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SendTestEventsDto} [sendTestEventsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDomainEventsTestSendPost: async (sendTestEventsDto?: SendTestEventsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/domain-events/test/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendTestEventsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminDomainEventsApi - functional programming interface
 * @export
 */
export const AdminDomainEventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminDomainEventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SendTestEventsDto} [sendTestEventsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminDomainEventsTestSendPost(sendTestEventsDto?: SendTestEventsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentIdentityTestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminDomainEventsTestSendPost(sendTestEventsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminDomainEventsApi.apiV1AdminDomainEventsTestSendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminDomainEventsApi - factory interface
 * @export
 */
export const AdminDomainEventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminDomainEventsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminDomainEventsApiApiV1AdminDomainEventsTestSendPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminDomainEventsTestSendPost(requestParameters: AdminDomainEventsApiApiV1AdminDomainEventsTestSendPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CurrentIdentityTestDto> {
            return localVarFp.apiV1AdminDomainEventsTestSendPost(requestParameters.sendTestEventsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminDomainEventsTestSendPost operation in AdminDomainEventsApi.
 * @export
 * @interface AdminDomainEventsApiApiV1AdminDomainEventsTestSendPostRequest
 */
export interface AdminDomainEventsApiApiV1AdminDomainEventsTestSendPostRequest {
    /**
     * 
     * @type {SendTestEventsDto}
     * @memberof AdminDomainEventsApiApiV1AdminDomainEventsTestSendPost
     */
    readonly sendTestEventsDto?: SendTestEventsDto
}

/**
 * AdminDomainEventsApi - object-oriented interface
 * @export
 * @class AdminDomainEventsApi
 * @extends {BaseAPI}
 */
export class AdminDomainEventsApi extends BaseAPI {
    /**
     * 
     * @param {AdminDomainEventsApiApiV1AdminDomainEventsTestSendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDomainEventsApi
     */
    public apiV1AdminDomainEventsTestSendPost(requestParameters: AdminDomainEventsApiApiV1AdminDomainEventsTestSendPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminDomainEventsApiFp(this.configuration).apiV1AdminDomainEventsTestSendPost(requestParameters.sendTestEventsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminGeneralReferenceDataApi - axios parameter creator
 * @export
 */
export const AdminGeneralReferenceDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralCountriesGet: async (search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/general/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralCulturesDefaultGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/general/cultures/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralCulturesGet: async (search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/general/cultures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralCurrenciesDefaultGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/general/currencies/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralCurrenciesGet: async (search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/general/currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralTimeZonesDefaultGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/general/time-zones/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralTimeZonesGet: async (search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/general/time-zones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminGeneralReferenceDataApi - functional programming interface
 * @export
 */
export const AdminGeneralReferenceDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminGeneralReferenceDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataGeneralCountriesGet(search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralCountryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataGeneralCountriesGet(search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminGeneralReferenceDataApi.apiV1AdminReferenceDataGeneralCountriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataGeneralCulturesDefaultGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCultureDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataGeneralCulturesDefaultGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminGeneralReferenceDataApi.apiV1AdminReferenceDataGeneralCulturesDefaultGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataGeneralCulturesGet(search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralCultureDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataGeneralCulturesGet(search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminGeneralReferenceDataApi.apiV1AdminReferenceDataGeneralCulturesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataGeneralCurrenciesDefaultGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCurrencyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataGeneralCurrenciesDefaultGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminGeneralReferenceDataApi.apiV1AdminReferenceDataGeneralCurrenciesDefaultGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataGeneralCurrenciesGet(search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralCurrencyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataGeneralCurrenciesGet(search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminGeneralReferenceDataApi.apiV1AdminReferenceDataGeneralCurrenciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataGeneralTimeZonesDefaultGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralTimeZoneDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataGeneralTimeZonesDefaultGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminGeneralReferenceDataApi.apiV1AdminReferenceDataGeneralTimeZonesDefaultGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataGeneralTimeZonesGet(search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralTimeZoneDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataGeneralTimeZonesGet(search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminGeneralReferenceDataApi.apiV1AdminReferenceDataGeneralTimeZonesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminGeneralReferenceDataApi - factory interface
 * @export
 */
export const AdminGeneralReferenceDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminGeneralReferenceDataApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCountriesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralCountriesGet(requestParameters: AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCountriesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<GeneralCountryDto>> {
            return localVarFp.apiV1AdminReferenceDataGeneralCountriesGet(requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralCulturesDefaultGet(options?: RawAxiosRequestConfig): AxiosPromise<GeneralCultureDto> {
            return localVarFp.apiV1AdminReferenceDataGeneralCulturesDefaultGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCulturesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralCulturesGet(requestParameters: AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCulturesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<GeneralCultureDto>> {
            return localVarFp.apiV1AdminReferenceDataGeneralCulturesGet(requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralCurrenciesDefaultGet(options?: RawAxiosRequestConfig): AxiosPromise<GeneralCurrencyDto> {
            return localVarFp.apiV1AdminReferenceDataGeneralCurrenciesDefaultGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCurrenciesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralCurrenciesGet(requestParameters: AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCurrenciesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<GeneralCurrencyDto>> {
            return localVarFp.apiV1AdminReferenceDataGeneralCurrenciesGet(requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralTimeZonesDefaultGet(options?: RawAxiosRequestConfig): AxiosPromise<GeneralTimeZoneDto> {
            return localVarFp.apiV1AdminReferenceDataGeneralTimeZonesDefaultGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralTimeZonesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataGeneralTimeZonesGet(requestParameters: AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralTimeZonesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<GeneralTimeZoneDto>> {
            return localVarFp.apiV1AdminReferenceDataGeneralTimeZonesGet(requestParameters.search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminReferenceDataGeneralCountriesGet operation in AdminGeneralReferenceDataApi.
 * @export
 * @interface AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCountriesGetRequest
 */
export interface AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCountriesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCountriesGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiV1AdminReferenceDataGeneralCulturesGet operation in AdminGeneralReferenceDataApi.
 * @export
 * @interface AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCulturesGetRequest
 */
export interface AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCulturesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCulturesGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiV1AdminReferenceDataGeneralCurrenciesGet operation in AdminGeneralReferenceDataApi.
 * @export
 * @interface AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCurrenciesGetRequest
 */
export interface AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCurrenciesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCurrenciesGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiV1AdminReferenceDataGeneralTimeZonesGet operation in AdminGeneralReferenceDataApi.
 * @export
 * @interface AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralTimeZonesGetRequest
 */
export interface AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralTimeZonesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralTimeZonesGet
     */
    readonly search?: string
}

/**
 * AdminGeneralReferenceDataApi - object-oriented interface
 * @export
 * @class AdminGeneralReferenceDataApi
 * @extends {BaseAPI}
 */
export class AdminGeneralReferenceDataApi extends BaseAPI {
    /**
     * 
     * @param {AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCountriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGeneralReferenceDataApi
     */
    public apiV1AdminReferenceDataGeneralCountriesGet(requestParameters: AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCountriesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminGeneralReferenceDataApiFp(this.configuration).apiV1AdminReferenceDataGeneralCountriesGet(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGeneralReferenceDataApi
     */
    public apiV1AdminReferenceDataGeneralCulturesDefaultGet(options?: RawAxiosRequestConfig) {
        return AdminGeneralReferenceDataApiFp(this.configuration).apiV1AdminReferenceDataGeneralCulturesDefaultGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCulturesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGeneralReferenceDataApi
     */
    public apiV1AdminReferenceDataGeneralCulturesGet(requestParameters: AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCulturesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminGeneralReferenceDataApiFp(this.configuration).apiV1AdminReferenceDataGeneralCulturesGet(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGeneralReferenceDataApi
     */
    public apiV1AdminReferenceDataGeneralCurrenciesDefaultGet(options?: RawAxiosRequestConfig) {
        return AdminGeneralReferenceDataApiFp(this.configuration).apiV1AdminReferenceDataGeneralCurrenciesDefaultGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCurrenciesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGeneralReferenceDataApi
     */
    public apiV1AdminReferenceDataGeneralCurrenciesGet(requestParameters: AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralCurrenciesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminGeneralReferenceDataApiFp(this.configuration).apiV1AdminReferenceDataGeneralCurrenciesGet(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGeneralReferenceDataApi
     */
    public apiV1AdminReferenceDataGeneralTimeZonesDefaultGet(options?: RawAxiosRequestConfig) {
        return AdminGeneralReferenceDataApiFp(this.configuration).apiV1AdminReferenceDataGeneralTimeZonesDefaultGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralTimeZonesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGeneralReferenceDataApi
     */
    public apiV1AdminReferenceDataGeneralTimeZonesGet(requestParameters: AdminGeneralReferenceDataApiApiV1AdminReferenceDataGeneralTimeZonesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminGeneralReferenceDataApiFp(this.configuration).apiV1AdminReferenceDataGeneralTimeZonesGet(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminIntegrationApiClientsApi - axios parameter creator
 * @export
 */
export const AdminIntegrationApiClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminIntegrationApiClientGetPaginatedDto} [adminIntegrationApiClientGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsGetPost: async (adminIntegrationApiClientGetPaginatedDto?: AdminIntegrationApiClientGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/integration/api-clients/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminIntegrationApiClientGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete: async (integrationApiClientId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete', 'integrationApiClientId', integrationApiClientId)
            const localVarPath = `/api/v1/admin/integration/api-clients/{integrationApiClientId}`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdGet: async (integrationApiClientId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1AdminIntegrationApiClientsIntegrationApiClientIdGet', 'integrationApiClientId', integrationApiClientId)
            const localVarPath = `/api/v1/admin/integration/api-clients/{integrationApiClientId}`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} keyId 
         * @param {string} [tenantId] 
         * @param {IntegrationApiClientKeyInputDto} [integrationApiClientKeyInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut: async (integrationApiClientId: string, keyId: string, tenantId?: string, integrationApiClientKeyInputDto?: IntegrationApiClientKeyInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut', 'integrationApiClientId', integrationApiClientId)
            // verify required parameter 'keyId' is not null or undefined
            assertParamExists('apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut', 'keyId', keyId)
            const localVarPath = `/api/v1/admin/integration/api-clients/{integrationApiClientId}/keys/{keyId}`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)))
                .replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationApiClientKeyInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} keyId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost: async (integrationApiClientId: string, keyId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost', 'integrationApiClientId', integrationApiClientId)
            // verify required parameter 'keyId' is not null or undefined
            assertParamExists('apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost', 'keyId', keyId)
            const localVarPath = `/api/v1/admin/integration/api-clients/{integrationApiClientId}/keys/{keyId}/revoke`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)))
                .replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} keyId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet: async (integrationApiClientId: string, keyId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet', 'integrationApiClientId', integrationApiClientId)
            // verify required parameter 'keyId' is not null or undefined
            assertParamExists('apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet', 'keyId', keyId)
            const localVarPath = `/api/v1/admin/integration/api-clients/{integrationApiClientId}/keys/{keyId}/value`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)))
                .replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} [tenantId] 
         * @param {IntegrationApiClientKeyInputDto} [integrationApiClientKeyInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPost: async (integrationApiClientId: string, tenantId?: string, integrationApiClientKeyInputDto?: IntegrationApiClientKeyInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPost', 'integrationApiClientId', integrationApiClientId)
            const localVarPath = `/api/v1/admin/integration/api-clients/{integrationApiClientId}/keys`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationApiClientKeyInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} [tenantId] 
         * @param {IntegrationApiClientUpdateDto} [integrationApiClientUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdPut: async (integrationApiClientId: string, tenantId?: string, integrationApiClientUpdateDto?: IntegrationApiClientUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1AdminIntegrationApiClientsIntegrationApiClientIdPut', 'integrationApiClientId', integrationApiClientId)
            const localVarPath = `/api/v1/admin/integration/api-clients/{integrationApiClientId}`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationApiClientUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {IntegrationApiClientCreateDto} [integrationApiClientCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsPost: async (tenantId?: string, integrationApiClientCreateDto?: IntegrationApiClientCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/integration/api-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationApiClientCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminIntegrationApiClientsApi - functional programming interface
 * @export
 */
export const AdminIntegrationApiClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminIntegrationApiClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminIntegrationApiClientGetPaginatedDto} [adminIntegrationApiClientGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminIntegrationApiClientsGetPost(adminIntegrationApiClientGetPaginatedDto?: AdminIntegrationApiClientGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfIntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminIntegrationApiClientsGetPost(adminIntegrationApiClientGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete(integrationApiClientId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete(integrationApiClientId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminIntegrationApiClientsIntegrationApiClientIdGet(integrationApiClientId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminIntegrationApiClientsIntegrationApiClientIdGet(integrationApiClientId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsIntegrationApiClientIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} keyId 
         * @param {string} [tenantId] 
         * @param {IntegrationApiClientKeyInputDto} [integrationApiClientKeyInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut(integrationApiClientId: string, keyId: string, tenantId?: string, integrationApiClientKeyInputDto?: IntegrationApiClientKeyInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut(integrationApiClientId, keyId, tenantId, integrationApiClientKeyInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} keyId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(integrationApiClientId: string, keyId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(integrationApiClientId, keyId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} keyId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(integrationApiClientId: string, keyId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientKeyValueDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(integrationApiClientId, keyId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} [tenantId] 
         * @param {IntegrationApiClientKeyInputDto} [integrationApiClientKeyInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPost(integrationApiClientId: string, tenantId?: string, integrationApiClientKeyInputDto?: IntegrationApiClientKeyInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPost(integrationApiClientId, tenantId, integrationApiClientKeyInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} integrationApiClientId 
         * @param {string} [tenantId] 
         * @param {IntegrationApiClientUpdateDto} [integrationApiClientUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminIntegrationApiClientsIntegrationApiClientIdPut(integrationApiClientId: string, tenantId?: string, integrationApiClientUpdateDto?: IntegrationApiClientUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminIntegrationApiClientsIntegrationApiClientIdPut(integrationApiClientId, tenantId, integrationApiClientUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsIntegrationApiClientIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {IntegrationApiClientCreateDto} [integrationApiClientCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminIntegrationApiClientsPost(tenantId?: string, integrationApiClientCreateDto?: IntegrationApiClientCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminIntegrationApiClientsPost(tenantId, integrationApiClientCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminIntegrationApiClientsApi - factory interface
 * @export
 */
export const AdminIntegrationApiClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminIntegrationApiClientsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsGetPost(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfIntegrationApiClientDto> {
            return localVarFp.apiV1AdminIntegrationApiClientsGetPost(requestParameters.adminIntegrationApiClientGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete(requestParameters.integrationApiClientId, requestParameters.tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdGet(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientDto> {
            return localVarFp.apiV1AdminIntegrationApiClientsIntegrationApiClientIdGet(requestParameters.integrationApiClientId, requestParameters.tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientDto> {
            return localVarFp.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut(requestParameters.integrationApiClientId, requestParameters.keyId, requestParameters.tenantId, requestParameters.integrationApiClientKeyInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientDto> {
            return localVarFp.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(requestParameters.integrationApiClientId, requestParameters.keyId, requestParameters.tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientKeyValueDto> {
            return localVarFp.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(requestParameters.integrationApiClientId, requestParameters.keyId, requestParameters.tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPost(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientDto> {
            return localVarFp.apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPost(requestParameters.integrationApiClientId, requestParameters.tenantId, requestParameters.integrationApiClientKeyInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsIntegrationApiClientIdPut(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientDto> {
            return localVarFp.apiV1AdminIntegrationApiClientsIntegrationApiClientIdPut(requestParameters.integrationApiClientId, requestParameters.tenantId, requestParameters.integrationApiClientUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminIntegrationApiClientsPost(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientDto> {
            return localVarFp.apiV1AdminIntegrationApiClientsPost(requestParameters.tenantId, requestParameters.integrationApiClientCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminIntegrationApiClientsGetPost operation in AdminIntegrationApiClientsApi.
 * @export
 * @interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsGetPostRequest
 */
export interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsGetPostRequest {
    /**
     * 
     * @type {AdminIntegrationApiClientGetPaginatedDto}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsGetPost
     */
    readonly adminIntegrationApiClientGetPaginatedDto?: AdminIntegrationApiClientGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete operation in AdminIntegrationApiClientsApi.
 * @export
 * @interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdDeleteRequest
 */
export interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete
     */
    readonly integrationApiClientId: string

    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete
     */
    readonly tenantId?: string
}

/**
 * Request parameters for apiV1AdminIntegrationApiClientsIntegrationApiClientIdGet operation in AdminIntegrationApiClientsApi.
 * @export
 * @interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdGetRequest
 */
export interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdGet
     */
    readonly integrationApiClientId: string

    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdGet
     */
    readonly tenantId?: string
}

/**
 * Request parameters for apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut operation in AdminIntegrationApiClientsApi.
 * @export
 * @interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPutRequest
 */
export interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut
     */
    readonly integrationApiClientId: string

    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut
     */
    readonly keyId: string

    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut
     */
    readonly tenantId?: string

    /**
     * 
     * @type {IntegrationApiClientKeyInputDto}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut
     */
    readonly integrationApiClientKeyInputDto?: IntegrationApiClientKeyInputDto
}

/**
 * Request parameters for apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost operation in AdminIntegrationApiClientsApi.
 * @export
 * @interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePostRequest
 */
export interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePostRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost
     */
    readonly integrationApiClientId: string

    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost
     */
    readonly keyId: string

    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost
     */
    readonly tenantId?: string
}

/**
 * Request parameters for apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet operation in AdminIntegrationApiClientsApi.
 * @export
 * @interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGetRequest
 */
export interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet
     */
    readonly integrationApiClientId: string

    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet
     */
    readonly keyId: string

    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet
     */
    readonly tenantId?: string
}

/**
 * Request parameters for apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPost operation in AdminIntegrationApiClientsApi.
 * @export
 * @interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPostRequest
 */
export interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPost
     */
    readonly integrationApiClientId: string

    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPost
     */
    readonly tenantId?: string

    /**
     * 
     * @type {IntegrationApiClientKeyInputDto}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPost
     */
    readonly integrationApiClientKeyInputDto?: IntegrationApiClientKeyInputDto
}

/**
 * Request parameters for apiV1AdminIntegrationApiClientsIntegrationApiClientIdPut operation in AdminIntegrationApiClientsApi.
 * @export
 * @interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdPutRequest
 */
export interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdPut
     */
    readonly integrationApiClientId: string

    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdPut
     */
    readonly tenantId?: string

    /**
     * 
     * @type {IntegrationApiClientUpdateDto}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdPut
     */
    readonly integrationApiClientUpdateDto?: IntegrationApiClientUpdateDto
}

/**
 * Request parameters for apiV1AdminIntegrationApiClientsPost operation in AdminIntegrationApiClientsApi.
 * @export
 * @interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsPostRequest
 */
export interface AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsPost
     */
    readonly tenantId?: string

    /**
     * 
     * @type {IntegrationApiClientCreateDto}
     * @memberof AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsPost
     */
    readonly integrationApiClientCreateDto?: IntegrationApiClientCreateDto
}

/**
 * AdminIntegrationApiClientsApi - object-oriented interface
 * @export
 * @class AdminIntegrationApiClientsApi
 * @extends {BaseAPI}
 */
export class AdminIntegrationApiClientsApi extends BaseAPI {
    /**
     * 
     * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIntegrationApiClientsApi
     */
    public apiV1AdminIntegrationApiClientsGetPost(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminIntegrationApiClientsApiFp(this.configuration).apiV1AdminIntegrationApiClientsGetPost(requestParameters.adminIntegrationApiClientGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIntegrationApiClientsApi
     */
    public apiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminIntegrationApiClientsApiFp(this.configuration).apiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete(requestParameters.integrationApiClientId, requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIntegrationApiClientsApi
     */
    public apiV1AdminIntegrationApiClientsIntegrationApiClientIdGet(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminIntegrationApiClientsApiFp(this.configuration).apiV1AdminIntegrationApiClientsIntegrationApiClientIdGet(requestParameters.integrationApiClientId, requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIntegrationApiClientsApi
     */
    public apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminIntegrationApiClientsApiFp(this.configuration).apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut(requestParameters.integrationApiClientId, requestParameters.keyId, requestParameters.tenantId, requestParameters.integrationApiClientKeyInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIntegrationApiClientsApi
     */
    public apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePostRequest, options?: RawAxiosRequestConfig) {
        return AdminIntegrationApiClientsApiFp(this.configuration).apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(requestParameters.integrationApiClientId, requestParameters.keyId, requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIntegrationApiClientsApi
     */
    public apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGetRequest, options?: RawAxiosRequestConfig) {
        return AdminIntegrationApiClientsApiFp(this.configuration).apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(requestParameters.integrationApiClientId, requestParameters.keyId, requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIntegrationApiClientsApi
     */
    public apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPost(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPostRequest, options?: RawAxiosRequestConfig) {
        return AdminIntegrationApiClientsApiFp(this.configuration).apiV1AdminIntegrationApiClientsIntegrationApiClientIdKeysPost(requestParameters.integrationApiClientId, requestParameters.tenantId, requestParameters.integrationApiClientKeyInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIntegrationApiClientsApi
     */
    public apiV1AdminIntegrationApiClientsIntegrationApiClientIdPut(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsIntegrationApiClientIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminIntegrationApiClientsApiFp(this.configuration).apiV1AdminIntegrationApiClientsIntegrationApiClientIdPut(requestParameters.integrationApiClientId, requestParameters.tenantId, requestParameters.integrationApiClientUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminIntegrationApiClientsApi
     */
    public apiV1AdminIntegrationApiClientsPost(requestParameters: AdminIntegrationApiClientsApiApiV1AdminIntegrationApiClientsPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminIntegrationApiClientsApiFp(this.configuration).apiV1AdminIntegrationApiClientsPost(requestParameters.tenantId, requestParameters.integrationApiClientCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminInvitesApi - axios parameter creator
 * @export
 */
export const AdminInvitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminCreateAnonymousInviteDto} [adminCreateAnonymousInviteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvitesAnonymousPost: async (adminCreateAnonymousInviteDto?: AdminCreateAnonymousInviteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/invites/anonymous`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateAnonymousInviteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminInviteGetPaginatedDto} [adminInviteGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvitesGetPost: async (adminInviteGetPaginatedDto?: AdminInviteGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/invites/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminInviteGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvitesInviteIdGet: async (inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('apiV1AdminInvitesInviteIdGet', 'inviteId', inviteId)
            const localVarPath = `/api/v1/admin/invites/{inviteId}`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateInviteDto} [adminCreateInviteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvitesPost: async (adminCreateInviteDto?: AdminCreateInviteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/invites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateInviteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminInvitesApi - functional programming interface
 * @export
 */
export const AdminInvitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminInvitesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminCreateAnonymousInviteDto} [adminCreateAnonymousInviteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminInvitesAnonymousPost(adminCreateAnonymousInviteDto?: AdminCreateAnonymousInviteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminInviteAnonymousResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminInvitesAnonymousPost(adminCreateAnonymousInviteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminInvitesApi.apiV1AdminInvitesAnonymousPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminInviteGetPaginatedDto} [adminInviteGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminInvitesGetPost(adminInviteGetPaginatedDto?: AdminInviteGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminInviteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminInvitesGetPost(adminInviteGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminInvitesApi.apiV1AdminInvitesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminInvitesInviteIdGet(inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminInviteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminInvitesInviteIdGet(inviteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminInvitesApi.apiV1AdminInvitesInviteIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateInviteDto} [adminCreateInviteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminInvitesPost(adminCreateInviteDto?: AdminCreateInviteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminInvitePersonalResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminInvitesPost(adminCreateInviteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminInvitesApi.apiV1AdminInvitesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminInvitesApi - factory interface
 * @export
 */
export const AdminInvitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminInvitesApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminInvitesApiApiV1AdminInvitesAnonymousPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvitesAnonymousPost(requestParameters: AdminInvitesApiApiV1AdminInvitesAnonymousPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminInviteAnonymousResultDto> {
            return localVarFp.apiV1AdminInvitesAnonymousPost(requestParameters.adminCreateAnonymousInviteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminInvitesApiApiV1AdminInvitesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvitesGetPost(requestParameters: AdminInvitesApiApiV1AdminInvitesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminInviteDto> {
            return localVarFp.apiV1AdminInvitesGetPost(requestParameters.adminInviteGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminInvitesApiApiV1AdminInvitesInviteIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvitesInviteIdGet(requestParameters: AdminInvitesApiApiV1AdminInvitesInviteIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminInviteDto> {
            return localVarFp.apiV1AdminInvitesInviteIdGet(requestParameters.inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminInvitesApiApiV1AdminInvitesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvitesPost(requestParameters: AdminInvitesApiApiV1AdminInvitesPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminInvitePersonalResultDto> {
            return localVarFp.apiV1AdminInvitesPost(requestParameters.adminCreateInviteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminInvitesAnonymousPost operation in AdminInvitesApi.
 * @export
 * @interface AdminInvitesApiApiV1AdminInvitesAnonymousPostRequest
 */
export interface AdminInvitesApiApiV1AdminInvitesAnonymousPostRequest {
    /**
     * 
     * @type {AdminCreateAnonymousInviteDto}
     * @memberof AdminInvitesApiApiV1AdminInvitesAnonymousPost
     */
    readonly adminCreateAnonymousInviteDto?: AdminCreateAnonymousInviteDto
}

/**
 * Request parameters for apiV1AdminInvitesGetPost operation in AdminInvitesApi.
 * @export
 * @interface AdminInvitesApiApiV1AdminInvitesGetPostRequest
 */
export interface AdminInvitesApiApiV1AdminInvitesGetPostRequest {
    /**
     * 
     * @type {AdminInviteGetPaginatedDto}
     * @memberof AdminInvitesApiApiV1AdminInvitesGetPost
     */
    readonly adminInviteGetPaginatedDto?: AdminInviteGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminInvitesInviteIdGet operation in AdminInvitesApi.
 * @export
 * @interface AdminInvitesApiApiV1AdminInvitesInviteIdGetRequest
 */
export interface AdminInvitesApiApiV1AdminInvitesInviteIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminInvitesApiApiV1AdminInvitesInviteIdGet
     */
    readonly inviteId: string
}

/**
 * Request parameters for apiV1AdminInvitesPost operation in AdminInvitesApi.
 * @export
 * @interface AdminInvitesApiApiV1AdminInvitesPostRequest
 */
export interface AdminInvitesApiApiV1AdminInvitesPostRequest {
    /**
     * 
     * @type {AdminCreateInviteDto}
     * @memberof AdminInvitesApiApiV1AdminInvitesPost
     */
    readonly adminCreateInviteDto?: AdminCreateInviteDto
}

/**
 * AdminInvitesApi - object-oriented interface
 * @export
 * @class AdminInvitesApi
 * @extends {BaseAPI}
 */
export class AdminInvitesApi extends BaseAPI {
    /**
     * 
     * @param {AdminInvitesApiApiV1AdminInvitesAnonymousPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvitesApi
     */
    public apiV1AdminInvitesAnonymousPost(requestParameters: AdminInvitesApiApiV1AdminInvitesAnonymousPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminInvitesApiFp(this.configuration).apiV1AdminInvitesAnonymousPost(requestParameters.adminCreateAnonymousInviteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminInvitesApiApiV1AdminInvitesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvitesApi
     */
    public apiV1AdminInvitesGetPost(requestParameters: AdminInvitesApiApiV1AdminInvitesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminInvitesApiFp(this.configuration).apiV1AdminInvitesGetPost(requestParameters.adminInviteGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminInvitesApiApiV1AdminInvitesInviteIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvitesApi
     */
    public apiV1AdminInvitesInviteIdGet(requestParameters: AdminInvitesApiApiV1AdminInvitesInviteIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminInvitesApiFp(this.configuration).apiV1AdminInvitesInviteIdGet(requestParameters.inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminInvitesApiApiV1AdminInvitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvitesApi
     */
    public apiV1AdminInvitesPost(requestParameters: AdminInvitesApiApiV1AdminInvitesPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminInvitesApiFp(this.configuration).apiV1AdminInvitesPost(requestParameters.adminCreateInviteDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminInvoicesApi - axios parameter creator
 * @export
 */
export const AdminInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminInvoiceGetPaginatedDto} [adminInvoiceGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvoicesGetPost: async (adminInvoiceGetPaginatedDto?: AdminInvoiceGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/invoices/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminInvoiceGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvoicesInvoiceIdCancelPost: async (invoiceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiV1AdminInvoicesInvoiceIdCancelPost', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/admin/invoices/{invoiceId}/cancel`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvoicesInvoiceIdConfirmPost: async (invoiceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiV1AdminInvoicesInvoiceIdConfirmPost', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/admin/invoices/{invoiceId}/confirm`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvoicesInvoiceIdDownloadPdfGet: async (invoiceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiV1AdminInvoicesInvoiceIdDownloadPdfGet', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/admin/invoices/{invoiceId}/download/pdf`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvoicesInvoiceIdGet: async (invoiceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiV1AdminInvoicesInvoiceIdGet', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/admin/invoices/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminInvoicesApi - functional programming interface
 * @export
 */
export const AdminInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminInvoiceGetPaginatedDto} [adminInvoiceGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminInvoicesGetPost(adminInvoiceGetPaginatedDto?: AdminInvoiceGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminInvoicesGetPost(adminInvoiceGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminInvoicesApi.apiV1AdminInvoicesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminInvoicesInvoiceIdCancelPost(invoiceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminInvoicesInvoiceIdCancelPost(invoiceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminInvoicesApi.apiV1AdminInvoicesInvoiceIdCancelPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminInvoicesInvoiceIdConfirmPost(invoiceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminInvoicesInvoiceIdConfirmPost(invoiceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminInvoicesApi.apiV1AdminInvoicesInvoiceIdConfirmPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminInvoicesInvoiceIdDownloadPdfGet(invoiceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminInvoicesInvoiceIdDownloadPdfGet(invoiceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminInvoicesApi.apiV1AdminInvoicesInvoiceIdDownloadPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminInvoicesInvoiceIdGet(invoiceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminInvoicesInvoiceIdGet(invoiceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminInvoicesApi.apiV1AdminInvoicesInvoiceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminInvoicesApi - factory interface
 * @export
 */
export const AdminInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminInvoicesApiApiV1AdminInvoicesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvoicesGetPost(requestParameters: AdminInvoicesApiApiV1AdminInvoicesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminInvoiceDto> {
            return localVarFp.apiV1AdminInvoicesGetPost(requestParameters.adminInvoiceGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminInvoicesApiApiV1AdminInvoicesInvoiceIdCancelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvoicesInvoiceIdCancelPost(requestParameters: AdminInvoicesApiApiV1AdminInvoicesInvoiceIdCancelPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminInvoiceDto> {
            return localVarFp.apiV1AdminInvoicesInvoiceIdCancelPost(requestParameters.invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminInvoicesApiApiV1AdminInvoicesInvoiceIdConfirmPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvoicesInvoiceIdConfirmPost(requestParameters: AdminInvoicesApiApiV1AdminInvoicesInvoiceIdConfirmPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminInvoiceDto> {
            return localVarFp.apiV1AdminInvoicesInvoiceIdConfirmPost(requestParameters.invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminInvoicesApiApiV1AdminInvoicesInvoiceIdDownloadPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvoicesInvoiceIdDownloadPdfGet(requestParameters: AdminInvoicesApiApiV1AdminInvoicesInvoiceIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminInvoicesInvoiceIdDownloadPdfGet(requestParameters.invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminInvoicesApiApiV1AdminInvoicesInvoiceIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminInvoicesInvoiceIdGet(requestParameters: AdminInvoicesApiApiV1AdminInvoicesInvoiceIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminInvoiceDto> {
            return localVarFp.apiV1AdminInvoicesInvoiceIdGet(requestParameters.invoiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminInvoicesGetPost operation in AdminInvoicesApi.
 * @export
 * @interface AdminInvoicesApiApiV1AdminInvoicesGetPostRequest
 */
export interface AdminInvoicesApiApiV1AdminInvoicesGetPostRequest {
    /**
     * 
     * @type {AdminInvoiceGetPaginatedDto}
     * @memberof AdminInvoicesApiApiV1AdminInvoicesGetPost
     */
    readonly adminInvoiceGetPaginatedDto?: AdminInvoiceGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminInvoicesInvoiceIdCancelPost operation in AdminInvoicesApi.
 * @export
 * @interface AdminInvoicesApiApiV1AdminInvoicesInvoiceIdCancelPostRequest
 */
export interface AdminInvoicesApiApiV1AdminInvoicesInvoiceIdCancelPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminInvoicesApiApiV1AdminInvoicesInvoiceIdCancelPost
     */
    readonly invoiceId: string
}

/**
 * Request parameters for apiV1AdminInvoicesInvoiceIdConfirmPost operation in AdminInvoicesApi.
 * @export
 * @interface AdminInvoicesApiApiV1AdminInvoicesInvoiceIdConfirmPostRequest
 */
export interface AdminInvoicesApiApiV1AdminInvoicesInvoiceIdConfirmPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminInvoicesApiApiV1AdminInvoicesInvoiceIdConfirmPost
     */
    readonly invoiceId: string
}

/**
 * Request parameters for apiV1AdminInvoicesInvoiceIdDownloadPdfGet operation in AdminInvoicesApi.
 * @export
 * @interface AdminInvoicesApiApiV1AdminInvoicesInvoiceIdDownloadPdfGetRequest
 */
export interface AdminInvoicesApiApiV1AdminInvoicesInvoiceIdDownloadPdfGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminInvoicesApiApiV1AdminInvoicesInvoiceIdDownloadPdfGet
     */
    readonly invoiceId: string
}

/**
 * Request parameters for apiV1AdminInvoicesInvoiceIdGet operation in AdminInvoicesApi.
 * @export
 * @interface AdminInvoicesApiApiV1AdminInvoicesInvoiceIdGetRequest
 */
export interface AdminInvoicesApiApiV1AdminInvoicesInvoiceIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminInvoicesApiApiV1AdminInvoicesInvoiceIdGet
     */
    readonly invoiceId: string
}

/**
 * AdminInvoicesApi - object-oriented interface
 * @export
 * @class AdminInvoicesApi
 * @extends {BaseAPI}
 */
export class AdminInvoicesApi extends BaseAPI {
    /**
     * 
     * @param {AdminInvoicesApiApiV1AdminInvoicesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvoicesApi
     */
    public apiV1AdminInvoicesGetPost(requestParameters: AdminInvoicesApiApiV1AdminInvoicesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminInvoicesApiFp(this.configuration).apiV1AdminInvoicesGetPost(requestParameters.adminInvoiceGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminInvoicesApiApiV1AdminInvoicesInvoiceIdCancelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvoicesApi
     */
    public apiV1AdminInvoicesInvoiceIdCancelPost(requestParameters: AdminInvoicesApiApiV1AdminInvoicesInvoiceIdCancelPostRequest, options?: RawAxiosRequestConfig) {
        return AdminInvoicesApiFp(this.configuration).apiV1AdminInvoicesInvoiceIdCancelPost(requestParameters.invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminInvoicesApiApiV1AdminInvoicesInvoiceIdConfirmPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvoicesApi
     */
    public apiV1AdminInvoicesInvoiceIdConfirmPost(requestParameters: AdminInvoicesApiApiV1AdminInvoicesInvoiceIdConfirmPostRequest, options?: RawAxiosRequestConfig) {
        return AdminInvoicesApiFp(this.configuration).apiV1AdminInvoicesInvoiceIdConfirmPost(requestParameters.invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminInvoicesApiApiV1AdminInvoicesInvoiceIdDownloadPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvoicesApi
     */
    public apiV1AdminInvoicesInvoiceIdDownloadPdfGet(requestParameters: AdminInvoicesApiApiV1AdminInvoicesInvoiceIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig) {
        return AdminInvoicesApiFp(this.configuration).apiV1AdminInvoicesInvoiceIdDownloadPdfGet(requestParameters.invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminInvoicesApiApiV1AdminInvoicesInvoiceIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInvoicesApi
     */
    public apiV1AdminInvoicesInvoiceIdGet(requestParameters: AdminInvoicesApiApiV1AdminInvoicesInvoiceIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminInvoicesApiFp(this.configuration).apiV1AdminInvoicesInvoiceIdGet(requestParameters.invoiceId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminNotificationsApi - axios parameter creator
 * @export
 */
export const AdminNotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminNotificationGetPaginatedDto} [adminNotificationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNotificationsGetPost: async (adminNotificationGetPaginatedDto?: AdminNotificationGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/notifications/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminNotificationGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNotificationsNotificationIdGet: async (notificationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('apiV1AdminNotificationsNotificationIdGet', 'notificationId', notificationId)
            const localVarPath = `/api/v1/admin/notifications/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNotificationsNotificationIdResendPost: async (notificationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('apiV1AdminNotificationsNotificationIdResendPost', 'notificationId', notificationId)
            const localVarPath = `/api/v1/admin/notifications/{notificationId}/resend`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateNotificationDto} [adminCreateNotificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNotificationsPost: async (adminCreateNotificationDto?: AdminCreateNotificationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateNotificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminNotificationsApi - functional programming interface
 * @export
 */
export const AdminNotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminNotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminNotificationGetPaginatedDto} [adminNotificationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminNotificationsGetPost(adminNotificationGetPaginatedDto?: AdminNotificationGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminNotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminNotificationsGetPost(adminNotificationGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminNotificationsApi.apiV1AdminNotificationsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminNotificationsNotificationIdGet(notificationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminNotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminNotificationsNotificationIdGet(notificationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminNotificationsApi.apiV1AdminNotificationsNotificationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminNotificationsNotificationIdResendPost(notificationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminNotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminNotificationsNotificationIdResendPost(notificationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminNotificationsApi.apiV1AdminNotificationsNotificationIdResendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateNotificationDto} [adminCreateNotificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminNotificationsPost(adminCreateNotificationDto?: AdminCreateNotificationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminNotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminNotificationsPost(adminCreateNotificationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminNotificationsApi.apiV1AdminNotificationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminNotificationsApi - factory interface
 * @export
 */
export const AdminNotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminNotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminNotificationsApiApiV1AdminNotificationsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNotificationsGetPost(requestParameters: AdminNotificationsApiApiV1AdminNotificationsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminNotificationDto> {
            return localVarFp.apiV1AdminNotificationsGetPost(requestParameters.adminNotificationGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminNotificationsApiApiV1AdminNotificationsNotificationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNotificationsNotificationIdGet(requestParameters: AdminNotificationsApiApiV1AdminNotificationsNotificationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminNotificationDto> {
            return localVarFp.apiV1AdminNotificationsNotificationIdGet(requestParameters.notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminNotificationsApiApiV1AdminNotificationsNotificationIdResendPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNotificationsNotificationIdResendPost(requestParameters: AdminNotificationsApiApiV1AdminNotificationsNotificationIdResendPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminNotificationDto> {
            return localVarFp.apiV1AdminNotificationsNotificationIdResendPost(requestParameters.notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminNotificationsApiApiV1AdminNotificationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminNotificationsPost(requestParameters: AdminNotificationsApiApiV1AdminNotificationsPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminNotificationDto> {
            return localVarFp.apiV1AdminNotificationsPost(requestParameters.adminCreateNotificationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminNotificationsGetPost operation in AdminNotificationsApi.
 * @export
 * @interface AdminNotificationsApiApiV1AdminNotificationsGetPostRequest
 */
export interface AdminNotificationsApiApiV1AdminNotificationsGetPostRequest {
    /**
     * 
     * @type {AdminNotificationGetPaginatedDto}
     * @memberof AdminNotificationsApiApiV1AdminNotificationsGetPost
     */
    readonly adminNotificationGetPaginatedDto?: AdminNotificationGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminNotificationsNotificationIdGet operation in AdminNotificationsApi.
 * @export
 * @interface AdminNotificationsApiApiV1AdminNotificationsNotificationIdGetRequest
 */
export interface AdminNotificationsApiApiV1AdminNotificationsNotificationIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminNotificationsApiApiV1AdminNotificationsNotificationIdGet
     */
    readonly notificationId: string
}

/**
 * Request parameters for apiV1AdminNotificationsNotificationIdResendPost operation in AdminNotificationsApi.
 * @export
 * @interface AdminNotificationsApiApiV1AdminNotificationsNotificationIdResendPostRequest
 */
export interface AdminNotificationsApiApiV1AdminNotificationsNotificationIdResendPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminNotificationsApiApiV1AdminNotificationsNotificationIdResendPost
     */
    readonly notificationId: string
}

/**
 * Request parameters for apiV1AdminNotificationsPost operation in AdminNotificationsApi.
 * @export
 * @interface AdminNotificationsApiApiV1AdminNotificationsPostRequest
 */
export interface AdminNotificationsApiApiV1AdminNotificationsPostRequest {
    /**
     * 
     * @type {AdminCreateNotificationDto}
     * @memberof AdminNotificationsApiApiV1AdminNotificationsPost
     */
    readonly adminCreateNotificationDto?: AdminCreateNotificationDto
}

/**
 * AdminNotificationsApi - object-oriented interface
 * @export
 * @class AdminNotificationsApi
 * @extends {BaseAPI}
 */
export class AdminNotificationsApi extends BaseAPI {
    /**
     * 
     * @param {AdminNotificationsApiApiV1AdminNotificationsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminNotificationsApi
     */
    public apiV1AdminNotificationsGetPost(requestParameters: AdminNotificationsApiApiV1AdminNotificationsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminNotificationsApiFp(this.configuration).apiV1AdminNotificationsGetPost(requestParameters.adminNotificationGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminNotificationsApiApiV1AdminNotificationsNotificationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminNotificationsApi
     */
    public apiV1AdminNotificationsNotificationIdGet(requestParameters: AdminNotificationsApiApiV1AdminNotificationsNotificationIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminNotificationsApiFp(this.configuration).apiV1AdminNotificationsNotificationIdGet(requestParameters.notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminNotificationsApiApiV1AdminNotificationsNotificationIdResendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminNotificationsApi
     */
    public apiV1AdminNotificationsNotificationIdResendPost(requestParameters: AdminNotificationsApiApiV1AdminNotificationsNotificationIdResendPostRequest, options?: RawAxiosRequestConfig) {
        return AdminNotificationsApiFp(this.configuration).apiV1AdminNotificationsNotificationIdResendPost(requestParameters.notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminNotificationsApiApiV1AdminNotificationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminNotificationsApi
     */
    public apiV1AdminNotificationsPost(requestParameters: AdminNotificationsApiApiV1AdminNotificationsPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminNotificationsApiFp(this.configuration).apiV1AdminNotificationsPost(requestParameters.adminCreateNotificationDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminRepairMaterialsApi - axios parameter creator
 * @export
 */
export const AdminRepairMaterialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RepairMaterialGetPaginatedDto} [repairMaterialGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairMaterialsGetPost: async (repairMaterialGetPaginatedDto?: RepairMaterialGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/repair/materials/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairMaterialGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRepairMaterialDto} [createRepairMaterialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairMaterialsPost: async (createRepairMaterialDto?: CreateRepairMaterialDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/repair/materials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRepairMaterialDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repairMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairMaterialsRepairMaterialIdDelete: async (repairMaterialId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repairMaterialId' is not null or undefined
            assertParamExists('apiV1AdminRepairMaterialsRepairMaterialIdDelete', 'repairMaterialId', repairMaterialId)
            const localVarPath = `/api/v1/admin/repair/materials/{repairMaterialId}`
                .replace(`{${"repairMaterialId"}}`, encodeURIComponent(String(repairMaterialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repairMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairMaterialsRepairMaterialIdGet: async (repairMaterialId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repairMaterialId' is not null or undefined
            assertParamExists('apiV1AdminRepairMaterialsRepairMaterialIdGet', 'repairMaterialId', repairMaterialId)
            const localVarPath = `/api/v1/admin/repair/materials/{repairMaterialId}`
                .replace(`{${"repairMaterialId"}}`, encodeURIComponent(String(repairMaterialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repairMaterialId 
         * @param {UpdateRepairMaterialDto} [updateRepairMaterialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairMaterialsRepairMaterialIdPut: async (repairMaterialId: string, updateRepairMaterialDto?: UpdateRepairMaterialDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repairMaterialId' is not null or undefined
            assertParamExists('apiV1AdminRepairMaterialsRepairMaterialIdPut', 'repairMaterialId', repairMaterialId)
            const localVarPath = `/api/v1/admin/repair/materials/{repairMaterialId}`
                .replace(`{${"repairMaterialId"}}`, encodeURIComponent(String(repairMaterialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepairMaterialDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RepairMaterialSearchPaginatedDto} [repairMaterialSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairMaterialsSearchPost: async (repairMaterialSearchPaginatedDto?: RepairMaterialSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/repair/materials/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairMaterialSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminRepairMaterialsApi - functional programming interface
 * @export
 */
export const AdminRepairMaterialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminRepairMaterialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RepairMaterialGetPaginatedDto} [repairMaterialGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairMaterialsGetPost(repairMaterialGetPaginatedDto?: RepairMaterialGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairMaterialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairMaterialsGetPost(repairMaterialGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairMaterialsApi.apiV1AdminRepairMaterialsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateRepairMaterialDto} [createRepairMaterialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairMaterialsPost(createRepairMaterialDto?: CreateRepairMaterialDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairMaterialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairMaterialsPost(createRepairMaterialDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairMaterialsApi.apiV1AdminRepairMaterialsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} repairMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairMaterialsRepairMaterialIdDelete(repairMaterialId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairMaterialsRepairMaterialIdDelete(repairMaterialId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairMaterialsApi.apiV1AdminRepairMaterialsRepairMaterialIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} repairMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairMaterialsRepairMaterialIdGet(repairMaterialId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairMaterialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairMaterialsRepairMaterialIdGet(repairMaterialId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairMaterialsApi.apiV1AdminRepairMaterialsRepairMaterialIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} repairMaterialId 
         * @param {UpdateRepairMaterialDto} [updateRepairMaterialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairMaterialsRepairMaterialIdPut(repairMaterialId: string, updateRepairMaterialDto?: UpdateRepairMaterialDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairMaterialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairMaterialsRepairMaterialIdPut(repairMaterialId, updateRepairMaterialDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairMaterialsApi.apiV1AdminRepairMaterialsRepairMaterialIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RepairMaterialSearchPaginatedDto} [repairMaterialSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairMaterialsSearchPost(repairMaterialSearchPaginatedDto?: RepairMaterialSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairMaterialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairMaterialsSearchPost(repairMaterialSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairMaterialsApi.apiV1AdminRepairMaterialsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminRepairMaterialsApi - factory interface
 * @export
 */
export const AdminRepairMaterialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminRepairMaterialsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminRepairMaterialsApiApiV1AdminRepairMaterialsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairMaterialsGetPost(requestParameters: AdminRepairMaterialsApiApiV1AdminRepairMaterialsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairMaterialDto> {
            return localVarFp.apiV1AdminRepairMaterialsGetPost(requestParameters.repairMaterialGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairMaterialsApiApiV1AdminRepairMaterialsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairMaterialsPost(requestParameters: AdminRepairMaterialsApiApiV1AdminRepairMaterialsPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RepairMaterialDto> {
            return localVarFp.apiV1AdminRepairMaterialsPost(requestParameters.createRepairMaterialDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairMaterialsRepairMaterialIdDelete(requestParameters: AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminRepairMaterialsRepairMaterialIdDelete(requestParameters.repairMaterialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairMaterialsRepairMaterialIdGet(requestParameters: AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairMaterialDto> {
            return localVarFp.apiV1AdminRepairMaterialsRepairMaterialIdGet(requestParameters.repairMaterialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairMaterialsRepairMaterialIdPut(requestParameters: AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairMaterialDto> {
            return localVarFp.apiV1AdminRepairMaterialsRepairMaterialIdPut(requestParameters.repairMaterialId, requestParameters.updateRepairMaterialDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairMaterialsApiApiV1AdminRepairMaterialsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairMaterialsSearchPost(requestParameters: AdminRepairMaterialsApiApiV1AdminRepairMaterialsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairMaterialDto> {
            return localVarFp.apiV1AdminRepairMaterialsSearchPost(requestParameters.repairMaterialSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminRepairMaterialsGetPost operation in AdminRepairMaterialsApi.
 * @export
 * @interface AdminRepairMaterialsApiApiV1AdminRepairMaterialsGetPostRequest
 */
export interface AdminRepairMaterialsApiApiV1AdminRepairMaterialsGetPostRequest {
    /**
     * 
     * @type {RepairMaterialGetPaginatedDto}
     * @memberof AdminRepairMaterialsApiApiV1AdminRepairMaterialsGetPost
     */
    readonly repairMaterialGetPaginatedDto?: RepairMaterialGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminRepairMaterialsPost operation in AdminRepairMaterialsApi.
 * @export
 * @interface AdminRepairMaterialsApiApiV1AdminRepairMaterialsPostRequest
 */
export interface AdminRepairMaterialsApiApiV1AdminRepairMaterialsPostRequest {
    /**
     * 
     * @type {CreateRepairMaterialDto}
     * @memberof AdminRepairMaterialsApiApiV1AdminRepairMaterialsPost
     */
    readonly createRepairMaterialDto?: CreateRepairMaterialDto
}

/**
 * Request parameters for apiV1AdminRepairMaterialsRepairMaterialIdDelete operation in AdminRepairMaterialsApi.
 * @export
 * @interface AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdDeleteRequest
 */
export interface AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdDelete
     */
    readonly repairMaterialId: string
}

/**
 * Request parameters for apiV1AdminRepairMaterialsRepairMaterialIdGet operation in AdminRepairMaterialsApi.
 * @export
 * @interface AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdGetRequest
 */
export interface AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdGet
     */
    readonly repairMaterialId: string
}

/**
 * Request parameters for apiV1AdminRepairMaterialsRepairMaterialIdPut operation in AdminRepairMaterialsApi.
 * @export
 * @interface AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdPutRequest
 */
export interface AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdPut
     */
    readonly repairMaterialId: string

    /**
     * 
     * @type {UpdateRepairMaterialDto}
     * @memberof AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdPut
     */
    readonly updateRepairMaterialDto?: UpdateRepairMaterialDto
}

/**
 * Request parameters for apiV1AdminRepairMaterialsSearchPost operation in AdminRepairMaterialsApi.
 * @export
 * @interface AdminRepairMaterialsApiApiV1AdminRepairMaterialsSearchPostRequest
 */
export interface AdminRepairMaterialsApiApiV1AdminRepairMaterialsSearchPostRequest {
    /**
     * 
     * @type {RepairMaterialSearchPaginatedDto}
     * @memberof AdminRepairMaterialsApiApiV1AdminRepairMaterialsSearchPost
     */
    readonly repairMaterialSearchPaginatedDto?: RepairMaterialSearchPaginatedDto
}

/**
 * AdminRepairMaterialsApi - object-oriented interface
 * @export
 * @class AdminRepairMaterialsApi
 * @extends {BaseAPI}
 */
export class AdminRepairMaterialsApi extends BaseAPI {
    /**
     * 
     * @param {AdminRepairMaterialsApiApiV1AdminRepairMaterialsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairMaterialsApi
     */
    public apiV1AdminRepairMaterialsGetPost(requestParameters: AdminRepairMaterialsApiApiV1AdminRepairMaterialsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminRepairMaterialsApiFp(this.configuration).apiV1AdminRepairMaterialsGetPost(requestParameters.repairMaterialGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairMaterialsApiApiV1AdminRepairMaterialsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairMaterialsApi
     */
    public apiV1AdminRepairMaterialsPost(requestParameters: AdminRepairMaterialsApiApiV1AdminRepairMaterialsPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminRepairMaterialsApiFp(this.configuration).apiV1AdminRepairMaterialsPost(requestParameters.createRepairMaterialDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairMaterialsApi
     */
    public apiV1AdminRepairMaterialsRepairMaterialIdDelete(requestParameters: AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminRepairMaterialsApiFp(this.configuration).apiV1AdminRepairMaterialsRepairMaterialIdDelete(requestParameters.repairMaterialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairMaterialsApi
     */
    public apiV1AdminRepairMaterialsRepairMaterialIdGet(requestParameters: AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminRepairMaterialsApiFp(this.configuration).apiV1AdminRepairMaterialsRepairMaterialIdGet(requestParameters.repairMaterialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairMaterialsApi
     */
    public apiV1AdminRepairMaterialsRepairMaterialIdPut(requestParameters: AdminRepairMaterialsApiApiV1AdminRepairMaterialsRepairMaterialIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminRepairMaterialsApiFp(this.configuration).apiV1AdminRepairMaterialsRepairMaterialIdPut(requestParameters.repairMaterialId, requestParameters.updateRepairMaterialDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairMaterialsApiApiV1AdminRepairMaterialsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairMaterialsApi
     */
    public apiV1AdminRepairMaterialsSearchPost(requestParameters: AdminRepairMaterialsApiApiV1AdminRepairMaterialsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminRepairMaterialsApiFp(this.configuration).apiV1AdminRepairMaterialsSearchPost(requestParameters.repairMaterialSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminRepairSparePartApi - axios parameter creator
 * @export
 */
export const AdminRepairSparePartApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RepairSparePartGetPaginatedDto} [repairSparePartGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsGetPost: async (repairSparePartGetPaginatedDto?: RepairSparePartGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/repair/spare-parts/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairSparePartGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRepairSparePartDto} [createRepairSparePartDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsPost: async (createRepairSparePartDto?: CreateRepairSparePartDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/repair/spare-parts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRepairSparePartDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repairSparePartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsRepairSparePartIdDelete: async (repairSparePartId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repairSparePartId' is not null or undefined
            assertParamExists('apiV1AdminRepairSparePartsRepairSparePartIdDelete', 'repairSparePartId', repairSparePartId)
            const localVarPath = `/api/v1/admin/repair/spare-parts/{repairSparePartId}`
                .replace(`{${"repairSparePartId"}}`, encodeURIComponent(String(repairSparePartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repairSparePartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGet: async (repairSparePartId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repairSparePartId' is not null or undefined
            assertParamExists('apiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGet', 'repairSparePartId', repairSparePartId)
            const localVarPath = `/api/v1/admin/repair/spare-parts/{repairSparePartId}/delete/info`
                .replace(`{${"repairSparePartId"}}`, encodeURIComponent(String(repairSparePartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repairSparePartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsRepairSparePartIdGet: async (repairSparePartId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repairSparePartId' is not null or undefined
            assertParamExists('apiV1AdminRepairSparePartsRepairSparePartIdGet', 'repairSparePartId', repairSparePartId)
            const localVarPath = `/api/v1/admin/repair/spare-parts/{repairSparePartId}`
                .replace(`{${"repairSparePartId"}}`, encodeURIComponent(String(repairSparePartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repairSparePartId 
         * @param {UpdateRepairSparePartDto} [updateRepairSparePartDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsRepairSparePartIdPut: async (repairSparePartId: string, updateRepairSparePartDto?: UpdateRepairSparePartDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repairSparePartId' is not null or undefined
            assertParamExists('apiV1AdminRepairSparePartsRepairSparePartIdPut', 'repairSparePartId', repairSparePartId)
            const localVarPath = `/api/v1/admin/repair/spare-parts/{repairSparePartId}`
                .replace(`{${"repairSparePartId"}}`, encodeURIComponent(String(repairSparePartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepairSparePartDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RepairSparePartSearchPaginatedDto} [repairSparePartSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsSearchPost: async (repairSparePartSearchPaginatedDto?: RepairSparePartSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/repair/spare-parts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairSparePartSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminRepairSparePartApi - functional programming interface
 * @export
 */
export const AdminRepairSparePartApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminRepairSparePartApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RepairSparePartGetPaginatedDto} [repairSparePartGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairSparePartsGetPost(repairSparePartGetPaginatedDto?: RepairSparePartGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairSparePartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairSparePartsGetPost(repairSparePartGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairSparePartApi.apiV1AdminRepairSparePartsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateRepairSparePartDto} [createRepairSparePartDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairSparePartsPost(createRepairSparePartDto?: CreateRepairSparePartDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairSparePartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairSparePartsPost(createRepairSparePartDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairSparePartApi.apiV1AdminRepairSparePartsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} repairSparePartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairSparePartsRepairSparePartIdDelete(repairSparePartId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairSparePartsRepairSparePartIdDelete(repairSparePartId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairSparePartApi.apiV1AdminRepairSparePartsRepairSparePartIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} repairSparePartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGet(repairSparePartId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteRepairSparePartInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGet(repairSparePartId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairSparePartApi.apiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} repairSparePartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairSparePartsRepairSparePartIdGet(repairSparePartId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairSparePartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairSparePartsRepairSparePartIdGet(repairSparePartId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairSparePartApi.apiV1AdminRepairSparePartsRepairSparePartIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} repairSparePartId 
         * @param {UpdateRepairSparePartDto} [updateRepairSparePartDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairSparePartsRepairSparePartIdPut(repairSparePartId: string, updateRepairSparePartDto?: UpdateRepairSparePartDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairSparePartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairSparePartsRepairSparePartIdPut(repairSparePartId, updateRepairSparePartDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairSparePartApi.apiV1AdminRepairSparePartsRepairSparePartIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RepairSparePartSearchPaginatedDto} [repairSparePartSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairSparePartsSearchPost(repairSparePartSearchPaginatedDto?: RepairSparePartSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairSparePartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairSparePartsSearchPost(repairSparePartSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairSparePartApi.apiV1AdminRepairSparePartsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminRepairSparePartApi - factory interface
 * @export
 */
export const AdminRepairSparePartApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminRepairSparePartApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsGetPost(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairSparePartDto> {
            return localVarFp.apiV1AdminRepairSparePartsGetPost(requestParameters.repairSparePartGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsPost(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RepairSparePartDto> {
            return localVarFp.apiV1AdminRepairSparePartsPost(requestParameters.createRepairSparePartDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsRepairSparePartIdDelete(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminRepairSparePartsRepairSparePartIdDelete(requestParameters.repairSparePartId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGet(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteRepairSparePartInfoDto> {
            return localVarFp.apiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGet(requestParameters.repairSparePartId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsRepairSparePartIdGet(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairSparePartDto> {
            return localVarFp.apiV1AdminRepairSparePartsRepairSparePartIdGet(requestParameters.repairSparePartId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsRepairSparePartIdPut(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairSparePartDto> {
            return localVarFp.apiV1AdminRepairSparePartsRepairSparePartIdPut(requestParameters.repairSparePartId, requestParameters.updateRepairSparePartDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSparePartsSearchPost(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairSparePartDto> {
            return localVarFp.apiV1AdminRepairSparePartsSearchPost(requestParameters.repairSparePartSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminRepairSparePartsGetPost operation in AdminRepairSparePartApi.
 * @export
 * @interface AdminRepairSparePartApiApiV1AdminRepairSparePartsGetPostRequest
 */
export interface AdminRepairSparePartApiApiV1AdminRepairSparePartsGetPostRequest {
    /**
     * 
     * @type {RepairSparePartGetPaginatedDto}
     * @memberof AdminRepairSparePartApiApiV1AdminRepairSparePartsGetPost
     */
    readonly repairSparePartGetPaginatedDto?: RepairSparePartGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminRepairSparePartsPost operation in AdminRepairSparePartApi.
 * @export
 * @interface AdminRepairSparePartApiApiV1AdminRepairSparePartsPostRequest
 */
export interface AdminRepairSparePartApiApiV1AdminRepairSparePartsPostRequest {
    /**
     * 
     * @type {CreateRepairSparePartDto}
     * @memberof AdminRepairSparePartApiApiV1AdminRepairSparePartsPost
     */
    readonly createRepairSparePartDto?: CreateRepairSparePartDto
}

/**
 * Request parameters for apiV1AdminRepairSparePartsRepairSparePartIdDelete operation in AdminRepairSparePartApi.
 * @export
 * @interface AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDeleteRequest
 */
export interface AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDelete
     */
    readonly repairSparePartId: string
}

/**
 * Request parameters for apiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGet operation in AdminRepairSparePartApi.
 * @export
 * @interface AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGetRequest
 */
export interface AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGet
     */
    readonly repairSparePartId: string
}

/**
 * Request parameters for apiV1AdminRepairSparePartsRepairSparePartIdGet operation in AdminRepairSparePartApi.
 * @export
 * @interface AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdGetRequest
 */
export interface AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdGet
     */
    readonly repairSparePartId: string
}

/**
 * Request parameters for apiV1AdminRepairSparePartsRepairSparePartIdPut operation in AdminRepairSparePartApi.
 * @export
 * @interface AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdPutRequest
 */
export interface AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdPut
     */
    readonly repairSparePartId: string

    /**
     * 
     * @type {UpdateRepairSparePartDto}
     * @memberof AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdPut
     */
    readonly updateRepairSparePartDto?: UpdateRepairSparePartDto
}

/**
 * Request parameters for apiV1AdminRepairSparePartsSearchPost operation in AdminRepairSparePartApi.
 * @export
 * @interface AdminRepairSparePartApiApiV1AdminRepairSparePartsSearchPostRequest
 */
export interface AdminRepairSparePartApiApiV1AdminRepairSparePartsSearchPostRequest {
    /**
     * 
     * @type {RepairSparePartSearchPaginatedDto}
     * @memberof AdminRepairSparePartApiApiV1AdminRepairSparePartsSearchPost
     */
    readonly repairSparePartSearchPaginatedDto?: RepairSparePartSearchPaginatedDto
}

/**
 * AdminRepairSparePartApi - object-oriented interface
 * @export
 * @class AdminRepairSparePartApi
 * @extends {BaseAPI}
 */
export class AdminRepairSparePartApi extends BaseAPI {
    /**
     * 
     * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairSparePartApi
     */
    public apiV1AdminRepairSparePartsGetPost(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminRepairSparePartApiFp(this.configuration).apiV1AdminRepairSparePartsGetPost(requestParameters.repairSparePartGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairSparePartApi
     */
    public apiV1AdminRepairSparePartsPost(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminRepairSparePartApiFp(this.configuration).apiV1AdminRepairSparePartsPost(requestParameters.createRepairSparePartDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairSparePartApi
     */
    public apiV1AdminRepairSparePartsRepairSparePartIdDelete(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminRepairSparePartApiFp(this.configuration).apiV1AdminRepairSparePartsRepairSparePartIdDelete(requestParameters.repairSparePartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairSparePartApi
     */
    public apiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGet(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGetRequest, options?: RawAxiosRequestConfig) {
        return AdminRepairSparePartApiFp(this.configuration).apiV1AdminRepairSparePartsRepairSparePartIdDeleteInfoGet(requestParameters.repairSparePartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairSparePartApi
     */
    public apiV1AdminRepairSparePartsRepairSparePartIdGet(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminRepairSparePartApiFp(this.configuration).apiV1AdminRepairSparePartsRepairSparePartIdGet(requestParameters.repairSparePartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairSparePartApi
     */
    public apiV1AdminRepairSparePartsRepairSparePartIdPut(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsRepairSparePartIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminRepairSparePartApiFp(this.configuration).apiV1AdminRepairSparePartsRepairSparePartIdPut(requestParameters.repairSparePartId, requestParameters.updateRepairSparePartDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairSparePartApiApiV1AdminRepairSparePartsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairSparePartApi
     */
    public apiV1AdminRepairSparePartsSearchPost(requestParameters: AdminRepairSparePartApiApiV1AdminRepairSparePartsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminRepairSparePartApiFp(this.configuration).apiV1AdminRepairSparePartsSearchPost(requestParameters.repairSparePartSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminRepairSpecsApi - axios parameter creator
 * @export
 */
export const AdminRepairSpecsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RepairSpecGetPaginatedDto} [repairSpecGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSpecsGetPost: async (repairSpecGetPaginatedDto?: RepairSpecGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/repair/specs/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairSpecGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRepairSpecDto} [createRepairSpecDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSpecsPost: async (createRepairSpecDto?: CreateRepairSpecDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/repair/specs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRepairSpecDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repairSpecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSpecsRepairSpecIdDelete: async (repairSpecId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repairSpecId' is not null or undefined
            assertParamExists('apiV1AdminRepairSpecsRepairSpecIdDelete', 'repairSpecId', repairSpecId)
            const localVarPath = `/api/v1/admin/repair/specs/{repairSpecId}`
                .replace(`{${"repairSpecId"}}`, encodeURIComponent(String(repairSpecId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repairSpecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSpecsRepairSpecIdGet: async (repairSpecId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repairSpecId' is not null or undefined
            assertParamExists('apiV1AdminRepairSpecsRepairSpecIdGet', 'repairSpecId', repairSpecId)
            const localVarPath = `/api/v1/admin/repair/specs/{repairSpecId}`
                .replace(`{${"repairSpecId"}}`, encodeURIComponent(String(repairSpecId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repairSpecId 
         * @param {UpdateRepairSpecDto} [updateRepairSpecDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSpecsRepairSpecIdPut: async (repairSpecId: string, updateRepairSpecDto?: UpdateRepairSpecDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repairSpecId' is not null or undefined
            assertParamExists('apiV1AdminRepairSpecsRepairSpecIdPut', 'repairSpecId', repairSpecId)
            const localVarPath = `/api/v1/admin/repair/specs/{repairSpecId}`
                .replace(`{${"repairSpecId"}}`, encodeURIComponent(String(repairSpecId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepairSpecDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RepairSpecSearchPaginatedDto} [repairSpecSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSpecsSearchPost: async (repairSpecSearchPaginatedDto?: RepairSpecSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/repair/specs/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairSpecSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminRepairSpecsApi - functional programming interface
 * @export
 */
export const AdminRepairSpecsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminRepairSpecsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RepairSpecGetPaginatedDto} [repairSpecGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairSpecsGetPost(repairSpecGetPaginatedDto?: RepairSpecGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairSpecsGetPost(repairSpecGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairSpecsApi.apiV1AdminRepairSpecsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateRepairSpecDto} [createRepairSpecDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairSpecsPost(createRepairSpecDto?: CreateRepairSpecDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairSpecsPost(createRepairSpecDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairSpecsApi.apiV1AdminRepairSpecsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} repairSpecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairSpecsRepairSpecIdDelete(repairSpecId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairSpecsRepairSpecIdDelete(repairSpecId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairSpecsApi.apiV1AdminRepairSpecsRepairSpecIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} repairSpecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairSpecsRepairSpecIdGet(repairSpecId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairSpecsRepairSpecIdGet(repairSpecId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairSpecsApi.apiV1AdminRepairSpecsRepairSpecIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} repairSpecId 
         * @param {UpdateRepairSpecDto} [updateRepairSpecDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairSpecsRepairSpecIdPut(repairSpecId: string, updateRepairSpecDto?: UpdateRepairSpecDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairSpecsRepairSpecIdPut(repairSpecId, updateRepairSpecDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairSpecsApi.apiV1AdminRepairSpecsRepairSpecIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RepairSpecSearchPaginatedDto} [repairSpecSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairSpecsSearchPost(repairSpecSearchPaginatedDto?: RepairSpecSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairSpecsSearchPost(repairSpecSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairSpecsApi.apiV1AdminRepairSpecsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminRepairSpecsApi - factory interface
 * @export
 */
export const AdminRepairSpecsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminRepairSpecsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminRepairSpecsApiApiV1AdminRepairSpecsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSpecsGetPost(requestParameters: AdminRepairSpecsApiApiV1AdminRepairSpecsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairSpecDto> {
            return localVarFp.apiV1AdminRepairSpecsGetPost(requestParameters.repairSpecGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairSpecsApiApiV1AdminRepairSpecsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSpecsPost(requestParameters: AdminRepairSpecsApiApiV1AdminRepairSpecsPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RepairSpecDto> {
            return localVarFp.apiV1AdminRepairSpecsPost(requestParameters.createRepairSpecDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSpecsRepairSpecIdDelete(requestParameters: AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminRepairSpecsRepairSpecIdDelete(requestParameters.repairSpecId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSpecsRepairSpecIdGet(requestParameters: AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairSpecDto> {
            return localVarFp.apiV1AdminRepairSpecsRepairSpecIdGet(requestParameters.repairSpecId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSpecsRepairSpecIdPut(requestParameters: AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairSpecDto> {
            return localVarFp.apiV1AdminRepairSpecsRepairSpecIdPut(requestParameters.repairSpecId, requestParameters.updateRepairSpecDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairSpecsApiApiV1AdminRepairSpecsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairSpecsSearchPost(requestParameters: AdminRepairSpecsApiApiV1AdminRepairSpecsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairSpecDto> {
            return localVarFp.apiV1AdminRepairSpecsSearchPost(requestParameters.repairSpecSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminRepairSpecsGetPost operation in AdminRepairSpecsApi.
 * @export
 * @interface AdminRepairSpecsApiApiV1AdminRepairSpecsGetPostRequest
 */
export interface AdminRepairSpecsApiApiV1AdminRepairSpecsGetPostRequest {
    /**
     * 
     * @type {RepairSpecGetPaginatedDto}
     * @memberof AdminRepairSpecsApiApiV1AdminRepairSpecsGetPost
     */
    readonly repairSpecGetPaginatedDto?: RepairSpecGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminRepairSpecsPost operation in AdminRepairSpecsApi.
 * @export
 * @interface AdminRepairSpecsApiApiV1AdminRepairSpecsPostRequest
 */
export interface AdminRepairSpecsApiApiV1AdminRepairSpecsPostRequest {
    /**
     * 
     * @type {CreateRepairSpecDto}
     * @memberof AdminRepairSpecsApiApiV1AdminRepairSpecsPost
     */
    readonly createRepairSpecDto?: CreateRepairSpecDto
}

/**
 * Request parameters for apiV1AdminRepairSpecsRepairSpecIdDelete operation in AdminRepairSpecsApi.
 * @export
 * @interface AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdDeleteRequest
 */
export interface AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdDelete
     */
    readonly repairSpecId: string
}

/**
 * Request parameters for apiV1AdminRepairSpecsRepairSpecIdGet operation in AdminRepairSpecsApi.
 * @export
 * @interface AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdGetRequest
 */
export interface AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdGet
     */
    readonly repairSpecId: string
}

/**
 * Request parameters for apiV1AdminRepairSpecsRepairSpecIdPut operation in AdminRepairSpecsApi.
 * @export
 * @interface AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdPutRequest
 */
export interface AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdPut
     */
    readonly repairSpecId: string

    /**
     * 
     * @type {UpdateRepairSpecDto}
     * @memberof AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdPut
     */
    readonly updateRepairSpecDto?: UpdateRepairSpecDto
}

/**
 * Request parameters for apiV1AdminRepairSpecsSearchPost operation in AdminRepairSpecsApi.
 * @export
 * @interface AdminRepairSpecsApiApiV1AdminRepairSpecsSearchPostRequest
 */
export interface AdminRepairSpecsApiApiV1AdminRepairSpecsSearchPostRequest {
    /**
     * 
     * @type {RepairSpecSearchPaginatedDto}
     * @memberof AdminRepairSpecsApiApiV1AdminRepairSpecsSearchPost
     */
    readonly repairSpecSearchPaginatedDto?: RepairSpecSearchPaginatedDto
}

/**
 * AdminRepairSpecsApi - object-oriented interface
 * @export
 * @class AdminRepairSpecsApi
 * @extends {BaseAPI}
 */
export class AdminRepairSpecsApi extends BaseAPI {
    /**
     * 
     * @param {AdminRepairSpecsApiApiV1AdminRepairSpecsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairSpecsApi
     */
    public apiV1AdminRepairSpecsGetPost(requestParameters: AdminRepairSpecsApiApiV1AdminRepairSpecsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminRepairSpecsApiFp(this.configuration).apiV1AdminRepairSpecsGetPost(requestParameters.repairSpecGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairSpecsApiApiV1AdminRepairSpecsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairSpecsApi
     */
    public apiV1AdminRepairSpecsPost(requestParameters: AdminRepairSpecsApiApiV1AdminRepairSpecsPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminRepairSpecsApiFp(this.configuration).apiV1AdminRepairSpecsPost(requestParameters.createRepairSpecDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairSpecsApi
     */
    public apiV1AdminRepairSpecsRepairSpecIdDelete(requestParameters: AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminRepairSpecsApiFp(this.configuration).apiV1AdminRepairSpecsRepairSpecIdDelete(requestParameters.repairSpecId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairSpecsApi
     */
    public apiV1AdminRepairSpecsRepairSpecIdGet(requestParameters: AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminRepairSpecsApiFp(this.configuration).apiV1AdminRepairSpecsRepairSpecIdGet(requestParameters.repairSpecId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairSpecsApi
     */
    public apiV1AdminRepairSpecsRepairSpecIdPut(requestParameters: AdminRepairSpecsApiApiV1AdminRepairSpecsRepairSpecIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminRepairSpecsApiFp(this.configuration).apiV1AdminRepairSpecsRepairSpecIdPut(requestParameters.repairSpecId, requestParameters.updateRepairSpecDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairSpecsApiApiV1AdminRepairSpecsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairSpecsApi
     */
    public apiV1AdminRepairSpecsSearchPost(requestParameters: AdminRepairSpecsApiApiV1AdminRepairSpecsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminRepairSpecsApiFp(this.configuration).apiV1AdminRepairSpecsSearchPost(requestParameters.repairSpecSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminRepairWorkApi - axios parameter creator
 * @export
 */
export const AdminRepairWorkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RepairWorkGetPaginatedDto} [repairWorkGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairWorkGetPost: async (repairWorkGetPaginatedDto?: RepairWorkGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/repair/work/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairWorkGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRepairWorkDto} [createRepairWorkDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairWorkPost: async (createRepairWorkDto?: CreateRepairWorkDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/repair/work`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRepairWorkDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repairWorkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairWorkRepairWorkIdDelete: async (repairWorkId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repairWorkId' is not null or undefined
            assertParamExists('apiV1AdminRepairWorkRepairWorkIdDelete', 'repairWorkId', repairWorkId)
            const localVarPath = `/api/v1/admin/repair/work/{repairWorkId}`
                .replace(`{${"repairWorkId"}}`, encodeURIComponent(String(repairWorkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repairWorkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairWorkRepairWorkIdGet: async (repairWorkId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repairWorkId' is not null or undefined
            assertParamExists('apiV1AdminRepairWorkRepairWorkIdGet', 'repairWorkId', repairWorkId)
            const localVarPath = `/api/v1/admin/repair/work/{repairWorkId}`
                .replace(`{${"repairWorkId"}}`, encodeURIComponent(String(repairWorkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repairWorkId 
         * @param {UpdateRepairWorkDto} [updateRepairWorkDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairWorkRepairWorkIdPut: async (repairWorkId: string, updateRepairWorkDto?: UpdateRepairWorkDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repairWorkId' is not null or undefined
            assertParamExists('apiV1AdminRepairWorkRepairWorkIdPut', 'repairWorkId', repairWorkId)
            const localVarPath = `/api/v1/admin/repair/work/{repairWorkId}`
                .replace(`{${"repairWorkId"}}`, encodeURIComponent(String(repairWorkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepairWorkDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RepairWorkSearchPaginatedDto} [repairWorkSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairWorkSearchPost: async (repairWorkSearchPaginatedDto?: RepairWorkSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/repair/work/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairWorkSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminRepairWorkApi - functional programming interface
 * @export
 */
export const AdminRepairWorkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminRepairWorkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RepairWorkGetPaginatedDto} [repairWorkGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairWorkGetPost(repairWorkGetPaginatedDto?: RepairWorkGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairWorkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairWorkGetPost(repairWorkGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairWorkApi.apiV1AdminRepairWorkGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateRepairWorkDto} [createRepairWorkDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairWorkPost(createRepairWorkDto?: CreateRepairWorkDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairWorkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairWorkPost(createRepairWorkDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairWorkApi.apiV1AdminRepairWorkPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} repairWorkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairWorkRepairWorkIdDelete(repairWorkId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairWorkRepairWorkIdDelete(repairWorkId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairWorkApi.apiV1AdminRepairWorkRepairWorkIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} repairWorkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairWorkRepairWorkIdGet(repairWorkId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairWorkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairWorkRepairWorkIdGet(repairWorkId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairWorkApi.apiV1AdminRepairWorkRepairWorkIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} repairWorkId 
         * @param {UpdateRepairWorkDto} [updateRepairWorkDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairWorkRepairWorkIdPut(repairWorkId: string, updateRepairWorkDto?: UpdateRepairWorkDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairWorkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairWorkRepairWorkIdPut(repairWorkId, updateRepairWorkDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairWorkApi.apiV1AdminRepairWorkRepairWorkIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RepairWorkSearchPaginatedDto} [repairWorkSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminRepairWorkSearchPost(repairWorkSearchPaginatedDto?: RepairWorkSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairWorkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminRepairWorkSearchPost(repairWorkSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminRepairWorkApi.apiV1AdminRepairWorkSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminRepairWorkApi - factory interface
 * @export
 */
export const AdminRepairWorkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminRepairWorkApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminRepairWorkApiApiV1AdminRepairWorkGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairWorkGetPost(requestParameters: AdminRepairWorkApiApiV1AdminRepairWorkGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairWorkDto> {
            return localVarFp.apiV1AdminRepairWorkGetPost(requestParameters.repairWorkGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairWorkApiApiV1AdminRepairWorkPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairWorkPost(requestParameters: AdminRepairWorkApiApiV1AdminRepairWorkPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RepairWorkDto> {
            return localVarFp.apiV1AdminRepairWorkPost(requestParameters.createRepairWorkDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairWorkRepairWorkIdDelete(requestParameters: AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminRepairWorkRepairWorkIdDelete(requestParameters.repairWorkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairWorkRepairWorkIdGet(requestParameters: AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairWorkDto> {
            return localVarFp.apiV1AdminRepairWorkRepairWorkIdGet(requestParameters.repairWorkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairWorkRepairWorkIdPut(requestParameters: AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairWorkDto> {
            return localVarFp.apiV1AdminRepairWorkRepairWorkIdPut(requestParameters.repairWorkId, requestParameters.updateRepairWorkDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRepairWorkApiApiV1AdminRepairWorkSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminRepairWorkSearchPost(requestParameters: AdminRepairWorkApiApiV1AdminRepairWorkSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairWorkDto> {
            return localVarFp.apiV1AdminRepairWorkSearchPost(requestParameters.repairWorkSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminRepairWorkGetPost operation in AdminRepairWorkApi.
 * @export
 * @interface AdminRepairWorkApiApiV1AdminRepairWorkGetPostRequest
 */
export interface AdminRepairWorkApiApiV1AdminRepairWorkGetPostRequest {
    /**
     * 
     * @type {RepairWorkGetPaginatedDto}
     * @memberof AdminRepairWorkApiApiV1AdminRepairWorkGetPost
     */
    readonly repairWorkGetPaginatedDto?: RepairWorkGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminRepairWorkPost operation in AdminRepairWorkApi.
 * @export
 * @interface AdminRepairWorkApiApiV1AdminRepairWorkPostRequest
 */
export interface AdminRepairWorkApiApiV1AdminRepairWorkPostRequest {
    /**
     * 
     * @type {CreateRepairWorkDto}
     * @memberof AdminRepairWorkApiApiV1AdminRepairWorkPost
     */
    readonly createRepairWorkDto?: CreateRepairWorkDto
}

/**
 * Request parameters for apiV1AdminRepairWorkRepairWorkIdDelete operation in AdminRepairWorkApi.
 * @export
 * @interface AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdDeleteRequest
 */
export interface AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdDelete
     */
    readonly repairWorkId: string
}

/**
 * Request parameters for apiV1AdminRepairWorkRepairWorkIdGet operation in AdminRepairWorkApi.
 * @export
 * @interface AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdGetRequest
 */
export interface AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdGet
     */
    readonly repairWorkId: string
}

/**
 * Request parameters for apiV1AdminRepairWorkRepairWorkIdPut operation in AdminRepairWorkApi.
 * @export
 * @interface AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdPutRequest
 */
export interface AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdPut
     */
    readonly repairWorkId: string

    /**
     * 
     * @type {UpdateRepairWorkDto}
     * @memberof AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdPut
     */
    readonly updateRepairWorkDto?: UpdateRepairWorkDto
}

/**
 * Request parameters for apiV1AdminRepairWorkSearchPost operation in AdminRepairWorkApi.
 * @export
 * @interface AdminRepairWorkApiApiV1AdminRepairWorkSearchPostRequest
 */
export interface AdminRepairWorkApiApiV1AdminRepairWorkSearchPostRequest {
    /**
     * 
     * @type {RepairWorkSearchPaginatedDto}
     * @memberof AdminRepairWorkApiApiV1AdminRepairWorkSearchPost
     */
    readonly repairWorkSearchPaginatedDto?: RepairWorkSearchPaginatedDto
}

/**
 * AdminRepairWorkApi - object-oriented interface
 * @export
 * @class AdminRepairWorkApi
 * @extends {BaseAPI}
 */
export class AdminRepairWorkApi extends BaseAPI {
    /**
     * 
     * @param {AdminRepairWorkApiApiV1AdminRepairWorkGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairWorkApi
     */
    public apiV1AdminRepairWorkGetPost(requestParameters: AdminRepairWorkApiApiV1AdminRepairWorkGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminRepairWorkApiFp(this.configuration).apiV1AdminRepairWorkGetPost(requestParameters.repairWorkGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairWorkApiApiV1AdminRepairWorkPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairWorkApi
     */
    public apiV1AdminRepairWorkPost(requestParameters: AdminRepairWorkApiApiV1AdminRepairWorkPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminRepairWorkApiFp(this.configuration).apiV1AdminRepairWorkPost(requestParameters.createRepairWorkDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairWorkApi
     */
    public apiV1AdminRepairWorkRepairWorkIdDelete(requestParameters: AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminRepairWorkApiFp(this.configuration).apiV1AdminRepairWorkRepairWorkIdDelete(requestParameters.repairWorkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairWorkApi
     */
    public apiV1AdminRepairWorkRepairWorkIdGet(requestParameters: AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminRepairWorkApiFp(this.configuration).apiV1AdminRepairWorkRepairWorkIdGet(requestParameters.repairWorkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairWorkApi
     */
    public apiV1AdminRepairWorkRepairWorkIdPut(requestParameters: AdminRepairWorkApiApiV1AdminRepairWorkRepairWorkIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminRepairWorkApiFp(this.configuration).apiV1AdminRepairWorkRepairWorkIdPut(requestParameters.repairWorkId, requestParameters.updateRepairWorkDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRepairWorkApiApiV1AdminRepairWorkSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRepairWorkApi
     */
    public apiV1AdminRepairWorkSearchPost(requestParameters: AdminRepairWorkApiApiV1AdminRepairWorkSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminRepairWorkApiFp(this.configuration).apiV1AdminRepairWorkSearchPost(requestParameters.repairWorkSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminSubscriptionPlansApi - axios parameter creator
 * @export
 */
export const AdminSubscriptionPlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSubscriptionsPlansGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/subscriptions/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateSubscriptionPlanDto} [adminCreateSubscriptionPlanDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSubscriptionsPlansPost: async (adminCreateSubscriptionPlanDto?: AdminCreateSubscriptionPlanDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/subscriptions/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateSubscriptionPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriptionPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSubscriptionsPlansSubscriptionPlanIdDelete: async (subscriptionPlanId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionPlanId' is not null or undefined
            assertParamExists('apiV1AdminSubscriptionsPlansSubscriptionPlanIdDelete', 'subscriptionPlanId', subscriptionPlanId)
            const localVarPath = `/api/v1/admin/subscriptions/plans/{subscriptionPlanId}`
                .replace(`{${"subscriptionPlanId"}}`, encodeURIComponent(String(subscriptionPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriptionPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSubscriptionsPlansSubscriptionPlanIdGet: async (subscriptionPlanId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionPlanId' is not null or undefined
            assertParamExists('apiV1AdminSubscriptionsPlansSubscriptionPlanIdGet', 'subscriptionPlanId', subscriptionPlanId)
            const localVarPath = `/api/v1/admin/subscriptions/plans/{subscriptionPlanId}`
                .replace(`{${"subscriptionPlanId"}}`, encodeURIComponent(String(subscriptionPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriptionPlanId 
         * @param {AdminUpdateSubscriptionPlanDto} [adminUpdateSubscriptionPlanDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSubscriptionsPlansSubscriptionPlanIdPut: async (subscriptionPlanId: string, adminUpdateSubscriptionPlanDto?: AdminUpdateSubscriptionPlanDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionPlanId' is not null or undefined
            assertParamExists('apiV1AdminSubscriptionsPlansSubscriptionPlanIdPut', 'subscriptionPlanId', subscriptionPlanId)
            const localVarPath = `/api/v1/admin/subscriptions/plans/{subscriptionPlanId}`
                .replace(`{${"subscriptionPlanId"}}`, encodeURIComponent(String(subscriptionPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateSubscriptionPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSubscriptionPlansApi - functional programming interface
 * @export
 */
export const AdminSubscriptionPlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSubscriptionPlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminSubscriptionsPlansGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminSubscriptionPlanDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminSubscriptionsPlansGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminSubscriptionPlansApi.apiV1AdminSubscriptionsPlansGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateSubscriptionPlanDto} [adminCreateSubscriptionPlanDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminSubscriptionsPlansPost(adminCreateSubscriptionPlanDto?: AdminCreateSubscriptionPlanDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSubscriptionPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminSubscriptionsPlansPost(adminCreateSubscriptionPlanDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminSubscriptionPlansApi.apiV1AdminSubscriptionsPlansPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} subscriptionPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminSubscriptionsPlansSubscriptionPlanIdDelete(subscriptionPlanId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminSubscriptionsPlansSubscriptionPlanIdDelete(subscriptionPlanId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminSubscriptionPlansApi.apiV1AdminSubscriptionsPlansSubscriptionPlanIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} subscriptionPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminSubscriptionsPlansSubscriptionPlanIdGet(subscriptionPlanId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSubscriptionPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminSubscriptionsPlansSubscriptionPlanIdGet(subscriptionPlanId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminSubscriptionPlansApi.apiV1AdminSubscriptionsPlansSubscriptionPlanIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} subscriptionPlanId 
         * @param {AdminUpdateSubscriptionPlanDto} [adminUpdateSubscriptionPlanDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminSubscriptionsPlansSubscriptionPlanIdPut(subscriptionPlanId: string, adminUpdateSubscriptionPlanDto?: AdminUpdateSubscriptionPlanDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSubscriptionPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminSubscriptionsPlansSubscriptionPlanIdPut(subscriptionPlanId, adminUpdateSubscriptionPlanDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminSubscriptionPlansApi.apiV1AdminSubscriptionsPlansSubscriptionPlanIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminSubscriptionPlansApi - factory interface
 * @export
 */
export const AdminSubscriptionPlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSubscriptionPlansApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSubscriptionsPlansGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminSubscriptionPlanDto>> {
            return localVarFp.apiV1AdminSubscriptionsPlansGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSubscriptionsPlansPost(requestParameters: AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminSubscriptionPlanDto> {
            return localVarFp.apiV1AdminSubscriptionsPlansPost(requestParameters.adminCreateSubscriptionPlanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSubscriptionsPlansSubscriptionPlanIdDelete(requestParameters: AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminSubscriptionsPlansSubscriptionPlanIdDelete(requestParameters.subscriptionPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSubscriptionsPlansSubscriptionPlanIdGet(requestParameters: AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminSubscriptionPlanDto> {
            return localVarFp.apiV1AdminSubscriptionsPlansSubscriptionPlanIdGet(requestParameters.subscriptionPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSubscriptionsPlansSubscriptionPlanIdPut(requestParameters: AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminSubscriptionPlanDto> {
            return localVarFp.apiV1AdminSubscriptionsPlansSubscriptionPlanIdPut(requestParameters.subscriptionPlanId, requestParameters.adminUpdateSubscriptionPlanDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminSubscriptionsPlansPost operation in AdminSubscriptionPlansApi.
 * @export
 * @interface AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansPostRequest
 */
export interface AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansPostRequest {
    /**
     * 
     * @type {AdminCreateSubscriptionPlanDto}
     * @memberof AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansPost
     */
    readonly adminCreateSubscriptionPlanDto?: AdminCreateSubscriptionPlanDto
}

/**
 * Request parameters for apiV1AdminSubscriptionsPlansSubscriptionPlanIdDelete operation in AdminSubscriptionPlansApi.
 * @export
 * @interface AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdDeleteRequest
 */
export interface AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdDelete
     */
    readonly subscriptionPlanId: string
}

/**
 * Request parameters for apiV1AdminSubscriptionsPlansSubscriptionPlanIdGet operation in AdminSubscriptionPlansApi.
 * @export
 * @interface AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdGetRequest
 */
export interface AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdGet
     */
    readonly subscriptionPlanId: string
}

/**
 * Request parameters for apiV1AdminSubscriptionsPlansSubscriptionPlanIdPut operation in AdminSubscriptionPlansApi.
 * @export
 * @interface AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdPutRequest
 */
export interface AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdPut
     */
    readonly subscriptionPlanId: string

    /**
     * 
     * @type {AdminUpdateSubscriptionPlanDto}
     * @memberof AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdPut
     */
    readonly adminUpdateSubscriptionPlanDto?: AdminUpdateSubscriptionPlanDto
}

/**
 * AdminSubscriptionPlansApi - object-oriented interface
 * @export
 * @class AdminSubscriptionPlansApi
 * @extends {BaseAPI}
 */
export class AdminSubscriptionPlansApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionPlansApi
     */
    public apiV1AdminSubscriptionsPlansGet(options?: RawAxiosRequestConfig) {
        return AdminSubscriptionPlansApiFp(this.configuration).apiV1AdminSubscriptionsPlansGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionPlansApi
     */
    public apiV1AdminSubscriptionsPlansPost(requestParameters: AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminSubscriptionPlansApiFp(this.configuration).apiV1AdminSubscriptionsPlansPost(requestParameters.adminCreateSubscriptionPlanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionPlansApi
     */
    public apiV1AdminSubscriptionsPlansSubscriptionPlanIdDelete(requestParameters: AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminSubscriptionPlansApiFp(this.configuration).apiV1AdminSubscriptionsPlansSubscriptionPlanIdDelete(requestParameters.subscriptionPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionPlansApi
     */
    public apiV1AdminSubscriptionsPlansSubscriptionPlanIdGet(requestParameters: AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminSubscriptionPlansApiFp(this.configuration).apiV1AdminSubscriptionsPlansSubscriptionPlanIdGet(requestParameters.subscriptionPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionPlansApi
     */
    public apiV1AdminSubscriptionsPlansSubscriptionPlanIdPut(requestParameters: AdminSubscriptionPlansApiApiV1AdminSubscriptionsPlansSubscriptionPlanIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminSubscriptionPlansApiFp(this.configuration).apiV1AdminSubscriptionsPlansSubscriptionPlanIdPut(requestParameters.subscriptionPlanId, requestParameters.adminUpdateSubscriptionPlanDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminSubscriptionsApi - axios parameter creator
 * @export
 */
export const AdminSubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminSubscriptionGetPaginatedDto} [adminSubscriptionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSubscriptionsGetPost: async (adminSubscriptionGetPaginatedDto?: AdminSubscriptionGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/subscriptions/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSubscriptionGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSubscriptionsApi - functional programming interface
 * @export
 */
export const AdminSubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminSubscriptionGetPaginatedDto} [adminSubscriptionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminSubscriptionsGetPost(adminSubscriptionGetPaginatedDto?: AdminSubscriptionGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminSubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminSubscriptionsGetPost(adminSubscriptionGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminSubscriptionsApi.apiV1AdminSubscriptionsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminSubscriptionsApi - factory interface
 * @export
 */
export const AdminSubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSubscriptionsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminSubscriptionsApiApiV1AdminSubscriptionsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSubscriptionsGetPost(requestParameters: AdminSubscriptionsApiApiV1AdminSubscriptionsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminSubscriptionDto> {
            return localVarFp.apiV1AdminSubscriptionsGetPost(requestParameters.adminSubscriptionGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminSubscriptionsGetPost operation in AdminSubscriptionsApi.
 * @export
 * @interface AdminSubscriptionsApiApiV1AdminSubscriptionsGetPostRequest
 */
export interface AdminSubscriptionsApiApiV1AdminSubscriptionsGetPostRequest {
    /**
     * 
     * @type {AdminSubscriptionGetPaginatedDto}
     * @memberof AdminSubscriptionsApiApiV1AdminSubscriptionsGetPost
     */
    readonly adminSubscriptionGetPaginatedDto?: AdminSubscriptionGetPaginatedDto
}

/**
 * AdminSubscriptionsApi - object-oriented interface
 * @export
 * @class AdminSubscriptionsApi
 * @extends {BaseAPI}
 */
export class AdminSubscriptionsApi extends BaseAPI {
    /**
     * 
     * @param {AdminSubscriptionsApiApiV1AdminSubscriptionsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public apiV1AdminSubscriptionsGetPost(requestParameters: AdminSubscriptionsApiApiV1AdminSubscriptionsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminSubscriptionsApiFp(this.configuration).apiV1AdminSubscriptionsGetPost(requestParameters.adminSubscriptionGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminSystemCacheApi - axios parameter creator
 * @export
 */
export const AdminSystemCacheApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminClearCacheDto} [adminClearCacheDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSystemCacheClearDelete: async (adminClearCacheDto?: AdminClearCacheDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/system/cache/clear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminClearCacheDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSystemCacheApi - functional programming interface
 * @export
 */
export const AdminSystemCacheApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSystemCacheApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminClearCacheDto} [adminClearCacheDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminSystemCacheClearDelete(adminClearCacheDto?: AdminClearCacheDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminSystemCacheClearDelete(adminClearCacheDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminSystemCacheApi.apiV1AdminSystemCacheClearDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminSystemCacheApi - factory interface
 * @export
 */
export const AdminSystemCacheApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSystemCacheApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminSystemCacheApiApiV1AdminSystemCacheClearDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminSystemCacheClearDelete(requestParameters: AdminSystemCacheApiApiV1AdminSystemCacheClearDeleteRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminSystemCacheClearDelete(requestParameters.adminClearCacheDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminSystemCacheClearDelete operation in AdminSystemCacheApi.
 * @export
 * @interface AdminSystemCacheApiApiV1AdminSystemCacheClearDeleteRequest
 */
export interface AdminSystemCacheApiApiV1AdminSystemCacheClearDeleteRequest {
    /**
     * 
     * @type {AdminClearCacheDto}
     * @memberof AdminSystemCacheApiApiV1AdminSystemCacheClearDelete
     */
    readonly adminClearCacheDto?: AdminClearCacheDto
}

/**
 * AdminSystemCacheApi - object-oriented interface
 * @export
 * @class AdminSystemCacheApi
 * @extends {BaseAPI}
 */
export class AdminSystemCacheApi extends BaseAPI {
    /**
     * 
     * @param {AdminSystemCacheApiApiV1AdminSystemCacheClearDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSystemCacheApi
     */
    public apiV1AdminSystemCacheClearDelete(requestParameters: AdminSystemCacheApiApiV1AdminSystemCacheClearDeleteRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminSystemCacheApiFp(this.configuration).apiV1AdminSystemCacheClearDelete(requestParameters.adminClearCacheDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminTenantRequestsApi - axios parameter creator
 * @export
 */
export const AdminTenantRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TenantRequestGetPaginatedDto} [tenantRequestGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTenantToTenantTenantRequestsGetPost: async (tenantRequestGetPaginatedDto?: TenantRequestGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/tenant-to-tenant/tenant-requests/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantRequestGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TenantRequestSearchPaginatedDto} [tenantRequestSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTenantToTenantTenantRequestsSearchPost: async (tenantRequestSearchPaginatedDto?: TenantRequestSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/tenant-to-tenant/tenant-requests/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantRequestSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTenantToTenantTenantRequestsTenantRequestIdDelete: async (tenantRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1AdminTenantToTenantTenantRequestsTenantRequestIdDelete', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/admin/tenant-to-tenant/tenant-requests/{tenantRequestId}`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTenantToTenantTenantRequestsTenantRequestIdGet: async (tenantRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1AdminTenantToTenantTenantRequestsTenantRequestIdGet', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/admin/tenant-to-tenant/tenant-requests/{tenantRequestId}`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTenantRequestsApi - functional programming interface
 * @export
 */
export const AdminTenantRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTenantRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TenantRequestGetPaginatedDto} [tenantRequestGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminTenantToTenantTenantRequestsGetPost(tenantRequestGetPaginatedDto?: TenantRequestGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminTenantToTenantTenantRequestsGetPost(tenantRequestGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTenantRequestsApi.apiV1AdminTenantToTenantTenantRequestsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TenantRequestSearchPaginatedDto} [tenantRequestSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminTenantToTenantTenantRequestsSearchPost(tenantRequestSearchPaginatedDto?: TenantRequestSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminTenantToTenantTenantRequestsSearchPost(tenantRequestSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTenantRequestsApi.apiV1AdminTenantToTenantTenantRequestsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminTenantToTenantTenantRequestsTenantRequestIdDelete(tenantRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminTenantToTenantTenantRequestsTenantRequestIdDelete(tenantRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTenantRequestsApi.apiV1AdminTenantToTenantTenantRequestsTenantRequestIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminTenantToTenantTenantRequestsTenantRequestIdGet(tenantRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminTenantToTenantTenantRequestsTenantRequestIdGet(tenantRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTenantRequestsApi.apiV1AdminTenantToTenantTenantRequestsTenantRequestIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminTenantRequestsApi - factory interface
 * @export
 */
export const AdminTenantRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTenantRequestsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTenantToTenantTenantRequestsGetPost(requestParameters: AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTenantRequestDto> {
            return localVarFp.apiV1AdminTenantToTenantTenantRequestsGetPost(requestParameters.tenantRequestGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTenantToTenantTenantRequestsSearchPost(requestParameters: AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTenantRequestDto> {
            return localVarFp.apiV1AdminTenantToTenantTenantRequestsSearchPost(requestParameters.tenantRequestSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTenantToTenantTenantRequestsTenantRequestIdDelete(requestParameters: AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminTenantToTenantTenantRequestsTenantRequestIdDelete(requestParameters.tenantRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTenantToTenantTenantRequestsTenantRequestIdGet(requestParameters: AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1AdminTenantToTenantTenantRequestsTenantRequestIdGet(requestParameters.tenantRequestId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminTenantToTenantTenantRequestsGetPost operation in AdminTenantRequestsApi.
 * @export
 * @interface AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsGetPostRequest
 */
export interface AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsGetPostRequest {
    /**
     * 
     * @type {TenantRequestGetPaginatedDto}
     * @memberof AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsGetPost
     */
    readonly tenantRequestGetPaginatedDto?: TenantRequestGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminTenantToTenantTenantRequestsSearchPost operation in AdminTenantRequestsApi.
 * @export
 * @interface AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsSearchPostRequest
 */
export interface AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsSearchPostRequest {
    /**
     * 
     * @type {TenantRequestSearchPaginatedDto}
     * @memberof AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsSearchPost
     */
    readonly tenantRequestSearchPaginatedDto?: TenantRequestSearchPaginatedDto
}

/**
 * Request parameters for apiV1AdminTenantToTenantTenantRequestsTenantRequestIdDelete operation in AdminTenantRequestsApi.
 * @export
 * @interface AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdDeleteRequest
 */
export interface AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdDelete
     */
    readonly tenantRequestId: string
}

/**
 * Request parameters for apiV1AdminTenantToTenantTenantRequestsTenantRequestIdGet operation in AdminTenantRequestsApi.
 * @export
 * @interface AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdGetRequest
 */
export interface AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdGet
     */
    readonly tenantRequestId: string
}

/**
 * AdminTenantRequestsApi - object-oriented interface
 * @export
 * @class AdminTenantRequestsApi
 * @extends {BaseAPI}
 */
export class AdminTenantRequestsApi extends BaseAPI {
    /**
     * 
     * @param {AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantRequestsApi
     */
    public apiV1AdminTenantToTenantTenantRequestsGetPost(requestParameters: AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminTenantRequestsApiFp(this.configuration).apiV1AdminTenantToTenantTenantRequestsGetPost(requestParameters.tenantRequestGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantRequestsApi
     */
    public apiV1AdminTenantToTenantTenantRequestsSearchPost(requestParameters: AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminTenantRequestsApiFp(this.configuration).apiV1AdminTenantToTenantTenantRequestsSearchPost(requestParameters.tenantRequestSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantRequestsApi
     */
    public apiV1AdminTenantToTenantTenantRequestsTenantRequestIdDelete(requestParameters: AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminTenantRequestsApiFp(this.configuration).apiV1AdminTenantToTenantTenantRequestsTenantRequestIdDelete(requestParameters.tenantRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantRequestsApi
     */
    public apiV1AdminTenantToTenantTenantRequestsTenantRequestIdGet(requestParameters: AdminTenantRequestsApiApiV1AdminTenantToTenantTenantRequestsTenantRequestIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminTenantRequestsApiFp(this.configuration).apiV1AdminTenantToTenantTenantRequestsTenantRequestIdGet(requestParameters.tenantRequestId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminTenantsApi - axios parameter creator
 * @export
 */
export const AdminTenantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminTenantGetPaginatedDto} [adminTenantGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTenantsGetPost: async (adminTenantGetPaginatedDto?: AdminTenantGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/tenants/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminTenantGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTenantsTenantIdGet: async (tenantId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiV1AdminTenantsTenantIdGet', 'tenantId', tenantId)
            const localVarPath = `/api/v1/admin/tenants/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTenantsApi - functional programming interface
 * @export
 */
export const AdminTenantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTenantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminTenantGetPaginatedDto} [adminTenantGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminTenantsGetPost(adminTenantGetPaginatedDto?: AdminTenantGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminTenantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminTenantsGetPost(adminTenantGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTenantsApi.apiV1AdminTenantsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminTenantsTenantIdGet(tenantId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTenantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminTenantsTenantIdGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTenantsApi.apiV1AdminTenantsTenantIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminTenantsApi - factory interface
 * @export
 */
export const AdminTenantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTenantsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminTenantsApiApiV1AdminTenantsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTenantsGetPost(requestParameters: AdminTenantsApiApiV1AdminTenantsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminTenantDto> {
            return localVarFp.apiV1AdminTenantsGetPost(requestParameters.adminTenantGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminTenantsApiApiV1AdminTenantsTenantIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminTenantsTenantIdGet(requestParameters: AdminTenantsApiApiV1AdminTenantsTenantIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminTenantDto> {
            return localVarFp.apiV1AdminTenantsTenantIdGet(requestParameters.tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminTenantsGetPost operation in AdminTenantsApi.
 * @export
 * @interface AdminTenantsApiApiV1AdminTenantsGetPostRequest
 */
export interface AdminTenantsApiApiV1AdminTenantsGetPostRequest {
    /**
     * 
     * @type {AdminTenantGetPaginatedDto}
     * @memberof AdminTenantsApiApiV1AdminTenantsGetPost
     */
    readonly adminTenantGetPaginatedDto?: AdminTenantGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminTenantsTenantIdGet operation in AdminTenantsApi.
 * @export
 * @interface AdminTenantsApiApiV1AdminTenantsTenantIdGetRequest
 */
export interface AdminTenantsApiApiV1AdminTenantsTenantIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminTenantsApiApiV1AdminTenantsTenantIdGet
     */
    readonly tenantId: string
}

/**
 * AdminTenantsApi - object-oriented interface
 * @export
 * @class AdminTenantsApi
 * @extends {BaseAPI}
 */
export class AdminTenantsApi extends BaseAPI {
    /**
     * 
     * @param {AdminTenantsApiApiV1AdminTenantsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantsApi
     */
    public apiV1AdminTenantsGetPost(requestParameters: AdminTenantsApiApiV1AdminTenantsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminTenantsApiFp(this.configuration).apiV1AdminTenantsGetPost(requestParameters.adminTenantGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminTenantsApiApiV1AdminTenantsTenantIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTenantsApi
     */
    public apiV1AdminTenantsTenantIdGet(requestParameters: AdminTenantsApiApiV1AdminTenantsTenantIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminTenantsApiFp(this.configuration).apiV1AdminTenantsTenantIdGet(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminUsersApi - axios parameter creator
 * @export
 */
export const AdminUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AdminUserGetPaginatedDto} [adminUserGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersGetPost: async (nexusOpsTenant: string, adminUserGetPaginatedDto?: AdminUserGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AdminUsersGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/admin/users/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} targetUserId 
         * @param {AdminUserActivateDto} [adminUserActivateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersTargetUserIdActivatePost: async (nexusOpsTenant: string, targetUserId: string, adminUserActivateDto?: AdminUserActivateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AdminUsersTargetUserIdActivatePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'targetUserId' is not null or undefined
            assertParamExists('apiV1AdminUsersTargetUserIdActivatePost', 'targetUserId', targetUserId)
            const localVarPath = `/api/v1/admin/users/{targetUserId}/activate`
                .replace(`{${"targetUserId"}}`, encodeURIComponent(String(targetUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserActivateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} targetUserId 
         * @param {AdminUserSuspendDto} [adminUserSuspendDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersTargetUserIdSuspendPost: async (nexusOpsTenant: string, targetUserId: string, adminUserSuspendDto?: AdminUserSuspendDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AdminUsersTargetUserIdSuspendPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'targetUserId' is not null or undefined
            assertParamExists('apiV1AdminUsersTargetUserIdSuspendPost', 'targetUserId', targetUserId)
            const localVarPath = `/api/v1/admin/users/{targetUserId}/suspend`
                .replace(`{${"targetUserId"}}`, encodeURIComponent(String(targetUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserSuspendDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} targetUserId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersTargetUserIdTenantTenantIdDelete: async (nexusOpsTenant: string, targetUserId: string, tenantId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AdminUsersTargetUserIdTenantTenantIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'targetUserId' is not null or undefined
            assertParamExists('apiV1AdminUsersTargetUserIdTenantTenantIdDelete', 'targetUserId', targetUserId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiV1AdminUsersTargetUserIdTenantTenantIdDelete', 'tenantId', tenantId)
            const localVarPath = `/api/v1/admin/users/{targetUserId}/tenant/{tenantId}`
                .replace(`{${"targetUserId"}}`, encodeURIComponent(String(targetUserId)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} targetUserId 
         * @param {AdminUserUnsuspendDto} [adminUserUnsuspendDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersTargetUserIdUnsuspendPost: async (nexusOpsTenant: string, targetUserId: string, adminUserUnsuspendDto?: AdminUserUnsuspendDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AdminUsersTargetUserIdUnsuspendPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'targetUserId' is not null or undefined
            assertParamExists('apiV1AdminUsersTargetUserIdUnsuspendPost', 'targetUserId', targetUserId)
            const localVarPath = `/api/v1/admin/users/{targetUserId}/unsuspend`
                .replace(`{${"targetUserId"}}`, encodeURIComponent(String(targetUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserUnsuspendDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AdminAddUserToTenantDto} [adminAddUserToTenantDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersTenantPost: async (nexusOpsTenant: string, adminAddUserToTenantDto?: AdminAddUserToTenantDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AdminUsersTenantPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/admin/users/tenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAddUserToTenantDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersUserIdGet: async (nexusOpsTenant: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AdminUsersUserIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1AdminUsersUserIdGet', 'userId', userId)
            const localVarPath = `/api/v1/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {UpdateAdminUserDto} [updateAdminUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersUserIdPut: async (nexusOpsTenant: string, userId: string, updateAdminUserDto?: UpdateAdminUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AdminUsersUserIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1AdminUsersUserIdPut', 'userId', userId)
            const localVarPath = `/api/v1/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdminUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUsersApi - functional programming interface
 * @export
 */
export const AdminUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AdminUserGetPaginatedDto} [adminUserGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersGetPost(nexusOpsTenant: string, adminUserGetPaginatedDto?: AdminUserGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersGetPost(nexusOpsTenant, adminUserGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminUsersApi.apiV1AdminUsersGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} targetUserId 
         * @param {AdminUserActivateDto} [adminUserActivateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersTargetUserIdActivatePost(nexusOpsTenant: string, targetUserId: string, adminUserActivateDto?: AdminUserActivateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersTargetUserIdActivatePost(nexusOpsTenant, targetUserId, adminUserActivateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminUsersApi.apiV1AdminUsersTargetUserIdActivatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} targetUserId 
         * @param {AdminUserSuspendDto} [adminUserSuspendDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersTargetUserIdSuspendPost(nexusOpsTenant: string, targetUserId: string, adminUserSuspendDto?: AdminUserSuspendDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersTargetUserIdSuspendPost(nexusOpsTenant, targetUserId, adminUserSuspendDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminUsersApi.apiV1AdminUsersTargetUserIdSuspendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} targetUserId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersTargetUserIdTenantTenantIdDelete(nexusOpsTenant: string, targetUserId: string, tenantId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersTargetUserIdTenantTenantIdDelete(nexusOpsTenant, targetUserId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminUsersApi.apiV1AdminUsersTargetUserIdTenantTenantIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} targetUserId 
         * @param {AdminUserUnsuspendDto} [adminUserUnsuspendDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersTargetUserIdUnsuspendPost(nexusOpsTenant: string, targetUserId: string, adminUserUnsuspendDto?: AdminUserUnsuspendDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersTargetUserIdUnsuspendPost(nexusOpsTenant, targetUserId, adminUserUnsuspendDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminUsersApi.apiV1AdminUsersTargetUserIdUnsuspendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AdminAddUserToTenantDto} [adminAddUserToTenantDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersTenantPost(nexusOpsTenant: string, adminAddUserToTenantDto?: AdminAddUserToTenantDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersTenantPost(nexusOpsTenant, adminAddUserToTenantDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminUsersApi.apiV1AdminUsersTenantPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersUserIdGet(nexusOpsTenant: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersUserIdGet(nexusOpsTenant, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminUsersApi.apiV1AdminUsersUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {UpdateAdminUserDto} [updateAdminUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminUsersUserIdPut(nexusOpsTenant: string, userId: string, updateAdminUserDto?: UpdateAdminUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminUsersUserIdPut(nexusOpsTenant, userId, updateAdminUserDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminUsersApi.apiV1AdminUsersUserIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminUsersApi - factory interface
 * @export
 */
export const AdminUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUsersApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminUsersApiApiV1AdminUsersGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersGetPost(requestParameters: AdminUsersApiApiV1AdminUsersGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminUserDto> {
            return localVarFp.apiV1AdminUsersGetPost(requestParameters.nexusOpsTenant, requestParameters.adminUserGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUsersApiApiV1AdminUsersTargetUserIdActivatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersTargetUserIdActivatePost(requestParameters: AdminUsersApiApiV1AdminUsersTargetUserIdActivatePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminUserDto> {
            return localVarFp.apiV1AdminUsersTargetUserIdActivatePost(requestParameters.nexusOpsTenant, requestParameters.targetUserId, requestParameters.adminUserActivateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUsersApiApiV1AdminUsersTargetUserIdSuspendPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersTargetUserIdSuspendPost(requestParameters: AdminUsersApiApiV1AdminUsersTargetUserIdSuspendPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminUserDto> {
            return localVarFp.apiV1AdminUsersTargetUserIdSuspendPost(requestParameters.nexusOpsTenant, requestParameters.targetUserId, requestParameters.adminUserSuspendDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUsersApiApiV1AdminUsersTargetUserIdTenantTenantIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersTargetUserIdTenantTenantIdDelete(requestParameters: AdminUsersApiApiV1AdminUsersTargetUserIdTenantTenantIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminUsersTargetUserIdTenantTenantIdDelete(requestParameters.nexusOpsTenant, requestParameters.targetUserId, requestParameters.tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUsersApiApiV1AdminUsersTargetUserIdUnsuspendPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersTargetUserIdUnsuspendPost(requestParameters: AdminUsersApiApiV1AdminUsersTargetUserIdUnsuspendPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminUserDto> {
            return localVarFp.apiV1AdminUsersTargetUserIdUnsuspendPost(requestParameters.nexusOpsTenant, requestParameters.targetUserId, requestParameters.adminUserUnsuspendDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUsersApiApiV1AdminUsersTenantPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersTenantPost(requestParameters: AdminUsersApiApiV1AdminUsersTenantPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminUserDto> {
            return localVarFp.apiV1AdminUsersTenantPost(requestParameters.nexusOpsTenant, requestParameters.adminAddUserToTenantDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUsersApiApiV1AdminUsersUserIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersUserIdGet(requestParameters: AdminUsersApiApiV1AdminUsersUserIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminUserDto> {
            return localVarFp.apiV1AdminUsersUserIdGet(requestParameters.nexusOpsTenant, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUsersApiApiV1AdminUsersUserIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminUsersUserIdPut(requestParameters: AdminUsersApiApiV1AdminUsersUserIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminUserDto> {
            return localVarFp.apiV1AdminUsersUserIdPut(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.updateAdminUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminUsersGetPost operation in AdminUsersApi.
 * @export
 * @interface AdminUsersApiApiV1AdminUsersGetPostRequest
 */
export interface AdminUsersApiApiV1AdminUsersGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AdminUserGetPaginatedDto}
     * @memberof AdminUsersApiApiV1AdminUsersGetPost
     */
    readonly adminUserGetPaginatedDto?: AdminUserGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminUsersTargetUserIdActivatePost operation in AdminUsersApi.
 * @export
 * @interface AdminUsersApiApiV1AdminUsersTargetUserIdActivatePostRequest
 */
export interface AdminUsersApiApiV1AdminUsersTargetUserIdActivatePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersTargetUserIdActivatePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersTargetUserIdActivatePost
     */
    readonly targetUserId: string

    /**
     * 
     * @type {AdminUserActivateDto}
     * @memberof AdminUsersApiApiV1AdminUsersTargetUserIdActivatePost
     */
    readonly adminUserActivateDto?: AdminUserActivateDto
}

/**
 * Request parameters for apiV1AdminUsersTargetUserIdSuspendPost operation in AdminUsersApi.
 * @export
 * @interface AdminUsersApiApiV1AdminUsersTargetUserIdSuspendPostRequest
 */
export interface AdminUsersApiApiV1AdminUsersTargetUserIdSuspendPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersTargetUserIdSuspendPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersTargetUserIdSuspendPost
     */
    readonly targetUserId: string

    /**
     * 
     * @type {AdminUserSuspendDto}
     * @memberof AdminUsersApiApiV1AdminUsersTargetUserIdSuspendPost
     */
    readonly adminUserSuspendDto?: AdminUserSuspendDto
}

/**
 * Request parameters for apiV1AdminUsersTargetUserIdTenantTenantIdDelete operation in AdminUsersApi.
 * @export
 * @interface AdminUsersApiApiV1AdminUsersTargetUserIdTenantTenantIdDeleteRequest
 */
export interface AdminUsersApiApiV1AdminUsersTargetUserIdTenantTenantIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersTargetUserIdTenantTenantIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersTargetUserIdTenantTenantIdDelete
     */
    readonly targetUserId: string

    /**
     * 
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersTargetUserIdTenantTenantIdDelete
     */
    readonly tenantId: string
}

/**
 * Request parameters for apiV1AdminUsersTargetUserIdUnsuspendPost operation in AdminUsersApi.
 * @export
 * @interface AdminUsersApiApiV1AdminUsersTargetUserIdUnsuspendPostRequest
 */
export interface AdminUsersApiApiV1AdminUsersTargetUserIdUnsuspendPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersTargetUserIdUnsuspendPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersTargetUserIdUnsuspendPost
     */
    readonly targetUserId: string

    /**
     * 
     * @type {AdminUserUnsuspendDto}
     * @memberof AdminUsersApiApiV1AdminUsersTargetUserIdUnsuspendPost
     */
    readonly adminUserUnsuspendDto?: AdminUserUnsuspendDto
}

/**
 * Request parameters for apiV1AdminUsersTenantPost operation in AdminUsersApi.
 * @export
 * @interface AdminUsersApiApiV1AdminUsersTenantPostRequest
 */
export interface AdminUsersApiApiV1AdminUsersTenantPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersTenantPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AdminAddUserToTenantDto}
     * @memberof AdminUsersApiApiV1AdminUsersTenantPost
     */
    readonly adminAddUserToTenantDto?: AdminAddUserToTenantDto
}

/**
 * Request parameters for apiV1AdminUsersUserIdGet operation in AdminUsersApi.
 * @export
 * @interface AdminUsersApiApiV1AdminUsersUserIdGetRequest
 */
export interface AdminUsersApiApiV1AdminUsersUserIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersUserIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersUserIdGet
     */
    readonly userId: string
}

/**
 * Request parameters for apiV1AdminUsersUserIdPut operation in AdminUsersApi.
 * @export
 * @interface AdminUsersApiApiV1AdminUsersUserIdPutRequest
 */
export interface AdminUsersApiApiV1AdminUsersUserIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersUserIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AdminUsersApiApiV1AdminUsersUserIdPut
     */
    readonly userId: string

    /**
     * 
     * @type {UpdateAdminUserDto}
     * @memberof AdminUsersApiApiV1AdminUsersUserIdPut
     */
    readonly updateAdminUserDto?: UpdateAdminUserDto
}

/**
 * AdminUsersApi - object-oriented interface
 * @export
 * @class AdminUsersApi
 * @extends {BaseAPI}
 */
export class AdminUsersApi extends BaseAPI {
    /**
     * 
     * @param {AdminUsersApiApiV1AdminUsersGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public apiV1AdminUsersGetPost(requestParameters: AdminUsersApiApiV1AdminUsersGetPostRequest, options?: RawAxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).apiV1AdminUsersGetPost(requestParameters.nexusOpsTenant, requestParameters.adminUserGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUsersApiApiV1AdminUsersTargetUserIdActivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public apiV1AdminUsersTargetUserIdActivatePost(requestParameters: AdminUsersApiApiV1AdminUsersTargetUserIdActivatePostRequest, options?: RawAxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).apiV1AdminUsersTargetUserIdActivatePost(requestParameters.nexusOpsTenant, requestParameters.targetUserId, requestParameters.adminUserActivateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUsersApiApiV1AdminUsersTargetUserIdSuspendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public apiV1AdminUsersTargetUserIdSuspendPost(requestParameters: AdminUsersApiApiV1AdminUsersTargetUserIdSuspendPostRequest, options?: RawAxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).apiV1AdminUsersTargetUserIdSuspendPost(requestParameters.nexusOpsTenant, requestParameters.targetUserId, requestParameters.adminUserSuspendDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUsersApiApiV1AdminUsersTargetUserIdTenantTenantIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public apiV1AdminUsersTargetUserIdTenantTenantIdDelete(requestParameters: AdminUsersApiApiV1AdminUsersTargetUserIdTenantTenantIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).apiV1AdminUsersTargetUserIdTenantTenantIdDelete(requestParameters.nexusOpsTenant, requestParameters.targetUserId, requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUsersApiApiV1AdminUsersTargetUserIdUnsuspendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public apiV1AdminUsersTargetUserIdUnsuspendPost(requestParameters: AdminUsersApiApiV1AdminUsersTargetUserIdUnsuspendPostRequest, options?: RawAxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).apiV1AdminUsersTargetUserIdUnsuspendPost(requestParameters.nexusOpsTenant, requestParameters.targetUserId, requestParameters.adminUserUnsuspendDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUsersApiApiV1AdminUsersTenantPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public apiV1AdminUsersTenantPost(requestParameters: AdminUsersApiApiV1AdminUsersTenantPostRequest, options?: RawAxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).apiV1AdminUsersTenantPost(requestParameters.nexusOpsTenant, requestParameters.adminAddUserToTenantDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUsersApiApiV1AdminUsersUserIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public apiV1AdminUsersUserIdGet(requestParameters: AdminUsersApiApiV1AdminUsersUserIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).apiV1AdminUsersUserIdGet(requestParameters.nexusOpsTenant, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUsersApiApiV1AdminUsersUserIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public apiV1AdminUsersUserIdPut(requestParameters: AdminUsersApiApiV1AdminUsersUserIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).apiV1AdminUsersUserIdPut(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.updateAdminUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminVehicleBodyTypesApi - axios parameter creator
 * @export
 */
export const AdminVehicleBodyTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} bodyTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDelete: async (bodyTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyTypeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDelete', 'bodyTypeId', bodyTypeId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/body-types/{bodyTypeId}`
                .replace(`{${"bodyTypeId"}}`, encodeURIComponent(String(bodyTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bodyTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGet: async (bodyTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyTypeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGet', 'bodyTypeId', bodyTypeId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/body-types/{bodyTypeId}`
                .replace(`{${"bodyTypeId"}}`, encodeURIComponent(String(bodyTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bodyTypeId 
         * @param {AdminUpdateVehicleBodyTypeDto} [adminUpdateVehicleBodyTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPut: async (bodyTypeId: string, adminUpdateVehicleBodyTypeDto?: AdminUpdateVehicleBodyTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyTypeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPut', 'bodyTypeId', bodyTypeId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/body-types/{bodyTypeId}`
                .replace(`{${"bodyTypeId"}}`, encodeURIComponent(String(bodyTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateVehicleBodyTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminVehicleBodyTypeGetPaginatedDto} [adminVehicleBodyTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesBodyTypesGetPost: async (adminVehicleBodyTypeGetPaginatedDto?: AdminVehicleBodyTypeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/body-types/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleBodyTypeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateVehicleBodyTypeDto} [adminCreateVehicleBodyTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesBodyTypesPost: async (adminCreateVehicleBodyTypeDto?: AdminCreateVehicleBodyTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/body-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateVehicleBodyTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminVehicleBodyTypeSearchPaginatedDto} [adminVehicleBodyTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesBodyTypesSearchPost: async (adminVehicleBodyTypeSearchPaginatedDto?: AdminVehicleBodyTypeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/body-types/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleBodyTypeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVehicleBodyTypesApi - functional programming interface
 * @export
 */
export const AdminVehicleBodyTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVehicleBodyTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} bodyTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDelete(bodyTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDelete(bodyTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleBodyTypesApi.apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} bodyTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGet(bodyTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleBodyTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGet(bodyTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleBodyTypesApi.apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} bodyTypeId 
         * @param {AdminUpdateVehicleBodyTypeDto} [adminUpdateVehicleBodyTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPut(bodyTypeId: string, adminUpdateVehicleBodyTypeDto?: AdminUpdateVehicleBodyTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleBodyTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPut(bodyTypeId, adminUpdateVehicleBodyTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleBodyTypesApi.apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminVehicleBodyTypeGetPaginatedDto} [adminVehicleBodyTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesBodyTypesGetPost(adminVehicleBodyTypeGetPaginatedDto?: AdminVehicleBodyTypeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleBodyTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesBodyTypesGetPost(adminVehicleBodyTypeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleBodyTypesApi.apiV1AdminReferenceDataVehiclesBodyTypesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateVehicleBodyTypeDto} [adminCreateVehicleBodyTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesBodyTypesPost(adminCreateVehicleBodyTypeDto?: AdminCreateVehicleBodyTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleBodyTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesBodyTypesPost(adminCreateVehicleBodyTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleBodyTypesApi.apiV1AdminReferenceDataVehiclesBodyTypesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminVehicleBodyTypeSearchPaginatedDto} [adminVehicleBodyTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesBodyTypesSearchPost(adminVehicleBodyTypeSearchPaginatedDto?: AdminVehicleBodyTypeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleBodyTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesBodyTypesSearchPost(adminVehicleBodyTypeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleBodyTypesApi.apiV1AdminReferenceDataVehiclesBodyTypesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminVehicleBodyTypesApi - factory interface
 * @export
 */
export const AdminVehicleBodyTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVehicleBodyTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDelete(requestParameters: AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDelete(requestParameters.bodyTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGet(requestParameters: AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleBodyTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGet(requestParameters.bodyTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPut(requestParameters: AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleBodyTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPut(requestParameters.bodyTypeId, requestParameters.adminUpdateVehicleBodyTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesBodyTypesGetPost(requestParameters: AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleBodyTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesBodyTypesGetPost(requestParameters.adminVehicleBodyTypeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesBodyTypesPost(requestParameters: AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleBodyTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesBodyTypesPost(requestParameters.adminCreateVehicleBodyTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesBodyTypesSearchPost(requestParameters: AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleBodyTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesBodyTypesSearchPost(requestParameters.adminVehicleBodyTypeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDelete operation in AdminVehicleBodyTypesApi.
 * @export
 * @interface AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDeleteRequest
 */
export interface AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDelete
     */
    readonly bodyTypeId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGet operation in AdminVehicleBodyTypesApi.
 * @export
 * @interface AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGetRequest
 */
export interface AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGet
     */
    readonly bodyTypeId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPut operation in AdminVehicleBodyTypesApi.
 * @export
 * @interface AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPutRequest
 */
export interface AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPut
     */
    readonly bodyTypeId: string

    /**
     * 
     * @type {AdminUpdateVehicleBodyTypeDto}
     * @memberof AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPut
     */
    readonly adminUpdateVehicleBodyTypeDto?: AdminUpdateVehicleBodyTypeDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesBodyTypesGetPost operation in AdminVehicleBodyTypesApi.
 * @export
 * @interface AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesGetPostRequest
 */
export interface AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesGetPostRequest {
    /**
     * 
     * @type {AdminVehicleBodyTypeGetPaginatedDto}
     * @memberof AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesGetPost
     */
    readonly adminVehicleBodyTypeGetPaginatedDto?: AdminVehicleBodyTypeGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesBodyTypesPost operation in AdminVehicleBodyTypesApi.
 * @export
 * @interface AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesPostRequest
 */
export interface AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesPostRequest {
    /**
     * 
     * @type {AdminCreateVehicleBodyTypeDto}
     * @memberof AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesPost
     */
    readonly adminCreateVehicleBodyTypeDto?: AdminCreateVehicleBodyTypeDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesBodyTypesSearchPost operation in AdminVehicleBodyTypesApi.
 * @export
 * @interface AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesSearchPostRequest
 */
export interface AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesSearchPostRequest {
    /**
     * 
     * @type {AdminVehicleBodyTypeSearchPaginatedDto}
     * @memberof AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesSearchPost
     */
    readonly adminVehicleBodyTypeSearchPaginatedDto?: AdminVehicleBodyTypeSearchPaginatedDto
}

/**
 * AdminVehicleBodyTypesApi - object-oriented interface
 * @export
 * @class AdminVehicleBodyTypesApi
 * @extends {BaseAPI}
 */
export class AdminVehicleBodyTypesApi extends BaseAPI {
    /**
     * 
     * @param {AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleBodyTypesApi
     */
    public apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDelete(requestParameters: AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleBodyTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdDelete(requestParameters.bodyTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleBodyTypesApi
     */
    public apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGet(requestParameters: AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleBodyTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdGet(requestParameters.bodyTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleBodyTypesApi
     */
    public apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPut(requestParameters: AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleBodyTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesBodyTypesBodyTypeIdPut(requestParameters.bodyTypeId, requestParameters.adminUpdateVehicleBodyTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleBodyTypesApi
     */
    public apiV1AdminReferenceDataVehiclesBodyTypesGetPost(requestParameters: AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleBodyTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesBodyTypesGetPost(requestParameters.adminVehicleBodyTypeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleBodyTypesApi
     */
    public apiV1AdminReferenceDataVehiclesBodyTypesPost(requestParameters: AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleBodyTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesBodyTypesPost(requestParameters.adminCreateVehicleBodyTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleBodyTypesApi
     */
    public apiV1AdminReferenceDataVehiclesBodyTypesSearchPost(requestParameters: AdminVehicleBodyTypesApiApiV1AdminReferenceDataVehiclesBodyTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleBodyTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesBodyTypesSearchPost(requestParameters.adminVehicleBodyTypeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminVehicleFuelTypesApi - axios parameter creator
 * @export
 */
export const AdminVehicleFuelTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} fuelTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDelete: async (fuelTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fuelTypeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDelete', 'fuelTypeId', fuelTypeId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/fuel-types/{fuelTypeId}`
                .replace(`{${"fuelTypeId"}}`, encodeURIComponent(String(fuelTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fuelTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGet: async (fuelTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fuelTypeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGet', 'fuelTypeId', fuelTypeId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/fuel-types/{fuelTypeId}`
                .replace(`{${"fuelTypeId"}}`, encodeURIComponent(String(fuelTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fuelTypeId 
         * @param {AdminUpdateVehicleFuelTypeDto} [adminUpdateVehicleFuelTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPut: async (fuelTypeId: string, adminUpdateVehicleFuelTypeDto?: AdminUpdateVehicleFuelTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fuelTypeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPut', 'fuelTypeId', fuelTypeId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/fuel-types/{fuelTypeId}`
                .replace(`{${"fuelTypeId"}}`, encodeURIComponent(String(fuelTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateVehicleFuelTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminVehicleFuelTypeGetPaginatedDto} [adminVehicleFuelTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesFuelTypesGetPost: async (adminVehicleFuelTypeGetPaginatedDto?: AdminVehicleFuelTypeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/fuel-types/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleFuelTypeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateVehicleFuelTypeDto} [adminCreateVehicleFuelTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesFuelTypesPost: async (adminCreateVehicleFuelTypeDto?: AdminCreateVehicleFuelTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/fuel-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateVehicleFuelTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminVehicleFuelTypeSearchPaginatedDto} [adminVehicleFuelTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesFuelTypesSearchPost: async (adminVehicleFuelTypeSearchPaginatedDto?: AdminVehicleFuelTypeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/fuel-types/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleFuelTypeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVehicleFuelTypesApi - functional programming interface
 * @export
 */
export const AdminVehicleFuelTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVehicleFuelTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} fuelTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDelete(fuelTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDelete(fuelTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleFuelTypesApi.apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} fuelTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGet(fuelTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleFuelTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGet(fuelTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleFuelTypesApi.apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} fuelTypeId 
         * @param {AdminUpdateVehicleFuelTypeDto} [adminUpdateVehicleFuelTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPut(fuelTypeId: string, adminUpdateVehicleFuelTypeDto?: AdminUpdateVehicleFuelTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleFuelTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPut(fuelTypeId, adminUpdateVehicleFuelTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleFuelTypesApi.apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminVehicleFuelTypeGetPaginatedDto} [adminVehicleFuelTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesFuelTypesGetPost(adminVehicleFuelTypeGetPaginatedDto?: AdminVehicleFuelTypeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleFuelTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesFuelTypesGetPost(adminVehicleFuelTypeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleFuelTypesApi.apiV1AdminReferenceDataVehiclesFuelTypesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateVehicleFuelTypeDto} [adminCreateVehicleFuelTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesFuelTypesPost(adminCreateVehicleFuelTypeDto?: AdminCreateVehicleFuelTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleFuelTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesFuelTypesPost(adminCreateVehicleFuelTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleFuelTypesApi.apiV1AdminReferenceDataVehiclesFuelTypesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminVehicleFuelTypeSearchPaginatedDto} [adminVehicleFuelTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesFuelTypesSearchPost(adminVehicleFuelTypeSearchPaginatedDto?: AdminVehicleFuelTypeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleFuelTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesFuelTypesSearchPost(adminVehicleFuelTypeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleFuelTypesApi.apiV1AdminReferenceDataVehiclesFuelTypesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminVehicleFuelTypesApi - factory interface
 * @export
 */
export const AdminVehicleFuelTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVehicleFuelTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDelete(requestParameters: AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDelete(requestParameters.fuelTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGet(requestParameters: AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleFuelTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGet(requestParameters.fuelTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPut(requestParameters: AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleFuelTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPut(requestParameters.fuelTypeId, requestParameters.adminUpdateVehicleFuelTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesFuelTypesGetPost(requestParameters: AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleFuelTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesFuelTypesGetPost(requestParameters.adminVehicleFuelTypeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesFuelTypesPost(requestParameters: AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleFuelTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesFuelTypesPost(requestParameters.adminCreateVehicleFuelTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesFuelTypesSearchPost(requestParameters: AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleFuelTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesFuelTypesSearchPost(requestParameters.adminVehicleFuelTypeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDelete operation in AdminVehicleFuelTypesApi.
 * @export
 * @interface AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDeleteRequest
 */
export interface AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDelete
     */
    readonly fuelTypeId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGet operation in AdminVehicleFuelTypesApi.
 * @export
 * @interface AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGetRequest
 */
export interface AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGet
     */
    readonly fuelTypeId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPut operation in AdminVehicleFuelTypesApi.
 * @export
 * @interface AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPutRequest
 */
export interface AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPut
     */
    readonly fuelTypeId: string

    /**
     * 
     * @type {AdminUpdateVehicleFuelTypeDto}
     * @memberof AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPut
     */
    readonly adminUpdateVehicleFuelTypeDto?: AdminUpdateVehicleFuelTypeDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesFuelTypesGetPost operation in AdminVehicleFuelTypesApi.
 * @export
 * @interface AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesGetPostRequest
 */
export interface AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesGetPostRequest {
    /**
     * 
     * @type {AdminVehicleFuelTypeGetPaginatedDto}
     * @memberof AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesGetPost
     */
    readonly adminVehicleFuelTypeGetPaginatedDto?: AdminVehicleFuelTypeGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesFuelTypesPost operation in AdminVehicleFuelTypesApi.
 * @export
 * @interface AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesPostRequest
 */
export interface AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesPostRequest {
    /**
     * 
     * @type {AdminCreateVehicleFuelTypeDto}
     * @memberof AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesPost
     */
    readonly adminCreateVehicleFuelTypeDto?: AdminCreateVehicleFuelTypeDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesFuelTypesSearchPost operation in AdminVehicleFuelTypesApi.
 * @export
 * @interface AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesSearchPostRequest
 */
export interface AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesSearchPostRequest {
    /**
     * 
     * @type {AdminVehicleFuelTypeSearchPaginatedDto}
     * @memberof AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesSearchPost
     */
    readonly adminVehicleFuelTypeSearchPaginatedDto?: AdminVehicleFuelTypeSearchPaginatedDto
}

/**
 * AdminVehicleFuelTypesApi - object-oriented interface
 * @export
 * @class AdminVehicleFuelTypesApi
 * @extends {BaseAPI}
 */
export class AdminVehicleFuelTypesApi extends BaseAPI {
    /**
     * 
     * @param {AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleFuelTypesApi
     */
    public apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDelete(requestParameters: AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleFuelTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDelete(requestParameters.fuelTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleFuelTypesApi
     */
    public apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGet(requestParameters: AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleFuelTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdGet(requestParameters.fuelTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleFuelTypesApi
     */
    public apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPut(requestParameters: AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleFuelTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdPut(requestParameters.fuelTypeId, requestParameters.adminUpdateVehicleFuelTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleFuelTypesApi
     */
    public apiV1AdminReferenceDataVehiclesFuelTypesGetPost(requestParameters: AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleFuelTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesFuelTypesGetPost(requestParameters.adminVehicleFuelTypeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleFuelTypesApi
     */
    public apiV1AdminReferenceDataVehiclesFuelTypesPost(requestParameters: AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleFuelTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesFuelTypesPost(requestParameters.adminCreateVehicleFuelTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleFuelTypesApi
     */
    public apiV1AdminReferenceDataVehiclesFuelTypesSearchPost(requestParameters: AdminVehicleFuelTypesApiApiV1AdminReferenceDataVehiclesFuelTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleFuelTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesFuelTypesSearchPost(requestParameters.adminVehicleFuelTypeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminVehicleGenerationsApi - axios parameter creator
 * @export
 */
export const AdminVehicleGenerationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} generationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesGenerationsGenerationIdDelete: async (generationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generationId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesGenerationsGenerationIdDelete', 'generationId', generationId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/generations/{generationId}`
                .replace(`{${"generationId"}}`, encodeURIComponent(String(generationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} generationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesGenerationsGenerationIdGet: async (generationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generationId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesGenerationsGenerationIdGet', 'generationId', generationId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/generations/{generationId}`
                .replace(`{${"generationId"}}`, encodeURIComponent(String(generationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} generationId 
         * @param {AdminUpdateVehicleGenerationDto} [adminUpdateVehicleGenerationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesGenerationsGenerationIdPut: async (generationId: string, adminUpdateVehicleGenerationDto?: AdminUpdateVehicleGenerationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generationId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesGenerationsGenerationIdPut', 'generationId', generationId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/generations/{generationId}`
                .replace(`{${"generationId"}}`, encodeURIComponent(String(generationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateVehicleGenerationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminVehicleGenerationGetPaginatedDto} [adminVehicleGenerationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesGenerationsGetPost: async (adminVehicleGenerationGetPaginatedDto?: AdminVehicleGenerationGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/generations/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleGenerationGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateVehicleGenerationDto} [adminCreateVehicleGenerationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesGenerationsPost: async (adminCreateVehicleGenerationDto?: AdminCreateVehicleGenerationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/generations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateVehicleGenerationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminVehicleGenerationSearchPaginatedDto} [adminVehicleGenerationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesGenerationsSearchPost: async (adminVehicleGenerationSearchPaginatedDto?: AdminVehicleGenerationSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/generations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleGenerationSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVehicleGenerationsApi - functional programming interface
 * @export
 */
export const AdminVehicleGenerationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVehicleGenerationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} generationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesGenerationsGenerationIdDelete(generationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesGenerationsGenerationIdDelete(generationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleGenerationsApi.apiV1AdminReferenceDataVehiclesGenerationsGenerationIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} generationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesGenerationsGenerationIdGet(generationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleGenerationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesGenerationsGenerationIdGet(generationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleGenerationsApi.apiV1AdminReferenceDataVehiclesGenerationsGenerationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} generationId 
         * @param {AdminUpdateVehicleGenerationDto} [adminUpdateVehicleGenerationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesGenerationsGenerationIdPut(generationId: string, adminUpdateVehicleGenerationDto?: AdminUpdateVehicleGenerationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleGenerationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesGenerationsGenerationIdPut(generationId, adminUpdateVehicleGenerationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleGenerationsApi.apiV1AdminReferenceDataVehiclesGenerationsGenerationIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminVehicleGenerationGetPaginatedDto} [adminVehicleGenerationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesGenerationsGetPost(adminVehicleGenerationGetPaginatedDto?: AdminVehicleGenerationGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleGenerationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesGenerationsGetPost(adminVehicleGenerationGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleGenerationsApi.apiV1AdminReferenceDataVehiclesGenerationsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateVehicleGenerationDto} [adminCreateVehicleGenerationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesGenerationsPost(adminCreateVehicleGenerationDto?: AdminCreateVehicleGenerationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleGenerationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesGenerationsPost(adminCreateVehicleGenerationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleGenerationsApi.apiV1AdminReferenceDataVehiclesGenerationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminVehicleGenerationSearchPaginatedDto} [adminVehicleGenerationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesGenerationsSearchPost(adminVehicleGenerationSearchPaginatedDto?: AdminVehicleGenerationSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleGenerationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesGenerationsSearchPost(adminVehicleGenerationSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleGenerationsApi.apiV1AdminReferenceDataVehiclesGenerationsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminVehicleGenerationsApi - factory interface
 * @export
 */
export const AdminVehicleGenerationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVehicleGenerationsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesGenerationsGenerationIdDelete(requestParameters: AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminReferenceDataVehiclesGenerationsGenerationIdDelete(requestParameters.generationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesGenerationsGenerationIdGet(requestParameters: AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleGenerationDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesGenerationsGenerationIdGet(requestParameters.generationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesGenerationsGenerationIdPut(requestParameters: AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleGenerationDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesGenerationsGenerationIdPut(requestParameters.generationId, requestParameters.adminUpdateVehicleGenerationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesGenerationsGetPost(requestParameters: AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleGenerationDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesGenerationsGetPost(requestParameters.adminVehicleGenerationGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesGenerationsPost(requestParameters: AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleGenerationDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesGenerationsPost(requestParameters.adminCreateVehicleGenerationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesGenerationsSearchPost(requestParameters: AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleGenerationDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesGenerationsSearchPost(requestParameters.adminVehicleGenerationSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesGenerationsGenerationIdDelete operation in AdminVehicleGenerationsApi.
 * @export
 * @interface AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdDeleteRequest
 */
export interface AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdDelete
     */
    readonly generationId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesGenerationsGenerationIdGet operation in AdminVehicleGenerationsApi.
 * @export
 * @interface AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdGetRequest
 */
export interface AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdGet
     */
    readonly generationId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesGenerationsGenerationIdPut operation in AdminVehicleGenerationsApi.
 * @export
 * @interface AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdPutRequest
 */
export interface AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdPut
     */
    readonly generationId: string

    /**
     * 
     * @type {AdminUpdateVehicleGenerationDto}
     * @memberof AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdPut
     */
    readonly adminUpdateVehicleGenerationDto?: AdminUpdateVehicleGenerationDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesGenerationsGetPost operation in AdminVehicleGenerationsApi.
 * @export
 * @interface AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGetPostRequest
 */
export interface AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGetPostRequest {
    /**
     * 
     * @type {AdminVehicleGenerationGetPaginatedDto}
     * @memberof AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGetPost
     */
    readonly adminVehicleGenerationGetPaginatedDto?: AdminVehicleGenerationGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesGenerationsPost operation in AdminVehicleGenerationsApi.
 * @export
 * @interface AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsPostRequest
 */
export interface AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsPostRequest {
    /**
     * 
     * @type {AdminCreateVehicleGenerationDto}
     * @memberof AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsPost
     */
    readonly adminCreateVehicleGenerationDto?: AdminCreateVehicleGenerationDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesGenerationsSearchPost operation in AdminVehicleGenerationsApi.
 * @export
 * @interface AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsSearchPostRequest
 */
export interface AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsSearchPostRequest {
    /**
     * 
     * @type {AdminVehicleGenerationSearchPaginatedDto}
     * @memberof AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsSearchPost
     */
    readonly adminVehicleGenerationSearchPaginatedDto?: AdminVehicleGenerationSearchPaginatedDto
}

/**
 * AdminVehicleGenerationsApi - object-oriented interface
 * @export
 * @class AdminVehicleGenerationsApi
 * @extends {BaseAPI}
 */
export class AdminVehicleGenerationsApi extends BaseAPI {
    /**
     * 
     * @param {AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleGenerationsApi
     */
    public apiV1AdminReferenceDataVehiclesGenerationsGenerationIdDelete(requestParameters: AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleGenerationsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesGenerationsGenerationIdDelete(requestParameters.generationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleGenerationsApi
     */
    public apiV1AdminReferenceDataVehiclesGenerationsGenerationIdGet(requestParameters: AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleGenerationsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesGenerationsGenerationIdGet(requestParameters.generationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleGenerationsApi
     */
    public apiV1AdminReferenceDataVehiclesGenerationsGenerationIdPut(requestParameters: AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGenerationIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleGenerationsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesGenerationsGenerationIdPut(requestParameters.generationId, requestParameters.adminUpdateVehicleGenerationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleGenerationsApi
     */
    public apiV1AdminReferenceDataVehiclesGenerationsGetPost(requestParameters: AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleGenerationsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesGenerationsGetPost(requestParameters.adminVehicleGenerationGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleGenerationsApi
     */
    public apiV1AdminReferenceDataVehiclesGenerationsPost(requestParameters: AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleGenerationsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesGenerationsPost(requestParameters.adminCreateVehicleGenerationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleGenerationsApi
     */
    public apiV1AdminReferenceDataVehiclesGenerationsSearchPost(requestParameters: AdminVehicleGenerationsApiApiV1AdminReferenceDataVehiclesGenerationsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleGenerationsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesGenerationsSearchPost(requestParameters.adminVehicleGenerationSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminVehicleMakesApi - axios parameter creator
 * @export
 */
export const AdminVehicleMakesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminVehicleMakeGetPaginatedDto} [adminVehicleMakeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesMakesGetPost: async (adminVehicleMakeGetPaginatedDto?: AdminVehicleMakeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/makes/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleMakeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} makeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesMakesMakeIdDelete: async (makeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'makeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesMakesMakeIdDelete', 'makeId', makeId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/makes/{makeId}`
                .replace(`{${"makeId"}}`, encodeURIComponent(String(makeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} makeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesMakesMakeIdGet: async (makeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'makeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesMakesMakeIdGet', 'makeId', makeId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/makes/{makeId}`
                .replace(`{${"makeId"}}`, encodeURIComponent(String(makeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} makeId 
         * @param {AdminUpdateVehicleMakeDto} [adminUpdateVehicleMakeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesMakesMakeIdPut: async (makeId: string, adminUpdateVehicleMakeDto?: AdminUpdateVehicleMakeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'makeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesMakesMakeIdPut', 'makeId', makeId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/makes/{makeId}`
                .replace(`{${"makeId"}}`, encodeURIComponent(String(makeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateVehicleMakeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateVehicleMakeDto} [adminCreateVehicleMakeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesMakesPost: async (adminCreateVehicleMakeDto?: AdminCreateVehicleMakeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/makes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateVehicleMakeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminVehicleMakeSearchPaginatedDto} [adminVehicleMakeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesMakesSearchPost: async (adminVehicleMakeSearchPaginatedDto?: AdminVehicleMakeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/makes/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleMakeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVehicleMakesApi - functional programming interface
 * @export
 */
export const AdminVehicleMakesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVehicleMakesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminVehicleMakeGetPaginatedDto} [adminVehicleMakeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesMakesGetPost(adminVehicleMakeGetPaginatedDto?: AdminVehicleMakeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleMakeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesMakesGetPost(adminVehicleMakeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleMakesApi.apiV1AdminReferenceDataVehiclesMakesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} makeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesMakesMakeIdDelete(makeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesMakesMakeIdDelete(makeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleMakesApi.apiV1AdminReferenceDataVehiclesMakesMakeIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} makeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesMakesMakeIdGet(makeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleMakeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesMakesMakeIdGet(makeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleMakesApi.apiV1AdminReferenceDataVehiclesMakesMakeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} makeId 
         * @param {AdminUpdateVehicleMakeDto} [adminUpdateVehicleMakeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesMakesMakeIdPut(makeId: string, adminUpdateVehicleMakeDto?: AdminUpdateVehicleMakeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleMakeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesMakesMakeIdPut(makeId, adminUpdateVehicleMakeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleMakesApi.apiV1AdminReferenceDataVehiclesMakesMakeIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateVehicleMakeDto} [adminCreateVehicleMakeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesMakesPost(adminCreateVehicleMakeDto?: AdminCreateVehicleMakeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleMakeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesMakesPost(adminCreateVehicleMakeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleMakesApi.apiV1AdminReferenceDataVehiclesMakesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminVehicleMakeSearchPaginatedDto} [adminVehicleMakeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesMakesSearchPost(adminVehicleMakeSearchPaginatedDto?: AdminVehicleMakeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleMakeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesMakesSearchPost(adminVehicleMakeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleMakesApi.apiV1AdminReferenceDataVehiclesMakesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminVehicleMakesApi - factory interface
 * @export
 */
export const AdminVehicleMakesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVehicleMakesApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesMakesGetPost(requestParameters: AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleMakeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesMakesGetPost(requestParameters.adminVehicleMakeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesMakesMakeIdDelete(requestParameters: AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminReferenceDataVehiclesMakesMakeIdDelete(requestParameters.makeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesMakesMakeIdGet(requestParameters: AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleMakeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesMakesMakeIdGet(requestParameters.makeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesMakesMakeIdPut(requestParameters: AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleMakeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesMakesMakeIdPut(requestParameters.makeId, requestParameters.adminUpdateVehicleMakeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesMakesPost(requestParameters: AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleMakeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesMakesPost(requestParameters.adminCreateVehicleMakeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesMakesSearchPost(requestParameters: AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleMakeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesMakesSearchPost(requestParameters.adminVehicleMakeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesMakesGetPost operation in AdminVehicleMakesApi.
 * @export
 * @interface AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesGetPostRequest
 */
export interface AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesGetPostRequest {
    /**
     * 
     * @type {AdminVehicleMakeGetPaginatedDto}
     * @memberof AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesGetPost
     */
    readonly adminVehicleMakeGetPaginatedDto?: AdminVehicleMakeGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesMakesMakeIdDelete operation in AdminVehicleMakesApi.
 * @export
 * @interface AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdDeleteRequest
 */
export interface AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdDelete
     */
    readonly makeId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesMakesMakeIdGet operation in AdminVehicleMakesApi.
 * @export
 * @interface AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdGetRequest
 */
export interface AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdGet
     */
    readonly makeId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesMakesMakeIdPut operation in AdminVehicleMakesApi.
 * @export
 * @interface AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdPutRequest
 */
export interface AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdPut
     */
    readonly makeId: string

    /**
     * 
     * @type {AdminUpdateVehicleMakeDto}
     * @memberof AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdPut
     */
    readonly adminUpdateVehicleMakeDto?: AdminUpdateVehicleMakeDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesMakesPost operation in AdminVehicleMakesApi.
 * @export
 * @interface AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesPostRequest
 */
export interface AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesPostRequest {
    /**
     * 
     * @type {AdminCreateVehicleMakeDto}
     * @memberof AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesPost
     */
    readonly adminCreateVehicleMakeDto?: AdminCreateVehicleMakeDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesMakesSearchPost operation in AdminVehicleMakesApi.
 * @export
 * @interface AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesSearchPostRequest
 */
export interface AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesSearchPostRequest {
    /**
     * 
     * @type {AdminVehicleMakeSearchPaginatedDto}
     * @memberof AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesSearchPost
     */
    readonly adminVehicleMakeSearchPaginatedDto?: AdminVehicleMakeSearchPaginatedDto
}

/**
 * AdminVehicleMakesApi - object-oriented interface
 * @export
 * @class AdminVehicleMakesApi
 * @extends {BaseAPI}
 */
export class AdminVehicleMakesApi extends BaseAPI {
    /**
     * 
     * @param {AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleMakesApi
     */
    public apiV1AdminReferenceDataVehiclesMakesGetPost(requestParameters: AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleMakesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesMakesGetPost(requestParameters.adminVehicleMakeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleMakesApi
     */
    public apiV1AdminReferenceDataVehiclesMakesMakeIdDelete(requestParameters: AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleMakesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesMakesMakeIdDelete(requestParameters.makeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleMakesApi
     */
    public apiV1AdminReferenceDataVehiclesMakesMakeIdGet(requestParameters: AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleMakesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesMakesMakeIdGet(requestParameters.makeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleMakesApi
     */
    public apiV1AdminReferenceDataVehiclesMakesMakeIdPut(requestParameters: AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesMakeIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleMakesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesMakesMakeIdPut(requestParameters.makeId, requestParameters.adminUpdateVehicleMakeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleMakesApi
     */
    public apiV1AdminReferenceDataVehiclesMakesPost(requestParameters: AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleMakesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesMakesPost(requestParameters.adminCreateVehicleMakeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleMakesApi
     */
    public apiV1AdminReferenceDataVehiclesMakesSearchPost(requestParameters: AdminVehicleMakesApiApiV1AdminReferenceDataVehiclesMakesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleMakesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesMakesSearchPost(requestParameters.adminVehicleMakeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminVehicleModelsApi - axios parameter creator
 * @export
 */
export const AdminVehicleModelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminVehicleModelGetPaginatedDto} [adminVehicleModelGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModelsGetPost: async (adminVehicleModelGetPaginatedDto?: AdminVehicleModelGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/models/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleModelGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModelsModelIdDelete: async (modelId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesModelsModelIdDelete', 'modelId', modelId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/models/{modelId}`
                .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModelsModelIdGet: async (modelId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesModelsModelIdGet', 'modelId', modelId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/models/{modelId}`
                .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelId 
         * @param {AdminUpdateVehicleModelDto} [adminUpdateVehicleModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModelsModelIdPut: async (modelId: string, adminUpdateVehicleModelDto?: AdminUpdateVehicleModelDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesModelsModelIdPut', 'modelId', modelId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/models/{modelId}`
                .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateVehicleModelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateVehicleModelDto} [adminCreateVehicleModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModelsPost: async (adminCreateVehicleModelDto?: AdminCreateVehicleModelDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateVehicleModelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminVehicleModelSearchPaginatedDto} [adminVehicleModelSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModelsSearchPost: async (adminVehicleModelSearchPaginatedDto?: AdminVehicleModelSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/models/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleModelSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVehicleModelsApi - functional programming interface
 * @export
 */
export const AdminVehicleModelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVehicleModelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminVehicleModelGetPaginatedDto} [adminVehicleModelGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesModelsGetPost(adminVehicleModelGetPaginatedDto?: AdminVehicleModelGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesModelsGetPost(adminVehicleModelGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleModelsApi.apiV1AdminReferenceDataVehiclesModelsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesModelsModelIdDelete(modelId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesModelsModelIdDelete(modelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleModelsApi.apiV1AdminReferenceDataVehiclesModelsModelIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesModelsModelIdGet(modelId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesModelsModelIdGet(modelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleModelsApi.apiV1AdminReferenceDataVehiclesModelsModelIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} modelId 
         * @param {AdminUpdateVehicleModelDto} [adminUpdateVehicleModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesModelsModelIdPut(modelId: string, adminUpdateVehicleModelDto?: AdminUpdateVehicleModelDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesModelsModelIdPut(modelId, adminUpdateVehicleModelDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleModelsApi.apiV1AdminReferenceDataVehiclesModelsModelIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateVehicleModelDto} [adminCreateVehicleModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesModelsPost(adminCreateVehicleModelDto?: AdminCreateVehicleModelDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesModelsPost(adminCreateVehicleModelDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleModelsApi.apiV1AdminReferenceDataVehiclesModelsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminVehicleModelSearchPaginatedDto} [adminVehicleModelSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesModelsSearchPost(adminVehicleModelSearchPaginatedDto?: AdminVehicleModelSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesModelsSearchPost(adminVehicleModelSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleModelsApi.apiV1AdminReferenceDataVehiclesModelsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminVehicleModelsApi - factory interface
 * @export
 */
export const AdminVehicleModelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVehicleModelsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModelsGetPost(requestParameters: AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleModelDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesModelsGetPost(requestParameters.adminVehicleModelGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModelsModelIdDelete(requestParameters: AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminReferenceDataVehiclesModelsModelIdDelete(requestParameters.modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModelsModelIdGet(requestParameters: AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleModelDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesModelsModelIdGet(requestParameters.modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModelsModelIdPut(requestParameters: AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleModelDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesModelsModelIdPut(requestParameters.modelId, requestParameters.adminUpdateVehicleModelDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModelsPost(requestParameters: AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleModelDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesModelsPost(requestParameters.adminCreateVehicleModelDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModelsSearchPost(requestParameters: AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleModelDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesModelsSearchPost(requestParameters.adminVehicleModelSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesModelsGetPost operation in AdminVehicleModelsApi.
 * @export
 * @interface AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsGetPostRequest
 */
export interface AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsGetPostRequest {
    /**
     * 
     * @type {AdminVehicleModelGetPaginatedDto}
     * @memberof AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsGetPost
     */
    readonly adminVehicleModelGetPaginatedDto?: AdminVehicleModelGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesModelsModelIdDelete operation in AdminVehicleModelsApi.
 * @export
 * @interface AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdDeleteRequest
 */
export interface AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdDelete
     */
    readonly modelId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesModelsModelIdGet operation in AdminVehicleModelsApi.
 * @export
 * @interface AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdGetRequest
 */
export interface AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdGet
     */
    readonly modelId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesModelsModelIdPut operation in AdminVehicleModelsApi.
 * @export
 * @interface AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdPutRequest
 */
export interface AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdPut
     */
    readonly modelId: string

    /**
     * 
     * @type {AdminUpdateVehicleModelDto}
     * @memberof AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdPut
     */
    readonly adminUpdateVehicleModelDto?: AdminUpdateVehicleModelDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesModelsPost operation in AdminVehicleModelsApi.
 * @export
 * @interface AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsPostRequest
 */
export interface AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsPostRequest {
    /**
     * 
     * @type {AdminCreateVehicleModelDto}
     * @memberof AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsPost
     */
    readonly adminCreateVehicleModelDto?: AdminCreateVehicleModelDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesModelsSearchPost operation in AdminVehicleModelsApi.
 * @export
 * @interface AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsSearchPostRequest
 */
export interface AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsSearchPostRequest {
    /**
     * 
     * @type {AdminVehicleModelSearchPaginatedDto}
     * @memberof AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsSearchPost
     */
    readonly adminVehicleModelSearchPaginatedDto?: AdminVehicleModelSearchPaginatedDto
}

/**
 * AdminVehicleModelsApi - object-oriented interface
 * @export
 * @class AdminVehicleModelsApi
 * @extends {BaseAPI}
 */
export class AdminVehicleModelsApi extends BaseAPI {
    /**
     * 
     * @param {AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleModelsApi
     */
    public apiV1AdminReferenceDataVehiclesModelsGetPost(requestParameters: AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesModelsGetPost(requestParameters.adminVehicleModelGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleModelsApi
     */
    public apiV1AdminReferenceDataVehiclesModelsModelIdDelete(requestParameters: AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesModelsModelIdDelete(requestParameters.modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleModelsApi
     */
    public apiV1AdminReferenceDataVehiclesModelsModelIdGet(requestParameters: AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesModelsModelIdGet(requestParameters.modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleModelsApi
     */
    public apiV1AdminReferenceDataVehiclesModelsModelIdPut(requestParameters: AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsModelIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesModelsModelIdPut(requestParameters.modelId, requestParameters.adminUpdateVehicleModelDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleModelsApi
     */
    public apiV1AdminReferenceDataVehiclesModelsPost(requestParameters: AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesModelsPost(requestParameters.adminCreateVehicleModelDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleModelsApi
     */
    public apiV1AdminReferenceDataVehiclesModelsSearchPost(requestParameters: AdminVehicleModelsApiApiV1AdminReferenceDataVehiclesModelsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesModelsSearchPost(requestParameters.adminVehicleModelSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminVehicleModificationsApi - axios parameter creator
 * @export
 */
export const AdminVehicleModificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminVehicleModificationGetPaginatedDto} [adminVehicleModificationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModificationsGetPost: async (adminVehicleModificationGetPaginatedDto?: AdminVehicleModificationGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/modifications/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleModificationGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModificationsModificationIdDelete: async (modificationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modificationId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesModificationsModificationIdDelete', 'modificationId', modificationId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/modifications/{modificationId}`
                .replace(`{${"modificationId"}}`, encodeURIComponent(String(modificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModificationsModificationIdGet: async (modificationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modificationId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesModificationsModificationIdGet', 'modificationId', modificationId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/modifications/{modificationId}`
                .replace(`{${"modificationId"}}`, encodeURIComponent(String(modificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modificationId 
         * @param {AdminUpdateVehicleModificationDto} [adminUpdateVehicleModificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModificationsModificationIdPut: async (modificationId: string, adminUpdateVehicleModificationDto?: AdminUpdateVehicleModificationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modificationId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesModificationsModificationIdPut', 'modificationId', modificationId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/modifications/{modificationId}`
                .replace(`{${"modificationId"}}`, encodeURIComponent(String(modificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateVehicleModificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateVehicleModificationDto} [adminCreateVehicleModificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModificationsPost: async (adminCreateVehicleModificationDto?: AdminCreateVehicleModificationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/modifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateVehicleModificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminVehicleModificationSearchPaginatedDto} [adminVehicleModificationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModificationsSearchPost: async (adminVehicleModificationSearchPaginatedDto?: AdminVehicleModificationSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/modifications/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleModificationSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVehicleModificationsApi - functional programming interface
 * @export
 */
export const AdminVehicleModificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVehicleModificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminVehicleModificationGetPaginatedDto} [adminVehicleModificationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesModificationsGetPost(adminVehicleModificationGetPaginatedDto?: AdminVehicleModificationGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleModificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesModificationsGetPost(adminVehicleModificationGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleModificationsApi.apiV1AdminReferenceDataVehiclesModificationsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} modificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesModificationsModificationIdDelete(modificationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesModificationsModificationIdDelete(modificationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleModificationsApi.apiV1AdminReferenceDataVehiclesModificationsModificationIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} modificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesModificationsModificationIdGet(modificationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleModificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesModificationsModificationIdGet(modificationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleModificationsApi.apiV1AdminReferenceDataVehiclesModificationsModificationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} modificationId 
         * @param {AdminUpdateVehicleModificationDto} [adminUpdateVehicleModificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesModificationsModificationIdPut(modificationId: string, adminUpdateVehicleModificationDto?: AdminUpdateVehicleModificationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleModificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesModificationsModificationIdPut(modificationId, adminUpdateVehicleModificationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleModificationsApi.apiV1AdminReferenceDataVehiclesModificationsModificationIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateVehicleModificationDto} [adminCreateVehicleModificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesModificationsPost(adminCreateVehicleModificationDto?: AdminCreateVehicleModificationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleModificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesModificationsPost(adminCreateVehicleModificationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleModificationsApi.apiV1AdminReferenceDataVehiclesModificationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminVehicleModificationSearchPaginatedDto} [adminVehicleModificationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesModificationsSearchPost(adminVehicleModificationSearchPaginatedDto?: AdminVehicleModificationSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleModificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesModificationsSearchPost(adminVehicleModificationSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleModificationsApi.apiV1AdminReferenceDataVehiclesModificationsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminVehicleModificationsApi - factory interface
 * @export
 */
export const AdminVehicleModificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVehicleModificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModificationsGetPost(requestParameters: AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleModificationDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesModificationsGetPost(requestParameters.adminVehicleModificationGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModificationsModificationIdDelete(requestParameters: AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminReferenceDataVehiclesModificationsModificationIdDelete(requestParameters.modificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModificationsModificationIdGet(requestParameters: AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleModificationDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesModificationsModificationIdGet(requestParameters.modificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModificationsModificationIdPut(requestParameters: AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleModificationDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesModificationsModificationIdPut(requestParameters.modificationId, requestParameters.adminUpdateVehicleModificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModificationsPost(requestParameters: AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleModificationDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesModificationsPost(requestParameters.adminCreateVehicleModificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesModificationsSearchPost(requestParameters: AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleModificationDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesModificationsSearchPost(requestParameters.adminVehicleModificationSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesModificationsGetPost operation in AdminVehicleModificationsApi.
 * @export
 * @interface AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsGetPostRequest
 */
export interface AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsGetPostRequest {
    /**
     * 
     * @type {AdminVehicleModificationGetPaginatedDto}
     * @memberof AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsGetPost
     */
    readonly adminVehicleModificationGetPaginatedDto?: AdminVehicleModificationGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesModificationsModificationIdDelete operation in AdminVehicleModificationsApi.
 * @export
 * @interface AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdDeleteRequest
 */
export interface AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdDelete
     */
    readonly modificationId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesModificationsModificationIdGet operation in AdminVehicleModificationsApi.
 * @export
 * @interface AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdGetRequest
 */
export interface AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdGet
     */
    readonly modificationId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesModificationsModificationIdPut operation in AdminVehicleModificationsApi.
 * @export
 * @interface AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdPutRequest
 */
export interface AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdPut
     */
    readonly modificationId: string

    /**
     * 
     * @type {AdminUpdateVehicleModificationDto}
     * @memberof AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdPut
     */
    readonly adminUpdateVehicleModificationDto?: AdminUpdateVehicleModificationDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesModificationsPost operation in AdminVehicleModificationsApi.
 * @export
 * @interface AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsPostRequest
 */
export interface AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsPostRequest {
    /**
     * 
     * @type {AdminCreateVehicleModificationDto}
     * @memberof AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsPost
     */
    readonly adminCreateVehicleModificationDto?: AdminCreateVehicleModificationDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesModificationsSearchPost operation in AdminVehicleModificationsApi.
 * @export
 * @interface AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsSearchPostRequest
 */
export interface AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsSearchPostRequest {
    /**
     * 
     * @type {AdminVehicleModificationSearchPaginatedDto}
     * @memberof AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsSearchPost
     */
    readonly adminVehicleModificationSearchPaginatedDto?: AdminVehicleModificationSearchPaginatedDto
}

/**
 * AdminVehicleModificationsApi - object-oriented interface
 * @export
 * @class AdminVehicleModificationsApi
 * @extends {BaseAPI}
 */
export class AdminVehicleModificationsApi extends BaseAPI {
    /**
     * 
     * @param {AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleModificationsApi
     */
    public apiV1AdminReferenceDataVehiclesModificationsGetPost(requestParameters: AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleModificationsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesModificationsGetPost(requestParameters.adminVehicleModificationGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleModificationsApi
     */
    public apiV1AdminReferenceDataVehiclesModificationsModificationIdDelete(requestParameters: AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleModificationsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesModificationsModificationIdDelete(requestParameters.modificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleModificationsApi
     */
    public apiV1AdminReferenceDataVehiclesModificationsModificationIdGet(requestParameters: AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleModificationsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesModificationsModificationIdGet(requestParameters.modificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleModificationsApi
     */
    public apiV1AdminReferenceDataVehiclesModificationsModificationIdPut(requestParameters: AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsModificationIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleModificationsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesModificationsModificationIdPut(requestParameters.modificationId, requestParameters.adminUpdateVehicleModificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleModificationsApi
     */
    public apiV1AdminReferenceDataVehiclesModificationsPost(requestParameters: AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleModificationsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesModificationsPost(requestParameters.adminCreateVehicleModificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleModificationsApi
     */
    public apiV1AdminReferenceDataVehiclesModificationsSearchPost(requestParameters: AdminVehicleModificationsApiApiV1AdminReferenceDataVehiclesModificationsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleModificationsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesModificationsSearchPost(requestParameters.adminVehicleModificationSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminVehiclePartTypesApi - axios parameter creator
 * @export
 */
export const AdminVehiclePartTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminVehiclePartTypeGetPaginatedDto} [adminVehiclePartTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesPartTypesGetPost: async (adminVehiclePartTypeGetPaginatedDto?: AdminVehiclePartTypeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/part-types/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehiclePartTypeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDelete: async (partTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partTypeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDelete', 'partTypeId', partTypeId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/part-types/{partTypeId}`
                .replace(`{${"partTypeId"}}`, encodeURIComponent(String(partTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGet: async (partTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partTypeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGet', 'partTypeId', partTypeId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/part-types/{partTypeId}`
                .replace(`{${"partTypeId"}}`, encodeURIComponent(String(partTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partTypeId 
         * @param {AdminUpdateVehiclePartTypeDto} [adminUpdateVehiclePartTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPut: async (partTypeId: string, adminUpdateVehiclePartTypeDto?: AdminUpdateVehiclePartTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partTypeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPut', 'partTypeId', partTypeId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/part-types/{partTypeId}`
                .replace(`{${"partTypeId"}}`, encodeURIComponent(String(partTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateVehiclePartTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateVehiclePartTypeDto} [adminCreateVehiclePartTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesPartTypesPost: async (adminCreateVehiclePartTypeDto?: AdminCreateVehiclePartTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/part-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateVehiclePartTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminVehiclePartTypeSearchPaginatedDto} [adminVehiclePartTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesPartTypesSearchPost: async (adminVehiclePartTypeSearchPaginatedDto?: AdminVehiclePartTypeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/part-types/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehiclePartTypeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVehiclePartTypesApi - functional programming interface
 * @export
 */
export const AdminVehiclePartTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVehiclePartTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminVehiclePartTypeGetPaginatedDto} [adminVehiclePartTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesPartTypesGetPost(adminVehiclePartTypeGetPaginatedDto?: AdminVehiclePartTypeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehiclePartTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesPartTypesGetPost(adminVehiclePartTypeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehiclePartTypesApi.apiV1AdminReferenceDataVehiclesPartTypesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDelete(partTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDelete(partTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehiclePartTypesApi.apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGet(partTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehiclePartTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGet(partTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehiclePartTypesApi.apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partTypeId 
         * @param {AdminUpdateVehiclePartTypeDto} [adminUpdateVehiclePartTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPut(partTypeId: string, adminUpdateVehiclePartTypeDto?: AdminUpdateVehiclePartTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehiclePartTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPut(partTypeId, adminUpdateVehiclePartTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehiclePartTypesApi.apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateVehiclePartTypeDto} [adminCreateVehiclePartTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesPartTypesPost(adminCreateVehiclePartTypeDto?: AdminCreateVehiclePartTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehiclePartTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesPartTypesPost(adminCreateVehiclePartTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehiclePartTypesApi.apiV1AdminReferenceDataVehiclesPartTypesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminVehiclePartTypeSearchPaginatedDto} [adminVehiclePartTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesPartTypesSearchPost(adminVehiclePartTypeSearchPaginatedDto?: AdminVehiclePartTypeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehiclePartTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesPartTypesSearchPost(adminVehiclePartTypeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehiclePartTypesApi.apiV1AdminReferenceDataVehiclesPartTypesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminVehiclePartTypesApi - factory interface
 * @export
 */
export const AdminVehiclePartTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVehiclePartTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesPartTypesGetPost(requestParameters: AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehiclePartTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesPartTypesGetPost(requestParameters.adminVehiclePartTypeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDelete(requestParameters: AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDelete(requestParameters.partTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGet(requestParameters: AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehiclePartTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGet(requestParameters.partTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPut(requestParameters: AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehiclePartTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPut(requestParameters.partTypeId, requestParameters.adminUpdateVehiclePartTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesPartTypesPost(requestParameters: AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehiclePartTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesPartTypesPost(requestParameters.adminCreateVehiclePartTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesPartTypesSearchPost(requestParameters: AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehiclePartTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesPartTypesSearchPost(requestParameters.adminVehiclePartTypeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesPartTypesGetPost operation in AdminVehiclePartTypesApi.
 * @export
 * @interface AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesGetPostRequest
 */
export interface AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesGetPostRequest {
    /**
     * 
     * @type {AdminVehiclePartTypeGetPaginatedDto}
     * @memberof AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesGetPost
     */
    readonly adminVehiclePartTypeGetPaginatedDto?: AdminVehiclePartTypeGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDelete operation in AdminVehiclePartTypesApi.
 * @export
 * @interface AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDeleteRequest
 */
export interface AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDelete
     */
    readonly partTypeId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGet operation in AdminVehiclePartTypesApi.
 * @export
 * @interface AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGetRequest
 */
export interface AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGet
     */
    readonly partTypeId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPut operation in AdminVehiclePartTypesApi.
 * @export
 * @interface AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPutRequest
 */
export interface AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPut
     */
    readonly partTypeId: string

    /**
     * 
     * @type {AdminUpdateVehiclePartTypeDto}
     * @memberof AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPut
     */
    readonly adminUpdateVehiclePartTypeDto?: AdminUpdateVehiclePartTypeDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesPartTypesPost operation in AdminVehiclePartTypesApi.
 * @export
 * @interface AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPostRequest
 */
export interface AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPostRequest {
    /**
     * 
     * @type {AdminCreateVehiclePartTypeDto}
     * @memberof AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPost
     */
    readonly adminCreateVehiclePartTypeDto?: AdminCreateVehiclePartTypeDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesPartTypesSearchPost operation in AdminVehiclePartTypesApi.
 * @export
 * @interface AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesSearchPostRequest
 */
export interface AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesSearchPostRequest {
    /**
     * 
     * @type {AdminVehiclePartTypeSearchPaginatedDto}
     * @memberof AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesSearchPost
     */
    readonly adminVehiclePartTypeSearchPaginatedDto?: AdminVehiclePartTypeSearchPaginatedDto
}

/**
 * AdminVehiclePartTypesApi - object-oriented interface
 * @export
 * @class AdminVehiclePartTypesApi
 * @extends {BaseAPI}
 */
export class AdminVehiclePartTypesApi extends BaseAPI {
    /**
     * 
     * @param {AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehiclePartTypesApi
     */
    public apiV1AdminReferenceDataVehiclesPartTypesGetPost(requestParameters: AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehiclePartTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesPartTypesGetPost(requestParameters.adminVehiclePartTypeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehiclePartTypesApi
     */
    public apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDelete(requestParameters: AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminVehiclePartTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdDelete(requestParameters.partTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehiclePartTypesApi
     */
    public apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGet(requestParameters: AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminVehiclePartTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGet(requestParameters.partTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehiclePartTypesApi
     */
    public apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPut(requestParameters: AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminVehiclePartTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPut(requestParameters.partTypeId, requestParameters.adminUpdateVehiclePartTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehiclePartTypesApi
     */
    public apiV1AdminReferenceDataVehiclesPartTypesPost(requestParameters: AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehiclePartTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesPartTypesPost(requestParameters.adminCreateVehiclePartTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehiclePartTypesApi
     */
    public apiV1AdminReferenceDataVehiclesPartTypesSearchPost(requestParameters: AdminVehiclePartTypesApiApiV1AdminReferenceDataVehiclesPartTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehiclePartTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesPartTypesSearchPost(requestParameters.adminVehiclePartTypeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminVehicleTypesApi - axios parameter creator
 * @export
 */
export const AdminVehicleTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminVehicleTypeGetPaginatedDto} [adminVehicleTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesTypesGetPost: async (adminVehicleTypeGetPaginatedDto?: AdminVehicleTypeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/types/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleTypeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateVehicleTypeDto} [adminCreateVehicleTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesTypesPost: async (adminCreateVehicleTypeDto?: AdminCreateVehicleTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateVehicleTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminVehicleTypeSearchPaginatedDto} [adminVehicleTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesTypesSearchPost: async (adminVehicleTypeSearchPaginatedDto?: AdminVehicleTypeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/types/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleTypeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleTypeId 
         * @param {AdminUpdateVehicleTypeDto} [adminUpdateVehicleTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPut: async (vehicleTypeId: string, adminUpdateVehicleTypeDto?: AdminUpdateVehicleTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleTypeId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPut', 'vehicleTypeId', vehicleTypeId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/types/{vehicleTypeId}`
                .replace(`{${"vehicleTypeId"}}`, encodeURIComponent(String(vehicleTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateVehicleTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVehicleTypesApi - functional programming interface
 * @export
 */
export const AdminVehicleTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVehicleTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminVehicleTypeGetPaginatedDto} [adminVehicleTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesTypesGetPost(adminVehicleTypeGetPaginatedDto?: AdminVehicleTypeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesTypesGetPost(adminVehicleTypeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleTypesApi.apiV1AdminReferenceDataVehiclesTypesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateVehicleTypeDto} [adminCreateVehicleTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesTypesPost(adminCreateVehicleTypeDto?: AdminCreateVehicleTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesTypesPost(adminCreateVehicleTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleTypesApi.apiV1AdminReferenceDataVehiclesTypesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminVehicleTypeSearchPaginatedDto} [adminVehicleTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesTypesSearchPost(adminVehicleTypeSearchPaginatedDto?: AdminVehicleTypeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesTypesSearchPost(adminVehicleTypeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleTypesApi.apiV1AdminReferenceDataVehiclesTypesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} vehicleTypeId 
         * @param {AdminUpdateVehicleTypeDto} [adminUpdateVehicleTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPut(vehicleTypeId: string, adminUpdateVehicleTypeDto?: AdminUpdateVehicleTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPut(vehicleTypeId, adminUpdateVehicleTypeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleTypesApi.apiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminVehicleTypesApi - factory interface
 * @export
 */
export const AdminVehicleTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVehicleTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesTypesGetPost(requestParameters: AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesTypesGetPost(requestParameters.adminVehicleTypeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesTypesPost(requestParameters: AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesTypesPost(requestParameters.adminCreateVehicleTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesTypesSearchPost(requestParameters: AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesTypesSearchPost(requestParameters.adminVehicleTypeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPut(requestParameters: AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleTypeDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPut(requestParameters.vehicleTypeId, requestParameters.adminUpdateVehicleTypeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesTypesGetPost operation in AdminVehicleTypesApi.
 * @export
 * @interface AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesGetPostRequest
 */
export interface AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesGetPostRequest {
    /**
     * 
     * @type {AdminVehicleTypeGetPaginatedDto}
     * @memberof AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesGetPost
     */
    readonly adminVehicleTypeGetPaginatedDto?: AdminVehicleTypeGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesTypesPost operation in AdminVehicleTypesApi.
 * @export
 * @interface AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesPostRequest
 */
export interface AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesPostRequest {
    /**
     * 
     * @type {AdminCreateVehicleTypeDto}
     * @memberof AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesPost
     */
    readonly adminCreateVehicleTypeDto?: AdminCreateVehicleTypeDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesTypesSearchPost operation in AdminVehicleTypesApi.
 * @export
 * @interface AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesSearchPostRequest
 */
export interface AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesSearchPostRequest {
    /**
     * 
     * @type {AdminVehicleTypeSearchPaginatedDto}
     * @memberof AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesSearchPost
     */
    readonly adminVehicleTypeSearchPaginatedDto?: AdminVehicleTypeSearchPaginatedDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPut operation in AdminVehicleTypesApi.
 * @export
 * @interface AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPutRequest
 */
export interface AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPut
     */
    readonly vehicleTypeId: string

    /**
     * 
     * @type {AdminUpdateVehicleTypeDto}
     * @memberof AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPut
     */
    readonly adminUpdateVehicleTypeDto?: AdminUpdateVehicleTypeDto
}

/**
 * AdminVehicleTypesApi - object-oriented interface
 * @export
 * @class AdminVehicleTypesApi
 * @extends {BaseAPI}
 */
export class AdminVehicleTypesApi extends BaseAPI {
    /**
     * 
     * @param {AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleTypesApi
     */
    public apiV1AdminReferenceDataVehiclesTypesGetPost(requestParameters: AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesTypesGetPost(requestParameters.adminVehicleTypeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleTypesApi
     */
    public apiV1AdminReferenceDataVehiclesTypesPost(requestParameters: AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesTypesPost(requestParameters.adminCreateVehicleTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleTypesApi
     */
    public apiV1AdminReferenceDataVehiclesTypesSearchPost(requestParameters: AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesTypesSearchPost(requestParameters.adminVehicleTypeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleTypesApi
     */
    public apiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPut(requestParameters: AdminVehicleTypesApiApiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleTypesApiFp(this.configuration).apiV1AdminReferenceDataVehiclesTypesVehicleTypeIdPut(requestParameters.vehicleTypeId, requestParameters.adminUpdateVehicleTypeDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminVehicleVisualModelsApi - axios parameter creator
 * @export
 */
export const AdminVehicleVisualModelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminVehicleVisualModelGetPaginatedDto} [adminVehicleVisualModelGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsGetPost: async (adminVehicleVisualModelGetPaginatedDto?: AdminVehicleVisualModelGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/visual-models/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleVisualModelGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleVisualModelType} [type] 
         * @param {VehicleType} [vehicleType] 
         * @param {VehicleBodyType} [bodyType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGet: async (type?: VehicleVisualModelType, vehicleType?: VehicleType, bodyType?: VehicleBodyType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/visual-models/latest-or-default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (vehicleType !== undefined) {
                localVarQueryParameter['vehicleType'] = vehicleType;
            }

            if (bodyType !== undefined) {
                localVarQueryParameter['bodyType'] = bodyType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateVehicleVisualModelDto} [adminCreateVehicleVisualModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsPost: async (adminCreateVehicleVisualModelDto?: AdminCreateVehicleVisualModelDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/visual-models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateVehicleVisualModelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminVehicleVisualModelSearchPaginatedDto} [adminVehicleVisualModelSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsSearchPost: async (adminVehicleVisualModelSearchPaginatedDto?: AdminVehicleVisualModelSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/reference-data/vehicles/visual-models/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVehicleVisualModelSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} visualModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDelete: async (visualModelId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visualModelId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDelete', 'visualModelId', visualModelId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/visual-models/{visualModelId}`
                .replace(`{${"visualModelId"}}`, encodeURIComponent(String(visualModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} visualModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGet: async (visualModelId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visualModelId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGet', 'visualModelId', visualModelId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/visual-models/{visualModelId}`
                .replace(`{${"visualModelId"}}`, encodeURIComponent(String(visualModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} visualModelId 
         * @param {AdminUpdateVehicleVisualModelDto} [adminUpdateVehicleVisualModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPut: async (visualModelId: string, adminUpdateVehicleVisualModelDto?: AdminUpdateVehicleVisualModelDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visualModelId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPut', 'visualModelId', visualModelId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/visual-models/{visualModelId}`
                .replace(`{${"visualModelId"}}`, encodeURIComponent(String(visualModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateVehicleVisualModelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a version of entity after update (next version).
         * @param {string} visualModelId 
         * @param {AdminUpdateVehicleVisualModelDto} [adminUpdateVehicleVisualModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPost: async (visualModelId: string, adminUpdateVehicleVisualModelDto?: AdminUpdateVehicleVisualModelDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visualModelId' is not null or undefined
            assertParamExists('apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPost', 'visualModelId', visualModelId)
            const localVarPath = `/api/v1/admin/reference-data/vehicles/visual-models/{visualModelId}/update/next-version`
                .replace(`{${"visualModelId"}}`, encodeURIComponent(String(visualModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateVehicleVisualModelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVehicleVisualModelsApi - functional programming interface
 * @export
 */
export const AdminVehicleVisualModelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVehicleVisualModelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminVehicleVisualModelGetPaginatedDto} [adminVehicleVisualModelGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesVisualModelsGetPost(adminVehicleVisualModelGetPaginatedDto?: AdminVehicleVisualModelGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesVisualModelsGetPost(adminVehicleVisualModelGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleVisualModelsApi.apiV1AdminReferenceDataVehiclesVisualModelsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleVisualModelType} [type] 
         * @param {VehicleType} [vehicleType] 
         * @param {VehicleBodyType} [bodyType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGet(type?: VehicleVisualModelType, vehicleType?: VehicleType, bodyType?: VehicleBodyType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGet(type, vehicleType, bodyType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleVisualModelsApi.apiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminCreateVehicleVisualModelDto} [adminCreateVehicleVisualModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesVisualModelsPost(adminCreateVehicleVisualModelDto?: AdminCreateVehicleVisualModelDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesVisualModelsPost(adminCreateVehicleVisualModelDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleVisualModelsApi.apiV1AdminReferenceDataVehiclesVisualModelsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminVehicleVisualModelSearchPaginatedDto} [adminVehicleVisualModelSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesVisualModelsSearchPost(adminVehicleVisualModelSearchPaginatedDto?: AdminVehicleVisualModelSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAdminVehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesVisualModelsSearchPost(adminVehicleVisualModelSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleVisualModelsApi.apiV1AdminReferenceDataVehiclesVisualModelsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} visualModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDelete(visualModelId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDelete(visualModelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleVisualModelsApi.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} visualModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGet(visualModelId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGet(visualModelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleVisualModelsApi.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} visualModelId 
         * @param {AdminUpdateVehicleVisualModelDto} [adminUpdateVehicleVisualModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPut(visualModelId: string, adminUpdateVehicleVisualModelDto?: AdminUpdateVehicleVisualModelDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPut(visualModelId, adminUpdateVehicleVisualModelDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleVisualModelsApi.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a version of entity after update (next version).
         * @param {string} visualModelId 
         * @param {AdminUpdateVehicleVisualModelDto} [adminUpdateVehicleVisualModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPost(visualModelId: string, adminUpdateVehicleVisualModelDto?: AdminUpdateVehicleVisualModelDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPost(visualModelId, adminUpdateVehicleVisualModelDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminVehicleVisualModelsApi.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminVehicleVisualModelsApi - factory interface
 * @export
 */
export const AdminVehicleVisualModelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVehicleVisualModelsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsGetPost(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleVisualModelDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesVisualModelsGetPost(requestParameters.adminVehicleVisualModelGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGet(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleVisualModelDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGet(requestParameters.type, requestParameters.vehicleType, requestParameters.bodyType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsPost(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleVisualModelDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesVisualModelsPost(requestParameters.adminCreateVehicleVisualModelDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsSearchPost(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAdminVehicleVisualModelDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesVisualModelsSearchPost(requestParameters.adminVehicleVisualModelSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDelete(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDelete(requestParameters.visualModelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGet(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleVisualModelDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGet(requestParameters.visualModelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPut(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminVehicleVisualModelDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPut(requestParameters.visualModelId, requestParameters.adminUpdateVehicleVisualModelDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a version of entity after update (next version).
         * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPost(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<VersionDto> {
            return localVarFp.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPost(requestParameters.visualModelId, requestParameters.adminUpdateVehicleVisualModelDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesVisualModelsGetPost operation in AdminVehicleVisualModelsApi.
 * @export
 * @interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsGetPostRequest
 */
export interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsGetPostRequest {
    /**
     * 
     * @type {AdminVehicleVisualModelGetPaginatedDto}
     * @memberof AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsGetPost
     */
    readonly adminVehicleVisualModelGetPaginatedDto?: AdminVehicleVisualModelGetPaginatedDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGet operation in AdminVehicleVisualModelsApi.
 * @export
 * @interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest
 */
export interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest {
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGet
     */
    readonly type?: VehicleVisualModelType

    /**
     * 
     * @type {VehicleType}
     * @memberof AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGet
     */
    readonly vehicleType?: VehicleType

    /**
     * 
     * @type {VehicleBodyType}
     * @memberof AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGet
     */
    readonly bodyType?: VehicleBodyType
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesVisualModelsPost operation in AdminVehicleVisualModelsApi.
 * @export
 * @interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsPostRequest
 */
export interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsPostRequest {
    /**
     * 
     * @type {AdminCreateVehicleVisualModelDto}
     * @memberof AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsPost
     */
    readonly adminCreateVehicleVisualModelDto?: AdminCreateVehicleVisualModelDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesVisualModelsSearchPost operation in AdminVehicleVisualModelsApi.
 * @export
 * @interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsSearchPostRequest
 */
export interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsSearchPostRequest {
    /**
     * 
     * @type {AdminVehicleVisualModelSearchPaginatedDto}
     * @memberof AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsSearchPost
     */
    readonly adminVehicleVisualModelSearchPaginatedDto?: AdminVehicleVisualModelSearchPaginatedDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDelete operation in AdminVehicleVisualModelsApi.
 * @export
 * @interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDeleteRequest
 */
export interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDelete
     */
    readonly visualModelId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGet operation in AdminVehicleVisualModelsApi.
 * @export
 * @interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGetRequest
 */
export interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGet
     */
    readonly visualModelId: string
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPut operation in AdminVehicleVisualModelsApi.
 * @export
 * @interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPutRequest
 */
export interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPut
     */
    readonly visualModelId: string

    /**
     * 
     * @type {AdminUpdateVehicleVisualModelDto}
     * @memberof AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPut
     */
    readonly adminUpdateVehicleVisualModelDto?: AdminUpdateVehicleVisualModelDto
}

/**
 * Request parameters for apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPost operation in AdminVehicleVisualModelsApi.
 * @export
 * @interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPostRequest
 */
export interface AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPost
     */
    readonly visualModelId: string

    /**
     * 
     * @type {AdminUpdateVehicleVisualModelDto}
     * @memberof AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPost
     */
    readonly adminUpdateVehicleVisualModelDto?: AdminUpdateVehicleVisualModelDto
}

/**
 * AdminVehicleVisualModelsApi - object-oriented interface
 * @export
 * @class AdminVehicleVisualModelsApi
 * @extends {BaseAPI}
 */
export class AdminVehicleVisualModelsApi extends BaseAPI {
    /**
     * 
     * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleVisualModelsApi
     */
    public apiV1AdminReferenceDataVehiclesVisualModelsGetPost(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleVisualModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesVisualModelsGetPost(requestParameters.adminVehicleVisualModelGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleVisualModelsApi
     */
    public apiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGet(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleVisualModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesVisualModelsLatestOrDefaultGet(requestParameters.type, requestParameters.vehicleType, requestParameters.bodyType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleVisualModelsApi
     */
    public apiV1AdminReferenceDataVehiclesVisualModelsPost(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleVisualModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesVisualModelsPost(requestParameters.adminCreateVehicleVisualModelDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleVisualModelsApi
     */
    public apiV1AdminReferenceDataVehiclesVisualModelsSearchPost(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminVehicleVisualModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesVisualModelsSearchPost(requestParameters.adminVehicleVisualModelSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleVisualModelsApi
     */
    public apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDelete(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleVisualModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDelete(requestParameters.visualModelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleVisualModelsApi
     */
    public apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGet(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGetRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleVisualModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGet(requestParameters.visualModelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleVisualModelsApi
     */
    public apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPut(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPutRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleVisualModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPut(requestParameters.visualModelId, requestParameters.adminUpdateVehicleVisualModelDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a version of entity after update (next version).
     * @param {AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVehicleVisualModelsApi
     */
    public apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPost(requestParameters: AdminVehicleVisualModelsApiApiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPostRequest, options?: RawAxiosRequestConfig) {
        return AdminVehicleVisualModelsApiFp(this.configuration).apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPost(requestParameters.visualModelId, requestParameters.adminUpdateVehicleVisualModelDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssessmentFlowsApi - axios parameter creator
 * @export
 */
export const AssessmentFlowsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assessmentFlowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FlowsAssessmentFlowsAssessmentFlowIdGet: async (nexusOpsTenant: string, assessmentFlowId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1FlowsAssessmentFlowsAssessmentFlowIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assessmentFlowId' is not null or undefined
            assertParamExists('apiV1FlowsAssessmentFlowsAssessmentFlowIdGet', 'assessmentFlowId', assessmentFlowId)
            const localVarPath = `/api/v1/flows/assessment-flows/{assessmentFlowId}`
                .replace(`{${"assessmentFlowId"}}`, encodeURIComponent(String(assessmentFlowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assessmentFlowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost: async (nexusOpsTenant: string, assessmentFlowId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assessmentFlowId' is not null or undefined
            assertParamExists('apiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost', 'assessmentFlowId', assessmentFlowId)
            const localVarPath = `/api/v1/flows/assessment-flows/{assessmentFlowId}/send-to-customer`
                .replace(`{${"assessmentFlowId"}}`, encodeURIComponent(String(assessmentFlowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assessmentFlowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost: async (nexusOpsTenant: string, assessmentFlowId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assessmentFlowId' is not null or undefined
            assertParamExists('apiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost', 'assessmentFlowId', assessmentFlowId)
            const localVarPath = `/api/v1/flows/assessment-flows/{assessmentFlowId}/state/recompute`
                .replace(`{${"assessmentFlowId"}}`, encodeURIComponent(String(assessmentFlowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FlowsAssessmentFlowsByContractContractIdGet: async (nexusOpsTenant: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1FlowsAssessmentFlowsByContractContractIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1FlowsAssessmentFlowsByContractContractIdGet', 'contractId', contractId)
            const localVarPath = `/api/v1/flows/assessment-flows/by-contract/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssessmentFlowGetCountersDto} [assessmentFlowGetCountersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FlowsAssessmentFlowsCountersGetPost: async (nexusOpsTenant: string, assessmentFlowGetCountersDto?: AssessmentFlowGetCountersDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1FlowsAssessmentFlowsCountersGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/flows/assessment-flows/counters/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assessmentFlowGetCountersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssessmentFlowGetPaginatedDto} [assessmentFlowGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FlowsAssessmentFlowsGetPost: async (nexusOpsTenant: string, assessmentFlowGetPaginatedDto?: AssessmentFlowGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1FlowsAssessmentFlowsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/flows/assessment-flows/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assessmentFlowGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssessmentFlowsApi - functional programming interface
 * @export
 */
export const AssessmentFlowsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssessmentFlowsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assessmentFlowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FlowsAssessmentFlowsAssessmentFlowIdGet(nexusOpsTenant: string, assessmentFlowId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentFlowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FlowsAssessmentFlowsAssessmentFlowIdGet(nexusOpsTenant, assessmentFlowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentFlowsApi.apiV1FlowsAssessmentFlowsAssessmentFlowIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assessmentFlowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost(nexusOpsTenant: string, assessmentFlowId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost(nexusOpsTenant, assessmentFlowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentFlowsApi.apiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assessmentFlowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost(nexusOpsTenant: string, assessmentFlowId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentFlowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost(nexusOpsTenant, assessmentFlowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentFlowsApi.apiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FlowsAssessmentFlowsByContractContractIdGet(nexusOpsTenant: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentFlowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FlowsAssessmentFlowsByContractContractIdGet(nexusOpsTenant, contractId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentFlowsApi.apiV1FlowsAssessmentFlowsByContractContractIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssessmentFlowGetCountersDto} [assessmentFlowGetCountersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FlowsAssessmentFlowsCountersGetPost(nexusOpsTenant: string, assessmentFlowGetCountersDto?: AssessmentFlowGetCountersDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentFlowCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FlowsAssessmentFlowsCountersGetPost(nexusOpsTenant, assessmentFlowGetCountersDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentFlowsApi.apiV1FlowsAssessmentFlowsCountersGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssessmentFlowGetPaginatedDto} [assessmentFlowGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FlowsAssessmentFlowsGetPost(nexusOpsTenant: string, assessmentFlowGetPaginatedDto?: AssessmentFlowGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAssessmentFlowBriefDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FlowsAssessmentFlowsGetPost(nexusOpsTenant, assessmentFlowGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentFlowsApi.apiV1FlowsAssessmentFlowsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssessmentFlowsApi - factory interface
 * @export
 */
export const AssessmentFlowsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssessmentFlowsApiFp(configuration)
    return {
        /**
         * 
         * @param {AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FlowsAssessmentFlowsAssessmentFlowIdGet(requestParameters: AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssessmentFlowDto> {
            return localVarFp.apiV1FlowsAssessmentFlowsAssessmentFlowIdGet(requestParameters.nexusOpsTenant, requestParameters.assessmentFlowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost(requestParameters: AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost(requestParameters.nexusOpsTenant, requestParameters.assessmentFlowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost(requestParameters: AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssessmentFlowDto> {
            return localVarFp.apiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost(requestParameters.nexusOpsTenant, requestParameters.assessmentFlowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssessmentFlowsApiApiV1FlowsAssessmentFlowsByContractContractIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FlowsAssessmentFlowsByContractContractIdGet(requestParameters: AssessmentFlowsApiApiV1FlowsAssessmentFlowsByContractContractIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssessmentFlowDto> {
            return localVarFp.apiV1FlowsAssessmentFlowsByContractContractIdGet(requestParameters.nexusOpsTenant, requestParameters.contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssessmentFlowsApiApiV1FlowsAssessmentFlowsCountersGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FlowsAssessmentFlowsCountersGetPost(requestParameters: AssessmentFlowsApiApiV1FlowsAssessmentFlowsCountersGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssessmentFlowCountersDto> {
            return localVarFp.apiV1FlowsAssessmentFlowsCountersGetPost(requestParameters.nexusOpsTenant, requestParameters.assessmentFlowGetCountersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssessmentFlowsApiApiV1FlowsAssessmentFlowsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FlowsAssessmentFlowsGetPost(requestParameters: AssessmentFlowsApiApiV1FlowsAssessmentFlowsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAssessmentFlowBriefDto> {
            return localVarFp.apiV1FlowsAssessmentFlowsGetPost(requestParameters.nexusOpsTenant, requestParameters.assessmentFlowGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1FlowsAssessmentFlowsAssessmentFlowIdGet operation in AssessmentFlowsApi.
 * @export
 * @interface AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdGetRequest
 */
export interface AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdGet
     */
    readonly assessmentFlowId: string
}

/**
 * Request parameters for apiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost operation in AssessmentFlowsApi.
 * @export
 * @interface AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPostRequest
 */
export interface AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost
     */
    readonly assessmentFlowId: string
}

/**
 * Request parameters for apiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost operation in AssessmentFlowsApi.
 * @export
 * @interface AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePostRequest
 */
export interface AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost
     */
    readonly assessmentFlowId: string
}

/**
 * Request parameters for apiV1FlowsAssessmentFlowsByContractContractIdGet operation in AssessmentFlowsApi.
 * @export
 * @interface AssessmentFlowsApiApiV1FlowsAssessmentFlowsByContractContractIdGetRequest
 */
export interface AssessmentFlowsApiApiV1FlowsAssessmentFlowsByContractContractIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssessmentFlowsApiApiV1FlowsAssessmentFlowsByContractContractIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowsApiApiV1FlowsAssessmentFlowsByContractContractIdGet
     */
    readonly contractId: string
}

/**
 * Request parameters for apiV1FlowsAssessmentFlowsCountersGetPost operation in AssessmentFlowsApi.
 * @export
 * @interface AssessmentFlowsApiApiV1FlowsAssessmentFlowsCountersGetPostRequest
 */
export interface AssessmentFlowsApiApiV1FlowsAssessmentFlowsCountersGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssessmentFlowsApiApiV1FlowsAssessmentFlowsCountersGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssessmentFlowGetCountersDto}
     * @memberof AssessmentFlowsApiApiV1FlowsAssessmentFlowsCountersGetPost
     */
    readonly assessmentFlowGetCountersDto?: AssessmentFlowGetCountersDto
}

/**
 * Request parameters for apiV1FlowsAssessmentFlowsGetPost operation in AssessmentFlowsApi.
 * @export
 * @interface AssessmentFlowsApiApiV1FlowsAssessmentFlowsGetPostRequest
 */
export interface AssessmentFlowsApiApiV1FlowsAssessmentFlowsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssessmentFlowsApiApiV1FlowsAssessmentFlowsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssessmentFlowGetPaginatedDto}
     * @memberof AssessmentFlowsApiApiV1FlowsAssessmentFlowsGetPost
     */
    readonly assessmentFlowGetPaginatedDto?: AssessmentFlowGetPaginatedDto
}

/**
 * AssessmentFlowsApi - object-oriented interface
 * @export
 * @class AssessmentFlowsApi
 * @extends {BaseAPI}
 */
export class AssessmentFlowsApi extends BaseAPI {
    /**
     * 
     * @param {AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentFlowsApi
     */
    public apiV1FlowsAssessmentFlowsAssessmentFlowIdGet(requestParameters: AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdGetRequest, options?: RawAxiosRequestConfig) {
        return AssessmentFlowsApiFp(this.configuration).apiV1FlowsAssessmentFlowsAssessmentFlowIdGet(requestParameters.nexusOpsTenant, requestParameters.assessmentFlowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentFlowsApi
     */
    public apiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost(requestParameters: AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPostRequest, options?: RawAxiosRequestConfig) {
        return AssessmentFlowsApiFp(this.configuration).apiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost(requestParameters.nexusOpsTenant, requestParameters.assessmentFlowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentFlowsApi
     */
    public apiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost(requestParameters: AssessmentFlowsApiApiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePostRequest, options?: RawAxiosRequestConfig) {
        return AssessmentFlowsApiFp(this.configuration).apiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost(requestParameters.nexusOpsTenant, requestParameters.assessmentFlowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssessmentFlowsApiApiV1FlowsAssessmentFlowsByContractContractIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentFlowsApi
     */
    public apiV1FlowsAssessmentFlowsByContractContractIdGet(requestParameters: AssessmentFlowsApiApiV1FlowsAssessmentFlowsByContractContractIdGetRequest, options?: RawAxiosRequestConfig) {
        return AssessmentFlowsApiFp(this.configuration).apiV1FlowsAssessmentFlowsByContractContractIdGet(requestParameters.nexusOpsTenant, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssessmentFlowsApiApiV1FlowsAssessmentFlowsCountersGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentFlowsApi
     */
    public apiV1FlowsAssessmentFlowsCountersGetPost(requestParameters: AssessmentFlowsApiApiV1FlowsAssessmentFlowsCountersGetPostRequest, options?: RawAxiosRequestConfig) {
        return AssessmentFlowsApiFp(this.configuration).apiV1FlowsAssessmentFlowsCountersGetPost(requestParameters.nexusOpsTenant, requestParameters.assessmentFlowGetCountersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssessmentFlowsApiApiV1FlowsAssessmentFlowsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentFlowsApi
     */
    public apiV1FlowsAssessmentFlowsGetPost(requestParameters: AssessmentFlowsApiApiV1FlowsAssessmentFlowsGetPostRequest, options?: RawAxiosRequestConfig) {
        return AssessmentFlowsApiFp(this.configuration).apiV1FlowsAssessmentFlowsGetPost(requestParameters.nexusOpsTenant, requestParameters.assessmentFlowGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssetSubscriptionPlansApi - axios parameter creator
 * @export
 */
export const AssetSubscriptionPlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {string} [productLocationId] 
         * @param {string} [dateRangeFrom] 
         * @param {string} [dateRangeTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet: async (nexusOpsTenant: string, assetSubscriptionPlanId: string, productLocationId?: string, dateRangeFrom?: string, dateRangeTo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionPlanId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet', 'assetSubscriptionPlanId', assetSubscriptionPlanId)
            const localVarPath = `/api/v1/asset-subscription-plans/{assetSubscriptionPlanId}/availability`
                .replace(`{${"assetSubscriptionPlanId"}}`, encodeURIComponent(String(assetSubscriptionPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productLocationId !== undefined) {
                localVarQueryParameter['productLocationId'] = productLocationId;
            }

            if (dateRangeFrom !== undefined) {
                localVarQueryParameter['dateRange.from'] = dateRangeFrom;
            }

            if (dateRangeTo !== undefined) {
                localVarQueryParameter['dateRange.to'] = dateRangeTo;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete: async (nexusOpsTenant: string, assetSubscriptionPlanId: string, isDeleteCascade?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionPlanId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete', 'assetSubscriptionPlanId', assetSubscriptionPlanId)
            const localVarPath = `/api/v1/asset-subscription-plans/{assetSubscriptionPlanId}`
                .replace(`{${"assetSubscriptionPlanId"}}`, encodeURIComponent(String(assetSubscriptionPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost: async (nexusOpsTenant: string, assetSubscriptionPlanId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionPlanId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost', 'assetSubscriptionPlanId', assetSubscriptionPlanId)
            const localVarPath = `/api/v1/asset-subscription-plans/{assetSubscriptionPlanId}/enabled/toggle`
                .replace(`{${"assetSubscriptionPlanId"}}`, encodeURIComponent(String(assetSubscriptionPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet: async (nexusOpsTenant: string, assetSubscriptionPlanId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionPlanId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet', 'assetSubscriptionPlanId', assetSubscriptionPlanId)
            const localVarPath = `/api/v1/asset-subscription-plans/{assetSubscriptionPlanId}`
                .replace(`{${"assetSubscriptionPlanId"}}`, encodeURIComponent(String(assetSubscriptionPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {AddAssetSubscriptionPlanProductLocationsDto} [addAssetSubscriptionPlanProductLocationsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost: async (nexusOpsTenant: string, assetSubscriptionPlanId: string, addAssetSubscriptionPlanProductLocationsDto?: AddAssetSubscriptionPlanProductLocationsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionPlanId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost', 'assetSubscriptionPlanId', assetSubscriptionPlanId)
            const localVarPath = `/api/v1/asset-subscription-plans/{assetSubscriptionPlanId}/product-locations`
                .replace(`{${"assetSubscriptionPlanId"}}`, encodeURIComponent(String(assetSubscriptionPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAssetSubscriptionPlanProductLocationsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {string} productLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete: async (nexusOpsTenant: string, assetSubscriptionPlanId: string, productLocationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionPlanId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete', 'assetSubscriptionPlanId', assetSubscriptionPlanId)
            // verify required parameter 'productLocationId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete', 'productLocationId', productLocationId)
            const localVarPath = `/api/v1/asset-subscription-plans/{assetSubscriptionPlanId}/product-locations/{productLocationId}`
                .replace(`{${"assetSubscriptionPlanId"}}`, encodeURIComponent(String(assetSubscriptionPlanId)))
                .replace(`{${"productLocationId"}}`, encodeURIComponent(String(productLocationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {AssetSubscriptionPlanUpdateDto} [assetSubscriptionPlanUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut: async (nexusOpsTenant: string, assetSubscriptionPlanId: string, assetSubscriptionPlanUpdateDto?: AssetSubscriptionPlanUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionPlanId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut', 'assetSubscriptionPlanId', assetSubscriptionPlanId)
            const localVarPath = `/api/v1/asset-subscription-plans/{assetSubscriptionPlanId}`
                .replace(`{${"assetSubscriptionPlanId"}}`, encodeURIComponent(String(assetSubscriptionPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSubscriptionPlanUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/asset-subscription-plans/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionPlanGetPaginatedDto} [assetSubscriptionPlanGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansGetPost: async (nexusOpsTenant: string, assetSubscriptionPlanGetPaginatedDto?: AssetSubscriptionPlanGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/asset-subscription-plans/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSubscriptionPlanGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionPlanCreateDto} [assetSubscriptionPlanCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansPost: async (nexusOpsTenant: string, assetSubscriptionPlanCreateDto?: AssetSubscriptionPlanCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/asset-subscription-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSubscriptionPlanCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionPlanSearchPaginatedDto} [assetSubscriptionPlanSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansSearchPost: async (nexusOpsTenant: string, assetSubscriptionPlanSearchPaginatedDto?: AssetSubscriptionPlanSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionPlansSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/asset-subscription-plans/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSubscriptionPlanSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetSubscriptionPlansApi - functional programming interface
 * @export
 */
export const AssetSubscriptionPlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetSubscriptionPlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {string} [productLocationId] 
         * @param {string} [dateRangeFrom] 
         * @param {string} [dateRangeTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet(nexusOpsTenant: string, assetSubscriptionPlanId: string, productLocationId?: string, dateRangeFrom?: string, dateRangeTo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionPlanAvailabilityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet(nexusOpsTenant, assetSubscriptionPlanId, productLocationId, dateRangeFrom, dateRangeTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete(nexusOpsTenant: string, assetSubscriptionPlanId: string, isDeleteCascade?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete(nexusOpsTenant, assetSubscriptionPlanId, isDeleteCascade, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost(nexusOpsTenant: string, assetSubscriptionPlanId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost(nexusOpsTenant, assetSubscriptionPlanId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet(nexusOpsTenant: string, assetSubscriptionPlanId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet(nexusOpsTenant, assetSubscriptionPlanId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {AddAssetSubscriptionPlanProductLocationsDto} [addAssetSubscriptionPlanProductLocationsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost(nexusOpsTenant: string, assetSubscriptionPlanId: string, addAssetSubscriptionPlanProductLocationsDto?: AddAssetSubscriptionPlanProductLocationsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost(nexusOpsTenant, assetSubscriptionPlanId, addAssetSubscriptionPlanProductLocationsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {string} productLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete(nexusOpsTenant: string, assetSubscriptionPlanId: string, productLocationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete(nexusOpsTenant, assetSubscriptionPlanId, productLocationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionPlanId 
         * @param {AssetSubscriptionPlanUpdateDto} [assetSubscriptionPlanUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut(nexusOpsTenant: string, assetSubscriptionPlanId: string, assetSubscriptionPlanUpdateDto?: AssetSubscriptionPlanUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut(nexusOpsTenant, assetSubscriptionPlanId, assetSubscriptionPlanUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionPlansBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionPlansBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionPlansApi.apiV1AssetSubscriptionPlansBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionPlanGetPaginatedDto} [assetSubscriptionPlanGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionPlansGetPost(nexusOpsTenant: string, assetSubscriptionPlanGetPaginatedDto?: AssetSubscriptionPlanGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAssetSubscriptionPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionPlansGetPost(nexusOpsTenant, assetSubscriptionPlanGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionPlansApi.apiV1AssetSubscriptionPlansGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionPlanCreateDto} [assetSubscriptionPlanCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionPlansPost(nexusOpsTenant: string, assetSubscriptionPlanCreateDto?: AssetSubscriptionPlanCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionPlansPost(nexusOpsTenant, assetSubscriptionPlanCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionPlansApi.apiV1AssetSubscriptionPlansPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionPlanSearchPaginatedDto} [assetSubscriptionPlanSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionPlansSearchPost(nexusOpsTenant: string, assetSubscriptionPlanSearchPaginatedDto?: AssetSubscriptionPlanSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAssetSubscriptionPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionPlansSearchPost(nexusOpsTenant, assetSubscriptionPlanSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionPlansApi.apiV1AssetSubscriptionPlansSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetSubscriptionPlansApi - factory interface
 * @export
 */
export const AssetSubscriptionPlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetSubscriptionPlansApiFp(configuration)
    return {
        /**
         * 
         * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionPlanAvailabilityDto> {
            return localVarFp.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, requestParameters.productLocationId, requestParameters.dateRangeFrom, requestParameters.dateRangeTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, requestParameters.isDeleteCascade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionPlanDto> {
            return localVarFp.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionPlanDto> {
            return localVarFp.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionPlanDto> {
            return localVarFp.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, requestParameters.addAssetSubscriptionPlanProductLocationsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionPlanDto> {
            return localVarFp.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, requestParameters.productLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionPlanDto> {
            return localVarFp.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, requestParameters.assetSubscriptionPlanUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansBulkDeleteDelete(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AssetSubscriptionPlansBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansGetPost(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAssetSubscriptionPlanDto> {
            return localVarFp.apiV1AssetSubscriptionPlansGetPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansPost(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionPlanDto> {
            return localVarFp.apiV1AssetSubscriptionPlansPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionPlansSearchPost(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAssetSubscriptionPlanDto> {
            return localVarFp.apiV1AssetSubscriptionPlansSearchPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet operation in AssetSubscriptionPlansApi.
 * @export
 * @interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGetRequest
 */
export interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet
     */
    readonly assetSubscriptionPlanId: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet
     */
    readonly productLocationId?: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet
     */
    readonly dateRangeFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet
     */
    readonly dateRangeTo?: string
}

/**
 * Request parameters for apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete operation in AssetSubscriptionPlansApi.
 * @export
 * @interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDeleteRequest
 */
export interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete
     */
    readonly assetSubscriptionPlanId: string

    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete
     */
    readonly isDeleteCascade?: boolean
}

/**
 * Request parameters for apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost operation in AssetSubscriptionPlansApi.
 * @export
 * @interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePostRequest
 */
export interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost
     */
    readonly assetSubscriptionPlanId: string
}

/**
 * Request parameters for apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet operation in AssetSubscriptionPlansApi.
 * @export
 * @interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGetRequest
 */
export interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet
     */
    readonly assetSubscriptionPlanId: string
}

/**
 * Request parameters for apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost operation in AssetSubscriptionPlansApi.
 * @export
 * @interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPostRequest
 */
export interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost
     */
    readonly assetSubscriptionPlanId: string

    /**
     * 
     * @type {AddAssetSubscriptionPlanProductLocationsDto}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost
     */
    readonly addAssetSubscriptionPlanProductLocationsDto?: AddAssetSubscriptionPlanProductLocationsDto
}

/**
 * Request parameters for apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete operation in AssetSubscriptionPlansApi.
 * @export
 * @interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDeleteRequest
 */
export interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete
     */
    readonly assetSubscriptionPlanId: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete
     */
    readonly productLocationId: string
}

/**
 * Request parameters for apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut operation in AssetSubscriptionPlansApi.
 * @export
 * @interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPutRequest
 */
export interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut
     */
    readonly assetSubscriptionPlanId: string

    /**
     * 
     * @type {AssetSubscriptionPlanUpdateDto}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut
     */
    readonly assetSubscriptionPlanUpdateDto?: AssetSubscriptionPlanUpdateDto
}

/**
 * Request parameters for apiV1AssetSubscriptionPlansBulkDeleteDelete operation in AssetSubscriptionPlansApi.
 * @export
 * @interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansBulkDeleteDeleteRequest
 */
export interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1AssetSubscriptionPlansGetPost operation in AssetSubscriptionPlansApi.
 * @export
 * @interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansGetPostRequest
 */
export interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssetSubscriptionPlanGetPaginatedDto}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansGetPost
     */
    readonly assetSubscriptionPlanGetPaginatedDto?: AssetSubscriptionPlanGetPaginatedDto
}

/**
 * Request parameters for apiV1AssetSubscriptionPlansPost operation in AssetSubscriptionPlansApi.
 * @export
 * @interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansPostRequest
 */
export interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssetSubscriptionPlanCreateDto}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansPost
     */
    readonly assetSubscriptionPlanCreateDto?: AssetSubscriptionPlanCreateDto
}

/**
 * Request parameters for apiV1AssetSubscriptionPlansSearchPost operation in AssetSubscriptionPlansApi.
 * @export
 * @interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansSearchPostRequest
 */
export interface AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssetSubscriptionPlanSearchPaginatedDto}
     * @memberof AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansSearchPost
     */
    readonly assetSubscriptionPlanSearchPaginatedDto?: AssetSubscriptionPlanSearchPaginatedDto
}

/**
 * AssetSubscriptionPlansApi - object-oriented interface
 * @export
 * @class AssetSubscriptionPlansApi
 * @extends {BaseAPI}
 */
export class AssetSubscriptionPlansApi extends BaseAPI {
    /**
     * 
     * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionPlansApi
     */
    public apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGetRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionPlansApiFp(this.configuration).apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, requestParameters.productLocationId, requestParameters.dateRangeFrom, requestParameters.dateRangeTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionPlansApi
     */
    public apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionPlansApiFp(this.configuration).apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, requestParameters.isDeleteCascade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionPlansApi
     */
    public apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePostRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionPlansApiFp(this.configuration).apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdEnabledTogglePost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionPlansApi
     */
    public apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGetRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionPlansApiFp(this.configuration).apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionPlansApi
     */
    public apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPostRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionPlansApiFp(this.configuration).apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, requestParameters.addAssetSubscriptionPlanProductLocationsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionPlansApi
     */
    public apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionPlansApiFp(this.configuration).apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdProductLocationsProductLocationIdDelete(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, requestParameters.productLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionPlansApi
     */
    public apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPutRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionPlansApiFp(this.configuration).apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanId, requestParameters.assetSubscriptionPlanUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionPlansApi
     */
    public apiV1AssetSubscriptionPlansBulkDeleteDelete(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionPlansApiFp(this.configuration).apiV1AssetSubscriptionPlansBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionPlansApi
     */
    public apiV1AssetSubscriptionPlansGetPost(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansGetPostRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionPlansApiFp(this.configuration).apiV1AssetSubscriptionPlansGetPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionPlansApi
     */
    public apiV1AssetSubscriptionPlansPost(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansPostRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionPlansApiFp(this.configuration).apiV1AssetSubscriptionPlansPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionPlansApi
     */
    public apiV1AssetSubscriptionPlansSearchPost(requestParameters: AssetSubscriptionPlansApiApiV1AssetSubscriptionPlansSearchPostRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionPlansApiFp(this.configuration).apiV1AssetSubscriptionPlansSearchPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionPlanSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssetSubscriptionsApi - axios parameter creator
 * @export
 */
export const AssetSubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPost: async (nexusOpsTenant: string, assetSubscriptionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPost', 'assetSubscriptionId', assetSubscriptionId)
            const localVarPath = `/api/v1/asset-subscriptions/{assetSubscriptionId}/asset/allocation/confirm`
                .replace(`{${"assetSubscriptionId"}}`, encodeURIComponent(String(assetSubscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {AssetSubscriptionReallocateAssetDto} [assetSubscriptionReallocateAssetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost: async (nexusOpsTenant: string, assetSubscriptionId: string, assetSubscriptionReallocateAssetDto?: AssetSubscriptionReallocateAssetDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost', 'assetSubscriptionId', assetSubscriptionId)
            const localVarPath = `/api/v1/asset-subscriptions/{assetSubscriptionId}/asset/allocation/reallocate`
                .replace(`{${"assetSubscriptionId"}}`, encodeURIComponent(String(assetSubscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSubscriptionReallocateAssetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost: async (nexusOpsTenant: string, assetSubscriptionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost', 'assetSubscriptionId', assetSubscriptionId)
            const localVarPath = `/api/v1/asset-subscriptions/{assetSubscriptionId}/asset/release`
                .replace(`{${"assetSubscriptionId"}}`, encodeURIComponent(String(assetSubscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdDelete: async (nexusOpsTenant: string, assetSubscriptionId: string, isDeleteCascade?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdDelete', 'assetSubscriptionId', assetSubscriptionId)
            const localVarPath = `/api/v1/asset-subscriptions/{assetSubscriptionId}`
                .replace(`{${"assetSubscriptionId"}}`, encodeURIComponent(String(assetSubscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdGet: async (nexusOpsTenant: string, assetSubscriptionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdGet', 'assetSubscriptionId', assetSubscriptionId)
            const localVarPath = `/api/v1/asset-subscriptions/{assetSubscriptionId}`
                .replace(`{${"assetSubscriptionId"}}`, encodeURIComponent(String(assetSubscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {AssetSubscriptionUpdateDto} [assetSubscriptionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdPut: async (nexusOpsTenant: string, assetSubscriptionId: string, assetSubscriptionUpdateDto?: AssetSubscriptionUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdPut', 'assetSubscriptionId', assetSubscriptionId)
            const localVarPath = `/api/v1/asset-subscriptions/{assetSubscriptionId}`
                .replace(`{${"assetSubscriptionId"}}`, encodeURIComponent(String(assetSubscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSubscriptionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {AssetSubscriptionUpdateStatusDto} [assetSubscriptionUpdateStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdStatusPut: async (nexusOpsTenant: string, assetSubscriptionId: string, assetSubscriptionUpdateStatusDto?: AssetSubscriptionUpdateStatusDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdStatusPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetSubscriptionId' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsAssetSubscriptionIdStatusPut', 'assetSubscriptionId', assetSubscriptionId)
            const localVarPath = `/api/v1/asset-subscriptions/{assetSubscriptionId}/status`
                .replace(`{${"assetSubscriptionId"}}`, encodeURIComponent(String(assetSubscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSubscriptionUpdateStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/asset-subscriptions/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionGetCountersDto} [assetSubscriptionGetCountersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsCountersGetPost: async (nexusOpsTenant: string, assetSubscriptionGetCountersDto?: AssetSubscriptionGetCountersDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsCountersGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/asset-subscriptions/counters/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSubscriptionGetCountersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionGetPaginatedDto} [assetSubscriptionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsGetPost: async (nexusOpsTenant: string, assetSubscriptionGetPaginatedDto?: AssetSubscriptionGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/asset-subscriptions/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSubscriptionGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionCreateDto} [assetSubscriptionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsPost: async (nexusOpsTenant: string, assetSubscriptionCreateDto?: AssetSubscriptionCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/asset-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSubscriptionCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionSearchPaginatedDto} [assetSubscriptionSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsSearchPost: async (nexusOpsTenant: string, assetSubscriptionSearchPaginatedDto?: AssetSubscriptionSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetSubscriptionsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/asset-subscriptions/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSubscriptionSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetSubscriptionsApi - functional programming interface
 * @export
 */
export const AssetSubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetSubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPost(nexusOpsTenant: string, assetSubscriptionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPost(nexusOpsTenant, assetSubscriptionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {AssetSubscriptionReallocateAssetDto} [assetSubscriptionReallocateAssetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost(nexusOpsTenant: string, assetSubscriptionId: string, assetSubscriptionReallocateAssetDto?: AssetSubscriptionReallocateAssetDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost(nexusOpsTenant, assetSubscriptionId, assetSubscriptionReallocateAssetDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost(nexusOpsTenant: string, assetSubscriptionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost(nexusOpsTenant, assetSubscriptionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionsAssetSubscriptionIdDelete(nexusOpsTenant: string, assetSubscriptionId: string, isDeleteCascade?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionsAssetSubscriptionIdDelete(nexusOpsTenant, assetSubscriptionId, isDeleteCascade, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionsAssetSubscriptionIdGet(nexusOpsTenant: string, assetSubscriptionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionsAssetSubscriptionIdGet(nexusOpsTenant, assetSubscriptionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {AssetSubscriptionUpdateDto} [assetSubscriptionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionsAssetSubscriptionIdPut(nexusOpsTenant: string, assetSubscriptionId: string, assetSubscriptionUpdateDto?: AssetSubscriptionUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionsAssetSubscriptionIdPut(nexusOpsTenant, assetSubscriptionId, assetSubscriptionUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetSubscriptionId 
         * @param {AssetSubscriptionUpdateStatusDto} [assetSubscriptionUpdateStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionsAssetSubscriptionIdStatusPut(nexusOpsTenant: string, assetSubscriptionId: string, assetSubscriptionUpdateStatusDto?: AssetSubscriptionUpdateStatusDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionsAssetSubscriptionIdStatusPut(nexusOpsTenant, assetSubscriptionId, assetSubscriptionUpdateStatusDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdStatusPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionsBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionsBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionsApi.apiV1AssetSubscriptionsBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionGetCountersDto} [assetSubscriptionGetCountersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionsCountersGetPost(nexusOpsTenant: string, assetSubscriptionGetCountersDto?: AssetSubscriptionGetCountersDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionsCountersGetPost(nexusOpsTenant, assetSubscriptionGetCountersDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionsApi.apiV1AssetSubscriptionsCountersGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionGetPaginatedDto} [assetSubscriptionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionsGetPost(nexusOpsTenant: string, assetSubscriptionGetPaginatedDto?: AssetSubscriptionGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAssetSubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionsGetPost(nexusOpsTenant, assetSubscriptionGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionsApi.apiV1AssetSubscriptionsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionCreateDto} [assetSubscriptionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionsPost(nexusOpsTenant: string, assetSubscriptionCreateDto?: AssetSubscriptionCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionsPost(nexusOpsTenant, assetSubscriptionCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionsApi.apiV1AssetSubscriptionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSubscriptionSearchPaginatedDto} [assetSubscriptionSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetSubscriptionsSearchPost(nexusOpsTenant: string, assetSubscriptionSearchPaginatedDto?: AssetSubscriptionSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAssetSubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetSubscriptionsSearchPost(nexusOpsTenant, assetSubscriptionSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetSubscriptionsApi.apiV1AssetSubscriptionsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetSubscriptionsApi - factory interface
 * @export
 */
export const AssetSubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetSubscriptionsApiFp(configuration)
    return {
        /**
         * 
         * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionDto> {
            return localVarFp.apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionDto> {
            return localVarFp.apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, requestParameters.assetSubscriptionReallocateAssetDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionDto> {
            return localVarFp.apiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdDelete(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AssetSubscriptionsAssetSubscriptionIdDelete(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, requestParameters.isDeleteCascade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdGet(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionDto> {
            return localVarFp.apiV1AssetSubscriptionsAssetSubscriptionIdGet(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdPut(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionDto> {
            return localVarFp.apiV1AssetSubscriptionsAssetSubscriptionIdPut(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, requestParameters.assetSubscriptionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdStatusPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsAssetSubscriptionIdStatusPut(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdStatusPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionDto> {
            return localVarFp.apiV1AssetSubscriptionsAssetSubscriptionIdStatusPut(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, requestParameters.assetSubscriptionUpdateStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsBulkDeleteDelete(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AssetSubscriptionsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsCountersGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsCountersGetPost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsCountersGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionCountersDto> {
            return localVarFp.apiV1AssetSubscriptionsCountersGetPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionGetCountersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsGetPost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAssetSubscriptionDto> {
            return localVarFp.apiV1AssetSubscriptionsGetPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsPost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSubscriptionDto> {
            return localVarFp.apiV1AssetSubscriptionsPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetSubscriptionsSearchPost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAssetSubscriptionDto> {
            return localVarFp.apiV1AssetSubscriptionsSearchPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPost operation in AssetSubscriptionsApi.
 * @export
 * @interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPostRequest
 */
export interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPost
     */
    readonly assetSubscriptionId: string
}

/**
 * Request parameters for apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost operation in AssetSubscriptionsApi.
 * @export
 * @interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePostRequest
 */
export interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost
     */
    readonly assetSubscriptionId: string

    /**
     * 
     * @type {AssetSubscriptionReallocateAssetDto}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost
     */
    readonly assetSubscriptionReallocateAssetDto?: AssetSubscriptionReallocateAssetDto
}

/**
 * Request parameters for apiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost operation in AssetSubscriptionsApi.
 * @export
 * @interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePostRequest
 */
export interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost
     */
    readonly assetSubscriptionId: string
}

/**
 * Request parameters for apiV1AssetSubscriptionsAssetSubscriptionIdDelete operation in AssetSubscriptionsApi.
 * @export
 * @interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdDeleteRequest
 */
export interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdDelete
     */
    readonly assetSubscriptionId: string

    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdDelete
     */
    readonly isDeleteCascade?: boolean
}

/**
 * Request parameters for apiV1AssetSubscriptionsAssetSubscriptionIdGet operation in AssetSubscriptionsApi.
 * @export
 * @interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdGetRequest
 */
export interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdGet
     */
    readonly assetSubscriptionId: string
}

/**
 * Request parameters for apiV1AssetSubscriptionsAssetSubscriptionIdPut operation in AssetSubscriptionsApi.
 * @export
 * @interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdPutRequest
 */
export interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdPut
     */
    readonly assetSubscriptionId: string

    /**
     * 
     * @type {AssetSubscriptionUpdateDto}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdPut
     */
    readonly assetSubscriptionUpdateDto?: AssetSubscriptionUpdateDto
}

/**
 * Request parameters for apiV1AssetSubscriptionsAssetSubscriptionIdStatusPut operation in AssetSubscriptionsApi.
 * @export
 * @interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdStatusPutRequest
 */
export interface AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdStatusPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdStatusPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdStatusPut
     */
    readonly assetSubscriptionId: string

    /**
     * 
     * @type {AssetSubscriptionUpdateStatusDto}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdStatusPut
     */
    readonly assetSubscriptionUpdateStatusDto?: AssetSubscriptionUpdateStatusDto
}

/**
 * Request parameters for apiV1AssetSubscriptionsBulkDeleteDelete operation in AssetSubscriptionsApi.
 * @export
 * @interface AssetSubscriptionsApiApiV1AssetSubscriptionsBulkDeleteDeleteRequest
 */
export interface AssetSubscriptionsApiApiV1AssetSubscriptionsBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1AssetSubscriptionsCountersGetPost operation in AssetSubscriptionsApi.
 * @export
 * @interface AssetSubscriptionsApiApiV1AssetSubscriptionsCountersGetPostRequest
 */
export interface AssetSubscriptionsApiApiV1AssetSubscriptionsCountersGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsCountersGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssetSubscriptionGetCountersDto}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsCountersGetPost
     */
    readonly assetSubscriptionGetCountersDto?: AssetSubscriptionGetCountersDto
}

/**
 * Request parameters for apiV1AssetSubscriptionsGetPost operation in AssetSubscriptionsApi.
 * @export
 * @interface AssetSubscriptionsApiApiV1AssetSubscriptionsGetPostRequest
 */
export interface AssetSubscriptionsApiApiV1AssetSubscriptionsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssetSubscriptionGetPaginatedDto}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsGetPost
     */
    readonly assetSubscriptionGetPaginatedDto?: AssetSubscriptionGetPaginatedDto
}

/**
 * Request parameters for apiV1AssetSubscriptionsPost operation in AssetSubscriptionsApi.
 * @export
 * @interface AssetSubscriptionsApiApiV1AssetSubscriptionsPostRequest
 */
export interface AssetSubscriptionsApiApiV1AssetSubscriptionsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssetSubscriptionCreateDto}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsPost
     */
    readonly assetSubscriptionCreateDto?: AssetSubscriptionCreateDto
}

/**
 * Request parameters for apiV1AssetSubscriptionsSearchPost operation in AssetSubscriptionsApi.
 * @export
 * @interface AssetSubscriptionsApiApiV1AssetSubscriptionsSearchPostRequest
 */
export interface AssetSubscriptionsApiApiV1AssetSubscriptionsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssetSubscriptionSearchPaginatedDto}
     * @memberof AssetSubscriptionsApiApiV1AssetSubscriptionsSearchPost
     */
    readonly assetSubscriptionSearchPaginatedDto?: AssetSubscriptionSearchPaginatedDto
}

/**
 * AssetSubscriptionsApi - object-oriented interface
 * @export
 * @class AssetSubscriptionsApi
 * @extends {BaseAPI}
 */
export class AssetSubscriptionsApi extends BaseAPI {
    /**
     * 
     * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionsApi
     */
    public apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPostRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionsApiFp(this.configuration).apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationConfirmPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionsApi
     */
    public apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePostRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionsApiFp(this.configuration).apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, requestParameters.assetSubscriptionReallocateAssetDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionsApi
     */
    public apiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePostRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionsApiFp(this.configuration).apiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionsApi
     */
    public apiV1AssetSubscriptionsAssetSubscriptionIdDelete(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionsApiFp(this.configuration).apiV1AssetSubscriptionsAssetSubscriptionIdDelete(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, requestParameters.isDeleteCascade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionsApi
     */
    public apiV1AssetSubscriptionsAssetSubscriptionIdGet(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdGetRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionsApiFp(this.configuration).apiV1AssetSubscriptionsAssetSubscriptionIdGet(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionsApi
     */
    public apiV1AssetSubscriptionsAssetSubscriptionIdPut(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdPutRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionsApiFp(this.configuration).apiV1AssetSubscriptionsAssetSubscriptionIdPut(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, requestParameters.assetSubscriptionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdStatusPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionsApi
     */
    public apiV1AssetSubscriptionsAssetSubscriptionIdStatusPut(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsAssetSubscriptionIdStatusPutRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionsApiFp(this.configuration).apiV1AssetSubscriptionsAssetSubscriptionIdStatusPut(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionId, requestParameters.assetSubscriptionUpdateStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionsApi
     */
    public apiV1AssetSubscriptionsBulkDeleteDelete(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionsApiFp(this.configuration).apiV1AssetSubscriptionsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsCountersGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionsApi
     */
    public apiV1AssetSubscriptionsCountersGetPost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsCountersGetPostRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionsApiFp(this.configuration).apiV1AssetSubscriptionsCountersGetPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionGetCountersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionsApi
     */
    public apiV1AssetSubscriptionsGetPost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsGetPostRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionsApiFp(this.configuration).apiV1AssetSubscriptionsGetPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionsApi
     */
    public apiV1AssetSubscriptionsPost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsPostRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionsApiFp(this.configuration).apiV1AssetSubscriptionsPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetSubscriptionsApiApiV1AssetSubscriptionsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetSubscriptionsApi
     */
    public apiV1AssetSubscriptionsSearchPost(requestParameters: AssetSubscriptionsApiApiV1AssetSubscriptionsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return AssetSubscriptionsApiFp(this.configuration).apiV1AssetSubscriptionsSearchPost(requestParameters.nexusOpsTenant, requestParameters.assetSubscriptionSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsAssetIdDelete: async (nexusOpsTenant: string, assetId: string, isDeleteCascade?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetsAssetIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('apiV1AssetsAssetIdDelete', 'assetId', assetId)
            const localVarPath = `/api/v1/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsAssetIdGet: async (nexusOpsTenant: string, assetId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetsAssetIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('apiV1AssetsAssetIdGet', 'assetId', assetId)
            const localVarPath = `/api/v1/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetId 
         * @param {AssetUpdateDto} [assetUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsAssetIdPut: async (nexusOpsTenant: string, assetId: string, assetUpdateDto?: AssetUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetsAssetIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('apiV1AssetsAssetIdPut', 'assetId', assetId)
            const localVarPath = `/api/v1/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetsBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/assets/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetEntityType} [entityType] 
         * @param {string} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsByEntityGet: async (nexusOpsTenant: string, entityType?: AssetEntityType, entityId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetsByEntityGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/assets/by-entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {AssetEntityType} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsCandidatesGet: async (nexusOpsTenant: string, offset?: number, limit?: number, search?: string, entityType?: AssetEntityType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetsCandidatesGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/assets/candidates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetGetPaginatedDto} [assetGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsGetPost: async (nexusOpsTenant: string, assetGetPaginatedDto?: AssetGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/assets/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetCreateDto} [assetCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsPost: async (nexusOpsTenant: string, assetCreateDto?: AssetCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSearchPaginatedDto} [assetSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsSearchPost: async (nexusOpsTenant: string, assetSearchPaginatedDto?: AssetSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/assets/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetEntityType} [entityType] 
         * @param {string} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsSpecInferFromEntityGet: async (nexusOpsTenant: string, entityType?: AssetEntityType, entityId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetsSpecInferFromEntityGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/assets/spec/infer/from-entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} [poolId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsSpecInferFromPoolGet: async (nexusOpsTenant: string, poolId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1AssetsSpecInferFromPoolGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/assets/spec/infer/from-pool`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (poolId !== undefined) {
                localVarQueryParameter['poolId'] = poolId;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetsAssetIdDelete(nexusOpsTenant: string, assetId: string, isDeleteCascade?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetsAssetIdDelete(nexusOpsTenant, assetId, isDeleteCascade, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.apiV1AssetsAssetIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetsAssetIdGet(nexusOpsTenant: string, assetId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetsAssetIdGet(nexusOpsTenant, assetId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.apiV1AssetsAssetIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} assetId 
         * @param {AssetUpdateDto} [assetUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetsAssetIdPut(nexusOpsTenant: string, assetId: string, assetUpdateDto?: AssetUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetsAssetIdPut(nexusOpsTenant, assetId, assetUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.apiV1AssetsAssetIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetsBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetsBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.apiV1AssetsBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetEntityType} [entityType] 
         * @param {string} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetsByEntityGet(nexusOpsTenant: string, entityType?: AssetEntityType, entityId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetsByEntityGet(nexusOpsTenant, entityType, entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.apiV1AssetsByEntityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {AssetEntityType} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetsCandidatesGet(nexusOpsTenant: string, offset?: number, limit?: number, search?: string, entityType?: AssetEntityType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAssetCandidateEntityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetsCandidatesGet(nexusOpsTenant, offset, limit, search, entityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.apiV1AssetsCandidatesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetGetPaginatedDto} [assetGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetsGetPost(nexusOpsTenant: string, assetGetPaginatedDto?: AssetGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAssetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetsGetPost(nexusOpsTenant, assetGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.apiV1AssetsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetCreateDto} [assetCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetsPost(nexusOpsTenant: string, assetCreateDto?: AssetCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetsPost(nexusOpsTenant, assetCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.apiV1AssetsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetSearchPaginatedDto} [assetSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetsSearchPost(nexusOpsTenant: string, assetSearchPaginatedDto?: AssetSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAssetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetsSearchPost(nexusOpsTenant, assetSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.apiV1AssetsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AssetEntityType} [entityType] 
         * @param {string} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetsSpecInferFromEntityGet(nexusOpsTenant: string, entityType?: AssetEntityType, entityId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetsSpecInferFromEntityGet(nexusOpsTenant, entityType, entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.apiV1AssetsSpecInferFromEntityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} [poolId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AssetsSpecInferFromPoolGet(nexusOpsTenant: string, poolId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AssetsSpecInferFromPoolGet(nexusOpsTenant, poolId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetsApi.apiV1AssetsSpecInferFromPoolGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * 
         * @param {AssetsApiApiV1AssetsAssetIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsAssetIdDelete(requestParameters: AssetsApiApiV1AssetsAssetIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AssetsAssetIdDelete(requestParameters.nexusOpsTenant, requestParameters.assetId, requestParameters.isDeleteCascade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetsApiApiV1AssetsAssetIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsAssetIdGet(requestParameters: AssetsApiApiV1AssetsAssetIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetDto> {
            return localVarFp.apiV1AssetsAssetIdGet(requestParameters.nexusOpsTenant, requestParameters.assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetsApiApiV1AssetsAssetIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsAssetIdPut(requestParameters: AssetsApiApiV1AssetsAssetIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetDto> {
            return localVarFp.apiV1AssetsAssetIdPut(requestParameters.nexusOpsTenant, requestParameters.assetId, requestParameters.assetUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetsApiApiV1AssetsBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsBulkDeleteDelete(requestParameters: AssetsApiApiV1AssetsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AssetsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetsApiApiV1AssetsByEntityGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsByEntityGet(requestParameters: AssetsApiApiV1AssetsByEntityGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetDto> {
            return localVarFp.apiV1AssetsByEntityGet(requestParameters.nexusOpsTenant, requestParameters.entityType, requestParameters.entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetsApiApiV1AssetsCandidatesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsCandidatesGet(requestParameters: AssetsApiApiV1AssetsCandidatesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAssetCandidateEntityDto> {
            return localVarFp.apiV1AssetsCandidatesGet(requestParameters.nexusOpsTenant, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.entityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetsApiApiV1AssetsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsGetPost(requestParameters: AssetsApiApiV1AssetsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAssetDto> {
            return localVarFp.apiV1AssetsGetPost(requestParameters.nexusOpsTenant, requestParameters.assetGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetsApiApiV1AssetsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsPost(requestParameters: AssetsApiApiV1AssetsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetDto> {
            return localVarFp.apiV1AssetsPost(requestParameters.nexusOpsTenant, requestParameters.assetCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetsApiApiV1AssetsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsSearchPost(requestParameters: AssetsApiApiV1AssetsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAssetDto> {
            return localVarFp.apiV1AssetsSearchPost(requestParameters.nexusOpsTenant, requestParameters.assetSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetsApiApiV1AssetsSpecInferFromEntityGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsSpecInferFromEntityGet(requestParameters: AssetsApiApiV1AssetsSpecInferFromEntityGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSpecDto> {
            return localVarFp.apiV1AssetsSpecInferFromEntityGet(requestParameters.nexusOpsTenant, requestParameters.entityType, requestParameters.entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetsApiApiV1AssetsSpecInferFromPoolGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AssetsSpecInferFromPoolGet(requestParameters: AssetsApiApiV1AssetsSpecInferFromPoolGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetSpecDto> {
            return localVarFp.apiV1AssetsSpecInferFromPoolGet(requestParameters.nexusOpsTenant, requestParameters.poolId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AssetsAssetIdDelete operation in AssetsApi.
 * @export
 * @interface AssetsApiApiV1AssetsAssetIdDeleteRequest
 */
export interface AssetsApiApiV1AssetsAssetIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetsApiApiV1AssetsAssetIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetsApiApiV1AssetsAssetIdDelete
     */
    readonly assetId: string

    /**
     * 
     * @type {boolean}
     * @memberof AssetsApiApiV1AssetsAssetIdDelete
     */
    readonly isDeleteCascade?: boolean
}

/**
 * Request parameters for apiV1AssetsAssetIdGet operation in AssetsApi.
 * @export
 * @interface AssetsApiApiV1AssetsAssetIdGetRequest
 */
export interface AssetsApiApiV1AssetsAssetIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetsApiApiV1AssetsAssetIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetsApiApiV1AssetsAssetIdGet
     */
    readonly assetId: string
}

/**
 * Request parameters for apiV1AssetsAssetIdPut operation in AssetsApi.
 * @export
 * @interface AssetsApiApiV1AssetsAssetIdPutRequest
 */
export interface AssetsApiApiV1AssetsAssetIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetsApiApiV1AssetsAssetIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetsApiApiV1AssetsAssetIdPut
     */
    readonly assetId: string

    /**
     * 
     * @type {AssetUpdateDto}
     * @memberof AssetsApiApiV1AssetsAssetIdPut
     */
    readonly assetUpdateDto?: AssetUpdateDto
}

/**
 * Request parameters for apiV1AssetsBulkDeleteDelete operation in AssetsApi.
 * @export
 * @interface AssetsApiApiV1AssetsBulkDeleteDeleteRequest
 */
export interface AssetsApiApiV1AssetsBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetsApiApiV1AssetsBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof AssetsApiApiV1AssetsBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1AssetsByEntityGet operation in AssetsApi.
 * @export
 * @interface AssetsApiApiV1AssetsByEntityGetRequest
 */
export interface AssetsApiApiV1AssetsByEntityGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetsApiApiV1AssetsByEntityGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetsApiApiV1AssetsByEntityGet
     */
    readonly entityType?: AssetEntityType

    /**
     * 
     * @type {string}
     * @memberof AssetsApiApiV1AssetsByEntityGet
     */
    readonly entityId?: string
}

/**
 * Request parameters for apiV1AssetsCandidatesGet operation in AssetsApi.
 * @export
 * @interface AssetsApiApiV1AssetsCandidatesGetRequest
 */
export interface AssetsApiApiV1AssetsCandidatesGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetsApiApiV1AssetsCandidatesGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {number}
     * @memberof AssetsApiApiV1AssetsCandidatesGet
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof AssetsApiApiV1AssetsCandidatesGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AssetsApiApiV1AssetsCandidatesGet
     */
    readonly search?: string

    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetsApiApiV1AssetsCandidatesGet
     */
    readonly entityType?: AssetEntityType
}

/**
 * Request parameters for apiV1AssetsGetPost operation in AssetsApi.
 * @export
 * @interface AssetsApiApiV1AssetsGetPostRequest
 */
export interface AssetsApiApiV1AssetsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetsApiApiV1AssetsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssetGetPaginatedDto}
     * @memberof AssetsApiApiV1AssetsGetPost
     */
    readonly assetGetPaginatedDto?: AssetGetPaginatedDto
}

/**
 * Request parameters for apiV1AssetsPost operation in AssetsApi.
 * @export
 * @interface AssetsApiApiV1AssetsPostRequest
 */
export interface AssetsApiApiV1AssetsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetsApiApiV1AssetsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssetCreateDto}
     * @memberof AssetsApiApiV1AssetsPost
     */
    readonly assetCreateDto?: AssetCreateDto
}

/**
 * Request parameters for apiV1AssetsSearchPost operation in AssetsApi.
 * @export
 * @interface AssetsApiApiV1AssetsSearchPostRequest
 */
export interface AssetsApiApiV1AssetsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetsApiApiV1AssetsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssetSearchPaginatedDto}
     * @memberof AssetsApiApiV1AssetsSearchPost
     */
    readonly assetSearchPaginatedDto?: AssetSearchPaginatedDto
}

/**
 * Request parameters for apiV1AssetsSpecInferFromEntityGet operation in AssetsApi.
 * @export
 * @interface AssetsApiApiV1AssetsSpecInferFromEntityGetRequest
 */
export interface AssetsApiApiV1AssetsSpecInferFromEntityGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetsApiApiV1AssetsSpecInferFromEntityGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetsApiApiV1AssetsSpecInferFromEntityGet
     */
    readonly entityType?: AssetEntityType

    /**
     * 
     * @type {string}
     * @memberof AssetsApiApiV1AssetsSpecInferFromEntityGet
     */
    readonly entityId?: string
}

/**
 * Request parameters for apiV1AssetsSpecInferFromPoolGet operation in AssetsApi.
 * @export
 * @interface AssetsApiApiV1AssetsSpecInferFromPoolGetRequest
 */
export interface AssetsApiApiV1AssetsSpecInferFromPoolGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof AssetsApiApiV1AssetsSpecInferFromPoolGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof AssetsApiApiV1AssetsSpecInferFromPoolGet
     */
    readonly poolId?: string
}

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
    /**
     * 
     * @param {AssetsApiApiV1AssetsAssetIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public apiV1AssetsAssetIdDelete(requestParameters: AssetsApiApiV1AssetsAssetIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).apiV1AssetsAssetIdDelete(requestParameters.nexusOpsTenant, requestParameters.assetId, requestParameters.isDeleteCascade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetsApiApiV1AssetsAssetIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public apiV1AssetsAssetIdGet(requestParameters: AssetsApiApiV1AssetsAssetIdGetRequest, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).apiV1AssetsAssetIdGet(requestParameters.nexusOpsTenant, requestParameters.assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetsApiApiV1AssetsAssetIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public apiV1AssetsAssetIdPut(requestParameters: AssetsApiApiV1AssetsAssetIdPutRequest, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).apiV1AssetsAssetIdPut(requestParameters.nexusOpsTenant, requestParameters.assetId, requestParameters.assetUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetsApiApiV1AssetsBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public apiV1AssetsBulkDeleteDelete(requestParameters: AssetsApiApiV1AssetsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).apiV1AssetsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetsApiApiV1AssetsByEntityGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public apiV1AssetsByEntityGet(requestParameters: AssetsApiApiV1AssetsByEntityGetRequest, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).apiV1AssetsByEntityGet(requestParameters.nexusOpsTenant, requestParameters.entityType, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetsApiApiV1AssetsCandidatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public apiV1AssetsCandidatesGet(requestParameters: AssetsApiApiV1AssetsCandidatesGetRequest, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).apiV1AssetsCandidatesGet(requestParameters.nexusOpsTenant, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.entityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetsApiApiV1AssetsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public apiV1AssetsGetPost(requestParameters: AssetsApiApiV1AssetsGetPostRequest, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).apiV1AssetsGetPost(requestParameters.nexusOpsTenant, requestParameters.assetGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetsApiApiV1AssetsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public apiV1AssetsPost(requestParameters: AssetsApiApiV1AssetsPostRequest, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).apiV1AssetsPost(requestParameters.nexusOpsTenant, requestParameters.assetCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetsApiApiV1AssetsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public apiV1AssetsSearchPost(requestParameters: AssetsApiApiV1AssetsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).apiV1AssetsSearchPost(requestParameters.nexusOpsTenant, requestParameters.assetSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetsApiApiV1AssetsSpecInferFromEntityGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public apiV1AssetsSpecInferFromEntityGet(requestParameters: AssetsApiApiV1AssetsSpecInferFromEntityGetRequest, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).apiV1AssetsSpecInferFromEntityGet(requestParameters.nexusOpsTenant, requestParameters.entityType, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetsApiApiV1AssetsSpecInferFromPoolGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public apiV1AssetsSpecInferFromPoolGet(requestParameters: AssetsApiApiV1AssetsSpecInferFromPoolGetRequest, options?: RawAxiosRequestConfig) {
        return AssetsApiFp(this.configuration).apiV1AssetsSpecInferFromPoolGet(requestParameters.nexusOpsTenant, requestParameters.poolId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CascadeActionsApi - axios parameter creator
 * @export
 */
export const CascadeActionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CascadeActionType} [actionType] 
         * @param {EntityType} [baseEntityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CascadeActionsSpecGet: async (nexusOpsTenant: string, actionType?: CascadeActionType, baseEntityType?: EntityType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CascadeActionsSpecGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/cascade-actions/spec`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (actionType !== undefined) {
                localVarQueryParameter['actionType'] = actionType;
            }

            if (baseEntityType !== undefined) {
                localVarQueryParameter['baseEntityType'] = baseEntityType;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CascadeActionInputDto} [cascadeActionInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CascadeActionsUpdateAffiliationPost: async (nexusOpsTenant: string, cascadeActionInputDto?: CascadeActionInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CascadeActionsUpdateAffiliationPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/cascade-actions/update-affiliation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cascadeActionInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CascadeActionsApi - functional programming interface
 * @export
 */
export const CascadeActionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CascadeActionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CascadeActionType} [actionType] 
         * @param {EntityType} [baseEntityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CascadeActionsSpecGet(nexusOpsTenant: string, actionType?: CascadeActionType, baseEntityType?: EntityType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CascadeActionSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CascadeActionsSpecGet(nexusOpsTenant, actionType, baseEntityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CascadeActionsApi.apiV1CascadeActionsSpecGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CascadeActionInputDto} [cascadeActionInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CascadeActionsUpdateAffiliationPost(nexusOpsTenant: string, cascadeActionInputDto?: CascadeActionInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CascadeActionsUpdateAffiliationPost(nexusOpsTenant, cascadeActionInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CascadeActionsApi.apiV1CascadeActionsUpdateAffiliationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CascadeActionsApi - factory interface
 * @export
 */
export const CascadeActionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CascadeActionsApiFp(configuration)
    return {
        /**
         * 
         * @param {CascadeActionsApiApiV1CascadeActionsSpecGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CascadeActionsSpecGet(requestParameters: CascadeActionsApiApiV1CascadeActionsSpecGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CascadeActionSpecDto> {
            return localVarFp.apiV1CascadeActionsSpecGet(requestParameters.nexusOpsTenant, requestParameters.actionType, requestParameters.baseEntityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CascadeActionsApiApiV1CascadeActionsUpdateAffiliationPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CascadeActionsUpdateAffiliationPost(requestParameters: CascadeActionsApiApiV1CascadeActionsUpdateAffiliationPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1CascadeActionsUpdateAffiliationPost(requestParameters.nexusOpsTenant, requestParameters.cascadeActionInputDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1CascadeActionsSpecGet operation in CascadeActionsApi.
 * @export
 * @interface CascadeActionsApiApiV1CascadeActionsSpecGetRequest
 */
export interface CascadeActionsApiApiV1CascadeActionsSpecGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CascadeActionsApiApiV1CascadeActionsSpecGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CascadeActionType}
     * @memberof CascadeActionsApiApiV1CascadeActionsSpecGet
     */
    readonly actionType?: CascadeActionType

    /**
     * 
     * @type {EntityType}
     * @memberof CascadeActionsApiApiV1CascadeActionsSpecGet
     */
    readonly baseEntityType?: EntityType
}

/**
 * Request parameters for apiV1CascadeActionsUpdateAffiliationPost operation in CascadeActionsApi.
 * @export
 * @interface CascadeActionsApiApiV1CascadeActionsUpdateAffiliationPostRequest
 */
export interface CascadeActionsApiApiV1CascadeActionsUpdateAffiliationPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CascadeActionsApiApiV1CascadeActionsUpdateAffiliationPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CascadeActionInputDto}
     * @memberof CascadeActionsApiApiV1CascadeActionsUpdateAffiliationPost
     */
    readonly cascadeActionInputDto?: CascadeActionInputDto
}

/**
 * CascadeActionsApi - object-oriented interface
 * @export
 * @class CascadeActionsApi
 * @extends {BaseAPI}
 */
export class CascadeActionsApi extends BaseAPI {
    /**
     * 
     * @param {CascadeActionsApiApiV1CascadeActionsSpecGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CascadeActionsApi
     */
    public apiV1CascadeActionsSpecGet(requestParameters: CascadeActionsApiApiV1CascadeActionsSpecGetRequest, options?: RawAxiosRequestConfig) {
        return CascadeActionsApiFp(this.configuration).apiV1CascadeActionsSpecGet(requestParameters.nexusOpsTenant, requestParameters.actionType, requestParameters.baseEntityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CascadeActionsApiApiV1CascadeActionsUpdateAffiliationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CascadeActionsApi
     */
    public apiV1CascadeActionsUpdateAffiliationPost(requestParameters: CascadeActionsApiApiV1CascadeActionsUpdateAffiliationPostRequest, options?: RawAxiosRequestConfig) {
        return CascadeActionsApiFp(this.configuration).apiV1CascadeActionsUpdateAffiliationPost(requestParameters.nexusOpsTenant, requestParameters.cascadeActionInputDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChatActivityApi - axios parameter creator
 * @export
 */
export const ChatActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {ChatActivityGetPaginatedDto} [chatActivityGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatActivityByChatChatIdGetPost: async (nexusOpsTenant: string, chatId: string, chatActivityGetPaginatedDto?: ChatActivityGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatActivityByChatChatIdGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatActivityByChatChatIdGetPost', 'chatId', chatId)
            const localVarPath = `/api/v1/chat-activity/by-chat/{chatId}/get`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatActivityGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatActivityApi - functional programming interface
 * @export
 */
export const ChatActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {ChatActivityGetPaginatedDto} [chatActivityGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatActivityByChatChatIdGetPost(nexusOpsTenant: string, chatId: string, chatActivityGetPaginatedDto?: ChatActivityGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfChatActivityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatActivityByChatChatIdGetPost(nexusOpsTenant, chatId, chatActivityGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatActivityApi.apiV1ChatActivityByChatChatIdGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChatActivityApi - factory interface
 * @export
 */
export const ChatActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatActivityApiFp(configuration)
    return {
        /**
         * 
         * @param {ChatActivityApiApiV1ChatActivityByChatChatIdGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatActivityByChatChatIdGetPost(requestParameters: ChatActivityApiApiV1ChatActivityByChatChatIdGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfChatActivityDto> {
            return localVarFp.apiV1ChatActivityByChatChatIdGetPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.chatActivityGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ChatActivityByChatChatIdGetPost operation in ChatActivityApi.
 * @export
 * @interface ChatActivityApiApiV1ChatActivityByChatChatIdGetPostRequest
 */
export interface ChatActivityApiApiV1ChatActivityByChatChatIdGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatActivityApiApiV1ChatActivityByChatChatIdGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatActivityApiApiV1ChatActivityByChatChatIdGetPost
     */
    readonly chatId: string

    /**
     * 
     * @type {ChatActivityGetPaginatedDto}
     * @memberof ChatActivityApiApiV1ChatActivityByChatChatIdGetPost
     */
    readonly chatActivityGetPaginatedDto?: ChatActivityGetPaginatedDto
}

/**
 * ChatActivityApi - object-oriented interface
 * @export
 * @class ChatActivityApi
 * @extends {BaseAPI}
 */
export class ChatActivityApi extends BaseAPI {
    /**
     * 
     * @param {ChatActivityApiApiV1ChatActivityByChatChatIdGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatActivityApi
     */
    public apiV1ChatActivityByChatChatIdGetPost(requestParameters: ChatActivityApiApiV1ChatActivityByChatChatIdGetPostRequest, options?: RawAxiosRequestConfig) {
        return ChatActivityApiFp(this.configuration).apiV1ChatActivityByChatChatIdGetPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.chatActivityGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChatActivityOverviewsApi - axios parameter creator
 * @export
 */
export const ChatActivityOverviewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatActivityOverviewsByChatChatIdGet: async (nexusOpsTenant: string, chatId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatActivityOverviewsByChatChatIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatActivityOverviewsByChatChatIdGet', 'chatId', chatId)
            const localVarPath = `/api/v1/chat-activity-overviews/by-chat/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [chatIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatActivityOverviewsByChatsGet: async (nexusOpsTenant: string, chatIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatActivityOverviewsByChatsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/chat-activity-overviews/by-chats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chatIds) {
                localVarQueryParameter['chatIds'] = chatIds;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GetChatActivityOverviewByScopeDto} [getChatActivityOverviewByScopeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatActivityOverviewsGetByScopePost: async (nexusOpsTenant: string, getChatActivityOverviewByScopeDto?: GetChatActivityOverviewByScopeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatActivityOverviewsGetByScopePost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/chat-activity-overviews/get/by-scope`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getChatActivityOverviewByScopeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GetChatActivityOverviewByScopesDto} [getChatActivityOverviewByScopesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatActivityOverviewsGetByScopesPost: async (nexusOpsTenant: string, getChatActivityOverviewByScopesDto?: GetChatActivityOverviewByScopesDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatActivityOverviewsGetByScopesPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/chat-activity-overviews/get/by-scopes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getChatActivityOverviewByScopesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatActivityOverviewsApi - functional programming interface
 * @export
 */
export const ChatActivityOverviewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatActivityOverviewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatActivityOverviewsByChatChatIdGet(nexusOpsTenant: string, chatId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatActivityOverviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatActivityOverviewsByChatChatIdGet(nexusOpsTenant, chatId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatActivityOverviewsApi.apiV1ChatActivityOverviewsByChatChatIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [chatIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatActivityOverviewsByChatsGet(nexusOpsTenant: string, chatIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatActivityOverviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatActivityOverviewsByChatsGet(nexusOpsTenant, chatIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatActivityOverviewsApi.apiV1ChatActivityOverviewsByChatsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GetChatActivityOverviewByScopeDto} [getChatActivityOverviewByScopeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatActivityOverviewsGetByScopePost(nexusOpsTenant: string, getChatActivityOverviewByScopeDto?: GetChatActivityOverviewByScopeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatActivityOverviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatActivityOverviewsGetByScopePost(nexusOpsTenant, getChatActivityOverviewByScopeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatActivityOverviewsApi.apiV1ChatActivityOverviewsGetByScopePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GetChatActivityOverviewByScopesDto} [getChatActivityOverviewByScopesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatActivityOverviewsGetByScopesPost(nexusOpsTenant: string, getChatActivityOverviewByScopesDto?: GetChatActivityOverviewByScopesDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatActivityOverviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatActivityOverviewsGetByScopesPost(nexusOpsTenant, getChatActivityOverviewByScopesDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatActivityOverviewsApi.apiV1ChatActivityOverviewsGetByScopesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChatActivityOverviewsApi - factory interface
 * @export
 */
export const ChatActivityOverviewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatActivityOverviewsApiFp(configuration)
    return {
        /**
         * 
         * @param {ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatChatIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatActivityOverviewsByChatChatIdGet(requestParameters: ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatChatIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatActivityOverviewDto> {
            return localVarFp.apiV1ChatActivityOverviewsByChatChatIdGet(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatActivityOverviewsByChatsGet(requestParameters: ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ChatActivityOverviewDto>> {
            return localVarFp.apiV1ChatActivityOverviewsByChatsGet(requestParameters.nexusOpsTenant, requestParameters.chatIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatActivityOverviewsGetByScopePost(requestParameters: ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatActivityOverviewDto> {
            return localVarFp.apiV1ChatActivityOverviewsGetByScopePost(requestParameters.nexusOpsTenant, requestParameters.getChatActivityOverviewByScopeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatActivityOverviewsGetByScopesPost(requestParameters: ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ChatActivityOverviewDto>> {
            return localVarFp.apiV1ChatActivityOverviewsGetByScopesPost(requestParameters.nexusOpsTenant, requestParameters.getChatActivityOverviewByScopesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ChatActivityOverviewsByChatChatIdGet operation in ChatActivityOverviewsApi.
 * @export
 * @interface ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatChatIdGetRequest
 */
export interface ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatChatIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatChatIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatChatIdGet
     */
    readonly chatId: string
}

/**
 * Request parameters for apiV1ChatActivityOverviewsByChatsGet operation in ChatActivityOverviewsApi.
 * @export
 * @interface ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatsGetRequest
 */
export interface ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatsGet
     */
    readonly chatIds?: Array<string>
}

/**
 * Request parameters for apiV1ChatActivityOverviewsGetByScopePost operation in ChatActivityOverviewsApi.
 * @export
 * @interface ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopePostRequest
 */
export interface ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GetChatActivityOverviewByScopeDto}
     * @memberof ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopePost
     */
    readonly getChatActivityOverviewByScopeDto?: GetChatActivityOverviewByScopeDto
}

/**
 * Request parameters for apiV1ChatActivityOverviewsGetByScopesPost operation in ChatActivityOverviewsApi.
 * @export
 * @interface ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopesPostRequest
 */
export interface ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopesPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopesPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GetChatActivityOverviewByScopesDto}
     * @memberof ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopesPost
     */
    readonly getChatActivityOverviewByScopesDto?: GetChatActivityOverviewByScopesDto
}

/**
 * ChatActivityOverviewsApi - object-oriented interface
 * @export
 * @class ChatActivityOverviewsApi
 * @extends {BaseAPI}
 */
export class ChatActivityOverviewsApi extends BaseAPI {
    /**
     * 
     * @param {ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatChatIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatActivityOverviewsApi
     */
    public apiV1ChatActivityOverviewsByChatChatIdGet(requestParameters: ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatChatIdGetRequest, options?: RawAxiosRequestConfig) {
        return ChatActivityOverviewsApiFp(this.configuration).apiV1ChatActivityOverviewsByChatChatIdGet(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatActivityOverviewsApi
     */
    public apiV1ChatActivityOverviewsByChatsGet(requestParameters: ChatActivityOverviewsApiApiV1ChatActivityOverviewsByChatsGetRequest, options?: RawAxiosRequestConfig) {
        return ChatActivityOverviewsApiFp(this.configuration).apiV1ChatActivityOverviewsByChatsGet(requestParameters.nexusOpsTenant, requestParameters.chatIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatActivityOverviewsApi
     */
    public apiV1ChatActivityOverviewsGetByScopePost(requestParameters: ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopePostRequest, options?: RawAxiosRequestConfig) {
        return ChatActivityOverviewsApiFp(this.configuration).apiV1ChatActivityOverviewsGetByScopePost(requestParameters.nexusOpsTenant, requestParameters.getChatActivityOverviewByScopeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatActivityOverviewsApi
     */
    public apiV1ChatActivityOverviewsGetByScopesPost(requestParameters: ChatActivityOverviewsApiApiV1ChatActivityOverviewsGetByScopesPostRequest, options?: RawAxiosRequestConfig) {
        return ChatActivityOverviewsApiFp(this.configuration).apiV1ChatActivityOverviewsGetByScopesPost(requestParameters.nexusOpsTenant, requestParameters.getChatActivityOverviewByScopesDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChatEventsApi - axios parameter creator
 * @export
 */
export const ChatEventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {ChatEventGetPaginatedDto} [chatEventGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdEventsGetPost: async (nexusOpsTenant: string, chatId: string, chatEventGetPaginatedDto?: ChatEventGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdEventsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdEventsGetPost', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/events/get`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatEventGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatEventsApi - functional programming interface
 * @export
 */
export const ChatEventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatEventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {ChatEventGetPaginatedDto} [chatEventGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdEventsGetPost(nexusOpsTenant: string, chatId: string, chatEventGetPaginatedDto?: ChatEventGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfChatEventDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdEventsGetPost(nexusOpsTenant, chatId, chatEventGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatEventsApi.apiV1ChatsChatIdEventsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChatEventsApi - factory interface
 * @export
 */
export const ChatEventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatEventsApiFp(configuration)
    return {
        /**
         * 
         * @param {ChatEventsApiApiV1ChatsChatIdEventsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdEventsGetPost(requestParameters: ChatEventsApiApiV1ChatsChatIdEventsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfChatEventDto> {
            return localVarFp.apiV1ChatsChatIdEventsGetPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.chatEventGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ChatsChatIdEventsGetPost operation in ChatEventsApi.
 * @export
 * @interface ChatEventsApiApiV1ChatsChatIdEventsGetPostRequest
 */
export interface ChatEventsApiApiV1ChatsChatIdEventsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatEventsApiApiV1ChatsChatIdEventsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatEventsApiApiV1ChatsChatIdEventsGetPost
     */
    readonly chatId: string

    /**
     * 
     * @type {ChatEventGetPaginatedDto}
     * @memberof ChatEventsApiApiV1ChatsChatIdEventsGetPost
     */
    readonly chatEventGetPaginatedDto?: ChatEventGetPaginatedDto
}

/**
 * ChatEventsApi - object-oriented interface
 * @export
 * @class ChatEventsApi
 * @extends {BaseAPI}
 */
export class ChatEventsApi extends BaseAPI {
    /**
     * 
     * @param {ChatEventsApiApiV1ChatsChatIdEventsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatEventsApi
     */
    public apiV1ChatsChatIdEventsGetPost(requestParameters: ChatEventsApiApiV1ChatsChatIdEventsGetPostRequest, options?: RawAxiosRequestConfig) {
        return ChatEventsApiFp(this.configuration).apiV1ChatsChatIdEventsGetPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.chatEventGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChatHistoryApi - axios parameter creator
 * @export
 */
export const ChatHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {ChatHistoryGetPaginatedDto} [chatHistoryGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdHistoryGetPinnedPost: async (nexusOpsTenant: string, chatId: string, chatHistoryGetPaginatedDto?: ChatHistoryGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdHistoryGetPinnedPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdHistoryGetPinnedPost', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/history/get/pinned`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatHistoryGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {ChatHistoryGetPaginatedDto} [chatHistoryGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdHistoryGetPost: async (nexusOpsTenant: string, chatId: string, chatHistoryGetPaginatedDto?: ChatHistoryGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdHistoryGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdHistoryGetPost', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/history/get`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatHistoryGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdHistoryItemsItemIdPinPost: async (nexusOpsTenant: string, chatId: string, itemId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdHistoryItemsItemIdPinPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdHistoryItemsItemIdPinPost', 'chatId', chatId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdHistoryItemsItemIdPinPost', 'itemId', itemId)
            const localVarPath = `/api/v1/chats/{chatId}/history/items/{itemId}/pin`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdHistoryItemsItemIdUnpinPost: async (nexusOpsTenant: string, chatId: string, itemId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdHistoryItemsItemIdUnpinPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdHistoryItemsItemIdUnpinPost', 'chatId', chatId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdHistoryItemsItemIdUnpinPost', 'itemId', itemId)
            const localVarPath = `/api/v1/chats/{chatId}/history/items/{itemId}/unpin`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatHistoryApi - functional programming interface
 * @export
 */
export const ChatHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {ChatHistoryGetPaginatedDto} [chatHistoryGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdHistoryGetPinnedPost(nexusOpsTenant: string, chatId: string, chatHistoryGetPaginatedDto?: ChatHistoryGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfChatHistoryItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdHistoryGetPinnedPost(nexusOpsTenant, chatId, chatHistoryGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatHistoryApi.apiV1ChatsChatIdHistoryGetPinnedPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {ChatHistoryGetPaginatedDto} [chatHistoryGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdHistoryGetPost(nexusOpsTenant: string, chatId: string, chatHistoryGetPaginatedDto?: ChatHistoryGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfChatHistoryItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdHistoryGetPost(nexusOpsTenant, chatId, chatHistoryGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatHistoryApi.apiV1ChatsChatIdHistoryGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdHistoryItemsItemIdPinPost(nexusOpsTenant: string, chatId: string, itemId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatHistoryItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdHistoryItemsItemIdPinPost(nexusOpsTenant, chatId, itemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatHistoryApi.apiV1ChatsChatIdHistoryItemsItemIdPinPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdHistoryItemsItemIdUnpinPost(nexusOpsTenant: string, chatId: string, itemId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdHistoryItemsItemIdUnpinPost(nexusOpsTenant, chatId, itemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatHistoryApi.apiV1ChatsChatIdHistoryItemsItemIdUnpinPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChatHistoryApi - factory interface
 * @export
 */
export const ChatHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {ChatHistoryApiApiV1ChatsChatIdHistoryGetPinnedPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdHistoryGetPinnedPost(requestParameters: ChatHistoryApiApiV1ChatsChatIdHistoryGetPinnedPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfChatHistoryItemDto> {
            return localVarFp.apiV1ChatsChatIdHistoryGetPinnedPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.chatHistoryGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatHistoryApiApiV1ChatsChatIdHistoryGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdHistoryGetPost(requestParameters: ChatHistoryApiApiV1ChatsChatIdHistoryGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfChatHistoryItemDto> {
            return localVarFp.apiV1ChatsChatIdHistoryGetPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.chatHistoryGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdPinPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdHistoryItemsItemIdPinPost(requestParameters: ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdPinPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatHistoryItemDto> {
            return localVarFp.apiV1ChatsChatIdHistoryItemsItemIdPinPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdUnpinPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdHistoryItemsItemIdUnpinPost(requestParameters: ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdUnpinPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1ChatsChatIdHistoryItemsItemIdUnpinPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.itemId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ChatsChatIdHistoryGetPinnedPost operation in ChatHistoryApi.
 * @export
 * @interface ChatHistoryApiApiV1ChatsChatIdHistoryGetPinnedPostRequest
 */
export interface ChatHistoryApiApiV1ChatsChatIdHistoryGetPinnedPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatHistoryApiApiV1ChatsChatIdHistoryGetPinnedPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatHistoryApiApiV1ChatsChatIdHistoryGetPinnedPost
     */
    readonly chatId: string

    /**
     * 
     * @type {ChatHistoryGetPaginatedDto}
     * @memberof ChatHistoryApiApiV1ChatsChatIdHistoryGetPinnedPost
     */
    readonly chatHistoryGetPaginatedDto?: ChatHistoryGetPaginatedDto
}

/**
 * Request parameters for apiV1ChatsChatIdHistoryGetPost operation in ChatHistoryApi.
 * @export
 * @interface ChatHistoryApiApiV1ChatsChatIdHistoryGetPostRequest
 */
export interface ChatHistoryApiApiV1ChatsChatIdHistoryGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatHistoryApiApiV1ChatsChatIdHistoryGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatHistoryApiApiV1ChatsChatIdHistoryGetPost
     */
    readonly chatId: string

    /**
     * 
     * @type {ChatHistoryGetPaginatedDto}
     * @memberof ChatHistoryApiApiV1ChatsChatIdHistoryGetPost
     */
    readonly chatHistoryGetPaginatedDto?: ChatHistoryGetPaginatedDto
}

/**
 * Request parameters for apiV1ChatsChatIdHistoryItemsItemIdPinPost operation in ChatHistoryApi.
 * @export
 * @interface ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdPinPostRequest
 */
export interface ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdPinPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdPinPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdPinPost
     */
    readonly chatId: string

    /**
     * 
     * @type {string}
     * @memberof ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdPinPost
     */
    readonly itemId: string
}

/**
 * Request parameters for apiV1ChatsChatIdHistoryItemsItemIdUnpinPost operation in ChatHistoryApi.
 * @export
 * @interface ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdUnpinPostRequest
 */
export interface ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdUnpinPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdUnpinPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdUnpinPost
     */
    readonly chatId: string

    /**
     * 
     * @type {string}
     * @memberof ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdUnpinPost
     */
    readonly itemId: string
}

/**
 * ChatHistoryApi - object-oriented interface
 * @export
 * @class ChatHistoryApi
 * @extends {BaseAPI}
 */
export class ChatHistoryApi extends BaseAPI {
    /**
     * 
     * @param {ChatHistoryApiApiV1ChatsChatIdHistoryGetPinnedPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public apiV1ChatsChatIdHistoryGetPinnedPost(requestParameters: ChatHistoryApiApiV1ChatsChatIdHistoryGetPinnedPostRequest, options?: RawAxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).apiV1ChatsChatIdHistoryGetPinnedPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.chatHistoryGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatHistoryApiApiV1ChatsChatIdHistoryGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public apiV1ChatsChatIdHistoryGetPost(requestParameters: ChatHistoryApiApiV1ChatsChatIdHistoryGetPostRequest, options?: RawAxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).apiV1ChatsChatIdHistoryGetPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.chatHistoryGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdPinPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public apiV1ChatsChatIdHistoryItemsItemIdPinPost(requestParameters: ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdPinPostRequest, options?: RawAxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).apiV1ChatsChatIdHistoryItemsItemIdPinPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdUnpinPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public apiV1ChatsChatIdHistoryItemsItemIdUnpinPost(requestParameters: ChatHistoryApiApiV1ChatsChatIdHistoryItemsItemIdUnpinPostRequest, options?: RawAxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).apiV1ChatsChatIdHistoryItemsItemIdUnpinPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChatMessagesApi - axios parameter creator
 * @export
 */
export const ChatMessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {ChatMessageGetPaginatedDto} [chatMessageGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesGetPost: async (nexusOpsTenant: string, chatId: string, chatMessageGetPaginatedDto?: ChatMessageGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesGetPost', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/messages/get`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatMessageGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {MarkChatMessagesAsReadDto} [markChatMessagesAsReadDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesMarkAsReadPost: async (nexusOpsTenant: string, chatId: string, markChatMessagesAsReadDto?: MarkChatMessagesAsReadDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMarkAsReadPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMarkAsReadPost', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/messages/mark-as-read`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markChatMessagesAsReadDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesMessageIdAcknowledgePost: async (nexusOpsTenant: string, chatId: string, messageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMessageIdAcknowledgePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMessageIdAcknowledgePost', 'chatId', chatId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMessageIdAcknowledgePost', 'messageId', messageId)
            const localVarPath = `/api/v1/chats/{chatId}/messages/{messageId}/acknowledge`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesMessageIdDelete: async (nexusOpsTenant: string, chatId: string, messageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMessageIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMessageIdDelete', 'chatId', chatId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMessageIdDelete', 'messageId', messageId)
            const localVarPath = `/api/v1/chats/{chatId}/messages/{messageId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesMessageIdGet: async (nexusOpsTenant: string, chatId: string, messageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMessageIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMessageIdGet', 'chatId', chatId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMessageIdGet', 'messageId', messageId)
            const localVarPath = `/api/v1/chats/{chatId}/messages/{messageId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} messageId 
         * @param {UpdateChatMessageDto} [updateChatMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesMessageIdPut: async (nexusOpsTenant: string, chatId: string, messageId: string, updateChatMessageDto?: UpdateChatMessageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMessageIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMessageIdPut', 'chatId', chatId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesMessageIdPut', 'messageId', messageId)
            const localVarPath = `/api/v1/chats/{chatId}/messages/{messageId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChatMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {SendChatMessageDto} [sendChatMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesPost: async (nexusOpsTenant: string, chatId: string, sendChatMessageDto?: SendChatMessageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdMessagesPost', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/messages`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendChatMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatMessagesApi - functional programming interface
 * @export
 */
export const ChatMessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatMessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {ChatMessageGetPaginatedDto} [chatMessageGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdMessagesGetPost(nexusOpsTenant: string, chatId: string, chatMessageGetPaginatedDto?: ChatMessageGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfChatMessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdMessagesGetPost(nexusOpsTenant, chatId, chatMessageGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatMessagesApi.apiV1ChatsChatIdMessagesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {MarkChatMessagesAsReadDto} [markChatMessagesAsReadDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdMessagesMarkAsReadPost(nexusOpsTenant: string, chatId: string, markChatMessagesAsReadDto?: MarkChatMessagesAsReadDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkChatMessagesAsReadResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdMessagesMarkAsReadPost(nexusOpsTenant, chatId, markChatMessagesAsReadDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatMessagesApi.apiV1ChatsChatIdMessagesMarkAsReadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdMessagesMessageIdAcknowledgePost(nexusOpsTenant: string, chatId: string, messageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdMessagesMessageIdAcknowledgePost(nexusOpsTenant, chatId, messageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatMessagesApi.apiV1ChatsChatIdMessagesMessageIdAcknowledgePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdMessagesMessageIdDelete(nexusOpsTenant: string, chatId: string, messageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdMessagesMessageIdDelete(nexusOpsTenant, chatId, messageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatMessagesApi.apiV1ChatsChatIdMessagesMessageIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdMessagesMessageIdGet(nexusOpsTenant: string, chatId: string, messageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdMessagesMessageIdGet(nexusOpsTenant, chatId, messageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatMessagesApi.apiV1ChatsChatIdMessagesMessageIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} messageId 
         * @param {UpdateChatMessageDto} [updateChatMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdMessagesMessageIdPut(nexusOpsTenant: string, chatId: string, messageId: string, updateChatMessageDto?: UpdateChatMessageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdMessagesMessageIdPut(nexusOpsTenant, chatId, messageId, updateChatMessageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatMessagesApi.apiV1ChatsChatIdMessagesMessageIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {SendChatMessageDto} [sendChatMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdMessagesPost(nexusOpsTenant: string, chatId: string, sendChatMessageDto?: SendChatMessageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdMessagesPost(nexusOpsTenant, chatId, sendChatMessageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatMessagesApi.apiV1ChatsChatIdMessagesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChatMessagesApi - factory interface
 * @export
 */
export const ChatMessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatMessagesApiFp(configuration)
    return {
        /**
         * 
         * @param {ChatMessagesApiApiV1ChatsChatIdMessagesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesGetPost(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfChatMessageDto> {
            return localVarFp.apiV1ChatsChatIdMessagesGetPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.chatMessageGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatMessagesApiApiV1ChatsChatIdMessagesMarkAsReadPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesMarkAsReadPost(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesMarkAsReadPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<MarkChatMessagesAsReadResultDto> {
            return localVarFp.apiV1ChatsChatIdMessagesMarkAsReadPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.markChatMessagesAsReadDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdAcknowledgePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesMessageIdAcknowledgePost(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdAcknowledgePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatMessageDto> {
            return localVarFp.apiV1ChatsChatIdMessagesMessageIdAcknowledgePost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesMessageIdDelete(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1ChatsChatIdMessagesMessageIdDelete(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesMessageIdGet(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatMessageDto> {
            return localVarFp.apiV1ChatsChatIdMessagesMessageIdGet(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesMessageIdPut(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatMessageDto> {
            return localVarFp.apiV1ChatsChatIdMessagesMessageIdPut(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.messageId, requestParameters.updateChatMessageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatMessagesApiApiV1ChatsChatIdMessagesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdMessagesPost(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatMessageDto> {
            return localVarFp.apiV1ChatsChatIdMessagesPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.sendChatMessageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ChatsChatIdMessagesGetPost operation in ChatMessagesApi.
 * @export
 * @interface ChatMessagesApiApiV1ChatsChatIdMessagesGetPostRequest
 */
export interface ChatMessagesApiApiV1ChatsChatIdMessagesGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesGetPost
     */
    readonly chatId: string

    /**
     * 
     * @type {ChatMessageGetPaginatedDto}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesGetPost
     */
    readonly chatMessageGetPaginatedDto?: ChatMessageGetPaginatedDto
}

/**
 * Request parameters for apiV1ChatsChatIdMessagesMarkAsReadPost operation in ChatMessagesApi.
 * @export
 * @interface ChatMessagesApiApiV1ChatsChatIdMessagesMarkAsReadPostRequest
 */
export interface ChatMessagesApiApiV1ChatsChatIdMessagesMarkAsReadPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMarkAsReadPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMarkAsReadPost
     */
    readonly chatId: string

    /**
     * 
     * @type {MarkChatMessagesAsReadDto}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMarkAsReadPost
     */
    readonly markChatMessagesAsReadDto?: MarkChatMessagesAsReadDto
}

/**
 * Request parameters for apiV1ChatsChatIdMessagesMessageIdAcknowledgePost operation in ChatMessagesApi.
 * @export
 * @interface ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdAcknowledgePostRequest
 */
export interface ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdAcknowledgePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdAcknowledgePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdAcknowledgePost
     */
    readonly chatId: string

    /**
     * 
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdAcknowledgePost
     */
    readonly messageId: string
}

/**
 * Request parameters for apiV1ChatsChatIdMessagesMessageIdDelete operation in ChatMessagesApi.
 * @export
 * @interface ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdDeleteRequest
 */
export interface ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdDelete
     */
    readonly chatId: string

    /**
     * 
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdDelete
     */
    readonly messageId: string
}

/**
 * Request parameters for apiV1ChatsChatIdMessagesMessageIdGet operation in ChatMessagesApi.
 * @export
 * @interface ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdGetRequest
 */
export interface ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdGet
     */
    readonly chatId: string

    /**
     * 
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdGet
     */
    readonly messageId: string
}

/**
 * Request parameters for apiV1ChatsChatIdMessagesMessageIdPut operation in ChatMessagesApi.
 * @export
 * @interface ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdPutRequest
 */
export interface ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdPut
     */
    readonly chatId: string

    /**
     * 
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdPut
     */
    readonly messageId: string

    /**
     * 
     * @type {UpdateChatMessageDto}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdPut
     */
    readonly updateChatMessageDto?: UpdateChatMessageDto
}

/**
 * Request parameters for apiV1ChatsChatIdMessagesPost operation in ChatMessagesApi.
 * @export
 * @interface ChatMessagesApiApiV1ChatsChatIdMessagesPostRequest
 */
export interface ChatMessagesApiApiV1ChatsChatIdMessagesPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesPost
     */
    readonly chatId: string

    /**
     * 
     * @type {SendChatMessageDto}
     * @memberof ChatMessagesApiApiV1ChatsChatIdMessagesPost
     */
    readonly sendChatMessageDto?: SendChatMessageDto
}

/**
 * ChatMessagesApi - object-oriented interface
 * @export
 * @class ChatMessagesApi
 * @extends {BaseAPI}
 */
export class ChatMessagesApi extends BaseAPI {
    /**
     * 
     * @param {ChatMessagesApiApiV1ChatsChatIdMessagesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatMessagesApi
     */
    public apiV1ChatsChatIdMessagesGetPost(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesGetPostRequest, options?: RawAxiosRequestConfig) {
        return ChatMessagesApiFp(this.configuration).apiV1ChatsChatIdMessagesGetPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.chatMessageGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatMessagesApiApiV1ChatsChatIdMessagesMarkAsReadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatMessagesApi
     */
    public apiV1ChatsChatIdMessagesMarkAsReadPost(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesMarkAsReadPostRequest, options?: RawAxiosRequestConfig) {
        return ChatMessagesApiFp(this.configuration).apiV1ChatsChatIdMessagesMarkAsReadPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.markChatMessagesAsReadDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdAcknowledgePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatMessagesApi
     */
    public apiV1ChatsChatIdMessagesMessageIdAcknowledgePost(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdAcknowledgePostRequest, options?: RawAxiosRequestConfig) {
        return ChatMessagesApiFp(this.configuration).apiV1ChatsChatIdMessagesMessageIdAcknowledgePost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatMessagesApi
     */
    public apiV1ChatsChatIdMessagesMessageIdDelete(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ChatMessagesApiFp(this.configuration).apiV1ChatsChatIdMessagesMessageIdDelete(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatMessagesApi
     */
    public apiV1ChatsChatIdMessagesMessageIdGet(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdGetRequest, options?: RawAxiosRequestConfig) {
        return ChatMessagesApiFp(this.configuration).apiV1ChatsChatIdMessagesMessageIdGet(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatMessagesApi
     */
    public apiV1ChatsChatIdMessagesMessageIdPut(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesMessageIdPutRequest, options?: RawAxiosRequestConfig) {
        return ChatMessagesApiFp(this.configuration).apiV1ChatsChatIdMessagesMessageIdPut(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.messageId, requestParameters.updateChatMessageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatMessagesApiApiV1ChatsChatIdMessagesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatMessagesApi
     */
    public apiV1ChatsChatIdMessagesPost(requestParameters: ChatMessagesApiApiV1ChatsChatIdMessagesPostRequest, options?: RawAxiosRequestConfig) {
        return ChatMessagesApiFp(this.configuration).apiV1ChatsChatIdMessagesPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.sendChatMessageDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChatParticipantsApi - axios parameter creator
 * @export
 */
export const ChatParticipantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsCandidatesGet: async (nexusOpsTenant: string, chatId: string, offset?: number, limit?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsCandidatesGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsCandidatesGet', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/participants/candidates`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {ChatParticipantGetPaginatedDto} [chatParticipantGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsGetPost: async (nexusOpsTenant: string, chatId: string, chatParticipantGetPaginatedDto?: ChatParticipantGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsGetPost', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/participants/get`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatParticipantGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsJoinPost: async (nexusOpsTenant: string, chatId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsJoinPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsJoinPost', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/participants/join`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsLeavePost: async (nexusOpsTenant: string, chatId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsLeavePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsLeavePost', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/participants/leave`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsMeGet: async (nexusOpsTenant: string, chatId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsMeGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsMeGet', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/participants/me`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsParticipantIdDelete: async (nexusOpsTenant: string, chatId: string, participantId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsParticipantIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsParticipantIdDelete', 'chatId', chatId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsParticipantIdDelete', 'participantId', participantId)
            const localVarPath = `/api/v1/chats/{chatId}/participants/{participantId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsParticipantIdGet: async (nexusOpsTenant: string, chatId: string, participantId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsParticipantIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsParticipantIdGet', 'chatId', chatId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsParticipantIdGet', 'participantId', participantId)
            const localVarPath = `/api/v1/chats/{chatId}/participants/{participantId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {AddChatParticipantsDto} [addChatParticipantsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsPost: async (nexusOpsTenant: string, chatId: string, addChatParticipantsDto?: AddChatParticipantsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdParticipantsPost', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/participants`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addChatParticipantsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatParticipantsApi - functional programming interface
 * @export
 */
export const ChatParticipantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatParticipantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdParticipantsCandidatesGet(nexusOpsTenant: string, chatId: string, offset?: number, limit?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfChatParticipantCandidateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdParticipantsCandidatesGet(nexusOpsTenant, chatId, offset, limit, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatParticipantsApi.apiV1ChatsChatIdParticipantsCandidatesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {ChatParticipantGetPaginatedDto} [chatParticipantGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdParticipantsGetPost(nexusOpsTenant: string, chatId: string, chatParticipantGetPaginatedDto?: ChatParticipantGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfChatParticipantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdParticipantsGetPost(nexusOpsTenant, chatId, chatParticipantGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatParticipantsApi.apiV1ChatsChatIdParticipantsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdParticipantsJoinPost(nexusOpsTenant: string, chatId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdParticipantsJoinPost(nexusOpsTenant, chatId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatParticipantsApi.apiV1ChatsChatIdParticipantsJoinPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdParticipantsLeavePost(nexusOpsTenant: string, chatId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatParticipantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdParticipantsLeavePost(nexusOpsTenant, chatId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatParticipantsApi.apiV1ChatsChatIdParticipantsLeavePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdParticipantsMeGet(nexusOpsTenant: string, chatId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfChatParticipantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdParticipantsMeGet(nexusOpsTenant, chatId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatParticipantsApi.apiV1ChatsChatIdParticipantsMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdParticipantsParticipantIdDelete(nexusOpsTenant: string, chatId: string, participantId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdParticipantsParticipantIdDelete(nexusOpsTenant, chatId, participantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatParticipantsApi.apiV1ChatsChatIdParticipantsParticipantIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdParticipantsParticipantIdGet(nexusOpsTenant: string, chatId: string, participantId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatParticipantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdParticipantsParticipantIdGet(nexusOpsTenant, chatId, participantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatParticipantsApi.apiV1ChatsChatIdParticipantsParticipantIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {AddChatParticipantsDto} [addChatParticipantsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdParticipantsPost(nexusOpsTenant: string, chatId: string, addChatParticipantsDto?: AddChatParticipantsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatParticipantDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdParticipantsPost(nexusOpsTenant, chatId, addChatParticipantsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatParticipantsApi.apiV1ChatsChatIdParticipantsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChatParticipantsApi - factory interface
 * @export
 */
export const ChatParticipantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatParticipantsApiFp(configuration)
    return {
        /**
         * 
         * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsCandidatesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsCandidatesGet(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsCandidatesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfChatParticipantCandidateDto> {
            return localVarFp.apiV1ChatsChatIdParticipantsCandidatesGet(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.offset, requestParameters.limit, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsGetPost(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfChatParticipantDto> {
            return localVarFp.apiV1ChatsChatIdParticipantsGetPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.chatParticipantGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsJoinPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsJoinPost(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsJoinPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatDto> {
            return localVarFp.apiV1ChatsChatIdParticipantsJoinPost(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsLeavePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsLeavePost(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsLeavePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatParticipantDto> {
            return localVarFp.apiV1ChatsChatIdParticipantsLeavePost(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsMeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsMeGet(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsMeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfChatParticipantDto> {
            return localVarFp.apiV1ChatsChatIdParticipantsMeGet(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsParticipantIdDelete(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1ChatsChatIdParticipantsParticipantIdDelete(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsParticipantIdGet(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatParticipantDto> {
            return localVarFp.apiV1ChatsChatIdParticipantsParticipantIdGet(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdParticipantsPost(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ChatParticipantDto>> {
            return localVarFp.apiV1ChatsChatIdParticipantsPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.addChatParticipantsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ChatsChatIdParticipantsCandidatesGet operation in ChatParticipantsApi.
 * @export
 * @interface ChatParticipantsApiApiV1ChatsChatIdParticipantsCandidatesGetRequest
 */
export interface ChatParticipantsApiApiV1ChatsChatIdParticipantsCandidatesGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsCandidatesGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsCandidatesGet
     */
    readonly chatId: string

    /**
     * 
     * @type {number}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsCandidatesGet
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsCandidatesGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsCandidatesGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiV1ChatsChatIdParticipantsGetPost operation in ChatParticipantsApi.
 * @export
 * @interface ChatParticipantsApiApiV1ChatsChatIdParticipantsGetPostRequest
 */
export interface ChatParticipantsApiApiV1ChatsChatIdParticipantsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsGetPost
     */
    readonly chatId: string

    /**
     * 
     * @type {ChatParticipantGetPaginatedDto}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsGetPost
     */
    readonly chatParticipantGetPaginatedDto?: ChatParticipantGetPaginatedDto
}

/**
 * Request parameters for apiV1ChatsChatIdParticipantsJoinPost operation in ChatParticipantsApi.
 * @export
 * @interface ChatParticipantsApiApiV1ChatsChatIdParticipantsJoinPostRequest
 */
export interface ChatParticipantsApiApiV1ChatsChatIdParticipantsJoinPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsJoinPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsJoinPost
     */
    readonly chatId: string
}

/**
 * Request parameters for apiV1ChatsChatIdParticipantsLeavePost operation in ChatParticipantsApi.
 * @export
 * @interface ChatParticipantsApiApiV1ChatsChatIdParticipantsLeavePostRequest
 */
export interface ChatParticipantsApiApiV1ChatsChatIdParticipantsLeavePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsLeavePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsLeavePost
     */
    readonly chatId: string
}

/**
 * Request parameters for apiV1ChatsChatIdParticipantsMeGet operation in ChatParticipantsApi.
 * @export
 * @interface ChatParticipantsApiApiV1ChatsChatIdParticipantsMeGetRequest
 */
export interface ChatParticipantsApiApiV1ChatsChatIdParticipantsMeGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsMeGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsMeGet
     */
    readonly chatId: string
}

/**
 * Request parameters for apiV1ChatsChatIdParticipantsParticipantIdDelete operation in ChatParticipantsApi.
 * @export
 * @interface ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdDeleteRequest
 */
export interface ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdDelete
     */
    readonly chatId: string

    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdDelete
     */
    readonly participantId: string
}

/**
 * Request parameters for apiV1ChatsChatIdParticipantsParticipantIdGet operation in ChatParticipantsApi.
 * @export
 * @interface ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdGetRequest
 */
export interface ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdGet
     */
    readonly chatId: string

    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdGet
     */
    readonly participantId: string
}

/**
 * Request parameters for apiV1ChatsChatIdParticipantsPost operation in ChatParticipantsApi.
 * @export
 * @interface ChatParticipantsApiApiV1ChatsChatIdParticipantsPostRequest
 */
export interface ChatParticipantsApiApiV1ChatsChatIdParticipantsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsPost
     */
    readonly chatId: string

    /**
     * 
     * @type {AddChatParticipantsDto}
     * @memberof ChatParticipantsApiApiV1ChatsChatIdParticipantsPost
     */
    readonly addChatParticipantsDto?: AddChatParticipantsDto
}

/**
 * ChatParticipantsApi - object-oriented interface
 * @export
 * @class ChatParticipantsApi
 * @extends {BaseAPI}
 */
export class ChatParticipantsApi extends BaseAPI {
    /**
     * 
     * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsCandidatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatParticipantsApi
     */
    public apiV1ChatsChatIdParticipantsCandidatesGet(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsCandidatesGetRequest, options?: RawAxiosRequestConfig) {
        return ChatParticipantsApiFp(this.configuration).apiV1ChatsChatIdParticipantsCandidatesGet(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.offset, requestParameters.limit, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatParticipantsApi
     */
    public apiV1ChatsChatIdParticipantsGetPost(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsGetPostRequest, options?: RawAxiosRequestConfig) {
        return ChatParticipantsApiFp(this.configuration).apiV1ChatsChatIdParticipantsGetPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.chatParticipantGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsJoinPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatParticipantsApi
     */
    public apiV1ChatsChatIdParticipantsJoinPost(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsJoinPostRequest, options?: RawAxiosRequestConfig) {
        return ChatParticipantsApiFp(this.configuration).apiV1ChatsChatIdParticipantsJoinPost(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsLeavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatParticipantsApi
     */
    public apiV1ChatsChatIdParticipantsLeavePost(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsLeavePostRequest, options?: RawAxiosRequestConfig) {
        return ChatParticipantsApiFp(this.configuration).apiV1ChatsChatIdParticipantsLeavePost(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsMeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatParticipantsApi
     */
    public apiV1ChatsChatIdParticipantsMeGet(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsMeGetRequest, options?: RawAxiosRequestConfig) {
        return ChatParticipantsApiFp(this.configuration).apiV1ChatsChatIdParticipantsMeGet(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatParticipantsApi
     */
    public apiV1ChatsChatIdParticipantsParticipantIdDelete(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ChatParticipantsApiFp(this.configuration).apiV1ChatsChatIdParticipantsParticipantIdDelete(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatParticipantsApi
     */
    public apiV1ChatsChatIdParticipantsParticipantIdGet(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsParticipantIdGetRequest, options?: RawAxiosRequestConfig) {
        return ChatParticipantsApiFp(this.configuration).apiV1ChatsChatIdParticipantsParticipantIdGet(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatParticipantsApiApiV1ChatsChatIdParticipantsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatParticipantsApi
     */
    public apiV1ChatsChatIdParticipantsPost(requestParameters: ChatParticipantsApiApiV1ChatsChatIdParticipantsPostRequest, options?: RawAxiosRequestConfig) {
        return ChatParticipantsApiFp(this.configuration).apiV1ChatsChatIdParticipantsPost(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.addChatParticipantsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChatUserSettingsApi - axios parameter creator
 * @export
 */
export const ChatUserSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatUserSettingsChatIdGet: async (nexusOpsTenant: string, chatId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatUserSettingsChatIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatUserSettingsChatIdGet', 'chatId', chatId)
            const localVarPath = `/api/v1/chat-user-settings/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {UpdateChatUserSettingsDto} [updateChatUserSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatUserSettingsPut: async (nexusOpsTenant: string, updateChatUserSettingsDto?: UpdateChatUserSettingsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatUserSettingsPut', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/chat-user-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChatUserSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatUserSettingsApi - functional programming interface
 * @export
 */
export const ChatUserSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatUserSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatUserSettingsChatIdGet(nexusOpsTenant: string, chatId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChatUserSettingsResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatUserSettingsChatIdGet(nexusOpsTenant, chatId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatUserSettingsApi.apiV1ChatUserSettingsChatIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {UpdateChatUserSettingsDto} [updateChatUserSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatUserSettingsPut(nexusOpsTenant: string, updateChatUserSettingsDto?: UpdateChatUserSettingsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChatUserSettingsResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatUserSettingsPut(nexusOpsTenant, updateChatUserSettingsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatUserSettingsApi.apiV1ChatUserSettingsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChatUserSettingsApi - factory interface
 * @export
 */
export const ChatUserSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatUserSettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {ChatUserSettingsApiApiV1ChatUserSettingsChatIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatUserSettingsChatIdGet(requestParameters: ChatUserSettingsApiApiV1ChatUserSettingsChatIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetChatUserSettingsResultDto> {
            return localVarFp.apiV1ChatUserSettingsChatIdGet(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatUserSettingsApiApiV1ChatUserSettingsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatUserSettingsPut(requestParameters: ChatUserSettingsApiApiV1ChatUserSettingsPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetChatUserSettingsResultDto> {
            return localVarFp.apiV1ChatUserSettingsPut(requestParameters.nexusOpsTenant, requestParameters.updateChatUserSettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ChatUserSettingsChatIdGet operation in ChatUserSettingsApi.
 * @export
 * @interface ChatUserSettingsApiApiV1ChatUserSettingsChatIdGetRequest
 */
export interface ChatUserSettingsApiApiV1ChatUserSettingsChatIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatUserSettingsApiApiV1ChatUserSettingsChatIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsApiApiV1ChatUserSettingsChatIdGet
     */
    readonly chatId: string
}

/**
 * Request parameters for apiV1ChatUserSettingsPut operation in ChatUserSettingsApi.
 * @export
 * @interface ChatUserSettingsApiApiV1ChatUserSettingsPutRequest
 */
export interface ChatUserSettingsApiApiV1ChatUserSettingsPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatUserSettingsApiApiV1ChatUserSettingsPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {UpdateChatUserSettingsDto}
     * @memberof ChatUserSettingsApiApiV1ChatUserSettingsPut
     */
    readonly updateChatUserSettingsDto?: UpdateChatUserSettingsDto
}

/**
 * ChatUserSettingsApi - object-oriented interface
 * @export
 * @class ChatUserSettingsApi
 * @extends {BaseAPI}
 */
export class ChatUserSettingsApi extends BaseAPI {
    /**
     * 
     * @param {ChatUserSettingsApiApiV1ChatUserSettingsChatIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatUserSettingsApi
     */
    public apiV1ChatUserSettingsChatIdGet(requestParameters: ChatUserSettingsApiApiV1ChatUserSettingsChatIdGetRequest, options?: RawAxiosRequestConfig) {
        return ChatUserSettingsApiFp(this.configuration).apiV1ChatUserSettingsChatIdGet(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatUserSettingsApiApiV1ChatUserSettingsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatUserSettingsApi
     */
    public apiV1ChatUserSettingsPut(requestParameters: ChatUserSettingsApiApiV1ChatUserSettingsPutRequest, options?: RawAxiosRequestConfig) {
        return ChatUserSettingsApiFp(this.configuration).apiV1ChatUserSettingsPut(requestParameters.nexusOpsTenant, requestParameters.updateChatUserSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChatsApi - axios parameter creator
 * @export
 */
export const ChatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} [identifier] 
         * @param {string} [subIdentifier] 
         * @param {GeneralScopeType} [type] 
         * @param {boolean} [isResolved] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsByScopeGet: async (nexusOpsTenant: string, identifier?: string, subIdentifier?: string, type?: GeneralScopeType, isResolved?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsByScopeGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/chats/by-scope`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (subIdentifier !== undefined) {
                localVarQueryParameter['subIdentifier'] = subIdentifier;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (isResolved !== undefined) {
                localVarQueryParameter['isResolved'] = isResolved;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdAcknowledgePost: async (nexusOpsTenant: string, chatId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdAcknowledgePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdAcknowledgePost', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/acknowledge`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdDelete: async (nexusOpsTenant: string, chatId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdDelete', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdGet: async (nexusOpsTenant: string, chatId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdGet', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {UpdateChatDto} [updateChatDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdPut: async (nexusOpsTenant: string, chatId: string, updateChatDto?: UpdateChatDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdPut', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChatDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdReopenPost: async (nexusOpsTenant: string, chatId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdReopenPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdReopenPost', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/reopen`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdResolvePost: async (nexusOpsTenant: string, chatId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsChatIdResolvePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1ChatsChatIdResolvePost', 'chatId', chatId)
            const localVarPath = `/api/v1/chats/{chatId}/resolve`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GetOrCreateCommentChatDto} [getOrCreateCommentChatDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsCommentGetOrCreatePost: async (nexusOpsTenant: string, getOrCreateCommentChatDto?: GetOrCreateCommentChatDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsCommentGetOrCreatePost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/chats/comment/get-or-create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOrCreateCommentChatDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ChatType} [type] 
         * @param {Array<ChatType>} [types] 
         * @param {boolean} [isResolved] 
         * @param {string} [sortBy] Comma-separated list of fields to sort by.
         * @param {string} [sortOrder] Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsGet: async (nexusOpsTenant: string, offset?: number, limit?: number, search?: string, type?: ChatType, types?: Array<ChatType>, isResolved?: boolean, sortBy?: string, sortOrder?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/chats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (isResolved !== undefined) {
                localVarQueryParameter['isResolved'] = isResolved;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateGroupChatDto} [createGroupChatDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsGroupPost: async (nexusOpsTenant: string, createGroupChatDto?: CreateGroupChatDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsGroupPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/chats/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGroupChatDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralScopeRequestDto} [generalScopeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsNegotiationGetOrCreatePost: async (nexusOpsTenant: string, generalScopeRequestDto?: GeneralScopeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsNegotiationGetOrCreatePost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/chats/negotiation/get-or-create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalScopeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreatePersonalChatDto} [createPersonalChatDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsPersonalPost: async (nexusOpsTenant: string, createPersonalChatDto?: CreatePersonalChatDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ChatsPersonalPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/chats/personal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPersonalChatDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatsApi - functional programming interface
 * @export
 */
export const ChatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} [identifier] 
         * @param {string} [subIdentifier] 
         * @param {GeneralScopeType} [type] 
         * @param {boolean} [isResolved] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsByScopeGet(nexusOpsTenant: string, identifier?: string, subIdentifier?: string, type?: GeneralScopeType, isResolved?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsByScopeGet(nexusOpsTenant, identifier, subIdentifier, type, isResolved, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.apiV1ChatsByScopeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdAcknowledgePost(nexusOpsTenant: string, chatId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatAcknowledgeResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdAcknowledgePost(nexusOpsTenant, chatId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.apiV1ChatsChatIdAcknowledgePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdDelete(nexusOpsTenant: string, chatId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdDelete(nexusOpsTenant, chatId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.apiV1ChatsChatIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdGet(nexusOpsTenant: string, chatId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdGet(nexusOpsTenant, chatId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.apiV1ChatsChatIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {UpdateChatDto} [updateChatDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdPut(nexusOpsTenant: string, chatId: string, updateChatDto?: UpdateChatDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdPut(nexusOpsTenant, chatId, updateChatDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.apiV1ChatsChatIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdReopenPost(nexusOpsTenant: string, chatId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdReopenPost(nexusOpsTenant, chatId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.apiV1ChatsChatIdReopenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsChatIdResolvePost(nexusOpsTenant: string, chatId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsChatIdResolvePost(nexusOpsTenant, chatId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.apiV1ChatsChatIdResolvePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GetOrCreateCommentChatDto} [getOrCreateCommentChatDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsCommentGetOrCreatePost(nexusOpsTenant: string, getOrCreateCommentChatDto?: GetOrCreateCommentChatDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsCommentGetOrCreatePost(nexusOpsTenant, getOrCreateCommentChatDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.apiV1ChatsCommentGetOrCreatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ChatType} [type] 
         * @param {Array<ChatType>} [types] 
         * @param {boolean} [isResolved] 
         * @param {string} [sortBy] Comma-separated list of fields to sort by.
         * @param {string} [sortOrder] Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsGet(nexusOpsTenant: string, offset?: number, limit?: number, search?: string, type?: ChatType, types?: Array<ChatType>, isResolved?: boolean, sortBy?: string, sortOrder?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfChatDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsGet(nexusOpsTenant, offset, limit, search, type, types, isResolved, sortBy, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.apiV1ChatsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateGroupChatDto} [createGroupChatDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsGroupPost(nexusOpsTenant: string, createGroupChatDto?: CreateGroupChatDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsGroupPost(nexusOpsTenant, createGroupChatDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.apiV1ChatsGroupPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralScopeRequestDto} [generalScopeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsNegotiationGetOrCreatePost(nexusOpsTenant: string, generalScopeRequestDto?: GeneralScopeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsNegotiationGetOrCreatePost(nexusOpsTenant, generalScopeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.apiV1ChatsNegotiationGetOrCreatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreatePersonalChatDto} [createPersonalChatDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChatsPersonalPost(nexusOpsTenant: string, createPersonalChatDto?: CreatePersonalChatDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChatsPersonalPost(nexusOpsTenant, createPersonalChatDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatsApi.apiV1ChatsPersonalPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChatsApi - factory interface
 * @export
 */
export const ChatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatsApiFp(configuration)
    return {
        /**
         * 
         * @param {ChatsApiApiV1ChatsByScopeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsByScopeGet(requestParameters: ChatsApiApiV1ChatsByScopeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatDto> {
            return localVarFp.apiV1ChatsByScopeGet(requestParameters.nexusOpsTenant, requestParameters.identifier, requestParameters.subIdentifier, requestParameters.type, requestParameters.isResolved, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatsApiApiV1ChatsChatIdAcknowledgePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdAcknowledgePost(requestParameters: ChatsApiApiV1ChatsChatIdAcknowledgePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatAcknowledgeResultDto> {
            return localVarFp.apiV1ChatsChatIdAcknowledgePost(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatsApiApiV1ChatsChatIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdDelete(requestParameters: ChatsApiApiV1ChatsChatIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1ChatsChatIdDelete(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatsApiApiV1ChatsChatIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdGet(requestParameters: ChatsApiApiV1ChatsChatIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatDto> {
            return localVarFp.apiV1ChatsChatIdGet(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatsApiApiV1ChatsChatIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdPut(requestParameters: ChatsApiApiV1ChatsChatIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatDto> {
            return localVarFp.apiV1ChatsChatIdPut(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.updateChatDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatsApiApiV1ChatsChatIdReopenPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdReopenPost(requestParameters: ChatsApiApiV1ChatsChatIdReopenPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatDto> {
            return localVarFp.apiV1ChatsChatIdReopenPost(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatsApiApiV1ChatsChatIdResolvePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsChatIdResolvePost(requestParameters: ChatsApiApiV1ChatsChatIdResolvePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatDto> {
            return localVarFp.apiV1ChatsChatIdResolvePost(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatsApiApiV1ChatsCommentGetOrCreatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsCommentGetOrCreatePost(requestParameters: ChatsApiApiV1ChatsCommentGetOrCreatePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatDto> {
            return localVarFp.apiV1ChatsCommentGetOrCreatePost(requestParameters.nexusOpsTenant, requestParameters.getOrCreateCommentChatDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatsApiApiV1ChatsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsGet(requestParameters: ChatsApiApiV1ChatsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfChatDto> {
            return localVarFp.apiV1ChatsGet(requestParameters.nexusOpsTenant, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.type, requestParameters.types, requestParameters.isResolved, requestParameters.sortBy, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatsApiApiV1ChatsGroupPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsGroupPost(requestParameters: ChatsApiApiV1ChatsGroupPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatDto> {
            return localVarFp.apiV1ChatsGroupPost(requestParameters.nexusOpsTenant, requestParameters.createGroupChatDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatsApiApiV1ChatsNegotiationGetOrCreatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsNegotiationGetOrCreatePost(requestParameters: ChatsApiApiV1ChatsNegotiationGetOrCreatePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatDto> {
            return localVarFp.apiV1ChatsNegotiationGetOrCreatePost(requestParameters.nexusOpsTenant, requestParameters.generalScopeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatsApiApiV1ChatsPersonalPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChatsPersonalPost(requestParameters: ChatsApiApiV1ChatsPersonalPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChatDto> {
            return localVarFp.apiV1ChatsPersonalPost(requestParameters.nexusOpsTenant, requestParameters.createPersonalChatDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ChatsByScopeGet operation in ChatsApi.
 * @export
 * @interface ChatsApiApiV1ChatsByScopeGetRequest
 */
export interface ChatsApiApiV1ChatsByScopeGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatsApiApiV1ChatsByScopeGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatsApiApiV1ChatsByScopeGet
     */
    readonly identifier?: string

    /**
     * 
     * @type {string}
     * @memberof ChatsApiApiV1ChatsByScopeGet
     */
    readonly subIdentifier?: string

    /**
     * 
     * @type {GeneralScopeType}
     * @memberof ChatsApiApiV1ChatsByScopeGet
     */
    readonly type?: GeneralScopeType

    /**
     * 
     * @type {boolean}
     * @memberof ChatsApiApiV1ChatsByScopeGet
     */
    readonly isResolved?: boolean
}

/**
 * Request parameters for apiV1ChatsChatIdAcknowledgePost operation in ChatsApi.
 * @export
 * @interface ChatsApiApiV1ChatsChatIdAcknowledgePostRequest
 */
export interface ChatsApiApiV1ChatsChatIdAcknowledgePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatsApiApiV1ChatsChatIdAcknowledgePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatsApiApiV1ChatsChatIdAcknowledgePost
     */
    readonly chatId: string
}

/**
 * Request parameters for apiV1ChatsChatIdDelete operation in ChatsApi.
 * @export
 * @interface ChatsApiApiV1ChatsChatIdDeleteRequest
 */
export interface ChatsApiApiV1ChatsChatIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatsApiApiV1ChatsChatIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatsApiApiV1ChatsChatIdDelete
     */
    readonly chatId: string
}

/**
 * Request parameters for apiV1ChatsChatIdGet operation in ChatsApi.
 * @export
 * @interface ChatsApiApiV1ChatsChatIdGetRequest
 */
export interface ChatsApiApiV1ChatsChatIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatsApiApiV1ChatsChatIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatsApiApiV1ChatsChatIdGet
     */
    readonly chatId: string
}

/**
 * Request parameters for apiV1ChatsChatIdPut operation in ChatsApi.
 * @export
 * @interface ChatsApiApiV1ChatsChatIdPutRequest
 */
export interface ChatsApiApiV1ChatsChatIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatsApiApiV1ChatsChatIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatsApiApiV1ChatsChatIdPut
     */
    readonly chatId: string

    /**
     * 
     * @type {UpdateChatDto}
     * @memberof ChatsApiApiV1ChatsChatIdPut
     */
    readonly updateChatDto?: UpdateChatDto
}

/**
 * Request parameters for apiV1ChatsChatIdReopenPost operation in ChatsApi.
 * @export
 * @interface ChatsApiApiV1ChatsChatIdReopenPostRequest
 */
export interface ChatsApiApiV1ChatsChatIdReopenPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatsApiApiV1ChatsChatIdReopenPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatsApiApiV1ChatsChatIdReopenPost
     */
    readonly chatId: string
}

/**
 * Request parameters for apiV1ChatsChatIdResolvePost operation in ChatsApi.
 * @export
 * @interface ChatsApiApiV1ChatsChatIdResolvePostRequest
 */
export interface ChatsApiApiV1ChatsChatIdResolvePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatsApiApiV1ChatsChatIdResolvePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ChatsApiApiV1ChatsChatIdResolvePost
     */
    readonly chatId: string
}

/**
 * Request parameters for apiV1ChatsCommentGetOrCreatePost operation in ChatsApi.
 * @export
 * @interface ChatsApiApiV1ChatsCommentGetOrCreatePostRequest
 */
export interface ChatsApiApiV1ChatsCommentGetOrCreatePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatsApiApiV1ChatsCommentGetOrCreatePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GetOrCreateCommentChatDto}
     * @memberof ChatsApiApiV1ChatsCommentGetOrCreatePost
     */
    readonly getOrCreateCommentChatDto?: GetOrCreateCommentChatDto
}

/**
 * Request parameters for apiV1ChatsGet operation in ChatsApi.
 * @export
 * @interface ChatsApiApiV1ChatsGetRequest
 */
export interface ChatsApiApiV1ChatsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatsApiApiV1ChatsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {number}
     * @memberof ChatsApiApiV1ChatsGet
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ChatsApiApiV1ChatsGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ChatsApiApiV1ChatsGet
     */
    readonly search?: string

    /**
     * 
     * @type {ChatType}
     * @memberof ChatsApiApiV1ChatsGet
     */
    readonly type?: ChatType

    /**
     * 
     * @type {Array<ChatType>}
     * @memberof ChatsApiApiV1ChatsGet
     */
    readonly types?: Array<ChatType>

    /**
     * 
     * @type {boolean}
     * @memberof ChatsApiApiV1ChatsGet
     */
    readonly isResolved?: boolean

    /**
     * Comma-separated list of fields to sort by.
     * @type {string}
     * @memberof ChatsApiApiV1ChatsGet
     */
    readonly sortBy?: string

    /**
     * Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
     * @type {string}
     * @memberof ChatsApiApiV1ChatsGet
     */
    readonly sortOrder?: string
}

/**
 * Request parameters for apiV1ChatsGroupPost operation in ChatsApi.
 * @export
 * @interface ChatsApiApiV1ChatsGroupPostRequest
 */
export interface ChatsApiApiV1ChatsGroupPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatsApiApiV1ChatsGroupPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateGroupChatDto}
     * @memberof ChatsApiApiV1ChatsGroupPost
     */
    readonly createGroupChatDto?: CreateGroupChatDto
}

/**
 * Request parameters for apiV1ChatsNegotiationGetOrCreatePost operation in ChatsApi.
 * @export
 * @interface ChatsApiApiV1ChatsNegotiationGetOrCreatePostRequest
 */
export interface ChatsApiApiV1ChatsNegotiationGetOrCreatePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatsApiApiV1ChatsNegotiationGetOrCreatePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GeneralScopeRequestDto}
     * @memberof ChatsApiApiV1ChatsNegotiationGetOrCreatePost
     */
    readonly generalScopeRequestDto?: GeneralScopeRequestDto
}

/**
 * Request parameters for apiV1ChatsPersonalPost operation in ChatsApi.
 * @export
 * @interface ChatsApiApiV1ChatsPersonalPostRequest
 */
export interface ChatsApiApiV1ChatsPersonalPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ChatsApiApiV1ChatsPersonalPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreatePersonalChatDto}
     * @memberof ChatsApiApiV1ChatsPersonalPost
     */
    readonly createPersonalChatDto?: CreatePersonalChatDto
}

/**
 * ChatsApi - object-oriented interface
 * @export
 * @class ChatsApi
 * @extends {BaseAPI}
 */
export class ChatsApi extends BaseAPI {
    /**
     * 
     * @param {ChatsApiApiV1ChatsByScopeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public apiV1ChatsByScopeGet(requestParameters: ChatsApiApiV1ChatsByScopeGetRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).apiV1ChatsByScopeGet(requestParameters.nexusOpsTenant, requestParameters.identifier, requestParameters.subIdentifier, requestParameters.type, requestParameters.isResolved, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatsApiApiV1ChatsChatIdAcknowledgePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public apiV1ChatsChatIdAcknowledgePost(requestParameters: ChatsApiApiV1ChatsChatIdAcknowledgePostRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).apiV1ChatsChatIdAcknowledgePost(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatsApiApiV1ChatsChatIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public apiV1ChatsChatIdDelete(requestParameters: ChatsApiApiV1ChatsChatIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).apiV1ChatsChatIdDelete(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatsApiApiV1ChatsChatIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public apiV1ChatsChatIdGet(requestParameters: ChatsApiApiV1ChatsChatIdGetRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).apiV1ChatsChatIdGet(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatsApiApiV1ChatsChatIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public apiV1ChatsChatIdPut(requestParameters: ChatsApiApiV1ChatsChatIdPutRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).apiV1ChatsChatIdPut(requestParameters.nexusOpsTenant, requestParameters.chatId, requestParameters.updateChatDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatsApiApiV1ChatsChatIdReopenPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public apiV1ChatsChatIdReopenPost(requestParameters: ChatsApiApiV1ChatsChatIdReopenPostRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).apiV1ChatsChatIdReopenPost(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatsApiApiV1ChatsChatIdResolvePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public apiV1ChatsChatIdResolvePost(requestParameters: ChatsApiApiV1ChatsChatIdResolvePostRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).apiV1ChatsChatIdResolvePost(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatsApiApiV1ChatsCommentGetOrCreatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public apiV1ChatsCommentGetOrCreatePost(requestParameters: ChatsApiApiV1ChatsCommentGetOrCreatePostRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).apiV1ChatsCommentGetOrCreatePost(requestParameters.nexusOpsTenant, requestParameters.getOrCreateCommentChatDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatsApiApiV1ChatsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public apiV1ChatsGet(requestParameters: ChatsApiApiV1ChatsGetRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).apiV1ChatsGet(requestParameters.nexusOpsTenant, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.type, requestParameters.types, requestParameters.isResolved, requestParameters.sortBy, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatsApiApiV1ChatsGroupPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public apiV1ChatsGroupPost(requestParameters: ChatsApiApiV1ChatsGroupPostRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).apiV1ChatsGroupPost(requestParameters.nexusOpsTenant, requestParameters.createGroupChatDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatsApiApiV1ChatsNegotiationGetOrCreatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public apiV1ChatsNegotiationGetOrCreatePost(requestParameters: ChatsApiApiV1ChatsNegotiationGetOrCreatePostRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).apiV1ChatsNegotiationGetOrCreatePost(requestParameters.nexusOpsTenant, requestParameters.generalScopeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatsApiApiV1ChatsPersonalPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public apiV1ChatsPersonalPost(requestParameters: ChatsApiApiV1ChatsPersonalPostRequest, options?: RawAxiosRequestConfig) {
        return ChatsApiFp(this.configuration).apiV1ChatsPersonalPost(requestParameters.nexusOpsTenant, requestParameters.createPersonalChatDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ContractsApi - axios parameter creator
 * @export
 */
export const ContractsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractBulkDeleteDto} [contractBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsBulkDeleteDelete: async (nexusOpsTenant: string, contractBulkDeleteDto?: ContractBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/contracts/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {CreateContractCustomerCommunicationEntryDto} [createContractCustomerCommunicationEntryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdCustomerCommunicationEntriesPost: async (nexusOpsTenant: string, contractId: string, createContractCustomerCommunicationEntryDto?: CreateContractCustomerCommunicationEntryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdCustomerCommunicationEntriesPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdCustomerCommunicationEntriesPost', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}/customer-communication/entries`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContractCustomerCommunicationEntryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {boolean} [isDeleteCascade] 
         * @param {GeneralDeleteEntityDto} [generalDeleteEntityDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdDelete: async (nexusOpsTenant: string, contractId: string, isDeleteCascade?: boolean, generalDeleteEntityDto?: GeneralDeleteEntityDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdDelete', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalDeleteEntityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdGet: async (nexusOpsTenant: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdGet', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {UpdateContractDto} [updateContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdPut: async (nexusOpsTenant: string, contractId: string, updateContractDto?: UpdateContractDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdPut', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdReferenceGet: async (nexusOpsTenant: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdReferenceGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdReferenceGet', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}/reference`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {string} reminderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdRemindersReminderIdEnabledTogglePut: async (nexusOpsTenant: string, contractId: string, reminderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdRemindersReminderIdEnabledTogglePut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdRemindersReminderIdEnabledTogglePut', 'contractId', contractId)
            // verify required parameter 'reminderId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdRemindersReminderIdEnabledTogglePut', 'reminderId', reminderId)
            const localVarPath = `/api/v1/contracts/{contractId}/reminders/{reminderId}/enabled/toggle`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"reminderId"}}`, encodeURIComponent(String(reminderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {string} reminderId 
         * @param {UpdateContractReminderDto} [updateContractReminderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdRemindersReminderIdPut: async (nexusOpsTenant: string, contractId: string, reminderId: string, updateContractReminderDto?: UpdateContractReminderDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdRemindersReminderIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdRemindersReminderIdPut', 'contractId', contractId)
            // verify required parameter 'reminderId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdRemindersReminderIdPut', 'reminderId', reminderId)
            const localVarPath = `/api/v1/contracts/{contractId}/reminders/{reminderId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"reminderId"}}`, encodeURIComponent(String(reminderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContractReminderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {UpdateContractStageDto} [updateContractStageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdStagePut: async (nexusOpsTenant: string, contractId: string, updateContractStageDto?: UpdateContractStageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdStagePut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdStagePut', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}/stage`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContractStageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleAllocationConfirmPost: async (nexusOpsTenant: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleAllocationConfirmPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleAllocationConfirmPost', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}/vehicle/allocation/confirm`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractReallocateVehicleDto} [contractReallocateVehicleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleAllocationReallocatePost: async (nexusOpsTenant: string, contractId: string, contractReallocateVehicleDto?: ContractReallocateVehicleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleAllocationReallocatePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleAllocationReallocatePost', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}/vehicle/allocation/reallocate`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractReallocateVehicleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractVehicleCheckInDto} [contractVehicleCheckInDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleCheckInPost: async (nexusOpsTenant: string, contractId: string, contractVehicleCheckInDto?: ContractVehicleCheckInDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleCheckInPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleCheckInPost', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}/vehicle/check-in`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractVehicleCheckInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractSpotInfoInputDto} [contractSpotInfoInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleCheckInSpotInfoPut: async (nexusOpsTenant: string, contractId: string, contractSpotInfoInputDto?: ContractSpotInfoInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleCheckInSpotInfoPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleCheckInSpotInfoPut', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}/vehicle/check-in/spot-info`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractSpotInfoInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractSpotInfoVisualInspectionInputDto} [contractSpotInfoVisualInspectionInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut: async (nexusOpsTenant: string, contractId: string, contractSpotInfoVisualInspectionInputDto?: ContractSpotInfoVisualInspectionInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}/vehicle/check-in/spot-info/visual-inspection`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractSpotInfoVisualInspectionInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractVehicleCheckOutDto} [contractVehicleCheckOutDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleCheckOutPost: async (nexusOpsTenant: string, contractId: string, contractVehicleCheckOutDto?: ContractVehicleCheckOutDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleCheckOutPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleCheckOutPost', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}/vehicle/check-out`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractVehicleCheckOutDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractSpotInfoInputDto} [contractSpotInfoInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleCheckOutSpotInfoPut: async (nexusOpsTenant: string, contractId: string, contractSpotInfoInputDto?: ContractSpotInfoInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleCheckOutSpotInfoPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleCheckOutSpotInfoPut', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}/vehicle/check-out/spot-info`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractSpotInfoInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractSpotInfoVisualInspectionInputDto} [contractSpotInfoVisualInspectionInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut: async (nexusOpsTenant: string, contractId: string, contractSpotInfoVisualInspectionInputDto?: ContractSpotInfoVisualInspectionInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut', 'contractId', contractId)
            const localVarPath = `/api/v1/contracts/{contractId}/vehicle/check-out/spot-info/visual-inspection`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractSpotInfoVisualInspectionInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractGetCountersDto} [contractGetCountersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsCountersGetPost: async (nexusOpsTenant: string, contractGetCountersDto?: ContractGetCountersDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsCountersGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/contracts/counters/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractGetCountersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractGetPaginatedDto} [contractGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsGetPost: async (nexusOpsTenant: string, contractGetPaginatedDto?: ContractGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/contracts/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateContractDto} [createContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsPost: async (nexusOpsTenant: string, createContractDto?: CreateContractDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractSearchPaginatedDto} [contractSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsSearchPost: async (nexusOpsTenant: string, contractSearchPaginatedDto?: ContractSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ContractsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/contracts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractsApi - functional programming interface
 * @export
 */
export const ContractsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractBulkDeleteDto} [contractBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsBulkDeleteDelete(nexusOpsTenant: string, contractBulkDeleteDto?: ContractBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsBulkDeleteDelete(nexusOpsTenant, contractBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {CreateContractCustomerCommunicationEntryDto} [createContractCustomerCommunicationEntryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdCustomerCommunicationEntriesPost(nexusOpsTenant: string, contractId: string, createContractCustomerCommunicationEntryDto?: CreateContractCustomerCommunicationEntryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdCustomerCommunicationEntriesPost(nexusOpsTenant, contractId, createContractCustomerCommunicationEntryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdCustomerCommunicationEntriesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {boolean} [isDeleteCascade] 
         * @param {GeneralDeleteEntityDto} [generalDeleteEntityDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdDelete(nexusOpsTenant: string, contractId: string, isDeleteCascade?: boolean, generalDeleteEntityDto?: GeneralDeleteEntityDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdDelete(nexusOpsTenant, contractId, isDeleteCascade, generalDeleteEntityDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdGet(nexusOpsTenant: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdGet(nexusOpsTenant, contractId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {UpdateContractDto} [updateContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdPut(nexusOpsTenant: string, contractId: string, updateContractDto?: UpdateContractDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdPut(nexusOpsTenant, contractId, updateContractDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdReferenceGet(nexusOpsTenant: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdReferenceGet(nexusOpsTenant, contractId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdReferenceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {string} reminderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdRemindersReminderIdEnabledTogglePut(nexusOpsTenant: string, contractId: string, reminderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractReminderUpdatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdRemindersReminderIdEnabledTogglePut(nexusOpsTenant, contractId, reminderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdRemindersReminderIdEnabledTogglePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {string} reminderId 
         * @param {UpdateContractReminderDto} [updateContractReminderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdRemindersReminderIdPut(nexusOpsTenant: string, contractId: string, reminderId: string, updateContractReminderDto?: UpdateContractReminderDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractReminderUpdatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdRemindersReminderIdPut(nexusOpsTenant, contractId, reminderId, updateContractReminderDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdRemindersReminderIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {UpdateContractStageDto} [updateContractStageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdStagePut(nexusOpsTenant: string, contractId: string, updateContractStageDto?: UpdateContractStageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdStagePut(nexusOpsTenant, contractId, updateContractStageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdStagePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdVehicleAllocationConfirmPost(nexusOpsTenant: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdVehicleAllocationConfirmPost(nexusOpsTenant, contractId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdVehicleAllocationConfirmPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractReallocateVehicleDto} [contractReallocateVehicleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdVehicleAllocationReallocatePost(nexusOpsTenant: string, contractId: string, contractReallocateVehicleDto?: ContractReallocateVehicleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdVehicleAllocationReallocatePost(nexusOpsTenant, contractId, contractReallocateVehicleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdVehicleAllocationReallocatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractVehicleCheckInDto} [contractVehicleCheckInDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdVehicleCheckInPost(nexusOpsTenant: string, contractId: string, contractVehicleCheckInDto?: ContractVehicleCheckInDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdVehicleCheckInPost(nexusOpsTenant, contractId, contractVehicleCheckInDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdVehicleCheckInPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractSpotInfoInputDto} [contractSpotInfoInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdVehicleCheckInSpotInfoPut(nexusOpsTenant: string, contractId: string, contractSpotInfoInputDto?: ContractSpotInfoInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdVehicleCheckInSpotInfoPut(nexusOpsTenant, contractId, contractSpotInfoInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdVehicleCheckInSpotInfoPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractSpotInfoVisualInspectionInputDto} [contractSpotInfoVisualInspectionInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut(nexusOpsTenant: string, contractId: string, contractSpotInfoVisualInspectionInputDto?: ContractSpotInfoVisualInspectionInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut(nexusOpsTenant, contractId, contractSpotInfoVisualInspectionInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractVehicleCheckOutDto} [contractVehicleCheckOutDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdVehicleCheckOutPost(nexusOpsTenant: string, contractId: string, contractVehicleCheckOutDto?: ContractVehicleCheckOutDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdVehicleCheckOutPost(nexusOpsTenant, contractId, contractVehicleCheckOutDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdVehicleCheckOutPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractSpotInfoInputDto} [contractSpotInfoInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdVehicleCheckOutSpotInfoPut(nexusOpsTenant: string, contractId: string, contractSpotInfoInputDto?: ContractSpotInfoInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdVehicleCheckOutSpotInfoPut(nexusOpsTenant, contractId, contractSpotInfoInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdVehicleCheckOutSpotInfoPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractSpotInfoVisualInspectionInputDto} [contractSpotInfoVisualInspectionInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut(nexusOpsTenant: string, contractId: string, contractSpotInfoVisualInspectionInputDto?: ContractSpotInfoVisualInspectionInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut(nexusOpsTenant, contractId, contractSpotInfoVisualInspectionInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractGetCountersDto} [contractGetCountersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsCountersGetPost(nexusOpsTenant: string, contractGetCountersDto?: ContractGetCountersDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsCountersGetPost(nexusOpsTenant, contractGetCountersDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsCountersGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractGetPaginatedDto} [contractGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsGetPost(nexusOpsTenant: string, contractGetPaginatedDto?: ContractGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsGetPost(nexusOpsTenant, contractGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateContractDto} [createContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsPost(nexusOpsTenant: string, createContractDto?: CreateContractDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsPost(nexusOpsTenant, createContractDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractSearchPaginatedDto} [contractSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContractsSearchPost(nexusOpsTenant: string, contractSearchPaginatedDto?: ContractSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContractsSearchPost(nexusOpsTenant, contractSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContractsApi.apiV1ContractsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContractsApi - factory interface
 * @export
 */
export const ContractsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractsApiFp(configuration)
    return {
        /**
         * 
         * @param {ContractsApiApiV1ContractsBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsBulkDeleteDelete(requestParameters: ContractsApiApiV1ContractsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1ContractsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.contractBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdCustomerCommunicationEntriesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdCustomerCommunicationEntriesPost(requestParameters: ContractsApiApiV1ContractsContractIdCustomerCommunicationEntriesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1ContractsContractIdCustomerCommunicationEntriesPost(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.createContractCustomerCommunicationEntryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdDelete(requestParameters: ContractsApiApiV1ContractsContractIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1ContractsContractIdDelete(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.isDeleteCascade, requestParameters.generalDeleteEntityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdGet(requestParameters: ContractsApiApiV1ContractsContractIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1ContractsContractIdGet(requestParameters.nexusOpsTenant, requestParameters.contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdPut(requestParameters: ContractsApiApiV1ContractsContractIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1ContractsContractIdPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.updateContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdReferenceGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdReferenceGet(requestParameters: ContractsApiApiV1ContractsContractIdReferenceGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractReferenceDto> {
            return localVarFp.apiV1ContractsContractIdReferenceGet(requestParameters.nexusOpsTenant, requestParameters.contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdRemindersReminderIdEnabledTogglePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdRemindersReminderIdEnabledTogglePut(requestParameters: ContractsApiApiV1ContractsContractIdRemindersReminderIdEnabledTogglePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractReminderUpdatedDto> {
            return localVarFp.apiV1ContractsContractIdRemindersReminderIdEnabledTogglePut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.reminderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdRemindersReminderIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdRemindersReminderIdPut(requestParameters: ContractsApiApiV1ContractsContractIdRemindersReminderIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractReminderUpdatedDto> {
            return localVarFp.apiV1ContractsContractIdRemindersReminderIdPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.reminderId, requestParameters.updateContractReminderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdStagePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdStagePut(requestParameters: ContractsApiApiV1ContractsContractIdStagePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1ContractsContractIdStagePut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.updateContractStageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdVehicleAllocationConfirmPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleAllocationConfirmPost(requestParameters: ContractsApiApiV1ContractsContractIdVehicleAllocationConfirmPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1ContractsContractIdVehicleAllocationConfirmPost(requestParameters.nexusOpsTenant, requestParameters.contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdVehicleAllocationReallocatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleAllocationReallocatePost(requestParameters: ContractsApiApiV1ContractsContractIdVehicleAllocationReallocatePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1ContractsContractIdVehicleAllocationReallocatePost(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractReallocateVehicleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdVehicleCheckInPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleCheckInPost(requestParameters: ContractsApiApiV1ContractsContractIdVehicleCheckInPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1ContractsContractIdVehicleCheckInPost(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractVehicleCheckInDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleCheckInSpotInfoPut(requestParameters: ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1ContractsContractIdVehicleCheckInSpotInfoPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractSpotInfoInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut(requestParameters: ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractSpotInfoVisualInspectionInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdVehicleCheckOutPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleCheckOutPost(requestParameters: ContractsApiApiV1ContractsContractIdVehicleCheckOutPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1ContractsContractIdVehicleCheckOutPost(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractVehicleCheckOutDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleCheckOutSpotInfoPut(requestParameters: ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1ContractsContractIdVehicleCheckOutSpotInfoPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractSpotInfoInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut(requestParameters: ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractSpotInfoVisualInspectionInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsCountersGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsCountersGetPost(requestParameters: ContractsApiApiV1ContractsCountersGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractCountersDto> {
            return localVarFp.apiV1ContractsCountersGetPost(requestParameters.nexusOpsTenant, requestParameters.contractGetCountersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsGetPost(requestParameters: ContractsApiApiV1ContractsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfContractDto> {
            return localVarFp.apiV1ContractsGetPost(requestParameters.nexusOpsTenant, requestParameters.contractGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsPost(requestParameters: ContractsApiApiV1ContractsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1ContractsPost(requestParameters.nexusOpsTenant, requestParameters.createContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContractsApiApiV1ContractsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContractsSearchPost(requestParameters: ContractsApiApiV1ContractsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfContractDto> {
            return localVarFp.apiV1ContractsSearchPost(requestParameters.nexusOpsTenant, requestParameters.contractSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ContractsBulkDeleteDelete operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsBulkDeleteDeleteRequest
 */
export interface ContractsApiApiV1ContractsBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ContractBulkDeleteDto}
     * @memberof ContractsApiApiV1ContractsBulkDeleteDelete
     */
    readonly contractBulkDeleteDto?: ContractBulkDeleteDto
}

/**
 * Request parameters for apiV1ContractsContractIdCustomerCommunicationEntriesPost operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdCustomerCommunicationEntriesPostRequest
 */
export interface ContractsApiApiV1ContractsContractIdCustomerCommunicationEntriesPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdCustomerCommunicationEntriesPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdCustomerCommunicationEntriesPost
     */
    readonly contractId: string

    /**
     * 
     * @type {CreateContractCustomerCommunicationEntryDto}
     * @memberof ContractsApiApiV1ContractsContractIdCustomerCommunicationEntriesPost
     */
    readonly createContractCustomerCommunicationEntryDto?: CreateContractCustomerCommunicationEntryDto
}

/**
 * Request parameters for apiV1ContractsContractIdDelete operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdDeleteRequest
 */
export interface ContractsApiApiV1ContractsContractIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdDelete
     */
    readonly contractId: string

    /**
     * 
     * @type {boolean}
     * @memberof ContractsApiApiV1ContractsContractIdDelete
     */
    readonly isDeleteCascade?: boolean

    /**
     * 
     * @type {GeneralDeleteEntityDto}
     * @memberof ContractsApiApiV1ContractsContractIdDelete
     */
    readonly generalDeleteEntityDto?: GeneralDeleteEntityDto
}

/**
 * Request parameters for apiV1ContractsContractIdGet operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdGetRequest
 */
export interface ContractsApiApiV1ContractsContractIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdGet
     */
    readonly contractId: string
}

/**
 * Request parameters for apiV1ContractsContractIdPut operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdPutRequest
 */
export interface ContractsApiApiV1ContractsContractIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdPut
     */
    readonly contractId: string

    /**
     * 
     * @type {UpdateContractDto}
     * @memberof ContractsApiApiV1ContractsContractIdPut
     */
    readonly updateContractDto?: UpdateContractDto
}

/**
 * Request parameters for apiV1ContractsContractIdReferenceGet operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdReferenceGetRequest
 */
export interface ContractsApiApiV1ContractsContractIdReferenceGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdReferenceGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdReferenceGet
     */
    readonly contractId: string
}

/**
 * Request parameters for apiV1ContractsContractIdRemindersReminderIdEnabledTogglePut operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdRemindersReminderIdEnabledTogglePutRequest
 */
export interface ContractsApiApiV1ContractsContractIdRemindersReminderIdEnabledTogglePutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdRemindersReminderIdEnabledTogglePut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdRemindersReminderIdEnabledTogglePut
     */
    readonly contractId: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdRemindersReminderIdEnabledTogglePut
     */
    readonly reminderId: string
}

/**
 * Request parameters for apiV1ContractsContractIdRemindersReminderIdPut operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdRemindersReminderIdPutRequest
 */
export interface ContractsApiApiV1ContractsContractIdRemindersReminderIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdRemindersReminderIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdRemindersReminderIdPut
     */
    readonly contractId: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdRemindersReminderIdPut
     */
    readonly reminderId: string

    /**
     * 
     * @type {UpdateContractReminderDto}
     * @memberof ContractsApiApiV1ContractsContractIdRemindersReminderIdPut
     */
    readonly updateContractReminderDto?: UpdateContractReminderDto
}

/**
 * Request parameters for apiV1ContractsContractIdStagePut operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdStagePutRequest
 */
export interface ContractsApiApiV1ContractsContractIdStagePutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdStagePut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdStagePut
     */
    readonly contractId: string

    /**
     * 
     * @type {UpdateContractStageDto}
     * @memberof ContractsApiApiV1ContractsContractIdStagePut
     */
    readonly updateContractStageDto?: UpdateContractStageDto
}

/**
 * Request parameters for apiV1ContractsContractIdVehicleAllocationConfirmPost operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdVehicleAllocationConfirmPostRequest
 */
export interface ContractsApiApiV1ContractsContractIdVehicleAllocationConfirmPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleAllocationConfirmPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleAllocationConfirmPost
     */
    readonly contractId: string
}

/**
 * Request parameters for apiV1ContractsContractIdVehicleAllocationReallocatePost operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdVehicleAllocationReallocatePostRequest
 */
export interface ContractsApiApiV1ContractsContractIdVehicleAllocationReallocatePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleAllocationReallocatePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleAllocationReallocatePost
     */
    readonly contractId: string

    /**
     * 
     * @type {ContractReallocateVehicleDto}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleAllocationReallocatePost
     */
    readonly contractReallocateVehicleDto?: ContractReallocateVehicleDto
}

/**
 * Request parameters for apiV1ContractsContractIdVehicleCheckInPost operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdVehicleCheckInPostRequest
 */
export interface ContractsApiApiV1ContractsContractIdVehicleCheckInPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckInPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckInPost
     */
    readonly contractId: string

    /**
     * 
     * @type {ContractVehicleCheckInDto}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckInPost
     */
    readonly contractVehicleCheckInDto?: ContractVehicleCheckInDto
}

/**
 * Request parameters for apiV1ContractsContractIdVehicleCheckInSpotInfoPut operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoPutRequest
 */
export interface ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoPut
     */
    readonly contractId: string

    /**
     * 
     * @type {ContractSpotInfoInputDto}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoPut
     */
    readonly contractSpotInfoInputDto?: ContractSpotInfoInputDto
}

/**
 * Request parameters for apiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPutRequest
 */
export interface ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut
     */
    readonly contractId: string

    /**
     * 
     * @type {ContractSpotInfoVisualInspectionInputDto}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut
     */
    readonly contractSpotInfoVisualInspectionInputDto?: ContractSpotInfoVisualInspectionInputDto
}

/**
 * Request parameters for apiV1ContractsContractIdVehicleCheckOutPost operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdVehicleCheckOutPostRequest
 */
export interface ContractsApiApiV1ContractsContractIdVehicleCheckOutPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckOutPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckOutPost
     */
    readonly contractId: string

    /**
     * 
     * @type {ContractVehicleCheckOutDto}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckOutPost
     */
    readonly contractVehicleCheckOutDto?: ContractVehicleCheckOutDto
}

/**
 * Request parameters for apiV1ContractsContractIdVehicleCheckOutSpotInfoPut operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoPutRequest
 */
export interface ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoPut
     */
    readonly contractId: string

    /**
     * 
     * @type {ContractSpotInfoInputDto}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoPut
     */
    readonly contractSpotInfoInputDto?: ContractSpotInfoInputDto
}

/**
 * Request parameters for apiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPutRequest
 */
export interface ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut
     */
    readonly contractId: string

    /**
     * 
     * @type {ContractSpotInfoVisualInspectionInputDto}
     * @memberof ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut
     */
    readonly contractSpotInfoVisualInspectionInputDto?: ContractSpotInfoVisualInspectionInputDto
}

/**
 * Request parameters for apiV1ContractsCountersGetPost operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsCountersGetPostRequest
 */
export interface ContractsApiApiV1ContractsCountersGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsCountersGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ContractGetCountersDto}
     * @memberof ContractsApiApiV1ContractsCountersGetPost
     */
    readonly contractGetCountersDto?: ContractGetCountersDto
}

/**
 * Request parameters for apiV1ContractsGetPost operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsGetPostRequest
 */
export interface ContractsApiApiV1ContractsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ContractGetPaginatedDto}
     * @memberof ContractsApiApiV1ContractsGetPost
     */
    readonly contractGetPaginatedDto?: ContractGetPaginatedDto
}

/**
 * Request parameters for apiV1ContractsPost operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsPostRequest
 */
export interface ContractsApiApiV1ContractsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateContractDto}
     * @memberof ContractsApiApiV1ContractsPost
     */
    readonly createContractDto?: CreateContractDto
}

/**
 * Request parameters for apiV1ContractsSearchPost operation in ContractsApi.
 * @export
 * @interface ContractsApiApiV1ContractsSearchPostRequest
 */
export interface ContractsApiApiV1ContractsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ContractsApiApiV1ContractsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ContractSearchPaginatedDto}
     * @memberof ContractsApiApiV1ContractsSearchPost
     */
    readonly contractSearchPaginatedDto?: ContractSearchPaginatedDto
}

/**
 * ContractsApi - object-oriented interface
 * @export
 * @class ContractsApi
 * @extends {BaseAPI}
 */
export class ContractsApi extends BaseAPI {
    /**
     * 
     * @param {ContractsApiApiV1ContractsBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsBulkDeleteDelete(requestParameters: ContractsApiApiV1ContractsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.contractBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdCustomerCommunicationEntriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdCustomerCommunicationEntriesPost(requestParameters: ContractsApiApiV1ContractsContractIdCustomerCommunicationEntriesPostRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdCustomerCommunicationEntriesPost(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.createContractCustomerCommunicationEntryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdDelete(requestParameters: ContractsApiApiV1ContractsContractIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdDelete(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.isDeleteCascade, requestParameters.generalDeleteEntityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdGet(requestParameters: ContractsApiApiV1ContractsContractIdGetRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdGet(requestParameters.nexusOpsTenant, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdPut(requestParameters: ContractsApiApiV1ContractsContractIdPutRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.updateContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdReferenceGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdReferenceGet(requestParameters: ContractsApiApiV1ContractsContractIdReferenceGetRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdReferenceGet(requestParameters.nexusOpsTenant, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdRemindersReminderIdEnabledTogglePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdRemindersReminderIdEnabledTogglePut(requestParameters: ContractsApiApiV1ContractsContractIdRemindersReminderIdEnabledTogglePutRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdRemindersReminderIdEnabledTogglePut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.reminderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdRemindersReminderIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdRemindersReminderIdPut(requestParameters: ContractsApiApiV1ContractsContractIdRemindersReminderIdPutRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdRemindersReminderIdPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.reminderId, requestParameters.updateContractReminderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdStagePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdStagePut(requestParameters: ContractsApiApiV1ContractsContractIdStagePutRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdStagePut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.updateContractStageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdVehicleAllocationConfirmPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdVehicleAllocationConfirmPost(requestParameters: ContractsApiApiV1ContractsContractIdVehicleAllocationConfirmPostRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdVehicleAllocationConfirmPost(requestParameters.nexusOpsTenant, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdVehicleAllocationReallocatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdVehicleAllocationReallocatePost(requestParameters: ContractsApiApiV1ContractsContractIdVehicleAllocationReallocatePostRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdVehicleAllocationReallocatePost(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractReallocateVehicleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdVehicleCheckInPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdVehicleCheckInPost(requestParameters: ContractsApiApiV1ContractsContractIdVehicleCheckInPostRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdVehicleCheckInPost(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractVehicleCheckInDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdVehicleCheckInSpotInfoPut(requestParameters: ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoPutRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdVehicleCheckInSpotInfoPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractSpotInfoInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut(requestParameters: ContractsApiApiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPutRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractSpotInfoVisualInspectionInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdVehicleCheckOutPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdVehicleCheckOutPost(requestParameters: ContractsApiApiV1ContractsContractIdVehicleCheckOutPostRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdVehicleCheckOutPost(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractVehicleCheckOutDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdVehicleCheckOutSpotInfoPut(requestParameters: ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoPutRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdVehicleCheckOutSpotInfoPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractSpotInfoInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut(requestParameters: ContractsApiApiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPutRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractSpotInfoVisualInspectionInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsCountersGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsCountersGetPost(requestParameters: ContractsApiApiV1ContractsCountersGetPostRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsCountersGetPost(requestParameters.nexusOpsTenant, requestParameters.contractGetCountersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsGetPost(requestParameters: ContractsApiApiV1ContractsGetPostRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsGetPost(requestParameters.nexusOpsTenant, requestParameters.contractGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsPost(requestParameters: ContractsApiApiV1ContractsPostRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsPost(requestParameters.nexusOpsTenant, requestParameters.createContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContractsApiApiV1ContractsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public apiV1ContractsSearchPost(requestParameters: ContractsApiApiV1ContractsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return ContractsApiFp(this.configuration).apiV1ContractsSearchPost(requestParameters.nexusOpsTenant, requestParameters.contractSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomSessionsApi - axios parameter creator
 * @export
 */
export const CustomSessionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} sessionIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomSessionsByIdentifierSessionIdentifierGet: async (nexusOpsTenant: string, sessionIdentifier: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomSessionsByIdentifierSessionIdentifierGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'sessionIdentifier' is not null or undefined
            assertParamExists('apiV1CustomSessionsByIdentifierSessionIdentifierGet', 'sessionIdentifier', sessionIdentifier)
            const localVarPath = `/api/v1/custom-sessions/by-identifier/{sessionIdentifier}`
                .replace(`{${"sessionIdentifier"}}`, encodeURIComponent(String(sessionIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomSessionsSessionIdGet: async (nexusOpsTenant: string, sessionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomSessionsSessionIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('apiV1CustomSessionsSessionIdGet', 'sessionId', sessionId)
            const localVarPath = `/api/v1/custom-sessions/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomSessionsApi - functional programming interface
 * @export
 */
export const CustomSessionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomSessionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} sessionIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomSessionsByIdentifierSessionIdentifierGet(nexusOpsTenant: string, sessionIdentifier: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomSessionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomSessionsByIdentifierSessionIdentifierGet(nexusOpsTenant, sessionIdentifier, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomSessionsApi.apiV1CustomSessionsByIdentifierSessionIdentifierGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomSessionsSessionIdGet(nexusOpsTenant: string, sessionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomSessionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomSessionsSessionIdGet(nexusOpsTenant, sessionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomSessionsApi.apiV1CustomSessionsSessionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomSessionsApi - factory interface
 * @export
 */
export const CustomSessionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomSessionsApiFp(configuration)
    return {
        /**
         * 
         * @param {CustomSessionsApiApiV1CustomSessionsByIdentifierSessionIdentifierGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomSessionsByIdentifierSessionIdentifierGet(requestParameters: CustomSessionsApiApiV1CustomSessionsByIdentifierSessionIdentifierGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomSessionDto> {
            return localVarFp.apiV1CustomSessionsByIdentifierSessionIdentifierGet(requestParameters.nexusOpsTenant, requestParameters.sessionIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomSessionsApiApiV1CustomSessionsSessionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomSessionsSessionIdGet(requestParameters: CustomSessionsApiApiV1CustomSessionsSessionIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomSessionDto> {
            return localVarFp.apiV1CustomSessionsSessionIdGet(requestParameters.nexusOpsTenant, requestParameters.sessionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1CustomSessionsByIdentifierSessionIdentifierGet operation in CustomSessionsApi.
 * @export
 * @interface CustomSessionsApiApiV1CustomSessionsByIdentifierSessionIdentifierGetRequest
 */
export interface CustomSessionsApiApiV1CustomSessionsByIdentifierSessionIdentifierGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomSessionsApiApiV1CustomSessionsByIdentifierSessionIdentifierGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomSessionsApiApiV1CustomSessionsByIdentifierSessionIdentifierGet
     */
    readonly sessionIdentifier: string
}

/**
 * Request parameters for apiV1CustomSessionsSessionIdGet operation in CustomSessionsApi.
 * @export
 * @interface CustomSessionsApiApiV1CustomSessionsSessionIdGetRequest
 */
export interface CustomSessionsApiApiV1CustomSessionsSessionIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomSessionsApiApiV1CustomSessionsSessionIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomSessionsApiApiV1CustomSessionsSessionIdGet
     */
    readonly sessionId: string
}

/**
 * CustomSessionsApi - object-oriented interface
 * @export
 * @class CustomSessionsApi
 * @extends {BaseAPI}
 */
export class CustomSessionsApi extends BaseAPI {
    /**
     * 
     * @param {CustomSessionsApiApiV1CustomSessionsByIdentifierSessionIdentifierGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomSessionsApi
     */
    public apiV1CustomSessionsByIdentifierSessionIdentifierGet(requestParameters: CustomSessionsApiApiV1CustomSessionsByIdentifierSessionIdentifierGetRequest, options?: RawAxiosRequestConfig) {
        return CustomSessionsApiFp(this.configuration).apiV1CustomSessionsByIdentifierSessionIdentifierGet(requestParameters.nexusOpsTenant, requestParameters.sessionIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomSessionsApiApiV1CustomSessionsSessionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomSessionsApi
     */
    public apiV1CustomSessionsSessionIdGet(requestParameters: CustomSessionsApiApiV1CustomSessionsSessionIdGetRequest, options?: RawAxiosRequestConfig) {
        return CustomSessionsApiFp(this.configuration).apiV1CustomSessionsSessionIdGet(requestParameters.nexusOpsTenant, requestParameters.sessionId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomTagsApi - axios parameter creator
 * @export
 */
export const CustomTagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsCustomBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tags/custom/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomCustomTagIdDelete: async (nexusOpsTenant: string, customTagId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsCustomCustomTagIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'customTagId' is not null or undefined
            assertParamExists('apiV1TagsCustomCustomTagIdDelete', 'customTagId', customTagId)
            const localVarPath = `/api/v1/tags/custom/{customTagId}`
                .replace(`{${"customTagId"}}`, encodeURIComponent(String(customTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomCustomTagIdGet: async (nexusOpsTenant: string, customTagId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsCustomCustomTagIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'customTagId' is not null or undefined
            assertParamExists('apiV1TagsCustomCustomTagIdGet', 'customTagId', customTagId)
            const localVarPath = `/api/v1/tags/custom/{customTagId}`
                .replace(`{${"customTagId"}}`, encodeURIComponent(String(customTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customTagId 
         * @param {CustomTagUpdateDto} [customTagUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomCustomTagIdPut: async (nexusOpsTenant: string, customTagId: string, customTagUpdateDto?: CustomTagUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsCustomCustomTagIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'customTagId' is not null or undefined
            assertParamExists('apiV1TagsCustomCustomTagIdPut', 'customTagId', customTagId)
            const localVarPath = `/api/v1/tags/custom/{customTagId}`
                .replace(`{${"customTagId"}}`, encodeURIComponent(String(customTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customTagUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CustomTagGetPaginatedDto} [customTagGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomGetPost: async (nexusOpsTenant: string, customTagGetPaginatedDto?: CustomTagGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsCustomGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tags/custom/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customTagGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CustomTagCreateDto} [customTagCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomPost: async (nexusOpsTenant: string, customTagCreateDto?: CustomTagCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsCustomPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tags/custom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customTagCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CustomTagSearchPaginatedDto} [customTagSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomSearchPost: async (nexusOpsTenant: string, customTagSearchPaginatedDto?: CustomTagSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsCustomSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tags/custom/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customTagSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomTagsApi - functional programming interface
 * @export
 */
export const CustomTagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomTagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsCustomBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsCustomBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomTagsApi.apiV1TagsCustomBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsCustomCustomTagIdDelete(nexusOpsTenant: string, customTagId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsCustomCustomTagIdDelete(nexusOpsTenant, customTagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomTagsApi.apiV1TagsCustomCustomTagIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsCustomCustomTagIdGet(nexusOpsTenant: string, customTagId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsCustomCustomTagIdGet(nexusOpsTenant, customTagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomTagsApi.apiV1TagsCustomCustomTagIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customTagId 
         * @param {CustomTagUpdateDto} [customTagUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsCustomCustomTagIdPut(nexusOpsTenant: string, customTagId: string, customTagUpdateDto?: CustomTagUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsCustomCustomTagIdPut(nexusOpsTenant, customTagId, customTagUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomTagsApi.apiV1TagsCustomCustomTagIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CustomTagGetPaginatedDto} [customTagGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsCustomGetPost(nexusOpsTenant: string, customTagGetPaginatedDto?: CustomTagGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfCustomTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsCustomGetPost(nexusOpsTenant, customTagGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomTagsApi.apiV1TagsCustomGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CustomTagCreateDto} [customTagCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsCustomPost(nexusOpsTenant: string, customTagCreateDto?: CustomTagCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsCustomPost(nexusOpsTenant, customTagCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomTagsApi.apiV1TagsCustomPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CustomTagSearchPaginatedDto} [customTagSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsCustomSearchPost(nexusOpsTenant: string, customTagSearchPaginatedDto?: CustomTagSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfCustomTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsCustomSearchPost(nexusOpsTenant, customTagSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomTagsApi.apiV1TagsCustomSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomTagsApi - factory interface
 * @export
 */
export const CustomTagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomTagsApiFp(configuration)
    return {
        /**
         * 
         * @param {CustomTagsApiApiV1TagsCustomBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomBulkDeleteDelete(requestParameters: CustomTagsApiApiV1TagsCustomBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TagsCustomBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomTagsApiApiV1TagsCustomCustomTagIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomCustomTagIdDelete(requestParameters: CustomTagsApiApiV1TagsCustomCustomTagIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TagsCustomCustomTagIdDelete(requestParameters.nexusOpsTenant, requestParameters.customTagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomTagsApiApiV1TagsCustomCustomTagIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomCustomTagIdGet(requestParameters: CustomTagsApiApiV1TagsCustomCustomTagIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomTagDto> {
            return localVarFp.apiV1TagsCustomCustomTagIdGet(requestParameters.nexusOpsTenant, requestParameters.customTagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomTagsApiApiV1TagsCustomCustomTagIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomCustomTagIdPut(requestParameters: CustomTagsApiApiV1TagsCustomCustomTagIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomTagDto> {
            return localVarFp.apiV1TagsCustomCustomTagIdPut(requestParameters.nexusOpsTenant, requestParameters.customTagId, requestParameters.customTagUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomTagsApiApiV1TagsCustomGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomGetPost(requestParameters: CustomTagsApiApiV1TagsCustomGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfCustomTagDto> {
            return localVarFp.apiV1TagsCustomGetPost(requestParameters.nexusOpsTenant, requestParameters.customTagGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomTagsApiApiV1TagsCustomPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomPost(requestParameters: CustomTagsApiApiV1TagsCustomPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomTagDto> {
            return localVarFp.apiV1TagsCustomPost(requestParameters.nexusOpsTenant, requestParameters.customTagCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomTagsApiApiV1TagsCustomSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsCustomSearchPost(requestParameters: CustomTagsApiApiV1TagsCustomSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfCustomTagDto> {
            return localVarFp.apiV1TagsCustomSearchPost(requestParameters.nexusOpsTenant, requestParameters.customTagSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TagsCustomBulkDeleteDelete operation in CustomTagsApi.
 * @export
 * @interface CustomTagsApiApiV1TagsCustomBulkDeleteDeleteRequest
 */
export interface CustomTagsApiApiV1TagsCustomBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomTagsApiApiV1TagsCustomBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof CustomTagsApiApiV1TagsCustomBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1TagsCustomCustomTagIdDelete operation in CustomTagsApi.
 * @export
 * @interface CustomTagsApiApiV1TagsCustomCustomTagIdDeleteRequest
 */
export interface CustomTagsApiApiV1TagsCustomCustomTagIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomTagsApiApiV1TagsCustomCustomTagIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomTagsApiApiV1TagsCustomCustomTagIdDelete
     */
    readonly customTagId: string
}

/**
 * Request parameters for apiV1TagsCustomCustomTagIdGet operation in CustomTagsApi.
 * @export
 * @interface CustomTagsApiApiV1TagsCustomCustomTagIdGetRequest
 */
export interface CustomTagsApiApiV1TagsCustomCustomTagIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomTagsApiApiV1TagsCustomCustomTagIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomTagsApiApiV1TagsCustomCustomTagIdGet
     */
    readonly customTagId: string
}

/**
 * Request parameters for apiV1TagsCustomCustomTagIdPut operation in CustomTagsApi.
 * @export
 * @interface CustomTagsApiApiV1TagsCustomCustomTagIdPutRequest
 */
export interface CustomTagsApiApiV1TagsCustomCustomTagIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomTagsApiApiV1TagsCustomCustomTagIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomTagsApiApiV1TagsCustomCustomTagIdPut
     */
    readonly customTagId: string

    /**
     * 
     * @type {CustomTagUpdateDto}
     * @memberof CustomTagsApiApiV1TagsCustomCustomTagIdPut
     */
    readonly customTagUpdateDto?: CustomTagUpdateDto
}

/**
 * Request parameters for apiV1TagsCustomGetPost operation in CustomTagsApi.
 * @export
 * @interface CustomTagsApiApiV1TagsCustomGetPostRequest
 */
export interface CustomTagsApiApiV1TagsCustomGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomTagsApiApiV1TagsCustomGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CustomTagGetPaginatedDto}
     * @memberof CustomTagsApiApiV1TagsCustomGetPost
     */
    readonly customTagGetPaginatedDto?: CustomTagGetPaginatedDto
}

/**
 * Request parameters for apiV1TagsCustomPost operation in CustomTagsApi.
 * @export
 * @interface CustomTagsApiApiV1TagsCustomPostRequest
 */
export interface CustomTagsApiApiV1TagsCustomPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomTagsApiApiV1TagsCustomPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CustomTagCreateDto}
     * @memberof CustomTagsApiApiV1TagsCustomPost
     */
    readonly customTagCreateDto?: CustomTagCreateDto
}

/**
 * Request parameters for apiV1TagsCustomSearchPost operation in CustomTagsApi.
 * @export
 * @interface CustomTagsApiApiV1TagsCustomSearchPostRequest
 */
export interface CustomTagsApiApiV1TagsCustomSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomTagsApiApiV1TagsCustomSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CustomTagSearchPaginatedDto}
     * @memberof CustomTagsApiApiV1TagsCustomSearchPost
     */
    readonly customTagSearchPaginatedDto?: CustomTagSearchPaginatedDto
}

/**
 * CustomTagsApi - object-oriented interface
 * @export
 * @class CustomTagsApi
 * @extends {BaseAPI}
 */
export class CustomTagsApi extends BaseAPI {
    /**
     * 
     * @param {CustomTagsApiApiV1TagsCustomBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTagsApi
     */
    public apiV1TagsCustomBulkDeleteDelete(requestParameters: CustomTagsApiApiV1TagsCustomBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return CustomTagsApiFp(this.configuration).apiV1TagsCustomBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomTagsApiApiV1TagsCustomCustomTagIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTagsApi
     */
    public apiV1TagsCustomCustomTagIdDelete(requestParameters: CustomTagsApiApiV1TagsCustomCustomTagIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return CustomTagsApiFp(this.configuration).apiV1TagsCustomCustomTagIdDelete(requestParameters.nexusOpsTenant, requestParameters.customTagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomTagsApiApiV1TagsCustomCustomTagIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTagsApi
     */
    public apiV1TagsCustomCustomTagIdGet(requestParameters: CustomTagsApiApiV1TagsCustomCustomTagIdGetRequest, options?: RawAxiosRequestConfig) {
        return CustomTagsApiFp(this.configuration).apiV1TagsCustomCustomTagIdGet(requestParameters.nexusOpsTenant, requestParameters.customTagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomTagsApiApiV1TagsCustomCustomTagIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTagsApi
     */
    public apiV1TagsCustomCustomTagIdPut(requestParameters: CustomTagsApiApiV1TagsCustomCustomTagIdPutRequest, options?: RawAxiosRequestConfig) {
        return CustomTagsApiFp(this.configuration).apiV1TagsCustomCustomTagIdPut(requestParameters.nexusOpsTenant, requestParameters.customTagId, requestParameters.customTagUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomTagsApiApiV1TagsCustomGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTagsApi
     */
    public apiV1TagsCustomGetPost(requestParameters: CustomTagsApiApiV1TagsCustomGetPostRequest, options?: RawAxiosRequestConfig) {
        return CustomTagsApiFp(this.configuration).apiV1TagsCustomGetPost(requestParameters.nexusOpsTenant, requestParameters.customTagGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomTagsApiApiV1TagsCustomPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTagsApi
     */
    public apiV1TagsCustomPost(requestParameters: CustomTagsApiApiV1TagsCustomPostRequest, options?: RawAxiosRequestConfig) {
        return CustomTagsApiFp(this.configuration).apiV1TagsCustomPost(requestParameters.nexusOpsTenant, requestParameters.customTagCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomTagsApiApiV1TagsCustomSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTagsApi
     */
    public apiV1TagsCustomSearchPost(requestParameters: CustomTagsApiApiV1TagsCustomSearchPostRequest, options?: RawAxiosRequestConfig) {
        return CustomTagsApiFp(this.configuration).apiV1TagsCustomSearchPost(requestParameters.nexusOpsTenant, requestParameters.customTagSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomersBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/customers/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersByContractContractIdGet: async (nexusOpsTenant: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomersByContractContractIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1CustomersByContractContractIdGet', 'contractId', contractId)
            const localVarPath = `/api/v1/customers/by-contract/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdDelete: async (nexusOpsTenant: string, customerId: string, isDeleteCascade?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdDelete', 'customerId', customerId)
            const localVarPath = `/api/v1/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdGet: async (nexusOpsTenant: string, customerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdGet', 'customerId', customerId)
            const localVarPath = `/api/v1/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {UpdateCustomerDto} [updateCustomerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdPut: async (nexusOpsTenant: string, customerId: string, updateCustomerDto?: UpdateCustomerDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdPut', 'customerId', customerId)
            const localVarPath = `/api/v1/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CustomerGetPaginatedDto} [customerGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersGetPost: async (nexusOpsTenant: string, customerGetPaginatedDto?: CustomerGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomersGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/customers/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateCustomerDto} [createCustomerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersPost: async (nexusOpsTenant: string, createCustomerDto?: CreateCustomerDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomersPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CustomerSearchPaginatedDto} [customerSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersSearchPost: async (nexusOpsTenant: string, customerSearchPaginatedDto?: CustomerSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomersSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/customers/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomersBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomersBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.apiV1CustomersBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomersByContractContractIdGet(nexusOpsTenant: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomersByContractContractIdGet(nexusOpsTenant, contractId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.apiV1CustomersByContractContractIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomersCustomerIdDelete(nexusOpsTenant: string, customerId: string, isDeleteCascade?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomersCustomerIdDelete(nexusOpsTenant, customerId, isDeleteCascade, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.apiV1CustomersCustomerIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomersCustomerIdGet(nexusOpsTenant: string, customerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomersCustomerIdGet(nexusOpsTenant, customerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.apiV1CustomersCustomerIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {UpdateCustomerDto} [updateCustomerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomersCustomerIdPut(nexusOpsTenant: string, customerId: string, updateCustomerDto?: UpdateCustomerDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomersCustomerIdPut(nexusOpsTenant, customerId, updateCustomerDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.apiV1CustomersCustomerIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CustomerGetPaginatedDto} [customerGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomersGetPost(nexusOpsTenant: string, customerGetPaginatedDto?: CustomerGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfCustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomersGetPost(nexusOpsTenant, customerGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.apiV1CustomersGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateCustomerDto} [createCustomerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomersPost(nexusOpsTenant: string, createCustomerDto?: CreateCustomerDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomersPost(nexusOpsTenant, createCustomerDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.apiV1CustomersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CustomerSearchPaginatedDto} [customerSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomersSearchPost(nexusOpsTenant: string, customerSearchPaginatedDto?: CustomerSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfCustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomersSearchPost(nexusOpsTenant, customerSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.apiV1CustomersSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * 
         * @param {CustomersApiApiV1CustomersBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersBulkDeleteDelete(requestParameters: CustomersApiApiV1CustomersBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1CustomersBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersApiApiV1CustomersByContractContractIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersByContractContractIdGet(requestParameters: CustomersApiApiV1CustomersByContractContractIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerDto> {
            return localVarFp.apiV1CustomersByContractContractIdGet(requestParameters.nexusOpsTenant, requestParameters.contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersApiApiV1CustomersCustomerIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdDelete(requestParameters: CustomersApiApiV1CustomersCustomerIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1CustomersCustomerIdDelete(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.isDeleteCascade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersApiApiV1CustomersCustomerIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdGet(requestParameters: CustomersApiApiV1CustomersCustomerIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerDto> {
            return localVarFp.apiV1CustomersCustomerIdGet(requestParameters.nexusOpsTenant, requestParameters.customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersApiApiV1CustomersCustomerIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdPut(requestParameters: CustomersApiApiV1CustomersCustomerIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerDto> {
            return localVarFp.apiV1CustomersCustomerIdPut(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.updateCustomerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersApiApiV1CustomersGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersGetPost(requestParameters: CustomersApiApiV1CustomersGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfCustomerDto> {
            return localVarFp.apiV1CustomersGetPost(requestParameters.nexusOpsTenant, requestParameters.customerGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersApiApiV1CustomersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersPost(requestParameters: CustomersApiApiV1CustomersPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerDto> {
            return localVarFp.apiV1CustomersPost(requestParameters.nexusOpsTenant, requestParameters.createCustomerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersApiApiV1CustomersSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersSearchPost(requestParameters: CustomersApiApiV1CustomersSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfCustomerDto> {
            return localVarFp.apiV1CustomersSearchPost(requestParameters.nexusOpsTenant, requestParameters.customerSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1CustomersBulkDeleteDelete operation in CustomersApi.
 * @export
 * @interface CustomersApiApiV1CustomersBulkDeleteDeleteRequest
 */
export interface CustomersApiApiV1CustomersBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomersApiApiV1CustomersBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof CustomersApiApiV1CustomersBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1CustomersByContractContractIdGet operation in CustomersApi.
 * @export
 * @interface CustomersApiApiV1CustomersByContractContractIdGetRequest
 */
export interface CustomersApiApiV1CustomersByContractContractIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomersApiApiV1CustomersByContractContractIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomersApiApiV1CustomersByContractContractIdGet
     */
    readonly contractId: string
}

/**
 * Request parameters for apiV1CustomersCustomerIdDelete operation in CustomersApi.
 * @export
 * @interface CustomersApiApiV1CustomersCustomerIdDeleteRequest
 */
export interface CustomersApiApiV1CustomersCustomerIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomersApiApiV1CustomersCustomerIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomersApiApiV1CustomersCustomerIdDelete
     */
    readonly customerId: string

    /**
     * 
     * @type {boolean}
     * @memberof CustomersApiApiV1CustomersCustomerIdDelete
     */
    readonly isDeleteCascade?: boolean
}

/**
 * Request parameters for apiV1CustomersCustomerIdGet operation in CustomersApi.
 * @export
 * @interface CustomersApiApiV1CustomersCustomerIdGetRequest
 */
export interface CustomersApiApiV1CustomersCustomerIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomersApiApiV1CustomersCustomerIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomersApiApiV1CustomersCustomerIdGet
     */
    readonly customerId: string
}

/**
 * Request parameters for apiV1CustomersCustomerIdPut operation in CustomersApi.
 * @export
 * @interface CustomersApiApiV1CustomersCustomerIdPutRequest
 */
export interface CustomersApiApiV1CustomersCustomerIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomersApiApiV1CustomersCustomerIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomersApiApiV1CustomersCustomerIdPut
     */
    readonly customerId: string

    /**
     * 
     * @type {UpdateCustomerDto}
     * @memberof CustomersApiApiV1CustomersCustomerIdPut
     */
    readonly updateCustomerDto?: UpdateCustomerDto
}

/**
 * Request parameters for apiV1CustomersGetPost operation in CustomersApi.
 * @export
 * @interface CustomersApiApiV1CustomersGetPostRequest
 */
export interface CustomersApiApiV1CustomersGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomersApiApiV1CustomersGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CustomerGetPaginatedDto}
     * @memberof CustomersApiApiV1CustomersGetPost
     */
    readonly customerGetPaginatedDto?: CustomerGetPaginatedDto
}

/**
 * Request parameters for apiV1CustomersPost operation in CustomersApi.
 * @export
 * @interface CustomersApiApiV1CustomersPostRequest
 */
export interface CustomersApiApiV1CustomersPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomersApiApiV1CustomersPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateCustomerDto}
     * @memberof CustomersApiApiV1CustomersPost
     */
    readonly createCustomerDto?: CreateCustomerDto
}

/**
 * Request parameters for apiV1CustomersSearchPost operation in CustomersApi.
 * @export
 * @interface CustomersApiApiV1CustomersSearchPostRequest
 */
export interface CustomersApiApiV1CustomersSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomersApiApiV1CustomersSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CustomerSearchPaginatedDto}
     * @memberof CustomersApiApiV1CustomersSearchPost
     */
    readonly customerSearchPaginatedDto?: CustomerSearchPaginatedDto
}

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * 
     * @param {CustomersApiApiV1CustomersBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiV1CustomersBulkDeleteDelete(requestParameters: CustomersApiApiV1CustomersBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiV1CustomersBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiApiV1CustomersByContractContractIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiV1CustomersByContractContractIdGet(requestParameters: CustomersApiApiV1CustomersByContractContractIdGetRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiV1CustomersByContractContractIdGet(requestParameters.nexusOpsTenant, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiApiV1CustomersCustomerIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiV1CustomersCustomerIdDelete(requestParameters: CustomersApiApiV1CustomersCustomerIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiV1CustomersCustomerIdDelete(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.isDeleteCascade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiApiV1CustomersCustomerIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiV1CustomersCustomerIdGet(requestParameters: CustomersApiApiV1CustomersCustomerIdGetRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiV1CustomersCustomerIdGet(requestParameters.nexusOpsTenant, requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiApiV1CustomersCustomerIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiV1CustomersCustomerIdPut(requestParameters: CustomersApiApiV1CustomersCustomerIdPutRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiV1CustomersCustomerIdPut(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.updateCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiApiV1CustomersGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiV1CustomersGetPost(requestParameters: CustomersApiApiV1CustomersGetPostRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiV1CustomersGetPost(requestParameters.nexusOpsTenant, requestParameters.customerGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiApiV1CustomersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiV1CustomersPost(requestParameters: CustomersApiApiV1CustomersPostRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiV1CustomersPost(requestParameters.nexusOpsTenant, requestParameters.createCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiApiV1CustomersSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiV1CustomersSearchPost(requestParameters: CustomersApiApiV1CustomersSearchPostRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiV1CustomersSearchPost(requestParameters.nexusOpsTenant, requestParameters.customerSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomersInvitesApi - axios parameter creator
 * @export
 */
export const CustomersInvitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {string} customerContactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdInvitesCustomerContactIdPost: async (nexusOpsTenant: string, customerId: string, customerContactId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdInvitesCustomerContactIdPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdInvitesCustomerContactIdPost', 'customerId', customerId)
            // verify required parameter 'customerContactId' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdInvitesCustomerContactIdPost', 'customerContactId', customerContactId)
            const localVarPath = `/api/v1/customers/{customerId}/invites/{customerContactId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"customerContactId"}}`, encodeURIComponent(String(customerContactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {CustomersInviteGetPaginatedDto} [customersInviteGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdInvitesGetPost: async (nexusOpsTenant: string, customerId: string, customersInviteGetPaginatedDto?: CustomersInviteGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdInvitesGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdInvitesGetPost', 'customerId', customerId)
            const localVarPath = `/api/v1/customers/{customerId}/invites/get`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customersInviteGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdInvitesInviteIdDelete: async (nexusOpsTenant: string, customerId: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdInvitesInviteIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdInvitesInviteIdDelete', 'customerId', customerId)
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdInvitesInviteIdDelete', 'inviteId', inviteId)
            const localVarPath = `/api/v1/customers/{customerId}/invites/{inviteId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdInvitesInviteIdResendPost: async (nexusOpsTenant: string, customerId: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdInvitesInviteIdResendPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdInvitesInviteIdResendPost', 'customerId', customerId)
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdInvitesInviteIdResendPost', 'inviteId', inviteId)
            const localVarPath = `/api/v1/customers/{customerId}/invites/{inviteId}/resend`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {InviteCustomerAsUserDto} [inviteCustomerAsUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdInvitesPost: async (nexusOpsTenant: string, customerId: string, inviteCustomerAsUserDto?: InviteCustomerAsUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdInvitesPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiV1CustomersCustomerIdInvitesPost', 'customerId', customerId)
            const localVarPath = `/api/v1/customers/{customerId}/invites`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteCustomerAsUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersInvitesApi - functional programming interface
 * @export
 */
export const CustomersInvitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersInvitesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {string} customerContactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomersCustomerIdInvitesCustomerContactIdPost(nexusOpsTenant: string, customerId: string, customerContactId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomersCustomerIdInvitesCustomerContactIdPost(nexusOpsTenant, customerId, customerContactId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersInvitesApi.apiV1CustomersCustomerIdInvitesCustomerContactIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {CustomersInviteGetPaginatedDto} [customersInviteGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomersCustomerIdInvitesGetPost(nexusOpsTenant: string, customerId: string, customersInviteGetPaginatedDto?: CustomersInviteGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTenantInviteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomersCustomerIdInvitesGetPost(nexusOpsTenant, customerId, customersInviteGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersInvitesApi.apiV1CustomersCustomerIdInvitesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomersCustomerIdInvitesInviteIdDelete(nexusOpsTenant: string, customerId: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomersCustomerIdInvitesInviteIdDelete(nexusOpsTenant, customerId, inviteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersInvitesApi.apiV1CustomersCustomerIdInvitesInviteIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomersCustomerIdInvitesInviteIdResendPost(nexusOpsTenant: string, customerId: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomersCustomerIdInvitesInviteIdResendPost(nexusOpsTenant, customerId, inviteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersInvitesApi.apiV1CustomersCustomerIdInvitesInviteIdResendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customerId 
         * @param {InviteCustomerAsUserDto} [inviteCustomerAsUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CustomersCustomerIdInvitesPost(nexusOpsTenant: string, customerId: string, inviteCustomerAsUserDto?: InviteCustomerAsUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantInviteDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CustomersCustomerIdInvitesPost(nexusOpsTenant, customerId, inviteCustomerAsUserDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersInvitesApi.apiV1CustomersCustomerIdInvitesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomersInvitesApi - factory interface
 * @export
 */
export const CustomersInvitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersInvitesApiFp(configuration)
    return {
        /**
         * 
         * @param {CustomersInvitesApiApiV1CustomersCustomerIdInvitesCustomerContactIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdInvitesCustomerContactIdPost(requestParameters: CustomersInvitesApiApiV1CustomersCustomerIdInvitesCustomerContactIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1CustomersCustomerIdInvitesCustomerContactIdPost(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.customerContactId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersInvitesApiApiV1CustomersCustomerIdInvitesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdInvitesGetPost(requestParameters: CustomersInvitesApiApiV1CustomersCustomerIdInvitesGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTenantInviteDto> {
            return localVarFp.apiV1CustomersCustomerIdInvitesGetPost(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.customersInviteGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdInvitesInviteIdDelete(requestParameters: CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1CustomersCustomerIdInvitesInviteIdDelete(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdResendPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdInvitesInviteIdResendPost(requestParameters: CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdResendPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1CustomersCustomerIdInvitesInviteIdResendPost(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersInvitesApiApiV1CustomersCustomerIdInvitesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CustomersCustomerIdInvitesPost(requestParameters: CustomersInvitesApiApiV1CustomersCustomerIdInvitesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<TenantInviteDto>> {
            return localVarFp.apiV1CustomersCustomerIdInvitesPost(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.inviteCustomerAsUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1CustomersCustomerIdInvitesCustomerContactIdPost operation in CustomersInvitesApi.
 * @export
 * @interface CustomersInvitesApiApiV1CustomersCustomerIdInvitesCustomerContactIdPostRequest
 */
export interface CustomersInvitesApiApiV1CustomersCustomerIdInvitesCustomerContactIdPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesCustomerContactIdPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesCustomerContactIdPost
     */
    readonly customerId: string

    /**
     * 
     * @type {string}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesCustomerContactIdPost
     */
    readonly customerContactId: string
}

/**
 * Request parameters for apiV1CustomersCustomerIdInvitesGetPost operation in CustomersInvitesApi.
 * @export
 * @interface CustomersInvitesApiApiV1CustomersCustomerIdInvitesGetPostRequest
 */
export interface CustomersInvitesApiApiV1CustomersCustomerIdInvitesGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesGetPost
     */
    readonly customerId: string

    /**
     * 
     * @type {CustomersInviteGetPaginatedDto}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesGetPost
     */
    readonly customersInviteGetPaginatedDto?: CustomersInviteGetPaginatedDto
}

/**
 * Request parameters for apiV1CustomersCustomerIdInvitesInviteIdDelete operation in CustomersInvitesApi.
 * @export
 * @interface CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdDeleteRequest
 */
export interface CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdDelete
     */
    readonly customerId: string

    /**
     * 
     * @type {string}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdDelete
     */
    readonly inviteId: string
}

/**
 * Request parameters for apiV1CustomersCustomerIdInvitesInviteIdResendPost operation in CustomersInvitesApi.
 * @export
 * @interface CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdResendPostRequest
 */
export interface CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdResendPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdResendPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdResendPost
     */
    readonly customerId: string

    /**
     * 
     * @type {string}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdResendPost
     */
    readonly inviteId: string
}

/**
 * Request parameters for apiV1CustomersCustomerIdInvitesPost operation in CustomersInvitesApi.
 * @export
 * @interface CustomersInvitesApiApiV1CustomersCustomerIdInvitesPostRequest
 */
export interface CustomersInvitesApiApiV1CustomersCustomerIdInvitesPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesPost
     */
    readonly customerId: string

    /**
     * 
     * @type {InviteCustomerAsUserDto}
     * @memberof CustomersInvitesApiApiV1CustomersCustomerIdInvitesPost
     */
    readonly inviteCustomerAsUserDto?: InviteCustomerAsUserDto
}

/**
 * CustomersInvitesApi - object-oriented interface
 * @export
 * @class CustomersInvitesApi
 * @extends {BaseAPI}
 */
export class CustomersInvitesApi extends BaseAPI {
    /**
     * 
     * @param {CustomersInvitesApiApiV1CustomersCustomerIdInvitesCustomerContactIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersInvitesApi
     */
    public apiV1CustomersCustomerIdInvitesCustomerContactIdPost(requestParameters: CustomersInvitesApiApiV1CustomersCustomerIdInvitesCustomerContactIdPostRequest, options?: RawAxiosRequestConfig) {
        return CustomersInvitesApiFp(this.configuration).apiV1CustomersCustomerIdInvitesCustomerContactIdPost(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.customerContactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersInvitesApiApiV1CustomersCustomerIdInvitesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersInvitesApi
     */
    public apiV1CustomersCustomerIdInvitesGetPost(requestParameters: CustomersInvitesApiApiV1CustomersCustomerIdInvitesGetPostRequest, options?: RawAxiosRequestConfig) {
        return CustomersInvitesApiFp(this.configuration).apiV1CustomersCustomerIdInvitesGetPost(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.customersInviteGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersInvitesApi
     */
    public apiV1CustomersCustomerIdInvitesInviteIdDelete(requestParameters: CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return CustomersInvitesApiFp(this.configuration).apiV1CustomersCustomerIdInvitesInviteIdDelete(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdResendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersInvitesApi
     */
    public apiV1CustomersCustomerIdInvitesInviteIdResendPost(requestParameters: CustomersInvitesApiApiV1CustomersCustomerIdInvitesInviteIdResendPostRequest, options?: RawAxiosRequestConfig) {
        return CustomersInvitesApiFp(this.configuration).apiV1CustomersCustomerIdInvitesInviteIdResendPost(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersInvitesApiApiV1CustomersCustomerIdInvitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersInvitesApi
     */
    public apiV1CustomersCustomerIdInvitesPost(requestParameters: CustomersInvitesApiApiV1CustomersCustomerIdInvitesPostRequest, options?: RawAxiosRequestConfig) {
        return CustomersInvitesApiFp(this.configuration).apiV1CustomersCustomerIdInvitesPost(requestParameters.nexusOpsTenant, requestParameters.customerId, requestParameters.inviteCustomerAsUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DamageCostEvaluationAggregatesApi - axios parameter creator
 * @export
 */
export const DamageCostEvaluationAggregatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsAggregatesAggregateIdDelete: async (nexusOpsTenant: string, aggregateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsAggregatesAggregateIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'aggregateId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsAggregatesAggregateIdDelete', 'aggregateId', aggregateId)
            const localVarPath = `/api/v1/damage-cost-evaluations/aggregates/{aggregateId}`
                .replace(`{${"aggregateId"}}`, encodeURIComponent(String(aggregateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet: async (nexusOpsTenant: string, aggregateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'aggregateId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet', 'aggregateId', aggregateId)
            const localVarPath = `/api/v1/damage-cost-evaluations/aggregates/{aggregateId}/download/pdf`
                .replace(`{${"aggregateId"}}`, encodeURIComponent(String(aggregateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsAggregatesAggregateIdGet: async (nexusOpsTenant: string, aggregateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsAggregatesAggregateIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'aggregateId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsAggregatesAggregateIdGet', 'aggregateId', aggregateId)
            const localVarPath = `/api/v1/damage-cost-evaluations/aggregates/{aggregateId}`
                .replace(`{${"aggregateId"}}`, encodeURIComponent(String(aggregateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete: async (nexusOpsTenant: string, aggregateId: string, itemId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'aggregateId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete', 'aggregateId', aggregateId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete', 'itemId', itemId)
            const localVarPath = `/api/v1/damage-cost-evaluations/aggregates/{aggregateId}/items/{itemId}`
                .replace(`{${"aggregateId"}}`, encodeURIComponent(String(aggregateId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageCostEvaluationAggregateGetPaginatedDto} [damageCostEvaluationAggregateGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsAggregatesGetPost: async (nexusOpsTenant: string, damageCostEvaluationAggregateGetPaginatedDto?: DamageCostEvaluationAggregateGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsAggregatesGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-cost-evaluations/aggregates/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageCostEvaluationAggregateGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageCostEvaluationAggregateSearchPaginatedDto} [damageCostEvaluationAggregateSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsAggregatesSearchPost: async (nexusOpsTenant: string, damageCostEvaluationAggregateSearchPaginatedDto?: DamageCostEvaluationAggregateSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsAggregatesSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-cost-evaluations/aggregates/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageCostEvaluationAggregateSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DamageCostEvaluationAggregatesApi - functional programming interface
 * @export
 */
export const DamageCostEvaluationAggregatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DamageCostEvaluationAggregatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsAggregatesAggregateIdDelete(nexusOpsTenant: string, aggregateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsAggregatesAggregateIdDelete(nexusOpsTenant, aggregateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationAggregatesApi.apiV1DamageCostEvaluationsAggregatesAggregateIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet(nexusOpsTenant: string, aggregateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet(nexusOpsTenant, aggregateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationAggregatesApi.apiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsAggregatesAggregateIdGet(nexusOpsTenant: string, aggregateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageCostEvaluationAggregateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsAggregatesAggregateIdGet(nexusOpsTenant, aggregateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationAggregatesApi.apiV1DamageCostEvaluationsAggregatesAggregateIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete(nexusOpsTenant: string, aggregateId: string, itemId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageCostEvaluationAggregateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete(nexusOpsTenant, aggregateId, itemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationAggregatesApi.apiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageCostEvaluationAggregateGetPaginatedDto} [damageCostEvaluationAggregateGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsAggregatesGetPost(nexusOpsTenant: string, damageCostEvaluationAggregateGetPaginatedDto?: DamageCostEvaluationAggregateGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageCostEvaluationAggregateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsAggregatesGetPost(nexusOpsTenant, damageCostEvaluationAggregateGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationAggregatesApi.apiV1DamageCostEvaluationsAggregatesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageCostEvaluationAggregateSearchPaginatedDto} [damageCostEvaluationAggregateSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsAggregatesSearchPost(nexusOpsTenant: string, damageCostEvaluationAggregateSearchPaginatedDto?: DamageCostEvaluationAggregateSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageCostEvaluationAggregateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsAggregatesSearchPost(nexusOpsTenant, damageCostEvaluationAggregateSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationAggregatesApi.apiV1DamageCostEvaluationsAggregatesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DamageCostEvaluationAggregatesApi - factory interface
 * @export
 */
export const DamageCostEvaluationAggregatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DamageCostEvaluationAggregatesApiFp(configuration)
    return {
        /**
         * 
         * @param {DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsAggregatesAggregateIdDelete(requestParameters: DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DamageCostEvaluationsAggregatesAggregateIdDelete(requestParameters.nexusOpsTenant, requestParameters.aggregateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet(requestParameters: DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.aggregateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsAggregatesAggregateIdGet(requestParameters: DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageCostEvaluationAggregateDto> {
            return localVarFp.apiV1DamageCostEvaluationsAggregatesAggregateIdGet(requestParameters.nexusOpsTenant, requestParameters.aggregateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete(requestParameters: DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageCostEvaluationAggregateDto> {
            return localVarFp.apiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete(requestParameters.nexusOpsTenant, requestParameters.aggregateId, requestParameters.itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsAggregatesGetPost(requestParameters: DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageCostEvaluationAggregateDto> {
            return localVarFp.apiV1DamageCostEvaluationsAggregatesGetPost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationAggregateGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsAggregatesSearchPost(requestParameters: DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageCostEvaluationAggregateDto> {
            return localVarFp.apiV1DamageCostEvaluationsAggregatesSearchPost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationAggregateSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1DamageCostEvaluationsAggregatesAggregateIdDelete operation in DamageCostEvaluationAggregatesApi.
 * @export
 * @interface DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDeleteRequest
 */
export interface DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDelete
     */
    readonly aggregateId: string
}

/**
 * Request parameters for apiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet operation in DamageCostEvaluationAggregatesApi.
 * @export
 * @interface DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGetRequest
 */
export interface DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet
     */
    readonly aggregateId: string
}

/**
 * Request parameters for apiV1DamageCostEvaluationsAggregatesAggregateIdGet operation in DamageCostEvaluationAggregatesApi.
 * @export
 * @interface DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdGetRequest
 */
export interface DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdGet
     */
    readonly aggregateId: string
}

/**
 * Request parameters for apiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete operation in DamageCostEvaluationAggregatesApi.
 * @export
 * @interface DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDeleteRequest
 */
export interface DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete
     */
    readonly aggregateId: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete
     */
    readonly itemId: string
}

/**
 * Request parameters for apiV1DamageCostEvaluationsAggregatesGetPost operation in DamageCostEvaluationAggregatesApi.
 * @export
 * @interface DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesGetPostRequest
 */
export interface DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DamageCostEvaluationAggregateGetPaginatedDto}
     * @memberof DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesGetPost
     */
    readonly damageCostEvaluationAggregateGetPaginatedDto?: DamageCostEvaluationAggregateGetPaginatedDto
}

/**
 * Request parameters for apiV1DamageCostEvaluationsAggregatesSearchPost operation in DamageCostEvaluationAggregatesApi.
 * @export
 * @interface DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesSearchPostRequest
 */
export interface DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DamageCostEvaluationAggregateSearchPaginatedDto}
     * @memberof DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesSearchPost
     */
    readonly damageCostEvaluationAggregateSearchPaginatedDto?: DamageCostEvaluationAggregateSearchPaginatedDto
}

/**
 * DamageCostEvaluationAggregatesApi - object-oriented interface
 * @export
 * @class DamageCostEvaluationAggregatesApi
 * @extends {BaseAPI}
 */
export class DamageCostEvaluationAggregatesApi extends BaseAPI {
    /**
     * 
     * @param {DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationAggregatesApi
     */
    public apiV1DamageCostEvaluationsAggregatesAggregateIdDelete(requestParameters: DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationAggregatesApiFp(this.configuration).apiV1DamageCostEvaluationsAggregatesAggregateIdDelete(requestParameters.nexusOpsTenant, requestParameters.aggregateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationAggregatesApi
     */
    public apiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet(requestParameters: DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationAggregatesApiFp(this.configuration).apiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.aggregateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationAggregatesApi
     */
    public apiV1DamageCostEvaluationsAggregatesAggregateIdGet(requestParameters: DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdGetRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationAggregatesApiFp(this.configuration).apiV1DamageCostEvaluationsAggregatesAggregateIdGet(requestParameters.nexusOpsTenant, requestParameters.aggregateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationAggregatesApi
     */
    public apiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete(requestParameters: DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationAggregatesApiFp(this.configuration).apiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete(requestParameters.nexusOpsTenant, requestParameters.aggregateId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationAggregatesApi
     */
    public apiV1DamageCostEvaluationsAggregatesGetPost(requestParameters: DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesGetPostRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationAggregatesApiFp(this.configuration).apiV1DamageCostEvaluationsAggregatesGetPost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationAggregateGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationAggregatesApi
     */
    public apiV1DamageCostEvaluationsAggregatesSearchPost(requestParameters: DamageCostEvaluationAggregatesApiApiV1DamageCostEvaluationsAggregatesSearchPostRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationAggregatesApiFp(this.configuration).apiV1DamageCostEvaluationsAggregatesSearchPost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationAggregateSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DamageCostEvaluationsApi - axios parameter creator
 * @export
 */
export const DamageCostEvaluationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-cost-evaluations/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsByIdsGet: async (nexusOpsTenant: string, ids?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsByIdsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-cost-evaluations/by-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsCountersGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsCountersGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-cost-evaluations/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {AddDamageCostEvaluationToContractDto} [addDamageCostEvaluationToContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut: async (nexusOpsTenant: string, damageCostEvaluationId: string, addDamageCostEvaluationToContractDto?: AddDamageCostEvaluationToContractDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageCostEvaluationId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut', 'damageCostEvaluationId', damageCostEvaluationId)
            const localVarPath = `/api/v1/damage-cost-evaluations/{damageCostEvaluationId}/add-contract`
                .replace(`{${"damageCostEvaluationId"}}`, encodeURIComponent(String(damageCostEvaluationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDamageCostEvaluationToContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdDelete: async (nexusOpsTenant: string, damageCostEvaluationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageCostEvaluationId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdDelete', 'damageCostEvaluationId', damageCostEvaluationId)
            const localVarPath = `/api/v1/damage-cost-evaluations/{damageCostEvaluationId}`
                .replace(`{${"damageCostEvaluationId"}}`, encodeURIComponent(String(damageCostEvaluationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet: async (nexusOpsTenant: string, damageCostEvaluationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageCostEvaluationId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet', 'damageCostEvaluationId', damageCostEvaluationId)
            const localVarPath = `/api/v1/damage-cost-evaluations/{damageCostEvaluationId}/download/pdf`
                .replace(`{${"damageCostEvaluationId"}}`, encodeURIComponent(String(damageCostEvaluationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdGet: async (nexusOpsTenant: string, damageCostEvaluationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageCostEvaluationId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdGet', 'damageCostEvaluationId', damageCostEvaluationId)
            const localVarPath = `/api/v1/damage-cost-evaluations/{damageCostEvaluationId}`
                .replace(`{${"damageCostEvaluationId"}}`, encodeURIComponent(String(damageCostEvaluationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {DamageCostEvaluationUpdateDto} [damageCostEvaluationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdPut: async (nexusOpsTenant: string, damageCostEvaluationId: string, damageCostEvaluationUpdateDto?: DamageCostEvaluationUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageCostEvaluationId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdPut', 'damageCostEvaluationId', damageCostEvaluationId)
            const localVarPath = `/api/v1/damage-cost-evaluations/{damageCostEvaluationId}`
                .replace(`{${"damageCostEvaluationId"}}`, encodeURIComponent(String(damageCostEvaluationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageCostEvaluationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGet: async (nexusOpsTenant: string, damageCostEvaluationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageCostEvaluationId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGet', 'damageCostEvaluationId', damageCostEvaluationId)
            const localVarPath = `/api/v1/damage-cost-evaluations/{damageCostEvaluationId}/repair-specs`
                .replace(`{${"damageCostEvaluationId"}}`, encodeURIComponent(String(damageCostEvaluationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost: async (nexusOpsTenant: string, damageCostEvaluationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageCostEvaluationId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost', 'damageCostEvaluationId', damageCostEvaluationId)
            const localVarPath = `/api/v1/damage-cost-evaluations/{damageCostEvaluationId}/send-to-customer`
                .replace(`{${"damageCostEvaluationId"}}`, encodeURIComponent(String(damageCostEvaluationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {DamageCostEvaluationCompleteDto} [damageCostEvaluationCompleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost: async (nexusOpsTenant: string, damageCostEvaluationId: string, damageCostEvaluationCompleteDto?: DamageCostEvaluationCompleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageCostEvaluationId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost', 'damageCostEvaluationId', damageCostEvaluationId)
            const localVarPath = `/api/v1/damage-cost-evaluations/{damageCostEvaluationId}/stage/complete`
                .replace(`{${"damageCostEvaluationId"}}`, encodeURIComponent(String(damageCostEvaluationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageCostEvaluationCompleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {GeneralStageUpdateDtoOfDamageCostEvaluationStage} [generalStageUpdateDtoOfDamageCostEvaluationStage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut: async (nexusOpsTenant: string, damageCostEvaluationId: string, generalStageUpdateDtoOfDamageCostEvaluationStage?: GeneralStageUpdateDtoOfDamageCostEvaluationStage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageCostEvaluationId' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut', 'damageCostEvaluationId', damageCostEvaluationId)
            const localVarPath = `/api/v1/damage-cost-evaluations/{damageCostEvaluationId}/stage`
                .replace(`{${"damageCostEvaluationId"}}`, encodeURIComponent(String(damageCostEvaluationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalStageUpdateDtoOfDamageCostEvaluationStage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageCostEvaluationGetPaginatedDto} [damageCostEvaluationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsGetPost: async (nexusOpsTenant: string, damageCostEvaluationGetPaginatedDto?: DamageCostEvaluationGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-cost-evaluations/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageCostEvaluationGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageCostEvaluationCreateDto} [damageCostEvaluationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsPost: async (nexusOpsTenant: string, damageCostEvaluationCreateDto?: DamageCostEvaluationCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-cost-evaluations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageCostEvaluationCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageCostEvaluationSearchPaginatedDto} [damageCostEvaluationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsSearchPost: async (nexusOpsTenant: string, damageCostEvaluationSearchPaginatedDto?: DamageCostEvaluationSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageCostEvaluationsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-cost-evaluations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageCostEvaluationSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DamageCostEvaluationsApi - functional programming interface
 * @export
 */
export const DamageCostEvaluationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DamageCostEvaluationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsByIdsGet(nexusOpsTenant: string, ids?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DamageCostEvaluationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsByIdsGet(nexusOpsTenant, ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsByIdsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsCountersGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageCostEvaluationCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsCountersGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsCountersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {AddDamageCostEvaluationToContractDto} [addDamageCostEvaluationToContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut(nexusOpsTenant: string, damageCostEvaluationId: string, addDamageCostEvaluationToContractDto?: AddDamageCostEvaluationToContractDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageCostEvaluationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut(nexusOpsTenant, damageCostEvaluationId, addDamageCostEvaluationToContractDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsDamageCostEvaluationIdDelete(nexusOpsTenant: string, damageCostEvaluationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsDamageCostEvaluationIdDelete(nexusOpsTenant, damageCostEvaluationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet(nexusOpsTenant: string, damageCostEvaluationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet(nexusOpsTenant, damageCostEvaluationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsDamageCostEvaluationIdGet(nexusOpsTenant: string, damageCostEvaluationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageCostEvaluationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsDamageCostEvaluationIdGet(nexusOpsTenant, damageCostEvaluationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {DamageCostEvaluationUpdateDto} [damageCostEvaluationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsDamageCostEvaluationIdPut(nexusOpsTenant: string, damageCostEvaluationId: string, damageCostEvaluationUpdateDto?: DamageCostEvaluationUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageCostEvaluationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsDamageCostEvaluationIdPut(nexusOpsTenant, damageCostEvaluationId, damageCostEvaluationUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGet(nexusOpsTenant: string, damageCostEvaluationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairSpecDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGet(nexusOpsTenant, damageCostEvaluationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost(nexusOpsTenant: string, damageCostEvaluationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost(nexusOpsTenant, damageCostEvaluationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {DamageCostEvaluationCompleteDto} [damageCostEvaluationCompleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost(nexusOpsTenant: string, damageCostEvaluationId: string, damageCostEvaluationCompleteDto?: DamageCostEvaluationCompleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageCostEvaluationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost(nexusOpsTenant, damageCostEvaluationId, damageCostEvaluationCompleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageCostEvaluationId 
         * @param {GeneralStageUpdateDtoOfDamageCostEvaluationStage} [generalStageUpdateDtoOfDamageCostEvaluationStage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut(nexusOpsTenant: string, damageCostEvaluationId: string, generalStageUpdateDtoOfDamageCostEvaluationStage?: GeneralStageUpdateDtoOfDamageCostEvaluationStage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageCostEvaluationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut(nexusOpsTenant, damageCostEvaluationId, generalStageUpdateDtoOfDamageCostEvaluationStage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageCostEvaluationGetPaginatedDto} [damageCostEvaluationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsGetPost(nexusOpsTenant: string, damageCostEvaluationGetPaginatedDto?: DamageCostEvaluationGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageCostEvaluationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsGetPost(nexusOpsTenant, damageCostEvaluationGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageCostEvaluationCreateDto} [damageCostEvaluationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsPost(nexusOpsTenant: string, damageCostEvaluationCreateDto?: DamageCostEvaluationCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageCostEvaluationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsPost(nexusOpsTenant, damageCostEvaluationCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageCostEvaluationSearchPaginatedDto} [damageCostEvaluationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageCostEvaluationsSearchPost(nexusOpsTenant: string, damageCostEvaluationSearchPaginatedDto?: DamageCostEvaluationSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageCostEvaluationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageCostEvaluationsSearchPost(nexusOpsTenant, damageCostEvaluationSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageCostEvaluationsApi.apiV1DamageCostEvaluationsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DamageCostEvaluationsApi - factory interface
 * @export
 */
export const DamageCostEvaluationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DamageCostEvaluationsApiFp(configuration)
    return {
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsBulkDeleteDelete(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DamageCostEvaluationsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsByIdsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsByIdsGet(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsByIdsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DamageCostEvaluationDto>> {
            return localVarFp.apiV1DamageCostEvaluationsByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsCountersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsCountersGet(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsCountersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageCostEvaluationCountersDto> {
            return localVarFp.apiV1DamageCostEvaluationsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageCostEvaluationDto> {
            return localVarFp.apiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, requestParameters.addDamageCostEvaluationToContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdDelete(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DamageCostEvaluationsDamageCostEvaluationIdDelete(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdGet(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageCostEvaluationDto> {
            return localVarFp.apiV1DamageCostEvaluationsDamageCostEvaluationIdGet(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdPut(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageCostEvaluationDto> {
            return localVarFp.apiV1DamageCostEvaluationsDamageCostEvaluationIdPut(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, requestParameters.damageCostEvaluationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGet(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<RepairSpecDto>> {
            return localVarFp.apiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGet(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageCostEvaluationDto> {
            return localVarFp.apiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, requestParameters.damageCostEvaluationCompleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStagePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStagePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageCostEvaluationDto> {
            return localVarFp.apiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, requestParameters.generalStageUpdateDtoOfDamageCostEvaluationStage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsGetPost(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageCostEvaluationDto> {
            return localVarFp.apiV1DamageCostEvaluationsGetPost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsPost(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageCostEvaluationDto> {
            return localVarFp.apiV1DamageCostEvaluationsPost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageCostEvaluationsSearchPost(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageCostEvaluationDto> {
            return localVarFp.apiV1DamageCostEvaluationsSearchPost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1DamageCostEvaluationsBulkDeleteDelete operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsBulkDeleteDeleteRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1DamageCostEvaluationsByIdsGet operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsByIdsGetRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsByIdsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsByIdsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsByIdsGet
     */
    readonly ids?: Array<string>
}

/**
 * Request parameters for apiV1DamageCostEvaluationsCountersGet operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsCountersGetRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsCountersGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsCountersGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPutRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut
     */
    readonly damageCostEvaluationId: string

    /**
     * 
     * @type {AddDamageCostEvaluationToContractDto}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut
     */
    readonly addDamageCostEvaluationToContractDto?: AddDamageCostEvaluationToContractDto
}

/**
 * Request parameters for apiV1DamageCostEvaluationsDamageCostEvaluationIdDelete operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDeleteRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDelete
     */
    readonly damageCostEvaluationId: string
}

/**
 * Request parameters for apiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGetRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet
     */
    readonly damageCostEvaluationId: string
}

/**
 * Request parameters for apiV1DamageCostEvaluationsDamageCostEvaluationIdGet operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdGetRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdGet
     */
    readonly damageCostEvaluationId: string
}

/**
 * Request parameters for apiV1DamageCostEvaluationsDamageCostEvaluationIdPut operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdPutRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdPut
     */
    readonly damageCostEvaluationId: string

    /**
     * 
     * @type {DamageCostEvaluationUpdateDto}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdPut
     */
    readonly damageCostEvaluationUpdateDto?: DamageCostEvaluationUpdateDto
}

/**
 * Request parameters for apiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGet operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGetRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGet
     */
    readonly damageCostEvaluationId: string
}

/**
 * Request parameters for apiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPostRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost
     */
    readonly damageCostEvaluationId: string
}

/**
 * Request parameters for apiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePostRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost
     */
    readonly damageCostEvaluationId: string

    /**
     * 
     * @type {DamageCostEvaluationCompleteDto}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost
     */
    readonly damageCostEvaluationCompleteDto?: DamageCostEvaluationCompleteDto
}

/**
 * Request parameters for apiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStagePutRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStagePutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut
     */
    readonly damageCostEvaluationId: string

    /**
     * 
     * @type {GeneralStageUpdateDtoOfDamageCostEvaluationStage}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut
     */
    readonly generalStageUpdateDtoOfDamageCostEvaluationStage?: GeneralStageUpdateDtoOfDamageCostEvaluationStage
}

/**
 * Request parameters for apiV1DamageCostEvaluationsGetPost operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsGetPostRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DamageCostEvaluationGetPaginatedDto}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsGetPost
     */
    readonly damageCostEvaluationGetPaginatedDto?: DamageCostEvaluationGetPaginatedDto
}

/**
 * Request parameters for apiV1DamageCostEvaluationsPost operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsPostRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DamageCostEvaluationCreateDto}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsPost
     */
    readonly damageCostEvaluationCreateDto?: DamageCostEvaluationCreateDto
}

/**
 * Request parameters for apiV1DamageCostEvaluationsSearchPost operation in DamageCostEvaluationsApi.
 * @export
 * @interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsSearchPostRequest
 */
export interface DamageCostEvaluationsApiApiV1DamageCostEvaluationsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DamageCostEvaluationSearchPaginatedDto}
     * @memberof DamageCostEvaluationsApiApiV1DamageCostEvaluationsSearchPost
     */
    readonly damageCostEvaluationSearchPaginatedDto?: DamageCostEvaluationSearchPaginatedDto
}

/**
 * DamageCostEvaluationsApi - object-oriented interface
 * @export
 * @class DamageCostEvaluationsApi
 * @extends {BaseAPI}
 */
export class DamageCostEvaluationsApi extends BaseAPI {
    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsBulkDeleteDelete(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsByIdsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsByIdsGet(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsByIdsGetRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsCountersGet(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsCountersGetRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPutRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsDamageCostEvaluationIdAddContractPut(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, requestParameters.addDamageCostEvaluationToContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsDamageCostEvaluationIdDelete(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsDamageCostEvaluationIdDelete(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsDamageCostEvaluationIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsDamageCostEvaluationIdGet(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdGetRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsDamageCostEvaluationIdGet(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsDamageCostEvaluationIdPut(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdPutRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsDamageCostEvaluationIdPut(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, requestParameters.damageCostEvaluationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGet(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGetRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsDamageCostEvaluationIdRepairSpecsGet(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPostRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsDamageCostEvaluationIdSendToCustomerPost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePostRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsDamageCostEvaluationIdStageCompletePost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, requestParameters.damageCostEvaluationCompleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStagePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsDamageCostEvaluationIdStagePutRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsDamageCostEvaluationIdStagePut(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationId, requestParameters.generalStageUpdateDtoOfDamageCostEvaluationStage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsGetPost(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsGetPostRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsGetPost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsPost(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsPostRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsPost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageCostEvaluationsApiApiV1DamageCostEvaluationsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageCostEvaluationsApi
     */
    public apiV1DamageCostEvaluationsSearchPost(requestParameters: DamageCostEvaluationsApiApiV1DamageCostEvaluationsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return DamageCostEvaluationsApiFp(this.configuration).apiV1DamageCostEvaluationsSearchPost(requestParameters.nexusOpsTenant, requestParameters.damageCostEvaluationSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DamageDetectionAggregatesApi - axios parameter creator
 * @export
 */
export const DamageDetectionAggregatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesAggregateIdDelete: async (nexusOpsTenant: string, aggregateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsAggregatesAggregateIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'aggregateId' is not null or undefined
            assertParamExists('apiV1DamageDetectionsAggregatesAggregateIdDelete', 'aggregateId', aggregateId)
            const localVarPath = `/api/v1/damage-detections/aggregates/{aggregateId}`
                .replace(`{${"aggregateId"}}`, encodeURIComponent(String(aggregateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet: async (nexusOpsTenant: string, aggregateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'aggregateId' is not null or undefined
            assertParamExists('apiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet', 'aggregateId', aggregateId)
            const localVarPath = `/api/v1/damage-detections/aggregates/{aggregateId}/download/pdf`
                .replace(`{${"aggregateId"}}`, encodeURIComponent(String(aggregateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesAggregateIdGet: async (nexusOpsTenant: string, aggregateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsAggregatesAggregateIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'aggregateId' is not null or undefined
            assertParamExists('apiV1DamageDetectionsAggregatesAggregateIdGet', 'aggregateId', aggregateId)
            const localVarPath = `/api/v1/damage-detections/aggregates/{aggregateId}`
                .replace(`{${"aggregateId"}}`, encodeURIComponent(String(aggregateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete: async (nexusOpsTenant: string, aggregateId: string, itemId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'aggregateId' is not null or undefined
            assertParamExists('apiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete', 'aggregateId', aggregateId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('apiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete', 'itemId', itemId)
            const localVarPath = `/api/v1/damage-detections/aggregates/{aggregateId}/items/{itemId}`
                .replace(`{${"aggregateId"}}`, encodeURIComponent(String(aggregateId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {StartCostEvaluationDto} [startCostEvaluationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost: async (nexusOpsTenant: string, aggregateId: string, startCostEvaluationDto?: StartCostEvaluationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'aggregateId' is not null or undefined
            assertParamExists('apiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost', 'aggregateId', aggregateId)
            const localVarPath = `/api/v1/damage-detections/aggregates/{aggregateId}/start-cost-evaluation`
                .replace(`{${"aggregateId"}}`, encodeURIComponent(String(aggregateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startCostEvaluationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionAggregateGetPaginatedDto} [damageDetectionAggregateGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesGetPost: async (nexusOpsTenant: string, damageDetectionAggregateGetPaginatedDto?: DamageDetectionAggregateGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsAggregatesGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-detections/aggregates/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageDetectionAggregateGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionAggregateSearchPaginatedDto} [damageDetectionAggregateSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesSearchPost: async (nexusOpsTenant: string, damageDetectionAggregateSearchPaginatedDto?: DamageDetectionAggregateSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsAggregatesSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-detections/aggregates/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageDetectionAggregateSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DamageDetectionAggregatesApi - functional programming interface
 * @export
 */
export const DamageDetectionAggregatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DamageDetectionAggregatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsAggregatesAggregateIdDelete(nexusOpsTenant: string, aggregateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsAggregatesAggregateIdDelete(nexusOpsTenant, aggregateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesAggregateIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet(nexusOpsTenant: string, aggregateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet(nexusOpsTenant, aggregateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsAggregatesAggregateIdGet(nexusOpsTenant: string, aggregateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageDetectionAggregateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsAggregatesAggregateIdGet(nexusOpsTenant, aggregateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesAggregateIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete(nexusOpsTenant: string, aggregateId: string, itemId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageDetectionAggregateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete(nexusOpsTenant, aggregateId, itemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} aggregateId 
         * @param {StartCostEvaluationDto} [startCostEvaluationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost(nexusOpsTenant: string, aggregateId: string, startCostEvaluationDto?: StartCostEvaluationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartCostEvaluationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost(nexusOpsTenant, aggregateId, startCostEvaluationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionAggregateGetPaginatedDto} [damageDetectionAggregateGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsAggregatesGetPost(nexusOpsTenant: string, damageDetectionAggregateGetPaginatedDto?: DamageDetectionAggregateGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageDetectionAggregateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsAggregatesGetPost(nexusOpsTenant, damageDetectionAggregateGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionAggregateSearchPaginatedDto} [damageDetectionAggregateSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsAggregatesSearchPost(nexusOpsTenant: string, damageDetectionAggregateSearchPaginatedDto?: DamageDetectionAggregateSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageDetectionAggregateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsAggregatesSearchPost(nexusOpsTenant, damageDetectionAggregateSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DamageDetectionAggregatesApi - factory interface
 * @export
 */
export const DamageDetectionAggregatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DamageDetectionAggregatesApiFp(configuration)
    return {
        /**
         * 
         * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesAggregateIdDelete(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DamageDetectionsAggregatesAggregateIdDelete(requestParameters.nexusOpsTenant, requestParameters.aggregateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.aggregateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesAggregateIdGet(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageDetectionAggregateDto> {
            return localVarFp.apiV1DamageDetectionsAggregatesAggregateIdGet(requestParameters.nexusOpsTenant, requestParameters.aggregateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageDetectionAggregateDto> {
            return localVarFp.apiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete(requestParameters.nexusOpsTenant, requestParameters.aggregateId, requestParameters.itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<StartCostEvaluationResponseDto> {
            return localVarFp.apiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost(requestParameters.nexusOpsTenant, requestParameters.aggregateId, requestParameters.startCostEvaluationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesGetPost(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageDetectionAggregateDto> {
            return localVarFp.apiV1DamageDetectionsAggregatesGetPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionAggregateGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsAggregatesSearchPost(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageDetectionAggregateDto> {
            return localVarFp.apiV1DamageDetectionsAggregatesSearchPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionAggregateSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1DamageDetectionsAggregatesAggregateIdDelete operation in DamageDetectionAggregatesApi.
 * @export
 * @interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDeleteRequest
 */
export interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDelete
     */
    readonly aggregateId: string
}

/**
 * Request parameters for apiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet operation in DamageDetectionAggregatesApi.
 * @export
 * @interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGetRequest
 */
export interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet
     */
    readonly aggregateId: string
}

/**
 * Request parameters for apiV1DamageDetectionsAggregatesAggregateIdGet operation in DamageDetectionAggregatesApi.
 * @export
 * @interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdGetRequest
 */
export interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdGet
     */
    readonly aggregateId: string
}

/**
 * Request parameters for apiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete operation in DamageDetectionAggregatesApi.
 * @export
 * @interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDeleteRequest
 */
export interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete
     */
    readonly aggregateId: string

    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete
     */
    readonly itemId: string
}

/**
 * Request parameters for apiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost operation in DamageDetectionAggregatesApi.
 * @export
 * @interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPostRequest
 */
export interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost
     */
    readonly aggregateId: string

    /**
     * 
     * @type {StartCostEvaluationDto}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost
     */
    readonly startCostEvaluationDto?: StartCostEvaluationDto
}

/**
 * Request parameters for apiV1DamageDetectionsAggregatesGetPost operation in DamageDetectionAggregatesApi.
 * @export
 * @interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesGetPostRequest
 */
export interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DamageDetectionAggregateGetPaginatedDto}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesGetPost
     */
    readonly damageDetectionAggregateGetPaginatedDto?: DamageDetectionAggregateGetPaginatedDto
}

/**
 * Request parameters for apiV1DamageDetectionsAggregatesSearchPost operation in DamageDetectionAggregatesApi.
 * @export
 * @interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesSearchPostRequest
 */
export interface DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DamageDetectionAggregateSearchPaginatedDto}
     * @memberof DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesSearchPost
     */
    readonly damageDetectionAggregateSearchPaginatedDto?: DamageDetectionAggregateSearchPaginatedDto
}

/**
 * DamageDetectionAggregatesApi - object-oriented interface
 * @export
 * @class DamageDetectionAggregatesApi
 * @extends {BaseAPI}
 */
export class DamageDetectionAggregatesApi extends BaseAPI {
    /**
     * 
     * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionAggregatesApi
     */
    public apiV1DamageDetectionsAggregatesAggregateIdDelete(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionAggregatesApiFp(this.configuration).apiV1DamageDetectionsAggregatesAggregateIdDelete(requestParameters.nexusOpsTenant, requestParameters.aggregateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionAggregatesApi
     */
    public apiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionAggregatesApiFp(this.configuration).apiV1DamageDetectionsAggregatesAggregateIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.aggregateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionAggregatesApi
     */
    public apiV1DamageDetectionsAggregatesAggregateIdGet(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdGetRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionAggregatesApiFp(this.configuration).apiV1DamageDetectionsAggregatesAggregateIdGet(requestParameters.nexusOpsTenant, requestParameters.aggregateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionAggregatesApi
     */
    public apiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionAggregatesApiFp(this.configuration).apiV1DamageDetectionsAggregatesAggregateIdItemsItemIdDelete(requestParameters.nexusOpsTenant, requestParameters.aggregateId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionAggregatesApi
     */
    public apiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPostRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionAggregatesApiFp(this.configuration).apiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost(requestParameters.nexusOpsTenant, requestParameters.aggregateId, requestParameters.startCostEvaluationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionAggregatesApi
     */
    public apiV1DamageDetectionsAggregatesGetPost(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesGetPostRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionAggregatesApiFp(this.configuration).apiV1DamageDetectionsAggregatesGetPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionAggregateGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionAggregatesApi
     */
    public apiV1DamageDetectionsAggregatesSearchPost(requestParameters: DamageDetectionAggregatesApiApiV1DamageDetectionsAggregatesSearchPostRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionAggregatesApiFp(this.configuration).apiV1DamageDetectionsAggregatesSearchPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionAggregateSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DamageDetectionsApi - axios parameter creator
 * @export
 */
export const DamageDetectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionBulkDeleteDto} [damageDetectionBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsBulkDeleteDelete: async (nexusOpsTenant: string, damageDetectionBulkDeleteDto?: DamageDetectionBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-detections/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageDetectionBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsByIdsGet: async (nexusOpsTenant: string, ids?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsByIdsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-detections/by-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsCountersGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsCountersGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-detections/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {AddDamageDetectionToContractDto} [addDamageDetectionToContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdAddToContractPost: async (nexusOpsTenant: string, damageDetectionId: string, addDamageDetectionToContractDto?: AddDamageDetectionToContractDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdAddToContractPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageDetectionId' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdAddToContractPost', 'damageDetectionId', damageDetectionId)
            const localVarPath = `/api/v1/damage-detections/{damageDetectionId}/add-to-contract`
                .replace(`{${"damageDetectionId"}}`, encodeURIComponent(String(damageDetectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDamageDetectionToContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {boolean} [isDeleteCascade] 
         * @param {DamageDetectionDeleteDto} [damageDetectionDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdDelete: async (nexusOpsTenant: string, damageDetectionId: string, isDeleteCascade?: boolean, damageDetectionDeleteDto?: DamageDetectionDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageDetectionId' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdDelete', 'damageDetectionId', damageDetectionId)
            const localVarPath = `/api/v1/damage-detections/{damageDetectionId}`
                .replace(`{${"damageDetectionId"}}`, encodeURIComponent(String(damageDetectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageDetectionDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdDownloadPdfGet: async (nexusOpsTenant: string, damageDetectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdDownloadPdfGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageDetectionId' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdDownloadPdfGet', 'damageDetectionId', damageDetectionId)
            const localVarPath = `/api/v1/damage-detections/{damageDetectionId}/download/pdf`
                .replace(`{${"damageDetectionId"}}`, encodeURIComponent(String(damageDetectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdGet: async (nexusOpsTenant: string, damageDetectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageDetectionId' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdGet', 'damageDetectionId', damageDetectionId)
            const localVarPath = `/api/v1/damage-detections/{damageDetectionId}`
                .replace(`{${"damageDetectionId"}}`, encodeURIComponent(String(damageDetectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {DamageDetectionMigrateToNewVisualModelDto} [damageDetectionMigrateToNewVisualModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost: async (nexusOpsTenant: string, damageDetectionId: string, damageDetectionMigrateToNewVisualModelDto?: DamageDetectionMigrateToNewVisualModelDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageDetectionId' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost', 'damageDetectionId', damageDetectionId)
            const localVarPath = `/api/v1/damage-detections/{damageDetectionId}/migrate-to-new-visual-model`
                .replace(`{${"damageDetectionId"}}`, encodeURIComponent(String(damageDetectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageDetectionMigrateToNewVisualModelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {UpdateDamageDetectionDto} [updateDamageDetectionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdPut: async (nexusOpsTenant: string, damageDetectionId: string, updateDamageDetectionDto?: UpdateDamageDetectionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageDetectionId' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdPut', 'damageDetectionId', damageDetectionId)
            const localVarPath = `/api/v1/damage-detections/{damageDetectionId}`
                .replace(`{${"damageDetectionId"}}`, encodeURIComponent(String(damageDetectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDamageDetectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdSendToCustomerPost: async (nexusOpsTenant: string, damageDetectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdSendToCustomerPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageDetectionId' is not null or undefined
            assertParamExists('apiV1DamageDetectionsDamageDetectionIdSendToCustomerPost', 'damageDetectionId', damageDetectionId)
            const localVarPath = `/api/v1/damage-detections/{damageDetectionId}/send-to-customer`
                .replace(`{${"damageDetectionId"}}`, encodeURIComponent(String(damageDetectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionGetPaginatedDto} [damageDetectionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsGetPost: async (nexusOpsTenant: string, damageDetectionGetPaginatedDto?: DamageDetectionGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-detections/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageDetectionGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateDamageDetectionDto} [createDamageDetectionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsPost: async (nexusOpsTenant: string, createDamageDetectionDto?: CreateDamageDetectionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-detections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDamageDetectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionSearchPaginatedDto} [damageDetectionSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsSearchPost: async (nexusOpsTenant: string, damageDetectionSearchPaginatedDto?: DamageDetectionSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DamageDetectionsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/damage-detections/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageDetectionSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DamageDetectionsApi - functional programming interface
 * @export
 */
export const DamageDetectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DamageDetectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionBulkDeleteDto} [damageDetectionBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsBulkDeleteDelete(nexusOpsTenant: string, damageDetectionBulkDeleteDto?: DamageDetectionBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsBulkDeleteDelete(nexusOpsTenant, damageDetectionBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionsApi.apiV1DamageDetectionsBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsByIdsGet(nexusOpsTenant: string, ids?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DamageDetectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsByIdsGet(nexusOpsTenant, ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionsApi.apiV1DamageDetectionsByIdsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsCountersGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageDetectionCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsCountersGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionsApi.apiV1DamageDetectionsCountersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {AddDamageDetectionToContractDto} [addDamageDetectionToContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsDamageDetectionIdAddToContractPost(nexusOpsTenant: string, damageDetectionId: string, addDamageDetectionToContractDto?: AddDamageDetectionToContractDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsDamageDetectionIdAddToContractPost(nexusOpsTenant, damageDetectionId, addDamageDetectionToContractDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdAddToContractPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {boolean} [isDeleteCascade] 
         * @param {DamageDetectionDeleteDto} [damageDetectionDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsDamageDetectionIdDelete(nexusOpsTenant: string, damageDetectionId: string, isDeleteCascade?: boolean, damageDetectionDeleteDto?: DamageDetectionDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsDamageDetectionIdDelete(nexusOpsTenant, damageDetectionId, isDeleteCascade, damageDetectionDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsDamageDetectionIdDownloadPdfGet(nexusOpsTenant: string, damageDetectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsDamageDetectionIdDownloadPdfGet(nexusOpsTenant, damageDetectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdDownloadPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsDamageDetectionIdGet(nexusOpsTenant: string, damageDetectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsDamageDetectionIdGet(nexusOpsTenant, damageDetectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {DamageDetectionMigrateToNewVisualModelDto} [damageDetectionMigrateToNewVisualModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost(nexusOpsTenant: string, damageDetectionId: string, damageDetectionMigrateToNewVisualModelDto?: DamageDetectionMigrateToNewVisualModelDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost(nexusOpsTenant, damageDetectionId, damageDetectionMigrateToNewVisualModelDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {UpdateDamageDetectionDto} [updateDamageDetectionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsDamageDetectionIdPut(nexusOpsTenant: string, damageDetectionId: string, updateDamageDetectionDto?: UpdateDamageDetectionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsDamageDetectionIdPut(nexusOpsTenant, damageDetectionId, updateDamageDetectionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsDamageDetectionIdSendToCustomerPost(nexusOpsTenant: string, damageDetectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsDamageDetectionIdSendToCustomerPost(nexusOpsTenant, damageDetectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdSendToCustomerPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionGetPaginatedDto} [damageDetectionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsGetPost(nexusOpsTenant: string, damageDetectionGetPaginatedDto?: DamageDetectionGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsGetPost(nexusOpsTenant, damageDetectionGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionsApi.apiV1DamageDetectionsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateDamageDetectionDto} [createDamageDetectionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsPost(nexusOpsTenant: string, createDamageDetectionDto?: CreateDamageDetectionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsPost(nexusOpsTenant, createDamageDetectionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionsApi.apiV1DamageDetectionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionSearchPaginatedDto} [damageDetectionSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DamageDetectionsSearchPost(nexusOpsTenant: string, damageDetectionSearchPaginatedDto?: DamageDetectionSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DamageDetectionsSearchPost(nexusOpsTenant, damageDetectionSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageDetectionsApi.apiV1DamageDetectionsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DamageDetectionsApi - factory interface
 * @export
 */
export const DamageDetectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DamageDetectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {DamageDetectionsApiApiV1DamageDetectionsBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsBulkDeleteDelete(requestParameters: DamageDetectionsApiApiV1DamageDetectionsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DamageDetectionsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.damageDetectionBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionsApiApiV1DamageDetectionsByIdsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsByIdsGet(requestParameters: DamageDetectionsApiApiV1DamageDetectionsByIdsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DamageDetectionDto>> {
            return localVarFp.apiV1DamageDetectionsByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionsApiApiV1DamageDetectionsCountersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsCountersGet(requestParameters: DamageDetectionsApiApiV1DamageDetectionsCountersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageDetectionCountersDto> {
            return localVarFp.apiV1DamageDetectionsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdAddToContractPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdAddToContractPost(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdAddToContractPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageDetectionDto> {
            return localVarFp.apiV1DamageDetectionsDamageDetectionIdAddToContractPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, requestParameters.addDamageDetectionToContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdDelete(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DamageDetectionsDamageDetectionIdDelete(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, requestParameters.isDeleteCascade, requestParameters.damageDetectionDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDownloadPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdDownloadPdfGet(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DamageDetectionsDamageDetectionIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdGet(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageDetectionDto> {
            return localVarFp.apiV1DamageDetectionsDamageDetectionIdGet(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageDetectionDto> {
            return localVarFp.apiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, requestParameters.damageDetectionMigrateToNewVisualModelDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdPut(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageDetectionDto> {
            return localVarFp.apiV1DamageDetectionsDamageDetectionIdPut(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, requestParameters.updateDamageDetectionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdSendToCustomerPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsDamageDetectionIdSendToCustomerPost(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdSendToCustomerPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DamageDetectionsDamageDetectionIdSendToCustomerPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionsApiApiV1DamageDetectionsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsGetPost(requestParameters: DamageDetectionsApiApiV1DamageDetectionsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageDetectionDto> {
            return localVarFp.apiV1DamageDetectionsGetPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionsApiApiV1DamageDetectionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsPost(requestParameters: DamageDetectionsApiApiV1DamageDetectionsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageDetectionDto> {
            return localVarFp.apiV1DamageDetectionsPost(requestParameters.nexusOpsTenant, requestParameters.createDamageDetectionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageDetectionsApiApiV1DamageDetectionsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DamageDetectionsSearchPost(requestParameters: DamageDetectionsApiApiV1DamageDetectionsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageDetectionDto> {
            return localVarFp.apiV1DamageDetectionsSearchPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1DamageDetectionsBulkDeleteDelete operation in DamageDetectionsApi.
 * @export
 * @interface DamageDetectionsApiApiV1DamageDetectionsBulkDeleteDeleteRequest
 */
export interface DamageDetectionsApiApiV1DamageDetectionsBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DamageDetectionBulkDeleteDto}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsBulkDeleteDelete
     */
    readonly damageDetectionBulkDeleteDto?: DamageDetectionBulkDeleteDto
}

/**
 * Request parameters for apiV1DamageDetectionsByIdsGet operation in DamageDetectionsApi.
 * @export
 * @interface DamageDetectionsApiApiV1DamageDetectionsByIdsGetRequest
 */
export interface DamageDetectionsApiApiV1DamageDetectionsByIdsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsByIdsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsByIdsGet
     */
    readonly ids?: Array<string>
}

/**
 * Request parameters for apiV1DamageDetectionsCountersGet operation in DamageDetectionsApi.
 * @export
 * @interface DamageDetectionsApiApiV1DamageDetectionsCountersGetRequest
 */
export interface DamageDetectionsApiApiV1DamageDetectionsCountersGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsCountersGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1DamageDetectionsDamageDetectionIdAddToContractPost operation in DamageDetectionsApi.
 * @export
 * @interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdAddToContractPostRequest
 */
export interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdAddToContractPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdAddToContractPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdAddToContractPost
     */
    readonly damageDetectionId: string

    /**
     * 
     * @type {AddDamageDetectionToContractDto}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdAddToContractPost
     */
    readonly addDamageDetectionToContractDto?: AddDamageDetectionToContractDto
}

/**
 * Request parameters for apiV1DamageDetectionsDamageDetectionIdDelete operation in DamageDetectionsApi.
 * @export
 * @interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDeleteRequest
 */
export interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDelete
     */
    readonly damageDetectionId: string

    /**
     * 
     * @type {boolean}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDelete
     */
    readonly isDeleteCascade?: boolean

    /**
     * 
     * @type {DamageDetectionDeleteDto}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDelete
     */
    readonly damageDetectionDeleteDto?: DamageDetectionDeleteDto
}

/**
 * Request parameters for apiV1DamageDetectionsDamageDetectionIdDownloadPdfGet operation in DamageDetectionsApi.
 * @export
 * @interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDownloadPdfGetRequest
 */
export interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDownloadPdfGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDownloadPdfGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDownloadPdfGet
     */
    readonly damageDetectionId: string
}

/**
 * Request parameters for apiV1DamageDetectionsDamageDetectionIdGet operation in DamageDetectionsApi.
 * @export
 * @interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdGetRequest
 */
export interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdGet
     */
    readonly damageDetectionId: string
}

/**
 * Request parameters for apiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost operation in DamageDetectionsApi.
 * @export
 * @interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPostRequest
 */
export interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost
     */
    readonly damageDetectionId: string

    /**
     * 
     * @type {DamageDetectionMigrateToNewVisualModelDto}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost
     */
    readonly damageDetectionMigrateToNewVisualModelDto?: DamageDetectionMigrateToNewVisualModelDto
}

/**
 * Request parameters for apiV1DamageDetectionsDamageDetectionIdPut operation in DamageDetectionsApi.
 * @export
 * @interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdPutRequest
 */
export interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdPut
     */
    readonly damageDetectionId: string

    /**
     * 
     * @type {UpdateDamageDetectionDto}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdPut
     */
    readonly updateDamageDetectionDto?: UpdateDamageDetectionDto
}

/**
 * Request parameters for apiV1DamageDetectionsDamageDetectionIdSendToCustomerPost operation in DamageDetectionsApi.
 * @export
 * @interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdSendToCustomerPostRequest
 */
export interface DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdSendToCustomerPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdSendToCustomerPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdSendToCustomerPost
     */
    readonly damageDetectionId: string
}

/**
 * Request parameters for apiV1DamageDetectionsGetPost operation in DamageDetectionsApi.
 * @export
 * @interface DamageDetectionsApiApiV1DamageDetectionsGetPostRequest
 */
export interface DamageDetectionsApiApiV1DamageDetectionsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DamageDetectionGetPaginatedDto}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsGetPost
     */
    readonly damageDetectionGetPaginatedDto?: DamageDetectionGetPaginatedDto
}

/**
 * Request parameters for apiV1DamageDetectionsPost operation in DamageDetectionsApi.
 * @export
 * @interface DamageDetectionsApiApiV1DamageDetectionsPostRequest
 */
export interface DamageDetectionsApiApiV1DamageDetectionsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateDamageDetectionDto}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsPost
     */
    readonly createDamageDetectionDto?: CreateDamageDetectionDto
}

/**
 * Request parameters for apiV1DamageDetectionsSearchPost operation in DamageDetectionsApi.
 * @export
 * @interface DamageDetectionsApiApiV1DamageDetectionsSearchPostRequest
 */
export interface DamageDetectionsApiApiV1DamageDetectionsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DamageDetectionSearchPaginatedDto}
     * @memberof DamageDetectionsApiApiV1DamageDetectionsSearchPost
     */
    readonly damageDetectionSearchPaginatedDto?: DamageDetectionSearchPaginatedDto
}

/**
 * DamageDetectionsApi - object-oriented interface
 * @export
 * @class DamageDetectionsApi
 * @extends {BaseAPI}
 */
export class DamageDetectionsApi extends BaseAPI {
    /**
     * 
     * @param {DamageDetectionsApiApiV1DamageDetectionsBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionsApi
     */
    public apiV1DamageDetectionsBulkDeleteDelete(requestParameters: DamageDetectionsApiApiV1DamageDetectionsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionsApiFp(this.configuration).apiV1DamageDetectionsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.damageDetectionBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionsApiApiV1DamageDetectionsByIdsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionsApi
     */
    public apiV1DamageDetectionsByIdsGet(requestParameters: DamageDetectionsApiApiV1DamageDetectionsByIdsGetRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionsApiFp(this.configuration).apiV1DamageDetectionsByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionsApiApiV1DamageDetectionsCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionsApi
     */
    public apiV1DamageDetectionsCountersGet(requestParameters: DamageDetectionsApiApiV1DamageDetectionsCountersGetRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionsApiFp(this.configuration).apiV1DamageDetectionsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdAddToContractPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionsApi
     */
    public apiV1DamageDetectionsDamageDetectionIdAddToContractPost(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdAddToContractPostRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionsApiFp(this.configuration).apiV1DamageDetectionsDamageDetectionIdAddToContractPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, requestParameters.addDamageDetectionToContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionsApi
     */
    public apiV1DamageDetectionsDamageDetectionIdDelete(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionsApiFp(this.configuration).apiV1DamageDetectionsDamageDetectionIdDelete(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, requestParameters.isDeleteCascade, requestParameters.damageDetectionDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDownloadPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionsApi
     */
    public apiV1DamageDetectionsDamageDetectionIdDownloadPdfGet(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionsApiFp(this.configuration).apiV1DamageDetectionsDamageDetectionIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionsApi
     */
    public apiV1DamageDetectionsDamageDetectionIdGet(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdGetRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionsApiFp(this.configuration).apiV1DamageDetectionsDamageDetectionIdGet(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionsApi
     */
    public apiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPostRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionsApiFp(this.configuration).apiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, requestParameters.damageDetectionMigrateToNewVisualModelDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionsApi
     */
    public apiV1DamageDetectionsDamageDetectionIdPut(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdPutRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionsApiFp(this.configuration).apiV1DamageDetectionsDamageDetectionIdPut(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, requestParameters.updateDamageDetectionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdSendToCustomerPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionsApi
     */
    public apiV1DamageDetectionsDamageDetectionIdSendToCustomerPost(requestParameters: DamageDetectionsApiApiV1DamageDetectionsDamageDetectionIdSendToCustomerPostRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionsApiFp(this.configuration).apiV1DamageDetectionsDamageDetectionIdSendToCustomerPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionsApiApiV1DamageDetectionsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionsApi
     */
    public apiV1DamageDetectionsGetPost(requestParameters: DamageDetectionsApiApiV1DamageDetectionsGetPostRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionsApiFp(this.configuration).apiV1DamageDetectionsGetPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionsApiApiV1DamageDetectionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionsApi
     */
    public apiV1DamageDetectionsPost(requestParameters: DamageDetectionsApiApiV1DamageDetectionsPostRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionsApiFp(this.configuration).apiV1DamageDetectionsPost(requestParameters.nexusOpsTenant, requestParameters.createDamageDetectionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageDetectionsApiApiV1DamageDetectionsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageDetectionsApi
     */
    public apiV1DamageDetectionsSearchPost(requestParameters: DamageDetectionsApiApiV1DamageDetectionsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return DamageDetectionsApiFp(this.configuration).apiV1DamageDetectionsSearchPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DamageTypesApi - axios parameter creator
 * @export
 */
export const DamageTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} damageTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataDamageTypesDamageTypeIdGet: async (damageTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'damageTypeId' is not null or undefined
            assertParamExists('apiV1ReferenceDataDamageTypesDamageTypeIdGet', 'damageTypeId', damageTypeId)
            const localVarPath = `/api/v1/reference-data/damage-types/{damageTypeId}`
                .replace(`{${"damageTypeId"}}`, encodeURIComponent(String(damageTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DamageTypeGetPaginatedDto} [damageTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataDamageTypesGetPost: async (damageTypeGetPaginatedDto?: DamageTypeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/damage-types/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageTypeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DamageTypeSearchPaginatedDto} [damageTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataDamageTypesSearchPost: async (damageTypeSearchPaginatedDto?: DamageTypeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/damage-types/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageTypeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DamageTypesApi - functional programming interface
 * @export
 */
export const DamageTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DamageTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} damageTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataDamageTypesDamageTypeIdGet(damageTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataDamageTypesDamageTypeIdGet(damageTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageTypesApi.apiV1ReferenceDataDamageTypesDamageTypeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DamageTypeGetPaginatedDto} [damageTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataDamageTypesGetPost(damageTypeGetPaginatedDto?: DamageTypeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataDamageTypesGetPost(damageTypeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageTypesApi.apiV1ReferenceDataDamageTypesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DamageTypeSearchPaginatedDto} [damageTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataDamageTypesSearchPost(damageTypeSearchPaginatedDto?: DamageTypeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataDamageTypesSearchPost(damageTypeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DamageTypesApi.apiV1ReferenceDataDamageTypesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DamageTypesApi - factory interface
 * @export
 */
export const DamageTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DamageTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {DamageTypesApiApiV1ReferenceDataDamageTypesDamageTypeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataDamageTypesDamageTypeIdGet(requestParameters: DamageTypesApiApiV1ReferenceDataDamageTypesDamageTypeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageTypeDto> {
            return localVarFp.apiV1ReferenceDataDamageTypesDamageTypeIdGet(requestParameters.damageTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageTypesApiApiV1ReferenceDataDamageTypesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataDamageTypesGetPost(requestParameters: DamageTypesApiApiV1ReferenceDataDamageTypesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageTypeDto> {
            return localVarFp.apiV1ReferenceDataDamageTypesGetPost(requestParameters.damageTypeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamageTypesApiApiV1ReferenceDataDamageTypesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataDamageTypesSearchPost(requestParameters: DamageTypesApiApiV1ReferenceDataDamageTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageTypeDto> {
            return localVarFp.apiV1ReferenceDataDamageTypesSearchPost(requestParameters.damageTypeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ReferenceDataDamageTypesDamageTypeIdGet operation in DamageTypesApi.
 * @export
 * @interface DamageTypesApiApiV1ReferenceDataDamageTypesDamageTypeIdGetRequest
 */
export interface DamageTypesApiApiV1ReferenceDataDamageTypesDamageTypeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DamageTypesApiApiV1ReferenceDataDamageTypesDamageTypeIdGet
     */
    readonly damageTypeId: string
}

/**
 * Request parameters for apiV1ReferenceDataDamageTypesGetPost operation in DamageTypesApi.
 * @export
 * @interface DamageTypesApiApiV1ReferenceDataDamageTypesGetPostRequest
 */
export interface DamageTypesApiApiV1ReferenceDataDamageTypesGetPostRequest {
    /**
     * 
     * @type {DamageTypeGetPaginatedDto}
     * @memberof DamageTypesApiApiV1ReferenceDataDamageTypesGetPost
     */
    readonly damageTypeGetPaginatedDto?: DamageTypeGetPaginatedDto
}

/**
 * Request parameters for apiV1ReferenceDataDamageTypesSearchPost operation in DamageTypesApi.
 * @export
 * @interface DamageTypesApiApiV1ReferenceDataDamageTypesSearchPostRequest
 */
export interface DamageTypesApiApiV1ReferenceDataDamageTypesSearchPostRequest {
    /**
     * 
     * @type {DamageTypeSearchPaginatedDto}
     * @memberof DamageTypesApiApiV1ReferenceDataDamageTypesSearchPost
     */
    readonly damageTypeSearchPaginatedDto?: DamageTypeSearchPaginatedDto
}

/**
 * DamageTypesApi - object-oriented interface
 * @export
 * @class DamageTypesApi
 * @extends {BaseAPI}
 */
export class DamageTypesApi extends BaseAPI {
    /**
     * 
     * @param {DamageTypesApiApiV1ReferenceDataDamageTypesDamageTypeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageTypesApi
     */
    public apiV1ReferenceDataDamageTypesDamageTypeIdGet(requestParameters: DamageTypesApiApiV1ReferenceDataDamageTypesDamageTypeIdGetRequest, options?: RawAxiosRequestConfig) {
        return DamageTypesApiFp(this.configuration).apiV1ReferenceDataDamageTypesDamageTypeIdGet(requestParameters.damageTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageTypesApiApiV1ReferenceDataDamageTypesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageTypesApi
     */
    public apiV1ReferenceDataDamageTypesGetPost(requestParameters: DamageTypesApiApiV1ReferenceDataDamageTypesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DamageTypesApiFp(this.configuration).apiV1ReferenceDataDamageTypesGetPost(requestParameters.damageTypeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamageTypesApiApiV1ReferenceDataDamageTypesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamageTypesApi
     */
    public apiV1ReferenceDataDamageTypesSearchPost(requestParameters: DamageTypesApiApiV1ReferenceDataDamageTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DamageTypesApiFp(this.configuration).apiV1ReferenceDataDamageTypesSearchPost(requestParameters.damageTypeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DataGrantsApi - axios parameter creator
 * @export
 */
export const DataGrantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsCountersGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DataGrantsCountersGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/data-grants/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} dataGrantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsDataGrantIdDelete: async (nexusOpsTenant: string, dataGrantId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DataGrantsDataGrantIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'dataGrantId' is not null or undefined
            assertParamExists('apiV1DataGrantsDataGrantIdDelete', 'dataGrantId', dataGrantId)
            const localVarPath = `/api/v1/data-grants/{dataGrantId}`
                .replace(`{${"dataGrantId"}}`, encodeURIComponent(String(dataGrantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} dataGrantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsDataGrantIdGet: async (nexusOpsTenant: string, dataGrantId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DataGrantsDataGrantIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'dataGrantId' is not null or undefined
            assertParamExists('apiV1DataGrantsDataGrantIdGet', 'dataGrantId', dataGrantId)
            const localVarPath = `/api/v1/data-grants/{dataGrantId}`
                .replace(`{${"dataGrantId"}}`, encodeURIComponent(String(dataGrantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} dataGrantId 
         * @param {DataGrantUpdateDto} [dataGrantUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsDataGrantIdPut: async (nexusOpsTenant: string, dataGrantId: string, dataGrantUpdateDto?: DataGrantUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DataGrantsDataGrantIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'dataGrantId' is not null or undefined
            assertParamExists('apiV1DataGrantsDataGrantIdPut', 'dataGrantId', dataGrantId)
            const localVarPath = `/api/v1/data-grants/{dataGrantId}`
                .replace(`{${"dataGrantId"}}`, encodeURIComponent(String(dataGrantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataGrantUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DataGrantGetPaginatedDto} [dataGrantGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsGetPost: async (nexusOpsTenant: string, dataGrantGetPaginatedDto?: DataGrantGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DataGrantsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/data-grants/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataGrantGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DataGrantCreateDto} [dataGrantCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsPost: async (nexusOpsTenant: string, dataGrantCreateDto?: DataGrantCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DataGrantsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/data-grants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataGrantCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DataGrantSearchPaginatedDto} [dataGrantSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsSearchPost: async (nexusOpsTenant: string, dataGrantSearchPaginatedDto?: DataGrantSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DataGrantsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/data-grants/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataGrantSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataGrantsApi - functional programming interface
 * @export
 */
export const DataGrantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataGrantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DataGrantsCountersGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataGrantCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DataGrantsCountersGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataGrantsApi.apiV1DataGrantsCountersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} dataGrantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DataGrantsDataGrantIdDelete(nexusOpsTenant: string, dataGrantId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DataGrantsDataGrantIdDelete(nexusOpsTenant, dataGrantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataGrantsApi.apiV1DataGrantsDataGrantIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} dataGrantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DataGrantsDataGrantIdGet(nexusOpsTenant: string, dataGrantId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataGrantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DataGrantsDataGrantIdGet(nexusOpsTenant, dataGrantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataGrantsApi.apiV1DataGrantsDataGrantIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} dataGrantId 
         * @param {DataGrantUpdateDto} [dataGrantUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DataGrantsDataGrantIdPut(nexusOpsTenant: string, dataGrantId: string, dataGrantUpdateDto?: DataGrantUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataGrantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DataGrantsDataGrantIdPut(nexusOpsTenant, dataGrantId, dataGrantUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataGrantsApi.apiV1DataGrantsDataGrantIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DataGrantGetPaginatedDto} [dataGrantGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DataGrantsGetPost(nexusOpsTenant: string, dataGrantGetPaginatedDto?: DataGrantGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDataGrantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DataGrantsGetPost(nexusOpsTenant, dataGrantGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataGrantsApi.apiV1DataGrantsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DataGrantCreateDto} [dataGrantCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DataGrantsPost(nexusOpsTenant: string, dataGrantCreateDto?: DataGrantCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataGrantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DataGrantsPost(nexusOpsTenant, dataGrantCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataGrantsApi.apiV1DataGrantsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DataGrantSearchPaginatedDto} [dataGrantSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DataGrantsSearchPost(nexusOpsTenant: string, dataGrantSearchPaginatedDto?: DataGrantSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDataGrantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DataGrantsSearchPost(nexusOpsTenant, dataGrantSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataGrantsApi.apiV1DataGrantsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DataGrantsApi - factory interface
 * @export
 */
export const DataGrantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataGrantsApiFp(configuration)
    return {
        /**
         * 
         * @param {DataGrantsApiApiV1DataGrantsCountersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsCountersGet(requestParameters: DataGrantsApiApiV1DataGrantsCountersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DataGrantCountersDto> {
            return localVarFp.apiV1DataGrantsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataGrantsApiApiV1DataGrantsDataGrantIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsDataGrantIdDelete(requestParameters: DataGrantsApiApiV1DataGrantsDataGrantIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DataGrantsDataGrantIdDelete(requestParameters.nexusOpsTenant, requestParameters.dataGrantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataGrantsApiApiV1DataGrantsDataGrantIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsDataGrantIdGet(requestParameters: DataGrantsApiApiV1DataGrantsDataGrantIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DataGrantDto> {
            return localVarFp.apiV1DataGrantsDataGrantIdGet(requestParameters.nexusOpsTenant, requestParameters.dataGrantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataGrantsApiApiV1DataGrantsDataGrantIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsDataGrantIdPut(requestParameters: DataGrantsApiApiV1DataGrantsDataGrantIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<DataGrantDto> {
            return localVarFp.apiV1DataGrantsDataGrantIdPut(requestParameters.nexusOpsTenant, requestParameters.dataGrantId, requestParameters.dataGrantUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataGrantsApiApiV1DataGrantsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsGetPost(requestParameters: DataGrantsApiApiV1DataGrantsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDataGrantDto> {
            return localVarFp.apiV1DataGrantsGetPost(requestParameters.nexusOpsTenant, requestParameters.dataGrantGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataGrantsApiApiV1DataGrantsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsPost(requestParameters: DataGrantsApiApiV1DataGrantsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DataGrantDto> {
            return localVarFp.apiV1DataGrantsPost(requestParameters.nexusOpsTenant, requestParameters.dataGrantCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataGrantsApiApiV1DataGrantsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DataGrantsSearchPost(requestParameters: DataGrantsApiApiV1DataGrantsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDataGrantDto> {
            return localVarFp.apiV1DataGrantsSearchPost(requestParameters.nexusOpsTenant, requestParameters.dataGrantSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1DataGrantsCountersGet operation in DataGrantsApi.
 * @export
 * @interface DataGrantsApiApiV1DataGrantsCountersGetRequest
 */
export interface DataGrantsApiApiV1DataGrantsCountersGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DataGrantsApiApiV1DataGrantsCountersGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1DataGrantsDataGrantIdDelete operation in DataGrantsApi.
 * @export
 * @interface DataGrantsApiApiV1DataGrantsDataGrantIdDeleteRequest
 */
export interface DataGrantsApiApiV1DataGrantsDataGrantIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DataGrantsApiApiV1DataGrantsDataGrantIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DataGrantsApiApiV1DataGrantsDataGrantIdDelete
     */
    readonly dataGrantId: string
}

/**
 * Request parameters for apiV1DataGrantsDataGrantIdGet operation in DataGrantsApi.
 * @export
 * @interface DataGrantsApiApiV1DataGrantsDataGrantIdGetRequest
 */
export interface DataGrantsApiApiV1DataGrantsDataGrantIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DataGrantsApiApiV1DataGrantsDataGrantIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DataGrantsApiApiV1DataGrantsDataGrantIdGet
     */
    readonly dataGrantId: string
}

/**
 * Request parameters for apiV1DataGrantsDataGrantIdPut operation in DataGrantsApi.
 * @export
 * @interface DataGrantsApiApiV1DataGrantsDataGrantIdPutRequest
 */
export interface DataGrantsApiApiV1DataGrantsDataGrantIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DataGrantsApiApiV1DataGrantsDataGrantIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DataGrantsApiApiV1DataGrantsDataGrantIdPut
     */
    readonly dataGrantId: string

    /**
     * 
     * @type {DataGrantUpdateDto}
     * @memberof DataGrantsApiApiV1DataGrantsDataGrantIdPut
     */
    readonly dataGrantUpdateDto?: DataGrantUpdateDto
}

/**
 * Request parameters for apiV1DataGrantsGetPost operation in DataGrantsApi.
 * @export
 * @interface DataGrantsApiApiV1DataGrantsGetPostRequest
 */
export interface DataGrantsApiApiV1DataGrantsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DataGrantsApiApiV1DataGrantsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DataGrantGetPaginatedDto}
     * @memberof DataGrantsApiApiV1DataGrantsGetPost
     */
    readonly dataGrantGetPaginatedDto?: DataGrantGetPaginatedDto
}

/**
 * Request parameters for apiV1DataGrantsPost operation in DataGrantsApi.
 * @export
 * @interface DataGrantsApiApiV1DataGrantsPostRequest
 */
export interface DataGrantsApiApiV1DataGrantsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DataGrantsApiApiV1DataGrantsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DataGrantCreateDto}
     * @memberof DataGrantsApiApiV1DataGrantsPost
     */
    readonly dataGrantCreateDto?: DataGrantCreateDto
}

/**
 * Request parameters for apiV1DataGrantsSearchPost operation in DataGrantsApi.
 * @export
 * @interface DataGrantsApiApiV1DataGrantsSearchPostRequest
 */
export interface DataGrantsApiApiV1DataGrantsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DataGrantsApiApiV1DataGrantsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DataGrantSearchPaginatedDto}
     * @memberof DataGrantsApiApiV1DataGrantsSearchPost
     */
    readonly dataGrantSearchPaginatedDto?: DataGrantSearchPaginatedDto
}

/**
 * DataGrantsApi - object-oriented interface
 * @export
 * @class DataGrantsApi
 * @extends {BaseAPI}
 */
export class DataGrantsApi extends BaseAPI {
    /**
     * 
     * @param {DataGrantsApiApiV1DataGrantsCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataGrantsApi
     */
    public apiV1DataGrantsCountersGet(requestParameters: DataGrantsApiApiV1DataGrantsCountersGetRequest, options?: RawAxiosRequestConfig) {
        return DataGrantsApiFp(this.configuration).apiV1DataGrantsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataGrantsApiApiV1DataGrantsDataGrantIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataGrantsApi
     */
    public apiV1DataGrantsDataGrantIdDelete(requestParameters: DataGrantsApiApiV1DataGrantsDataGrantIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DataGrantsApiFp(this.configuration).apiV1DataGrantsDataGrantIdDelete(requestParameters.nexusOpsTenant, requestParameters.dataGrantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataGrantsApiApiV1DataGrantsDataGrantIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataGrantsApi
     */
    public apiV1DataGrantsDataGrantIdGet(requestParameters: DataGrantsApiApiV1DataGrantsDataGrantIdGetRequest, options?: RawAxiosRequestConfig) {
        return DataGrantsApiFp(this.configuration).apiV1DataGrantsDataGrantIdGet(requestParameters.nexusOpsTenant, requestParameters.dataGrantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataGrantsApiApiV1DataGrantsDataGrantIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataGrantsApi
     */
    public apiV1DataGrantsDataGrantIdPut(requestParameters: DataGrantsApiApiV1DataGrantsDataGrantIdPutRequest, options?: RawAxiosRequestConfig) {
        return DataGrantsApiFp(this.configuration).apiV1DataGrantsDataGrantIdPut(requestParameters.nexusOpsTenant, requestParameters.dataGrantId, requestParameters.dataGrantUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataGrantsApiApiV1DataGrantsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataGrantsApi
     */
    public apiV1DataGrantsGetPost(requestParameters: DataGrantsApiApiV1DataGrantsGetPostRequest, options?: RawAxiosRequestConfig) {
        return DataGrantsApiFp(this.configuration).apiV1DataGrantsGetPost(requestParameters.nexusOpsTenant, requestParameters.dataGrantGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataGrantsApiApiV1DataGrantsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataGrantsApi
     */
    public apiV1DataGrantsPost(requestParameters: DataGrantsApiApiV1DataGrantsPostRequest, options?: RawAxiosRequestConfig) {
        return DataGrantsApiFp(this.configuration).apiV1DataGrantsPost(requestParameters.nexusOpsTenant, requestParameters.dataGrantCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataGrantsApiApiV1DataGrantsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataGrantsApi
     */
    public apiV1DataGrantsSearchPost(requestParameters: DataGrantsApiApiV1DataGrantsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return DataGrantsApiFp(this.configuration).apiV1DataGrantsSearchPost(requestParameters.nexusOpsTenant, requestParameters.dataGrantSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DepartmentsApi - axios parameter creator
 * @export
 */
export const DepartmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/departments/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdDelete: async (nexusOpsTenant: string, departmentId: string, isDeleteCascade?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdDelete', 'departmentId', departmentId)
            const localVarPath = `/api/v1/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdGet: async (nexusOpsTenant: string, departmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdGet', 'departmentId', departmentId)
            const localVarPath = `/api/v1/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdLocationsLocationIdDelete: async (nexusOpsTenant: string, departmentId: string, locationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdLocationsLocationIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdLocationsLocationIdDelete', 'departmentId', departmentId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdLocationsLocationIdDelete', 'locationId', locationId)
            const localVarPath = `/api/v1/departments/{departmentId}/locations/{locationId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {AddDepartmentLocationsDto} [addDepartmentLocationsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdLocationsPost: async (nexusOpsTenant: string, departmentId: string, addDepartmentLocationsDto?: AddDepartmentLocationsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdLocationsPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdLocationsPost', 'departmentId', departmentId)
            const localVarPath = `/api/v1/departments/{departmentId}/locations`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDepartmentLocationsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {UpdateDepartmentDto} [updateDepartmentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdPut: async (nexusOpsTenant: string, departmentId: string, updateDepartmentDto?: UpdateDepartmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdPut', 'departmentId', departmentId)
            const localVarPath = `/api/v1/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDepartmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdReferenceGet: async (nexusOpsTenant: string, departmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdReferenceGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdReferenceGet', 'departmentId', departmentId)
            const localVarPath = `/api/v1/departments/{departmentId}/reference`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {UserGetPaginatedDto} [userGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdUsersCandidatesGetPost: async (nexusOpsTenant: string, departmentId: string, userGetPaginatedDto?: UserGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersCandidatesGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersCandidatesGetPost', 'departmentId', departmentId)
            const localVarPath = `/api/v1/departments/{departmentId}/users/candidates/get`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {UserGetPaginatedDto} [userGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdUsersGetPost: async (nexusOpsTenant: string, departmentId: string, userGetPaginatedDto?: UserGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersGetPost', 'departmentId', departmentId)
            const localVarPath = `/api/v1/departments/{departmentId}/users/get`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {AddUserToLocationDto} [addUserToLocationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdUsersLocationsPost: async (nexusOpsTenant: string, departmentId: string, addUserToLocationDto?: AddUserToLocationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersLocationsPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersLocationsPost', 'departmentId', departmentId)
            const localVarPath = `/api/v1/departments/{departmentId}/users-locations`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserToLocationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {string} userId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete: async (nexusOpsTenant: string, departmentId: string, userId: string, locationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete', 'departmentId', departmentId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete', 'userId', userId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete', 'locationId', locationId)
            const localVarPath = `/api/v1/departments/{departmentId}/users-locations/{userId}/{locationId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {AddDepartmentUsersDto} [addDepartmentUsersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdUsersPost: async (nexusOpsTenant: string, departmentId: string, addDepartmentUsersDto?: AddDepartmentUsersDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersPost', 'departmentId', departmentId)
            const localVarPath = `/api/v1/departments/{departmentId}/users`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDepartmentUsersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdUsersUserIdDelete: async (nexusOpsTenant: string, departmentId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersUserIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersUserIdDelete', 'departmentId', departmentId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1DepartmentsDepartmentIdUsersUserIdDelete', 'userId', userId)
            const localVarPath = `/api/v1/departments/{departmentId}/users/{userId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DepartmentGetPaginatedDto} [departmentGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsGetPost: async (nexusOpsTenant: string, departmentGetPaginatedDto?: DepartmentGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/departments/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateDepartmentDto} [createDepartmentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsPost: async (nexusOpsTenant: string, createDepartmentDto?: CreateDepartmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDepartmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DepartmentSearchPaginatedDto} [departmentSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsSearchPost: async (nexusOpsTenant: string, departmentSearchPaginatedDto?: DepartmentSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DepartmentsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/departments/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentsApi - functional programming interface
 * @export
 */
export const DepartmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsDepartmentIdDelete(nexusOpsTenant: string, departmentId: string, isDeleteCascade?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsDepartmentIdDelete(nexusOpsTenant, departmentId, isDeleteCascade, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsDepartmentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsDepartmentIdGet(nexusOpsTenant: string, departmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsDepartmentIdGet(nexusOpsTenant, departmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsDepartmentIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsDepartmentIdLocationsLocationIdDelete(nexusOpsTenant: string, departmentId: string, locationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsDepartmentIdLocationsLocationIdDelete(nexusOpsTenant, departmentId, locationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsDepartmentIdLocationsLocationIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {AddDepartmentLocationsDto} [addDepartmentLocationsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsDepartmentIdLocationsPost(nexusOpsTenant: string, departmentId: string, addDepartmentLocationsDto?: AddDepartmentLocationsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsDepartmentIdLocationsPost(nexusOpsTenant, departmentId, addDepartmentLocationsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsDepartmentIdLocationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {UpdateDepartmentDto} [updateDepartmentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsDepartmentIdPut(nexusOpsTenant: string, departmentId: string, updateDepartmentDto?: UpdateDepartmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsDepartmentIdPut(nexusOpsTenant, departmentId, updateDepartmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsDepartmentIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsDepartmentIdReferenceGet(nexusOpsTenant: string, departmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsDepartmentIdReferenceGet(nexusOpsTenant, departmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsDepartmentIdReferenceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {UserGetPaginatedDto} [userGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsDepartmentIdUsersCandidatesGetPost(nexusOpsTenant: string, departmentId: string, userGetPaginatedDto?: UserGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsDepartmentIdUsersCandidatesGetPost(nexusOpsTenant, departmentId, userGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsDepartmentIdUsersCandidatesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {UserGetPaginatedDto} [userGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsDepartmentIdUsersGetPost(nexusOpsTenant: string, departmentId: string, userGetPaginatedDto?: UserGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsDepartmentIdUsersGetPost(nexusOpsTenant, departmentId, userGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsDepartmentIdUsersGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {AddUserToLocationDto} [addUserToLocationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsDepartmentIdUsersLocationsPost(nexusOpsTenant: string, departmentId: string, addUserToLocationDto?: AddUserToLocationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsDepartmentIdUsersLocationsPost(nexusOpsTenant, departmentId, addUserToLocationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsDepartmentIdUsersLocationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {string} userId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete(nexusOpsTenant: string, departmentId: string, userId: string, locationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete(nexusOpsTenant, departmentId, userId, locationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {AddDepartmentUsersDto} [addDepartmentUsersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsDepartmentIdUsersPost(nexusOpsTenant: string, departmentId: string, addDepartmentUsersDto?: AddDepartmentUsersDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsDepartmentIdUsersPost(nexusOpsTenant, departmentId, addDepartmentUsersDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsDepartmentIdUsersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} departmentId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsDepartmentIdUsersUserIdDelete(nexusOpsTenant: string, departmentId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsDepartmentIdUsersUserIdDelete(nexusOpsTenant, departmentId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsDepartmentIdUsersUserIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DepartmentGetPaginatedDto} [departmentGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsGetPost(nexusOpsTenant: string, departmentGetPaginatedDto?: DepartmentGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsGetPost(nexusOpsTenant, departmentGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateDepartmentDto} [createDepartmentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsPost(nexusOpsTenant: string, createDepartmentDto?: CreateDepartmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsPost(nexusOpsTenant, createDepartmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DepartmentSearchPaginatedDto} [departmentSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsSearchPost(nexusOpsTenant: string, departmentSearchPaginatedDto?: DepartmentSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDepartmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsSearchPost(nexusOpsTenant, departmentSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.apiV1DepartmentsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DepartmentsApi - factory interface
 * @export
 */
export const DepartmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentsApiFp(configuration)
    return {
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsBulkDeleteDelete(requestParameters: DepartmentsApiApiV1DepartmentsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DepartmentsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsDepartmentIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdDelete(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DepartmentsDepartmentIdDelete(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.isDeleteCascade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsDepartmentIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdGet(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepartmentDto> {
            return localVarFp.apiV1DepartmentsDepartmentIdGet(requestParameters.nexusOpsTenant, requestParameters.departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsDepartmentIdLocationsLocationIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdLocationsLocationIdDelete(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdLocationsLocationIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepartmentDto> {
            return localVarFp.apiV1DepartmentsDepartmentIdLocationsLocationIdDelete(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsDepartmentIdLocationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdLocationsPost(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdLocationsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepartmentDto> {
            return localVarFp.apiV1DepartmentsDepartmentIdLocationsPost(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.addDepartmentLocationsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsDepartmentIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdPut(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepartmentDto> {
            return localVarFp.apiV1DepartmentsDepartmentIdPut(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.updateDepartmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsDepartmentIdReferenceGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdReferenceGet(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdReferenceGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepartmentReferenceDto> {
            return localVarFp.apiV1DepartmentsDepartmentIdReferenceGet(requestParameters.nexusOpsTenant, requestParameters.departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsDepartmentIdUsersCandidatesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdUsersCandidatesGetPost(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdUsersCandidatesGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfUserDto> {
            return localVarFp.apiV1DepartmentsDepartmentIdUsersCandidatesGetPost(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.userGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsDepartmentIdUsersGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdUsersGetPost(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdUsersGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfUserDto> {
            return localVarFp.apiV1DepartmentsDepartmentIdUsersGetPost(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.userGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdUsersLocationsPost(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepartmentDto> {
            return localVarFp.apiV1DepartmentsDepartmentIdUsersLocationsPost(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.addUserToLocationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepartmentDto> {
            return localVarFp.apiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.userId, requestParameters.locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsDepartmentIdUsersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdUsersPost(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdUsersPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepartmentDto> {
            return localVarFp.apiV1DepartmentsDepartmentIdUsersPost(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.addDepartmentUsersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsDepartmentIdUsersUserIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsDepartmentIdUsersUserIdDelete(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdUsersUserIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepartmentDto> {
            return localVarFp.apiV1DepartmentsDepartmentIdUsersUserIdDelete(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsGetPost(requestParameters: DepartmentsApiApiV1DepartmentsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDepartmentDto> {
            return localVarFp.apiV1DepartmentsGetPost(requestParameters.nexusOpsTenant, requestParameters.departmentGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsPost(requestParameters: DepartmentsApiApiV1DepartmentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DepartmentDto> {
            return localVarFp.apiV1DepartmentsPost(requestParameters.nexusOpsTenant, requestParameters.createDepartmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiApiV1DepartmentsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsSearchPost(requestParameters: DepartmentsApiApiV1DepartmentsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDepartmentDto> {
            return localVarFp.apiV1DepartmentsSearchPost(requestParameters.nexusOpsTenant, requestParameters.departmentSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1DepartmentsBulkDeleteDelete operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsBulkDeleteDeleteRequest
 */
export interface DepartmentsApiApiV1DepartmentsBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof DepartmentsApiApiV1DepartmentsBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1DepartmentsDepartmentIdDelete operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsDepartmentIdDeleteRequest
 */
export interface DepartmentsApiApiV1DepartmentsDepartmentIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdDelete
     */
    readonly departmentId: string

    /**
     * 
     * @type {boolean}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdDelete
     */
    readonly isDeleteCascade?: boolean
}

/**
 * Request parameters for apiV1DepartmentsDepartmentIdGet operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsDepartmentIdGetRequest
 */
export interface DepartmentsApiApiV1DepartmentsDepartmentIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdGet
     */
    readonly departmentId: string
}

/**
 * Request parameters for apiV1DepartmentsDepartmentIdLocationsLocationIdDelete operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsDepartmentIdLocationsLocationIdDeleteRequest
 */
export interface DepartmentsApiApiV1DepartmentsDepartmentIdLocationsLocationIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdLocationsLocationIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdLocationsLocationIdDelete
     */
    readonly departmentId: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdLocationsLocationIdDelete
     */
    readonly locationId: string
}

/**
 * Request parameters for apiV1DepartmentsDepartmentIdLocationsPost operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsDepartmentIdLocationsPostRequest
 */
export interface DepartmentsApiApiV1DepartmentsDepartmentIdLocationsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdLocationsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdLocationsPost
     */
    readonly departmentId: string

    /**
     * 
     * @type {AddDepartmentLocationsDto}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdLocationsPost
     */
    readonly addDepartmentLocationsDto?: AddDepartmentLocationsDto
}

/**
 * Request parameters for apiV1DepartmentsDepartmentIdPut operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsDepartmentIdPutRequest
 */
export interface DepartmentsApiApiV1DepartmentsDepartmentIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdPut
     */
    readonly departmentId: string

    /**
     * 
     * @type {UpdateDepartmentDto}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdPut
     */
    readonly updateDepartmentDto?: UpdateDepartmentDto
}

/**
 * Request parameters for apiV1DepartmentsDepartmentIdReferenceGet operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsDepartmentIdReferenceGetRequest
 */
export interface DepartmentsApiApiV1DepartmentsDepartmentIdReferenceGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdReferenceGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdReferenceGet
     */
    readonly departmentId: string
}

/**
 * Request parameters for apiV1DepartmentsDepartmentIdUsersCandidatesGetPost operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsDepartmentIdUsersCandidatesGetPostRequest
 */
export interface DepartmentsApiApiV1DepartmentsDepartmentIdUsersCandidatesGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersCandidatesGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersCandidatesGetPost
     */
    readonly departmentId: string

    /**
     * 
     * @type {UserGetPaginatedDto}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersCandidatesGetPost
     */
    readonly userGetPaginatedDto?: UserGetPaginatedDto
}

/**
 * Request parameters for apiV1DepartmentsDepartmentIdUsersGetPost operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsDepartmentIdUsersGetPostRequest
 */
export interface DepartmentsApiApiV1DepartmentsDepartmentIdUsersGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersGetPost
     */
    readonly departmentId: string

    /**
     * 
     * @type {UserGetPaginatedDto}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersGetPost
     */
    readonly userGetPaginatedDto?: UserGetPaginatedDto
}

/**
 * Request parameters for apiV1DepartmentsDepartmentIdUsersLocationsPost operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsPostRequest
 */
export interface DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsPost
     */
    readonly departmentId: string

    /**
     * 
     * @type {AddUserToLocationDto}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsPost
     */
    readonly addUserToLocationDto?: AddUserToLocationDto
}

/**
 * Request parameters for apiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDeleteRequest
 */
export interface DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete
     */
    readonly departmentId: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete
     */
    readonly locationId: string
}

/**
 * Request parameters for apiV1DepartmentsDepartmentIdUsersPost operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsDepartmentIdUsersPostRequest
 */
export interface DepartmentsApiApiV1DepartmentsDepartmentIdUsersPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersPost
     */
    readonly departmentId: string

    /**
     * 
     * @type {AddDepartmentUsersDto}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersPost
     */
    readonly addDepartmentUsersDto?: AddDepartmentUsersDto
}

/**
 * Request parameters for apiV1DepartmentsDepartmentIdUsersUserIdDelete operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsDepartmentIdUsersUserIdDeleteRequest
 */
export interface DepartmentsApiApiV1DepartmentsDepartmentIdUsersUserIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersUserIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersUserIdDelete
     */
    readonly departmentId: string

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsDepartmentIdUsersUserIdDelete
     */
    readonly userId: string
}

/**
 * Request parameters for apiV1DepartmentsGetPost operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsGetPostRequest
 */
export interface DepartmentsApiApiV1DepartmentsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DepartmentGetPaginatedDto}
     * @memberof DepartmentsApiApiV1DepartmentsGetPost
     */
    readonly departmentGetPaginatedDto?: DepartmentGetPaginatedDto
}

/**
 * Request parameters for apiV1DepartmentsPost operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsPostRequest
 */
export interface DepartmentsApiApiV1DepartmentsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateDepartmentDto}
     * @memberof DepartmentsApiApiV1DepartmentsPost
     */
    readonly createDepartmentDto?: CreateDepartmentDto
}

/**
 * Request parameters for apiV1DepartmentsSearchPost operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiApiV1DepartmentsSearchPostRequest
 */
export interface DepartmentsApiApiV1DepartmentsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DepartmentsApiApiV1DepartmentsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DepartmentSearchPaginatedDto}
     * @memberof DepartmentsApiApiV1DepartmentsSearchPost
     */
    readonly departmentSearchPaginatedDto?: DepartmentSearchPaginatedDto
}

/**
 * DepartmentsApi - object-oriented interface
 * @export
 * @class DepartmentsApi
 * @extends {BaseAPI}
 */
export class DepartmentsApi extends BaseAPI {
    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsBulkDeleteDelete(requestParameters: DepartmentsApiApiV1DepartmentsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsDepartmentIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsDepartmentIdDelete(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsDepartmentIdDelete(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.isDeleteCascade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsDepartmentIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsDepartmentIdGet(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdGetRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsDepartmentIdGet(requestParameters.nexusOpsTenant, requestParameters.departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsDepartmentIdLocationsLocationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsDepartmentIdLocationsLocationIdDelete(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdLocationsLocationIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsDepartmentIdLocationsLocationIdDelete(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsDepartmentIdLocationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsDepartmentIdLocationsPost(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdLocationsPostRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsDepartmentIdLocationsPost(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.addDepartmentLocationsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsDepartmentIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsDepartmentIdPut(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdPutRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsDepartmentIdPut(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.updateDepartmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsDepartmentIdReferenceGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsDepartmentIdReferenceGet(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdReferenceGetRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsDepartmentIdReferenceGet(requestParameters.nexusOpsTenant, requestParameters.departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsDepartmentIdUsersCandidatesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsDepartmentIdUsersCandidatesGetPost(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdUsersCandidatesGetPostRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsDepartmentIdUsersCandidatesGetPost(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.userGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsDepartmentIdUsersGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsDepartmentIdUsersGetPost(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdUsersGetPostRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsDepartmentIdUsersGetPost(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.userGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsDepartmentIdUsersLocationsPost(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsPostRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsDepartmentIdUsersLocationsPost(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.addUserToLocationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsDepartmentIdUsersLocationsUserIdLocationIdDelete(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.userId, requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsDepartmentIdUsersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsDepartmentIdUsersPost(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdUsersPostRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsDepartmentIdUsersPost(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.addDepartmentUsersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsDepartmentIdUsersUserIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsDepartmentIdUsersUserIdDelete(requestParameters: DepartmentsApiApiV1DepartmentsDepartmentIdUsersUserIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsDepartmentIdUsersUserIdDelete(requestParameters.nexusOpsTenant, requestParameters.departmentId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsGetPost(requestParameters: DepartmentsApiApiV1DepartmentsGetPostRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsGetPost(requestParameters.nexusOpsTenant, requestParameters.departmentGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsPost(requestParameters: DepartmentsApiApiV1DepartmentsPostRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsPost(requestParameters.nexusOpsTenant, requestParameters.createDepartmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiApiV1DepartmentsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiV1DepartmentsSearchPost(requestParameters: DepartmentsApiApiV1DepartmentsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiV1DepartmentsSearchPost(requestParameters.nexusOpsTenant, requestParameters.departmentSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeviceTokensApi - axios parameter creator
 * @export
 */
export const DeviceTokensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RegisterDeviceTokenDto} [registerDeviceTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DeviceTokensPost: async (nexusOpsTenant: string, registerDeviceTokenDto?: RegisterDeviceTokenDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DeviceTokensPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/device-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerDeviceTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceTokensApi - functional programming interface
 * @export
 */
export const DeviceTokensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceTokensApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RegisterDeviceTokenDto} [registerDeviceTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DeviceTokensPost(nexusOpsTenant: string, registerDeviceTokenDto?: RegisterDeviceTokenDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DeviceTokensPost(nexusOpsTenant, registerDeviceTokenDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceTokensApi.apiV1DeviceTokensPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeviceTokensApi - factory interface
 * @export
 */
export const DeviceTokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceTokensApiFp(configuration)
    return {
        /**
         * 
         * @param {DeviceTokensApiApiV1DeviceTokensPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DeviceTokensPost(requestParameters: DeviceTokensApiApiV1DeviceTokensPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DeviceTokensPost(requestParameters.nexusOpsTenant, requestParameters.registerDeviceTokenDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1DeviceTokensPost operation in DeviceTokensApi.
 * @export
 * @interface DeviceTokensApiApiV1DeviceTokensPostRequest
 */
export interface DeviceTokensApiApiV1DeviceTokensPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DeviceTokensApiApiV1DeviceTokensPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RegisterDeviceTokenDto}
     * @memberof DeviceTokensApiApiV1DeviceTokensPost
     */
    readonly registerDeviceTokenDto?: RegisterDeviceTokenDto
}

/**
 * DeviceTokensApi - object-oriented interface
 * @export
 * @class DeviceTokensApi
 * @extends {BaseAPI}
 */
export class DeviceTokensApi extends BaseAPI {
    /**
     * 
     * @param {DeviceTokensApiApiV1DeviceTokensPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceTokensApi
     */
    public apiV1DeviceTokensPost(requestParameters: DeviceTokensApiApiV1DeviceTokensPostRequest, options?: RawAxiosRequestConfig) {
        return DeviceTokensApiFp(this.configuration).apiV1DeviceTokensPost(requestParameters.nexusOpsTenant, requestParameters.registerDeviceTokenDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {RemoveDocumentAttachmentsDto} [removeDocumentAttachmentsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdAttachmentsDelete: async (nexusOpsTenant: string, documentId: string, removeDocumentAttachmentsDto?: RemoveDocumentAttachmentsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdAttachmentsDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdAttachmentsDelete', 'documentId', documentId)
            const localVarPath = `/api/v1/documents/{documentId}/attachments`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeDocumentAttachmentsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {AddDocumentAttachmentsDto} [addDocumentAttachmentsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdAttachmentsPost: async (nexusOpsTenant: string, documentId: string, addDocumentAttachmentsDto?: AddDocumentAttachmentsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdAttachmentsPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdAttachmentsPost', 'documentId', documentId)
            const localVarPath = `/api/v1/documents/{documentId}/attachments`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDocumentAttachmentsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdDelete: async (nexusOpsTenant: string, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdDelete', 'documentId', documentId)
            const localVarPath = `/api/v1/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdDownloadPdfGet: async (nexusOpsTenant: string, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdDownloadPdfGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdDownloadPdfGet', 'documentId', documentId)
            const localVarPath = `/api/v1/documents/{documentId}/download/pdf`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdGet: async (nexusOpsTenant: string, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdGet', 'documentId', documentId)
            const localVarPath = `/api/v1/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {string} itemId 
         * @param {RemoveDocumentItemAttachmentsDto} [removeDocumentItemAttachmentsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete: async (nexusOpsTenant: string, documentId: string, itemId: string, removeDocumentItemAttachmentsDto?: RemoveDocumentItemAttachmentsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete', 'documentId', documentId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete', 'itemId', itemId)
            const localVarPath = `/api/v1/documents/{documentId}/items/{itemId}/attachments`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeDocumentItemAttachmentsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {string} itemId 
         * @param {AddDocumentItemAttachmentsDto} [addDocumentItemAttachmentsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdItemsItemIdAttachmentsPost: async (nexusOpsTenant: string, documentId: string, itemId: string, addDocumentItemAttachmentsDto?: AddDocumentItemAttachmentsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdItemsItemIdAttachmentsPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdItemsItemIdAttachmentsPost', 'documentId', documentId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdItemsItemIdAttachmentsPost', 'itemId', itemId)
            const localVarPath = `/api/v1/documents/{documentId}/items/{itemId}/attachments`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDocumentItemAttachmentsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdSendToCustomerPost: async (nexusOpsTenant: string, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdSendToCustomerPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdSendToCustomerPost', 'documentId', documentId)
            const localVarPath = `/api/v1/documents/{documentId}/send-to-customer`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {UpdateDocumentStageDto} [updateDocumentStageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdStagePut: async (nexusOpsTenant: string, documentId: string, updateDocumentStageDto?: UpdateDocumentStageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdStagePut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiV1DocumentsDocumentIdStagePut', 'documentId', documentId)
            const localVarPath = `/api/v1/documents/{documentId}/stage`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentStageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DocumentGetPaginatedDto} [documentGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsGetPost: async (nexusOpsTenant: string, documentGetPaginatedDto?: DocumentGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1DocumentsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/documents/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {RemoveDocumentAttachmentsDto} [removeDocumentAttachmentsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsDocumentIdAttachmentsDelete(nexusOpsTenant: string, documentId: string, removeDocumentAttachmentsDto?: RemoveDocumentAttachmentsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsDocumentIdAttachmentsDelete(nexusOpsTenant, documentId, removeDocumentAttachmentsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.apiV1DocumentsDocumentIdAttachmentsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {AddDocumentAttachmentsDto} [addDocumentAttachmentsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsDocumentIdAttachmentsPost(nexusOpsTenant: string, documentId: string, addDocumentAttachmentsDto?: AddDocumentAttachmentsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsDocumentIdAttachmentsPost(nexusOpsTenant, documentId, addDocumentAttachmentsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.apiV1DocumentsDocumentIdAttachmentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsDocumentIdDelete(nexusOpsTenant: string, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsDocumentIdDelete(nexusOpsTenant, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.apiV1DocumentsDocumentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsDocumentIdDownloadPdfGet(nexusOpsTenant: string, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsDocumentIdDownloadPdfGet(nexusOpsTenant, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.apiV1DocumentsDocumentIdDownloadPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsDocumentIdGet(nexusOpsTenant: string, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsDocumentIdGet(nexusOpsTenant, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.apiV1DocumentsDocumentIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {string} itemId 
         * @param {RemoveDocumentItemAttachmentsDto} [removeDocumentItemAttachmentsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete(nexusOpsTenant: string, documentId: string, itemId: string, removeDocumentItemAttachmentsDto?: RemoveDocumentItemAttachmentsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete(nexusOpsTenant, documentId, itemId, removeDocumentItemAttachmentsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.apiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {string} itemId 
         * @param {AddDocumentItemAttachmentsDto} [addDocumentItemAttachmentsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsDocumentIdItemsItemIdAttachmentsPost(nexusOpsTenant: string, documentId: string, itemId: string, addDocumentItemAttachmentsDto?: AddDocumentItemAttachmentsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsDocumentIdItemsItemIdAttachmentsPost(nexusOpsTenant, documentId, itemId, addDocumentItemAttachmentsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.apiV1DocumentsDocumentIdItemsItemIdAttachmentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsDocumentIdSendToCustomerPost(nexusOpsTenant: string, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsDocumentIdSendToCustomerPost(nexusOpsTenant, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.apiV1DocumentsDocumentIdSendToCustomerPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} documentId 
         * @param {UpdateDocumentStageDto} [updateDocumentStageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsDocumentIdStagePut(nexusOpsTenant: string, documentId: string, updateDocumentStageDto?: UpdateDocumentStageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsDocumentIdStagePut(nexusOpsTenant, documentId, updateDocumentStageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.apiV1DocumentsDocumentIdStagePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DocumentGetPaginatedDto} [documentGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsGetPost(nexusOpsTenant: string, documentGetPaginatedDto?: DocumentGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsGetPost(nexusOpsTenant, documentGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.apiV1DocumentsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {DocumentsApiApiV1DocumentsDocumentIdAttachmentsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdAttachmentsDelete(requestParameters: DocumentsApiApiV1DocumentsDocumentIdAttachmentsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<DocumentDto> {
            return localVarFp.apiV1DocumentsDocumentIdAttachmentsDelete(requestParameters.nexusOpsTenant, requestParameters.documentId, requestParameters.removeDocumentAttachmentsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentsApiApiV1DocumentsDocumentIdAttachmentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdAttachmentsPost(requestParameters: DocumentsApiApiV1DocumentsDocumentIdAttachmentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DocumentDto> {
            return localVarFp.apiV1DocumentsDocumentIdAttachmentsPost(requestParameters.nexusOpsTenant, requestParameters.documentId, requestParameters.addDocumentAttachmentsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentsApiApiV1DocumentsDocumentIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdDelete(requestParameters: DocumentsApiApiV1DocumentsDocumentIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DocumentsDocumentIdDelete(requestParameters.nexusOpsTenant, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentsApiApiV1DocumentsDocumentIdDownloadPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdDownloadPdfGet(requestParameters: DocumentsApiApiV1DocumentsDocumentIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DocumentsDocumentIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentsApiApiV1DocumentsDocumentIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdGet(requestParameters: DocumentsApiApiV1DocumentsDocumentIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DocumentDto> {
            return localVarFp.apiV1DocumentsDocumentIdGet(requestParameters.nexusOpsTenant, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete(requestParameters: DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<DocumentItemDto> {
            return localVarFp.apiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete(requestParameters.nexusOpsTenant, requestParameters.documentId, requestParameters.itemId, requestParameters.removeDocumentItemAttachmentsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdItemsItemIdAttachmentsPost(requestParameters: DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DocumentItemDto> {
            return localVarFp.apiV1DocumentsDocumentIdItemsItemIdAttachmentsPost(requestParameters.nexusOpsTenant, requestParameters.documentId, requestParameters.itemId, requestParameters.addDocumentItemAttachmentsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentsApiApiV1DocumentsDocumentIdSendToCustomerPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdSendToCustomerPost(requestParameters: DocumentsApiApiV1DocumentsDocumentIdSendToCustomerPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DocumentsDocumentIdSendToCustomerPost(requestParameters.nexusOpsTenant, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentsApiApiV1DocumentsDocumentIdStagePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsDocumentIdStagePut(requestParameters: DocumentsApiApiV1DocumentsDocumentIdStagePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<DocumentDto> {
            return localVarFp.apiV1DocumentsDocumentIdStagePut(requestParameters.nexusOpsTenant, requestParameters.documentId, requestParameters.updateDocumentStageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentsApiApiV1DocumentsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsGetPost(requestParameters: DocumentsApiApiV1DocumentsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDocumentDto> {
            return localVarFp.apiV1DocumentsGetPost(requestParameters.nexusOpsTenant, requestParameters.documentGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1DocumentsDocumentIdAttachmentsDelete operation in DocumentsApi.
 * @export
 * @interface DocumentsApiApiV1DocumentsDocumentIdAttachmentsDeleteRequest
 */
export interface DocumentsApiApiV1DocumentsDocumentIdAttachmentsDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdAttachmentsDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdAttachmentsDelete
     */
    readonly documentId: string

    /**
     * 
     * @type {RemoveDocumentAttachmentsDto}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdAttachmentsDelete
     */
    readonly removeDocumentAttachmentsDto?: RemoveDocumentAttachmentsDto
}

/**
 * Request parameters for apiV1DocumentsDocumentIdAttachmentsPost operation in DocumentsApi.
 * @export
 * @interface DocumentsApiApiV1DocumentsDocumentIdAttachmentsPostRequest
 */
export interface DocumentsApiApiV1DocumentsDocumentIdAttachmentsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdAttachmentsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdAttachmentsPost
     */
    readonly documentId: string

    /**
     * 
     * @type {AddDocumentAttachmentsDto}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdAttachmentsPost
     */
    readonly addDocumentAttachmentsDto?: AddDocumentAttachmentsDto
}

/**
 * Request parameters for apiV1DocumentsDocumentIdDelete operation in DocumentsApi.
 * @export
 * @interface DocumentsApiApiV1DocumentsDocumentIdDeleteRequest
 */
export interface DocumentsApiApiV1DocumentsDocumentIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdDelete
     */
    readonly documentId: string
}

/**
 * Request parameters for apiV1DocumentsDocumentIdDownloadPdfGet operation in DocumentsApi.
 * @export
 * @interface DocumentsApiApiV1DocumentsDocumentIdDownloadPdfGetRequest
 */
export interface DocumentsApiApiV1DocumentsDocumentIdDownloadPdfGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdDownloadPdfGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdDownloadPdfGet
     */
    readonly documentId: string
}

/**
 * Request parameters for apiV1DocumentsDocumentIdGet operation in DocumentsApi.
 * @export
 * @interface DocumentsApiApiV1DocumentsDocumentIdGetRequest
 */
export interface DocumentsApiApiV1DocumentsDocumentIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdGet
     */
    readonly documentId: string
}

/**
 * Request parameters for apiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete operation in DocumentsApi.
 * @export
 * @interface DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsDeleteRequest
 */
export interface DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete
     */
    readonly documentId: string

    /**
     * 
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete
     */
    readonly itemId: string

    /**
     * 
     * @type {RemoveDocumentItemAttachmentsDto}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete
     */
    readonly removeDocumentItemAttachmentsDto?: RemoveDocumentItemAttachmentsDto
}

/**
 * Request parameters for apiV1DocumentsDocumentIdItemsItemIdAttachmentsPost operation in DocumentsApi.
 * @export
 * @interface DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsPostRequest
 */
export interface DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsPost
     */
    readonly documentId: string

    /**
     * 
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsPost
     */
    readonly itemId: string

    /**
     * 
     * @type {AddDocumentItemAttachmentsDto}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsPost
     */
    readonly addDocumentItemAttachmentsDto?: AddDocumentItemAttachmentsDto
}

/**
 * Request parameters for apiV1DocumentsDocumentIdSendToCustomerPost operation in DocumentsApi.
 * @export
 * @interface DocumentsApiApiV1DocumentsDocumentIdSendToCustomerPostRequest
 */
export interface DocumentsApiApiV1DocumentsDocumentIdSendToCustomerPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdSendToCustomerPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdSendToCustomerPost
     */
    readonly documentId: string
}

/**
 * Request parameters for apiV1DocumentsDocumentIdStagePut operation in DocumentsApi.
 * @export
 * @interface DocumentsApiApiV1DocumentsDocumentIdStagePutRequest
 */
export interface DocumentsApiApiV1DocumentsDocumentIdStagePutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdStagePut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdStagePut
     */
    readonly documentId: string

    /**
     * 
     * @type {UpdateDocumentStageDto}
     * @memberof DocumentsApiApiV1DocumentsDocumentIdStagePut
     */
    readonly updateDocumentStageDto?: UpdateDocumentStageDto
}

/**
 * Request parameters for apiV1DocumentsGetPost operation in DocumentsApi.
 * @export
 * @interface DocumentsApiApiV1DocumentsGetPostRequest
 */
export interface DocumentsApiApiV1DocumentsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof DocumentsApiApiV1DocumentsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DocumentGetPaginatedDto}
     * @memberof DocumentsApiApiV1DocumentsGetPost
     */
    readonly documentGetPaginatedDto?: DocumentGetPaginatedDto
}

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * 
     * @param {DocumentsApiApiV1DocumentsDocumentIdAttachmentsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsDocumentIdAttachmentsDelete(requestParameters: DocumentsApiApiV1DocumentsDocumentIdAttachmentsDeleteRequest, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsDocumentIdAttachmentsDelete(requestParameters.nexusOpsTenant, requestParameters.documentId, requestParameters.removeDocumentAttachmentsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentsApiApiV1DocumentsDocumentIdAttachmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsDocumentIdAttachmentsPost(requestParameters: DocumentsApiApiV1DocumentsDocumentIdAttachmentsPostRequest, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsDocumentIdAttachmentsPost(requestParameters.nexusOpsTenant, requestParameters.documentId, requestParameters.addDocumentAttachmentsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentsApiApiV1DocumentsDocumentIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsDocumentIdDelete(requestParameters: DocumentsApiApiV1DocumentsDocumentIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsDocumentIdDelete(requestParameters.nexusOpsTenant, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentsApiApiV1DocumentsDocumentIdDownloadPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsDocumentIdDownloadPdfGet(requestParameters: DocumentsApiApiV1DocumentsDocumentIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsDocumentIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentsApiApiV1DocumentsDocumentIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsDocumentIdGet(requestParameters: DocumentsApiApiV1DocumentsDocumentIdGetRequest, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsDocumentIdGet(requestParameters.nexusOpsTenant, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete(requestParameters: DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsDeleteRequest, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsDocumentIdItemsItemIdAttachmentsDelete(requestParameters.nexusOpsTenant, requestParameters.documentId, requestParameters.itemId, requestParameters.removeDocumentItemAttachmentsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsDocumentIdItemsItemIdAttachmentsPost(requestParameters: DocumentsApiApiV1DocumentsDocumentIdItemsItemIdAttachmentsPostRequest, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsDocumentIdItemsItemIdAttachmentsPost(requestParameters.nexusOpsTenant, requestParameters.documentId, requestParameters.itemId, requestParameters.addDocumentItemAttachmentsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentsApiApiV1DocumentsDocumentIdSendToCustomerPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsDocumentIdSendToCustomerPost(requestParameters: DocumentsApiApiV1DocumentsDocumentIdSendToCustomerPostRequest, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsDocumentIdSendToCustomerPost(requestParameters.nexusOpsTenant, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentsApiApiV1DocumentsDocumentIdStagePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsDocumentIdStagePut(requestParameters: DocumentsApiApiV1DocumentsDocumentIdStagePutRequest, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsDocumentIdStagePut(requestParameters.nexusOpsTenant, requestParameters.documentId, requestParameters.updateDocumentStageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentsApiApiV1DocumentsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public apiV1DocumentsGetPost(requestParameters: DocumentsApiApiV1DocumentsGetPostRequest, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).apiV1DocumentsGetPost(requestParameters.nexusOpsTenant, requestParameters.documentGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EnumsApi - axios parameter creator
 * @export
 */
export const EnumsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} enumTypeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsByTypeNameEnumTypeNameGet: async (enumTypeName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enumTypeName' is not null or undefined
            assertParamExists('apiV1EnumsByTypeNameEnumTypeNameGet', 'enumTypeName', enumTypeName)
            const localVarPath = `/api/v1/enums/by-type-name/{enumTypeName}`
                .replace(`{${"enumTypeName"}}`, encodeURIComponent(String(enumTypeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleProjection} [projection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsByTypeNameVehicleareaGet: async (projection?: VehicleProjection, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/enums/by-type-name/vehiclearea`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projection !== undefined) {
                localVarQueryParameter['projection'] = projection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleArea} [area] 
         * @param {VehicleProjection} [projection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsByTypeNameVehiclepartcategoryGet: async (area?: VehicleArea, projection?: VehicleProjection, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/enums/by-type-name/vehiclepartcategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }

            if (projection !== undefined) {
                localVarQueryParameter['projection'] = projection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleArea} [area] 
         * @param {VehicleProjection} [projection] 
         * @param {VehiclePartCategory} [category] 
         * @param {VehiclePartType} [type] 
         * @param {Array<VehiclePartType>} [types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsByTypeNameVehiclepartdescriptorGet: async (area?: VehicleArea, projection?: VehicleProjection, category?: VehiclePartCategory, type?: VehiclePartType, types?: Array<VehiclePartType>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/enums/by-type-name/vehiclepartdescriptor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }

            if (projection !== undefined) {
                localVarQueryParameter['projection'] = projection;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleArea} [area] 
         * @param {VehicleProjection} [projection] 
         * @param {VehiclePartCategory} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsByTypeNameVehicleparttypeGet: async (area?: VehicleArea, projection?: VehicleProjection, category?: VehiclePartCategory, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/enums/by-type-name/vehicleparttype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }

            if (projection !== undefined) {
                localVarQueryParameter['projection'] = projection;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleArea} [area] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsByTypeNameVehicleprojectionGet: async (area?: VehicleArea, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/enums/by-type-name/vehicleprojection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsCatalogGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/enums/catalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [enumTypeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsTransitionSpecGet: async (enumTypeName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/enums/transition-spec`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (enumTypeName !== undefined) {
                localVarQueryParameter['enumTypeName'] = enumTypeName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnumsApi - functional programming interface
 * @export
 */
export const EnumsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnumsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} enumTypeName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EnumsByTypeNameEnumTypeNameGet(enumTypeName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnumDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EnumsByTypeNameEnumTypeNameGet(enumTypeName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnumsApi.apiV1EnumsByTypeNameEnumTypeNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleProjection} [projection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EnumsByTypeNameVehicleareaGet(projection?: VehicleProjection, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnumDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EnumsByTypeNameVehicleareaGet(projection, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnumsApi.apiV1EnumsByTypeNameVehicleareaGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleArea} [area] 
         * @param {VehicleProjection} [projection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EnumsByTypeNameVehiclepartcategoryGet(area?: VehicleArea, projection?: VehicleProjection, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnumDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EnumsByTypeNameVehiclepartcategoryGet(area, projection, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnumsApi.apiV1EnumsByTypeNameVehiclepartcategoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleArea} [area] 
         * @param {VehicleProjection} [projection] 
         * @param {VehiclePartCategory} [category] 
         * @param {VehiclePartType} [type] 
         * @param {Array<VehiclePartType>} [types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EnumsByTypeNameVehiclepartdescriptorGet(area?: VehicleArea, projection?: VehicleProjection, category?: VehiclePartCategory, type?: VehiclePartType, types?: Array<VehiclePartType>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnumDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EnumsByTypeNameVehiclepartdescriptorGet(area, projection, category, type, types, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnumsApi.apiV1EnumsByTypeNameVehiclepartdescriptorGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleArea} [area] 
         * @param {VehicleProjection} [projection] 
         * @param {VehiclePartCategory} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EnumsByTypeNameVehicleparttypeGet(area?: VehicleArea, projection?: VehicleProjection, category?: VehiclePartCategory, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnumDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EnumsByTypeNameVehicleparttypeGet(area, projection, category, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnumsApi.apiV1EnumsByTypeNameVehicleparttypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleArea} [area] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EnumsByTypeNameVehicleprojectionGet(area?: VehicleArea, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnumDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EnumsByTypeNameVehicleprojectionGet(area, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnumsApi.apiV1EnumsByTypeNameVehicleprojectionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EnumsCatalogGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnumCatalogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EnumsCatalogGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnumsApi.apiV1EnumsCatalogGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [enumTypeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EnumsTransitionSpecGet(enumTypeName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnumTransitionSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EnumsTransitionSpecGet(enumTypeName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnumsApi.apiV1EnumsTransitionSpecGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EnumsApi - factory interface
 * @export
 */
export const EnumsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnumsApiFp(configuration)
    return {
        /**
         * 
         * @param {EnumsApiApiV1EnumsByTypeNameEnumTypeNameGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsByTypeNameEnumTypeNameGet(requestParameters: EnumsApiApiV1EnumsByTypeNameEnumTypeNameGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EnumDto>> {
            return localVarFp.apiV1EnumsByTypeNameEnumTypeNameGet(requestParameters.enumTypeName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EnumsApiApiV1EnumsByTypeNameVehicleareaGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsByTypeNameVehicleareaGet(requestParameters: EnumsApiApiV1EnumsByTypeNameVehicleareaGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<EnumDto>> {
            return localVarFp.apiV1EnumsByTypeNameVehicleareaGet(requestParameters.projection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EnumsApiApiV1EnumsByTypeNameVehiclepartcategoryGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsByTypeNameVehiclepartcategoryGet(requestParameters: EnumsApiApiV1EnumsByTypeNameVehiclepartcategoryGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<EnumDto>> {
            return localVarFp.apiV1EnumsByTypeNameVehiclepartcategoryGet(requestParameters.area, requestParameters.projection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EnumsApiApiV1EnumsByTypeNameVehiclepartdescriptorGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsByTypeNameVehiclepartdescriptorGet(requestParameters: EnumsApiApiV1EnumsByTypeNameVehiclepartdescriptorGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<EnumDto>> {
            return localVarFp.apiV1EnumsByTypeNameVehiclepartdescriptorGet(requestParameters.area, requestParameters.projection, requestParameters.category, requestParameters.type, requestParameters.types, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EnumsApiApiV1EnumsByTypeNameVehicleparttypeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsByTypeNameVehicleparttypeGet(requestParameters: EnumsApiApiV1EnumsByTypeNameVehicleparttypeGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<EnumDto>> {
            return localVarFp.apiV1EnumsByTypeNameVehicleparttypeGet(requestParameters.area, requestParameters.projection, requestParameters.category, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EnumsApiApiV1EnumsByTypeNameVehicleprojectionGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsByTypeNameVehicleprojectionGet(requestParameters: EnumsApiApiV1EnumsByTypeNameVehicleprojectionGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<EnumDto>> {
            return localVarFp.apiV1EnumsByTypeNameVehicleprojectionGet(requestParameters.area, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsCatalogGet(options?: RawAxiosRequestConfig): AxiosPromise<EnumCatalogDto> {
            return localVarFp.apiV1EnumsCatalogGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EnumsApiApiV1EnumsTransitionSpecGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsTransitionSpecGet(requestParameters: EnumsApiApiV1EnumsTransitionSpecGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<EnumTransitionSpecDto> {
            return localVarFp.apiV1EnumsTransitionSpecGet(requestParameters.enumTypeName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1EnumsByTypeNameEnumTypeNameGet operation in EnumsApi.
 * @export
 * @interface EnumsApiApiV1EnumsByTypeNameEnumTypeNameGetRequest
 */
export interface EnumsApiApiV1EnumsByTypeNameEnumTypeNameGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EnumsApiApiV1EnumsByTypeNameEnumTypeNameGet
     */
    readonly enumTypeName: string
}

/**
 * Request parameters for apiV1EnumsByTypeNameVehicleareaGet operation in EnumsApi.
 * @export
 * @interface EnumsApiApiV1EnumsByTypeNameVehicleareaGetRequest
 */
export interface EnumsApiApiV1EnumsByTypeNameVehicleareaGetRequest {
    /**
     * 
     * @type {VehicleProjection}
     * @memberof EnumsApiApiV1EnumsByTypeNameVehicleareaGet
     */
    readonly projection?: VehicleProjection
}

/**
 * Request parameters for apiV1EnumsByTypeNameVehiclepartcategoryGet operation in EnumsApi.
 * @export
 * @interface EnumsApiApiV1EnumsByTypeNameVehiclepartcategoryGetRequest
 */
export interface EnumsApiApiV1EnumsByTypeNameVehiclepartcategoryGetRequest {
    /**
     * 
     * @type {VehicleArea}
     * @memberof EnumsApiApiV1EnumsByTypeNameVehiclepartcategoryGet
     */
    readonly area?: VehicleArea

    /**
     * 
     * @type {VehicleProjection}
     * @memberof EnumsApiApiV1EnumsByTypeNameVehiclepartcategoryGet
     */
    readonly projection?: VehicleProjection
}

/**
 * Request parameters for apiV1EnumsByTypeNameVehiclepartdescriptorGet operation in EnumsApi.
 * @export
 * @interface EnumsApiApiV1EnumsByTypeNameVehiclepartdescriptorGetRequest
 */
export interface EnumsApiApiV1EnumsByTypeNameVehiclepartdescriptorGetRequest {
    /**
     * 
     * @type {VehicleArea}
     * @memberof EnumsApiApiV1EnumsByTypeNameVehiclepartdescriptorGet
     */
    readonly area?: VehicleArea

    /**
     * 
     * @type {VehicleProjection}
     * @memberof EnumsApiApiV1EnumsByTypeNameVehiclepartdescriptorGet
     */
    readonly projection?: VehicleProjection

    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof EnumsApiApiV1EnumsByTypeNameVehiclepartdescriptorGet
     */
    readonly category?: VehiclePartCategory

    /**
     * 
     * @type {VehiclePartType}
     * @memberof EnumsApiApiV1EnumsByTypeNameVehiclepartdescriptorGet
     */
    readonly type?: VehiclePartType

    /**
     * 
     * @type {Array<VehiclePartType>}
     * @memberof EnumsApiApiV1EnumsByTypeNameVehiclepartdescriptorGet
     */
    readonly types?: Array<VehiclePartType>
}

/**
 * Request parameters for apiV1EnumsByTypeNameVehicleparttypeGet operation in EnumsApi.
 * @export
 * @interface EnumsApiApiV1EnumsByTypeNameVehicleparttypeGetRequest
 */
export interface EnumsApiApiV1EnumsByTypeNameVehicleparttypeGetRequest {
    /**
     * 
     * @type {VehicleArea}
     * @memberof EnumsApiApiV1EnumsByTypeNameVehicleparttypeGet
     */
    readonly area?: VehicleArea

    /**
     * 
     * @type {VehicleProjection}
     * @memberof EnumsApiApiV1EnumsByTypeNameVehicleparttypeGet
     */
    readonly projection?: VehicleProjection

    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof EnumsApiApiV1EnumsByTypeNameVehicleparttypeGet
     */
    readonly category?: VehiclePartCategory
}

/**
 * Request parameters for apiV1EnumsByTypeNameVehicleprojectionGet operation in EnumsApi.
 * @export
 * @interface EnumsApiApiV1EnumsByTypeNameVehicleprojectionGetRequest
 */
export interface EnumsApiApiV1EnumsByTypeNameVehicleprojectionGetRequest {
    /**
     * 
     * @type {VehicleArea}
     * @memberof EnumsApiApiV1EnumsByTypeNameVehicleprojectionGet
     */
    readonly area?: VehicleArea
}

/**
 * Request parameters for apiV1EnumsTransitionSpecGet operation in EnumsApi.
 * @export
 * @interface EnumsApiApiV1EnumsTransitionSpecGetRequest
 */
export interface EnumsApiApiV1EnumsTransitionSpecGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EnumsApiApiV1EnumsTransitionSpecGet
     */
    readonly enumTypeName?: string
}

/**
 * EnumsApi - object-oriented interface
 * @export
 * @class EnumsApi
 * @extends {BaseAPI}
 */
export class EnumsApi extends BaseAPI {
    /**
     * 
     * @param {EnumsApiApiV1EnumsByTypeNameEnumTypeNameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public apiV1EnumsByTypeNameEnumTypeNameGet(requestParameters: EnumsApiApiV1EnumsByTypeNameEnumTypeNameGetRequest, options?: RawAxiosRequestConfig) {
        return EnumsApiFp(this.configuration).apiV1EnumsByTypeNameEnumTypeNameGet(requestParameters.enumTypeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumsApiApiV1EnumsByTypeNameVehicleareaGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public apiV1EnumsByTypeNameVehicleareaGet(requestParameters: EnumsApiApiV1EnumsByTypeNameVehicleareaGetRequest = {}, options?: RawAxiosRequestConfig) {
        return EnumsApiFp(this.configuration).apiV1EnumsByTypeNameVehicleareaGet(requestParameters.projection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumsApiApiV1EnumsByTypeNameVehiclepartcategoryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public apiV1EnumsByTypeNameVehiclepartcategoryGet(requestParameters: EnumsApiApiV1EnumsByTypeNameVehiclepartcategoryGetRequest = {}, options?: RawAxiosRequestConfig) {
        return EnumsApiFp(this.configuration).apiV1EnumsByTypeNameVehiclepartcategoryGet(requestParameters.area, requestParameters.projection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumsApiApiV1EnumsByTypeNameVehiclepartdescriptorGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public apiV1EnumsByTypeNameVehiclepartdescriptorGet(requestParameters: EnumsApiApiV1EnumsByTypeNameVehiclepartdescriptorGetRequest = {}, options?: RawAxiosRequestConfig) {
        return EnumsApiFp(this.configuration).apiV1EnumsByTypeNameVehiclepartdescriptorGet(requestParameters.area, requestParameters.projection, requestParameters.category, requestParameters.type, requestParameters.types, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumsApiApiV1EnumsByTypeNameVehicleparttypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public apiV1EnumsByTypeNameVehicleparttypeGet(requestParameters: EnumsApiApiV1EnumsByTypeNameVehicleparttypeGetRequest = {}, options?: RawAxiosRequestConfig) {
        return EnumsApiFp(this.configuration).apiV1EnumsByTypeNameVehicleparttypeGet(requestParameters.area, requestParameters.projection, requestParameters.category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumsApiApiV1EnumsByTypeNameVehicleprojectionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public apiV1EnumsByTypeNameVehicleprojectionGet(requestParameters: EnumsApiApiV1EnumsByTypeNameVehicleprojectionGetRequest = {}, options?: RawAxiosRequestConfig) {
        return EnumsApiFp(this.configuration).apiV1EnumsByTypeNameVehicleprojectionGet(requestParameters.area, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public apiV1EnumsCatalogGet(options?: RawAxiosRequestConfig) {
        return EnumsApiFp(this.configuration).apiV1EnumsCatalogGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnumsApiApiV1EnumsTransitionSpecGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public apiV1EnumsTransitionSpecGet(requestParameters: EnumsApiApiV1EnumsTransitionSpecGetRequest = {}, options?: RawAxiosRequestConfig) {
        return EnumsApiFp(this.configuration).apiV1EnumsTransitionSpecGet(requestParameters.enumTypeName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {string} [responseOptionsFileUrlLifetime] Check out how C# TimeSpan is serialized to string for more info.
         * @param {string} [responseOptionsFileUrlExpiresAt] Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesAvatarUploadPost: async (files?: Array<File>, responseOptionsFileUrlLifetime?: string, responseOptionsFileUrlExpiresAt?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/files/avatar/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (responseOptionsFileUrlLifetime !== undefined) { 
                localVarFormParams.append('responseOptions.fileUrlLifetime', responseOptionsFileUrlLifetime as any);
            }
    
            if (responseOptionsFileUrlExpiresAt !== undefined) { 
                localVarFormParams.append('responseOptions.fileUrlExpiresAt', responseOptionsFileUrlExpiresAt as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} chatId 
         * @param {Array<File>} [files] 
         * @param {string} [responseOptionsFileUrlLifetime] Check out how C# TimeSpan is serialized to string for more info.
         * @param {string} [responseOptionsFileUrlExpiresAt] Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesChatsChatIdAttachmentsUploadPost: async (chatId: string, files?: Array<File>, responseOptionsFileUrlLifetime?: string, responseOptionsFileUrlExpiresAt?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1FilesChatsChatIdAttachmentsUploadPost', 'chatId', chatId)
            const localVarPath = `/api/v1/files/chats/{chatId}/attachments/upload`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (responseOptionsFileUrlLifetime !== undefined) { 
                localVarFormParams.append('responseOptions.fileUrlLifetime', responseOptionsFileUrlLifetime as any);
            }
    
            if (responseOptionsFileUrlExpiresAt !== undefined) { 
                localVarFormParams.append('responseOptions.fileUrlExpiresAt', responseOptionsFileUrlExpiresAt as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {string} [responseOptionsFileUrlLifetime] Check out how C# TimeSpan is serialized to string for more info.
         * @param {string} [responseOptionsFileUrlExpiresAt] Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesImportUploadPost: async (files?: Array<File>, responseOptionsFileUrlLifetime?: string, responseOptionsFileUrlExpiresAt?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/files/import/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (responseOptionsFileUrlLifetime !== undefined) { 
                localVarFormParams.append('responseOptions.fileUrlLifetime', responseOptionsFileUrlLifetime as any);
            }
    
            if (responseOptionsFileUrlExpiresAt !== undefined) { 
                localVarFormParams.append('responseOptions.fileUrlExpiresAt', responseOptionsFileUrlExpiresAt as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {string} [responseOptionsFileUrlLifetime] Check out how C# TimeSpan is serialized to string for more info.
         * @param {string} [responseOptionsFileUrlExpiresAt] Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesUploadPost: async (files?: Array<File>, responseOptionsFileUrlLifetime?: string, responseOptionsFileUrlExpiresAt?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (responseOptionsFileUrlLifetime !== undefined) { 
                localVarFormParams.append('responseOptions.fileUrlLifetime', responseOptionsFileUrlLifetime as any);
            }
    
            if (responseOptionsFileUrlExpiresAt !== undefined) { 
                localVarFormParams.append('responseOptions.fileUrlExpiresAt', responseOptionsFileUrlExpiresAt as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesUploadSpecGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/files/upload/spec`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleVisualModelType} type 
         * @param {Array<File>} [files] 
         * @param {string} [responseOptionsFileUrlLifetime] Check out how C# TimeSpan is serialized to string for more info.
         * @param {string} [responseOptionsFileUrlExpiresAt] Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesVehicleVisualModelTypeImagesUploadPost: async (type: VehicleVisualModelType, files?: Array<File>, responseOptionsFileUrlLifetime?: string, responseOptionsFileUrlExpiresAt?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiV1FilesVehicleVisualModelTypeImagesUploadPost', 'type', type)
            const localVarPath = `/api/v1/files/vehicle-visual-model/{type}/images/upload`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (responseOptionsFileUrlLifetime !== undefined) { 
                localVarFormParams.append('responseOptions.fileUrlLifetime', responseOptionsFileUrlLifetime as any);
            }
    
            if (responseOptionsFileUrlExpiresAt !== undefined) { 
                localVarFormParams.append('responseOptions.fileUrlExpiresAt', responseOptionsFileUrlExpiresAt as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {string} [responseOptionsFileUrlLifetime] Check out how C# TimeSpan is serialized to string for more info.
         * @param {string} [responseOptionsFileUrlExpiresAt] Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FilesAvatarUploadPost(files?: Array<File>, responseOptionsFileUrlLifetime?: string, responseOptionsFileUrlExpiresAt?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFilesResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FilesAvatarUploadPost(files, responseOptionsFileUrlLifetime, responseOptionsFileUrlExpiresAt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.apiV1FilesAvatarUploadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} chatId 
         * @param {Array<File>} [files] 
         * @param {string} [responseOptionsFileUrlLifetime] Check out how C# TimeSpan is serialized to string for more info.
         * @param {string} [responseOptionsFileUrlExpiresAt] Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FilesChatsChatIdAttachmentsUploadPost(chatId: string, files?: Array<File>, responseOptionsFileUrlLifetime?: string, responseOptionsFileUrlExpiresAt?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFilesResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FilesChatsChatIdAttachmentsUploadPost(chatId, files, responseOptionsFileUrlLifetime, responseOptionsFileUrlExpiresAt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.apiV1FilesChatsChatIdAttachmentsUploadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {string} [responseOptionsFileUrlLifetime] Check out how C# TimeSpan is serialized to string for more info.
         * @param {string} [responseOptionsFileUrlExpiresAt] Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FilesImportUploadPost(files?: Array<File>, responseOptionsFileUrlLifetime?: string, responseOptionsFileUrlExpiresAt?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFilesResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FilesImportUploadPost(files, responseOptionsFileUrlLifetime, responseOptionsFileUrlExpiresAt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.apiV1FilesImportUploadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {string} [responseOptionsFileUrlLifetime] Check out how C# TimeSpan is serialized to string for more info.
         * @param {string} [responseOptionsFileUrlExpiresAt] Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FilesUploadPost(files?: Array<File>, responseOptionsFileUrlLifetime?: string, responseOptionsFileUrlExpiresAt?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFilesResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FilesUploadPost(files, responseOptionsFileUrlLifetime, responseOptionsFileUrlExpiresAt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.apiV1FilesUploadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FilesUploadSpecGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FilesUploadSpecGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.apiV1FilesUploadSpecGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleVisualModelType} type 
         * @param {Array<File>} [files] 
         * @param {string} [responseOptionsFileUrlLifetime] Check out how C# TimeSpan is serialized to string for more info.
         * @param {string} [responseOptionsFileUrlExpiresAt] Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FilesVehicleVisualModelTypeImagesUploadPost(type: VehicleVisualModelType, files?: Array<File>, responseOptionsFileUrlLifetime?: string, responseOptionsFileUrlExpiresAt?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFilesResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FilesVehicleVisualModelTypeImagesUploadPost(type, files, responseOptionsFileUrlLifetime, responseOptionsFileUrlExpiresAt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.apiV1FilesVehicleVisualModelTypeImagesUploadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @param {FileApiApiV1FilesAvatarUploadPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesAvatarUploadPost(requestParameters: FileApiApiV1FilesAvatarUploadPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<UploadFilesResultDto> {
            return localVarFp.apiV1FilesAvatarUploadPost(requestParameters.files, requestParameters.responseOptionsFileUrlLifetime, requestParameters.responseOptionsFileUrlExpiresAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileApiApiV1FilesChatsChatIdAttachmentsUploadPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesChatsChatIdAttachmentsUploadPost(requestParameters: FileApiApiV1FilesChatsChatIdAttachmentsUploadPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<UploadFilesResultDto> {
            return localVarFp.apiV1FilesChatsChatIdAttachmentsUploadPost(requestParameters.chatId, requestParameters.files, requestParameters.responseOptionsFileUrlLifetime, requestParameters.responseOptionsFileUrlExpiresAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileApiApiV1FilesImportUploadPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesImportUploadPost(requestParameters: FileApiApiV1FilesImportUploadPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<UploadFilesResultDto> {
            return localVarFp.apiV1FilesImportUploadPost(requestParameters.files, requestParameters.responseOptionsFileUrlLifetime, requestParameters.responseOptionsFileUrlExpiresAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileApiApiV1FilesUploadPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesUploadPost(requestParameters: FileApiApiV1FilesUploadPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<UploadFilesResultDto> {
            return localVarFp.apiV1FilesUploadPost(requestParameters.files, requestParameters.responseOptionsFileUrlLifetime, requestParameters.responseOptionsFileUrlExpiresAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesUploadSpecGet(options?: RawAxiosRequestConfig): AxiosPromise<FileUploadSpecDto> {
            return localVarFp.apiV1FilesUploadSpecGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileApiApiV1FilesVehicleVisualModelTypeImagesUploadPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesVehicleVisualModelTypeImagesUploadPost(requestParameters: FileApiApiV1FilesVehicleVisualModelTypeImagesUploadPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<UploadFilesResultDto> {
            return localVarFp.apiV1FilesVehicleVisualModelTypeImagesUploadPost(requestParameters.type, requestParameters.files, requestParameters.responseOptionsFileUrlLifetime, requestParameters.responseOptionsFileUrlExpiresAt, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1FilesAvatarUploadPost operation in FileApi.
 * @export
 * @interface FileApiApiV1FilesAvatarUploadPostRequest
 */
export interface FileApiApiV1FilesAvatarUploadPostRequest {
    /**
     * 
     * @type {Array<File>}
     * @memberof FileApiApiV1FilesAvatarUploadPost
     */
    readonly files?: Array<File>

    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof FileApiApiV1FilesAvatarUploadPost
     */
    readonly responseOptionsFileUrlLifetime?: string

    /**
     * Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
     * @type {string}
     * @memberof FileApiApiV1FilesAvatarUploadPost
     */
    readonly responseOptionsFileUrlExpiresAt?: string
}

/**
 * Request parameters for apiV1FilesChatsChatIdAttachmentsUploadPost operation in FileApi.
 * @export
 * @interface FileApiApiV1FilesChatsChatIdAttachmentsUploadPostRequest
 */
export interface FileApiApiV1FilesChatsChatIdAttachmentsUploadPostRequest {
    /**
     * 
     * @type {string}
     * @memberof FileApiApiV1FilesChatsChatIdAttachmentsUploadPost
     */
    readonly chatId: string

    /**
     * 
     * @type {Array<File>}
     * @memberof FileApiApiV1FilesChatsChatIdAttachmentsUploadPost
     */
    readonly files?: Array<File>

    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof FileApiApiV1FilesChatsChatIdAttachmentsUploadPost
     */
    readonly responseOptionsFileUrlLifetime?: string

    /**
     * Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
     * @type {string}
     * @memberof FileApiApiV1FilesChatsChatIdAttachmentsUploadPost
     */
    readonly responseOptionsFileUrlExpiresAt?: string
}

/**
 * Request parameters for apiV1FilesImportUploadPost operation in FileApi.
 * @export
 * @interface FileApiApiV1FilesImportUploadPostRequest
 */
export interface FileApiApiV1FilesImportUploadPostRequest {
    /**
     * 
     * @type {Array<File>}
     * @memberof FileApiApiV1FilesImportUploadPost
     */
    readonly files?: Array<File>

    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof FileApiApiV1FilesImportUploadPost
     */
    readonly responseOptionsFileUrlLifetime?: string

    /**
     * Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
     * @type {string}
     * @memberof FileApiApiV1FilesImportUploadPost
     */
    readonly responseOptionsFileUrlExpiresAt?: string
}

/**
 * Request parameters for apiV1FilesUploadPost operation in FileApi.
 * @export
 * @interface FileApiApiV1FilesUploadPostRequest
 */
export interface FileApiApiV1FilesUploadPostRequest {
    /**
     * 
     * @type {Array<File>}
     * @memberof FileApiApiV1FilesUploadPost
     */
    readonly files?: Array<File>

    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof FileApiApiV1FilesUploadPost
     */
    readonly responseOptionsFileUrlLifetime?: string

    /**
     * Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
     * @type {string}
     * @memberof FileApiApiV1FilesUploadPost
     */
    readonly responseOptionsFileUrlExpiresAt?: string
}

/**
 * Request parameters for apiV1FilesVehicleVisualModelTypeImagesUploadPost operation in FileApi.
 * @export
 * @interface FileApiApiV1FilesVehicleVisualModelTypeImagesUploadPostRequest
 */
export interface FileApiApiV1FilesVehicleVisualModelTypeImagesUploadPostRequest {
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof FileApiApiV1FilesVehicleVisualModelTypeImagesUploadPost
     */
    readonly type: VehicleVisualModelType

    /**
     * 
     * @type {Array<File>}
     * @memberof FileApiApiV1FilesVehicleVisualModelTypeImagesUploadPost
     */
    readonly files?: Array<File>

    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof FileApiApiV1FilesVehicleVisualModelTypeImagesUploadPost
     */
    readonly responseOptionsFileUrlLifetime?: string

    /**
     * Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
     * @type {string}
     * @memberof FileApiApiV1FilesVehicleVisualModelTypeImagesUploadPost
     */
    readonly responseOptionsFileUrlExpiresAt?: string
}

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @param {FileApiApiV1FilesAvatarUploadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FilesAvatarUploadPost(requestParameters: FileApiApiV1FilesAvatarUploadPostRequest = {}, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).apiV1FilesAvatarUploadPost(requestParameters.files, requestParameters.responseOptionsFileUrlLifetime, requestParameters.responseOptionsFileUrlExpiresAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileApiApiV1FilesChatsChatIdAttachmentsUploadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FilesChatsChatIdAttachmentsUploadPost(requestParameters: FileApiApiV1FilesChatsChatIdAttachmentsUploadPostRequest, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).apiV1FilesChatsChatIdAttachmentsUploadPost(requestParameters.chatId, requestParameters.files, requestParameters.responseOptionsFileUrlLifetime, requestParameters.responseOptionsFileUrlExpiresAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileApiApiV1FilesImportUploadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FilesImportUploadPost(requestParameters: FileApiApiV1FilesImportUploadPostRequest = {}, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).apiV1FilesImportUploadPost(requestParameters.files, requestParameters.responseOptionsFileUrlLifetime, requestParameters.responseOptionsFileUrlExpiresAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileApiApiV1FilesUploadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FilesUploadPost(requestParameters: FileApiApiV1FilesUploadPostRequest = {}, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).apiV1FilesUploadPost(requestParameters.files, requestParameters.responseOptionsFileUrlLifetime, requestParameters.responseOptionsFileUrlExpiresAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FilesUploadSpecGet(options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).apiV1FilesUploadSpecGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileApiApiV1FilesVehicleVisualModelTypeImagesUploadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiV1FilesVehicleVisualModelTypeImagesUploadPost(requestParameters: FileApiApiV1FilesVehicleVisualModelTypeImagesUploadPostRequest, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).apiV1FilesVehicleVisualModelTypeImagesUploadPost(requestParameters.type, requestParameters.files, requestParameters.responseOptionsFileUrlLifetime, requestParameters.responseOptionsFileUrlExpiresAt, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GeneralBrandingApi - axios parameter creator
 * @export
 */
export const GeneralBrandingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralBrandingInputMetaGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1GeneralBrandingInputMetaGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/general/branding/input/meta`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralBrandingApi - functional programming interface
 * @export
 */
export const GeneralBrandingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralBrandingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GeneralBrandingInputMetaGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralBrandingInputMetaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GeneralBrandingInputMetaGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralBrandingApi.apiV1GeneralBrandingInputMetaGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GeneralBrandingApi - factory interface
 * @export
 */
export const GeneralBrandingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralBrandingApiFp(configuration)
    return {
        /**
         * 
         * @param {GeneralBrandingApiApiV1GeneralBrandingInputMetaGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralBrandingInputMetaGet(requestParameters: GeneralBrandingApiApiV1GeneralBrandingInputMetaGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralBrandingInputMetaDto> {
            return localVarFp.apiV1GeneralBrandingInputMetaGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1GeneralBrandingInputMetaGet operation in GeneralBrandingApi.
 * @export
 * @interface GeneralBrandingApiApiV1GeneralBrandingInputMetaGetRequest
 */
export interface GeneralBrandingApiApiV1GeneralBrandingInputMetaGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralBrandingApiApiV1GeneralBrandingInputMetaGet
     */
    readonly nexusOpsTenant: string
}

/**
 * GeneralBrandingApi - object-oriented interface
 * @export
 * @class GeneralBrandingApi
 * @extends {BaseAPI}
 */
export class GeneralBrandingApi extends BaseAPI {
    /**
     * 
     * @param {GeneralBrandingApiApiV1GeneralBrandingInputMetaGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralBrandingApi
     */
    public apiV1GeneralBrandingInputMetaGet(requestParameters: GeneralBrandingApiApiV1GeneralBrandingInputMetaGetRequest, options?: RawAxiosRequestConfig) {
        return GeneralBrandingApiFp(this.configuration).apiV1GeneralBrandingInputMetaGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GeneralEventLogsApi - axios parameter creator
 * @export
 */
export const GeneralEventLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} entityId 
         * @param {GeneralEventLogGetPaginatedDto} [generalEventLogGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralEventLogsEntityIdGetPost: async (nexusOpsTenant: string, entityId: string, generalEventLogGetPaginatedDto?: GeneralEventLogGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1GeneralEventLogsEntityIdGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('apiV1GeneralEventLogsEntityIdGetPost', 'entityId', entityId)
            const localVarPath = `/api/v1/general/event-logs/{entityId}/get`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalEventLogGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} id 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralEventLogsEntityIdIdGet: async (nexusOpsTenant: string, id: string, entityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1GeneralEventLogsEntityIdIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GeneralEventLogsEntityIdIdGet', 'id', id)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('apiV1GeneralEventLogsEntityIdIdGet', 'entityId', entityId)
            const localVarPath = `/api/v1/general/event-logs/{entityId}/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralEventLogsApi - functional programming interface
 * @export
 */
export const GeneralEventLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralEventLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} entityId 
         * @param {GeneralEventLogGetPaginatedDto} [generalEventLogGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GeneralEventLogsEntityIdGetPost(nexusOpsTenant: string, entityId: string, generalEventLogGetPaginatedDto?: GeneralEventLogGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfGeneralEventLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GeneralEventLogsEntityIdGetPost(nexusOpsTenant, entityId, generalEventLogGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralEventLogsApi.apiV1GeneralEventLogsEntityIdGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} id 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GeneralEventLogsEntityIdIdGet(nexusOpsTenant: string, id: string, entityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralEventLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GeneralEventLogsEntityIdIdGet(nexusOpsTenant, id, entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralEventLogsApi.apiV1GeneralEventLogsEntityIdIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GeneralEventLogsApi - factory interface
 * @export
 */
export const GeneralEventLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralEventLogsApiFp(configuration)
    return {
        /**
         * 
         * @param {GeneralEventLogsApiApiV1GeneralEventLogsEntityIdGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralEventLogsEntityIdGetPost(requestParameters: GeneralEventLogsApiApiV1GeneralEventLogsEntityIdGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfGeneralEventLogDto> {
            return localVarFp.apiV1GeneralEventLogsEntityIdGetPost(requestParameters.nexusOpsTenant, requestParameters.entityId, requestParameters.generalEventLogGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralEventLogsApiApiV1GeneralEventLogsEntityIdIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralEventLogsEntityIdIdGet(requestParameters: GeneralEventLogsApiApiV1GeneralEventLogsEntityIdIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralEventLogDto> {
            return localVarFp.apiV1GeneralEventLogsEntityIdIdGet(requestParameters.nexusOpsTenant, requestParameters.id, requestParameters.entityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1GeneralEventLogsEntityIdGetPost operation in GeneralEventLogsApi.
 * @export
 * @interface GeneralEventLogsApiApiV1GeneralEventLogsEntityIdGetPostRequest
 */
export interface GeneralEventLogsApiApiV1GeneralEventLogsEntityIdGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralEventLogsApiApiV1GeneralEventLogsEntityIdGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogsApiApiV1GeneralEventLogsEntityIdGetPost
     */
    readonly entityId: string

    /**
     * 
     * @type {GeneralEventLogGetPaginatedDto}
     * @memberof GeneralEventLogsApiApiV1GeneralEventLogsEntityIdGetPost
     */
    readonly generalEventLogGetPaginatedDto?: GeneralEventLogGetPaginatedDto
}

/**
 * Request parameters for apiV1GeneralEventLogsEntityIdIdGet operation in GeneralEventLogsApi.
 * @export
 * @interface GeneralEventLogsApiApiV1GeneralEventLogsEntityIdIdGetRequest
 */
export interface GeneralEventLogsApiApiV1GeneralEventLogsEntityIdIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralEventLogsApiApiV1GeneralEventLogsEntityIdIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogsApiApiV1GeneralEventLogsEntityIdIdGet
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogsApiApiV1GeneralEventLogsEntityIdIdGet
     */
    readonly entityId: string
}

/**
 * GeneralEventLogsApi - object-oriented interface
 * @export
 * @class GeneralEventLogsApi
 * @extends {BaseAPI}
 */
export class GeneralEventLogsApi extends BaseAPI {
    /**
     * 
     * @param {GeneralEventLogsApiApiV1GeneralEventLogsEntityIdGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralEventLogsApi
     */
    public apiV1GeneralEventLogsEntityIdGetPost(requestParameters: GeneralEventLogsApiApiV1GeneralEventLogsEntityIdGetPostRequest, options?: RawAxiosRequestConfig) {
        return GeneralEventLogsApiFp(this.configuration).apiV1GeneralEventLogsEntityIdGetPost(requestParameters.nexusOpsTenant, requestParameters.entityId, requestParameters.generalEventLogGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralEventLogsApiApiV1GeneralEventLogsEntityIdIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralEventLogsApi
     */
    public apiV1GeneralEventLogsEntityIdIdGet(requestParameters: GeneralEventLogsApiApiV1GeneralEventLogsEntityIdIdGetRequest, options?: RawAxiosRequestConfig) {
        return GeneralEventLogsApiFp(this.configuration).apiV1GeneralEventLogsEntityIdIdGet(requestParameters.nexusOpsTenant, requestParameters.id, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GeneralHistoryApi - axios parameter creator
 * @export
 */
export const GeneralHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistoryBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1GeneralHistoryBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/general-history/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} generalHistoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistoryGeneralHistoryIdDelete: async (nexusOpsTenant: string, generalHistoryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1GeneralHistoryGeneralHistoryIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'generalHistoryId' is not null or undefined
            assertParamExists('apiV1GeneralHistoryGeneralHistoryIdDelete', 'generalHistoryId', generalHistoryId)
            const localVarPath = `/api/v1/general-history/{generalHistoryId}`
                .replace(`{${"generalHistoryId"}}`, encodeURIComponent(String(generalHistoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} generalHistoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistoryGeneralHistoryIdGet: async (nexusOpsTenant: string, generalHistoryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1GeneralHistoryGeneralHistoryIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'generalHistoryId' is not null or undefined
            assertParamExists('apiV1GeneralHistoryGeneralHistoryIdGet', 'generalHistoryId', generalHistoryId)
            const localVarPath = `/api/v1/general-history/{generalHistoryId}`
                .replace(`{${"generalHistoryId"}}`, encodeURIComponent(String(generalHistoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} generalHistoryId 
         * @param {GeneralHistoryUpdateDto} [generalHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistoryGeneralHistoryIdPut: async (nexusOpsTenant: string, generalHistoryId: string, generalHistoryUpdateDto?: GeneralHistoryUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1GeneralHistoryGeneralHistoryIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'generalHistoryId' is not null or undefined
            assertParamExists('apiV1GeneralHistoryGeneralHistoryIdPut', 'generalHistoryId', generalHistoryId)
            const localVarPath = `/api/v1/general-history/{generalHistoryId}`
                .replace(`{${"generalHistoryId"}}`, encodeURIComponent(String(generalHistoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalHistoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralHistoryGetPaginatedDto} [generalHistoryGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistoryGetPost: async (nexusOpsTenant: string, generalHistoryGetPaginatedDto?: GeneralHistoryGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1GeneralHistoryGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/general-history/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalHistoryGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralHistoryCreateDto} [generalHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistoryPost: async (nexusOpsTenant: string, generalHistoryCreateDto?: GeneralHistoryCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1GeneralHistoryPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/general-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalHistoryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralHistorySearchPaginatedDto} [generalHistorySearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistorySearchPost: async (nexusOpsTenant: string, generalHistorySearchPaginatedDto?: GeneralHistorySearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1GeneralHistorySearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/general-history/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalHistorySearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralHistoryApi - functional programming interface
 * @export
 */
export const GeneralHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GeneralHistoryBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GeneralHistoryBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralHistoryApi.apiV1GeneralHistoryBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} generalHistoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GeneralHistoryGeneralHistoryIdDelete(nexusOpsTenant: string, generalHistoryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GeneralHistoryGeneralHistoryIdDelete(nexusOpsTenant, generalHistoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralHistoryApi.apiV1GeneralHistoryGeneralHistoryIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} generalHistoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GeneralHistoryGeneralHistoryIdGet(nexusOpsTenant: string, generalHistoryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GeneralHistoryGeneralHistoryIdGet(nexusOpsTenant, generalHistoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralHistoryApi.apiV1GeneralHistoryGeneralHistoryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} generalHistoryId 
         * @param {GeneralHistoryUpdateDto} [generalHistoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GeneralHistoryGeneralHistoryIdPut(nexusOpsTenant: string, generalHistoryId: string, generalHistoryUpdateDto?: GeneralHistoryUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GeneralHistoryGeneralHistoryIdPut(nexusOpsTenant, generalHistoryId, generalHistoryUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralHistoryApi.apiV1GeneralHistoryGeneralHistoryIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralHistoryGetPaginatedDto} [generalHistoryGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GeneralHistoryGetPost(nexusOpsTenant: string, generalHistoryGetPaginatedDto?: GeneralHistoryGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfGeneralHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GeneralHistoryGetPost(nexusOpsTenant, generalHistoryGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralHistoryApi.apiV1GeneralHistoryGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralHistoryCreateDto} [generalHistoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GeneralHistoryPost(nexusOpsTenant: string, generalHistoryCreateDto?: GeneralHistoryCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GeneralHistoryPost(nexusOpsTenant, generalHistoryCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralHistoryApi.apiV1GeneralHistoryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralHistorySearchPaginatedDto} [generalHistorySearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GeneralHistorySearchPost(nexusOpsTenant: string, generalHistorySearchPaginatedDto?: GeneralHistorySearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfGeneralHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GeneralHistorySearchPost(nexusOpsTenant, generalHistorySearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralHistoryApi.apiV1GeneralHistorySearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GeneralHistoryApi - factory interface
 * @export
 */
export const GeneralHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {GeneralHistoryApiApiV1GeneralHistoryBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistoryBulkDeleteDelete(requestParameters: GeneralHistoryApiApiV1GeneralHistoryBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1GeneralHistoryBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistoryGeneralHistoryIdDelete(requestParameters: GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1GeneralHistoryGeneralHistoryIdDelete(requestParameters.nexusOpsTenant, requestParameters.generalHistoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistoryGeneralHistoryIdGet(requestParameters: GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralHistoryDto> {
            return localVarFp.apiV1GeneralHistoryGeneralHistoryIdGet(requestParameters.nexusOpsTenant, requestParameters.generalHistoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistoryGeneralHistoryIdPut(requestParameters: GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralHistoryDto> {
            return localVarFp.apiV1GeneralHistoryGeneralHistoryIdPut(requestParameters.nexusOpsTenant, requestParameters.generalHistoryId, requestParameters.generalHistoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralHistoryApiApiV1GeneralHistoryGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistoryGetPost(requestParameters: GeneralHistoryApiApiV1GeneralHistoryGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfGeneralHistoryDto> {
            return localVarFp.apiV1GeneralHistoryGetPost(requestParameters.nexusOpsTenant, requestParameters.generalHistoryGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralHistoryApiApiV1GeneralHistoryPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistoryPost(requestParameters: GeneralHistoryApiApiV1GeneralHistoryPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralHistoryDto> {
            return localVarFp.apiV1GeneralHistoryPost(requestParameters.nexusOpsTenant, requestParameters.generalHistoryCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralHistoryApiApiV1GeneralHistorySearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralHistorySearchPost(requestParameters: GeneralHistoryApiApiV1GeneralHistorySearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfGeneralHistoryDto> {
            return localVarFp.apiV1GeneralHistorySearchPost(requestParameters.nexusOpsTenant, requestParameters.generalHistorySearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1GeneralHistoryBulkDeleteDelete operation in GeneralHistoryApi.
 * @export
 * @interface GeneralHistoryApiApiV1GeneralHistoryBulkDeleteDeleteRequest
 */
export interface GeneralHistoryApiApiV1GeneralHistoryBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralHistoryApiApiV1GeneralHistoryBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof GeneralHistoryApiApiV1GeneralHistoryBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1GeneralHistoryGeneralHistoryIdDelete operation in GeneralHistoryApi.
 * @export
 * @interface GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdDeleteRequest
 */
export interface GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdDelete
     */
    readonly generalHistoryId: string
}

/**
 * Request parameters for apiV1GeneralHistoryGeneralHistoryIdGet operation in GeneralHistoryApi.
 * @export
 * @interface GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdGetRequest
 */
export interface GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdGet
     */
    readonly generalHistoryId: string
}

/**
 * Request parameters for apiV1GeneralHistoryGeneralHistoryIdPut operation in GeneralHistoryApi.
 * @export
 * @interface GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdPutRequest
 */
export interface GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdPut
     */
    readonly generalHistoryId: string

    /**
     * 
     * @type {GeneralHistoryUpdateDto}
     * @memberof GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdPut
     */
    readonly generalHistoryUpdateDto?: GeneralHistoryUpdateDto
}

/**
 * Request parameters for apiV1GeneralHistoryGetPost operation in GeneralHistoryApi.
 * @export
 * @interface GeneralHistoryApiApiV1GeneralHistoryGetPostRequest
 */
export interface GeneralHistoryApiApiV1GeneralHistoryGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralHistoryApiApiV1GeneralHistoryGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GeneralHistoryGetPaginatedDto}
     * @memberof GeneralHistoryApiApiV1GeneralHistoryGetPost
     */
    readonly generalHistoryGetPaginatedDto?: GeneralHistoryGetPaginatedDto
}

/**
 * Request parameters for apiV1GeneralHistoryPost operation in GeneralHistoryApi.
 * @export
 * @interface GeneralHistoryApiApiV1GeneralHistoryPostRequest
 */
export interface GeneralHistoryApiApiV1GeneralHistoryPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralHistoryApiApiV1GeneralHistoryPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GeneralHistoryCreateDto}
     * @memberof GeneralHistoryApiApiV1GeneralHistoryPost
     */
    readonly generalHistoryCreateDto?: GeneralHistoryCreateDto
}

/**
 * Request parameters for apiV1GeneralHistorySearchPost operation in GeneralHistoryApi.
 * @export
 * @interface GeneralHistoryApiApiV1GeneralHistorySearchPostRequest
 */
export interface GeneralHistoryApiApiV1GeneralHistorySearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralHistoryApiApiV1GeneralHistorySearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GeneralHistorySearchPaginatedDto}
     * @memberof GeneralHistoryApiApiV1GeneralHistorySearchPost
     */
    readonly generalHistorySearchPaginatedDto?: GeneralHistorySearchPaginatedDto
}

/**
 * GeneralHistoryApi - object-oriented interface
 * @export
 * @class GeneralHistoryApi
 * @extends {BaseAPI}
 */
export class GeneralHistoryApi extends BaseAPI {
    /**
     * 
     * @param {GeneralHistoryApiApiV1GeneralHistoryBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralHistoryApi
     */
    public apiV1GeneralHistoryBulkDeleteDelete(requestParameters: GeneralHistoryApiApiV1GeneralHistoryBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return GeneralHistoryApiFp(this.configuration).apiV1GeneralHistoryBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralHistoryApi
     */
    public apiV1GeneralHistoryGeneralHistoryIdDelete(requestParameters: GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return GeneralHistoryApiFp(this.configuration).apiV1GeneralHistoryGeneralHistoryIdDelete(requestParameters.nexusOpsTenant, requestParameters.generalHistoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralHistoryApi
     */
    public apiV1GeneralHistoryGeneralHistoryIdGet(requestParameters: GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdGetRequest, options?: RawAxiosRequestConfig) {
        return GeneralHistoryApiFp(this.configuration).apiV1GeneralHistoryGeneralHistoryIdGet(requestParameters.nexusOpsTenant, requestParameters.generalHistoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralHistoryApi
     */
    public apiV1GeneralHistoryGeneralHistoryIdPut(requestParameters: GeneralHistoryApiApiV1GeneralHistoryGeneralHistoryIdPutRequest, options?: RawAxiosRequestConfig) {
        return GeneralHistoryApiFp(this.configuration).apiV1GeneralHistoryGeneralHistoryIdPut(requestParameters.nexusOpsTenant, requestParameters.generalHistoryId, requestParameters.generalHistoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralHistoryApiApiV1GeneralHistoryGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralHistoryApi
     */
    public apiV1GeneralHistoryGetPost(requestParameters: GeneralHistoryApiApiV1GeneralHistoryGetPostRequest, options?: RawAxiosRequestConfig) {
        return GeneralHistoryApiFp(this.configuration).apiV1GeneralHistoryGetPost(requestParameters.nexusOpsTenant, requestParameters.generalHistoryGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralHistoryApiApiV1GeneralHistoryPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralHistoryApi
     */
    public apiV1GeneralHistoryPost(requestParameters: GeneralHistoryApiApiV1GeneralHistoryPostRequest, options?: RawAxiosRequestConfig) {
        return GeneralHistoryApiFp(this.configuration).apiV1GeneralHistoryPost(requestParameters.nexusOpsTenant, requestParameters.generalHistoryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralHistoryApiApiV1GeneralHistorySearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralHistoryApi
     */
    public apiV1GeneralHistorySearchPost(requestParameters: GeneralHistoryApiApiV1GeneralHistorySearchPostRequest, options?: RawAxiosRequestConfig) {
        return GeneralHistoryApiFp(this.configuration).apiV1GeneralHistorySearchPost(requestParameters.nexusOpsTenant, requestParameters.generalHistorySearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GeneralReferenceDataApi - axios parameter creator
 * @export
 */
export const GeneralReferenceDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralCountriesGet: async (search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/general/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralCulturesDefaultGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/general/cultures/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralCulturesGet: async (search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/general/cultures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralCurrenciesDefaultGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/general/currencies/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralCurrenciesGet: async (search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/general/currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralTimeZonesDefaultGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/general/time-zones/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralTimeZonesGet: async (search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/general/time-zones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralReferenceDataApi - functional programming interface
 * @export
 */
export const GeneralReferenceDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralReferenceDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataGeneralCountriesGet(search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralCountryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataGeneralCountriesGet(search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralReferenceDataApi.apiV1ReferenceDataGeneralCountriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataGeneralCulturesDefaultGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCultureDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataGeneralCulturesDefaultGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralReferenceDataApi.apiV1ReferenceDataGeneralCulturesDefaultGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataGeneralCulturesGet(search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralCultureDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataGeneralCulturesGet(search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralReferenceDataApi.apiV1ReferenceDataGeneralCulturesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataGeneralCurrenciesDefaultGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCurrencyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataGeneralCurrenciesDefaultGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralReferenceDataApi.apiV1ReferenceDataGeneralCurrenciesDefaultGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataGeneralCurrenciesGet(search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralCurrencyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataGeneralCurrenciesGet(search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralReferenceDataApi.apiV1ReferenceDataGeneralCurrenciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataGeneralTimeZonesDefaultGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralTimeZoneDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataGeneralTimeZonesDefaultGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralReferenceDataApi.apiV1ReferenceDataGeneralTimeZonesDefaultGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataGeneralTimeZonesGet(search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralTimeZoneDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataGeneralTimeZonesGet(search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralReferenceDataApi.apiV1ReferenceDataGeneralTimeZonesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GeneralReferenceDataApi - factory interface
 * @export
 */
export const GeneralReferenceDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralReferenceDataApiFp(configuration)
    return {
        /**
         * 
         * @param {GeneralReferenceDataApiApiV1ReferenceDataGeneralCountriesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralCountriesGet(requestParameters: GeneralReferenceDataApiApiV1ReferenceDataGeneralCountriesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<GeneralCountryDto>> {
            return localVarFp.apiV1ReferenceDataGeneralCountriesGet(requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralCulturesDefaultGet(options?: RawAxiosRequestConfig): AxiosPromise<GeneralCultureDto> {
            return localVarFp.apiV1ReferenceDataGeneralCulturesDefaultGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralReferenceDataApiApiV1ReferenceDataGeneralCulturesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralCulturesGet(requestParameters: GeneralReferenceDataApiApiV1ReferenceDataGeneralCulturesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<GeneralCultureDto>> {
            return localVarFp.apiV1ReferenceDataGeneralCulturesGet(requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralCurrenciesDefaultGet(options?: RawAxiosRequestConfig): AxiosPromise<GeneralCurrencyDto> {
            return localVarFp.apiV1ReferenceDataGeneralCurrenciesDefaultGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralReferenceDataApiApiV1ReferenceDataGeneralCurrenciesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralCurrenciesGet(requestParameters: GeneralReferenceDataApiApiV1ReferenceDataGeneralCurrenciesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<GeneralCurrencyDto>> {
            return localVarFp.apiV1ReferenceDataGeneralCurrenciesGet(requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralTimeZonesDefaultGet(options?: RawAxiosRequestConfig): AxiosPromise<GeneralTimeZoneDto> {
            return localVarFp.apiV1ReferenceDataGeneralTimeZonesDefaultGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralReferenceDataApiApiV1ReferenceDataGeneralTimeZonesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataGeneralTimeZonesGet(requestParameters: GeneralReferenceDataApiApiV1ReferenceDataGeneralTimeZonesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<GeneralTimeZoneDto>> {
            return localVarFp.apiV1ReferenceDataGeneralTimeZonesGet(requestParameters.search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ReferenceDataGeneralCountriesGet operation in GeneralReferenceDataApi.
 * @export
 * @interface GeneralReferenceDataApiApiV1ReferenceDataGeneralCountriesGetRequest
 */
export interface GeneralReferenceDataApiApiV1ReferenceDataGeneralCountriesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralReferenceDataApiApiV1ReferenceDataGeneralCountriesGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiV1ReferenceDataGeneralCulturesGet operation in GeneralReferenceDataApi.
 * @export
 * @interface GeneralReferenceDataApiApiV1ReferenceDataGeneralCulturesGetRequest
 */
export interface GeneralReferenceDataApiApiV1ReferenceDataGeneralCulturesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralReferenceDataApiApiV1ReferenceDataGeneralCulturesGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiV1ReferenceDataGeneralCurrenciesGet operation in GeneralReferenceDataApi.
 * @export
 * @interface GeneralReferenceDataApiApiV1ReferenceDataGeneralCurrenciesGetRequest
 */
export interface GeneralReferenceDataApiApiV1ReferenceDataGeneralCurrenciesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralReferenceDataApiApiV1ReferenceDataGeneralCurrenciesGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiV1ReferenceDataGeneralTimeZonesGet operation in GeneralReferenceDataApi.
 * @export
 * @interface GeneralReferenceDataApiApiV1ReferenceDataGeneralTimeZonesGetRequest
 */
export interface GeneralReferenceDataApiApiV1ReferenceDataGeneralTimeZonesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralReferenceDataApiApiV1ReferenceDataGeneralTimeZonesGet
     */
    readonly search?: string
}

/**
 * GeneralReferenceDataApi - object-oriented interface
 * @export
 * @class GeneralReferenceDataApi
 * @extends {BaseAPI}
 */
export class GeneralReferenceDataApi extends BaseAPI {
    /**
     * 
     * @param {GeneralReferenceDataApiApiV1ReferenceDataGeneralCountriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralReferenceDataApi
     */
    public apiV1ReferenceDataGeneralCountriesGet(requestParameters: GeneralReferenceDataApiApiV1ReferenceDataGeneralCountriesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return GeneralReferenceDataApiFp(this.configuration).apiV1ReferenceDataGeneralCountriesGet(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralReferenceDataApi
     */
    public apiV1ReferenceDataGeneralCulturesDefaultGet(options?: RawAxiosRequestConfig) {
        return GeneralReferenceDataApiFp(this.configuration).apiV1ReferenceDataGeneralCulturesDefaultGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralReferenceDataApiApiV1ReferenceDataGeneralCulturesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralReferenceDataApi
     */
    public apiV1ReferenceDataGeneralCulturesGet(requestParameters: GeneralReferenceDataApiApiV1ReferenceDataGeneralCulturesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return GeneralReferenceDataApiFp(this.configuration).apiV1ReferenceDataGeneralCulturesGet(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralReferenceDataApi
     */
    public apiV1ReferenceDataGeneralCurrenciesDefaultGet(options?: RawAxiosRequestConfig) {
        return GeneralReferenceDataApiFp(this.configuration).apiV1ReferenceDataGeneralCurrenciesDefaultGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralReferenceDataApiApiV1ReferenceDataGeneralCurrenciesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralReferenceDataApi
     */
    public apiV1ReferenceDataGeneralCurrenciesGet(requestParameters: GeneralReferenceDataApiApiV1ReferenceDataGeneralCurrenciesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return GeneralReferenceDataApiFp(this.configuration).apiV1ReferenceDataGeneralCurrenciesGet(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralReferenceDataApi
     */
    public apiV1ReferenceDataGeneralTimeZonesDefaultGet(options?: RawAxiosRequestConfig) {
        return GeneralReferenceDataApiFp(this.configuration).apiV1ReferenceDataGeneralTimeZonesDefaultGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralReferenceDataApiApiV1ReferenceDataGeneralTimeZonesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralReferenceDataApi
     */
    public apiV1ReferenceDataGeneralTimeZonesGet(requestParameters: GeneralReferenceDataApiApiV1ReferenceDataGeneralTimeZonesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return GeneralReferenceDataApiFp(this.configuration).apiV1ReferenceDataGeneralTimeZonesGet(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GeneralScopesApi - axios parameter creator
 * @export
 */
export const GeneralScopesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralScopeDto} [generalScopeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralScopesGetUnwoundPost: async (nexusOpsTenant: string, generalScopeDto?: GeneralScopeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1GeneralScopesGetUnwoundPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/general/scopes/get/unwound`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalScopeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralScopesApi - functional programming interface
 * @export
 */
export const GeneralScopesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralScopesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralScopeDto} [generalScopeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GeneralScopesGetUnwoundPost(nexusOpsTenant: string, generalScopeDto?: GeneralScopeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralScopeUnwoundDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GeneralScopesGetUnwoundPost(nexusOpsTenant, generalScopeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralScopesApi.apiV1GeneralScopesGetUnwoundPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GeneralScopesApi - factory interface
 * @export
 */
export const GeneralScopesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralScopesApiFp(configuration)
    return {
        /**
         * 
         * @param {GeneralScopesApiApiV1GeneralScopesGetUnwoundPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GeneralScopesGetUnwoundPost(requestParameters: GeneralScopesApiApiV1GeneralScopesGetUnwoundPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralScopeUnwoundDto> {
            return localVarFp.apiV1GeneralScopesGetUnwoundPost(requestParameters.nexusOpsTenant, requestParameters.generalScopeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1GeneralScopesGetUnwoundPost operation in GeneralScopesApi.
 * @export
 * @interface GeneralScopesApiApiV1GeneralScopesGetUnwoundPostRequest
 */
export interface GeneralScopesApiApiV1GeneralScopesGetUnwoundPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralScopesApiApiV1GeneralScopesGetUnwoundPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GeneralScopeDto}
     * @memberof GeneralScopesApiApiV1GeneralScopesGetUnwoundPost
     */
    readonly generalScopeDto?: GeneralScopeDto
}

/**
 * GeneralScopesApi - object-oriented interface
 * @export
 * @class GeneralScopesApi
 * @extends {BaseAPI}
 */
export class GeneralScopesApi extends BaseAPI {
    /**
     * 
     * @param {GeneralScopesApiApiV1GeneralScopesGetUnwoundPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralScopesApi
     */
    public apiV1GeneralScopesGetUnwoundPost(requestParameters: GeneralScopesApiApiV1GeneralScopesGetUnwoundPostRequest, options?: RawAxiosRequestConfig) {
        return GeneralScopesApiFp(this.configuration).apiV1GeneralScopesGetUnwoundPost(requestParameters.nexusOpsTenant, requestParameters.generalScopeDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GeneralTagsApi - axios parameter creator
 * @export
 */
export const GeneralTagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralByCustomTagCustomTagIdGet: async (nexusOpsTenant: string, customTagId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsGeneralByCustomTagCustomTagIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'customTagId' is not null or undefined
            assertParamExists('apiV1TagsGeneralByCustomTagCustomTagIdGet', 'customTagId', customTagId)
            const localVarPath = `/api/v1/tags/general/by-custom-tag/{customTagId}`
                .replace(`{${"customTagId"}}`, encodeURIComponent(String(customTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet: async (nexusOpsTenant: string, customTagId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'customTagId' is not null or undefined
            assertParamExists('apiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet', 'customTagId', customTagId)
            const localVarPath = `/api/v1/tags/general/candidates/get/by-custom-tag/{customTagId}`
                .replace(`{${"customTagId"}}`, encodeURIComponent(String(customTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralTagCandidatesByScopeRequestDto} [generalTagCandidatesByScopeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralCandidatesGetByScopePost: async (nexusOpsTenant: string, generalTagCandidatesByScopeRequestDto?: GeneralTagCandidatesByScopeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsGeneralCandidatesGetByScopePost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tags/general/candidates/get/by-scope`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalTagCandidatesByScopeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralTagCandidatesRequestDto} [generalTagCandidatesRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralCandidatesGetPost: async (nexusOpsTenant: string, generalTagCandidatesRequestDto?: GeneralTagCandidatesRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsGeneralCandidatesGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tags/general/candidates/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalTagCandidatesRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralAttachedTagsOfEntitiesDeleteDto} [generalAttachedTagsOfEntitiesDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralForEntitiesDeleteDelete: async (nexusOpsTenant: string, generalAttachedTagsOfEntitiesDeleteDto?: GeneralAttachedTagsOfEntitiesDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsGeneralForEntitiesDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tags/general/for-entities/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalAttachedTagsOfEntitiesDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralAttachedTagsOfEntitiesUpdateDto} [generalAttachedTagsOfEntitiesUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralForEntitiesUpdatePut: async (nexusOpsTenant: string, generalAttachedTagsOfEntitiesUpdateDto?: GeneralAttachedTagsOfEntitiesUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsGeneralForEntitiesUpdatePut', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tags/general/for-entities/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalAttachedTagsOfEntitiesUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralAttachedTagsOfEntityDeleteDto} [generalAttachedTagsOfEntityDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralForEntityDeleteDelete: async (nexusOpsTenant: string, generalAttachedTagsOfEntityDeleteDto?: GeneralAttachedTagsOfEntityDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsGeneralForEntityDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tags/general/for-entity/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalAttachedTagsOfEntityDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TagEntityType} [entityType] 
         * @param {string} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralForEntityGetGet: async (nexusOpsTenant: string, entityType?: TagEntityType, entityId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsGeneralForEntityGetGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tags/general/for-entity/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralAttachedTagsOfEntityUpdateDto} [generalAttachedTagsOfEntityUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralForEntityUpdatePut: async (nexusOpsTenant: string, generalAttachedTagsOfEntityUpdateDto?: GeneralAttachedTagsOfEntityUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TagsGeneralForEntityUpdatePut', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tags/general/for-entity/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalAttachedTagsOfEntityUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralTagsApi - functional programming interface
 * @export
 */
export const GeneralTagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralTagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsGeneralByCustomTagCustomTagIdGet(nexusOpsTenant: string, customTagId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsGeneralByCustomTagCustomTagIdGet(nexusOpsTenant, customTagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralTagsApi.apiV1TagsGeneralByCustomTagCustomTagIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} customTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet(nexusOpsTenant: string, customTagId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralTagCandidateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet(nexusOpsTenant, customTagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralTagsApi.apiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralTagCandidatesByScopeRequestDto} [generalTagCandidatesByScopeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsGeneralCandidatesGetByScopePost(nexusOpsTenant: string, generalTagCandidatesByScopeRequestDto?: GeneralTagCandidatesByScopeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfGeneralTagCandidateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsGeneralCandidatesGetByScopePost(nexusOpsTenant, generalTagCandidatesByScopeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralTagsApi.apiV1TagsGeneralCandidatesGetByScopePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralTagCandidatesRequestDto} [generalTagCandidatesRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsGeneralCandidatesGetPost(nexusOpsTenant: string, generalTagCandidatesRequestDto?: GeneralTagCandidatesRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfGeneralTagCandidateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsGeneralCandidatesGetPost(nexusOpsTenant, generalTagCandidatesRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralTagsApi.apiV1TagsGeneralCandidatesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralAttachedTagsOfEntitiesDeleteDto} [generalAttachedTagsOfEntitiesDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsGeneralForEntitiesDeleteDelete(nexusOpsTenant: string, generalAttachedTagsOfEntitiesDeleteDto?: GeneralAttachedTagsOfEntitiesDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsGeneralForEntitiesDeleteDelete(nexusOpsTenant, generalAttachedTagsOfEntitiesDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralTagsApi.apiV1TagsGeneralForEntitiesDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralAttachedTagsOfEntitiesUpdateDto} [generalAttachedTagsOfEntitiesUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsGeneralForEntitiesUpdatePut(nexusOpsTenant: string, generalAttachedTagsOfEntitiesUpdateDto?: GeneralAttachedTagsOfEntitiesUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsGeneralForEntitiesUpdatePut(nexusOpsTenant, generalAttachedTagsOfEntitiesUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralTagsApi.apiV1TagsGeneralForEntitiesUpdatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralAttachedTagsOfEntityDeleteDto} [generalAttachedTagsOfEntityDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsGeneralForEntityDeleteDelete(nexusOpsTenant: string, generalAttachedTagsOfEntityDeleteDto?: GeneralAttachedTagsOfEntityDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralAttachedTagsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsGeneralForEntityDeleteDelete(nexusOpsTenant, generalAttachedTagsOfEntityDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralTagsApi.apiV1TagsGeneralForEntityDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TagEntityType} [entityType] 
         * @param {string} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsGeneralForEntityGetGet(nexusOpsTenant: string, entityType?: TagEntityType, entityId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralAttachedTagsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsGeneralForEntityGetGet(nexusOpsTenant, entityType, entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralTagsApi.apiV1TagsGeneralForEntityGetGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralAttachedTagsOfEntityUpdateDto} [generalAttachedTagsOfEntityUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TagsGeneralForEntityUpdatePut(nexusOpsTenant: string, generalAttachedTagsOfEntityUpdateDto?: GeneralAttachedTagsOfEntityUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralAttachedTagsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TagsGeneralForEntityUpdatePut(nexusOpsTenant, generalAttachedTagsOfEntityUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralTagsApi.apiV1TagsGeneralForEntityUpdatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GeneralTagsApi - factory interface
 * @export
 */
export const GeneralTagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralTagsApiFp(configuration)
    return {
        /**
         * 
         * @param {GeneralTagsApiApiV1TagsGeneralByCustomTagCustomTagIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralByCustomTagCustomTagIdGet(requestParameters: GeneralTagsApiApiV1TagsGeneralByCustomTagCustomTagIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralTagDto> {
            return localVarFp.apiV1TagsGeneralByCustomTagCustomTagIdGet(requestParameters.nexusOpsTenant, requestParameters.customTagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralTagsApiApiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet(requestParameters: GeneralTagsApiApiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralTagCandidateDto> {
            return localVarFp.apiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet(requestParameters.nexusOpsTenant, requestParameters.customTagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralTagsApiApiV1TagsGeneralCandidatesGetByScopePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralCandidatesGetByScopePost(requestParameters: GeneralTagsApiApiV1TagsGeneralCandidatesGetByScopePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfGeneralTagCandidateDto> {
            return localVarFp.apiV1TagsGeneralCandidatesGetByScopePost(requestParameters.nexusOpsTenant, requestParameters.generalTagCandidatesByScopeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralTagsApiApiV1TagsGeneralCandidatesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralCandidatesGetPost(requestParameters: GeneralTagsApiApiV1TagsGeneralCandidatesGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfGeneralTagCandidateDto> {
            return localVarFp.apiV1TagsGeneralCandidatesGetPost(requestParameters.nexusOpsTenant, requestParameters.generalTagCandidatesRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralTagsApiApiV1TagsGeneralForEntitiesDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralForEntitiesDeleteDelete(requestParameters: GeneralTagsApiApiV1TagsGeneralForEntitiesDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TagsGeneralForEntitiesDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.generalAttachedTagsOfEntitiesDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralTagsApiApiV1TagsGeneralForEntitiesUpdatePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralForEntitiesUpdatePut(requestParameters: GeneralTagsApiApiV1TagsGeneralForEntitiesUpdatePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TagsGeneralForEntitiesUpdatePut(requestParameters.nexusOpsTenant, requestParameters.generalAttachedTagsOfEntitiesUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralTagsApiApiV1TagsGeneralForEntityDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralForEntityDeleteDelete(requestParameters: GeneralTagsApiApiV1TagsGeneralForEntityDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralAttachedTagsDto> {
            return localVarFp.apiV1TagsGeneralForEntityDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.generalAttachedTagsOfEntityDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralTagsApiApiV1TagsGeneralForEntityGetGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralForEntityGetGet(requestParameters: GeneralTagsApiApiV1TagsGeneralForEntityGetGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralAttachedTagsDto> {
            return localVarFp.apiV1TagsGeneralForEntityGetGet(requestParameters.nexusOpsTenant, requestParameters.entityType, requestParameters.entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralTagsApiApiV1TagsGeneralForEntityUpdatePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TagsGeneralForEntityUpdatePut(requestParameters: GeneralTagsApiApiV1TagsGeneralForEntityUpdatePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralAttachedTagsDto> {
            return localVarFp.apiV1TagsGeneralForEntityUpdatePut(requestParameters.nexusOpsTenant, requestParameters.generalAttachedTagsOfEntityUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TagsGeneralByCustomTagCustomTagIdGet operation in GeneralTagsApi.
 * @export
 * @interface GeneralTagsApiApiV1TagsGeneralByCustomTagCustomTagIdGetRequest
 */
export interface GeneralTagsApiApiV1TagsGeneralByCustomTagCustomTagIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralTagsApiApiV1TagsGeneralByCustomTagCustomTagIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof GeneralTagsApiApiV1TagsGeneralByCustomTagCustomTagIdGet
     */
    readonly customTagId: string
}

/**
 * Request parameters for apiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet operation in GeneralTagsApi.
 * @export
 * @interface GeneralTagsApiApiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGetRequest
 */
export interface GeneralTagsApiApiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralTagsApiApiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof GeneralTagsApiApiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet
     */
    readonly customTagId: string
}

/**
 * Request parameters for apiV1TagsGeneralCandidatesGetByScopePost operation in GeneralTagsApi.
 * @export
 * @interface GeneralTagsApiApiV1TagsGeneralCandidatesGetByScopePostRequest
 */
export interface GeneralTagsApiApiV1TagsGeneralCandidatesGetByScopePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralTagsApiApiV1TagsGeneralCandidatesGetByScopePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GeneralTagCandidatesByScopeRequestDto}
     * @memberof GeneralTagsApiApiV1TagsGeneralCandidatesGetByScopePost
     */
    readonly generalTagCandidatesByScopeRequestDto?: GeneralTagCandidatesByScopeRequestDto
}

/**
 * Request parameters for apiV1TagsGeneralCandidatesGetPost operation in GeneralTagsApi.
 * @export
 * @interface GeneralTagsApiApiV1TagsGeneralCandidatesGetPostRequest
 */
export interface GeneralTagsApiApiV1TagsGeneralCandidatesGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralTagsApiApiV1TagsGeneralCandidatesGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GeneralTagCandidatesRequestDto}
     * @memberof GeneralTagsApiApiV1TagsGeneralCandidatesGetPost
     */
    readonly generalTagCandidatesRequestDto?: GeneralTagCandidatesRequestDto
}

/**
 * Request parameters for apiV1TagsGeneralForEntitiesDeleteDelete operation in GeneralTagsApi.
 * @export
 * @interface GeneralTagsApiApiV1TagsGeneralForEntitiesDeleteDeleteRequest
 */
export interface GeneralTagsApiApiV1TagsGeneralForEntitiesDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralTagsApiApiV1TagsGeneralForEntitiesDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GeneralAttachedTagsOfEntitiesDeleteDto}
     * @memberof GeneralTagsApiApiV1TagsGeneralForEntitiesDeleteDelete
     */
    readonly generalAttachedTagsOfEntitiesDeleteDto?: GeneralAttachedTagsOfEntitiesDeleteDto
}

/**
 * Request parameters for apiV1TagsGeneralForEntitiesUpdatePut operation in GeneralTagsApi.
 * @export
 * @interface GeneralTagsApiApiV1TagsGeneralForEntitiesUpdatePutRequest
 */
export interface GeneralTagsApiApiV1TagsGeneralForEntitiesUpdatePutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralTagsApiApiV1TagsGeneralForEntitiesUpdatePut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GeneralAttachedTagsOfEntitiesUpdateDto}
     * @memberof GeneralTagsApiApiV1TagsGeneralForEntitiesUpdatePut
     */
    readonly generalAttachedTagsOfEntitiesUpdateDto?: GeneralAttachedTagsOfEntitiesUpdateDto
}

/**
 * Request parameters for apiV1TagsGeneralForEntityDeleteDelete operation in GeneralTagsApi.
 * @export
 * @interface GeneralTagsApiApiV1TagsGeneralForEntityDeleteDeleteRequest
 */
export interface GeneralTagsApiApiV1TagsGeneralForEntityDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralTagsApiApiV1TagsGeneralForEntityDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GeneralAttachedTagsOfEntityDeleteDto}
     * @memberof GeneralTagsApiApiV1TagsGeneralForEntityDeleteDelete
     */
    readonly generalAttachedTagsOfEntityDeleteDto?: GeneralAttachedTagsOfEntityDeleteDto
}

/**
 * Request parameters for apiV1TagsGeneralForEntityGetGet operation in GeneralTagsApi.
 * @export
 * @interface GeneralTagsApiApiV1TagsGeneralForEntityGetGetRequest
 */
export interface GeneralTagsApiApiV1TagsGeneralForEntityGetGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralTagsApiApiV1TagsGeneralForEntityGetGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TagEntityType}
     * @memberof GeneralTagsApiApiV1TagsGeneralForEntityGetGet
     */
    readonly entityType?: TagEntityType

    /**
     * 
     * @type {string}
     * @memberof GeneralTagsApiApiV1TagsGeneralForEntityGetGet
     */
    readonly entityId?: string
}

/**
 * Request parameters for apiV1TagsGeneralForEntityUpdatePut operation in GeneralTagsApi.
 * @export
 * @interface GeneralTagsApiApiV1TagsGeneralForEntityUpdatePutRequest
 */
export interface GeneralTagsApiApiV1TagsGeneralForEntityUpdatePutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GeneralTagsApiApiV1TagsGeneralForEntityUpdatePut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GeneralAttachedTagsOfEntityUpdateDto}
     * @memberof GeneralTagsApiApiV1TagsGeneralForEntityUpdatePut
     */
    readonly generalAttachedTagsOfEntityUpdateDto?: GeneralAttachedTagsOfEntityUpdateDto
}

/**
 * GeneralTagsApi - object-oriented interface
 * @export
 * @class GeneralTagsApi
 * @extends {BaseAPI}
 */
export class GeneralTagsApi extends BaseAPI {
    /**
     * 
     * @param {GeneralTagsApiApiV1TagsGeneralByCustomTagCustomTagIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralTagsApi
     */
    public apiV1TagsGeneralByCustomTagCustomTagIdGet(requestParameters: GeneralTagsApiApiV1TagsGeneralByCustomTagCustomTagIdGetRequest, options?: RawAxiosRequestConfig) {
        return GeneralTagsApiFp(this.configuration).apiV1TagsGeneralByCustomTagCustomTagIdGet(requestParameters.nexusOpsTenant, requestParameters.customTagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralTagsApiApiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralTagsApi
     */
    public apiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet(requestParameters: GeneralTagsApiApiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGetRequest, options?: RawAxiosRequestConfig) {
        return GeneralTagsApiFp(this.configuration).apiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet(requestParameters.nexusOpsTenant, requestParameters.customTagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralTagsApiApiV1TagsGeneralCandidatesGetByScopePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralTagsApi
     */
    public apiV1TagsGeneralCandidatesGetByScopePost(requestParameters: GeneralTagsApiApiV1TagsGeneralCandidatesGetByScopePostRequest, options?: RawAxiosRequestConfig) {
        return GeneralTagsApiFp(this.configuration).apiV1TagsGeneralCandidatesGetByScopePost(requestParameters.nexusOpsTenant, requestParameters.generalTagCandidatesByScopeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralTagsApiApiV1TagsGeneralCandidatesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralTagsApi
     */
    public apiV1TagsGeneralCandidatesGetPost(requestParameters: GeneralTagsApiApiV1TagsGeneralCandidatesGetPostRequest, options?: RawAxiosRequestConfig) {
        return GeneralTagsApiFp(this.configuration).apiV1TagsGeneralCandidatesGetPost(requestParameters.nexusOpsTenant, requestParameters.generalTagCandidatesRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralTagsApiApiV1TagsGeneralForEntitiesDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralTagsApi
     */
    public apiV1TagsGeneralForEntitiesDeleteDelete(requestParameters: GeneralTagsApiApiV1TagsGeneralForEntitiesDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return GeneralTagsApiFp(this.configuration).apiV1TagsGeneralForEntitiesDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.generalAttachedTagsOfEntitiesDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralTagsApiApiV1TagsGeneralForEntitiesUpdatePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralTagsApi
     */
    public apiV1TagsGeneralForEntitiesUpdatePut(requestParameters: GeneralTagsApiApiV1TagsGeneralForEntitiesUpdatePutRequest, options?: RawAxiosRequestConfig) {
        return GeneralTagsApiFp(this.configuration).apiV1TagsGeneralForEntitiesUpdatePut(requestParameters.nexusOpsTenant, requestParameters.generalAttachedTagsOfEntitiesUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralTagsApiApiV1TagsGeneralForEntityDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralTagsApi
     */
    public apiV1TagsGeneralForEntityDeleteDelete(requestParameters: GeneralTagsApiApiV1TagsGeneralForEntityDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return GeneralTagsApiFp(this.configuration).apiV1TagsGeneralForEntityDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.generalAttachedTagsOfEntityDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralTagsApiApiV1TagsGeneralForEntityGetGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralTagsApi
     */
    public apiV1TagsGeneralForEntityGetGet(requestParameters: GeneralTagsApiApiV1TagsGeneralForEntityGetGetRequest, options?: RawAxiosRequestConfig) {
        return GeneralTagsApiFp(this.configuration).apiV1TagsGeneralForEntityGetGet(requestParameters.nexusOpsTenant, requestParameters.entityType, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralTagsApiApiV1TagsGeneralForEntityUpdatePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralTagsApi
     */
    public apiV1TagsGeneralForEntityUpdatePut(requestParameters: GeneralTagsApiApiV1TagsGeneralForEntityUpdatePutRequest, options?: RawAxiosRequestConfig) {
        return GeneralTagsApiFp(this.configuration).apiV1TagsGeneralForEntityUpdatePut(requestParameters.nexusOpsTenant, requestParameters.generalAttachedTagsOfEntityUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GlobalSearchApi - axios parameter creator
 * @export
 */
export const GlobalSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {number} [limitPerCategory] 
         * @param {string} [search] 
         * @param {GlobalSearchCategory} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SearchGlobalGet: async (nexusOpsTenant: string, offset?: number, limit?: number, limitPerCategory?: number, search?: string, category?: GlobalSearchCategory, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1SearchGlobalGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/search/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (limitPerCategory !== undefined) {
                localVarQueryParameter['limitPerCategory'] = limitPerCategory;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalSearchApi - functional programming interface
 * @export
 */
export const GlobalSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {number} [limitPerCategory] 
         * @param {string} [search] 
         * @param {GlobalSearchCategory} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SearchGlobalGet(nexusOpsTenant: string, offset?: number, limit?: number, limitPerCategory?: number, search?: string, category?: GlobalSearchCategory, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalSearchResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SearchGlobalGet(nexusOpsTenant, offset, limit, limitPerCategory, search, category, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalSearchApi.apiV1SearchGlobalGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GlobalSearchApi - factory interface
 * @export
 */
export const GlobalSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalSearchApiFp(configuration)
    return {
        /**
         * 
         * @param {GlobalSearchApiApiV1SearchGlobalGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SearchGlobalGet(requestParameters: GlobalSearchApiApiV1SearchGlobalGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GlobalSearchResultDto> {
            return localVarFp.apiV1SearchGlobalGet(requestParameters.nexusOpsTenant, requestParameters.offset, requestParameters.limit, requestParameters.limitPerCategory, requestParameters.search, requestParameters.category, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1SearchGlobalGet operation in GlobalSearchApi.
 * @export
 * @interface GlobalSearchApiApiV1SearchGlobalGetRequest
 */
export interface GlobalSearchApiApiV1SearchGlobalGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof GlobalSearchApiApiV1SearchGlobalGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {number}
     * @memberof GlobalSearchApiApiV1SearchGlobalGet
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof GlobalSearchApiApiV1SearchGlobalGet
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof GlobalSearchApiApiV1SearchGlobalGet
     */
    readonly limitPerCategory?: number

    /**
     * 
     * @type {string}
     * @memberof GlobalSearchApiApiV1SearchGlobalGet
     */
    readonly search?: string

    /**
     * 
     * @type {GlobalSearchCategory}
     * @memberof GlobalSearchApiApiV1SearchGlobalGet
     */
    readonly category?: GlobalSearchCategory
}

/**
 * GlobalSearchApi - object-oriented interface
 * @export
 * @class GlobalSearchApi
 * @extends {BaseAPI}
 */
export class GlobalSearchApi extends BaseAPI {
    /**
     * 
     * @param {GlobalSearchApiApiV1SearchGlobalGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalSearchApi
     */
    public apiV1SearchGlobalGet(requestParameters: GlobalSearchApiApiV1SearchGlobalGetRequest, options?: RawAxiosRequestConfig) {
        return GlobalSearchApiFp(this.configuration).apiV1SearchGlobalGet(requestParameters.nexusOpsTenant, requestParameters.offset, requestParameters.limit, requestParameters.limitPerCategory, requestParameters.search, requestParameters.category, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IntegrationApiClientsApi - axios parameter creator
 * @export
 */
export const IntegrationApiClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {IntegrationApiClientGetPaginatedDto} [integrationApiClientGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsGetPost: async (nexusOpsTenant: string, integrationApiClientGetPaginatedDto?: IntegrationApiClientGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/integration/api-clients/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationApiClientGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdDelete: async (nexusOpsTenant: string, integrationApiClientId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdDelete', 'integrationApiClientId', integrationApiClientId)
            const localVarPath = `/api/v1/integration/api-clients/{integrationApiClientId}`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdGet: async (nexusOpsTenant: string, integrationApiClientId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdGet', 'integrationApiClientId', integrationApiClientId)
            const localVarPath = `/api/v1/integration/api-clients/{integrationApiClientId}`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {string} keyId 
         * @param {IntegrationApiClientKeyInputDto} [integrationApiClientKeyInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut: async (nexusOpsTenant: string, integrationApiClientId: string, keyId: string, integrationApiClientKeyInputDto?: IntegrationApiClientKeyInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut', 'integrationApiClientId', integrationApiClientId)
            // verify required parameter 'keyId' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut', 'keyId', keyId)
            const localVarPath = `/api/v1/integration/api-clients/{integrationApiClientId}/keys/{keyId}`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)))
                .replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationApiClientKeyInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {string} keyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost: async (nexusOpsTenant: string, integrationApiClientId: string, keyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost', 'integrationApiClientId', integrationApiClientId)
            // verify required parameter 'keyId' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost', 'keyId', keyId)
            const localVarPath = `/api/v1/integration/api-clients/{integrationApiClientId}/keys/{keyId}/revoke`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)))
                .replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {string} keyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet: async (nexusOpsTenant: string, integrationApiClientId: string, keyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet', 'integrationApiClientId', integrationApiClientId)
            // verify required parameter 'keyId' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet', 'keyId', keyId)
            const localVarPath = `/api/v1/integration/api-clients/{integrationApiClientId}/keys/{keyId}/value`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)))
                .replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {IntegrationApiClientKeyInputDto} [integrationApiClientKeyInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdKeysPost: async (nexusOpsTenant: string, integrationApiClientId: string, integrationApiClientKeyInputDto?: IntegrationApiClientKeyInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdKeysPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdKeysPost', 'integrationApiClientId', integrationApiClientId)
            const localVarPath = `/api/v1/integration/api-clients/{integrationApiClientId}/keys`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationApiClientKeyInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {IntegrationApiClientUpdateDto} [integrationApiClientUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdPut: async (nexusOpsTenant: string, integrationApiClientId: string, integrationApiClientUpdateDto?: IntegrationApiClientUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'integrationApiClientId' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsIntegrationApiClientIdPut', 'integrationApiClientId', integrationApiClientId)
            const localVarPath = `/api/v1/integration/api-clients/{integrationApiClientId}`
                .replace(`{${"integrationApiClientId"}}`, encodeURIComponent(String(integrationApiClientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationApiClientUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {IntegrationApiClientCreateDto} [integrationApiClientCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsPost: async (nexusOpsTenant: string, integrationApiClientCreateDto?: IntegrationApiClientCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/integration/api-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationApiClientCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {IntegrationApiClientSearchPaginatedDto} [integrationApiClientSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsSearchPost: async (nexusOpsTenant: string, integrationApiClientSearchPaginatedDto?: IntegrationApiClientSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1IntegrationApiClientsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/integration/api-clients/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationApiClientSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationApiClientsApi - functional programming interface
 * @export
 */
export const IntegrationApiClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationApiClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {IntegrationApiClientGetPaginatedDto} [integrationApiClientGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IntegrationApiClientsGetPost(nexusOpsTenant: string, integrationApiClientGetPaginatedDto?: IntegrationApiClientGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfIntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IntegrationApiClientsGetPost(nexusOpsTenant, integrationApiClientGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApiClientsApi.apiV1IntegrationApiClientsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IntegrationApiClientsIntegrationApiClientIdDelete(nexusOpsTenant: string, integrationApiClientId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IntegrationApiClientsIntegrationApiClientIdDelete(nexusOpsTenant, integrationApiClientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IntegrationApiClientsIntegrationApiClientIdGet(nexusOpsTenant: string, integrationApiClientId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IntegrationApiClientsIntegrationApiClientIdGet(nexusOpsTenant, integrationApiClientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {string} keyId 
         * @param {IntegrationApiClientKeyInputDto} [integrationApiClientKeyInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut(nexusOpsTenant: string, integrationApiClientId: string, keyId: string, integrationApiClientKeyInputDto?: IntegrationApiClientKeyInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut(nexusOpsTenant, integrationApiClientId, keyId, integrationApiClientKeyInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {string} keyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(nexusOpsTenant: string, integrationApiClientId: string, keyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(nexusOpsTenant, integrationApiClientId, keyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {string} keyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(nexusOpsTenant: string, integrationApiClientId: string, keyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientKeyValueDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(nexusOpsTenant, integrationApiClientId, keyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {IntegrationApiClientKeyInputDto} [integrationApiClientKeyInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IntegrationApiClientsIntegrationApiClientIdKeysPost(nexusOpsTenant: string, integrationApiClientId: string, integrationApiClientKeyInputDto?: IntegrationApiClientKeyInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IntegrationApiClientsIntegrationApiClientIdKeysPost(nexusOpsTenant, integrationApiClientId, integrationApiClientKeyInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdKeysPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} integrationApiClientId 
         * @param {IntegrationApiClientUpdateDto} [integrationApiClientUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IntegrationApiClientsIntegrationApiClientIdPut(nexusOpsTenant: string, integrationApiClientId: string, integrationApiClientUpdateDto?: IntegrationApiClientUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IntegrationApiClientsIntegrationApiClientIdPut(nexusOpsTenant, integrationApiClientId, integrationApiClientUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {IntegrationApiClientCreateDto} [integrationApiClientCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IntegrationApiClientsPost(nexusOpsTenant: string, integrationApiClientCreateDto?: IntegrationApiClientCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IntegrationApiClientsPost(nexusOpsTenant, integrationApiClientCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApiClientsApi.apiV1IntegrationApiClientsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {IntegrationApiClientSearchPaginatedDto} [integrationApiClientSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IntegrationApiClientsSearchPost(nexusOpsTenant: string, integrationApiClientSearchPaginatedDto?: IntegrationApiClientSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfIntegrationApiClientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IntegrationApiClientsSearchPost(nexusOpsTenant, integrationApiClientSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApiClientsApi.apiV1IntegrationApiClientsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IntegrationApiClientsApi - factory interface
 * @export
 */
export const IntegrationApiClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationApiClientsApiFp(configuration)
    return {
        /**
         * 
         * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsGetPost(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfIntegrationApiClientDto> {
            return localVarFp.apiV1IntegrationApiClientsGetPost(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdDelete(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1IntegrationApiClientsIntegrationApiClientIdDelete(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdGet(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientDto> {
            return localVarFp.apiV1IntegrationApiClientsIntegrationApiClientIdGet(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientDto> {
            return localVarFp.apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, requestParameters.keyId, requestParameters.integrationApiClientKeyInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientDto> {
            return localVarFp.apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, requestParameters.keyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientKeyValueDto> {
            return localVarFp.apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, requestParameters.keyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdKeysPost(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientDto> {
            return localVarFp.apiV1IntegrationApiClientsIntegrationApiClientIdKeysPost(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, requestParameters.integrationApiClientKeyInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsIntegrationApiClientIdPut(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientDto> {
            return localVarFp.apiV1IntegrationApiClientsIntegrationApiClientIdPut(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, requestParameters.integrationApiClientUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsPost(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<IntegrationApiClientDto> {
            return localVarFp.apiV1IntegrationApiClientsPost(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IntegrationApiClientsSearchPost(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfIntegrationApiClientDto> {
            return localVarFp.apiV1IntegrationApiClientsSearchPost(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1IntegrationApiClientsGetPost operation in IntegrationApiClientsApi.
 * @export
 * @interface IntegrationApiClientsApiApiV1IntegrationApiClientsGetPostRequest
 */
export interface IntegrationApiClientsApiApiV1IntegrationApiClientsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {IntegrationApiClientGetPaginatedDto}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsGetPost
     */
    readonly integrationApiClientGetPaginatedDto?: IntegrationApiClientGetPaginatedDto
}

/**
 * Request parameters for apiV1IntegrationApiClientsIntegrationApiClientIdDelete operation in IntegrationApiClientsApi.
 * @export
 * @interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdDeleteRequest
 */
export interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdDelete
     */
    readonly integrationApiClientId: string
}

/**
 * Request parameters for apiV1IntegrationApiClientsIntegrationApiClientIdGet operation in IntegrationApiClientsApi.
 * @export
 * @interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdGetRequest
 */
export interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdGet
     */
    readonly integrationApiClientId: string
}

/**
 * Request parameters for apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut operation in IntegrationApiClientsApi.
 * @export
 * @interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPutRequest
 */
export interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut
     */
    readonly integrationApiClientId: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut
     */
    readonly keyId: string

    /**
     * 
     * @type {IntegrationApiClientKeyInputDto}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut
     */
    readonly integrationApiClientKeyInputDto?: IntegrationApiClientKeyInputDto
}

/**
 * Request parameters for apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost operation in IntegrationApiClientsApi.
 * @export
 * @interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePostRequest
 */
export interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost
     */
    readonly integrationApiClientId: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost
     */
    readonly keyId: string
}

/**
 * Request parameters for apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet operation in IntegrationApiClientsApi.
 * @export
 * @interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGetRequest
 */
export interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet
     */
    readonly integrationApiClientId: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet
     */
    readonly keyId: string
}

/**
 * Request parameters for apiV1IntegrationApiClientsIntegrationApiClientIdKeysPost operation in IntegrationApiClientsApi.
 * @export
 * @interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysPostRequest
 */
export interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysPost
     */
    readonly integrationApiClientId: string

    /**
     * 
     * @type {IntegrationApiClientKeyInputDto}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysPost
     */
    readonly integrationApiClientKeyInputDto?: IntegrationApiClientKeyInputDto
}

/**
 * Request parameters for apiV1IntegrationApiClientsIntegrationApiClientIdPut operation in IntegrationApiClientsApi.
 * @export
 * @interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdPutRequest
 */
export interface IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdPut
     */
    readonly integrationApiClientId: string

    /**
     * 
     * @type {IntegrationApiClientUpdateDto}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdPut
     */
    readonly integrationApiClientUpdateDto?: IntegrationApiClientUpdateDto
}

/**
 * Request parameters for apiV1IntegrationApiClientsPost operation in IntegrationApiClientsApi.
 * @export
 * @interface IntegrationApiClientsApiApiV1IntegrationApiClientsPostRequest
 */
export interface IntegrationApiClientsApiApiV1IntegrationApiClientsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {IntegrationApiClientCreateDto}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsPost
     */
    readonly integrationApiClientCreateDto?: IntegrationApiClientCreateDto
}

/**
 * Request parameters for apiV1IntegrationApiClientsSearchPost operation in IntegrationApiClientsApi.
 * @export
 * @interface IntegrationApiClientsApiApiV1IntegrationApiClientsSearchPostRequest
 */
export interface IntegrationApiClientsApiApiV1IntegrationApiClientsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {IntegrationApiClientSearchPaginatedDto}
     * @memberof IntegrationApiClientsApiApiV1IntegrationApiClientsSearchPost
     */
    readonly integrationApiClientSearchPaginatedDto?: IntegrationApiClientSearchPaginatedDto
}

/**
 * IntegrationApiClientsApi - object-oriented interface
 * @export
 * @class IntegrationApiClientsApi
 * @extends {BaseAPI}
 */
export class IntegrationApiClientsApi extends BaseAPI {
    /**
     * 
     * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApiClientsApi
     */
    public apiV1IntegrationApiClientsGetPost(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsGetPostRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiClientsApiFp(this.configuration).apiV1IntegrationApiClientsGetPost(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApiClientsApi
     */
    public apiV1IntegrationApiClientsIntegrationApiClientIdDelete(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiClientsApiFp(this.configuration).apiV1IntegrationApiClientsIntegrationApiClientIdDelete(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApiClientsApi
     */
    public apiV1IntegrationApiClientsIntegrationApiClientIdGet(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdGetRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiClientsApiFp(this.configuration).apiV1IntegrationApiClientsIntegrationApiClientIdGet(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApiClientsApi
     */
    public apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPutRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiClientsApiFp(this.configuration).apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdPut(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, requestParameters.keyId, requestParameters.integrationApiClientKeyInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApiClientsApi
     */
    public apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePostRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiClientsApiFp(this.configuration).apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, requestParameters.keyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApiClientsApi
     */
    public apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGetRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiClientsApiFp(this.configuration).apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, requestParameters.keyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApiClientsApi
     */
    public apiV1IntegrationApiClientsIntegrationApiClientIdKeysPost(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdKeysPostRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiClientsApiFp(this.configuration).apiV1IntegrationApiClientsIntegrationApiClientIdKeysPost(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, requestParameters.integrationApiClientKeyInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApiClientsApi
     */
    public apiV1IntegrationApiClientsIntegrationApiClientIdPut(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsIntegrationApiClientIdPutRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiClientsApiFp(this.configuration).apiV1IntegrationApiClientsIntegrationApiClientIdPut(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientId, requestParameters.integrationApiClientUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApiClientsApi
     */
    public apiV1IntegrationApiClientsPost(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsPostRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiClientsApiFp(this.configuration).apiV1IntegrationApiClientsPost(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IntegrationApiClientsApiApiV1IntegrationApiClientsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApiClientsApi
     */
    public apiV1IntegrationApiClientsSearchPost(requestParameters: IntegrationApiClientsApiApiV1IntegrationApiClientsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiClientsApiFp(this.configuration).apiV1IntegrationApiClientsSearchPost(requestParameters.nexusOpsTenant, requestParameters.integrationApiClientSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvitesApi - axios parameter creator
 * @export
 */
export const InvitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesInviteIdAcceptPost: async (inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('apiV1InvitesInviteIdAcceptPost', 'inviteId', inviteId)
            const localVarPath = `/api/v1/invites/{inviteId}/accept`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesInviteIdAnonymouslyGet: async (inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('apiV1InvitesInviteIdAnonymouslyGet', 'inviteId', inviteId)
            const localVarPath = `/api/v1/invites/{inviteId}/anonymously`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {RegisterInvitedUserDto} [registerInvitedUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesInviteIdRegisterPost: async (inviteId: string, registerInvitedUserDto?: RegisterInvitedUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('apiV1InvitesInviteIdRegisterPost', 'inviteId', inviteId)
            const localVarPath = `/api/v1/invites/{inviteId}/register`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerInvitedUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesInviteIdRejectPost: async (inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('apiV1InvitesInviteIdRejectPost', 'inviteId', inviteId)
            const localVarPath = `/api/v1/invites/{inviteId}/reject`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesMyGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/invites/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesMyInviteIdGet: async (inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('apiV1InvitesMyInviteIdGet', 'inviteId', inviteId)
            const localVarPath = `/api/v1/invites/my/{inviteId}`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InviteCheckUserExistsDto} [inviteCheckUserExistsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesUserExistsAnonymouslyPost: async (inviteCheckUserExistsDto?: InviteCheckUserExistsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/invites/user-exists/anonymously`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteCheckUserExistsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvitesApi - functional programming interface
 * @export
 */
export const InvitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvitesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvitesInviteIdAcceptPost(inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvitesInviteIdAcceptPost(inviteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitesApi.apiV1InvitesInviteIdAcceptPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvitesInviteIdAnonymouslyGet(inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvitesInviteIdAnonymouslyGet(inviteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitesApi.apiV1InvitesInviteIdAnonymouslyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {RegisterInvitedUserDto} [registerInvitedUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvitesInviteIdRegisterPost(inviteId: string, registerInvitedUserDto?: RegisterInvitedUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvitesInviteIdRegisterPost(inviteId, registerInvitedUserDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitesApi.apiV1InvitesInviteIdRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvitesInviteIdRejectPost(inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvitesInviteIdRejectPost(inviteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitesApi.apiV1InvitesInviteIdRejectPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvitesMyGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InviteDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvitesMyGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitesApi.apiV1InvitesMyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvitesMyInviteIdGet(inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvitesMyInviteIdGet(inviteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitesApi.apiV1InvitesMyInviteIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InviteCheckUserExistsDto} [inviteCheckUserExistsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvitesUserExistsAnonymouslyPost(inviteCheckUserExistsDto?: InviteCheckUserExistsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteUserExistsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvitesUserExistsAnonymouslyPost(inviteCheckUserExistsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitesApi.apiV1InvitesUserExistsAnonymouslyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvitesApi - factory interface
 * @export
 */
export const InvitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvitesApiFp(configuration)
    return {
        /**
         * 
         * @param {InvitesApiApiV1InvitesInviteIdAcceptPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesInviteIdAcceptPost(requestParameters: InvitesApiApiV1InvitesInviteIdAcceptPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1InvitesInviteIdAcceptPost(requestParameters.inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InvitesApiApiV1InvitesInviteIdAnonymouslyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesInviteIdAnonymouslyGet(requestParameters: InvitesApiApiV1InvitesInviteIdAnonymouslyGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<InviteDto> {
            return localVarFp.apiV1InvitesInviteIdAnonymouslyGet(requestParameters.inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InvitesApiApiV1InvitesInviteIdRegisterPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesInviteIdRegisterPost(requestParameters: InvitesApiApiV1InvitesInviteIdRegisterPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1InvitesInviteIdRegisterPost(requestParameters.inviteId, requestParameters.registerInvitedUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InvitesApiApiV1InvitesInviteIdRejectPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesInviteIdRejectPost(requestParameters: InvitesApiApiV1InvitesInviteIdRejectPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1InvitesInviteIdRejectPost(requestParameters.inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesMyGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<InviteDto>> {
            return localVarFp.apiV1InvitesMyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InvitesApiApiV1InvitesMyInviteIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesMyInviteIdGet(requestParameters: InvitesApiApiV1InvitesMyInviteIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<InviteDto> {
            return localVarFp.apiV1InvitesMyInviteIdGet(requestParameters.inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InvitesApiApiV1InvitesUserExistsAnonymouslyPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitesUserExistsAnonymouslyPost(requestParameters: InvitesApiApiV1InvitesUserExistsAnonymouslyPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InviteUserExistsDto> {
            return localVarFp.apiV1InvitesUserExistsAnonymouslyPost(requestParameters.inviteCheckUserExistsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1InvitesInviteIdAcceptPost operation in InvitesApi.
 * @export
 * @interface InvitesApiApiV1InvitesInviteIdAcceptPostRequest
 */
export interface InvitesApiApiV1InvitesInviteIdAcceptPostRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitesApiApiV1InvitesInviteIdAcceptPost
     */
    readonly inviteId: string
}

/**
 * Request parameters for apiV1InvitesInviteIdAnonymouslyGet operation in InvitesApi.
 * @export
 * @interface InvitesApiApiV1InvitesInviteIdAnonymouslyGetRequest
 */
export interface InvitesApiApiV1InvitesInviteIdAnonymouslyGetRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitesApiApiV1InvitesInviteIdAnonymouslyGet
     */
    readonly inviteId: string
}

/**
 * Request parameters for apiV1InvitesInviteIdRegisterPost operation in InvitesApi.
 * @export
 * @interface InvitesApiApiV1InvitesInviteIdRegisterPostRequest
 */
export interface InvitesApiApiV1InvitesInviteIdRegisterPostRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitesApiApiV1InvitesInviteIdRegisterPost
     */
    readonly inviteId: string

    /**
     * 
     * @type {RegisterInvitedUserDto}
     * @memberof InvitesApiApiV1InvitesInviteIdRegisterPost
     */
    readonly registerInvitedUserDto?: RegisterInvitedUserDto
}

/**
 * Request parameters for apiV1InvitesInviteIdRejectPost operation in InvitesApi.
 * @export
 * @interface InvitesApiApiV1InvitesInviteIdRejectPostRequest
 */
export interface InvitesApiApiV1InvitesInviteIdRejectPostRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitesApiApiV1InvitesInviteIdRejectPost
     */
    readonly inviteId: string
}

/**
 * Request parameters for apiV1InvitesMyInviteIdGet operation in InvitesApi.
 * @export
 * @interface InvitesApiApiV1InvitesMyInviteIdGetRequest
 */
export interface InvitesApiApiV1InvitesMyInviteIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitesApiApiV1InvitesMyInviteIdGet
     */
    readonly inviteId: string
}

/**
 * Request parameters for apiV1InvitesUserExistsAnonymouslyPost operation in InvitesApi.
 * @export
 * @interface InvitesApiApiV1InvitesUserExistsAnonymouslyPostRequest
 */
export interface InvitesApiApiV1InvitesUserExistsAnonymouslyPostRequest {
    /**
     * 
     * @type {InviteCheckUserExistsDto}
     * @memberof InvitesApiApiV1InvitesUserExistsAnonymouslyPost
     */
    readonly inviteCheckUserExistsDto?: InviteCheckUserExistsDto
}

/**
 * InvitesApi - object-oriented interface
 * @export
 * @class InvitesApi
 * @extends {BaseAPI}
 */
export class InvitesApi extends BaseAPI {
    /**
     * 
     * @param {InvitesApiApiV1InvitesInviteIdAcceptPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    public apiV1InvitesInviteIdAcceptPost(requestParameters: InvitesApiApiV1InvitesInviteIdAcceptPostRequest, options?: RawAxiosRequestConfig) {
        return InvitesApiFp(this.configuration).apiV1InvitesInviteIdAcceptPost(requestParameters.inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvitesApiApiV1InvitesInviteIdAnonymouslyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    public apiV1InvitesInviteIdAnonymouslyGet(requestParameters: InvitesApiApiV1InvitesInviteIdAnonymouslyGetRequest, options?: RawAxiosRequestConfig) {
        return InvitesApiFp(this.configuration).apiV1InvitesInviteIdAnonymouslyGet(requestParameters.inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvitesApiApiV1InvitesInviteIdRegisterPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    public apiV1InvitesInviteIdRegisterPost(requestParameters: InvitesApiApiV1InvitesInviteIdRegisterPostRequest, options?: RawAxiosRequestConfig) {
        return InvitesApiFp(this.configuration).apiV1InvitesInviteIdRegisterPost(requestParameters.inviteId, requestParameters.registerInvitedUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvitesApiApiV1InvitesInviteIdRejectPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    public apiV1InvitesInviteIdRejectPost(requestParameters: InvitesApiApiV1InvitesInviteIdRejectPostRequest, options?: RawAxiosRequestConfig) {
        return InvitesApiFp(this.configuration).apiV1InvitesInviteIdRejectPost(requestParameters.inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    public apiV1InvitesMyGet(options?: RawAxiosRequestConfig) {
        return InvitesApiFp(this.configuration).apiV1InvitesMyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvitesApiApiV1InvitesMyInviteIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    public apiV1InvitesMyInviteIdGet(requestParameters: InvitesApiApiV1InvitesMyInviteIdGetRequest, options?: RawAxiosRequestConfig) {
        return InvitesApiFp(this.configuration).apiV1InvitesMyInviteIdGet(requestParameters.inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvitesApiApiV1InvitesUserExistsAnonymouslyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    public apiV1InvitesUserExistsAnonymouslyPost(requestParameters: InvitesApiApiV1InvitesUserExistsAnonymouslyPostRequest = {}, options?: RawAxiosRequestConfig) {
        return InvitesApiFp(this.configuration).apiV1InvitesUserExistsAnonymouslyPost(requestParameters.inviteCheckUserExistsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {InvoiceGetPaginatedDto} [invoiceGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvoicesGetPost: async (nexusOpsTenant: string, invoiceGetPaginatedDto?: InvoiceGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1InvoicesGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/invoices/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvoicesInvoiceIdDownloadPdfGet: async (nexusOpsTenant: string, invoiceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1InvoicesInvoiceIdDownloadPdfGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiV1InvoicesInvoiceIdDownloadPdfGet', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/invoices/{invoiceId}/download/pdf`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvoicesInvoiceIdGet: async (nexusOpsTenant: string, invoiceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1InvoicesInvoiceIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiV1InvoicesInvoiceIdGet', 'invoiceId', invoiceId)
            const localVarPath = `/api/v1/invoices/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {InvoiceGetPaginatedDto} [invoiceGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvoicesGetPost(nexusOpsTenant: string, invoiceGetPaginatedDto?: InvoiceGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvoicesGetPost(nexusOpsTenant, invoiceGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoicesApi.apiV1InvoicesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvoicesInvoiceIdDownloadPdfGet(nexusOpsTenant: string, invoiceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvoicesInvoiceIdDownloadPdfGet(nexusOpsTenant, invoiceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoicesApi.apiV1InvoicesInvoiceIdDownloadPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvoicesInvoiceIdGet(nexusOpsTenant: string, invoiceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvoicesInvoiceIdGet(nexusOpsTenant, invoiceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoicesApi.apiV1InvoicesInvoiceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoicesApiFp(configuration)
    return {
        /**
         * 
         * @param {InvoicesApiApiV1InvoicesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvoicesGetPost(requestParameters: InvoicesApiApiV1InvoicesGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfInvoiceDto> {
            return localVarFp.apiV1InvoicesGetPost(requestParameters.nexusOpsTenant, requestParameters.invoiceGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InvoicesApiApiV1InvoicesInvoiceIdDownloadPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvoicesInvoiceIdDownloadPdfGet(requestParameters: InvoicesApiApiV1InvoicesInvoiceIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1InvoicesInvoiceIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InvoicesApiApiV1InvoicesInvoiceIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvoicesInvoiceIdGet(requestParameters: InvoicesApiApiV1InvoicesInvoiceIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<InvoiceDto> {
            return localVarFp.apiV1InvoicesInvoiceIdGet(requestParameters.nexusOpsTenant, requestParameters.invoiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1InvoicesGetPost operation in InvoicesApi.
 * @export
 * @interface InvoicesApiApiV1InvoicesGetPostRequest
 */
export interface InvoicesApiApiV1InvoicesGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof InvoicesApiApiV1InvoicesGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {InvoiceGetPaginatedDto}
     * @memberof InvoicesApiApiV1InvoicesGetPost
     */
    readonly invoiceGetPaginatedDto?: InvoiceGetPaginatedDto
}

/**
 * Request parameters for apiV1InvoicesInvoiceIdDownloadPdfGet operation in InvoicesApi.
 * @export
 * @interface InvoicesApiApiV1InvoicesInvoiceIdDownloadPdfGetRequest
 */
export interface InvoicesApiApiV1InvoicesInvoiceIdDownloadPdfGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof InvoicesApiApiV1InvoicesInvoiceIdDownloadPdfGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiApiV1InvoicesInvoiceIdDownloadPdfGet
     */
    readonly invoiceId: string
}

/**
 * Request parameters for apiV1InvoicesInvoiceIdGet operation in InvoicesApi.
 * @export
 * @interface InvoicesApiApiV1InvoicesInvoiceIdGetRequest
 */
export interface InvoicesApiApiV1InvoicesInvoiceIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof InvoicesApiApiV1InvoicesInvoiceIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof InvoicesApiApiV1InvoicesInvoiceIdGet
     */
    readonly invoiceId: string
}

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
    /**
     * 
     * @param {InvoicesApiApiV1InvoicesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiV1InvoicesGetPost(requestParameters: InvoicesApiApiV1InvoicesGetPostRequest, options?: RawAxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiV1InvoicesGetPost(requestParameters.nexusOpsTenant, requestParameters.invoiceGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiApiV1InvoicesInvoiceIdDownloadPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiV1InvoicesInvoiceIdDownloadPdfGet(requestParameters: InvoicesApiApiV1InvoicesInvoiceIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiV1InvoicesInvoiceIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoicesApiApiV1InvoicesInvoiceIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiV1InvoicesInvoiceIdGet(requestParameters: InvoicesApiApiV1InvoicesInvoiceIdGetRequest, options?: RawAxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiV1InvoicesInvoiceIdGet(requestParameters.nexusOpsTenant, requestParameters.invoiceId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LocationsApi - axios parameter creator
 * @export
 */
export const LocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1LocationsBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/locations/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {LocationGetPaginatedDto} [locationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsGetPost: async (nexusOpsTenant: string, locationGetPaginatedDto?: LocationGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1LocationsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/locations/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdDelete: async (nexusOpsTenant: string, locationId: string, isDeleteCascade?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdDelete', 'locationId', locationId)
            const localVarPath = `/api/v1/locations/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdGet: async (nexusOpsTenant: string, locationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdGet', 'locationId', locationId)
            const localVarPath = `/api/v1/locations/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {UpdateLocationDto} [updateLocationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdPut: async (nexusOpsTenant: string, locationId: string, updateLocationDto?: UpdateLocationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdPut', 'locationId', locationId)
            const localVarPath = `/api/v1/locations/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLocationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdReferenceGet: async (nexusOpsTenant: string, locationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdReferenceGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdReferenceGet', 'locationId', locationId)
            const localVarPath = `/api/v1/locations/{locationId}/reference`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {AddLocationSpotsDto} [addLocationSpotsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdSpotsPost: async (nexusOpsTenant: string, locationId: string, addLocationSpotsDto?: AddLocationSpotsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdSpotsPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdSpotsPost', 'locationId', locationId)
            const localVarPath = `/api/v1/locations/{locationId}/spots`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLocationSpotsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {string} spotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdSpotsSpotIdDelete: async (nexusOpsTenant: string, locationId: string, spotId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdSpotsSpotIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdSpotsSpotIdDelete', 'locationId', locationId)
            // verify required parameter 'spotId' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdSpotsSpotIdDelete', 'spotId', spotId)
            const localVarPath = `/api/v1/locations/{locationId}/spots/{spotId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"spotId"}}`, encodeURIComponent(String(spotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {UserGetPaginatedDto} [userGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdUsersGetPost: async (nexusOpsTenant: string, locationId: string, userGetPaginatedDto?: UserGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdUsersGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdUsersGetPost', 'locationId', locationId)
            const localVarPath = `/api/v1/locations/{locationId}/users/get`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdUsersUserIdDelete: async (nexusOpsTenant: string, locationId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdUsersUserIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdUsersUserIdDelete', 'locationId', locationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdUsersUserIdDelete', 'userId', userId)
            const localVarPath = `/api/v1/locations/{locationId}/users/{userId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdUsersUserIdPost: async (nexusOpsTenant: string, locationId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdUsersUserIdPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdUsersUserIdPost', 'locationId', locationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1LocationsLocationIdUsersUserIdPost', 'userId', userId)
            const localVarPath = `/api/v1/locations/{locationId}/users/{userId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateLocationDto} [createLocationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsPost: async (nexusOpsTenant: string, createLocationDto?: CreateLocationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1LocationsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLocationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {LocationSearchPaginatedDto} [locationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsSearchPost: async (nexusOpsTenant: string, locationSearchPaginatedDto?: LocationSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1LocationsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/locations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationsApi - functional programming interface
 * @export
 */
export const LocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationsBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationsBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.apiV1LocationsBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {LocationGetPaginatedDto} [locationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationsGetPost(nexusOpsTenant: string, locationGetPaginatedDto?: LocationGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfLocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationsGetPost(nexusOpsTenant, locationGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.apiV1LocationsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationsLocationIdDelete(nexusOpsTenant: string, locationId: string, isDeleteCascade?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationsLocationIdDelete(nexusOpsTenant, locationId, isDeleteCascade, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.apiV1LocationsLocationIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationsLocationIdGet(nexusOpsTenant: string, locationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationsLocationIdGet(nexusOpsTenant, locationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.apiV1LocationsLocationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {UpdateLocationDto} [updateLocationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationsLocationIdPut(nexusOpsTenant: string, locationId: string, updateLocationDto?: UpdateLocationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationsLocationIdPut(nexusOpsTenant, locationId, updateLocationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.apiV1LocationsLocationIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationsLocationIdReferenceGet(nexusOpsTenant: string, locationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationsLocationIdReferenceGet(nexusOpsTenant, locationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.apiV1LocationsLocationIdReferenceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {AddLocationSpotsDto} [addLocationSpotsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationsLocationIdSpotsPost(nexusOpsTenant: string, locationId: string, addLocationSpotsDto?: AddLocationSpotsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationsLocationIdSpotsPost(nexusOpsTenant, locationId, addLocationSpotsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.apiV1LocationsLocationIdSpotsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {string} spotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationsLocationIdSpotsSpotIdDelete(nexusOpsTenant: string, locationId: string, spotId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationsLocationIdSpotsSpotIdDelete(nexusOpsTenant, locationId, spotId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.apiV1LocationsLocationIdSpotsSpotIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {UserGetPaginatedDto} [userGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationsLocationIdUsersGetPost(nexusOpsTenant: string, locationId: string, userGetPaginatedDto?: UserGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationsLocationIdUsersGetPost(nexusOpsTenant, locationId, userGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.apiV1LocationsLocationIdUsersGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationsLocationIdUsersUserIdDelete(nexusOpsTenant: string, locationId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationsLocationIdUsersUserIdDelete(nexusOpsTenant, locationId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.apiV1LocationsLocationIdUsersUserIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationsLocationIdUsersUserIdPost(nexusOpsTenant: string, locationId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationsLocationIdUsersUserIdPost(nexusOpsTenant, locationId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.apiV1LocationsLocationIdUsersUserIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateLocationDto} [createLocationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationsPost(nexusOpsTenant: string, createLocationDto?: CreateLocationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationsPost(nexusOpsTenant, createLocationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.apiV1LocationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {LocationSearchPaginatedDto} [locationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocationsSearchPost(nexusOpsTenant: string, locationSearchPaginatedDto?: LocationSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfLocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocationsSearchPost(nexusOpsTenant, locationSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.apiV1LocationsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LocationsApi - factory interface
 * @export
 */
export const LocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationsApiFp(configuration)
    return {
        /**
         * 
         * @param {LocationsApiApiV1LocationsBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsBulkDeleteDelete(requestParameters: LocationsApiApiV1LocationsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1LocationsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationsApiApiV1LocationsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsGetPost(requestParameters: LocationsApiApiV1LocationsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfLocationDto> {
            return localVarFp.apiV1LocationsGetPost(requestParameters.nexusOpsTenant, requestParameters.locationGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationsApiApiV1LocationsLocationIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdDelete(requestParameters: LocationsApiApiV1LocationsLocationIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1LocationsLocationIdDelete(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.isDeleteCascade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationsApiApiV1LocationsLocationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdGet(requestParameters: LocationsApiApiV1LocationsLocationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<LocationDto> {
            return localVarFp.apiV1LocationsLocationIdGet(requestParameters.nexusOpsTenant, requestParameters.locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationsApiApiV1LocationsLocationIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdPut(requestParameters: LocationsApiApiV1LocationsLocationIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<LocationDto> {
            return localVarFp.apiV1LocationsLocationIdPut(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.updateLocationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationsApiApiV1LocationsLocationIdReferenceGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdReferenceGet(requestParameters: LocationsApiApiV1LocationsLocationIdReferenceGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<LocationReferenceDto> {
            return localVarFp.apiV1LocationsLocationIdReferenceGet(requestParameters.nexusOpsTenant, requestParameters.locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationsApiApiV1LocationsLocationIdSpotsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdSpotsPost(requestParameters: LocationsApiApiV1LocationsLocationIdSpotsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<LocationDto> {
            return localVarFp.apiV1LocationsLocationIdSpotsPost(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.addLocationSpotsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationsApiApiV1LocationsLocationIdSpotsSpotIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdSpotsSpotIdDelete(requestParameters: LocationsApiApiV1LocationsLocationIdSpotsSpotIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<LocationDto> {
            return localVarFp.apiV1LocationsLocationIdSpotsSpotIdDelete(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.spotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationsApiApiV1LocationsLocationIdUsersGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdUsersGetPost(requestParameters: LocationsApiApiV1LocationsLocationIdUsersGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfUserDto> {
            return localVarFp.apiV1LocationsLocationIdUsersGetPost(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.userGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationsApiApiV1LocationsLocationIdUsersUserIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdUsersUserIdDelete(requestParameters: LocationsApiApiV1LocationsLocationIdUsersUserIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<LocationDto> {
            return localVarFp.apiV1LocationsLocationIdUsersUserIdDelete(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationsApiApiV1LocationsLocationIdUsersUserIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsLocationIdUsersUserIdPost(requestParameters: LocationsApiApiV1LocationsLocationIdUsersUserIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<LocationDto> {
            return localVarFp.apiV1LocationsLocationIdUsersUserIdPost(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationsApiApiV1LocationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsPost(requestParameters: LocationsApiApiV1LocationsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<LocationDto> {
            return localVarFp.apiV1LocationsPost(requestParameters.nexusOpsTenant, requestParameters.createLocationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationsApiApiV1LocationsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocationsSearchPost(requestParameters: LocationsApiApiV1LocationsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfLocationDto> {
            return localVarFp.apiV1LocationsSearchPost(requestParameters.nexusOpsTenant, requestParameters.locationSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1LocationsBulkDeleteDelete operation in LocationsApi.
 * @export
 * @interface LocationsApiApiV1LocationsBulkDeleteDeleteRequest
 */
export interface LocationsApiApiV1LocationsBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof LocationsApiApiV1LocationsBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof LocationsApiApiV1LocationsBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1LocationsGetPost operation in LocationsApi.
 * @export
 * @interface LocationsApiApiV1LocationsGetPostRequest
 */
export interface LocationsApiApiV1LocationsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof LocationsApiApiV1LocationsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {LocationGetPaginatedDto}
     * @memberof LocationsApiApiV1LocationsGetPost
     */
    readonly locationGetPaginatedDto?: LocationGetPaginatedDto
}

/**
 * Request parameters for apiV1LocationsLocationIdDelete operation in LocationsApi.
 * @export
 * @interface LocationsApiApiV1LocationsLocationIdDeleteRequest
 */
export interface LocationsApiApiV1LocationsLocationIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdDelete
     */
    readonly locationId: string

    /**
     * 
     * @type {boolean}
     * @memberof LocationsApiApiV1LocationsLocationIdDelete
     */
    readonly isDeleteCascade?: boolean
}

/**
 * Request parameters for apiV1LocationsLocationIdGet operation in LocationsApi.
 * @export
 * @interface LocationsApiApiV1LocationsLocationIdGetRequest
 */
export interface LocationsApiApiV1LocationsLocationIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdGet
     */
    readonly locationId: string
}

/**
 * Request parameters for apiV1LocationsLocationIdPut operation in LocationsApi.
 * @export
 * @interface LocationsApiApiV1LocationsLocationIdPutRequest
 */
export interface LocationsApiApiV1LocationsLocationIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdPut
     */
    readonly locationId: string

    /**
     * 
     * @type {UpdateLocationDto}
     * @memberof LocationsApiApiV1LocationsLocationIdPut
     */
    readonly updateLocationDto?: UpdateLocationDto
}

/**
 * Request parameters for apiV1LocationsLocationIdReferenceGet operation in LocationsApi.
 * @export
 * @interface LocationsApiApiV1LocationsLocationIdReferenceGetRequest
 */
export interface LocationsApiApiV1LocationsLocationIdReferenceGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdReferenceGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdReferenceGet
     */
    readonly locationId: string
}

/**
 * Request parameters for apiV1LocationsLocationIdSpotsPost operation in LocationsApi.
 * @export
 * @interface LocationsApiApiV1LocationsLocationIdSpotsPostRequest
 */
export interface LocationsApiApiV1LocationsLocationIdSpotsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdSpotsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdSpotsPost
     */
    readonly locationId: string

    /**
     * 
     * @type {AddLocationSpotsDto}
     * @memberof LocationsApiApiV1LocationsLocationIdSpotsPost
     */
    readonly addLocationSpotsDto?: AddLocationSpotsDto
}

/**
 * Request parameters for apiV1LocationsLocationIdSpotsSpotIdDelete operation in LocationsApi.
 * @export
 * @interface LocationsApiApiV1LocationsLocationIdSpotsSpotIdDeleteRequest
 */
export interface LocationsApiApiV1LocationsLocationIdSpotsSpotIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdSpotsSpotIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdSpotsSpotIdDelete
     */
    readonly locationId: string

    /**
     * 
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdSpotsSpotIdDelete
     */
    readonly spotId: string
}

/**
 * Request parameters for apiV1LocationsLocationIdUsersGetPost operation in LocationsApi.
 * @export
 * @interface LocationsApiApiV1LocationsLocationIdUsersGetPostRequest
 */
export interface LocationsApiApiV1LocationsLocationIdUsersGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdUsersGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdUsersGetPost
     */
    readonly locationId: string

    /**
     * 
     * @type {UserGetPaginatedDto}
     * @memberof LocationsApiApiV1LocationsLocationIdUsersGetPost
     */
    readonly userGetPaginatedDto?: UserGetPaginatedDto
}

/**
 * Request parameters for apiV1LocationsLocationIdUsersUserIdDelete operation in LocationsApi.
 * @export
 * @interface LocationsApiApiV1LocationsLocationIdUsersUserIdDeleteRequest
 */
export interface LocationsApiApiV1LocationsLocationIdUsersUserIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdUsersUserIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdUsersUserIdDelete
     */
    readonly locationId: string

    /**
     * 
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdUsersUserIdDelete
     */
    readonly userId: string
}

/**
 * Request parameters for apiV1LocationsLocationIdUsersUserIdPost operation in LocationsApi.
 * @export
 * @interface LocationsApiApiV1LocationsLocationIdUsersUserIdPostRequest
 */
export interface LocationsApiApiV1LocationsLocationIdUsersUserIdPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdUsersUserIdPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdUsersUserIdPost
     */
    readonly locationId: string

    /**
     * 
     * @type {string}
     * @memberof LocationsApiApiV1LocationsLocationIdUsersUserIdPost
     */
    readonly userId: string
}

/**
 * Request parameters for apiV1LocationsPost operation in LocationsApi.
 * @export
 * @interface LocationsApiApiV1LocationsPostRequest
 */
export interface LocationsApiApiV1LocationsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof LocationsApiApiV1LocationsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateLocationDto}
     * @memberof LocationsApiApiV1LocationsPost
     */
    readonly createLocationDto?: CreateLocationDto
}

/**
 * Request parameters for apiV1LocationsSearchPost operation in LocationsApi.
 * @export
 * @interface LocationsApiApiV1LocationsSearchPostRequest
 */
export interface LocationsApiApiV1LocationsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof LocationsApiApiV1LocationsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {LocationSearchPaginatedDto}
     * @memberof LocationsApiApiV1LocationsSearchPost
     */
    readonly locationSearchPaginatedDto?: LocationSearchPaginatedDto
}

/**
 * LocationsApi - object-oriented interface
 * @export
 * @class LocationsApi
 * @extends {BaseAPI}
 */
export class LocationsApi extends BaseAPI {
    /**
     * 
     * @param {LocationsApiApiV1LocationsBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiV1LocationsBulkDeleteDelete(requestParameters: LocationsApiApiV1LocationsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiV1LocationsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiApiV1LocationsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiV1LocationsGetPost(requestParameters: LocationsApiApiV1LocationsGetPostRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiV1LocationsGetPost(requestParameters.nexusOpsTenant, requestParameters.locationGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiApiV1LocationsLocationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiV1LocationsLocationIdDelete(requestParameters: LocationsApiApiV1LocationsLocationIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiV1LocationsLocationIdDelete(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.isDeleteCascade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiApiV1LocationsLocationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiV1LocationsLocationIdGet(requestParameters: LocationsApiApiV1LocationsLocationIdGetRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiV1LocationsLocationIdGet(requestParameters.nexusOpsTenant, requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiApiV1LocationsLocationIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiV1LocationsLocationIdPut(requestParameters: LocationsApiApiV1LocationsLocationIdPutRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiV1LocationsLocationIdPut(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.updateLocationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiApiV1LocationsLocationIdReferenceGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiV1LocationsLocationIdReferenceGet(requestParameters: LocationsApiApiV1LocationsLocationIdReferenceGetRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiV1LocationsLocationIdReferenceGet(requestParameters.nexusOpsTenant, requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiApiV1LocationsLocationIdSpotsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiV1LocationsLocationIdSpotsPost(requestParameters: LocationsApiApiV1LocationsLocationIdSpotsPostRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiV1LocationsLocationIdSpotsPost(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.addLocationSpotsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiApiV1LocationsLocationIdSpotsSpotIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiV1LocationsLocationIdSpotsSpotIdDelete(requestParameters: LocationsApiApiV1LocationsLocationIdSpotsSpotIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiV1LocationsLocationIdSpotsSpotIdDelete(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.spotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiApiV1LocationsLocationIdUsersGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiV1LocationsLocationIdUsersGetPost(requestParameters: LocationsApiApiV1LocationsLocationIdUsersGetPostRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiV1LocationsLocationIdUsersGetPost(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.userGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiApiV1LocationsLocationIdUsersUserIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiV1LocationsLocationIdUsersUserIdDelete(requestParameters: LocationsApiApiV1LocationsLocationIdUsersUserIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiV1LocationsLocationIdUsersUserIdDelete(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiApiV1LocationsLocationIdUsersUserIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiV1LocationsLocationIdUsersUserIdPost(requestParameters: LocationsApiApiV1LocationsLocationIdUsersUserIdPostRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiV1LocationsLocationIdUsersUserIdPost(requestParameters.nexusOpsTenant, requestParameters.locationId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiApiV1LocationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiV1LocationsPost(requestParameters: LocationsApiApiV1LocationsPostRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiV1LocationsPost(requestParameters.nexusOpsTenant, requestParameters.createLocationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationsApiApiV1LocationsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiV1LocationsSearchPost(requestParameters: LocationsApiApiV1LocationsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiV1LocationsSearchPost(requestParameters.nexusOpsTenant, requestParameters.locationSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileAccessoryChecksApi - axios parameter creator
 * @export
 */
export const MobileAccessoryChecksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileAccessoryChecksAccessoryCheckIdDelete: async (nexusOpsTenant: string, accessoryCheckId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileAccessoryChecksAccessoryCheckIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'accessoryCheckId' is not null or undefined
            assertParamExists('apiV1MobileAccessoryChecksAccessoryCheckIdDelete', 'accessoryCheckId', accessoryCheckId)
            const localVarPath = `/api/v1/mobile/accessory-checks/{accessoryCheckId}`
                .replace(`{${"accessoryCheckId"}}`, encodeURIComponent(String(accessoryCheckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {UpdateAccessoryCheckDto} [updateAccessoryCheckDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileAccessoryChecksAccessoryCheckIdPut: async (nexusOpsTenant: string, accessoryCheckId: string, updateAccessoryCheckDto?: UpdateAccessoryCheckDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileAccessoryChecksAccessoryCheckIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'accessoryCheckId' is not null or undefined
            assertParamExists('apiV1MobileAccessoryChecksAccessoryCheckIdPut', 'accessoryCheckId', accessoryCheckId)
            const localVarPath = `/api/v1/mobile/accessory-checks/{accessoryCheckId}`
                .replace(`{${"accessoryCheckId"}}`, encodeURIComponent(String(accessoryCheckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccessoryCheckDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AccessoryCheckGetPaginatedDto} [accessoryCheckGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileAccessoryChecksGetPost: async (nexusOpsTenant: string, accessoryCheckGetPaginatedDto?: AccessoryCheckGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileAccessoryChecksGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/accessory-checks/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessoryCheckGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateAccessoryCheckDto} [createAccessoryCheckDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileAccessoryChecksPost: async (nexusOpsTenant: string, createAccessoryCheckDto?: CreateAccessoryCheckDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileAccessoryChecksPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/accessory-checks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccessoryCheckDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AccessoryCheckSearchPaginatedDto} [accessoryCheckSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileAccessoryChecksSearchPost: async (nexusOpsTenant: string, accessoryCheckSearchPaginatedDto?: AccessoryCheckSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileAccessoryChecksSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/accessory-checks/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessoryCheckSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileAccessoryChecksApi - functional programming interface
 * @export
 */
export const MobileAccessoryChecksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileAccessoryChecksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileAccessoryChecksAccessoryCheckIdDelete(nexusOpsTenant: string, accessoryCheckId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileAccessoryChecksAccessoryCheckIdDelete(nexusOpsTenant, accessoryCheckId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileAccessoryChecksApi.apiV1MobileAccessoryChecksAccessoryCheckIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} accessoryCheckId 
         * @param {UpdateAccessoryCheckDto} [updateAccessoryCheckDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileAccessoryChecksAccessoryCheckIdPut(nexusOpsTenant: string, accessoryCheckId: string, updateAccessoryCheckDto?: UpdateAccessoryCheckDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessoryCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileAccessoryChecksAccessoryCheckIdPut(nexusOpsTenant, accessoryCheckId, updateAccessoryCheckDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileAccessoryChecksApi.apiV1MobileAccessoryChecksAccessoryCheckIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AccessoryCheckGetPaginatedDto} [accessoryCheckGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileAccessoryChecksGetPost(nexusOpsTenant: string, accessoryCheckGetPaginatedDto?: AccessoryCheckGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAccessoryCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileAccessoryChecksGetPost(nexusOpsTenant, accessoryCheckGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileAccessoryChecksApi.apiV1MobileAccessoryChecksGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateAccessoryCheckDto} [createAccessoryCheckDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileAccessoryChecksPost(nexusOpsTenant: string, createAccessoryCheckDto?: CreateAccessoryCheckDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessoryCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileAccessoryChecksPost(nexusOpsTenant, createAccessoryCheckDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileAccessoryChecksApi.apiV1MobileAccessoryChecksPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {AccessoryCheckSearchPaginatedDto} [accessoryCheckSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileAccessoryChecksSearchPost(nexusOpsTenant: string, accessoryCheckSearchPaginatedDto?: AccessoryCheckSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfAccessoryCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileAccessoryChecksSearchPost(nexusOpsTenant, accessoryCheckSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileAccessoryChecksApi.apiV1MobileAccessoryChecksSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileAccessoryChecksApi - factory interface
 * @export
 */
export const MobileAccessoryChecksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileAccessoryChecksApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileAccessoryChecksAccessoryCheckIdDelete(requestParameters: MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1MobileAccessoryChecksAccessoryCheckIdDelete(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileAccessoryChecksAccessoryCheckIdPut(requestParameters: MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessoryCheckDto> {
            return localVarFp.apiV1MobileAccessoryChecksAccessoryCheckIdPut(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, requestParameters.updateAccessoryCheckDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileAccessoryChecksApiApiV1MobileAccessoryChecksGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileAccessoryChecksGetPost(requestParameters: MobileAccessoryChecksApiApiV1MobileAccessoryChecksGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAccessoryCheckDto> {
            return localVarFp.apiV1MobileAccessoryChecksGetPost(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileAccessoryChecksApiApiV1MobileAccessoryChecksPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileAccessoryChecksPost(requestParameters: MobileAccessoryChecksApiApiV1MobileAccessoryChecksPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessoryCheckDto> {
            return localVarFp.apiV1MobileAccessoryChecksPost(requestParameters.nexusOpsTenant, requestParameters.createAccessoryCheckDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileAccessoryChecksApiApiV1MobileAccessoryChecksSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileAccessoryChecksSearchPost(requestParameters: MobileAccessoryChecksApiApiV1MobileAccessoryChecksSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfAccessoryCheckDto> {
            return localVarFp.apiV1MobileAccessoryChecksSearchPost(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileAccessoryChecksAccessoryCheckIdDelete operation in MobileAccessoryChecksApi.
 * @export
 * @interface MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdDeleteRequest
 */
export interface MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdDelete
     */
    readonly accessoryCheckId: string
}

/**
 * Request parameters for apiV1MobileAccessoryChecksAccessoryCheckIdPut operation in MobileAccessoryChecksApi.
 * @export
 * @interface MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdPutRequest
 */
export interface MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdPut
     */
    readonly accessoryCheckId: string

    /**
     * 
     * @type {UpdateAccessoryCheckDto}
     * @memberof MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdPut
     */
    readonly updateAccessoryCheckDto?: UpdateAccessoryCheckDto
}

/**
 * Request parameters for apiV1MobileAccessoryChecksGetPost operation in MobileAccessoryChecksApi.
 * @export
 * @interface MobileAccessoryChecksApiApiV1MobileAccessoryChecksGetPostRequest
 */
export interface MobileAccessoryChecksApiApiV1MobileAccessoryChecksGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileAccessoryChecksApiApiV1MobileAccessoryChecksGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AccessoryCheckGetPaginatedDto}
     * @memberof MobileAccessoryChecksApiApiV1MobileAccessoryChecksGetPost
     */
    readonly accessoryCheckGetPaginatedDto?: AccessoryCheckGetPaginatedDto
}

/**
 * Request parameters for apiV1MobileAccessoryChecksPost operation in MobileAccessoryChecksApi.
 * @export
 * @interface MobileAccessoryChecksApiApiV1MobileAccessoryChecksPostRequest
 */
export interface MobileAccessoryChecksApiApiV1MobileAccessoryChecksPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileAccessoryChecksApiApiV1MobileAccessoryChecksPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateAccessoryCheckDto}
     * @memberof MobileAccessoryChecksApiApiV1MobileAccessoryChecksPost
     */
    readonly createAccessoryCheckDto?: CreateAccessoryCheckDto
}

/**
 * Request parameters for apiV1MobileAccessoryChecksSearchPost operation in MobileAccessoryChecksApi.
 * @export
 * @interface MobileAccessoryChecksApiApiV1MobileAccessoryChecksSearchPostRequest
 */
export interface MobileAccessoryChecksApiApiV1MobileAccessoryChecksSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileAccessoryChecksApiApiV1MobileAccessoryChecksSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {AccessoryCheckSearchPaginatedDto}
     * @memberof MobileAccessoryChecksApiApiV1MobileAccessoryChecksSearchPost
     */
    readonly accessoryCheckSearchPaginatedDto?: AccessoryCheckSearchPaginatedDto
}

/**
 * MobileAccessoryChecksApi - object-oriented interface
 * @export
 * @class MobileAccessoryChecksApi
 * @extends {BaseAPI}
 */
export class MobileAccessoryChecksApi extends BaseAPI {
    /**
     * 
     * @param {MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAccessoryChecksApi
     */
    public apiV1MobileAccessoryChecksAccessoryCheckIdDelete(requestParameters: MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return MobileAccessoryChecksApiFp(this.configuration).apiV1MobileAccessoryChecksAccessoryCheckIdDelete(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAccessoryChecksApi
     */
    public apiV1MobileAccessoryChecksAccessoryCheckIdPut(requestParameters: MobileAccessoryChecksApiApiV1MobileAccessoryChecksAccessoryCheckIdPutRequest, options?: RawAxiosRequestConfig) {
        return MobileAccessoryChecksApiFp(this.configuration).apiV1MobileAccessoryChecksAccessoryCheckIdPut(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckId, requestParameters.updateAccessoryCheckDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileAccessoryChecksApiApiV1MobileAccessoryChecksGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAccessoryChecksApi
     */
    public apiV1MobileAccessoryChecksGetPost(requestParameters: MobileAccessoryChecksApiApiV1MobileAccessoryChecksGetPostRequest, options?: RawAxiosRequestConfig) {
        return MobileAccessoryChecksApiFp(this.configuration).apiV1MobileAccessoryChecksGetPost(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileAccessoryChecksApiApiV1MobileAccessoryChecksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAccessoryChecksApi
     */
    public apiV1MobileAccessoryChecksPost(requestParameters: MobileAccessoryChecksApiApiV1MobileAccessoryChecksPostRequest, options?: RawAxiosRequestConfig) {
        return MobileAccessoryChecksApiFp(this.configuration).apiV1MobileAccessoryChecksPost(requestParameters.nexusOpsTenant, requestParameters.createAccessoryCheckDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileAccessoryChecksApiApiV1MobileAccessoryChecksSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAccessoryChecksApi
     */
    public apiV1MobileAccessoryChecksSearchPost(requestParameters: MobileAccessoryChecksApiApiV1MobileAccessoryChecksSearchPostRequest, options?: RawAxiosRequestConfig) {
        return MobileAccessoryChecksApiFp(this.configuration).apiV1MobileAccessoryChecksSearchPost(requestParameters.nexusOpsTenant, requestParameters.accessoryCheckSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileContractsApi - axios parameter creator
 * @export
 */
export const MobileContractsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsContractIdGet: async (nexusOpsTenant: string, contractId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileContractsContractIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1MobileContractsContractIdGet', 'contractId', contractId)
            const localVarPath = `/api/v1/mobile/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractVehicleCheckInDto} [contractVehicleCheckInDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsContractIdVehicleCheckInPost: async (nexusOpsTenant: string, contractId: string, contractVehicleCheckInDto?: ContractVehicleCheckInDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileContractsContractIdVehicleCheckInPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1MobileContractsContractIdVehicleCheckInPost', 'contractId', contractId)
            const localVarPath = `/api/v1/mobile/contracts/{contractId}/vehicle/check-in`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractVehicleCheckInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractSpotInfoVisualInspectionInputDto} [contractSpotInfoVisualInspectionInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut: async (nexusOpsTenant: string, contractId: string, contractSpotInfoVisualInspectionInputDto?: ContractSpotInfoVisualInspectionInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut', 'contractId', contractId)
            const localVarPath = `/api/v1/mobile/contracts/{contractId}/vehicle/check-in/spot-info/visual-inspection`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractSpotInfoVisualInspectionInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractVehicleCheckOutDto} [contractVehicleCheckOutDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsContractIdVehicleCheckOutPost: async (nexusOpsTenant: string, contractId: string, contractVehicleCheckOutDto?: ContractVehicleCheckOutDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileContractsContractIdVehicleCheckOutPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1MobileContractsContractIdVehicleCheckOutPost', 'contractId', contractId)
            const localVarPath = `/api/v1/mobile/contracts/{contractId}/vehicle/check-out`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractVehicleCheckOutDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractSpotInfoVisualInspectionInputDto} [contractSpotInfoVisualInspectionInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut: async (nexusOpsTenant: string, contractId: string, contractSpotInfoVisualInspectionInputDto?: ContractSpotInfoVisualInspectionInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('apiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut', 'contractId', contractId)
            const localVarPath = `/api/v1/mobile/contracts/{contractId}/vehicle/check-out/spot-info/visual-inspection`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractSpotInfoVisualInspectionInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractGetCountersDto} [contractGetCountersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsCountersGetPost: async (nexusOpsTenant: string, contractGetCountersDto?: ContractGetCountersDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileContractsCountersGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/contracts/counters/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractGetCountersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractGetPaginatedDto} [contractGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsGetPost: async (nexusOpsTenant: string, contractGetPaginatedDto?: ContractGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileContractsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/contracts/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractSearchPaginatedDto} [contractSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsSearchPost: async (nexusOpsTenant: string, contractSearchPaginatedDto?: ContractSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileContractsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/contracts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileContractsApi - functional programming interface
 * @export
 */
export const MobileContractsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileContractsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileContractsContractIdGet(nexusOpsTenant: string, contractId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileContractsContractIdGet(nexusOpsTenant, contractId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileContractsApi.apiV1MobileContractsContractIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractVehicleCheckInDto} [contractVehicleCheckInDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileContractsContractIdVehicleCheckInPost(nexusOpsTenant: string, contractId: string, contractVehicleCheckInDto?: ContractVehicleCheckInDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileContractsContractIdVehicleCheckInPost(nexusOpsTenant, contractId, contractVehicleCheckInDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileContractsApi.apiV1MobileContractsContractIdVehicleCheckInPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractSpotInfoVisualInspectionInputDto} [contractSpotInfoVisualInspectionInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut(nexusOpsTenant: string, contractId: string, contractSpotInfoVisualInspectionInputDto?: ContractSpotInfoVisualInspectionInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut(nexusOpsTenant, contractId, contractSpotInfoVisualInspectionInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileContractsApi.apiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractVehicleCheckOutDto} [contractVehicleCheckOutDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileContractsContractIdVehicleCheckOutPost(nexusOpsTenant: string, contractId: string, contractVehicleCheckOutDto?: ContractVehicleCheckOutDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileContractsContractIdVehicleCheckOutPost(nexusOpsTenant, contractId, contractVehicleCheckOutDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileContractsApi.apiV1MobileContractsContractIdVehicleCheckOutPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} contractId 
         * @param {ContractSpotInfoVisualInspectionInputDto} [contractSpotInfoVisualInspectionInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut(nexusOpsTenant: string, contractId: string, contractSpotInfoVisualInspectionInputDto?: ContractSpotInfoVisualInspectionInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut(nexusOpsTenant, contractId, contractSpotInfoVisualInspectionInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileContractsApi.apiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractGetCountersDto} [contractGetCountersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileContractsCountersGetPost(nexusOpsTenant: string, contractGetCountersDto?: ContractGetCountersDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileContractsCountersGetPost(nexusOpsTenant, contractGetCountersDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileContractsApi.apiV1MobileContractsCountersGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractGetPaginatedDto} [contractGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileContractsGetPost(nexusOpsTenant: string, contractGetPaginatedDto?: ContractGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileContractsGetPost(nexusOpsTenant, contractGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileContractsApi.apiV1MobileContractsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ContractSearchPaginatedDto} [contractSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileContractsSearchPost(nexusOpsTenant: string, contractSearchPaginatedDto?: ContractSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileContractsSearchPost(nexusOpsTenant, contractSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileContractsApi.apiV1MobileContractsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileContractsApi - factory interface
 * @export
 */
export const MobileContractsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileContractsApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileContractsApiApiV1MobileContractsContractIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsContractIdGet(requestParameters: MobileContractsApiApiV1MobileContractsContractIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1MobileContractsContractIdGet(requestParameters.nexusOpsTenant, requestParameters.contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsContractIdVehicleCheckInPost(requestParameters: MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1MobileContractsContractIdVehicleCheckInPost(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractVehicleCheckInDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut(requestParameters: MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractSpotInfoVisualInspectionInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsContractIdVehicleCheckOutPost(requestParameters: MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1MobileContractsContractIdVehicleCheckOutPost(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractVehicleCheckOutDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut(requestParameters: MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractDto> {
            return localVarFp.apiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractSpotInfoVisualInspectionInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileContractsApiApiV1MobileContractsCountersGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsCountersGetPost(requestParameters: MobileContractsApiApiV1MobileContractsCountersGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ContractCountersDto> {
            return localVarFp.apiV1MobileContractsCountersGetPost(requestParameters.nexusOpsTenant, requestParameters.contractGetCountersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileContractsApiApiV1MobileContractsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsGetPost(requestParameters: MobileContractsApiApiV1MobileContractsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfContractDto> {
            return localVarFp.apiV1MobileContractsGetPost(requestParameters.nexusOpsTenant, requestParameters.contractGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileContractsApiApiV1MobileContractsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileContractsSearchPost(requestParameters: MobileContractsApiApiV1MobileContractsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfContractDto> {
            return localVarFp.apiV1MobileContractsSearchPost(requestParameters.nexusOpsTenant, requestParameters.contractSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileContractsContractIdGet operation in MobileContractsApi.
 * @export
 * @interface MobileContractsApiApiV1MobileContractsContractIdGetRequest
 */
export interface MobileContractsApiApiV1MobileContractsContractIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdGet
     */
    readonly contractId: string
}

/**
 * Request parameters for apiV1MobileContractsContractIdVehicleCheckInPost operation in MobileContractsApi.
 * @export
 * @interface MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInPostRequest
 */
export interface MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInPost
     */
    readonly contractId: string

    /**
     * 
     * @type {ContractVehicleCheckInDto}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInPost
     */
    readonly contractVehicleCheckInDto?: ContractVehicleCheckInDto
}

/**
 * Request parameters for apiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut operation in MobileContractsApi.
 * @export
 * @interface MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPutRequest
 */
export interface MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut
     */
    readonly contractId: string

    /**
     * 
     * @type {ContractSpotInfoVisualInspectionInputDto}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut
     */
    readonly contractSpotInfoVisualInspectionInputDto?: ContractSpotInfoVisualInspectionInputDto
}

/**
 * Request parameters for apiV1MobileContractsContractIdVehicleCheckOutPost operation in MobileContractsApi.
 * @export
 * @interface MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutPostRequest
 */
export interface MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutPost
     */
    readonly contractId: string

    /**
     * 
     * @type {ContractVehicleCheckOutDto}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutPost
     */
    readonly contractVehicleCheckOutDto?: ContractVehicleCheckOutDto
}

/**
 * Request parameters for apiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut operation in MobileContractsApi.
 * @export
 * @interface MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPutRequest
 */
export interface MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut
     */
    readonly contractId: string

    /**
     * 
     * @type {ContractSpotInfoVisualInspectionInputDto}
     * @memberof MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut
     */
    readonly contractSpotInfoVisualInspectionInputDto?: ContractSpotInfoVisualInspectionInputDto
}

/**
 * Request parameters for apiV1MobileContractsCountersGetPost operation in MobileContractsApi.
 * @export
 * @interface MobileContractsApiApiV1MobileContractsCountersGetPostRequest
 */
export interface MobileContractsApiApiV1MobileContractsCountersGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileContractsApiApiV1MobileContractsCountersGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ContractGetCountersDto}
     * @memberof MobileContractsApiApiV1MobileContractsCountersGetPost
     */
    readonly contractGetCountersDto?: ContractGetCountersDto
}

/**
 * Request parameters for apiV1MobileContractsGetPost operation in MobileContractsApi.
 * @export
 * @interface MobileContractsApiApiV1MobileContractsGetPostRequest
 */
export interface MobileContractsApiApiV1MobileContractsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileContractsApiApiV1MobileContractsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ContractGetPaginatedDto}
     * @memberof MobileContractsApiApiV1MobileContractsGetPost
     */
    readonly contractGetPaginatedDto?: ContractGetPaginatedDto
}

/**
 * Request parameters for apiV1MobileContractsSearchPost operation in MobileContractsApi.
 * @export
 * @interface MobileContractsApiApiV1MobileContractsSearchPostRequest
 */
export interface MobileContractsApiApiV1MobileContractsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileContractsApiApiV1MobileContractsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ContractSearchPaginatedDto}
     * @memberof MobileContractsApiApiV1MobileContractsSearchPost
     */
    readonly contractSearchPaginatedDto?: ContractSearchPaginatedDto
}

/**
 * MobileContractsApi - object-oriented interface
 * @export
 * @class MobileContractsApi
 * @extends {BaseAPI}
 */
export class MobileContractsApi extends BaseAPI {
    /**
     * 
     * @param {MobileContractsApiApiV1MobileContractsContractIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileContractsApi
     */
    public apiV1MobileContractsContractIdGet(requestParameters: MobileContractsApiApiV1MobileContractsContractIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileContractsApiFp(this.configuration).apiV1MobileContractsContractIdGet(requestParameters.nexusOpsTenant, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileContractsApi
     */
    public apiV1MobileContractsContractIdVehicleCheckInPost(requestParameters: MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInPostRequest, options?: RawAxiosRequestConfig) {
        return MobileContractsApiFp(this.configuration).apiV1MobileContractsContractIdVehicleCheckInPost(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractVehicleCheckInDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileContractsApi
     */
    public apiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut(requestParameters: MobileContractsApiApiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPutRequest, options?: RawAxiosRequestConfig) {
        return MobileContractsApiFp(this.configuration).apiV1MobileContractsContractIdVehicleCheckInSpotInfoVisualInspectionPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractSpotInfoVisualInspectionInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileContractsApi
     */
    public apiV1MobileContractsContractIdVehicleCheckOutPost(requestParameters: MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutPostRequest, options?: RawAxiosRequestConfig) {
        return MobileContractsApiFp(this.configuration).apiV1MobileContractsContractIdVehicleCheckOutPost(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractVehicleCheckOutDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileContractsApi
     */
    public apiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut(requestParameters: MobileContractsApiApiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPutRequest, options?: RawAxiosRequestConfig) {
        return MobileContractsApiFp(this.configuration).apiV1MobileContractsContractIdVehicleCheckOutSpotInfoVisualInspectionPut(requestParameters.nexusOpsTenant, requestParameters.contractId, requestParameters.contractSpotInfoVisualInspectionInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileContractsApiApiV1MobileContractsCountersGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileContractsApi
     */
    public apiV1MobileContractsCountersGetPost(requestParameters: MobileContractsApiApiV1MobileContractsCountersGetPostRequest, options?: RawAxiosRequestConfig) {
        return MobileContractsApiFp(this.configuration).apiV1MobileContractsCountersGetPost(requestParameters.nexusOpsTenant, requestParameters.contractGetCountersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileContractsApiApiV1MobileContractsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileContractsApi
     */
    public apiV1MobileContractsGetPost(requestParameters: MobileContractsApiApiV1MobileContractsGetPostRequest, options?: RawAxiosRequestConfig) {
        return MobileContractsApiFp(this.configuration).apiV1MobileContractsGetPost(requestParameters.nexusOpsTenant, requestParameters.contractGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileContractsApiApiV1MobileContractsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileContractsApi
     */
    public apiV1MobileContractsSearchPost(requestParameters: MobileContractsApiApiV1MobileContractsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return MobileContractsApiFp(this.configuration).apiV1MobileContractsSearchPost(requestParameters.nexusOpsTenant, requestParameters.contractSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileDamageDetectionsApi - axios parameter creator
 * @export
 */
export const MobileDamageDetectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {AddDamageDetectionToContractDto} [addDamageDetectionToContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost: async (nexusOpsTenant: string, damageDetectionId: string, addDamageDetectionToContractDto?: AddDamageDetectionToContractDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageDetectionId' is not null or undefined
            assertParamExists('apiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost', 'damageDetectionId', damageDetectionId)
            const localVarPath = `/api/v1/mobile/damage-detections/{damageDetectionId}/add-to-contract`
                .replace(`{${"damageDetectionId"}}`, encodeURIComponent(String(damageDetectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDamageDetectionToContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGet: async (nexusOpsTenant: string, damageDetectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageDetectionId' is not null or undefined
            assertParamExists('apiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGet', 'damageDetectionId', damageDetectionId)
            const localVarPath = `/api/v1/mobile/damage-detections/{damageDetectionId}/download/pdf`
                .replace(`{${"damageDetectionId"}}`, encodeURIComponent(String(damageDetectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsDamageDetectionIdGet: async (nexusOpsTenant: string, damageDetectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileDamageDetectionsDamageDetectionIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'damageDetectionId' is not null or undefined
            assertParamExists('apiV1MobileDamageDetectionsDamageDetectionIdGet', 'damageDetectionId', damageDetectionId)
            const localVarPath = `/api/v1/mobile/damage-detections/{damageDetectionId}`
                .replace(`{${"damageDetectionId"}}`, encodeURIComponent(String(damageDetectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionGetPaginatedDto} [damageDetectionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsGetPost: async (nexusOpsTenant: string, damageDetectionGetPaginatedDto?: DamageDetectionGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileDamageDetectionsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/damage-detections/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageDetectionGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsMyGet: async (nexusOpsTenant: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileDamageDetectionsMyGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/damage-detections/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateDamageDetectionDto} [createDamageDetectionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsPost: async (nexusOpsTenant: string, createDamageDetectionDto?: CreateDamageDetectionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileDamageDetectionsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/damage-detections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDamageDetectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionSearchPaginatedDto} [damageDetectionSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsSearchPost: async (nexusOpsTenant: string, damageDetectionSearchPaginatedDto?: DamageDetectionSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileDamageDetectionsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/damage-detections/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageDetectionSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsVehicleVehicleIdGet: async (nexusOpsTenant: string, vehicleId: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileDamageDetectionsVehicleVehicleIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1MobileDamageDetectionsVehicleVehicleIdGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/mobile/damage-detections/vehicle/{vehicleId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileDamageDetectionsApi - functional programming interface
 * @export
 */
export const MobileDamageDetectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileDamageDetectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {AddDamageDetectionToContractDto} [addDamageDetectionToContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost(nexusOpsTenant: string, damageDetectionId: string, addDamageDetectionToContractDto?: AddDamageDetectionToContractDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost(nexusOpsTenant, damageDetectionId, addDamageDetectionToContractDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileDamageDetectionsApi.apiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGet(nexusOpsTenant: string, damageDetectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGet(nexusOpsTenant, damageDetectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileDamageDetectionsApi.apiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} damageDetectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileDamageDetectionsDamageDetectionIdGet(nexusOpsTenant: string, damageDetectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileDamageDetectionsDamageDetectionIdGet(nexusOpsTenant, damageDetectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileDamageDetectionsApi.apiV1MobileDamageDetectionsDamageDetectionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionGetPaginatedDto} [damageDetectionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileDamageDetectionsGetPost(nexusOpsTenant: string, damageDetectionGetPaginatedDto?: DamageDetectionGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileDamageDetectionsGetPost(nexusOpsTenant, damageDetectionGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileDamageDetectionsApi.apiV1MobileDamageDetectionsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileDamageDetectionsMyGet(nexusOpsTenant: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileDamageDetectionsMyGet(nexusOpsTenant, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileDamageDetectionsApi.apiV1MobileDamageDetectionsMyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateDamageDetectionDto} [createDamageDetectionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileDamageDetectionsPost(nexusOpsTenant: string, createDamageDetectionDto?: CreateDamageDetectionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileDamageDetectionsPost(nexusOpsTenant, createDamageDetectionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileDamageDetectionsApi.apiV1MobileDamageDetectionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {DamageDetectionSearchPaginatedDto} [damageDetectionSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileDamageDetectionsSearchPost(nexusOpsTenant: string, damageDetectionSearchPaginatedDto?: DamageDetectionSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileDamageDetectionsSearchPost(nexusOpsTenant, damageDetectionSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileDamageDetectionsApi.apiV1MobileDamageDetectionsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileDamageDetectionsVehicleVehicleIdGet(nexusOpsTenant: string, vehicleId: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageDetectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileDamageDetectionsVehicleVehicleIdGet(nexusOpsTenant, vehicleId, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileDamageDetectionsApi.apiV1MobileDamageDetectionsVehicleVehicleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileDamageDetectionsApi - factory interface
 * @export
 */
export const MobileDamageDetectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileDamageDetectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdAddToContractPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdAddToContractPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageDetectionDto> {
            return localVarFp.apiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, requestParameters.addDamageDetectionToContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGet(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsDamageDetectionIdGet(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageDetectionDto> {
            return localVarFp.apiV1MobileDamageDetectionsDamageDetectionIdGet(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsGetPost(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageDetectionDto> {
            return localVarFp.apiV1MobileDamageDetectionsGetPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsMyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsMyGet(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsMyGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageDetectionDto> {
            return localVarFp.apiV1MobileDamageDetectionsMyGet(requestParameters.nexusOpsTenant, requestParameters.offset, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsPost(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageDetectionDto> {
            return localVarFp.apiV1MobileDamageDetectionsPost(requestParameters.nexusOpsTenant, requestParameters.createDamageDetectionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsSearchPost(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageDetectionDto> {
            return localVarFp.apiV1MobileDamageDetectionsSearchPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsVehicleVehicleIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDamageDetectionsVehicleVehicleIdGet(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsVehicleVehicleIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageDetectionDto> {
            return localVarFp.apiV1MobileDamageDetectionsVehicleVehicleIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.offset, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost operation in MobileDamageDetectionsApi.
 * @export
 * @interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdAddToContractPostRequest
 */
export interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdAddToContractPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost
     */
    readonly damageDetectionId: string

    /**
     * 
     * @type {AddDamageDetectionToContractDto}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost
     */
    readonly addDamageDetectionToContractDto?: AddDamageDetectionToContractDto
}

/**
 * Request parameters for apiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGet operation in MobileDamageDetectionsApi.
 * @export
 * @interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGetRequest
 */
export interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGet
     */
    readonly damageDetectionId: string
}

/**
 * Request parameters for apiV1MobileDamageDetectionsDamageDetectionIdGet operation in MobileDamageDetectionsApi.
 * @export
 * @interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdGetRequest
 */
export interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdGet
     */
    readonly damageDetectionId: string
}

/**
 * Request parameters for apiV1MobileDamageDetectionsGetPost operation in MobileDamageDetectionsApi.
 * @export
 * @interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsGetPostRequest
 */
export interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DamageDetectionGetPaginatedDto}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsGetPost
     */
    readonly damageDetectionGetPaginatedDto?: DamageDetectionGetPaginatedDto
}

/**
 * Request parameters for apiV1MobileDamageDetectionsMyGet operation in MobileDamageDetectionsApi.
 * @export
 * @interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsMyGetRequest
 */
export interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsMyGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsMyGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {number}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsMyGet
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsMyGet
     */
    readonly limit?: number
}

/**
 * Request parameters for apiV1MobileDamageDetectionsPost operation in MobileDamageDetectionsApi.
 * @export
 * @interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsPostRequest
 */
export interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateDamageDetectionDto}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsPost
     */
    readonly createDamageDetectionDto?: CreateDamageDetectionDto
}

/**
 * Request parameters for apiV1MobileDamageDetectionsSearchPost operation in MobileDamageDetectionsApi.
 * @export
 * @interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsSearchPostRequest
 */
export interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {DamageDetectionSearchPaginatedDto}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsSearchPost
     */
    readonly damageDetectionSearchPaginatedDto?: DamageDetectionSearchPaginatedDto
}

/**
 * Request parameters for apiV1MobileDamageDetectionsVehicleVehicleIdGet operation in MobileDamageDetectionsApi.
 * @export
 * @interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsVehicleVehicleIdGetRequest
 */
export interface MobileDamageDetectionsApiApiV1MobileDamageDetectionsVehicleVehicleIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsVehicleVehicleIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsVehicleVehicleIdGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {number}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsVehicleVehicleIdGet
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof MobileDamageDetectionsApiApiV1MobileDamageDetectionsVehicleVehicleIdGet
     */
    readonly limit?: number
}

/**
 * MobileDamageDetectionsApi - object-oriented interface
 * @export
 * @class MobileDamageDetectionsApi
 * @extends {BaseAPI}
 */
export class MobileDamageDetectionsApi extends BaseAPI {
    /**
     * 
     * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdAddToContractPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileDamageDetectionsApi
     */
    public apiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdAddToContractPostRequest, options?: RawAxiosRequestConfig) {
        return MobileDamageDetectionsApiFp(this.configuration).apiV1MobileDamageDetectionsDamageDetectionIdAddToContractPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, requestParameters.addDamageDetectionToContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileDamageDetectionsApi
     */
    public apiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGet(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig) {
        return MobileDamageDetectionsApiFp(this.configuration).apiV1MobileDamageDetectionsDamageDetectionIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileDamageDetectionsApi
     */
    public apiV1MobileDamageDetectionsDamageDetectionIdGet(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsDamageDetectionIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileDamageDetectionsApiFp(this.configuration).apiV1MobileDamageDetectionsDamageDetectionIdGet(requestParameters.nexusOpsTenant, requestParameters.damageDetectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileDamageDetectionsApi
     */
    public apiV1MobileDamageDetectionsGetPost(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsGetPostRequest, options?: RawAxiosRequestConfig) {
        return MobileDamageDetectionsApiFp(this.configuration).apiV1MobileDamageDetectionsGetPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsMyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileDamageDetectionsApi
     */
    public apiV1MobileDamageDetectionsMyGet(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsMyGetRequest, options?: RawAxiosRequestConfig) {
        return MobileDamageDetectionsApiFp(this.configuration).apiV1MobileDamageDetectionsMyGet(requestParameters.nexusOpsTenant, requestParameters.offset, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileDamageDetectionsApi
     */
    public apiV1MobileDamageDetectionsPost(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsPostRequest, options?: RawAxiosRequestConfig) {
        return MobileDamageDetectionsApiFp(this.configuration).apiV1MobileDamageDetectionsPost(requestParameters.nexusOpsTenant, requestParameters.createDamageDetectionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileDamageDetectionsApi
     */
    public apiV1MobileDamageDetectionsSearchPost(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return MobileDamageDetectionsApiFp(this.configuration).apiV1MobileDamageDetectionsSearchPost(requestParameters.nexusOpsTenant, requestParameters.damageDetectionSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileDamageDetectionsApiApiV1MobileDamageDetectionsVehicleVehicleIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileDamageDetectionsApi
     */
    public apiV1MobileDamageDetectionsVehicleVehicleIdGet(requestParameters: MobileDamageDetectionsApiApiV1MobileDamageDetectionsVehicleVehicleIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileDamageDetectionsApiFp(this.configuration).apiV1MobileDamageDetectionsVehicleVehicleIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.offset, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileDamageTypesApi - axios parameter creator
 * @export
 */
export const MobileDamageTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} damageTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataDamageTypesDamageTypeIdGet: async (damageTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'damageTypeId' is not null or undefined
            assertParamExists('apiV1MobileReferenceDataDamageTypesDamageTypeIdGet', 'damageTypeId', damageTypeId)
            const localVarPath = `/api/v1/mobile/reference-data/damage-types/{damageTypeId}`
                .replace(`{${"damageTypeId"}}`, encodeURIComponent(String(damageTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DamageTypeGetPaginatedDto} [damageTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataDamageTypesGetPost: async (damageTypeGetPaginatedDto?: DamageTypeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/reference-data/damage-types/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageTypeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DamageTypeSearchPaginatedDto} [damageTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataDamageTypesSearchPost: async (damageTypeSearchPaginatedDto?: DamageTypeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/reference-data/damage-types/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damageTypeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileDamageTypesApi - functional programming interface
 * @export
 */
export const MobileDamageTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileDamageTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} damageTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataDamageTypesDamageTypeIdGet(damageTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamageTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataDamageTypesDamageTypeIdGet(damageTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileDamageTypesApi.apiV1MobileReferenceDataDamageTypesDamageTypeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DamageTypeGetPaginatedDto} [damageTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataDamageTypesGetPost(damageTypeGetPaginatedDto?: DamageTypeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataDamageTypesGetPost(damageTypeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileDamageTypesApi.apiV1MobileReferenceDataDamageTypesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DamageTypeSearchPaginatedDto} [damageTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataDamageTypesSearchPost(damageTypeSearchPaginatedDto?: DamageTypeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfDamageTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataDamageTypesSearchPost(damageTypeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileDamageTypesApi.apiV1MobileReferenceDataDamageTypesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileDamageTypesApi - factory interface
 * @export
 */
export const MobileDamageTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileDamageTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesDamageTypeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataDamageTypesDamageTypeIdGet(requestParameters: MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesDamageTypeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DamageTypeDto> {
            return localVarFp.apiV1MobileReferenceDataDamageTypesDamageTypeIdGet(requestParameters.damageTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataDamageTypesGetPost(requestParameters: MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageTypeDto> {
            return localVarFp.apiV1MobileReferenceDataDamageTypesGetPost(requestParameters.damageTypeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataDamageTypesSearchPost(requestParameters: MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfDamageTypeDto> {
            return localVarFp.apiV1MobileReferenceDataDamageTypesSearchPost(requestParameters.damageTypeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileReferenceDataDamageTypesDamageTypeIdGet operation in MobileDamageTypesApi.
 * @export
 * @interface MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesDamageTypeIdGetRequest
 */
export interface MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesDamageTypeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesDamageTypeIdGet
     */
    readonly damageTypeId: string
}

/**
 * Request parameters for apiV1MobileReferenceDataDamageTypesGetPost operation in MobileDamageTypesApi.
 * @export
 * @interface MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesGetPostRequest
 */
export interface MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesGetPostRequest {
    /**
     * 
     * @type {DamageTypeGetPaginatedDto}
     * @memberof MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesGetPost
     */
    readonly damageTypeGetPaginatedDto?: DamageTypeGetPaginatedDto
}

/**
 * Request parameters for apiV1MobileReferenceDataDamageTypesSearchPost operation in MobileDamageTypesApi.
 * @export
 * @interface MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesSearchPostRequest
 */
export interface MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesSearchPostRequest {
    /**
     * 
     * @type {DamageTypeSearchPaginatedDto}
     * @memberof MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesSearchPost
     */
    readonly damageTypeSearchPaginatedDto?: DamageTypeSearchPaginatedDto
}

/**
 * MobileDamageTypesApi - object-oriented interface
 * @export
 * @class MobileDamageTypesApi
 * @extends {BaseAPI}
 */
export class MobileDamageTypesApi extends BaseAPI {
    /**
     * 
     * @param {MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesDamageTypeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileDamageTypesApi
     */
    public apiV1MobileReferenceDataDamageTypesDamageTypeIdGet(requestParameters: MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesDamageTypeIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileDamageTypesApiFp(this.configuration).apiV1MobileReferenceDataDamageTypesDamageTypeIdGet(requestParameters.damageTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileDamageTypesApi
     */
    public apiV1MobileReferenceDataDamageTypesGetPost(requestParameters: MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return MobileDamageTypesApiFp(this.configuration).apiV1MobileReferenceDataDamageTypesGetPost(requestParameters.damageTypeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileDamageTypesApi
     */
    public apiV1MobileReferenceDataDamageTypesSearchPost(requestParameters: MobileDamageTypesApiApiV1MobileReferenceDataDamageTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return MobileDamageTypesApiFp(this.configuration).apiV1MobileReferenceDataDamageTypesSearchPost(requestParameters.damageTypeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileDeviceTokensApi - axios parameter creator
 * @export
 */
export const MobileDeviceTokensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RegisterDeviceTokenDto} [registerDeviceTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDeviceTokensPost: async (nexusOpsTenant: string, registerDeviceTokenDto?: RegisterDeviceTokenDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileDeviceTokensPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/device-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerDeviceTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileDeviceTokensApi - functional programming interface
 * @export
 */
export const MobileDeviceTokensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileDeviceTokensApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RegisterDeviceTokenDto} [registerDeviceTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileDeviceTokensPost(nexusOpsTenant: string, registerDeviceTokenDto?: RegisterDeviceTokenDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileDeviceTokensPost(nexusOpsTenant, registerDeviceTokenDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileDeviceTokensApi.apiV1MobileDeviceTokensPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileDeviceTokensApi - factory interface
 * @export
 */
export const MobileDeviceTokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileDeviceTokensApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileDeviceTokensApiApiV1MobileDeviceTokensPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileDeviceTokensPost(requestParameters: MobileDeviceTokensApiApiV1MobileDeviceTokensPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1MobileDeviceTokensPost(requestParameters.nexusOpsTenant, requestParameters.registerDeviceTokenDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileDeviceTokensPost operation in MobileDeviceTokensApi.
 * @export
 * @interface MobileDeviceTokensApiApiV1MobileDeviceTokensPostRequest
 */
export interface MobileDeviceTokensApiApiV1MobileDeviceTokensPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileDeviceTokensApiApiV1MobileDeviceTokensPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RegisterDeviceTokenDto}
     * @memberof MobileDeviceTokensApiApiV1MobileDeviceTokensPost
     */
    readonly registerDeviceTokenDto?: RegisterDeviceTokenDto
}

/**
 * MobileDeviceTokensApi - object-oriented interface
 * @export
 * @class MobileDeviceTokensApi
 * @extends {BaseAPI}
 */
export class MobileDeviceTokensApi extends BaseAPI {
    /**
     * 
     * @param {MobileDeviceTokensApiApiV1MobileDeviceTokensPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileDeviceTokensApi
     */
    public apiV1MobileDeviceTokensPost(requestParameters: MobileDeviceTokensApiApiV1MobileDeviceTokensPostRequest, options?: RawAxiosRequestConfig) {
        return MobileDeviceTokensApiFp(this.configuration).apiV1MobileDeviceTokensPost(requestParameters.nexusOpsTenant, requestParameters.registerDeviceTokenDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileFileApi - axios parameter creator
 * @export
 */
export const MobileFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<File>} [files] 
         * @param {string} [responseOptionsFileUrlLifetime] Check out how C# TimeSpan is serialized to string for more info.
         * @param {string} [responseOptionsFileUrlExpiresAt] Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileFilesUploadPost: async (nexusOpsTenant: string, files?: Array<File>, responseOptionsFileUrlLifetime?: string, responseOptionsFileUrlExpiresAt?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileFilesUploadPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (responseOptionsFileUrlLifetime !== undefined) { 
                localVarFormParams.append('responseOptions.fileUrlLifetime', responseOptionsFileUrlLifetime as any);
            }
    
            if (responseOptionsFileUrlExpiresAt !== undefined) { 
                localVarFormParams.append('responseOptions.fileUrlExpiresAt', responseOptionsFileUrlExpiresAt as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileFilesUploadSpecGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileFilesUploadSpecGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/files/upload/spec`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileFileApi - functional programming interface
 * @export
 */
export const MobileFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<File>} [files] 
         * @param {string} [responseOptionsFileUrlLifetime] Check out how C# TimeSpan is serialized to string for more info.
         * @param {string} [responseOptionsFileUrlExpiresAt] Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileFilesUploadPost(nexusOpsTenant: string, files?: Array<File>, responseOptionsFileUrlLifetime?: string, responseOptionsFileUrlExpiresAt?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFilesResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileFilesUploadPost(nexusOpsTenant, files, responseOptionsFileUrlLifetime, responseOptionsFileUrlExpiresAt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileFileApi.apiV1MobileFilesUploadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileFilesUploadSpecGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileFilesUploadSpecGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileFileApi.apiV1MobileFilesUploadSpecGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileFileApi - factory interface
 * @export
 */
export const MobileFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileFileApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileFileApiApiV1MobileFilesUploadPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileFilesUploadPost(requestParameters: MobileFileApiApiV1MobileFilesUploadPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<UploadFilesResultDto> {
            return localVarFp.apiV1MobileFilesUploadPost(requestParameters.nexusOpsTenant, requestParameters.files, requestParameters.responseOptionsFileUrlLifetime, requestParameters.responseOptionsFileUrlExpiresAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileFileApiApiV1MobileFilesUploadSpecGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileFilesUploadSpecGet(requestParameters: MobileFileApiApiV1MobileFilesUploadSpecGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FileUploadSpecDto> {
            return localVarFp.apiV1MobileFilesUploadSpecGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileFilesUploadPost operation in MobileFileApi.
 * @export
 * @interface MobileFileApiApiV1MobileFilesUploadPostRequest
 */
export interface MobileFileApiApiV1MobileFilesUploadPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileFileApiApiV1MobileFilesUploadPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {Array<File>}
     * @memberof MobileFileApiApiV1MobileFilesUploadPost
     */
    readonly files?: Array<File>

    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof MobileFileApiApiV1MobileFilesUploadPost
     */
    readonly responseOptionsFileUrlLifetime?: string

    /**
     * Date-time string in &lt;a href&#x3D;\\\&quot;https://en.wikipedia.org/wiki/ISO_8601#UTC\\\\\\\&quot;&gt;ISO 8601 format&lt;/a&gt;.
     * @type {string}
     * @memberof MobileFileApiApiV1MobileFilesUploadPost
     */
    readonly responseOptionsFileUrlExpiresAt?: string
}

/**
 * Request parameters for apiV1MobileFilesUploadSpecGet operation in MobileFileApi.
 * @export
 * @interface MobileFileApiApiV1MobileFilesUploadSpecGetRequest
 */
export interface MobileFileApiApiV1MobileFilesUploadSpecGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileFileApiApiV1MobileFilesUploadSpecGet
     */
    readonly nexusOpsTenant: string
}

/**
 * MobileFileApi - object-oriented interface
 * @export
 * @class MobileFileApi
 * @extends {BaseAPI}
 */
export class MobileFileApi extends BaseAPI {
    /**
     * 
     * @param {MobileFileApiApiV1MobileFilesUploadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileFileApi
     */
    public apiV1MobileFilesUploadPost(requestParameters: MobileFileApiApiV1MobileFilesUploadPostRequest, options?: RawAxiosRequestConfig) {
        return MobileFileApiFp(this.configuration).apiV1MobileFilesUploadPost(requestParameters.nexusOpsTenant, requestParameters.files, requestParameters.responseOptionsFileUrlLifetime, requestParameters.responseOptionsFileUrlExpiresAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileFileApiApiV1MobileFilesUploadSpecGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileFileApi
     */
    public apiV1MobileFilesUploadSpecGet(requestParameters: MobileFileApiApiV1MobileFilesUploadSpecGetRequest, options?: RawAxiosRequestConfig) {
        return MobileFileApiFp(this.configuration).apiV1MobileFilesUploadSpecGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileLocationsApi - axios parameter creator
 * @export
 */
export const MobileLocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {LocationGetPaginatedDto} [locationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileLocationsGetPost: async (nexusOpsTenant: string, locationGetPaginatedDto?: LocationGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileLocationsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/locations/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileLocationsLocationIdGet: async (nexusOpsTenant: string, locationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileLocationsLocationIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1MobileLocationsLocationIdGet', 'locationId', locationId)
            const localVarPath = `/api/v1/mobile/locations/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {LocationSearchPaginatedDto} [locationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileLocationsSearchPost: async (nexusOpsTenant: string, locationSearchPaginatedDto?: LocationSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileLocationsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/locations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileLocationsApi - functional programming interface
 * @export
 */
export const MobileLocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileLocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {LocationGetPaginatedDto} [locationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileLocationsGetPost(nexusOpsTenant: string, locationGetPaginatedDto?: LocationGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfLocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileLocationsGetPost(nexusOpsTenant, locationGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileLocationsApi.apiV1MobileLocationsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileLocationsLocationIdGet(nexusOpsTenant: string, locationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileLocationsLocationIdGet(nexusOpsTenant, locationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileLocationsApi.apiV1MobileLocationsLocationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {LocationSearchPaginatedDto} [locationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileLocationsSearchPost(nexusOpsTenant: string, locationSearchPaginatedDto?: LocationSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfLocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileLocationsSearchPost(nexusOpsTenant, locationSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileLocationsApi.apiV1MobileLocationsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileLocationsApi - factory interface
 * @export
 */
export const MobileLocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileLocationsApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileLocationsApiApiV1MobileLocationsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileLocationsGetPost(requestParameters: MobileLocationsApiApiV1MobileLocationsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfLocationDto> {
            return localVarFp.apiV1MobileLocationsGetPost(requestParameters.nexusOpsTenant, requestParameters.locationGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileLocationsApiApiV1MobileLocationsLocationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileLocationsLocationIdGet(requestParameters: MobileLocationsApiApiV1MobileLocationsLocationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<LocationDto> {
            return localVarFp.apiV1MobileLocationsLocationIdGet(requestParameters.nexusOpsTenant, requestParameters.locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileLocationsApiApiV1MobileLocationsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileLocationsSearchPost(requestParameters: MobileLocationsApiApiV1MobileLocationsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfLocationDto> {
            return localVarFp.apiV1MobileLocationsSearchPost(requestParameters.nexusOpsTenant, requestParameters.locationSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileLocationsGetPost operation in MobileLocationsApi.
 * @export
 * @interface MobileLocationsApiApiV1MobileLocationsGetPostRequest
 */
export interface MobileLocationsApiApiV1MobileLocationsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileLocationsApiApiV1MobileLocationsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {LocationGetPaginatedDto}
     * @memberof MobileLocationsApiApiV1MobileLocationsGetPost
     */
    readonly locationGetPaginatedDto?: LocationGetPaginatedDto
}

/**
 * Request parameters for apiV1MobileLocationsLocationIdGet operation in MobileLocationsApi.
 * @export
 * @interface MobileLocationsApiApiV1MobileLocationsLocationIdGetRequest
 */
export interface MobileLocationsApiApiV1MobileLocationsLocationIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileLocationsApiApiV1MobileLocationsLocationIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileLocationsApiApiV1MobileLocationsLocationIdGet
     */
    readonly locationId: string
}

/**
 * Request parameters for apiV1MobileLocationsSearchPost operation in MobileLocationsApi.
 * @export
 * @interface MobileLocationsApiApiV1MobileLocationsSearchPostRequest
 */
export interface MobileLocationsApiApiV1MobileLocationsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileLocationsApiApiV1MobileLocationsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {LocationSearchPaginatedDto}
     * @memberof MobileLocationsApiApiV1MobileLocationsSearchPost
     */
    readonly locationSearchPaginatedDto?: LocationSearchPaginatedDto
}

/**
 * MobileLocationsApi - object-oriented interface
 * @export
 * @class MobileLocationsApi
 * @extends {BaseAPI}
 */
export class MobileLocationsApi extends BaseAPI {
    /**
     * 
     * @param {MobileLocationsApiApiV1MobileLocationsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileLocationsApi
     */
    public apiV1MobileLocationsGetPost(requestParameters: MobileLocationsApiApiV1MobileLocationsGetPostRequest, options?: RawAxiosRequestConfig) {
        return MobileLocationsApiFp(this.configuration).apiV1MobileLocationsGetPost(requestParameters.nexusOpsTenant, requestParameters.locationGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileLocationsApiApiV1MobileLocationsLocationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileLocationsApi
     */
    public apiV1MobileLocationsLocationIdGet(requestParameters: MobileLocationsApiApiV1MobileLocationsLocationIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileLocationsApiFp(this.configuration).apiV1MobileLocationsLocationIdGet(requestParameters.nexusOpsTenant, requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileLocationsApiApiV1MobileLocationsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileLocationsApi
     */
    public apiV1MobileLocationsSearchPost(requestParameters: MobileLocationsApiApiV1MobileLocationsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return MobileLocationsApiFp(this.configuration).apiV1MobileLocationsSearchPost(requestParameters.nexusOpsTenant, requestParameters.locationSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileProfileApi - axios parameter creator
 * @export
 */
export const MobileProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileProfileGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileProfileTenantsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/profile/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileProfileApi - functional programming interface
 * @export
 */
export const MobileProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileProfileGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileProfileGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileProfileApi.apiV1MobileProfileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileProfileTenantsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileProfileTenantsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileProfileApi.apiV1MobileProfileTenantsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileProfileApi - factory interface
 * @export
 */
export const MobileProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileProfileGet(options?: RawAxiosRequestConfig): AxiosPromise<ProfileDto> {
            return localVarFp.apiV1MobileProfileGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileProfileTenantsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<TenantDto>> {
            return localVarFp.apiV1MobileProfileTenantsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileProfileApi - object-oriented interface
 * @export
 * @class MobileProfileApi
 * @extends {BaseAPI}
 */
export class MobileProfileApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProfileApi
     */
    public apiV1MobileProfileGet(options?: RawAxiosRequestConfig) {
        return MobileProfileApiFp(this.configuration).apiV1MobileProfileGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProfileApi
     */
    public apiV1MobileProfileTenantsGet(options?: RawAxiosRequestConfig) {
        return MobileProfileApiFp(this.configuration).apiV1MobileProfileTenantsGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileSpotsApi - axios parameter creator
 * @export
 */
export const MobileSpotsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SpotGetPaginatedDto} [spotGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileSpotsGetPost: async (nexusOpsTenant: string, spotGetPaginatedDto?: SpotGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileSpotsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/spots/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(spotGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SpotSearchPaginatedDto} [spotSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileSpotsSearchPost: async (nexusOpsTenant: string, spotSearchPaginatedDto?: SpotSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileSpotsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/spots/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(spotSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} spotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileSpotsSpotIdGet: async (nexusOpsTenant: string, spotId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileSpotsSpotIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'spotId' is not null or undefined
            assertParamExists('apiV1MobileSpotsSpotIdGet', 'spotId', spotId)
            const localVarPath = `/api/v1/mobile/spots/{spotId}`
                .replace(`{${"spotId"}}`, encodeURIComponent(String(spotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileSpotsApi - functional programming interface
 * @export
 */
export const MobileSpotsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileSpotsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SpotGetPaginatedDto} [spotGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileSpotsGetPost(nexusOpsTenant: string, spotGetPaginatedDto?: SpotGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfSpotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileSpotsGetPost(nexusOpsTenant, spotGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileSpotsApi.apiV1MobileSpotsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SpotSearchPaginatedDto} [spotSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileSpotsSearchPost(nexusOpsTenant: string, spotSearchPaginatedDto?: SpotSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfSpotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileSpotsSearchPost(nexusOpsTenant, spotSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileSpotsApi.apiV1MobileSpotsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} spotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileSpotsSpotIdGet(nexusOpsTenant: string, spotId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileSpotsSpotIdGet(nexusOpsTenant, spotId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileSpotsApi.apiV1MobileSpotsSpotIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileSpotsApi - factory interface
 * @export
 */
export const MobileSpotsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileSpotsApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileSpotsApiApiV1MobileSpotsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileSpotsGetPost(requestParameters: MobileSpotsApiApiV1MobileSpotsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfSpotDto> {
            return localVarFp.apiV1MobileSpotsGetPost(requestParameters.nexusOpsTenant, requestParameters.spotGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileSpotsApiApiV1MobileSpotsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileSpotsSearchPost(requestParameters: MobileSpotsApiApiV1MobileSpotsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfSpotDto> {
            return localVarFp.apiV1MobileSpotsSearchPost(requestParameters.nexusOpsTenant, requestParameters.spotSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileSpotsApiApiV1MobileSpotsSpotIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileSpotsSpotIdGet(requestParameters: MobileSpotsApiApiV1MobileSpotsSpotIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<SpotDto> {
            return localVarFp.apiV1MobileSpotsSpotIdGet(requestParameters.nexusOpsTenant, requestParameters.spotId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileSpotsGetPost operation in MobileSpotsApi.
 * @export
 * @interface MobileSpotsApiApiV1MobileSpotsGetPostRequest
 */
export interface MobileSpotsApiApiV1MobileSpotsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileSpotsApiApiV1MobileSpotsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {SpotGetPaginatedDto}
     * @memberof MobileSpotsApiApiV1MobileSpotsGetPost
     */
    readonly spotGetPaginatedDto?: SpotGetPaginatedDto
}

/**
 * Request parameters for apiV1MobileSpotsSearchPost operation in MobileSpotsApi.
 * @export
 * @interface MobileSpotsApiApiV1MobileSpotsSearchPostRequest
 */
export interface MobileSpotsApiApiV1MobileSpotsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileSpotsApiApiV1MobileSpotsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {SpotSearchPaginatedDto}
     * @memberof MobileSpotsApiApiV1MobileSpotsSearchPost
     */
    readonly spotSearchPaginatedDto?: SpotSearchPaginatedDto
}

/**
 * Request parameters for apiV1MobileSpotsSpotIdGet operation in MobileSpotsApi.
 * @export
 * @interface MobileSpotsApiApiV1MobileSpotsSpotIdGetRequest
 */
export interface MobileSpotsApiApiV1MobileSpotsSpotIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileSpotsApiApiV1MobileSpotsSpotIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileSpotsApiApiV1MobileSpotsSpotIdGet
     */
    readonly spotId: string
}

/**
 * MobileSpotsApi - object-oriented interface
 * @export
 * @class MobileSpotsApi
 * @extends {BaseAPI}
 */
export class MobileSpotsApi extends BaseAPI {
    /**
     * 
     * @param {MobileSpotsApiApiV1MobileSpotsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileSpotsApi
     */
    public apiV1MobileSpotsGetPost(requestParameters: MobileSpotsApiApiV1MobileSpotsGetPostRequest, options?: RawAxiosRequestConfig) {
        return MobileSpotsApiFp(this.configuration).apiV1MobileSpotsGetPost(requestParameters.nexusOpsTenant, requestParameters.spotGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileSpotsApiApiV1MobileSpotsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileSpotsApi
     */
    public apiV1MobileSpotsSearchPost(requestParameters: MobileSpotsApiApiV1MobileSpotsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return MobileSpotsApiFp(this.configuration).apiV1MobileSpotsSearchPost(requestParameters.nexusOpsTenant, requestParameters.spotSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileSpotsApiApiV1MobileSpotsSpotIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileSpotsApi
     */
    public apiV1MobileSpotsSpotIdGet(requestParameters: MobileSpotsApiApiV1MobileSpotsSpotIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileSpotsApiFp(this.configuration).apiV1MobileSpotsSpotIdGet(requestParameters.nexusOpsTenant, requestParameters.spotId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileVehicleBodyTypesApi - axios parameter creator
 * @export
 */
export const MobileVehicleBodyTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehicleBodyTypeGetPaginatedDto} [vehicleBodyTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesBodyTypesGetPost: async (vehicleBodyTypeGetPaginatedDto?: VehicleBodyTypeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/body-types/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleBodyTypeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleBodyTypeSearchPaginatedDto} [vehicleBodyTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesBodyTypesSearchPost: async (vehicleBodyTypeSearchPaginatedDto?: VehicleBodyTypeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/body-types/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleBodyTypeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleBodyTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet: async (vehicleBodyTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleBodyTypeId' is not null or undefined
            assertParamExists('apiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet', 'vehicleBodyTypeId', vehicleBodyTypeId)
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/body-types/{vehicleBodyTypeId}`
                .replace(`{${"vehicleBodyTypeId"}}`, encodeURIComponent(String(vehicleBodyTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileVehicleBodyTypesApi - functional programming interface
 * @export
 */
export const MobileVehicleBodyTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileVehicleBodyTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehicleBodyTypeGetPaginatedDto} [vehicleBodyTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesBodyTypesGetPost(vehicleBodyTypeGetPaginatedDto?: VehicleBodyTypeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleBodyTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesBodyTypesGetPost(vehicleBodyTypeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleBodyTypesApi.apiV1MobileReferenceDataVehiclesBodyTypesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleBodyTypeSearchPaginatedDto} [vehicleBodyTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesBodyTypesSearchPost(vehicleBodyTypeSearchPaginatedDto?: VehicleBodyTypeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleBodyTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesBodyTypesSearchPost(vehicleBodyTypeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleBodyTypesApi.apiV1MobileReferenceDataVehiclesBodyTypesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} vehicleBodyTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet(vehicleBodyTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleBodyTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet(vehicleBodyTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleBodyTypesApi.apiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileVehicleBodyTypesApi - factory interface
 * @export
 */
export const MobileVehicleBodyTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileVehicleBodyTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesBodyTypesGetPost(requestParameters: MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleBodyTypeDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesBodyTypesGetPost(requestParameters.vehicleBodyTypeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesBodyTypesSearchPost(requestParameters: MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleBodyTypeDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesBodyTypesSearchPost(requestParameters.vehicleBodyTypeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet(requestParameters: MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleBodyTypeDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet(requestParameters.vehicleBodyTypeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesBodyTypesGetPost operation in MobileVehicleBodyTypesApi.
 * @export
 * @interface MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesGetPostRequest
 */
export interface MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesGetPostRequest {
    /**
     * 
     * @type {VehicleBodyTypeGetPaginatedDto}
     * @memberof MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesGetPost
     */
    readonly vehicleBodyTypeGetPaginatedDto?: VehicleBodyTypeGetPaginatedDto
}

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesBodyTypesSearchPost operation in MobileVehicleBodyTypesApi.
 * @export
 * @interface MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesSearchPostRequest
 */
export interface MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesSearchPostRequest {
    /**
     * 
     * @type {VehicleBodyTypeSearchPaginatedDto}
     * @memberof MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesSearchPost
     */
    readonly vehicleBodyTypeSearchPaginatedDto?: VehicleBodyTypeSearchPaginatedDto
}

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet operation in MobileVehicleBodyTypesApi.
 * @export
 * @interface MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGetRequest
 */
export interface MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet
     */
    readonly vehicleBodyTypeId: string
}

/**
 * MobileVehicleBodyTypesApi - object-oriented interface
 * @export
 * @class MobileVehicleBodyTypesApi
 * @extends {BaseAPI}
 */
export class MobileVehicleBodyTypesApi extends BaseAPI {
    /**
     * 
     * @param {MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleBodyTypesApi
     */
    public apiV1MobileReferenceDataVehiclesBodyTypesGetPost(requestParameters: MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return MobileVehicleBodyTypesApiFp(this.configuration).apiV1MobileReferenceDataVehiclesBodyTypesGetPost(requestParameters.vehicleBodyTypeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleBodyTypesApi
     */
    public apiV1MobileReferenceDataVehiclesBodyTypesSearchPost(requestParameters: MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return MobileVehicleBodyTypesApiFp(this.configuration).apiV1MobileReferenceDataVehiclesBodyTypesSearchPost(requestParameters.vehicleBodyTypeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleBodyTypesApi
     */
    public apiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet(requestParameters: MobileVehicleBodyTypesApiApiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleBodyTypesApiFp(this.configuration).apiV1MobileReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet(requestParameters.vehicleBodyTypeId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileVehicleDamagesApi - axios parameter creator
 * @export
 */
export const MobileVehicleDamagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleDamageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehicleDamagesVehicleDamageIdGet: async (nexusOpsTenant: string, vehicleDamageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVehicleDamagesVehicleDamageIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleDamageId' is not null or undefined
            assertParamExists('apiV1MobileVehicleDamagesVehicleDamageIdGet', 'vehicleDamageId', vehicleDamageId)
            const localVarPath = `/api/v1/mobile/vehicle-damages/{vehicleDamageId}`
                .replace(`{${"vehicleDamageId"}}`, encodeURIComponent(String(vehicleDamageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {VehicleDamageGetCountersDto} [vehicleDamageGetCountersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdDamagesCountersGetPost: async (nexusOpsTenant: string, vehicleId: string, vehicleDamageGetCountersDto?: VehicleDamageGetCountersDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdDamagesCountersGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdDamagesCountersGetPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/mobile/vehicles/{vehicleId}/damages/counters/get`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleDamageGetCountersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {VehicleDamageState} [state] 
         * @param {string} [sortBy] Comma-separated list of fields to sort by.
         * @param {string} [sortOrder] Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdDamagesGet: async (nexusOpsTenant: string, vehicleId: string, offset?: number, limit?: number, search?: string, state?: VehicleDamageState, sortBy?: string, sortOrder?: string, from?: string, to?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdDamagesGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdDamagesGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/mobile/vehicles/{vehicleId}/damages`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [includeIds] 
         * @param {Array<string>} [excludeIds] 
         * @param {string} [sortBy] Comma-separated list of fields to sort by.
         * @param {string} [sortOrder] Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdDamagesSearchGet: async (nexusOpsTenant: string, vehicleId: string, offset?: number, limit?: number, search?: string, includeIds?: Array<string>, excludeIds?: Array<string>, sortBy?: string, sortOrder?: string, from?: string, to?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdDamagesSearchGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdDamagesSearchGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/mobile/vehicles/{vehicleId}/damages/search`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (includeIds) {
                localVarQueryParameter['includeIds'] = includeIds;
            }

            if (excludeIds) {
                localVarQueryParameter['excludeIds'] = excludeIds;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} vehicleDamageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet: async (nexusOpsTenant: string, vehicleId: string, vehicleDamageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet', 'vehicleId', vehicleId)
            // verify required parameter 'vehicleDamageId' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet', 'vehicleDamageId', vehicleDamageId)
            const localVarPath = `/api/v1/mobile/vehicles/{vehicleId}/damages/{vehicleDamageId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)))
                .replace(`{${"vehicleDamageId"}}`, encodeURIComponent(String(vehicleDamageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileVehicleDamagesApi - functional programming interface
 * @export
 */
export const MobileVehicleDamagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileVehicleDamagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleDamageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVehicleDamagesVehicleDamageIdGet(nexusOpsTenant: string, vehicleDamageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDamageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVehicleDamagesVehicleDamageIdGet(nexusOpsTenant, vehicleDamageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleDamagesApi.apiV1MobileVehicleDamagesVehicleDamageIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {VehicleDamageGetCountersDto} [vehicleDamageGetCountersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVehiclesVehicleIdDamagesCountersGetPost(nexusOpsTenant: string, vehicleId: string, vehicleDamageGetCountersDto?: VehicleDamageGetCountersDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDamageCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVehiclesVehicleIdDamagesCountersGetPost(nexusOpsTenant, vehicleId, vehicleDamageGetCountersDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleDamagesApi.apiV1MobileVehiclesVehicleIdDamagesCountersGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {VehicleDamageState} [state] 
         * @param {string} [sortBy] Comma-separated list of fields to sort by.
         * @param {string} [sortOrder] Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVehiclesVehicleIdDamagesGet(nexusOpsTenant: string, vehicleId: string, offset?: number, limit?: number, search?: string, state?: VehicleDamageState, sortBy?: string, sortOrder?: string, from?: string, to?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleDamageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVehiclesVehicleIdDamagesGet(nexusOpsTenant, vehicleId, offset, limit, search, state, sortBy, sortOrder, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleDamagesApi.apiV1MobileVehiclesVehicleIdDamagesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [includeIds] 
         * @param {Array<string>} [excludeIds] 
         * @param {string} [sortBy] Comma-separated list of fields to sort by.
         * @param {string} [sortOrder] Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVehiclesVehicleIdDamagesSearchGet(nexusOpsTenant: string, vehicleId: string, offset?: number, limit?: number, search?: string, includeIds?: Array<string>, excludeIds?: Array<string>, sortBy?: string, sortOrder?: string, from?: string, to?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleDamageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVehiclesVehicleIdDamagesSearchGet(nexusOpsTenant, vehicleId, offset, limit, search, includeIds, excludeIds, sortBy, sortOrder, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleDamagesApi.apiV1MobileVehiclesVehicleIdDamagesSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} vehicleDamageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet(nexusOpsTenant: string, vehicleId: string, vehicleDamageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDamageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet(nexusOpsTenant, vehicleId, vehicleDamageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleDamagesApi.apiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileVehicleDamagesApi - factory interface
 * @export
 */
export const MobileVehicleDamagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileVehicleDamagesApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileVehicleDamagesApiApiV1MobileVehicleDamagesVehicleDamageIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehicleDamagesVehicleDamageIdGet(requestParameters: MobileVehicleDamagesApiApiV1MobileVehicleDamagesVehicleDamageIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDamageDto> {
            return localVarFp.apiV1MobileVehicleDamagesVehicleDamageIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleDamageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesCountersGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdDamagesCountersGetPost(requestParameters: MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesCountersGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDamageCountersDto> {
            return localVarFp.apiV1MobileVehiclesVehicleIdDamagesCountersGetPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageGetCountersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdDamagesGet(requestParameters: MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleDamageDto> {
            return localVarFp.apiV1MobileVehiclesVehicleIdDamagesGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.state, requestParameters.sortBy, requestParameters.sortOrder, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdDamagesSearchGet(requestParameters: MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleDamageDto> {
            return localVarFp.apiV1MobileVehiclesVehicleIdDamagesSearchGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.includeIds, requestParameters.excludeIds, requestParameters.sortBy, requestParameters.sortOrder, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet(requestParameters: MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDamageDto> {
            return localVarFp.apiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileVehicleDamagesVehicleDamageIdGet operation in MobileVehicleDamagesApi.
 * @export
 * @interface MobileVehicleDamagesApiApiV1MobileVehicleDamagesVehicleDamageIdGetRequest
 */
export interface MobileVehicleDamagesApiApiV1MobileVehicleDamagesVehicleDamageIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehicleDamagesVehicleDamageIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehicleDamagesVehicleDamageIdGet
     */
    readonly vehicleDamageId: string
}

/**
 * Request parameters for apiV1MobileVehiclesVehicleIdDamagesCountersGetPost operation in MobileVehicleDamagesApi.
 * @export
 * @interface MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesCountersGetPostRequest
 */
export interface MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesCountersGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesCountersGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesCountersGetPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {VehicleDamageGetCountersDto}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesCountersGetPost
     */
    readonly vehicleDamageGetCountersDto?: VehicleDamageGetCountersDto
}

/**
 * Request parameters for apiV1MobileVehiclesVehicleIdDamagesGet operation in MobileVehicleDamagesApi.
 * @export
 * @interface MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGetRequest
 */
export interface MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {number}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGet
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGet
     */
    readonly search?: string

    /**
     * 
     * @type {VehicleDamageState}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGet
     */
    readonly state?: VehicleDamageState

    /**
     * Comma-separated list of fields to sort by.
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGet
     */
    readonly sortBy?: string

    /**
     * Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGet
     */
    readonly sortOrder?: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGet
     */
    readonly to?: string
}

/**
 * Request parameters for apiV1MobileVehiclesVehicleIdDamagesSearchGet operation in MobileVehicleDamagesApi.
 * @export
 * @interface MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGetRequest
 */
export interface MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {number}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGet
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGet
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGet
     */
    readonly includeIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGet
     */
    readonly excludeIds?: Array<string>

    /**
     * Comma-separated list of fields to sort by.
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGet
     */
    readonly sortBy?: string

    /**
     * Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGet
     */
    readonly sortOrder?: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGet
     */
    readonly to?: string
}

/**
 * Request parameters for apiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet operation in MobileVehicleDamagesApi.
 * @export
 * @interface MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGetRequest
 */
export interface MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet
     */
    readonly vehicleDamageId: string
}

/**
 * MobileVehicleDamagesApi - object-oriented interface
 * @export
 * @class MobileVehicleDamagesApi
 * @extends {BaseAPI}
 */
export class MobileVehicleDamagesApi extends BaseAPI {
    /**
     * 
     * @param {MobileVehicleDamagesApiApiV1MobileVehicleDamagesVehicleDamageIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleDamagesApi
     */
    public apiV1MobileVehicleDamagesVehicleDamageIdGet(requestParameters: MobileVehicleDamagesApiApiV1MobileVehicleDamagesVehicleDamageIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleDamagesApiFp(this.configuration).apiV1MobileVehicleDamagesVehicleDamageIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleDamageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesCountersGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleDamagesApi
     */
    public apiV1MobileVehiclesVehicleIdDamagesCountersGetPost(requestParameters: MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesCountersGetPostRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleDamagesApiFp(this.configuration).apiV1MobileVehiclesVehicleIdDamagesCountersGetPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageGetCountersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleDamagesApi
     */
    public apiV1MobileVehiclesVehicleIdDamagesGet(requestParameters: MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleDamagesApiFp(this.configuration).apiV1MobileVehiclesVehicleIdDamagesGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.state, requestParameters.sortBy, requestParameters.sortOrder, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleDamagesApi
     */
    public apiV1MobileVehiclesVehicleIdDamagesSearchGet(requestParameters: MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesSearchGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleDamagesApiFp(this.configuration).apiV1MobileVehiclesVehicleIdDamagesSearchGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.includeIds, requestParameters.excludeIds, requestParameters.sortBy, requestParameters.sortOrder, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleDamagesApi
     */
    public apiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet(requestParameters: MobileVehicleDamagesApiApiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleDamagesApiFp(this.configuration).apiV1MobileVehiclesVehicleIdDamagesVehicleDamageIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileVehicleMakesApi - axios parameter creator
 * @export
 */
export const MobileVehicleMakesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehicleMakeGetPaginatedDto} [vehicleMakeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesMakesGetPost: async (vehicleMakeGetPaginatedDto?: VehicleMakeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/makes/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleMakeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} makeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesMakesMakeIdGet: async (makeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'makeId' is not null or undefined
            assertParamExists('apiV1MobileReferenceDataVehiclesMakesMakeIdGet', 'makeId', makeId)
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/makes/{makeId}`
                .replace(`{${"makeId"}}`, encodeURIComponent(String(makeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleMakeSearchPaginatedDto} [vehicleMakeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesMakesSearchPost: async (vehicleMakeSearchPaginatedDto?: VehicleMakeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/makes/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleMakeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileVehicleMakesApi - functional programming interface
 * @export
 */
export const MobileVehicleMakesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileVehicleMakesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehicleMakeGetPaginatedDto} [vehicleMakeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesMakesGetPost(vehicleMakeGetPaginatedDto?: VehicleMakeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleMakeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesMakesGetPost(vehicleMakeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleMakesApi.apiV1MobileReferenceDataVehiclesMakesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} makeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesMakesMakeIdGet(makeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleMakeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesMakesMakeIdGet(makeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleMakesApi.apiV1MobileReferenceDataVehiclesMakesMakeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleMakeSearchPaginatedDto} [vehicleMakeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesMakesSearchPost(vehicleMakeSearchPaginatedDto?: VehicleMakeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleMakeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesMakesSearchPost(vehicleMakeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleMakesApi.apiV1MobileReferenceDataVehiclesMakesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileVehicleMakesApi - factory interface
 * @export
 */
export const MobileVehicleMakesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileVehicleMakesApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesMakesGetPost(requestParameters: MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleMakeDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesMakesGetPost(requestParameters.vehicleMakeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesMakeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesMakesMakeIdGet(requestParameters: MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesMakeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleMakeDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesMakesMakeIdGet(requestParameters.makeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesMakesSearchPost(requestParameters: MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleMakeDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesMakesSearchPost(requestParameters.vehicleMakeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesMakesGetPost operation in MobileVehicleMakesApi.
 * @export
 * @interface MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesGetPostRequest
 */
export interface MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesGetPostRequest {
    /**
     * 
     * @type {VehicleMakeGetPaginatedDto}
     * @memberof MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesGetPost
     */
    readonly vehicleMakeGetPaginatedDto?: VehicleMakeGetPaginatedDto
}

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesMakesMakeIdGet operation in MobileVehicleMakesApi.
 * @export
 * @interface MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesMakeIdGetRequest
 */
export interface MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesMakeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesMakeIdGet
     */
    readonly makeId: string
}

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesMakesSearchPost operation in MobileVehicleMakesApi.
 * @export
 * @interface MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesSearchPostRequest
 */
export interface MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesSearchPostRequest {
    /**
     * 
     * @type {VehicleMakeSearchPaginatedDto}
     * @memberof MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesSearchPost
     */
    readonly vehicleMakeSearchPaginatedDto?: VehicleMakeSearchPaginatedDto
}

/**
 * MobileVehicleMakesApi - object-oriented interface
 * @export
 * @class MobileVehicleMakesApi
 * @extends {BaseAPI}
 */
export class MobileVehicleMakesApi extends BaseAPI {
    /**
     * 
     * @param {MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleMakesApi
     */
    public apiV1MobileReferenceDataVehiclesMakesGetPost(requestParameters: MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return MobileVehicleMakesApiFp(this.configuration).apiV1MobileReferenceDataVehiclesMakesGetPost(requestParameters.vehicleMakeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesMakeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleMakesApi
     */
    public apiV1MobileReferenceDataVehiclesMakesMakeIdGet(requestParameters: MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesMakeIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleMakesApiFp(this.configuration).apiV1MobileReferenceDataVehiclesMakesMakeIdGet(requestParameters.makeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleMakesApi
     */
    public apiV1MobileReferenceDataVehiclesMakesSearchPost(requestParameters: MobileVehicleMakesApiApiV1MobileReferenceDataVehiclesMakesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return MobileVehicleMakesApiFp(this.configuration).apiV1MobileReferenceDataVehiclesMakesSearchPost(requestParameters.vehicleMakeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileVehicleModelsApi - axios parameter creator
 * @export
 */
export const MobileVehicleModelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehicleModelGetPaginatedDto} [vehicleModelGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesModelsGetPost: async (vehicleModelGetPaginatedDto?: VehicleModelGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/models/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleModelGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesModelsModelIdGet: async (modelId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('apiV1MobileReferenceDataVehiclesModelsModelIdGet', 'modelId', modelId)
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/models/{modelId}`
                .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleModelSearchPaginatedDto} [vehicleModelSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesModelsSearchPost: async (vehicleModelSearchPaginatedDto?: VehicleModelSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/models/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleModelSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileVehicleModelsApi - functional programming interface
 * @export
 */
export const MobileVehicleModelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileVehicleModelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehicleModelGetPaginatedDto} [vehicleModelGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesModelsGetPost(vehicleModelGetPaginatedDto?: VehicleModelGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesModelsGetPost(vehicleModelGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleModelsApi.apiV1MobileReferenceDataVehiclesModelsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesModelsModelIdGet(modelId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesModelsModelIdGet(modelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleModelsApi.apiV1MobileReferenceDataVehiclesModelsModelIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleModelSearchPaginatedDto} [vehicleModelSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesModelsSearchPost(vehicleModelSearchPaginatedDto?: VehicleModelSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesModelsSearchPost(vehicleModelSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleModelsApi.apiV1MobileReferenceDataVehiclesModelsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileVehicleModelsApi - factory interface
 * @export
 */
export const MobileVehicleModelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileVehicleModelsApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesModelsGetPost(requestParameters: MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleModelDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesModelsGetPost(requestParameters.vehicleModelGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsModelIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesModelsModelIdGet(requestParameters: MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsModelIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleModelDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesModelsModelIdGet(requestParameters.modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesModelsSearchPost(requestParameters: MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleModelDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesModelsSearchPost(requestParameters.vehicleModelSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesModelsGetPost operation in MobileVehicleModelsApi.
 * @export
 * @interface MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsGetPostRequest
 */
export interface MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsGetPostRequest {
    /**
     * 
     * @type {VehicleModelGetPaginatedDto}
     * @memberof MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsGetPost
     */
    readonly vehicleModelGetPaginatedDto?: VehicleModelGetPaginatedDto
}

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesModelsModelIdGet operation in MobileVehicleModelsApi.
 * @export
 * @interface MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsModelIdGetRequest
 */
export interface MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsModelIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsModelIdGet
     */
    readonly modelId: string
}

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesModelsSearchPost operation in MobileVehicleModelsApi.
 * @export
 * @interface MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsSearchPostRequest
 */
export interface MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsSearchPostRequest {
    /**
     * 
     * @type {VehicleModelSearchPaginatedDto}
     * @memberof MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsSearchPost
     */
    readonly vehicleModelSearchPaginatedDto?: VehicleModelSearchPaginatedDto
}

/**
 * MobileVehicleModelsApi - object-oriented interface
 * @export
 * @class MobileVehicleModelsApi
 * @extends {BaseAPI}
 */
export class MobileVehicleModelsApi extends BaseAPI {
    /**
     * 
     * @param {MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleModelsApi
     */
    public apiV1MobileReferenceDataVehiclesModelsGetPost(requestParameters: MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return MobileVehicleModelsApiFp(this.configuration).apiV1MobileReferenceDataVehiclesModelsGetPost(requestParameters.vehicleModelGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsModelIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleModelsApi
     */
    public apiV1MobileReferenceDataVehiclesModelsModelIdGet(requestParameters: MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsModelIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleModelsApiFp(this.configuration).apiV1MobileReferenceDataVehiclesModelsModelIdGet(requestParameters.modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleModelsApi
     */
    public apiV1MobileReferenceDataVehiclesModelsSearchPost(requestParameters: MobileVehicleModelsApiApiV1MobileReferenceDataVehiclesModelsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return MobileVehicleModelsApiFp(this.configuration).apiV1MobileReferenceDataVehiclesModelsSearchPost(requestParameters.vehicleModelSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileVehiclePartTypesApi - axios parameter creator
 * @export
 */
export const MobileVehiclePartTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehiclePartTypeGetPaginatedDto} [vehiclePartTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesPartTypesGetPost: async (vehiclePartTypeGetPaginatedDto?: VehiclePartTypeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/part-types/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehiclePartTypeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGet: async (partTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partTypeId' is not null or undefined
            assertParamExists('apiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGet', 'partTypeId', partTypeId)
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/part-types/{partTypeId}`
                .replace(`{${"partTypeId"}}`, encodeURIComponent(String(partTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehiclePartTypeSearchPaginatedDto} [vehiclePartTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesPartTypesSearchPost: async (vehiclePartTypeSearchPaginatedDto?: VehiclePartTypeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/part-types/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehiclePartTypeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileVehiclePartTypesApi - functional programming interface
 * @export
 */
export const MobileVehiclePartTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileVehiclePartTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehiclePartTypeGetPaginatedDto} [vehiclePartTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesPartTypesGetPost(vehiclePartTypeGetPaginatedDto?: VehiclePartTypeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehiclePartTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesPartTypesGetPost(vehiclePartTypeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehiclePartTypesApi.apiV1MobileReferenceDataVehiclesPartTypesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGet(partTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehiclePartTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGet(partTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehiclePartTypesApi.apiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehiclePartTypeSearchPaginatedDto} [vehiclePartTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesPartTypesSearchPost(vehiclePartTypeSearchPaginatedDto?: VehiclePartTypeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehiclePartTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesPartTypesSearchPost(vehiclePartTypeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehiclePartTypesApi.apiV1MobileReferenceDataVehiclesPartTypesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileVehiclePartTypesApi - factory interface
 * @export
 */
export const MobileVehiclePartTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileVehiclePartTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesPartTypesGetPost(requestParameters: MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehiclePartTypeDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesPartTypesGetPost(requestParameters.vehiclePartTypeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGet(requestParameters: MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehiclePartTypeDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGet(requestParameters.partTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesPartTypesSearchPost(requestParameters: MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehiclePartTypeDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesPartTypesSearchPost(requestParameters.vehiclePartTypeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesPartTypesGetPost operation in MobileVehiclePartTypesApi.
 * @export
 * @interface MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesGetPostRequest
 */
export interface MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesGetPostRequest {
    /**
     * 
     * @type {VehiclePartTypeGetPaginatedDto}
     * @memberof MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesGetPost
     */
    readonly vehiclePartTypeGetPaginatedDto?: VehiclePartTypeGetPaginatedDto
}

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGet operation in MobileVehiclePartTypesApi.
 * @export
 * @interface MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGetRequest
 */
export interface MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGet
     */
    readonly partTypeId: string
}

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesPartTypesSearchPost operation in MobileVehiclePartTypesApi.
 * @export
 * @interface MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesSearchPostRequest
 */
export interface MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesSearchPostRequest {
    /**
     * 
     * @type {VehiclePartTypeSearchPaginatedDto}
     * @memberof MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesSearchPost
     */
    readonly vehiclePartTypeSearchPaginatedDto?: VehiclePartTypeSearchPaginatedDto
}

/**
 * MobileVehiclePartTypesApi - object-oriented interface
 * @export
 * @class MobileVehiclePartTypesApi
 * @extends {BaseAPI}
 */
export class MobileVehiclePartTypesApi extends BaseAPI {
    /**
     * 
     * @param {MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehiclePartTypesApi
     */
    public apiV1MobileReferenceDataVehiclesPartTypesGetPost(requestParameters: MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return MobileVehiclePartTypesApiFp(this.configuration).apiV1MobileReferenceDataVehiclesPartTypesGetPost(requestParameters.vehiclePartTypeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehiclePartTypesApi
     */
    public apiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGet(requestParameters: MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehiclePartTypesApiFp(this.configuration).apiV1MobileReferenceDataVehiclesPartTypesPartTypeIdGet(requestParameters.partTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehiclePartTypesApi
     */
    public apiV1MobileReferenceDataVehiclesPartTypesSearchPost(requestParameters: MobileVehiclePartTypesApiApiV1MobileReferenceDataVehiclesPartTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return MobileVehiclePartTypesApiFp(this.configuration).apiV1MobileReferenceDataVehiclesPartTypesSearchPost(requestParameters.vehiclePartTypeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileVehicleVisualModelsApi - axios parameter creator
 * @export
 */
export const MobileVehicleVisualModelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehicleVisualModelGetPaginatedDto} [vehicleVisualModelGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesVisualModelsGetPost: async (vehicleVisualModelGetPaginatedDto?: VehicleVisualModelGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/visual-models/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleVisualModelGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleVisualModelType} [type] 
         * @param {VehicleType} [vehicleType] 
         * @param {VehicleBodyType} [bodyType] 
         * @param {string} [vehicleId] 
         * @param {boolean} [isIgnoreDefaultForVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet: async (type?: VehicleVisualModelType, vehicleType?: VehicleType, bodyType?: VehicleBodyType, vehicleId?: string, isIgnoreDefaultForVehicle?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/visual-models/latest-or-default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (vehicleType !== undefined) {
                localVarQueryParameter['vehicleType'] = vehicleType;
            }

            if (bodyType !== undefined) {
                localVarQueryParameter['bodyType'] = bodyType;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (isIgnoreDefaultForVehicle !== undefined) {
                localVarQueryParameter['isIgnoreDefaultForVehicle'] = isIgnoreDefaultForVehicle;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesVisualModelsModelIdGet: async (modelId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('apiV1MobileReferenceDataVehiclesVisualModelsModelIdGet', 'modelId', modelId)
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/visual-models/{modelId}`
                .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleVisualModelSearchPaginatedDto} [vehicleVisualModelSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesVisualModelsSearchPost: async (vehicleVisualModelSearchPaginatedDto?: VehicleVisualModelSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mobile/reference-data/vehicles/visual-models/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleVisualModelSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileVehicleVisualModelsApi - functional programming interface
 * @export
 */
export const MobileVehicleVisualModelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileVehicleVisualModelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehicleVisualModelGetPaginatedDto} [vehicleVisualModelGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesVisualModelsGetPost(vehicleVisualModelGetPaginatedDto?: VehicleVisualModelGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesVisualModelsGetPost(vehicleVisualModelGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleVisualModelsApi.apiV1MobileReferenceDataVehiclesVisualModelsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleVisualModelType} [type] 
         * @param {VehicleType} [vehicleType] 
         * @param {VehicleBodyType} [bodyType] 
         * @param {string} [vehicleId] 
         * @param {boolean} [isIgnoreDefaultForVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet(type?: VehicleVisualModelType, vehicleType?: VehicleType, bodyType?: VehicleBodyType, vehicleId?: string, isIgnoreDefaultForVehicle?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet(type, vehicleType, bodyType, vehicleId, isIgnoreDefaultForVehicle, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleVisualModelsApi.apiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesVisualModelsModelIdGet(modelId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesVisualModelsModelIdGet(modelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleVisualModelsApi.apiV1MobileReferenceDataVehiclesVisualModelsModelIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleVisualModelSearchPaginatedDto} [vehicleVisualModelSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileReferenceDataVehiclesVisualModelsSearchPost(vehicleVisualModelSearchPaginatedDto?: VehicleVisualModelSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileReferenceDataVehiclesVisualModelsSearchPost(vehicleVisualModelSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleVisualModelsApi.apiV1MobileReferenceDataVehiclesVisualModelsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileVehicleVisualModelsApi - factory interface
 * @export
 */
export const MobileVehicleVisualModelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileVehicleVisualModelsApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesVisualModelsGetPost(requestParameters: MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleVisualModelDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesVisualModelsGetPost(requestParameters.vehicleVisualModelGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet(requestParameters: MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<VehicleVisualModelDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet(requestParameters.type, requestParameters.vehicleType, requestParameters.bodyType, requestParameters.vehicleId, requestParameters.isIgnoreDefaultForVehicle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsModelIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesVisualModelsModelIdGet(requestParameters: MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsModelIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleVisualModelDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesVisualModelsModelIdGet(requestParameters.modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileReferenceDataVehiclesVisualModelsSearchPost(requestParameters: MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleVisualModelDto> {
            return localVarFp.apiV1MobileReferenceDataVehiclesVisualModelsSearchPost(requestParameters.vehicleVisualModelSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesVisualModelsGetPost operation in MobileVehicleVisualModelsApi.
 * @export
 * @interface MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsGetPostRequest
 */
export interface MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsGetPostRequest {
    /**
     * 
     * @type {VehicleVisualModelGetPaginatedDto}
     * @memberof MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsGetPost
     */
    readonly vehicleVisualModelGetPaginatedDto?: VehicleVisualModelGetPaginatedDto
}

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet operation in MobileVehicleVisualModelsApi.
 * @export
 * @interface MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest
 */
export interface MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest {
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet
     */
    readonly type?: VehicleVisualModelType

    /**
     * 
     * @type {VehicleType}
     * @memberof MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet
     */
    readonly vehicleType?: VehicleType

    /**
     * 
     * @type {VehicleBodyType}
     * @memberof MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet
     */
    readonly bodyType?: VehicleBodyType

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet
     */
    readonly vehicleId?: string

    /**
     * 
     * @type {boolean}
     * @memberof MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet
     */
    readonly isIgnoreDefaultForVehicle?: boolean
}

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesVisualModelsModelIdGet operation in MobileVehicleVisualModelsApi.
 * @export
 * @interface MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsModelIdGetRequest
 */
export interface MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsModelIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsModelIdGet
     */
    readonly modelId: string
}

/**
 * Request parameters for apiV1MobileReferenceDataVehiclesVisualModelsSearchPost operation in MobileVehicleVisualModelsApi.
 * @export
 * @interface MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsSearchPostRequest
 */
export interface MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsSearchPostRequest {
    /**
     * 
     * @type {VehicleVisualModelSearchPaginatedDto}
     * @memberof MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsSearchPost
     */
    readonly vehicleVisualModelSearchPaginatedDto?: VehicleVisualModelSearchPaginatedDto
}

/**
 * MobileVehicleVisualModelsApi - object-oriented interface
 * @export
 * @class MobileVehicleVisualModelsApi
 * @extends {BaseAPI}
 */
export class MobileVehicleVisualModelsApi extends BaseAPI {
    /**
     * 
     * @param {MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleVisualModelsApi
     */
    public apiV1MobileReferenceDataVehiclesVisualModelsGetPost(requestParameters: MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return MobileVehicleVisualModelsApiFp(this.configuration).apiV1MobileReferenceDataVehiclesVisualModelsGetPost(requestParameters.vehicleVisualModelGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleVisualModelsApi
     */
    public apiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet(requestParameters: MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest = {}, options?: RawAxiosRequestConfig) {
        return MobileVehicleVisualModelsApiFp(this.configuration).apiV1MobileReferenceDataVehiclesVisualModelsLatestOrDefaultGet(requestParameters.type, requestParameters.vehicleType, requestParameters.bodyType, requestParameters.vehicleId, requestParameters.isIgnoreDefaultForVehicle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsModelIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleVisualModelsApi
     */
    public apiV1MobileReferenceDataVehiclesVisualModelsModelIdGet(requestParameters: MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsModelIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleVisualModelsApiFp(this.configuration).apiV1MobileReferenceDataVehiclesVisualModelsModelIdGet(requestParameters.modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleVisualModelsApi
     */
    public apiV1MobileReferenceDataVehiclesVisualModelsSearchPost(requestParameters: MobileVehicleVisualModelsApiApiV1MobileReferenceDataVehiclesVisualModelsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return MobileVehicleVisualModelsApiFp(this.configuration).apiV1MobileReferenceDataVehiclesVisualModelsSearchPost(requestParameters.vehicleVisualModelSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileVehiclesApi - axios parameter creator
 * @export
 */
export const MobileVehiclesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateVehicleDto} [createVehicleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesPost: async (nexusOpsTenant: string, createVehicleDto?: CreateVehicleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVehiclesPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVehicleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VehicleSearchPaginatedDto} [vehicleSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesSearchPost: async (nexusOpsTenant: string, vehicleSearchPaginatedDto?: VehicleSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVehiclesSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/vehicles/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} [contractId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdAccessoriesGet: async (nexusOpsTenant: string, vehicleId: string, contractId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdAccessoriesGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdAccessoriesGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/mobile/vehicles/{vehicleId}/accessories`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdActualStateGet: async (nexusOpsTenant: string, vehicleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdActualStateGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdActualStateGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/mobile/vehicles/{vehicleId}/actual-state`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdGet: async (nexusOpsTenant: string, vehicleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/mobile/vehicles/{vehicleId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {UpdateVehicleMileageDto} [updateVehicleMileageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdMileagePut: async (nexusOpsTenant: string, vehicleId: string, updateVehicleMileageDto?: UpdateVehicleMileageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdMileagePut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1MobileVehiclesVehicleIdMileagePut', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/mobile/vehicles/{vehicleId}/mileage`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVehicleMileageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileVehiclesApi - functional programming interface
 * @export
 */
export const MobileVehiclesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileVehiclesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateVehicleDto} [createVehicleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVehiclesPost(nexusOpsTenant: string, createVehicleDto?: CreateVehicleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVehiclesPost(nexusOpsTenant, createVehicleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehiclesApi.apiV1MobileVehiclesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VehicleSearchPaginatedDto} [vehicleSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVehiclesSearchPost(nexusOpsTenant: string, vehicleSearchPaginatedDto?: VehicleSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVehiclesSearchPost(nexusOpsTenant, vehicleSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehiclesApi.apiV1MobileVehiclesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} [contractId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVehiclesVehicleIdAccessoriesGet(nexusOpsTenant: string, vehicleId: string, contractId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleAccessoriesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVehiclesVehicleIdAccessoriesGet(nexusOpsTenant, vehicleId, contractId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehiclesApi.apiV1MobileVehiclesVehicleIdAccessoriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVehiclesVehicleIdActualStateGet(nexusOpsTenant: string, vehicleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleActualStateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVehiclesVehicleIdActualStateGet(nexusOpsTenant, vehicleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehiclesApi.apiV1MobileVehiclesVehicleIdActualStateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVehiclesVehicleIdGet(nexusOpsTenant: string, vehicleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVehiclesVehicleIdGet(nexusOpsTenant, vehicleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehiclesApi.apiV1MobileVehiclesVehicleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {UpdateVehicleMileageDto} [updateVehicleMileageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVehiclesVehicleIdMileagePut(nexusOpsTenant: string, vehicleId: string, updateVehicleMileageDto?: UpdateVehicleMileageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVehiclesVehicleIdMileagePut(nexusOpsTenant, vehicleId, updateVehicleMileageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehiclesApi.apiV1MobileVehiclesVehicleIdMileagePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileVehiclesApi - factory interface
 * @export
 */
export const MobileVehiclesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileVehiclesApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileVehiclesApiApiV1MobileVehiclesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesPost(requestParameters: MobileVehiclesApiApiV1MobileVehiclesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDto> {
            return localVarFp.apiV1MobileVehiclesPost(requestParameters.nexusOpsTenant, requestParameters.createVehicleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehiclesApiApiV1MobileVehiclesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesSearchPost(requestParameters: MobileVehiclesApiApiV1MobileVehiclesSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleDto> {
            return localVarFp.apiV1MobileVehiclesSearchPost(requestParameters.nexusOpsTenant, requestParameters.vehicleSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehiclesApiApiV1MobileVehiclesVehicleIdAccessoriesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdAccessoriesGet(requestParameters: MobileVehiclesApiApiV1MobileVehiclesVehicleIdAccessoriesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleAccessoriesDto> {
            return localVarFp.apiV1MobileVehiclesVehicleIdAccessoriesGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehiclesApiApiV1MobileVehiclesVehicleIdActualStateGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdActualStateGet(requestParameters: MobileVehiclesApiApiV1MobileVehiclesVehicleIdActualStateGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleActualStateDto> {
            return localVarFp.apiV1MobileVehiclesVehicleIdActualStateGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehiclesApiApiV1MobileVehiclesVehicleIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdGet(requestParameters: MobileVehiclesApiApiV1MobileVehiclesVehicleIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDto> {
            return localVarFp.apiV1MobileVehiclesVehicleIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehiclesApiApiV1MobileVehiclesVehicleIdMileagePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVehiclesVehicleIdMileagePut(requestParameters: MobileVehiclesApiApiV1MobileVehiclesVehicleIdMileagePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDto> {
            return localVarFp.apiV1MobileVehiclesVehicleIdMileagePut(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.updateVehicleMileageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileVehiclesPost operation in MobileVehiclesApi.
 * @export
 * @interface MobileVehiclesApiApiV1MobileVehiclesPostRequest
 */
export interface MobileVehiclesApiApiV1MobileVehiclesPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateVehicleDto}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesPost
     */
    readonly createVehicleDto?: CreateVehicleDto
}

/**
 * Request parameters for apiV1MobileVehiclesSearchPost operation in MobileVehiclesApi.
 * @export
 * @interface MobileVehiclesApiApiV1MobileVehiclesSearchPostRequest
 */
export interface MobileVehiclesApiApiV1MobileVehiclesSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {VehicleSearchPaginatedDto}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesSearchPost
     */
    readonly vehicleSearchPaginatedDto?: VehicleSearchPaginatedDto
}

/**
 * Request parameters for apiV1MobileVehiclesVehicleIdAccessoriesGet operation in MobileVehiclesApi.
 * @export
 * @interface MobileVehiclesApiApiV1MobileVehiclesVehicleIdAccessoriesGetRequest
 */
export interface MobileVehiclesApiApiV1MobileVehiclesVehicleIdAccessoriesGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesVehicleIdAccessoriesGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesVehicleIdAccessoriesGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesVehicleIdAccessoriesGet
     */
    readonly contractId?: string
}

/**
 * Request parameters for apiV1MobileVehiclesVehicleIdActualStateGet operation in MobileVehiclesApi.
 * @export
 * @interface MobileVehiclesApiApiV1MobileVehiclesVehicleIdActualStateGetRequest
 */
export interface MobileVehiclesApiApiV1MobileVehiclesVehicleIdActualStateGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesVehicleIdActualStateGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesVehicleIdActualStateGet
     */
    readonly vehicleId: string
}

/**
 * Request parameters for apiV1MobileVehiclesVehicleIdGet operation in MobileVehiclesApi.
 * @export
 * @interface MobileVehiclesApiApiV1MobileVehiclesVehicleIdGetRequest
 */
export interface MobileVehiclesApiApiV1MobileVehiclesVehicleIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesVehicleIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesVehicleIdGet
     */
    readonly vehicleId: string
}

/**
 * Request parameters for apiV1MobileVehiclesVehicleIdMileagePut operation in MobileVehiclesApi.
 * @export
 * @interface MobileVehiclesApiApiV1MobileVehiclesVehicleIdMileagePutRequest
 */
export interface MobileVehiclesApiApiV1MobileVehiclesVehicleIdMileagePutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesVehicleIdMileagePut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesVehicleIdMileagePut
     */
    readonly vehicleId: string

    /**
     * 
     * @type {UpdateVehicleMileageDto}
     * @memberof MobileVehiclesApiApiV1MobileVehiclesVehicleIdMileagePut
     */
    readonly updateVehicleMileageDto?: UpdateVehicleMileageDto
}

/**
 * MobileVehiclesApi - object-oriented interface
 * @export
 * @class MobileVehiclesApi
 * @extends {BaseAPI}
 */
export class MobileVehiclesApi extends BaseAPI {
    /**
     * 
     * @param {MobileVehiclesApiApiV1MobileVehiclesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehiclesApi
     */
    public apiV1MobileVehiclesPost(requestParameters: MobileVehiclesApiApiV1MobileVehiclesPostRequest, options?: RawAxiosRequestConfig) {
        return MobileVehiclesApiFp(this.configuration).apiV1MobileVehiclesPost(requestParameters.nexusOpsTenant, requestParameters.createVehicleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehiclesApiApiV1MobileVehiclesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehiclesApi
     */
    public apiV1MobileVehiclesSearchPost(requestParameters: MobileVehiclesApiApiV1MobileVehiclesSearchPostRequest, options?: RawAxiosRequestConfig) {
        return MobileVehiclesApiFp(this.configuration).apiV1MobileVehiclesSearchPost(requestParameters.nexusOpsTenant, requestParameters.vehicleSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehiclesApiApiV1MobileVehiclesVehicleIdAccessoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehiclesApi
     */
    public apiV1MobileVehiclesVehicleIdAccessoriesGet(requestParameters: MobileVehiclesApiApiV1MobileVehiclesVehicleIdAccessoriesGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehiclesApiFp(this.configuration).apiV1MobileVehiclesVehicleIdAccessoriesGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehiclesApiApiV1MobileVehiclesVehicleIdActualStateGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehiclesApi
     */
    public apiV1MobileVehiclesVehicleIdActualStateGet(requestParameters: MobileVehiclesApiApiV1MobileVehiclesVehicleIdActualStateGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehiclesApiFp(this.configuration).apiV1MobileVehiclesVehicleIdActualStateGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehiclesApiApiV1MobileVehiclesVehicleIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehiclesApi
     */
    public apiV1MobileVehiclesVehicleIdGet(requestParameters: MobileVehiclesApiApiV1MobileVehiclesVehicleIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehiclesApiFp(this.configuration).apiV1MobileVehiclesVehicleIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehiclesApiApiV1MobileVehiclesVehicleIdMileagePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehiclesApi
     */
    public apiV1MobileVehiclesVehicleIdMileagePut(requestParameters: MobileVehiclesApiApiV1MobileVehiclesVehicleIdMileagePutRequest, options?: RawAxiosRequestConfig) {
        return MobileVehiclesApiFp(this.configuration).apiV1MobileVehiclesVehicleIdMileagePut(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.updateVehicleMileageDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileVisualInspectionsApi - axios parameter creator
 * @export
 */
export const MobileVisualInspectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsByIdsGet: async (nexusOpsTenant: string, ids?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsByIdsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/visual-inspections/by-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsCountersGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsCountersGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/visual-inspections/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsCreateMetaGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsCreateMetaGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/visual-inspections/create/meta`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VisualInspectionGetPaginatedDto} [visualInspectionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsGetPost: async (nexusOpsTenant: string, visualInspectionGetPaginatedDto?: VisualInspectionGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/visual-inspections/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visualInspectionGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VisualInspectionCreateDto} [visualInspectionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsPost: async (nexusOpsTenant: string, visualInspectionCreateDto?: VisualInspectionCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/visual-inspections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visualInspectionCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VisualInspectionSearchPaginatedDto} [visualInspectionSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsSearchPost: async (nexusOpsTenant: string, visualInspectionSearchPaginatedDto?: VisualInspectionSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/mobile/visual-inspections/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visualInspectionSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {VisualInspectionAddToContractDto} [visualInspectionAddToContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost: async (nexusOpsTenant: string, visualInspectionId: string, visualInspectionAddToContractDto?: VisualInspectionAddToContractDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'visualInspectionId' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost', 'visualInspectionId', visualInspectionId)
            const localVarPath = `/api/v1/mobile/visual-inspections/{visualInspectionId}/add-to-contract`
                .replace(`{${"visualInspectionId"}}`, encodeURIComponent(String(visualInspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visualInspectionAddToContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsVisualInspectionIdDelete: async (nexusOpsTenant: string, visualInspectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsVisualInspectionIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'visualInspectionId' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsVisualInspectionIdDelete', 'visualInspectionId', visualInspectionId)
            const localVarPath = `/api/v1/mobile/visual-inspections/{visualInspectionId}`
                .replace(`{${"visualInspectionId"}}`, encodeURIComponent(String(visualInspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGet: async (nexusOpsTenant: string, visualInspectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'visualInspectionId' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGet', 'visualInspectionId', visualInspectionId)
            const localVarPath = `/api/v1/mobile/visual-inspections/{visualInspectionId}/download/pdf`
                .replace(`{${"visualInspectionId"}}`, encodeURIComponent(String(visualInspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsVisualInspectionIdGet: async (nexusOpsTenant: string, visualInspectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsVisualInspectionIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'visualInspectionId' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsVisualInspectionIdGet', 'visualInspectionId', visualInspectionId)
            const localVarPath = `/api/v1/mobile/visual-inspections/{visualInspectionId}`
                .replace(`{${"visualInspectionId"}}`, encodeURIComponent(String(visualInspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {VisualInspectionUpdateDto} [visualInspectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsVisualInspectionIdPut: async (nexusOpsTenant: string, visualInspectionId: string, visualInspectionUpdateDto?: VisualInspectionUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsVisualInspectionIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'visualInspectionId' is not null or undefined
            assertParamExists('apiV1MobileVisualInspectionsVisualInspectionIdPut', 'visualInspectionId', visualInspectionId)
            const localVarPath = `/api/v1/mobile/visual-inspections/{visualInspectionId}`
                .replace(`{${"visualInspectionId"}}`, encodeURIComponent(String(visualInspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visualInspectionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileVisualInspectionsApi - functional programming interface
 * @export
 */
export const MobileVisualInspectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileVisualInspectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVisualInspectionsByIdsGet(nexusOpsTenant: string, ids?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VisualInspectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVisualInspectionsByIdsGet(nexusOpsTenant, ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVisualInspectionsApi.apiV1MobileVisualInspectionsByIdsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVisualInspectionsCountersGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualInspectionCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVisualInspectionsCountersGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVisualInspectionsApi.apiV1MobileVisualInspectionsCountersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVisualInspectionsCreateMetaGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualInspectionGetCreateMetaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVisualInspectionsCreateMetaGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVisualInspectionsApi.apiV1MobileVisualInspectionsCreateMetaGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VisualInspectionGetPaginatedDto} [visualInspectionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVisualInspectionsGetPost(nexusOpsTenant: string, visualInspectionGetPaginatedDto?: VisualInspectionGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVisualInspectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVisualInspectionsGetPost(nexusOpsTenant, visualInspectionGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVisualInspectionsApi.apiV1MobileVisualInspectionsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VisualInspectionCreateDto} [visualInspectionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVisualInspectionsPost(nexusOpsTenant: string, visualInspectionCreateDto?: VisualInspectionCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualInspectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVisualInspectionsPost(nexusOpsTenant, visualInspectionCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVisualInspectionsApi.apiV1MobileVisualInspectionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VisualInspectionSearchPaginatedDto} [visualInspectionSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVisualInspectionsSearchPost(nexusOpsTenant: string, visualInspectionSearchPaginatedDto?: VisualInspectionSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVisualInspectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVisualInspectionsSearchPost(nexusOpsTenant, visualInspectionSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVisualInspectionsApi.apiV1MobileVisualInspectionsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {VisualInspectionAddToContractDto} [visualInspectionAddToContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost(nexusOpsTenant: string, visualInspectionId: string, visualInspectionAddToContractDto?: VisualInspectionAddToContractDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualInspectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost(nexusOpsTenant, visualInspectionId, visualInspectionAddToContractDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVisualInspectionsApi.apiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVisualInspectionsVisualInspectionIdDelete(nexusOpsTenant: string, visualInspectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVisualInspectionsVisualInspectionIdDelete(nexusOpsTenant, visualInspectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVisualInspectionsApi.apiV1MobileVisualInspectionsVisualInspectionIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGet(nexusOpsTenant: string, visualInspectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGet(nexusOpsTenant, visualInspectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVisualInspectionsApi.apiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVisualInspectionsVisualInspectionIdGet(nexusOpsTenant: string, visualInspectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualInspectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVisualInspectionsVisualInspectionIdGet(nexusOpsTenant, visualInspectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVisualInspectionsApi.apiV1MobileVisualInspectionsVisualInspectionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {VisualInspectionUpdateDto} [visualInspectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileVisualInspectionsVisualInspectionIdPut(nexusOpsTenant: string, visualInspectionId: string, visualInspectionUpdateDto?: VisualInspectionUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualInspectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileVisualInspectionsVisualInspectionIdPut(nexusOpsTenant, visualInspectionId, visualInspectionUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVisualInspectionsApi.apiV1MobileVisualInspectionsVisualInspectionIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileVisualInspectionsApi - factory interface
 * @export
 */
export const MobileVisualInspectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileVisualInspectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsByIdsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsByIdsGet(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsByIdsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<VisualInspectionDto>> {
            return localVarFp.apiV1MobileVisualInspectionsByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsCountersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsCountersGet(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsCountersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VisualInspectionCountersDto> {
            return localVarFp.apiV1MobileVisualInspectionsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsCreateMetaGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsCreateMetaGet(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsCreateMetaGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VisualInspectionGetCreateMetaDto> {
            return localVarFp.apiV1MobileVisualInspectionsCreateMetaGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsGetPost(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVisualInspectionDto> {
            return localVarFp.apiV1MobileVisualInspectionsGetPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsPost(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<VisualInspectionDto> {
            return localVarFp.apiV1MobileVisualInspectionsPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsSearchPost(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVisualInspectionDto> {
            return localVarFp.apiV1MobileVisualInspectionsSearchPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdAddToContractPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdAddToContractPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<VisualInspectionDto> {
            return localVarFp.apiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, requestParameters.visualInspectionAddToContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsVisualInspectionIdDelete(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1MobileVisualInspectionsVisualInspectionIdDelete(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGet(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsVisualInspectionIdGet(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VisualInspectionDto> {
            return localVarFp.apiV1MobileVisualInspectionsVisualInspectionIdGet(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileVisualInspectionsVisualInspectionIdPut(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<VisualInspectionDto> {
            return localVarFp.apiV1MobileVisualInspectionsVisualInspectionIdPut(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, requestParameters.visualInspectionUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MobileVisualInspectionsByIdsGet operation in MobileVisualInspectionsApi.
 * @export
 * @interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsByIdsGetRequest
 */
export interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsByIdsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsByIdsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsByIdsGet
     */
    readonly ids?: Array<string>
}

/**
 * Request parameters for apiV1MobileVisualInspectionsCountersGet operation in MobileVisualInspectionsApi.
 * @export
 * @interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsCountersGetRequest
 */
export interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsCountersGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsCountersGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1MobileVisualInspectionsCreateMetaGet operation in MobileVisualInspectionsApi.
 * @export
 * @interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsCreateMetaGetRequest
 */
export interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsCreateMetaGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsCreateMetaGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1MobileVisualInspectionsGetPost operation in MobileVisualInspectionsApi.
 * @export
 * @interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsGetPostRequest
 */
export interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {VisualInspectionGetPaginatedDto}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsGetPost
     */
    readonly visualInspectionGetPaginatedDto?: VisualInspectionGetPaginatedDto
}

/**
 * Request parameters for apiV1MobileVisualInspectionsPost operation in MobileVisualInspectionsApi.
 * @export
 * @interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsPostRequest
 */
export interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {VisualInspectionCreateDto}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsPost
     */
    readonly visualInspectionCreateDto?: VisualInspectionCreateDto
}

/**
 * Request parameters for apiV1MobileVisualInspectionsSearchPost operation in MobileVisualInspectionsApi.
 * @export
 * @interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsSearchPostRequest
 */
export interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {VisualInspectionSearchPaginatedDto}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsSearchPost
     */
    readonly visualInspectionSearchPaginatedDto?: VisualInspectionSearchPaginatedDto
}

/**
 * Request parameters for apiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost operation in MobileVisualInspectionsApi.
 * @export
 * @interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdAddToContractPostRequest
 */
export interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdAddToContractPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost
     */
    readonly visualInspectionId: string

    /**
     * 
     * @type {VisualInspectionAddToContractDto}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost
     */
    readonly visualInspectionAddToContractDto?: VisualInspectionAddToContractDto
}

/**
 * Request parameters for apiV1MobileVisualInspectionsVisualInspectionIdDelete operation in MobileVisualInspectionsApi.
 * @export
 * @interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDeleteRequest
 */
export interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDelete
     */
    readonly visualInspectionId: string
}

/**
 * Request parameters for apiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGet operation in MobileVisualInspectionsApi.
 * @export
 * @interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGetRequest
 */
export interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGet
     */
    readonly visualInspectionId: string
}

/**
 * Request parameters for apiV1MobileVisualInspectionsVisualInspectionIdGet operation in MobileVisualInspectionsApi.
 * @export
 * @interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdGetRequest
 */
export interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdGet
     */
    readonly visualInspectionId: string
}

/**
 * Request parameters for apiV1MobileVisualInspectionsVisualInspectionIdPut operation in MobileVisualInspectionsApi.
 * @export
 * @interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdPutRequest
 */
export interface MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdPut
     */
    readonly visualInspectionId: string

    /**
     * 
     * @type {VisualInspectionUpdateDto}
     * @memberof MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdPut
     */
    readonly visualInspectionUpdateDto?: VisualInspectionUpdateDto
}

/**
 * MobileVisualInspectionsApi - object-oriented interface
 * @export
 * @class MobileVisualInspectionsApi
 * @extends {BaseAPI}
 */
export class MobileVisualInspectionsApi extends BaseAPI {
    /**
     * 
     * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsByIdsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVisualInspectionsApi
     */
    public apiV1MobileVisualInspectionsByIdsGet(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsByIdsGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVisualInspectionsApiFp(this.configuration).apiV1MobileVisualInspectionsByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVisualInspectionsApi
     */
    public apiV1MobileVisualInspectionsCountersGet(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsCountersGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVisualInspectionsApiFp(this.configuration).apiV1MobileVisualInspectionsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsCreateMetaGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVisualInspectionsApi
     */
    public apiV1MobileVisualInspectionsCreateMetaGet(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsCreateMetaGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVisualInspectionsApiFp(this.configuration).apiV1MobileVisualInspectionsCreateMetaGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVisualInspectionsApi
     */
    public apiV1MobileVisualInspectionsGetPost(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsGetPostRequest, options?: RawAxiosRequestConfig) {
        return MobileVisualInspectionsApiFp(this.configuration).apiV1MobileVisualInspectionsGetPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVisualInspectionsApi
     */
    public apiV1MobileVisualInspectionsPost(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsPostRequest, options?: RawAxiosRequestConfig) {
        return MobileVisualInspectionsApiFp(this.configuration).apiV1MobileVisualInspectionsPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVisualInspectionsApi
     */
    public apiV1MobileVisualInspectionsSearchPost(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return MobileVisualInspectionsApiFp(this.configuration).apiV1MobileVisualInspectionsSearchPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdAddToContractPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVisualInspectionsApi
     */
    public apiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdAddToContractPostRequest, options?: RawAxiosRequestConfig) {
        return MobileVisualInspectionsApiFp(this.configuration).apiV1MobileVisualInspectionsVisualInspectionIdAddToContractPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, requestParameters.visualInspectionAddToContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVisualInspectionsApi
     */
    public apiV1MobileVisualInspectionsVisualInspectionIdDelete(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return MobileVisualInspectionsApiFp(this.configuration).apiV1MobileVisualInspectionsVisualInspectionIdDelete(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVisualInspectionsApi
     */
    public apiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGet(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVisualInspectionsApiFp(this.configuration).apiV1MobileVisualInspectionsVisualInspectionIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVisualInspectionsApi
     */
    public apiV1MobileVisualInspectionsVisualInspectionIdGet(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVisualInspectionsApiFp(this.configuration).apiV1MobileVisualInspectionsVisualInspectionIdGet(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVisualInspectionsApi
     */
    public apiV1MobileVisualInspectionsVisualInspectionIdPut(requestParameters: MobileVisualInspectionsApiApiV1MobileVisualInspectionsVisualInspectionIdPutRequest, options?: RawAxiosRequestConfig) {
        return MobileVisualInspectionsApiFp(this.configuration).apiV1MobileVisualInspectionsVisualInspectionIdPut(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, requestParameters.visualInspectionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NegotiationsApi - axios parameter creator
 * @export
 */
export const NegotiationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [negotiationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsAllowedActionsGet: async (nexusOpsTenant: string, negotiationIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsAllowedActionsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/negotiations/allowed-actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (negotiationIds) {
                localVarQueryParameter['negotiationIds'] = negotiationIds;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsByIdsGet: async (nexusOpsTenant: string, ids?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsByIdsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/negotiations/by-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsChatsChatIdCurrentAllGet: async (nexusOpsTenant: string, chatId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsChatsChatIdCurrentAllGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('apiV1NegotiationsChatsChatIdCurrentAllGet', 'chatId', chatId)
            const localVarPath = `/api/v1/negotiations/chats/{chatId}/current/all`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {NegotiationGetPaginatedDto} [negotiationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsGetPost: async (nexusOpsTenant: string, negotiationGetPaginatedDto?: NegotiationGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/negotiations/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(negotiationGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdAllowedActionsGet: async (nexusOpsTenant: string, negotiationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdAllowedActionsGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdAllowedActionsGet', 'negotiationId', negotiationId)
            const localVarPath = `/api/v1/negotiations/{negotiationId}/allowed-actions`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdDelete: async (nexusOpsTenant: string, negotiationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdDelete', 'negotiationId', negotiationId)
            const localVarPath = `/api/v1/negotiations/{negotiationId}`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdGet: async (nexusOpsTenant: string, negotiationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdGet', 'negotiationId', negotiationId)
            const localVarPath = `/api/v1/negotiations/{negotiationId}`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {CreateNegotiationProposalDto} [createNegotiationProposalDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdProposalsPost: async (nexusOpsTenant: string, negotiationId: string, createNegotiationProposalDto?: CreateNegotiationProposalDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdProposalsPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdProposalsPost', 'negotiationId', negotiationId)
            const localVarPath = `/api/v1/negotiations/{negotiationId}/proposals`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNegotiationProposalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {string} proposalId 
         * @param {UpdateNegotiationProposalDto} [updateNegotiationProposalDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdProposalsProposalIdPut: async (nexusOpsTenant: string, negotiationId: string, proposalId: string, updateNegotiationProposalDto?: UpdateNegotiationProposalDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdProposalsProposalIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdProposalsProposalIdPut', 'negotiationId', negotiationId)
            // verify required parameter 'proposalId' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdProposalsProposalIdPut', 'proposalId', proposalId)
            const localVarPath = `/api/v1/negotiations/{negotiationId}/proposals/{proposalId}`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)))
                .replace(`{${"proposalId"}}`, encodeURIComponent(String(proposalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNegotiationProposalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {UpdateNegotiationDto} [updateNegotiationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdPut: async (nexusOpsTenant: string, negotiationId: string, updateNegotiationDto?: UpdateNegotiationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdPut', 'negotiationId', negotiationId)
            const localVarPath = `/api/v1/negotiations/{negotiationId}`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNegotiationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {AgreeOrDisagreeWithNegotiationProposalResponseDto} [agreeOrDisagreeWithNegotiationProposalResponseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdRespondAgreePost: async (nexusOpsTenant: string, negotiationId: string, agreeOrDisagreeWithNegotiationProposalResponseDto?: AgreeOrDisagreeWithNegotiationProposalResponseDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdRespondAgreePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdRespondAgreePost', 'negotiationId', negotiationId)
            const localVarPath = `/api/v1/negotiations/{negotiationId}/respond/agree`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agreeOrDisagreeWithNegotiationProposalResponseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {AgreeOrDisagreeWithNegotiationProposalResponseDto} [agreeOrDisagreeWithNegotiationProposalResponseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdRespondDisagreePost: async (nexusOpsTenant: string, negotiationId: string, agreeOrDisagreeWithNegotiationProposalResponseDto?: AgreeOrDisagreeWithNegotiationProposalResponseDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdRespondDisagreePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdRespondDisagreePost', 'negotiationId', negotiationId)
            const localVarPath = `/api/v1/negotiations/{negotiationId}/respond/disagree`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agreeOrDisagreeWithNegotiationProposalResponseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {RespondOnNegotiationProposalDto} [respondOnNegotiationProposalDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdRespondPost: async (nexusOpsTenant: string, negotiationId: string, respondOnNegotiationProposalDto?: RespondOnNegotiationProposalDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdRespondPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('apiV1NegotiationsNegotiationIdRespondPost', 'negotiationId', negotiationId)
            const localVarPath = `/api/v1/negotiations/{negotiationId}/respond`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(respondOnNegotiationProposalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get info to respond on many negotiation proposals in one or multiple negotiations.
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RespondOnManyProposalsInfoRequestDto} [respondOnManyProposalsInfoRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsProposalsRespondOnManyInfoGetPost: async (nexusOpsTenant: string, respondOnManyProposalsInfoRequestDto?: RespondOnManyProposalsInfoRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsProposalsRespondOnManyInfoGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/negotiations/proposals/respond-on-many/info/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(respondOnManyProposalsInfoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Respond on many negotiation proposals in one or multiple negotiations.
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RespondOnManyProposalsDto} [respondOnManyProposalsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsProposalsRespondOnManyNegotiationsPost: async (nexusOpsTenant: string, respondOnManyProposalsDto?: RespondOnManyProposalsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NegotiationsProposalsRespondOnManyNegotiationsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/negotiations/proposals/respond-on-many-negotiations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(respondOnManyProposalsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NegotiationsApi - functional programming interface
 * @export
 */
export const NegotiationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NegotiationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [negotiationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsAllowedActionsGet(nexusOpsTenant: string, negotiationIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NegotiationAllowedActionsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsAllowedActionsGet(nexusOpsTenant, negotiationIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsAllowedActionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsByIdsGet(nexusOpsTenant: string, ids?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NegotiationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsByIdsGet(nexusOpsTenant, ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsByIdsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsChatsChatIdCurrentAllGet(nexusOpsTenant: string, chatId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NegotiationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsChatsChatIdCurrentAllGet(nexusOpsTenant, chatId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsChatsChatIdCurrentAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {NegotiationGetPaginatedDto} [negotiationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsGetPost(nexusOpsTenant: string, negotiationGetPaginatedDto?: NegotiationGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfNegotiationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsGetPost(nexusOpsTenant, negotiationGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsNegotiationIdAllowedActionsGet(nexusOpsTenant: string, negotiationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NegotiationAllowedActionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsNegotiationIdAllowedActionsGet(nexusOpsTenant, negotiationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsNegotiationIdAllowedActionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsNegotiationIdDelete(nexusOpsTenant: string, negotiationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsNegotiationIdDelete(nexusOpsTenant, negotiationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsNegotiationIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsNegotiationIdGet(nexusOpsTenant: string, negotiationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NegotiationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsNegotiationIdGet(nexusOpsTenant, negotiationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsNegotiationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {CreateNegotiationProposalDto} [createNegotiationProposalDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsNegotiationIdProposalsPost(nexusOpsTenant: string, negotiationId: string, createNegotiationProposalDto?: CreateNegotiationProposalDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NegotiationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsNegotiationIdProposalsPost(nexusOpsTenant, negotiationId, createNegotiationProposalDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsNegotiationIdProposalsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {string} proposalId 
         * @param {UpdateNegotiationProposalDto} [updateNegotiationProposalDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsNegotiationIdProposalsProposalIdPut(nexusOpsTenant: string, negotiationId: string, proposalId: string, updateNegotiationProposalDto?: UpdateNegotiationProposalDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NegotiationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsNegotiationIdProposalsProposalIdPut(nexusOpsTenant, negotiationId, proposalId, updateNegotiationProposalDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsNegotiationIdProposalsProposalIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {UpdateNegotiationDto} [updateNegotiationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsNegotiationIdPut(nexusOpsTenant: string, negotiationId: string, updateNegotiationDto?: UpdateNegotiationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NegotiationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsNegotiationIdPut(nexusOpsTenant, negotiationId, updateNegotiationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsNegotiationIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {AgreeOrDisagreeWithNegotiationProposalResponseDto} [agreeOrDisagreeWithNegotiationProposalResponseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsNegotiationIdRespondAgreePost(nexusOpsTenant: string, negotiationId: string, agreeOrDisagreeWithNegotiationProposalResponseDto?: AgreeOrDisagreeWithNegotiationProposalResponseDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NegotiationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsNegotiationIdRespondAgreePost(nexusOpsTenant, negotiationId, agreeOrDisagreeWithNegotiationProposalResponseDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsNegotiationIdRespondAgreePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {AgreeOrDisagreeWithNegotiationProposalResponseDto} [agreeOrDisagreeWithNegotiationProposalResponseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsNegotiationIdRespondDisagreePost(nexusOpsTenant: string, negotiationId: string, agreeOrDisagreeWithNegotiationProposalResponseDto?: AgreeOrDisagreeWithNegotiationProposalResponseDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NegotiationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsNegotiationIdRespondDisagreePost(nexusOpsTenant, negotiationId, agreeOrDisagreeWithNegotiationProposalResponseDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsNegotiationIdRespondDisagreePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} negotiationId 
         * @param {RespondOnNegotiationProposalDto} [respondOnNegotiationProposalDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsNegotiationIdRespondPost(nexusOpsTenant: string, negotiationId: string, respondOnNegotiationProposalDto?: RespondOnNegotiationProposalDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NegotiationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsNegotiationIdRespondPost(nexusOpsTenant, negotiationId, respondOnNegotiationProposalDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsNegotiationIdRespondPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get info to respond on many negotiation proposals in one or multiple negotiations.
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RespondOnManyProposalsInfoRequestDto} [respondOnManyProposalsInfoRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsProposalsRespondOnManyInfoGetPost(nexusOpsTenant: string, respondOnManyProposalsInfoRequestDto?: RespondOnManyProposalsInfoRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RespondOnManyProposalsInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsProposalsRespondOnManyInfoGetPost(nexusOpsTenant, respondOnManyProposalsInfoRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsProposalsRespondOnManyInfoGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Respond on many negotiation proposals in one or multiple negotiations.
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RespondOnManyProposalsDto} [respondOnManyProposalsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NegotiationsProposalsRespondOnManyNegotiationsPost(nexusOpsTenant: string, respondOnManyProposalsDto?: RespondOnManyProposalsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NegotiationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NegotiationsProposalsRespondOnManyNegotiationsPost(nexusOpsTenant, respondOnManyProposalsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NegotiationsApi.apiV1NegotiationsProposalsRespondOnManyNegotiationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NegotiationsApi - factory interface
 * @export
 */
export const NegotiationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NegotiationsApiFp(configuration)
    return {
        /**
         * 
         * @param {NegotiationsApiApiV1NegotiationsAllowedActionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsAllowedActionsGet(requestParameters: NegotiationsApiApiV1NegotiationsAllowedActionsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<NegotiationAllowedActionsDto>> {
            return localVarFp.apiV1NegotiationsAllowedActionsGet(requestParameters.nexusOpsTenant, requestParameters.negotiationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NegotiationsApiApiV1NegotiationsByIdsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsByIdsGet(requestParameters: NegotiationsApiApiV1NegotiationsByIdsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<NegotiationDto>> {
            return localVarFp.apiV1NegotiationsByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NegotiationsApiApiV1NegotiationsChatsChatIdCurrentAllGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsChatsChatIdCurrentAllGet(requestParameters: NegotiationsApiApiV1NegotiationsChatsChatIdCurrentAllGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<NegotiationDto>> {
            return localVarFp.apiV1NegotiationsChatsChatIdCurrentAllGet(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NegotiationsApiApiV1NegotiationsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsGetPost(requestParameters: NegotiationsApiApiV1NegotiationsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfNegotiationDto> {
            return localVarFp.apiV1NegotiationsGetPost(requestParameters.nexusOpsTenant, requestParameters.negotiationGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NegotiationsApiApiV1NegotiationsNegotiationIdAllowedActionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdAllowedActionsGet(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdAllowedActionsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<NegotiationAllowedActionsDto> {
            return localVarFp.apiV1NegotiationsNegotiationIdAllowedActionsGet(requestParameters.nexusOpsTenant, requestParameters.negotiationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NegotiationsApiApiV1NegotiationsNegotiationIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdDelete(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1NegotiationsNegotiationIdDelete(requestParameters.nexusOpsTenant, requestParameters.negotiationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NegotiationsApiApiV1NegotiationsNegotiationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdGet(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<NegotiationDto> {
            return localVarFp.apiV1NegotiationsNegotiationIdGet(requestParameters.nexusOpsTenant, requestParameters.negotiationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NegotiationsApiApiV1NegotiationsNegotiationIdProposalsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdProposalsPost(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdProposalsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<NegotiationDto> {
            return localVarFp.apiV1NegotiationsNegotiationIdProposalsPost(requestParameters.nexusOpsTenant, requestParameters.negotiationId, requestParameters.createNegotiationProposalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NegotiationsApiApiV1NegotiationsNegotiationIdProposalsProposalIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdProposalsProposalIdPut(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdProposalsProposalIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<NegotiationDto> {
            return localVarFp.apiV1NegotiationsNegotiationIdProposalsProposalIdPut(requestParameters.nexusOpsTenant, requestParameters.negotiationId, requestParameters.proposalId, requestParameters.updateNegotiationProposalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NegotiationsApiApiV1NegotiationsNegotiationIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdPut(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<NegotiationDto> {
            return localVarFp.apiV1NegotiationsNegotiationIdPut(requestParameters.nexusOpsTenant, requestParameters.negotiationId, requestParameters.updateNegotiationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NegotiationsApiApiV1NegotiationsNegotiationIdRespondAgreePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdRespondAgreePost(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdRespondAgreePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<NegotiationDto> {
            return localVarFp.apiV1NegotiationsNegotiationIdRespondAgreePost(requestParameters.nexusOpsTenant, requestParameters.negotiationId, requestParameters.agreeOrDisagreeWithNegotiationProposalResponseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NegotiationsApiApiV1NegotiationsNegotiationIdRespondDisagreePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdRespondDisagreePost(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdRespondDisagreePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<NegotiationDto> {
            return localVarFp.apiV1NegotiationsNegotiationIdRespondDisagreePost(requestParameters.nexusOpsTenant, requestParameters.negotiationId, requestParameters.agreeOrDisagreeWithNegotiationProposalResponseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NegotiationsApiApiV1NegotiationsNegotiationIdRespondPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsNegotiationIdRespondPost(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdRespondPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<NegotiationDto> {
            return localVarFp.apiV1NegotiationsNegotiationIdRespondPost(requestParameters.nexusOpsTenant, requestParameters.negotiationId, requestParameters.respondOnNegotiationProposalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get info to respond on many negotiation proposals in one or multiple negotiations.
         * @param {NegotiationsApiApiV1NegotiationsProposalsRespondOnManyInfoGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsProposalsRespondOnManyInfoGetPost(requestParameters: NegotiationsApiApiV1NegotiationsProposalsRespondOnManyInfoGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<RespondOnManyProposalsInfoDto> {
            return localVarFp.apiV1NegotiationsProposalsRespondOnManyInfoGetPost(requestParameters.nexusOpsTenant, requestParameters.respondOnManyProposalsInfoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Respond on many negotiation proposals in one or multiple negotiations.
         * @param {NegotiationsApiApiV1NegotiationsProposalsRespondOnManyNegotiationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NegotiationsProposalsRespondOnManyNegotiationsPost(requestParameters: NegotiationsApiApiV1NegotiationsProposalsRespondOnManyNegotiationsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<NegotiationDto>> {
            return localVarFp.apiV1NegotiationsProposalsRespondOnManyNegotiationsPost(requestParameters.nexusOpsTenant, requestParameters.respondOnManyProposalsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1NegotiationsAllowedActionsGet operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsAllowedActionsGetRequest
 */
export interface NegotiationsApiApiV1NegotiationsAllowedActionsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsAllowedActionsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {Array<string>}
     * @memberof NegotiationsApiApiV1NegotiationsAllowedActionsGet
     */
    readonly negotiationIds?: Array<string>
}

/**
 * Request parameters for apiV1NegotiationsByIdsGet operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsByIdsGetRequest
 */
export interface NegotiationsApiApiV1NegotiationsByIdsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsByIdsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {Array<string>}
     * @memberof NegotiationsApiApiV1NegotiationsByIdsGet
     */
    readonly ids?: Array<string>
}

/**
 * Request parameters for apiV1NegotiationsChatsChatIdCurrentAllGet operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsChatsChatIdCurrentAllGetRequest
 */
export interface NegotiationsApiApiV1NegotiationsChatsChatIdCurrentAllGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsChatsChatIdCurrentAllGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsChatsChatIdCurrentAllGet
     */
    readonly chatId: string
}

/**
 * Request parameters for apiV1NegotiationsGetPost operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsGetPostRequest
 */
export interface NegotiationsApiApiV1NegotiationsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {NegotiationGetPaginatedDto}
     * @memberof NegotiationsApiApiV1NegotiationsGetPost
     */
    readonly negotiationGetPaginatedDto?: NegotiationGetPaginatedDto
}

/**
 * Request parameters for apiV1NegotiationsNegotiationIdAllowedActionsGet operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsNegotiationIdAllowedActionsGetRequest
 */
export interface NegotiationsApiApiV1NegotiationsNegotiationIdAllowedActionsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdAllowedActionsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdAllowedActionsGet
     */
    readonly negotiationId: string
}

/**
 * Request parameters for apiV1NegotiationsNegotiationIdDelete operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsNegotiationIdDeleteRequest
 */
export interface NegotiationsApiApiV1NegotiationsNegotiationIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdDelete
     */
    readonly negotiationId: string
}

/**
 * Request parameters for apiV1NegotiationsNegotiationIdGet operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsNegotiationIdGetRequest
 */
export interface NegotiationsApiApiV1NegotiationsNegotiationIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdGet
     */
    readonly negotiationId: string
}

/**
 * Request parameters for apiV1NegotiationsNegotiationIdProposalsPost operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsNegotiationIdProposalsPostRequest
 */
export interface NegotiationsApiApiV1NegotiationsNegotiationIdProposalsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdProposalsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdProposalsPost
     */
    readonly negotiationId: string

    /**
     * 
     * @type {CreateNegotiationProposalDto}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdProposalsPost
     */
    readonly createNegotiationProposalDto?: CreateNegotiationProposalDto
}

/**
 * Request parameters for apiV1NegotiationsNegotiationIdProposalsProposalIdPut operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsNegotiationIdProposalsProposalIdPutRequest
 */
export interface NegotiationsApiApiV1NegotiationsNegotiationIdProposalsProposalIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdProposalsProposalIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdProposalsProposalIdPut
     */
    readonly negotiationId: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdProposalsProposalIdPut
     */
    readonly proposalId: string

    /**
     * 
     * @type {UpdateNegotiationProposalDto}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdProposalsProposalIdPut
     */
    readonly updateNegotiationProposalDto?: UpdateNegotiationProposalDto
}

/**
 * Request parameters for apiV1NegotiationsNegotiationIdPut operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsNegotiationIdPutRequest
 */
export interface NegotiationsApiApiV1NegotiationsNegotiationIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdPut
     */
    readonly negotiationId: string

    /**
     * 
     * @type {UpdateNegotiationDto}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdPut
     */
    readonly updateNegotiationDto?: UpdateNegotiationDto
}

/**
 * Request parameters for apiV1NegotiationsNegotiationIdRespondAgreePost operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsNegotiationIdRespondAgreePostRequest
 */
export interface NegotiationsApiApiV1NegotiationsNegotiationIdRespondAgreePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdRespondAgreePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdRespondAgreePost
     */
    readonly negotiationId: string

    /**
     * 
     * @type {AgreeOrDisagreeWithNegotiationProposalResponseDto}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdRespondAgreePost
     */
    readonly agreeOrDisagreeWithNegotiationProposalResponseDto?: AgreeOrDisagreeWithNegotiationProposalResponseDto
}

/**
 * Request parameters for apiV1NegotiationsNegotiationIdRespondDisagreePost operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsNegotiationIdRespondDisagreePostRequest
 */
export interface NegotiationsApiApiV1NegotiationsNegotiationIdRespondDisagreePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdRespondDisagreePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdRespondDisagreePost
     */
    readonly negotiationId: string

    /**
     * 
     * @type {AgreeOrDisagreeWithNegotiationProposalResponseDto}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdRespondDisagreePost
     */
    readonly agreeOrDisagreeWithNegotiationProposalResponseDto?: AgreeOrDisagreeWithNegotiationProposalResponseDto
}

/**
 * Request parameters for apiV1NegotiationsNegotiationIdRespondPost operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsNegotiationIdRespondPostRequest
 */
export interface NegotiationsApiApiV1NegotiationsNegotiationIdRespondPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdRespondPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdRespondPost
     */
    readonly negotiationId: string

    /**
     * 
     * @type {RespondOnNegotiationProposalDto}
     * @memberof NegotiationsApiApiV1NegotiationsNegotiationIdRespondPost
     */
    readonly respondOnNegotiationProposalDto?: RespondOnNegotiationProposalDto
}

/**
 * Request parameters for apiV1NegotiationsProposalsRespondOnManyInfoGetPost operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsProposalsRespondOnManyInfoGetPostRequest
 */
export interface NegotiationsApiApiV1NegotiationsProposalsRespondOnManyInfoGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsProposalsRespondOnManyInfoGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RespondOnManyProposalsInfoRequestDto}
     * @memberof NegotiationsApiApiV1NegotiationsProposalsRespondOnManyInfoGetPost
     */
    readonly respondOnManyProposalsInfoRequestDto?: RespondOnManyProposalsInfoRequestDto
}

/**
 * Request parameters for apiV1NegotiationsProposalsRespondOnManyNegotiationsPost operation in NegotiationsApi.
 * @export
 * @interface NegotiationsApiApiV1NegotiationsProposalsRespondOnManyNegotiationsPostRequest
 */
export interface NegotiationsApiApiV1NegotiationsProposalsRespondOnManyNegotiationsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NegotiationsApiApiV1NegotiationsProposalsRespondOnManyNegotiationsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RespondOnManyProposalsDto}
     * @memberof NegotiationsApiApiV1NegotiationsProposalsRespondOnManyNegotiationsPost
     */
    readonly respondOnManyProposalsDto?: RespondOnManyProposalsDto
}

/**
 * NegotiationsApi - object-oriented interface
 * @export
 * @class NegotiationsApi
 * @extends {BaseAPI}
 */
export class NegotiationsApi extends BaseAPI {
    /**
     * 
     * @param {NegotiationsApiApiV1NegotiationsAllowedActionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsAllowedActionsGet(requestParameters: NegotiationsApiApiV1NegotiationsAllowedActionsGetRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsAllowedActionsGet(requestParameters.nexusOpsTenant, requestParameters.negotiationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NegotiationsApiApiV1NegotiationsByIdsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsByIdsGet(requestParameters: NegotiationsApiApiV1NegotiationsByIdsGetRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NegotiationsApiApiV1NegotiationsChatsChatIdCurrentAllGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsChatsChatIdCurrentAllGet(requestParameters: NegotiationsApiApiV1NegotiationsChatsChatIdCurrentAllGetRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsChatsChatIdCurrentAllGet(requestParameters.nexusOpsTenant, requestParameters.chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NegotiationsApiApiV1NegotiationsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsGetPost(requestParameters: NegotiationsApiApiV1NegotiationsGetPostRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsGetPost(requestParameters.nexusOpsTenant, requestParameters.negotiationGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NegotiationsApiApiV1NegotiationsNegotiationIdAllowedActionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsNegotiationIdAllowedActionsGet(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdAllowedActionsGetRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsNegotiationIdAllowedActionsGet(requestParameters.nexusOpsTenant, requestParameters.negotiationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NegotiationsApiApiV1NegotiationsNegotiationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsNegotiationIdDelete(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsNegotiationIdDelete(requestParameters.nexusOpsTenant, requestParameters.negotiationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NegotiationsApiApiV1NegotiationsNegotiationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsNegotiationIdGet(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdGetRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsNegotiationIdGet(requestParameters.nexusOpsTenant, requestParameters.negotiationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NegotiationsApiApiV1NegotiationsNegotiationIdProposalsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsNegotiationIdProposalsPost(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdProposalsPostRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsNegotiationIdProposalsPost(requestParameters.nexusOpsTenant, requestParameters.negotiationId, requestParameters.createNegotiationProposalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NegotiationsApiApiV1NegotiationsNegotiationIdProposalsProposalIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsNegotiationIdProposalsProposalIdPut(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdProposalsProposalIdPutRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsNegotiationIdProposalsProposalIdPut(requestParameters.nexusOpsTenant, requestParameters.negotiationId, requestParameters.proposalId, requestParameters.updateNegotiationProposalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NegotiationsApiApiV1NegotiationsNegotiationIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsNegotiationIdPut(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdPutRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsNegotiationIdPut(requestParameters.nexusOpsTenant, requestParameters.negotiationId, requestParameters.updateNegotiationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NegotiationsApiApiV1NegotiationsNegotiationIdRespondAgreePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsNegotiationIdRespondAgreePost(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdRespondAgreePostRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsNegotiationIdRespondAgreePost(requestParameters.nexusOpsTenant, requestParameters.negotiationId, requestParameters.agreeOrDisagreeWithNegotiationProposalResponseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NegotiationsApiApiV1NegotiationsNegotiationIdRespondDisagreePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsNegotiationIdRespondDisagreePost(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdRespondDisagreePostRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsNegotiationIdRespondDisagreePost(requestParameters.nexusOpsTenant, requestParameters.negotiationId, requestParameters.agreeOrDisagreeWithNegotiationProposalResponseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NegotiationsApiApiV1NegotiationsNegotiationIdRespondPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsNegotiationIdRespondPost(requestParameters: NegotiationsApiApiV1NegotiationsNegotiationIdRespondPostRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsNegotiationIdRespondPost(requestParameters.nexusOpsTenant, requestParameters.negotiationId, requestParameters.respondOnNegotiationProposalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get info to respond on many negotiation proposals in one or multiple negotiations.
     * @param {NegotiationsApiApiV1NegotiationsProposalsRespondOnManyInfoGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsProposalsRespondOnManyInfoGetPost(requestParameters: NegotiationsApiApiV1NegotiationsProposalsRespondOnManyInfoGetPostRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsProposalsRespondOnManyInfoGetPost(requestParameters.nexusOpsTenant, requestParameters.respondOnManyProposalsInfoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Respond on many negotiation proposals in one or multiple negotiations.
     * @param {NegotiationsApiApiV1NegotiationsProposalsRespondOnManyNegotiationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public apiV1NegotiationsProposalsRespondOnManyNegotiationsPost(requestParameters: NegotiationsApiApiV1NegotiationsProposalsRespondOnManyNegotiationsPostRequest, options?: RawAxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).apiV1NegotiationsProposalsRespondOnManyNegotiationsPost(requestParameters.nexusOpsTenant, requestParameters.respondOnManyProposalsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsAllDelete: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NotificationsAllDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/notifications/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsAllMarkAsReadPost: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NotificationsAllMarkAsReadPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/notifications/all/mark-as-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} [tenantId] 
         * @param {NotificationImportance} [importance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsCountersGet: async (nexusOpsTenant: string, tenantId?: string, importance?: NotificationImportance, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NotificationsCountersGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/notifications/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (importance !== undefined) {
                localVarQueryParameter['importance'] = importance;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [tenantId] 
         * @param {NotificationImportance} [importance] 
         * @param {boolean} [isRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsGet: async (nexusOpsTenant: string, offset?: number, limit?: number, tenantId?: string, importance?: NotificationImportance, isRead?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NotificationsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (importance !== undefined) {
                localVarQueryParameter['importance'] = importance;
            }

            if (isRead !== undefined) {
                localVarQueryParameter['isRead'] = isRead;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsNotificationIdDelete: async (nexusOpsTenant: string, notificationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NotificationsNotificationIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('apiV1NotificationsNotificationIdDelete', 'notificationId', notificationId)
            const localVarPath = `/api/v1/notifications/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsNotificationIdMarkAsReadPost: async (nexusOpsTenant: string, notificationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NotificationsNotificationIdMarkAsReadPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('apiV1NotificationsNotificationIdMarkAsReadPost', 'notificationId', notificationId)
            const localVarPath = `/api/v1/notifications/{notificationId}/mark-as-read`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {NotificationSendTestDto} [notificationSendTestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsTestSendPost: async (nexusOpsTenant: string, notificationSendTestDto?: NotificationSendTestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1NotificationsTestSendPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/notifications/test/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationSendTestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationsAllDelete(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationsAllDelete(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.apiV1NotificationsAllDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationsAllMarkAsReadPost(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationsAllMarkAsReadPost(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.apiV1NotificationsAllMarkAsReadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} [tenantId] 
         * @param {NotificationImportance} [importance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationsCountersGet(nexusOpsTenant: string, tenantId?: string, importance?: NotificationImportance, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationsCountersGet(nexusOpsTenant, tenantId, importance, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.apiV1NotificationsCountersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [tenantId] 
         * @param {NotificationImportance} [importance] 
         * @param {boolean} [isRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationsGet(nexusOpsTenant: string, offset?: number, limit?: number, tenantId?: string, importance?: NotificationImportance, isRead?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfNotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationsGet(nexusOpsTenant, offset, limit, tenantId, importance, isRead, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.apiV1NotificationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationsNotificationIdDelete(nexusOpsTenant: string, notificationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationsNotificationIdDelete(nexusOpsTenant, notificationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.apiV1NotificationsNotificationIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationsNotificationIdMarkAsReadPost(nexusOpsTenant: string, notificationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationsNotificationIdMarkAsReadPost(nexusOpsTenant, notificationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.apiV1NotificationsNotificationIdMarkAsReadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {NotificationSendTestDto} [notificationSendTestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationsTestSendPost(nexusOpsTenant: string, notificationSendTestDto?: NotificationSendTestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationsTestSendPost(nexusOpsTenant, notificationSendTestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.apiV1NotificationsTestSendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {NotificationsApiApiV1NotificationsAllDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsAllDelete(requestParameters: NotificationsApiApiV1NotificationsAllDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1NotificationsAllDelete(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsApiApiV1NotificationsAllMarkAsReadPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsAllMarkAsReadPost(requestParameters: NotificationsApiApiV1NotificationsAllMarkAsReadPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1NotificationsAllMarkAsReadPost(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsApiApiV1NotificationsCountersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsCountersGet(requestParameters: NotificationsApiApiV1NotificationsCountersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<NotificationCountersDto> {
            return localVarFp.apiV1NotificationsCountersGet(requestParameters.nexusOpsTenant, requestParameters.tenantId, requestParameters.importance, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsApiApiV1NotificationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsGet(requestParameters: NotificationsApiApiV1NotificationsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfNotificationDto> {
            return localVarFp.apiV1NotificationsGet(requestParameters.nexusOpsTenant, requestParameters.offset, requestParameters.limit, requestParameters.tenantId, requestParameters.importance, requestParameters.isRead, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsApiApiV1NotificationsNotificationIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsNotificationIdDelete(requestParameters: NotificationsApiApiV1NotificationsNotificationIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1NotificationsNotificationIdDelete(requestParameters.nexusOpsTenant, requestParameters.notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsApiApiV1NotificationsNotificationIdMarkAsReadPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsNotificationIdMarkAsReadPost(requestParameters: NotificationsApiApiV1NotificationsNotificationIdMarkAsReadPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1NotificationsNotificationIdMarkAsReadPost(requestParameters.nexusOpsTenant, requestParameters.notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsApiApiV1NotificationsTestSendPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsTestSendPost(requestParameters: NotificationsApiApiV1NotificationsTestSendPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1NotificationsTestSendPost(requestParameters.nexusOpsTenant, requestParameters.notificationSendTestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1NotificationsAllDelete operation in NotificationsApi.
 * @export
 * @interface NotificationsApiApiV1NotificationsAllDeleteRequest
 */
export interface NotificationsApiApiV1NotificationsAllDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NotificationsApiApiV1NotificationsAllDelete
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1NotificationsAllMarkAsReadPost operation in NotificationsApi.
 * @export
 * @interface NotificationsApiApiV1NotificationsAllMarkAsReadPostRequest
 */
export interface NotificationsApiApiV1NotificationsAllMarkAsReadPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NotificationsApiApiV1NotificationsAllMarkAsReadPost
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1NotificationsCountersGet operation in NotificationsApi.
 * @export
 * @interface NotificationsApiApiV1NotificationsCountersGetRequest
 */
export interface NotificationsApiApiV1NotificationsCountersGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NotificationsApiApiV1NotificationsCountersGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof NotificationsApiApiV1NotificationsCountersGet
     */
    readonly tenantId?: string

    /**
     * 
     * @type {NotificationImportance}
     * @memberof NotificationsApiApiV1NotificationsCountersGet
     */
    readonly importance?: NotificationImportance
}

/**
 * Request parameters for apiV1NotificationsGet operation in NotificationsApi.
 * @export
 * @interface NotificationsApiApiV1NotificationsGetRequest
 */
export interface NotificationsApiApiV1NotificationsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NotificationsApiApiV1NotificationsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {number}
     * @memberof NotificationsApiApiV1NotificationsGet
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof NotificationsApiApiV1NotificationsGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof NotificationsApiApiV1NotificationsGet
     */
    readonly tenantId?: string

    /**
     * 
     * @type {NotificationImportance}
     * @memberof NotificationsApiApiV1NotificationsGet
     */
    readonly importance?: NotificationImportance

    /**
     * 
     * @type {boolean}
     * @memberof NotificationsApiApiV1NotificationsGet
     */
    readonly isRead?: boolean
}

/**
 * Request parameters for apiV1NotificationsNotificationIdDelete operation in NotificationsApi.
 * @export
 * @interface NotificationsApiApiV1NotificationsNotificationIdDeleteRequest
 */
export interface NotificationsApiApiV1NotificationsNotificationIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NotificationsApiApiV1NotificationsNotificationIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof NotificationsApiApiV1NotificationsNotificationIdDelete
     */
    readonly notificationId: string
}

/**
 * Request parameters for apiV1NotificationsNotificationIdMarkAsReadPost operation in NotificationsApi.
 * @export
 * @interface NotificationsApiApiV1NotificationsNotificationIdMarkAsReadPostRequest
 */
export interface NotificationsApiApiV1NotificationsNotificationIdMarkAsReadPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NotificationsApiApiV1NotificationsNotificationIdMarkAsReadPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof NotificationsApiApiV1NotificationsNotificationIdMarkAsReadPost
     */
    readonly notificationId: string
}

/**
 * Request parameters for apiV1NotificationsTestSendPost operation in NotificationsApi.
 * @export
 * @interface NotificationsApiApiV1NotificationsTestSendPostRequest
 */
export interface NotificationsApiApiV1NotificationsTestSendPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof NotificationsApiApiV1NotificationsTestSendPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {NotificationSendTestDto}
     * @memberof NotificationsApiApiV1NotificationsTestSendPost
     */
    readonly notificationSendTestDto?: NotificationSendTestDto
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @param {NotificationsApiApiV1NotificationsAllDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiV1NotificationsAllDelete(requestParameters: NotificationsApiApiV1NotificationsAllDeleteRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).apiV1NotificationsAllDelete(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsApiApiV1NotificationsAllMarkAsReadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiV1NotificationsAllMarkAsReadPost(requestParameters: NotificationsApiApiV1NotificationsAllMarkAsReadPostRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).apiV1NotificationsAllMarkAsReadPost(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsApiApiV1NotificationsCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiV1NotificationsCountersGet(requestParameters: NotificationsApiApiV1NotificationsCountersGetRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).apiV1NotificationsCountersGet(requestParameters.nexusOpsTenant, requestParameters.tenantId, requestParameters.importance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsApiApiV1NotificationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiV1NotificationsGet(requestParameters: NotificationsApiApiV1NotificationsGetRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).apiV1NotificationsGet(requestParameters.nexusOpsTenant, requestParameters.offset, requestParameters.limit, requestParameters.tenantId, requestParameters.importance, requestParameters.isRead, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsApiApiV1NotificationsNotificationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiV1NotificationsNotificationIdDelete(requestParameters: NotificationsApiApiV1NotificationsNotificationIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).apiV1NotificationsNotificationIdDelete(requestParameters.nexusOpsTenant, requestParameters.notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsApiApiV1NotificationsNotificationIdMarkAsReadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiV1NotificationsNotificationIdMarkAsReadPost(requestParameters: NotificationsApiApiV1NotificationsNotificationIdMarkAsReadPostRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).apiV1NotificationsNotificationIdMarkAsReadPost(requestParameters.nexusOpsTenant, requestParameters.notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsApiApiV1NotificationsTestSendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiV1NotificationsTestSendPost(requestParameters: NotificationsApiApiV1NotificationsTestSendPostRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).apiV1NotificationsTestSendPost(requestParameters.nexusOpsTenant, requestParameters.notificationSendTestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OperationsApi - axios parameter creator
 * @export
 */
export const OperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperationsCountersGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1OperationsCountersGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/operations/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperationsApi - functional programming interface
 * @export
 */
export const OperationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OperationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OperationsCountersGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OperationsCountersGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OperationsApi.apiV1OperationsCountersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OperationsApi - factory interface
 * @export
 */
export const OperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OperationsApiFp(configuration)
    return {
        /**
         * 
         * @param {OperationsApiApiV1OperationsCountersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OperationsCountersGet(requestParameters: OperationsApiApiV1OperationsCountersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<OperationCountersDto> {
            return localVarFp.apiV1OperationsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1OperationsCountersGet operation in OperationsApi.
 * @export
 * @interface OperationsApiApiV1OperationsCountersGetRequest
 */
export interface OperationsApiApiV1OperationsCountersGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof OperationsApiApiV1OperationsCountersGet
     */
    readonly nexusOpsTenant: string
}

/**
 * OperationsApi - object-oriented interface
 * @export
 * @class OperationsApi
 * @extends {BaseAPI}
 */
export class OperationsApi extends BaseAPI {
    /**
     * 
     * @param {OperationsApiApiV1OperationsCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationsApi
     */
    public apiV1OperationsCountersGet(requestParameters: OperationsApiApiV1OperationsCountersGetRequest, options?: RawAxiosRequestConfig) {
        return OperationsApiFp(this.configuration).apiV1OperationsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PartiesApi - axios parameter creator
 * @export
 */
export const PartiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} partyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PartiesPartyIdGet: async (nexusOpsTenant: string, partyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PartiesPartyIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('apiV1PartiesPartyIdGet', 'partyId', partyId)
            const localVarPath = `/api/v1/parties/{partyId}`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PartiesUserMembershipGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PartiesUserMembershipGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/parties/user/membership`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartiesApi - functional programming interface
 * @export
 */
export const PartiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} partyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PartiesPartyIdGet(nexusOpsTenant: string, partyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PartiesPartyIdGet(nexusOpsTenant, partyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartiesApi.apiV1PartiesPartyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PartiesUserMembershipGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPartiesMembershipDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PartiesUserMembershipGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartiesApi.apiV1PartiesUserMembershipGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PartiesApi - factory interface
 * @export
 */
export const PartiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartiesApiFp(configuration)
    return {
        /**
         * 
         * @param {PartiesApiApiV1PartiesPartyIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PartiesPartyIdGet(requestParameters: PartiesApiApiV1PartiesPartyIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PartyDto> {
            return localVarFp.apiV1PartiesPartyIdGet(requestParameters.nexusOpsTenant, requestParameters.partyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartiesApiApiV1PartiesUserMembershipGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PartiesUserMembershipGet(requestParameters: PartiesApiApiV1PartiesUserMembershipGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserPartiesMembershipDto> {
            return localVarFp.apiV1PartiesUserMembershipGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1PartiesPartyIdGet operation in PartiesApi.
 * @export
 * @interface PartiesApiApiV1PartiesPartyIdGetRequest
 */
export interface PartiesApiApiV1PartiesPartyIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PartiesApiApiV1PartiesPartyIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PartiesApiApiV1PartiesPartyIdGet
     */
    readonly partyId: string
}

/**
 * Request parameters for apiV1PartiesUserMembershipGet operation in PartiesApi.
 * @export
 * @interface PartiesApiApiV1PartiesUserMembershipGetRequest
 */
export interface PartiesApiApiV1PartiesUserMembershipGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PartiesApiApiV1PartiesUserMembershipGet
     */
    readonly nexusOpsTenant: string
}

/**
 * PartiesApi - object-oriented interface
 * @export
 * @class PartiesApi
 * @extends {BaseAPI}
 */
export class PartiesApi extends BaseAPI {
    /**
     * 
     * @param {PartiesApiApiV1PartiesPartyIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartiesApi
     */
    public apiV1PartiesPartyIdGet(requestParameters: PartiesApiApiV1PartiesPartyIdGetRequest, options?: RawAxiosRequestConfig) {
        return PartiesApiFp(this.configuration).apiV1PartiesPartyIdGet(requestParameters.nexusOpsTenant, requestParameters.partyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartiesApiApiV1PartiesUserMembershipGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartiesApi
     */
    public apiV1PartiesUserMembershipGet(requestParameters: PartiesApiApiV1PartiesUserMembershipGetRequest, options?: RawAxiosRequestConfig) {
        return PartiesApiFp(this.configuration).apiV1PartiesUserMembershipGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CheckoutManualDto} [checkoutManualDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentsCheckoutManualPost: async (nexusOpsTenant: string, checkoutManualDto?: CheckoutManualDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PaymentsCheckoutManualPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/payments/checkout/manual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkoutManualDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentsPaymentProfileGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PaymentsPaymentProfileGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/payments/payment-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantPaymentProfileInputDto} [tenantPaymentProfileInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentsPaymentProfilePut: async (nexusOpsTenant: string, tenantPaymentProfileInputDto?: TenantPaymentProfileInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PaymentsPaymentProfilePut', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/payments/payment-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantPaymentProfileInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CheckoutManualDto} [checkoutManualDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PaymentsCheckoutManualPost(nexusOpsTenant: string, checkoutManualDto?: CheckoutManualDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PaymentsCheckoutManualPost(nexusOpsTenant, checkoutManualDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.apiV1PaymentsCheckoutManualPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PaymentsPaymentProfileGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantPaymentProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PaymentsPaymentProfileGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.apiV1PaymentsPaymentProfileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantPaymentProfileInputDto} [tenantPaymentProfileInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PaymentsPaymentProfilePut(nexusOpsTenant: string, tenantPaymentProfileInputDto?: TenantPaymentProfileInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantPaymentProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PaymentsPaymentProfilePut(nexusOpsTenant, tenantPaymentProfileInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.apiV1PaymentsPaymentProfilePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @param {PaymentsApiApiV1PaymentsCheckoutManualPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentsCheckoutManualPost(requestParameters: PaymentsApiApiV1PaymentsCheckoutManualPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1PaymentsCheckoutManualPost(requestParameters.nexusOpsTenant, requestParameters.checkoutManualDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaymentsApiApiV1PaymentsPaymentProfileGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentsPaymentProfileGet(requestParameters: PaymentsApiApiV1PaymentsPaymentProfileGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantPaymentProfileDto> {
            return localVarFp.apiV1PaymentsPaymentProfileGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaymentsApiApiV1PaymentsPaymentProfilePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentsPaymentProfilePut(requestParameters: PaymentsApiApiV1PaymentsPaymentProfilePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantPaymentProfileDto> {
            return localVarFp.apiV1PaymentsPaymentProfilePut(requestParameters.nexusOpsTenant, requestParameters.tenantPaymentProfileInputDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1PaymentsCheckoutManualPost operation in PaymentsApi.
 * @export
 * @interface PaymentsApiApiV1PaymentsCheckoutManualPostRequest
 */
export interface PaymentsApiApiV1PaymentsCheckoutManualPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PaymentsApiApiV1PaymentsCheckoutManualPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CheckoutManualDto}
     * @memberof PaymentsApiApiV1PaymentsCheckoutManualPost
     */
    readonly checkoutManualDto?: CheckoutManualDto
}

/**
 * Request parameters for apiV1PaymentsPaymentProfileGet operation in PaymentsApi.
 * @export
 * @interface PaymentsApiApiV1PaymentsPaymentProfileGetRequest
 */
export interface PaymentsApiApiV1PaymentsPaymentProfileGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PaymentsApiApiV1PaymentsPaymentProfileGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1PaymentsPaymentProfilePut operation in PaymentsApi.
 * @export
 * @interface PaymentsApiApiV1PaymentsPaymentProfilePutRequest
 */
export interface PaymentsApiApiV1PaymentsPaymentProfilePutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PaymentsApiApiV1PaymentsPaymentProfilePut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantPaymentProfileInputDto}
     * @memberof PaymentsApiApiV1PaymentsPaymentProfilePut
     */
    readonly tenantPaymentProfileInputDto?: TenantPaymentProfileInputDto
}

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @param {PaymentsApiApiV1PaymentsCheckoutManualPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1PaymentsCheckoutManualPost(requestParameters: PaymentsApiApiV1PaymentsCheckoutManualPostRequest, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1PaymentsCheckoutManualPost(requestParameters.nexusOpsTenant, requestParameters.checkoutManualDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentsApiApiV1PaymentsPaymentProfileGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1PaymentsPaymentProfileGet(requestParameters: PaymentsApiApiV1PaymentsPaymentProfileGetRequest, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1PaymentsPaymentProfileGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentsApiApiV1PaymentsPaymentProfilePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1PaymentsPaymentProfilePut(requestParameters: PaymentsApiApiV1PaymentsPaymentProfilePutRequest, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1PaymentsPaymentProfilePut(requestParameters.nexusOpsTenant, requestParameters.tenantPaymentProfileInputDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Used to check that the service instance is up and running. Returns \"pong\" if so.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PingGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Used to check that the service instance is up and running. Returns \"pong\" if so.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PingGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PingGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PingApi.apiV1PingGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @summary Used to check that the service instance is up and running. Returns \"pong\" if so.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PingGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1PingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @summary Used to check that the service instance is up and running. Returns \"pong\" if so.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public apiV1PingGet(options?: RawAxiosRequestConfig) {
        return PingApiFp(this.configuration).apiV1PingGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PoolItemsApi - axios parameter creator
 * @export
 */
export const PoolItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolItemsPoolItemIdGet: async (nexusOpsTenant: string, poolItemId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolItemsPoolItemIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolItemId' is not null or undefined
            assertParamExists('apiV1PoolItemsPoolItemIdGet', 'poolItemId', poolItemId)
            const localVarPath = `/api/v1/pool-items/{poolItemId}`
                .replace(`{${"poolItemId"}}`, encodeURIComponent(String(poolItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsAllDelete: async (nexusOpsTenant: string, poolId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsAllDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsAllDelete', 'poolId', poolId)
            const localVarPath = `/api/v1/pools/{poolId}/items/all`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {PoolItemEntityType} [entityType] 
         * @param {Array<string>} [includeIds] 
         * @param {Array<string>} [excludeIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsCandidatesGet: async (nexusOpsTenant: string, poolId: string, offset?: number, limit?: number, search?: string, entityType?: PoolItemEntityType, includeIds?: Array<string>, excludeIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsCandidatesGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsCandidatesGet', 'poolId', poolId)
            const localVarPath = `/api/v1/pools/{poolId}/items/candidates`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (includeIds) {
                localVarQueryParameter['includeIds'] = includeIds;
            }

            if (excludeIds) {
                localVarQueryParameter['excludeIds'] = excludeIds;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {PoolItemCreateManyDto} [poolItemCreateManyDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsCreateManyPost: async (nexusOpsTenant: string, poolId: string, poolItemCreateManyDto?: PoolItemCreateManyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsCreateManyPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsCreateManyPost', 'poolId', poolId)
            const localVarPath = `/api/v1/pools/{poolId}/items/create-many`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poolItemCreateManyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {PoolItemGetPaginatedDto} [poolItemGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsGetPost: async (nexusOpsTenant: string, poolId: string, poolItemGetPaginatedDto?: PoolItemGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsGetPost', 'poolId', poolId)
            const localVarPath = `/api/v1/pools/{poolId}/items/get`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poolItemGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {string} poolItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsPoolItemIdDelete: async (nexusOpsTenant: string, poolId: string, poolItemId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPoolItemIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPoolItemIdDelete', 'poolId', poolId)
            // verify required parameter 'poolItemId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPoolItemIdDelete', 'poolItemId', poolItemId)
            const localVarPath = `/api/v1/pools/{poolId}/items/{poolItemId}`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)))
                .replace(`{${"poolItemId"}}`, encodeURIComponent(String(poolItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {string} poolItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsPoolItemIdGet: async (nexusOpsTenant: string, poolId: string, poolItemId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPoolItemIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPoolItemIdGet', 'poolId', poolId)
            // verify required parameter 'poolItemId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPoolItemIdGet', 'poolItemId', poolItemId)
            const localVarPath = `/api/v1/pools/{poolId}/items/{poolItemId}`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)))
                .replace(`{${"poolItemId"}}`, encodeURIComponent(String(poolItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {string} poolItemId 
         * @param {PoolItemUpdateDto} [poolItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsPoolItemIdPut: async (nexusOpsTenant: string, poolId: string, poolItemId: string, poolItemUpdateDto?: PoolItemUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPoolItemIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPoolItemIdPut', 'poolId', poolId)
            // verify required parameter 'poolItemId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPoolItemIdPut', 'poolItemId', poolItemId)
            const localVarPath = `/api/v1/pools/{poolId}/items/{poolItemId}`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)))
                .replace(`{${"poolItemId"}}`, encodeURIComponent(String(poolItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poolItemUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {string} poolItemId 
         * @param {PoolItemUpdateStatusDto} [poolItemUpdateStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsPoolItemIdStatusPut: async (nexusOpsTenant: string, poolId: string, poolItemId: string, poolItemUpdateStatusDto?: PoolItemUpdateStatusDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPoolItemIdStatusPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPoolItemIdStatusPut', 'poolId', poolId)
            // verify required parameter 'poolItemId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPoolItemIdStatusPut', 'poolItemId', poolItemId)
            const localVarPath = `/api/v1/pools/{poolId}/items/{poolItemId}/status`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)))
                .replace(`{${"poolItemId"}}`, encodeURIComponent(String(poolItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poolItemUpdateStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {PoolItemCreateDto} [poolItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsPost: async (nexusOpsTenant: string, poolId: string, poolItemCreateDto?: PoolItemCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsPost', 'poolId', poolId)
            const localVarPath = `/api/v1/pools/{poolId}/items`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poolItemCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {PoolItemSearchPaginatedDto} [poolItemSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsSearchPost: async (nexusOpsTenant: string, poolId: string, poolItemSearchPaginatedDto?: PoolItemSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdItemsSearchPost', 'poolId', poolId)
            const localVarPath = `/api/v1/pools/{poolId}/items/search`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poolItemSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoolItemsApi - functional programming interface
 * @export
 */
export const PoolItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoolItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolItemsPoolItemIdGet(nexusOpsTenant: string, poolItemId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolItemsPoolItemIdGet(nexusOpsTenant, poolItemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolItemsApi.apiV1PoolItemsPoolItemIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdItemsAllDelete(nexusOpsTenant: string, poolId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdItemsAllDelete(nexusOpsTenant, poolId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolItemsApi.apiV1PoolsPoolIdItemsAllDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {PoolItemEntityType} [entityType] 
         * @param {Array<string>} [includeIds] 
         * @param {Array<string>} [excludeIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdItemsCandidatesGet(nexusOpsTenant: string, poolId: string, offset?: number, limit?: number, search?: string, entityType?: PoolItemEntityType, includeIds?: Array<string>, excludeIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfPoolItemCandidateEntityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdItemsCandidatesGet(nexusOpsTenant, poolId, offset, limit, search, entityType, includeIds, excludeIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolItemsApi.apiV1PoolsPoolIdItemsCandidatesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {PoolItemCreateManyDto} [poolItemCreateManyDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdItemsCreateManyPost(nexusOpsTenant: string, poolId: string, poolItemCreateManyDto?: PoolItemCreateManyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PoolItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdItemsCreateManyPost(nexusOpsTenant, poolId, poolItemCreateManyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolItemsApi.apiV1PoolsPoolIdItemsCreateManyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {PoolItemGetPaginatedDto} [poolItemGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdItemsGetPost(nexusOpsTenant: string, poolId: string, poolItemGetPaginatedDto?: PoolItemGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfPoolItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdItemsGetPost(nexusOpsTenant, poolId, poolItemGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolItemsApi.apiV1PoolsPoolIdItemsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {string} poolItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdItemsPoolItemIdDelete(nexusOpsTenant: string, poolId: string, poolItemId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdItemsPoolItemIdDelete(nexusOpsTenant, poolId, poolItemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolItemsApi.apiV1PoolsPoolIdItemsPoolItemIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {string} poolItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdItemsPoolItemIdGet(nexusOpsTenant: string, poolId: string, poolItemId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdItemsPoolItemIdGet(nexusOpsTenant, poolId, poolItemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolItemsApi.apiV1PoolsPoolIdItemsPoolItemIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {string} poolItemId 
         * @param {PoolItemUpdateDto} [poolItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdItemsPoolItemIdPut(nexusOpsTenant: string, poolId: string, poolItemId: string, poolItemUpdateDto?: PoolItemUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdItemsPoolItemIdPut(nexusOpsTenant, poolId, poolItemId, poolItemUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolItemsApi.apiV1PoolsPoolIdItemsPoolItemIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {string} poolItemId 
         * @param {PoolItemUpdateStatusDto} [poolItemUpdateStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdItemsPoolItemIdStatusPut(nexusOpsTenant: string, poolId: string, poolItemId: string, poolItemUpdateStatusDto?: PoolItemUpdateStatusDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdItemsPoolItemIdStatusPut(nexusOpsTenant, poolId, poolItemId, poolItemUpdateStatusDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolItemsApi.apiV1PoolsPoolIdItemsPoolItemIdStatusPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {PoolItemCreateDto} [poolItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdItemsPost(nexusOpsTenant: string, poolId: string, poolItemCreateDto?: PoolItemCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdItemsPost(nexusOpsTenant, poolId, poolItemCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolItemsApi.apiV1PoolsPoolIdItemsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {PoolItemSearchPaginatedDto} [poolItemSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdItemsSearchPost(nexusOpsTenant: string, poolId: string, poolItemSearchPaginatedDto?: PoolItemSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfPoolItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdItemsSearchPost(nexusOpsTenant, poolId, poolItemSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolItemsApi.apiV1PoolsPoolIdItemsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PoolItemsApi - factory interface
 * @export
 */
export const PoolItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoolItemsApiFp(configuration)
    return {
        /**
         * 
         * @param {PoolItemsApiApiV1PoolItemsPoolItemIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolItemsPoolItemIdGet(requestParameters: PoolItemsApiApiV1PoolItemsPoolItemIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PoolItemDto> {
            return localVarFp.apiV1PoolItemsPoolItemIdGet(requestParameters.nexusOpsTenant, requestParameters.poolItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolItemsApiApiV1PoolsPoolIdItemsAllDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsAllDelete(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsAllDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1PoolsPoolIdItemsAllDelete(requestParameters.nexusOpsTenant, requestParameters.poolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsCandidatesGet(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfPoolItemCandidateEntityDto> {
            return localVarFp.apiV1PoolsPoolIdItemsCandidatesGet(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.entityType, requestParameters.includeIds, requestParameters.excludeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolItemsApiApiV1PoolsPoolIdItemsCreateManyPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsCreateManyPost(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsCreateManyPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PoolItemDto>> {
            return localVarFp.apiV1PoolsPoolIdItemsCreateManyPost(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemCreateManyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolItemsApiApiV1PoolsPoolIdItemsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsGetPost(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfPoolItemDto> {
            return localVarFp.apiV1PoolsPoolIdItemsGetPost(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsPoolItemIdDelete(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1PoolsPoolIdItemsPoolItemIdDelete(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsPoolItemIdGet(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PoolItemDto> {
            return localVarFp.apiV1PoolsPoolIdItemsPoolItemIdGet(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsPoolItemIdPut(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<PoolItemDto> {
            return localVarFp.apiV1PoolsPoolIdItemsPoolItemIdPut(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemId, requestParameters.poolItemUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdStatusPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsPoolItemIdStatusPut(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdStatusPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<PoolItemDto> {
            return localVarFp.apiV1PoolsPoolIdItemsPoolItemIdStatusPut(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemId, requestParameters.poolItemUpdateStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolItemsApiApiV1PoolsPoolIdItemsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsPost(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PoolItemDto> {
            return localVarFp.apiV1PoolsPoolIdItemsPost(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolItemsApiApiV1PoolsPoolIdItemsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdItemsSearchPost(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfPoolItemDto> {
            return localVarFp.apiV1PoolsPoolIdItemsSearchPost(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1PoolItemsPoolItemIdGet operation in PoolItemsApi.
 * @export
 * @interface PoolItemsApiApiV1PoolItemsPoolItemIdGetRequest
 */
export interface PoolItemsApiApiV1PoolItemsPoolItemIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolItemsPoolItemIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolItemsPoolItemIdGet
     */
    readonly poolItemId: string
}

/**
 * Request parameters for apiV1PoolsPoolIdItemsAllDelete operation in PoolItemsApi.
 * @export
 * @interface PoolItemsApiApiV1PoolsPoolIdItemsAllDeleteRequest
 */
export interface PoolItemsApiApiV1PoolsPoolIdItemsAllDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsAllDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsAllDelete
     */
    readonly poolId: string
}

/**
 * Request parameters for apiV1PoolsPoolIdItemsCandidatesGet operation in PoolItemsApi.
 * @export
 * @interface PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGetRequest
 */
export interface PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGet
     */
    readonly poolId: string

    /**
     * 
     * @type {number}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGet
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGet
     */
    readonly search?: string

    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGet
     */
    readonly entityType?: PoolItemEntityType

    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGet
     */
    readonly includeIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGet
     */
    readonly excludeIds?: Array<string>
}

/**
 * Request parameters for apiV1PoolsPoolIdItemsCreateManyPost operation in PoolItemsApi.
 * @export
 * @interface PoolItemsApiApiV1PoolsPoolIdItemsCreateManyPostRequest
 */
export interface PoolItemsApiApiV1PoolsPoolIdItemsCreateManyPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsCreateManyPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsCreateManyPost
     */
    readonly poolId: string

    /**
     * 
     * @type {PoolItemCreateManyDto}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsCreateManyPost
     */
    readonly poolItemCreateManyDto?: PoolItemCreateManyDto
}

/**
 * Request parameters for apiV1PoolsPoolIdItemsGetPost operation in PoolItemsApi.
 * @export
 * @interface PoolItemsApiApiV1PoolsPoolIdItemsGetPostRequest
 */
export interface PoolItemsApiApiV1PoolsPoolIdItemsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsGetPost
     */
    readonly poolId: string

    /**
     * 
     * @type {PoolItemGetPaginatedDto}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsGetPost
     */
    readonly poolItemGetPaginatedDto?: PoolItemGetPaginatedDto
}

/**
 * Request parameters for apiV1PoolsPoolIdItemsPoolItemIdDelete operation in PoolItemsApi.
 * @export
 * @interface PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdDeleteRequest
 */
export interface PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdDelete
     */
    readonly poolId: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdDelete
     */
    readonly poolItemId: string
}

/**
 * Request parameters for apiV1PoolsPoolIdItemsPoolItemIdGet operation in PoolItemsApi.
 * @export
 * @interface PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdGetRequest
 */
export interface PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdGet
     */
    readonly poolId: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdGet
     */
    readonly poolItemId: string
}

/**
 * Request parameters for apiV1PoolsPoolIdItemsPoolItemIdPut operation in PoolItemsApi.
 * @export
 * @interface PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdPutRequest
 */
export interface PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdPut
     */
    readonly poolId: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdPut
     */
    readonly poolItemId: string

    /**
     * 
     * @type {PoolItemUpdateDto}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdPut
     */
    readonly poolItemUpdateDto?: PoolItemUpdateDto
}

/**
 * Request parameters for apiV1PoolsPoolIdItemsPoolItemIdStatusPut operation in PoolItemsApi.
 * @export
 * @interface PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdStatusPutRequest
 */
export interface PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdStatusPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdStatusPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdStatusPut
     */
    readonly poolId: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdStatusPut
     */
    readonly poolItemId: string

    /**
     * 
     * @type {PoolItemUpdateStatusDto}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdStatusPut
     */
    readonly poolItemUpdateStatusDto?: PoolItemUpdateStatusDto
}

/**
 * Request parameters for apiV1PoolsPoolIdItemsPost operation in PoolItemsApi.
 * @export
 * @interface PoolItemsApiApiV1PoolsPoolIdItemsPostRequest
 */
export interface PoolItemsApiApiV1PoolsPoolIdItemsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPost
     */
    readonly poolId: string

    /**
     * 
     * @type {PoolItemCreateDto}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsPost
     */
    readonly poolItemCreateDto?: PoolItemCreateDto
}

/**
 * Request parameters for apiV1PoolsPoolIdItemsSearchPost operation in PoolItemsApi.
 * @export
 * @interface PoolItemsApiApiV1PoolsPoolIdItemsSearchPostRequest
 */
export interface PoolItemsApiApiV1PoolsPoolIdItemsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsSearchPost
     */
    readonly poolId: string

    /**
     * 
     * @type {PoolItemSearchPaginatedDto}
     * @memberof PoolItemsApiApiV1PoolsPoolIdItemsSearchPost
     */
    readonly poolItemSearchPaginatedDto?: PoolItemSearchPaginatedDto
}

/**
 * PoolItemsApi - object-oriented interface
 * @export
 * @class PoolItemsApi
 * @extends {BaseAPI}
 */
export class PoolItemsApi extends BaseAPI {
    /**
     * 
     * @param {PoolItemsApiApiV1PoolItemsPoolItemIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolItemsApi
     */
    public apiV1PoolItemsPoolItemIdGet(requestParameters: PoolItemsApiApiV1PoolItemsPoolItemIdGetRequest, options?: RawAxiosRequestConfig) {
        return PoolItemsApiFp(this.configuration).apiV1PoolItemsPoolItemIdGet(requestParameters.nexusOpsTenant, requestParameters.poolItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolItemsApiApiV1PoolsPoolIdItemsAllDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolItemsApi
     */
    public apiV1PoolsPoolIdItemsAllDelete(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsAllDeleteRequest, options?: RawAxiosRequestConfig) {
        return PoolItemsApiFp(this.configuration).apiV1PoolsPoolIdItemsAllDelete(requestParameters.nexusOpsTenant, requestParameters.poolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolItemsApi
     */
    public apiV1PoolsPoolIdItemsCandidatesGet(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsCandidatesGetRequest, options?: RawAxiosRequestConfig) {
        return PoolItemsApiFp(this.configuration).apiV1PoolsPoolIdItemsCandidatesGet(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.entityType, requestParameters.includeIds, requestParameters.excludeIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolItemsApiApiV1PoolsPoolIdItemsCreateManyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolItemsApi
     */
    public apiV1PoolsPoolIdItemsCreateManyPost(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsCreateManyPostRequest, options?: RawAxiosRequestConfig) {
        return PoolItemsApiFp(this.configuration).apiV1PoolsPoolIdItemsCreateManyPost(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemCreateManyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolItemsApiApiV1PoolsPoolIdItemsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolItemsApi
     */
    public apiV1PoolsPoolIdItemsGetPost(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsGetPostRequest, options?: RawAxiosRequestConfig) {
        return PoolItemsApiFp(this.configuration).apiV1PoolsPoolIdItemsGetPost(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolItemsApi
     */
    public apiV1PoolsPoolIdItemsPoolItemIdDelete(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return PoolItemsApiFp(this.configuration).apiV1PoolsPoolIdItemsPoolItemIdDelete(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolItemsApi
     */
    public apiV1PoolsPoolIdItemsPoolItemIdGet(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdGetRequest, options?: RawAxiosRequestConfig) {
        return PoolItemsApiFp(this.configuration).apiV1PoolsPoolIdItemsPoolItemIdGet(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolItemsApi
     */
    public apiV1PoolsPoolIdItemsPoolItemIdPut(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdPutRequest, options?: RawAxiosRequestConfig) {
        return PoolItemsApiFp(this.configuration).apiV1PoolsPoolIdItemsPoolItemIdPut(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemId, requestParameters.poolItemUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdStatusPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolItemsApi
     */
    public apiV1PoolsPoolIdItemsPoolItemIdStatusPut(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsPoolItemIdStatusPutRequest, options?: RawAxiosRequestConfig) {
        return PoolItemsApiFp(this.configuration).apiV1PoolsPoolIdItemsPoolItemIdStatusPut(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemId, requestParameters.poolItemUpdateStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolItemsApiApiV1PoolsPoolIdItemsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolItemsApi
     */
    public apiV1PoolsPoolIdItemsPost(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsPostRequest, options?: RawAxiosRequestConfig) {
        return PoolItemsApiFp(this.configuration).apiV1PoolsPoolIdItemsPost(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolItemsApiApiV1PoolsPoolIdItemsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolItemsApi
     */
    public apiV1PoolsPoolIdItemsSearchPost(requestParameters: PoolItemsApiApiV1PoolsPoolIdItemsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return PoolItemsApiFp(this.configuration).apiV1PoolsPoolIdItemsSearchPost(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolItemSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PoolsApi - axios parameter creator
 * @export
 */
export const PoolsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {PoolItemEntityType} [entityType] 
         * @param {string} [entityId] 
         * @param {boolean} [isIncludeAssetPools] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsForEntityAllGet: async (nexusOpsTenant: string, entityType?: PoolItemEntityType, entityId?: string, isIncludeAssetPools?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsForEntityAllGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/pools/for-entity/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (isIncludeAssetPools !== undefined) {
                localVarQueryParameter['isIncludeAssetPools'] = isIncludeAssetPools;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {PoolItemEntityType} [entityType] 
         * @param {string} [entityId] 
         * @param {boolean} [isIncludeAssetPools] 
         * @param {string} [search] 
         * @param {Array<string>} [ids] 
         * @param {Array<string>} [includeIds] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [sortBy] Comma-separated list of fields to sort by.
         * @param {string} [sortOrder] Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsForEntityGet: async (nexusOpsTenant: string, offset?: number, limit?: number, entityType?: PoolItemEntityType, entityId?: string, isIncludeAssetPools?: boolean, search?: string, ids?: Array<string>, includeIds?: Array<string>, from?: string, to?: string, sortBy?: string, sortOrder?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsForEntityGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/pools/for-entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (isIncludeAssetPools !== undefined) {
                localVarQueryParameter['isIncludeAssetPools'] = isIncludeAssetPools;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (includeIds) {
                localVarQueryParameter['includeIds'] = includeIds;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntitiesPoolsGetRequestDto} [entitiesPoolsGetRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsGetForEntitiesPost: async (nexusOpsTenant: string, entitiesPoolsGetRequestDto?: EntitiesPoolsGetRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsGetForEntitiesPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/pools/get/for-entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entitiesPoolsGetRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {PoolGetPaginatedDto} [poolGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsGetPost: async (nexusOpsTenant: string, poolGetPaginatedDto?: PoolGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/pools/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poolGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [poolIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsIntersectionGet: async (nexusOpsTenant: string, poolIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsIntersectionGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/pools/intersection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (poolIds) {
                localVarQueryParameter['poolIds'] = poolIds;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {PoolItemStatus} [status] 
         * @param {PoolItemEntityType} [entityType] 
         * @param {string} [dateRangeFrom] 
         * @param {string} [dateRangeTo] 
         * @param {boolean} [isIncludeItemIds] 
         * @param {number} [itemIdsLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdAvailabilityGet: async (nexusOpsTenant: string, poolId: string, status?: PoolItemStatus, entityType?: PoolItemEntityType, dateRangeFrom?: string, dateRangeTo?: string, isIncludeItemIds?: boolean, itemIdsLimit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdAvailabilityGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdAvailabilityGet', 'poolId', poolId)
            const localVarPath = `/api/v1/pools/{poolId}/availability`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (dateRangeFrom !== undefined) {
                localVarQueryParameter['dateRange.from'] = dateRangeFrom;
            }

            if (dateRangeTo !== undefined) {
                localVarQueryParameter['dateRange.to'] = dateRangeTo;
            }

            if (isIncludeItemIds !== undefined) {
                localVarQueryParameter['isIncludeItemIds'] = isIncludeItemIds;
            }

            if (itemIdsLimit !== undefined) {
                localVarQueryParameter['itemIdsLimit'] = itemIdsLimit;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdDelete: async (nexusOpsTenant: string, poolId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdDelete', 'poolId', poolId)
            const localVarPath = `/api/v1/pools/{poolId}`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdGet: async (nexusOpsTenant: string, poolId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdGet', 'poolId', poolId)
            const localVarPath = `/api/v1/pools/{poolId}`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {PoolUpdateDto} [poolUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdPut: async (nexusOpsTenant: string, poolId: string, poolUpdateDto?: PoolUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'poolId' is not null or undefined
            assertParamExists('apiV1PoolsPoolIdPut', 'poolId', poolId)
            const localVarPath = `/api/v1/pools/{poolId}`
                .replace(`{${"poolId"}}`, encodeURIComponent(String(poolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poolUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {PoolCreateDto} [poolCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPost: async (nexusOpsTenant: string, poolCreateDto?: PoolCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/pools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poolCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {PoolSearchPaginatedDto} [poolSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsSearchPost: async (nexusOpsTenant: string, poolSearchPaginatedDto?: PoolSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1PoolsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/pools/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(poolSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoolsApi - functional programming interface
 * @export
 */
export const PoolsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoolsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {PoolItemEntityType} [entityType] 
         * @param {string} [entityId] 
         * @param {boolean} [isIncludeAssetPools] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsForEntityAllGet(nexusOpsTenant: string, entityType?: PoolItemEntityType, entityId?: string, isIncludeAssetPools?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityPoolsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsForEntityAllGet(nexusOpsTenant, entityType, entityId, isIncludeAssetPools, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolsApi.apiV1PoolsForEntityAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {PoolItemEntityType} [entityType] 
         * @param {string} [entityId] 
         * @param {boolean} [isIncludeAssetPools] 
         * @param {string} [search] 
         * @param {Array<string>} [ids] 
         * @param {Array<string>} [includeIds] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [sortBy] Comma-separated list of fields to sort by.
         * @param {string} [sortOrder] Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsForEntityGet(nexusOpsTenant: string, offset?: number, limit?: number, entityType?: PoolItemEntityType, entityId?: string, isIncludeAssetPools?: boolean, search?: string, ids?: Array<string>, includeIds?: Array<string>, from?: string, to?: string, sortBy?: string, sortOrder?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfPoolDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsForEntityGet(nexusOpsTenant, offset, limit, entityType, entityId, isIncludeAssetPools, search, ids, includeIds, from, to, sortBy, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolsApi.apiV1PoolsForEntityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntitiesPoolsGetRequestDto} [entitiesPoolsGetRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsGetForEntitiesPost(nexusOpsTenant: string, entitiesPoolsGetRequestDto?: EntitiesPoolsGetRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityPoolsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsGetForEntitiesPost(nexusOpsTenant, entitiesPoolsGetRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolsApi.apiV1PoolsGetForEntitiesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {PoolGetPaginatedDto} [poolGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsGetPost(nexusOpsTenant: string, poolGetPaginatedDto?: PoolGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfPoolDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsGetPost(nexusOpsTenant, poolGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolsApi.apiV1PoolsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [poolIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsIntersectionGet(nexusOpsTenant: string, poolIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolsIntersectionResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsIntersectionGet(nexusOpsTenant, poolIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolsApi.apiV1PoolsIntersectionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {PoolItemStatus} [status] 
         * @param {PoolItemEntityType} [entityType] 
         * @param {string} [dateRangeFrom] 
         * @param {string} [dateRangeTo] 
         * @param {boolean} [isIncludeItemIds] 
         * @param {number} [itemIdsLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdAvailabilityGet(nexusOpsTenant: string, poolId: string, status?: PoolItemStatus, entityType?: PoolItemEntityType, dateRangeFrom?: string, dateRangeTo?: string, isIncludeItemIds?: boolean, itemIdsLimit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolAvailabilityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdAvailabilityGet(nexusOpsTenant, poolId, status, entityType, dateRangeFrom, dateRangeTo, isIncludeItemIds, itemIdsLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolsApi.apiV1PoolsPoolIdAvailabilityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdDelete(nexusOpsTenant: string, poolId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdDelete(nexusOpsTenant, poolId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolsApi.apiV1PoolsPoolIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdGet(nexusOpsTenant: string, poolId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdGet(nexusOpsTenant, poolId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolsApi.apiV1PoolsPoolIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} poolId 
         * @param {PoolUpdateDto} [poolUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPoolIdPut(nexusOpsTenant: string, poolId: string, poolUpdateDto?: PoolUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPoolIdPut(nexusOpsTenant, poolId, poolUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolsApi.apiV1PoolsPoolIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {PoolCreateDto} [poolCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsPost(nexusOpsTenant: string, poolCreateDto?: PoolCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsPost(nexusOpsTenant, poolCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolsApi.apiV1PoolsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {PoolSearchPaginatedDto} [poolSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoolsSearchPost(nexusOpsTenant: string, poolSearchPaginatedDto?: PoolSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfPoolDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoolsSearchPost(nexusOpsTenant, poolSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoolsApi.apiV1PoolsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PoolsApi - factory interface
 * @export
 */
export const PoolsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoolsApiFp(configuration)
    return {
        /**
         * 
         * @param {PoolsApiApiV1PoolsForEntityAllGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsForEntityAllGet(requestParameters: PoolsApiApiV1PoolsForEntityAllGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityPoolsDto> {
            return localVarFp.apiV1PoolsForEntityAllGet(requestParameters.nexusOpsTenant, requestParameters.entityType, requestParameters.entityId, requestParameters.isIncludeAssetPools, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolsApiApiV1PoolsForEntityGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsForEntityGet(requestParameters: PoolsApiApiV1PoolsForEntityGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfPoolDto> {
            return localVarFp.apiV1PoolsForEntityGet(requestParameters.nexusOpsTenant, requestParameters.offset, requestParameters.limit, requestParameters.entityType, requestParameters.entityId, requestParameters.isIncludeAssetPools, requestParameters.search, requestParameters.ids, requestParameters.includeIds, requestParameters.from, requestParameters.to, requestParameters.sortBy, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolsApiApiV1PoolsGetForEntitiesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsGetForEntitiesPost(requestParameters: PoolsApiApiV1PoolsGetForEntitiesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityPoolsDto>> {
            return localVarFp.apiV1PoolsGetForEntitiesPost(requestParameters.nexusOpsTenant, requestParameters.entitiesPoolsGetRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolsApiApiV1PoolsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsGetPost(requestParameters: PoolsApiApiV1PoolsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfPoolDto> {
            return localVarFp.apiV1PoolsGetPost(requestParameters.nexusOpsTenant, requestParameters.poolGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolsApiApiV1PoolsIntersectionGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsIntersectionGet(requestParameters: PoolsApiApiV1PoolsIntersectionGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PoolsIntersectionResultDto> {
            return localVarFp.apiV1PoolsIntersectionGet(requestParameters.nexusOpsTenant, requestParameters.poolIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolsApiApiV1PoolsPoolIdAvailabilityGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdAvailabilityGet(requestParameters: PoolsApiApiV1PoolsPoolIdAvailabilityGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PoolAvailabilityDto> {
            return localVarFp.apiV1PoolsPoolIdAvailabilityGet(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.status, requestParameters.entityType, requestParameters.dateRangeFrom, requestParameters.dateRangeTo, requestParameters.isIncludeItemIds, requestParameters.itemIdsLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolsApiApiV1PoolsPoolIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdDelete(requestParameters: PoolsApiApiV1PoolsPoolIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1PoolsPoolIdDelete(requestParameters.nexusOpsTenant, requestParameters.poolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolsApiApiV1PoolsPoolIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdGet(requestParameters: PoolsApiApiV1PoolsPoolIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PoolDto> {
            return localVarFp.apiV1PoolsPoolIdGet(requestParameters.nexusOpsTenant, requestParameters.poolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolsApiApiV1PoolsPoolIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPoolIdPut(requestParameters: PoolsApiApiV1PoolsPoolIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<PoolDto> {
            return localVarFp.apiV1PoolsPoolIdPut(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolsApiApiV1PoolsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsPost(requestParameters: PoolsApiApiV1PoolsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PoolDto> {
            return localVarFp.apiV1PoolsPost(requestParameters.nexusOpsTenant, requestParameters.poolCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PoolsApiApiV1PoolsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoolsSearchPost(requestParameters: PoolsApiApiV1PoolsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfPoolDto> {
            return localVarFp.apiV1PoolsSearchPost(requestParameters.nexusOpsTenant, requestParameters.poolSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1PoolsForEntityAllGet operation in PoolsApi.
 * @export
 * @interface PoolsApiApiV1PoolsForEntityAllGetRequest
 */
export interface PoolsApiApiV1PoolsForEntityAllGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolsApiApiV1PoolsForEntityAllGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolsApiApiV1PoolsForEntityAllGet
     */
    readonly entityType?: PoolItemEntityType

    /**
     * 
     * @type {string}
     * @memberof PoolsApiApiV1PoolsForEntityAllGet
     */
    readonly entityId?: string

    /**
     * 
     * @type {boolean}
     * @memberof PoolsApiApiV1PoolsForEntityAllGet
     */
    readonly isIncludeAssetPools?: boolean
}

/**
 * Request parameters for apiV1PoolsForEntityGet operation in PoolsApi.
 * @export
 * @interface PoolsApiApiV1PoolsForEntityGetRequest
 */
export interface PoolsApiApiV1PoolsForEntityGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolsApiApiV1PoolsForEntityGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {number}
     * @memberof PoolsApiApiV1PoolsForEntityGet
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PoolsApiApiV1PoolsForEntityGet
     */
    readonly limit?: number

    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolsApiApiV1PoolsForEntityGet
     */
    readonly entityType?: PoolItemEntityType

    /**
     * 
     * @type {string}
     * @memberof PoolsApiApiV1PoolsForEntityGet
     */
    readonly entityId?: string

    /**
     * 
     * @type {boolean}
     * @memberof PoolsApiApiV1PoolsForEntityGet
     */
    readonly isIncludeAssetPools?: boolean

    /**
     * 
     * @type {string}
     * @memberof PoolsApiApiV1PoolsForEntityGet
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof PoolsApiApiV1PoolsForEntityGet
     */
    readonly ids?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof PoolsApiApiV1PoolsForEntityGet
     */
    readonly includeIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof PoolsApiApiV1PoolsForEntityGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof PoolsApiApiV1PoolsForEntityGet
     */
    readonly to?: string

    /**
     * Comma-separated list of fields to sort by.
     * @type {string}
     * @memberof PoolsApiApiV1PoolsForEntityGet
     */
    readonly sortBy?: string

    /**
     * Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
     * @type {string}
     * @memberof PoolsApiApiV1PoolsForEntityGet
     */
    readonly sortOrder?: string
}

/**
 * Request parameters for apiV1PoolsGetForEntitiesPost operation in PoolsApi.
 * @export
 * @interface PoolsApiApiV1PoolsGetForEntitiesPostRequest
 */
export interface PoolsApiApiV1PoolsGetForEntitiesPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolsApiApiV1PoolsGetForEntitiesPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntitiesPoolsGetRequestDto}
     * @memberof PoolsApiApiV1PoolsGetForEntitiesPost
     */
    readonly entitiesPoolsGetRequestDto?: EntitiesPoolsGetRequestDto
}

/**
 * Request parameters for apiV1PoolsGetPost operation in PoolsApi.
 * @export
 * @interface PoolsApiApiV1PoolsGetPostRequest
 */
export interface PoolsApiApiV1PoolsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolsApiApiV1PoolsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {PoolGetPaginatedDto}
     * @memberof PoolsApiApiV1PoolsGetPost
     */
    readonly poolGetPaginatedDto?: PoolGetPaginatedDto
}

/**
 * Request parameters for apiV1PoolsIntersectionGet operation in PoolsApi.
 * @export
 * @interface PoolsApiApiV1PoolsIntersectionGetRequest
 */
export interface PoolsApiApiV1PoolsIntersectionGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolsApiApiV1PoolsIntersectionGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {Array<string>}
     * @memberof PoolsApiApiV1PoolsIntersectionGet
     */
    readonly poolIds?: Array<string>
}

/**
 * Request parameters for apiV1PoolsPoolIdAvailabilityGet operation in PoolsApi.
 * @export
 * @interface PoolsApiApiV1PoolsPoolIdAvailabilityGetRequest
 */
export interface PoolsApiApiV1PoolsPoolIdAvailabilityGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolsApiApiV1PoolsPoolIdAvailabilityGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolsApiApiV1PoolsPoolIdAvailabilityGet
     */
    readonly poolId: string

    /**
     * 
     * @type {PoolItemStatus}
     * @memberof PoolsApiApiV1PoolsPoolIdAvailabilityGet
     */
    readonly status?: PoolItemStatus

    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolsApiApiV1PoolsPoolIdAvailabilityGet
     */
    readonly entityType?: PoolItemEntityType

    /**
     * 
     * @type {string}
     * @memberof PoolsApiApiV1PoolsPoolIdAvailabilityGet
     */
    readonly dateRangeFrom?: string

    /**
     * 
     * @type {string}
     * @memberof PoolsApiApiV1PoolsPoolIdAvailabilityGet
     */
    readonly dateRangeTo?: string

    /**
     * 
     * @type {boolean}
     * @memberof PoolsApiApiV1PoolsPoolIdAvailabilityGet
     */
    readonly isIncludeItemIds?: boolean

    /**
     * 
     * @type {number}
     * @memberof PoolsApiApiV1PoolsPoolIdAvailabilityGet
     */
    readonly itemIdsLimit?: number
}

/**
 * Request parameters for apiV1PoolsPoolIdDelete operation in PoolsApi.
 * @export
 * @interface PoolsApiApiV1PoolsPoolIdDeleteRequest
 */
export interface PoolsApiApiV1PoolsPoolIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolsApiApiV1PoolsPoolIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolsApiApiV1PoolsPoolIdDelete
     */
    readonly poolId: string
}

/**
 * Request parameters for apiV1PoolsPoolIdGet operation in PoolsApi.
 * @export
 * @interface PoolsApiApiV1PoolsPoolIdGetRequest
 */
export interface PoolsApiApiV1PoolsPoolIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolsApiApiV1PoolsPoolIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolsApiApiV1PoolsPoolIdGet
     */
    readonly poolId: string
}

/**
 * Request parameters for apiV1PoolsPoolIdPut operation in PoolsApi.
 * @export
 * @interface PoolsApiApiV1PoolsPoolIdPutRequest
 */
export interface PoolsApiApiV1PoolsPoolIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolsApiApiV1PoolsPoolIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof PoolsApiApiV1PoolsPoolIdPut
     */
    readonly poolId: string

    /**
     * 
     * @type {PoolUpdateDto}
     * @memberof PoolsApiApiV1PoolsPoolIdPut
     */
    readonly poolUpdateDto?: PoolUpdateDto
}

/**
 * Request parameters for apiV1PoolsPost operation in PoolsApi.
 * @export
 * @interface PoolsApiApiV1PoolsPostRequest
 */
export interface PoolsApiApiV1PoolsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolsApiApiV1PoolsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {PoolCreateDto}
     * @memberof PoolsApiApiV1PoolsPost
     */
    readonly poolCreateDto?: PoolCreateDto
}

/**
 * Request parameters for apiV1PoolsSearchPost operation in PoolsApi.
 * @export
 * @interface PoolsApiApiV1PoolsSearchPostRequest
 */
export interface PoolsApiApiV1PoolsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof PoolsApiApiV1PoolsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {PoolSearchPaginatedDto}
     * @memberof PoolsApiApiV1PoolsSearchPost
     */
    readonly poolSearchPaginatedDto?: PoolSearchPaginatedDto
}

/**
 * PoolsApi - object-oriented interface
 * @export
 * @class PoolsApi
 * @extends {BaseAPI}
 */
export class PoolsApi extends BaseAPI {
    /**
     * 
     * @param {PoolsApiApiV1PoolsForEntityAllGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolsApi
     */
    public apiV1PoolsForEntityAllGet(requestParameters: PoolsApiApiV1PoolsForEntityAllGetRequest, options?: RawAxiosRequestConfig) {
        return PoolsApiFp(this.configuration).apiV1PoolsForEntityAllGet(requestParameters.nexusOpsTenant, requestParameters.entityType, requestParameters.entityId, requestParameters.isIncludeAssetPools, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolsApiApiV1PoolsForEntityGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolsApi
     */
    public apiV1PoolsForEntityGet(requestParameters: PoolsApiApiV1PoolsForEntityGetRequest, options?: RawAxiosRequestConfig) {
        return PoolsApiFp(this.configuration).apiV1PoolsForEntityGet(requestParameters.nexusOpsTenant, requestParameters.offset, requestParameters.limit, requestParameters.entityType, requestParameters.entityId, requestParameters.isIncludeAssetPools, requestParameters.search, requestParameters.ids, requestParameters.includeIds, requestParameters.from, requestParameters.to, requestParameters.sortBy, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolsApiApiV1PoolsGetForEntitiesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolsApi
     */
    public apiV1PoolsGetForEntitiesPost(requestParameters: PoolsApiApiV1PoolsGetForEntitiesPostRequest, options?: RawAxiosRequestConfig) {
        return PoolsApiFp(this.configuration).apiV1PoolsGetForEntitiesPost(requestParameters.nexusOpsTenant, requestParameters.entitiesPoolsGetRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolsApiApiV1PoolsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolsApi
     */
    public apiV1PoolsGetPost(requestParameters: PoolsApiApiV1PoolsGetPostRequest, options?: RawAxiosRequestConfig) {
        return PoolsApiFp(this.configuration).apiV1PoolsGetPost(requestParameters.nexusOpsTenant, requestParameters.poolGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolsApiApiV1PoolsIntersectionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolsApi
     */
    public apiV1PoolsIntersectionGet(requestParameters: PoolsApiApiV1PoolsIntersectionGetRequest, options?: RawAxiosRequestConfig) {
        return PoolsApiFp(this.configuration).apiV1PoolsIntersectionGet(requestParameters.nexusOpsTenant, requestParameters.poolIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolsApiApiV1PoolsPoolIdAvailabilityGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolsApi
     */
    public apiV1PoolsPoolIdAvailabilityGet(requestParameters: PoolsApiApiV1PoolsPoolIdAvailabilityGetRequest, options?: RawAxiosRequestConfig) {
        return PoolsApiFp(this.configuration).apiV1PoolsPoolIdAvailabilityGet(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.status, requestParameters.entityType, requestParameters.dateRangeFrom, requestParameters.dateRangeTo, requestParameters.isIncludeItemIds, requestParameters.itemIdsLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolsApiApiV1PoolsPoolIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolsApi
     */
    public apiV1PoolsPoolIdDelete(requestParameters: PoolsApiApiV1PoolsPoolIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return PoolsApiFp(this.configuration).apiV1PoolsPoolIdDelete(requestParameters.nexusOpsTenant, requestParameters.poolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolsApiApiV1PoolsPoolIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolsApi
     */
    public apiV1PoolsPoolIdGet(requestParameters: PoolsApiApiV1PoolsPoolIdGetRequest, options?: RawAxiosRequestConfig) {
        return PoolsApiFp(this.configuration).apiV1PoolsPoolIdGet(requestParameters.nexusOpsTenant, requestParameters.poolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolsApiApiV1PoolsPoolIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolsApi
     */
    public apiV1PoolsPoolIdPut(requestParameters: PoolsApiApiV1PoolsPoolIdPutRequest, options?: RawAxiosRequestConfig) {
        return PoolsApiFp(this.configuration).apiV1PoolsPoolIdPut(requestParameters.nexusOpsTenant, requestParameters.poolId, requestParameters.poolUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolsApiApiV1PoolsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolsApi
     */
    public apiV1PoolsPost(requestParameters: PoolsApiApiV1PoolsPostRequest, options?: RawAxiosRequestConfig) {
        return PoolsApiFp(this.configuration).apiV1PoolsPost(requestParameters.nexusOpsTenant, requestParameters.poolCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PoolsApiApiV1PoolsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolsApi
     */
    public apiV1PoolsSearchPost(requestParameters: PoolsApiApiV1PoolsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return PoolsApiFp(this.configuration).apiV1PoolsSearchPost(requestParameters.nexusOpsTenant, requestParameters.poolSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductLocationsApi - axios parameter creator
 * @export
 */
export const ProductLocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProductLocationsBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/product/locations/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ProductLocationGetPaginatedDto} [productLocationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsGetPost: async (nexusOpsTenant: string, productLocationGetPaginatedDto?: ProductLocationGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProductLocationsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/product/locations/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productLocationGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateProductLocationDto} [createProductLocationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsPost: async (nexusOpsTenant: string, createProductLocationDto?: CreateProductLocationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProductLocationsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/product/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductLocationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} productLocationId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsProductLocationIdDelete: async (nexusOpsTenant: string, productLocationId: string, isDeleteCascade?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProductLocationsProductLocationIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'productLocationId' is not null or undefined
            assertParamExists('apiV1ProductLocationsProductLocationIdDelete', 'productLocationId', productLocationId)
            const localVarPath = `/api/v1/product/locations/{productLocationId}`
                .replace(`{${"productLocationId"}}`, encodeURIComponent(String(productLocationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} productLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsProductLocationIdGet: async (nexusOpsTenant: string, productLocationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProductLocationsProductLocationIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'productLocationId' is not null or undefined
            assertParamExists('apiV1ProductLocationsProductLocationIdGet', 'productLocationId', productLocationId)
            const localVarPath = `/api/v1/product/locations/{productLocationId}`
                .replace(`{${"productLocationId"}}`, encodeURIComponent(String(productLocationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} productLocationId 
         * @param {UpdateProductLocationDto} [updateProductLocationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsProductLocationIdPut: async (nexusOpsTenant: string, productLocationId: string, updateProductLocationDto?: UpdateProductLocationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProductLocationsProductLocationIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'productLocationId' is not null or undefined
            assertParamExists('apiV1ProductLocationsProductLocationIdPut', 'productLocationId', productLocationId)
            const localVarPath = `/api/v1/product/locations/{productLocationId}`
                .replace(`{${"productLocationId"}}`, encodeURIComponent(String(productLocationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductLocationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} productLocationId 
         * @param {AddProductLocationSpotsDto} [addProductLocationSpotsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsProductLocationIdSpotsPost: async (nexusOpsTenant: string, productLocationId: string, addProductLocationSpotsDto?: AddProductLocationSpotsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProductLocationsProductLocationIdSpotsPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'productLocationId' is not null or undefined
            assertParamExists('apiV1ProductLocationsProductLocationIdSpotsPost', 'productLocationId', productLocationId)
            const localVarPath = `/api/v1/product/locations/{productLocationId}/spots`
                .replace(`{${"productLocationId"}}`, encodeURIComponent(String(productLocationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addProductLocationSpotsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} productLocationId 
         * @param {string} spotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsProductLocationIdSpotsSpotIdDelete: async (nexusOpsTenant: string, productLocationId: string, spotId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProductLocationsProductLocationIdSpotsSpotIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'productLocationId' is not null or undefined
            assertParamExists('apiV1ProductLocationsProductLocationIdSpotsSpotIdDelete', 'productLocationId', productLocationId)
            // verify required parameter 'spotId' is not null or undefined
            assertParamExists('apiV1ProductLocationsProductLocationIdSpotsSpotIdDelete', 'spotId', spotId)
            const localVarPath = `/api/v1/product/locations/{productLocationId}/spots/{spotId}`
                .replace(`{${"productLocationId"}}`, encodeURIComponent(String(productLocationId)))
                .replace(`{${"spotId"}}`, encodeURIComponent(String(spotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ProductLocationSearchPaginatedDto} [productLocationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsSearchPost: async (nexusOpsTenant: string, productLocationSearchPaginatedDto?: ProductLocationSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProductLocationsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/product/locations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productLocationSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductLocationsApi - functional programming interface
 * @export
 */
export const ProductLocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductLocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProductLocationsBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProductLocationsBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductLocationsApi.apiV1ProductLocationsBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ProductLocationGetPaginatedDto} [productLocationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProductLocationsGetPost(nexusOpsTenant: string, productLocationGetPaginatedDto?: ProductLocationGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfProductLocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProductLocationsGetPost(nexusOpsTenant, productLocationGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductLocationsApi.apiV1ProductLocationsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateProductLocationDto} [createProductLocationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProductLocationsPost(nexusOpsTenant: string, createProductLocationDto?: CreateProductLocationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductLocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProductLocationsPost(nexusOpsTenant, createProductLocationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductLocationsApi.apiV1ProductLocationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} productLocationId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProductLocationsProductLocationIdDelete(nexusOpsTenant: string, productLocationId: string, isDeleteCascade?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProductLocationsProductLocationIdDelete(nexusOpsTenant, productLocationId, isDeleteCascade, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductLocationsApi.apiV1ProductLocationsProductLocationIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} productLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProductLocationsProductLocationIdGet(nexusOpsTenant: string, productLocationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductLocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProductLocationsProductLocationIdGet(nexusOpsTenant, productLocationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductLocationsApi.apiV1ProductLocationsProductLocationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} productLocationId 
         * @param {UpdateProductLocationDto} [updateProductLocationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProductLocationsProductLocationIdPut(nexusOpsTenant: string, productLocationId: string, updateProductLocationDto?: UpdateProductLocationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductLocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProductLocationsProductLocationIdPut(nexusOpsTenant, productLocationId, updateProductLocationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductLocationsApi.apiV1ProductLocationsProductLocationIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} productLocationId 
         * @param {AddProductLocationSpotsDto} [addProductLocationSpotsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProductLocationsProductLocationIdSpotsPost(nexusOpsTenant: string, productLocationId: string, addProductLocationSpotsDto?: AddProductLocationSpotsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductLocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProductLocationsProductLocationIdSpotsPost(nexusOpsTenant, productLocationId, addProductLocationSpotsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductLocationsApi.apiV1ProductLocationsProductLocationIdSpotsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} productLocationId 
         * @param {string} spotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProductLocationsProductLocationIdSpotsSpotIdDelete(nexusOpsTenant: string, productLocationId: string, spotId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductLocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProductLocationsProductLocationIdSpotsSpotIdDelete(nexusOpsTenant, productLocationId, spotId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductLocationsApi.apiV1ProductLocationsProductLocationIdSpotsSpotIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ProductLocationSearchPaginatedDto} [productLocationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProductLocationsSearchPost(nexusOpsTenant: string, productLocationSearchPaginatedDto?: ProductLocationSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfProductLocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProductLocationsSearchPost(nexusOpsTenant, productLocationSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductLocationsApi.apiV1ProductLocationsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductLocationsApi - factory interface
 * @export
 */
export const ProductLocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductLocationsApiFp(configuration)
    return {
        /**
         * 
         * @param {ProductLocationsApiApiV1ProductLocationsBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsBulkDeleteDelete(requestParameters: ProductLocationsApiApiV1ProductLocationsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1ProductLocationsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductLocationsApiApiV1ProductLocationsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsGetPost(requestParameters: ProductLocationsApiApiV1ProductLocationsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfProductLocationDto> {
            return localVarFp.apiV1ProductLocationsGetPost(requestParameters.nexusOpsTenant, requestParameters.productLocationGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductLocationsApiApiV1ProductLocationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsPost(requestParameters: ProductLocationsApiApiV1ProductLocationsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProductLocationDto> {
            return localVarFp.apiV1ProductLocationsPost(requestParameters.nexusOpsTenant, requestParameters.createProductLocationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductLocationsApiApiV1ProductLocationsProductLocationIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsProductLocationIdDelete(requestParameters: ProductLocationsApiApiV1ProductLocationsProductLocationIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1ProductLocationsProductLocationIdDelete(requestParameters.nexusOpsTenant, requestParameters.productLocationId, requestParameters.isDeleteCascade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductLocationsApiApiV1ProductLocationsProductLocationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsProductLocationIdGet(requestParameters: ProductLocationsApiApiV1ProductLocationsProductLocationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProductLocationDto> {
            return localVarFp.apiV1ProductLocationsProductLocationIdGet(requestParameters.nexusOpsTenant, requestParameters.productLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductLocationsApiApiV1ProductLocationsProductLocationIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsProductLocationIdPut(requestParameters: ProductLocationsApiApiV1ProductLocationsProductLocationIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProductLocationDto> {
            return localVarFp.apiV1ProductLocationsProductLocationIdPut(requestParameters.nexusOpsTenant, requestParameters.productLocationId, requestParameters.updateProductLocationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsProductLocationIdSpotsPost(requestParameters: ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProductLocationDto> {
            return localVarFp.apiV1ProductLocationsProductLocationIdSpotsPost(requestParameters.nexusOpsTenant, requestParameters.productLocationId, requestParameters.addProductLocationSpotsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsSpotIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsProductLocationIdSpotsSpotIdDelete(requestParameters: ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsSpotIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProductLocationDto> {
            return localVarFp.apiV1ProductLocationsProductLocationIdSpotsSpotIdDelete(requestParameters.nexusOpsTenant, requestParameters.productLocationId, requestParameters.spotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductLocationsApiApiV1ProductLocationsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductLocationsSearchPost(requestParameters: ProductLocationsApiApiV1ProductLocationsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfProductLocationDto> {
            return localVarFp.apiV1ProductLocationsSearchPost(requestParameters.nexusOpsTenant, requestParameters.productLocationSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ProductLocationsBulkDeleteDelete operation in ProductLocationsApi.
 * @export
 * @interface ProductLocationsApiApiV1ProductLocationsBulkDeleteDeleteRequest
 */
export interface ProductLocationsApiApiV1ProductLocationsBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof ProductLocationsApiApiV1ProductLocationsBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1ProductLocationsGetPost operation in ProductLocationsApi.
 * @export
 * @interface ProductLocationsApiApiV1ProductLocationsGetPostRequest
 */
export interface ProductLocationsApiApiV1ProductLocationsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ProductLocationGetPaginatedDto}
     * @memberof ProductLocationsApiApiV1ProductLocationsGetPost
     */
    readonly productLocationGetPaginatedDto?: ProductLocationGetPaginatedDto
}

/**
 * Request parameters for apiV1ProductLocationsPost operation in ProductLocationsApi.
 * @export
 * @interface ProductLocationsApiApiV1ProductLocationsPostRequest
 */
export interface ProductLocationsApiApiV1ProductLocationsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateProductLocationDto}
     * @memberof ProductLocationsApiApiV1ProductLocationsPost
     */
    readonly createProductLocationDto?: CreateProductLocationDto
}

/**
 * Request parameters for apiV1ProductLocationsProductLocationIdDelete operation in ProductLocationsApi.
 * @export
 * @interface ProductLocationsApiApiV1ProductLocationsProductLocationIdDeleteRequest
 */
export interface ProductLocationsApiApiV1ProductLocationsProductLocationIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdDelete
     */
    readonly productLocationId: string

    /**
     * 
     * @type {boolean}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdDelete
     */
    readonly isDeleteCascade?: boolean
}

/**
 * Request parameters for apiV1ProductLocationsProductLocationIdGet operation in ProductLocationsApi.
 * @export
 * @interface ProductLocationsApiApiV1ProductLocationsProductLocationIdGetRequest
 */
export interface ProductLocationsApiApiV1ProductLocationsProductLocationIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdGet
     */
    readonly productLocationId: string
}

/**
 * Request parameters for apiV1ProductLocationsProductLocationIdPut operation in ProductLocationsApi.
 * @export
 * @interface ProductLocationsApiApiV1ProductLocationsProductLocationIdPutRequest
 */
export interface ProductLocationsApiApiV1ProductLocationsProductLocationIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdPut
     */
    readonly productLocationId: string

    /**
     * 
     * @type {UpdateProductLocationDto}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdPut
     */
    readonly updateProductLocationDto?: UpdateProductLocationDto
}

/**
 * Request parameters for apiV1ProductLocationsProductLocationIdSpotsPost operation in ProductLocationsApi.
 * @export
 * @interface ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsPostRequest
 */
export interface ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsPost
     */
    readonly productLocationId: string

    /**
     * 
     * @type {AddProductLocationSpotsDto}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsPost
     */
    readonly addProductLocationSpotsDto?: AddProductLocationSpotsDto
}

/**
 * Request parameters for apiV1ProductLocationsProductLocationIdSpotsSpotIdDelete operation in ProductLocationsApi.
 * @export
 * @interface ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsSpotIdDeleteRequest
 */
export interface ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsSpotIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsSpotIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsSpotIdDelete
     */
    readonly productLocationId: string

    /**
     * 
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsSpotIdDelete
     */
    readonly spotId: string
}

/**
 * Request parameters for apiV1ProductLocationsSearchPost operation in ProductLocationsApi.
 * @export
 * @interface ProductLocationsApiApiV1ProductLocationsSearchPostRequest
 */
export interface ProductLocationsApiApiV1ProductLocationsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProductLocationsApiApiV1ProductLocationsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ProductLocationSearchPaginatedDto}
     * @memberof ProductLocationsApiApiV1ProductLocationsSearchPost
     */
    readonly productLocationSearchPaginatedDto?: ProductLocationSearchPaginatedDto
}

/**
 * ProductLocationsApi - object-oriented interface
 * @export
 * @class ProductLocationsApi
 * @extends {BaseAPI}
 */
export class ProductLocationsApi extends BaseAPI {
    /**
     * 
     * @param {ProductLocationsApiApiV1ProductLocationsBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLocationsApi
     */
    public apiV1ProductLocationsBulkDeleteDelete(requestParameters: ProductLocationsApiApiV1ProductLocationsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return ProductLocationsApiFp(this.configuration).apiV1ProductLocationsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductLocationsApiApiV1ProductLocationsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLocationsApi
     */
    public apiV1ProductLocationsGetPost(requestParameters: ProductLocationsApiApiV1ProductLocationsGetPostRequest, options?: RawAxiosRequestConfig) {
        return ProductLocationsApiFp(this.configuration).apiV1ProductLocationsGetPost(requestParameters.nexusOpsTenant, requestParameters.productLocationGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductLocationsApiApiV1ProductLocationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLocationsApi
     */
    public apiV1ProductLocationsPost(requestParameters: ProductLocationsApiApiV1ProductLocationsPostRequest, options?: RawAxiosRequestConfig) {
        return ProductLocationsApiFp(this.configuration).apiV1ProductLocationsPost(requestParameters.nexusOpsTenant, requestParameters.createProductLocationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductLocationsApiApiV1ProductLocationsProductLocationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLocationsApi
     */
    public apiV1ProductLocationsProductLocationIdDelete(requestParameters: ProductLocationsApiApiV1ProductLocationsProductLocationIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ProductLocationsApiFp(this.configuration).apiV1ProductLocationsProductLocationIdDelete(requestParameters.nexusOpsTenant, requestParameters.productLocationId, requestParameters.isDeleteCascade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductLocationsApiApiV1ProductLocationsProductLocationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLocationsApi
     */
    public apiV1ProductLocationsProductLocationIdGet(requestParameters: ProductLocationsApiApiV1ProductLocationsProductLocationIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProductLocationsApiFp(this.configuration).apiV1ProductLocationsProductLocationIdGet(requestParameters.nexusOpsTenant, requestParameters.productLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductLocationsApiApiV1ProductLocationsProductLocationIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLocationsApi
     */
    public apiV1ProductLocationsProductLocationIdPut(requestParameters: ProductLocationsApiApiV1ProductLocationsProductLocationIdPutRequest, options?: RawAxiosRequestConfig) {
        return ProductLocationsApiFp(this.configuration).apiV1ProductLocationsProductLocationIdPut(requestParameters.nexusOpsTenant, requestParameters.productLocationId, requestParameters.updateProductLocationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLocationsApi
     */
    public apiV1ProductLocationsProductLocationIdSpotsPost(requestParameters: ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsPostRequest, options?: RawAxiosRequestConfig) {
        return ProductLocationsApiFp(this.configuration).apiV1ProductLocationsProductLocationIdSpotsPost(requestParameters.nexusOpsTenant, requestParameters.productLocationId, requestParameters.addProductLocationSpotsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsSpotIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLocationsApi
     */
    public apiV1ProductLocationsProductLocationIdSpotsSpotIdDelete(requestParameters: ProductLocationsApiApiV1ProductLocationsProductLocationIdSpotsSpotIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ProductLocationsApiFp(this.configuration).apiV1ProductLocationsProductLocationIdSpotsSpotIdDelete(requestParameters.nexusOpsTenant, requestParameters.productLocationId, requestParameters.spotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductLocationsApiApiV1ProductLocationsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLocationsApi
     */
    public apiV1ProductLocationsSearchPost(requestParameters: ProductLocationsApiApiV1ProductLocationsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return ProductLocationsApiFp(this.configuration).apiV1ProductLocationsSearchPost(requestParameters.nexusOpsTenant, requestParameters.productLocationSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileAuthorizationGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProfileAuthorizationGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/profile/authorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProfileGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralAvatarAddDto} [generalAvatarAddDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfilePersonalInfoAvatarPut: async (nexusOpsTenant: string, generalAvatarAddDto?: GeneralAvatarAddDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProfilePersonalInfoAvatarPut', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/profile/personal-info/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalAvatarAddDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfilePersonalInfoAvatarRemoveDelete: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProfilePersonalInfoAvatarRemoveDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/profile/personal-info/avatar/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ProfilePersonalInfoUpdateDto} [profilePersonalInfoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfilePersonalInfoPut: async (nexusOpsTenant: string, profilePersonalInfoUpdateDto?: ProfilePersonalInfoUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProfilePersonalInfoPut', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/profile/personal-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profilePersonalInfoUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileRolesGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProfileRolesGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/profile/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileSettingsGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProfileSettingsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/profile/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {UserSettingsInputDto} [userSettingsInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileSettingsPut: async (nexusOpsTenant: string, userSettingsInputDto?: UserSettingsInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProfileSettingsPut', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/profile/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSettingsInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileTenantsCurrentGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProfileTenantsCurrentGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/profile/tenants/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileTenantsGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1ProfileTenantsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/profile/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProfileAuthorizationGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizationProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProfileAuthorizationGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.apiV1ProfileAuthorizationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProfileGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProfileGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.apiV1ProfileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {GeneralAvatarAddDto} [generalAvatarAddDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProfilePersonalInfoAvatarPut(nexusOpsTenant: string, generalAvatarAddDto?: GeneralAvatarAddDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProfilePersonalInfoAvatarPut(nexusOpsTenant, generalAvatarAddDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.apiV1ProfilePersonalInfoAvatarPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProfilePersonalInfoAvatarRemoveDelete(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProfilePersonalInfoAvatarRemoveDelete(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.apiV1ProfilePersonalInfoAvatarRemoveDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ProfilePersonalInfoUpdateDto} [profilePersonalInfoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProfilePersonalInfoPut(nexusOpsTenant: string, profilePersonalInfoUpdateDto?: ProfilePersonalInfoUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProfilePersonalInfoPut(nexusOpsTenant, profilePersonalInfoUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.apiV1ProfilePersonalInfoPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProfileRolesGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileRolesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProfileRolesGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.apiV1ProfileRolesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProfileSettingsGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProfileSettingsGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.apiV1ProfileSettingsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {UserSettingsInputDto} [userSettingsInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProfileSettingsPut(nexusOpsTenant: string, userSettingsInputDto?: UserSettingsInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProfileSettingsPut(nexusOpsTenant, userSettingsInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.apiV1ProfileSettingsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProfileTenantsCurrentGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProfileTenantsCurrentGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.apiV1ProfileTenantsCurrentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProfileTenantsGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProfileTenantsGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfileApi.apiV1ProfileTenantsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {ProfileApiApiV1ProfileAuthorizationGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileAuthorizationGet(requestParameters: ProfileApiApiV1ProfileAuthorizationGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AuthorizationProfileDto> {
            return localVarFp.apiV1ProfileAuthorizationGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileApiApiV1ProfileGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileGet(requestParameters: ProfileApiApiV1ProfileGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProfileDto> {
            return localVarFp.apiV1ProfileGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileApiApiV1ProfilePersonalInfoAvatarPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfilePersonalInfoAvatarPut(requestParameters: ProfileApiApiV1ProfilePersonalInfoAvatarPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProfileDto> {
            return localVarFp.apiV1ProfilePersonalInfoAvatarPut(requestParameters.nexusOpsTenant, requestParameters.generalAvatarAddDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileApiApiV1ProfilePersonalInfoAvatarRemoveDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfilePersonalInfoAvatarRemoveDelete(requestParameters: ProfileApiApiV1ProfilePersonalInfoAvatarRemoveDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProfileDto> {
            return localVarFp.apiV1ProfilePersonalInfoAvatarRemoveDelete(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileApiApiV1ProfilePersonalInfoPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfilePersonalInfoPut(requestParameters: ProfileApiApiV1ProfilePersonalInfoPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProfileDto> {
            return localVarFp.apiV1ProfilePersonalInfoPut(requestParameters.nexusOpsTenant, requestParameters.profilePersonalInfoUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileApiApiV1ProfileRolesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileRolesGet(requestParameters: ProfileApiApiV1ProfileRolesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProfileRolesDto> {
            return localVarFp.apiV1ProfileRolesGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileApiApiV1ProfileSettingsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileSettingsGet(requestParameters: ProfileApiApiV1ProfileSettingsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserSettingsDto> {
            return localVarFp.apiV1ProfileSettingsGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileApiApiV1ProfileSettingsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileSettingsPut(requestParameters: ProfileApiApiV1ProfileSettingsPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserSettingsDto> {
            return localVarFp.apiV1ProfileSettingsPut(requestParameters.nexusOpsTenant, requestParameters.userSettingsInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileApiApiV1ProfileTenantsCurrentGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileTenantsCurrentGet(requestParameters: ProfileApiApiV1ProfileTenantsCurrentGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantDto> {
            return localVarFp.apiV1ProfileTenantsCurrentGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileApiApiV1ProfileTenantsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProfileTenantsGet(requestParameters: ProfileApiApiV1ProfileTenantsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<TenantDto>> {
            return localVarFp.apiV1ProfileTenantsGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ProfileAuthorizationGet operation in ProfileApi.
 * @export
 * @interface ProfileApiApiV1ProfileAuthorizationGetRequest
 */
export interface ProfileApiApiV1ProfileAuthorizationGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProfileApiApiV1ProfileAuthorizationGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1ProfileGet operation in ProfileApi.
 * @export
 * @interface ProfileApiApiV1ProfileGetRequest
 */
export interface ProfileApiApiV1ProfileGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProfileApiApiV1ProfileGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1ProfilePersonalInfoAvatarPut operation in ProfileApi.
 * @export
 * @interface ProfileApiApiV1ProfilePersonalInfoAvatarPutRequest
 */
export interface ProfileApiApiV1ProfilePersonalInfoAvatarPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProfileApiApiV1ProfilePersonalInfoAvatarPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {GeneralAvatarAddDto}
     * @memberof ProfileApiApiV1ProfilePersonalInfoAvatarPut
     */
    readonly generalAvatarAddDto?: GeneralAvatarAddDto
}

/**
 * Request parameters for apiV1ProfilePersonalInfoAvatarRemoveDelete operation in ProfileApi.
 * @export
 * @interface ProfileApiApiV1ProfilePersonalInfoAvatarRemoveDeleteRequest
 */
export interface ProfileApiApiV1ProfilePersonalInfoAvatarRemoveDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProfileApiApiV1ProfilePersonalInfoAvatarRemoveDelete
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1ProfilePersonalInfoPut operation in ProfileApi.
 * @export
 * @interface ProfileApiApiV1ProfilePersonalInfoPutRequest
 */
export interface ProfileApiApiV1ProfilePersonalInfoPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProfileApiApiV1ProfilePersonalInfoPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ProfilePersonalInfoUpdateDto}
     * @memberof ProfileApiApiV1ProfilePersonalInfoPut
     */
    readonly profilePersonalInfoUpdateDto?: ProfilePersonalInfoUpdateDto
}

/**
 * Request parameters for apiV1ProfileRolesGet operation in ProfileApi.
 * @export
 * @interface ProfileApiApiV1ProfileRolesGetRequest
 */
export interface ProfileApiApiV1ProfileRolesGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProfileApiApiV1ProfileRolesGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1ProfileSettingsGet operation in ProfileApi.
 * @export
 * @interface ProfileApiApiV1ProfileSettingsGetRequest
 */
export interface ProfileApiApiV1ProfileSettingsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProfileApiApiV1ProfileSettingsGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1ProfileSettingsPut operation in ProfileApi.
 * @export
 * @interface ProfileApiApiV1ProfileSettingsPutRequest
 */
export interface ProfileApiApiV1ProfileSettingsPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProfileApiApiV1ProfileSettingsPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {UserSettingsInputDto}
     * @memberof ProfileApiApiV1ProfileSettingsPut
     */
    readonly userSettingsInputDto?: UserSettingsInputDto
}

/**
 * Request parameters for apiV1ProfileTenantsCurrentGet operation in ProfileApi.
 * @export
 * @interface ProfileApiApiV1ProfileTenantsCurrentGetRequest
 */
export interface ProfileApiApiV1ProfileTenantsCurrentGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProfileApiApiV1ProfileTenantsCurrentGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1ProfileTenantsGet operation in ProfileApi.
 * @export
 * @interface ProfileApiApiV1ProfileTenantsGetRequest
 */
export interface ProfileApiApiV1ProfileTenantsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof ProfileApiApiV1ProfileTenantsGet
     */
    readonly nexusOpsTenant: string
}

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @param {ProfileApiApiV1ProfileAuthorizationGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiV1ProfileAuthorizationGet(requestParameters: ProfileApiApiV1ProfileAuthorizationGetRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiV1ProfileAuthorizationGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiApiV1ProfileGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiV1ProfileGet(requestParameters: ProfileApiApiV1ProfileGetRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiV1ProfileGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiApiV1ProfilePersonalInfoAvatarPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiV1ProfilePersonalInfoAvatarPut(requestParameters: ProfileApiApiV1ProfilePersonalInfoAvatarPutRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiV1ProfilePersonalInfoAvatarPut(requestParameters.nexusOpsTenant, requestParameters.generalAvatarAddDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiApiV1ProfilePersonalInfoAvatarRemoveDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiV1ProfilePersonalInfoAvatarRemoveDelete(requestParameters: ProfileApiApiV1ProfilePersonalInfoAvatarRemoveDeleteRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiV1ProfilePersonalInfoAvatarRemoveDelete(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiApiV1ProfilePersonalInfoPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiV1ProfilePersonalInfoPut(requestParameters: ProfileApiApiV1ProfilePersonalInfoPutRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiV1ProfilePersonalInfoPut(requestParameters.nexusOpsTenant, requestParameters.profilePersonalInfoUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiApiV1ProfileRolesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiV1ProfileRolesGet(requestParameters: ProfileApiApiV1ProfileRolesGetRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiV1ProfileRolesGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiApiV1ProfileSettingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiV1ProfileSettingsGet(requestParameters: ProfileApiApiV1ProfileSettingsGetRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiV1ProfileSettingsGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiApiV1ProfileSettingsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiV1ProfileSettingsPut(requestParameters: ProfileApiApiV1ProfileSettingsPutRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiV1ProfileSettingsPut(requestParameters.nexusOpsTenant, requestParameters.userSettingsInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiApiV1ProfileTenantsCurrentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiV1ProfileTenantsCurrentGet(requestParameters: ProfileApiApiV1ProfileTenantsCurrentGetRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiV1ProfileTenantsCurrentGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiApiV1ProfileTenantsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiV1ProfileTenantsGet(requestParameters: ProfileApiApiV1ProfileTenantsGetRequest, options?: RawAxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiV1ProfileTenantsGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PushWebhookApi - axios parameter creator
 * @export
 */
export const PushWebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PushWebhookDto} [pushWebhookDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebHookPushPost: async (pushWebhookDto?: PushWebhookDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web-hook/push`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pushWebhookDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PushWebhookApi - functional programming interface
 * @export
 */
export const PushWebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PushWebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PushWebhookDto} [pushWebhookDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WebHookPushPost(pushWebhookDto?: PushWebhookDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WebHookPushPost(pushWebhookDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PushWebhookApi.apiV1WebHookPushPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PushWebhookApi - factory interface
 * @export
 */
export const PushWebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PushWebhookApiFp(configuration)
    return {
        /**
         * 
         * @param {PushWebhookApiApiV1WebHookPushPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebHookPushPost(requestParameters: PushWebhookApiApiV1WebHookPushPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1WebHookPushPost(requestParameters.pushWebhookDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1WebHookPushPost operation in PushWebhookApi.
 * @export
 * @interface PushWebhookApiApiV1WebHookPushPostRequest
 */
export interface PushWebhookApiApiV1WebHookPushPostRequest {
    /**
     * 
     * @type {PushWebhookDto}
     * @memberof PushWebhookApiApiV1WebHookPushPost
     */
    readonly pushWebhookDto?: PushWebhookDto
}

/**
 * PushWebhookApi - object-oriented interface
 * @export
 * @class PushWebhookApi
 * @extends {BaseAPI}
 */
export class PushWebhookApi extends BaseAPI {
    /**
     * 
     * @param {PushWebhookApiApiV1WebHookPushPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushWebhookApi
     */
    public apiV1WebHookPushPost(requestParameters: PushWebhookApiApiV1WebHookPushPostRequest = {}, options?: RawAxiosRequestConfig) {
        return PushWebhookApiFp(this.configuration).apiV1WebHookPushPost(requestParameters.pushWebhookDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RepairCatalogsApi - axios parameter creator
 * @export
 */
export const RepairCatalogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairCatalogGetPaginatedDto} [repairCatalogGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairCatalogsGetPost: async (nexusOpsTenant: string, repairCatalogGetPaginatedDto?: RepairCatalogGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairCatalogsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/catalogs/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairCatalogGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} [sourceCatalogId] 
         * @param {string} [targetCatalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairCatalogsImportFromCatalogInfoGet: async (nexusOpsTenant: string, sourceCatalogId?: string, targetCatalogId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairCatalogsImportFromCatalogInfoGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/catalogs/import/from-catalog/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sourceCatalogId !== undefined) {
                localVarQueryParameter['sourceCatalogId'] = sourceCatalogId;
            }

            if (targetCatalogId !== undefined) {
                localVarQueryParameter['targetCatalogId'] = targetCatalogId;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ImportFromCatalogDto} [importFromCatalogDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairCatalogsImportFromCatalogPost: async (nexusOpsTenant: string, importFromCatalogDto?: ImportFromCatalogDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairCatalogsImportFromCatalogPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/catalogs/import/from-catalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importFromCatalogDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairCatalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairCatalogsRepairCatalogIdGet: async (nexusOpsTenant: string, repairCatalogId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairCatalogsRepairCatalogIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairCatalogId' is not null or undefined
            assertParamExists('apiV1RepairCatalogsRepairCatalogIdGet', 'repairCatalogId', repairCatalogId)
            const localVarPath = `/api/v1/repair/catalogs/{repairCatalogId}`
                .replace(`{${"repairCatalogId"}}`, encodeURIComponent(String(repairCatalogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairCatalogSearchPaginatedDto} [repairCatalogSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairCatalogsSearchPost: async (nexusOpsTenant: string, repairCatalogSearchPaginatedDto?: RepairCatalogSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairCatalogsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/catalogs/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairCatalogSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepairCatalogsApi - functional programming interface
 * @export
 */
export const RepairCatalogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepairCatalogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairCatalogGetPaginatedDto} [repairCatalogGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairCatalogsGetPost(nexusOpsTenant: string, repairCatalogGetPaginatedDto?: RepairCatalogGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairCatalogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairCatalogsGetPost(nexusOpsTenant, repairCatalogGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairCatalogsApi.apiV1RepairCatalogsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} [sourceCatalogId] 
         * @param {string} [targetCatalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairCatalogsImportFromCatalogInfoGet(nexusOpsTenant: string, sourceCatalogId?: string, targetCatalogId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportFromCatalogInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairCatalogsImportFromCatalogInfoGet(nexusOpsTenant, sourceCatalogId, targetCatalogId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairCatalogsApi.apiV1RepairCatalogsImportFromCatalogInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ImportFromCatalogDto} [importFromCatalogDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairCatalogsImportFromCatalogPost(nexusOpsTenant: string, importFromCatalogDto?: ImportFromCatalogDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairCatalogsImportFromCatalogPost(nexusOpsTenant, importFromCatalogDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairCatalogsApi.apiV1RepairCatalogsImportFromCatalogPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairCatalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairCatalogsRepairCatalogIdGet(nexusOpsTenant: string, repairCatalogId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairCatalogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairCatalogsRepairCatalogIdGet(nexusOpsTenant, repairCatalogId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairCatalogsApi.apiV1RepairCatalogsRepairCatalogIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairCatalogSearchPaginatedDto} [repairCatalogSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairCatalogsSearchPost(nexusOpsTenant: string, repairCatalogSearchPaginatedDto?: RepairCatalogSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairCatalogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairCatalogsSearchPost(nexusOpsTenant, repairCatalogSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairCatalogsApi.apiV1RepairCatalogsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RepairCatalogsApi - factory interface
 * @export
 */
export const RepairCatalogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepairCatalogsApiFp(configuration)
    return {
        /**
         * 
         * @param {RepairCatalogsApiApiV1RepairCatalogsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairCatalogsGetPost(requestParameters: RepairCatalogsApiApiV1RepairCatalogsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairCatalogDto> {
            return localVarFp.apiV1RepairCatalogsGetPost(requestParameters.nexusOpsTenant, requestParameters.repairCatalogGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogInfoGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairCatalogsImportFromCatalogInfoGet(requestParameters: RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogInfoGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ImportFromCatalogInfoDto> {
            return localVarFp.apiV1RepairCatalogsImportFromCatalogInfoGet(requestParameters.nexusOpsTenant, requestParameters.sourceCatalogId, requestParameters.targetCatalogId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairCatalogsImportFromCatalogPost(requestParameters: RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1RepairCatalogsImportFromCatalogPost(requestParameters.nexusOpsTenant, requestParameters.importFromCatalogDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairCatalogsApiApiV1RepairCatalogsRepairCatalogIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairCatalogsRepairCatalogIdGet(requestParameters: RepairCatalogsApiApiV1RepairCatalogsRepairCatalogIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairCatalogDto> {
            return localVarFp.apiV1RepairCatalogsRepairCatalogIdGet(requestParameters.nexusOpsTenant, requestParameters.repairCatalogId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairCatalogsApiApiV1RepairCatalogsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairCatalogsSearchPost(requestParameters: RepairCatalogsApiApiV1RepairCatalogsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairCatalogDto> {
            return localVarFp.apiV1RepairCatalogsSearchPost(requestParameters.nexusOpsTenant, requestParameters.repairCatalogSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1RepairCatalogsGetPost operation in RepairCatalogsApi.
 * @export
 * @interface RepairCatalogsApiApiV1RepairCatalogsGetPostRequest
 */
export interface RepairCatalogsApiApiV1RepairCatalogsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairCatalogsApiApiV1RepairCatalogsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RepairCatalogGetPaginatedDto}
     * @memberof RepairCatalogsApiApiV1RepairCatalogsGetPost
     */
    readonly repairCatalogGetPaginatedDto?: RepairCatalogGetPaginatedDto
}

/**
 * Request parameters for apiV1RepairCatalogsImportFromCatalogInfoGet operation in RepairCatalogsApi.
 * @export
 * @interface RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogInfoGetRequest
 */
export interface RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogInfoGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogInfoGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogInfoGet
     */
    readonly sourceCatalogId?: string

    /**
     * 
     * @type {string}
     * @memberof RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogInfoGet
     */
    readonly targetCatalogId?: string
}

/**
 * Request parameters for apiV1RepairCatalogsImportFromCatalogPost operation in RepairCatalogsApi.
 * @export
 * @interface RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogPostRequest
 */
export interface RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ImportFromCatalogDto}
     * @memberof RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogPost
     */
    readonly importFromCatalogDto?: ImportFromCatalogDto
}

/**
 * Request parameters for apiV1RepairCatalogsRepairCatalogIdGet operation in RepairCatalogsApi.
 * @export
 * @interface RepairCatalogsApiApiV1RepairCatalogsRepairCatalogIdGetRequest
 */
export interface RepairCatalogsApiApiV1RepairCatalogsRepairCatalogIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairCatalogsApiApiV1RepairCatalogsRepairCatalogIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairCatalogsApiApiV1RepairCatalogsRepairCatalogIdGet
     */
    readonly repairCatalogId: string
}

/**
 * Request parameters for apiV1RepairCatalogsSearchPost operation in RepairCatalogsApi.
 * @export
 * @interface RepairCatalogsApiApiV1RepairCatalogsSearchPostRequest
 */
export interface RepairCatalogsApiApiV1RepairCatalogsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairCatalogsApiApiV1RepairCatalogsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RepairCatalogSearchPaginatedDto}
     * @memberof RepairCatalogsApiApiV1RepairCatalogsSearchPost
     */
    readonly repairCatalogSearchPaginatedDto?: RepairCatalogSearchPaginatedDto
}

/**
 * RepairCatalogsApi - object-oriented interface
 * @export
 * @class RepairCatalogsApi
 * @extends {BaseAPI}
 */
export class RepairCatalogsApi extends BaseAPI {
    /**
     * 
     * @param {RepairCatalogsApiApiV1RepairCatalogsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairCatalogsApi
     */
    public apiV1RepairCatalogsGetPost(requestParameters: RepairCatalogsApiApiV1RepairCatalogsGetPostRequest, options?: RawAxiosRequestConfig) {
        return RepairCatalogsApiFp(this.configuration).apiV1RepairCatalogsGetPost(requestParameters.nexusOpsTenant, requestParameters.repairCatalogGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairCatalogsApi
     */
    public apiV1RepairCatalogsImportFromCatalogInfoGet(requestParameters: RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogInfoGetRequest, options?: RawAxiosRequestConfig) {
        return RepairCatalogsApiFp(this.configuration).apiV1RepairCatalogsImportFromCatalogInfoGet(requestParameters.nexusOpsTenant, requestParameters.sourceCatalogId, requestParameters.targetCatalogId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairCatalogsApi
     */
    public apiV1RepairCatalogsImportFromCatalogPost(requestParameters: RepairCatalogsApiApiV1RepairCatalogsImportFromCatalogPostRequest, options?: RawAxiosRequestConfig) {
        return RepairCatalogsApiFp(this.configuration).apiV1RepairCatalogsImportFromCatalogPost(requestParameters.nexusOpsTenant, requestParameters.importFromCatalogDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairCatalogsApiApiV1RepairCatalogsRepairCatalogIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairCatalogsApi
     */
    public apiV1RepairCatalogsRepairCatalogIdGet(requestParameters: RepairCatalogsApiApiV1RepairCatalogsRepairCatalogIdGetRequest, options?: RawAxiosRequestConfig) {
        return RepairCatalogsApiFp(this.configuration).apiV1RepairCatalogsRepairCatalogIdGet(requestParameters.nexusOpsTenant, requestParameters.repairCatalogId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairCatalogsApiApiV1RepairCatalogsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairCatalogsApi
     */
    public apiV1RepairCatalogsSearchPost(requestParameters: RepairCatalogsApiApiV1RepairCatalogsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return RepairCatalogsApiFp(this.configuration).apiV1RepairCatalogsSearchPost(requestParameters.nexusOpsTenant, requestParameters.repairCatalogSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RepairMaterialsApi - axios parameter creator
 * @export
 */
export const RepairMaterialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairMaterialGetPaginatedDto} [repairMaterialGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsGetPost: async (nexusOpsTenant: string, repairMaterialGetPaginatedDto?: RepairMaterialGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairMaterialsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/materials/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairMaterialGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateRepairMaterialDto} [createRepairMaterialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsPost: async (nexusOpsTenant: string, createRepairMaterialDto?: CreateRepairMaterialDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairMaterialsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/materials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRepairMaterialDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsRepairMaterialIdDelete: async (nexusOpsTenant: string, repairMaterialId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairMaterialsRepairMaterialIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairMaterialId' is not null or undefined
            assertParamExists('apiV1RepairMaterialsRepairMaterialIdDelete', 'repairMaterialId', repairMaterialId)
            const localVarPath = `/api/v1/repair/materials/{repairMaterialId}`
                .replace(`{${"repairMaterialId"}}`, encodeURIComponent(String(repairMaterialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsRepairMaterialIdDeleteInfoGet: async (nexusOpsTenant: string, repairMaterialId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairMaterialsRepairMaterialIdDeleteInfoGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairMaterialId' is not null or undefined
            assertParamExists('apiV1RepairMaterialsRepairMaterialIdDeleteInfoGet', 'repairMaterialId', repairMaterialId)
            const localVarPath = `/api/v1/repair/materials/{repairMaterialId}/delete/info`
                .replace(`{${"repairMaterialId"}}`, encodeURIComponent(String(repairMaterialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsRepairMaterialIdGet: async (nexusOpsTenant: string, repairMaterialId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairMaterialsRepairMaterialIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairMaterialId' is not null or undefined
            assertParamExists('apiV1RepairMaterialsRepairMaterialIdGet', 'repairMaterialId', repairMaterialId)
            const localVarPath = `/api/v1/repair/materials/{repairMaterialId}`
                .replace(`{${"repairMaterialId"}}`, encodeURIComponent(String(repairMaterialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairMaterialId 
         * @param {UpdateRepairMaterialDto} [updateRepairMaterialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsRepairMaterialIdPut: async (nexusOpsTenant: string, repairMaterialId: string, updateRepairMaterialDto?: UpdateRepairMaterialDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairMaterialsRepairMaterialIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairMaterialId' is not null or undefined
            assertParamExists('apiV1RepairMaterialsRepairMaterialIdPut', 'repairMaterialId', repairMaterialId)
            const localVarPath = `/api/v1/repair/materials/{repairMaterialId}`
                .replace(`{${"repairMaterialId"}}`, encodeURIComponent(String(repairMaterialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepairMaterialDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairMaterialSearchPaginatedDto} [repairMaterialSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsSearchPost: async (nexusOpsTenant: string, repairMaterialSearchPaginatedDto?: RepairMaterialSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairMaterialsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/materials/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairMaterialSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepairMaterialsApi - functional programming interface
 * @export
 */
export const RepairMaterialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepairMaterialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairMaterialGetPaginatedDto} [repairMaterialGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairMaterialsGetPost(nexusOpsTenant: string, repairMaterialGetPaginatedDto?: RepairMaterialGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairMaterialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairMaterialsGetPost(nexusOpsTenant, repairMaterialGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairMaterialsApi.apiV1RepairMaterialsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateRepairMaterialDto} [createRepairMaterialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairMaterialsPost(nexusOpsTenant: string, createRepairMaterialDto?: CreateRepairMaterialDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairMaterialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairMaterialsPost(nexusOpsTenant, createRepairMaterialDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairMaterialsApi.apiV1RepairMaterialsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairMaterialsRepairMaterialIdDelete(nexusOpsTenant: string, repairMaterialId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairMaterialsRepairMaterialIdDelete(nexusOpsTenant, repairMaterialId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairMaterialsApi.apiV1RepairMaterialsRepairMaterialIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairMaterialsRepairMaterialIdDeleteInfoGet(nexusOpsTenant: string, repairMaterialId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteRepairMaterialInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairMaterialsRepairMaterialIdDeleteInfoGet(nexusOpsTenant, repairMaterialId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairMaterialsApi.apiV1RepairMaterialsRepairMaterialIdDeleteInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairMaterialsRepairMaterialIdGet(nexusOpsTenant: string, repairMaterialId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairMaterialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairMaterialsRepairMaterialIdGet(nexusOpsTenant, repairMaterialId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairMaterialsApi.apiV1RepairMaterialsRepairMaterialIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairMaterialId 
         * @param {UpdateRepairMaterialDto} [updateRepairMaterialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairMaterialsRepairMaterialIdPut(nexusOpsTenant: string, repairMaterialId: string, updateRepairMaterialDto?: UpdateRepairMaterialDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairMaterialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairMaterialsRepairMaterialIdPut(nexusOpsTenant, repairMaterialId, updateRepairMaterialDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairMaterialsApi.apiV1RepairMaterialsRepairMaterialIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairMaterialSearchPaginatedDto} [repairMaterialSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairMaterialsSearchPost(nexusOpsTenant: string, repairMaterialSearchPaginatedDto?: RepairMaterialSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairMaterialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairMaterialsSearchPost(nexusOpsTenant, repairMaterialSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairMaterialsApi.apiV1RepairMaterialsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RepairMaterialsApi - factory interface
 * @export
 */
export const RepairMaterialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepairMaterialsApiFp(configuration)
    return {
        /**
         * 
         * @param {RepairMaterialsApiApiV1RepairMaterialsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsGetPost(requestParameters: RepairMaterialsApiApiV1RepairMaterialsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairMaterialDto> {
            return localVarFp.apiV1RepairMaterialsGetPost(requestParameters.nexusOpsTenant, requestParameters.repairMaterialGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairMaterialsApiApiV1RepairMaterialsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsPost(requestParameters: RepairMaterialsApiApiV1RepairMaterialsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairMaterialDto> {
            return localVarFp.apiV1RepairMaterialsPost(requestParameters.nexusOpsTenant, requestParameters.createRepairMaterialDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsRepairMaterialIdDelete(requestParameters: RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1RepairMaterialsRepairMaterialIdDelete(requestParameters.nexusOpsTenant, requestParameters.repairMaterialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteInfoGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsRepairMaterialIdDeleteInfoGet(requestParameters: RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteInfoGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteRepairMaterialInfoDto> {
            return localVarFp.apiV1RepairMaterialsRepairMaterialIdDeleteInfoGet(requestParameters.nexusOpsTenant, requestParameters.repairMaterialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsRepairMaterialIdGet(requestParameters: RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairMaterialDto> {
            return localVarFp.apiV1RepairMaterialsRepairMaterialIdGet(requestParameters.nexusOpsTenant, requestParameters.repairMaterialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsRepairMaterialIdPut(requestParameters: RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairMaterialDto> {
            return localVarFp.apiV1RepairMaterialsRepairMaterialIdPut(requestParameters.nexusOpsTenant, requestParameters.repairMaterialId, requestParameters.updateRepairMaterialDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairMaterialsApiApiV1RepairMaterialsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairMaterialsSearchPost(requestParameters: RepairMaterialsApiApiV1RepairMaterialsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairMaterialDto> {
            return localVarFp.apiV1RepairMaterialsSearchPost(requestParameters.nexusOpsTenant, requestParameters.repairMaterialSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1RepairMaterialsGetPost operation in RepairMaterialsApi.
 * @export
 * @interface RepairMaterialsApiApiV1RepairMaterialsGetPostRequest
 */
export interface RepairMaterialsApiApiV1RepairMaterialsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RepairMaterialGetPaginatedDto}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsGetPost
     */
    readonly repairMaterialGetPaginatedDto?: RepairMaterialGetPaginatedDto
}

/**
 * Request parameters for apiV1RepairMaterialsPost operation in RepairMaterialsApi.
 * @export
 * @interface RepairMaterialsApiApiV1RepairMaterialsPostRequest
 */
export interface RepairMaterialsApiApiV1RepairMaterialsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateRepairMaterialDto}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsPost
     */
    readonly createRepairMaterialDto?: CreateRepairMaterialDto
}

/**
 * Request parameters for apiV1RepairMaterialsRepairMaterialIdDelete operation in RepairMaterialsApi.
 * @export
 * @interface RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteRequest
 */
export interface RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDelete
     */
    readonly repairMaterialId: string
}

/**
 * Request parameters for apiV1RepairMaterialsRepairMaterialIdDeleteInfoGet operation in RepairMaterialsApi.
 * @export
 * @interface RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteInfoGetRequest
 */
export interface RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteInfoGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteInfoGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteInfoGet
     */
    readonly repairMaterialId: string
}

/**
 * Request parameters for apiV1RepairMaterialsRepairMaterialIdGet operation in RepairMaterialsApi.
 * @export
 * @interface RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdGetRequest
 */
export interface RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdGet
     */
    readonly repairMaterialId: string
}

/**
 * Request parameters for apiV1RepairMaterialsRepairMaterialIdPut operation in RepairMaterialsApi.
 * @export
 * @interface RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdPutRequest
 */
export interface RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdPut
     */
    readonly repairMaterialId: string

    /**
     * 
     * @type {UpdateRepairMaterialDto}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdPut
     */
    readonly updateRepairMaterialDto?: UpdateRepairMaterialDto
}

/**
 * Request parameters for apiV1RepairMaterialsSearchPost operation in RepairMaterialsApi.
 * @export
 * @interface RepairMaterialsApiApiV1RepairMaterialsSearchPostRequest
 */
export interface RepairMaterialsApiApiV1RepairMaterialsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RepairMaterialSearchPaginatedDto}
     * @memberof RepairMaterialsApiApiV1RepairMaterialsSearchPost
     */
    readonly repairMaterialSearchPaginatedDto?: RepairMaterialSearchPaginatedDto
}

/**
 * RepairMaterialsApi - object-oriented interface
 * @export
 * @class RepairMaterialsApi
 * @extends {BaseAPI}
 */
export class RepairMaterialsApi extends BaseAPI {
    /**
     * 
     * @param {RepairMaterialsApiApiV1RepairMaterialsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairMaterialsApi
     */
    public apiV1RepairMaterialsGetPost(requestParameters: RepairMaterialsApiApiV1RepairMaterialsGetPostRequest, options?: RawAxiosRequestConfig) {
        return RepairMaterialsApiFp(this.configuration).apiV1RepairMaterialsGetPost(requestParameters.nexusOpsTenant, requestParameters.repairMaterialGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairMaterialsApiApiV1RepairMaterialsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairMaterialsApi
     */
    public apiV1RepairMaterialsPost(requestParameters: RepairMaterialsApiApiV1RepairMaterialsPostRequest, options?: RawAxiosRequestConfig) {
        return RepairMaterialsApiFp(this.configuration).apiV1RepairMaterialsPost(requestParameters.nexusOpsTenant, requestParameters.createRepairMaterialDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairMaterialsApi
     */
    public apiV1RepairMaterialsRepairMaterialIdDelete(requestParameters: RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return RepairMaterialsApiFp(this.configuration).apiV1RepairMaterialsRepairMaterialIdDelete(requestParameters.nexusOpsTenant, requestParameters.repairMaterialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairMaterialsApi
     */
    public apiV1RepairMaterialsRepairMaterialIdDeleteInfoGet(requestParameters: RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdDeleteInfoGetRequest, options?: RawAxiosRequestConfig) {
        return RepairMaterialsApiFp(this.configuration).apiV1RepairMaterialsRepairMaterialIdDeleteInfoGet(requestParameters.nexusOpsTenant, requestParameters.repairMaterialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairMaterialsApi
     */
    public apiV1RepairMaterialsRepairMaterialIdGet(requestParameters: RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdGetRequest, options?: RawAxiosRequestConfig) {
        return RepairMaterialsApiFp(this.configuration).apiV1RepairMaterialsRepairMaterialIdGet(requestParameters.nexusOpsTenant, requestParameters.repairMaterialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairMaterialsApi
     */
    public apiV1RepairMaterialsRepairMaterialIdPut(requestParameters: RepairMaterialsApiApiV1RepairMaterialsRepairMaterialIdPutRequest, options?: RawAxiosRequestConfig) {
        return RepairMaterialsApiFp(this.configuration).apiV1RepairMaterialsRepairMaterialIdPut(requestParameters.nexusOpsTenant, requestParameters.repairMaterialId, requestParameters.updateRepairMaterialDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairMaterialsApiApiV1RepairMaterialsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairMaterialsApi
     */
    public apiV1RepairMaterialsSearchPost(requestParameters: RepairMaterialsApiApiV1RepairMaterialsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return RepairMaterialsApiFp(this.configuration).apiV1RepairMaterialsSearchPost(requestParameters.nexusOpsTenant, requestParameters.repairMaterialSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RepairOperationsApi - axios parameter creator
 * @export
 */
export const RepairOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairOperationsBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair-operations/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsByIdsGet: async (nexusOpsTenant: string, ids?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairOperationsByIdsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair-operations/by-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsCountersGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairOperationsCountersGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair-operations/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairOperationGetPaginatedDto} [repairOperationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsGetPost: async (nexusOpsTenant: string, repairOperationGetPaginatedDto?: RepairOperationGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairOperationsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair-operations/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairOperationGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateRepairOperationDto} [createRepairOperationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsPost: async (nexusOpsTenant: string, createRepairOperationDto?: CreateRepairOperationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairOperationsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair-operations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRepairOperationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairOperationId 
         * @param {RepairOperationAddVehicleDamagesDto} [repairOperationAddVehicleDamagesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsRepairOperationIdDamagesAddPost: async (nexusOpsTenant: string, repairOperationId: string, repairOperationAddVehicleDamagesDto?: RepairOperationAddVehicleDamagesDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairOperationsRepairOperationIdDamagesAddPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairOperationId' is not null or undefined
            assertParamExists('apiV1RepairOperationsRepairOperationIdDamagesAddPost', 'repairOperationId', repairOperationId)
            const localVarPath = `/api/v1/repair-operations/{repairOperationId}/damages/add`
                .replace(`{${"repairOperationId"}}`, encodeURIComponent(String(repairOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairOperationAddVehicleDamagesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairOperationId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsRepairOperationIdDelete: async (nexusOpsTenant: string, repairOperationId: string, isDeleteCascade?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairOperationsRepairOperationIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairOperationId' is not null or undefined
            assertParamExists('apiV1RepairOperationsRepairOperationIdDelete', 'repairOperationId', repairOperationId)
            const localVarPath = `/api/v1/repair-operations/{repairOperationId}`
                .replace(`{${"repairOperationId"}}`, encodeURIComponent(String(repairOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsRepairOperationIdDownloadPdfGet: async (nexusOpsTenant: string, repairOperationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairOperationsRepairOperationIdDownloadPdfGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairOperationId' is not null or undefined
            assertParamExists('apiV1RepairOperationsRepairOperationIdDownloadPdfGet', 'repairOperationId', repairOperationId)
            const localVarPath = `/api/v1/repair-operations/{repairOperationId}/download/pdf`
                .replace(`{${"repairOperationId"}}`, encodeURIComponent(String(repairOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsRepairOperationIdGet: async (nexusOpsTenant: string, repairOperationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairOperationsRepairOperationIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairOperationId' is not null or undefined
            assertParamExists('apiV1RepairOperationsRepairOperationIdGet', 'repairOperationId', repairOperationId)
            const localVarPath = `/api/v1/repair-operations/{repairOperationId}`
                .replace(`{${"repairOperationId"}}`, encodeURIComponent(String(repairOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairOperationId 
         * @param {UpdateRepairOperationDto} [updateRepairOperationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsRepairOperationIdPut: async (nexusOpsTenant: string, repairOperationId: string, updateRepairOperationDto?: UpdateRepairOperationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairOperationsRepairOperationIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairOperationId' is not null or undefined
            assertParamExists('apiV1RepairOperationsRepairOperationIdPut', 'repairOperationId', repairOperationId)
            const localVarPath = `/api/v1/repair-operations/{repairOperationId}`
                .replace(`{${"repairOperationId"}}`, encodeURIComponent(String(repairOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepairOperationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairOperationId 
         * @param {GeneralStageUpdateDtoOfRepairOperationStage} [generalStageUpdateDtoOfRepairOperationStage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsRepairOperationIdStagePut: async (nexusOpsTenant: string, repairOperationId: string, generalStageUpdateDtoOfRepairOperationStage?: GeneralStageUpdateDtoOfRepairOperationStage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairOperationsRepairOperationIdStagePut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairOperationId' is not null or undefined
            assertParamExists('apiV1RepairOperationsRepairOperationIdStagePut', 'repairOperationId', repairOperationId)
            const localVarPath = `/api/v1/repair-operations/{repairOperationId}/stage`
                .replace(`{${"repairOperationId"}}`, encodeURIComponent(String(repairOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalStageUpdateDtoOfRepairOperationStage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairOperationSearchPaginatedDto} [repairOperationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsSearchPost: async (nexusOpsTenant: string, repairOperationSearchPaginatedDto?: RepairOperationSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairOperationsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair-operations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairOperationSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepairOperationsApi - functional programming interface
 * @export
 */
export const RepairOperationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepairOperationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairOperationsBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairOperationsBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairOperationsApi.apiV1RepairOperationsBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairOperationsByIdsGet(nexusOpsTenant: string, ids?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairOperationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairOperationsByIdsGet(nexusOpsTenant, ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairOperationsApi.apiV1RepairOperationsByIdsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairOperationsCountersGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairOperationCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairOperationsCountersGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairOperationsApi.apiV1RepairOperationsCountersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairOperationGetPaginatedDto} [repairOperationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairOperationsGetPost(nexusOpsTenant: string, repairOperationGetPaginatedDto?: RepairOperationGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairOperationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairOperationsGetPost(nexusOpsTenant, repairOperationGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairOperationsApi.apiV1RepairOperationsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateRepairOperationDto} [createRepairOperationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairOperationsPost(nexusOpsTenant: string, createRepairOperationDto?: CreateRepairOperationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairOperationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairOperationsPost(nexusOpsTenant, createRepairOperationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairOperationsApi.apiV1RepairOperationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairOperationId 
         * @param {RepairOperationAddVehicleDamagesDto} [repairOperationAddVehicleDamagesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairOperationsRepairOperationIdDamagesAddPost(nexusOpsTenant: string, repairOperationId: string, repairOperationAddVehicleDamagesDto?: RepairOperationAddVehicleDamagesDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairOperationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairOperationsRepairOperationIdDamagesAddPost(nexusOpsTenant, repairOperationId, repairOperationAddVehicleDamagesDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairOperationsApi.apiV1RepairOperationsRepairOperationIdDamagesAddPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairOperationId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairOperationsRepairOperationIdDelete(nexusOpsTenant: string, repairOperationId: string, isDeleteCascade?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairOperationsRepairOperationIdDelete(nexusOpsTenant, repairOperationId, isDeleteCascade, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairOperationsApi.apiV1RepairOperationsRepairOperationIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairOperationsRepairOperationIdDownloadPdfGet(nexusOpsTenant: string, repairOperationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairOperationsRepairOperationIdDownloadPdfGet(nexusOpsTenant, repairOperationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairOperationsApi.apiV1RepairOperationsRepairOperationIdDownloadPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairOperationsRepairOperationIdGet(nexusOpsTenant: string, repairOperationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairOperationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairOperationsRepairOperationIdGet(nexusOpsTenant, repairOperationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairOperationsApi.apiV1RepairOperationsRepairOperationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairOperationId 
         * @param {UpdateRepairOperationDto} [updateRepairOperationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairOperationsRepairOperationIdPut(nexusOpsTenant: string, repairOperationId: string, updateRepairOperationDto?: UpdateRepairOperationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairOperationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairOperationsRepairOperationIdPut(nexusOpsTenant, repairOperationId, updateRepairOperationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairOperationsApi.apiV1RepairOperationsRepairOperationIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairOperationId 
         * @param {GeneralStageUpdateDtoOfRepairOperationStage} [generalStageUpdateDtoOfRepairOperationStage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairOperationsRepairOperationIdStagePut(nexusOpsTenant: string, repairOperationId: string, generalStageUpdateDtoOfRepairOperationStage?: GeneralStageUpdateDtoOfRepairOperationStage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairOperationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairOperationsRepairOperationIdStagePut(nexusOpsTenant, repairOperationId, generalStageUpdateDtoOfRepairOperationStage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairOperationsApi.apiV1RepairOperationsRepairOperationIdStagePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairOperationSearchPaginatedDto} [repairOperationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairOperationsSearchPost(nexusOpsTenant: string, repairOperationSearchPaginatedDto?: RepairOperationSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairOperationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairOperationsSearchPost(nexusOpsTenant, repairOperationSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairOperationsApi.apiV1RepairOperationsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RepairOperationsApi - factory interface
 * @export
 */
export const RepairOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepairOperationsApiFp(configuration)
    return {
        /**
         * 
         * @param {RepairOperationsApiApiV1RepairOperationsBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsBulkDeleteDelete(requestParameters: RepairOperationsApiApiV1RepairOperationsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1RepairOperationsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairOperationsApiApiV1RepairOperationsByIdsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsByIdsGet(requestParameters: RepairOperationsApiApiV1RepairOperationsByIdsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<RepairOperationDto>> {
            return localVarFp.apiV1RepairOperationsByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairOperationsApiApiV1RepairOperationsCountersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsCountersGet(requestParameters: RepairOperationsApiApiV1RepairOperationsCountersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairOperationCountersDto> {
            return localVarFp.apiV1RepairOperationsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairOperationsApiApiV1RepairOperationsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsGetPost(requestParameters: RepairOperationsApiApiV1RepairOperationsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairOperationDto> {
            return localVarFp.apiV1RepairOperationsGetPost(requestParameters.nexusOpsTenant, requestParameters.repairOperationGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairOperationsApiApiV1RepairOperationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsPost(requestParameters: RepairOperationsApiApiV1RepairOperationsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairOperationDto> {
            return localVarFp.apiV1RepairOperationsPost(requestParameters.nexusOpsTenant, requestParameters.createRepairOperationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairOperationsApiApiV1RepairOperationsRepairOperationIdDamagesAddPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsRepairOperationIdDamagesAddPost(requestParameters: RepairOperationsApiApiV1RepairOperationsRepairOperationIdDamagesAddPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairOperationDto> {
            return localVarFp.apiV1RepairOperationsRepairOperationIdDamagesAddPost(requestParameters.nexusOpsTenant, requestParameters.repairOperationId, requestParameters.repairOperationAddVehicleDamagesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairOperationsApiApiV1RepairOperationsRepairOperationIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsRepairOperationIdDelete(requestParameters: RepairOperationsApiApiV1RepairOperationsRepairOperationIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1RepairOperationsRepairOperationIdDelete(requestParameters.nexusOpsTenant, requestParameters.repairOperationId, requestParameters.isDeleteCascade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairOperationsApiApiV1RepairOperationsRepairOperationIdDownloadPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsRepairOperationIdDownloadPdfGet(requestParameters: RepairOperationsApiApiV1RepairOperationsRepairOperationIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1RepairOperationsRepairOperationIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.repairOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairOperationsApiApiV1RepairOperationsRepairOperationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsRepairOperationIdGet(requestParameters: RepairOperationsApiApiV1RepairOperationsRepairOperationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairOperationDto> {
            return localVarFp.apiV1RepairOperationsRepairOperationIdGet(requestParameters.nexusOpsTenant, requestParameters.repairOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairOperationsApiApiV1RepairOperationsRepairOperationIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsRepairOperationIdPut(requestParameters: RepairOperationsApiApiV1RepairOperationsRepairOperationIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairOperationDto> {
            return localVarFp.apiV1RepairOperationsRepairOperationIdPut(requestParameters.nexusOpsTenant, requestParameters.repairOperationId, requestParameters.updateRepairOperationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairOperationsApiApiV1RepairOperationsRepairOperationIdStagePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsRepairOperationIdStagePut(requestParameters: RepairOperationsApiApiV1RepairOperationsRepairOperationIdStagePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairOperationDto> {
            return localVarFp.apiV1RepairOperationsRepairOperationIdStagePut(requestParameters.nexusOpsTenant, requestParameters.repairOperationId, requestParameters.generalStageUpdateDtoOfRepairOperationStage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairOperationsApiApiV1RepairOperationsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairOperationsSearchPost(requestParameters: RepairOperationsApiApiV1RepairOperationsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairOperationDto> {
            return localVarFp.apiV1RepairOperationsSearchPost(requestParameters.nexusOpsTenant, requestParameters.repairOperationSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1RepairOperationsBulkDeleteDelete operation in RepairOperationsApi.
 * @export
 * @interface RepairOperationsApiApiV1RepairOperationsBulkDeleteDeleteRequest
 */
export interface RepairOperationsApiApiV1RepairOperationsBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof RepairOperationsApiApiV1RepairOperationsBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1RepairOperationsByIdsGet operation in RepairOperationsApi.
 * @export
 * @interface RepairOperationsApiApiV1RepairOperationsByIdsGetRequest
 */
export interface RepairOperationsApiApiV1RepairOperationsByIdsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsByIdsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationsApiApiV1RepairOperationsByIdsGet
     */
    readonly ids?: Array<string>
}

/**
 * Request parameters for apiV1RepairOperationsCountersGet operation in RepairOperationsApi.
 * @export
 * @interface RepairOperationsApiApiV1RepairOperationsCountersGetRequest
 */
export interface RepairOperationsApiApiV1RepairOperationsCountersGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsCountersGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1RepairOperationsGetPost operation in RepairOperationsApi.
 * @export
 * @interface RepairOperationsApiApiV1RepairOperationsGetPostRequest
 */
export interface RepairOperationsApiApiV1RepairOperationsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RepairOperationGetPaginatedDto}
     * @memberof RepairOperationsApiApiV1RepairOperationsGetPost
     */
    readonly repairOperationGetPaginatedDto?: RepairOperationGetPaginatedDto
}

/**
 * Request parameters for apiV1RepairOperationsPost operation in RepairOperationsApi.
 * @export
 * @interface RepairOperationsApiApiV1RepairOperationsPostRequest
 */
export interface RepairOperationsApiApiV1RepairOperationsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateRepairOperationDto}
     * @memberof RepairOperationsApiApiV1RepairOperationsPost
     */
    readonly createRepairOperationDto?: CreateRepairOperationDto
}

/**
 * Request parameters for apiV1RepairOperationsRepairOperationIdDamagesAddPost operation in RepairOperationsApi.
 * @export
 * @interface RepairOperationsApiApiV1RepairOperationsRepairOperationIdDamagesAddPostRequest
 */
export interface RepairOperationsApiApiV1RepairOperationsRepairOperationIdDamagesAddPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdDamagesAddPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdDamagesAddPost
     */
    readonly repairOperationId: string

    /**
     * 
     * @type {RepairOperationAddVehicleDamagesDto}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdDamagesAddPost
     */
    readonly repairOperationAddVehicleDamagesDto?: RepairOperationAddVehicleDamagesDto
}

/**
 * Request parameters for apiV1RepairOperationsRepairOperationIdDelete operation in RepairOperationsApi.
 * @export
 * @interface RepairOperationsApiApiV1RepairOperationsRepairOperationIdDeleteRequest
 */
export interface RepairOperationsApiApiV1RepairOperationsRepairOperationIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdDelete
     */
    readonly repairOperationId: string

    /**
     * 
     * @type {boolean}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdDelete
     */
    readonly isDeleteCascade?: boolean
}

/**
 * Request parameters for apiV1RepairOperationsRepairOperationIdDownloadPdfGet operation in RepairOperationsApi.
 * @export
 * @interface RepairOperationsApiApiV1RepairOperationsRepairOperationIdDownloadPdfGetRequest
 */
export interface RepairOperationsApiApiV1RepairOperationsRepairOperationIdDownloadPdfGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdDownloadPdfGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdDownloadPdfGet
     */
    readonly repairOperationId: string
}

/**
 * Request parameters for apiV1RepairOperationsRepairOperationIdGet operation in RepairOperationsApi.
 * @export
 * @interface RepairOperationsApiApiV1RepairOperationsRepairOperationIdGetRequest
 */
export interface RepairOperationsApiApiV1RepairOperationsRepairOperationIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdGet
     */
    readonly repairOperationId: string
}

/**
 * Request parameters for apiV1RepairOperationsRepairOperationIdPut operation in RepairOperationsApi.
 * @export
 * @interface RepairOperationsApiApiV1RepairOperationsRepairOperationIdPutRequest
 */
export interface RepairOperationsApiApiV1RepairOperationsRepairOperationIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdPut
     */
    readonly repairOperationId: string

    /**
     * 
     * @type {UpdateRepairOperationDto}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdPut
     */
    readonly updateRepairOperationDto?: UpdateRepairOperationDto
}

/**
 * Request parameters for apiV1RepairOperationsRepairOperationIdStagePut operation in RepairOperationsApi.
 * @export
 * @interface RepairOperationsApiApiV1RepairOperationsRepairOperationIdStagePutRequest
 */
export interface RepairOperationsApiApiV1RepairOperationsRepairOperationIdStagePutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdStagePut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdStagePut
     */
    readonly repairOperationId: string

    /**
     * 
     * @type {GeneralStageUpdateDtoOfRepairOperationStage}
     * @memberof RepairOperationsApiApiV1RepairOperationsRepairOperationIdStagePut
     */
    readonly generalStageUpdateDtoOfRepairOperationStage?: GeneralStageUpdateDtoOfRepairOperationStage
}

/**
 * Request parameters for apiV1RepairOperationsSearchPost operation in RepairOperationsApi.
 * @export
 * @interface RepairOperationsApiApiV1RepairOperationsSearchPostRequest
 */
export interface RepairOperationsApiApiV1RepairOperationsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairOperationsApiApiV1RepairOperationsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RepairOperationSearchPaginatedDto}
     * @memberof RepairOperationsApiApiV1RepairOperationsSearchPost
     */
    readonly repairOperationSearchPaginatedDto?: RepairOperationSearchPaginatedDto
}

/**
 * RepairOperationsApi - object-oriented interface
 * @export
 * @class RepairOperationsApi
 * @extends {BaseAPI}
 */
export class RepairOperationsApi extends BaseAPI {
    /**
     * 
     * @param {RepairOperationsApiApiV1RepairOperationsBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairOperationsApi
     */
    public apiV1RepairOperationsBulkDeleteDelete(requestParameters: RepairOperationsApiApiV1RepairOperationsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return RepairOperationsApiFp(this.configuration).apiV1RepairOperationsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairOperationsApiApiV1RepairOperationsByIdsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairOperationsApi
     */
    public apiV1RepairOperationsByIdsGet(requestParameters: RepairOperationsApiApiV1RepairOperationsByIdsGetRequest, options?: RawAxiosRequestConfig) {
        return RepairOperationsApiFp(this.configuration).apiV1RepairOperationsByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairOperationsApiApiV1RepairOperationsCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairOperationsApi
     */
    public apiV1RepairOperationsCountersGet(requestParameters: RepairOperationsApiApiV1RepairOperationsCountersGetRequest, options?: RawAxiosRequestConfig) {
        return RepairOperationsApiFp(this.configuration).apiV1RepairOperationsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairOperationsApiApiV1RepairOperationsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairOperationsApi
     */
    public apiV1RepairOperationsGetPost(requestParameters: RepairOperationsApiApiV1RepairOperationsGetPostRequest, options?: RawAxiosRequestConfig) {
        return RepairOperationsApiFp(this.configuration).apiV1RepairOperationsGetPost(requestParameters.nexusOpsTenant, requestParameters.repairOperationGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairOperationsApiApiV1RepairOperationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairOperationsApi
     */
    public apiV1RepairOperationsPost(requestParameters: RepairOperationsApiApiV1RepairOperationsPostRequest, options?: RawAxiosRequestConfig) {
        return RepairOperationsApiFp(this.configuration).apiV1RepairOperationsPost(requestParameters.nexusOpsTenant, requestParameters.createRepairOperationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairOperationsApiApiV1RepairOperationsRepairOperationIdDamagesAddPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairOperationsApi
     */
    public apiV1RepairOperationsRepairOperationIdDamagesAddPost(requestParameters: RepairOperationsApiApiV1RepairOperationsRepairOperationIdDamagesAddPostRequest, options?: RawAxiosRequestConfig) {
        return RepairOperationsApiFp(this.configuration).apiV1RepairOperationsRepairOperationIdDamagesAddPost(requestParameters.nexusOpsTenant, requestParameters.repairOperationId, requestParameters.repairOperationAddVehicleDamagesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairOperationsApiApiV1RepairOperationsRepairOperationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairOperationsApi
     */
    public apiV1RepairOperationsRepairOperationIdDelete(requestParameters: RepairOperationsApiApiV1RepairOperationsRepairOperationIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return RepairOperationsApiFp(this.configuration).apiV1RepairOperationsRepairOperationIdDelete(requestParameters.nexusOpsTenant, requestParameters.repairOperationId, requestParameters.isDeleteCascade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairOperationsApiApiV1RepairOperationsRepairOperationIdDownloadPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairOperationsApi
     */
    public apiV1RepairOperationsRepairOperationIdDownloadPdfGet(requestParameters: RepairOperationsApiApiV1RepairOperationsRepairOperationIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig) {
        return RepairOperationsApiFp(this.configuration).apiV1RepairOperationsRepairOperationIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.repairOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairOperationsApiApiV1RepairOperationsRepairOperationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairOperationsApi
     */
    public apiV1RepairOperationsRepairOperationIdGet(requestParameters: RepairOperationsApiApiV1RepairOperationsRepairOperationIdGetRequest, options?: RawAxiosRequestConfig) {
        return RepairOperationsApiFp(this.configuration).apiV1RepairOperationsRepairOperationIdGet(requestParameters.nexusOpsTenant, requestParameters.repairOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairOperationsApiApiV1RepairOperationsRepairOperationIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairOperationsApi
     */
    public apiV1RepairOperationsRepairOperationIdPut(requestParameters: RepairOperationsApiApiV1RepairOperationsRepairOperationIdPutRequest, options?: RawAxiosRequestConfig) {
        return RepairOperationsApiFp(this.configuration).apiV1RepairOperationsRepairOperationIdPut(requestParameters.nexusOpsTenant, requestParameters.repairOperationId, requestParameters.updateRepairOperationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairOperationsApiApiV1RepairOperationsRepairOperationIdStagePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairOperationsApi
     */
    public apiV1RepairOperationsRepairOperationIdStagePut(requestParameters: RepairOperationsApiApiV1RepairOperationsRepairOperationIdStagePutRequest, options?: RawAxiosRequestConfig) {
        return RepairOperationsApiFp(this.configuration).apiV1RepairOperationsRepairOperationIdStagePut(requestParameters.nexusOpsTenant, requestParameters.repairOperationId, requestParameters.generalStageUpdateDtoOfRepairOperationStage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairOperationsApiApiV1RepairOperationsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairOperationsApi
     */
    public apiV1RepairOperationsSearchPost(requestParameters: RepairOperationsApiApiV1RepairOperationsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return RepairOperationsApiFp(this.configuration).apiV1RepairOperationsSearchPost(requestParameters.nexusOpsTenant, requestParameters.repairOperationSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RepairSparePartApi - axios parameter creator
 * @export
 */
export const RepairSparePartApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairSparePartGetPaginatedDto} [repairSparePartGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsGetPost: async (nexusOpsTenant: string, repairSparePartGetPaginatedDto?: RepairSparePartGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairSparePartsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/spare-parts/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairSparePartGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateRepairSparePartDto} [createRepairSparePartDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsPost: async (nexusOpsTenant: string, createRepairSparePartDto?: CreateRepairSparePartDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairSparePartsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/spare-parts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRepairSparePartDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSparePartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsRepairSparePartIdDelete: async (nexusOpsTenant: string, repairSparePartId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairSparePartsRepairSparePartIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairSparePartId' is not null or undefined
            assertParamExists('apiV1RepairSparePartsRepairSparePartIdDelete', 'repairSparePartId', repairSparePartId)
            const localVarPath = `/api/v1/repair/spare-parts/{repairSparePartId}`
                .replace(`{${"repairSparePartId"}}`, encodeURIComponent(String(repairSparePartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSparePartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsRepairSparePartIdDeleteInfoGet: async (nexusOpsTenant: string, repairSparePartId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairSparePartsRepairSparePartIdDeleteInfoGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairSparePartId' is not null or undefined
            assertParamExists('apiV1RepairSparePartsRepairSparePartIdDeleteInfoGet', 'repairSparePartId', repairSparePartId)
            const localVarPath = `/api/v1/repair/spare-parts/{repairSparePartId}/delete/info`
                .replace(`{${"repairSparePartId"}}`, encodeURIComponent(String(repairSparePartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSparePartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsRepairSparePartIdGet: async (nexusOpsTenant: string, repairSparePartId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairSparePartsRepairSparePartIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairSparePartId' is not null or undefined
            assertParamExists('apiV1RepairSparePartsRepairSparePartIdGet', 'repairSparePartId', repairSparePartId)
            const localVarPath = `/api/v1/repair/spare-parts/{repairSparePartId}`
                .replace(`{${"repairSparePartId"}}`, encodeURIComponent(String(repairSparePartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSparePartId 
         * @param {UpdateRepairSparePartDto} [updateRepairSparePartDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsRepairSparePartIdPut: async (nexusOpsTenant: string, repairSparePartId: string, updateRepairSparePartDto?: UpdateRepairSparePartDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairSparePartsRepairSparePartIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairSparePartId' is not null or undefined
            assertParamExists('apiV1RepairSparePartsRepairSparePartIdPut', 'repairSparePartId', repairSparePartId)
            const localVarPath = `/api/v1/repair/spare-parts/{repairSparePartId}`
                .replace(`{${"repairSparePartId"}}`, encodeURIComponent(String(repairSparePartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepairSparePartDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairSparePartSearchPaginatedDto} [repairSparePartSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsSearchPost: async (nexusOpsTenant: string, repairSparePartSearchPaginatedDto?: RepairSparePartSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairSparePartsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/spare-parts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairSparePartSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepairSparePartApi - functional programming interface
 * @export
 */
export const RepairSparePartApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepairSparePartApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairSparePartGetPaginatedDto} [repairSparePartGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairSparePartsGetPost(nexusOpsTenant: string, repairSparePartGetPaginatedDto?: RepairSparePartGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairSparePartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairSparePartsGetPost(nexusOpsTenant, repairSparePartGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairSparePartApi.apiV1RepairSparePartsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateRepairSparePartDto} [createRepairSparePartDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairSparePartsPost(nexusOpsTenant: string, createRepairSparePartDto?: CreateRepairSparePartDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairSparePartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairSparePartsPost(nexusOpsTenant, createRepairSparePartDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairSparePartApi.apiV1RepairSparePartsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSparePartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairSparePartsRepairSparePartIdDelete(nexusOpsTenant: string, repairSparePartId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairSparePartsRepairSparePartIdDelete(nexusOpsTenant, repairSparePartId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairSparePartApi.apiV1RepairSparePartsRepairSparePartIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSparePartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairSparePartsRepairSparePartIdDeleteInfoGet(nexusOpsTenant: string, repairSparePartId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteRepairSparePartInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairSparePartsRepairSparePartIdDeleteInfoGet(nexusOpsTenant, repairSparePartId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairSparePartApi.apiV1RepairSparePartsRepairSparePartIdDeleteInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSparePartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairSparePartsRepairSparePartIdGet(nexusOpsTenant: string, repairSparePartId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairSparePartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairSparePartsRepairSparePartIdGet(nexusOpsTenant, repairSparePartId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairSparePartApi.apiV1RepairSparePartsRepairSparePartIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSparePartId 
         * @param {UpdateRepairSparePartDto} [updateRepairSparePartDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairSparePartsRepairSparePartIdPut(nexusOpsTenant: string, repairSparePartId: string, updateRepairSparePartDto?: UpdateRepairSparePartDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairSparePartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairSparePartsRepairSparePartIdPut(nexusOpsTenant, repairSparePartId, updateRepairSparePartDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairSparePartApi.apiV1RepairSparePartsRepairSparePartIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairSparePartSearchPaginatedDto} [repairSparePartSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairSparePartsSearchPost(nexusOpsTenant: string, repairSparePartSearchPaginatedDto?: RepairSparePartSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairSparePartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairSparePartsSearchPost(nexusOpsTenant, repairSparePartSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairSparePartApi.apiV1RepairSparePartsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RepairSparePartApi - factory interface
 * @export
 */
export const RepairSparePartApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepairSparePartApiFp(configuration)
    return {
        /**
         * 
         * @param {RepairSparePartApiApiV1RepairSparePartsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsGetPost(requestParameters: RepairSparePartApiApiV1RepairSparePartsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairSparePartDto> {
            return localVarFp.apiV1RepairSparePartsGetPost(requestParameters.nexusOpsTenant, requestParameters.repairSparePartGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairSparePartApiApiV1RepairSparePartsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsPost(requestParameters: RepairSparePartApiApiV1RepairSparePartsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairSparePartDto> {
            return localVarFp.apiV1RepairSparePartsPost(requestParameters.nexusOpsTenant, requestParameters.createRepairSparePartDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsRepairSparePartIdDelete(requestParameters: RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1RepairSparePartsRepairSparePartIdDelete(requestParameters.nexusOpsTenant, requestParameters.repairSparePartId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteInfoGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsRepairSparePartIdDeleteInfoGet(requestParameters: RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteInfoGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteRepairSparePartInfoDto> {
            return localVarFp.apiV1RepairSparePartsRepairSparePartIdDeleteInfoGet(requestParameters.nexusOpsTenant, requestParameters.repairSparePartId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsRepairSparePartIdGet(requestParameters: RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairSparePartDto> {
            return localVarFp.apiV1RepairSparePartsRepairSparePartIdGet(requestParameters.nexusOpsTenant, requestParameters.repairSparePartId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsRepairSparePartIdPut(requestParameters: RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairSparePartDto> {
            return localVarFp.apiV1RepairSparePartsRepairSparePartIdPut(requestParameters.nexusOpsTenant, requestParameters.repairSparePartId, requestParameters.updateRepairSparePartDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairSparePartApiApiV1RepairSparePartsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSparePartsSearchPost(requestParameters: RepairSparePartApiApiV1RepairSparePartsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairSparePartDto> {
            return localVarFp.apiV1RepairSparePartsSearchPost(requestParameters.nexusOpsTenant, requestParameters.repairSparePartSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1RepairSparePartsGetPost operation in RepairSparePartApi.
 * @export
 * @interface RepairSparePartApiApiV1RepairSparePartsGetPostRequest
 */
export interface RepairSparePartApiApiV1RepairSparePartsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairSparePartApiApiV1RepairSparePartsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RepairSparePartGetPaginatedDto}
     * @memberof RepairSparePartApiApiV1RepairSparePartsGetPost
     */
    readonly repairSparePartGetPaginatedDto?: RepairSparePartGetPaginatedDto
}

/**
 * Request parameters for apiV1RepairSparePartsPost operation in RepairSparePartApi.
 * @export
 * @interface RepairSparePartApiApiV1RepairSparePartsPostRequest
 */
export interface RepairSparePartApiApiV1RepairSparePartsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairSparePartApiApiV1RepairSparePartsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateRepairSparePartDto}
     * @memberof RepairSparePartApiApiV1RepairSparePartsPost
     */
    readonly createRepairSparePartDto?: CreateRepairSparePartDto
}

/**
 * Request parameters for apiV1RepairSparePartsRepairSparePartIdDelete operation in RepairSparePartApi.
 * @export
 * @interface RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteRequest
 */
export interface RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDelete
     */
    readonly repairSparePartId: string
}

/**
 * Request parameters for apiV1RepairSparePartsRepairSparePartIdDeleteInfoGet operation in RepairSparePartApi.
 * @export
 * @interface RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteInfoGetRequest
 */
export interface RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteInfoGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteInfoGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteInfoGet
     */
    readonly repairSparePartId: string
}

/**
 * Request parameters for apiV1RepairSparePartsRepairSparePartIdGet operation in RepairSparePartApi.
 * @export
 * @interface RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdGetRequest
 */
export interface RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdGet
     */
    readonly repairSparePartId: string
}

/**
 * Request parameters for apiV1RepairSparePartsRepairSparePartIdPut operation in RepairSparePartApi.
 * @export
 * @interface RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdPutRequest
 */
export interface RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdPut
     */
    readonly repairSparePartId: string

    /**
     * 
     * @type {UpdateRepairSparePartDto}
     * @memberof RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdPut
     */
    readonly updateRepairSparePartDto?: UpdateRepairSparePartDto
}

/**
 * Request parameters for apiV1RepairSparePartsSearchPost operation in RepairSparePartApi.
 * @export
 * @interface RepairSparePartApiApiV1RepairSparePartsSearchPostRequest
 */
export interface RepairSparePartApiApiV1RepairSparePartsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairSparePartApiApiV1RepairSparePartsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RepairSparePartSearchPaginatedDto}
     * @memberof RepairSparePartApiApiV1RepairSparePartsSearchPost
     */
    readonly repairSparePartSearchPaginatedDto?: RepairSparePartSearchPaginatedDto
}

/**
 * RepairSparePartApi - object-oriented interface
 * @export
 * @class RepairSparePartApi
 * @extends {BaseAPI}
 */
export class RepairSparePartApi extends BaseAPI {
    /**
     * 
     * @param {RepairSparePartApiApiV1RepairSparePartsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairSparePartApi
     */
    public apiV1RepairSparePartsGetPost(requestParameters: RepairSparePartApiApiV1RepairSparePartsGetPostRequest, options?: RawAxiosRequestConfig) {
        return RepairSparePartApiFp(this.configuration).apiV1RepairSparePartsGetPost(requestParameters.nexusOpsTenant, requestParameters.repairSparePartGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairSparePartApiApiV1RepairSparePartsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairSparePartApi
     */
    public apiV1RepairSparePartsPost(requestParameters: RepairSparePartApiApiV1RepairSparePartsPostRequest, options?: RawAxiosRequestConfig) {
        return RepairSparePartApiFp(this.configuration).apiV1RepairSparePartsPost(requestParameters.nexusOpsTenant, requestParameters.createRepairSparePartDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairSparePartApi
     */
    public apiV1RepairSparePartsRepairSparePartIdDelete(requestParameters: RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return RepairSparePartApiFp(this.configuration).apiV1RepairSparePartsRepairSparePartIdDelete(requestParameters.nexusOpsTenant, requestParameters.repairSparePartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairSparePartApi
     */
    public apiV1RepairSparePartsRepairSparePartIdDeleteInfoGet(requestParameters: RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdDeleteInfoGetRequest, options?: RawAxiosRequestConfig) {
        return RepairSparePartApiFp(this.configuration).apiV1RepairSparePartsRepairSparePartIdDeleteInfoGet(requestParameters.nexusOpsTenant, requestParameters.repairSparePartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairSparePartApi
     */
    public apiV1RepairSparePartsRepairSparePartIdGet(requestParameters: RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdGetRequest, options?: RawAxiosRequestConfig) {
        return RepairSparePartApiFp(this.configuration).apiV1RepairSparePartsRepairSparePartIdGet(requestParameters.nexusOpsTenant, requestParameters.repairSparePartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairSparePartApi
     */
    public apiV1RepairSparePartsRepairSparePartIdPut(requestParameters: RepairSparePartApiApiV1RepairSparePartsRepairSparePartIdPutRequest, options?: RawAxiosRequestConfig) {
        return RepairSparePartApiFp(this.configuration).apiV1RepairSparePartsRepairSparePartIdPut(requestParameters.nexusOpsTenant, requestParameters.repairSparePartId, requestParameters.updateRepairSparePartDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairSparePartApiApiV1RepairSparePartsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairSparePartApi
     */
    public apiV1RepairSparePartsSearchPost(requestParameters: RepairSparePartApiApiV1RepairSparePartsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return RepairSparePartApiFp(this.configuration).apiV1RepairSparePartsSearchPost(requestParameters.nexusOpsTenant, requestParameters.repairSparePartSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RepairSpecsApi - axios parameter creator
 * @export
 */
export const RepairSpecsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairSpecGetPaginatedDto} [repairSpecGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSpecsGetPost: async (nexusOpsTenant: string, repairSpecGetPaginatedDto?: RepairSpecGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairSpecsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/specs/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairSpecGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateRepairSpecDto} [createRepairSpecDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSpecsPost: async (nexusOpsTenant: string, createRepairSpecDto?: CreateRepairSpecDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairSpecsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/specs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRepairSpecDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSpecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSpecsRepairSpecIdDelete: async (nexusOpsTenant: string, repairSpecId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairSpecsRepairSpecIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairSpecId' is not null or undefined
            assertParamExists('apiV1RepairSpecsRepairSpecIdDelete', 'repairSpecId', repairSpecId)
            const localVarPath = `/api/v1/repair/specs/{repairSpecId}`
                .replace(`{${"repairSpecId"}}`, encodeURIComponent(String(repairSpecId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSpecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSpecsRepairSpecIdGet: async (nexusOpsTenant: string, repairSpecId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairSpecsRepairSpecIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairSpecId' is not null or undefined
            assertParamExists('apiV1RepairSpecsRepairSpecIdGet', 'repairSpecId', repairSpecId)
            const localVarPath = `/api/v1/repair/specs/{repairSpecId}`
                .replace(`{${"repairSpecId"}}`, encodeURIComponent(String(repairSpecId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSpecId 
         * @param {UpdateRepairSpecDto} [updateRepairSpecDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSpecsRepairSpecIdPut: async (nexusOpsTenant: string, repairSpecId: string, updateRepairSpecDto?: UpdateRepairSpecDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairSpecsRepairSpecIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairSpecId' is not null or undefined
            assertParamExists('apiV1RepairSpecsRepairSpecIdPut', 'repairSpecId', repairSpecId)
            const localVarPath = `/api/v1/repair/specs/{repairSpecId}`
                .replace(`{${"repairSpecId"}}`, encodeURIComponent(String(repairSpecId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepairSpecDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairSpecSearchPaginatedDto} [repairSpecSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSpecsSearchPost: async (nexusOpsTenant: string, repairSpecSearchPaginatedDto?: RepairSpecSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairSpecsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/specs/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairSpecSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepairSpecsApi - functional programming interface
 * @export
 */
export const RepairSpecsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepairSpecsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairSpecGetPaginatedDto} [repairSpecGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairSpecsGetPost(nexusOpsTenant: string, repairSpecGetPaginatedDto?: RepairSpecGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairSpecsGetPost(nexusOpsTenant, repairSpecGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairSpecsApi.apiV1RepairSpecsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateRepairSpecDto} [createRepairSpecDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairSpecsPost(nexusOpsTenant: string, createRepairSpecDto?: CreateRepairSpecDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairSpecsPost(nexusOpsTenant, createRepairSpecDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairSpecsApi.apiV1RepairSpecsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSpecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairSpecsRepairSpecIdDelete(nexusOpsTenant: string, repairSpecId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairSpecsRepairSpecIdDelete(nexusOpsTenant, repairSpecId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairSpecsApi.apiV1RepairSpecsRepairSpecIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSpecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairSpecsRepairSpecIdGet(nexusOpsTenant: string, repairSpecId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairSpecsRepairSpecIdGet(nexusOpsTenant, repairSpecId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairSpecsApi.apiV1RepairSpecsRepairSpecIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairSpecId 
         * @param {UpdateRepairSpecDto} [updateRepairSpecDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairSpecsRepairSpecIdPut(nexusOpsTenant: string, repairSpecId: string, updateRepairSpecDto?: UpdateRepairSpecDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairSpecsRepairSpecIdPut(nexusOpsTenant, repairSpecId, updateRepairSpecDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairSpecsApi.apiV1RepairSpecsRepairSpecIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairSpecSearchPaginatedDto} [repairSpecSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairSpecsSearchPost(nexusOpsTenant: string, repairSpecSearchPaginatedDto?: RepairSpecSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairSpecsSearchPost(nexusOpsTenant, repairSpecSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairSpecsApi.apiV1RepairSpecsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RepairSpecsApi - factory interface
 * @export
 */
export const RepairSpecsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepairSpecsApiFp(configuration)
    return {
        /**
         * 
         * @param {RepairSpecsApiApiV1RepairSpecsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSpecsGetPost(requestParameters: RepairSpecsApiApiV1RepairSpecsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairSpecDto> {
            return localVarFp.apiV1RepairSpecsGetPost(requestParameters.nexusOpsTenant, requestParameters.repairSpecGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairSpecsApiApiV1RepairSpecsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSpecsPost(requestParameters: RepairSpecsApiApiV1RepairSpecsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairSpecDto> {
            return localVarFp.apiV1RepairSpecsPost(requestParameters.nexusOpsTenant, requestParameters.createRepairSpecDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairSpecsApiApiV1RepairSpecsRepairSpecIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSpecsRepairSpecIdDelete(requestParameters: RepairSpecsApiApiV1RepairSpecsRepairSpecIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1RepairSpecsRepairSpecIdDelete(requestParameters.nexusOpsTenant, requestParameters.repairSpecId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairSpecsApiApiV1RepairSpecsRepairSpecIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSpecsRepairSpecIdGet(requestParameters: RepairSpecsApiApiV1RepairSpecsRepairSpecIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairSpecDto> {
            return localVarFp.apiV1RepairSpecsRepairSpecIdGet(requestParameters.nexusOpsTenant, requestParameters.repairSpecId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairSpecsApiApiV1RepairSpecsRepairSpecIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSpecsRepairSpecIdPut(requestParameters: RepairSpecsApiApiV1RepairSpecsRepairSpecIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairSpecDto> {
            return localVarFp.apiV1RepairSpecsRepairSpecIdPut(requestParameters.nexusOpsTenant, requestParameters.repairSpecId, requestParameters.updateRepairSpecDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairSpecsApiApiV1RepairSpecsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairSpecsSearchPost(requestParameters: RepairSpecsApiApiV1RepairSpecsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairSpecDto> {
            return localVarFp.apiV1RepairSpecsSearchPost(requestParameters.nexusOpsTenant, requestParameters.repairSpecSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1RepairSpecsGetPost operation in RepairSpecsApi.
 * @export
 * @interface RepairSpecsApiApiV1RepairSpecsGetPostRequest
 */
export interface RepairSpecsApiApiV1RepairSpecsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairSpecsApiApiV1RepairSpecsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RepairSpecGetPaginatedDto}
     * @memberof RepairSpecsApiApiV1RepairSpecsGetPost
     */
    readonly repairSpecGetPaginatedDto?: RepairSpecGetPaginatedDto
}

/**
 * Request parameters for apiV1RepairSpecsPost operation in RepairSpecsApi.
 * @export
 * @interface RepairSpecsApiApiV1RepairSpecsPostRequest
 */
export interface RepairSpecsApiApiV1RepairSpecsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairSpecsApiApiV1RepairSpecsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateRepairSpecDto}
     * @memberof RepairSpecsApiApiV1RepairSpecsPost
     */
    readonly createRepairSpecDto?: CreateRepairSpecDto
}

/**
 * Request parameters for apiV1RepairSpecsRepairSpecIdDelete operation in RepairSpecsApi.
 * @export
 * @interface RepairSpecsApiApiV1RepairSpecsRepairSpecIdDeleteRequest
 */
export interface RepairSpecsApiApiV1RepairSpecsRepairSpecIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairSpecsApiApiV1RepairSpecsRepairSpecIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairSpecsApiApiV1RepairSpecsRepairSpecIdDelete
     */
    readonly repairSpecId: string
}

/**
 * Request parameters for apiV1RepairSpecsRepairSpecIdGet operation in RepairSpecsApi.
 * @export
 * @interface RepairSpecsApiApiV1RepairSpecsRepairSpecIdGetRequest
 */
export interface RepairSpecsApiApiV1RepairSpecsRepairSpecIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairSpecsApiApiV1RepairSpecsRepairSpecIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairSpecsApiApiV1RepairSpecsRepairSpecIdGet
     */
    readonly repairSpecId: string
}

/**
 * Request parameters for apiV1RepairSpecsRepairSpecIdPut operation in RepairSpecsApi.
 * @export
 * @interface RepairSpecsApiApiV1RepairSpecsRepairSpecIdPutRequest
 */
export interface RepairSpecsApiApiV1RepairSpecsRepairSpecIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairSpecsApiApiV1RepairSpecsRepairSpecIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairSpecsApiApiV1RepairSpecsRepairSpecIdPut
     */
    readonly repairSpecId: string

    /**
     * 
     * @type {UpdateRepairSpecDto}
     * @memberof RepairSpecsApiApiV1RepairSpecsRepairSpecIdPut
     */
    readonly updateRepairSpecDto?: UpdateRepairSpecDto
}

/**
 * Request parameters for apiV1RepairSpecsSearchPost operation in RepairSpecsApi.
 * @export
 * @interface RepairSpecsApiApiV1RepairSpecsSearchPostRequest
 */
export interface RepairSpecsApiApiV1RepairSpecsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairSpecsApiApiV1RepairSpecsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RepairSpecSearchPaginatedDto}
     * @memberof RepairSpecsApiApiV1RepairSpecsSearchPost
     */
    readonly repairSpecSearchPaginatedDto?: RepairSpecSearchPaginatedDto
}

/**
 * RepairSpecsApi - object-oriented interface
 * @export
 * @class RepairSpecsApi
 * @extends {BaseAPI}
 */
export class RepairSpecsApi extends BaseAPI {
    /**
     * 
     * @param {RepairSpecsApiApiV1RepairSpecsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairSpecsApi
     */
    public apiV1RepairSpecsGetPost(requestParameters: RepairSpecsApiApiV1RepairSpecsGetPostRequest, options?: RawAxiosRequestConfig) {
        return RepairSpecsApiFp(this.configuration).apiV1RepairSpecsGetPost(requestParameters.nexusOpsTenant, requestParameters.repairSpecGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairSpecsApiApiV1RepairSpecsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairSpecsApi
     */
    public apiV1RepairSpecsPost(requestParameters: RepairSpecsApiApiV1RepairSpecsPostRequest, options?: RawAxiosRequestConfig) {
        return RepairSpecsApiFp(this.configuration).apiV1RepairSpecsPost(requestParameters.nexusOpsTenant, requestParameters.createRepairSpecDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairSpecsApiApiV1RepairSpecsRepairSpecIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairSpecsApi
     */
    public apiV1RepairSpecsRepairSpecIdDelete(requestParameters: RepairSpecsApiApiV1RepairSpecsRepairSpecIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return RepairSpecsApiFp(this.configuration).apiV1RepairSpecsRepairSpecIdDelete(requestParameters.nexusOpsTenant, requestParameters.repairSpecId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairSpecsApiApiV1RepairSpecsRepairSpecIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairSpecsApi
     */
    public apiV1RepairSpecsRepairSpecIdGet(requestParameters: RepairSpecsApiApiV1RepairSpecsRepairSpecIdGetRequest, options?: RawAxiosRequestConfig) {
        return RepairSpecsApiFp(this.configuration).apiV1RepairSpecsRepairSpecIdGet(requestParameters.nexusOpsTenant, requestParameters.repairSpecId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairSpecsApiApiV1RepairSpecsRepairSpecIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairSpecsApi
     */
    public apiV1RepairSpecsRepairSpecIdPut(requestParameters: RepairSpecsApiApiV1RepairSpecsRepairSpecIdPutRequest, options?: RawAxiosRequestConfig) {
        return RepairSpecsApiFp(this.configuration).apiV1RepairSpecsRepairSpecIdPut(requestParameters.nexusOpsTenant, requestParameters.repairSpecId, requestParameters.updateRepairSpecDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairSpecsApiApiV1RepairSpecsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairSpecsApi
     */
    public apiV1RepairSpecsSearchPost(requestParameters: RepairSpecsApiApiV1RepairSpecsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return RepairSpecsApiFp(this.configuration).apiV1RepairSpecsSearchPost(requestParameters.nexusOpsTenant, requestParameters.repairSpecSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RepairWorkApi - axios parameter creator
 * @export
 */
export const RepairWorkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairWorkGetPaginatedDto} [repairWorkGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkGetPost: async (nexusOpsTenant: string, repairWorkGetPaginatedDto?: RepairWorkGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairWorkGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/work/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairWorkGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateRepairWorkDto} [createRepairWorkDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkPost: async (nexusOpsTenant: string, createRepairWorkDto?: CreateRepairWorkDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairWorkPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/work`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRepairWorkDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairWorkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkRepairWorkIdDelete: async (nexusOpsTenant: string, repairWorkId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairWorkRepairWorkIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairWorkId' is not null or undefined
            assertParamExists('apiV1RepairWorkRepairWorkIdDelete', 'repairWorkId', repairWorkId)
            const localVarPath = `/api/v1/repair/work/{repairWorkId}`
                .replace(`{${"repairWorkId"}}`, encodeURIComponent(String(repairWorkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairWorkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkRepairWorkIdDeleteInfoGet: async (nexusOpsTenant: string, repairWorkId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairWorkRepairWorkIdDeleteInfoGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairWorkId' is not null or undefined
            assertParamExists('apiV1RepairWorkRepairWorkIdDeleteInfoGet', 'repairWorkId', repairWorkId)
            const localVarPath = `/api/v1/repair/work/{repairWorkId}/delete/info`
                .replace(`{${"repairWorkId"}}`, encodeURIComponent(String(repairWorkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairWorkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkRepairWorkIdGet: async (nexusOpsTenant: string, repairWorkId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairWorkRepairWorkIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairWorkId' is not null or undefined
            assertParamExists('apiV1RepairWorkRepairWorkIdGet', 'repairWorkId', repairWorkId)
            const localVarPath = `/api/v1/repair/work/{repairWorkId}`
                .replace(`{${"repairWorkId"}}`, encodeURIComponent(String(repairWorkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairWorkId 
         * @param {UpdateRepairWorkDto} [updateRepairWorkDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkRepairWorkIdPut: async (nexusOpsTenant: string, repairWorkId: string, updateRepairWorkDto?: UpdateRepairWorkDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairWorkRepairWorkIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'repairWorkId' is not null or undefined
            assertParamExists('apiV1RepairWorkRepairWorkIdPut', 'repairWorkId', repairWorkId)
            const localVarPath = `/api/v1/repair/work/{repairWorkId}`
                .replace(`{${"repairWorkId"}}`, encodeURIComponent(String(repairWorkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRepairWorkDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairWorkSearchPaginatedDto} [repairWorkSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkSearchPost: async (nexusOpsTenant: string, repairWorkSearchPaginatedDto?: RepairWorkSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1RepairWorkSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/repair/work/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repairWorkSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepairWorkApi - functional programming interface
 * @export
 */
export const RepairWorkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepairWorkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairWorkGetPaginatedDto} [repairWorkGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairWorkGetPost(nexusOpsTenant: string, repairWorkGetPaginatedDto?: RepairWorkGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairWorkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairWorkGetPost(nexusOpsTenant, repairWorkGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairWorkApi.apiV1RepairWorkGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateRepairWorkDto} [createRepairWorkDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairWorkPost(nexusOpsTenant: string, createRepairWorkDto?: CreateRepairWorkDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairWorkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairWorkPost(nexusOpsTenant, createRepairWorkDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairWorkApi.apiV1RepairWorkPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairWorkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairWorkRepairWorkIdDelete(nexusOpsTenant: string, repairWorkId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairWorkRepairWorkIdDelete(nexusOpsTenant, repairWorkId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairWorkApi.apiV1RepairWorkRepairWorkIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairWorkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairWorkRepairWorkIdDeleteInfoGet(nexusOpsTenant: string, repairWorkId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteRepairWorkInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairWorkRepairWorkIdDeleteInfoGet(nexusOpsTenant, repairWorkId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairWorkApi.apiV1RepairWorkRepairWorkIdDeleteInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairWorkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairWorkRepairWorkIdGet(nexusOpsTenant: string, repairWorkId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairWorkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairWorkRepairWorkIdGet(nexusOpsTenant, repairWorkId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairWorkApi.apiV1RepairWorkRepairWorkIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} repairWorkId 
         * @param {UpdateRepairWorkDto} [updateRepairWorkDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairWorkRepairWorkIdPut(nexusOpsTenant: string, repairWorkId: string, updateRepairWorkDto?: UpdateRepairWorkDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairWorkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairWorkRepairWorkIdPut(nexusOpsTenant, repairWorkId, updateRepairWorkDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairWorkApi.apiV1RepairWorkRepairWorkIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {RepairWorkSearchPaginatedDto} [repairWorkSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepairWorkSearchPost(nexusOpsTenant: string, repairWorkSearchPaginatedDto?: RepairWorkSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfRepairWorkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepairWorkSearchPost(nexusOpsTenant, repairWorkSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RepairWorkApi.apiV1RepairWorkSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RepairWorkApi - factory interface
 * @export
 */
export const RepairWorkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepairWorkApiFp(configuration)
    return {
        /**
         * 
         * @param {RepairWorkApiApiV1RepairWorkGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkGetPost(requestParameters: RepairWorkApiApiV1RepairWorkGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairWorkDto> {
            return localVarFp.apiV1RepairWorkGetPost(requestParameters.nexusOpsTenant, requestParameters.repairWorkGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairWorkApiApiV1RepairWorkPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkPost(requestParameters: RepairWorkApiApiV1RepairWorkPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairWorkDto> {
            return localVarFp.apiV1RepairWorkPost(requestParameters.nexusOpsTenant, requestParameters.createRepairWorkDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkRepairWorkIdDelete(requestParameters: RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1RepairWorkRepairWorkIdDelete(requestParameters.nexusOpsTenant, requestParameters.repairWorkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteInfoGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkRepairWorkIdDeleteInfoGet(requestParameters: RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteInfoGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteRepairWorkInfoDto> {
            return localVarFp.apiV1RepairWorkRepairWorkIdDeleteInfoGet(requestParameters.nexusOpsTenant, requestParameters.repairWorkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairWorkApiApiV1RepairWorkRepairWorkIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkRepairWorkIdGet(requestParameters: RepairWorkApiApiV1RepairWorkRepairWorkIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairWorkDto> {
            return localVarFp.apiV1RepairWorkRepairWorkIdGet(requestParameters.nexusOpsTenant, requestParameters.repairWorkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairWorkApiApiV1RepairWorkRepairWorkIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkRepairWorkIdPut(requestParameters: RepairWorkApiApiV1RepairWorkRepairWorkIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<RepairWorkDto> {
            return localVarFp.apiV1RepairWorkRepairWorkIdPut(requestParameters.nexusOpsTenant, requestParameters.repairWorkId, requestParameters.updateRepairWorkDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepairWorkApiApiV1RepairWorkSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepairWorkSearchPost(requestParameters: RepairWorkApiApiV1RepairWorkSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfRepairWorkDto> {
            return localVarFp.apiV1RepairWorkSearchPost(requestParameters.nexusOpsTenant, requestParameters.repairWorkSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1RepairWorkGetPost operation in RepairWorkApi.
 * @export
 * @interface RepairWorkApiApiV1RepairWorkGetPostRequest
 */
export interface RepairWorkApiApiV1RepairWorkGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairWorkApiApiV1RepairWorkGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RepairWorkGetPaginatedDto}
     * @memberof RepairWorkApiApiV1RepairWorkGetPost
     */
    readonly repairWorkGetPaginatedDto?: RepairWorkGetPaginatedDto
}

/**
 * Request parameters for apiV1RepairWorkPost operation in RepairWorkApi.
 * @export
 * @interface RepairWorkApiApiV1RepairWorkPostRequest
 */
export interface RepairWorkApiApiV1RepairWorkPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairWorkApiApiV1RepairWorkPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateRepairWorkDto}
     * @memberof RepairWorkApiApiV1RepairWorkPost
     */
    readonly createRepairWorkDto?: CreateRepairWorkDto
}

/**
 * Request parameters for apiV1RepairWorkRepairWorkIdDelete operation in RepairWorkApi.
 * @export
 * @interface RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteRequest
 */
export interface RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairWorkApiApiV1RepairWorkRepairWorkIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairWorkApiApiV1RepairWorkRepairWorkIdDelete
     */
    readonly repairWorkId: string
}

/**
 * Request parameters for apiV1RepairWorkRepairWorkIdDeleteInfoGet operation in RepairWorkApi.
 * @export
 * @interface RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteInfoGetRequest
 */
export interface RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteInfoGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteInfoGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteInfoGet
     */
    readonly repairWorkId: string
}

/**
 * Request parameters for apiV1RepairWorkRepairWorkIdGet operation in RepairWorkApi.
 * @export
 * @interface RepairWorkApiApiV1RepairWorkRepairWorkIdGetRequest
 */
export interface RepairWorkApiApiV1RepairWorkRepairWorkIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairWorkApiApiV1RepairWorkRepairWorkIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairWorkApiApiV1RepairWorkRepairWorkIdGet
     */
    readonly repairWorkId: string
}

/**
 * Request parameters for apiV1RepairWorkRepairWorkIdPut operation in RepairWorkApi.
 * @export
 * @interface RepairWorkApiApiV1RepairWorkRepairWorkIdPutRequest
 */
export interface RepairWorkApiApiV1RepairWorkRepairWorkIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairWorkApiApiV1RepairWorkRepairWorkIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof RepairWorkApiApiV1RepairWorkRepairWorkIdPut
     */
    readonly repairWorkId: string

    /**
     * 
     * @type {UpdateRepairWorkDto}
     * @memberof RepairWorkApiApiV1RepairWorkRepairWorkIdPut
     */
    readonly updateRepairWorkDto?: UpdateRepairWorkDto
}

/**
 * Request parameters for apiV1RepairWorkSearchPost operation in RepairWorkApi.
 * @export
 * @interface RepairWorkApiApiV1RepairWorkSearchPostRequest
 */
export interface RepairWorkApiApiV1RepairWorkSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof RepairWorkApiApiV1RepairWorkSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {RepairWorkSearchPaginatedDto}
     * @memberof RepairWorkApiApiV1RepairWorkSearchPost
     */
    readonly repairWorkSearchPaginatedDto?: RepairWorkSearchPaginatedDto
}

/**
 * RepairWorkApi - object-oriented interface
 * @export
 * @class RepairWorkApi
 * @extends {BaseAPI}
 */
export class RepairWorkApi extends BaseAPI {
    /**
     * 
     * @param {RepairWorkApiApiV1RepairWorkGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairWorkApi
     */
    public apiV1RepairWorkGetPost(requestParameters: RepairWorkApiApiV1RepairWorkGetPostRequest, options?: RawAxiosRequestConfig) {
        return RepairWorkApiFp(this.configuration).apiV1RepairWorkGetPost(requestParameters.nexusOpsTenant, requestParameters.repairWorkGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairWorkApiApiV1RepairWorkPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairWorkApi
     */
    public apiV1RepairWorkPost(requestParameters: RepairWorkApiApiV1RepairWorkPostRequest, options?: RawAxiosRequestConfig) {
        return RepairWorkApiFp(this.configuration).apiV1RepairWorkPost(requestParameters.nexusOpsTenant, requestParameters.createRepairWorkDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairWorkApi
     */
    public apiV1RepairWorkRepairWorkIdDelete(requestParameters: RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return RepairWorkApiFp(this.configuration).apiV1RepairWorkRepairWorkIdDelete(requestParameters.nexusOpsTenant, requestParameters.repairWorkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairWorkApi
     */
    public apiV1RepairWorkRepairWorkIdDeleteInfoGet(requestParameters: RepairWorkApiApiV1RepairWorkRepairWorkIdDeleteInfoGetRequest, options?: RawAxiosRequestConfig) {
        return RepairWorkApiFp(this.configuration).apiV1RepairWorkRepairWorkIdDeleteInfoGet(requestParameters.nexusOpsTenant, requestParameters.repairWorkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairWorkApiApiV1RepairWorkRepairWorkIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairWorkApi
     */
    public apiV1RepairWorkRepairWorkIdGet(requestParameters: RepairWorkApiApiV1RepairWorkRepairWorkIdGetRequest, options?: RawAxiosRequestConfig) {
        return RepairWorkApiFp(this.configuration).apiV1RepairWorkRepairWorkIdGet(requestParameters.nexusOpsTenant, requestParameters.repairWorkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairWorkApiApiV1RepairWorkRepairWorkIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairWorkApi
     */
    public apiV1RepairWorkRepairWorkIdPut(requestParameters: RepairWorkApiApiV1RepairWorkRepairWorkIdPutRequest, options?: RawAxiosRequestConfig) {
        return RepairWorkApiFp(this.configuration).apiV1RepairWorkRepairWorkIdPut(requestParameters.nexusOpsTenant, requestParameters.repairWorkId, requestParameters.updateRepairWorkDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepairWorkApiApiV1RepairWorkSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairWorkApi
     */
    public apiV1RepairWorkSearchPost(requestParameters: RepairWorkApiApiV1RepairWorkSearchPostRequest, options?: RawAxiosRequestConfig) {
        return RepairWorkApiFp(this.configuration).apiV1RepairWorkSearchPost(requestParameters.nexusOpsTenant, requestParameters.repairWorkSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SendGridWebhookApi - axios parameter creator
 * @export
 */
export const SendGridWebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebHookSendGridPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web-hook/send-grid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SendGridWebhookApi - functional programming interface
 * @export
 */
export const SendGridWebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SendGridWebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WebHookSendGridPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WebHookSendGridPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SendGridWebhookApi.apiV1WebHookSendGridPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SendGridWebhookApi - factory interface
 * @export
 */
export const SendGridWebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SendGridWebhookApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebHookSendGridPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1WebHookSendGridPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SendGridWebhookApi - object-oriented interface
 * @export
 * @class SendGridWebhookApi
 * @extends {BaseAPI}
 */
export class SendGridWebhookApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SendGridWebhookApi
     */
    public apiV1WebHookSendGridPost(options?: RawAxiosRequestConfig) {
        return SendGridWebhookApiFp(this.configuration).apiV1WebHookSendGridPost(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SpotsApi - axios parameter creator
 * @export
 */
export const SpotsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1SpotsBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/spots/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SpotGetPaginatedDto} [spotGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsGetPost: async (nexusOpsTenant: string, spotGetPaginatedDto?: SpotGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1SpotsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/spots/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(spotGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateSpotDto} [createSpotDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsPost: async (nexusOpsTenant: string, createSpotDto?: CreateSpotDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1SpotsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/spots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSpotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SpotSearchPaginatedDto} [spotSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsSearchPost: async (nexusOpsTenant: string, spotSearchPaginatedDto?: SpotSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1SpotsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/spots/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(spotSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} spotId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsSpotIdDelete: async (nexusOpsTenant: string, spotId: string, isDeleteCascade?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1SpotsSpotIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'spotId' is not null or undefined
            assertParamExists('apiV1SpotsSpotIdDelete', 'spotId', spotId)
            const localVarPath = `/api/v1/spots/{spotId}`
                .replace(`{${"spotId"}}`, encodeURIComponent(String(spotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} spotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsSpotIdGet: async (nexusOpsTenant: string, spotId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1SpotsSpotIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'spotId' is not null or undefined
            assertParamExists('apiV1SpotsSpotIdGet', 'spotId', spotId)
            const localVarPath = `/api/v1/spots/{spotId}`
                .replace(`{${"spotId"}}`, encodeURIComponent(String(spotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} spotId 
         * @param {UpdateSpotDto} [updateSpotDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsSpotIdPut: async (nexusOpsTenant: string, spotId: string, updateSpotDto?: UpdateSpotDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1SpotsSpotIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'spotId' is not null or undefined
            assertParamExists('apiV1SpotsSpotIdPut', 'spotId', spotId)
            const localVarPath = `/api/v1/spots/{spotId}`
                .replace(`{${"spotId"}}`, encodeURIComponent(String(spotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSpotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} spotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsSpotIdReferenceGet: async (nexusOpsTenant: string, spotId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1SpotsSpotIdReferenceGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'spotId' is not null or undefined
            assertParamExists('apiV1SpotsSpotIdReferenceGet', 'spotId', spotId)
            const localVarPath = `/api/v1/spots/{spotId}/reference`
                .replace(`{${"spotId"}}`, encodeURIComponent(String(spotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpotsApi - functional programming interface
 * @export
 */
export const SpotsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpotsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SpotsBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SpotsBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpotsApi.apiV1SpotsBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SpotGetPaginatedDto} [spotGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SpotsGetPost(nexusOpsTenant: string, spotGetPaginatedDto?: SpotGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfSpotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SpotsGetPost(nexusOpsTenant, spotGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpotsApi.apiV1SpotsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateSpotDto} [createSpotDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SpotsPost(nexusOpsTenant: string, createSpotDto?: CreateSpotDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SpotsPost(nexusOpsTenant, createSpotDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpotsApi.apiV1SpotsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SpotSearchPaginatedDto} [spotSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SpotsSearchPost(nexusOpsTenant: string, spotSearchPaginatedDto?: SpotSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfSpotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SpotsSearchPost(nexusOpsTenant, spotSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpotsApi.apiV1SpotsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} spotId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SpotsSpotIdDelete(nexusOpsTenant: string, spotId: string, isDeleteCascade?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SpotsSpotIdDelete(nexusOpsTenant, spotId, isDeleteCascade, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpotsApi.apiV1SpotsSpotIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} spotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SpotsSpotIdGet(nexusOpsTenant: string, spotId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SpotsSpotIdGet(nexusOpsTenant, spotId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpotsApi.apiV1SpotsSpotIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} spotId 
         * @param {UpdateSpotDto} [updateSpotDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SpotsSpotIdPut(nexusOpsTenant: string, spotId: string, updateSpotDto?: UpdateSpotDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SpotsSpotIdPut(nexusOpsTenant, spotId, updateSpotDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpotsApi.apiV1SpotsSpotIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} spotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SpotsSpotIdReferenceGet(nexusOpsTenant: string, spotId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SpotsSpotIdReferenceGet(nexusOpsTenant, spotId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpotsApi.apiV1SpotsSpotIdReferenceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SpotsApi - factory interface
 * @export
 */
export const SpotsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpotsApiFp(configuration)
    return {
        /**
         * 
         * @param {SpotsApiApiV1SpotsBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsBulkDeleteDelete(requestParameters: SpotsApiApiV1SpotsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1SpotsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpotsApiApiV1SpotsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsGetPost(requestParameters: SpotsApiApiV1SpotsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfSpotDto> {
            return localVarFp.apiV1SpotsGetPost(requestParameters.nexusOpsTenant, requestParameters.spotGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpotsApiApiV1SpotsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsPost(requestParameters: SpotsApiApiV1SpotsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<SpotDto> {
            return localVarFp.apiV1SpotsPost(requestParameters.nexusOpsTenant, requestParameters.createSpotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpotsApiApiV1SpotsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsSearchPost(requestParameters: SpotsApiApiV1SpotsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfSpotDto> {
            return localVarFp.apiV1SpotsSearchPost(requestParameters.nexusOpsTenant, requestParameters.spotSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpotsApiApiV1SpotsSpotIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsSpotIdDelete(requestParameters: SpotsApiApiV1SpotsSpotIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1SpotsSpotIdDelete(requestParameters.nexusOpsTenant, requestParameters.spotId, requestParameters.isDeleteCascade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpotsApiApiV1SpotsSpotIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsSpotIdGet(requestParameters: SpotsApiApiV1SpotsSpotIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<SpotDto> {
            return localVarFp.apiV1SpotsSpotIdGet(requestParameters.nexusOpsTenant, requestParameters.spotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpotsApiApiV1SpotsSpotIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsSpotIdPut(requestParameters: SpotsApiApiV1SpotsSpotIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<SpotDto> {
            return localVarFp.apiV1SpotsSpotIdPut(requestParameters.nexusOpsTenant, requestParameters.spotId, requestParameters.updateSpotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpotsApiApiV1SpotsSpotIdReferenceGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SpotsSpotIdReferenceGet(requestParameters: SpotsApiApiV1SpotsSpotIdReferenceGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<SpotReferenceDto> {
            return localVarFp.apiV1SpotsSpotIdReferenceGet(requestParameters.nexusOpsTenant, requestParameters.spotId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1SpotsBulkDeleteDelete operation in SpotsApi.
 * @export
 * @interface SpotsApiApiV1SpotsBulkDeleteDeleteRequest
 */
export interface SpotsApiApiV1SpotsBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof SpotsApiApiV1SpotsBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof SpotsApiApiV1SpotsBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1SpotsGetPost operation in SpotsApi.
 * @export
 * @interface SpotsApiApiV1SpotsGetPostRequest
 */
export interface SpotsApiApiV1SpotsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof SpotsApiApiV1SpotsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {SpotGetPaginatedDto}
     * @memberof SpotsApiApiV1SpotsGetPost
     */
    readonly spotGetPaginatedDto?: SpotGetPaginatedDto
}

/**
 * Request parameters for apiV1SpotsPost operation in SpotsApi.
 * @export
 * @interface SpotsApiApiV1SpotsPostRequest
 */
export interface SpotsApiApiV1SpotsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof SpotsApiApiV1SpotsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateSpotDto}
     * @memberof SpotsApiApiV1SpotsPost
     */
    readonly createSpotDto?: CreateSpotDto
}

/**
 * Request parameters for apiV1SpotsSearchPost operation in SpotsApi.
 * @export
 * @interface SpotsApiApiV1SpotsSearchPostRequest
 */
export interface SpotsApiApiV1SpotsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof SpotsApiApiV1SpotsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {SpotSearchPaginatedDto}
     * @memberof SpotsApiApiV1SpotsSearchPost
     */
    readonly spotSearchPaginatedDto?: SpotSearchPaginatedDto
}

/**
 * Request parameters for apiV1SpotsSpotIdDelete operation in SpotsApi.
 * @export
 * @interface SpotsApiApiV1SpotsSpotIdDeleteRequest
 */
export interface SpotsApiApiV1SpotsSpotIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof SpotsApiApiV1SpotsSpotIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof SpotsApiApiV1SpotsSpotIdDelete
     */
    readonly spotId: string

    /**
     * 
     * @type {boolean}
     * @memberof SpotsApiApiV1SpotsSpotIdDelete
     */
    readonly isDeleteCascade?: boolean
}

/**
 * Request parameters for apiV1SpotsSpotIdGet operation in SpotsApi.
 * @export
 * @interface SpotsApiApiV1SpotsSpotIdGetRequest
 */
export interface SpotsApiApiV1SpotsSpotIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof SpotsApiApiV1SpotsSpotIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof SpotsApiApiV1SpotsSpotIdGet
     */
    readonly spotId: string
}

/**
 * Request parameters for apiV1SpotsSpotIdPut operation in SpotsApi.
 * @export
 * @interface SpotsApiApiV1SpotsSpotIdPutRequest
 */
export interface SpotsApiApiV1SpotsSpotIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof SpotsApiApiV1SpotsSpotIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof SpotsApiApiV1SpotsSpotIdPut
     */
    readonly spotId: string

    /**
     * 
     * @type {UpdateSpotDto}
     * @memberof SpotsApiApiV1SpotsSpotIdPut
     */
    readonly updateSpotDto?: UpdateSpotDto
}

/**
 * Request parameters for apiV1SpotsSpotIdReferenceGet operation in SpotsApi.
 * @export
 * @interface SpotsApiApiV1SpotsSpotIdReferenceGetRequest
 */
export interface SpotsApiApiV1SpotsSpotIdReferenceGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof SpotsApiApiV1SpotsSpotIdReferenceGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof SpotsApiApiV1SpotsSpotIdReferenceGet
     */
    readonly spotId: string
}

/**
 * SpotsApi - object-oriented interface
 * @export
 * @class SpotsApi
 * @extends {BaseAPI}
 */
export class SpotsApi extends BaseAPI {
    /**
     * 
     * @param {SpotsApiApiV1SpotsBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public apiV1SpotsBulkDeleteDelete(requestParameters: SpotsApiApiV1SpotsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return SpotsApiFp(this.configuration).apiV1SpotsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotsApiApiV1SpotsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public apiV1SpotsGetPost(requestParameters: SpotsApiApiV1SpotsGetPostRequest, options?: RawAxiosRequestConfig) {
        return SpotsApiFp(this.configuration).apiV1SpotsGetPost(requestParameters.nexusOpsTenant, requestParameters.spotGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotsApiApiV1SpotsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public apiV1SpotsPost(requestParameters: SpotsApiApiV1SpotsPostRequest, options?: RawAxiosRequestConfig) {
        return SpotsApiFp(this.configuration).apiV1SpotsPost(requestParameters.nexusOpsTenant, requestParameters.createSpotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotsApiApiV1SpotsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public apiV1SpotsSearchPost(requestParameters: SpotsApiApiV1SpotsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return SpotsApiFp(this.configuration).apiV1SpotsSearchPost(requestParameters.nexusOpsTenant, requestParameters.spotSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotsApiApiV1SpotsSpotIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public apiV1SpotsSpotIdDelete(requestParameters: SpotsApiApiV1SpotsSpotIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return SpotsApiFp(this.configuration).apiV1SpotsSpotIdDelete(requestParameters.nexusOpsTenant, requestParameters.spotId, requestParameters.isDeleteCascade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotsApiApiV1SpotsSpotIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public apiV1SpotsSpotIdGet(requestParameters: SpotsApiApiV1SpotsSpotIdGetRequest, options?: RawAxiosRequestConfig) {
        return SpotsApiFp(this.configuration).apiV1SpotsSpotIdGet(requestParameters.nexusOpsTenant, requestParameters.spotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotsApiApiV1SpotsSpotIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public apiV1SpotsSpotIdPut(requestParameters: SpotsApiApiV1SpotsSpotIdPutRequest, options?: RawAxiosRequestConfig) {
        return SpotsApiFp(this.configuration).apiV1SpotsSpotIdPut(requestParameters.nexusOpsTenant, requestParameters.spotId, requestParameters.updateSpotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotsApiApiV1SpotsSpotIdReferenceGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public apiV1SpotsSpotIdReferenceGet(requestParameters: SpotsApiApiV1SpotsSpotIdReferenceGetRequest, options?: RawAxiosRequestConfig) {
        return SpotsApiFp(this.configuration).apiV1SpotsSpotIdReferenceGet(requestParameters.nexusOpsTenant, requestParameters.spotId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SubscriptionPlansApi - axios parameter creator
 * @export
 */
export const SubscriptionPlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionsPlansGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1SubscriptionsPlansGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/subscriptions/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionPlansApi - functional programming interface
 * @export
 */
export const SubscriptionPlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionPlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SubscriptionsPlansGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionPlanDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SubscriptionsPlansGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionPlansApi.apiV1SubscriptionsPlansGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SubscriptionPlansApi - factory interface
 * @export
 */
export const SubscriptionPlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionPlansApiFp(configuration)
    return {
        /**
         * 
         * @param {SubscriptionPlansApiApiV1SubscriptionsPlansGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionsPlansGet(requestParameters: SubscriptionPlansApiApiV1SubscriptionsPlansGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<SubscriptionPlanDto>> {
            return localVarFp.apiV1SubscriptionsPlansGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1SubscriptionsPlansGet operation in SubscriptionPlansApi.
 * @export
 * @interface SubscriptionPlansApiApiV1SubscriptionsPlansGetRequest
 */
export interface SubscriptionPlansApiApiV1SubscriptionsPlansGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof SubscriptionPlansApiApiV1SubscriptionsPlansGet
     */
    readonly nexusOpsTenant: string
}

/**
 * SubscriptionPlansApi - object-oriented interface
 * @export
 * @class SubscriptionPlansApi
 * @extends {BaseAPI}
 */
export class SubscriptionPlansApi extends BaseAPI {
    /**
     * 
     * @param {SubscriptionPlansApiApiV1SubscriptionsPlansGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionPlansApi
     */
    public apiV1SubscriptionsPlansGet(requestParameters: SubscriptionPlansApiApiV1SubscriptionsPlansGetRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionPlansApiFp(this.configuration).apiV1SubscriptionsPlansGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionsCurrentGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1SubscriptionsCurrentGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/subscriptions/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SubscriptionGetPaginatedDto} [subscriptionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionsGetPost: async (nexusOpsTenant: string, subscriptionGetPaginatedDto?: SubscriptionGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1SubscriptionsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/subscriptions/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SubscriptionsCurrentGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SubscriptionsCurrentGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.apiV1SubscriptionsCurrentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SubscriptionGetPaginatedDto} [subscriptionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SubscriptionsGetPost(nexusOpsTenant: string, subscriptionGetPaginatedDto?: SubscriptionGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfSubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SubscriptionsGetPost(nexusOpsTenant, subscriptionGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.apiV1SubscriptionsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionsApiFp(configuration)
    return {
        /**
         * 
         * @param {SubscriptionsApiApiV1SubscriptionsCurrentGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionsCurrentGet(requestParameters: SubscriptionsApiApiV1SubscriptionsCurrentGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<SubscriptionDto> {
            return localVarFp.apiV1SubscriptionsCurrentGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubscriptionsApiApiV1SubscriptionsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionsGetPost(requestParameters: SubscriptionsApiApiV1SubscriptionsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfSubscriptionDto> {
            return localVarFp.apiV1SubscriptionsGetPost(requestParameters.nexusOpsTenant, requestParameters.subscriptionGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1SubscriptionsCurrentGet operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiApiV1SubscriptionsCurrentGetRequest
 */
export interface SubscriptionsApiApiV1SubscriptionsCurrentGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof SubscriptionsApiApiV1SubscriptionsCurrentGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1SubscriptionsGetPost operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiApiV1SubscriptionsGetPostRequest
 */
export interface SubscriptionsApiApiV1SubscriptionsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof SubscriptionsApiApiV1SubscriptionsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {SubscriptionGetPaginatedDto}
     * @memberof SubscriptionsApiApiV1SubscriptionsGetPost
     */
    readonly subscriptionGetPaginatedDto?: SubscriptionGetPaginatedDto
}

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
    /**
     * 
     * @param {SubscriptionsApiApiV1SubscriptionsCurrentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public apiV1SubscriptionsCurrentGet(requestParameters: SubscriptionsApiApiV1SubscriptionsCurrentGetRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).apiV1SubscriptionsCurrentGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubscriptionsApiApiV1SubscriptionsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public apiV1SubscriptionsGetPost(requestParameters: SubscriptionsApiApiV1SubscriptionsGetPostRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).apiV1SubscriptionsGetPost(requestParameters.nexusOpsTenant, requestParameters.subscriptionGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantAccountApi - axios parameter creator
 * @export
 */
export const TenantAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountPermissionsGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantAccountPermissionsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/account/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} [targetUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRoleAssignmentsRestrictionsGet: async (nexusOpsTenant: string, targetUserId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantAccountRoleAssignmentsRestrictionsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/account/role-assignments/restrictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetUserId !== undefined) {
                localVarQueryParameter['targetUserId'] = targetUserId;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [roleIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRolesByIdsGet: async (nexusOpsTenant: string, roleIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantAccountRolesByIdsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/account/roles/by-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (roleIds) {
                localVarQueryParameter['roleIds'] = roleIds;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} [sortBy] Comma-separated list of fields to sort by.
         * @param {string} [sortOrder] Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRolesGet: async (nexusOpsTenant: string, sortBy?: string, sortOrder?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantAccountRolesGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/account/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateTenantRoleDto} [createTenantRoleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRolesPost: async (nexusOpsTenant: string, createTenantRoleDto?: CreateTenantRoleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantAccountRolesPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/account/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTenantRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRolesRoleIdDelete: async (nexusOpsTenant: string, roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantAccountRolesRoleIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiV1TenantAccountRolesRoleIdDelete', 'roleId', roleId)
            const localVarPath = `/api/v1/tenant/account/roles/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRolesRoleIdGet: async (nexusOpsTenant: string, roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantAccountRolesRoleIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiV1TenantAccountRolesRoleIdGet', 'roleId', roleId)
            const localVarPath = `/api/v1/tenant/account/roles/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} roleId 
         * @param {UpdateTenantRoleDto} [updateTenantRoleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRolesRoleIdPut: async (nexusOpsTenant: string, roleId: string, updateTenantRoleDto?: UpdateTenantRoleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantAccountRolesRoleIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiV1TenantAccountRolesRoleIdPut', 'roleId', roleId)
            const localVarPath = `/api/v1/tenant/account/roles/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTenantRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {UpdateUserRolesDto} [updateUserRolesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountUsersUserIdRolesPut: async (nexusOpsTenant: string, userId: string, updateUserRolesDto?: UpdateUserRolesDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantAccountUsersUserIdRolesPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1TenantAccountUsersUserIdRolesPut', 'userId', userId)
            const localVarPath = `/api/v1/tenant/account/users/{userId}/roles`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRolesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantAccountApi - functional programming interface
 * @export
 */
export const TenantAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantAccountPermissionsGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantAccountPermissionsGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantAccountApi.apiV1TenantAccountPermissionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} [targetUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantAccountRoleAssignmentsRestrictionsGet(nexusOpsTenant: string, targetUserId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleAssignmentRestrictionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantAccountRoleAssignmentsRestrictionsGet(nexusOpsTenant, targetUserId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantAccountApi.apiV1TenantAccountRoleAssignmentsRestrictionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [roleIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantAccountRolesByIdsGet(nexusOpsTenant: string, roleIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantAccountRolesByIdsGet(nexusOpsTenant, roleIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantAccountApi.apiV1TenantAccountRolesByIdsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} [sortBy] Comma-separated list of fields to sort by.
         * @param {string} [sortOrder] Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantAccountRolesGet(nexusOpsTenant: string, sortBy?: string, sortOrder?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantAccountRolesGet(nexusOpsTenant, sortBy, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantAccountApi.apiV1TenantAccountRolesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateTenantRoleDto} [createTenantRoleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantAccountRolesPost(nexusOpsTenant: string, createTenantRoleDto?: CreateTenantRoleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantAccountRolesPost(nexusOpsTenant, createTenantRoleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantAccountApi.apiV1TenantAccountRolesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantAccountRolesRoleIdDelete(nexusOpsTenant: string, roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantAccountRolesRoleIdDelete(nexusOpsTenant, roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantAccountApi.apiV1TenantAccountRolesRoleIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantAccountRolesRoleIdGet(nexusOpsTenant: string, roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantAccountRolesRoleIdGet(nexusOpsTenant, roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantAccountApi.apiV1TenantAccountRolesRoleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} roleId 
         * @param {UpdateTenantRoleDto} [updateTenantRoleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantAccountRolesRoleIdPut(nexusOpsTenant: string, roleId: string, updateTenantRoleDto?: UpdateTenantRoleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantAccountRolesRoleIdPut(nexusOpsTenant, roleId, updateTenantRoleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantAccountApi.apiV1TenantAccountRolesRoleIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {UpdateUserRolesDto} [updateUserRolesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantAccountUsersUserIdRolesPut(nexusOpsTenant: string, userId: string, updateUserRolesDto?: UpdateUserRolesDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantAccountUsersUserIdRolesPut(nexusOpsTenant, userId, updateUserRolesDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantAccountApi.apiV1TenantAccountUsersUserIdRolesPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantAccountApi - factory interface
 * @export
 */
export const TenantAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantAccountApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantAccountApiApiV1TenantAccountPermissionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountPermissionsGet(requestParameters: TenantAccountApiApiV1TenantAccountPermissionsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PermissionDto>> {
            return localVarFp.apiV1TenantAccountPermissionsGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantAccountApiApiV1TenantAccountRoleAssignmentsRestrictionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRoleAssignmentsRestrictionsGet(requestParameters: TenantAccountApiApiV1TenantAccountRoleAssignmentsRestrictionsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoleAssignmentRestrictionsDto> {
            return localVarFp.apiV1TenantAccountRoleAssignmentsRestrictionsGet(requestParameters.nexusOpsTenant, requestParameters.targetUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantAccountApiApiV1TenantAccountRolesByIdsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRolesByIdsGet(requestParameters: TenantAccountApiApiV1TenantAccountRolesByIdsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<RoleDto>> {
            return localVarFp.apiV1TenantAccountRolesByIdsGet(requestParameters.nexusOpsTenant, requestParameters.roleIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantAccountApiApiV1TenantAccountRolesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRolesGet(requestParameters: TenantAccountApiApiV1TenantAccountRolesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<RoleDto>> {
            return localVarFp.apiV1TenantAccountRolesGet(requestParameters.nexusOpsTenant, requestParameters.sortBy, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantAccountApiApiV1TenantAccountRolesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRolesPost(requestParameters: TenantAccountApiApiV1TenantAccountRolesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoleDto> {
            return localVarFp.apiV1TenantAccountRolesPost(requestParameters.nexusOpsTenant, requestParameters.createTenantRoleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantAccountApiApiV1TenantAccountRolesRoleIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRolesRoleIdDelete(requestParameters: TenantAccountApiApiV1TenantAccountRolesRoleIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TenantAccountRolesRoleIdDelete(requestParameters.nexusOpsTenant, requestParameters.roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantAccountApiApiV1TenantAccountRolesRoleIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRolesRoleIdGet(requestParameters: TenantAccountApiApiV1TenantAccountRolesRoleIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoleDto> {
            return localVarFp.apiV1TenantAccountRolesRoleIdGet(requestParameters.nexusOpsTenant, requestParameters.roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantAccountApiApiV1TenantAccountRolesRoleIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountRolesRoleIdPut(requestParameters: TenantAccountApiApiV1TenantAccountRolesRoleIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoleDto> {
            return localVarFp.apiV1TenantAccountRolesRoleIdPut(requestParameters.nexusOpsTenant, requestParameters.roleId, requestParameters.updateTenantRoleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantAccountApiApiV1TenantAccountUsersUserIdRolesPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantAccountUsersUserIdRolesPut(requestParameters: TenantAccountApiApiV1TenantAccountUsersUserIdRolesPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TenantAccountUsersUserIdRolesPut(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.updateUserRolesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TenantAccountPermissionsGet operation in TenantAccountApi.
 * @export
 * @interface TenantAccountApiApiV1TenantAccountPermissionsGetRequest
 */
export interface TenantAccountApiApiV1TenantAccountPermissionsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountPermissionsGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1TenantAccountRoleAssignmentsRestrictionsGet operation in TenantAccountApi.
 * @export
 * @interface TenantAccountApiApiV1TenantAccountRoleAssignmentsRestrictionsGetRequest
 */
export interface TenantAccountApiApiV1TenantAccountRoleAssignmentsRestrictionsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountRoleAssignmentsRestrictionsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountRoleAssignmentsRestrictionsGet
     */
    readonly targetUserId?: string
}

/**
 * Request parameters for apiV1TenantAccountRolesByIdsGet operation in TenantAccountApi.
 * @export
 * @interface TenantAccountApiApiV1TenantAccountRolesByIdsGetRequest
 */
export interface TenantAccountApiApiV1TenantAccountRolesByIdsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountRolesByIdsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {Array<string>}
     * @memberof TenantAccountApiApiV1TenantAccountRolesByIdsGet
     */
    readonly roleIds?: Array<string>
}

/**
 * Request parameters for apiV1TenantAccountRolesGet operation in TenantAccountApi.
 * @export
 * @interface TenantAccountApiApiV1TenantAccountRolesGetRequest
 */
export interface TenantAccountApiApiV1TenantAccountRolesGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountRolesGet
     */
    readonly nexusOpsTenant: string

    /**
     * Comma-separated list of fields to sort by.
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountRolesGet
     */
    readonly sortBy?: string

    /**
     * Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountRolesGet
     */
    readonly sortOrder?: string
}

/**
 * Request parameters for apiV1TenantAccountRolesPost operation in TenantAccountApi.
 * @export
 * @interface TenantAccountApiApiV1TenantAccountRolesPostRequest
 */
export interface TenantAccountApiApiV1TenantAccountRolesPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountRolesPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateTenantRoleDto}
     * @memberof TenantAccountApiApiV1TenantAccountRolesPost
     */
    readonly createTenantRoleDto?: CreateTenantRoleDto
}

/**
 * Request parameters for apiV1TenantAccountRolesRoleIdDelete operation in TenantAccountApi.
 * @export
 * @interface TenantAccountApiApiV1TenantAccountRolesRoleIdDeleteRequest
 */
export interface TenantAccountApiApiV1TenantAccountRolesRoleIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountRolesRoleIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountRolesRoleIdDelete
     */
    readonly roleId: string
}

/**
 * Request parameters for apiV1TenantAccountRolesRoleIdGet operation in TenantAccountApi.
 * @export
 * @interface TenantAccountApiApiV1TenantAccountRolesRoleIdGetRequest
 */
export interface TenantAccountApiApiV1TenantAccountRolesRoleIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountRolesRoleIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountRolesRoleIdGet
     */
    readonly roleId: string
}

/**
 * Request parameters for apiV1TenantAccountRolesRoleIdPut operation in TenantAccountApi.
 * @export
 * @interface TenantAccountApiApiV1TenantAccountRolesRoleIdPutRequest
 */
export interface TenantAccountApiApiV1TenantAccountRolesRoleIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountRolesRoleIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountRolesRoleIdPut
     */
    readonly roleId: string

    /**
     * 
     * @type {UpdateTenantRoleDto}
     * @memberof TenantAccountApiApiV1TenantAccountRolesRoleIdPut
     */
    readonly updateTenantRoleDto?: UpdateTenantRoleDto
}

/**
 * Request parameters for apiV1TenantAccountUsersUserIdRolesPut operation in TenantAccountApi.
 * @export
 * @interface TenantAccountApiApiV1TenantAccountUsersUserIdRolesPutRequest
 */
export interface TenantAccountApiApiV1TenantAccountUsersUserIdRolesPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountUsersUserIdRolesPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantAccountApiApiV1TenantAccountUsersUserIdRolesPut
     */
    readonly userId: string

    /**
     * 
     * @type {UpdateUserRolesDto}
     * @memberof TenantAccountApiApiV1TenantAccountUsersUserIdRolesPut
     */
    readonly updateUserRolesDto?: UpdateUserRolesDto
}

/**
 * TenantAccountApi - object-oriented interface
 * @export
 * @class TenantAccountApi
 * @extends {BaseAPI}
 */
export class TenantAccountApi extends BaseAPI {
    /**
     * 
     * @param {TenantAccountApiApiV1TenantAccountPermissionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantAccountApi
     */
    public apiV1TenantAccountPermissionsGet(requestParameters: TenantAccountApiApiV1TenantAccountPermissionsGetRequest, options?: RawAxiosRequestConfig) {
        return TenantAccountApiFp(this.configuration).apiV1TenantAccountPermissionsGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantAccountApiApiV1TenantAccountRoleAssignmentsRestrictionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantAccountApi
     */
    public apiV1TenantAccountRoleAssignmentsRestrictionsGet(requestParameters: TenantAccountApiApiV1TenantAccountRoleAssignmentsRestrictionsGetRequest, options?: RawAxiosRequestConfig) {
        return TenantAccountApiFp(this.configuration).apiV1TenantAccountRoleAssignmentsRestrictionsGet(requestParameters.nexusOpsTenant, requestParameters.targetUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantAccountApiApiV1TenantAccountRolesByIdsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantAccountApi
     */
    public apiV1TenantAccountRolesByIdsGet(requestParameters: TenantAccountApiApiV1TenantAccountRolesByIdsGetRequest, options?: RawAxiosRequestConfig) {
        return TenantAccountApiFp(this.configuration).apiV1TenantAccountRolesByIdsGet(requestParameters.nexusOpsTenant, requestParameters.roleIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantAccountApiApiV1TenantAccountRolesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantAccountApi
     */
    public apiV1TenantAccountRolesGet(requestParameters: TenantAccountApiApiV1TenantAccountRolesGetRequest, options?: RawAxiosRequestConfig) {
        return TenantAccountApiFp(this.configuration).apiV1TenantAccountRolesGet(requestParameters.nexusOpsTenant, requestParameters.sortBy, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantAccountApiApiV1TenantAccountRolesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantAccountApi
     */
    public apiV1TenantAccountRolesPost(requestParameters: TenantAccountApiApiV1TenantAccountRolesPostRequest, options?: RawAxiosRequestConfig) {
        return TenantAccountApiFp(this.configuration).apiV1TenantAccountRolesPost(requestParameters.nexusOpsTenant, requestParameters.createTenantRoleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantAccountApiApiV1TenantAccountRolesRoleIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantAccountApi
     */
    public apiV1TenantAccountRolesRoleIdDelete(requestParameters: TenantAccountApiApiV1TenantAccountRolesRoleIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return TenantAccountApiFp(this.configuration).apiV1TenantAccountRolesRoleIdDelete(requestParameters.nexusOpsTenant, requestParameters.roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantAccountApiApiV1TenantAccountRolesRoleIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantAccountApi
     */
    public apiV1TenantAccountRolesRoleIdGet(requestParameters: TenantAccountApiApiV1TenantAccountRolesRoleIdGetRequest, options?: RawAxiosRequestConfig) {
        return TenantAccountApiFp(this.configuration).apiV1TenantAccountRolesRoleIdGet(requestParameters.nexusOpsTenant, requestParameters.roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantAccountApiApiV1TenantAccountRolesRoleIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantAccountApi
     */
    public apiV1TenantAccountRolesRoleIdPut(requestParameters: TenantAccountApiApiV1TenantAccountRolesRoleIdPutRequest, options?: RawAxiosRequestConfig) {
        return TenantAccountApiFp(this.configuration).apiV1TenantAccountRolesRoleIdPut(requestParameters.nexusOpsTenant, requestParameters.roleId, requestParameters.updateTenantRoleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantAccountApiApiV1TenantAccountUsersUserIdRolesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantAccountApi
     */
    public apiV1TenantAccountUsersUserIdRolesPut(requestParameters: TenantAccountApiApiV1TenantAccountUsersUserIdRolesPutRequest, options?: RawAxiosRequestConfig) {
        return TenantAccountApiFp(this.configuration).apiV1TenantAccountUsersUserIdRolesPut(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.updateUserRolesDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantConnectionRequestsApi - axios parameter creator
 * @export
 */
export const TenantConnectionRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsCountersGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionRequestsCountersGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-connection-requests/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantConnectionRequestGetPaginatedDto} [tenantConnectionRequestGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsGetPost: async (nexusOpsTenant: string, tenantConnectionRequestGetPaginatedDto?: TenantConnectionRequestGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionRequestsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-connection-requests/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantConnectionRequestGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantConnectionRequestCreateDto} [tenantConnectionRequestCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsPost: async (nexusOpsTenant: string, tenantConnectionRequestCreateDto?: TenantConnectionRequestCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionRequestsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-connection-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantConnectionRequestCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantConnectionRequestSearchPaginatedDto} [tenantConnectionRequestSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsSearchPost: async (nexusOpsTenant: string, tenantConnectionRequestSearchPaginatedDto?: TenantConnectionRequestSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionRequestsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-connection-requests/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantConnectionRequestSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantConnectionRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet: async (nexusOpsTenant: string, tenantConnectionRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantConnectionRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet', 'tenantConnectionRequestId', tenantConnectionRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-connection-requests/{tenantConnectionRequestId}`
                .replace(`{${"tenantConnectionRequestId"}}`, encodeURIComponent(String(tenantConnectionRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantConnectionRequestId 
         * @param {TenantConnectionRequestRespondDto} [tenantConnectionRequestRespondDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost: async (nexusOpsTenant: string, tenantConnectionRequestId: string, tenantConnectionRequestRespondDto?: TenantConnectionRequestRespondDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantConnectionRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost', 'tenantConnectionRequestId', tenantConnectionRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-connection-requests/{tenantConnectionRequestId}/respond`
                .replace(`{${"tenantConnectionRequestId"}}`, encodeURIComponent(String(tenantConnectionRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantConnectionRequestRespondDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantConnectionRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost: async (nexusOpsTenant: string, tenantConnectionRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantConnectionRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost', 'tenantConnectionRequestId', tenantConnectionRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-connection-requests/{tenantConnectionRequestId}/revoke`
                .replace(`{${"tenantConnectionRequestId"}}`, encodeURIComponent(String(tenantConnectionRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantConnectionRequestsApi - functional programming interface
 * @export
 */
export const TenantConnectionRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantConnectionRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantConnectionRequestsCountersGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantConnectionRequestCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantConnectionRequestsCountersGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantConnectionRequestsApi.apiV1TenantToTenantTenantConnectionRequestsCountersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantConnectionRequestGetPaginatedDto} [tenantConnectionRequestGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantConnectionRequestsGetPost(nexusOpsTenant: string, tenantConnectionRequestGetPaginatedDto?: TenantConnectionRequestGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTenantConnectionRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantConnectionRequestsGetPost(nexusOpsTenant, tenantConnectionRequestGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantConnectionRequestsApi.apiV1TenantToTenantTenantConnectionRequestsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantConnectionRequestCreateDto} [tenantConnectionRequestCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantConnectionRequestsPost(nexusOpsTenant: string, tenantConnectionRequestCreateDto?: TenantConnectionRequestCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantConnectionRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantConnectionRequestsPost(nexusOpsTenant, tenantConnectionRequestCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantConnectionRequestsApi.apiV1TenantToTenantTenantConnectionRequestsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantConnectionRequestSearchPaginatedDto} [tenantConnectionRequestSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantConnectionRequestsSearchPost(nexusOpsTenant: string, tenantConnectionRequestSearchPaginatedDto?: TenantConnectionRequestSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTenantConnectionRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantConnectionRequestsSearchPost(nexusOpsTenant, tenantConnectionRequestSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantConnectionRequestsApi.apiV1TenantToTenantTenantConnectionRequestsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantConnectionRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet(nexusOpsTenant: string, tenantConnectionRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantConnectionRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet(nexusOpsTenant, tenantConnectionRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantConnectionRequestsApi.apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantConnectionRequestId 
         * @param {TenantConnectionRequestRespondDto} [tenantConnectionRequestRespondDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost(nexusOpsTenant: string, tenantConnectionRequestId: string, tenantConnectionRequestRespondDto?: TenantConnectionRequestRespondDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantConnectionRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost(nexusOpsTenant, tenantConnectionRequestId, tenantConnectionRequestRespondDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantConnectionRequestsApi.apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantConnectionRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost(nexusOpsTenant: string, tenantConnectionRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantConnectionRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost(nexusOpsTenant, tenantConnectionRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantConnectionRequestsApi.apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantConnectionRequestsApi - factory interface
 * @export
 */
export const TenantConnectionRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantConnectionRequestsApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsCountersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsCountersGet(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsCountersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantConnectionRequestCountersDto> {
            return localVarFp.apiV1TenantToTenantTenantConnectionRequestsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsGetPost(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTenantConnectionRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantConnectionRequestsGetPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionRequestGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsPost(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantConnectionRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantConnectionRequestsPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionRequestCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsSearchPost(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTenantConnectionRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantConnectionRequestsSearchPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionRequestSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantConnectionRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantConnectionRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionRequestId, requestParameters.tenantConnectionRequestRespondDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantConnectionRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionRequestId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TenantToTenantTenantConnectionRequestsCountersGet operation in TenantConnectionRequestsApi.
 * @export
 * @interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsCountersGetRequest
 */
export interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsCountersGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsCountersGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1TenantToTenantTenantConnectionRequestsGetPost operation in TenantConnectionRequestsApi.
 * @export
 * @interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsGetPostRequest
 */
export interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantConnectionRequestGetPaginatedDto}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsGetPost
     */
    readonly tenantConnectionRequestGetPaginatedDto?: TenantConnectionRequestGetPaginatedDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantConnectionRequestsPost operation in TenantConnectionRequestsApi.
 * @export
 * @interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsPostRequest
 */
export interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantConnectionRequestCreateDto}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsPost
     */
    readonly tenantConnectionRequestCreateDto?: TenantConnectionRequestCreateDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantConnectionRequestsSearchPost operation in TenantConnectionRequestsApi.
 * @export
 * @interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsSearchPostRequest
 */
export interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantConnectionRequestSearchPaginatedDto}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsSearchPost
     */
    readonly tenantConnectionRequestSearchPaginatedDto?: TenantConnectionRequestSearchPaginatedDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet operation in TenantConnectionRequestsApi.
 * @export
 * @interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGetRequest
 */
export interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet
     */
    readonly tenantConnectionRequestId: string
}

/**
 * Request parameters for apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost operation in TenantConnectionRequestsApi.
 * @export
 * @interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPostRequest
 */
export interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost
     */
    readonly tenantConnectionRequestId: string

    /**
     * 
     * @type {TenantConnectionRequestRespondDto}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost
     */
    readonly tenantConnectionRequestRespondDto?: TenantConnectionRequestRespondDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost operation in TenantConnectionRequestsApi.
 * @export
 * @interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePostRequest
 */
export interface TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost
     */
    readonly tenantConnectionRequestId: string
}

/**
 * TenantConnectionRequestsApi - object-oriented interface
 * @export
 * @class TenantConnectionRequestsApi
 * @extends {BaseAPI}
 */
export class TenantConnectionRequestsApi extends BaseAPI {
    /**
     * 
     * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConnectionRequestsApi
     */
    public apiV1TenantToTenantTenantConnectionRequestsCountersGet(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsCountersGetRequest, options?: RawAxiosRequestConfig) {
        return TenantConnectionRequestsApiFp(this.configuration).apiV1TenantToTenantTenantConnectionRequestsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConnectionRequestsApi
     */
    public apiV1TenantToTenantTenantConnectionRequestsGetPost(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsGetPostRequest, options?: RawAxiosRequestConfig) {
        return TenantConnectionRequestsApiFp(this.configuration).apiV1TenantToTenantTenantConnectionRequestsGetPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionRequestGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConnectionRequestsApi
     */
    public apiV1TenantToTenantTenantConnectionRequestsPost(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsPostRequest, options?: RawAxiosRequestConfig) {
        return TenantConnectionRequestsApiFp(this.configuration).apiV1TenantToTenantTenantConnectionRequestsPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionRequestCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConnectionRequestsApi
     */
    public apiV1TenantToTenantTenantConnectionRequestsSearchPost(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return TenantConnectionRequestsApiFp(this.configuration).apiV1TenantToTenantTenantConnectionRequestsSearchPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionRequestSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConnectionRequestsApi
     */
    public apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGetRequest, options?: RawAxiosRequestConfig) {
        return TenantConnectionRequestsApiFp(this.configuration).apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdGet(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConnectionRequestsApi
     */
    public apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPostRequest, options?: RawAxiosRequestConfig) {
        return TenantConnectionRequestsApiFp(this.configuration).apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRespondPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionRequestId, requestParameters.tenantConnectionRequestRespondDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConnectionRequestsApi
     */
    public apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost(requestParameters: TenantConnectionRequestsApiApiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePostRequest, options?: RawAxiosRequestConfig) {
        return TenantConnectionRequestsApiFp(this.configuration).apiV1TenantToTenantTenantConnectionRequestsTenantConnectionRequestIdRevokePost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionRequestId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantConnectionsApi - axios parameter creator
 * @export
 */
export const TenantConnectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionsCountersGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionsCountersGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-connections/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantConnectionGetPaginatedDto} [tenantConnectionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionsGetPost: async (nexusOpsTenant: string, tenantConnectionGetPaginatedDto?: TenantConnectionGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-connections/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantConnectionGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantConnectionSearchPaginatedDto} [tenantConnectionSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionsSearchPost: async (nexusOpsTenant: string, tenantConnectionSearchPaginatedDto?: TenantConnectionSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-connections/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantConnectionSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantConnectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost: async (nexusOpsTenant: string, tenantConnectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantConnectionId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost', 'tenantConnectionId', tenantConnectionId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-connections/{tenantConnectionId}/end`
                .replace(`{${"tenantConnectionId"}}`, encodeURIComponent(String(tenantConnectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantConnectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionsTenantConnectionIdGet: async (nexusOpsTenant: string, tenantConnectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionsTenantConnectionIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantConnectionId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantConnectionsTenantConnectionIdGet', 'tenantConnectionId', tenantConnectionId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-connections/{tenantConnectionId}`
                .replace(`{${"tenantConnectionId"}}`, encodeURIComponent(String(tenantConnectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantConnectionsApi - functional programming interface
 * @export
 */
export const TenantConnectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantConnectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantConnectionsCountersGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantConnectionCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantConnectionsCountersGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantConnectionsApi.apiV1TenantToTenantTenantConnectionsCountersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantConnectionGetPaginatedDto} [tenantConnectionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantConnectionsGetPost(nexusOpsTenant: string, tenantConnectionGetPaginatedDto?: TenantConnectionGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTenantConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantConnectionsGetPost(nexusOpsTenant, tenantConnectionGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantConnectionsApi.apiV1TenantToTenantTenantConnectionsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantConnectionSearchPaginatedDto} [tenantConnectionSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantConnectionsSearchPost(nexusOpsTenant: string, tenantConnectionSearchPaginatedDto?: TenantConnectionSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTenantConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantConnectionsSearchPost(nexusOpsTenant, tenantConnectionSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantConnectionsApi.apiV1TenantToTenantTenantConnectionsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantConnectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost(nexusOpsTenant: string, tenantConnectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost(nexusOpsTenant, tenantConnectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantConnectionsApi.apiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantConnectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantConnectionsTenantConnectionIdGet(nexusOpsTenant: string, tenantConnectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantConnectionsTenantConnectionIdGet(nexusOpsTenant, tenantConnectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantConnectionsApi.apiV1TenantToTenantTenantConnectionsTenantConnectionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantConnectionsApi - factory interface
 * @export
 */
export const TenantConnectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantConnectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantConnectionsApiApiV1TenantToTenantTenantConnectionsCountersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionsCountersGet(requestParameters: TenantConnectionsApiApiV1TenantToTenantTenantConnectionsCountersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantConnectionCountersDto> {
            return localVarFp.apiV1TenantToTenantTenantConnectionsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantConnectionsApiApiV1TenantToTenantTenantConnectionsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionsGetPost(requestParameters: TenantConnectionsApiApiV1TenantToTenantTenantConnectionsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTenantConnectionDto> {
            return localVarFp.apiV1TenantToTenantTenantConnectionsGetPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantConnectionsApiApiV1TenantToTenantTenantConnectionsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionsSearchPost(requestParameters: TenantConnectionsApiApiV1TenantToTenantTenantConnectionsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTenantConnectionDto> {
            return localVarFp.apiV1TenantToTenantTenantConnectionsSearchPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost(requestParameters: TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantConnectionDto> {
            return localVarFp.apiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantConnectionsTenantConnectionIdGet(requestParameters: TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantConnectionDto> {
            return localVarFp.apiV1TenantToTenantTenantConnectionsTenantConnectionIdGet(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TenantToTenantTenantConnectionsCountersGet operation in TenantConnectionsApi.
 * @export
 * @interface TenantConnectionsApiApiV1TenantToTenantTenantConnectionsCountersGetRequest
 */
export interface TenantConnectionsApiApiV1TenantToTenantTenantConnectionsCountersGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantConnectionsApiApiV1TenantToTenantTenantConnectionsCountersGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1TenantToTenantTenantConnectionsGetPost operation in TenantConnectionsApi.
 * @export
 * @interface TenantConnectionsApiApiV1TenantToTenantTenantConnectionsGetPostRequest
 */
export interface TenantConnectionsApiApiV1TenantToTenantTenantConnectionsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantConnectionsApiApiV1TenantToTenantTenantConnectionsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantConnectionGetPaginatedDto}
     * @memberof TenantConnectionsApiApiV1TenantToTenantTenantConnectionsGetPost
     */
    readonly tenantConnectionGetPaginatedDto?: TenantConnectionGetPaginatedDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantConnectionsSearchPost operation in TenantConnectionsApi.
 * @export
 * @interface TenantConnectionsApiApiV1TenantToTenantTenantConnectionsSearchPostRequest
 */
export interface TenantConnectionsApiApiV1TenantToTenantTenantConnectionsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantConnectionsApiApiV1TenantToTenantTenantConnectionsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantConnectionSearchPaginatedDto}
     * @memberof TenantConnectionsApiApiV1TenantToTenantTenantConnectionsSearchPost
     */
    readonly tenantConnectionSearchPaginatedDto?: TenantConnectionSearchPaginatedDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost operation in TenantConnectionsApi.
 * @export
 * @interface TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPostRequest
 */
export interface TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost
     */
    readonly tenantConnectionId: string
}

/**
 * Request parameters for apiV1TenantToTenantTenantConnectionsTenantConnectionIdGet operation in TenantConnectionsApi.
 * @export
 * @interface TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdGetRequest
 */
export interface TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdGet
     */
    readonly tenantConnectionId: string
}

/**
 * TenantConnectionsApi - object-oriented interface
 * @export
 * @class TenantConnectionsApi
 * @extends {BaseAPI}
 */
export class TenantConnectionsApi extends BaseAPI {
    /**
     * 
     * @param {TenantConnectionsApiApiV1TenantToTenantTenantConnectionsCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConnectionsApi
     */
    public apiV1TenantToTenantTenantConnectionsCountersGet(requestParameters: TenantConnectionsApiApiV1TenantToTenantTenantConnectionsCountersGetRequest, options?: RawAxiosRequestConfig) {
        return TenantConnectionsApiFp(this.configuration).apiV1TenantToTenantTenantConnectionsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantConnectionsApiApiV1TenantToTenantTenantConnectionsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConnectionsApi
     */
    public apiV1TenantToTenantTenantConnectionsGetPost(requestParameters: TenantConnectionsApiApiV1TenantToTenantTenantConnectionsGetPostRequest, options?: RawAxiosRequestConfig) {
        return TenantConnectionsApiFp(this.configuration).apiV1TenantToTenantTenantConnectionsGetPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantConnectionsApiApiV1TenantToTenantTenantConnectionsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConnectionsApi
     */
    public apiV1TenantToTenantTenantConnectionsSearchPost(requestParameters: TenantConnectionsApiApiV1TenantToTenantTenantConnectionsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return TenantConnectionsApiFp(this.configuration).apiV1TenantToTenantTenantConnectionsSearchPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConnectionsApi
     */
    public apiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost(requestParameters: TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPostRequest, options?: RawAxiosRequestConfig) {
        return TenantConnectionsApiFp(this.configuration).apiV1TenantToTenantTenantConnectionsTenantConnectionIdEndPost(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConnectionsApi
     */
    public apiV1TenantToTenantTenantConnectionsTenantConnectionIdGet(requestParameters: TenantConnectionsApiApiV1TenantToTenantTenantConnectionsTenantConnectionIdGetRequest, options?: RawAxiosRequestConfig) {
        return TenantConnectionsApiFp(this.configuration).apiV1TenantToTenantTenantConnectionsTenantConnectionIdGet(requestParameters.nexusOpsTenant, requestParameters.tenantConnectionId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantExportApi - axios parameter creator
 * @export
 */
export const TenantExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ExportOptionsDto} [exportOptionsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantExportsPost: async (nexusOpsTenant: string, exportOptionsDto?: ExportOptionsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantExportsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/exports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportOptionsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ExportEntityType} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantExportsPropertiesGet: async (nexusOpsTenant: string, entityType?: ExportEntityType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantExportsPropertiesGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/exports/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantExportApi - functional programming interface
 * @export
 */
export const TenantExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ExportOptionsDto} [exportOptionsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantExportsPost(nexusOpsTenant: string, exportOptionsDto?: ExportOptionsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantExportsPost(nexusOpsTenant, exportOptionsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantExportApi.apiV1TenantExportsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ExportEntityType} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantExportsPropertiesGet(nexusOpsTenant: string, entityType?: ExportEntityType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantExportsPropertiesGet(nexusOpsTenant, entityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantExportApi.apiV1TenantExportsPropertiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantExportApi - factory interface
 * @export
 */
export const TenantExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantExportApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantExportApiApiV1TenantExportsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantExportsPost(requestParameters: TenantExportApiApiV1TenantExportsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.apiV1TenantExportsPost(requestParameters.nexusOpsTenant, requestParameters.exportOptionsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantExportApiApiV1TenantExportsPropertiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantExportsPropertiesGet(requestParameters: TenantExportApiApiV1TenantExportsPropertiesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.apiV1TenantExportsPropertiesGet(requestParameters.nexusOpsTenant, requestParameters.entityType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TenantExportsPost operation in TenantExportApi.
 * @export
 * @interface TenantExportApiApiV1TenantExportsPostRequest
 */
export interface TenantExportApiApiV1TenantExportsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantExportApiApiV1TenantExportsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ExportOptionsDto}
     * @memberof TenantExportApiApiV1TenantExportsPost
     */
    readonly exportOptionsDto?: ExportOptionsDto
}

/**
 * Request parameters for apiV1TenantExportsPropertiesGet operation in TenantExportApi.
 * @export
 * @interface TenantExportApiApiV1TenantExportsPropertiesGetRequest
 */
export interface TenantExportApiApiV1TenantExportsPropertiesGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantExportApiApiV1TenantExportsPropertiesGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ExportEntityType}
     * @memberof TenantExportApiApiV1TenantExportsPropertiesGet
     */
    readonly entityType?: ExportEntityType
}

/**
 * TenantExportApi - object-oriented interface
 * @export
 * @class TenantExportApi
 * @extends {BaseAPI}
 */
export class TenantExportApi extends BaseAPI {
    /**
     * 
     * @param {TenantExportApiApiV1TenantExportsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantExportApi
     */
    public apiV1TenantExportsPost(requestParameters: TenantExportApiApiV1TenantExportsPostRequest, options?: RawAxiosRequestConfig) {
        return TenantExportApiFp(this.configuration).apiV1TenantExportsPost(requestParameters.nexusOpsTenant, requestParameters.exportOptionsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantExportApiApiV1TenantExportsPropertiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantExportApi
     */
    public apiV1TenantExportsPropertiesGet(requestParameters: TenantExportApiApiV1TenantExportsPropertiesGetRequest, options?: RawAxiosRequestConfig) {
        return TenantExportApiFp(this.configuration).apiV1TenantExportsPropertiesGet(requestParameters.nexusOpsTenant, requestParameters.entityType, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantImportApi - axios parameter creator
 * @export
 */
export const TenantImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsDataImportIdGet: async (nexusOpsTenant: string, importId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantImportsDataImportIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('apiV1TenantImportsDataImportIdGet', 'importId', importId)
            const localVarPath = `/api/v1/tenant/imports/data/{importId}`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ImportEntityType} [entityType] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsFileExampleGet: async (nexusOpsTenant: string, entityType?: ImportEntityType, mimeType?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantImportsFileExampleGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/imports/file/example`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (mimeType !== undefined) {
                localVarQueryParameter['mimeType'] = mimeType;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ImportGetPaginatedDto} [importGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsGetPost: async (nexusOpsTenant: string, importGetPaginatedDto?: ImportGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantImportsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/imports/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsImportIdGet: async (nexusOpsTenant: string, importId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantImportsImportIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('apiV1TenantImportsImportIdGet', 'importId', importId)
            const localVarPath = `/api/v1/tenant/imports/{importId}`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {LaunchImportDto} [launchImportDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsLaunchPost: async (nexusOpsTenant: string, launchImportDto?: LaunchImportDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantImportsLaunchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/imports/launch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(launchImportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {LaunchImportDto} [launchImportDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsLaunchSimulatePost: async (nexusOpsTenant: string, launchImportDto?: LaunchImportDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantImportsLaunchSimulatePost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/imports/launch/simulate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(launchImportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ApplyMappingToImportDto} [applyMappingToImportDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsMappingApplyPost: async (nexusOpsTenant: string, applyMappingToImportDto?: ApplyMappingToImportDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantImportsMappingApplyPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/imports/mapping/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyMappingToImportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SubmitMappingDto} [submitMappingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsMappingSubmitPost: async (nexusOpsTenant: string, submitMappingDto?: SubmitMappingDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantImportsMappingSubmitPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/imports/mapping/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitMappingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ImportEntityType} [importEntityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsMappingsGet: async (nexusOpsTenant: string, importEntityType?: ImportEntityType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantImportsMappingsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/imports/mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (importEntityType !== undefined) {
                localVarQueryParameter['importEntityType'] = importEntityType;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsMappingsMappingIdGet: async (nexusOpsTenant: string, mappingId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantImportsMappingsMappingIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'mappingId' is not null or undefined
            assertParamExists('apiV1TenantImportsMappingsMappingIdGet', 'mappingId', mappingId)
            const localVarPath = `/api/v1/tenant/imports/mappings/{mappingId}`
                .replace(`{${"mappingId"}}`, encodeURIComponent(String(mappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsPost: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantImportsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/imports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ImportEntityType} [importEntityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsSpecGet: async (nexusOpsTenant: string, importEntityType?: ImportEntityType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantImportsSpecGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/imports/spec`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (importEntityType !== undefined) {
                localVarQueryParameter['importEntityType'] = importEntityType;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SubmitUploadDto} [submitUploadDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsUploadSubmitPost: async (nexusOpsTenant: string, submitUploadDto?: SubmitUploadDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantImportsUploadSubmitPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/imports/upload/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitUploadDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantImportApi - functional programming interface
 * @export
 */
export const TenantImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantImportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantImportsDataImportIdGet(nexusOpsTenant: string, importId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpreadsheetDtoOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantImportsDataImportIdGet(nexusOpsTenant, importId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantImportApi.apiV1TenantImportsDataImportIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ImportEntityType} [entityType] 
         * @param {string} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantImportsFileExampleGet(nexusOpsTenant: string, entityType?: ImportEntityType, mimeType?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantImportsFileExampleGet(nexusOpsTenant, entityType, mimeType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantImportApi.apiV1TenantImportsFileExampleGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ImportGetPaginatedDto} [importGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantImportsGetPost(nexusOpsTenant: string, importGetPaginatedDto?: ImportGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfImportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantImportsGetPost(nexusOpsTenant, importGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantImportApi.apiV1TenantImportsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantImportsImportIdGet(nexusOpsTenant: string, importId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantImportsImportIdGet(nexusOpsTenant, importId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantImportApi.apiV1TenantImportsImportIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {LaunchImportDto} [launchImportDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantImportsLaunchPost(nexusOpsTenant: string, launchImportDto?: LaunchImportDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantImportsLaunchPost(nexusOpsTenant, launchImportDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantImportApi.apiV1TenantImportsLaunchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {LaunchImportDto} [launchImportDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantImportsLaunchSimulatePost(nexusOpsTenant: string, launchImportDto?: LaunchImportDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantImportsLaunchSimulatePost(nexusOpsTenant, launchImportDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantImportApi.apiV1TenantImportsLaunchSimulatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ApplyMappingToImportDto} [applyMappingToImportDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantImportsMappingApplyPost(nexusOpsTenant: string, applyMappingToImportDto?: ApplyMappingToImportDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantImportsMappingApplyPost(nexusOpsTenant, applyMappingToImportDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantImportApi.apiV1TenantImportsMappingApplyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SubmitMappingDto} [submitMappingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantImportsMappingSubmitPost(nexusOpsTenant: string, submitMappingDto?: SubmitMappingDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantImportsMappingSubmitPost(nexusOpsTenant, submitMappingDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantImportApi.apiV1TenantImportsMappingSubmitPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ImportEntityType} [importEntityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantImportsMappingsGet(nexusOpsTenant: string, importEntityType?: ImportEntityType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportMappingTemplateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantImportsMappingsGet(nexusOpsTenant, importEntityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantImportApi.apiV1TenantImportsMappingsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantImportsMappingsMappingIdGet(nexusOpsTenant: string, mappingId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportMappingTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantImportsMappingsMappingIdGet(nexusOpsTenant, mappingId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantImportApi.apiV1TenantImportsMappingsMappingIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantImportsPost(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantImportsPost(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantImportApi.apiV1TenantImportsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {ImportEntityType} [importEntityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantImportsSpecGet(nexusOpsTenant: string, importEntityType?: ImportEntityType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportSpecDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantImportsSpecGet(nexusOpsTenant, importEntityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantImportApi.apiV1TenantImportsSpecGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {SubmitUploadDto} [submitUploadDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantImportsUploadSubmitPost(nexusOpsTenant: string, submitUploadDto?: SubmitUploadDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantImportsUploadSubmitPost(nexusOpsTenant, submitUploadDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantImportApi.apiV1TenantImportsUploadSubmitPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantImportApi - factory interface
 * @export
 */
export const TenantImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantImportApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantImportApiApiV1TenantImportsDataImportIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsDataImportIdGet(requestParameters: TenantImportApiApiV1TenantImportsDataImportIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<SpreadsheetDtoOfString> {
            return localVarFp.apiV1TenantImportsDataImportIdGet(requestParameters.nexusOpsTenant, requestParameters.importId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantImportApiApiV1TenantImportsFileExampleGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsFileExampleGet(requestParameters: TenantImportApiApiV1TenantImportsFileExampleGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.apiV1TenantImportsFileExampleGet(requestParameters.nexusOpsTenant, requestParameters.entityType, requestParameters.mimeType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantImportApiApiV1TenantImportsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsGetPost(requestParameters: TenantImportApiApiV1TenantImportsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfImportDto> {
            return localVarFp.apiV1TenantImportsGetPost(requestParameters.nexusOpsTenant, requestParameters.importGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantImportApiApiV1TenantImportsImportIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsImportIdGet(requestParameters: TenantImportApiApiV1TenantImportsImportIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ImportDto> {
            return localVarFp.apiV1TenantImportsImportIdGet(requestParameters.nexusOpsTenant, requestParameters.importId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantImportApiApiV1TenantImportsLaunchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsLaunchPost(requestParameters: TenantImportApiApiV1TenantImportsLaunchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ImportDto> {
            return localVarFp.apiV1TenantImportsLaunchPost(requestParameters.nexusOpsTenant, requestParameters.launchImportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantImportApiApiV1TenantImportsLaunchSimulatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsLaunchSimulatePost(requestParameters: TenantImportApiApiV1TenantImportsLaunchSimulatePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ImportResultDto> {
            return localVarFp.apiV1TenantImportsLaunchSimulatePost(requestParameters.nexusOpsTenant, requestParameters.launchImportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantImportApiApiV1TenantImportsMappingApplyPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsMappingApplyPost(requestParameters: TenantImportApiApiV1TenantImportsMappingApplyPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ImportDto> {
            return localVarFp.apiV1TenantImportsMappingApplyPost(requestParameters.nexusOpsTenant, requestParameters.applyMappingToImportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantImportApiApiV1TenantImportsMappingSubmitPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsMappingSubmitPost(requestParameters: TenantImportApiApiV1TenantImportsMappingSubmitPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ImportDto> {
            return localVarFp.apiV1TenantImportsMappingSubmitPost(requestParameters.nexusOpsTenant, requestParameters.submitMappingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantImportApiApiV1TenantImportsMappingsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsMappingsGet(requestParameters: TenantImportApiApiV1TenantImportsMappingsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ImportMappingTemplateDto>> {
            return localVarFp.apiV1TenantImportsMappingsGet(requestParameters.nexusOpsTenant, requestParameters.importEntityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantImportApiApiV1TenantImportsMappingsMappingIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsMappingsMappingIdGet(requestParameters: TenantImportApiApiV1TenantImportsMappingsMappingIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ImportMappingTemplateDto> {
            return localVarFp.apiV1TenantImportsMappingsMappingIdGet(requestParameters.nexusOpsTenant, requestParameters.mappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantImportApiApiV1TenantImportsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsPost(requestParameters: TenantImportApiApiV1TenantImportsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ImportDto> {
            return localVarFp.apiV1TenantImportsPost(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantImportApiApiV1TenantImportsSpecGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsSpecGet(requestParameters: TenantImportApiApiV1TenantImportsSpecGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ImportSpecDto> {
            return localVarFp.apiV1TenantImportsSpecGet(requestParameters.nexusOpsTenant, requestParameters.importEntityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantImportApiApiV1TenantImportsUploadSubmitPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantImportsUploadSubmitPost(requestParameters: TenantImportApiApiV1TenantImportsUploadSubmitPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ImportDto> {
            return localVarFp.apiV1TenantImportsUploadSubmitPost(requestParameters.nexusOpsTenant, requestParameters.submitUploadDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TenantImportsDataImportIdGet operation in TenantImportApi.
 * @export
 * @interface TenantImportApiApiV1TenantImportsDataImportIdGetRequest
 */
export interface TenantImportApiApiV1TenantImportsDataImportIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsDataImportIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsDataImportIdGet
     */
    readonly importId: string
}

/**
 * Request parameters for apiV1TenantImportsFileExampleGet operation in TenantImportApi.
 * @export
 * @interface TenantImportApiApiV1TenantImportsFileExampleGetRequest
 */
export interface TenantImportApiApiV1TenantImportsFileExampleGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsFileExampleGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ImportEntityType}
     * @memberof TenantImportApiApiV1TenantImportsFileExampleGet
     */
    readonly entityType?: ImportEntityType

    /**
     * 
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsFileExampleGet
     */
    readonly mimeType?: string
}

/**
 * Request parameters for apiV1TenantImportsGetPost operation in TenantImportApi.
 * @export
 * @interface TenantImportApiApiV1TenantImportsGetPostRequest
 */
export interface TenantImportApiApiV1TenantImportsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ImportGetPaginatedDto}
     * @memberof TenantImportApiApiV1TenantImportsGetPost
     */
    readonly importGetPaginatedDto?: ImportGetPaginatedDto
}

/**
 * Request parameters for apiV1TenantImportsImportIdGet operation in TenantImportApi.
 * @export
 * @interface TenantImportApiApiV1TenantImportsImportIdGetRequest
 */
export interface TenantImportApiApiV1TenantImportsImportIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsImportIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsImportIdGet
     */
    readonly importId: string
}

/**
 * Request parameters for apiV1TenantImportsLaunchPost operation in TenantImportApi.
 * @export
 * @interface TenantImportApiApiV1TenantImportsLaunchPostRequest
 */
export interface TenantImportApiApiV1TenantImportsLaunchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsLaunchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {LaunchImportDto}
     * @memberof TenantImportApiApiV1TenantImportsLaunchPost
     */
    readonly launchImportDto?: LaunchImportDto
}

/**
 * Request parameters for apiV1TenantImportsLaunchSimulatePost operation in TenantImportApi.
 * @export
 * @interface TenantImportApiApiV1TenantImportsLaunchSimulatePostRequest
 */
export interface TenantImportApiApiV1TenantImportsLaunchSimulatePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsLaunchSimulatePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {LaunchImportDto}
     * @memberof TenantImportApiApiV1TenantImportsLaunchSimulatePost
     */
    readonly launchImportDto?: LaunchImportDto
}

/**
 * Request parameters for apiV1TenantImportsMappingApplyPost operation in TenantImportApi.
 * @export
 * @interface TenantImportApiApiV1TenantImportsMappingApplyPostRequest
 */
export interface TenantImportApiApiV1TenantImportsMappingApplyPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsMappingApplyPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ApplyMappingToImportDto}
     * @memberof TenantImportApiApiV1TenantImportsMappingApplyPost
     */
    readonly applyMappingToImportDto?: ApplyMappingToImportDto
}

/**
 * Request parameters for apiV1TenantImportsMappingSubmitPost operation in TenantImportApi.
 * @export
 * @interface TenantImportApiApiV1TenantImportsMappingSubmitPostRequest
 */
export interface TenantImportApiApiV1TenantImportsMappingSubmitPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsMappingSubmitPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {SubmitMappingDto}
     * @memberof TenantImportApiApiV1TenantImportsMappingSubmitPost
     */
    readonly submitMappingDto?: SubmitMappingDto
}

/**
 * Request parameters for apiV1TenantImportsMappingsGet operation in TenantImportApi.
 * @export
 * @interface TenantImportApiApiV1TenantImportsMappingsGetRequest
 */
export interface TenantImportApiApiV1TenantImportsMappingsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsMappingsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ImportEntityType}
     * @memberof TenantImportApiApiV1TenantImportsMappingsGet
     */
    readonly importEntityType?: ImportEntityType
}

/**
 * Request parameters for apiV1TenantImportsMappingsMappingIdGet operation in TenantImportApi.
 * @export
 * @interface TenantImportApiApiV1TenantImportsMappingsMappingIdGetRequest
 */
export interface TenantImportApiApiV1TenantImportsMappingsMappingIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsMappingsMappingIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsMappingsMappingIdGet
     */
    readonly mappingId: string
}

/**
 * Request parameters for apiV1TenantImportsPost operation in TenantImportApi.
 * @export
 * @interface TenantImportApiApiV1TenantImportsPostRequest
 */
export interface TenantImportApiApiV1TenantImportsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsPost
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1TenantImportsSpecGet operation in TenantImportApi.
 * @export
 * @interface TenantImportApiApiV1TenantImportsSpecGetRequest
 */
export interface TenantImportApiApiV1TenantImportsSpecGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsSpecGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {ImportEntityType}
     * @memberof TenantImportApiApiV1TenantImportsSpecGet
     */
    readonly importEntityType?: ImportEntityType
}

/**
 * Request parameters for apiV1TenantImportsUploadSubmitPost operation in TenantImportApi.
 * @export
 * @interface TenantImportApiApiV1TenantImportsUploadSubmitPostRequest
 */
export interface TenantImportApiApiV1TenantImportsUploadSubmitPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantImportApiApiV1TenantImportsUploadSubmitPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {SubmitUploadDto}
     * @memberof TenantImportApiApiV1TenantImportsUploadSubmitPost
     */
    readonly submitUploadDto?: SubmitUploadDto
}

/**
 * TenantImportApi - object-oriented interface
 * @export
 * @class TenantImportApi
 * @extends {BaseAPI}
 */
export class TenantImportApi extends BaseAPI {
    /**
     * 
     * @param {TenantImportApiApiV1TenantImportsDataImportIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantImportApi
     */
    public apiV1TenantImportsDataImportIdGet(requestParameters: TenantImportApiApiV1TenantImportsDataImportIdGetRequest, options?: RawAxiosRequestConfig) {
        return TenantImportApiFp(this.configuration).apiV1TenantImportsDataImportIdGet(requestParameters.nexusOpsTenant, requestParameters.importId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantImportApiApiV1TenantImportsFileExampleGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantImportApi
     */
    public apiV1TenantImportsFileExampleGet(requestParameters: TenantImportApiApiV1TenantImportsFileExampleGetRequest, options?: RawAxiosRequestConfig) {
        return TenantImportApiFp(this.configuration).apiV1TenantImportsFileExampleGet(requestParameters.nexusOpsTenant, requestParameters.entityType, requestParameters.mimeType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantImportApiApiV1TenantImportsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantImportApi
     */
    public apiV1TenantImportsGetPost(requestParameters: TenantImportApiApiV1TenantImportsGetPostRequest, options?: RawAxiosRequestConfig) {
        return TenantImportApiFp(this.configuration).apiV1TenantImportsGetPost(requestParameters.nexusOpsTenant, requestParameters.importGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantImportApiApiV1TenantImportsImportIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantImportApi
     */
    public apiV1TenantImportsImportIdGet(requestParameters: TenantImportApiApiV1TenantImportsImportIdGetRequest, options?: RawAxiosRequestConfig) {
        return TenantImportApiFp(this.configuration).apiV1TenantImportsImportIdGet(requestParameters.nexusOpsTenant, requestParameters.importId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantImportApiApiV1TenantImportsLaunchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantImportApi
     */
    public apiV1TenantImportsLaunchPost(requestParameters: TenantImportApiApiV1TenantImportsLaunchPostRequest, options?: RawAxiosRequestConfig) {
        return TenantImportApiFp(this.configuration).apiV1TenantImportsLaunchPost(requestParameters.nexusOpsTenant, requestParameters.launchImportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantImportApiApiV1TenantImportsLaunchSimulatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantImportApi
     */
    public apiV1TenantImportsLaunchSimulatePost(requestParameters: TenantImportApiApiV1TenantImportsLaunchSimulatePostRequest, options?: RawAxiosRequestConfig) {
        return TenantImportApiFp(this.configuration).apiV1TenantImportsLaunchSimulatePost(requestParameters.nexusOpsTenant, requestParameters.launchImportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantImportApiApiV1TenantImportsMappingApplyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantImportApi
     */
    public apiV1TenantImportsMappingApplyPost(requestParameters: TenantImportApiApiV1TenantImportsMappingApplyPostRequest, options?: RawAxiosRequestConfig) {
        return TenantImportApiFp(this.configuration).apiV1TenantImportsMappingApplyPost(requestParameters.nexusOpsTenant, requestParameters.applyMappingToImportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantImportApiApiV1TenantImportsMappingSubmitPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantImportApi
     */
    public apiV1TenantImportsMappingSubmitPost(requestParameters: TenantImportApiApiV1TenantImportsMappingSubmitPostRequest, options?: RawAxiosRequestConfig) {
        return TenantImportApiFp(this.configuration).apiV1TenantImportsMappingSubmitPost(requestParameters.nexusOpsTenant, requestParameters.submitMappingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantImportApiApiV1TenantImportsMappingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantImportApi
     */
    public apiV1TenantImportsMappingsGet(requestParameters: TenantImportApiApiV1TenantImportsMappingsGetRequest, options?: RawAxiosRequestConfig) {
        return TenantImportApiFp(this.configuration).apiV1TenantImportsMappingsGet(requestParameters.nexusOpsTenant, requestParameters.importEntityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantImportApiApiV1TenantImportsMappingsMappingIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantImportApi
     */
    public apiV1TenantImportsMappingsMappingIdGet(requestParameters: TenantImportApiApiV1TenantImportsMappingsMappingIdGetRequest, options?: RawAxiosRequestConfig) {
        return TenantImportApiFp(this.configuration).apiV1TenantImportsMappingsMappingIdGet(requestParameters.nexusOpsTenant, requestParameters.mappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantImportApiApiV1TenantImportsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantImportApi
     */
    public apiV1TenantImportsPost(requestParameters: TenantImportApiApiV1TenantImportsPostRequest, options?: RawAxiosRequestConfig) {
        return TenantImportApiFp(this.configuration).apiV1TenantImportsPost(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantImportApiApiV1TenantImportsSpecGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantImportApi
     */
    public apiV1TenantImportsSpecGet(requestParameters: TenantImportApiApiV1TenantImportsSpecGetRequest, options?: RawAxiosRequestConfig) {
        return TenantImportApiFp(this.configuration).apiV1TenantImportsSpecGet(requestParameters.nexusOpsTenant, requestParameters.importEntityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantImportApiApiV1TenantImportsUploadSubmitPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantImportApi
     */
    public apiV1TenantImportsUploadSubmitPost(requestParameters: TenantImportApiApiV1TenantImportsUploadSubmitPostRequest, options?: RawAxiosRequestConfig) {
        return TenantImportApiFp(this.configuration).apiV1TenantImportsUploadSubmitPost(requestParameters.nexusOpsTenant, requestParameters.submitUploadDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantInvitesApi - axios parameter creator
 * @export
 */
export const TenantInvitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {InviteAnonymousUserDto} [inviteAnonymousUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantInvitesAnonymousPost: async (nexusOpsTenant: string, inviteAnonymousUserDto?: InviteAnonymousUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantInvitesAnonymousPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/invites/anonymous`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteAnonymousUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantInviteGetPaginatedDto} [tenantInviteGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantInvitesGetPost: async (nexusOpsTenant: string, tenantInviteGetPaginatedDto?: TenantInviteGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantInvitesGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/invites/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantInviteGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantInvitesInviteIdDelete: async (nexusOpsTenant: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantInvitesInviteIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('apiV1TenantInvitesInviteIdDelete', 'inviteId', inviteId)
            const localVarPath = `/api/v1/tenant/invites/{inviteId}`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantInvitesInviteIdGet: async (nexusOpsTenant: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantInvitesInviteIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('apiV1TenantInvitesInviteIdGet', 'inviteId', inviteId)
            const localVarPath = `/api/v1/tenant/invites/{inviteId}`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantInvitesInviteIdResendPost: async (nexusOpsTenant: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantInvitesInviteIdResendPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('apiV1TenantInvitesInviteIdResendPost', 'inviteId', inviteId)
            const localVarPath = `/api/v1/tenant/invites/{inviteId}/resend`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {InviteUserDto} [inviteUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantInvitesPost: async (nexusOpsTenant: string, inviteUserDto?: InviteUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantInvitesPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/invites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantInvitesApi - functional programming interface
 * @export
 */
export const TenantInvitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantInvitesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {InviteAnonymousUserDto} [inviteAnonymousUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantInvitesAnonymousPost(nexusOpsTenant: string, inviteAnonymousUserDto?: InviteAnonymousUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteAnonymousUserResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantInvitesAnonymousPost(nexusOpsTenant, inviteAnonymousUserDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantInvitesApi.apiV1TenantInvitesAnonymousPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantInviteGetPaginatedDto} [tenantInviteGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantInvitesGetPost(nexusOpsTenant: string, tenantInviteGetPaginatedDto?: TenantInviteGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTenantInviteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantInvitesGetPost(nexusOpsTenant, tenantInviteGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantInvitesApi.apiV1TenantInvitesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantInvitesInviteIdDelete(nexusOpsTenant: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantInvitesInviteIdDelete(nexusOpsTenant, inviteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantInvitesApi.apiV1TenantInvitesInviteIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantInvitesInviteIdGet(nexusOpsTenant: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantInviteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantInvitesInviteIdGet(nexusOpsTenant, inviteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantInvitesApi.apiV1TenantInvitesInviteIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantInvitesInviteIdResendPost(nexusOpsTenant: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantInvitesInviteIdResendPost(nexusOpsTenant, inviteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantInvitesApi.apiV1TenantInvitesInviteIdResendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {InviteUserDto} [inviteUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantInvitesPost(nexusOpsTenant: string, inviteUserDto?: InviteUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantInvitesPost(nexusOpsTenant, inviteUserDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantInvitesApi.apiV1TenantInvitesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantInvitesApi - factory interface
 * @export
 */
export const TenantInvitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantInvitesApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantInvitesApiApiV1TenantInvitesAnonymousPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantInvitesAnonymousPost(requestParameters: TenantInvitesApiApiV1TenantInvitesAnonymousPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<InviteAnonymousUserResultDto> {
            return localVarFp.apiV1TenantInvitesAnonymousPost(requestParameters.nexusOpsTenant, requestParameters.inviteAnonymousUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantInvitesApiApiV1TenantInvitesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantInvitesGetPost(requestParameters: TenantInvitesApiApiV1TenantInvitesGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTenantInviteDto> {
            return localVarFp.apiV1TenantInvitesGetPost(requestParameters.nexusOpsTenant, requestParameters.tenantInviteGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantInvitesApiApiV1TenantInvitesInviteIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantInvitesInviteIdDelete(requestParameters: TenantInvitesApiApiV1TenantInvitesInviteIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TenantInvitesInviteIdDelete(requestParameters.nexusOpsTenant, requestParameters.inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantInvitesApiApiV1TenantInvitesInviteIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantInvitesInviteIdGet(requestParameters: TenantInvitesApiApiV1TenantInvitesInviteIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantInviteDto> {
            return localVarFp.apiV1TenantInvitesInviteIdGet(requestParameters.nexusOpsTenant, requestParameters.inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantInvitesApiApiV1TenantInvitesInviteIdResendPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantInvitesInviteIdResendPost(requestParameters: TenantInvitesApiApiV1TenantInvitesInviteIdResendPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TenantInvitesInviteIdResendPost(requestParameters.nexusOpsTenant, requestParameters.inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantInvitesApiApiV1TenantInvitesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantInvitesPost(requestParameters: TenantInvitesApiApiV1TenantInvitesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TenantInvitesPost(requestParameters.nexusOpsTenant, requestParameters.inviteUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TenantInvitesAnonymousPost operation in TenantInvitesApi.
 * @export
 * @interface TenantInvitesApiApiV1TenantInvitesAnonymousPostRequest
 */
export interface TenantInvitesApiApiV1TenantInvitesAnonymousPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantInvitesApiApiV1TenantInvitesAnonymousPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {InviteAnonymousUserDto}
     * @memberof TenantInvitesApiApiV1TenantInvitesAnonymousPost
     */
    readonly inviteAnonymousUserDto?: InviteAnonymousUserDto
}

/**
 * Request parameters for apiV1TenantInvitesGetPost operation in TenantInvitesApi.
 * @export
 * @interface TenantInvitesApiApiV1TenantInvitesGetPostRequest
 */
export interface TenantInvitesApiApiV1TenantInvitesGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantInvitesApiApiV1TenantInvitesGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantInviteGetPaginatedDto}
     * @memberof TenantInvitesApiApiV1TenantInvitesGetPost
     */
    readonly tenantInviteGetPaginatedDto?: TenantInviteGetPaginatedDto
}

/**
 * Request parameters for apiV1TenantInvitesInviteIdDelete operation in TenantInvitesApi.
 * @export
 * @interface TenantInvitesApiApiV1TenantInvitesInviteIdDeleteRequest
 */
export interface TenantInvitesApiApiV1TenantInvitesInviteIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantInvitesApiApiV1TenantInvitesInviteIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantInvitesApiApiV1TenantInvitesInviteIdDelete
     */
    readonly inviteId: string
}

/**
 * Request parameters for apiV1TenantInvitesInviteIdGet operation in TenantInvitesApi.
 * @export
 * @interface TenantInvitesApiApiV1TenantInvitesInviteIdGetRequest
 */
export interface TenantInvitesApiApiV1TenantInvitesInviteIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantInvitesApiApiV1TenantInvitesInviteIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantInvitesApiApiV1TenantInvitesInviteIdGet
     */
    readonly inviteId: string
}

/**
 * Request parameters for apiV1TenantInvitesInviteIdResendPost operation in TenantInvitesApi.
 * @export
 * @interface TenantInvitesApiApiV1TenantInvitesInviteIdResendPostRequest
 */
export interface TenantInvitesApiApiV1TenantInvitesInviteIdResendPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantInvitesApiApiV1TenantInvitesInviteIdResendPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantInvitesApiApiV1TenantInvitesInviteIdResendPost
     */
    readonly inviteId: string
}

/**
 * Request parameters for apiV1TenantInvitesPost operation in TenantInvitesApi.
 * @export
 * @interface TenantInvitesApiApiV1TenantInvitesPostRequest
 */
export interface TenantInvitesApiApiV1TenantInvitesPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantInvitesApiApiV1TenantInvitesPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {InviteUserDto}
     * @memberof TenantInvitesApiApiV1TenantInvitesPost
     */
    readonly inviteUserDto?: InviteUserDto
}

/**
 * TenantInvitesApi - object-oriented interface
 * @export
 * @class TenantInvitesApi
 * @extends {BaseAPI}
 */
export class TenantInvitesApi extends BaseAPI {
    /**
     * 
     * @param {TenantInvitesApiApiV1TenantInvitesAnonymousPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantInvitesApi
     */
    public apiV1TenantInvitesAnonymousPost(requestParameters: TenantInvitesApiApiV1TenantInvitesAnonymousPostRequest, options?: RawAxiosRequestConfig) {
        return TenantInvitesApiFp(this.configuration).apiV1TenantInvitesAnonymousPost(requestParameters.nexusOpsTenant, requestParameters.inviteAnonymousUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantInvitesApiApiV1TenantInvitesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantInvitesApi
     */
    public apiV1TenantInvitesGetPost(requestParameters: TenantInvitesApiApiV1TenantInvitesGetPostRequest, options?: RawAxiosRequestConfig) {
        return TenantInvitesApiFp(this.configuration).apiV1TenantInvitesGetPost(requestParameters.nexusOpsTenant, requestParameters.tenantInviteGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantInvitesApiApiV1TenantInvitesInviteIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantInvitesApi
     */
    public apiV1TenantInvitesInviteIdDelete(requestParameters: TenantInvitesApiApiV1TenantInvitesInviteIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return TenantInvitesApiFp(this.configuration).apiV1TenantInvitesInviteIdDelete(requestParameters.nexusOpsTenant, requestParameters.inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantInvitesApiApiV1TenantInvitesInviteIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantInvitesApi
     */
    public apiV1TenantInvitesInviteIdGet(requestParameters: TenantInvitesApiApiV1TenantInvitesInviteIdGetRequest, options?: RawAxiosRequestConfig) {
        return TenantInvitesApiFp(this.configuration).apiV1TenantInvitesInviteIdGet(requestParameters.nexusOpsTenant, requestParameters.inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantInvitesApiApiV1TenantInvitesInviteIdResendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantInvitesApi
     */
    public apiV1TenantInvitesInviteIdResendPost(requestParameters: TenantInvitesApiApiV1TenantInvitesInviteIdResendPostRequest, options?: RawAxiosRequestConfig) {
        return TenantInvitesApiFp(this.configuration).apiV1TenantInvitesInviteIdResendPost(requestParameters.nexusOpsTenant, requestParameters.inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantInvitesApiApiV1TenantInvitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantInvitesApi
     */
    public apiV1TenantInvitesPost(requestParameters: TenantInvitesApiApiV1TenantInvitesPostRequest, options?: RawAxiosRequestConfig) {
        return TenantInvitesApiFp(this.configuration).apiV1TenantInvitesPost(requestParameters.nexusOpsTenant, requestParameters.inviteUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantProfileApi - axios parameter creator
 * @export
 */
export const TenantProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantProfileGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantProfileGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantProfileUpdateDto} [tenantProfileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantProfileProfilePut: async (nexusOpsTenant: string, tenantProfileUpdateDto?: TenantProfileUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantProfileProfilePut', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/profile/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantProfileUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantProfileSettingsEmailGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantProfileSettingsEmailGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/profile/settings/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantEmailSettingsInputDto} [tenantEmailSettingsInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantProfileSettingsEmailPut: async (nexusOpsTenant: string, tenantEmailSettingsInputDto?: TenantEmailSettingsInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantProfileSettingsEmailPut', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/profile/settings/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantEmailSettingsInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantProfileSettingsGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantProfileSettingsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/profile/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantSettingsInputDto} [tenantSettingsInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantProfileSettingsPut: async (nexusOpsTenant: string, tenantSettingsInputDto?: TenantSettingsInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantProfileSettingsPut', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant/profile/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantSettingsInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantProfileApi - functional programming interface
 * @export
 */
export const TenantProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantProfileGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantProfileGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantProfileApi.apiV1TenantProfileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantProfileUpdateDto} [tenantProfileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantProfileProfilePut(nexusOpsTenant: string, tenantProfileUpdateDto?: TenantProfileUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantProfileProfilePut(nexusOpsTenant, tenantProfileUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantProfileApi.apiV1TenantProfileProfilePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantProfileSettingsEmailGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantEmailSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantProfileSettingsEmailGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantProfileApi.apiV1TenantProfileSettingsEmailGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantEmailSettingsInputDto} [tenantEmailSettingsInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantProfileSettingsEmailPut(nexusOpsTenant: string, tenantEmailSettingsInputDto?: TenantEmailSettingsInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantEmailSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantProfileSettingsEmailPut(nexusOpsTenant, tenantEmailSettingsInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantProfileApi.apiV1TenantProfileSettingsEmailPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantProfileSettingsGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantProfileSettingsGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantProfileApi.apiV1TenantProfileSettingsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantSettingsInputDto} [tenantSettingsInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantProfileSettingsPut(nexusOpsTenant: string, tenantSettingsInputDto?: TenantSettingsInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantProfileSettingsPut(nexusOpsTenant, tenantSettingsInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantProfileApi.apiV1TenantProfileSettingsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantProfileApi - factory interface
 * @export
 */
export const TenantProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantProfileApiApiV1TenantProfileGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantProfileGet(requestParameters: TenantProfileApiApiV1TenantProfileGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantProfileDto> {
            return localVarFp.apiV1TenantProfileGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantProfileApiApiV1TenantProfileProfilePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantProfileProfilePut(requestParameters: TenantProfileApiApiV1TenantProfileProfilePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantProfileDto> {
            return localVarFp.apiV1TenantProfileProfilePut(requestParameters.nexusOpsTenant, requestParameters.tenantProfileUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantProfileApiApiV1TenantProfileSettingsEmailGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantProfileSettingsEmailGet(requestParameters: TenantProfileApiApiV1TenantProfileSettingsEmailGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantEmailSettingsDto> {
            return localVarFp.apiV1TenantProfileSettingsEmailGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantProfileApiApiV1TenantProfileSettingsEmailPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantProfileSettingsEmailPut(requestParameters: TenantProfileApiApiV1TenantProfileSettingsEmailPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantEmailSettingsDto> {
            return localVarFp.apiV1TenantProfileSettingsEmailPut(requestParameters.nexusOpsTenant, requestParameters.tenantEmailSettingsInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantProfileApiApiV1TenantProfileSettingsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantProfileSettingsGet(requestParameters: TenantProfileApiApiV1TenantProfileSettingsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantSettingsDto> {
            return localVarFp.apiV1TenantProfileSettingsGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantProfileApiApiV1TenantProfileSettingsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantProfileSettingsPut(requestParameters: TenantProfileApiApiV1TenantProfileSettingsPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantSettingsDto> {
            return localVarFp.apiV1TenantProfileSettingsPut(requestParameters.nexusOpsTenant, requestParameters.tenantSettingsInputDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TenantProfileGet operation in TenantProfileApi.
 * @export
 * @interface TenantProfileApiApiV1TenantProfileGetRequest
 */
export interface TenantProfileApiApiV1TenantProfileGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantProfileApiApiV1TenantProfileGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1TenantProfileProfilePut operation in TenantProfileApi.
 * @export
 * @interface TenantProfileApiApiV1TenantProfileProfilePutRequest
 */
export interface TenantProfileApiApiV1TenantProfileProfilePutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantProfileApiApiV1TenantProfileProfilePut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantProfileUpdateDto}
     * @memberof TenantProfileApiApiV1TenantProfileProfilePut
     */
    readonly tenantProfileUpdateDto?: TenantProfileUpdateDto
}

/**
 * Request parameters for apiV1TenantProfileSettingsEmailGet operation in TenantProfileApi.
 * @export
 * @interface TenantProfileApiApiV1TenantProfileSettingsEmailGetRequest
 */
export interface TenantProfileApiApiV1TenantProfileSettingsEmailGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantProfileApiApiV1TenantProfileSettingsEmailGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1TenantProfileSettingsEmailPut operation in TenantProfileApi.
 * @export
 * @interface TenantProfileApiApiV1TenantProfileSettingsEmailPutRequest
 */
export interface TenantProfileApiApiV1TenantProfileSettingsEmailPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantProfileApiApiV1TenantProfileSettingsEmailPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantEmailSettingsInputDto}
     * @memberof TenantProfileApiApiV1TenantProfileSettingsEmailPut
     */
    readonly tenantEmailSettingsInputDto?: TenantEmailSettingsInputDto
}

/**
 * Request parameters for apiV1TenantProfileSettingsGet operation in TenantProfileApi.
 * @export
 * @interface TenantProfileApiApiV1TenantProfileSettingsGetRequest
 */
export interface TenantProfileApiApiV1TenantProfileSettingsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantProfileApiApiV1TenantProfileSettingsGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1TenantProfileSettingsPut operation in TenantProfileApi.
 * @export
 * @interface TenantProfileApiApiV1TenantProfileSettingsPutRequest
 */
export interface TenantProfileApiApiV1TenantProfileSettingsPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantProfileApiApiV1TenantProfileSettingsPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantSettingsInputDto}
     * @memberof TenantProfileApiApiV1TenantProfileSettingsPut
     */
    readonly tenantSettingsInputDto?: TenantSettingsInputDto
}

/**
 * TenantProfileApi - object-oriented interface
 * @export
 * @class TenantProfileApi
 * @extends {BaseAPI}
 */
export class TenantProfileApi extends BaseAPI {
    /**
     * 
     * @param {TenantProfileApiApiV1TenantProfileGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProfileApi
     */
    public apiV1TenantProfileGet(requestParameters: TenantProfileApiApiV1TenantProfileGetRequest, options?: RawAxiosRequestConfig) {
        return TenantProfileApiFp(this.configuration).apiV1TenantProfileGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantProfileApiApiV1TenantProfileProfilePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProfileApi
     */
    public apiV1TenantProfileProfilePut(requestParameters: TenantProfileApiApiV1TenantProfileProfilePutRequest, options?: RawAxiosRequestConfig) {
        return TenantProfileApiFp(this.configuration).apiV1TenantProfileProfilePut(requestParameters.nexusOpsTenant, requestParameters.tenantProfileUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantProfileApiApiV1TenantProfileSettingsEmailGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProfileApi
     */
    public apiV1TenantProfileSettingsEmailGet(requestParameters: TenantProfileApiApiV1TenantProfileSettingsEmailGetRequest, options?: RawAxiosRequestConfig) {
        return TenantProfileApiFp(this.configuration).apiV1TenantProfileSettingsEmailGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantProfileApiApiV1TenantProfileSettingsEmailPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProfileApi
     */
    public apiV1TenantProfileSettingsEmailPut(requestParameters: TenantProfileApiApiV1TenantProfileSettingsEmailPutRequest, options?: RawAxiosRequestConfig) {
        return TenantProfileApiFp(this.configuration).apiV1TenantProfileSettingsEmailPut(requestParameters.nexusOpsTenant, requestParameters.tenantEmailSettingsInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantProfileApiApiV1TenantProfileSettingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProfileApi
     */
    public apiV1TenantProfileSettingsGet(requestParameters: TenantProfileApiApiV1TenantProfileSettingsGetRequest, options?: RawAxiosRequestConfig) {
        return TenantProfileApiFp(this.configuration).apiV1TenantProfileSettingsGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantProfileApiApiV1TenantProfileSettingsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantProfileApi
     */
    public apiV1TenantProfileSettingsPut(requestParameters: TenantProfileApiApiV1TenantProfileSettingsPutRequest, options?: RawAxiosRequestConfig) {
        return TenantProfileApiFp(this.configuration).apiV1TenantProfileSettingsPut(requestParameters.nexusOpsTenant, requestParameters.tenantSettingsInputDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantRequestsApi - axios parameter creator
 * @export
 */
export const TenantRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsCountersGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsCountersGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantRequestGetPaginatedDto} [tenantRequestGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsGetPost: async (nexusOpsTenant: string, tenantRequestGetPaginatedDto?: TenantRequestGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantRequestGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantRequestCreateDto} [tenantRequestCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsPost: async (nexusOpsTenant: string, tenantRequestCreateDto?: TenantRequestCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantRequestCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantRequestSearchPaginatedDto} [tenantRequestSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsSearchPost: async (nexusOpsTenant: string, tenantRequestSearchPaginatedDto?: TenantRequestSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantRequestSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdDelete: async (nexusOpsTenant: string, tenantRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdDelete', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdGet: async (nexusOpsTenant: string, tenantRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdGet', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestUpdateDto} [tenantRequestUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdPut: async (nexusOpsTenant: string, tenantRequestId: string, tenantRequestUpdateDto?: TenantRequestUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdPut', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantRequestUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestApplyResultDto} [tenantRequestApplyResultDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost: async (nexusOpsTenant: string, tenantRequestId: string, tenantRequestApplyResultDto?: TenantRequestApplyResultDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}/result/apply`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantRequestApplyResultDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestOperationResultInputDto} [tenantRequestOperationResultInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut: async (nexusOpsTenant: string, tenantRequestId: string, tenantRequestOperationResultInputDto?: TenantRequestOperationResultInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}/result/operation`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantRequestOperationResultInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestResultInputDto} [tenantRequestResultInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdResultPut: async (nexusOpsTenant: string, tenantRequestId: string, tenantRequestResultInputDto?: TenantRequestResultInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdResultPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdResultPut', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}/result`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantRequestResultInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestCloseDto} [tenantRequestCloseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost: async (nexusOpsTenant: string, tenantRequestId: string, tenantRequestCloseDto?: TenantRequestCloseDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}/stage/close`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantRequestCloseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost: async (nexusOpsTenant: string, tenantRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}/stage/complete`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost: async (nexusOpsTenant: string, tenantRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}/stage/draft`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost: async (nexusOpsTenant: string, tenantRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}/stage/report`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestRespondDto} [tenantRequestRespondDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost: async (nexusOpsTenant: string, tenantRequestId: string, tenantRequestRespondDto?: TenantRequestRespondDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}/stage/respond`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantRequestRespondDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestReworkDto} [tenantRequestReworkDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost: async (nexusOpsTenant: string, tenantRequestId: string, tenantRequestReworkDto?: TenantRequestReworkDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}/stage/rework`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantRequestReworkDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost: async (nexusOpsTenant: string, tenantRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}/stage/send`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost: async (nexusOpsTenant: string, tenantRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantRequestId' is not null or undefined
            assertParamExists('apiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost', 'tenantRequestId', tenantRequestId)
            const localVarPath = `/api/v1/tenant-to-tenant/tenant-requests/{tenantRequestId}/stage/start`
                .replace(`{${"tenantRequestId"}}`, encodeURIComponent(String(tenantRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantRequestsApi - functional programming interface
 * @export
 */
export const TenantRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsCountersGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsCountersGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsCountersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantRequestGetPaginatedDto} [tenantRequestGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsGetPost(nexusOpsTenant: string, tenantRequestGetPaginatedDto?: TenantRequestGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsGetPost(nexusOpsTenant, tenantRequestGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantRequestCreateDto} [tenantRequestCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsPost(nexusOpsTenant: string, tenantRequestCreateDto?: TenantRequestCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsPost(nexusOpsTenant, tenantRequestCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantRequestSearchPaginatedDto} [tenantRequestSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsSearchPost(nexusOpsTenant: string, tenantRequestSearchPaginatedDto?: TenantRequestSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsSearchPost(nexusOpsTenant, tenantRequestSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdDelete(nexusOpsTenant: string, tenantRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdDelete(nexusOpsTenant, tenantRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdGet(nexusOpsTenant: string, tenantRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdGet(nexusOpsTenant, tenantRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestUpdateDto} [tenantRequestUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdPut(nexusOpsTenant: string, tenantRequestId: string, tenantRequestUpdateDto?: TenantRequestUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdPut(nexusOpsTenant, tenantRequestId, tenantRequestUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestApplyResultDto} [tenantRequestApplyResultDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost(nexusOpsTenant: string, tenantRequestId: string, tenantRequestApplyResultDto?: TenantRequestApplyResultDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost(nexusOpsTenant, tenantRequestId, tenantRequestApplyResultDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestOperationResultInputDto} [tenantRequestOperationResultInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut(nexusOpsTenant: string, tenantRequestId: string, tenantRequestOperationResultInputDto?: TenantRequestOperationResultInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut(nexusOpsTenant, tenantRequestId, tenantRequestOperationResultInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestResultInputDto} [tenantRequestResultInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdResultPut(nexusOpsTenant: string, tenantRequestId: string, tenantRequestResultInputDto?: TenantRequestResultInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdResultPut(nexusOpsTenant, tenantRequestId, tenantRequestResultInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdResultPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestCloseDto} [tenantRequestCloseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost(nexusOpsTenant: string, tenantRequestId: string, tenantRequestCloseDto?: TenantRequestCloseDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost(nexusOpsTenant, tenantRequestId, tenantRequestCloseDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost(nexusOpsTenant: string, tenantRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost(nexusOpsTenant, tenantRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost(nexusOpsTenant: string, tenantRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost(nexusOpsTenant, tenantRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost(nexusOpsTenant: string, tenantRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost(nexusOpsTenant, tenantRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestRespondDto} [tenantRequestRespondDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost(nexusOpsTenant: string, tenantRequestId: string, tenantRequestRespondDto?: TenantRequestRespondDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost(nexusOpsTenant, tenantRequestId, tenantRequestRespondDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {TenantRequestReworkDto} [tenantRequestReworkDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost(nexusOpsTenant: string, tenantRequestId: string, tenantRequestReworkDto?: TenantRequestReworkDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost(nexusOpsTenant, tenantRequestId, tenantRequestReworkDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost(nexusOpsTenant: string, tenantRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost(nexusOpsTenant, tenantRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost(nexusOpsTenant: string, tenantRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost(nexusOpsTenant, tenantRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantRequestsApi - factory interface
 * @export
 */
export const TenantRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantRequestsApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsCountersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsCountersGet(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsCountersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestCountersDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsGetPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsGetPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsSearchPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsSearchPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdDelete(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdDelete(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdGet(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdGet(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdPut(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdPut(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestApplyResultDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestOperationResultInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdResultPut(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdResultPut(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestResultInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestCloseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestRespondDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestReworkDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantRequestDto> {
            return localVarFp.apiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsCountersGet operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsCountersGetRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsCountersGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsCountersGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsGetPost operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsGetPostRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantRequestGetPaginatedDto}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsGetPost
     */
    readonly tenantRequestGetPaginatedDto?: TenantRequestGetPaginatedDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsPost operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsPostRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantRequestCreateDto}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsPost
     */
    readonly tenantRequestCreateDto?: TenantRequestCreateDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsSearchPost operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsSearchPostRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantRequestSearchPaginatedDto}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsSearchPost
     */
    readonly tenantRequestSearchPaginatedDto?: TenantRequestSearchPaginatedDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdDelete operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdDeleteRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdDelete
     */
    readonly tenantRequestId: string
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdGet operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdGetRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdGet
     */
    readonly tenantRequestId: string
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdPut operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdPutRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdPut
     */
    readonly tenantRequestId: string

    /**
     * 
     * @type {TenantRequestUpdateDto}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdPut
     */
    readonly tenantRequestUpdateDto?: TenantRequestUpdateDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPostRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost
     */
    readonly tenantRequestId: string

    /**
     * 
     * @type {TenantRequestApplyResultDto}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost
     */
    readonly tenantRequestApplyResultDto?: TenantRequestApplyResultDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPutRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut
     */
    readonly tenantRequestId: string

    /**
     * 
     * @type {TenantRequestOperationResultInputDto}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut
     */
    readonly tenantRequestOperationResultInputDto?: TenantRequestOperationResultInputDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdResultPut operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultPutRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultPut
     */
    readonly tenantRequestId: string

    /**
     * 
     * @type {TenantRequestResultInputDto}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultPut
     */
    readonly tenantRequestResultInputDto?: TenantRequestResultInputDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePostRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost
     */
    readonly tenantRequestId: string

    /**
     * 
     * @type {TenantRequestCloseDto}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost
     */
    readonly tenantRequestCloseDto?: TenantRequestCloseDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePostRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost
     */
    readonly tenantRequestId: string
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPostRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost
     */
    readonly tenantRequestId: string
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPostRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost
     */
    readonly tenantRequestId: string
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPostRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost
     */
    readonly tenantRequestId: string

    /**
     * 
     * @type {TenantRequestRespondDto}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost
     */
    readonly tenantRequestRespondDto?: TenantRequestRespondDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPostRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost
     */
    readonly tenantRequestId: string

    /**
     * 
     * @type {TenantRequestReworkDto}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost
     */
    readonly tenantRequestReworkDto?: TenantRequestReworkDto
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPostRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost
     */
    readonly tenantRequestId: string
}

/**
 * Request parameters for apiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost operation in TenantRequestsApi.
 * @export
 * @interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPostRequest
 */
export interface TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost
     */
    readonly tenantRequestId: string
}

/**
 * TenantRequestsApi - object-oriented interface
 * @export
 * @class TenantRequestsApi
 * @extends {BaseAPI}
 */
export class TenantRequestsApi extends BaseAPI {
    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsCountersGet(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsCountersGetRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsGetPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsGetPostRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsGetPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsPostRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsSearchPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsSearchPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdDelete(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdDelete(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdGet(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdGetRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdGet(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdPut(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdPutRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdPut(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPostRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdResultApplyPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestApplyResultDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPutRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdResultOperationPut(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestOperationResultInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdResultPut(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdResultPutRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdResultPut(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestResultInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePostRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdStageClosePost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestCloseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePostRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdStageCompletePost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPostRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdStageDraftPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPostRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdStageReportPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPostRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdStageRespondPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestRespondDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPostRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdStageReworkPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, requestParameters.tenantRequestReworkDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPostRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdStageSendPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantRequestsApi
     */
    public apiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost(requestParameters: TenantRequestsApiApiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPostRequest, options?: RawAxiosRequestConfig) {
        return TenantRequestsApiFp(this.configuration).apiV1TenantToTenantTenantRequestsTenantRequestIdStageStartPost(requestParameters.nexusOpsTenant, requestParameters.tenantRequestId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantStructureApi - axios parameter creator
 * @export
 */
export const TenantStructureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantMembershipOfEntityUpdateDto} [tenantMembershipOfEntityUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantStructureMembershipEntityPut: async (nexusOpsTenant: string, tenantMembershipOfEntityUpdateDto?: TenantMembershipOfEntityUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantStructureMembershipEntityPut', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant-structure/membership/entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantMembershipOfEntityUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantStructureApi - functional programming interface
 * @export
 */
export const TenantStructureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantStructureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantMembershipOfEntityUpdateDto} [tenantMembershipOfEntityUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantStructureMembershipEntityPut(nexusOpsTenant: string, tenantMembershipOfEntityUpdateDto?: TenantMembershipOfEntityUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantStructureMembershipEntityPut(nexusOpsTenant, tenantMembershipOfEntityUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantStructureApi.apiV1TenantStructureMembershipEntityPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantStructureApi - factory interface
 * @export
 */
export const TenantStructureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantStructureApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantStructureApiApiV1TenantStructureMembershipEntityPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantStructureMembershipEntityPut(requestParameters: TenantStructureApiApiV1TenantStructureMembershipEntityPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TenantStructureMembershipEntityPut(requestParameters.nexusOpsTenant, requestParameters.tenantMembershipOfEntityUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TenantStructureMembershipEntityPut operation in TenantStructureApi.
 * @export
 * @interface TenantStructureApiApiV1TenantStructureMembershipEntityPutRequest
 */
export interface TenantStructureApiApiV1TenantStructureMembershipEntityPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantStructureApiApiV1TenantStructureMembershipEntityPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantMembershipOfEntityUpdateDto}
     * @memberof TenantStructureApiApiV1TenantStructureMembershipEntityPut
     */
    readonly tenantMembershipOfEntityUpdateDto?: TenantMembershipOfEntityUpdateDto
}

/**
 * TenantStructureApi - object-oriented interface
 * @export
 * @class TenantStructureApi
 * @extends {BaseAPI}
 */
export class TenantStructureApi extends BaseAPI {
    /**
     * 
     * @param {TenantStructureApiApiV1TenantStructureMembershipEntityPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantStructureApi
     */
    public apiV1TenantStructureMembershipEntityPut(requestParameters: TenantStructureApiApiV1TenantStructureMembershipEntityPutRequest, options?: RawAxiosRequestConfig) {
        return TenantStructureApiFp(this.configuration).apiV1TenantStructureMembershipEntityPut(requestParameters.nexusOpsTenant, requestParameters.tenantMembershipOfEntityUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantToTenantApi - axios parameter creator
 * @export
 */
export const TenantToTenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantCountersGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantToTenantCountersGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenant-to-tenant/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantToTenantApi - functional programming interface
 * @export
 */
export const TenantToTenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantToTenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantToTenantCountersGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantToTenantCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantToTenantCountersGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantToTenantApi.apiV1TenantToTenantCountersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantToTenantApi - factory interface
 * @export
 */
export const TenantToTenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantToTenantApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantToTenantApiApiV1TenantToTenantCountersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantToTenantCountersGet(requestParameters: TenantToTenantApiApiV1TenantToTenantCountersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantToTenantCountersDto> {
            return localVarFp.apiV1TenantToTenantCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TenantToTenantCountersGet operation in TenantToTenantApi.
 * @export
 * @interface TenantToTenantApiApiV1TenantToTenantCountersGetRequest
 */
export interface TenantToTenantApiApiV1TenantToTenantCountersGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantToTenantApiApiV1TenantToTenantCountersGet
     */
    readonly nexusOpsTenant: string
}

/**
 * TenantToTenantApi - object-oriented interface
 * @export
 * @class TenantToTenantApi
 * @extends {BaseAPI}
 */
export class TenantToTenantApi extends BaseAPI {
    /**
     * 
     * @param {TenantToTenantApiApiV1TenantToTenantCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantToTenantApi
     */
    public apiV1TenantToTenantCountersGet(requestParameters: TenantToTenantApiApiV1TenantToTenantCountersGetRequest, options?: RawAxiosRequestConfig) {
        return TenantToTenantApiFp(this.configuration).apiV1TenantToTenantCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantsApi - axios parameter creator
 * @export
 */
export const TenantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantGetPaginatedDto} [tenantGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantsGetPost: async (nexusOpsTenant: string, tenantGetPaginatedDto?: TenantGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenants/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantSearchPaginatedDto} [tenantSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantsSearchPost: async (nexusOpsTenant: string, tenantSearchPaginatedDto?: TenantSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/tenants/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantsTenantIdGet: async (nexusOpsTenant: string, tenantId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TenantsTenantIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiV1TenantsTenantIdGet', 'tenantId', tenantId)
            const localVarPath = `/api/v1/tenants/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantsApi - functional programming interface
 * @export
 */
export const TenantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantGetPaginatedDto} [tenantGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantsGetPost(nexusOpsTenant: string, tenantGetPaginatedDto?: TenantGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTenantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantsGetPost(nexusOpsTenant, tenantGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantsApi.apiV1TenantsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {TenantSearchPaginatedDto} [tenantSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantsSearchPost(nexusOpsTenant: string, tenantSearchPaginatedDto?: TenantSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTenantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantsSearchPost(nexusOpsTenant, tenantSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantsApi.apiV1TenantsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TenantsTenantIdGet(nexusOpsTenant: string, tenantId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TenantsTenantIdGet(nexusOpsTenant, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantsApi.apiV1TenantsTenantIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantsApi - factory interface
 * @export
 */
export const TenantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantsApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantsApiApiV1TenantsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantsGetPost(requestParameters: TenantsApiApiV1TenantsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTenantDto> {
            return localVarFp.apiV1TenantsGetPost(requestParameters.nexusOpsTenant, requestParameters.tenantGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantsApiApiV1TenantsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantsSearchPost(requestParameters: TenantsApiApiV1TenantsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTenantDto> {
            return localVarFp.apiV1TenantsSearchPost(requestParameters.nexusOpsTenant, requestParameters.tenantSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantsApiApiV1TenantsTenantIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TenantsTenantIdGet(requestParameters: TenantsApiApiV1TenantsTenantIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TenantDto> {
            return localVarFp.apiV1TenantsTenantIdGet(requestParameters.nexusOpsTenant, requestParameters.tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TenantsGetPost operation in TenantsApi.
 * @export
 * @interface TenantsApiApiV1TenantsGetPostRequest
 */
export interface TenantsApiApiV1TenantsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantsApiApiV1TenantsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantGetPaginatedDto}
     * @memberof TenantsApiApiV1TenantsGetPost
     */
    readonly tenantGetPaginatedDto?: TenantGetPaginatedDto
}

/**
 * Request parameters for apiV1TenantsSearchPost operation in TenantsApi.
 * @export
 * @interface TenantsApiApiV1TenantsSearchPostRequest
 */
export interface TenantsApiApiV1TenantsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantsApiApiV1TenantsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TenantSearchPaginatedDto}
     * @memberof TenantsApiApiV1TenantsSearchPost
     */
    readonly tenantSearchPaginatedDto?: TenantSearchPaginatedDto
}

/**
 * Request parameters for apiV1TenantsTenantIdGet operation in TenantsApi.
 * @export
 * @interface TenantsApiApiV1TenantsTenantIdGetRequest
 */
export interface TenantsApiApiV1TenantsTenantIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TenantsApiApiV1TenantsTenantIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TenantsApiApiV1TenantsTenantIdGet
     */
    readonly tenantId: string
}

/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
export class TenantsApi extends BaseAPI {
    /**
     * 
     * @param {TenantsApiApiV1TenantsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public apiV1TenantsGetPost(requestParameters: TenantsApiApiV1TenantsGetPostRequest, options?: RawAxiosRequestConfig) {
        return TenantsApiFp(this.configuration).apiV1TenantsGetPost(requestParameters.nexusOpsTenant, requestParameters.tenantGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantsApiApiV1TenantsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public apiV1TenantsSearchPost(requestParameters: TenantsApiApiV1TenantsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return TenantsApiFp(this.configuration).apiV1TenantsSearchPost(requestParameters.nexusOpsTenant, requestParameters.tenantSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantsApiApiV1TenantsTenantIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public apiV1TenantsTenantIdGet(requestParameters: TenantsApiApiV1TenantsTenantIdGetRequest, options?: RawAxiosRequestConfig) {
        return TenantsApiFp(this.configuration).apiV1TenantsTenantIdGet(requestParameters.nexusOpsTenant, requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestConfigurationApi - axios parameter creator
 * @export
 */
export const TestConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestConfigurationGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestConfigurationApi - functional programming interface
 * @export
 */
export const TestConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestConfigurationGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestConfigurationGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestConfigurationApi.apiV1TestConfigurationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TestConfigurationApi - factory interface
 * @export
 */
export const TestConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestConfigurationGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.apiV1TestConfigurationGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestConfigurationApi - object-oriented interface
 * @export
 * @class TestConfigurationApi
 * @extends {BaseAPI}
 */
export class TestConfigurationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestConfigurationApi
     */
    public apiV1TestConfigurationGet(options?: RawAxiosRequestConfig) {
        return TestConfigurationApiFp(this.configuration).apiV1TestConfigurationGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestErrorsApi - axios parameter creator
 * @export
 */
export const TestErrorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestErrors500Get: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/errors/500`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestErrorsThrowGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/errors/throw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestErrorsApi - functional programming interface
 * @export
 */
export const TestErrorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestErrorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestErrors500Get(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestErrors500Get(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestErrorsApi.apiV1TestErrors500Get']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestErrorsThrowGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestErrorsThrowGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestErrorsApi.apiV1TestErrorsThrowGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TestErrorsApi - factory interface
 * @export
 */
export const TestErrorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestErrorsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestErrors500Get(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TestErrors500Get(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestErrorsThrowGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TestErrorsThrowGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestErrorsApi - object-oriented interface
 * @export
 * @class TestErrorsApi
 * @extends {BaseAPI}
 */
export class TestErrorsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestErrorsApi
     */
    public apiV1TestErrors500Get(options?: RawAxiosRequestConfig) {
        return TestErrorsApiFp(this.configuration).apiV1TestErrors500Get(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestErrorsApi
     */
    public apiV1TestErrorsThrowGet(options?: RawAxiosRequestConfig) {
        return TestErrorsApiFp(this.configuration).apiV1TestErrorsThrowGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestI18nApi - axios parameter creator
 * @export
 */
export const TestI18nApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestI18nCulturesRequestCultureGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/i18n/cultures/request-culture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestI18nApi - functional programming interface
 * @export
 */
export const TestI18nApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestI18nApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestI18nCulturesRequestCultureGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestI18nCulturesRequestCultureGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestI18nApi.apiV1TestI18nCulturesRequestCultureGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TestI18nApi - factory interface
 * @export
 */
export const TestI18nApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestI18nApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestI18nCulturesRequestCultureGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.apiV1TestI18nCulturesRequestCultureGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestI18nApi - object-oriented interface
 * @export
 * @class TestI18nApi
 * @extends {BaseAPI}
 */
export class TestI18nApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestI18nApi
     */
    public apiV1TestI18nCulturesRequestCultureGet(options?: RawAxiosRequestConfig) {
        return TestI18nApiFp(this.configuration).apiV1TestI18nCulturesRequestCultureGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestIdentityApi - axios parameter creator
 * @export
 */
export const TestIdentityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [useCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestIdentityGet: async (useCache?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/identity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (useCache !== undefined) {
                localVarQueryParameter['useCache'] = useCache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestIdentityApi - functional programming interface
 * @export
 */
export const TestIdentityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestIdentityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [useCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestIdentityGet(useCache?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentIdentityTestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestIdentityGet(useCache, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestIdentityApi.apiV1TestIdentityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TestIdentityApi - factory interface
 * @export
 */
export const TestIdentityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestIdentityApiFp(configuration)
    return {
        /**
         * 
         * @param {TestIdentityApiApiV1TestIdentityGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestIdentityGet(requestParameters: TestIdentityApiApiV1TestIdentityGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CurrentIdentityTestDto> {
            return localVarFp.apiV1TestIdentityGet(requestParameters.useCache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TestIdentityGet operation in TestIdentityApi.
 * @export
 * @interface TestIdentityApiApiV1TestIdentityGetRequest
 */
export interface TestIdentityApiApiV1TestIdentityGetRequest {
    /**
     * 
     * @type {boolean}
     * @memberof TestIdentityApiApiV1TestIdentityGet
     */
    readonly useCache?: boolean
}

/**
 * TestIdentityApi - object-oriented interface
 * @export
 * @class TestIdentityApi
 * @extends {BaseAPI}
 */
export class TestIdentityApi extends BaseAPI {
    /**
     * 
     * @param {TestIdentityApiApiV1TestIdentityGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestIdentityApi
     */
    public apiV1TestIdentityGet(requestParameters: TestIdentityApiApiV1TestIdentityGetRequest = {}, options?: RawAxiosRequestConfig) {
        return TestIdentityApiFp(this.configuration).apiV1TestIdentityGet(requestParameters.useCache, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestPermissionsApi - axios parameter creator
 * @export
 */
export const TestPermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestPermissionsFeature1Get: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/permissions/feature1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestPermissionsFeature2Get: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/permissions/feature2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestPermissionsManagePost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/permissions/manage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestPermissionsPremiumfeatureGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/permissions/premiumfeature`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestPermissionsPremiumfeaturetenantGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1TestPermissionsPremiumfeaturetenantGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/test/permissions/premiumfeaturetenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestPermissionsReadGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/permissions/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestPermissionsApi - functional programming interface
 * @export
 */
export const TestPermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestPermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestPermissionsFeature1Get(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestPermissionsFeature1Get(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestPermissionsApi.apiV1TestPermissionsFeature1Get']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestPermissionsFeature2Get(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestPermissionsFeature2Get(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestPermissionsApi.apiV1TestPermissionsFeature2Get']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestPermissionsManagePost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestPermissionsManagePost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestPermissionsApi.apiV1TestPermissionsManagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestPermissionsPremiumfeatureGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestPermissionsPremiumfeatureGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestPermissionsApi.apiV1TestPermissionsPremiumfeatureGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestPermissionsPremiumfeaturetenantGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestPermissionsPremiumfeaturetenantGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestPermissionsApi.apiV1TestPermissionsPremiumfeaturetenantGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestPermissionsReadGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestPermissionsReadGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestPermissionsApi.apiV1TestPermissionsReadGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TestPermissionsApi - factory interface
 * @export
 */
export const TestPermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestPermissionsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestPermissionsFeature1Get(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TestPermissionsFeature1Get(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestPermissionsFeature2Get(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TestPermissionsFeature2Get(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestPermissionsManagePost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TestPermissionsManagePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestPermissionsPremiumfeatureGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TestPermissionsPremiumfeatureGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TestPermissionsApiApiV1TestPermissionsPremiumfeaturetenantGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestPermissionsPremiumfeaturetenantGet(requestParameters: TestPermissionsApiApiV1TestPermissionsPremiumfeaturetenantGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TestPermissionsPremiumfeaturetenantGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestPermissionsReadGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1TestPermissionsReadGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TestPermissionsPremiumfeaturetenantGet operation in TestPermissionsApi.
 * @export
 * @interface TestPermissionsApiApiV1TestPermissionsPremiumfeaturetenantGetRequest
 */
export interface TestPermissionsApiApiV1TestPermissionsPremiumfeaturetenantGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof TestPermissionsApiApiV1TestPermissionsPremiumfeaturetenantGet
     */
    readonly nexusOpsTenant: string
}

/**
 * TestPermissionsApi - object-oriented interface
 * @export
 * @class TestPermissionsApi
 * @extends {BaseAPI}
 */
export class TestPermissionsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPermissionsApi
     */
    public apiV1TestPermissionsFeature1Get(options?: RawAxiosRequestConfig) {
        return TestPermissionsApiFp(this.configuration).apiV1TestPermissionsFeature1Get(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPermissionsApi
     */
    public apiV1TestPermissionsFeature2Get(options?: RawAxiosRequestConfig) {
        return TestPermissionsApiFp(this.configuration).apiV1TestPermissionsFeature2Get(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPermissionsApi
     */
    public apiV1TestPermissionsManagePost(options?: RawAxiosRequestConfig) {
        return TestPermissionsApiFp(this.configuration).apiV1TestPermissionsManagePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPermissionsApi
     */
    public apiV1TestPermissionsPremiumfeatureGet(options?: RawAxiosRequestConfig) {
        return TestPermissionsApiFp(this.configuration).apiV1TestPermissionsPremiumfeatureGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TestPermissionsApiApiV1TestPermissionsPremiumfeaturetenantGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPermissionsApi
     */
    public apiV1TestPermissionsPremiumfeaturetenantGet(requestParameters: TestPermissionsApiApiV1TestPermissionsPremiumfeaturetenantGetRequest, options?: RawAxiosRequestConfig) {
        return TestPermissionsApiFp(this.configuration).apiV1TestPermissionsPremiumfeaturetenantGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestPermissionsApi
     */
    public apiV1TestPermissionsReadGet(options?: RawAxiosRequestConfig) {
        return TestPermissionsApiFp(this.configuration).apiV1TestPermissionsReadGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestSomethingApi - axios parameter creator
 * @export
 */
export const TestSomethingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestSomethingHelloGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/something/hello`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestSomethingPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/something`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestSomethingApi - functional programming interface
 * @export
 */
export const TestSomethingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestSomethingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestSomethingHelloGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestSomethingHelloGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestSomethingApi.apiV1TestSomethingHelloGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestSomethingPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentIdentityTestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestSomethingPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestSomethingApi.apiV1TestSomethingPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TestSomethingApi - factory interface
 * @export
 */
export const TestSomethingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestSomethingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestSomethingHelloGet(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.apiV1TestSomethingHelloGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestSomethingPost(options?: RawAxiosRequestConfig): AxiosPromise<CurrentIdentityTestDto> {
            return localVarFp.apiV1TestSomethingPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestSomethingApi - object-oriented interface
 * @export
 * @class TestSomethingApi
 * @extends {BaseAPI}
 */
export class TestSomethingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestSomethingApi
     */
    public apiV1TestSomethingHelloGet(options?: RawAxiosRequestConfig) {
        return TestSomethingApiFp(this.configuration).apiV1TestSomethingHelloGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestSomethingApi
     */
    public apiV1TestSomethingPost(options?: RawAxiosRequestConfig) {
        return TestSomethingApiFp(this.configuration).apiV1TestSomethingPost(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TwilioWebhookApi - axios parameter creator
 * @export
 */
export const TwilioWebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebHookTwilioPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web-hook/twilio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TwilioWebhookApi - functional programming interface
 * @export
 */
export const TwilioWebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TwilioWebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WebHookTwilioPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WebHookTwilioPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwilioWebhookApi.apiV1WebHookTwilioPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TwilioWebhookApi - factory interface
 * @export
 */
export const TwilioWebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TwilioWebhookApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebHookTwilioPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1WebHookTwilioPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TwilioWebhookApi - object-oriented interface
 * @export
 * @class TwilioWebhookApi
 * @extends {BaseAPI}
 */
export class TwilioWebhookApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioWebhookApi
     */
    public apiV1WebHookTwilioPost(options?: RawAxiosRequestConfig) {
        return TwilioWebhookApiFp(this.configuration).apiV1WebHookTwilioPost(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserSecretsApi - axios parameter creator
 * @export
 */
export const UserSecretsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userSecretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserSecretsUserSecretIdValueGet: async (nexusOpsTenant: string, userSecretId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UserSecretsUserSecretIdValueGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'userSecretId' is not null or undefined
            assertParamExists('apiV1UserSecretsUserSecretIdValueGet', 'userSecretId', userSecretId)
            const localVarPath = `/api/v1/user-secrets/{userSecretId}/value`
                .replace(`{${"userSecretId"}}`, encodeURIComponent(String(userSecretId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSecretsApi - functional programming interface
 * @export
 */
export const UserSecretsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSecretsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userSecretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserSecretsUserSecretIdValueGet(nexusOpsTenant: string, userSecretId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSecretValueDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserSecretsUserSecretIdValueGet(nexusOpsTenant, userSecretId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserSecretsApi.apiV1UserSecretsUserSecretIdValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserSecretsApi - factory interface
 * @export
 */
export const UserSecretsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSecretsApiFp(configuration)
    return {
        /**
         * 
         * @param {UserSecretsApiApiV1UserSecretsUserSecretIdValueGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserSecretsUserSecretIdValueGet(requestParameters: UserSecretsApiApiV1UserSecretsUserSecretIdValueGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserSecretValueDto> {
            return localVarFp.apiV1UserSecretsUserSecretIdValueGet(requestParameters.nexusOpsTenant, requestParameters.userSecretId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1UserSecretsUserSecretIdValueGet operation in UserSecretsApi.
 * @export
 * @interface UserSecretsApiApiV1UserSecretsUserSecretIdValueGetRequest
 */
export interface UserSecretsApiApiV1UserSecretsUserSecretIdValueGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UserSecretsApiApiV1UserSecretsUserSecretIdValueGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof UserSecretsApiApiV1UserSecretsUserSecretIdValueGet
     */
    readonly userSecretId: string
}

/**
 * UserSecretsApi - object-oriented interface
 * @export
 * @class UserSecretsApi
 * @extends {BaseAPI}
 */
export class UserSecretsApi extends BaseAPI {
    /**
     * 
     * @param {UserSecretsApiApiV1UserSecretsUserSecretIdValueGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSecretsApi
     */
    public apiV1UserSecretsUserSecretIdValueGet(requestParameters: UserSecretsApiApiV1UserSecretsUserSecretIdValueGetRequest, options?: RawAxiosRequestConfig) {
        return UserSecretsApiFp(this.configuration).apiV1UserSecretsUserSecretIdValueGet(requestParameters.nexusOpsTenant, requestParameters.userSecretId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersByIdsGetPost: async (nexusOpsTenant: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersByIdsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/users/by-ids/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {UserGetPaginatedDto} [userGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersGetPost: async (nexusOpsTenant: string, userGetPaginatedDto?: UserGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/users/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {UserSearchPaginatedDto} [userSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersSearchPost: async (nexusOpsTenant: string, userSearchPaginatedDto?: UserSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/users/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} targetUserId 
         * @param {UserSuspendDto} [userSuspendDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersTargetUserIdSuspendPost: async (nexusOpsTenant: string, targetUserId: string, userSuspendDto?: UserSuspendDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersTargetUserIdSuspendPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'targetUserId' is not null or undefined
            assertParamExists('apiV1UsersTargetUserIdSuspendPost', 'targetUserId', targetUserId)
            const localVarPath = `/api/v1/users/{targetUserId}/suspend`
                .replace(`{${"targetUserId"}}`, encodeURIComponent(String(targetUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSuspendDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} targetUserId 
         * @param {UserUnsuspendDto} [userUnsuspendDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersTargetUserIdUnsuspendPost: async (nexusOpsTenant: string, targetUserId: string, userUnsuspendDto?: UserUnsuspendDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersTargetUserIdUnsuspendPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'targetUserId' is not null or undefined
            assertParamExists('apiV1UsersTargetUserIdUnsuspendPost', 'targetUserId', targetUserId)
            const localVarPath = `/api/v1/users/{targetUserId}/unsuspend`
                .replace(`{${"targetUserId"}}`, encodeURIComponent(String(targetUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUnsuspendDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDelete: async (nexusOpsTenant: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersUserIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdDelete', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDepartmentsDepartmentIdDelete: async (nexusOpsTenant: string, userId: string, departmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersUserIdDepartmentsDepartmentIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdDepartmentsDepartmentIdDelete', 'userId', userId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1UsersUserIdDepartmentsDepartmentIdDelete', 'departmentId', departmentId)
            const localVarPath = `/api/v1/users/{userId}/departments/{departmentId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDepartmentsDepartmentIdPost: async (nexusOpsTenant: string, userId: string, departmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersUserIdDepartmentsDepartmentIdPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdDepartmentsDepartmentIdPost', 'userId', userId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1UsersUserIdDepartmentsDepartmentIdPost', 'departmentId', departmentId)
            const localVarPath = `/api/v1/users/{userId}/departments/{departmentId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDepartmentsGet: async (nexusOpsTenant: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersUserIdDepartmentsGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdDepartmentsGet', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/departments`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdGet: async (nexusOpsTenant: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersUserIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdGet', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdLocationsGet: async (nexusOpsTenant: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersUserIdLocationsGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdLocationsGet', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/locations`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdLocationsLocationIdDelete: async (nexusOpsTenant: string, userId: string, locationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersUserIdLocationsLocationIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdLocationsLocationIdDelete', 'userId', userId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1UsersUserIdLocationsLocationIdDelete', 'locationId', locationId)
            const localVarPath = `/api/v1/users/{userId}/locations/{locationId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdLocationsLocationIdPost: async (nexusOpsTenant: string, userId: string, locationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersUserIdLocationsLocationIdPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdLocationsLocationIdPost', 'userId', userId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('apiV1UsersUserIdLocationsLocationIdPost', 'locationId', locationId)
            const localVarPath = `/api/v1/users/{userId}/locations/{locationId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {UpdateTenantUserDto} [updateTenantUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdPut: async (nexusOpsTenant: string, userId: string, updateTenantUserDto?: UpdateTenantUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1UsersUserIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdPut', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTenantUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersByIdsGetPost(nexusOpsTenant: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersByIdsGetPost(nexusOpsTenant, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersByIdsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {UserGetPaginatedDto} [userGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersGetPost(nexusOpsTenant: string, userGetPaginatedDto?: UserGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersGetPost(nexusOpsTenant, userGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {UserSearchPaginatedDto} [userSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersSearchPost(nexusOpsTenant: string, userSearchPaginatedDto?: UserSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersSearchPost(nexusOpsTenant, userSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} targetUserId 
         * @param {UserSuspendDto} [userSuspendDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersTargetUserIdSuspendPost(nexusOpsTenant: string, targetUserId: string, userSuspendDto?: UserSuspendDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersTargetUserIdSuspendPost(nexusOpsTenant, targetUserId, userSuspendDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersTargetUserIdSuspendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} targetUserId 
         * @param {UserUnsuspendDto} [userUnsuspendDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersTargetUserIdUnsuspendPost(nexusOpsTenant: string, targetUserId: string, userUnsuspendDto?: UserUnsuspendDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersTargetUserIdUnsuspendPost(nexusOpsTenant, targetUserId, userUnsuspendDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersTargetUserIdUnsuspendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdDelete(nexusOpsTenant: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdDelete(nexusOpsTenant, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersUserIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdDepartmentsDepartmentIdDelete(nexusOpsTenant: string, userId: string, departmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdDepartmentsDepartmentIdDelete(nexusOpsTenant, userId, departmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersUserIdDepartmentsDepartmentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdDepartmentsDepartmentIdPost(nexusOpsTenant: string, userId: string, departmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdDepartmentsDepartmentIdPost(nexusOpsTenant, userId, departmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersUserIdDepartmentsDepartmentIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdDepartmentsGet(nexusOpsTenant: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdDepartmentsGet(nexusOpsTenant, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersUserIdDepartmentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdGet(nexusOpsTenant: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdGet(nexusOpsTenant, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdLocationsGet(nexusOpsTenant: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdLocationsGet(nexusOpsTenant, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersUserIdLocationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdLocationsLocationIdDelete(nexusOpsTenant: string, userId: string, locationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdLocationsLocationIdDelete(nexusOpsTenant, userId, locationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersUserIdLocationsLocationIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdLocationsLocationIdPost(nexusOpsTenant: string, userId: string, locationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdLocationsLocationIdPost(nexusOpsTenant, userId, locationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersUserIdLocationsLocationIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} userId 
         * @param {UpdateTenantUserDto} [updateTenantUserDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdPut(nexusOpsTenant: string, userId: string, updateTenantUserDto?: UpdateTenantUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdPut(nexusOpsTenant, userId, updateTenantUserDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.apiV1UsersUserIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {UsersApiApiV1UsersByIdsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersByIdsGetPost(requestParameters: UsersApiApiV1UsersByIdsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserDto>> {
            return localVarFp.apiV1UsersByIdsGetPost(requestParameters.nexusOpsTenant, requestParameters.requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiApiV1UsersGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersGetPost(requestParameters: UsersApiApiV1UsersGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfUserDto> {
            return localVarFp.apiV1UsersGetPost(requestParameters.nexusOpsTenant, requestParameters.userGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiApiV1UsersSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersSearchPost(requestParameters: UsersApiApiV1UsersSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfUserDto> {
            return localVarFp.apiV1UsersSearchPost(requestParameters.nexusOpsTenant, requestParameters.userSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiApiV1UsersTargetUserIdSuspendPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersTargetUserIdSuspendPost(requestParameters: UsersApiApiV1UsersTargetUserIdSuspendPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.apiV1UsersTargetUserIdSuspendPost(requestParameters.nexusOpsTenant, requestParameters.targetUserId, requestParameters.userSuspendDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiApiV1UsersTargetUserIdUnsuspendPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersTargetUserIdUnsuspendPost(requestParameters: UsersApiApiV1UsersTargetUserIdUnsuspendPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.apiV1UsersTargetUserIdUnsuspendPost(requestParameters.nexusOpsTenant, requestParameters.targetUserId, requestParameters.userUnsuspendDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiApiV1UsersUserIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDelete(requestParameters: UsersApiApiV1UsersUserIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1UsersUserIdDelete(requestParameters.nexusOpsTenant, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiApiV1UsersUserIdDepartmentsDepartmentIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDepartmentsDepartmentIdDelete(requestParameters: UsersApiApiV1UsersUserIdDepartmentsDepartmentIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.apiV1UsersUserIdDepartmentsDepartmentIdDelete(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiApiV1UsersUserIdDepartmentsDepartmentIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDepartmentsDepartmentIdPost(requestParameters: UsersApiApiV1UsersUserIdDepartmentsDepartmentIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.apiV1UsersUserIdDepartmentsDepartmentIdPost(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiApiV1UsersUserIdDepartmentsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDepartmentsGet(requestParameters: UsersApiApiV1UsersUserIdDepartmentsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DepartmentDto>> {
            return localVarFp.apiV1UsersUserIdDepartmentsGet(requestParameters.nexusOpsTenant, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiApiV1UsersUserIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdGet(requestParameters: UsersApiApiV1UsersUserIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.apiV1UsersUserIdGet(requestParameters.nexusOpsTenant, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiApiV1UsersUserIdLocationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdLocationsGet(requestParameters: UsersApiApiV1UsersUserIdLocationsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<LocationDto>> {
            return localVarFp.apiV1UsersUserIdLocationsGet(requestParameters.nexusOpsTenant, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiApiV1UsersUserIdLocationsLocationIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdLocationsLocationIdDelete(requestParameters: UsersApiApiV1UsersUserIdLocationsLocationIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.apiV1UsersUserIdLocationsLocationIdDelete(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiApiV1UsersUserIdLocationsLocationIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdLocationsLocationIdPost(requestParameters: UsersApiApiV1UsersUserIdLocationsLocationIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.apiV1UsersUserIdLocationsLocationIdPost(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiApiV1UsersUserIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdPut(requestParameters: UsersApiApiV1UsersUserIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.apiV1UsersUserIdPut(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.updateTenantUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1UsersByIdsGetPost operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersByIdsGetPostRequest
 */
export interface UsersApiApiV1UsersByIdsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersByIdsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {Array<string>}
     * @memberof UsersApiApiV1UsersByIdsGetPost
     */
    readonly requestBody?: Array<string>
}

/**
 * Request parameters for apiV1UsersGetPost operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersGetPostRequest
 */
export interface UsersApiApiV1UsersGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {UserGetPaginatedDto}
     * @memberof UsersApiApiV1UsersGetPost
     */
    readonly userGetPaginatedDto?: UserGetPaginatedDto
}

/**
 * Request parameters for apiV1UsersSearchPost operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersSearchPostRequest
 */
export interface UsersApiApiV1UsersSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {UserSearchPaginatedDto}
     * @memberof UsersApiApiV1UsersSearchPost
     */
    readonly userSearchPaginatedDto?: UserSearchPaginatedDto
}

/**
 * Request parameters for apiV1UsersTargetUserIdSuspendPost operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersTargetUserIdSuspendPostRequest
 */
export interface UsersApiApiV1UsersTargetUserIdSuspendPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersTargetUserIdSuspendPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersTargetUserIdSuspendPost
     */
    readonly targetUserId: string

    /**
     * 
     * @type {UserSuspendDto}
     * @memberof UsersApiApiV1UsersTargetUserIdSuspendPost
     */
    readonly userSuspendDto?: UserSuspendDto
}

/**
 * Request parameters for apiV1UsersTargetUserIdUnsuspendPost operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersTargetUserIdUnsuspendPostRequest
 */
export interface UsersApiApiV1UsersTargetUserIdUnsuspendPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersTargetUserIdUnsuspendPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersTargetUserIdUnsuspendPost
     */
    readonly targetUserId: string

    /**
     * 
     * @type {UserUnsuspendDto}
     * @memberof UsersApiApiV1UsersTargetUserIdUnsuspendPost
     */
    readonly userUnsuspendDto?: UserUnsuspendDto
}

/**
 * Request parameters for apiV1UsersUserIdDelete operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersUserIdDeleteRequest
 */
export interface UsersApiApiV1UsersUserIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdDelete
     */
    readonly userId: string
}

/**
 * Request parameters for apiV1UsersUserIdDepartmentsDepartmentIdDelete operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersUserIdDepartmentsDepartmentIdDeleteRequest
 */
export interface UsersApiApiV1UsersUserIdDepartmentsDepartmentIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdDepartmentsDepartmentIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdDepartmentsDepartmentIdDelete
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdDepartmentsDepartmentIdDelete
     */
    readonly departmentId: string
}

/**
 * Request parameters for apiV1UsersUserIdDepartmentsDepartmentIdPost operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersUserIdDepartmentsDepartmentIdPostRequest
 */
export interface UsersApiApiV1UsersUserIdDepartmentsDepartmentIdPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdDepartmentsDepartmentIdPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdDepartmentsDepartmentIdPost
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdDepartmentsDepartmentIdPost
     */
    readonly departmentId: string
}

/**
 * Request parameters for apiV1UsersUserIdDepartmentsGet operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersUserIdDepartmentsGetRequest
 */
export interface UsersApiApiV1UsersUserIdDepartmentsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdDepartmentsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdDepartmentsGet
     */
    readonly userId: string
}

/**
 * Request parameters for apiV1UsersUserIdGet operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersUserIdGetRequest
 */
export interface UsersApiApiV1UsersUserIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdGet
     */
    readonly userId: string
}

/**
 * Request parameters for apiV1UsersUserIdLocationsGet operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersUserIdLocationsGetRequest
 */
export interface UsersApiApiV1UsersUserIdLocationsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdLocationsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdLocationsGet
     */
    readonly userId: string
}

/**
 * Request parameters for apiV1UsersUserIdLocationsLocationIdDelete operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersUserIdLocationsLocationIdDeleteRequest
 */
export interface UsersApiApiV1UsersUserIdLocationsLocationIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdLocationsLocationIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdLocationsLocationIdDelete
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdLocationsLocationIdDelete
     */
    readonly locationId: string
}

/**
 * Request parameters for apiV1UsersUserIdLocationsLocationIdPost operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersUserIdLocationsLocationIdPostRequest
 */
export interface UsersApiApiV1UsersUserIdLocationsLocationIdPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdLocationsLocationIdPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdLocationsLocationIdPost
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdLocationsLocationIdPost
     */
    readonly locationId: string
}

/**
 * Request parameters for apiV1UsersUserIdPut operation in UsersApi.
 * @export
 * @interface UsersApiApiV1UsersUserIdPutRequest
 */
export interface UsersApiApiV1UsersUserIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiApiV1UsersUserIdPut
     */
    readonly userId: string

    /**
     * 
     * @type {UpdateTenantUserDto}
     * @memberof UsersApiApiV1UsersUserIdPut
     */
    readonly updateTenantUserDto?: UpdateTenantUserDto
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {UsersApiApiV1UsersByIdsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersByIdsGetPost(requestParameters: UsersApiApiV1UsersByIdsGetPostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersByIdsGetPost(requestParameters.nexusOpsTenant, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiApiV1UsersGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersGetPost(requestParameters: UsersApiApiV1UsersGetPostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersGetPost(requestParameters.nexusOpsTenant, requestParameters.userGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiApiV1UsersSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersSearchPost(requestParameters: UsersApiApiV1UsersSearchPostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersSearchPost(requestParameters.nexusOpsTenant, requestParameters.userSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiApiV1UsersTargetUserIdSuspendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersTargetUserIdSuspendPost(requestParameters: UsersApiApiV1UsersTargetUserIdSuspendPostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersTargetUserIdSuspendPost(requestParameters.nexusOpsTenant, requestParameters.targetUserId, requestParameters.userSuspendDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiApiV1UsersTargetUserIdUnsuspendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersTargetUserIdUnsuspendPost(requestParameters: UsersApiApiV1UsersTargetUserIdUnsuspendPostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersTargetUserIdUnsuspendPost(requestParameters.nexusOpsTenant, requestParameters.targetUserId, requestParameters.userUnsuspendDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiApiV1UsersUserIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersUserIdDelete(requestParameters: UsersApiApiV1UsersUserIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersUserIdDelete(requestParameters.nexusOpsTenant, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiApiV1UsersUserIdDepartmentsDepartmentIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersUserIdDepartmentsDepartmentIdDelete(requestParameters: UsersApiApiV1UsersUserIdDepartmentsDepartmentIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersUserIdDepartmentsDepartmentIdDelete(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiApiV1UsersUserIdDepartmentsDepartmentIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersUserIdDepartmentsDepartmentIdPost(requestParameters: UsersApiApiV1UsersUserIdDepartmentsDepartmentIdPostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersUserIdDepartmentsDepartmentIdPost(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiApiV1UsersUserIdDepartmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersUserIdDepartmentsGet(requestParameters: UsersApiApiV1UsersUserIdDepartmentsGetRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersUserIdDepartmentsGet(requestParameters.nexusOpsTenant, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiApiV1UsersUserIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersUserIdGet(requestParameters: UsersApiApiV1UsersUserIdGetRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersUserIdGet(requestParameters.nexusOpsTenant, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiApiV1UsersUserIdLocationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersUserIdLocationsGet(requestParameters: UsersApiApiV1UsersUserIdLocationsGetRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersUserIdLocationsGet(requestParameters.nexusOpsTenant, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiApiV1UsersUserIdLocationsLocationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersUserIdLocationsLocationIdDelete(requestParameters: UsersApiApiV1UsersUserIdLocationsLocationIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersUserIdLocationsLocationIdDelete(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiApiV1UsersUserIdLocationsLocationIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersUserIdLocationsLocationIdPost(requestParameters: UsersApiApiV1UsersUserIdLocationsLocationIdPostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersUserIdLocationsLocationIdPost(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiApiV1UsersUserIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiV1UsersUserIdPut(requestParameters: UsersApiApiV1UsersUserIdPutRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiV1UsersUserIdPut(requestParameters.nexusOpsTenant, requestParameters.userId, requestParameters.updateTenantUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleBodyTypesApi - axios parameter creator
 * @export
 */
export const VehicleBodyTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehicleBodyTypeGetPaginatedDto} [vehicleBodyTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesBodyTypesGetPost: async (vehicleBodyTypeGetPaginatedDto?: VehicleBodyTypeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/body-types/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleBodyTypeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleBodyTypeSearchPaginatedDto} [vehicleBodyTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesBodyTypesSearchPost: async (vehicleBodyTypeSearchPaginatedDto?: VehicleBodyTypeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/body-types/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleBodyTypeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleBodyTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet: async (vehicleBodyTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleBodyTypeId' is not null or undefined
            assertParamExists('apiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet', 'vehicleBodyTypeId', vehicleBodyTypeId)
            const localVarPath = `/api/v1/reference-data/vehicles/body-types/{vehicleBodyTypeId}`
                .replace(`{${"vehicleBodyTypeId"}}`, encodeURIComponent(String(vehicleBodyTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleBodyTypesApi - functional programming interface
 * @export
 */
export const VehicleBodyTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleBodyTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehicleBodyTypeGetPaginatedDto} [vehicleBodyTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesBodyTypesGetPost(vehicleBodyTypeGetPaginatedDto?: VehicleBodyTypeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleBodyTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesBodyTypesGetPost(vehicleBodyTypeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleBodyTypesApi.apiV1ReferenceDataVehiclesBodyTypesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleBodyTypeSearchPaginatedDto} [vehicleBodyTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesBodyTypesSearchPost(vehicleBodyTypeSearchPaginatedDto?: VehicleBodyTypeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleBodyTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesBodyTypesSearchPost(vehicleBodyTypeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleBodyTypesApi.apiV1ReferenceDataVehiclesBodyTypesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} vehicleBodyTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet(vehicleBodyTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleBodyTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet(vehicleBodyTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleBodyTypesApi.apiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VehicleBodyTypesApi - factory interface
 * @export
 */
export const VehicleBodyTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleBodyTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesBodyTypesGetPost(requestParameters: VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleBodyTypeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesBodyTypesGetPost(requestParameters.vehicleBodyTypeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesBodyTypesSearchPost(requestParameters: VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleBodyTypeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesBodyTypesSearchPost(requestParameters.vehicleBodyTypeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet(requestParameters: VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleBodyTypeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet(requestParameters.vehicleBodyTypeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ReferenceDataVehiclesBodyTypesGetPost operation in VehicleBodyTypesApi.
 * @export
 * @interface VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesGetPostRequest
 */
export interface VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesGetPostRequest {
    /**
     * 
     * @type {VehicleBodyTypeGetPaginatedDto}
     * @memberof VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesGetPost
     */
    readonly vehicleBodyTypeGetPaginatedDto?: VehicleBodyTypeGetPaginatedDto
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesBodyTypesSearchPost operation in VehicleBodyTypesApi.
 * @export
 * @interface VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesSearchPostRequest
 */
export interface VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesSearchPostRequest {
    /**
     * 
     * @type {VehicleBodyTypeSearchPaginatedDto}
     * @memberof VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesSearchPost
     */
    readonly vehicleBodyTypeSearchPaginatedDto?: VehicleBodyTypeSearchPaginatedDto
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet operation in VehicleBodyTypesApi.
 * @export
 * @interface VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGetRequest
 */
export interface VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet
     */
    readonly vehicleBodyTypeId: string
}

/**
 * VehicleBodyTypesApi - object-oriented interface
 * @export
 * @class VehicleBodyTypesApi
 * @extends {BaseAPI}
 */
export class VehicleBodyTypesApi extends BaseAPI {
    /**
     * 
     * @param {VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleBodyTypesApi
     */
    public apiV1ReferenceDataVehiclesBodyTypesGetPost(requestParameters: VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleBodyTypesApiFp(this.configuration).apiV1ReferenceDataVehiclesBodyTypesGetPost(requestParameters.vehicleBodyTypeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleBodyTypesApi
     */
    public apiV1ReferenceDataVehiclesBodyTypesSearchPost(requestParameters: VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleBodyTypesApiFp(this.configuration).apiV1ReferenceDataVehiclesBodyTypesSearchPost(requestParameters.vehicleBodyTypeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleBodyTypesApi
     */
    public apiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet(requestParameters: VehicleBodyTypesApiApiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleBodyTypesApiFp(this.configuration).apiV1ReferenceDataVehiclesBodyTypesVehicleBodyTypeIdGet(requestParameters.vehicleBodyTypeId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleDamagesApi - axios parameter creator
 * @export
 */
export const VehicleDamagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleDamageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehicleDamagesVehicleDamageIdGet: async (nexusOpsTenant: string, vehicleDamageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehicleDamagesVehicleDamageIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleDamageId' is not null or undefined
            assertParamExists('apiV1VehicleDamagesVehicleDamageIdGet', 'vehicleDamageId', vehicleDamageId)
            const localVarPath = `/api/v1/vehicle-damages/{vehicleDamageId}`
                .replace(`{${"vehicleDamageId"}}`, encodeURIComponent(String(vehicleDamageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} [contractId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesByContractGet: async (nexusOpsTenant: string, vehicleId: string, contractId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesByContractGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesByContractGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/damages/by-contract`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {Array<string>} [contractIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesByContractsGet: async (nexusOpsTenant: string, vehicleId: string, contractIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesByContractsGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesByContractsGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/damages/by-contracts`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contractIds) {
                localVarQueryParameter['contractIds'] = contractIds;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {Array<string>} [vehicleDamageIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesByIdsGet: async (nexusOpsTenant: string, vehicleId: string, vehicleDamageIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesByIdsGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesByIdsGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/damages/by-ids`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (vehicleDamageIds) {
                localVarQueryParameter['vehicleDamageIds'] = vehicleDamageIds;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {VehicleDamageGetCountersDto} [vehicleDamageGetCountersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesCountersGetPost: async (nexusOpsTenant: string, vehicleId: string, vehicleDamageGetCountersDto?: VehicleDamageGetCountersDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesCountersGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesCountersGetPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/damages/counters/get`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleDamageGetCountersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} [damageDetectionId] 
         * @param {string} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet: async (nexusOpsTenant: string, vehicleId: string, damageDetectionId?: string, itemId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/damages/get/by-damage-detection-item`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (damageDetectionId !== undefined) {
                localVarQueryParameter['damageDetectionId'] = damageDetectionId;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['itemId'] = itemId;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {VehicleDamageGetPaginatedDto} [vehicleDamageGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesGetPost: async (nexusOpsTenant: string, vehicleId: string, vehicleDamageGetPaginatedDto?: VehicleDamageGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesGetPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/damages/get`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleDamageGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {VehicleDamageSearchPaginatedDto} [vehicleDamageSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesSearchPost: async (nexusOpsTenant: string, vehicleId: string, vehicleDamageSearchPaginatedDto?: VehicleDamageSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesSearchPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/damages/search`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleDamageSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} vehicleDamageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete: async (nexusOpsTenant: string, vehicleId: string, vehicleDamageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete', 'vehicleId', vehicleId)
            // verify required parameter 'vehicleDamageId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete', 'vehicleDamageId', vehicleDamageId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/damages/{vehicleDamageId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)))
                .replace(`{${"vehicleDamageId"}}`, encodeURIComponent(String(vehicleDamageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} vehicleDamageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet: async (nexusOpsTenant: string, vehicleId: string, vehicleDamageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet', 'vehicleId', vehicleId)
            // verify required parameter 'vehicleDamageId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet', 'vehicleDamageId', vehicleDamageId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/damages/{vehicleDamageId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)))
                .replace(`{${"vehicleDamageId"}}`, encodeURIComponent(String(vehicleDamageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} vehicleDamageId 
         * @param {VehicleDamageMigrateToNewVisualModelDto} [vehicleDamageMigrateToNewVisualModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost: async (nexusOpsTenant: string, vehicleId: string, vehicleDamageId: string, vehicleDamageMigrateToNewVisualModelDto?: VehicleDamageMigrateToNewVisualModelDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost', 'vehicleId', vehicleId)
            // verify required parameter 'vehicleDamageId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost', 'vehicleDamageId', vehicleDamageId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/damages/{vehicleDamageId}/migrate-to-new-visual-model`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)))
                .replace(`{${"vehicleDamageId"}}`, encodeURIComponent(String(vehicleDamageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleDamageMigrateToNewVisualModelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleDamagesApi - functional programming interface
 * @export
 */
export const VehicleDamagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleDamagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleDamageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehicleDamagesVehicleDamageIdGet(nexusOpsTenant: string, vehicleDamageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDamageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehicleDamagesVehicleDamageIdGet(nexusOpsTenant, vehicleDamageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleDamagesApi.apiV1VehicleDamagesVehicleDamageIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} [contractId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdDamagesByContractGet(nexusOpsTenant: string, vehicleId: string, contractId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleDamageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdDamagesByContractGet(nexusOpsTenant, vehicleId, contractId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleDamagesApi.apiV1VehiclesVehicleIdDamagesByContractGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {Array<string>} [contractIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdDamagesByContractsGet(nexusOpsTenant: string, vehicleId: string, contractIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleDamageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdDamagesByContractsGet(nexusOpsTenant, vehicleId, contractIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleDamagesApi.apiV1VehiclesVehicleIdDamagesByContractsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {Array<string>} [vehicleDamageIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdDamagesByIdsGet(nexusOpsTenant: string, vehicleId: string, vehicleDamageIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleDamageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdDamagesByIdsGet(nexusOpsTenant, vehicleId, vehicleDamageIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleDamagesApi.apiV1VehiclesVehicleIdDamagesByIdsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {VehicleDamageGetCountersDto} [vehicleDamageGetCountersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdDamagesCountersGetPost(nexusOpsTenant: string, vehicleId: string, vehicleDamageGetCountersDto?: VehicleDamageGetCountersDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDamageCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdDamagesCountersGetPost(nexusOpsTenant, vehicleId, vehicleDamageGetCountersDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleDamagesApi.apiV1VehiclesVehicleIdDamagesCountersGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} [damageDetectionId] 
         * @param {string} [itemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet(nexusOpsTenant: string, vehicleId: string, damageDetectionId?: string, itemId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDamageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet(nexusOpsTenant, vehicleId, damageDetectionId, itemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleDamagesApi.apiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {VehicleDamageGetPaginatedDto} [vehicleDamageGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdDamagesGetPost(nexusOpsTenant: string, vehicleId: string, vehicleDamageGetPaginatedDto?: VehicleDamageGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleDamageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdDamagesGetPost(nexusOpsTenant, vehicleId, vehicleDamageGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleDamagesApi.apiV1VehiclesVehicleIdDamagesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {VehicleDamageSearchPaginatedDto} [vehicleDamageSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdDamagesSearchPost(nexusOpsTenant: string, vehicleId: string, vehicleDamageSearchPaginatedDto?: VehicleDamageSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleDamageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdDamagesSearchPost(nexusOpsTenant, vehicleId, vehicleDamageSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleDamagesApi.apiV1VehiclesVehicleIdDamagesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} vehicleDamageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete(nexusOpsTenant: string, vehicleId: string, vehicleDamageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete(nexusOpsTenant, vehicleId, vehicleDamageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleDamagesApi.apiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} vehicleDamageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet(nexusOpsTenant: string, vehicleId: string, vehicleDamageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDamageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet(nexusOpsTenant, vehicleId, vehicleDamageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleDamagesApi.apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} vehicleDamageId 
         * @param {VehicleDamageMigrateToNewVisualModelDto} [vehicleDamageMigrateToNewVisualModelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost(nexusOpsTenant: string, vehicleId: string, vehicleDamageId: string, vehicleDamageMigrateToNewVisualModelDto?: VehicleDamageMigrateToNewVisualModelDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDamageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost(nexusOpsTenant, vehicleId, vehicleDamageId, vehicleDamageMigrateToNewVisualModelDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleDamagesApi.apiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VehicleDamagesApi - factory interface
 * @export
 */
export const VehicleDamagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleDamagesApiFp(configuration)
    return {
        /**
         * 
         * @param {VehicleDamagesApiApiV1VehicleDamagesVehicleDamageIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehicleDamagesVehicleDamageIdGet(requestParameters: VehicleDamagesApiApiV1VehicleDamagesVehicleDamageIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDamageDto> {
            return localVarFp.apiV1VehicleDamagesVehicleDamageIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleDamageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesByContractGet(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<VehicleDamageDto>> {
            return localVarFp.apiV1VehiclesVehicleIdDamagesByContractGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesByContractsGet(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<VehicleDamageDto>> {
            return localVarFp.apiV1VehiclesVehicleIdDamagesByContractsGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.contractIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByIdsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesByIdsGet(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByIdsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<VehicleDamageDto>> {
            return localVarFp.apiV1VehiclesVehicleIdDamagesByIdsGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesCountersGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesCountersGetPost(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesCountersGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDamageCountersDto> {
            return localVarFp.apiV1VehiclesVehicleIdDamagesCountersGetPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageGetCountersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDamageDto> {
            return localVarFp.apiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.damageDetectionId, requestParameters.itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesGetPost(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleDamageDto> {
            return localVarFp.apiV1VehiclesVehicleIdDamagesGetPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesSearchPost(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleDamageDto> {
            return localVarFp.apiV1VehiclesVehicleIdDamagesSearchPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDamageDto> {
            return localVarFp.apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDamageDto> {
            return localVarFp.apiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageId, requestParameters.vehicleDamageMigrateToNewVisualModelDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1VehicleDamagesVehicleDamageIdGet operation in VehicleDamagesApi.
 * @export
 * @interface VehicleDamagesApiApiV1VehicleDamagesVehicleDamageIdGetRequest
 */
export interface VehicleDamagesApiApiV1VehicleDamagesVehicleDamageIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehicleDamagesVehicleDamageIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehicleDamagesVehicleDamageIdGet
     */
    readonly vehicleDamageId: string
}

/**
 * Request parameters for apiV1VehiclesVehicleIdDamagesByContractGet operation in VehicleDamagesApi.
 * @export
 * @interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractGetRequest
 */
export interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractGet
     */
    readonly contractId?: string
}

/**
 * Request parameters for apiV1VehiclesVehicleIdDamagesByContractsGet operation in VehicleDamagesApi.
 * @export
 * @interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractsGetRequest
 */
export interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractsGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractsGet
     */
    readonly contractIds?: Array<string>
}

/**
 * Request parameters for apiV1VehiclesVehicleIdDamagesByIdsGet operation in VehicleDamagesApi.
 * @export
 * @interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByIdsGetRequest
 */
export interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByIdsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByIdsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByIdsGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByIdsGet
     */
    readonly vehicleDamageIds?: Array<string>
}

/**
 * Request parameters for apiV1VehiclesVehicleIdDamagesCountersGetPost operation in VehicleDamagesApi.
 * @export
 * @interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesCountersGetPostRequest
 */
export interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesCountersGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesCountersGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesCountersGetPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {VehicleDamageGetCountersDto}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesCountersGetPost
     */
    readonly vehicleDamageGetCountersDto?: VehicleDamageGetCountersDto
}

/**
 * Request parameters for apiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet operation in VehicleDamagesApi.
 * @export
 * @interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGetRequest
 */
export interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet
     */
    readonly damageDetectionId?: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet
     */
    readonly itemId?: string
}

/**
 * Request parameters for apiV1VehiclesVehicleIdDamagesGetPost operation in VehicleDamagesApi.
 * @export
 * @interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetPostRequest
 */
export interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {VehicleDamageGetPaginatedDto}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetPost
     */
    readonly vehicleDamageGetPaginatedDto?: VehicleDamageGetPaginatedDto
}

/**
 * Request parameters for apiV1VehiclesVehicleIdDamagesSearchPost operation in VehicleDamagesApi.
 * @export
 * @interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesSearchPostRequest
 */
export interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesSearchPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {VehicleDamageSearchPaginatedDto}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesSearchPost
     */
    readonly vehicleDamageSearchPaginatedDto?: VehicleDamageSearchPaginatedDto
}

/**
 * Request parameters for apiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete operation in VehicleDamagesApi.
 * @export
 * @interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdDeleteRequest
 */
export interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete
     */
    readonly vehicleId: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete
     */
    readonly vehicleDamageId: string
}

/**
 * Request parameters for apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet operation in VehicleDamagesApi.
 * @export
 * @interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdGetRequest
 */
export interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdGet
     */
    readonly vehicleDamageId: string
}

/**
 * Request parameters for apiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost operation in VehicleDamagesApi.
 * @export
 * @interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPostRequest
 */
export interface VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {string}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost
     */
    readonly vehicleDamageId: string

    /**
     * 
     * @type {VehicleDamageMigrateToNewVisualModelDto}
     * @memberof VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost
     */
    readonly vehicleDamageMigrateToNewVisualModelDto?: VehicleDamageMigrateToNewVisualModelDto
}

/**
 * VehicleDamagesApi - object-oriented interface
 * @export
 * @class VehicleDamagesApi
 * @extends {BaseAPI}
 */
export class VehicleDamagesApi extends BaseAPI {
    /**
     * 
     * @param {VehicleDamagesApiApiV1VehicleDamagesVehicleDamageIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDamagesApi
     */
    public apiV1VehicleDamagesVehicleDamageIdGet(requestParameters: VehicleDamagesApiApiV1VehicleDamagesVehicleDamageIdGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleDamagesApiFp(this.configuration).apiV1VehicleDamagesVehicleDamageIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleDamageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDamagesApi
     */
    public apiV1VehiclesVehicleIdDamagesByContractGet(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleDamagesApiFp(this.configuration).apiV1VehiclesVehicleIdDamagesByContractGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDamagesApi
     */
    public apiV1VehiclesVehicleIdDamagesByContractsGet(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByContractsGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleDamagesApiFp(this.configuration).apiV1VehiclesVehicleIdDamagesByContractsGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.contractIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByIdsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDamagesApi
     */
    public apiV1VehiclesVehicleIdDamagesByIdsGet(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesByIdsGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleDamagesApiFp(this.configuration).apiV1VehiclesVehicleIdDamagesByIdsGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesCountersGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDamagesApi
     */
    public apiV1VehiclesVehicleIdDamagesCountersGetPost(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesCountersGetPostRequest, options?: RawAxiosRequestConfig) {
        return VehicleDamagesApiFp(this.configuration).apiV1VehiclesVehicleIdDamagesCountersGetPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageGetCountersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDamagesApi
     */
    public apiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleDamagesApiFp(this.configuration).apiV1VehiclesVehicleIdDamagesGetByDamageDetectionItemGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.damageDetectionId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDamagesApi
     */
    public apiV1VehiclesVehicleIdDamagesGetPost(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesGetPostRequest, options?: RawAxiosRequestConfig) {
        return VehicleDamagesApiFp(this.configuration).apiV1VehiclesVehicleIdDamagesGetPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDamagesApi
     */
    public apiV1VehiclesVehicleIdDamagesSearchPost(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesSearchPostRequest, options?: RawAxiosRequestConfig) {
        return VehicleDamagesApiFp(this.configuration).apiV1VehiclesVehicleIdDamagesSearchPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDamagesApi
     */
    public apiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return VehicleDamagesApiFp(this.configuration).apiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDamagesApi
     */
    public apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleDamagesApiFp(this.configuration).apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDamagesApi
     */
    public apiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost(requestParameters: VehicleDamagesApiApiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPostRequest, options?: RawAxiosRequestConfig) {
        return VehicleDamagesApiFp(this.configuration).apiV1VehiclesVehicleIdDamagesVehicleDamageIdMigrateToNewVisualModelPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleDamageId, requestParameters.vehicleDamageMigrateToNewVisualModelDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleFuelTypesApi - axios parameter creator
 * @export
 */
export const VehicleFuelTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehicleFuelTypeGetPaginatedDto} [vehicleFuelTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesFuelTypesGetPost: async (vehicleFuelTypeGetPaginatedDto?: VehicleFuelTypeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/fuel-types/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleFuelTypeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleFuelTypeSearchPaginatedDto} [vehicleFuelTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesFuelTypesSearchPost: async (vehicleFuelTypeSearchPaginatedDto?: VehicleFuelTypeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/fuel-types/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleFuelTypeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleFuelTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGet: async (vehicleFuelTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleFuelTypeId' is not null or undefined
            assertParamExists('apiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGet', 'vehicleFuelTypeId', vehicleFuelTypeId)
            const localVarPath = `/api/v1/reference-data/vehicles/fuel-types/{vehicleFuelTypeId}`
                .replace(`{${"vehicleFuelTypeId"}}`, encodeURIComponent(String(vehicleFuelTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleFuelTypesApi - functional programming interface
 * @export
 */
export const VehicleFuelTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleFuelTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehicleFuelTypeGetPaginatedDto} [vehicleFuelTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesFuelTypesGetPost(vehicleFuelTypeGetPaginatedDto?: VehicleFuelTypeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleFuelTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesFuelTypesGetPost(vehicleFuelTypeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleFuelTypesApi.apiV1ReferenceDataVehiclesFuelTypesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleFuelTypeSearchPaginatedDto} [vehicleFuelTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesFuelTypesSearchPost(vehicleFuelTypeSearchPaginatedDto?: VehicleFuelTypeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleFuelTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesFuelTypesSearchPost(vehicleFuelTypeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleFuelTypesApi.apiV1ReferenceDataVehiclesFuelTypesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} vehicleFuelTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGet(vehicleFuelTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleFuelTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGet(vehicleFuelTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleFuelTypesApi.apiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VehicleFuelTypesApi - factory interface
 * @export
 */
export const VehicleFuelTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleFuelTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesFuelTypesGetPost(requestParameters: VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleFuelTypeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesFuelTypesGetPost(requestParameters.vehicleFuelTypeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesFuelTypesSearchPost(requestParameters: VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleFuelTypeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesFuelTypesSearchPost(requestParameters.vehicleFuelTypeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGet(requestParameters: VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleFuelTypeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGet(requestParameters.vehicleFuelTypeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ReferenceDataVehiclesFuelTypesGetPost operation in VehicleFuelTypesApi.
 * @export
 * @interface VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesGetPostRequest
 */
export interface VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesGetPostRequest {
    /**
     * 
     * @type {VehicleFuelTypeGetPaginatedDto}
     * @memberof VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesGetPost
     */
    readonly vehicleFuelTypeGetPaginatedDto?: VehicleFuelTypeGetPaginatedDto
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesFuelTypesSearchPost operation in VehicleFuelTypesApi.
 * @export
 * @interface VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesSearchPostRequest
 */
export interface VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesSearchPostRequest {
    /**
     * 
     * @type {VehicleFuelTypeSearchPaginatedDto}
     * @memberof VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesSearchPost
     */
    readonly vehicleFuelTypeSearchPaginatedDto?: VehicleFuelTypeSearchPaginatedDto
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGet operation in VehicleFuelTypesApi.
 * @export
 * @interface VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGetRequest
 */
export interface VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGet
     */
    readonly vehicleFuelTypeId: string
}

/**
 * VehicleFuelTypesApi - object-oriented interface
 * @export
 * @class VehicleFuelTypesApi
 * @extends {BaseAPI}
 */
export class VehicleFuelTypesApi extends BaseAPI {
    /**
     * 
     * @param {VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleFuelTypesApi
     */
    public apiV1ReferenceDataVehiclesFuelTypesGetPost(requestParameters: VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleFuelTypesApiFp(this.configuration).apiV1ReferenceDataVehiclesFuelTypesGetPost(requestParameters.vehicleFuelTypeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleFuelTypesApi
     */
    public apiV1ReferenceDataVehiclesFuelTypesSearchPost(requestParameters: VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleFuelTypesApiFp(this.configuration).apiV1ReferenceDataVehiclesFuelTypesSearchPost(requestParameters.vehicleFuelTypeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleFuelTypesApi
     */
    public apiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGet(requestParameters: VehicleFuelTypesApiApiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleFuelTypesApiFp(this.configuration).apiV1ReferenceDataVehiclesFuelTypesVehicleFuelTypeIdGet(requestParameters.vehicleFuelTypeId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleGenerationsApi - axios parameter creator
 * @export
 */
export const VehicleGenerationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} generationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesGenerationsGenerationIdGet: async (generationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generationId' is not null or undefined
            assertParamExists('apiV1ReferenceDataVehiclesGenerationsGenerationIdGet', 'generationId', generationId)
            const localVarPath = `/api/v1/reference-data/vehicles/generations/{generationId}`
                .replace(`{${"generationId"}}`, encodeURIComponent(String(generationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleGenerationGetPaginatedDto} [vehicleGenerationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesGenerationsGetPost: async (vehicleGenerationGetPaginatedDto?: VehicleGenerationGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/generations/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleGenerationGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleGenerationSearchPaginatedDto} [vehicleGenerationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesGenerationsSearchPost: async (vehicleGenerationSearchPaginatedDto?: VehicleGenerationSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/generations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleGenerationSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleGenerationsApi - functional programming interface
 * @export
 */
export const VehicleGenerationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleGenerationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} generationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesGenerationsGenerationIdGet(generationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleGenerationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesGenerationsGenerationIdGet(generationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleGenerationsApi.apiV1ReferenceDataVehiclesGenerationsGenerationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleGenerationGetPaginatedDto} [vehicleGenerationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesGenerationsGetPost(vehicleGenerationGetPaginatedDto?: VehicleGenerationGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleGenerationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesGenerationsGetPost(vehicleGenerationGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleGenerationsApi.apiV1ReferenceDataVehiclesGenerationsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleGenerationSearchPaginatedDto} [vehicleGenerationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesGenerationsSearchPost(vehicleGenerationSearchPaginatedDto?: VehicleGenerationSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleGenerationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesGenerationsSearchPost(vehicleGenerationSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleGenerationsApi.apiV1ReferenceDataVehiclesGenerationsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VehicleGenerationsApi - factory interface
 * @export
 */
export const VehicleGenerationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleGenerationsApiFp(configuration)
    return {
        /**
         * 
         * @param {VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGenerationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesGenerationsGenerationIdGet(requestParameters: VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGenerationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleGenerationDto> {
            return localVarFp.apiV1ReferenceDataVehiclesGenerationsGenerationIdGet(requestParameters.generationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesGenerationsGetPost(requestParameters: VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleGenerationDto> {
            return localVarFp.apiV1ReferenceDataVehiclesGenerationsGetPost(requestParameters.vehicleGenerationGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesGenerationsSearchPost(requestParameters: VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleGenerationDto> {
            return localVarFp.apiV1ReferenceDataVehiclesGenerationsSearchPost(requestParameters.vehicleGenerationSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ReferenceDataVehiclesGenerationsGenerationIdGet operation in VehicleGenerationsApi.
 * @export
 * @interface VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGenerationIdGetRequest
 */
export interface VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGenerationIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGenerationIdGet
     */
    readonly generationId: string
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesGenerationsGetPost operation in VehicleGenerationsApi.
 * @export
 * @interface VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGetPostRequest
 */
export interface VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGetPostRequest {
    /**
     * 
     * @type {VehicleGenerationGetPaginatedDto}
     * @memberof VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGetPost
     */
    readonly vehicleGenerationGetPaginatedDto?: VehicleGenerationGetPaginatedDto
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesGenerationsSearchPost operation in VehicleGenerationsApi.
 * @export
 * @interface VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsSearchPostRequest
 */
export interface VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsSearchPostRequest {
    /**
     * 
     * @type {VehicleGenerationSearchPaginatedDto}
     * @memberof VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsSearchPost
     */
    readonly vehicleGenerationSearchPaginatedDto?: VehicleGenerationSearchPaginatedDto
}

/**
 * VehicleGenerationsApi - object-oriented interface
 * @export
 * @class VehicleGenerationsApi
 * @extends {BaseAPI}
 */
export class VehicleGenerationsApi extends BaseAPI {
    /**
     * 
     * @param {VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGenerationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGenerationsApi
     */
    public apiV1ReferenceDataVehiclesGenerationsGenerationIdGet(requestParameters: VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGenerationIdGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleGenerationsApiFp(this.configuration).apiV1ReferenceDataVehiclesGenerationsGenerationIdGet(requestParameters.generationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGenerationsApi
     */
    public apiV1ReferenceDataVehiclesGenerationsGetPost(requestParameters: VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleGenerationsApiFp(this.configuration).apiV1ReferenceDataVehiclesGenerationsGetPost(requestParameters.vehicleGenerationGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGenerationsApi
     */
    public apiV1ReferenceDataVehiclesGenerationsSearchPost(requestParameters: VehicleGenerationsApiApiV1ReferenceDataVehiclesGenerationsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleGenerationsApiFp(this.configuration).apiV1ReferenceDataVehiclesGenerationsSearchPost(requestParameters.vehicleGenerationSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleHistoryApi - axios parameter creator
 * @export
 */
export const VehicleHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {VehicleHistoryGetPaginatedDto} [vehicleHistoryGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdHistoryGetPost: async (nexusOpsTenant: string, vehicleId: string, vehicleHistoryGetPaginatedDto?: VehicleHistoryGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdHistoryGetPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdHistoryGetPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/history/get`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleHistoryGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdHistoryIdGet: async (nexusOpsTenant: string, vehicleId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdHistoryIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdHistoryIdGet', 'vehicleId', vehicleId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdHistoryIdGet', 'id', id)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/history/{id}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleHistoryApi - functional programming interface
 * @export
 */
export const VehicleHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {VehicleHistoryGetPaginatedDto} [vehicleHistoryGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdHistoryGetPost(nexusOpsTenant: string, vehicleId: string, vehicleHistoryGetPaginatedDto?: VehicleHistoryGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleHistoryItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdHistoryGetPost(nexusOpsTenant, vehicleId, vehicleHistoryGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleHistoryApi.apiV1VehiclesVehicleIdHistoryGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdHistoryIdGet(nexusOpsTenant: string, vehicleId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleHistoryItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdHistoryIdGet(nexusOpsTenant, vehicleId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleHistoryApi.apiV1VehiclesVehicleIdHistoryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VehicleHistoryApi - factory interface
 * @export
 */
export const VehicleHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {VehicleHistoryApiApiV1VehiclesVehicleIdHistoryGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdHistoryGetPost(requestParameters: VehicleHistoryApiApiV1VehiclesVehicleIdHistoryGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleHistoryItemDto> {
            return localVarFp.apiV1VehiclesVehicleIdHistoryGetPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleHistoryGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleHistoryApiApiV1VehiclesVehicleIdHistoryIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdHistoryIdGet(requestParameters: VehicleHistoryApiApiV1VehiclesVehicleIdHistoryIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleHistoryItemDto> {
            return localVarFp.apiV1VehiclesVehicleIdHistoryIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1VehiclesVehicleIdHistoryGetPost operation in VehicleHistoryApi.
 * @export
 * @interface VehicleHistoryApiApiV1VehiclesVehicleIdHistoryGetPostRequest
 */
export interface VehicleHistoryApiApiV1VehiclesVehicleIdHistoryGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehicleHistoryApiApiV1VehiclesVehicleIdHistoryGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryApiApiV1VehiclesVehicleIdHistoryGetPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {VehicleHistoryGetPaginatedDto}
     * @memberof VehicleHistoryApiApiV1VehiclesVehicleIdHistoryGetPost
     */
    readonly vehicleHistoryGetPaginatedDto?: VehicleHistoryGetPaginatedDto
}

/**
 * Request parameters for apiV1VehiclesVehicleIdHistoryIdGet operation in VehicleHistoryApi.
 * @export
 * @interface VehicleHistoryApiApiV1VehiclesVehicleIdHistoryIdGetRequest
 */
export interface VehicleHistoryApiApiV1VehiclesVehicleIdHistoryIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehicleHistoryApiApiV1VehiclesVehicleIdHistoryIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryApiApiV1VehiclesVehicleIdHistoryIdGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryApiApiV1VehiclesVehicleIdHistoryIdGet
     */
    readonly id: string
}

/**
 * VehicleHistoryApi - object-oriented interface
 * @export
 * @class VehicleHistoryApi
 * @extends {BaseAPI}
 */
export class VehicleHistoryApi extends BaseAPI {
    /**
     * 
     * @param {VehicleHistoryApiApiV1VehiclesVehicleIdHistoryGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleHistoryApi
     */
    public apiV1VehiclesVehicleIdHistoryGetPost(requestParameters: VehicleHistoryApiApiV1VehiclesVehicleIdHistoryGetPostRequest, options?: RawAxiosRequestConfig) {
        return VehicleHistoryApiFp(this.configuration).apiV1VehiclesVehicleIdHistoryGetPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.vehicleHistoryGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleHistoryApiApiV1VehiclesVehicleIdHistoryIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleHistoryApi
     */
    public apiV1VehiclesVehicleIdHistoryIdGet(requestParameters: VehicleHistoryApiApiV1VehiclesVehicleIdHistoryIdGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleHistoryApiFp(this.configuration).apiV1VehiclesVehicleIdHistoryIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleMakesApi - axios parameter creator
 * @export
 */
export const VehicleMakesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehicleMakeGetPaginatedDto} [vehicleMakeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesMakesGetPost: async (vehicleMakeGetPaginatedDto?: VehicleMakeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/makes/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleMakeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} makeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesMakesMakeIdGet: async (makeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'makeId' is not null or undefined
            assertParamExists('apiV1ReferenceDataVehiclesMakesMakeIdGet', 'makeId', makeId)
            const localVarPath = `/api/v1/reference-data/vehicles/makes/{makeId}`
                .replace(`{${"makeId"}}`, encodeURIComponent(String(makeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleMakeSearchPaginatedDto} [vehicleMakeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesMakesSearchPost: async (vehicleMakeSearchPaginatedDto?: VehicleMakeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/makes/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleMakeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleMakesApi - functional programming interface
 * @export
 */
export const VehicleMakesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleMakesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehicleMakeGetPaginatedDto} [vehicleMakeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesMakesGetPost(vehicleMakeGetPaginatedDto?: VehicleMakeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleMakeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesMakesGetPost(vehicleMakeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleMakesApi.apiV1ReferenceDataVehiclesMakesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} makeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesMakesMakeIdGet(makeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleMakeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesMakesMakeIdGet(makeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleMakesApi.apiV1ReferenceDataVehiclesMakesMakeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleMakeSearchPaginatedDto} [vehicleMakeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesMakesSearchPost(vehicleMakeSearchPaginatedDto?: VehicleMakeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleMakeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesMakesSearchPost(vehicleMakeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleMakesApi.apiV1ReferenceDataVehiclesMakesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VehicleMakesApi - factory interface
 * @export
 */
export const VehicleMakesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleMakesApiFp(configuration)
    return {
        /**
         * 
         * @param {VehicleMakesApiApiV1ReferenceDataVehiclesMakesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesMakesGetPost(requestParameters: VehicleMakesApiApiV1ReferenceDataVehiclesMakesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleMakeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesMakesGetPost(requestParameters.vehicleMakeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleMakesApiApiV1ReferenceDataVehiclesMakesMakeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesMakesMakeIdGet(requestParameters: VehicleMakesApiApiV1ReferenceDataVehiclesMakesMakeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleMakeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesMakesMakeIdGet(requestParameters.makeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleMakesApiApiV1ReferenceDataVehiclesMakesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesMakesSearchPost(requestParameters: VehicleMakesApiApiV1ReferenceDataVehiclesMakesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleMakeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesMakesSearchPost(requestParameters.vehicleMakeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ReferenceDataVehiclesMakesGetPost operation in VehicleMakesApi.
 * @export
 * @interface VehicleMakesApiApiV1ReferenceDataVehiclesMakesGetPostRequest
 */
export interface VehicleMakesApiApiV1ReferenceDataVehiclesMakesGetPostRequest {
    /**
     * 
     * @type {VehicleMakeGetPaginatedDto}
     * @memberof VehicleMakesApiApiV1ReferenceDataVehiclesMakesGetPost
     */
    readonly vehicleMakeGetPaginatedDto?: VehicleMakeGetPaginatedDto
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesMakesMakeIdGet operation in VehicleMakesApi.
 * @export
 * @interface VehicleMakesApiApiV1ReferenceDataVehiclesMakesMakeIdGetRequest
 */
export interface VehicleMakesApiApiV1ReferenceDataVehiclesMakesMakeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VehicleMakesApiApiV1ReferenceDataVehiclesMakesMakeIdGet
     */
    readonly makeId: string
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesMakesSearchPost operation in VehicleMakesApi.
 * @export
 * @interface VehicleMakesApiApiV1ReferenceDataVehiclesMakesSearchPostRequest
 */
export interface VehicleMakesApiApiV1ReferenceDataVehiclesMakesSearchPostRequest {
    /**
     * 
     * @type {VehicleMakeSearchPaginatedDto}
     * @memberof VehicleMakesApiApiV1ReferenceDataVehiclesMakesSearchPost
     */
    readonly vehicleMakeSearchPaginatedDto?: VehicleMakeSearchPaginatedDto
}

/**
 * VehicleMakesApi - object-oriented interface
 * @export
 * @class VehicleMakesApi
 * @extends {BaseAPI}
 */
export class VehicleMakesApi extends BaseAPI {
    /**
     * 
     * @param {VehicleMakesApiApiV1ReferenceDataVehiclesMakesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleMakesApi
     */
    public apiV1ReferenceDataVehiclesMakesGetPost(requestParameters: VehicleMakesApiApiV1ReferenceDataVehiclesMakesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleMakesApiFp(this.configuration).apiV1ReferenceDataVehiclesMakesGetPost(requestParameters.vehicleMakeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleMakesApiApiV1ReferenceDataVehiclesMakesMakeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleMakesApi
     */
    public apiV1ReferenceDataVehiclesMakesMakeIdGet(requestParameters: VehicleMakesApiApiV1ReferenceDataVehiclesMakesMakeIdGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleMakesApiFp(this.configuration).apiV1ReferenceDataVehiclesMakesMakeIdGet(requestParameters.makeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleMakesApiApiV1ReferenceDataVehiclesMakesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleMakesApi
     */
    public apiV1ReferenceDataVehiclesMakesSearchPost(requestParameters: VehicleMakesApiApiV1ReferenceDataVehiclesMakesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleMakesApiFp(this.configuration).apiV1ReferenceDataVehiclesMakesSearchPost(requestParameters.vehicleMakeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleModelsApi - axios parameter creator
 * @export
 */
export const VehicleModelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehicleModelGetPaginatedDto} [vehicleModelGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesModelsGetPost: async (vehicleModelGetPaginatedDto?: VehicleModelGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/models/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleModelGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesModelsModelIdGet: async (modelId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('apiV1ReferenceDataVehiclesModelsModelIdGet', 'modelId', modelId)
            const localVarPath = `/api/v1/reference-data/vehicles/models/{modelId}`
                .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleModelSearchPaginatedDto} [vehicleModelSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesModelsSearchPost: async (vehicleModelSearchPaginatedDto?: VehicleModelSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/models/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleModelSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleModelsApi - functional programming interface
 * @export
 */
export const VehicleModelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleModelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehicleModelGetPaginatedDto} [vehicleModelGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesModelsGetPost(vehicleModelGetPaginatedDto?: VehicleModelGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesModelsGetPost(vehicleModelGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleModelsApi.apiV1ReferenceDataVehiclesModelsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesModelsModelIdGet(modelId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesModelsModelIdGet(modelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleModelsApi.apiV1ReferenceDataVehiclesModelsModelIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleModelSearchPaginatedDto} [vehicleModelSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesModelsSearchPost(vehicleModelSearchPaginatedDto?: VehicleModelSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesModelsSearchPost(vehicleModelSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleModelsApi.apiV1ReferenceDataVehiclesModelsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VehicleModelsApi - factory interface
 * @export
 */
export const VehicleModelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleModelsApiFp(configuration)
    return {
        /**
         * 
         * @param {VehicleModelsApiApiV1ReferenceDataVehiclesModelsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesModelsGetPost(requestParameters: VehicleModelsApiApiV1ReferenceDataVehiclesModelsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleModelDto> {
            return localVarFp.apiV1ReferenceDataVehiclesModelsGetPost(requestParameters.vehicleModelGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleModelsApiApiV1ReferenceDataVehiclesModelsModelIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesModelsModelIdGet(requestParameters: VehicleModelsApiApiV1ReferenceDataVehiclesModelsModelIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleModelDto> {
            return localVarFp.apiV1ReferenceDataVehiclesModelsModelIdGet(requestParameters.modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleModelsApiApiV1ReferenceDataVehiclesModelsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesModelsSearchPost(requestParameters: VehicleModelsApiApiV1ReferenceDataVehiclesModelsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleModelDto> {
            return localVarFp.apiV1ReferenceDataVehiclesModelsSearchPost(requestParameters.vehicleModelSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ReferenceDataVehiclesModelsGetPost operation in VehicleModelsApi.
 * @export
 * @interface VehicleModelsApiApiV1ReferenceDataVehiclesModelsGetPostRequest
 */
export interface VehicleModelsApiApiV1ReferenceDataVehiclesModelsGetPostRequest {
    /**
     * 
     * @type {VehicleModelGetPaginatedDto}
     * @memberof VehicleModelsApiApiV1ReferenceDataVehiclesModelsGetPost
     */
    readonly vehicleModelGetPaginatedDto?: VehicleModelGetPaginatedDto
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesModelsModelIdGet operation in VehicleModelsApi.
 * @export
 * @interface VehicleModelsApiApiV1ReferenceDataVehiclesModelsModelIdGetRequest
 */
export interface VehicleModelsApiApiV1ReferenceDataVehiclesModelsModelIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VehicleModelsApiApiV1ReferenceDataVehiclesModelsModelIdGet
     */
    readonly modelId: string
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesModelsSearchPost operation in VehicleModelsApi.
 * @export
 * @interface VehicleModelsApiApiV1ReferenceDataVehiclesModelsSearchPostRequest
 */
export interface VehicleModelsApiApiV1ReferenceDataVehiclesModelsSearchPostRequest {
    /**
     * 
     * @type {VehicleModelSearchPaginatedDto}
     * @memberof VehicleModelsApiApiV1ReferenceDataVehiclesModelsSearchPost
     */
    readonly vehicleModelSearchPaginatedDto?: VehicleModelSearchPaginatedDto
}

/**
 * VehicleModelsApi - object-oriented interface
 * @export
 * @class VehicleModelsApi
 * @extends {BaseAPI}
 */
export class VehicleModelsApi extends BaseAPI {
    /**
     * 
     * @param {VehicleModelsApiApiV1ReferenceDataVehiclesModelsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleModelsApi
     */
    public apiV1ReferenceDataVehiclesModelsGetPost(requestParameters: VehicleModelsApiApiV1ReferenceDataVehiclesModelsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleModelsApiFp(this.configuration).apiV1ReferenceDataVehiclesModelsGetPost(requestParameters.vehicleModelGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleModelsApiApiV1ReferenceDataVehiclesModelsModelIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleModelsApi
     */
    public apiV1ReferenceDataVehiclesModelsModelIdGet(requestParameters: VehicleModelsApiApiV1ReferenceDataVehiclesModelsModelIdGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleModelsApiFp(this.configuration).apiV1ReferenceDataVehiclesModelsModelIdGet(requestParameters.modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleModelsApiApiV1ReferenceDataVehiclesModelsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleModelsApi
     */
    public apiV1ReferenceDataVehiclesModelsSearchPost(requestParameters: VehicleModelsApiApiV1ReferenceDataVehiclesModelsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleModelsApiFp(this.configuration).apiV1ReferenceDataVehiclesModelsSearchPost(requestParameters.vehicleModelSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleModificationsApi - axios parameter creator
 * @export
 */
export const VehicleModificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehicleModificationGetPaginatedDto} [vehicleModificationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesModificationsGetPost: async (vehicleModificationGetPaginatedDto?: VehicleModificationGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/modifications/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleModificationGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesModificationsModificationIdGet: async (modificationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modificationId' is not null or undefined
            assertParamExists('apiV1ReferenceDataVehiclesModificationsModificationIdGet', 'modificationId', modificationId)
            const localVarPath = `/api/v1/reference-data/vehicles/modifications/{modificationId}`
                .replace(`{${"modificationId"}}`, encodeURIComponent(String(modificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleModificationSearchPaginatedDto} [vehicleModificationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesModificationsSearchPost: async (vehicleModificationSearchPaginatedDto?: VehicleModificationSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/modifications/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleModificationSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleModificationsApi - functional programming interface
 * @export
 */
export const VehicleModificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleModificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehicleModificationGetPaginatedDto} [vehicleModificationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesModificationsGetPost(vehicleModificationGetPaginatedDto?: VehicleModificationGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleModificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesModificationsGetPost(vehicleModificationGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleModificationsApi.apiV1ReferenceDataVehiclesModificationsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} modificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesModificationsModificationIdGet(modificationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleModificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesModificationsModificationIdGet(modificationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleModificationsApi.apiV1ReferenceDataVehiclesModificationsModificationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleModificationSearchPaginatedDto} [vehicleModificationSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesModificationsSearchPost(vehicleModificationSearchPaginatedDto?: VehicleModificationSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleModificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesModificationsSearchPost(vehicleModificationSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleModificationsApi.apiV1ReferenceDataVehiclesModificationsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VehicleModificationsApi - factory interface
 * @export
 */
export const VehicleModificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleModificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesModificationsGetPost(requestParameters: VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleModificationDto> {
            return localVarFp.apiV1ReferenceDataVehiclesModificationsGetPost(requestParameters.vehicleModificationGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsModificationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesModificationsModificationIdGet(requestParameters: VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsModificationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleModificationDto> {
            return localVarFp.apiV1ReferenceDataVehiclesModificationsModificationIdGet(requestParameters.modificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesModificationsSearchPost(requestParameters: VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleModificationDto> {
            return localVarFp.apiV1ReferenceDataVehiclesModificationsSearchPost(requestParameters.vehicleModificationSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ReferenceDataVehiclesModificationsGetPost operation in VehicleModificationsApi.
 * @export
 * @interface VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsGetPostRequest
 */
export interface VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsGetPostRequest {
    /**
     * 
     * @type {VehicleModificationGetPaginatedDto}
     * @memberof VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsGetPost
     */
    readonly vehicleModificationGetPaginatedDto?: VehicleModificationGetPaginatedDto
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesModificationsModificationIdGet operation in VehicleModificationsApi.
 * @export
 * @interface VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsModificationIdGetRequest
 */
export interface VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsModificationIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsModificationIdGet
     */
    readonly modificationId: string
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesModificationsSearchPost operation in VehicleModificationsApi.
 * @export
 * @interface VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsSearchPostRequest
 */
export interface VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsSearchPostRequest {
    /**
     * 
     * @type {VehicleModificationSearchPaginatedDto}
     * @memberof VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsSearchPost
     */
    readonly vehicleModificationSearchPaginatedDto?: VehicleModificationSearchPaginatedDto
}

/**
 * VehicleModificationsApi - object-oriented interface
 * @export
 * @class VehicleModificationsApi
 * @extends {BaseAPI}
 */
export class VehicleModificationsApi extends BaseAPI {
    /**
     * 
     * @param {VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleModificationsApi
     */
    public apiV1ReferenceDataVehiclesModificationsGetPost(requestParameters: VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleModificationsApiFp(this.configuration).apiV1ReferenceDataVehiclesModificationsGetPost(requestParameters.vehicleModificationGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsModificationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleModificationsApi
     */
    public apiV1ReferenceDataVehiclesModificationsModificationIdGet(requestParameters: VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsModificationIdGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleModificationsApiFp(this.configuration).apiV1ReferenceDataVehiclesModificationsModificationIdGet(requestParameters.modificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleModificationsApi
     */
    public apiV1ReferenceDataVehiclesModificationsSearchPost(requestParameters: VehicleModificationsApiApiV1ReferenceDataVehiclesModificationsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleModificationsApiFp(this.configuration).apiV1ReferenceDataVehiclesModificationsSearchPost(requestParameters.vehicleModificationSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehiclePartTypesApi - axios parameter creator
 * @export
 */
export const VehiclePartTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehiclePartTypeGetPaginatedDto} [vehiclePartTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesPartTypesGetPost: async (vehiclePartTypeGetPaginatedDto?: VehiclePartTypeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/part-types/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehiclePartTypeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesPartTypesPartTypeIdGet: async (partTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partTypeId' is not null or undefined
            assertParamExists('apiV1ReferenceDataVehiclesPartTypesPartTypeIdGet', 'partTypeId', partTypeId)
            const localVarPath = `/api/v1/reference-data/vehicles/part-types/{partTypeId}`
                .replace(`{${"partTypeId"}}`, encodeURIComponent(String(partTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehiclePartTypeSearchPaginatedDto} [vehiclePartTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesPartTypesSearchPost: async (vehiclePartTypeSearchPaginatedDto?: VehiclePartTypeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/part-types/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehiclePartTypeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehiclePartTypesApi - functional programming interface
 * @export
 */
export const VehiclePartTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehiclePartTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehiclePartTypeGetPaginatedDto} [vehiclePartTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesPartTypesGetPost(vehiclePartTypeGetPaginatedDto?: VehiclePartTypeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehiclePartTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesPartTypesGetPost(vehiclePartTypeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclePartTypesApi.apiV1ReferenceDataVehiclesPartTypesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesPartTypesPartTypeIdGet(partTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehiclePartTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesPartTypesPartTypeIdGet(partTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclePartTypesApi.apiV1ReferenceDataVehiclesPartTypesPartTypeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehiclePartTypeSearchPaginatedDto} [vehiclePartTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesPartTypesSearchPost(vehiclePartTypeSearchPaginatedDto?: VehiclePartTypeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehiclePartTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesPartTypesSearchPost(vehiclePartTypeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclePartTypesApi.apiV1ReferenceDataVehiclesPartTypesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VehiclePartTypesApi - factory interface
 * @export
 */
export const VehiclePartTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehiclePartTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesPartTypesGetPost(requestParameters: VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehiclePartTypeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesPartTypesGetPost(requestParameters.vehiclePartTypeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesPartTypeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesPartTypesPartTypeIdGet(requestParameters: VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesPartTypeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehiclePartTypeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesPartTypesPartTypeIdGet(requestParameters.partTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesPartTypesSearchPost(requestParameters: VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehiclePartTypeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesPartTypesSearchPost(requestParameters.vehiclePartTypeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ReferenceDataVehiclesPartTypesGetPost operation in VehiclePartTypesApi.
 * @export
 * @interface VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesGetPostRequest
 */
export interface VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesGetPostRequest {
    /**
     * 
     * @type {VehiclePartTypeGetPaginatedDto}
     * @memberof VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesGetPost
     */
    readonly vehiclePartTypeGetPaginatedDto?: VehiclePartTypeGetPaginatedDto
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesPartTypesPartTypeIdGet operation in VehiclePartTypesApi.
 * @export
 * @interface VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesPartTypeIdGetRequest
 */
export interface VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesPartTypeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesPartTypeIdGet
     */
    readonly partTypeId: string
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesPartTypesSearchPost operation in VehiclePartTypesApi.
 * @export
 * @interface VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesSearchPostRequest
 */
export interface VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesSearchPostRequest {
    /**
     * 
     * @type {VehiclePartTypeSearchPaginatedDto}
     * @memberof VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesSearchPost
     */
    readonly vehiclePartTypeSearchPaginatedDto?: VehiclePartTypeSearchPaginatedDto
}

/**
 * VehiclePartTypesApi - object-oriented interface
 * @export
 * @class VehiclePartTypesApi
 * @extends {BaseAPI}
 */
export class VehiclePartTypesApi extends BaseAPI {
    /**
     * 
     * @param {VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclePartTypesApi
     */
    public apiV1ReferenceDataVehiclesPartTypesGetPost(requestParameters: VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehiclePartTypesApiFp(this.configuration).apiV1ReferenceDataVehiclesPartTypesGetPost(requestParameters.vehiclePartTypeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesPartTypeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclePartTypesApi
     */
    public apiV1ReferenceDataVehiclesPartTypesPartTypeIdGet(requestParameters: VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesPartTypeIdGetRequest, options?: RawAxiosRequestConfig) {
        return VehiclePartTypesApiFp(this.configuration).apiV1ReferenceDataVehiclesPartTypesPartTypeIdGet(requestParameters.partTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclePartTypesApi
     */
    public apiV1ReferenceDataVehiclesPartTypesSearchPost(requestParameters: VehiclePartTypesApiApiV1ReferenceDataVehiclesPartTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehiclePartTypesApiFp(this.configuration).apiV1ReferenceDataVehiclesPartTypesSearchPost(requestParameters.vehiclePartTypeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleTypesApi - axios parameter creator
 * @export
 */
export const VehicleTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehicleTypeGetPaginatedDto} [vehicleTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesTypesGetPost: async (vehicleTypeGetPaginatedDto?: VehicleTypeGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/types/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleTypeGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleTypeSearchPaginatedDto} [vehicleTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesTypesSearchPost: async (vehicleTypeSearchPaginatedDto?: VehicleTypeSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/types/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleTypeSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} typeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesTypesTypeIdGet: async (typeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeId' is not null or undefined
            assertParamExists('apiV1ReferenceDataVehiclesTypesTypeIdGet', 'typeId', typeId)
            const localVarPath = `/api/v1/reference-data/vehicles/types/{typeId}`
                .replace(`{${"typeId"}}`, encodeURIComponent(String(typeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleTypesApi - functional programming interface
 * @export
 */
export const VehicleTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehicleTypeGetPaginatedDto} [vehicleTypeGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesTypesGetPost(vehicleTypeGetPaginatedDto?: VehicleTypeGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesTypesGetPost(vehicleTypeGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleTypesApi.apiV1ReferenceDataVehiclesTypesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleTypeSearchPaginatedDto} [vehicleTypeSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesTypesSearchPost(vehicleTypeSearchPaginatedDto?: VehicleTypeSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesTypesSearchPost(vehicleTypeSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleTypesApi.apiV1ReferenceDataVehiclesTypesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} typeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesTypesTypeIdGet(typeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesTypesTypeIdGet(typeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleTypesApi.apiV1ReferenceDataVehiclesTypesTypeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VehicleTypesApi - factory interface
 * @export
 */
export const VehicleTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {VehicleTypesApiApiV1ReferenceDataVehiclesTypesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesTypesGetPost(requestParameters: VehicleTypesApiApiV1ReferenceDataVehiclesTypesGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleTypeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesTypesGetPost(requestParameters.vehicleTypeGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleTypesApiApiV1ReferenceDataVehiclesTypesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesTypesSearchPost(requestParameters: VehicleTypesApiApiV1ReferenceDataVehiclesTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleTypeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesTypesSearchPost(requestParameters.vehicleTypeSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleTypesApiApiV1ReferenceDataVehiclesTypesTypeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesTypesTypeIdGet(requestParameters: VehicleTypesApiApiV1ReferenceDataVehiclesTypesTypeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleTypeDto> {
            return localVarFp.apiV1ReferenceDataVehiclesTypesTypeIdGet(requestParameters.typeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ReferenceDataVehiclesTypesGetPost operation in VehicleTypesApi.
 * @export
 * @interface VehicleTypesApiApiV1ReferenceDataVehiclesTypesGetPostRequest
 */
export interface VehicleTypesApiApiV1ReferenceDataVehiclesTypesGetPostRequest {
    /**
     * 
     * @type {VehicleTypeGetPaginatedDto}
     * @memberof VehicleTypesApiApiV1ReferenceDataVehiclesTypesGetPost
     */
    readonly vehicleTypeGetPaginatedDto?: VehicleTypeGetPaginatedDto
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesTypesSearchPost operation in VehicleTypesApi.
 * @export
 * @interface VehicleTypesApiApiV1ReferenceDataVehiclesTypesSearchPostRequest
 */
export interface VehicleTypesApiApiV1ReferenceDataVehiclesTypesSearchPostRequest {
    /**
     * 
     * @type {VehicleTypeSearchPaginatedDto}
     * @memberof VehicleTypesApiApiV1ReferenceDataVehiclesTypesSearchPost
     */
    readonly vehicleTypeSearchPaginatedDto?: VehicleTypeSearchPaginatedDto
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesTypesTypeIdGet operation in VehicleTypesApi.
 * @export
 * @interface VehicleTypesApiApiV1ReferenceDataVehiclesTypesTypeIdGetRequest
 */
export interface VehicleTypesApiApiV1ReferenceDataVehiclesTypesTypeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VehicleTypesApiApiV1ReferenceDataVehiclesTypesTypeIdGet
     */
    readonly typeId: string
}

/**
 * VehicleTypesApi - object-oriented interface
 * @export
 * @class VehicleTypesApi
 * @extends {BaseAPI}
 */
export class VehicleTypesApi extends BaseAPI {
    /**
     * 
     * @param {VehicleTypesApiApiV1ReferenceDataVehiclesTypesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTypesApi
     */
    public apiV1ReferenceDataVehiclesTypesGetPost(requestParameters: VehicleTypesApiApiV1ReferenceDataVehiclesTypesGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleTypesApiFp(this.configuration).apiV1ReferenceDataVehiclesTypesGetPost(requestParameters.vehicleTypeGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleTypesApiApiV1ReferenceDataVehiclesTypesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTypesApi
     */
    public apiV1ReferenceDataVehiclesTypesSearchPost(requestParameters: VehicleTypesApiApiV1ReferenceDataVehiclesTypesSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleTypesApiFp(this.configuration).apiV1ReferenceDataVehiclesTypesSearchPost(requestParameters.vehicleTypeSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleTypesApiApiV1ReferenceDataVehiclesTypesTypeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTypesApi
     */
    public apiV1ReferenceDataVehiclesTypesTypeIdGet(requestParameters: VehicleTypesApiApiV1ReferenceDataVehiclesTypesTypeIdGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleTypesApiFp(this.configuration).apiV1ReferenceDataVehiclesTypesTypeIdGet(requestParameters.typeId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehicleVisualModelsApi - axios parameter creator
 * @export
 */
export const VehicleVisualModelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehicleVisualModelGetPaginatedDto} [vehicleVisualModelGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesVisualModelsGetPost: async (vehicleVisualModelGetPaginatedDto?: VehicleVisualModelGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/visual-models/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleVisualModelGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleVisualModelType} [type] 
         * @param {VehicleType} [vehicleType] 
         * @param {VehicleBodyType} [bodyType] 
         * @param {string} [vehicleId] 
         * @param {boolean} [isIgnoreDefaultForVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet: async (type?: VehicleVisualModelType, vehicleType?: VehicleType, bodyType?: VehicleBodyType, vehicleId?: string, isIgnoreDefaultForVehicle?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/visual-models/latest-or-default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (vehicleType !== undefined) {
                localVarQueryParameter['vehicleType'] = vehicleType;
            }

            if (bodyType !== undefined) {
                localVarQueryParameter['bodyType'] = bodyType;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (isIgnoreDefaultForVehicle !== undefined) {
                localVarQueryParameter['isIgnoreDefaultForVehicle'] = isIgnoreDefaultForVehicle;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesVisualModelsModelIdGet: async (modelId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('apiV1ReferenceDataVehiclesVisualModelsModelIdGet', 'modelId', modelId)
            const localVarPath = `/api/v1/reference-data/vehicles/visual-models/{modelId}`
                .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleVisualModelSearchPaginatedDto} [vehicleVisualModelSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesVisualModelsSearchPost: async (vehicleVisualModelSearchPaginatedDto?: VehicleVisualModelSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference-data/vehicles/visual-models/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleVisualModelSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleVisualModelsApi - functional programming interface
 * @export
 */
export const VehicleVisualModelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleVisualModelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehicleVisualModelGetPaginatedDto} [vehicleVisualModelGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesVisualModelsGetPost(vehicleVisualModelGetPaginatedDto?: VehicleVisualModelGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesVisualModelsGetPost(vehicleVisualModelGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleVisualModelType} [type] 
         * @param {VehicleType} [vehicleType] 
         * @param {VehicleBodyType} [bodyType] 
         * @param {string} [vehicleId] 
         * @param {boolean} [isIgnoreDefaultForVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet(type?: VehicleVisualModelType, vehicleType?: VehicleType, bodyType?: VehicleBodyType, vehicleId?: string, isIgnoreDefaultForVehicle?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet(type, vehicleType, bodyType, vehicleId, isIgnoreDefaultForVehicle, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesVisualModelsModelIdGet(modelId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesVisualModelsModelIdGet(modelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VehicleVisualModelSearchPaginatedDto} [vehicleVisualModelSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReferenceDataVehiclesVisualModelsSearchPost(vehicleVisualModelSearchPaginatedDto?: VehicleVisualModelSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleVisualModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReferenceDataVehiclesVisualModelsSearchPost(vehicleVisualModelSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VehicleVisualModelsApi - factory interface
 * @export
 */
export const VehicleVisualModelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleVisualModelsApiFp(configuration)
    return {
        /**
         * 
         * @param {VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesVisualModelsGetPost(requestParameters: VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsGetPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleVisualModelDto> {
            return localVarFp.apiV1ReferenceDataVehiclesVisualModelsGetPost(requestParameters.vehicleVisualModelGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet(requestParameters: VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<VehicleVisualModelDto> {
            return localVarFp.apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet(requestParameters.type, requestParameters.vehicleType, requestParameters.bodyType, requestParameters.vehicleId, requestParameters.isIgnoreDefaultForVehicle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsModelIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesVisualModelsModelIdGet(requestParameters: VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsModelIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleVisualModelDto> {
            return localVarFp.apiV1ReferenceDataVehiclesVisualModelsModelIdGet(requestParameters.modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReferenceDataVehiclesVisualModelsSearchPost(requestParameters: VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsSearchPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleVisualModelDto> {
            return localVarFp.apiV1ReferenceDataVehiclesVisualModelsSearchPost(requestParameters.vehicleVisualModelSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1ReferenceDataVehiclesVisualModelsGetPost operation in VehicleVisualModelsApi.
 * @export
 * @interface VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsGetPostRequest
 */
export interface VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsGetPostRequest {
    /**
     * 
     * @type {VehicleVisualModelGetPaginatedDto}
     * @memberof VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsGetPost
     */
    readonly vehicleVisualModelGetPaginatedDto?: VehicleVisualModelGetPaginatedDto
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet operation in VehicleVisualModelsApi.
 * @export
 * @interface VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest
 */
export interface VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest {
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet
     */
    readonly type?: VehicleVisualModelType

    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet
     */
    readonly vehicleType?: VehicleType

    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet
     */
    readonly bodyType?: VehicleBodyType

    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet
     */
    readonly vehicleId?: string

    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet
     */
    readonly isIgnoreDefaultForVehicle?: boolean
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesVisualModelsModelIdGet operation in VehicleVisualModelsApi.
 * @export
 * @interface VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsModelIdGetRequest
 */
export interface VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsModelIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsModelIdGet
     */
    readonly modelId: string
}

/**
 * Request parameters for apiV1ReferenceDataVehiclesVisualModelsSearchPost operation in VehicleVisualModelsApi.
 * @export
 * @interface VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsSearchPostRequest
 */
export interface VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsSearchPostRequest {
    /**
     * 
     * @type {VehicleVisualModelSearchPaginatedDto}
     * @memberof VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsSearchPost
     */
    readonly vehicleVisualModelSearchPaginatedDto?: VehicleVisualModelSearchPaginatedDto
}

/**
 * VehicleVisualModelsApi - object-oriented interface
 * @export
 * @class VehicleVisualModelsApi
 * @extends {BaseAPI}
 */
export class VehicleVisualModelsApi extends BaseAPI {
    /**
     * 
     * @param {VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleVisualModelsApi
     */
    public apiV1ReferenceDataVehiclesVisualModelsGetPost(requestParameters: VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsGetPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleVisualModelsApiFp(this.configuration).apiV1ReferenceDataVehiclesVisualModelsGetPost(requestParameters.vehicleVisualModelGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleVisualModelsApi
     */
    public apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet(requestParameters: VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleVisualModelsApiFp(this.configuration).apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet(requestParameters.type, requestParameters.vehicleType, requestParameters.bodyType, requestParameters.vehicleId, requestParameters.isIgnoreDefaultForVehicle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsModelIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleVisualModelsApi
     */
    public apiV1ReferenceDataVehiclesVisualModelsModelIdGet(requestParameters: VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsModelIdGetRequest, options?: RawAxiosRequestConfig) {
        return VehicleVisualModelsApiFp(this.configuration).apiV1ReferenceDataVehiclesVisualModelsModelIdGet(requestParameters.modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleVisualModelsApi
     */
    public apiV1ReferenceDataVehiclesVisualModelsSearchPost(requestParameters: VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsSearchPostRequest = {}, options?: RawAxiosRequestConfig) {
        return VehicleVisualModelsApiFp(this.configuration).apiV1ReferenceDataVehiclesVisualModelsSearchPost(requestParameters.vehicleVisualModelSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehiclesApi - axios parameter creator
 * @export
 */
export const VehiclesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VehicleCreateManySimilarDto} [vehicleCreateManySimilarDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesCreateManySimilarPost: async (nexusOpsTenant: string, vehicleCreateManySimilarDto?: VehicleCreateManySimilarDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesCreateManySimilarPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/vehicles/create-many-similar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleCreateManySimilarDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VehicleGetPaginatedDto} [vehicleGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesGetPost: async (nexusOpsTenant: string, vehicleGetPaginatedDto?: VehicleGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/vehicles/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateVehicleDto} [createVehicleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesPost: async (nexusOpsTenant: string, createVehicleDto?: CreateVehicleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVehicleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VehicleSearchPaginatedDto} [vehicleSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesSearchPost: async (nexusOpsTenant: string, vehicleSearchPaginatedDto?: VehicleSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/vehicles/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VehicleTaxCalcRequestDto} [vehicleTaxCalcRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesTaxCalcPost: async (nexusOpsTenant: string, vehicleTaxCalcRequestDto?: VehicleTaxCalcRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesTaxCalcPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/vehicles/tax/calc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleTaxCalcRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} [contractId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdAccessoriesGet: async (nexusOpsTenant: string, vehicleId: string, contractId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdAccessoriesGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdAccessoriesGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/accessories`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdActualStateGet: async (nexusOpsTenant: string, vehicleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdActualStateGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdActualStateGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/actual-state`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdActualStatePdfGet: async (nexusOpsTenant: string, vehicleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdActualStatePdfGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdActualStatePdfGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/actual-state/pdf`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {AttachmentsUpdateDto} [attachmentsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdAttachmentsPut: async (nexusOpsTenant: string, vehicleId: string, attachmentsUpdateDto?: AttachmentsUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdAttachmentsPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdAttachmentsPut', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/attachments`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachmentsUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdGet: async (nexusOpsTenant: string, vehicleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {UpdateVehicleMileageDto} [updateVehicleMileageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdMileagePut: async (nexusOpsTenant: string, vehicleId: string, updateVehicleMileageDto?: UpdateVehicleMileageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdMileagePut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdMileagePut', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}/mileage`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVehicleMileageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {UpdateVehicleDto} [updateVehicleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdPut: async (nexusOpsTenant: string, vehicleId: string, updateVehicleDto?: UpdateVehicleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV1VehiclesVehicleIdPut', 'vehicleId', vehicleId)
            const localVarPath = `/api/v1/vehicles/{vehicleId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVehicleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehiclesApi - functional programming interface
 * @export
 */
export const VehiclesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehiclesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VehicleCreateManySimilarDto} [vehicleCreateManySimilarDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesCreateManySimilarPost(nexusOpsTenant: string, vehicleCreateManySimilarDto?: VehicleCreateManySimilarDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesCreateManySimilarPost(nexusOpsTenant, vehicleCreateManySimilarDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.apiV1VehiclesCreateManySimilarPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VehicleGetPaginatedDto} [vehicleGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesGetPost(nexusOpsTenant: string, vehicleGetPaginatedDto?: VehicleGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesGetPost(nexusOpsTenant, vehicleGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.apiV1VehiclesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateVehicleDto} [createVehicleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesPost(nexusOpsTenant: string, createVehicleDto?: CreateVehicleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesPost(nexusOpsTenant, createVehicleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.apiV1VehiclesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VehicleSearchPaginatedDto} [vehicleSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesSearchPost(nexusOpsTenant: string, vehicleSearchPaginatedDto?: VehicleSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVehicleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesSearchPost(nexusOpsTenant, vehicleSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.apiV1VehiclesSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VehicleTaxCalcRequestDto} [vehicleTaxCalcRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesTaxCalcPost(nexusOpsTenant: string, vehicleTaxCalcRequestDto?: VehicleTaxCalcRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleTaxCalcResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesTaxCalcPost(nexusOpsTenant, vehicleTaxCalcRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.apiV1VehiclesTaxCalcPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {string} [contractId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdAccessoriesGet(nexusOpsTenant: string, vehicleId: string, contractId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleAccessoriesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdAccessoriesGet(nexusOpsTenant, vehicleId, contractId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.apiV1VehiclesVehicleIdAccessoriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdActualStateGet(nexusOpsTenant: string, vehicleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleActualStateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdActualStateGet(nexusOpsTenant, vehicleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.apiV1VehiclesVehicleIdActualStateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdActualStatePdfGet(nexusOpsTenant: string, vehicleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdActualStatePdfGet(nexusOpsTenant, vehicleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.apiV1VehiclesVehicleIdActualStatePdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {AttachmentsUpdateDto} [attachmentsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdAttachmentsPut(nexusOpsTenant: string, vehicleId: string, attachmentsUpdateDto?: AttachmentsUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdAttachmentsPut(nexusOpsTenant, vehicleId, attachmentsUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.apiV1VehiclesVehicleIdAttachmentsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdGet(nexusOpsTenant: string, vehicleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdGet(nexusOpsTenant, vehicleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.apiV1VehiclesVehicleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {UpdateVehicleMileageDto} [updateVehicleMileageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdMileagePut(nexusOpsTenant: string, vehicleId: string, updateVehicleMileageDto?: UpdateVehicleMileageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdMileagePut(nexusOpsTenant, vehicleId, updateVehicleMileageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.apiV1VehiclesVehicleIdMileagePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} vehicleId 
         * @param {UpdateVehicleDto} [updateVehicleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VehiclesVehicleIdPut(nexusOpsTenant: string, vehicleId: string, updateVehicleDto?: UpdateVehicleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VehiclesVehicleIdPut(nexusOpsTenant, vehicleId, updateVehicleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.apiV1VehiclesVehicleIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VehiclesApi - factory interface
 * @export
 */
export const VehiclesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehiclesApiFp(configuration)
    return {
        /**
         * 
         * @param {VehiclesApiApiV1VehiclesCreateManySimilarPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesCreateManySimilarPost(requestParameters: VehiclesApiApiV1VehiclesCreateManySimilarPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<VehicleDto>> {
            return localVarFp.apiV1VehiclesCreateManySimilarPost(requestParameters.nexusOpsTenant, requestParameters.vehicleCreateManySimilarDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehiclesApiApiV1VehiclesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesGetPost(requestParameters: VehiclesApiApiV1VehiclesGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleDto> {
            return localVarFp.apiV1VehiclesGetPost(requestParameters.nexusOpsTenant, requestParameters.vehicleGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehiclesApiApiV1VehiclesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesPost(requestParameters: VehiclesApiApiV1VehiclesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDto> {
            return localVarFp.apiV1VehiclesPost(requestParameters.nexusOpsTenant, requestParameters.createVehicleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehiclesApiApiV1VehiclesSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesSearchPost(requestParameters: VehiclesApiApiV1VehiclesSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVehicleDto> {
            return localVarFp.apiV1VehiclesSearchPost(requestParameters.nexusOpsTenant, requestParameters.vehicleSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehiclesApiApiV1VehiclesTaxCalcPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesTaxCalcPost(requestParameters: VehiclesApiApiV1VehiclesTaxCalcPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleTaxCalcResponseDto> {
            return localVarFp.apiV1VehiclesTaxCalcPost(requestParameters.nexusOpsTenant, requestParameters.vehicleTaxCalcRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehiclesApiApiV1VehiclesVehicleIdAccessoriesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdAccessoriesGet(requestParameters: VehiclesApiApiV1VehiclesVehicleIdAccessoriesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleAccessoriesDto> {
            return localVarFp.apiV1VehiclesVehicleIdAccessoriesGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehiclesApiApiV1VehiclesVehicleIdActualStateGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdActualStateGet(requestParameters: VehiclesApiApiV1VehiclesVehicleIdActualStateGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleActualStateDto> {
            return localVarFp.apiV1VehiclesVehicleIdActualStateGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehiclesApiApiV1VehiclesVehicleIdActualStatePdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdActualStatePdfGet(requestParameters: VehiclesApiApiV1VehiclesVehicleIdActualStatePdfGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1VehiclesVehicleIdActualStatePdfGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehiclesApiApiV1VehiclesVehicleIdAttachmentsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdAttachmentsPut(requestParameters: VehiclesApiApiV1VehiclesVehicleIdAttachmentsPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDto> {
            return localVarFp.apiV1VehiclesVehicleIdAttachmentsPut(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.attachmentsUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehiclesApiApiV1VehiclesVehicleIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdGet(requestParameters: VehiclesApiApiV1VehiclesVehicleIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDto> {
            return localVarFp.apiV1VehiclesVehicleIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehiclesApiApiV1VehiclesVehicleIdMileagePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdMileagePut(requestParameters: VehiclesApiApiV1VehiclesVehicleIdMileagePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDto> {
            return localVarFp.apiV1VehiclesVehicleIdMileagePut(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.updateVehicleMileageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehiclesApiApiV1VehiclesVehicleIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VehiclesVehicleIdPut(requestParameters: VehiclesApiApiV1VehiclesVehicleIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<VehicleDto> {
            return localVarFp.apiV1VehiclesVehicleIdPut(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.updateVehicleDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1VehiclesCreateManySimilarPost operation in VehiclesApi.
 * @export
 * @interface VehiclesApiApiV1VehiclesCreateManySimilarPostRequest
 */
export interface VehiclesApiApiV1VehiclesCreateManySimilarPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesCreateManySimilarPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {VehicleCreateManySimilarDto}
     * @memberof VehiclesApiApiV1VehiclesCreateManySimilarPost
     */
    readonly vehicleCreateManySimilarDto?: VehicleCreateManySimilarDto
}

/**
 * Request parameters for apiV1VehiclesGetPost operation in VehiclesApi.
 * @export
 * @interface VehiclesApiApiV1VehiclesGetPostRequest
 */
export interface VehiclesApiApiV1VehiclesGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {VehicleGetPaginatedDto}
     * @memberof VehiclesApiApiV1VehiclesGetPost
     */
    readonly vehicleGetPaginatedDto?: VehicleGetPaginatedDto
}

/**
 * Request parameters for apiV1VehiclesPost operation in VehiclesApi.
 * @export
 * @interface VehiclesApiApiV1VehiclesPostRequest
 */
export interface VehiclesApiApiV1VehiclesPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateVehicleDto}
     * @memberof VehiclesApiApiV1VehiclesPost
     */
    readonly createVehicleDto?: CreateVehicleDto
}

/**
 * Request parameters for apiV1VehiclesSearchPost operation in VehiclesApi.
 * @export
 * @interface VehiclesApiApiV1VehiclesSearchPostRequest
 */
export interface VehiclesApiApiV1VehiclesSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {VehicleSearchPaginatedDto}
     * @memberof VehiclesApiApiV1VehiclesSearchPost
     */
    readonly vehicleSearchPaginatedDto?: VehicleSearchPaginatedDto
}

/**
 * Request parameters for apiV1VehiclesTaxCalcPost operation in VehiclesApi.
 * @export
 * @interface VehiclesApiApiV1VehiclesTaxCalcPostRequest
 */
export interface VehiclesApiApiV1VehiclesTaxCalcPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesTaxCalcPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {VehicleTaxCalcRequestDto}
     * @memberof VehiclesApiApiV1VehiclesTaxCalcPost
     */
    readonly vehicleTaxCalcRequestDto?: VehicleTaxCalcRequestDto
}

/**
 * Request parameters for apiV1VehiclesVehicleIdAccessoriesGet operation in VehiclesApi.
 * @export
 * @interface VehiclesApiApiV1VehiclesVehicleIdAccessoriesGetRequest
 */
export interface VehiclesApiApiV1VehiclesVehicleIdAccessoriesGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdAccessoriesGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdAccessoriesGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdAccessoriesGet
     */
    readonly contractId?: string
}

/**
 * Request parameters for apiV1VehiclesVehicleIdActualStateGet operation in VehiclesApi.
 * @export
 * @interface VehiclesApiApiV1VehiclesVehicleIdActualStateGetRequest
 */
export interface VehiclesApiApiV1VehiclesVehicleIdActualStateGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdActualStateGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdActualStateGet
     */
    readonly vehicleId: string
}

/**
 * Request parameters for apiV1VehiclesVehicleIdActualStatePdfGet operation in VehiclesApi.
 * @export
 * @interface VehiclesApiApiV1VehiclesVehicleIdActualStatePdfGetRequest
 */
export interface VehiclesApiApiV1VehiclesVehicleIdActualStatePdfGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdActualStatePdfGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdActualStatePdfGet
     */
    readonly vehicleId: string
}

/**
 * Request parameters for apiV1VehiclesVehicleIdAttachmentsPut operation in VehiclesApi.
 * @export
 * @interface VehiclesApiApiV1VehiclesVehicleIdAttachmentsPutRequest
 */
export interface VehiclesApiApiV1VehiclesVehicleIdAttachmentsPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdAttachmentsPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdAttachmentsPut
     */
    readonly vehicleId: string

    /**
     * 
     * @type {AttachmentsUpdateDto}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdAttachmentsPut
     */
    readonly attachmentsUpdateDto?: AttachmentsUpdateDto
}

/**
 * Request parameters for apiV1VehiclesVehicleIdGet operation in VehiclesApi.
 * @export
 * @interface VehiclesApiApiV1VehiclesVehicleIdGetRequest
 */
export interface VehiclesApiApiV1VehiclesVehicleIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdGet
     */
    readonly vehicleId: string
}

/**
 * Request parameters for apiV1VehiclesVehicleIdMileagePut operation in VehiclesApi.
 * @export
 * @interface VehiclesApiApiV1VehiclesVehicleIdMileagePutRequest
 */
export interface VehiclesApiApiV1VehiclesVehicleIdMileagePutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdMileagePut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdMileagePut
     */
    readonly vehicleId: string

    /**
     * 
     * @type {UpdateVehicleMileageDto}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdMileagePut
     */
    readonly updateVehicleMileageDto?: UpdateVehicleMileageDto
}

/**
 * Request parameters for apiV1VehiclesVehicleIdPut operation in VehiclesApi.
 * @export
 * @interface VehiclesApiApiV1VehiclesVehicleIdPutRequest
 */
export interface VehiclesApiApiV1VehiclesVehicleIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdPut
     */
    readonly vehicleId: string

    /**
     * 
     * @type {UpdateVehicleDto}
     * @memberof VehiclesApiApiV1VehiclesVehicleIdPut
     */
    readonly updateVehicleDto?: UpdateVehicleDto
}

/**
 * VehiclesApi - object-oriented interface
 * @export
 * @class VehiclesApi
 * @extends {BaseAPI}
 */
export class VehiclesApi extends BaseAPI {
    /**
     * 
     * @param {VehiclesApiApiV1VehiclesCreateManySimilarPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiV1VehiclesCreateManySimilarPost(requestParameters: VehiclesApiApiV1VehiclesCreateManySimilarPostRequest, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiV1VehiclesCreateManySimilarPost(requestParameters.nexusOpsTenant, requestParameters.vehicleCreateManySimilarDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehiclesApiApiV1VehiclesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiV1VehiclesGetPost(requestParameters: VehiclesApiApiV1VehiclesGetPostRequest, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiV1VehiclesGetPost(requestParameters.nexusOpsTenant, requestParameters.vehicleGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehiclesApiApiV1VehiclesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiV1VehiclesPost(requestParameters: VehiclesApiApiV1VehiclesPostRequest, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiV1VehiclesPost(requestParameters.nexusOpsTenant, requestParameters.createVehicleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehiclesApiApiV1VehiclesSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiV1VehiclesSearchPost(requestParameters: VehiclesApiApiV1VehiclesSearchPostRequest, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiV1VehiclesSearchPost(requestParameters.nexusOpsTenant, requestParameters.vehicleSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehiclesApiApiV1VehiclesTaxCalcPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiV1VehiclesTaxCalcPost(requestParameters: VehiclesApiApiV1VehiclesTaxCalcPostRequest, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiV1VehiclesTaxCalcPost(requestParameters.nexusOpsTenant, requestParameters.vehicleTaxCalcRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehiclesApiApiV1VehiclesVehicleIdAccessoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiV1VehiclesVehicleIdAccessoriesGet(requestParameters: VehiclesApiApiV1VehiclesVehicleIdAccessoriesGetRequest, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiV1VehiclesVehicleIdAccessoriesGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehiclesApiApiV1VehiclesVehicleIdActualStateGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiV1VehiclesVehicleIdActualStateGet(requestParameters: VehiclesApiApiV1VehiclesVehicleIdActualStateGetRequest, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiV1VehiclesVehicleIdActualStateGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehiclesApiApiV1VehiclesVehicleIdActualStatePdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiV1VehiclesVehicleIdActualStatePdfGet(requestParameters: VehiclesApiApiV1VehiclesVehicleIdActualStatePdfGetRequest, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiV1VehiclesVehicleIdActualStatePdfGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehiclesApiApiV1VehiclesVehicleIdAttachmentsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiV1VehiclesVehicleIdAttachmentsPut(requestParameters: VehiclesApiApiV1VehiclesVehicleIdAttachmentsPutRequest, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiV1VehiclesVehicleIdAttachmentsPut(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.attachmentsUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehiclesApiApiV1VehiclesVehicleIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiV1VehiclesVehicleIdGet(requestParameters: VehiclesApiApiV1VehiclesVehicleIdGetRequest, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiV1VehiclesVehicleIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehiclesApiApiV1VehiclesVehicleIdMileagePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiV1VehiclesVehicleIdMileagePut(requestParameters: VehiclesApiApiV1VehiclesVehicleIdMileagePutRequest, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiV1VehiclesVehicleIdMileagePut(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.updateVehicleMileageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehiclesApiApiV1VehiclesVehicleIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiV1VehiclesVehicleIdPut(requestParameters: VehiclesApiApiV1VehiclesVehicleIdPutRequest, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiV1VehiclesVehicleIdPut(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.updateVehicleDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VisualInspectionsApi - axios parameter creator
 * @export
 */
export const VisualInspectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsBulkDeleteDelete: async (nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VisualInspectionsBulkDeleteDelete', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/visual-inspections/bulk/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityBulkDeleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsByIdsGet: async (nexusOpsTenant: string, ids?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VisualInspectionsByIdsGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/visual-inspections/by-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsCountersGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VisualInspectionsCountersGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/visual-inspections/counters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsCreateMetaGet: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VisualInspectionsCreateMetaGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/visual-inspections/create/meta`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VisualInspectionGetPaginatedDto} [visualInspectionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsGetPost: async (nexusOpsTenant: string, visualInspectionGetPaginatedDto?: VisualInspectionGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VisualInspectionsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/visual-inspections/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visualInspectionGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VisualInspectionCreateDto} [visualInspectionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsPost: async (nexusOpsTenant: string, visualInspectionCreateDto?: VisualInspectionCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VisualInspectionsPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/visual-inspections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visualInspectionCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VisualInspectionSearchPaginatedDto} [visualInspectionSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsSearchPost: async (nexusOpsTenant: string, visualInspectionSearchPaginatedDto?: VisualInspectionSearchPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VisualInspectionsSearchPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/visual-inspections/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visualInspectionSearchPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {VisualInspectionAddToContractDto} [visualInspectionAddToContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsVisualInspectionIdAddToContractPost: async (nexusOpsTenant: string, visualInspectionId: string, visualInspectionAddToContractDto?: VisualInspectionAddToContractDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VisualInspectionsVisualInspectionIdAddToContractPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'visualInspectionId' is not null or undefined
            assertParamExists('apiV1VisualInspectionsVisualInspectionIdAddToContractPost', 'visualInspectionId', visualInspectionId)
            const localVarPath = `/api/v1/visual-inspections/{visualInspectionId}/add-to-contract`
                .replace(`{${"visualInspectionId"}}`, encodeURIComponent(String(visualInspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visualInspectionAddToContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsVisualInspectionIdDelete: async (nexusOpsTenant: string, visualInspectionId: string, isDeleteCascade?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VisualInspectionsVisualInspectionIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'visualInspectionId' is not null or undefined
            assertParamExists('apiV1VisualInspectionsVisualInspectionIdDelete', 'visualInspectionId', visualInspectionId)
            const localVarPath = `/api/v1/visual-inspections/{visualInspectionId}`
                .replace(`{${"visualInspectionId"}}`, encodeURIComponent(String(visualInspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDeleteCascade !== undefined) {
                localVarQueryParameter['isDeleteCascade'] = isDeleteCascade;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsVisualInspectionIdDownloadPdfGet: async (nexusOpsTenant: string, visualInspectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VisualInspectionsVisualInspectionIdDownloadPdfGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'visualInspectionId' is not null or undefined
            assertParamExists('apiV1VisualInspectionsVisualInspectionIdDownloadPdfGet', 'visualInspectionId', visualInspectionId)
            const localVarPath = `/api/v1/visual-inspections/{visualInspectionId}/download/pdf`
                .replace(`{${"visualInspectionId"}}`, encodeURIComponent(String(visualInspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsVisualInspectionIdGet: async (nexusOpsTenant: string, visualInspectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VisualInspectionsVisualInspectionIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'visualInspectionId' is not null or undefined
            assertParamExists('apiV1VisualInspectionsVisualInspectionIdGet', 'visualInspectionId', visualInspectionId)
            const localVarPath = `/api/v1/visual-inspections/{visualInspectionId}`
                .replace(`{${"visualInspectionId"}}`, encodeURIComponent(String(visualInspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {VisualInspectionUpdateDto} [visualInspectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsVisualInspectionIdPut: async (nexusOpsTenant: string, visualInspectionId: string, visualInspectionUpdateDto?: VisualInspectionUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1VisualInspectionsVisualInspectionIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'visualInspectionId' is not null or undefined
            assertParamExists('apiV1VisualInspectionsVisualInspectionIdPut', 'visualInspectionId', visualInspectionId)
            const localVarPath = `/api/v1/visual-inspections/{visualInspectionId}`
                .replace(`{${"visualInspectionId"}}`, encodeURIComponent(String(visualInspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visualInspectionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VisualInspectionsApi - functional programming interface
 * @export
 */
export const VisualInspectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VisualInspectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {EntityBulkDeleteDto} [entityBulkDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VisualInspectionsBulkDeleteDelete(nexusOpsTenant: string, entityBulkDeleteDto?: EntityBulkDeleteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VisualInspectionsBulkDeleteDelete(nexusOpsTenant, entityBulkDeleteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisualInspectionsApi.apiV1VisualInspectionsBulkDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VisualInspectionsByIdsGet(nexusOpsTenant: string, ids?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VisualInspectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VisualInspectionsByIdsGet(nexusOpsTenant, ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisualInspectionsApi.apiV1VisualInspectionsByIdsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VisualInspectionsCountersGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualInspectionCountersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VisualInspectionsCountersGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisualInspectionsApi.apiV1VisualInspectionsCountersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VisualInspectionsCreateMetaGet(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualInspectionGetCreateMetaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VisualInspectionsCreateMetaGet(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisualInspectionsApi.apiV1VisualInspectionsCreateMetaGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VisualInspectionGetPaginatedDto} [visualInspectionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VisualInspectionsGetPost(nexusOpsTenant: string, visualInspectionGetPaginatedDto?: VisualInspectionGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVisualInspectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VisualInspectionsGetPost(nexusOpsTenant, visualInspectionGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisualInspectionsApi.apiV1VisualInspectionsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VisualInspectionCreateDto} [visualInspectionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VisualInspectionsPost(nexusOpsTenant: string, visualInspectionCreateDto?: VisualInspectionCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualInspectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VisualInspectionsPost(nexusOpsTenant, visualInspectionCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisualInspectionsApi.apiV1VisualInspectionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {VisualInspectionSearchPaginatedDto} [visualInspectionSearchPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VisualInspectionsSearchPost(nexusOpsTenant: string, visualInspectionSearchPaginatedDto?: VisualInspectionSearchPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfVisualInspectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VisualInspectionsSearchPost(nexusOpsTenant, visualInspectionSearchPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisualInspectionsApi.apiV1VisualInspectionsSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {VisualInspectionAddToContractDto} [visualInspectionAddToContractDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VisualInspectionsVisualInspectionIdAddToContractPost(nexusOpsTenant: string, visualInspectionId: string, visualInspectionAddToContractDto?: VisualInspectionAddToContractDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualInspectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VisualInspectionsVisualInspectionIdAddToContractPost(nexusOpsTenant, visualInspectionId, visualInspectionAddToContractDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdAddToContractPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {boolean} [isDeleteCascade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VisualInspectionsVisualInspectionIdDelete(nexusOpsTenant: string, visualInspectionId: string, isDeleteCascade?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VisualInspectionsVisualInspectionIdDelete(nexusOpsTenant, visualInspectionId, isDeleteCascade, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VisualInspectionsVisualInspectionIdDownloadPdfGet(nexusOpsTenant: string, visualInspectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VisualInspectionsVisualInspectionIdDownloadPdfGet(nexusOpsTenant, visualInspectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdDownloadPdfGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VisualInspectionsVisualInspectionIdGet(nexusOpsTenant: string, visualInspectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualInspectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VisualInspectionsVisualInspectionIdGet(nexusOpsTenant, visualInspectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} visualInspectionId 
         * @param {VisualInspectionUpdateDto} [visualInspectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VisualInspectionsVisualInspectionIdPut(nexusOpsTenant: string, visualInspectionId: string, visualInspectionUpdateDto?: VisualInspectionUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualInspectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VisualInspectionsVisualInspectionIdPut(nexusOpsTenant, visualInspectionId, visualInspectionUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VisualInspectionsApi - factory interface
 * @export
 */
export const VisualInspectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VisualInspectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {VisualInspectionsApiApiV1VisualInspectionsBulkDeleteDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsBulkDeleteDelete(requestParameters: VisualInspectionsApiApiV1VisualInspectionsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1VisualInspectionsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VisualInspectionsApiApiV1VisualInspectionsByIdsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsByIdsGet(requestParameters: VisualInspectionsApiApiV1VisualInspectionsByIdsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<VisualInspectionDto>> {
            return localVarFp.apiV1VisualInspectionsByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VisualInspectionsApiApiV1VisualInspectionsCountersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsCountersGet(requestParameters: VisualInspectionsApiApiV1VisualInspectionsCountersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VisualInspectionCountersDto> {
            return localVarFp.apiV1VisualInspectionsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VisualInspectionsApiApiV1VisualInspectionsCreateMetaGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsCreateMetaGet(requestParameters: VisualInspectionsApiApiV1VisualInspectionsCreateMetaGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VisualInspectionGetCreateMetaDto> {
            return localVarFp.apiV1VisualInspectionsCreateMetaGet(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VisualInspectionsApiApiV1VisualInspectionsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsGetPost(requestParameters: VisualInspectionsApiApiV1VisualInspectionsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVisualInspectionDto> {
            return localVarFp.apiV1VisualInspectionsGetPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VisualInspectionsApiApiV1VisualInspectionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsPost(requestParameters: VisualInspectionsApiApiV1VisualInspectionsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<VisualInspectionDto> {
            return localVarFp.apiV1VisualInspectionsPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VisualInspectionsApiApiV1VisualInspectionsSearchPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsSearchPost(requestParameters: VisualInspectionsApiApiV1VisualInspectionsSearchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfVisualInspectionDto> {
            return localVarFp.apiV1VisualInspectionsSearchPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionSearchPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdAddToContractPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsVisualInspectionIdAddToContractPost(requestParameters: VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdAddToContractPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<VisualInspectionDto> {
            return localVarFp.apiV1VisualInspectionsVisualInspectionIdAddToContractPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, requestParameters.visualInspectionAddToContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsVisualInspectionIdDelete(requestParameters: VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1VisualInspectionsVisualInspectionIdDelete(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, requestParameters.isDeleteCascade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDownloadPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsVisualInspectionIdDownloadPdfGet(requestParameters: VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1VisualInspectionsVisualInspectionIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsVisualInspectionIdGet(requestParameters: VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VisualInspectionDto> {
            return localVarFp.apiV1VisualInspectionsVisualInspectionIdGet(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VisualInspectionsVisualInspectionIdPut(requestParameters: VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<VisualInspectionDto> {
            return localVarFp.apiV1VisualInspectionsVisualInspectionIdPut(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, requestParameters.visualInspectionUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1VisualInspectionsBulkDeleteDelete operation in VisualInspectionsApi.
 * @export
 * @interface VisualInspectionsApiApiV1VisualInspectionsBulkDeleteDeleteRequest
 */
export interface VisualInspectionsApiApiV1VisualInspectionsBulkDeleteDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsBulkDeleteDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {EntityBulkDeleteDto}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsBulkDeleteDelete
     */
    readonly entityBulkDeleteDto?: EntityBulkDeleteDto
}

/**
 * Request parameters for apiV1VisualInspectionsByIdsGet operation in VisualInspectionsApi.
 * @export
 * @interface VisualInspectionsApiApiV1VisualInspectionsByIdsGetRequest
 */
export interface VisualInspectionsApiApiV1VisualInspectionsByIdsGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsByIdsGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {Array<string>}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsByIdsGet
     */
    readonly ids?: Array<string>
}

/**
 * Request parameters for apiV1VisualInspectionsCountersGet operation in VisualInspectionsApi.
 * @export
 * @interface VisualInspectionsApiApiV1VisualInspectionsCountersGetRequest
 */
export interface VisualInspectionsApiApiV1VisualInspectionsCountersGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsCountersGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1VisualInspectionsCreateMetaGet operation in VisualInspectionsApi.
 * @export
 * @interface VisualInspectionsApiApiV1VisualInspectionsCreateMetaGetRequest
 */
export interface VisualInspectionsApiApiV1VisualInspectionsCreateMetaGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsCreateMetaGet
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1VisualInspectionsGetPost operation in VisualInspectionsApi.
 * @export
 * @interface VisualInspectionsApiApiV1VisualInspectionsGetPostRequest
 */
export interface VisualInspectionsApiApiV1VisualInspectionsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {VisualInspectionGetPaginatedDto}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsGetPost
     */
    readonly visualInspectionGetPaginatedDto?: VisualInspectionGetPaginatedDto
}

/**
 * Request parameters for apiV1VisualInspectionsPost operation in VisualInspectionsApi.
 * @export
 * @interface VisualInspectionsApiApiV1VisualInspectionsPostRequest
 */
export interface VisualInspectionsApiApiV1VisualInspectionsPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {VisualInspectionCreateDto}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsPost
     */
    readonly visualInspectionCreateDto?: VisualInspectionCreateDto
}

/**
 * Request parameters for apiV1VisualInspectionsSearchPost operation in VisualInspectionsApi.
 * @export
 * @interface VisualInspectionsApiApiV1VisualInspectionsSearchPostRequest
 */
export interface VisualInspectionsApiApiV1VisualInspectionsSearchPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsSearchPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {VisualInspectionSearchPaginatedDto}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsSearchPost
     */
    readonly visualInspectionSearchPaginatedDto?: VisualInspectionSearchPaginatedDto
}

/**
 * Request parameters for apiV1VisualInspectionsVisualInspectionIdAddToContractPost operation in VisualInspectionsApi.
 * @export
 * @interface VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdAddToContractPostRequest
 */
export interface VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdAddToContractPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdAddToContractPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdAddToContractPost
     */
    readonly visualInspectionId: string

    /**
     * 
     * @type {VisualInspectionAddToContractDto}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdAddToContractPost
     */
    readonly visualInspectionAddToContractDto?: VisualInspectionAddToContractDto
}

/**
 * Request parameters for apiV1VisualInspectionsVisualInspectionIdDelete operation in VisualInspectionsApi.
 * @export
 * @interface VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDeleteRequest
 */
export interface VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDelete
     */
    readonly visualInspectionId: string

    /**
     * 
     * @type {boolean}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDelete
     */
    readonly isDeleteCascade?: boolean
}

/**
 * Request parameters for apiV1VisualInspectionsVisualInspectionIdDownloadPdfGet operation in VisualInspectionsApi.
 * @export
 * @interface VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDownloadPdfGetRequest
 */
export interface VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDownloadPdfGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDownloadPdfGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDownloadPdfGet
     */
    readonly visualInspectionId: string
}

/**
 * Request parameters for apiV1VisualInspectionsVisualInspectionIdGet operation in VisualInspectionsApi.
 * @export
 * @interface VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdGetRequest
 */
export interface VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdGet
     */
    readonly visualInspectionId: string
}

/**
 * Request parameters for apiV1VisualInspectionsVisualInspectionIdPut operation in VisualInspectionsApi.
 * @export
 * @interface VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdPutRequest
 */
export interface VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdPut
     */
    readonly visualInspectionId: string

    /**
     * 
     * @type {VisualInspectionUpdateDto}
     * @memberof VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdPut
     */
    readonly visualInspectionUpdateDto?: VisualInspectionUpdateDto
}

/**
 * VisualInspectionsApi - object-oriented interface
 * @export
 * @class VisualInspectionsApi
 * @extends {BaseAPI}
 */
export class VisualInspectionsApi extends BaseAPI {
    /**
     * 
     * @param {VisualInspectionsApiApiV1VisualInspectionsBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualInspectionsApi
     */
    public apiV1VisualInspectionsBulkDeleteDelete(requestParameters: VisualInspectionsApiApiV1VisualInspectionsBulkDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return VisualInspectionsApiFp(this.configuration).apiV1VisualInspectionsBulkDeleteDelete(requestParameters.nexusOpsTenant, requestParameters.entityBulkDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisualInspectionsApiApiV1VisualInspectionsByIdsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualInspectionsApi
     */
    public apiV1VisualInspectionsByIdsGet(requestParameters: VisualInspectionsApiApiV1VisualInspectionsByIdsGetRequest, options?: RawAxiosRequestConfig) {
        return VisualInspectionsApiFp(this.configuration).apiV1VisualInspectionsByIdsGet(requestParameters.nexusOpsTenant, requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisualInspectionsApiApiV1VisualInspectionsCountersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualInspectionsApi
     */
    public apiV1VisualInspectionsCountersGet(requestParameters: VisualInspectionsApiApiV1VisualInspectionsCountersGetRequest, options?: RawAxiosRequestConfig) {
        return VisualInspectionsApiFp(this.configuration).apiV1VisualInspectionsCountersGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisualInspectionsApiApiV1VisualInspectionsCreateMetaGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualInspectionsApi
     */
    public apiV1VisualInspectionsCreateMetaGet(requestParameters: VisualInspectionsApiApiV1VisualInspectionsCreateMetaGetRequest, options?: RawAxiosRequestConfig) {
        return VisualInspectionsApiFp(this.configuration).apiV1VisualInspectionsCreateMetaGet(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisualInspectionsApiApiV1VisualInspectionsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualInspectionsApi
     */
    public apiV1VisualInspectionsGetPost(requestParameters: VisualInspectionsApiApiV1VisualInspectionsGetPostRequest, options?: RawAxiosRequestConfig) {
        return VisualInspectionsApiFp(this.configuration).apiV1VisualInspectionsGetPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisualInspectionsApiApiV1VisualInspectionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualInspectionsApi
     */
    public apiV1VisualInspectionsPost(requestParameters: VisualInspectionsApiApiV1VisualInspectionsPostRequest, options?: RawAxiosRequestConfig) {
        return VisualInspectionsApiFp(this.configuration).apiV1VisualInspectionsPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisualInspectionsApiApiV1VisualInspectionsSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualInspectionsApi
     */
    public apiV1VisualInspectionsSearchPost(requestParameters: VisualInspectionsApiApiV1VisualInspectionsSearchPostRequest, options?: RawAxiosRequestConfig) {
        return VisualInspectionsApiFp(this.configuration).apiV1VisualInspectionsSearchPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionSearchPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdAddToContractPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualInspectionsApi
     */
    public apiV1VisualInspectionsVisualInspectionIdAddToContractPost(requestParameters: VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdAddToContractPostRequest, options?: RawAxiosRequestConfig) {
        return VisualInspectionsApiFp(this.configuration).apiV1VisualInspectionsVisualInspectionIdAddToContractPost(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, requestParameters.visualInspectionAddToContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualInspectionsApi
     */
    public apiV1VisualInspectionsVisualInspectionIdDelete(requestParameters: VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return VisualInspectionsApiFp(this.configuration).apiV1VisualInspectionsVisualInspectionIdDelete(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, requestParameters.isDeleteCascade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDownloadPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualInspectionsApi
     */
    public apiV1VisualInspectionsVisualInspectionIdDownloadPdfGet(requestParameters: VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdDownloadPdfGetRequest, options?: RawAxiosRequestConfig) {
        return VisualInspectionsApiFp(this.configuration).apiV1VisualInspectionsVisualInspectionIdDownloadPdfGet(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualInspectionsApi
     */
    public apiV1VisualInspectionsVisualInspectionIdGet(requestParameters: VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdGetRequest, options?: RawAxiosRequestConfig) {
        return VisualInspectionsApiFp(this.configuration).apiV1VisualInspectionsVisualInspectionIdGet(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualInspectionsApi
     */
    public apiV1VisualInspectionsVisualInspectionIdPut(requestParameters: VisualInspectionsApiApiV1VisualInspectionsVisualInspectionIdPutRequest, options?: RawAxiosRequestConfig) {
        return VisualInspectionsApiFp(this.configuration).apiV1VisualInspectionsVisualInspectionIdPut(requestParameters.nexusOpsTenant, requestParameters.visualInspectionId, requestParameters.visualInspectionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WarmupApi - axios parameter creator
 * @export
 */
export const WarmupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Used primarily by CI/CD pipelines to wake up the service instance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WarmupGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/warmup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarmupApi - functional programming interface
 * @export
 */
export const WarmupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarmupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Used primarily by CI/CD pipelines to wake up the service instance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WarmupGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WarmupGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WarmupApi.apiV1WarmupGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WarmupApi - factory interface
 * @export
 */
export const WarmupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarmupApiFp(configuration)
    return {
        /**
         * 
         * @summary Used primarily by CI/CD pipelines to wake up the service instance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WarmupGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1WarmupGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarmupApi - object-oriented interface
 * @export
 * @class WarmupApi
 * @extends {BaseAPI}
 */
export class WarmupApi extends BaseAPI {
    /**
     * 
     * @summary Used primarily by CI/CD pipelines to wake up the service instance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarmupApi
     */
    public apiV1WarmupGet(options?: RawAxiosRequestConfig) {
        return WarmupApiFp(this.configuration).apiV1WarmupGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebPushWebhookApi - axios parameter creator
 * @export
 */
export const WebPushWebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WebPushWebhookDto} [webPushWebhookDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebHookWebPushPost: async (webPushWebhookDto?: WebPushWebhookDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web-hook/web-push`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webPushWebhookDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebPushWebhookApi - functional programming interface
 * @export
 */
export const WebPushWebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebPushWebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WebPushWebhookDto} [webPushWebhookDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WebHookWebPushPost(webPushWebhookDto?: WebPushWebhookDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WebHookWebPushPost(webPushWebhookDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebPushWebhookApi.apiV1WebHookWebPushPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WebPushWebhookApi - factory interface
 * @export
 */
export const WebPushWebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebPushWebhookApiFp(configuration)
    return {
        /**
         * 
         * @param {WebPushWebhookApiApiV1WebHookWebPushPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebHookWebPushPost(requestParameters: WebPushWebhookApiApiV1WebHookWebPushPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1WebHookWebPushPost(requestParameters.webPushWebhookDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1WebHookWebPushPost operation in WebPushWebhookApi.
 * @export
 * @interface WebPushWebhookApiApiV1WebHookWebPushPostRequest
 */
export interface WebPushWebhookApiApiV1WebHookWebPushPostRequest {
    /**
     * 
     * @type {WebPushWebhookDto}
     * @memberof WebPushWebhookApiApiV1WebHookWebPushPost
     */
    readonly webPushWebhookDto?: WebPushWebhookDto
}

/**
 * WebPushWebhookApi - object-oriented interface
 * @export
 * @class WebPushWebhookApi
 * @extends {BaseAPI}
 */
export class WebPushWebhookApi extends BaseAPI {
    /**
     * 
     * @param {WebPushWebhookApiApiV1WebHookWebPushPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebPushWebhookApi
     */
    public apiV1WebHookWebPushPost(requestParameters: WebPushWebhookApiApiV1WebHookWebPushPostRequest = {}, options?: RawAxiosRequestConfig) {
        return WebPushWebhookApiFp(this.configuration).apiV1WebHookWebPushPost(requestParameters.webPushWebhookDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {WebhookActivationGetPaginatedDto} [webhookActivationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksActivationsGetPost: async (nexusOpsTenant: string, webhookActivationGetPaginatedDto?: WebhookActivationGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1WebhooksActivationsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/webhooks/activations/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookActivationGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {WebhookGetPaginatedDto} [webhookGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksGetPost: async (nexusOpsTenant: string, webhookGetPaginatedDto?: WebhookGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1WebhooksGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/webhooks/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateWebhookDto} [createWebhookDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksPost: async (nexusOpsTenant: string, createWebhookDto?: CreateWebhookDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1WebhooksPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/webhooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWebhookDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksTriggerTestPost: async (nexusOpsTenant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1WebhooksTriggerTestPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v1/webhooks/trigger/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksWebhookIdDelete: async (nexusOpsTenant: string, webhookId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1WebhooksWebhookIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('apiV1WebhooksWebhookIdDelete', 'webhookId', webhookId)
            const localVarPath = `/api/v1/webhooks/{webhookId}`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksWebhookIdDisablePut: async (nexusOpsTenant: string, webhookId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1WebhooksWebhookIdDisablePut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('apiV1WebhooksWebhookIdDisablePut', 'webhookId', webhookId)
            const localVarPath = `/api/v1/webhooks/{webhookId}/disable`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksWebhookIdEnablePut: async (nexusOpsTenant: string, webhookId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1WebhooksWebhookIdEnablePut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('apiV1WebhooksWebhookIdEnablePut', 'webhookId', webhookId)
            const localVarPath = `/api/v1/webhooks/{webhookId}/enable`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksWebhookIdGet: async (nexusOpsTenant: string, webhookId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1WebhooksWebhookIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('apiV1WebhooksWebhookIdGet', 'webhookId', webhookId)
            const localVarPath = `/api/v1/webhooks/{webhookId}`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} webhookId 
         * @param {UpdateWebhookDto} [updateWebhookDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksWebhookIdPut: async (nexusOpsTenant: string, webhookId: string, updateWebhookDto?: UpdateWebhookDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV1WebhooksWebhookIdPut', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('apiV1WebhooksWebhookIdPut', 'webhookId', webhookId)
            const localVarPath = `/api/v1/webhooks/{webhookId}`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWebhookDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {WebhookActivationGetPaginatedDto} [webhookActivationGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WebhooksActivationsGetPost(nexusOpsTenant: string, webhookActivationGetPaginatedDto?: WebhookActivationGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfWebhookActivationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WebhooksActivationsGetPost(nexusOpsTenant, webhookActivationGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.apiV1WebhooksActivationsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {WebhookGetPaginatedDto} [webhookGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WebhooksGetPost(nexusOpsTenant: string, webhookGetPaginatedDto?: WebhookGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfWebhookDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WebhooksGetPost(nexusOpsTenant, webhookGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.apiV1WebhooksGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {CreateWebhookDto} [createWebhookDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WebhooksPost(nexusOpsTenant: string, createWebhookDto?: CreateWebhookDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WebhooksPost(nexusOpsTenant, createWebhookDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.apiV1WebhooksPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WebhooksTriggerTestPost(nexusOpsTenant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WebhooksTriggerTestPost(nexusOpsTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.apiV1WebhooksTriggerTestPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WebhooksWebhookIdDelete(nexusOpsTenant: string, webhookId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WebhooksWebhookIdDelete(nexusOpsTenant, webhookId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.apiV1WebhooksWebhookIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WebhooksWebhookIdDisablePut(nexusOpsTenant: string, webhookId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WebhooksWebhookIdDisablePut(nexusOpsTenant, webhookId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.apiV1WebhooksWebhookIdDisablePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WebhooksWebhookIdEnablePut(nexusOpsTenant: string, webhookId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WebhooksWebhookIdEnablePut(nexusOpsTenant, webhookId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.apiV1WebhooksWebhookIdEnablePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WebhooksWebhookIdGet(nexusOpsTenant: string, webhookId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WebhooksWebhookIdGet(nexusOpsTenant, webhookId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.apiV1WebhooksWebhookIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
         * @param {string} webhookId 
         * @param {UpdateWebhookDto} [updateWebhookDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WebhooksWebhookIdPut(nexusOpsTenant: string, webhookId: string, updateWebhookDto?: UpdateWebhookDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WebhooksWebhookIdPut(nexusOpsTenant, webhookId, updateWebhookDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.apiV1WebhooksWebhookIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * 
         * @param {WebhooksApiApiV1WebhooksActivationsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksActivationsGetPost(requestParameters: WebhooksApiApiV1WebhooksActivationsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfWebhookActivationDto> {
            return localVarFp.apiV1WebhooksActivationsGetPost(requestParameters.nexusOpsTenant, requestParameters.webhookActivationGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebhooksApiApiV1WebhooksGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksGetPost(requestParameters: WebhooksApiApiV1WebhooksGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfWebhookDto> {
            return localVarFp.apiV1WebhooksGetPost(requestParameters.nexusOpsTenant, requestParameters.webhookGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebhooksApiApiV1WebhooksPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksPost(requestParameters: WebhooksApiApiV1WebhooksPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<WebhookDto> {
            return localVarFp.apiV1WebhooksPost(requestParameters.nexusOpsTenant, requestParameters.createWebhookDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebhooksApiApiV1WebhooksTriggerTestPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksTriggerTestPost(requestParameters: WebhooksApiApiV1WebhooksTriggerTestPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1WebhooksTriggerTestPost(requestParameters.nexusOpsTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebhooksApiApiV1WebhooksWebhookIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksWebhookIdDelete(requestParameters: WebhooksApiApiV1WebhooksWebhookIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1WebhooksWebhookIdDelete(requestParameters.nexusOpsTenant, requestParameters.webhookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebhooksApiApiV1WebhooksWebhookIdDisablePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksWebhookIdDisablePut(requestParameters: WebhooksApiApiV1WebhooksWebhookIdDisablePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<WebhookDto> {
            return localVarFp.apiV1WebhooksWebhookIdDisablePut(requestParameters.nexusOpsTenant, requestParameters.webhookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebhooksApiApiV1WebhooksWebhookIdEnablePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksWebhookIdEnablePut(requestParameters: WebhooksApiApiV1WebhooksWebhookIdEnablePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<WebhookDto> {
            return localVarFp.apiV1WebhooksWebhookIdEnablePut(requestParameters.nexusOpsTenant, requestParameters.webhookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebhooksApiApiV1WebhooksWebhookIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksWebhookIdGet(requestParameters: WebhooksApiApiV1WebhooksWebhookIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<WebhookDto> {
            return localVarFp.apiV1WebhooksWebhookIdGet(requestParameters.nexusOpsTenant, requestParameters.webhookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebhooksApiApiV1WebhooksWebhookIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WebhooksWebhookIdPut(requestParameters: WebhooksApiApiV1WebhooksWebhookIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<WebhookDto> {
            return localVarFp.apiV1WebhooksWebhookIdPut(requestParameters.nexusOpsTenant, requestParameters.webhookId, requestParameters.updateWebhookDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1WebhooksActivationsGetPost operation in WebhooksApi.
 * @export
 * @interface WebhooksApiApiV1WebhooksActivationsGetPostRequest
 */
export interface WebhooksApiApiV1WebhooksActivationsGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksActivationsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {WebhookActivationGetPaginatedDto}
     * @memberof WebhooksApiApiV1WebhooksActivationsGetPost
     */
    readonly webhookActivationGetPaginatedDto?: WebhookActivationGetPaginatedDto
}

/**
 * Request parameters for apiV1WebhooksGetPost operation in WebhooksApi.
 * @export
 * @interface WebhooksApiApiV1WebhooksGetPostRequest
 */
export interface WebhooksApiApiV1WebhooksGetPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {WebhookGetPaginatedDto}
     * @memberof WebhooksApiApiV1WebhooksGetPost
     */
    readonly webhookGetPaginatedDto?: WebhookGetPaginatedDto
}

/**
 * Request parameters for apiV1WebhooksPost operation in WebhooksApi.
 * @export
 * @interface WebhooksApiApiV1WebhooksPostRequest
 */
export interface WebhooksApiApiV1WebhooksPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {CreateWebhookDto}
     * @memberof WebhooksApiApiV1WebhooksPost
     */
    readonly createWebhookDto?: CreateWebhookDto
}

/**
 * Request parameters for apiV1WebhooksTriggerTestPost operation in WebhooksApi.
 * @export
 * @interface WebhooksApiApiV1WebhooksTriggerTestPostRequest
 */
export interface WebhooksApiApiV1WebhooksTriggerTestPostRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksTriggerTestPost
     */
    readonly nexusOpsTenant: string
}

/**
 * Request parameters for apiV1WebhooksWebhookIdDelete operation in WebhooksApi.
 * @export
 * @interface WebhooksApiApiV1WebhooksWebhookIdDeleteRequest
 */
export interface WebhooksApiApiV1WebhooksWebhookIdDeleteRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksWebhookIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksWebhookIdDelete
     */
    readonly webhookId: string
}

/**
 * Request parameters for apiV1WebhooksWebhookIdDisablePut operation in WebhooksApi.
 * @export
 * @interface WebhooksApiApiV1WebhooksWebhookIdDisablePutRequest
 */
export interface WebhooksApiApiV1WebhooksWebhookIdDisablePutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksWebhookIdDisablePut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksWebhookIdDisablePut
     */
    readonly webhookId: string
}

/**
 * Request parameters for apiV1WebhooksWebhookIdEnablePut operation in WebhooksApi.
 * @export
 * @interface WebhooksApiApiV1WebhooksWebhookIdEnablePutRequest
 */
export interface WebhooksApiApiV1WebhooksWebhookIdEnablePutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksWebhookIdEnablePut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksWebhookIdEnablePut
     */
    readonly webhookId: string
}

/**
 * Request parameters for apiV1WebhooksWebhookIdGet operation in WebhooksApi.
 * @export
 * @interface WebhooksApiApiV1WebhooksWebhookIdGetRequest
 */
export interface WebhooksApiApiV1WebhooksWebhookIdGetRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksWebhookIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksWebhookIdGet
     */
    readonly webhookId: string
}

/**
 * Request parameters for apiV1WebhooksWebhookIdPut operation in WebhooksApi.
 * @export
 * @interface WebhooksApiApiV1WebhooksWebhookIdPutRequest
 */
export interface WebhooksApiApiV1WebhooksWebhookIdPutRequest {
    /**
     *    Tenant identifier, which specifies in a context of what tenant request is made.       When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,    and set tenant identifier header value manually.    For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual    header value on each request.   
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksWebhookIdPut
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof WebhooksApiApiV1WebhooksWebhookIdPut
     */
    readonly webhookId: string

    /**
     * 
     * @type {UpdateWebhookDto}
     * @memberof WebhooksApiApiV1WebhooksWebhookIdPut
     */
    readonly updateWebhookDto?: UpdateWebhookDto
}

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @param {WebhooksApiApiV1WebhooksActivationsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public apiV1WebhooksActivationsGetPost(requestParameters: WebhooksApiApiV1WebhooksActivationsGetPostRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).apiV1WebhooksActivationsGetPost(requestParameters.nexusOpsTenant, requestParameters.webhookActivationGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiApiV1WebhooksGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public apiV1WebhooksGetPost(requestParameters: WebhooksApiApiV1WebhooksGetPostRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).apiV1WebhooksGetPost(requestParameters.nexusOpsTenant, requestParameters.webhookGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiApiV1WebhooksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public apiV1WebhooksPost(requestParameters: WebhooksApiApiV1WebhooksPostRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).apiV1WebhooksPost(requestParameters.nexusOpsTenant, requestParameters.createWebhookDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiApiV1WebhooksTriggerTestPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public apiV1WebhooksTriggerTestPost(requestParameters: WebhooksApiApiV1WebhooksTriggerTestPostRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).apiV1WebhooksTriggerTestPost(requestParameters.nexusOpsTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiApiV1WebhooksWebhookIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public apiV1WebhooksWebhookIdDelete(requestParameters: WebhooksApiApiV1WebhooksWebhookIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).apiV1WebhooksWebhookIdDelete(requestParameters.nexusOpsTenant, requestParameters.webhookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiApiV1WebhooksWebhookIdDisablePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public apiV1WebhooksWebhookIdDisablePut(requestParameters: WebhooksApiApiV1WebhooksWebhookIdDisablePutRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).apiV1WebhooksWebhookIdDisablePut(requestParameters.nexusOpsTenant, requestParameters.webhookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiApiV1WebhooksWebhookIdEnablePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public apiV1WebhooksWebhookIdEnablePut(requestParameters: WebhooksApiApiV1WebhooksWebhookIdEnablePutRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).apiV1WebhooksWebhookIdEnablePut(requestParameters.nexusOpsTenant, requestParameters.webhookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiApiV1WebhooksWebhookIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public apiV1WebhooksWebhookIdGet(requestParameters: WebhooksApiApiV1WebhooksWebhookIdGetRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).apiV1WebhooksWebhookIdGet(requestParameters.nexusOpsTenant, requestParameters.webhookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiApiV1WebhooksWebhookIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public apiV1WebhooksWebhookIdPut(requestParameters: WebhooksApiApiV1WebhooksWebhookIdPutRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).apiV1WebhooksWebhookIdPut(requestParameters.nexusOpsTenant, requestParameters.webhookId, requestParameters.updateWebhookDto, options).then((request) => request(this.axios, this.basePath));
    }
}



