import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import * as Yup from "yup";

import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FormikHelper } from "@/common/helpers/formik";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssetSubscriptionCreateDto,
  AssetSubscriptionDto,
  AssetSubscriptionPlanDto,
  AssetSubscriptionUpdateDto,
  EntityType,
  SpotType,
} from "@/core/api/generated";
import moment from "moment";
import { useState } from "react";
import AppTooltip from "../../AppTooltip";
import FoldableBlock from "../../Display/FoldableBlock";
import EntityDataBlock from "../../EntityData/EntityDataBlock";
import GeneralValidationError from "../../Error/GeneralValidationError";
import AppIcon from "../../Icons/AppIcon";
import AppTypography from "../../Text/AppTypography";
import AssetSubscriptionPlanAutocompleteOrCreate from "../AssetSubscriptionPlan/AssetSubscriptionPlanAutocompleteOrCreate";
import AssetSubscriptionPlanAvailabilityCheck from "../AssetSubscriptionPlan/AssetSubscriptionPlanAvailabilityCheck";
import CustomerAutocompleteOrCreate from "../Customer/CustomerAutocompleteOrCreate";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import ProductLocationAutocomplete from "../ProductLocation/ProductLocationAutocomplete";
import SubscriptionPlanDurationSelect from "../Subscription/common/SubscriptionPlanDurationSelect";
import SubscriptionPlanExtraOptionMultiselect from "../Subscription/common/SubscriptionPlanExtraOptionMultiselect";
import SubscriptionPlanPriceSelect from "../Subscription/common/SubscriptionPlanPriceSelect";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";
import AssetSubscriptionSpotInfoInput from "./AssetSubscriptionSpotInfoInput";

type DefaultValues = {
  customerId?: AssetSubscriptionCreateDto["customerId"];
  plan?: AssetSubscriptionCreateDto["plan"];
  planId?: string | null | undefined;
};

export interface AssetSubscriptionCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<AssetSubscriptionDto, DefaultValues> {
  assetSubscriptionId?: string;
}

export type AssetSubscriptionCreateUpdateProps = AssetSubscriptionCreateUpdateOwnProps;

export default function AssetSubscriptionCreateUpdate({
  assetSubscriptionId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: AssetSubscriptionCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !assetSubscriptionId;
  const isEdit = !!assetSubscriptionId;

  const [isPlanAvailable, setIsPlanAvailable] = useState(false);

  const assetSubscriptionRequest = useApiRequest(
    apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionId: assetSubscriptionId!,
    },
    {
      skip: !assetSubscriptionId,
      deps: [assetSubscriptionId],
    },
  );
  const assetSubscription = assetSubscriptionRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: assetSubscription && {
      idValue: assetSubscription.id!,
      newTitle: assetSubscription.localNumber || "",
    },
  });

  const assetSubscriptionPlanRequest = useApiRequest(
    apiClient.assetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionPlanId: assetSubscription?.plan?.id || defaultValues?.planId || "",
    },
    {
      skip: !assetSubscription?.plan?.id && !defaultValues?.planId,
      deps: [assetSubscription?.plan?.id, defaultValues?.planId],
    },
  );
  const assetSubscriptionPlan = assetSubscriptionPlanRequest?.data;

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.AssetSubscription}
      entityId={assetSubscriptionId}
      entity={assetSubscription}
      entityRequest={assetSubscriptionRequest}
    >
      <Formik<
        AssetSubscriptionCreateDto &
          AssetSubscriptionUpdateDto &
          BaseFormikValues & { planDto?: AssetSubscriptionPlanDto | null }
      >
        enableReinitialize
        initialValues={{
          externalNumber: assetSubscription?.externalNumber || undefined,
          departmentId: assetSubscription?.departmentIds?.at(0) || undefined,
          locationId: assetSubscription?.locationIds?.at(0) || undefined,
          customerId: defaultValues?.customerId || assetSubscription?.customer?.id || undefined,
          plan:
            defaultValues?.plan ||
            (defaultValues?.planId ? { planId: defaultValues.planId } : undefined) ||
            (assetSubscription?.plan && {
              planId: assetSubscription.plan.id,
              priceId: assetSubscription.price?.id || undefined,
              durationId: assetSubscription.duration?.id || undefined,
              extraOptionIds: assetSubscription.extraOptions?.map((x) => x.id!) || undefined,
            }) ||
            undefined,
          planDto: assetSubscriptionPlan || undefined,
          isConfirmAssetAllocation: false,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            const values2: typeof values = {
              ...values,
              plan: {
                ...values.plan,
                checkOutSpotInfo: values.plan?.checkOutSpotInfo?.spotId
                  ? {
                      ...values.plan?.checkOutSpotInfo,
                      date:
                        values.plan?.checkOutSpotInfo?.date &&
                        moment(values.plan?.checkOutSpotInfo?.date)
                          .utc()
                          .format(),
                    }
                  : undefined,
                checkInSpotInfo: values.plan?.checkInSpotInfo?.spotId
                  ? {
                      ...values.plan?.checkInSpotInfo,
                      date:
                        values.plan?.checkInSpotInfo?.date &&
                        moment(values.plan?.checkInSpotInfo?.date)
                          .utc()
                          .format(),
                    }
                  : undefined,
              },
            };

            if (isCreate) {
              const response = await apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                assetSubscriptionCreateDto: {
                  ...values2,
                },
              });
              enqueueSnackbar("Asset subscription created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response =
                await apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdPut(
                  {
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    assetSubscriptionId,
                    assetSubscriptionUpdateDto: {
                      ...values2,
                    },
                  },
                );
              enqueueSnackbar("Asset subscription updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {(formikProps) => {
          const {
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
          } = formikProps;

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                {isCreate && (
                  <Box>
                    {/* Customer */}
                    <FormControl margin='dense' fullWidth>
                      <CustomerAutocompleteOrCreate
                        autocompleteProps={{
                          entityId: values.customerId,
                          isPreload: isCreate,
                          required: true,
                          disabled: isEdit,
                          label: "Customer",
                          textFieldProps: {
                            error: Boolean(errors.customerId),
                            helperText: errors.customerId,
                          },
                          onChange: async (newValue) => {
                            setFieldValue("customerId", newValue?.id);
                          },
                        }}
                        createUpdateProps={{
                          defaultValues: {},
                        }}
                        createFormPlacement='modal'
                        onCreate={async (newValue) => {
                          setFieldValue("customerId", newValue?.id);
                        }}
                      />
                    </FormControl>
                  </Box>
                )}

                {/* Entity affiliation */}
                <EntityAffiliationInput
                  department={{
                    departmentId: values.departmentId,
                    onChange: (d) => {
                      setFieldValue("departmentId", d?.id);
                      setFieldValue("locationId", undefined);
                    },
                    error: errors.departmentId,
                  }}
                  location={{
                    locationId: values.locationId,
                    onChange: (l) => {
                      setFieldValue("locationId", l?.id);
                    },
                    searchFilters: { departmentId: values.departmentId },
                    createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                    disabled: !values.departmentId,
                    error: errors.locationId,
                  }}
                />

                {/* SubscriptionPlan */}
                {isCreate && (
                  <FoldableBlock
                    defaultIsFolded={false}
                    trigger={{
                      label: <Typography variant='subtitle1'>Subscription plan *</Typography>,
                    }}
                  >
                    {/* SubscriptionPlan */}
                    <FormControl margin='dense' fullWidth>
                      <AssetSubscriptionPlanAutocompleteOrCreate
                        autocompleteProps={{
                          entityId: values.plan?.planId,
                          isPreload: isCreate,
                          required: true,
                          disabled: isEdit,
                          label: "Subscription plan",
                          searchFilters: {
                            isEnabled: true,
                          },
                          textFieldProps: {
                            error: Boolean(getIn(errors, "plan.planId")),
                            helperText: getIn(errors, "plan.planId"),
                          },
                          onChange: async (newValue) => {
                            setFieldValue("plan", undefined);
                            setFieldValue("plan.planId", newValue?.id);
                            setFieldValue("planDto", newValue);
                          },
                        }}
                        createUpdateProps={{
                          defaultValues: {},
                        }}
                        createFormPlacement='modal'
                        onCreate={async (newValue) => {
                          setFieldValue("plan", undefined);
                          setFieldValue("plan.planId", newValue?.id);
                          setFieldValue("planDto", newValue);
                        }}
                      />
                      <FormHelperText>Showing only active plans.</FormHelperText>
                    </FormControl>

                    {/* SubscriptionPlan Availability */}
                    <FormControl margin='dense' fullWidth>
                      <AssetSubscriptionPlanAvailabilityCheck
                        assetSubscriptionPlan={values.planDto}
                        onChange={(newValue) => {
                          setIsPlanAvailable(!!newValue && newValue.requestedCount !== 0);
                        }}
                      />
                    </FormControl>

                    {values.plan?.planId && values.planDto && (
                      <Box>
                        {/* ProductLocation & Spots */}
                        <FormControl margin='dense' fullWidth>
                          <EntityDataBlock
                            withDetailsToggle={false}
                            title={
                              <>
                                <AppIcon of='productLocation' inText /> Locations
                              </>
                            }
                            briefContent={
                              <Box>
                                <FormControl margin='dense' fullWidth>
                                  <ProductLocationAutocomplete
                                    entityId={values.plan?.productLocationId}
                                    isPreload
                                    searchFilters={{
                                      ids:
                                        values.planDto?.productLocations?.map(
                                          (x) => x.productLocation?.id || "",
                                        ) || [],
                                    }}
                                    textFieldProps={{
                                      error: Boolean(getIn(errors, "plan.productLocationId")),
                                      helperText: ValidationHelper.getFormikErrorsAsString(
                                        getIn(errors, "plan.productLocationId"),
                                      ),
                                    }}
                                    onChange={(newValue) => {
                                      setFieldValue("plan.productLocationId", newValue?.id);
                                      setFieldValue("plan.checkOutSpotId", undefined);
                                      setFieldValue("plan.checkInSpotId", undefined);
                                    }}
                                  />
                                </FormControl>

                                {values.plan?.productLocationId && (
                                  <Box>
                                    <Box
                                      sx={{
                                        display: "grid",
                                        gridTemplateColumns: {
                                          xxs: `1fr`,
                                          lg: `repeat(2, 1fr)`,
                                        },
                                        gridTemplateRows: "auto",
                                        rowGap: 1,
                                        columnGap: 4,
                                      }}
                                    >
                                      <FoldableBlock
                                        defaultIsFolded={false}
                                        trigger={{
                                          label: (
                                            <Typography variant='subtitle1'>
                                              Check-out spot info
                                            </Typography>
                                          ),
                                        }}
                                      >
                                        <FormHelperText>
                                          If not set, sales location will be used.
                                        </FormHelperText>

                                        <AssetSubscriptionSpotInfoInput
                                          formikProps={FormikHelper.getSubProps(
                                            formikProps,
                                            "plan.checkOutSpotInfo",
                                            (v) => v.plan?.checkOutSpotInfo,
                                          )}
                                          productLocationId={values.plan?.productLocationId}
                                          spotType={SpotType.CheckOut}
                                        />
                                      </FoldableBlock>

                                      <FoldableBlock
                                        defaultIsFolded={false}
                                        trigger={{
                                          label: (
                                            <Typography variant='subtitle1'>
                                              Check-in spot info
                                            </Typography>
                                          ),
                                        }}
                                      >
                                        <FormHelperText>
                                          If not set, sales location will be used.
                                        </FormHelperText>

                                        <AssetSubscriptionSpotInfoInput
                                          formikProps={FormikHelper.getSubProps(
                                            formikProps,
                                            "plan.checkInSpotInfo",
                                            (v) => v.plan?.checkInSpotInfo,
                                          )}
                                          productLocationId={values.plan?.productLocationId}
                                          spotType={SpotType.CheckIn}
                                        />
                                      </FoldableBlock>
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            }
                            detailedContent={undefined}
                          />
                        </FormControl>

                        {/* Price */}
                        <SubscriptionPlanPriceSelect
                          value={values?.plan?.priceId}
                          items={values.planDto?.prices}
                          basePrice={values.planDto?.basePrice}
                          currency={values.planDto?.currency}
                          label='Price'
                          required
                          disabled={!values.planDto || isEdit}
                          fullWidth
                          margin='dense'
                          error={Boolean(getIn(errors, "plan.priceId"))}
                          helperText={getIn(errors, "plan.priceId")}
                          onChange={(newValue) => {
                            setFieldValue("plan.priceId", newValue?.id);
                          }}
                        />

                        {/* Duration */}
                        <SubscriptionPlanDurationSelect
                          value={values?.plan?.durationId}
                          items={values.planDto?.durations}
                          currency={values.planDto?.currency}
                          label='Duration'
                          disabled={!values.planDto || isEdit}
                          fullWidth
                          margin='dense'
                          error={Boolean(getIn(errors, "plan.durationId"))}
                          helperText={getIn(errors, "plan.durationId")}
                          onChange={(newValue) => {
                            setFieldValue("plan.durationId", newValue?.id);
                          }}
                        />

                        {/* ExtraOptions */}
                        <SubscriptionPlanExtraOptionMultiselect
                          values={values?.plan?.extraOptionIds}
                          items={values?.planDto?.extraOptions}
                          currency={values.planDto?.currency}
                          label='Extra options'
                          disabled={!values.planDto || isEdit}
                          fullWidth
                          margin='dense'
                          error={Boolean(getIn(errors, "plan.extraOptionIds"))}
                          helperText={getIn(errors, "plan.extraOptionIds")}
                          renderValueProps={{
                            direction: "column",
                          }}
                          onChange={(newValues) => {
                            setFieldValue("plan.extraOptionIds", newValues?.map((x) => x.id!));
                          }}
                        />
                      </Box>
                    )}
                  </FoldableBlock>
                )}

                <FoldableBlock
                  defaultIsFolded={false}
                  trigger={{
                    label: <Typography variant='subtitle1'>Other</Typography>,
                  }}
                >
                  <FormControl
                    margin='dense'
                    fullWidth
                    disabled={!isCreate}
                    error={Boolean(errors.externalNumber)}
                  >
                    <TextField
                      error={Boolean(errors.externalNumber)}
                      fullWidth
                      helperText={errors.externalNumber}
                      label='External contract number'
                      name='externalNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      inputProps={{ maxLength: 100 }}
                      value={values.externalNumber || ""}
                      variant='outlined'
                    />
                    <FormHelperText>{errors.externalNumber}</FormHelperText>
                  </FormControl>

                  {isCreate && (
                    <FormControl margin='dense' fullWidth>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.isConfirmAssetAllocation}
                            onChange={(e) => {
                              setFieldValue("isConfirmAssetAllocation", e.target.checked);
                            }}
                          />
                        }
                        label={
                          <AppTooltip
                            title={`Confirm allocation of auto-allocated asset from the plan pools. Uncheck if you want to review asset allocation manually. When confirmed you won't be able to reallocate asset no more.`}
                          >
                            <AppTypography
                              decoration={{
                                variant: "helpText",
                              }}
                            >
                              Confirm asset allocation
                            </AppTypography>
                          </AppTooltip>
                        }
                      />
                      {errors.isConfirmAssetAllocation && (
                        <FormHelperText error>
                          {ValidationHelper.getFormikErrorsAsString(
                            errors.isConfirmAssetAllocation,
                          )}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                </FoldableBlock>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <LoadingButton
                  sx={{ mt: { xs: "auto", md: 2 } }}
                  color='primary'
                  disabled={isCreate && !isPlanAvailable}
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
