import { Stack, Typography } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { CustomerDto, EntityType } from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppIconButton from "@/common/components/Button/AppIconButton";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { useState } from "react";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import CustomerContactCard from "../../CustomerContactCard";

interface Props {
  customer: CustomerDto;
  onUpdate?: () => void;
}

export default function OverviewTabContent({ customer, onUpdate }: Props) {
  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={customer} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack direction='column' spacing={1}>
            <FieldValue label='Type'>
              <InlineApiEnumValue type='CustomerType' value={customer?.type} withDescription />
            </FieldValue>

            <FieldValue label='Name'>{customer?.name}</FieldValue>

            <FieldValue label='Address' isEmpty={!customer?.address || customer?.address.isEmpty}>
              <GeneralAddressDisplay address={customer?.address} />
            </FieldValue>

            <FieldValue label='Notes'>{customer?.notes}</FieldValue>
          </Stack>

          {/* Affiliation */}
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography variant='subtitle1'>
              Affiliation{" "}
              <AppIconButton
                tooltipProps={{ title: "Edit entity affiliation" }}
                onClick={() => setIsUpdateAffiliationModalOpened(true)}
              >
                <AppIcon of='edit' fontSize='small' />
              </AppIconButton>
            </Typography>
            <AffiliationInfoDisplay
              withoutTenant
              departmentIds={customer.departmentIds}
              locationIds={customer.locationIds}
              sx={{ mt: 1 }}
            />
            <UpdateEntityAffiliationCascadeModal
              open={isUpdateAffiliationModalOpened}
              onClose={() => setIsUpdateAffiliationModalOpened(false)}
              entityType={EntityType.Customer}
              entityId={customer.id}
              onUpdate={onUpdate}
            />
          </Stack>

          <Stack spacing={1}>
            <Typography component='div' variant='h6'>
              Contacts
            </Typography>
            <Stack spacing={1}>
              {customer?.contacts!.map((contact) => (
                <CustomerContactCard key={contact.id} contact={contact} sx={{ minWidth: 300 }} />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
