import { Button, Typography, TypographyProps } from "@mui/material";

import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DamageDetectionDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
} from "@/core/api/generated";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import VehicleViewPageHeader from "@/common/components/Entity/Vehicle/View/Header/VehicleViewPageHeader";
import AppLink from "@/common/components/Link/AppLink";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useHistory } from "react-router";
import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import BaseEntityView from "../../components/BaseEntityView";
import DamageDetectionMenu from "../DamageDetectionMenu";
import ContractTabContent from "./Tabs/ContractTabContent";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import RelatedOperationsTabContent from "./Tabs/RelatedOperationsTabContent";
import VehicleTabContent from "./Tabs/VehicleTabContent";

export enum DamageDetectionViewPageTabs {
  Overview = "Overview",
  Vehicle = "Vehicle",
  Contract = "Contract",
  RelatedOperations = "RelatedOperations",
  GeneralHistory = "GeneralHistory",
}

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};
const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};
interface Props {
  damageDetectionId: string | null | undefined;
  damageDetection: DamageDetectionDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

export default function DamageDetectionView({
  damageDetectionId,
  damageDetection,
  displayProps,
  headerProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.DamageDetection),
      viewVariant: displayProps?.viewVariant,
    },
  });

  const damageDetectionRequest = useApiRequest(
    apiClient.damageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      damageDetectionId: damageDetectionId!,
    },
    {
      deps: [damageDetectionId],
      skip: !damageDetectionId,
    },
  );
  damageDetection = damageDetection || damageDetectionRequest?.data;

  const visualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: damageDetection?.vehicleVisualModel?.id || "",
    },
    {
      deps: [damageDetection?.vehicleVisualModel?.id],
      skip: !damageDetection?.vehicleVisualModel?.id,
    },
  );
  const visualModel = visualModelRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.DamageDetection,
        damageDetectionId || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      damageDetectionRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: damageDetection && {
      idValue: damageDetection?.id || "",
      newTitle: damageDetection?.localNumber || "",
    },
  });

  return (
    <BaseEntityView
      entityType={EntityType.DamageDetection}
      entityId={damageDetectionId}
      entity={damageDetection}
      entityRequest={damageDetectionRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title={
              damageDetection?.vehicle && (
                <VehicleViewPageHeader
                  vehicleId={damageDetection?.vehicle?.id || ""}
                  vehicle={damageDetection?.vehicle || undefined}
                  withLink
                  primaryActions={
                    damageDetection?.contract?.localNumber && (
                      <Button
                        variant='outlined'
                        color='secondary'
                        sx={{ ml: "auto" }}
                        component={AppLink}
                        to={ROUTE_PATH.CONTRACT_VIEW(damageDetection?.contract.id)}
                      >
                        <AppIcon of='documents' />
                        <Typography mr={1} ml={1}>
                          Contract
                        </Typography>
                        <Typography>{damageDetection?.contract?.localNumber}</Typography>
                      </Button>
                    )
                  }
                  tags={
                    <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                      <GeneralAttachedTagsDisplay
                        tags={damageDetection?.tags}
                        defaultIsFolded={false}
                        edit={{
                          entityType: TagEntityType.DamageDetection,
                          entityId: damageDetection?.id,
                          onSaved: (newValue) => {
                            damageDetectionRequest.updateData((x) => {
                              x.tags = newValue || undefined;
                            });
                          },
                        }}
                      />
                    </AuthorizedElement>
                  }
                />
              )
            }
            primaryActions={
              <DamageDetectionMenu
                entity={damageDetection}
                onDelete={() => history.goBack()}
                onUpdate={(newValue) =>
                  newValue
                    ? damageDetectionRequest.replaceData(newValue)
                    : damageDetectionRequest.refetch()
                }
              />
            }
          />
        }
      >
        <PageTabs
          tabIdsDefinition={DamageDetectionViewPageTabs}
          defaultTabId={DamageDetectionViewPageTabs.Overview}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: DamageDetectionViewPageTabs.Overview, isHideable: false },
            { label: "Vehicle", value: DamageDetectionViewPageTabs.Vehicle },
            ...(damageDetection?.contract?.id
              ? [
                  {
                    label: "Contract",
                    value: DamageDetectionViewPageTabs.Contract,
                  },
                ]
              : []),
            { label: "Related operations", value: DamageDetectionViewPageTabs.RelatedOperations },
            { label: "History", value: DamageDetectionViewPageTabs.GeneralHistory },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            damageDetection && (
              <>
                {activeTab === DamageDetectionViewPageTabs.Overview && (
                  <OverviewTabContent
                    onUpdate={() => damageDetectionRequest.refetch()}
                    damageDetection={damageDetection}
                  />
                )}
                {activeTab === DamageDetectionViewPageTabs.Vehicle && (
                  <VehicleTabContent damageDetection={damageDetection} />
                )}
                {activeTab === DamageDetectionViewPageTabs.Contract && (
                  <ContractTabContent damageDetection={damageDetection} />
                )}
                {activeTab === DamageDetectionViewPageTabs.RelatedOperations && (
                  <RelatedOperationsTabContent damageDetection={damageDetection} />
                )}
                {activeTab === DamageDetectionViewPageTabs.GeneralHistory && (
                  <GeneralHistoryTabContent damageDetection={damageDetection} />
                )}
              </>
            )
          }
        </PageTabs>
      </ViewLayout>
    </BaseEntityView>
  );
}
