import {
  EntityChangeType,
  EntityChangedDtoOfTEntityDto,
  IBaseEntityDto,
} from "@/core/api/generated";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import _, { DebounceSettings, ThrottleSettings } from "lodash";
import moment, { Moment } from "moment";
import { DependencyList, useCallback, useEffect, useMemo, useRef } from "react";
import { useEffectWithDeepCompare } from "../effect/useEffectWithDeepCompare";
import { useValueMemoWithDeepCompare } from "../memo/useValueMemoWithDeepCompare";
import { useUnmountEffect } from "../mount/useUnmountEffect";
import { useTriggerRender } from "../render/useTriggerRender";
import { ShortPolling, ShortPollingOptions } from "./shortPolling";

import { ArrayHelper } from "@/common/helpers/array";
import { PaginationDtoOfTItemTyped } from "@/common/ts/pagination";
import { UseCommonRequestParamsHookResult } from "./useCommonRequestParams";

type DebounceOptions = { wait: number | undefined; options?: DebounceSettings };
type ThrottleOptions = { wait: number | undefined; options?: ThrottleSettings };

/** HTTP response details. */
export type UseApiRequestResponseDetails = {
  isOk: boolean;
  isBadRequest: boolean;
  isUnauthorized: boolean;
  isForbidden: boolean;
  isNotFound: boolean;
  isInternalServerError: boolean;
};

export type UseApiRequestBaseResult = {
  /** Request is in process. */
  isLoading: boolean;
  /** Request is started. Set only once, for the first request. */
  isStarted: boolean;
  /** Request is ended (success or error). Set only once, for the first request. */
  isEnded: boolean;
  /** HTTP response details. */
  responseDetails?: UseApiRequestResponseDetails;
  error?: any;
};

export type UseApiRequestResult<
  TApiRequestFunc extends (...args: any) => Promise<AxiosResponse>,
  TRequestParams extends Parameters<TApiRequestFunc>[0],
  TRequestOptions extends AxiosRequestConfig,
  TResponse extends Awaited<ReturnType<TApiRequestFunc>>,
  TData extends Awaited<ReturnType<TApiRequestFunc>>["data"],
  // TRequestParams = Parameters<TApiRequestFunc>[0],
> = {
  /** Request is in process. */
  isLoading: boolean;
  /** Request is started. Set only once, for the first request. */
  isStarted: boolean;
  /** Request is ended (success or error). Set only once, for the first request. */
  isEnded: boolean;
  /** Current request params. */
  requestParams: TRequestParams | null;
  /** HTTP response. */
  response?: TResponse | null;
  /** HTTP response details. */
  responseDetails?: UseApiRequestResponseDetails;
  /** Data from HTTP response body. */
  data: TData | undefined | null;
  error: any | undefined;
  /** Refetches data with current params. Ignores skip option. */
  refetch: () => Promise<TData | undefined | null>;
  /** Refetches data with new params. Ignores skip option. */
  refetchWithNewParams: (
    newRequestParams: Partial<TRequestParams>,
  ) => Promise<TData | undefined | null>;
  /** Locally replaces fetched data. */
  replaceData: (newData: TData | undefined | null) => void;
  /** Locally update fetched data using provided func. */
  updateData: (updateFunc: (fetchedData: TData) => void) => void;
  /** Figures out and automatically applies updates locally. */
  handleEntityChanged: (dto: EntityChangedDtoOfTEntityDto | undefined | null) => void;
  /** Resets to initial state (like if no requests has been sent). */
  reset: () => void;
};

/** Hook for provided API request. */
export function useApiRequest<
  TApiRequestFunc extends (...args: any) => Promise<AxiosResponse>,
  TRequestParams extends Parameters<TApiRequestFunc>[0],
  TRequestOptions extends AxiosRequestConfig,
  TResponse extends Awaited<ReturnType<TApiRequestFunc>>,
  TData extends Awaited<ReturnType<TApiRequestFunc>>["data"],
>(
  apiRequestFunc: TApiRequestFunc,
  /** API endpoint params.
   *  By default data is refetched when params change.
   *  You can change this behavior by providing custom deps. */
  requestParams: Parameters<TApiRequestFunc>[0],
  options?: {
    /** Additional request options. */
    requestOptions?: TRequestOptions;
    /** Custom list of deps. When changed data is refetched. */
    deps?: DependencyList;
    /** List of deps for which debounce is applied. */
    debouncedDeps?: {
      deps?: DependencyList;
      /** If true/false, then skip for main request is ignored.
       *  If undefined, main requests skip is used.
       */
      skip?: boolean;
    } & DebounceOptions;
    /** List of deps for which throttle is applied. */
    throttledDeps?: {
      deps?: DependencyList;
      /** If true/false, then skip for main request is ignored.
       *  If undefined, main requests skip is used.
       */
      skip?: boolean;
    } & ThrottleOptions;
    /** Debounce options for main request. */
    debounce?: DebounceOptions;
    /** Throttle options for main request. */
    throttle?: ThrottleOptions;
    /** For main request. When set, request is not sent (skipped) if condition is true. Doesn't apply to refetch. */
    skip?: boolean;
    /** Configures Short Polling which will poll server (send request) after every time interval. */
    shortPolling?: ShortPollingOptions<TData>;
    /** Integration with useRequestParams() hook. */
    commonRequestParams?: UseCommonRequestParamsHookResult<any>; // use any as we don't know and don't care about the custom request params type stored inside
  },
): UseApiRequestResult<TApiRequestFunc, TRequestParams, TRequestOptions, TResponse, TData> {
  const { triggerRender } = useTriggerRender();

  // memorize value to avoid issue with anonymous objects equality
  // (ensure the same object is not treated as a new value)
  const isRequestStartedRef = useRef(false);
  const requestCountRef = useRef(0);
  const firstRequestStartedAtRef = useRef<Moment | null>(null);
  const lastRequestStartedAtRef = useRef<Moment | null>(null);
  const requestParamsMemorizedRef = useRef<TRequestParams>(requestParams);
  const responseRef = useRef<TResponse | undefined | null>(undefined);
  const dataRef = useRef<TData | undefined | null>(undefined);
  const errorRef = useRef<any | undefined | null>(undefined);
  const isLoadingRef = useRef<boolean>(false);
  const isStartedRef = useRef<boolean>(false);
  const isEndedRef = useRef<boolean>(false);
  const isAnyRequestStartedRef = useRef<boolean>(false);
  const shortPollingRef = useRef<ShortPolling<TData> | null>(null);

  const optionsMemorized = useValueMemoWithDeepCompare(options);
  const depsMemorized = useValueMemoWithDeepCompare(options?.deps || []);
  const debouncedDepsMemorized = useValueMemoWithDeepCompare(options?.debouncedDeps?.deps);
  const throttledDepsMemorized = useValueMemoWithDeepCompare(options?.throttledDeps?.deps);
  const fetchDepsMemorized = useMemo(
    () => (!_.isNil(options?.deps) ? depsMemorized : requestParamsMemorizedRef.current),
    [options?.deps, depsMemorized, requestParamsMemorizedRef.current],
  );
  const responseDetailsComputed = useMemo<UseApiRequestResponseDetails | undefined>(
    () =>
      responseRef.current
        ? {
            isOk: responseRef.current.status >= 200 && responseRef.current.status <= 299,
            isBadRequest: responseRef.current.status === 400,
            isUnauthorized: responseRef.current.status === 401,
            isForbidden: responseRef.current.status === 403,
            isNotFound: responseRef.current.status === 404,
            isInternalServerError: responseRef.current.status === 500,
          }
        : undefined,
    [responseRef.current],
  );

  const elapsedMs = firstRequestStartedAtRef.current
    ? moment().diff(firstRequestStartedAtRef.current, "milliseconds")
    : 0;

  // track request params change
  useEffectWithDeepCompare(() => {
    requestParamsMemorizedRef.current = requestParams;
  }, [requestParams]);
  useEffect(() => {
    isAnyRequestStartedRef.current = !_.isNil(firstRequestStartedAtRef.current);
  }, [firstRequestStartedAtRef.current]);

  // #region Handle short polling

  const shortPollingIfDep =
    !_.isNil(options?.shortPolling?.if) && _.isBoolean(options?.shortPolling?.if)
      ? options?.shortPolling?.if
      : null;

  useEffectWithDeepCompare(() => {
    const newShortPolling = options?.shortPolling
      ? new ShortPolling<TData>(options?.shortPolling)
      : null;
    if (shortPollingRef.current) {
      shortPollingRef.current.end();
    }
    shortPollingRef.current = newShortPolling;
    shortPollingRef.current?.start();
  }, [options?.shortPolling?.intervalMs, shortPollingIfDep]);

  useEffect(() => {
    shortPollingRef.current?.setData(dataRef.current || null);
  }, [dataRef.current]);

  useUnmountEffect(() => {
    shortPollingRef.current?.end();
  }, []);

  // #endregion

  const handleRequestSucceeded = useCallback(() => {
    if (options?.commonRequestParams) {
      const paginated = dataRef.current as PaginationDtoOfTItemTyped<TData>;
      if (!_.isNil(paginated?.pagination?.totalCount)) {
        options.commonRequestParams.setPaginationInfo(paginated.pagination);
      }
    }
  }, [options?.commonRequestParams]);

  const apiRequestFuncComputed = useCallback(
    (requestParams2: TRequestParams) => {
      const func: TApiRequestFunc =
        (optionsMemorized?.debounce &&
          (_.debounce(
            apiRequestFunc,
            optionsMemorized.debounce.wait,
            optionsMemorized.debounce.options,
          ) as unknown as TApiRequestFunc)) ||
        (optionsMemorized?.throttle &&
          (_.throttle(
            apiRequestFunc,
            optionsMemorized.throttle.wait,
            optionsMemorized.throttle.options,
          ) as unknown as TApiRequestFunc)) ||
        (_.throttle(apiRequestFunc, 500, {
          leading: true,
          trailing: false,
        }) as unknown as TApiRequestFunc);

      return func(requestParams2, optionsMemorized?.requestOptions);
    },
    [optionsMemorized?.debounce, optionsMemorized?.throttle],
  );

  const executeRequest = useCallback(
    async (
      requestParams2: TRequestParams,
      requestOptions?: { isPollRequest?: boolean },
    ): Promise<TData | null> => {
      isRequestStartedRef.current = true;
      isLoadingRef.current = true;
      if (!isStartedRef.current) {
        isStartedRef.current = true;
      }
      if (!firstRequestStartedAtRef.current) {
        firstRequestStartedAtRef.current = moment();
      }
      lastRequestStartedAtRef.current = moment();
      triggerRender(); // notify caller
      if (!requestOptions?.isPollRequest) {
        shortPollingRef.current?.end();
      }

      try {
        const response2 = await apiRequestFuncComputed(requestParams2);
        const newResponse = response2 as TResponse;
        const newData = (response2 as TResponse).data as TData;
        responseRef.current = newResponse;
        dataRef.current = newData;
        errorRef.current = undefined;
        handleRequestSucceeded();
        return newData;
      } catch (err) {
        responseRef.current =
          err instanceof AxiosError ? ((err as AxiosError)?.response as TResponse) || null : null;
        dataRef.current = undefined;
        errorRef.current = err;
      } finally {
        isRequestStartedRef.current = false;
        isLoadingRef.current = false;
        isEndedRef.current = true;
        requestCountRef.current += 1;
        triggerRender();
        if (!requestOptions?.isPollRequest) {
          shortPollingRef.current?.start();
        }
      }

      return null;
    },
    [apiRequestFuncComputed],
  );

  const executeRequestForDebouncedDeps = useCallback(
    _.debounce(
      executeRequest,
      optionsMemorized?.debouncedDeps?.wait,
      optionsMemorized?.debouncedDeps?.options,
    ),
    [executeRequest],
  );

  const executeRequestForThrottledDeps = useCallback(
    _.throttle(
      executeRequest,
      optionsMemorized?.throttledDeps?.wait,
      optionsMemorized?.throttledDeps?.options,
    ),
    [executeRequest],
  );

  const refetch = useCallback(async () => {
    if (isRequestStartedRef.current === true) {
      return undefined;
    }
    return await executeRequest(requestParamsMemorizedRef.current);
  }, [executeRequest]);

  const refetchWithNewParams = useCallback(
    async (newRequestParams: Partial<TRequestParams>) => {
      requestParamsMemorizedRef.current = {
        ...requestParamsMemorizedRef.current,
        ...newRequestParams,
      };
      return await executeRequest(requestParamsMemorizedRef.current);
    },
    [executeRequest],
  );

  const replaceData = useCallback((newData?: TData | null) => {
    if (!_.isNil(newData)) {
      errorRef.current = undefined;
    }
    responseRef.current = undefined;
    dataRef.current = _.cloneDeep(newData);
    triggerRender();
  }, []);

  const updateData = useCallback((updateFunc: (fetchedData: TData) => void) => {
    if (!_.isNil(dataRef.current)) {
      const newData = _.cloneDeep(dataRef.current);
      updateFunc(newData);
      dataRef.current = newData;
      triggerRender();
    }
  }, []);

  const handleEntityChanged = useCallback(
    (dto: EntityChangedDtoOfTEntityDto | null | undefined) => {
      if (!dto || _.isNil(dataRef.current)) {
        return;
      }

      const entity = dto.entity as IBaseEntityDto | null | undefined;
      const entityId = dto.entityId;

      // handle few scenarios of current data:
      // 1. Array
      // 2. PaginatedDto
      // 3. Single object
      const oldArray = dataRef.current as Array<IBaseEntityDto>;
      const oldPaginated = dataRef.current as PaginationDtoOfTItemTyped<IBaseEntityDto>;
      const oldObj = dataRef.current as IBaseEntityDto;

      const isArray = _.isArray(dataRef.current);
      const isPaginated =
        _.isObject(dataRef.current) &&
        !_.isNil(oldPaginated.items) &&
        !_.isNil(oldPaginated.pagination);
      const isObject = !isArray && !isPaginated && _.isObject(dataRef.current);

      if (dto.changeType === EntityChangeType.Created) {
        // always refetch as we don't know if it's right to add new item locally and do not break the view
        refetch();
      } else if (dto.changeType === EntityChangeType.Updated) {
        // if entity is present then update locally, otherwise refetch
        if (entity && !_.isEmpty(entity.id)) {
          if (isArray && oldArray) {
            updateData((data2) => {
              ArrayHelper.replaceByPredicate(
                data2 as Array<IBaseEntityDto>,
                (x) => !_.isEmpty(x.id) && x.id === entity.id,
                entity,
              );
            });
          } else if (isPaginated && oldPaginated) {
            updateData((data2) => {
              const oldPaginated2 = data2 as PaginationDtoOfTItemTyped<IBaseEntityDto>;
              ArrayHelper.replaceByPredicate(
                oldPaginated2.items,
                (x) => !_.isEmpty(x.id) && x.id === entity.id,
                entity,
              );
            });
          } else if (isObject && oldObj) {
            replaceData(entity as TData);
          }
        } else {
          refetch();
        }
      } else if (dto.changeType === EntityChangeType.Deleted) {
        // if entityId is present then update locally, otherwise refetch
        if (!_.isEmpty(entityId)) {
          if (isArray && oldArray) {
            updateData((data2) => {
              ArrayHelper.removeByPredicate(
                data2 as Array<IBaseEntityDto>,
                (x) => !_.isEmpty(x.id) && x.id === entityId,
              );
            });
          } else if (isPaginated && oldPaginated) {
            updateData((data2) => {
              const oldPaginated2 = data2 as PaginationDtoOfTItemTyped<IBaseEntityDto>;
              ArrayHelper.removeByPredicate(
                oldPaginated2.items,
                (x) => !_.isEmpty(x.id) && x.id === entityId,
              );
            });
          } else if (isObject && oldObj) {
            replaceData(null);
          }
        } else {
          refetch();
        }
      }
    },
    [],
  );

  const reset = useCallback(() => {
    isRequestStartedRef.current = false;
    requestCountRef.current = 0;
    firstRequestStartedAtRef.current = null;
    lastRequestStartedAtRef.current = null;
    responseRef.current = null;
    dataRef.current = null;
    errorRef.current = null;
    isLoadingRef.current = false;
    isStartedRef.current = false;
    isEndedRef.current = false;
    isAnyRequestStartedRef.current = false;
  }, []);

  // refetch data when params change
  useEffect(() => {
    (async () => {
      if (isRequestStartedRef.current === true || optionsMemorized?.skip === true) {
        return;
      }

      await executeRequest(requestParamsMemorizedRef.current);
    })();
  }, [fetchDepsMemorized, optionsMemorized?.skip]);

  // refetch data for debounced deps
  useEffect(() => {
    (async () => {
      const skip = _.isNil(optionsMemorized?.debouncedDeps?.skip)
        ? optionsMemorized?.skip === true
        : optionsMemorized?.debouncedDeps?.skip === true;
      if (isRequestStartedRef.current === true || skip || _.isNil(debouncedDepsMemorized)) {
        return;
      }

      await executeRequestForDebouncedDeps(requestParamsMemorizedRef.current);
    })();
  }, [debouncedDepsMemorized]);

  // refetch data for throttled deps
  useEffect(() => {
    (async () => {
      const skip = _.isNil(optionsMemorized?.throttledDeps?.skip)
        ? optionsMemorized?.skip === true
        : optionsMemorized?.throttledDeps?.skip === true;
      if (isRequestStartedRef.current === true || skip || _.isNil(throttledDepsMemorized)) {
        return;
      }

      await executeRequestForThrottledDeps(requestParamsMemorizedRef.current);
    })();
  }, [throttledDepsMemorized]);

  // #region Handle short polling

  useEffect(() => {
    shortPollingRef.current?.setRequestFunc(async () => {
      await executeRequest(requestParamsMemorizedRef.current, {
        isPollRequest: true,
      });
    });
  }, [executeRequest]);

  // #endregion

  //#region Result

  // always return the same instance of the result object (same reference),
  // to allow callers to capture the result once and access the same instance regardless of re-renders.

  const computeResult = (): UseApiRequestResult<
    TApiRequestFunc,
    TRequestParams,
    TRequestOptions,
    TResponse,
    TData
  > => ({
    isLoading: isLoadingRef.current,
    isStarted: isStartedRef.current,
    isEnded: isEndedRef.current,
    requestParams: requestParamsMemorizedRef.current,
    response: responseRef.current,
    responseDetails: responseDetailsComputed,
    data: dataRef.current,
    error: errorRef.current,
    refetch,
    refetchWithNewParams,
    replaceData,
    updateData,
    handleEntityChanged,
    reset,
  });

  const initialResult = useMemo(() => computeResult(), []);
  const result = useRef(initialResult);
  Object.assign(result.current, computeResult());

  //#endregion

  return result.current;
}
