import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { Formik } from "formik";
import moment, { Moment } from "moment";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AccessoryCheckDto,
  AccessoryReferenceDto,
  AccessoryStatus,
  ContractStage,
  CreateAccessoryCheckDto,
  CreateAccessoryCheckItemDto,
  EntityType,
  UpdateAccessoryCheckDto,
  UpdateAccessoryCheckItemDto,
  VehicleAccessoriesDto,
} from "@/core/api/generated";

import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { TypeHelper } from "@/common/helpers/type";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { BaseFormikValues } from "@/common/ts/error";
import _ from "lodash";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import FormikComputedField from "../../Form/Formik/FormikComputedField";
import PerformantTextFieldForFormikUnstable from "../../Form/Input/PerformantTextFieldForFormikUnstable";
import AppIcon from "../../Icons/AppIcon";
import AppLink from "../../Link/AppLink";
import AppModal from "../../Modals/AppModal";
import AppModalTitle from "../../Modals/AppModalTitle";
import ContractAutocomplete from "../Contract/ContractAutocomplete";
import ContractAutocompleteOrCreate from "../Contract/ContractAutocompleteOrCreate";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type AccessoryCheckItemInputDtoLocal = CreateAccessoryCheckItemDto &
  UpdateAccessoryCheckItemDto & {
    accessoryRef?: AccessoryReferenceDto;
    initialAttachments?: AccessoryCheckDto["attachments"];
  };

function mapVehicleAccessoriesToAccessoryCheckItemInput(
  vehicleAccessories: VehicleAccessoriesDto | undefined | null,
): AccessoryCheckItemInputDtoLocal[] | undefined {
  return vehicleAccessories?.accessories?.map(
    (x) =>
      ({
        id: undefined,
        accessoryId: x.id,
        contractId: vehicleAccessories?.contract?.id,
        status: undefined,
        initialAttachments: undefined,
        attachments: undefined,
        accessoryRef: x,
      }) as AccessoryCheckItemInputDtoLocal,
  );
}

type DefaultValues = {
  vehicleId?: CreateAccessoryCheckDto["vehicleId"];
  contractId?: CreateAccessoryCheckDto["contractId"];
  tenantRequestsMeta?: CreateAccessoryCheckDto["tenantRequestsMeta"];
};

export interface AccessoryCheckCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<AccessoryCheckDto, DefaultValues> {
  accessoryCheckId?: string;
}

export type AccessoryCheckCreateUpdateProps = AccessoryCheckCreateUpdateOwnProps;

export default function AccessoryCheckCreateUpdate({
  accessoryCheckId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: AccessoryCheckCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();
  const history = useHistory();

  const isCreate = !accessoryCheckId;
  const isEdit = !!accessoryCheckId;

  const [contractModalOpen, setContractModalOpen] = useState(false);
  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);

  const accessoryCheckRequest = useApiRequest(
    apiClient.accessoryChecksApi.apiV1AccessoryChecksAccessoryCheckIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      accessoryCheckId: accessoryCheckId!,
    },
    {
      deps: [accessoryCheckId],
      skip: !accessoryCheckId,
    },
  );
  const accessoryCheck = accessoryCheckRequest.data;

  const vehicleIdComputed = defaultValues?.vehicleId || accessoryCheck?.vehicle?.id;
  const contractIdComputed = defaultValues?.contractId || accessoryCheck?.contract?.id;

  const vehicleAccessoriesRequest = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesVehicleIdAccessoriesGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicleIdComputed!,
      contractId: contractIdComputed || undefined,
    },
    {
      deps: [vehicleIdComputed, contractIdComputed],
      skip: !vehicleIdComputed,
    },
  );
  const vehicleAccessories = vehicleAccessoriesRequest.data;

  const { departments, locations } = useUserAffiliation();

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: accessoryCheck && {
      idValue: accessoryCheck.id!,
      newTitle: accessoryCheck.localNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.AccessoryCheck}
      entityId={accessoryCheckId}
      entity={accessoryCheck}
      entityRequest={accessoryCheckRequest}
    >
      <Formik<
        BaseFormikValues &
          Omit<CreateAccessoryCheckDto, "items"> &
          Omit<UpdateAccessoryCheckDto, "items"> & {
            initialAttachments: AccessoryCheckDto["attachments"];
            items: AccessoryCheckItemInputDtoLocal[] | undefined;
            submit: string;
          }
      >
        enableReinitialize={true}
        initialValues={{
          vehicleId: accessoryCheck?.vehicle?.id || defaultValues?.vehicleId || undefined,
          contractId: accessoryCheck?.contract?.id || defaultValues?.contractId || undefined,
          departmentId:
            (accessoryCheck?.departmentIds || [])?.at(0) ||
            (departments && departments[0] && departments[0].id) ||
            undefined,
          locationId:
            (accessoryCheck?.locationIds || [])?.at(0) ||
            (locations && locations[0] && locations[0].id) ||
            undefined,
          inspectedAt:
            accessoryCheck?.inspectedAt || moment().format(DATETIME_FORMATS.DISPLAY_DATETIME),
          inspector: {
            ...(accessoryCheck?.inspector || {}),
            isCurrentUser: true,
            phoneNumber: accessoryCheck?.inspector?.phoneNumber,
          },
          notes: accessoryCheck?.notes || undefined,
          initialAttachments: accessoryCheck?.attachments || undefined,
          attachments:
            accessoryCheck?.attachments && accessoryCheck.attachments.length > 0
              ? accessoryCheck.attachments
              : undefined,
          items: isCreate
            ? undefined
            : (accessoryCheck?.items || []).map((x) => ({
                ...x,
                id: x.id,
                accessoryId: x.accessory?.id,
                contractId: x?.contractId,
                status: x.status,
                initialAttachments: x?.attachments || undefined,
                attachments: x.attachments && x.attachments.length > 0 ? x.attachments : undefined,
                accessoryRef: x.accessory,
              })),
          tenantRequestsMeta:
            accessoryCheck?.tenantRequestsMeta || defaultValues?.tenantRequestsMeta || undefined,
          tags: accessoryCheck?.tags || undefined,

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
          // type: Yup.string().required("Type is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = await apiClient.accessoryChecksApi.apiV1AccessoryChecksPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                createAccessoryCheckDto: {
                  ..._.omit(values, ["submit"]),
                  inspectedAt: values.inspectedAt
                    ? moment(values.inspectedAt).utc().format()
                    : undefined,
                },
              });
              enqueueSnackbar("Accessory check created.", { variant: "success" });
              onCreate && (await onCreate(response.data));
              onSave && (await onSave(response.data));
            } else {
              const response =
                await apiClient.accessoryChecksApi.apiV1AccessoryChecksAccessoryCheckIdPut({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  accessoryCheckId,
                  updateAccessoryCheckDto: {
                    ..._.omit(values, ["submit"]),
                    inspectedAt: values.inspectedAt
                      ? moment(values.inspectedAt).utc().format()
                      : undefined,
                  },
                });
              enqueueSnackbar("Accessory check updated.", { variant: "success" });
              onUpdate && (await onUpdate(response.data));
              onSave && (await onSave(response.data));
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
          setFieldTouched,
          setFieldError,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <FormikComputedField<
                typeof values,
                Pick<typeof values, "departmentId" | "locationId">
              >
                deps={[values?.vehicleId]}
                compute={async (v) => {
                  const vehicleResponse = v.vehicleId
                    ? await apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet({
                        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                        vehicleId: v.vehicleId!,
                      })
                    : null;
                  return {
                    departmentId: !values.departmentId
                      ? vehicleResponse?.data?.departmentIds?.at(0)
                      : undefined,
                    locationId: !values.locationId
                      ? vehicleResponse?.data?.locationIds?.at(0)
                      : undefined,
                  };
                }}
                onComputed={(computed) => {
                  setFieldValue("departmentId", computed.departmentId);
                  setFieldValue("locationId", computed.locationId);
                }}
              />
              {/* Compute items based on vehicle */}
              <FormikComputedField<typeof values, Pick<typeof values, "items">>
                isEnabled={isCreate}
                deps={[values.vehicleId, values.contractId]}
                compute={async (v) => {
                  if (v.vehicleId) {
                    const newVehicleAccessories =
                      await vehicleAccessoriesRequest.refetchWithNewParams({
                        ...vehicleAccessoriesRequest.requestParams,
                        vehicleId: v.vehicleId,
                        contractId: v.contractId || undefined,
                      });
                    return {
                      items: mapVehicleAccessoriesToAccessoryCheckItemInput(newVehicleAccessories),
                    };
                  } else {
                    return {
                      items: undefined,
                    };
                  }
                }}
                onComputed={(newValue) => {
                  setFieldValue("items", newValue.items);
                }}
              />

              <Box>
                {/* Tags */}
                <FormControl margin='dense' fullWidth>
                  <GeneralAttachedTagsInput
                    value={values.tags}
                    onChange={(newValue) => {
                      setFieldValue("tags", newValue);
                    }}
                  />
                  <FormHelperText error>
                    {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                      isIncludeNested: false,
                    })}
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box sx={{ mt: 1 }}>
                {!isCreate && (
                  <TextField
                    fullWidth
                    disabled
                    label='Inspection number'
                    margin='dense'
                    type='text'
                    value={accessoryCheck?.localNumber}
                    variant='outlined'
                  />
                )}

                <FormControl
                  margin='dense'
                  fullWidth
                  error={Boolean(touched.vehicleId && errors.vehicleId)}
                >
                  <VehicleAutocompleteOrCreate
                    autocompleteProps={{
                      required: true,
                      disabled: !isCreate || !_.isEmpty(vehicleIdComputed),
                      entityId: values.vehicleId,
                      isPreload: isCreate,
                      onChange: async (newValue) => {
                        setFieldValue(`vehicleId`, newValue?.id);
                        setFieldValue(`contractId`, null);
                      },
                    }}
                    createFormPlacement='modal'
                    onCreate={async (newValue) => {
                      setFieldValue(`vehicleId`, newValue?.id);
                      setFieldValue(`contractId`, null);
                    }}
                  />
                  <FormHelperText>{touched.vehicleId && errors.vehicleId}</FormHelperText>
                </FormControl>

                {/* Entity affiliation */}
                <EntityAffiliationInput
                  department={{
                    departmentId: values.departmentId,
                    onChange: (d) => {
                      setFieldValue("departmentId", d?.id);
                      setFieldValue("locationId", undefined);
                    },
                    error: errors.departmentId,
                  }}
                  location={{
                    locationId: values.locationId,
                    onChange: (l) => {
                      setFieldValue("locationId", l?.id);
                    },
                    searchFilters: { departmentId: values.departmentId },
                    createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                    error: errors.locationId,
                    disabled: !values.departmentId,
                  }}
                />

                {values.contractId && (
                  <FormControl margin='dense' fullWidth error={Boolean(errors.contractId)}>
                    <ContractAutocompleteOrCreate
                      autocompleteProps={{
                        disabled: true,
                        entityId: values.contractId,
                        isPreload: isCreate,
                        searchFilters: {
                          vehicleId: values.vehicleId || undefined,
                          excludeStage: ContractStage.Draft,
                        },
                        onChange: (newValue) => {
                          setFieldValue(`contractId`, newValue?.id);
                          setFieldValue(`contract`, newValue || undefined);
                        },
                      }}
                      createFormPlacement='modal'
                      onCreate={(newValue) => {
                        setFieldValue(`contractId`, newValue?.id);
                        setFieldValue(`contract`, newValue || undefined);
                      }}
                    />
                    <FormHelperText>{errors.contractId}</FormHelperText>
                  </FormControl>
                )}

                <FormControl
                  margin='dense'
                  error={Boolean(touched.inspectedAt && errors.inspectedAt)}
                >
                  <MobileDateTimePicker
                    ampm={false}
                    label='Inspected at'
                    value={(values.inspectedAt && moment(values.inspectedAt)) || null}
                    format={DATETIME_FORMATS.DISPLAY_DATETIME}
                    onChange={(newValue: Moment | null) => {
                      setFieldValue("inspectedAt", newValue?.format() || null);
                    }}
                    slots={{ textField: (params) => <TextField {...params} /> }}
                  />
                  <FormHelperText>{touched.inspectedAt && errors.inspectedAt}</FormHelperText>
                </FormControl>

                {/* Accessories */}
                <Divider sx={{ my: 2 }} />

                <Stack sx={{ mb: 2 }} direction={{ xxs: "column", md: "row" }} spacing={1}>
                  <Box>
                    <Typography component='div' variant='h6'>
                      Accessories
                    </Typography>

                    {vehicleAccessories?.contract && (
                      <Typography
                        component='div'
                        variant='body1'
                        fontSize='small'
                        color='text.secondary'
                      >
                        Fetched accessories from contract{" "}
                        <AppLink
                          to={ROUTE_PATH.CONTRACT_VIEW(vehicleAccessories.contract.id)}
                          target='_blank'
                        >
                          {vehicleAccessories.contract.localNumber}
                        </AppLink>
                        .{" "}
                        {isCreate && (
                          <Button
                            variant='text'
                            size='small'
                            color='primary'
                            onClick={() => {
                              setFieldValue(`contractId`, undefined);
                            }}
                          >
                            Reset to vehicle accessories
                          </Button>
                        )}
                        .
                      </Typography>
                    )}
                  </Box>

                  <Stack sx={{ flex: 1 }} direction='row'>
                    {isCreate && (
                      <AppModal
                        fullWidth
                        trigger={
                          <LoadingButton
                            sx={{ ml: "auto" }}
                            variant='text'
                            size='small'
                            disabled={!values.vehicleId}
                          >
                            Load accessories from contract
                          </LoadingButton>
                        }
                        open={contractModalOpen}
                        onOpen={() => setContractModalOpen(true)}
                        onClose={() => setContractModalOpen(false)}
                      >
                        <FormControl
                          margin='dense'
                          sx={{ pr: "10px", pl: "10px" }}
                          fullWidth
                          error={Boolean(touched.contractId && errors.contractId)}
                        >
                          <AppModalTitle
                            title={"Select contract from which to load accessories"}
                            withCloseIcon
                            onCloseClicked={() => setContractModalOpen(false)}
                          />
                          <DialogContent>
                            <FormControl margin='normal' fullWidth>
                              <ContractAutocomplete
                                entityId={values.contractId}
                                searchFilters={{
                                  vehicleId: values.vehicleId || undefined,
                                }}
                                onChange={async (newValue) => {
                                  setFieldValue(`contractId`, newValue?.id || undefined);
                                }}
                              />
                              <FormHelperText>
                                {touched.contractId && errors.contractId}
                              </FormHelperText>
                            </FormControl>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant='text'
                              color='secondary'
                              onClick={() => {
                                setContractModalOpen(false);
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant='text'
                              color='primary'
                              onClick={async () => {
                                setContractModalOpen(false);
                              }}
                            >
                              Save
                            </Button>
                          </DialogActions>
                        </FormControl>
                      </AppModal>
                    )}
                  </Stack>
                </Stack>

                {vehicleAccessoriesRequest.isLoading && <LinearProgress sx={{ my: 1 }} />}

                {TypeHelper.isEmpty(values.items) && (
                  <Typography component='div' variant='body2'>
                    No accessories specified
                  </Typography>
                )}

                {!TypeHelper.isEmpty(values.items) && (
                  <Paper sx={{ p: 1 }}>
                    <Grid container>
                      <Grid item xxs={12} xs={12} sm={12} md={6} lg={4}>
                        <List sx={{ p: 0 }}>
                          {values.items?.map((item, i) => (
                            <ListItem key={i} sx={{ bgcolor: "background.gray", mb: 1 }}>
                              <ListItemText
                                primary={item.accessoryRef?.name}
                                secondary={item.accessoryRef?.description}
                                primaryTypographyProps={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                secondaryTypographyProps={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              />

                              <Tooltip title='In order' enterDelay={500} enterNextDelay={500}>
                                <IconButton
                                  onClick={() => {
                                    setFieldValue(`items[${i}].status`, AccessoryStatus.InOrder);
                                  }}
                                >
                                  <AppIcon
                                    of='accessoryInOrder'
                                    color={
                                      item.status === AccessoryStatus.InOrder
                                        ? "success"
                                        : "secondary"
                                    }
                                  />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title='Damaged' enterDelay={500} enterNextDelay={500}>
                                <IconButton
                                  onClick={() => {
                                    setFieldValue(`items[${i}].status`, AccessoryStatus.Damaged);
                                  }}
                                >
                                  <AppIcon
                                    of='accessoryDamaged'
                                    color={
                                      item.status === AccessoryStatus.Damaged
                                        ? "error"
                                        : "secondary"
                                    }
                                  />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title='Missing' enterDelay={500} enterNextDelay={500}>
                                <IconButton
                                  edge='end'
                                  onClick={() => {
                                    setFieldValue(`items[${i}].status`, AccessoryStatus.Missing);
                                  }}
                                >
                                  <AppIcon
                                    of='accessoryMissing'
                                    color={
                                      item.status === AccessoryStatus.Missing
                                        ? "error"
                                        : "secondary"
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </ListItem>
                          ))}
                        </List>

                        {errors.items && (
                          <FormHelperText error>
                            {ValidationHelper.getFormikErrorsAsString(errors.items, {
                              isIncludeNested: false,
                            })}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                )}

                <Divider sx={{ my: 2 }} />

                <PerformantTextFieldForFormikUnstable
                  error={Boolean(touched.notes && errors.notes)}
                  isPerformanceEnabled
                  fullWidth
                  multiline
                  rows={2}
                  helperText={touched.notes && errors.notes}
                  label='Notes'
                  margin='dense'
                  name='notes'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.notes}
                  variant='outlined'
                />

                <FormControl fullWidth component='fieldset' margin='dense'>
                  <FileUploader
                    multiple
                    defaultFiles={FileItem.createManyFrom(values.initialAttachments)}
                    onChange={(newFiles) => {
                      setFieldValue(
                        `attachments`,
                        FileItem.toManyGeneralAttachmentInputDto(newFiles),
                      );
                    }}
                    onUploadStarted={() => {
                      setIsAttachmentFilesUploading(true);
                    }}
                    onUploadFinished={() => {
                      setIsAttachmentFilesUploading(false);
                    }}
                  />
                </FormControl>

                <Divider sx={{ my: 2 }} />

                <FormControl component='fieldset' variant='standard'>
                  <FormLabel component='legend'>Inspected by</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled
                          checked={values.inspector?.isCurrentUser}
                          onChange={handleChange}
                          name='inspector.isCurrentUser'
                        />
                      }
                      label={
                        values.inspector?.personName
                          ? `${values.inspector.personName?.firstName} ${values.inspector.personName?.lastName}`
                          : profile?.personName?.name
                      }
                    />
                  </FormGroup>
                  <FormHelperText>Currently logged in user inspected the vehicle</FormHelperText>
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                disabled={isAttachmentFilesUploading}
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
