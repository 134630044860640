import { Button, Stack, TypographyProps } from "@mui/material";
import { useHistory } from "react-router";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { TeslaHelper } from "@/common/helpers/tesla";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  VehicleDto,
} from "@/core/api/generated";
import { useMemo } from "react";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import VehicleMenu from "../VehicleMenu";
import VehicleViewPageHeader from "./Header/VehicleViewPageHeader";
import AccessoriesTabContent from "./Tabs/AccessoriesTabContent";
import AssetSubscriptionsTabContent from "./Tabs/AssetSubscriptionsTabContent";
import AssetTabContent from "./Tabs/AssetTabContent";
import AttachmentsTabContent from "./Tabs/AttachmentsTabContent";
import ContractsTabContent from "./Tabs/ContractsTabContent";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OperationsTabContent from "./Tabs/OperationsTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import PoolsTabContent from "./Tabs/PoolsTabContent";
import TeslaVehicleDataTabContent from "./Tabs/TeslaVehicleDataTabContent";
import VehicleActualStateTabContent from "./Tabs/VehicleActualStateTabContent";

export enum VehicleViewPageTabs {
  Overview = "Overview",
  Tesla = "Tesla",
  Attachments = "Attachments",
  Accessories = "Accessories",
  VehicleActualState = "VehicleActualState",
  Operations = "Operations",
  Contracts = "Contracts",
  Asset = "Asset",
  Pools = "Pools",
  AssetSubscriptions = "AssetSubscriptions",
  GeneralHistory = "GeneralHistory",
}

export interface VehicleViewPageQueryParams extends GeneralQueryParams {
  tab?: VehicleViewPageTabs;
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<VehicleDto> {
  vehicleId: string | null | undefined;
  vehicle: VehicleDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function VehicleView({
  vehicleId,
  vehicle,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const profile = useUserProfile();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.Vehicle),
      viewVariant: displayProps.viewVariant,
    },
  });

  const vehicleRequest = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicleId!,
    },
    {
      deps: [vehicleId],
      skip: !vehicleId || !!vehicle,
    },
  );
  vehicle = vehicle || vehicleRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entity(undefined, EntityType.Vehicle, vehicleId || "")],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      vehicleRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        vehicle && {
          idValue: vehicle.id!,
          newTitle: vehicle.localNumber || "",
        }) ||
      undefined,
  });

  const isTeslaDemoEnabled = useMemo(
    () => TeslaHelper.isDemoEnabledForUserAndVehicle(profile, vehicle),
    [profile, vehicle],
  );

  return (
    <BaseEntityView
      entityType={EntityType.Vehicle}
      entityId={vehicleId}
      entity={vehicle}
      entityRequest={vehicleRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header &&
          vehicle && (
            <VehicleViewPageHeader
              vehicleId={vehicleId!}
              vehicle={vehicle}
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.ContractManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.VEHICLE_EDIT(vehicle?.id)}
                      variant='outlined'
                      color='secondary'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                displayProps.actions && (
                  <VehicleMenu
                    entity={vehicle}
                    onDelete={() => history.goBack()}
                    onUpdate={(newValue) =>
                      newValue ? vehicleRequest.replaceData(newValue) : vehicleRequest.refetch()
                    }
                    displayProps={{
                      actions: {
                        edit: false,
                        createContract: true,
                        createVisualInspection: true,
                        createDamageDetection: true,
                        createDamageCostEvaluation: true,
                        createRepairOperation: true,
                        createAccessoryCheck: true,
                        createAsset: true,
                        addToPool: true,
                      },
                    }}
                  />
                )
              }
              onUpdate={(newValue) => {
                vehicleRequest.replaceData(newValue);
              }}
            />
          )
        }
      >
        <Stack spacing={2}>
          <PageTabs
            tabIdsDefinition={VehicleViewPageTabs}
            defaultTabId={VehicleViewPageTabs.Overview}
            viewVariant={displayProps?.viewVariant}
            commonViewParams={commonViewParams}
            tabs={[
              { label: "Overview", value: VehicleViewPageTabs.Overview, isHideable: false },
              ...(isTeslaDemoEnabled ? [{ label: "Tesla", value: VehicleViewPageTabs.Tesla }] : []),
              { label: "Attachments", value: VehicleViewPageTabs.Attachments },
              { label: "Accessories", value: VehicleViewPageTabs.Accessories },
              { label: "Actual state", value: VehicleViewPageTabs.VehicleActualState },
              { label: "Contracts", value: VehicleViewPageTabs.Contracts },
              { label: "Operations", value: VehicleViewPageTabs.Operations },
              { label: "Asset", value: VehicleViewPageTabs.Asset },
              { label: "Pools", value: VehicleViewPageTabs.Pools },
              { label: "Asset subscriptions", value: VehicleViewPageTabs.AssetSubscriptions },
              { label: "History", value: VehicleViewPageTabs.GeneralHistory },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              vehicle && (
                <>
                  {activeTab === VehicleViewPageTabs.Overview && (
                    <OverviewTabContent
                      vehicle={vehicle}
                      onVehicleUpdated={vehicleRequest.refetch}
                    />
                  )}
                  {activeTab === VehicleViewPageTabs.Tesla && (
                    <TeslaVehicleDataTabContent vehicle={vehicle} />
                  )}
                  {activeTab === VehicleViewPageTabs.Attachments && (
                    <AttachmentsTabContent
                      vehicle={vehicle}
                      onVehicleUpdated={(newValue) =>
                        newValue ? vehicleRequest.replaceData(newValue) : vehicleRequest.refetch()
                      }
                    />
                  )}
                  {activeTab === VehicleViewPageTabs.Accessories && (
                    <AccessoriesTabContent vehicle={vehicle} />
                  )}
                  {activeTab === VehicleViewPageTabs.VehicleActualState && (
                    <VehicleActualStateTabContent vehicle={vehicle} />
                  )}
                  {activeTab === VehicleViewPageTabs.Operations && (
                    <OperationsTabContent vehicle={vehicle} />
                  )}
                  {activeTab === VehicleViewPageTabs.Contracts && (
                    <ContractsTabContent vehicle={vehicle} />
                  )}
                  {activeTab === VehicleViewPageTabs.Asset && <AssetTabContent vehicle={vehicle} />}
                  {activeTab === VehicleViewPageTabs.Pools && <PoolsTabContent vehicle={vehicle} />}
                  {activeTab === VehicleViewPageTabs.AssetSubscriptions && (
                    <AssetSubscriptionsTabContent vehicle={vehicle} />
                  )}
                  {activeTab === VehicleViewPageTabs.GeneralHistory && (
                    <GeneralHistoryTabContent vehicle={vehicle} />
                  )}
                </>
              )
            }
          </PageTabs>
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}
