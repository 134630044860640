import { ValidationHelper } from "@/common/validation";
import { ProblemDetails, ValidationProblemDetails } from "@/core/api/generated";
import { Box, FormHelperText, Stack, SxProps, Theme } from "@mui/material";
import { useMemo, useState } from "react";
import AppIcon from "../Icons/AppIcon";
import AppLink from "../Link/AppLink";
import ValidationInfoModal from "./ValidationInfoModal";

interface Props {
  problemDetails: ProblemDetails | ValidationProblemDetails | null | undefined;
  withDetails?: boolean;
  sx?: SxProps<Theme>;
}

/** Displays general validation error.
 *  E.g. form-level error, not related to specific field.
 */
export default function ProblemDetailsDisplay({ problemDetails, withDetails = true, sx }: Props) {
  const [isValidationInfoModalOpen, setIsValidationInfoModalOpen] = useState(false);

  const generalError = useMemo(
    () => ValidationHelper.getGeneralErrorsAsString(problemDetails?.errors),
    [problemDetails],
  );
  const validationInfo = useMemo(
    () => ValidationHelper.getValidationInfoFromValidationErrors(problemDetails?.errors),
    [problemDetails],
  );
  const isShowDetails = withDetails;

  if (!problemDetails) {
    return null;
  }

  return (
    <Box sx={sx}>
      <FormHelperText component='div' error>
        <Stack spacing={0.5}>
          {problemDetails?.title && <Box>{problemDetails?.title}</Box>}
          {problemDetails?.detail && <Box>{problemDetails?.detail}</Box>}
          {generalError && <Box>{problemDetails?.detail}</Box>}

          {isShowDetails && (
            <Box>
              <AppLink
                to='#'
                isInheritStyles
                onClick={(e) => {
                  e.preventDefault();
                  setIsValidationInfoModalOpen(true);
                }}
              >
                <AppIcon of='report' /> Report the issue
              </AppLink>
            </Box>
          )}
        </Stack>
      </FormHelperText>

      {isShowDetails && (
        <ValidationInfoModal
          open={isValidationInfoModalOpen}
          onClose={() => {
            setIsValidationInfoModalOpen(false);
          }}
          problemDetails={problemDetails}
          validationInfo={validationInfo}
        />
      )}
    </Box>
  );
}
