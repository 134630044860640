import { Button, IconButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataExportModal from "@/common/components/DataExport/DataExportModal";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import AccessoryDeleteModal from "@/common/components/Entity/Accessory/AccessoryDeleteModal";
import AssetCreateUpdateFromEntityModal from "@/common/components/Entity/Asset/AssetCreateUpdateFromEntityModal";
import { entitySpecToAssetSpecDto } from "@/common/components/Entity/Asset/AssetSpecInput";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import EntityPoolsInfoDisplay from "@/common/components/EntityInfo/EntityPoolsInfoDisplay";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import HeaderMenuItem from "@/common/components/Menu/HeaderMenuItem";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { TypeHelper } from "@/common/helpers/type";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AccessoryDto,
  AccessoryGetPaginatedDto,
  AppPermission,
  AssetEntityType,
  DataUpdatesHubClientMethodName,
  EntityType,
  ExportEntityType,
  GeneralAttachedTagsInputDto,
  ImportEntityType,
  PoolItemEntityType,
  TagEntityType,
} from "@/core/api/generated";
import _ from "lodash";
import GeneralAttachedTagsOfEntitiesEditModal from "../../General/GeneralTag/GeneralAttachedTagsOfEntitiesEditModal";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import PoolItemCreateFromEntityModal from "../../PoolItem/PoolItemCreateFromEntityModal";
import PoolItemCreateManyFromEntitiesModal from "../../PoolItem/PoolItemCreateManyFromEntitiesModal";
import AccessoriesDeleteModal from "../AccessoriesDeleteModal";

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  UpdateTags = "UpdateTags",
  AddToPool = "AddToPool",
  Delete = "Delete",
}

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
};

type AccessoryGetPaginatedInputDto = Omit<AccessoryGetPaginatedDto, "tags"> & {
  tags?: TagStaticFilterInputDto;
};

interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function AccessoriesPaginatedList({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<AccessoryGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: EntityType.Accessory,
    },
    initParams: (filterDefinition) => {
      const tagFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<AccessoryDto>().tags.$path,
      );

      return {
        tags: {
          operator: tagFilter?.operator,
          query: tagFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
      };
    },
  });

  const [exportAccessoryIds, setExportAccessoryIds] = useState<string[] | null>(null);
  const [accessoryToDelete, setAccessoryToDelete] = useState<AccessoryDto | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [accessory, setAccessory] = useState<AccessoryDto | null>(null);
  const [isCreateAssetFromEntityModalOpen, setIsCreateAssetFromEntityModalOpen] = useState(false);
  const [isDataExportModalOpened, setIsDataExportModalOpened] = useState(false);
  const [isAddToPoolModalOpen, setIsAddToPoolModalOpen] = useState(false);

  const paginatedAccessoriesRequest = useApiRequest(
    apiClient.accessoriesApi.apiV1AccessoriesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      accessoryGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<AccessoryDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, commonRequestParams.filterDefinition],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedAccessories = useMemo(
    () => paginatedAccessoriesRequest?.data,
    [paginatedAccessoriesRequest?.data],
  );

  const accessoryIds = useMemo(
    () => paginatedAccessories?.items?.map((x) => x.id!) || [],
    [paginatedAccessories?.items],
  );

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Accessory)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedAccessoriesRequest.handleEntityChanged(data);
    },
  });
  return (
    <ViewLayout
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title={undefined}
          primaryActions={
            <AuthorizedElement permissionsAny={[AppPermission.AccessoryManage]}>
              <Button
                variant='contained'
                color='primary'
                startIcon={<AppIcon of='add' />}
                component={RouterLink}
                to={ROUTE_PATH.ACCESSORY_CREATE}
              >
                Create new accessory
              </Button>
            </AuthorizedElement>
          }
          secondaryActions={
            <AuthorizedElement
              permissionsAny={[AppPermission.ExportManage, AppPermission.ImportManage]}
            >
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <AuthorizedMenuItem
                  permissions={[AppPermission.ExportManage]}
                  onClick={() => setIsDataExportModalOpened(true)}
                >
                  <AppIcon of='export' fontSize='small' sx={{ mr: 1 }} />
                  <Typography>Export accessories</Typography>
                </AuthorizedMenuItem>
                <AuthorizedMenuItem
                  permissions={[AppPermission.ImportManage]}
                  component={RouterLink}
                  to={ROUTE_PATH.MANAGEMENT_IMPORTS_CREATE({
                    entityType: ImportEntityType.Accessory,
                  })}
                >
                  <AppIcon of='download' fontSize='small' sx={{ mr: 1 }} />
                  <Typography>Import accessories</Typography>
                </AuthorizedMenuItem>
              </MenuWithTrigger>
            </AuthorizedElement>
          }
        />
      }
    >
      <Stack sx={{ mt: 1 }} spacing={1}>
        <DataTabular
          columns={[
            {
              field: getTypedPath<AccessoryDto>().id.$path,
              title: "ID",
              width: 250,
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.id,
              filters: {
                fieldType: FilterFieldType.Id,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<AccessoryDto>().localNumber.$path,
              title: "Number",
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => (
                <Stack direction='row' spacing={0.5}>
                  <span>{item.localNumber}</span> <EntityChipList entity={item} variant='compact' />
                </Stack>
              ),
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<AccessoryDto>().name.$path,
              title: "Name",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.name || "-",
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<AccessoryDto>().description.$path,
              title: "Description",
              width: 200,
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) => item.description || "-",
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<AccessoryDto>().type.$path,
              title: "Type",
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.type ? <InlineApiEnumValue type='AccessoryType' value={item.type} /> : "-",
              filters: {
                fieldType: FilterFieldType.Enum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.AccessoryType,
                  },
                },
              },
            },
            {
              field: getTypedPath<AccessoryDto>().vehicleTypes.$path,
              title: "Vehicle types",
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                !TypeHelper.isEmpty(item.vehicleTypes) ? (
                  <TableCellContentOfCount
                    count={item.vehicleTypes?.length}
                    popoverContent={
                      <Stack>
                        {item.vehicleTypes?.map((x, i) => (
                          <InlineApiEnumValue key={i} type='VehicleType' value={x} />
                        ))}
                      </Stack>
                    }
                  ></TableCellContentOfCount>
                ) : (
                  "-"
                ),
              filters: {
                fieldType: FilterFieldType.ArrayOfEnum,
                isUseDefaultOperators: true,
                fieldTypeMeta: {
                  enum: {
                    enumName: ApiEnumName.VehicleType,
                  },
                },
              },
            },
            {
              field: getTypedPath<AccessoryDto>().isForAllVehicles.$path,
              title: "For all vehicles",
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => <BooleanValue value={item.isForAllVehicles} />,
              filters: {
                fieldType: FilterFieldType.Boolean,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<AccessoryDto>().createdAt.$path,
              title: "Created at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<AccessoryDto>().updatedAt.$path,
              title: "Updated at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<AccessoryDto>().createdBy.$path,
              title: "Created by",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
              },
            },
            {
              field: getTypedPath<AccessoryDto>().updatedBy.$path,
              title: "Updated by",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
              },
            },
            {
              field: getTypedPath<AccessoryDto>().poolsMeta.$path,
              title: "Pools",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => (
                <EntityPoolsInfoDisplay
                  entityType={PoolItemEntityType.Asset}
                  entity={item}
                  isIncludeAssetPools
                  noDataPlaceholder='-'
                />
              ),
            },
            {
              field: getTypedPath<AccessoryDto>().tags.$path,
              title: "Tags",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              isColumnMenuDisabled: false,
              isToDisabled: true,
              renderCell: (item) => (
                <EntityTagsInfoDisplay
                  entityType={TagEntityType.Accessory}
                  entity={item}
                  noDataPlaceholder='-'
                  edit={{
                    onSaved: (newValue) =>
                      paginatedAccessoriesRequest.updateData((data) => {
                        data.items?.forEach((item2) => {
                          if (item2.id === item.id) {
                            item2.tags = newValue || undefined;
                          }
                        });
                      }),
                  }}
                />
              ),
              filters: {
                fieldType: FilterFieldType.Arbitrary,
                isUseSingleFilter: true,
                operators: FilterCatalog.getOperatorsForTagsField(),
              },
            },
          ]}
          rows={paginatedAccessories?.items}
          isLoading={paginatedAccessoriesRequest.isLoading}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.ACCESSORY_VIEW(item.id)}
          renderRowAction={({ item }) => (
            <MenuWithTrigger
              withAuthCloseOnClick
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              <AuthorizedMenuItem
                permissions={[AppPermission.AccessoryManage]}
                component={RouterLink}
                to={ROUTE_PATH.ACCESSORY_EDIT(item.id)}
              >
                <ListItemIcon>
                  <AppIcon of='edit' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </AuthorizedMenuItem>
              <AuthorizedMenuItem
                permissions={[AppPermission.AccessoryManage]}
                onClick={() => {
                  setAccessoryToDelete(item);
                  setIsDeleteModalOpen(true);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='delete' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </AuthorizedMenuItem>

              <HeaderMenuItem primaryTitle='Quick actions' />
              <AuthorizedMenuItem
                permissions={[AppPermission.AssetManage]}
                onClick={() => {
                  setIsCreateAssetFromEntityModalOpen(true);
                  setAccessory(item);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='asset' fontSize='small' />
                </ListItemIcon>
                <ListItemText>New asset</ListItemText>
              </AuthorizedMenuItem>
              <AuthorizedMenuItem
                permissions={[AppPermission.PoolManage]}
                onClick={() => {
                  setIsAddToPoolModalOpen(true);
                  setAccessory(item);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='pool' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Add to pool</ListItemText>
              </AuthorizedMenuItem>
            </MenuWithTrigger>
          )}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
          bulkActions={{
            enabled: true,
            definition: BulkActionFlags,
            actionTriggers: ({ currentAction, startAction, selectedIds }) => (
              <>
                <AppIconButton
                  tooltipProps={{ title: "Update affiliation" }}
                  onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
                >
                  <AppIcon of='department' />
                </AppIconButton>
                <AppIconButton
                  tooltipProps={{ title: "Update tags" }}
                  onClick={() => startAction(BulkActionFlags.UpdateTags)}
                >
                  <AppIcon of='tag' />
                </AppIconButton>
                <AppIconButton
                  tooltipProps={{ title: "Add to pool" }}
                  onClick={() => startAction(BulkActionFlags.AddToPool)}
                >
                  <AppIcon of='pool' />
                </AppIconButton>
                <AppIconButton
                  tooltipProps={{ title: "Export" }}
                  onClick={() => {
                    setExportAccessoryIds((selectedIds as string[]) || []);
                    setIsDataExportModalOpened(true);
                  }}
                >
                  <AppIcon of='export' />
                </AppIconButton>
                <AppIconButton
                  tooltipProps={{ title: "Delete" }}
                  onClick={() => startAction(BulkActionFlags.Delete)}
                >
                  <AppIcon of='delete' />
                </AppIconButton>
              </>
            ),
            actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => {
              const selectedEntities = EntityHelper.filterEntitiesByIds(
                paginatedAccessories?.items || [],
                selectedIds as string[],
              );
              return (
                <>
                  <AccessoriesDeleteModal
                    entities={selectedEntities}
                    open={currentAction === BulkActionFlags.Delete}
                    onClose={() => cancelAction()}
                    onDelete={() => {
                      completeAction();
                      paginatedAccessoriesRequest.refetch();
                    }}
                  />
                  <PoolItemCreateManyFromEntitiesModal
                    open={currentAction === BulkActionFlags.AddToPool}
                    onClose={() => cancelAction()}
                    createProps={{
                      entityType: PoolItemEntityType.Accessory,
                      entities: selectedEntities,
                      onSave: (newValue) => {
                        completeAction();
                        paginatedAccessoriesRequest.refetch();
                      },
                    }}
                  />
                  <TenantStructureMembershipOfEntityEditModal
                    open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                    onClose={() => cancelAction()}
                    tenantStructureMembershipOfEntityEditProps={{
                      entityType: EntityType.Accessory,
                      entities: selectedEntities,
                      onSave: () => {
                        completeAction();
                        paginatedAccessoriesRequest.refetch();
                      },
                    }}
                  />
                  <GeneralAttachedTagsOfEntitiesEditModal
                    tagEntityType={TagEntityType.Accessory}
                    entities={selectedEntities}
                    open={currentAction === BulkActionFlags.UpdateTags}
                    onClose={() => cancelAction()}
                    onSaved={() => {
                      completeAction();
                      paginatedAccessoriesRequest.refetch();
                    }}
                  />
                </>
              );
            },
          }}
        />
      </Stack>

      {/* Delete confirmation */}
      {accessoryToDelete && (
        <AccessoryDeleteModal
          entity={accessoryToDelete}
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={() => {
            paginatedAccessoriesRequest.refetch();
          }}
        />
      )}

      {/* Create asset from entity */}
      {accessory && (
        <AssetCreateUpdateFromEntityModal
          open={isCreateAssetFromEntityModalOpen}
          onClose={() => setIsCreateAssetFromEntityModalOpen(false)}
          entity={{
            entityType: AssetEntityType.Accessory,
            entityId: accessory.id!,
          }}
          spec={entitySpecToAssetSpecDto(AssetEntityType.Accessory, null)}
          createUpdateProps={{
            onSave: (asset) => {
              setIsCreateAssetFromEntityModalOpen(false);
              history.push(ROUTE_PATH.ASSET_VIEW(asset.id));
            },
          }}
        />
      )}
      {/* Export data modal */}
      <DataExportModal
        open={isDataExportModalOpened}
        onClose={() => setIsDataExportModalOpened(false)}
        exportDataProps={{
          entityType: ExportEntityType.Accessory,
          ids: accessoryIds,
          onExportDone: () => {
            setIsDataExportModalOpened(false);
          },
        }}
      />

      {/* Add to pool */}
      {accessory && (
        <PoolItemCreateFromEntityModal
          open={isAddToPoolModalOpen}
          onClose={() => setIsAddToPoolModalOpen(false)}
          createProps={{
            entityType: PoolItemEntityType.Accessory,
            entity: accessory,
            onSave: (newValue) => {
              setIsAddToPoolModalOpen(false);
              paginatedAccessoriesRequest.refetch();
            },
          }}
        />
      )}
    </ViewLayout>
  );
}
