import { Box, Button, Stack } from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import PercentValue from "@/common/components/Form/Display/PercentValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  RepairMaterialGetPaginatedDto,
  RepairWorkDto,
} from "@/core/api/generated";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import RepairWorkInline from "@/common/components/Entity/RepairWork/RepairWorkInline";
import RepairWorkMenu from "@/common/components/Entity/RepairWork/RepairWorkMenu";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import AppTypography from "@/common/components/Text/AppTypography";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import InlineUser from "../../components/User/InlineUser";
import ImportFromRepairCatalogModal from "../RepairCatalog/components/ImportFromRepairCatalogModal";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
  create: true,
  import: true,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function RepairWorkListPage({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<RepairMaterialGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.RepairMaterial,
    },
  });

  const [isImportFromRepairCatalogModalOpen, setIsImportFromRepairCatalogModalOpen] =
    useState(false);

  const paginatedRepairWorksRequest = useApiRequest(
    apiClient.repairWorkApi.apiV1RepairWorkGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairWorkGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedRepairWorks = paginatedRepairWorksRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.RepairWork)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedRepairWorksRequest.handleEntityChanged(data);
    },
  });

  return (
    <Box>
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title={undefined}
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement permissions={[AppPermission.RepairCatalogManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='add' />}
                    component={RouterLink}
                    to={ROUTE_PATH.REPAIR_WORK_CREATE}
                  >
                    Create new
                  </Button>
                </AuthorizedElement>
              )
            }
            /* secondaryActions={
              displayProps?.import && (
                <AuthorizedElement permissions={[AppPermission.RepairCatalogManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='import' />}
                    onClick={() => setIsImportFromRepairCatalogModalOpen(true)}
                  >
                    Import
                  </Button>
                </AuthorizedElement>
              )
            } */
          />
        }
      >
        <Stack spacing={1}>
          <DataTabular
            columns={[
              {
                field: getTypedPath<RepairWorkDto>().id.$path,
                title: "ID",
                width: 250,
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.id,
                filters: {
                  fieldType: FilterFieldType.Id,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().localNumber.$path,
                title: "Number",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <Stack direction='row' spacing={0.5}>
                    <span>{item.localNumber}</span>{" "}
                    <EntityChipList entity={item} variant='compact' />
                  </Stack>
                ),
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().name.$path,
                title: "Name",
                width: 100,
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.name || "-",
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().description.$path,
                title: "Description",
                width: 100,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => item.description || "-",
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().sourceWorkId.$path,
                title: "Source work",
                width: 200,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.sourceWorkId ? (
                    <RepairWorkInline entity={undefined} entityId={item.sourceWorkId} />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfRepairWork(),
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().expendablesPercent.$path,
                title: "Expendables %",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => <PercentValue value={item.expendablesPercent} />,
                filters: {
                  fieldType: FilterFieldType.Boolean,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().isDividable.$path,
                title: "Dividable?",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => <BooleanValue value={item.isDividable} />,
                filters: {
                  fieldType: FilterFieldType.Boolean,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().unit.$path,
                title: "Unit",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <InlineApiEnumValue type='MeasurementUnit' value={item.unit} />
                ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.MeasurementUnit,
                    },
                  },
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().currency.code.$path,
                title: "Currency",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.currency ? <GeneralCurrencyDisplay currency={item.currency} /> : "-",
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.CurrencyCode,
                    },
                  },
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().price.$path,
                title: "Price",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => <CurrencyValue value={item.price} currency={item.currency} />,
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().subTotal.$path,
                title: "Sub total",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <CurrencyValue value={item.subTotal} currency={item.currency} />
                ),
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().discount.$path,
                title: "Discount",
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => (
                  <GeneralDiscountDisplay
                    discount={item.discount}
                    currency={item.currency}
                    detailsPlacement='tooltip'
                  />
                ),
              },
              {
                field: getTypedPath<RepairWorkDto>().subTotalIncDiscount.$path,
                title: "Sub total (inc. discount)",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <CurrencyValue value={item.subTotalIncDiscount} currency={item.currency} />
                ),
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().tax.$path,
                title: "Tax",
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => <GeneralTaxDisplay tax={item.tax} currency={item.currency} />,
              },
              {
                field: getTypedPath<RepairWorkDto>().total.$path,
                title: "Total",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <AppPopover
                    hoverBehavior={{}}
                    trigger={
                      <AppTypography decoration={{ variant: "helpText" }}>
                        <CurrencyValue value={item.total} currency={item.currency} />
                      </AppTypography>
                    }
                  >
                    <AppPopoverContent>
                      <GeneralPriceSummaryDisplay
                        direction='column'
                        summary={{
                          currency: item.currency,
                          subTotal: item.subTotal,
                          discount: item.discount || undefined,
                          tax: item.tax || undefined,
                          total: item.total || 0,
                        }}
                      />
                    </AppPopoverContent>
                  </AppPopover>
                ),
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().createdAt.$path,
                title: "Created at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().updatedAt.$path,
                title: "Updated at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().createdBy.$path,
                title: "Created by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<RepairWorkDto>().updatedBy.$path,
                title: "Updated by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
            ]}
            rows={paginatedRepairWorks?.items}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.REPAIR_WORK_VIEW(item.id)}
            renderRowAction={({ item }) => (
              <RepairWorkMenu
                entity={item}
                onUpdate={() => paginatedRepairWorksRequest.refetch()}
                onDelete={() => paginatedRepairWorksRequest.refetch()}
              />
            )}
            isLoading={paginatedRepairWorksRequest.isLoading}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
          />
        </Stack>

        <ImportFromRepairCatalogModal
          open={isImportFromRepairCatalogModalOpen}
          onClose={() => setIsImportFromRepairCatalogModalOpen(false)}
          onImportEnd={() => paginatedRepairWorksRequest.refetch()}
        />
      </ViewLayout>
    </Box>
  );
}
