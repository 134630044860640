import { Stack, Typography } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import NoFilesAlert from "@/common/components/AppAlerts/NoFilesAlert";
import AppIconButton from "@/common/components/Button/AppIconButton";
import AccessoryGrid from "@/common/components/Entity/Accessory/AccessoryGrid";
import GeneralStrictEntityRelationLink from "@/common/components/Entity/General/Display/GeneralStrictEntityRelationLink";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { FileItem } from "@/common/fileItem";
import { renderIf } from "@/common/helpers/render/renderIf";
import { AssetDto, EntityType } from "@/core/api/generated";
import _ from "lodash";
import { useState } from "react";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import TenantLink from "../../../Tenant/TenantLink";

interface Props {
  asset: AssetDto;
  onUpdate?: () => void;
}

export default function OverviewTabContent({ asset, onUpdate }: Props) {
  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={asset} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <FieldValue label='Entity type' isEmpty={!asset?.entityType}>
                <InlineApiEnumValue type='AssetEntityType' value={asset.entityType} />
              </FieldValue>

              <FieldValue label='Entity' isEmpty={!asset?.entity}>
                <GeneralStrictEntityRelationLink value={asset.entity} />
              </FieldValue>

              <FieldValue label='Name' isEmpty={!asset?.name}>
                {asset?.name}
              </FieldValue>

              <FieldValue label='Description' isEmpty={!asset?.description}>
                {asset?.description}
              </FieldValue>

              <FieldValue label='Owner' isEmpty={!asset?.ownerTenantId}>
                <TenantLink entity={undefined} entityId={asset.ownerTenantId} />
              </FieldValue>
            </Stack>

            {/* Affiliation */}
            <Stack spacing={1} sx={{ mt: 2 }}>
              <Typography variant='subtitle1'>
                Affiliation{" "}
                <AppIconButton
                  tooltipProps={{ title: "Edit entity affiliation" }}
                  onClick={() => setIsUpdateAffiliationModalOpened(true)}
                >
                  <AppIcon of='edit' fontSize='small' />
                </AppIconButton>
              </Typography>
              <AffiliationInfoDisplay
                tenantId={asset.tenantId}
                departmentIds={asset.departmentIds}
                locationIds={asset.locationIds}
                sx={{ mt: 1 }}
              />
              <UpdateEntityAffiliationCascadeModal
                open={isUpdateAffiliationModalOpened}
                onClose={() => setIsUpdateAffiliationModalOpened(false)}
                entityType={EntityType.Asset}
                entityId={asset.id}
                onUpdate={onUpdate}
              />
            </Stack>

            {/* Accessories */}
            <Stack spacing={2}>
              <Typography component='div' variant='h6'>
                Accessories
              </Typography>

              {_.isEmpty(asset?.accessories) && <NoDataAlert />}

              <AccessoryGrid accessories={asset?.accessories} />
            </Stack>

            {/* Attachments */}
            <Stack spacing={1}>
              <Typography component='div' variant='h6'>
                Attachments
              </Typography>

              {renderIf()
                .if(!!asset?.attachments && asset.attachments.length !== 0)
                .then(
                  <FileListView
                    files={FileItem.createManyFrom(asset?.attachments)}
                    maxVisibleFiles={10}
                    itemWidth={250}
                    itemHeight={190}
                    sx={{ mt: 2, width: "100%" }}
                  />,
                )
                .else(<NoFilesAlert title='No attachments' description={undefined} />)
                .render()}
            </Stack>

            {/* Settings */}
            <Stack spacing={2}>
              <Typography component='div' variant='h6'>
                Settings
              </Typography>

              {_.isEmpty(asset?.settings) && <NoDataAlert />}

              {asset?.settings && (
                <Stack spacing={1}>
                  <FieldValue label='Sync spec' isEmpty={_.isNil(asset?.settings?.isSyncSpec)}>
                    <BooleanValue value={asset.settings?.isSyncSpec} />
                  </FieldValue>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
