import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { usePageTabs } from "@/common/hooks/layout/usePageTabs";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  EntityType,
  TagEntityType,
  TenantRequestDto,
  TenantRequestGetPaginatedDto,
} from "@/core/api/generated";
import { Box, IconButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import TenantInline from "../components/Entity/Tenant/TenantInline";

export enum TenantRequestPaginatedListTabs {
  All = "All",
  Incoming = "Incoming",
  Outcoming = "Outcoming",
}

const defaultDisplayProps = {
  breadcrumbs: true,
  counters: false,
  filters: true,
  create: false,
  viewVariant: ViewLayoutVariant.Page,
};

export default function TenantRequestsPage() {
  const { enqueueSnackbar } = useAppSnackbar();
  const pageTabs = usePageTabs<TenantRequestPaginatedListTabs>({
    tabIdsDefinition: TenantRequestPaginatedListTabs,
    defaultTabId: TenantRequestPaginatedListTabs.All,
    tabs: [],
  });
  const commonRequestParams = useCommonRequestParams<TenantRequestGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.TenantRequest),
    },
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [toDelete, setToDelete] = useState<TenantRequestDto | undefined>(undefined);

  const paginatedTenantRequestsRequest = useApiRequest(
    apiClient.adminTenantRequestsApi.apiV1AdminTenantToTenantTenantRequestsGetPost,
    {
      tenantRequestGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, pageTabs.activeTabId],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedTenantRequests = paginatedTenantRequestsRequest?.data;

  const handleDeleteConfirmed = useCallback(async () => {
    if (!toDelete) {
      return;
    }
    try {
      await apiClient.adminTenantRequestsApi.apiV1AdminTenantToTenantTenantRequestsTenantRequestIdDelete(
        {
          tenantRequestId: toDelete.id!,
        },
      );
      enqueueSnackbar("Item was successfully deleted.", { variant: "success" });
      setIsDeleteModalOpen(false);
      paginatedTenantRequestsRequest.refetch();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [toDelete]);

  return (
    <Box>
      <ViewLayout displayProps={defaultDisplayProps}>
        <Stack direction='column' spacing={1}>
          <DataTabular<TenantRequestDto>
            tabs={{
              value: pageTabs.activeTabId,
              onChange: (e, val) => pageTabs.setActiveTab(val),
              tabs: [
                {
                  label: <Box>{TenantRequestPaginatedListTabs.All}</Box>,
                  value: TenantRequestPaginatedListTabs.All,
                },
              ],
            }}
            columns={[
              {
                field: "name",
                title: "Name",
                flex: 1,
                renderCell: (item) => (
                  <Box>
                    <Box>{item.name}</Box>
                    {item.description && (
                      <Typography variant='body2'>{item.description}</Typography>
                    )}
                  </Box>
                ),
              },
              {
                field: "type",
                title: "Type",
                flex: 1,
                renderCell: (item) => (
                  <Box>
                    <InlineApiEnumValue type='TenantRequestType' value={item.content?.type} />
                    {item.content?.operation && (
                      <Typography variant='body2'>
                        <InlineApiEnumValue
                          type='TenantRequestOperationType'
                          value={item.content?.operation?.operationType}
                        />
                      </Typography>
                    )}
                  </Box>
                ),
              },
              {
                field: "stageHistory.stage",
                title: "Stage",
                flex: 1,
                renderCell: (item) => (
                  <InlineApiEnumValue type='TenantRequestStage' value={item.stageHistory?.stage} />
                ),
              },
              {
                field: "senderTenant.id",
                title: "Sender",
                flex: 1,
                renderCell: (item) => <TenantInline entity={item.senderTenant} />,
              },
              {
                field: "receiverTenant.id",
                title: "Receiver",
                flex: 1,
                renderCell: (item) => <TenantInline entity={item.receiverTenant} />,
              },
              {
                field: "tags",
                title: "Tags",
                flex: 0,
                renderCell: (item) => (
                  <EntityTagsInfoDisplay
                    entityType={TagEntityType.TenantRequest}
                    entity={item}
                    noDataPlaceholder='-'
                    edit={{
                      onSaved: (newValue) =>
                        paginatedTenantRequestsRequest.updateData((data) => {
                          data.items?.forEach((item2) => {
                            if (item2.id === item.id) {
                              item2.tags = newValue || undefined;
                            }
                          });
                        }),
                    }}
                  />
                ),
                isSortable: false,
                isColumnMenuDisabled: true,
                isToDisabled: true,
              },
            ]}
            rows={paginatedTenantRequests?.items}
            isLoading={paginatedTenantRequestsRequest.isLoading}
            getRowId={(item) => item.id!}
            rowActions={{
              variant: "arbitrary",
              renderActions: ({ item }) => (
                <Stack direction='row' spacing={0.5} justifyContent='flex-end'>
                  <MenuWithTrigger
                    withAuthCloseOnClick
                    trigger={
                      <IconButton>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                  >
                    <AuthorizedMenuItem
                      permissions={[AppPermission.TenantRequestManage]}
                      onClick={() => {
                        setToDelete(item);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='delete' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </AuthorizedMenuItem>
                  </MenuWithTrigger>
                </Stack>
              ),
            }}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
          />
        </Stack>

        {toDelete && (
          <ConfirmationModal
            title='Delete the tenant request?'
            body={
              <>
                {`You're going to delete the tenant request`} . {`This action can't be undone.`}
              </>
            }
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onCancel={() => setIsDeleteModalOpen(false)}
            onConfirm={handleDeleteConfirmed}
          />
        )}
      </ViewLayout>
    </Box>
  );
}
