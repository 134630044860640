import { Badge, Box, IconButton, Stack, TextField } from "@mui/material";

import { useMemo, useRef, useState } from "react";
import { FilterDefinition } from "../filters/filterDefinition";
import { FilterSpec } from "../filters/filterSpec";
import { SortDefinition } from "../sorting/sortDefinition";
import { SortSpec } from "../sorting/sortSpec";
import AppTooltip from "./AppTooltip";
import AppIconButton from "./Button/AppIconButton";
import FilterPopover, { FilterPopoverHandle } from "./Filters/FilterPopover";
import AppIcon from "./Icons/AppIcon";
import SortPopover from "./Sort/SortPopover";
import { TypeHelper } from "../helpers/type";

interface Props {
  sort?: {
    sortSpec?: SortSpec;
    sortDefinition: SortDefinition | undefined;
    maxSortItems?: number;
    onChange: (newValue: SortDefinition | undefined) => void;
  };
  quickFilter?: {
    initialSearch: string | undefined;
    search: string | undefined;
    onChange: (newValue: string | undefined) => void;
  };
  filters?: {
    filterSpec: FilterSpec | undefined;
    filterDefinition: FilterDefinition | undefined;
    onChange: (newValue: FilterDefinition | undefined) => void;
  };
}

/** Similar to CustomDataGridToolbar but for custom paginated lists. */
export default function PaginatedListToolbar({ sort, quickFilter, filters }: Props) {
  const filterPopoverRef = useRef<FilterPopoverHandle>(null);

  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const [search, setSearch] = useState<string | undefined>(quickFilter?.initialSearch);

  // const isTabsEnabled = useMemo(() => !!tabs, [tabs]);
  const isSortEnabled = useMemo(() => !!sort, [sort]);
  const isQuickFilterEnabled = useMemo(() => !!quickFilter, [quickFilter]);
  const isFiltersEnabled = useMemo(() => !!filters, [filters]);
  const enabledList = [
    // isTabsEnabled,
    isSortEnabled,
    isQuickFilterEnabled,
    isFiltersEnabled,
  ];
  const isAnyEnabled = useMemo(() => enabledList.some((x) => x === true), [...enabledList]);

  if (!isAnyEnabled) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: { xxs: "1fr", lg: "1fr 1fr" },
          }}
        >
          <Box>
            {/* Tabs */}
            {/* {isTabsEnabled && tabs && (
              <Tabs value={tabs.value} onChange={tabs.onChange}>
                {tabs.tabs.map((tab, index) => (
                  <Tab key={index} {...tab} />
                ))}
              </Tabs>
            )} */}
          </Box>

          {/* Actions */}
          <Stack
            direction='row'
            spacing={0.5}
            sx={{ justifyContent: "flex-end", alignItems: "center", pr: 1 }}
          >
            {/* Quick filter */}
            {isQuickFilterEnabled && quickFilter && !isSearchOpened && (
              <AppTooltip title='Quick search'>
                <Badge
                  variant='dot'
                  color='primary'
                  overlap='circular'
                  invisible={TypeHelper.isEmpty(search)}
                >
                  <AppIconButton onClick={() => setIsSearchOpened(true)}>
                    <AppIcon of='search' />
                  </AppIconButton>
                </Badge>
              </AppTooltip>
            )}
            {isQuickFilterEnabled && quickFilter && isSearchOpened && (
              <TextField
                sx={{ flex: 1, "& .MuiInputBase-root": { pr: 0 }, minWidth: 0 }}
                label='Search'
                size='small'
                value={search || ""}
                onChange={(e) => {
                  setSearch(e.target.value);
                  quickFilter.onChange(e.target.value || undefined);
                }}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        setIsSearchOpened(false);
                        setSearch(undefined);
                        quickFilter.onChange(undefined);
                      }}
                    >
                      <AppIcon of='close' />
                    </IconButton>
                  ),
                }}
              />
            )}

            {isFiltersEnabled && filters && filters.filterSpec && (
              <FilterPopover
                ref={filterPopoverRef}
                clickBehavior={{}}
                trigger={
                  <AppTooltip title='Filters'>
                    <Badge
                      color='primary'
                      overlap='circular'
                      badgeContent={filters.filterDefinition?.items?.length}
                    >
                      <AppIconButton>
                        <AppIcon of='filters' />
                      </AppIconButton>
                    </Badge>
                  </AppTooltip>
                }
                filterDefinitionInputProps={{
                  filterSpec: filters.filterSpec,
                  filterDefinition: filters.filterDefinition,
                  onChange: filters.onChange,
                }}
              />
            )}

            {isSortEnabled && sort && sort.sortSpec && (
              <SortPopover
                clickBehavior={{}}
                trigger={
                  <AppTooltip title='Sort'>
                    <Badge
                      color='primary'
                      overlap='circular'
                      badgeContent={sort.sortDefinition?.items?.length}
                    >
                      <AppIconButton>
                        <AppIcon of='sorting' />
                      </AppIconButton>
                    </Badge>
                  </AppTooltip>
                }
                sortDefinitionInputProps={{
                  sortSpec: sort.sortSpec,
                  sortDefinition: sort.sortDefinition,
                  maxSortItems: sort.maxSortItems,
                  onChange: sort.onChange,
                }}
              />
            )}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
