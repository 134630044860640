import { Box, Button, Chip, IconButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { useCallback, useState } from "react";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import AdminTenantInline from "@/common/components/Entity/Tenant/AdminTenantInline";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { clipboardService } from "@/common/services/clipboard";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AdminInviteDto,
  AdminInviteGetPaginatedDto,
  AppPermission,
  EntityType,
  InviteType,
} from "@/core/api/generated";
import * as tenantInvitesSlice from "@/store/management/tenantInvites/slice";
import { useHistory } from "react-router";

export default function InvitesPage() {
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();
  const thunkDispatch = useAppThunkDispatch();
  const commonRequestParams = useCommonRequestParams<AdminInviteGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.Invite),
    },
  });

  const [inviteToResend, setInviteToResend] = useState<AdminInviteDto | null>(null);
  const [inviteToDelete, setInviteToDelete] = useState<AdminInviteDto | null>(null);
  const [confirmResendInviteModalOpen, setConfirmResendInviteModalOpen] = useState(false);
  const [confirmDeleteInviteModalOpen, setConfirmDeleteInviteModalOpen] = useState(false);

  const paginatedInvitesRequest = useApiRequest(
    apiClient.adminInvitesApi.apiV1AdminInvitesGetPost,
    {
      adminInviteGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedInvites = paginatedInvitesRequest.data;

  const handleResendPersonalInvite = useCallback(async () => {
    try {
      await thunkDispatch(
        tenantInvitesSlice.resendPersonalInvite({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          inviteId: inviteToResend!.id!,
        }),
      );
      enqueueSnackbar("Invite resent", { variant: "success" });
      setConfirmResendInviteModalOpen(false);
    } catch (err: any) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [inviteToResend]);

  const handleDeleteInvite = useCallback(async () => {
    try {
      await thunkDispatch(
        tenantInvitesSlice.deleteInvite({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          inviteId: inviteToDelete!.id!,
        }),
      );
      enqueueSnackbar("Invite deleted", { variant: "success" });
      paginatedInvitesRequest.refetch();
      setConfirmDeleteInviteModalOpen(false);
    } catch (err: any) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [inviteToDelete]);

  return (
    <ListPageLayout>
      <Stack>
        <SimpleViewPageHeader
          title={undefined}
          primaryActions={
            <>
              <Button
                variant='contained'
                color='primary'
                startIcon={<AppIcon of='add' />}
                component={AppLink}
                to={ROUTE_PATH.ADMIN_INVITES_CREATE_PERSONAL()}
              >
                New personal invite
              </Button>

              <Button
                sx={{ ml: 1 }}
                variant='contained'
                color='primary'
                startIcon={<AppIcon of='add' />}
                component={AppLink}
                to={ROUTE_PATH.ADMIN_INVITES_CREATE_ANONYMOUS()}
              >
                New anonymous invite
              </Button>
            </>
          }
        />

        <DataTabular<AdminInviteDto>
          columns={[
            {
              field: "type",
              title: "Type",
              flex: 1,
              renderCell: (item) => <InlineApiEnumValue type='InviteType' value={item.type} />,
            },
            {
              field: "userType",
              title: "User type",
              flex: 1,
              renderCell: (item) => (
                <InlineApiEnumValue type='InviteUserType' value={item.userType} />
              ),
            },
            {
              field: "currentUsers",
              title: "Current/Max users",
              flex: 1,
              renderCell: (item) => (
                <>
                  {item.currentUsers} / {item.maxUsers}
                </>
              ),
            },
            {
              field: "tenantId",
              title: "Tenant",
              flex: 1,
              renderCell: (item) => (
                <AdminTenantInline entity={undefined} entityId={item.tenantId} />
              ),
            },
            {
              field: "userInfo.email",
              title: "Receiver",
              flex: 2,
              renderCell: (item) =>
                item.userInfo ? (
                  <Stack direction='column'>
                    <Box>{item.userInfo?.email}</Box>
                    {/* <Box>{item.userInfo?.phoneNumber}</Box>
                    <Box>
                      {item.userInfo?.personName?.firstName} {item.userInfo?.personName?.lastName}
                    </Box> */}
                  </Stack>
                ) : (
                  "-"
                ),
            },
            {
              field: "sentAt",
              title: "Sent at",
              flex: 1,
              renderCell: (item) => (
                <Datetime datetime={item.sentAt} direction='column' withDurationFromNow />
              ),
            },
            {
              field: "expiresAt",
              title: "Expires at",
              flex: 1,
              renderCell: (item) => (
                <>
                  <Datetime datetime={item.expiresAt} direction='column' withDurationFromNow />{" "}
                  {item.isExpired && (
                    <Chip
                      size='small'
                      color='error'
                      variant='outlined'
                      label='Expired'
                      sx={{ ml: 1 }}
                    />
                  )}
                </>
              ),
            },
            // {
            //   field: "roleIds",
            //   title: "Roles",
            //   xxs: 12,
            //   md: 1,
            //   flex: 1,
            //   renderCell: (item) => (
            //     <Stack>
            //       {item?.roleIds?.map((id, i) => <InlineRoleDisplay key={i} role={id} />)}
            //     </Stack>
            //   ),
            // },
            // {
            //   field: "departmentId",
            //   title: "Departments",
            //   xxs: 12,
            //   md: 1,
            //   flex: 1,
            //   renderCell: (item) =>
            //     item.departmentId ? (
            //       <DepartmentInline entity={undefined} entityId={item.departmentId} />
            //     ) : (
            //       "-"
            //     ),
            // },
            // {
            //   field: "customerId",
            //   title: "Customer",
            //   xxs: 12,
            //   md: 1,
            //   flex: 1,
            //   renderCell: (item) =>
            //     item.customerId ? (
            //       <CustomerInline entity={undefined} entityId={item.customerId} />
            //     ) : (
            //       "-"
            //     ),
            // },
          ]}
          rows={paginatedInvitesRequest.data?.items}
          isLoading={paginatedInvitesRequest.isLoading}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.ADMIN_INVITE_VIEW(item.id)}
          renderRowAction={({ item }) => (
            <MenuWithTrigger
              withAuthCloseOnClick
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              <AuthorizedMenuItem
                permissions={[AppPermission.AdminInviteRead]}
                onClick={async () => {
                  try {
                    await clipboardService.writeText(item.inviteUrl as string);
                    enqueueSnackbar("Link copied to clipboard.", {
                      variant: "success",
                    });
                  } catch (error) {
                    console.error("Clipboard copy error", error);
                  }
                }}
              >
                <ListItemIcon>
                  <AppIcon of='link' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Copy link</ListItemText>
              </AuthorizedMenuItem>

              <AuthorizedMenuItem
                permissions={[AppPermission.AdminInviteManage]}
                component={AppLink}
                to={
                  (item.type === InviteType.Personal
                    ? ROUTE_PATH.MANAGEMENT_INVITES_CREATE_PERSONAL({ sourceInviteId: item.id })
                    : undefined) ||
                  (item.type === InviteType.Anonymous
                    ? ROUTE_PATH.MANAGEMENT_INVITES_CREATE_ANONYMOUS({ sourceInviteId: item.id })
                    : undefined)
                }
              >
                <ListItemIcon>
                  <AppIcon of='add' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Create new from this invite</ListItemText>
              </AuthorizedMenuItem>

              <AuthorizedMenuItem
                permissions={[AppPermission.AdminInviteManage]}
                disabled={item.isExpired || item.type !== InviteType.Personal}
                onClick={() => {
                  setInviteToResend(item);
                  setConfirmResendInviteModalOpen(true);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='email' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Resend</ListItemText>
              </AuthorizedMenuItem>

              <AuthorizedMenuItem
                permissions={[AppPermission.AdminInviteManage]}
                onClick={() => {
                  setInviteToDelete(item);
                  setConfirmDeleteInviteModalOpen(true);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='delete' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </AuthorizedMenuItem>
            </MenuWithTrigger>
          )}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
        />
      </Stack>

      {/* Resend confirmation */}
      {inviteToResend && (
        <ConfirmationModal
          title='Resend the invite?'
          body={
            <>
              {`You're going to resend the invite to`}{" "}
              <Box>
                <strong>{inviteToResend.userInfo?.email}</strong>
              </Box>
            </>
          }
          open={confirmResendInviteModalOpen}
          onClose={() => setConfirmResendInviteModalOpen(false)}
          onCancel={() => setConfirmResendInviteModalOpen(false)}
          onConfirm={handleResendPersonalInvite}
        />
      )}

      {/* Delete confirmation */}
      {inviteToDelete && (
        <ConfirmationModal
          title='Delete the invite?'
          body={
            <>
              {`You're going to delete the invite`}. {`This action can't be undone.`}
            </>
          }
          open={confirmDeleteInviteModalOpen}
          onClose={() => setConfirmDeleteInviteModalOpen(false)}
          onCancel={() => setConfirmDeleteInviteModalOpen(false)}
          onConfirm={handleDeleteInvite}
        />
      )}
    </ListPageLayout>
  );
}
